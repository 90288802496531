import {
  HR_PROCESS_CANDIDATE_DETAILS_GET_FOR_HR_PROCESS,
  operationType,
  operationTypeHash,
  inputFieldType,
  hrActionInputFieldType,
  inputFieldTypeHash,
  hrActionInputFieldTypeHash,
  candidateFormat,
  candidateFieldTypeHash,
  candidateFieldType,
  HR_PROCESS_CANDIDATE_DETAILS,
  HR_PROCESS_CANDIDATE_DETAILS_EDIT,
  HR_PROCESS_CANDIDATE_RECRUITMENT_EDIT,
  HR_PROCESS_INTERVIEW_SLOTS_ADD,
  HR_PROCESS_PANELIST_NAME_CHANGED,
  HR_PROCESS_INTERVIEW_SLOTS_DELETE,
  HR_PROCESS_CANDIDATE_FEEDBACK,
  HR_PROCESS_CANDIDATE_DETAILS_HIDE,
  candidateAgencyFormat,
  HR_PROCESS_DUPLICATE_CANDIDATE_GET,
  HR_PROCESS_CANDIDATE_SKIP_ROUND,
  HR_PROCESS_CANDIDATE_COMMENT_TOGGLE,
  HR_PROCESS_CANDIDATE_OFFER_TOGGLE,
  HR_PROCESS_CANDIDATE_OFFER_READ,
  HR_PROCESS_CANDIDATE_OFFER_LOADER,
  HR_PROCESS_CANDIDATE_OFFER_UPDATE_LOCAL,
  HR_PROCESS_CANDIDATE_HR_STATUS_UPDATE_LOCAL,
  HR_PROCESS_CANDIDATE_JOB_CHANGE_TOGGLE,
  HR_PROCESS_CANDIDATE_ROUND_CHANGE_TOGGLE,
  HR_PROCESS_CANDIDATE_JOB_CHANGE_LOADER,
  HR_PROCESS_SET_CANDIDATE_SCREENSHOT,
  HR_PROCESS_TOOGLE_FEEDBACK_LOADER,
  HR_PROCESS_SET_SELECTED_CANDIDATE,
  HR_PROCESS_SET_CANDIDATE_FEEDBACK,
  HR_PROCESS_SELECT_CANDIDATE_ROUND,
  HR_PROCESS_SET_FEEDBACK_VIEW,
  HR_PROCESS_SET_HR_ACTION_EDITING_VIEW,
  HR_PROCESS_SEARCH_VALUE
} from "./action";


let initialState = {
  candidateTableLoader: false,
  candidates: [],
  panelists:[],
  candidateJobDescription: {},
  panelist: candidateFormat.panelist,
  changedPanelist:false,
  candidateJobDescriptionForHistory: [],
  candidateScreenType: "HrProcess",
  candidatesHrStatus: {},
  candidateInterviewMode: {},
  candidateAgencies: [{}],
  candidateJobs: [],
  candidateResetRounds: [{title : "Round 0"},
                         {title : "Current Round"}],
  candidateOriginalInfo: candidateFormat,
  candidateInfo: candidateFormat,
  candidateViewModal: false,
  candidatePreviousOrgsViewModal: false,
  candidateJobChangeModal: false,
  candidatePDFViewModal: false,
  candidateImageViewModal: false,
  candidateResume: "",
  candidateImage: "",
  // candidateAgency: candidateAgencyFormat,
  candidateAgency: undefined,
   candidateAgencyForHistory: [],
  candidateShortlistedLoader: false,
  candidateRejectedLoader: false,
  candidatePhoto: [],
  interviewModal: false,
  interviewSubmitLoader: false,
  interviewOnModalLoader: false,
  interviewDeleteLoader: false,
  interviewType: "Offline",
  interviewSchedule: {},
  interviewDate: null,
  interviewAvailableSlots: [],
  interviewSelfBookedSlots: [],
  interviewPanelistName: "",
  interviewConfirmModal: false,
  candidateDateDropdown: false,
  candidateDateRange: null,
  candidateStatusDropdown: false,
  candidateFilterStatus: "All",
  hiringFilterStatus: "All",
  candidateRound: "All",
  candidateHrStatus: "",
  duplicateViewModal: false,
  duplicateCandidates: [],
  candidateOngoingStatus: [],
  candidateRejectedStatus: [],
  candidateNewPageNumber: 1,
  candidateupdatedResume:"",
  candidateOngoingPageNumber: 1,
  candidateRejectedPageNumber: 1,
  candidateHistoryPageNumber: 1,
  candidateOfferBool: false,
  candidateCommentBool: false,
  candidateOfferText: "",
  candidateCommentText: "",
  candidateOfferLoaderBool: false,
  candidateJobChange: {},
  candidateRoundChange: {},
  candidateJobChangeToggleBool: false,
  candidateJobChangeToggle: false,
  candidateRoundChangeToggle: false,
  candidateJobChangeLoaderBool: false,
  candidatePhotoEdit: {},
  candidatePhotoLoader: false,
  candidatePhotoEditBool: false,
  candidatePageMeta: {},
  feedbackLoader: false,
  selectedCandidate: {},
  candidateFeedback: {},
  candidateRounds: [],
  candidatesScreenshot: [],
  candidateFeedbackRound: null,
  viewFeedback: false,
  editHrActions: false,
  hrActionEditingModal : false,
  actionEditingModal : false,
  hrActionEditingLoader: false,
  hrProcessSearchValue:''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HR_PROCESS_SELECT_CANDIDATE_ROUND:
      return {
        ...state,
        candidateFeedbackRound: action.val
      }
    case HR_PROCESS_SET_CANDIDATE_FEEDBACK:
      return {
        ...state,
        candidateFeedback: action.data,
        candidateRounds: action.data?.feedback?.map((item, index) => ({value: index, label: `Round ${item.round}`})),
        candidatesScreenshot: action.data?.feedback?.map((item, index) => ({value: index, label: `${item.screenshot}`})),
        candidateFeedbackRound: action.data?.feedback?.length ? 0 : null
      }
    case HR_PROCESS_SET_SELECTED_CANDIDATE:
      return {
        ...state,
        selectedCandidate: action.data
      }
    case HR_PROCESS_SET_FEEDBACK_VIEW:
      return {
        ...state,
        viewFeedback: action.bool
      }
    case HR_PROCESS_SET_HR_ACTION_EDITING_VIEW:
      return {
        ...state,
        editHrActions: action.bool
      }
    case HR_PROCESS_TOOGLE_FEEDBACK_LOADER:
      return {
        ...state,
        feedbackLoader: action.bool
      }
    case HR_PROCESS_SET_CANDIDATE_SCREENSHOT:
      return {
        ...state,
        candidatesScreenshot: action.value
      }
    case HR_PROCESS_CANDIDATE_SKIP_ROUND:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                currentRound: action.value.candidateRound,
                candidateStatus: action.value.candidateStatus,
                feedback: [
                  ...candidate.feedback,
                  {
                    round: action.value.candidateRound,
                    status: action.value.status,
                    interviewDate: action.value.interviewDate,
                    createdAt: action.value.createdAt,
                    jobId: action.value.jobId,
                  },
                ],
              }
            : candidate
        ),
      };
    case HR_PROCESS_DUPLICATE_CANDIDATE_GET:
      return {
        ...state,
        duplicateCandidates: action.value,
      };
    case HR_PROCESS_CANDIDATE_DETAILS_GET_FOR_HR_PROCESS:
      const candidates =
        action.value !== undefined
          ? action.value.candidates.map((candidate) => ({ ...candidate, key: candidate.id, candidateHrStatus: candidate.hrStatus?.hrStatus }))
          : [];
      return {
        ...state,
        candidates: candidates,
        candidatesHrStatus: action.value.candidatesHrStatus,
        candidatePageMeta: action.value.pageMeta,
      };

  
    case HR_PROCESS_CANDIDATE_DETAILS_EDIT:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id ? action.candidate : candidate
        ),
        candidateInfo: action.candidate
      };
    case HR_PROCESS_CANDIDATE_DETAILS_HIDE:
      return {
        ...state,
        candidates: state.candidates.filter((candidate) => candidate.id !== action.id),
      };
    case HR_PROCESS_CANDIDATE_RECRUITMENT_EDIT:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                [candidateFieldTypeHash[action.kind]]: action.value,
              }
            : candidate
        ),
        candidateInfo: {
          ...state.candidateInfo,
          [candidateFieldTypeHash[action.kind]]: action.value,
        },
      };
    case HR_PROCESS_INTERVIEW_SLOTS_ADD:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                interviewSlots: action.value,
              }
            : candidate
        ),
        candidateInfo: { ...state.candidateInfo, interviewSlots: action.value },
      };
    case HR_PROCESS_PANELIST_NAME_CHANGED:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                panelist: action.value,
              }
            : candidate
        ),
        candidateInfo: { ...state.candidateInfo, panelist: action.value },
      };
    case HR_PROCESS_INTERVIEW_SLOTS_DELETE:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                interviewSlots: [],
              }
            : candidate
        ),
        candidateInfo: { ...state.candidateInfo, interviewSlots: [] },
      };
    case HR_PROCESS_CANDIDATE_FEEDBACK:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                feedback: [...candidate.feedback, action.value],
              }
            : candidate
        ),
      };
    case operationType[action.type]:
      return {
        ...state,
        [operationTypeHash[action.type]]: action.value,
      };
    case inputFieldType[action.type]:
     
      return {
        ...state,
        [inputFieldTypeHash[action.type]]: action.value,
      };
    case hrActionInputFieldType[action.type]:
      return {
        ...state,
        candidateInfo: {
          ...state.candidateInfo,
          hrStatus: {
            ...state.candidateInfo.hrStatus,
            [hrActionInputFieldTypeHash[action.type]]: action.value,
          }
        },
    };
    case HR_PROCESS_CANDIDATE_DETAILS: {
      return {
        ...state,
        candidateInfo: action.value,
        candidateOriginalInfo: action.value,
      };
    }
    case candidateFieldType[action.type]:
      return {
        ...state,
        candidateInfo: { ...state.candidateInfo, [candidateFieldTypeHash[action.type]]: action.value },
      };
    case HR_PROCESS_CANDIDATE_COMMENT_TOGGLE:
      return {
        ...state,
        candidateCommentBool: action.id,
        candidateCommentText: action.text === null ? "" : action.text,
      };
    case HR_PROCESS_CANDIDATE_OFFER_TOGGLE:
      return {
        ...state,
        candidateOfferBool: action.id,
        candidateOfferText: action.text === null ? "" : action.text,
    };
    case HR_PROCESS_CANDIDATE_OFFER_UPDATE_LOCAL:
      state.candidates.map((candidate, index) => {
        if (action.id === candidate.id) {
          candidate.hrStatus.offeredCTC = action.text;
        }
      });
      return {
        ...state,
        candidates: [...state.candidates],
      };
    case HR_PROCESS_CANDIDATE_HR_STATUS_UPDATE_LOCAL:
      state.candidates.map((candidate, index) => {
        if (action.id === candidate.id) {
          candidate.candidateHrStatus = action.text;
          //candidate.hrStatus.hrStatus = action.text;
        }
      });
      return {
        ...state,
        candidates: [...state.candidates],
      };
    case HR_PROCESS_CANDIDATE_OFFER_READ:
      return {
        ...state,
        candidateOfferText: action.input,
      };
    case HR_PROCESS_CANDIDATE_OFFER_LOADER:
      return {
        ...state,
        candidateOfferLoaderBool: action.bool,
      };
    case HR_PROCESS_CANDIDATE_JOB_CHANGE_TOGGLE:
      return {
        ...state,
        candidateJobChangeToggleBool: action.bool,
        candidateJobChange: action.obj,
      };
    case HR_PROCESS_CANDIDATE_ROUND_CHANGE_TOGGLE:
      return {
        ...state,
        candidateRoundChangeToggleBool: action.bool,
        candidateRoundChange: action.obj,
      };
    case HR_PROCESS_CANDIDATE_JOB_CHANGE_LOADER:
      return {
        ...state,
        candidateJobChangeLoaderBool: action.bool,
      };
      // case HR_PROCESS_SEARCH_VALUE: 
      // return{
      //   ...state,
      //   hrProcessSearchValue: action.payload
      // }
    default:
      return state;
  }
};

export default reducer;
