import React from "react";
import { useEffect,useState } from "react";
import { Api } from "services";
import { feedbackQuesActions } from "./store";
import { useDispatch, useSelector } from "react-redux";
import { Form,Input, Radio, Rate, Slider,Button,Row,Upload, Progress, Spin,Image,Select,Typography } from "antd";
import classes from './feedback.module.css';
import brigoshaLogo from '../../assets/brigoshaLogo.svg';
import {Notification} from "services";
import { UserOutlined,UpOutlined,LoadingOutlined,UploadOutlined,PlusOutlined } from "@ant-design/icons";
import userIcon from '../../assets/user-icon.svg';
import selectIcon from '../../assets/selectIcon.svg';
import canFeedbackIcon from '../../assets/canFeedbackImg.svg';
import { useHistory } from "react-router";
import PreviousFeedback from "./previousFeedback";
import PdfLogo from "../../assets/ResumeImages.svg";
import UploadIcon from "../../assets/UploadIconss.svg";
import DeleteIcon from "../../assets/DeleteUploadIcon.svg";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
          return {
                  width,
                  height
          };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}
 const Feedback = (props) =>{
       
          const [statused,setStatused] = useState(true)
      
          const [showBasicInfo,setShowBasicInfo]=useState(false);
         const[imageUrl,setImageUrl]=useState("");
         const[imageFile,setImageFile]=useState("");
         const [spin,setSpin]=useState(true);
         const [isResponse,setIsResponse]=useState(true);
         const[imgFile,setImgFile]=useState([])
         const [feedbacks,setFeedbacks]=useState([]);
         const [roundType,setRoundType]=useState();
         const [OpenFeedback,setOpenFeedback]=useState(false);
         const{candidateRoundType}=useSelector(state=>state?.feedback?.info)
         const type=props?.type;
         const SFInfo=useSelector(state=>state?.feedback?.info);
        //  const OpenFeedback=useSelector(state=>state?.feedback?.OpenFeedback);
         const { height, width } = useWindowDimensions();  
         const [hrRoundQuestions,setHrRoundQuestions] = useState({});
         const [canFeedbackQues,setCanFeedbackQues]=useState([]); 
         const history=useHistory();
         const [othersValue,setOthersValue] = useState(null);
        const marks = {
        0: '0',
        10: '1',
        20: '2',
        30: '3',
        40: '4',
        50: '5',
        60: '6',
        70: '7',
        80: '8',
        90: '9',
        100: '10',
        }
        const marks2 = {
          0: '0',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
          10: '10',
          }
    let params = new URL(location.href);
    let valued;
    if(type==='panelist')
    {
      valued=params.search.split("=");
    }
    else if(type==='candidate')
    {
      valued=params.pathname.split('/');
    }   
    const dispatch=useDispatch()
    const {feedbackQuestions ,candidate,interviewer } = useSelector(state =>state.feedback.info);
    const [answer,setAnswer]=useState(new Array(20).fill(""));
    const [progess,setProgess]=useState(0);
  
    useEffect(() =>{
      if(type==='panelist')
      {   
        Api.get(`/hiring-publicapi/feedbackFromPanelist/?candidateId=${parseInt(valued[1])}`)
           .send((response, error) => {
            if (response !== undefined && response?.show?.type!=="error") {
              if(response.candidate.hiringStatus==='Hold')
              {
                Notification.error("Feedback already submitted")
                setTimeout(()=>{ window.close()},3000)
              }else{
               setStatused(false);
               setSpin(false);
               dispatch(feedbackQuesActions.updateFeedbackQuestion(response));  
               setHrRoundQuestions(response?.lastRoundDetail)
               setFeedbacks(response?.previousRoundsFeedback);
               setRoundType(response?.candidateRoundType)
              }
            }
             else {
              setIsResponse(false);
              setStatused(true)
            }       
          });
        }
        if(type==='candidate')
        {
          Api.get(`/hiring-publicapi/candidateFeedback/${parseInt(valued[3])}/${parseInt(valued[4])}`).send(res=>{
            if(res&& res?.show?.type!=="error")
            {
              setSpin(false);
              setCanFeedbackQues(res?.questions);        
            }
            else{
              setIsResponse(false);
            }
          })
        }
    },[])
    useEffect(()=>{
      let count=0;
      for(let i=0; i<answer.length; i++)
      {      
        if(answer[i]!=='')
        {
          count++;
        }
      }
      let length=4;
      const arr=feedbackQuestions?.feedBackQuestionsMeta;   
      for(let i=0; i<arr?.length; i++)
      {
        if(arr[i].require===true)
        {
          length++;
        }
      }
      if(length>0)
      {
        if(length===count)
        {
          setProgess(((count/length)*100));
        }
        else
        {
          setProgess(((count/length)*100).toPrecision(2));
        }       
      }
    },[answer,feedbackQuestions?.feedBackQuestionsMeta?.length])
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }    
    const imgbeforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";  
      const isLt10M = file.size / 1024 / 1024 < 10;
       if (!isLt10M) {
        message.error("Image must be smaller than 10MB!");
      }
      else {
        getBase64(file, (url) => {
          setImageUrl(url);
          setImageFile(file);          
        });
      }
    }    

    const handleFileRemove = () => {
      setImageUrl("");
    };

    const docBeforeUpload = (file,question) => {
        const isLt10M = file.size / 1024 / 1024 < 10;
         if (!isLt10M) {
          message.error("Image must smaller than 10MB!");
        }
        else {
          getBase64(file, (url) => {            
            Api.post(`/hiring-publicapi/upload`)      
            .upload({file},(per,res) =>{
             if(res)
             {
              setImgFile(prev=>{
                return [...prev,{   question:question, data:res?.data}]
               })
             }          
            })         
           });  
           if (allowedFiles.includes(file.name)) {
            Api.post(`/hiring-publicapi/upload`)      
            .upload({file},(per,res) =>{
             if(res)
             {
              setImgFile(prev=>{
                return [...prev,{   question:question, data:res?.data}]
               })
             }          
            })   
            return false;
           }                
        }
      }  

      const basicDetails =(<div className={classes.basicInfo}>
        <div>
                    <div>
                                <h4>Round: </h4>
                                <span>{SFInfo?.candidate?.interviewSlots[0].round ? SFInfo?.candidate?.interviewSlots[0].round:"NA"}</span>
                               
                    </div>
                    <div>
                                <h4>Date: </h4>
                                <span>{SFInfo?.candidate?.interviewSlots[0].date?SFInfo?.candidate?.interviewSlots[0].date:"NA"} </span>
                                
                    </div>               
        </div>
        <div>
                    <div>
                                  <h4> Round type:  </h4>
                                  <span>{candidateRoundType && candidateRoundType === "Relationship" ? "HR Relationship" 
                                    : candidateRoundType ? candidateRoundType : "NA"} </span> 
                                  
                    </div>
                    <div>
                                  <h4>Mode of interview:  </h4>
                                  <span>{SFInfo?.candidate?.interviewSlots[0].interviewMode?SFInfo?.candidate?.interviewSlots[0].interviewMode:"NA"}</span>  
                                  
                    </div>
                    <div>
                                  <h4>Preferred Location:  </h4>
                                  <span>{SFInfo?.candidate?.preferredLocation?.name ? SFInfo?.candidate?.preferredLocation?.name:"NA"}</span>                                    
                    </div>
        </div>                
        <div>
                      <div>
                                  <h4>Interviewer:  </h4>
                                  <span>{SFInfo?.candidate?.interviewSlots[0].panelist?.name?SFInfo?.candidate?.interviewSlots[0].panelist?.name:"NA"}</span>   
                                 
                      </div>
                      <div>
                                  <h4>Experience:  </h4>
                                  <span>            
                                  <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.5rem"}}>
                                  {SFInfo?.candidate?.experienceYears} year(s),{" "}{SFInfo?.candidate?.experienceMonths} month(s) 
                                  </Typography.Text></span>  
                      </div>     
                      <div>
                                  <h4>Job:  </h4>
                                  <span>{SFInfo?.candidate?.job?.title}</span>  
                      </div>                                       
        </div>                      
</div>)
  return(
    <>
              <header className={classes.mainHeader}>
                      <img src={brigoshaLogo}></img>
              </header>  
              {spin? ( <Spin  size="large" style={{margin:"10% 50%"}} />):
              <div className={classes.container}>
                          {type==='panelist'&&<div className={classes.header}>
                                     
                                      <div className={classes.userProfile}>
                                          <div>
                                              <div>
                                                  <Image src={SFInfo?.candidate?.photo?SFInfo?.candidate?.photo:userIcon} alt=""
                                                  />
                                              </div>
                                              <div>
                                                      <h1>{SFInfo?.candidate?.name}</h1>
                                                      <span>{SFInfo?.candidate?.candidateType?SFInfo?.candidate?.candidateType:"NA"}</span>
                                              </div>
                                          </div>
                                          <div>
                                                  <Progress type="circle"  width="80px" percent={progess} style={{ marginRight: 8 }} />
                                          </div>
                                      </div>
                                      <Row style={{ justifyContent: "flex-end" }}>
                                        <Button type="primary" style={{fontSize:"0.9rem",height:"2.3rem",fontWeight:"600",marginBottom:"0.5rem"}}
                                         onClick={()=>{
                                          setOpenFeedback(true)
                                          // dispatch(feedbackQuesActions.updateOpenFeedback(true))
                                         }}>
                                        <PlusOutlined style={{fontWeight:"600",fontSize:"0.88rem"}}/>Previous Round Feedback
                                        </Button>
                                        
                                      </Row> 
                                      {showBasicInfo&&width<400&&basicDetails}
                                      {width>400&&basicDetails}
                                      {width<400&&<img src={selectIcon} className={classes.selectIcon} onClick={()=>setShowBasicInfo(prev=>!prev)}></img>}        
                              </div>}

                              {type==='candidate'&&<div className={classes.canFeedback}>
                                      <div>
                                              <img src={canFeedbackIcon}></img>
                                      </div>
                                      <div>
                                        <h1>Feedback Form</h1>
                                        <span>Kindly share your feedback</span>
                                        <span>Your feedback is valuable to us and will push us to do better in future</span>
                                        
                                      </div>
                                </div>}
                             {type==='panelist'&&candidateRoundType === "HR" && 
                             SFInfo?.candidate?.interviewSlots[0]?.evaluate === true&&
                             hrRoundQuestions?.lastRoundFeedbackId !==null ?
                            <Form layout="vertical" className={classes.formControl} onFinish={(value) =>{
                              // console.log("sssOnFinish",value)
                              const reviewData = [];
                              const details = { ...value };
                              // if(type==='panelist'&&candidateRoundType === "HR"&& SFInfo?.candidate?.interviewSlots[0]?.evaluate === true){
                              const lastRoundReviewKeys = Object.keys(value);
                              const foundKeys = lastRoundReviewKeys.filter((key) => value.hasOwnProperty(key));

                              // console.log("sssKeys",foundKeys)
                              Object.keys(value).forEach((key) => {
                                if (key.endsWith("reasons")) {
                                  const questionKey = key.slice(0, -"reasons".length);
                            
                                  if (value.hasOwnProperty(questionKey)) {
                                    const reviewObj = {
                                      question: questionKey,
                                      answer: value[questionKey],
                                      review: value[key], 
                                    };
                                    reviewData.push(reviewObj);
                                  }
                                }
                              });
                              reviewData.forEach((review) => {
                                // Removing reviewData fields from details
                                delete details[review.question];
                                delete details[review.question + "reasons"];
                              });
                            
                              // console.log("reviewData", reviewData, details);
                              // }
                              for(let i=0; i<imgFile.length; i++)
                              {
                                value[imgFile[i].question]=imgFile[i].data
                              }
                              if(type==='panelist')
                              {
                                // let screenshot1=value.screenshot.file.originFileObj ;
                                  // let overallComment=value.overallComment
                                  // let overallRating=value.overallrating;
                                  let status=value.status 
                                  // delete(value.overallComment)
                                  // delete(value.overallrating)
                                  delete(value.status);
                                  // delete(value.screenshot);
                                  let params
                              //   if(candidateRoundType === "HR"){
                              //     params={
                              //     details:JSON.stringify(details),
                              //     lastRoundData: JSON.stringify({
                              //       lastRoundfeedbackId: hrRoundQuestions?.lastRoundFeedbackId,
                              //       reviewData: reviewData
                              //     }),
                              //     overallComment:overallComment,
                              //     overallRating:overallRating,
                              //     status:status,
                              //     round:candidate.currentRound,
                              //     roundType:candidateRoundType,
                              //     interviewer:interviewer,
                              //     screenshot:screenshot1,
                              //     jobId:candidate.job.id,
                              //     panelistId:candidate.interviewSlots[0].panelist.id,
                              //     interviewDate:candidate.interviewSlots[0]?.date,
                              //     interviewMode:candidate.interviewSlots[0].interviewMode
                              // }}else{
                                params={
                                  details:JSON.stringify({}),
                                  lastRoundData: JSON.stringify({
                                      lastRoundfeedbackId: hrRoundQuestions?.lastRoundFeedbackId,
                                      reviewData: reviewData
                                  }),
                                  // overallComment:"",
                                  // overallRating:overallRating,
                                  status:status,
                                  round:candidate.currentRound,
                                  roundType:candidateRoundType,
                                  interviewer:interviewer,
                                  // screenshot:screenshot1,
                                  jobId:candidate.job.id,
                                  panelistId:candidate.interviewSlots[0].panelist.id,
                                  interviewDate:candidate.interviewSlots[0]?.date,
                                  interviewMode:candidate.interviewSlots[0].interviewMode
                              }
                            // }
                            // console.log("sssssDEtailsssss",JSON.stringify(details))

                              Api.post(`/hiring-publicapi/giveFeedback/${parseInt(valued[1])}`)
                              .params( params)
                              .upload(params,(per,res) =>{   
                                if(res?.data?.show?.type==='success')
                                {
                                  history.push('/thanks');     
                                }                           
                              })
                            }
                            else if(type==='candidate')
                            {
                              let par={
                                details:JSON.stringify(value),
                              } 
                                Api.post(`/hiring-publicapi/feedBackFromCandidate?slotId=${parseInt(valued[3])}&feedbackId=${parseInt(valued[4])}`).params(par).send(res=>{
                                  if(res)
                                  {
                                    if(res.show.type==='success')
                                    {
                                      history.push('/thanks');
                                    }
                                  }
                                })
                              }
                            
                            }}>
                              {/* {type==='panelist'&&candidateRoundType === "HR"? */}
                              {/* <> */}
                              <h3 style={{margin:"0.5rem"}}>Previous HR-Relationship Round Feedback</h3>
                              {(Object.keys(hrRoundQuestions?.lastRoundReview || {}).length === 0) && (hrRoundQuestions?.overallComment === null &&
                               hrRoundQuestions?.overallRating === null && hrRoundQuestions?.overallComment === null) ?
                                <div style={{backgroundColor:"white",height:"5rem",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                  No Feedback Questions of previous Hr Relationship round
                                </div>
                                :
                                <div>
                                <>
                                {Object.entries(hrRoundQuestions).map(([key, value]) => {
                                  return (key === "overallComment" && value !== null?
                                    <div className={classes.wrapper} key={key}>
                                      <Form.Item label={<div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
                                        <span>{key}</span><span>answer : {value}</span></div>} name={key} 
                                        rules={[{ required: true, message: "Please answer the question!" }]}>
                                        <Radio.Group
                                          className={classes.options}
                                          onChange={(event) => {
                                            setOthersValue(event.target.value);
                                          }}
                                        >
                                          <Radio value={"yes"}>Yes</Radio>
                                          <Radio value={"no"}>No</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                      <Form.Item name={key+"reasons"} >
                                      <Input placeholder="Enter the Reason"
                                      onChange={(e)=>{
                                        // setInputVal(e.target.value);
                                      }}
                                      />
                                      </Form.Item>
                                      </div>
                                      : key === "overallRating" && value !== null?
                                      <div className={classes.wrapper} key={key}>
                                      <Form.Item label={<div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
                                        <span>{key}</span><span>answer : {value}</span></div>} name={key} 
                                        rules={[{ required: true, message: "Please answer the question!" }]}>
                                        <Radio.Group
                                          className={classes.options}
                                          onChange={(event) => {
                                            setOthersValue(event.target.value);
                                          }}
                                        >
                                          <Radio value={"yes"}>Yes</Radio>
                                          <Radio value={"no"}>No</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                      <Form.Item name={key+"reasons"} >
                                      <Input placeholder="Enter the Reason"
                                      onChange={(e)=>{
                                        // setInputVal(e.target.value);
                                      }}
                                      />
                                      </Form.Item>
                                      </div> 
                                      :""
                                  );
                                })}
                                </>
                                {/* :""} */} 
                                {
                                (hrRoundQuestions?.lastRoundReview !== undefined &&hrRoundQuestions?.lastRoundReview !== null) ?<>
                                {Object.entries(hrRoundQuestions?.lastRoundReview).map(([key, value]) => {
                                  return (key === "screenshot" ? "": 
                                    <div className={classes.wrapper} key={key}>
                                      <Form.Item label={<div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
                                        <span>{key}</span><span>answer : {value}</span></div>} name={key} 
                                        rules={[{ required: true, message: "Please answer the question!" }]}>
                                        <Radio.Group
                                          className={classes.options}
                                          onChange={(event) => {
                                            setOthersValue(event.target.value);
                                          }}
                                        >
                                          <Radio value={"yes"}>Yes</Radio>
                                          <Radio value={"no"}>No</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                      <Form.Item name={key+"reasons"} >
                                      <Input placeholder="Enter the Reason"
                                      onChange={(e)=>{
                                        // setInputVal(e.target.value);
                                      }}
                                      />
                                      </Form.Item>
                                      {/* {othersValue === "Other"?
                                      
                                      :""} */}
                                    </div>
                                  );
                                })}
                                </>
                                  :""}
                                </div>}
                                {type==='panelist'&&<div className={classes.wrapper}>
                                              <Form.Item label="Candidate Result" name="status" required={true} rules={[{required:true,message:"Please answer to the question!"}]}>
                                              <Radio.Group className={classes.options}
                                              onChange={(event)=>{                                                                                   
                                                setAnswer(answer.map((item2,index2)=>{
                                              if(index2===2)
                                              {
                                                return event;
                                              }
                                              return item2;
                                            }))}}
                                              >
                                                <div>
                                                      <Radio value="Selected">Selected</Radio>
                                                </div>
                                              <div>
                                                      <Radio value="Not Selected"> Not Selected</Radio>
                                                </div>
                                                <div>
                                                      <Radio value="Hold">On hold</Radio>
                                                </div>
                                              </Radio.Group>
                                              </Form.Item>
                                      </div>}
                               {/* :""} */}
                               <div className={classes.buttonDiv}>                      
                                    <Button htmlType="submit" >SUBMIT</Button>
                                </div>
                              </Form>
                              :
                              <Form layout="vertical" className={classes.formControl} onFinish={(value) =>{
                                // console.log("sssOnFinish",value)
                                const reviewData = [];
                                const details = value;
                                // console.log("reviewData", details);
                                if(type==='panelist'&&candidateRoundType === "HR"){
                                const lastRoundReviewKeys = Object.keys(value);
                                const foundKeys = lastRoundReviewKeys.filter((key) => value.hasOwnProperty(key));
  
                                // console.log("sssKeys",foundKeys)
                                Object.keys(value).forEach((key) => {
                                  if (key.endsWith("reasons")) {
                                    const questionKey = key.slice(0, -"reasons".length);
                              
                                    if (value.hasOwnProperty(questionKey)) {
                                      const reviewObj = {
                                        question: questionKey,
                                        answer: value[questionKey],
                                        review: value[key], 
                                      };
                                      reviewData.push(reviewObj);
                                    }
                                  }
                                });
                                reviewData.forEach((review) => {
                                  // Removing reviewData fields from details
                                  delete details[review.question];
                                  delete details[review.question + "reasons"];
                                });
                              
                                // console.log("reviewData", reviewData, details);
                                }
                                for(let i=0; i<imgFile.length; i++)
                                {
                                  value[imgFile[i].question]=imgFile[i].data
                                }
                                if(type==='panelist')
                                {
                                  let screenshot1=value.screenshot.file.originFileObj ;
                                    let overallComment=value.overallComment;
                                    let overallRating=value.overallRating;
                                    let status=value.status 
                                    delete(details.overallComment)
                                    delete(details.overallRating)
                                    delete(details.status);
                                    delete(details.screenshot);
                                    let params
                                  // if(candidateRoundType === "HR"){
                                    params={
                                    details:JSON.stringify(details),
                                    // lastRoundData: {},
                                    overallComment:overallComment,
                                    overallRating:overallRating,
                                    status:status,
                                    round:candidate.currentRound,
                                    roundType:candidateRoundType,
                                    interviewer:interviewer,
                                    screenshot:screenshot1,
                                    jobId:candidate.job.id,
                                    panelistId:candidate.interviewSlots[0].panelist.id,
                                    interviewDate:candidate.interviewSlots[0]?.date,
                                    interviewMode:candidate.interviewSlots[0].interviewMode
                                // }}else{
                                //   params={
                                //     details:JSON.stringify(value),
                                //     overallComment:overallComment,
                                //     overallRating:overallRating,
                                //     status:status,
                                //     round:candidate.currentRound,
                                //     roundType:candidateRoundType,
                                //     interviewer:interviewer,
                                //     screenshot:screenshot1,
                                //     jobId:candidate.job.id,
                                //     panelistId:candidate.interviewSlots[0].panelist.id,
                                //     interviewDate:candidate.interviewSlots[0]?.date,
                                //     interviewMode:candidate.interviewSlots[0].interviewMode
                                // }
                              }
                              // console.log("sssssDEtailsssss",details)
                                Api.post(`/hiring-publicapi/giveFeedback/${parseInt(valued[1])}`)
                                .params( params)
                                .upload(params,(per,res) =>{   
                                  if(res?.data?.show?.type==='success')
                                  {
                                    history.push('/thanks');     
                                  }                           
                                })
                              }
                              else if(type==='candidate')
                              {
                                let par={
                                  details:JSON.stringify(value),
                                } 
                                  Api.post(`/hiring-publicapi/feedBackFromCandidate?slotId=${parseInt(valued[3])}&feedbackId=${parseInt(valued[4])}`).params(par).send(res=>{
                                    if(res)
                                    {
                                      if(res.show.type==='success')
                                      {
                                        history.push('/thanks');
                                      }
                                    }
                                  })
                                }
                              
                              }}>
                              {/* {type==='panelist'&&candidateRoundType === "HR"?
                              <h3 style={{margin:"0.5rem"}}>Current Round Feedback</h3>
                              :""} */}
                              {(type==='panelist'?feedbackQuestions?.feedBackQuestionsMeta:type==='candidate'?canFeedbackQues:[])?.map((element,index) =>{
                                  if(element.inputType==="Text"){
                                  return (
                                    <div className={classes.wrapper}>
                                      <Form.Item label={element.question} name={element.question}  rules={[{required:element.require,message:"Please answer to the question!"}]}>
                                      <Input
                                        onChange={(event)=>{if(element.require===true){
                                          setAnswer(answer.map((item2,index2)=>{
                                        if((type==='panelist'&&index+4===index2)||(type==='candidate'&&index===index2))
                                        {
                                          return event.target.value;
                                        }
                                        return item2;
                                      }))}}}
                                      />
                                      </Form.Item>
                                      </div>
                                  )
                              } 
                                  if(element.inputType==="Slider"){
                                      return (
                                        <div className={classes.wrapper}>
                                          <Form.Item label={element.question} name={element.question}  rules={[{required:element.require,message:"Please answer to the question!"}]}>
                                          <Slider 
                                          step={null}
                                           marks={marks2}                                          
                                           min={0}
                                           max={10}
                                           onChange={(event)=>{
                                          
                                            if(element.require===true){
                                            setAnswer(answer.map((item2,index2)=>{
                                          if((type==='panelist'&&index+4===index2)||(type==='candidate'&&index===index2))
                                          {                           
                                            return event;
                                          }

                                          return item2;
                                        }))}}}
                                              />
                                          </Form.Item>
                                          </div>
                                      )
                              }
                              if(element.inputType==="Rating"){
                                  return (
                                    <div className={classes.wrapper}>
                                      <Form.Item label={element.question} name={element.question}  rules={[{required:element.require,message:"Please answer to the question!"}]} >
                                      <Rate className={classes.rate} 
                                      onChange={(event)=>{if(element.require===true){
                                        setAnswer(answer.map((item2,index2)=>{
                                      if((type==='panelist'&&index+4===index2)||(type==='candidate'&&index===index2))
                                      {
                                        return event;
                                      }
                                      return item2;
                                    }))}}}
                                      />
                                      </Form.Item>
                                      </div>
                                  )
                          }
                          if(element.inputType==="Multiple Choice"){
                              let data=[]
                              Object?.keys(element?.options)?.forEach((elements) =>{
                                  if(element?.options[elements]!==""){
                                      data?.push(element.options[elements])
                                  }
                             
                              })
                              return (
                                <div className={classes.wrapper}>
                                  <Form.Item label={element.question} name={element.question} rules={[{required:element.require,message:"Please answer to the question!"}]}>
                                      <Radio.Group className={classes.options}
                                      onChange={(event)=>{if(element.require===true){
                                        setAnswer(answer.map((item2,index2)=>{
                                      if((type==='panelist'&&index+4===index2)||(type==='candidate'&&index===index2))
                                      {
                                        return event;
                                      }
                                      return item2;
                                    }))}}}
                                      >
                                  {data.map((element ) =>{
                                      return(
                                          <div>
                                                <Radio value={element} >{element}</Radio>
                                          </div>
                                      )
                                  })}
                                  </Radio.Group>
                                  </Form.Item>
                                  </div>
                              )
                          }
                          if(element.inputType==="File Attachment")
                          {
                                          return(<div className={classes.wrapper}>
                                                                    <Form.Item label={element.question} name={element.question} className={classes.headerDiv}   multiple={false} rules={[{required:element.require, message: "Please upload the valid file!",},]}>                                                    
                                                                                <Upload name="file" showUploadList={true} beforeUpload={(file)=>docBeforeUpload(file,element.question)} 
                                                                                                            accept=".png, .jpg, .jpeg, .pdf, .docs"
                                                                                                            maxCount={1} listType="text"
                                                                                                            onChange={(event)=>{if(element.require===true){
                                                                                                              setAnswer(answer.map((item2,index2)=>{
                                                                                                            if((type==='panelist'&&index+4===index2)||(type==='candidate'&&index===index2))
                                                                                                            {
                                                                                                              
                                                                                                              return event;
                                                                                                            }
                                                                                                            return item2;
                                                                                                          }))}}}
                                                                                                            >
                                                                                                <Button icon={<UploadOutlined />} className={classes.attachButton}>Attach a File</Button>
                                                                                </Upload>                                                
                                                                  </Form.Item>  
                                              </div>  ) 
                          }
                              })} 
                                  {type==='panelist'&&<div className={classes.wrapper}>
                                          <Form.Item label={"Overall Comment"} name="overallComment" rules={[{required:true,message:"Please answer to the question!"}]}>
                                                  <Input 
                                                  onChange={(event)=>{                                                                                   
                                                    setAnswer(answer.map((item2,index2)=>{
                                                  if(index2===0)
                                                  {
                                                    return event.target.value;
                                                  }
                                                  return item2;
                                                }))}}
                                                  />
                                          </Form.Item>
                                    </div>}
                                    {type==='panelist'&&<div className={classes.wrapper}>
                                          <Form.Item label="Overall Rating" name="overallRating" rules={[{required:true,message:"Please answer to the question!"}]}>
                                              <Slider 
                                              step={null} 
                                              marks={marks2} 
                                              min={0}
                                              max={10} 
                                              onChange={(event)=>{                                                                                 
                                                setAnswer(answer.map((item2,index2)=>{
                                              if(index2===1)
                                              {
                                                return event;
                                              }
                                              return item2;
                                            }))}}
                                              />
                                            </Form.Item>
                                      </div>}
                                      {type==='panelist'&&<div className={classes.wrapper}>
                                              <Form.Item label="Candidate Result" name="status" required={true} rules={[{required:true,message:"Please answer to the question!"}]}>
                                              <Radio.Group className={classes.options}
                                              onChange={(event)=>{                                                                                   
                                                setAnswer(answer.map((item2,index2)=>{
                                              if(index2===2)
                                              {
                                                return event;
                                              }
                                              return item2;
                                            }))}}
                                              >
                                                <div>
                                                      <Radio value="Selected">Selected</Radio>
                                                </div>
                                              <div>
                                                      <Radio value="Not Selected"> Not Selected</Radio>
                                                </div>
                                                <div>
                                                      <Radio value="Hold">On hold</Radio>
                                                </div>
                                              </Radio.Group>
                                              </Form.Item>
                                      </div>}
                                      {type==='panelist'&&<div className={classes.wrapper}>
                                                                <Form.Item label="Attach candidate's screenshot" name="screenshot" className={classes.headerDiv} required multiple={false} rules={[{required: true,message: "Please upload the valid document!",},]}>                                                    
                                                                                <Upload.Dragger name="file" showUploadList={false} beforeUpload={imgbeforeUpload} style={{backgroundColor:"#EAF4FF",borderColor:"#1089FF",border:"2px dotted #1089FF"}}
                                                                                  accept=".png, .jpg, .jpeg," listType="picture" maxCount={1} onRemove={handleFileRemove}
                                                                                  onChange={(event)=>{                                        
                                                                                    setAnswer(answer.map((item2,index2)=>{
                                                                                    if(index2===3){
                                                                                      return event;
                                                                                    }
                                                                                    return item2;
                                                                                  }))}}                                                                             
                                                                                >
                                                                                  <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"0.8rem"}}>
                                                                                    {imageUrl ? (<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",padding:"0 0.9rem"}}>
                                                                                        <div style={{display:"flex",flexDirection:"row",gap:"1rem",alignItems:"center"}}>
                                                                                        <img
                                                                                            src={PdfLogo}
                                                                                            alt="Initial"
                                                                                            style={{ height: "2.5rem", width: "2.5rem",}}
                                                                                        />
                                                                                        <Typography.Text style={{width:"11rem"}} ellipsis={{tooltip:true}}>
                                                                                            {imageFile.name}
                                                                                        </Typography.Text>
                                                                                        </div>
                                                                                        <div>
                                                                                        <img onClick={handleFileRemove}
                                                                                            src={DeleteIcon}
                                                                                            alt="delete"
                                                                                            style={{ height: "1.4rem", width: "1.5rem",}}
                                                                                        />
                                                                                        </div>
                                                                                        </div>
                                                                                        ) : 
                                                                                        (<>
                                                                                        <p className="ant-upload-drag-icon">
                                                                                        <img src={UploadIcon} style={{height:"2.5rem",width:"2.5rem",}}/>
                                                                                        </p>
                                                                                        <div style={{marginTop:"-1vh"}}>
                                                                                        <h3>Upload your file here</h3>
                                                                                        <p className="ant-upload-hint" style={{color:"#808080"}}>Only PDF files with max size of 5 MB</p> 
                                                                                      </div>
                                                                                      </>)
                                                                                    }
                                                                                  </div>
                                                                                    {/* <Button icon={<UploadOutlined />} className={classes.attachButton}>Attach a File</Button> */}
                                                                                </Upload.Dragger>                                                
                                                                  </Form.Item>   
                                                                  {imageUrl&&<div style={{width:"50%",height:"15rem",display:"flex",margin:"1rem auto"}}>
                                                                      <img src={imageUrl} style={{width:"100%",height:"100%"}}></img>
                                                                  </div>}
                                          </div>}
                                          <div className={classes.buttonDiv}>                      
                                                  <Button htmlType="submit" >SUBMIT</Button>
                                          </div>

                            </Form>
                            }
                {<PreviousFeedback feedbacks={feedbacks} roundType={roundType} openFeedback={OpenFeedback} setOpenFeedback={setOpenFeedback}/>}
            </div>}
            {!isResponse&&<Row justify="center"><p style={{margin:"1rem",fontWeight:"600"}}>Interview might be Cancelled or submitted,kindly Contact R&D Team</p></Row>}
  </>)
 }
 export default Feedback;