import React, { useState,useEffect } from "react";
// import mask from "../../assets/mask.png";
import brigoshaIcon from "../../assets/brigosha_logo.svg";
import Api from "services/Api";
import login1 from "../../assets/login1.svg"
import login2 from "../../assets/login2.svg"
import login3 from "../../assets/login3.svg"
import { useDispatch,useSelector } from "react-redux";
import { authActions } from "./store";
import { Form, Input, Button, Typography, Card, Row, Col, Modal } from "antd";
import { keyframes } from "styled-components";
import { bounce } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { useHistory } from "react-router";
import "./styles.css"
import Designbackground from "./design-background";
const { Title, Text } = Typography;

const Login = () => {
  let valueWidth=window.innerWidth
  const [imageUrl,setImageUrl] = useState(login1)
  const [count,setCount] = useState(0)
  const [count1,setCount1] = useState(1)
  let values=["H","i","r","i","n","g"," ","M","a","n","a","g","e","m","e","n","t"]
  const [text,setText] = useState(values[0])
 
  let history = useHistory();
  const { user,token } = useSelector((state) => state.auth);

  // const {authUser} = useSelector(state => state.auth)
  const [loader, setLoader] = useState(false);
  const [btnloader, setBtnLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
   const dispatch = useDispatch()
   useEffect(() => {
    
    let myInterval = setInterval(() => {
      setCount(count+1)
      if(count%3===1){
            setImageUrl(login1)
          }
          else if(count%3===2){
            setImageUrl(login2)
          }
          else{
            setImageUrl(login3)
          }
     
    }, 3100)
    return () => {
      // history.push("/thank-you")
      clearInterval(myInterval);
     
    };
  });
  let i=1
  useEffect(() => {
    let value=text+values[count1]
    if(count1===values.length)
    {     
      return
    }
  
    let myInterval = setInterval(() => {
      setText(value)
      setCount1(count1+1)
    }, 300)
    return () => {
      // history.push("/thank-you")
      clearInterval(myInterval);
     
    };
  });
  
  // setInterval(() => {
  //  setCount(count+1)
 
  //   if(count%3===1){
  //     setImageUrl(login1)
  //   }
  //   else if(count%3===2){
  //     setImageUrl(login2)
  //   }
  //   else{
  //     setImageUrl(login3)
  //   }
  // }, 4000);

  const onFinish = (values) => {
    setLoader(true);
 

    Api.post("/hiring-auth/signIn")
      .params(values)
      .send((response) => {
        setLoader(false);
        if (typeof response != undefined && response?.show?.type != "error") {
          Api.header(response?.accessToken);
          dispatch(authActions.updateToken({ token: response?.accessToken }));
          dispatch(authActions.updateUser({ user: response?.user }));
          dispatch(authActions.candidateId(response?.candidateId))
          if(response?.candidateId){
            history.push("/candidate-info");
            //history.push("/login");
        }
        else if(response?.user?.role?.includes('Super-Admin') || response?.user?.role?.includes('Admin') )
        {  
          history.push("/Dashboard");
        }
        else if(response?.user?.role?.includes('Recruiter')||response?.user?.role?.includes('Sourcer') || response?.user?.role?.includes('Shortlister'))
        {
          history.push("/jd");
        }
        else if(response?.user?.role?.includes('HR')||response?.user?.role?.includes('Panelist'))
        {
          history.push("/dashboard-view");
        }
        else if(response?.user?.role?.includes('Agency'))
        {
          history.push("/exp-candidate-management");
        }
        else if(response?.user?.role?.includes("College-Admin"))
        {
          history.push("/institute-dashboard");
        }
        else
        {
          history.push("/");
        }
        }
      });
  };
  const onFinishFailed = (errorInfo) => {
    
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish1 = (values) => {
    setBtnLoader(true)
  
    Api.post("/hiring-auth/forgotpassword")
      .params(values)
      .send((response) => {
      
        setBtnLoader(false)
        setIsModalVisible(false)
      });
  };
  return (
    <div style={{backgroundColor:"white",width:"100%",height:"100%"}}>
      <Row style={{height:"100vh"}}>
        <Col span={valueWidth>728?12:20}>
            <Row
        style={{
          marginBottom: "14%",
        }}
      >
        <Col
          style={{
            top: "48px",
            left: "40px",
            width: "134px",
            height: "42px",
            opacity: "1",
          }}
        >
          <img src={brigoshaIcon} alt="brigosha" />
        </Col>
      </Row>
       <Card
            bordered={true}
            
            style={{
              width:valueWidth>728?"65%":"100%",
              marginTop:"20%",
              marginLeft:valueWidth>728?"15%":"10%",
              justifyContent: "center",
              borderRadius: "12px",
              boxShadow: "5px 12px 50px 5px rgba(208, 216, 243, 0.6)"
            }}
          >
            <Form
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                      <div style={{display:"flex",flexDirection:"row",gap:"2%",alignItems:"center",width:"18.15rem",margin:"2% auto"}}>
                                <h3 style={{fontWeight:"700",margin:"0",padding:"0",fontSize:"1.2rem",}}>Sign in to</h3>
                                <span style={{fontSize:"1.2rem",fontWeight:"700", color: "#0086FF"}}> {text} </span>
                        </div>
                        <h4 style={{textAlign:"center",fontSize:"14px",fontWeight:"600",color:"#636363"}}> Sign in using your registered email ID.</h4>
              </div>

              <Form.Item
                label="Email ID"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item><Row justify="end">
              <Button type="link" style={{ color: "#0086FF" }} onClick={() =>{
                history.push("/forgot")
              }}>
                Forgot Password?
              </Button></Row>
              <Modal
                title="Reset Password"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
              >
                <Form name="form2" onFinish={onFinish1}>
                  <Form.Item
                    label="Email ID"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item><Row justify="center">
                    <Button type="primary" htmlType="submit" size="large" style={{width:"150px"}} loading={btnloader}>Send</Button></Row>
                  </Form.Item>
                </Form>
              </Modal>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                {" "}
                <Row style={{marginTop:"20px"}}>
                  <Col span={24} >
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={loader}
                    >
                      SIGN IN
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card></Col>
          <Col span={12}>
      {valueWidth>728 ? <Designbackground value="login"
       />:""}
   </Col></Row></div>
  );
};

export default Login;
