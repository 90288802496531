import { Button, Input, Modal } from 'antd';
import "antd/dist/antd.css";
import { useDispatch, useSelector } from 'react-redux';
import { LocAndDeptActions } from '../store';
import classes from './addAndEditDept.module.css';
import deptIcon from '../../../assets/deptIcon.svg';
import { useEffect, useState } from 'react';
import { Api, Notification } from 'services';

const AddAndEditDept=(props)=>{
    const {addDeptModalOpen,search}=useSelector(state=>state?.LocAndDept);
    const {editDeptModalOpen}=useSelector(state=>state?.LocAndDept);
    const deptId=useSelector(state=>state?.LocAndDept?.deptId);
    const deptName=useSelector(state=>state?.LocAndDept?.deptName);
    const [dept,setDept]=useState("");
    const dispatch=useDispatch();
    const loader=props?.loader;
    const setLoader=props?.setLoader;
    const searchVal=props?.searchVal;

    useEffect(()=>{
            setDept(deptName);
    },[deptName])
    
    return(
        <Modal  open={addDeptModalOpen||editDeptModalOpen} maskClosable={true} centered closable={true} header={null} footer={null} onCancel={()=>{
            dispatch(LocAndDeptActions.updateAddDeptModal(false));
            dispatch(LocAndDeptActions.updateEditDeptModal(false))
        }}>
                        <div className={classes.container}>
                                        <div>
                                                    <img src={deptIcon}></img>
                                        </div>
                                        <h3>{editDeptModalOpen?"Edit Details":"Department Details"}</h3>
                                        <span>Kindly fill in the informations below </span>
                                        <div>
                                                    <span>DEPARTMENT <p >*</p></span>
                                                    <Input placeholder='Enter Department' onChange={(event)=>setDept(event.target.value)} value={dept}/>
                                        </div>                 
                                        <Button
                                        disabled={!dept}
                                        onClick={()=>{
                                            setLoader(true);
                                            if(dept.trim() === ''){
                                                Notification.error("Error","Add Department")
                                              }
                                            else{
                                            if(editDeptModalOpen)
                                            {
                                                Api.patch("/hiring-job/updateOrgDep").params({name:dept,id:deptId}).send(res=>{
                                                    if(res)
                                                    {                                                                                                         
                                                        Api.get("/hiring-job/orgDeps")
                                                        .params({search:searchVal,
                                                        page:searchVal?1:props.pageChange,
                                                        limit:props.pageSize})
                                                        .send(resp=>{
                                                            if(resp){
                                                                setLoader(false);
                                                              
                                                              dispatch(LocAndDeptActions.updateDeptDetails(resp?.items));
                                                            }
                                                          })   
                                                      
                                                    }
                                                })                                              
                                            }
                                            else
                                            {
                                                Api.post("/hiring-job/addOrgDep").params({name:dept}).send(res=>{
                                                    if(res)
                                                    {
                                                       
                                                        Api.get("/hiring-job/orgDeps")
                                                        .params({search:searchVal,
                                                            page:searchVal?1:props.pageChange,
                                                            limit:props.pageSize})
                                                        .send(resp=>{
                                                            if(resp){
                                                                setLoader(false);
                                                      
                                                              dispatch(LocAndDeptActions.updateDeptDetails(resp?.items));
                                                              setDept('');
                                                            }
                                                          })   
                                                    }
                                                })
                                            }
                                            dispatch(LocAndDeptActions.updateAddDeptModal(false));
                                            dispatch(LocAndDeptActions.updateEditDeptModal(false))  
                                          }                                        
                                        }}
                                        >{editDeptModalOpen?"UPDATE":"SAVE"}</Button>
                        </div>
        </Modal> 
    )     
}
export default AddAndEditDept;