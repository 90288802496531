import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   
    weekdays:[],
    
};

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        updateWeekdays(state, action) {
          state.weekdays = [...action.payload];
        },
    },
});

export default adminSlice.reducer;

export const adminActions = adminSlice.actions;

