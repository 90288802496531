import classes from './changeStatusPopover.module.css';
import {Button,Popover} from 'antd'
import { useState } from 'react';
import selectIcon from '../../assets/selectIcon.svg';
import ItemValue from './itemValue';


const ChangeStatusPopover=(props)=>{
    const [open, setOpen] = useState(false);
    const status=props?.status[0];
    const content=
    <div>
                    {status?.map((item,index)=><ItemValue key={index} item={item} statusChange={props?.statusChange} colorStatus={props?.colorStatus} setOpen={setOpen}/>)}
    </div>
    return(
            <Popover
                content={content}
                placement="bottom"
                trigger="click"
                open={open}
                onOpenChange={()=>setOpen(prev=>!prev)}
                >
                <span type="text">Change Status <img src={selectIcon}></img></span>
                </Popover>     
    )
}
export default ChangeStatusPopover;