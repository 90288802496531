import React from "react"
import { 
    Row,
    Col,  
    Button, 
    Modal, 
    Divider } from "antd";
    import {CloseOutlined} from "@ant-design/icons";

const FilterModel =({setFilter1,filter1})=>{

    const showfilter = () => {
       
        setFilter1(true);
    }

    const filterOk = () => {
        setFilter1(false);
    };

    const filterCancel = () => {
        setFilter1(false);
    };

return (
        <div>
            <Row>
                <Col span={10}>
                    <Modal
                        width="25%"
                        visible={filter1}
                        onOk={filterOk}
                        onCancel={filterCancel}
                        footer={null}
                        header={null}
                    >
                    <h3>All Filter(s)</h3>
                       <Divider/>

                       
                       <div>
                        <Row>
                        <h3>Job Vacancy</h3>
                       <CloseOutlined style={{marginTop:'2%',fontSize:'medium',marginLeft:'60%',color:'#FF9797'}} />
                       </Row>
                       </div>
                       <h5 style={{color:'#8F8F8F'}}>All</h5>
                       <br/>

                       <div>
                        <Row>
                        <h3>Job Type</h3>
                       <CloseOutlined style={{marginTop:'2%',fontSize:'medium',marginLeft:'70%',color:'#FF9797'}} />
                       </Row>
                       </div>
                       <h5 style={{color:'#8F8F8F'}}>All</h5>
                       <br/>

                       <div>
                        <Row>
                        <h3>Panelist Pool</h3>
                       <CloseOutlined style={{marginTop:'2%',fontSize:'medium',marginLeft:'60%',color:'#FF9797'}} />
                       </Row>
                       </div>
                       <h5 style={{color:'#8F8F8F'}}>All</h5>
                       <br/>
         
                            <div style={{ marginTop: '50px', display: "flex", alignItems: "center", justifyContent: "spacearound", width: "100%", justifyContent: 'space-between' }}>
                                <Button
                                 style={{ marginRight: '10px',borderRadius:'5px' }}
                                 onClick={filterCancel}>
                                 CANCEL
                                </Button>

                                <Button
                               style={{marginLeft: '30%',borderRadius:'5px'}}
                                type="danger" >
                                CLEAR ALL
                                </Button>
                            </div>
                    </Modal>
                </Col>
            </Row>

        </div>
       )
    }

export default FilterModel;