import { createSlice } from "@reduxjs/toolkit";

const initialState={
    addDeptModalOpen:false,
    editDeptModalOpen:false,
    addLocModalOpen:false,
    editLocModalOpen:false,
    editLocnDetails:{},
    deptDetails:[],
    deptId:null,
    deptName:"",
    search:""

}
const LocAndDeptSlice=createSlice({
    name:"LocAndDept",
    initialState,
    reducers:{
        udateEditLocnDetails(state,action){
            state.editLocnDetails=action.payload;
        },
        updateAddDeptModal(state,action){
            state.addDeptModalOpen=action.payload;
        },
        updateAddLocModal(state,action){
            state.addLocModalOpen=action.payload;
        },
        updateEditDeptModal(state,action){
            state.editDeptModalOpen=action.payload;
        },
        updateEditLocModal(state,action){
            state.editLocModalOpen=action.payload;
        },
        updateDeptDetails(state,action){
            state.deptDetails=action.payload;
        },
        updateDeptId(state,action){
            state.deptId=action.payload;
        },
        updateDeptName(state,action){
            state.deptName=action.payload;
        },
        updateSearch(state,action){
          state.search=action.payload
        }

    }

})

export default LocAndDeptSlice.reducer;
export const LocAndDeptActions=LocAndDeptSlice.actions;