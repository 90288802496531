import { Button, Modal,Input } from 'antd'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaCross } from 'react-icons/fa';
import { RxCross1 } from 'react-icons/rx';
import { Api } from 'services';
import classes from './regLinkModal.module.css'
import store from 'store';
import { adminActions } from '../store';
import {Notification} from 'services';
const RegLinkModal=(props)=>{
    
    const {regLinkModalOpen}=useSelector(state =>state.candidateFresher)
  
   
    const {candType}=store.getState().candidateFresher
    const [selectedMails,setSelectedMails]=useState([]);
    const [mail,setMail]=useState('');
    const [open,setOpen]=useState(false)
    useEffect(() =>{
       
     if(regLinkModalOpen===true){
        setOpen(true)
     }
     else{
        setOpen(false)
     }
    },[regLinkModalOpen])
  
    return(
        <Modal header={null} footer={null} closable={true} maskClosable={true} open={open} onCancel={()=>store.dispatch(adminActions.updateRegLinkModalOpen(false))} centered>
                    <div className={classes.container}>
                                    <div>                                 
                                                <Input placeholder="Type multiple  Email(s) with space between them" name="email" type="email" onChange={(event)=>setMail(event.target.value)} value={mail}/>
                                                <Button onClick={()=>{
                                                     const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                                                     const values = mail.split(" ");
                                                     const invalidEmails = values.filter(email => !email.match(emailPattern));
                                                   
                                                     if (invalidEmails.length > 0) {
                                                       Notification.error("Add valid email", "");
                                                       return;
                                                     }
                                                   
                                                     const updatedSelectedMails = selectedMails ? [...selectedMails, ...values] : values;
                                                     setSelectedMails(updatedSelectedMails);
                                                     setMail('');
                                                }} >+ADD Email</Button>
                                                <div>
                                                            {selectedMails?.map((item,index)=>{
                                                                return(
                                                                    <span>{item} <Button onClick={()=>{
                                                                        setSelectedMails(selectedMails?.filter((item2)=>item!==item2))
                                                                    }}><RxCross1></RxCross1> </Button></span>
                                                                )
                                                            })
                                                            }
                                                </div>
                                    </div>
                                    <div>
                                            <Button onClick={()=>{
                                                Api.post("/hiring-candidate/sendEmail").params(
                                                    {
                                                        candidateType:candType,
                                                        email:selectedMails
                                                    }
                                                ).send(res=>{
                                                    if(res){
                                                        setSelectedMails([])
                                                        store.dispatch(adminActions.updateRegLinkModalOpen(false))
                                                    }
                                                })
                                            }}
                                            disabled={selectedMails.length===0}
                                            >Send Email</Button>
                                     </div>
                    </div>
        </Modal>
    )
}
export default RegLinkModal;