import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Button,
  Input,
  Space,
  DatePicker,
  TimePicker,
  // Select,
  Row,
  Col,
  Typography,
  Switch,
} from "antd";
// import { DeleteOutlined,CopyOutlined,PlusCircleOutlined, CheckOutlined, CloseOutlined,EditOutlined } from "@ant-design/icons";
import moment from 'moment';
import Api from "services/Api";
import { adminActions, testActions } from "./store";
import { useDispatch, useSelector } from "react-redux";
import Notification from "services/Notification";
import CreateSectionDetails from "./create-sectionDetails";
const { Title } = Typography;

const { TextArea } = Input;
// const { Option } = Select;

const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Please select time!",
    },
  ],
};

const CreateTest = (props) => {
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false)

  const [page,setPage]=useState(1)

  const[sectionArray,setSectionArray] = useState([])
  const [sectionDetail,setDetails] = useState([])
  const [formValue,setFormValue]=useState("")


const {resetFeilds} =useSelector(state =>state.test)
  const[testTitle,setTitle]=useState("") 
  const [testDuration,setTestDuration] = useState(0)
  const[totMarks,setTotmarks] = useState(0)
  const[totcuttoffMarks,setTotcuttoffmarks] = useState(0)

  const dispatch=useDispatch()
  useEffect(() =>{
    if(resetFeilds===true){
      form.resetFields()
    }
   dispatch(testActions.updatereset(false))
  },[resetFeilds])

   useEffect(() =>{
    if(props.update){
     setIsModalVisible(props.update)
    // const selectedSecArray=props.selectedObj.sections
    // selectedSecArray.sort(function(a, b){return a.sectionRound - b.sectionRound})
    setDetails(props.selectedObj.sections)
    props.selectedObj.sections.forEach((element,index) => {
      setSectionArray(oldArray =>[...oldArray,sectionDetails])
    });
  }
  
   },[])
 
 
 
  const onFinish = (values) => {
    // setBtnLoader(true)
    // Should format date value before submit.
    let duration=parseInt(values.duration)
    let totalMarks=parseInt(values.totalMarks)
    let testCutt_off=parseInt(values.testCutt_off)
    let value={
      ...values,
      duration:duration,
      totalMarks:totalMarks,
      testCutt_off:testCutt_off
    }
    setFormValue(value)
 

    setTimeout(() =>{
      setPage(() => page+1)
    },400)
    

  };
  
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if(props.update){
      props.setUpdate(false)}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    if(props.update){
    props.setUpdate(false)}
    // props.setUpdate(false)
  };

  // function handleChange(value) {
    
  //   form.setFieldsValue(value);
  // }
  
  function disabledDate(current) {

    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }
  return (
    <>
      <Button type="primary"
        onClick={showModal}
        size="midle"
      style={{
        width:"10rem",
        fontWeight:"700",
      }}
      >
        Create Test
      </Button>
      <Modal
        style={{
          overflow: "hidden",
          borderRadius: "10px",
          textAlign: "center",
        }}
        width="700px"
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <div style={{margin:"2rem 1rem",}}>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",marginBottom:"0.5rem"}}>
                                    <Title level={3} style={{fontWeight:"bold", color:"#161616",marginLeft:"5px", fontSize:"1.3rem",textAlign:"left" }}>
                                              Create Test       
                                  </Title>           
                                    <div >
                                    {/* {props.update?<Button loading={cloneLoading} style={{borderColor:'#0086FF',color:'#0086FF',textAlign:"right"}} onClick={() =>{
                                      setCloneLoading(true)
                                      let params={
                                    testId: props.selectedObj.id,
                                    title:testTitle!=="" ? testTitle :props.selectedObj.title,
                                    testCutt_off:totcuttoffMarks!==0  ? parseInt(totcuttoffMarks) :props.selectedObj.testCutt_off,
                                    totalMarks:totMarks!==0 ?  parseInt(totMarks) :props?.selectedObj?.totalMarks,
                                    duration:testDuration !==0 ? parseInt(testDuration) : props.selectedObj.duration,
                                    instructions:ins!=="" ? ins : props.selectedObj.instructions
                                      }
                                      Api.post("/hiring-tests/cloneTest")
                                      .params(params)
                                      .send(response =>{
                                        
                                        setCloneLoading(false)
                                        props.refresh()
                                        props.setUpdate(false)
                                      
                                      })
                                    }}><CopyOutlined />  CLONE </Button>:""} */}
                                  </div>
                    </div>
                           
                      {page===1 ? 
                      <Form form={form} layout={"vertical"} onFinish={onFinish} initialValues={props.selectedObj} >

                        <Form.Item
                        style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",}}
                          name="title"
                          label="TEST TITLE"
                          rules={[
                            {
                              required: true,
                              message: "Please input your title!",
                            },
                          ]}
                        >
                          <Input
                          onChange={(e)=>{
                            let title=e.target.value
                            setTitle(title)
                          }}
                            style={{
                              width: "100%",
                            }}
                            size={"midle"}
                          />
                        </Form.Item >
                        
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              name="duration"
                              label="TEST DURATION (Minutes)"
                              rules={[
                                {
                                  
                                  required: true,
                                  message: "Please give duration!",
                                },
                              ]}
                              style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
                            >
                              <Input min={0} onChange={(e) =>{
                                let dur=e.target.value
                                setTestDuration(dur)
                              }}  type={"number"} size={"midle"}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12} offset={0}>
                            <Form.Item name="totalMarks" label="TOTAL MARKS"  rules={[
                            {
                              required: true,
                            },
                          ]}
                          style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
                          >
                              <Input min={0}  onChange={(e) =>{
                                let dur=e.target.value
                                setTotmarks(dur)
                              }}  type={"number"} size={"midle"} />
                            </Form.Item>
                          </Col>
                          <Col span={11} offset={1}>
                            <Form.Item 
                            name="testCutt_off" label="OVERALL CUT-OFF" 
                            rules={[
                              { 
                                required: true,
                                message: "Please enter valid input!",
                              },
                            ]}
                            style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}} >
                            <Input min={0} onChange={(e) =>{
                                let dur=e.target.value
                                setTotcuttoffmarks(dur)
                              }} type={"number"}
                              size="midle"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
                          name="instructions"
                          label="INSTRUCTIONS"
                          placeholder=""
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <TextArea onChange={(value) =>{
                           // setIns(value)
                          }} rows={5} />
                        </Form.Item>
                        <Form.Item
                          style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
                          wrapperCol={{
                            span: 24,
                            offset: 0,
                          }}
                        >
                          <Row justify="center" style={{display:"flex",flexDirection:"row",gap:"1rem"}} >
                            
                              <Button
                                size="midle"
                                onClick={handleCancel}
                                style={{width:"8rem",height:"2.3rem",borderRadius:"3px", borderColor: "#1e90ff", color: "#1e90ff",fontWeight:"bold" }}
                              >
                                CANCEL
                              </Button>                      
                              <Button type="primary" htmlType="submit" size="midle" loading={btnLoader} style={{width:"8rem",height:"2.3rem",borderRadius:"3px", border: "0", color: "#FFFFFF",fontWeight:"bold" }}>
                              NEXT
                              </Button>
                            
                          </Row>
                        </Form.Item>
                      </Form>:<>
                      <Row justify="center" style={{marginBottom:"0.5rem", fontWeight:"400"}}><b>Total marks:{" "+totMarks} & Cut off Marks:{" "+totcuttoffMarks}</b></Row>
                      <Row><Col span={24} style={{fontFamily:"Open Sans", fontWeight:"600",fontSize:"1.2rem",}}>Section Details</Col></Row>
                  
                      <CreateSectionDetails formValue={formValue} setIsModalVisible={setIsModalVisible} refresh={props.refresh.bind(this)} setPage={setPage} setFormValue={setFormValue} form={form}/>
                    
                        </>
                        }
          </div>
      </Modal>
    </>
  );
};
export default CreateTest;
