import React,{useRef} from "react";
import classes from './schedule.module.css';
import {
    Table,
    Row,
    Col,
    Button,
    Tabs,
    Space,
    Modal,
    Typography,
    Radio,
    Input,
    Tooltip, 
    Popover,
    Dropdown,
    Menu,
    DatePicker,
    Card,
    Carousel,
    Pagination,
    Spin
  } from "antd";
import Api from "services/Api";
import { ApiOutlined,WarningOutlined, InfoCircleOutlined,CalendarOutlined,SearchOutlined,FileDoneOutlined,ArrowLeftOutlined,ArrowRightOutlined } from '@ant-design/icons';
import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  interviewActions } from "./store";
import moment from "moment";
import { useWindowDimensions } from "components/layout";
import { useLocation,useHistory } from "react-router-dom";
import ResumeIcon from "../../assets/ResumeIconss.svg";
import dayjs from "dayjs";
import PdfViewModal from "components/pdfViewModal";

const { TabPane } = Tabs;
const {Title,Paragraph} =Typography
const { RangePicker } = DatePicker;



const InterviewSchedules = (props) => {
  const location = useLocation();
  const history=useHistory()

  let data=location.search.split('=')
   
  useEffect(() =>{
    
    if(width<420){
 
      window.scrollTo(0,0);
    }
  },[])
  const { height, width } = useWindowDimensions(); 
  let count=0
  const slider = useRef(null);
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const [currentCarousel,setCurrentCarousel]=useState(1)
  const [totLength,setTotLength]=useState("")
  // const Schedules = useSelector(state => state?.scheduleInterview?.scheduleList)
  const [schedule, setSchedule] = useState([])
  const [searchVal, setSearchVal] = useState("");
  const [pageMeta, setPageMeta] = useState();
  const [viewModals,setViewModals]=useState(false);
  const[details,SetDetails] = useState("");
  const [reasonModal, setReasonModal] = useState(false);
 
  const [discussionModal, setDiscussionModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [value, setValue] = useState("");
  const [status, setStatus] = useState("");
  const [otherValue, setOtherValue] = useState("")
  const [finalValue, setFinalValue] = useState("")
  const [otherReason, setOtherReason] = useState("")
  const [ellipsis, setEllipsis] = useState(true);
  const [pageChange, setPageChange] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tabStatus, setTabStatus] = useState("Ongoing")
  const [dateDropdown, setDateDropdown] = useState(false);
  const [dateVal, setDateVal] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfResume, setPdfResume] = useState(null);

  // const {finalValue} = useSelector(state=>state)
  useEffect(() =>{
    if(data[0]!==""){
      setSearchVal((data[1]).replace(/%20/g,' '));
    }
    else{
      setSearchVal("")
    }
  },[location])
 
  useEffect(() =>{
    if(location.state){
      setSearchVal(location.state)
    }

   
  },[])
  const [todaysInterValue,setTodaysInterValue]=useState("");
  const [popVisible,setPopVisible]=useState(false);
  const getPost=()=>{
        setBtnLoader(true)
        Api.patch("/hiring-panelist/changeCandidateStatus")
           .params({
            candidateId: details?.candidateId,
            status: status,
            slotId:details?.slotId,
            reason:value==='Others'?otherValue:value,
            otherReason:otherReason,
           })
           .send((response)=>{
            if(response !== undefined){
                getSheduleTable();
                setBtnLoader(false);
                setReasonModal(false);
              }})
    }

const cancelHandler =() =>{
    setViewModals(false)
  }

const reasonCancel=()=>{
  setReasonModal(false)
 }    

 const canHandler=()=>{
  setDiscussionModal(false)
}

const getSheduleTable=()=>{
  let value;
  if(dateVal === []){
    value={search:searchVal,}
  }
  else{
     value={search:searchVal,
      startDate:dateVal[0],
      endDate:dateVal[1],
    }
  }
  setLoader(true)
  let par={
    search:searchVal,
    startDate:dateVal[0],
    endDate:dateVal[1],
    page:searchVal==="" && todaysInterValue==="" ?pageChange:"",
    limit:searchVal===""&& todaysInterValue===""?pageSize:"",
    todayInterviews:todaysInterValue,
    status:tabStatus
  }
 
    Api.get('/hiring-panelist/interviewSchedule')
       .params(par)
       .send((response)=>{
        setLoader(false)
        if(typeof response != "undefined"){
        if(response?.items.length===0){
          setPageChange(1)
        }
        //dispatch(interviewActions.updateSchedule(response?.items))
        setSchedule(response?.items)
        setPageMeta(response?.meta)
        let data=[]
        response.items.map((element) =>{
          if(element.interviewStatus==="Scheduled"){
             data.push(element)
          }
        })
 
        setTotLength(data.length)
      }
    })
}

  // useEffect(()=>{

  //   if(tabStatus === "Ongoing"){
  //     let statusFilteredData = schedule.filter(item => item.interviewStatus !== "Completed" && item?.interviewStatus !== "Feedback-Pending")
  //     setFilteredData(statusFilteredData)
  //   }
  //   else{
  //     let statusFilteredData = schedule.filter(item => item.interviewStatus === "Completed" || item?.interviewStatus === "Feedback-Pending")
  //     setFilteredData(statusFilteredData)
  //   }

  // },[tabStatus,])


  useEffect(()=>{

    getSheduleTable();

  },[searchVal,dateVal[1] !== undefined && dateVal,pageChange,pageSize,todaysInterValue,tabStatus])

  const rangePickerMenu = () => (
    <div>
    <span>
    <Menu style={{ left: "-90%" }}>
      <Menu.Item key="1">
        <RangePicker
          format={"YYYY-MM-DD"}
          onChange={(date, dateString) => {
            
            setDateVal(dateString);
            setDateDropdown(!dateDropdown);
            
          }}
          // value={[
          //         moment(dateVal[0], "YYYY-MM-DD"),
          //         moment(dateVal[1], "YYYY-MM-DD"),
          //       ]
          // }
        />
      </Menu.Item>
    </Menu>
    </span>
    </div>
  );

  const submitArray=["Re-Discussion","Evaluate Previous round feedback","Current Round Feedback"]

  const columns = [
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center"}}>
                      <span>CANDIDATE NAME</span>
              </div>,
      dataIndex: "name",
      key: "name",
      // width:"14%",
      render: (text,record) => {
       
        return(
          <div style={{textAlign:"center"}}>
            {record?.candidateName ? record?.candidateName:"NA"}
            <a style={{paddingLeft:"5px"}} href={record?.resumeLink} target="_blank"><FileDoneOutlined/></a>
          </div>
        )
      },
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"5rem",textAlign:"center"}}>
            <span>JOB TITLE</span>
              </div>,
      dataIndex: "interview",
      key: "interview",
      width:"11%",
      render: (text, record) => (
        <div style={{textAlign:"center"}}>
            {record?.jobTitle ? record?.jobTitle:"NA"}
        </div>
      ),
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center"}}>
                      <span>ROUND</span>
              </div>,
      dataIndex: "round",
      key: "round",
      // width:"10%",
      render: (text,record) => {
      //  let round = rediscuss === "Re-Discussion Round" ? rediscuss : record?.roundType
        return(
          <div style={{textAlign:"center"}}>
            {record?.roundType && record?.roundType === "Relationship" ? "HR Relationship" 
            : record?.roundType ? record?.roundType :"NA"}
            {/* {record?.roundType} */}
          </div>
        )
      },
    },
    {
      title:  <div className={classes.headerName} style={{minWidth:"8rem",textAlign:"center"}}>
                  <span><Space size="small">
                    DATE & TIME 

                    <Dropdown
                      trigger={['click']}
                      visible={dateDropdown}
                      overlay={rangePickerMenu()}
                      onClick={() =>
                        setDateDropdown(!dateDropdown)
                      }           
                      id="date-dropdown"
                    >
                        <CalendarOutlined style={{color:"#2797FF"}} id="dateRange-calender" />
                    </Dropdown>
                  </Space></span>
              </div>,
      dataIndex: "datetime",
      key: "datetime",
      width:"12%",
      render:(text,record)=>{
        let dates=moment(record?.interviewDate).format('YYYY-MMM-DD')
        // let startTimes=moment(record?.startTime,'hh:mm').format('hh:mm a')
        // let endTimes=moment(record?.endTime,'hh:mm').format('hh:mm a')
        return(<>         
           <div style={{textAlign:"center"}}>{record?.interviewDate? moment(record?.interviewDate).format('YYYY-MMM-DD'):"NA"}</div> 
           <div style={{textAlign:"center"}}>{record?.startTime? record?.startTime:"NA"} - {record?.endTime?record?.endTime:"NA"}</div> 
            </>)}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center"}}>
                        <span>MEET LINK</span>
                </div>,
      dataIndex: "meetLink",
      key: "meetLink",
      width:"13%",
      render:(text,record)=>{
        let links =record?.meetingLink
        return(
          <div >
          { links === null ? <div style={{textAlign:"center"}}>NA</div> 
          : 
          <div style={{textAlign:"center"}}> 
            <Typography.Text ellipsis={{tooltip:true}} style={{width:"14rem"}}>
              
             <a href={record?.meetingLink} target="_blank"> 
                {record?.meetingLink ? record?.meetingLink :"NA"}
             </a>
            </Typography.Text>
          </div> }
          </div>
          )}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center"}}>
                      <span>STATUS 
                      <Tooltip placement="right" color={"white"} title={<div style={{color:"#0086FF"}}>Interview status of the Various Candidates</div>}>
                      <InfoCircleOutlined style={{marginLeft:"0.8rem",color:"#D3D3D3"}} />
                      </Tooltip>
                      </span>
              </div>,
      dataIndex: "status",
      key: "status",
      width:"11%",
      render: (text,record) => {
        return(
          <div style={{textAlign:"center"}}>
            {record?.interviewStatus ? record?.interviewStatus :"NA"}
          </div>
        )
      },
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"10rem",textAlign:"center"}}>
                      <span>REASONS</span>
              </div>,
      dataIndex: "status",
      key: "status",
      width:"15%",
      render: (text,record) => {
        return(
          <div style={{textAlign:"center"}}>
            <Paragraph style={{width: 200,textAlign:"center"}}  ellipsis={
          ellipsis
            ? {
                rows: 1,
                expandable: true,
                symbol: 'more',
              }
            : false
        }>
            {record?.reason ? record?.reason : "NA"}
            </Paragraph>
          </div>
        )
      },
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"9rem",textAlign:"center"}}>
                      <span>ACTION</span>
                </div>,
      dataIndex: "action",
      key: "action",
      width:"12%",
      render:(text,record)=>{
        return(
          <div style={{textAlign:"center"}}>
          <Button 
          disabled={record?.interviewStatus ==="Completed" || record?.interviewStatus ==="Feedback-Pending"} 
          onClick={() => {
              SetDetails(record)
              setViewModals(true)
          }} 
          style={{ backgroundColor: record?.interviewStatus==="Completed"||record?.interviewStatus ==="Feedback-Pending"?"#6F6F6F":'#0086FF',
           color:"white",fontFamily:"Open Sans",fontWeight:"bold",height:"2rem",borderRadius:"5px",textAlign:"center",fontSize:"0.9rem"}} 
          size="small">
            Interview Status
          </Button>
          </div>)}
    },
    
  ];



  const handlePageChange = (page, pageSize) => {
   
    setPageChange(page);
    setPageSize(pageSize);
  };

    return (
      <div style={{height: "90vh",overflowY:"auto",}}>
      
       {width>450? 
       <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",paddingRight:"10rem",margin:"0.6rem 0 0 1.6rem",}}>
       <h1 style={{ fontWeight:"bold",color:"#161616",fontSize:"1.4rem",padding:0,margin:0,}}>
        Interview Schedules
        </h1>
       
        </div>
        :
        <h1 style={{ marginLeft:"16px",marginTop:"10px" ,fontWeight:"bold",color:width>450&&"#0086FF",fontSize:"1.2rem"}}>
        Interview Schedules
        </h1>}
       
        <div
          style={{
            padding: width>450?"0.8% 2.5% 2.5% 1.8%" :"",
            width: "100%",
            boxSizing:"border-box",
            // border:"2px solid red"
          }}   
        >{width>450?
           <Row> 
          <div style={{ display: 'flex', gap:"1rem",justifyContent:"space-between",width:"100%",}}>
              {/* <div>
                  <h4 style={{color:"#B7B7B7",marginLeft:"0.2rem"}}>Displaying Last 7 Days Data..</h4>
              </div>
          */}
          <div>
            {/* <Tabs defaultActiveKey="1" items={[
              {
                key: '1',
                label: 'Ongoing Interview',
              },
              {
                key: '2',
                label: 'Completed Interview',
                // children: 'Content of Tab Pane 2',
              },
            ]} onChange={()=>{}} /> */}
          </div>
          
             </div>
          </Row>  :""}
         {width > 700?
          // <Tabs
          //   style={{fontWeight:"bold",fontFamily:"Open Sans", fontSize:"16px"}}  
          //  >
          //   <TabPane>
          <div>
            <Tabs style={{margin:"0 1rem"}} onChange={(key) =>{ setPageChange(1);setTabStatus(key) }} defaultActiveKey="Ongoing"
           activeKey={tabStatus}
           tabBarExtraContent={<div style={{display:"flex",flexDirection:"row",gap:"1rem"}}>
           <div className="head-icon">    
                 <Input
                 value={searchVal}
                    type="text"
                    placeholder='Search'
                    style={{color:"#161616",width:"18rem",fontWeight:"600",borderRadius:"5px"}}
                    onChange={(event)=>{
                    
                     setSearchVal(event.target.value)
                    }}
                    prefix={<SearchOutlined className="custom-search-icon" style={{ Color:"#0086FF", }} />}
                    >
                   </Input>
             </div>
             <Popover content={
             <div>
               <div>
                     <Radio.Group  value={todaysInterValue} bordered={false}
                                                 onChange={(e)=>{                   
                                                     setTodaysInterValue(e.target.value);
                                                     setPopVisible(false);
                                                 }}>
                                                 <Space direction="vertical">
                                                   <Radio value="ASC" 
                                                     >Ascending</Radio>
                                                   <Radio value="DESC"
                                                   >Descending</Radio>
                                                   {/* <Radio value=""
                                                   >Reset</Radio> */}
                                                 </Space>
                       </Radio.Group>     
               </div>
             </div>} 
             title={<div style={{display:"flex",flexDirection:"row",marginBottom:"0.1rem",marginTop:"0.3rem",fontSize:"0.91rem",gap:"2rem"}}><span>Select Time Order</span>
             <span style={{cursor:"pointer"}} onClick={()=>{setTodaysInterValue("");
             setPopVisible(false);
             }}>Reset</span>
             </div>}
             placement="bottom"
             trigger="click"
             visible={popVisible}
             onVisibleChange={() => setPopVisible(!popVisible)}
             >
               <Button type="primary" style={{borderRadius:"5px",height:"2.2rem",width:"10rem",fontWeight:"600"}} onClick={()=>setPopVisible(!popVisible)}>Today's Interview</Button>
             </Popover>
             </div>}
           >
            <TabPane tab={"Ongoing"}  key="Ongoing">
              
            <Table columns={columns} loading={loader} dataSource={schedule} 
                     rowClassName={classes.rowStyle} className={classes.table}
                     pagination={{ 
                      position: ["bottomRight"],
                      showSizeChanger: true,
                      pageSize:pageSize,
                      total:pageMeta?.totalItems,
                      pageSizeOptions:[10, 30, 50, 100], 
                      size:"small",
                      showTotal: (total, count) =>
                      `${count[0]}-${count[1]} of ${total} items`,
                      onChange: handlePageChange,
                      onShowSizeChange: handlePageChange,                      
                    }}
                     scroll={{
                      y:800,
                      x:1700}}
                />
            </TabPane>
            <TabPane tab={"Completed"} key="Completed">
            <Table columns={columns} loading={loader} dataSource={schedule} 
                     rowClassName={classes.rowStyle} className={classes.table}
                     pagination={{ 
                      position: ["bottomRight"],
                      showSizeChanger: true,
                      pageSize:pageSize,
                      total:pageMeta?.totalItems,
                      pageSizeOptions:[10, 30, 50, 100], 
                      size:"small",
                      showTotal: (total, count) =>
                      `${count[0]}-${count[1]} of ${total} items`,
                      onChange: handlePageChange,
                      onShowSizeChange: handlePageChange,                      
                    }}
                     scroll={{
                      y:800,
                      x:1700}}
                />
            </TabPane></Tabs>
            </div>
          :<>
          <Tabs style={{margin:"0 1rem"}} onChange={(key) =>{ setPageChange(1);setTabStatus(key) }} defaultActiveKey="Ongoing"
           activeKey={tabStatus}>
            <TabPane tab={"Ongoing"}  key="Ongoing">
            <Input type="text" placeholder='Search' style={{color:"#161616",width:"100%",fontSize:"16px",fontWeight:"600",borderRadius:5}}
              onChange={(event)=>{
                setSearchVal(event.target.value)
              }}
              prefix={<SearchOutlined className="custom-search-icon" style={{ Color:"#0086FF", }} />}
            ></Input>
            <div style={{width:"100%",borderRadius:"1px",border:"1.5px solid #D9D9D9",marginTop:"0.5rem",display: "flex",height:"2.3rem",backgroundColor:"white" }}>
            <DatePicker format={"YYYY-MM-DD"} style={{width:"100%",height:"2.1rem"}} bordered={false}
              allowClear placeholder="Start Date"
              onChange={(date, dateString) => {
                if(!dateString){
                  setDateVal([]); 
                }else{
                setDateVal([dateString, dateVal[1]]);  
                }            
              }}
              value={dateVal[0] !== "" && dateVal[0] !== undefined && dateVal[0] !== null ? moment(dateVal[0], "YYYY-MM-DD") : null}
            />
            <span style={{ fontSize: "1rem", alignItems: "center" }}>-</span>
              <DatePicker format={"YYYY-MM-DD"} style={{width:"100%",height:"2.1rem"}} bordered={false}
              allowClear  disabled={!dateVal[0] && !dateVal[1]} placeholder="End Date"
                onChange={(date, dateString) => {
                  setDateVal([dateVal[0], dateString]);              
                }}
              value={dateVal[1] !== "" && dateVal[1] !== undefined && dateVal[1] !== null ? moment(dateVal[1], "YYYY-MM-DD") : null}
              />
          </div>
             
          {schedule.filter(item => item.interviewStatus !=="Completed" && item?.interviewStatus !=="Feedback-Pending").length > 0 ?<>
            <div className={classes.ListBodyContainer} style={{height:"67vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",marginTop:"0.6rem"}}>
              {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
                schedule?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
                schedule?.map((record,id,index)=>{
                  if(record?.interviewStatus !=="Completed" && record?.interviewStatus !=="Feedback-Pending"){
                    // count=++count
                    return(
                      <div style={{width:"100%",marginTop:"0.1rem",marginBottom:"0.6rem",borderRadius:5,backgroundColor:"#FFFFFF",padding:"0.6rem 0.9rem"}} key={id}>
                      <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"0.5rem",paddingTop:"0rem",borderBottom:"1px solid #DFDFDF"}}>
                        <div style={{display: "flex",justifyContent: "flex-start",flexDirection:"column"}}>
                            <Popover content={record?.candidateName}>
                              <Space size="small">
                              <Typography.Text ellipsis={{tooltip:false}} style={{width:"15rem",fontSize:"1.1rem",fontWeight:700,}}>
                                {record.candidateName}</Typography.Text>
                              </Space>
                            </Popover>
                            <Popover content={record?.jobTitle}>
                              <Space size="small">
                              <Typography.Text ellipsis={{tooltip:false}} style={{width:"15rem",fontSize:"0.9rem",}}>
                                {record?.jobTitle}</Typography.Text>
                              </Space>
                            </Popover>
                        </div>
                        <div style={{display:"flex",gap:"0.5rem",alignItems:"center",}}>
                          <span style={{ fontSize: 10 }}>
                            {/* <a onClick={() => {
                                    // this.props.updateInputField(
                                    //   inputFieldType.CANDIDATE_RESUME,
                                    //   record.resume
                                    // );
                                    // this.props.performOperation(
                                    //   operationType.CANDIDATE_PDF_VIEW_MODAL,
                                    //   true
                                    // );  resumeLink
                                  }}
                            >
                            <img src={ResumeIcon} />
                            </a> */}
                            {width>700 ?
                              <a style={{paddingLeft:"5px"}} href={record?.resumeLink} target="_blank"><img src={ResumeIcon} /></a>
                            :
                              <a style={{paddingLeft:"5px"}} onClick={()=>{setPdfModal(true);setPdfResume(record?.resumeLink)}}><img src={ResumeIcon} /></a>
                            }
                          </span>
                        </div>
                      </div>

                      <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Round</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.roundType}</p>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Status</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.interviewStatus}</p>
                      </div>
                      </div>

                      <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Date</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.interviewDate? moment(record?.interviewDate).format('YYYY-MMM-DD'):"NA"}</p>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Time</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.startTime? record?.startTime:"NA"} - {record?.endTime?record?.endTime:"NA"}</p>
                      </div>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"100%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Meet link</span>
                        <p style={{fontSize:15,color:"#161616"}}>{ record?.meetingLink === null ? <div>NA</div> 
                        : <Typography.Text ellipsis={{tooltip:true}} style={{width:"14rem"}}>
                          <a href={record?.meetingLink} target="_blank"> 
                              {record?.meetingLink ? record?.meetingLink :"NA"}
                          </a>
                          </Typography.Text>
                        }</p>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"100%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Reasons</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.reason ? record?.reason : "NA"}</p>
                      </div>
                      <div style={{width:"100%",marginTop:"1rem",borderBottom:"0.6rem"}}>
                        <Button type="primary" style={{height:"2.1rem",width:"100%",borderRadius:5,}} 
                          disabled={record?.interviewStatus ==="Completed" || record?.interviewStatus ==="Feedback-Pending"} 
                          onClick={() => {
                              SetDetails(record)
                              setViewModals(true)
                          }} 
                        >
                         Interview status
                        </Button>
                      </div>
                      </div>
                    )
                  }
                  else{
                    return null;
                  }
                })}
            </div> 
            <div style={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <Pagination
              style={{marginTop:"20px",}} size="small" position="bottomRight"
              showSizeChanger={true}
              pageSize={pageSize}
              total={schedule?.filter(item => item?.interviewStatus !=="Completed" && item?.interviewStatus !=="Feedback-Pending")?.length}
              pageSizeOptions={[10, 30, 50, 100]} 
              //  size={"small"}
              current={parseInt(pageMeta?.currentPage)}
               showTotal={ (total, count) =>
               `${count[0]}-${count[1]} of ${total} items`}
              onChange={handlePageChange}
              onShowSizeChange={handlePageChange}
            />
            </div>
          </>
          :
          <div style={{display:"flex",alignItems:"center",width:"100%",height:"40vh",textAlign:"center",justifyContent:"center"}}>
            No Ongoing Interviews</div>
          }
          </TabPane>
          <TabPane tab={"Completed"} key="Completed">
            <Input type="text" placeholder='Search' style={{color:"#161616",width:"100%",fontSize:"16px",fontWeight:"600",borderRadius:5}}
              onChange={(event)=>{ setSearchVal(event.target.value) }}
              prefix={<SearchOutlined className="custom-search-icon" style={{ Color:"#0086FF", }} />}
            ></Input>
            <div style={{width:"100%",borderRadius:"1px",border:"1.5px solid #D9D9D9",marginTop:"0.5rem",display: "flex",height:"2.3rem",backgroundColor:"white" }}>
            <DatePicker format={"YYYY-MM-DD"} style={{width:"100%",height:"2.1rem"}} bordered={false}
              allowClear placeholder="Start Date"
              onChange={(date, dateString) => {
                if(!dateString){
                  setDateVal([]); 
                }else{
                setDateVal([dateString, dateVal[1]]);  
                }            
              }}
              value={dateVal[0] !== "" && dateVal[0] !== undefined && dateVal[0] !== null ? moment(dateVal[0], "YYYY-MM-DD") : null}
            />
            <span style={{ fontSize: "1rem", alignItems: "center" }}>-</span>
              <DatePicker format={"YYYY-MM-DD"} style={{width:"100%",height:"2.1rem"}} bordered={false}
              allowClear  disabled={!dateVal[0] && !dateVal[1]} placeholder="End Date"
                onChange={(date, dateString) => {
                  setDateVal([dateVal[0], dateString]);              
                }}
              value={dateVal[1] !== "" && dateVal[1] !== undefined && dateVal[1] !== null ? moment(dateVal[1], "YYYY-MM-DD") : null}
              />
            </div>
            {schedule.filter(item => item.interviewStatus ==="Completed" || item?.interviewStatus ==="Feedback-Pending").length > 0 ?<>
            <div className={classes.ListBodyContainer} style={{height:"67vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",marginTop:"0.6rem"}}>
              {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
                schedule?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
                schedule?.map((record,id,index)=>{
                  if(record?.interviewStatus ==="Completed" || record?.interviewStatus ==="Feedback-Pending"){
                    // count=++count
                    return(
                      <div style={{width:"100%",marginTop:"0.1rem",marginBottom:"0.6rem",borderRadius:5,backgroundColor:"#FFFFFF",padding:"0.6rem 0.9rem"}} key={id}>
                      <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"0.5rem",paddingTop:"0rem",borderBottom:"1px solid #DFDFDF"}}>
                        <div style={{display: "flex",justifyContent: "flex-start",flexDirection:"column"}}>
                            <Popover content={record?.candidateName}>
                              <Space size="small">
                              <Typography.Text ellipsis={{tooltip:false}} style={{width:"15rem",fontSize:"1.1rem",fontWeight:700,}}>
                                {record.candidateName}</Typography.Text>
                              </Space>
                            </Popover>
                            <Popover content={record?.jobTitle}>
                              <Space size="small">
                              <Typography.Text ellipsis={{tooltip:false}} style={{width:"15rem",fontSize:"0.9rem",}}>
                                {record?.jobTitle}</Typography.Text>
                              </Space>
                            </Popover>
                        </div>
                        <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                          <span style={{ fontSize: 10 }}>
                            {/* <a onClick={() => {
                                    // this.props.updateInputField(
                                    //   inputFieldType.CANDIDATE_RESUME,
                                    //   record.resume
                                    // );
                                    // this.props.performOperation(
                                    //   operationType.CANDIDATE_PDF_VIEW_MODAL,
                                    //   true
                                    // );  resumeLink
                                  }}
                            >
                            <img src={ResumeIcon} />
                            </a> */}
                            {width>700 ?
                              <a style={{paddingLeft:"5px"}} href={record?.resumeLink} target="_blank"><img src={ResumeIcon} /></a>
                            :
                              <a style={{paddingLeft:"5px"}} onClick={()=>{setPdfModal(true);setPdfResume(record?.resumeLink)}}><img src={ResumeIcon} /></a>
                            }                            
                          </span>
                        </div>
                      </div>

                      <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Round</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.roundType}</p>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Status</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.interviewStatus}</p>
                      </div>
                      </div>

                      <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Date</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.interviewDate? moment(record?.interviewDate).format('YYYY-MMM-DD'):"NA"}</p>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Time</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.startTime? record?.startTime:"NA"} - {record?.endTime?record?.endTime:"NA"}</p>
                      </div>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"100%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Meet link</span>
                        <p style={{fontSize:15,color:"#161616"}}>{ record?.meetingLink === null ? <div>NA</div> 
                        : <Typography.Text ellipsis={{tooltip:true}} style={{width:"14rem"}}>
                          <a href={record?.meetingLink} target="_blank"> 
                              {record?.meetingLink ? record?.meetingLink :"NA"}
                          </a>
                          </Typography.Text>
                        }</p>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"100%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Reasons</span>
                        <p style={{fontSize:15,color:"#161616"}}>{record?.reason ? record?.reason : "NA"}</p>
                      </div>
                      <div style={{width:"100%",marginTop:"1rem",borderBottom:"0.6rem"}}>
                        <Button type="primary" style={{height:"2.1rem",width:"100%",borderRadius:5,}} 
                          disabled={record?.interviewStatus ==="Completed" || record?.interviewStatus ==="Feedback-Pending"} 
                          onClick={() => {
                              SetDetails(record)
                              setViewModals(true)
                          }} 
                        >
                         Interview status
                        </Button>
                      </div>
                      </div>
                    )
                  }
                  return null;
                  
                })}
            </div>
            <div style={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <Pagination
              style={{marginTop:"20px",}} size="small" position="bottomRight"
              showSizeChanger={true}
              pageSize={pageSize}
              total={pageMeta?.totalItems}
              pageSizeOptions={[10, 30, 50, 100]} 
              //  size={"small"}
              current={parseInt(pageMeta?.currentPage)}
               showTotal={ (total, count) =>
               `${count[0]}-${count[1]} of ${total} items`}
              onChange={handlePageChange}
              onShowSizeChange={handlePageChange}
            />
            </div>
          </>
          :
          <div style={{display:"flex",alignItems:"center",width:"100%",height:"40vh",textAlign:"center",justifyContent:"center"}}>
           No Interviews</div>}
          </TabPane></Tabs></>
         }

          <Modal visible={viewModals} footer={false} onCancel={cancelHandler}
           closable={true}
           maskClosable={true} 
           centered
           width={width>450?"40%":"85%"}
           style={{
                 borderRadius: "0px",
                 textAlign: "center",
            }}>
                <Row justify="center"><p style={{fontSize:"1rem",fontSize:"18px",marginTop:"2rem",color:"#0086FF"}}>Is Interview Completed?</p></Row>
                <Row justify="center"><Space style={{marginBottom:"2rem",marginTop:"2rem"}}>
                
                <Button style={{ borderColor: "#4DAAFF", color: "#4DAAFF",width:"6rem", }} 
                   onClick={(e) => {
                    setStatus("Cancelled")
                    setViewModals(false)
                    setReasonModal(true)
                  }}>
                  No
                </Button>

                <Button style={{ backgroundColor: "#4daaff",width:"6rem", borderColor: "#4daaff", color: "white",marginLeft:"15px" }} 
                  onClick={() => {
                    setDiscussionModal(true)
                    setViewModals(false)
                    // getPost();
                  }}>
                    Yes
                </Button>
                </Space>
                </Row>
        </Modal>  

        <Modal visible={reasonModal} footer={false} onCancel={reasonCancel}
            closable={true}
            centered
            width={width>450?"40%":"85%"}
            style={{
            borderRadius: "0px",
            textAlign: "center",
          }}>
       <div><Row><p style={{fontSize:"1.5rem",marginTop:"20px",fontWeight:"bold"}}>Reasons</p></Row></div> 
        <Row>
          <p >
            Choose Appropriate Reason for not attending interview
          </p>
        </Row>
        <Row>
        <Radio.Group value={value} style={{width:"100%",display:'flex',marginTop:"0.5rem",}}
            onChange={(e)=>{
              
              
              setValue(e.target.value);
            }} >
            <Space direction="vertical" style={{marginLeft:"10px",textAlign:"left",width:"100%",display:'flex'}}>
            {/* {dataValue.map(({value, id) => { */}
                  <Radio value={"Candidate was Unavailable"}>Candidate was Unavailable</Radio>
                  <Radio value={"I was Unavailable"} >I was Unavailable</Radio>
                  <Row>
                  {value === "I was Unavailable" ? (
                                <Input
                                  type="text"
                                  style={{
                                    marginLeft:"1.5rem",
                                    marginRight:"7rem",
                                    // width:"175%",
                                    height:"4rem",
                                  }}
                                  onChange={(event)=>{
                                    let val=event.target.value
                                    setOtherReason(val)
                                  }}
                                />
                              ) : null}
                  </Row>
                  <Radio value={"Network Issues"} >Network Issues</Radio>
                  <Radio value={"Others"} style={{width:"100%",display:"flex"}}>
                    Others
                  </Radio>
                  <Row >
                    {value === "Others" ? (
                                <Input
                                  type="text"
                                  style={{
                                    marginLeft:width>700?"1.5rem":0,
                                    marginRight:width>700?"7rem":"auto",
                                    // width:"175%",
                                    height:"4rem",
                                  }}
                                  onChange={(event)=>{
                                    
                                    let values=event.target.value
                                    setOtherValue(values)
                                  }}
                                />
                              ) : null}
                   </Row>
                </Space>
              </Radio.Group>
                  
        </Row>
        <Row justify="right" style={{ display:"flex",margin:"5%",justifyContent:"right" }} >
        <Button loading={btnLoader}
            style={{ backgroundColor: "#1890FF",borderRadius:"5px",width:width>700?"9.2rem":"100%",height:"2.5rem", borderColor: "#4daaff", color: "white",}} 
            onClick={() => {
                getPost();
                setOtherReason("")
                setOtherValue("")
                setValue("")
            }}>
            {width>700?"Submit Reason":"Submit"}
        </Button>
        </Row>
    </Modal>

    <Modal visible={discussionModal} footer={false} onCancel={canHandler}
           closable={true}
           centered
          //  width="500px"
           style={{
                 borderRadius: "0px",
                 textAlign: "center",
            }}>
                <Row justify="left" ><span style={{marginLeft:width>700&&"2rem",fontSize:width>700?"1.2rem":"1rem",fontWeight:700,marginTop:"2rem",color:width>700? "#0086FF":"#000"}}>Select the Option for Update Status</span></Row>
                <Row justify="left" style={{marginTop:"1.3rem",marginBottom:"1rem", marginLeft:width>700&&"2rem"}}>
                 <Radio.Group style={{fontSize:"1rem",fontWeight:"648",}} value={finalValue} size="large" bordered={false}
                   onChange={(e)=>{     
                    setFinalValue(e.target.value)  
                    // dispatch(interviewActions.updateFinalValues(e.target.value))  
                    console.log("sssValue", e.target.value)          
                  }}>
                      {details?.roundType==="HR"?<>
                      {submitArray.map((item, index) => (
                        <Space direction="vertical" style={{marginLeft:"2px",textAlign:"left",width:"100%",display:'flex',marginTop:"0.5rem"}}>
                        <Radio style={{ fontSize: "1rem" }} value={item} key={index}>
                          {item}
                        </Radio>
                        </Space>
                      ))}
                      </>
                        :
                        <Space direction="vertical" style={{marginLeft:"7px",textAlign:"left",width:"100%",display:'flex',}}>
                        <Radio style={{fontSize:"1rem"}} value="Re-Discussion">Re-Discussion</Radio>
                        <Radio style={{fontSize:"1rem"}} value="Feedback">Feedback</Radio>
                        </Space>                        
                        }
                  </Radio.Group>
                </Row>
                {/* {width>700? */}
                <Row justify="center"><Space style={{marginBottom:"2rem",marginTop:"1.5rem"}}>
                <Button style={{ borderColor: "#4DAAFF", color: "#4DAAFF",width:"6rem", }} 
                  onClick={()=>{
                    // Api.patch("/hiring-panelist/changeCandidateStatus")
                    // .params({
                    //  candidateId: details?.candidateId,
                    //  status:"Completed",
                    //  slotId:details?.slotId,
                    //  reason:value==='Others'?otherValue:value,
                    //  otherReason:otherReason,
                    // })
                    // .send((response)=>{
                     
                    //      getSheduleTable()
                    //     //  window.open(response, "_blank")?.focus();

                    //  })
                     setFinalValue("")
                    // dispatch(interviewActions.updateFinalValues(""))
                     setDiscussionModal(false)
                     setViewModals(true)
                  }}>
                  Cancel
                </Button>
                <Button style={{ backgroundColor: finalValue === "" ? "grey":"#4daaff",width:"6rem", color: "white",marginLeft:"15px" }} 
                  disabled={finalValue === "" ? true : false}
                  onClick={(e) => {
                    if(finalValue === "Re-Discussion"){
                    Api.patch("/hiring-panelist/changeCandidateStatus")
                    .params({
                     candidateId: details?.candidateId,
                     status:"Re-Discussion",
                     slotId:details?.slotId,
                     reason:value==='Others'?otherValue:value,
                     otherReason:otherReason,
                    })
                    .send((response)=>{
                      if(response.show.type==="success"){
                         getSheduleTable();
                         setDiscussionModal(false)
                         setFinalValue("")
                        // dispatch(interviewActions.updateFinalValues(""))
                    }})
                    }
                    else if(finalValue === "Evaluate Previous round feedback"){
                     Api.patch("/hiring-panelist/changeCandidateStatus")
                    .params({
                     candidateId: details?.candidateId,
                     status:"Completed",
                     evaluate:true,
                     slotId:details?.slotId,
                     reason:value==='Others'?otherValue:value,
                     otherReason:otherReason,
                    })
                    .send((response)=>{
                        if(response.show.type==="success"){
                         getSheduleTable();
                         setDiscussionModal(false)
                         setFinalValue("")
                        // dispatch(interviewActions.updateFinalValues(""))
                         window.open(details?.feedbacklink,"_blank")?.focus()
                    }})
                    }
                    else{
                      Api.patch("/hiring-panelist/changeCandidateStatus")
                     .params({
                      candidateId: details?.candidateId,
                      status:"Completed",
                      evaluate:false,
                      slotId:details?.slotId,
                      reason:value==='Others'?otherValue:value,
                      otherReason:otherReason,
                     })
                     .send((response)=>{
                         if(response.show.type==="success"){
                          getSheduleTable();
                          setDiscussionModal(false)
                          setFinalValue("")
                         // dispatch(interviewActions.updateFinalValues(""))
                          window.open(details?.feedbacklink,"_blank")?.focus()
                     }})
                     }
                    // else if(finalValue === "Feedback" && details?.roundType === "HR"){

                    // }
                  }}
                  >
                    OK
                </Button>
                </Space>
                </Row>
                {/* :
                <button
                disabled={!finalValue}
                onClick={() => {
                  if(finalValue === "Re-Discussion"){
                  Api.patch("/hiring-panelist/changeCandidateStatus")
                  .params({
                   candidateId: details?.candidateId,
                   status:"Re-Discussion",
                   slotId:details?.slotId,
                   reason:value==='Others'?otherValue:value,
                   otherReason:otherReason,
                  })
                  .send((response)=>{
                    if(response.show.type==="success"){
                       getSheduleTable();
                       setDiscussionModal(false)
                       setFinalValue("")
                      // dispatch(interviewActions.updateFinalValues(""))
                  }})
                  }
                  else if(finalValue === "Evaluate Previous round feedback"){
                   Api.patch("/hiring-panelist/changeCandidateStatus")
                  .params({
                   candidateId: details?.candidateId,
                   status:"Completed",
                   evaluate:true,
                   slotId:details?.slotId,
                   reason:value==='Others'?otherValue:value,
                   otherReason:otherReason,
                  })
                  .send((response)=>{
                      if(response.show.type==="success"){
                       getSheduleTable();
                       setDiscussionModal(false)
                       setFinalValue("")
                      // dispatch(interviewActions.updateFinalValues(""))
                       window.open(details?.feedbacklink,"_blank")?.focus()
                  }})
                  }
                  else{
                    Api.patch("/hiring-panelist/changeCandidateStatus")
                   .params({
                    candidateId: details?.candidateId,
                    status:"Completed",
                    evaluate:false,
                    slotId:details?.slotId,
                    reason:value==='Others'?otherValue:value,
                    otherReason:otherReason,
                   })
                   .send((response)=>{
                       if(response.show.type==="success"){
                        getSheduleTable();
                        setDiscussionModal(false)
                        setFinalValue("")
                       // dispatch(interviewActions.updateFinalValues(""))
                        window.open(details?.feedbacklink,"_blank")?.focus()
                   }})
                   }
                  // else if(finalValue === "Feedback" && details?.roundType === "HR"){

                  // }
                }}
                 style={{height:"2.5rem",width:"100%",color:"#fff",backgroundColor:"#1089FF",borderRadius:"0.5rem",outline:0,border:0,cursor:finalValue === ""?"not-allowed":"pointer",marginBottom:"1rem"}}>Submit</button>
                } */}
    </Modal>

    {pdfModal && <PdfViewModal pdf={pdfResume} pdfModal={pdfModal} setPdfModal={setPdfModal} />}


        {/* <Modal visible={confirmModal} footer={false} onCancel={()=>{setConfirmModal(false)}}
           closable={true}
           centered
           width="500px"
           style={{
                 borderRadius: "0px",
                 textAlign: "center",
            }}>
                <WarningOutlined style={{ fontSize: "3em",margin:"1rem", color: "red" }} />
                <Row justify="center"><p style={{fontSize:"1rem",fontSize:"19px",color:"#0086FF",textAlign:"center"}}>
                  Are you sure you want Re-Discussion ?
                  <br></br>
                  </p></Row>
                <Row justify="center"><Space style={{marginBottom:"2rem",marginTop:"2rem"}}>
                <Button style={{ borderColor: "#4DAAFF", color: "#4DAAFF",width:"6rem", }} 
                  onClick={()=>{
                     setDiscussionModal(true)
                     setConfirmModal(false)
                  }}>
                  No
                </Button>
               
                <Button style={{ backgroundColor: "#4daaff",width:"6rem", borderColor: "#4daaff", color: "white",marginLeft:"15px" }} 
                  onClick={(e) => {
                     
                     Api.patch("/hiring-panelist/changeCandidateStatus")
                     .params({
                      candidateId: details?.candidateId,
                      status:"Re-Discussion",
                      slotId:details?.slotId,
                      reason:value==='Others'?otherValue:value,
                      otherReason:otherReason,
                     })
                     .send((response)=>{
                        
                          getSheduleTable();
                      })
                     setConfirmModal(false)
                  }}>
                    Yes
                </Button>
                </Space>
                </Row>
        </Modal> */}

        </div>
      </div>
      );

}

export default InterviewSchedules;