import React,{useEffect, useState}from "react";
import { Modal,Button,Input,TreeSelect,Row,Col, Form, Space,Switch } from "antd";
import "./style.css";
import classes from './jdViewModal.module.css';
import CreateJDModal   from "./createJdModal";
import PdfLogo from "../../assets/pdf-logo.svg";
import Api from "services/Api";
const JdViewModal = (props) =>{
  const [arrays,setArr]=useState([])
  let arr=[]
  useEffect(() =>{
   arr =props.record.jobInterviewrounds.map((element) =>{
    return element
   })
   setArr(arr.sort(function(a, b){return a.round < b.round}))
   
  },[])
    const [showUpdateModal,setUpdateModal]=useState(false)
    const [reverse, setReverse] = useState(true);
    const toggleUpdateModal = (record) => { 
        setUpdateModal(!showUpdateModal);
      };
    let data=[]
    
Object.keys(props.record).forEach(keys =>{
       
        let item=keys
        if(item==="class_10th" || item==="class_12th" || item==="cgpa" || item==="maxBackLogs"){
      data.push({
            keys:item,
            value:props.record[keys]
        })}
    })
 
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    const getFileName = (fileName) => {
        return fileName.split('/').at(-1);
      }

      
  return(<>
    <Button style={{color:"black",borderColor:"#00FF61",backgroundColor:"#EAFFF2",fontWeight:"bold"}} onClick={showModal}>
  VIEW
  </Button>
  <Modal title={props.record.title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} centered width="40%">
  <Row justify="end">
  {props?.user?.role.includes("Recruiter") || props?.user?.role.includes("Sourcer") ?
  ""
  :
  <Switch size="large" defaultChecked={props.record.status==="Active"} checkedChildren="Active" unCheckedChildren="Inactive"  onChange={(e) =>{
  let statusvalue
     if(e)
     {
        statusvalue="Active"
       
     }               
     else{
        statusvalue="Inactive"
     }
     Api.patch(`/hiring-job/updateStatus`).params({
     jobIds:[props?.record?.id],
     status:statusvalue

     })
     .send((resp) =>{
        props?.refresh()
     })
     }} />
    }
     </Row>
  <div className={classes.modalContent} style={{marginBottom:"20px"}}>
  <div>
<Row>
    <Col span={12}>
<div style={{ textAlign: "center" }}>
  <img
    onClick={() => window.open(props?.record?.descriptionFile)}
    src={PdfLogo}
    alt="pdf-logo"
    style={{width:"50%"}}
  />
</div>
<div style={{ textAlign: "center" }}> <span >{getFileName(props.record.descriptionFile ?props.record.descriptionFile :"")}</span></div></Col>
<Col span={12}>
    <Row>
      <p className="college-modal-field">Organization department</p>
    <Input disabled value={props.record.jobType?.name}/></Row>
    <Row><p className="college-modal-field">number of openings</p>
    <Input disabled value={props.record.vacancies}/></Row>
    <Row><p className="college-modal-field">Priority</p>
    <Input disabled value={props.record.priority}/></Row>
    </Col></Row>

</div>
    <Row><p className="college-modal-field">Eligibility Criteria</p></Row>
    <Row style={{marginTop:"20px"}}>
    {data.map((item) =>{
        return(<>
        <Col span={11} style={{marginLeft:"10px"}}>
        <p>{item.keys}</p>
        <Input value={item.value} disabled /></Col></>)
    })}</Row>
 

    
     {arrays.map((item) =>{
            return(<>
                <p className="college-modal-field">Round:{item.round}</p>
                <Input disabled value={item.roundType === "Relationship" ? "HR Relationship" : item.roundType}/>
            </>)
        })}
  </div>
  <Row justify="center">
    {props?.user?.role.includes("Recruiter") || props?.user?.role.includes("Sourcer") ?
    ""
    :
    <Space>
    <Button onClick={handleCancel}>Cancel</Button>
    <Button type="primary" onClick={() =>{setUpdateModal(true)}}>EDIT</Button>
    </Space>
  }
  </Row>
  </Modal>
  {showUpdateModal && <CreateJDModal 
          refresh={props.refresh.bind(this)}
          selectedObject={props.record}
          poc={props.poc}
          roundAgency={props.roundAgency}
            orgType={props.orgType}
            jobLocations={props.jobLocations}
            isOpen={showUpdateModal}
            toggle={toggleUpdateModal.bind(this)}/>}
</>
  )
}
export default JdViewModal