import {
  getAllCandidates,
  statusDropdownAction,
  hiringStatusDropdownAction,
  performOperation,
  updateInputField,
  updateHrActionInputField,
  handleCandidateEdit,
  handleCandidateHrAction,
  handleCandidateAction,
  getInterviewSlots,
  closeInterviewModal,
  scheduleInterview,
  deleteScheduledInterview,
  candidateDetailsHide,
  getDuplicateCandidates,
  handleCandidateFlagEdit,
  getCandidatesOnTabChange,
  handleSkipRound,
  candidateOfferToggle,
  candidateOfferRead,
  candidateOfferEdit,
  candidateJobChangeToggle,
  candidateRoundChangeToggle,
  patchCandidateJobChange,
  handleCandidatePhotoEdit,
  setCandidateScreenshot,
  getCandidateFeedback,
  setCurrenCandidate,
  selectCandidateRound,
  setFeedbackView,
  setHrActionEditingView,
  showHrActionEditingModal,
} from "./action";

export const mapStateToProps = (state) => {
  const {
    candidateTableLoader,
    candidates,
    panelists,
    candidateJobDescription,
    panelist,
    changedPanelist,
    candidateScreenType,
    candidateJobs,
    candidateResetRounds,
    candidateAgencies,
    candidatesHrStatus, //This is from backend
    candidateInfo,
    candidateOriginalInfo,
    candidateAgency,
    candidatePreviousOrgsViewModal,
    candidateJobChangeModal,
    candidateShortlistedLoader,
    interviewModal,
    interviewSubmitLoader,
    interviewOnModalLoader,
    interviewType,
    interviewDate,
    interviewAvailableSlots,
    interviewPanelistName,
    interviewSelfBookedSlots,
    interviewSchedule,
    interviewDeleteLoader,
    interviewConfirmModal,
    candidateInterviewMode,
    candidatePDFViewModal,
    candidateResume,
    candidateImageViewModal,
    candidateImage,
    candidateRejectedLoader,
    candidateDateDropdown,
    candidateDateRange,
    candidateStatusDropdown,
    candidateFilterStatus,
    hiringFilterStatus,
    candidateRound,
    candidateHrStatus, //This is local one
    duplicateViewModal,
    duplicateCandidates,
    candidateOngoingStatus,
    candidateRejectedStatus,
    candidateNewPageNumber,
    candidateOngoingPageNumber,
    candidateRejectedPageNumber,
    candidateHistoryPageNumber,
    candidateCommentBool,
    candidateOfferBool,
    candidateCommentText,
    candidateOfferText,
    candidateOfferLoaderBool,
    candidateJobDescriptionForHistory,
    candidateAgencyForHistory,
    candidateJobChange,
    candidateRoundChange,
    candidateJobChangeToggleBool,
    candidateJobChangeLoaderBool,
    candidatePhotoEdit,
    candidatePhotoLoader,
    candidatePhotoEditBool,
    candidatePageMeta,
    feedbackLoader,
    candidateRounds,
    candidatesScreenshot,
    candidateFeedbackRound,
    candidateFeedback,
    viewFeedback,
    editHrActions,
    hrActionEditingModal,
    actionEditingModal,
    hrActionEditingLoader,
    hrProcessSearchValue
  } = state.hrProcess;
  const { candidateViewModal } = state.candidate;
  // const { user } = state.session;
  return {
    candidateTableLoader,
    candidates,
    candidateJobDescription,
    panelist,
    changedPanelist,
    candidateScreenType,
    candidateJobs,
    candidateResetRounds,
    candidateAgencies,
    candidatesHrStatus,
    candidateInfo,
    candidateOriginalInfo,
    candidateAgency,
    candidateViewModal,
    candidatePreviousOrgsViewModal,
    candidateJobChangeModal,
    candidateShortlistedLoader,
    interviewSubmitLoader,
    interviewOnModalLoader,
    interviewModal,
    interviewType,
    interviewDate,
    interviewAvailableSlots,
    interviewPanelistName,
    interviewSelfBookedSlots,
    interviewSchedule,
    interviewDeleteLoader,
    interviewConfirmModal,
    candidateInterviewMode,
    candidatePDFViewModal,
    candidateResume,
    candidateImageViewModal,
    candidateImage,
    candidateRejectedLoader,
    candidateDateDropdown,
    candidateDateRange,
    candidateStatusDropdown,
    candidateFilterStatus,
    hiringFilterStatus,
    candidateRound,
    candidateHrStatus,
    duplicateViewModal,
    duplicateCandidates,
    // user,
    candidateRejectedStatus,
    candidateOngoingStatus,
    panelists,
    candidateNewPageNumber,
    candidateOngoingPageNumber,
    candidateRejectedPageNumber,
    candidateHistoryPageNumber,
    candidateCommentBool,
    candidateOfferBool,
    candidateCommentText,
    candidateOfferText,
    candidateOfferLoaderBool,
    candidateJobDescriptionForHistory,
    candidateAgencyForHistory,
    candidateJobChange,
    candidateRoundChange,
    candidateJobChangeToggleBool,
    candidateJobChangeLoaderBool,
    candidatePhotoEdit,
    candidatePhotoLoader,
    candidatePhotoEditBool,
    candidatePageMeta,
    feedbackLoader,
    candidateRounds,
    candidatesScreenshot,
    candidateFeedbackRound,
    candidateFeedback,
    viewFeedback,
    editHrActions,
    hrActionEditingModal,
    actionEditingModal,
    hrActionEditingLoader,
    hrProcessSearchValue
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getAllCandidates: (history, page, limit) =>
    dispatch(getAllCandidates(history, page, limit)),
  getCandidatesOnTabChange: (history) =>
    dispatch(getCandidatesOnTabChange(history)),
  getDuplicateCandidates: () => dispatch(getDuplicateCandidates()),
  statusDropdownAction: (history) => dispatch(statusDropdownAction(history)),
  hiringStatusDropdownAction: (history) => dispatch(hiringStatusDropdownAction(history)),
  handleCandidateEdit: () => dispatch(handleCandidateEdit()),
  handleCandidateFlagEdit: () => dispatch(handleCandidateFlagEdit()),
  handleCandidateHrAction: (id, hrStatus) =>
    dispatch(handleCandidateHrAction(id, hrStatus)),
    handleCandidateAction: (id, status) =>
    dispatch(handleCandidateAction(id, status)),
  candidateDetailsHide: (id) => dispatch(candidateDetailsHide(id)),
  handleSkipRound: (id) => dispatch(handleSkipRound(id)),
  handleCandidatePhotoEdit: () => dispatch(handleCandidatePhotoEdit()),

  getInterviewSlots: () => dispatch(getInterviewSlots()),
  closeInterviewModal: () => dispatch(closeInterviewModal()),
  scheduleInterview: () => dispatch(scheduleInterview()),
  deleteScheduledInterview: () => dispatch(deleteScheduledInterview()),

  updateInputField: (inputFieldType, value) =>
    dispatch(updateInputField(inputFieldType, value)),
  updateHrActionInputField: (hrActionInputFieldType, value) =>
    dispatch(updateHrActionInputField(hrActionInputFieldType, value)),

  performOperation: (operationType, value) =>
    dispatch(performOperation(operationType, value)),
  candidateOfferEdit: (id, text, history) =>
    dispatch(candidateOfferEdit(id, text, history)),
  candidateOfferToggle: (id, text) => dispatch(candidateOfferToggle(id, text)),
  candidateOfferRead: (input) => dispatch(candidateOfferRead(input)),
  candidateRoundChangeToggle: (bool, obj) =>
    dispatch(candidateRoundChangeToggle(bool, obj)),
  candidateJobChangeToggle: (bool, obj) =>
    dispatch(candidateJobChangeToggle(bool, obj)),
  patchCandidateJobChange: () => dispatch(patchCandidateJobChange()),
  setCandidateScreenshot: (value) => dispatch(setCandidateScreenshot(value)),
  getCandidateFeedback: (candidateId) =>
    dispatch(getCandidateFeedback(candidateId)),
  setCurrenCandidate: (data) => dispatch(setCurrenCandidate(data)),
  selectCandidateRound: (val) => dispatch(selectCandidateRound(val)),
  setFeedbackView: (bool) => dispatch(setFeedbackView(bool)),
  setHrActionEditingView: (bool) => dispatch(setHrActionEditingView(bool)),
  showHrActionEditingModal: (record) =>
    dispatch(showHrActionEditingModal(record)),
});
