import { LockOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Select, Collapse, Radio } from "antd";
import React from "react";
import { Color } from "services";
import "./styles.css"
const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not validate email!",
    number: "${label} is not a validate number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const removeSpecialCharacter = (str) => {
  return str.replace(/[^a-zA-Z0-9() ]/g, "");
}

const heading = (headingIcon, text, required, size, headingStyle) => {
  const asterisk = required !== undefined && required ? <span style={{color: Color.red}}>*</span> : ""
  return (
    <div className="text-black m-b-5" style={{color: Color.darkGrey,fontSize: "1rem"}}>
      {headingIcon !== undefined? (
        <div>
          {headingIcon} {text} {asterisk}
        </div>
      ) : (
        <div>
          {text} {asterisk}
        </div>
      )}
    </div>
  );
};

export const TextFormDisplay = ({ headingIcon, text, value, size, style, divStyle, headingStyle }) => {
  return (
    <>
      {heading(headingIcon, text, size, headingStyle)}
      <Form.Item className="form-item" style={style}>
        <div style={divStyle !== undefined ? divStyle : { color: Color.darkGrey }}>{value}</div>
      </Form.Item>
    </>
  );
};

export const RadioFormInput = ({ radios, value, onChange, disabled, style, headingIcon, text, required, size }) => {
  return (
    <>
      {heading(headingIcon, text, required, size)}
      <Form.Item className="form-item" style={style}>
        <Radio.Group value={value} onChange={onChange} disabled={disabled}>
          {radios.map((radio, index) => (
            <Radio key={index} value={radio}>{radio}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export const SelectFormInput = ({ text, size, onChange, headingIcon, dropDownList, 
  dropDownObjectList, value, fieldKey, style, disabled, id, suffixIcon, required, mode }) => {
  return (
    <>
      {heading(headingIcon, text, required, size)}
      <Form.Item 
        fieldKey={fieldKey} 
        className="form-item" 
        style={style} 
        rules={[{ required: true, message: `Please input the ${text}!` }]}
      >
        <Select
          id={id}
          showSearch
          mode={mode}
          placeholder={text !== undefined ? `Select ${text}` : "Select"}
          optionFilterProp="children"
          onChange={onChange}
          value={value !== "" ? value : null}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          disabled={disabled}
          suffixIcon={suffixIcon}
        >
          {dropDownList.map((user, index) => (
            <Option 
              value={dropDownObjectList !== undefined ? JSON.stringify(dropDownObjectList[index]) : user} 
              key={index}
            >
              {user}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export const NumberFormInput = ({ text, size, onChange, headingIcon, value, prefix, fieldKey, style, required, id, disabled }) => {
  const name = text !== undefined ? camelize(text) : "";
  return (
    <>
      {heading(headingIcon, text, required, size)}
      <Form.Item 
        fieldKey={fieldKey} 
        className="form-item" 
        style={style} 
        rules={[{ required: true, message: `Please input the ${text}!` }]}
      >
        <InputNumber
          id={id}
          prefix={prefix}
          name={name}
          placeholder={removeSpecialCharacter(text)}
          size={size}
          onChange={onChange}
          value={value}
          style={{ width: "100%" }}
          disabled={disabled}
          min={0}
        />
      </Form.Item>
    </>
  );
};

export const TextFormInput = ({ text, size, onChange, value, prefix, headingIcon, style, fieldKey, id, required, disabled }) => {
  const name = text !== undefined ? camelize(text) : "";
  const type = name === "email" ? name : name === "phone" ? "number" : "text";
  return (
    <>
      {heading(headingIcon, text, required, size)}
      <Form.Item 
        fieldKey={fieldKey} 
        className="form-item" 
        style={style} 
        rules={[{ required: true, message: `Please input the ${text}!` }]}
      >
        <Input
          id={id}
          prefix={prefix}
          name={name}
          placeholder={removeSpecialCharacter(text)}
          type={type}
          size={size}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export const TextAreaFormInput = ({ text, size, onChange, value, prefix, headingIcon, style, fieldKey, id, required, disabled, row }) => {
  const name = text !== undefined ? camelize(text) : "";
  const type = name === "email" ? name : "text";
  return (
    <>
      {heading(headingIcon, text, required, size)}
      <Form.Item 
        fieldKey={fieldKey} 
        className="form-item" 
        style={style} 
        rules={[{ required: false }]}
      >
        <TextArea
          id={id}
          prefix={prefix}
          name={name}
          placeholder={removeSpecialCharacter(text)}
          type={type}
          size={size}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export const EmailFormInput = ({ size, headingIcon, onChange, value, prefix, fieldKey, style, id, required }) => {
  return (
    <>
     {heading(headingIcon, "Email", required, size)}
     <Form.Item 
        fieldKey={fieldKey} 
        className="form-item" 
        style={style} 
        rules={[{ required: true, message: `Please input the Email!` }]}
      >
        <Input
          id={id}
          prefix={prefix}
          name="email"
          placeholder="Email"
          type="email"
          size={size}
          onChange={onChange}
          value={value}
        />
      </Form.Item>
    </>
  );
};

export const PasswordFormInput = ({ size, headingIcon, onChange, value, fieldKey, style, required, id }) => {
  return (
    <>
     {heading(headingIcon, "Password", required, size)}
     <Form.Item 
        fieldKey={fieldKey} 
        className="form-item" 
        style={style} 
        rules={[{ required: true, message: `Please input the Password!` }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          id={id}
          name="password"
          size={size}
          placeholder="Password"
          onChange={onChange}
          value={value}
        />
      </Form.Item>
    </>
  );
};

export const IntRange = ({text, size, minOnChange, maxOnChange, minName, maxName, headingIcon ,minValue, maxValue, fieldKey, style, required, width }) => {
  return (
    <>
      {heading(headingIcon, text, required, size)}
      <Form.Item fieldKey={fieldKey} style={style} className="form-item" rules={[{ required: true, message: `Please input the ${text}!` }]}>
        <Input.Group compact>
          <InputNumber
            style={width !== undefined ? {width: width} : { width: 70 }}
            onChange={minOnChange}
            value={minValue}
            name={minName}
            placeholder={minName}
            min={0}
          />
          <Input
            className="int-range-divider"
            style={{width: 30, backgroundColor: Color.white}}
            placeholder="->"
            disabled
          />
          <InputNumber
            onChange={maxOnChange}
            value={maxValue}
            style={ width !== undefined ? {width: width,  borderLeft: 0} : { width: 70, borderLeft: 0}}
            name={maxName}
            placeholder={maxName}
            min={0}
          />
        </Input.Group>
      </Form.Item>
    </>
  );
};

export const CollapseTextFormInput = ({text, onChange, value}) => {
  return (
    <Collapse
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      ghost 
    >
      <Panel header={text} style={{padding: 0}}>
        <TextArea allowClear onChange={onChange} value={value} style={{padding: 0}} name={camelize(text)}/>
      </Panel>
    </Collapse>
  )
}
