import { createSlice } from "@reduxjs/toolkit";
import store from "pages/dashboard-home/store";

const initialState = {
    jdList: [],
    info:{},
    columns:[],
    fresherColumn:[],
    tabStatus:"Active",
    jobTitleModalOpen:false,
  // warnings:4
  
};

const jdListSlice = createSlice({
  name: "jdFresher",
  initialState,
  reducers: {
    updateJdList: (state, action) => {
      state.jdList= [ ...action.payload.items ];
    },
    
     updateInfo(state, action) {
      state.info = { ...action.payload.meta };
    },
    updateAllColumns(state,action){
      
      if(!action.payload){
        return
      }
      state.columns=[...action.payload]
    },
    
    updateFresherMappedColumn(state,action)
    { if(!action.payload){
      return
    }
   
      state.fresherColumn=[...action.payload]
    },
    updateTabState (state,action){
      state.tabStatus=action.payload
     },
     
     updateJobTitleModalOpen(state,action){
      state.jobTitleModalOpen=action.payload;
    },

  //  updateWarning(state,action)
  //  {
  //    state.warnings=action.payload
  //  }
  },
});
export default jdListSlice.reducer;
export const jdFresherActions = jdListSlice.actions;
