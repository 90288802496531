import { WarningOutlined, EyeOutlined,SearchOutlined,EditOutlined } from "@ant-design/icons";
import { Space, Table, Tabs, PageHeader, Tooltip, Popover,Input,Button } from "antd";
import React from "react";
import { connect } from "react-redux";
import { AiOutlineSearch } from "services/reactIcons";
import store from "store";
import {
  Color,
  EmptyTable,
  InformationModal,
  userContent,
  modalContent,
} from "services";
import {
  AddPrimaryButton,
  DeleteIconButton,
  EditIconButton,
  SearchBar,
  IconButton,
} from "services/button";
import "services/styles.css";
import AdminModal from "./adminModal";
import AgencyModal from "./agencyModal";
import AgencyViewModal from "./agencyViewModal";
import {
  adminOperationType,
  agencyOperationType,
  AGENCY_DETAILS,
  SEARCH_VALUE,
} from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import "./styles.css";
import { getAdmins } from "./redux/action";
import MainHeader from "components/mainHeader";

const { TabPane } = Tabs;

const adminColumns = ({
  showAdminEditingModal,
  performOperation,
  handleAdminDelete,
  adminDetailsModal,
  user,getAdmins,
  getUserDetails
}) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <a
        style={{ fontWeight: "500" }}
        onClick={() => {
          performOperation(adminOperationType.ADMIN_DETAILS_MODAL, true);
          showAdminEditingModal(record);
        }}
      >
        {text}
      </a>
    ),
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "E-Mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Contact",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Manage",
    key: "manage",
    render: (text, record) => {
      return (
        <Space size="large">
          <Button type="primary" icon={<EditOutlined />} shape="circle" 
          className="editButton"
            onClick={() => {
              performOperation(adminOperationType.ADMIN_DETAILS_MODAL, true);
              showAdminEditingModal(record);
            }}
            disabled={
              record.email === "shakira.benazir@brigosha.com" ||
              record.email === user.email
            }
          ></Button>
          <DeleteIconButton
            onClick={() => {
              InformationModal.confirm(
                <div>
                  <WarningOutlined
                    style={{ fontSize: "3em", color: Color.red }}
                  />
                </div>,
                <div>
                  Are you sure you want to Permanently remove this user
                </div>,
                <div style={{ color: Color.darkGrey }}>
                  {modalContent("User Name:", record.name)}
                </div>,
                () => {handleAdminDelete(record.id)
                getAdmins()} 
              );
            }}
            disabled={
              record.email === "shakira.benazir@brigosha.com" ||
              record.email === user.email
            }
          ></DeleteIconButton>
        </Space>
      );
    },
  },
];

const agencyColumns = ({
  performOperation,
  updateInputField,
  handleAgencyDelete,
  agencyInfo,
}) => [
  {
    title: "Name",
    dataIndex: "agencyName",
    key: "agencyName",
    render: (text, record) => (
      <a
        style={{ fontWeight: "500" }}
        onClick={() => {
          performOperation(agencyOperationType.AGENCY_VIEW_MODAL, true);
          updateInputField(AGENCY_DETAILS, {
            ...record,
            users: record.users.map((user) => ({ ...user, disabled: true })),
          });
        }}
      >
        {text}
      </a>
    ),
    sorter: (a, b) => a.agencyName.localeCompare(b.agencyName),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Primary Contact",
    dataIndex: "primaryContact",
    key: "primaryContact",
    render: (text, record) => {
      const rowRecord =
        record.users === undefined
          ? ""
          : record.users.filter((user) => user.primaryContact === true)[0];
      return (
        <div>
          {rowRecord === undefined ? (
            ""
          ) : (
            <Popover content={userContent(rowRecord)}>
              <a>{rowRecord.name}</a>
            </Popover>
          )}
        </div>
      );
    },
  },
  {
    title: "Number of users",
    dataIndex: "count",
    key: "count",
    render: (text, record) => {
      return (
        <div>
          {record.users !== undefined && record.users.length !== undefined
            ? record.users.length
            : 0}
        </div>
      );
    },
  },
  {
    title: "Manage",
    key: "manage",
    render: (text, record) => {
      return (
        <Space size="large">
          <Tooltip title="View Agency">
            <span>
              <IconButton
                icon={<EyeOutlined />}
                id={`viewAgency${record.key}`}
                onClick={() => {
                  performOperation(agencyOperationType.AGENCY_VIEW_MODAL, true);
                  updateInputField(AGENCY_DETAILS, {
                    ...record,
                    users: record.users.map((user) => ({
                      ...user,
                      disabled: true,
                    })),
                  });
                }}
                className="editIcon"
              />
            </span>
          </Tooltip>
          <Tooltip title="Delete Agency">
            <span>
              <DeleteIconButton
                id={`agencyDelete${record.key}`}
                onClick={() => {
                  performOperation(
                    agencyOperationType.AGENCY_VIEW_MODAL,
                    false
                  );
                  InformationModal.confirm(
                    <div>
                      <WarningOutlined
                        style={{ fontSize: "3em", color: Color.red }}
                      />
                    </div>,
                    <div>
                      Are you sure you want to Permanently remove this agency
                    </div>,
                    <div style={{ color: Color.darkGrey }}>
                      {modalContent("Agency Name:", record.agencyName)}
                    </div>,
                    () => handleAgencyDelete(record.id)
                  );
                }}
              ></DeleteIconButton>
            </span>
          </Tooltip>
        </Space>
      );
    },
  },
];

class AdminAgency extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { page:1, pageSize: 10 };
    // this.handleClick = this.handleClick.bind(this);
  }
  
  componentDidMount() {
    if (this.props.screenType === "Users") {
      this.props.performOperation(adminOperationType.ADMIN_CLEAR, []);
      this.props.getAdmins();
      // this.props.getUserDetails();
    } else {
      this.props.performOperation(agencyOperationType.AGENCY_CLEAR, []);
      this.props.getAgencies();
    }
  }

  addAdminOrAgency = () => {
    if (this.props.screenType === "Users") {
      this.props.performOperation(adminOperationType.IS_ADDING_ADMIN, true);
      this.props.performOperation(adminOperationType.ADMIN_DETAILS_MODAL, true);
    } else {
      this.props.performOperation(agencyOperationType.IS_ADDING_AGENCY, true);
      this.props.performOperation(
        agencyOperationType.AGENCY_DETAILS_MODAL,
        true
      );
    }
  };

  onSearch = (e) => {
    this.props.performOperation(SEARCH_VALUE, e.target.value);
    if (this.props.screenType === "Users") {
      this.props.getAdmins();
    } else {
      this.props.getAgencies();
    }
  };

  handlePageChange = ( page, pageSize,value) => {
    if(!value)
    {
      return
    }
    this.setState({page:page,pageSize:pageSize});
    if(value==="usersAdmin"){
    this.props.getAdmins(page, pageSize); 
    }
    else {
    this.props.getAgencies(page, pageSize);}
  };
  render() {
    let {
      adminUsers,
      performOperation,
      adminTableLoader,
      screenType,
      agencies,
      agencyTableLoader,
      getAdmins,
      getUserDetails,
      getAgencies,
      searchValue,
    } = this.props;

    const props = this.props;
    const { meta } = store.getState().createUser;

   
    return (
      <>
          <div style={{padding:"1.1% 2% 2% 2%",height:"80vh",}}> 
          <h1 style={{fontSize:"1.5rem",fontWeight:"bold",}}>User</h1>  
          <div style={{marginTop:"-1.2rem"}}>  
            <Tabs
              defaultActiveKey="Users"
              activeKey={screenType}
              tabBarExtraContent={
                <div className="create-user">
                  {/* <SearchBar               
                    placeholder={`Search ${screenType}`}
                    onSearch={this.onSearch}
                  /> */}
                  <Input
                   size="medium"
                   style={{marginRight:"1rem",borderRadius:"4px",width:"16.5rem",height:"2.1rem"}}
                   prefix={<SearchOutlined className="custom-search-icon" />}
                   type="text"    
                   value={searchValue}          
                   placeholder={`Search ${screenType}`}
                   onChange={this.onSearch}                 
                   allowClear={true}
                  />
                  <AddPrimaryButton
                    style={{height:"2rem",width:"8.2rem",fontWeight:'600',borderRadius:"5px"}}
                    onClick={this.addAdminOrAgency}
                    text={`Add ${screenType==="Users"?"User":"Agency"}`}
                  />
                </div>
              }
              onChange={(key) => {
                performOperation(adminOperationType.SCREEN_TYPE, key);
                key === "Users" ? getAdmins() : getAgencies();
              }}
              style={{ overflow: "hidden" }}
            >
              <TabPane tab={<span style={{fontWeight:"bold",}}>Users</span>} key="Users">
                <Table
                  columns={adminColumns(props).map((col) => ({
                    ...col,
                    align: "left",
                  }))}
                  scroll={{y:450,x:1000}}
                  pagination={{
                    position: "bottomRight",
                    showSizeChanger: true,
                    pageSizeOptions: [10,25, 30, 50, 100],
                    pageSize: this.state.pageSize,
                    current: this.state.page,
                    total: meta?.totalItems
                    ?meta?.totalItems
                    : 0,
                    onChange: (page, pageSize) => {
                      this.handlePageChange(page, pageSize,"usersAdmin");
                    },
                    onShowSizeChange: this.handlePageChange,
                  }}
                  dataSource={adminUsers}
                  locale={{
                    emptyText: adminTableLoader ? (
                      <div className="no-data-loader"></div>
                    ) : (
                      <EmptyTable
                        text="No Users Found"
                        onClick={this.addAdminOrAgency}
                        buttonText="Users"
                      />
                    ),
                  }}
                  rowClassName="data-row"
                  loading={adminTableLoader}
                />
                <AdminModal page={this.state.page} pageSize={this.state.pageSize}/>
              </TabPane>
              <TabPane tab={<span style={{fontWeight:"bold"}}>Agency</span>} key="Agency">
                <Table
                  columns={agencyColumns(props).map((col) => ({
                    ...col,
                    align: "left",
                   
                  }))}
                  scroll={{y:450,x:1000}}
                  pagination={{
                    position: "bottomRight",
                    showSizeChanger: true,
                    pageSizeOptions: [10, 30, 50, 100],
                    pageSize: this.state.pageSize,
                    current: this.state.page,
                    total: meta?.totalItems
                    ?meta?.totalItems
                    : 0,

                    onChange: (page, pageSize) => {
                      this.handlePageChange(page, pageSize, "agency");
                    },
                    onShowSizeChange: this.handlePageChange,
                  }}
                  dataSource={agencies}
                  locale={{
                    emptyText: agencyTableLoader ? (
                      <div className="no-data-loader"></div>
                    ) : (
                      <EmptyTable
                        text="No Agencies Found"
                        onClick={this.addAdminOrAgency}
                        buttonText="Agency"
                      />
                    ),
                  }}
                  rowClassName="data-row"
                  loading={agencyTableLoader}
                />
                <AgencyModal />
                <AgencyViewModal />
              </TabPane>
            </Tabs>
            </div> 
          </div>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminAgency);
// pagination={{
//   showSizeChanger: true,
//   pageSizeOptions: [10, 30, 50, 100],
//   pageSize: info.itemPerPAge,
//   onChange: handlePageChange,
//   onShowSizeChange: handlePageChange,
//   total: info.totalItems,
//   current: parseInt(info.currentPage),
// }}
// const handlePageChange = (page, pageSize) => {
//   setPageChange(page);
//   setPageSize(pageSize);
// };
