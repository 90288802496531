import React, { useState, useEffect, useRef, useMemo } from "react";
import { CheckOutlined } from "@ant-design/icons";
import Webadaptor from "./webadaptor";
import { Card, Row, Col, Typography, Button, Form, Radio,Modal,Spin } from "antd";
import icon1 from "../../assets/brigosha_logo_1.png";
import stopWatch from "../../assets/ic-stop-watch.svg";
import Api from "services/Api";
import ReactHtmlParser from "react-html-parser";
import { useHistory, useParams } from "react-router";
import { candidateActions } from "./store";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import Notification from "services/Notification";
import moment from "moment";
import { authActions } from "pages/login/store";
import RoomClient from "./roomClient";
import { setUpChatSocket, setUpRoomClientSocket } from './utils';
import { doc } from "prettier";


const { Title } = Typography;
const CandidateMockTest = () => {
  let modalvisibility=true
  let history = useHistory();
  let roomClientAulasRecording = null;
  let broadcastHealthTimer = null;
  const [question, setQuestion] = useState("");
  const dispatch = useDispatch();
  const [pageLoad, setPageLoad] = useState(false)
  const[markreviewDisable,setMarkreviewDisable]=useState(false)
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnLoader1, setBtnLoader1] = useState(false);
  const [btnLoader2, setBtnLoader2] = useState(false);
  const [btnLoader3, setBtnLoader3] = useState(false);
  const [btnLoader4, setBtnLoader4] = useState(false);
  const [positive, setPositive] = useState(0)
  const [negative, setNegative] = useState(0)
  let [btnDisable, setBtnDisable] = useState(true)
  let [clearbtn, setClearbtn] = useState(true)
  const [indexed, setIndexed] = useState(0);
  let [options, setOptions] = useState({});
  const [spin, setSpin] = useState(true);
  const [loader, setLoader] = useState(false);
  let [id, setId] = useState(0);
  const [form] = Form.useForm();
  const { warnings, warningsReload } = useSelector((state) => state.auth)
  const [count, setCount] = useState(0);
   const [, updateState] = React.useState();
   const [sectionId,setSectionId]=useState("")
   const {candidateId} = useSelector(state => state.auth)
   const forceUpdate = React.useCallback(() => updateState({}), []);
  const [secQues,setSecQues] = useState([])
  const [candidatequestions,setCandidateQuestions] = useState([]);
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const [warningVal, setwarningVal] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(10);
  const [getMinutes, setGetMinutes] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const { candidatequestion } = useSelector((state) => {
    return state.userCandidate;
  });
  const { valued } = useParams()
  let stringId=""
  
  for (let i = valued.length - 1; i > 0; i--){
    if (valued[i] === "_") {
      break;
    }
    stringId += valued[i];
    
  }
  let candidatevalue=""
  for (let i = stringId.length-1; i >= 0; i--)
  {
    candidatevalue += stringId[i];    
  }
  let status = false
  const { submittedAnswers } = useSelector((state) => state.userCandidate);
  const { attempted, candidateInfo } = useSelector((state) => state.userCandidate);
  const { result } = useSelector((state) => state.userCandidate)

  const socketCallback = (event, data) => {
    console.log("ssssEvent",event)
    if (event==='muxError'){
        // console.log('muxError ', data);
        // // if(thiz.audioOn)
        // // if(thiz.screenON)
        // roomClient?.closeProducer('screenType');
        // setTimeout(()=>{
        //     roomClient?.closeProducer('audioType');
        // }, 1001)
        // setTimeout(()=>{
        //     roomClient?.produce('audioType')
        // },2000)
        // this.produce("audioType")
        setPageLoad(false)
    }else if(event==='connect'){
        clearTimeout(broadcastHealthTimer)
        setPageLoad(false)
    }
    else if(event==='disconnect'){
        console.log('19x socket is disconnected');
        broadcastHealthTimer = setTimeout(() => {
            // if (window.location.pathname==='/broadcast'){
            //   Notification.error("", "Socket disconnected, join again to resume the class")
            //   history.goBack();
            // }
            console.log("19x exitRoom called after 10 sec");
          }, 10000);
        setPageLoad(false)
    }
  }

  const recordingCallback = async (event, dataReceived) => {
    // console.log("ssssssEventtttttt318eee", event, dataReceived)
    let shareType = ''; 

    if (event==='rtmpStarted'){
      setPageLoad(true);
    }else if(event==='rtmpRemoved'){
      console.log("RtmtRemoved", )
        setTimeout(()=>{
            roomClientAulasRecording?.closeProducer('audioType');
        }, 1000);
        setPageLoad(false)
    }else if(event==='audioAdded'){
      shareType = dataReceived; 
        // console.log("439", shareType)
        // if (shareType==='screen'){
            roomClientAulasRecording.produce('screenType')
        // }else{
            // roomClientAulasRecording.produce('videoType')
            // setPageLoad(true)
        // }
    }
    else if(event === "Transport connected"){
      roomClientAulasRecording.produce("audioType");
    }
  }

  const handleSubmit = (e) => {
    Modal.confirm({
      title: 'Are you sure,your entire test will get submitted',
      onOk() {
        setBtnLoader4(true);
        Api.post(`/hiring-tests/submitTest/?candidateId=${candidateId}`).send((response) => {
          if (response?.show?.type === "success") {
            dispatch(candidateActions.updateResult(response));
          }   
          roomClientAulasRecording?.closeProducer('screenType')
          Api.post("/hiring-candidate/saveRoomIdOfCand")
          .params({
            roomId:valued,
            candidateId:candidateId
          })
          .send((res)=>{
            console.log("ssResponse",res);
          })
          history.push(`/thank-you/${status}`)
          dispatch(authActions.updateWarningLoad({ warningsReload: "reset" }));
          setTimeout(()=>{
            window.location.reload();
          }, 10000)
          setBtnLoader4(false);
        });
      }
    });
  };

  useEffect(()=>{
    // if (roomClientAulasRecording === null) {
      roomClientAulasRecording = new RoomClient({ 
      // room_id: "198_random", 
      room_id:valued,
      refer:false, 
      user:candidateInfo, 
      name: candidateInfo.name, 
      user_id:candidateId, 
      socket:setUpRoomClientSocket("/aulasrecordingdev.io", history, socketCallback), 
      // "/aulasstudent.io"
      eventCallback:recordingCallback, setStreamCallback: ()=>{}
      })         
    // }
    return ()=>{
          roomClientAulasRecording?.closeProducer('screenType');
          Api.post("/hiring-candidate/saveRoomIdOfCand")
          .params({
            roomId:valued,
            candidateId:candidateId
          })
          .send((res)=>{
            // console.log("ssResponse",res);
          })
        }
  },[])
  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // console.log("ssssBeforeload",event)
      dispatch(authActions.updateWarningLoad({ warningsReload: "add" }));
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave? Your progress will be lost.";
      return "Are you sure you want to leave? Your progress will be lost."
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // console.log("ssssWArnigPayload",warningsReload)

  useEffect(()=>{
    if(warningsReload > 3){
        Api.post(`/hiring-tests/submitTest/?candidateId=${candidateId}`).send((response) => {
          if (response?.show?.type === "success") {
            dispatch(candidateActions.updateResult(response));
          }
          roomClientAulasRecording?.closeProducer('screenType');
          Api.post("/hiring-candidate/saveRoomIdOfCand")
          .params({
            roomId:valued,
            candidateId:candidateId
          })
          .send((res)=>{
            console.log("ssResponse",res);
          })
          history.push(`/thank-you/${status}`);
          dispatch(authActions.updateWarningLoad({ warningsReload: "reset" }));
          setTimeout(() => {
            window.location.reload();
          }, 10000);
          errorLog(parseInt(candidatevalue), "reloaded the page");
        });
      }
  },[warningsReload])

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
        }
      })
      .catch((error) => {
        console.error('Error accessing webcam:', error);
      });

    return () => {
      if (videoRef && videoRef.srcObject) {
        const tracks = videoRef.srcObject.getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (videoRef && videoRef.srcObject) {
        const tracks = videoRef.srcObject.getTracks();
        tracks.forEach(track => track.stop());
      }
    });

    return () => {
      unlisten();
    };
  }, [history, stream]);

  // console.log("ssssPageLoad",pageLoad, stream)

  const VideoModal = useMemo(() => {
    return (
      <div>
        {stream &&
          <video
            autoPlay
            playsInline
            muted 
            style={{ width:"134px", height: "6rem",marginLeft:"1rem" }}
            // src={URL.createObjectURL(stream)}
            ref={(videoRef) => {
              if (videoRef) {
                videoRef.srcObject = stream;
              }
            }}
          ></video>
        } 
      </div>
    );
  }, [stream]);

   useEffect(() => {
        window.history.pushState(
      { name: "browserBack" },
      "on browser back click",
      window.location.href,
      
    );
    
    window.history.pushState(
      { name: "browserBack" },
      "on browser back click",
      window.location.href,
      
    );
    window.history.pushState(
      { name: "browserBack" },
      "on browser back click",
      window.location.href
    );
    
    window.onpopstate = _backConfirm
   
    setTimeout(() => {
          // if (adapter != null) {
        // adapter.listen();
        // adapter.setWebsocket();
    
      // }
      console.log("ssssSocketttt",)
      
    },1500)
      
   }, [])

  // const callback = (obj) => {    
  //   if (obj.command === "audioAdded") {
  //     // adapter.publish();
  //     setPageLoad(true)
      
  //   } else if (obj.command === "webSocketConnected") {
  //     // adapter.toggle("camera+screen");     
  //   } else if (obj.command === "screenSharingStopped") {
  //     Notification?.warning("Error", "unexpected behavior", obj?.command);
  //     errorLog(parseInt(candidatevalue), obj?.command,"reload")
  //      setPageLoad(false)
  //     // adapter.closeConnection()
  //   }
  //   else if (obj.command === "error") {
  //     Notification?.warning("unexpected behavior", obj?.error);
  //     errorLog(parseInt(candidatevalue), obj?.error,"reload")
  //     setPageLoad(false)
  //     // adapter.closeConnection()
  //   } else if (obj.command == "connectionInterrupted") {
  //     // if (adapter != null) {
  //     //   errorLog(parseInt(candidatevalue), obj?.error,"reload")
  //     //   adapter.closeConnection();
  //     // }
  //   }
    
  // };
  //   const [adapter, setAdapter] = useState(
  //       new Webadaptor({ streamId: valued, canvasId: "myCanvas2", callback })
  // );
  
    useEffect(() => {
    Api.get(`/hiring-tests/getTestInstructions?candidateId=${candidateId}`).send((response) => {
      if(response?.show?.type === "error"){
        roomClientAulasRecording?.closeProducer('screenType')
          history.push(`/thank-you/${status}`)
          dispatch(authActions.updateWarningLoad({ warningsReload: "reset" }));
          setTimeout(()=>{
            window.location.reload();
          }, 10000)        
      }else{
      dispatch(candidateActions.updateTestInstruction(response));
      if(response?.candidates[0]?.candidateType === "Experienced"){
        setGetMinutes(response?.duration - 1)
      }
      else{
      var startTime = moment(moment().format("HH:mm"), "HH:mm");
      var endTime = moment(response?.endTime, "HH:mm");
      var duration = endTime.diff(startTime, 'minutes');          
      var minute = duration - 1
      setGetMinutes(minute)
      }
     }
    });
  }, []);

  useEffect(()=>{
    Api.get(`/hiring-poc/candidateStartTestTiming?candidateId=${candidateId}`)
       .send((res)=>{
        // console.log("ssssResponse",res)
       })
  },[])

  
    
  const errorLog = (id, value, option = null) => {
    
    Api.post("/hiring-poc/errLogs")
      .params({
        candidateId: id,
        errorlogMsg: value
      })
      .send((response) => {

        if (option === "reload") {
          location.reload(); 
        }
        
      })

  }
  
  const onFinish = (values) => {
    
  };
  window.addEventListener("load", function () {
    function updateOnlineStatus(event) {
      var condition = navigator.onLine ? "online" : "offline";

      
      let title = "internet disconnected ";
      let text = "plzz connect";
      if (condition === "offline") {
        Notification.error("Error", title, text);
      }
    }

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    
    
  });
  const { instruction } = useSelector((state) => {
    return state.userCandidate;
  });
   const warning = () => {
      // let currentWarning = warnings + 1
    if (warnings === 4) {
      return
    }
    Modal.warning({
      title: warnings - 1 === 0 ? `You don't have any chance remaining.` : `You have only ${warnings - 1} chance left`,
      content: "Do not change screen or test will get auto submitted."
    });
  };
 
  useEffect(() => {
    if (warnings <= 0) {
      errorLog(parseInt(candidatevalue), "tab switching limit exceeded")
      Api.post(`/hiring-tests/submitTest/?candidateId=${candidateId}`).send((response) => {
        if (response?.show?.type === "success") {
          dispatch(candidateActions.updateResult(response));
          // adapter.closeConnection()
        }   
        roomClientAulasRecording?.closeProducer('screenType')
        Api.post("/hiring-candidate/saveRoomIdOfCand")
          .params({
            roomId:valued,
            candidateId:candidateId
          })
          .send((res)=>{
            console.log("ssResponse",res);
          })
        history.push(`/thank-you/${status}`)
        dispatch(authActions.updateWarningLoad({ warningsReload: "reset" }));
        setTimeout(()=>{
          window.location.reload();
        }, 10000)
      });
    } else {
      if (!spin && count != 0) {
        warning();
      }
    }
  }, [warnings])
  const _backConfirm = async () => {
   
  
    let title = "you are not allowed to go back"
    Notification?.error("Error", title)
    
  };

  const checkTabChange = (spinValue) => {
    let timeout = null
    document.addEventListener('visibilitychange', () => {         
      setCount(count => count + 1);
      if (document.visibilityState === 'hidden') {
         dispatch(authActions.decrementWarning())            
        if(timeout){
          clearTimeout(timeout)         
        }
        timeout = setTimeout(() => {
          if (result?.score) {
             return
           }
          Api.post(`/hiring-tests/submitTest/?candidateId=${candidateId}`).send((response) => {
            if (response?.show?.type === "success") {
              dispatch(candidateActions.updateResult(response));
            }   
            roomClientAulasRecording?.closeProducer('screenType')
            Api.post("/hiring-candidate/saveRoomIdOfCand")
            .params({
              roomId:valued,
              candidateId:candidateId
            })
            .send((res)=>{
              console.log("ssResponse",res);
            })
            history.push(`/thank-you/${status}`)
            dispatch(authActions.updateWarningLoad({ warningsReload: "reset" }));
          setTimeout(()=>{
            window.location.reload();
          }, 10000)          
            errorLog(parseInt(candidatevalue),"15 Sec tab switch detected")
          });
        }, 15000)           
      } else {     
        clearTimeout(timeout)       
      }      
    });
  }

  useEffect(() => {
    if (pageLoad === true) {
      setSpin(true);
      setLoader(true);
     Api.get(`/hiring-tests/getQuestionsForCandidate?candidateId=${candidateId}`)
        .params({})
       .send((response,error) => {  
         setSpin(false);
         setLoader(false);
         setCount(0);        
         dispatch(authActions.incrementWarning());    
          if (response?.show?.type === "error") {
            errorLog(parseInt(candidatevalue), response?.show?.message)
            if (response?.show?.message === "Test already submitted") {            
              status = true              
              dispatch(authActions.removeUser());
              roomClientAulasRecording?.closeProducer('screenType')
              history.push(`/thank-you/${status}`)
              dispatch(authActions.updateWarningLoad({ warningsReload: "reset" }));
              setTimeout(()=>{
            window.location.reload();
          }, 10000)
          }
          // checkTabChange()      
          }else if (typeof response != "undefined") {
             checkTabChange()
            dispatch(
              candidateActions.updateQuestions({
                candidatequestion: response.sectionWiseQuestions,
              })
            );
            dispatch(
              candidateActions.updateSelectedAnswers(response.submittedAnswers)
            );
            setSectionId(response.sectionWiseQuestions[0].id)
            setCandidateQuestions(response.sectionWiseQuestions[0].question)
            setSelectedOption(response?.submittedAnswers[0]?.answer_candidateAnswer)
            dispatch(candidateActions.updateAttempt(response.data))
            errorLog(parseInt(candidatevalue), "questions Api hit properly")
          }       
        });
   }
 
 },[pageLoad]) 

  useEffect(() => {
    onClickbtn(0, candidatequestions[0]?.id);
  }, [candidatequestions[0]?.id]);


  const getApifun = () => {
    setLoader(true);
    Api.get(`/hiring-tests/getQuestionsForCandidate?candidateId=${candidateId}`)
      
      .send((response) => {
        
        
        if (typeof response != "undefined") {
          setLoader(false);
          dispatch(
            candidateActions.updateQuestions({
              candidatequestion: response.sectionWiseQuestions,
            })
          );
          dispatch(
            candidateActions.updateSelectedAnswers(response.submittedAnswers)
          );
          dispatch(candidateActions.updateAttempt(response.data))
          
        }
      })
  };

  
  useEffect(() => {
    if (getMinutes > 60) {
      const remainingMinutes = getMinutes % 60;
      setHours(Math.floor(getMinutes / 60));
      setMinutes(remainingMinutes);
      // setSeconds(remainingMinutes * 60);
    } else {
      setHours(0);
      setMinutes(getMinutes);
    }
  }, [getMinutes]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if(stream){
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      else {
        if (minutes > 0 && minutes < 60) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          if (hours > 0) {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          } else {
            clearInterval(myInterval);
            Api.post(`/hiring-tests/submitTest/?candidateId=${candidateId}`).send((response) => {
                  if (response?.show?.type === "success") {
                      dispatch(candidateActions.updateResult(response));
                      // adapter.closeConnection()
                    }   
                    roomClientAulasRecording?.closeProducer('screenType')
                    Api.post("/hiring-candidate/saveRoomIdOfCand")
                      .params({
                        roomId:valued,
                        candidateId:candidateId
                      })
                      .send((res)=>{
                        console.log("ssResponse",res);
                      })
                    history.push(`/thank-you/${status}`)
                    dispatch(authActions.updateWarningLoad({ warningsReload: "reset" }));
                    setTimeout(()=>{
                      window.location.reload();
                    }, 10000)                  
                  });
            }
        }
      }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  // console.log('sssssTimeeee',getMinutes,minutes,seconds,hours)

  let [selectedOption, setSelectedOption] = useState("");
  const [checked, setChecked] = useState(false);

  const onChange = (e) => {
    if (e.target.value)
    {
      setMarkreviewDisable(true)
    }
    else {
      setMarkreviewDisable(false)
    }
    setSelectedOption(e.target.value);
    setBtnDisable(false)
    setClearbtn(false)
    setChecked(true);
  };
  
  const onsaveandNext = () => {
    let valuess = candidatequestion?.find(element => element.id===sectionId);
    let lastQue = valuess?.question[valuess?.question?.length-1];
    let questionPlace = valuess?.question?.find(element => element.question === question)
    let sectionAddIndex = candidatequestion.findIndex(item => item.id === sectionId);
    
    let length=valuess?.question?.length

    setBtnLoader(true); 

    const values = {
      candidateId:candidateId,
      questionId: id,
      answer: selectedOption,
      submitStatus: "Attempted",
    };
    Api.post("/hiring-tests/submitAnswer")
      .params(values)
      .send((response) => {
        if (response?.type === "success") {
          // dispatch(candidateActions.updateAttempt(response.data));         
          if (length === indexed + 1 && lastQue?.id !== questionPlace?.id) {
            setBtnLoader(false);
            getApifun();
            return;
          }
          getApifun();
          if(lastQue?.id === questionPlace?.id){
            if (sectionAddIndex !== -1 && sectionAddIndex < candidatequestion.length - 1) {
              let sectionAdd = candidatequestion[sectionAddIndex + 1];
              setSectionId(sectionAdd.id)
              setCandidateQuestions(sectionAdd.question)
              setBtnLoader(false);
              getApifun();
              return;
            }            
          }
          onClickbtn(indexed + 1, candidatequestions[indexed + 1]?.id);
        }
        setBtnLoader(false);
      });
      
  };
  // console.log("ssssQuestion",sectionId,candidatequestion)

  const SaveAndMarkForReview = () => {
    let valuess = candidatequestion?.find(element => element.id===sectionId);
    let lastQue = valuess?.question[valuess?.question?.length-1];
    let questionPlace = valuess?.question?.find(element => element.question === question)
    let sectionAddIndex = candidatequestion.findIndex(item => item.id === sectionId);
    
    let length=valuess?.question?.length

    setBtnLoader3(true);

    const values = {
      candidateId:candidateId,
      questionId: id,
      answer: selectedOption,
      submitStatus: "Attempted and Marked",
    };
    Api.post("/hiring-tests/submitAnswer")
      .params(values)
      .send((response) => {
        if (response?.type === "success") {
          // dispatch(candidateActions.updateAttempt(response.data));
          if (length === indexed + 1&&lastQue?.id !== questionPlace?.id) {
            setBtnLoader3(false);
            getApifun();
            return;
          }
          getApifun();
          if(lastQue?.id === questionPlace?.id){
            if (sectionAddIndex !== -1 && sectionAddIndex < candidatequestion.length - 1) {
              let sectionAdd = candidatequestion[sectionAddIndex + 1];
              setSectionId(sectionAdd.id)
              setCandidateQuestions(sectionAdd.question)
              setBtnLoader3(false);
              getApifun();
              return;
            }            
          }
          onClickbtn(indexed + 1, candidatequestions[indexed + 1]?.id);
        }
        setBtnLoader3(false);
      });
      
  };
  const markForReview = () => {
    let valuess = candidatequestion?.find(element => element.id===sectionId);
    // let length=valuess?.question?.length
    let lastQue = valuess?.question[valuess?.question?.length-1];
    let questionPlace = valuess?.question?.find(element => element.question === question)
    let sectionAddIndex = candidatequestion.findIndex(item => item.id === sectionId);
    
    let length=valuess?.question?.length

    const values = {
      candidateId:candidateId,
      questionId: id,
      answer: selectedOption,
      submitStatus: "Unattempted and Marked",
    };
    setBtnLoader1(true);
    Api.post("/hiring-tests/submitAnswer")
      .params(values)
      .send((response) => {
        if (response?.type === "success") {
          // dispatch(candidateActions.updateAttempt(response.data));  
          if (length === indexed + 1 && lastQue?.id !== questionPlace?.id) {
            setBtnLoader1(false);
            getApifun();
            return;
          }
          getApifun();
          if(lastQue?.id === questionPlace?.id){
            if (sectionAddIndex !== -1 && sectionAddIndex < candidatequestion.length - 1) {
              let sectionAdd = candidatequestion[sectionAddIndex + 1];
              setSectionId(sectionAdd.id)
              setCandidateQuestions(sectionAdd.question)
              setBtnLoader1(false);
              getApifun();
              return;
            }            
          }
          onClickbtn(indexed + 1, candidatequestions[indexed + 1]?.id);
        }
        setBtnLoader1(false);
      });
  };
  
  const clearResponse = () => {
    setBtnLoader2(true);
    setMarkreviewDisable(false)
    setSelectedOption("");
    form.resetFields();
    const values = {
      candidateId:candidateId,
      questionId: id,
      answer: "",
      submitStatus: "Unattempted",
    };
    Api.post("/hiring-tests/submitAnswer")
      .params(values)
      .send((response) => {
        if (
          typeof response != "undefined" 
          // &&
          // response.show.type === "success"
        ) {
          // dispatch(candidateActions.updateAttempt(response.data));
          
          getApifun();
        }
        setBtnDisable(true)
        setBtnLoader2(false);
      });
  };

  const onClickbtn = (index, id) => {
    let clickValue=submittedAnswers.find((element) => {
      if (element.question_id === id) {      
        if (element.answer_submitStatus === "Unattempted and Marked") {
          setBtnDisable(true)
        }
        
      }
    
      return element.question_id === id
    })
   if(clickValue && clickValue?.answer_candidateAnswer!==null){
    setBtnDisable(false)
      setMarkreviewDisable(true)
      setClearbtn(false)
    }
    else{
      setBtnDisable(true)
      setMarkreviewDisable(false)
      setClearbtn(true)
    }
    form.resetFields();
    setChecked(false);
    const foundEl = submittedAnswers.find(
      (answer) => {        
        return id === answer.question_id}
    );
    if (foundEl) {   
      setSelectedOption(foundEl.answer_candidateAnswer);    
    } else {
      setSelectedOption("");     
    }

    candidatequestions.forEach((item) => {
      if (id == item.id) {
        setOptions(item.options);
        setPositive(item.positiveMark)
        setNegative(item.negativeMark)
        setIndexed(index);
        setQuestion(item.question);
        setId(id);
      }
    });
    // console.log("sssssIfdd",id)

    if(!foundEl && id !== undefined){
      const values = {
        candidateId:candidateId,
        questionId: id,
        answer: "",
        submitStatus: "Unattempted",
      };
      Api.post("/hiring-tests/submitAnswer")
        .params(values)
        .send((response) => {
          if (
            typeof response != "undefined" 
            // &&
            // response.show.type === "success"
          ) {
            // dispatch(candidateActions.updateAttempt(response.data));
            if (length === indexed + 1) {
              setBtnLoader3(false);
              getApifun();
              return;
            }
            getApifun();
            // onClickbtn(indexed + 1, candidatequestions[indexed + 1]?.id);
          }
          // setBtnLoader3(false);
        });
      }
  };
  const Buttons = candidatequestions?.map((item, index) => {
    let id = item.id;
    let color;

    const foundEl = submittedAnswers.find(
      (answer) => id === answer.question_id
    );
    // console.log("ssssElemnt",item,submittedAnswers,foundEl)
      
    if (!foundEl) {
      color = "grey";
    } else if (foundEl.answer_submitStatus === "Attempted") {
      color = "blue";
    } else if (foundEl.answer_submitStatus === "Attempted and Marked") {
      color = "green";
    } else if (foundEl.answer_submitStatus === "Unattempted and Marked") {
      color = "orange";
    } else if (foundEl.answer_submitStatus === "Unattempted") {
      color = "red";
    } else {
      color = "grey";
    }
    const backgroundColor = index === indexed ? "#cccccc" : "white";
    return (
      <>
        <Col span={6} style={{ marginBottom: "20px" }}>
          <Button
            size="large"
            shape="circle"
            style={{ borderColor: color, backgroundColor: color, color: "white" }}
            onClick={() => {
              
                onClickbtn(index, id)}
            }
          >
            {index + 1}
          </Button>
        </Col>
      </>
    );
  });

  let SectionwiseQuestions = candidatequestion?.find(element => element.id===sectionId);
  let lastQue = SectionwiseQuestions?.question[SectionwiseQuestions?.question?.length-1];
  let questionIndex = SectionwiseQuestions?.question?.findIndex(element => element.question === question)
  let sectionAddIndex = candidatequestion.findIndex(item => item.id === sectionId);

// console.log("sssssQuestionSectionnn",SectionwiseQuestions,lastQue,questionIndex,sectionAddIndex, sectionAddIndex === candidatequestion.length-1 && questionIndex === SectionwiseQuestions?.question?.length -1)
// sectionAddIndex === candidatequestion.length-1 && questionIndex === SectionwiseQuestions?.question?.length -1

  return (
    <>
    {pageLoad === true && stream !== null && (stream !== null && stream?.active !== false) ? <>
      {spin===true ? (
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",textAlign:"center",height:"90vh"}}>
        <Spin className="loader" style={{ fontSize: 40 }} />
        </div>
      ) : (
        <div
          style={{
            background: "#ececec",
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",          
          }}
        >
          <Form name="validate_other" onFinish={onFinish} form={form}>
            
              <div style={{backgroundColor: "#fff",width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",padding:"0.6rem 3rem 1rem 3rem", position: "sticky", top: "0", zIndex: "1000"}}>
                <div>
                  <img src={icon1} width="134px" height="42px" />
                </div>
                <div style={{ display: "flex", justifyContent: "Right",alignItems:"center" }}>
                      
                        <Button
                          loading={btnLoader4}
                          style={{backgroundColor:"white",color:"blue",borderColor:"blue"}}
                          onClick={handleSubmit}
                        // htmlType="submit"
                        >
                         <b>SUBMIT TEST</b> 
                        </Button>
                        {VideoModal}
                    </div>
              </div>
            
            <Row
              style={{
                backgroundColor: "#e6f2ff",
                // marginBottom: "10px",
                paddingTop: "5px",
              }}
            >
              <Col span={12}>
                  <Title
                    id="welcome"
                  style={{
                    marginLeft: "60px",
                    justifyContent: "flex-start",
                    textAlign: "start",
                  }}
                  level={3}
                >
                  WELCOME
                </Title>
              </Col>
              <Col span={4} offset={7}>
                <div style={{display:"flex",alignItems:"center",flexDirection:"column"}}>
                  <img src={stopWatch} width="20px" height="20px" />
                  {/* {minutes === 0 && seconds === 0 ? null : (
                    <h2>
                      {" "}
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </h2>
                  )} */}
                  {minutes === 0 && seconds === 0 ? null : hours > 0 ? (
                    <h2>
                      {hours} : {minutes} : {seconds < 10 ? `0${seconds}` : seconds}
                    </h2>
                  ) : (
                    <h2>
                    {" "}
                    {minutes} : {seconds < 10 ? `0${seconds}` : seconds}
                  </h2>
                  )}
                </div>
              </Col>
            </Row>
            <Row style={{width:"100%", borderBottom:"1px solid #AEAEAE", borderTop:"1px solid #AEAEAE" ,marginBottom:"1rem"}}>
                  {candidatequestion.map((element) =>{
                    let value=element.id
                    let color=sectionId===element.id?"#7FFFD4":"#AEAEAE"
                    return(
                      <>
                      <Button onClick={() =>{
                        setSectionId(element.id)                    
                     let sectionQues= candidatequestion.find(elements =>elements.id===value)           
                    setCandidateQuestions(sectionQues.question)
                  }
                   } style={{color:"black",backgroundColor:color,fontFamily:"Open Sans", fontSize:"1rem",minWidth:"30%"}}>{element.sectionTitle}</Button></>
                    )
                  })}
                 </Row>
              {loader ? 
              <div style={{display:"flex",flexDirection:"column",justifyContent:"center",textAlign:"center",height:"50vh"}}>
              <Spin className="loader" style={{ fontSize: 40 }} />
              </div>
              :
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",             
                }}
              >
                <Row gutter={16} style={{ display: "flex" }}>
                  <Col span={16}>
                    <Card
                      bordered={false}
                      style={{
                        minHeight: "75vh",
                        marginLeft: "20px",
                        borderRadius: "12px",
                      }}
                    >
                      <div className="displayanswer">
                        <Row>
                          <Col span={18}>
                            <h1>
                              <Title level={2}>{indexed + 1}</Title>
                            </h1></Col>
                          <Col span={6}><h3><b style={{ color: "green" }}>+{positive}</b>/{negative != 0 ? (<b style={{ color: "red" }}> -{negative}</b>) : (<b style={{ color: "red" }}> {negative}</b>)}</h3></Col></Row>
                        
                        <p style={{marginBottom:"1.5rem"}}>{ReactHtmlParser(question)}</p>
                      </div>
                      <div>
                        <Form.Item
                          name="radio-button"
                          rules={[
                            { required: true, message: "Please pick an item!" },
                          ]}
                        >
                          <Radio.Group
                            span={24}
                            size="large"
                            style={{
                              marginRight: "20px",
                              width: "100%",
                              height: "100%"
                            }}
                            onChange={onChange}
                          >
                            <Row style={{ marginBottom: "20px" }}>
                              <Col span={10}>
                                <Radio.Button
                                  value="A"
                                  style={{
                                    marginRight: "20px",
                                    width: "100%",
                                    height: "100%"
                                  }}
                                  checked={checked}
                                >
                                  <p>
                                    <b>A.</b><span style={{whiteSpace:"pre-line"}}> {options.A}</span>
                                    {selectedOption === "A" ? (
                                      <CheckOutlined
                                        style={{
                                          display: "flex",
                                          justifyContent: "  flex-end ",
                                          marginTop: "-26px",
                                          
                                          color: "green",
                                        }}
                                      />
                                    ) : null}
                                  </p>
                                </Radio.Button>
                              </Col>
                              <Col span={10} offset={2}>
                                <Radio.Button
                                  value="B"
                                  style={{ marginRight: "20px", width: "100%", height: "100%" }}
                                >
                                  <p>
                                    <b>B.</b><span style={{whiteSpace:"pre-line"}}> {options.B}</span>
                                    {selectedOption === "B" ? (
                                      <CheckOutlined
                                        style={{
                                          display: "flex",
                                          justifyContent: "  flex-end ",
                                          marginTop: "-26px",
                                          color: "green",
                                          
                                        }}
                                      />
                                    ) : null}
                                  </p>
                                </Radio.Button>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col span={10}>
                                <Radio.Button
                                  value="C"
                                  style={{ marginRight: "20px", width: "100%", height: "100%" }}
                                >
                                  <p>
                                    <b>C.</b> <span style={{whiteSpace:"pre-line"}}>{options.C}</span>
                                    {selectedOption === "C" ? (
                                      <CheckOutlined
                                        style={{
                                          display: "flex",
                                          justifyContent: "  flex-end ",
                                          marginTop: "-26px",
                                          color: "green",
                                          // fontWeight:"bold"
                                        }}
                                      />
                                    ) : null}
                                  </p>
                                </Radio.Button>
                              </Col>
                              <Col span={10} offset={2}>
                                <Radio.Button
                                  value="D"
                                  style={{ marginRight: "20px", width: "100%", height: "100%" }}
                                >
                                  <p>
                                    <b>D.</b> <span style={{whiteSpace:"pre-line"}}>{options.D}</span>
                                    {selectedOption === "D" ? (
                                      <CheckOutlined
                                        style={{
                                          display: "flex",
                                          justifyContent: "  flex-end ",
                                          marginTop: "-26px",
                                          color: "green",
                                        }}
                                      />
                                    ) : null}
                                  </p>
                                </Radio.Button>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </Card>
                    <br />
                    <Card
                      bordered={false}
                      style={{
                        height: "15vh",
                        marginLeft: "20px",
                        borderRadius: "12px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "-15px",
                          display: "flex",
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          loading={btnLoader}
                          onClick={onsaveandNext}
                          type="primary"
                          style={{
                            width: "150px",
                            height: "auto",
                            fontSize: "12px",
                          }}
                          disabled={btnDisable}
                        >
                          {(sectionAddIndex === candidatequestion.length-1 && questionIndex === SectionwiseQuestions?.question?.length -1) 
                          ? "SAVE" : "SAVE & NEXT"}
                        </Button>

                        <Button
                          // type="primary"
                            disabled={markreviewDisable}
                          loading={btnLoader1}
                          onClick={markForReview}
                          style={{
                            backgroundColor: "#FF5E0D",
                            borderColor: "orange",
                            color: "white",
                            width: "150px",
                            height: "auto",
                          }}
                        >
                          {(sectionAddIndex === candidatequestion.length-1 && questionIndex === SectionwiseQuestions?.question?.length -1) 
                           ?
                          <p style={{ marginBottom: "0", fontSize: "12px" }}>
                            MARK FOR REVIEW
                          </p>
                           :<>
                           <p style={{ marginBottom: "0", fontSize: "12px" }}>
                            MARK FOR REVIEW
                          </p>
                          <p style={{ marginBottom: "0", fontSize: "12px" }}>
                            {" "}
                            & NEXT
                          </p>
                          </>
                          }
                        </Button>

                        <Button
                          // htmlType="submit"
                          loading={btnLoader2}
                          onClick={clearResponse}
                          style={{
                            width: "150px",
                            height: "auto",
                            fontSize: "12px",
                          }}
                          disabled={btnDisable}
                        >
                          CLEAR RESPONSE
                        </Button>

                        <Button
                          onClick={SaveAndMarkForReview}                      
                          loading={btnLoader3}
                          style={{
                            backgroundColor: "#0CE873",
                            borderColor: "#0CE873",
                            color: "white",
                            width: "150px",
                            height: "auto",                        
                          }}
                          disabled={btnDisable}
                        >
                          <p style={{ marginBottom: "0", fontSize: "12px" }}>
                            SAVE & MARK FOR
                          </p>
                          <p style={{ marginBottom: "0", fontSize: "12px" }}>
                            REVIEW
                          </p>
                        </Button>
                      </div>
                    
                    </Card>
                  </Col>
                  <Col span={8} style={{ display: "flex" }}>
                    <Card
                      bordered={false}
                      style={{
                        height: "100%",
                        marginRight: "20px",
                        display: "flex",
                        borderRadius: "12px",
                      }}
                    >
                      <Row>
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#0086FF",
                              width: "38px",
                              height: "38px",
                              marginTop: "20px",
                              color: "white",                           
                              margin: "20px",
                            }}
                          >
                            {attempted.totalAttempted}
                          </div>
                          <p>Attempted</p>
                        </Col>

                        <Col span={8} offset={2}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#FF0D3B",
                              width: "38px",
                              height: "38px",
                              marginTop: "20px",
                              color: "white",                         
                              margin: "20px",
                            }}
                          >
                            {attempted.totalUnattempted}
                          </div>
                          <p>Unattempted</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "green",
                              width: "38px",
                              height: "38px",
                              marginTop: "20px",
                              color: "white",                         
                              margin: "20px",
                            }}
                          >
                            {attempted.totalAttemptedAndMarked}
                          </div>
                          <p>Attempted & Marked for Review</p>
                        </Col>

                        <Col span={8} offset={2}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "orange",
                              width: "38px",
                              height: "38px",
                              marginTop: "20px",
                              color: "white",                       
                              margin: "20px",
                            }}
                          >
                            {attempted.totalUnattemptedAndMarked}
                          </div>
                          <p>Unattempted & Marked for Review</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#FFFFFF",
                              width: "38px",
                              height: "38px",
                              marginTop: "20px",
                              border: " 1px solid #AEAEAE",
                              marginTop: "20px",
                            }}
                          >
                            {attempted.totalUnseen}
                          </div>
                          <p>Unseen</p>
                        </Col>
                      </Row>
                      <Title style={{ marginLeft: "22px" }} level={5}>
                        All Questions
                      </Title>
                      <Row>{Buttons}</Row>
                    </Card>
                  </Col>
                  <div>
       {/* {errorMsg && <div>{errorMsg}</div>} */}
      {/* )} */}
    </div>
                </Row>
                
              </div>
              }
          </Form>
        </div>
      )}</>
      : <div style={{height:"90vh",alignItems:"center",gap:"1rem",flexDirection:"column",justifyContent:"center",display:"flex",width:"100%"}}>
        <div style={{gap:"1.5rem",flexDirection:"column",justifyContent:"left",display:"flex",}}>
        <p style={{fontSize:17}}>Kindly allow the following three options to enter the test page:</p>
        <ol style={{display:"flex",gap:"0.5rem",flexDirection:"column",marginLeft:"-1.3rem",fontWeight:550,fontSize:15}}>
          <li>Camera <span style={{fontSize:14,fontWeight:400}}>(check your web camera permission)</span></li>
          <li>Microphone <span style={{fontSize:14,fontWeight:400}}>(Check your web microphone permission)</span></li>
          <li>Screen Sharing <span style={{fontSize:14,fontWeight:400}}>(share your screen)</span></li>
        </ol>
        <p style={{fontSize:14,fontWeight:450,marginTop:"-0.6rem"}}>Note: After changing the permissions in your browser settings, please reload the page to apply the updates.</p>
        </div>
        </div>
}
    </>
  );
};
export default CandidateMockTest;