import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Menu, Dropdown, Typography, Space, Input ,Checkbox, Modal, Spin  } from "antd"
import { ApiFilled, DownOutlined, SearchOutlined } from "@ant-design/icons";
import EmailIcon from "../../assets/emailTemp.png"
import CreateModal from "./create-modal";
import Api from '../../services/Api';
import './style.css'
import {useDispatch,useSelector} from 'react-redux';
import classes from './emailTemplate.module.css';
import {templateActions} from './store';
const {Text}=Typography;

const EmailTemplate = () => { 
  const [isModalVisible, setModalVisible] = useState(false);
  const [isUpdateModalvisible, setUpdateModalVisibility] = useState(false);
  const [modalType,setModalType] = useState("Add");
  const [templates,setTemplates] = useState([1]);
  const [selectedTemplates,setSelectedTemplate] = useState([])
  const [selectedObject,setSelectedObject] = useState();
  const [searchedText , setSearchedText] = useState("");
  const[deleteModal,setDeleteModal] =useState(false)
  const dispatch = useDispatch();
  const [loader,setLoader]=useState(true);
  const [editedTemp,setEditedTemp]=useState({edited:false,index:-1});
  const [editedIndex,setEditedIndex]=useState();
  const [btnLoader,setBtnLoader]=useState(false);
  const [createTempIndex,setCreateTempIndex]=useState(-1);
  
const [createContent, setCreateContent] = useState({
  type: "",
  subject: "",
  body: ""
});
 
console.log("sssSelet",selectedTemplates)
  useEffect(()=>{
    fetchTemplate();
    setSelectedTemplate([...selectedTemplates])
  },[searchedText])

  const fetchTemplate = () => {
    Api.get('/hiring-template/all').params({search : searchedText}).send(res => {
       if(res)
       {
        const tempArr=[1].concat(res);
            setTemplates(tempArr);
            dispatch(templateActions.updateTemplates(res))
            setLoader(false);
       }
    })
  }

  const DeleteApi = () =>{
    setBtnLoader(true);
    Api.delete('/hiring-template/remove',{ids : selectedTemplates}).send(res => {
      if(res?.show?.type === "success")
      {
        setSelectedTemplate([])
        fetchTemplate();
        setDeleteModal(false)
      }
      setBtnLoader(false);
    })
  }

  const bgColor=(index)=>{
    let color;
    if(index%4===0)
    {
        color='#FFF0F0';
    }
    else if(index%4===1)
    {
        color="#EAF5FF";
    }
    else if(index%4===2)
    {
        color="#F0FAF1";
    }
    else if(index%4===3)
    {
        color="#FFFBEC";
    }
    return color;
}

  const templateCard = (templateObj,index) => {
    return  (
            <div key ={templateObj.id}           
             onClick={() => { 
              setCreateTempIndex(index);
              setModalType("preview");
              setModalVisible(true);
              setSelectedObject(templateObj);
              setEditedIndex(index);
             
              }} 
              style={{backgroundColor:editedTemp.edited&&editedTemp.index===index?"#F9F9F9":'',}}
              >
               
                         <div style={{backgroundColor:bgColor(index),display:"flex",flexDirection:"column",padding:"1.5rem",justifyContent:"right",marginBottom:"1rem",borderRadius:"0.5rem",margin:"0.5rem",height:"13rem"}}>
                                    <div style={{justifyContent:"right",display:"flex",}}>
                                            <Checkbox value={templateObj.id} checked={selectedTemplates.includes(templateObj.id)}
                                                    onChange={(e)=> {
                                                      if(e.target.checked){
                                                        setSelectedTemplate([...selectedTemplates,templateObj.id])
                                                      } else{
                                                        setSelectedTemplate(selectedTemplates.filter(a => a!= templateObj.id))
                                                      }
                                                      setModalVisible(false);
                                                    }}
                                                    >
                                            </Checkbox>
                                    </div>
                                    <div >
                                              <img src={EmailIcon}/>
                                    </div>
                            </div>
                                    <div style={{display:"flex",flexDirection:"column",margin:"0.5rem"}}>               
                                                
                                                <Typography.Text style={{width: "300px",fontSize:"1.2rem",fontWeight:"700"}} ellipsis={{tooltip:true}}>{templateObj.subject}</Typography.Text>
                                                <span style={{fontWeight:"350"}}> To: {templateObj.type}</span>  
                                                {editedTemp.edited&&editedTemp.index===index&&<span style={{color:"#0086ff",fontSize:"13px",fontWeight:"400",fontStyle:"italic"}}>Edited*</span>}             
                                    </div>
                          
                </div>  
    )
  }
 
  const toggleModal = () => {
    setModalVisible(false);
    setSelectedObject(null);
  }

  const onSearch = (e) => {
    setSearchedText(e.target.value)
  }

  return (
    <>
          <div className={classes.header}>
                    <h1>Email Template</h1>
          </div>
          < div className={classes.container}>
                    <div>           
                              <Input 
                              size="medium" 
                              placeholder="Search"
                              allowClear={true} 
                              prefix={<SearchOutlined className="custom-search-icon"/>}
                              onChange={(e)=>{
                                onSearch(e);
                                setEditedTemp({edited:false,index:-1});
                              }}
                                  /> 
                               <div>        
                                          {
                                            selectedTemplates.length > 0 &&  <Button type="primary" onClick={()=>{
                                              setDeleteModal(true);
                                              setEditedTemp({index:-1,edited:false})                                           
                                            }}>Delete</Button>
                                          }
                              </div>
                    </div>
                    <div>
                                      {templates.map((item,index) => {
                                        if(index===0)
                                        {
                                          return(
                                            <div key={index} className={templates.length===1?`${classes.createCard} ${classes.createCard2}`:`${classes.createCard}` }>
                                                    <div>
                                                                 <img src={EmailIcon}/>
                                                      </div>
                                                      <div>
                                                              <h3>Create Email templates</h3>
                                                              <span>Create and save templates for every occasion</span>
                                                      </div>  
                                                      <div>                                          
                                                              <Button
                                                              style={{fontWeight:"700"}}
                                                              type="primary"
                                                                onClick={() => {                                                              
                                                                  setModalType("Add");
                                                                  setModalVisible(true);
                                                                  setEditedTemp({edited:false,index:-1});
                                                                  setCreateTempIndex(index);  
                                                                  setCreateContent({
                                                                    type: "",
                                                                    subject: "",
                                                                    body: ""
                                                                  })                                                                       
                                                                  setSelectedObject(undefined);                                                        
                                                                  //dispatch(templateActions.updateTemplates([]));                                                       
                                                                }}  
                                                              >CREATE
                                                              </Button>
                                                      </div>
                                      </div>                                
                                          )
                                        }
                                        return  templateCard(item,index)                     
                                      })
                                    }
                    </div>     

            <Modal open={deleteModal} onCancel={() => setDeleteModal(false)}footer={false} centered>
                      <div className={classes.deleteModal}>
                                  <span>Are you sure that you want to delete the template(s)?</span>
                                  <div>
                                              <Button type="primary" onClick={()=>DeleteApi()} loading={btnLoader}>Delete</Button>
                                              <Button onClick={()=>setDeleteModal()}>Cancel</Button>
                                  </div>                     
                      </div>
            </Modal>

            { isModalVisible &&
                  <CreateModal
                  createContent={createContent}
                  setCreateContent={setCreateContent}
                            // content={content}
                            // setContent={setContent}
                            createTempIndex={createTempIndex}
                            isModalVisible={isModalVisible}
                            close={toggleModal.bind()}
                            templateObj={selectedObject} 
                            id={selectedObject && selectedObject.id ? selectedObject.id: null}
                            refresh={fetchTemplate.bind()}
                            type={modalType}
                            editedIndex={editedIndex}
                            setEditedTemp={setEditedTemp}
                           
              />}
          </div>
    </>
  )
}
export default EmailTemplate;