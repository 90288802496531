import React, { useState } from "react";
import { Card,Form, Input,Row,Col,Radio,Button,DatePicker } from "antd";
import { mapDispatchToProps, mapStateToProps } from "../redux/container"
import { connect,useDispatch,useSelector } from "react-redux";
import {ArrowLeftOutlined } from "@ant-design/icons";
import { HrFeedbackActions } from "./store";
import { DeleteOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import moment from "moment";
const OfferDetails = (props) => {
    const [offer, setOffer] = useState(false)
      const { feedback } = useSelector((state) => state.candidate.feedbackCandidateInfo)
  let feedbackType = feedback.find((value) => value.roundType === "HR")
    const dispatch = useDispatch()
    const {basicInfo} = useSelector((state) => state.HrFeedback)
    const onFInish=(values) => {
              const value ={
                  ...values,
              'joineddate': values['joineddate']?.format('YYYY-MM-DD'),
              'joineddate2': values['joineddate2']?.format('YYYY-MM-DD'),
              }
              dispatch(HrFeedbackActions.updatefeedback(value))
              props.onNext("LocationDetails")
    }
    const goBack = () => {
        props.onBack("CompanyDetails")
    } 

    return(
        <>
        
        <Card title="Offer Details"style={{border:"black",width:"100%"}}>
        
        <Button type="text" onClick={goBack}><ArrowLeftOutlined /></Button>
            <Form onFinish={onFInish} layout="vertical">
                <Row><Col span={12} style={{width:"100%"}}>
                    <Form.Item
                    name="companynameoffered_1"
                    label={<b>Company Name</b>}
                    initialValue={feedbackType?.details?.companynameoffered_1 !=undefined ? feedbackType?.details?.companynameoffered_1  : basicInfo?.companynameoffered_1}
                   
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="location"
                    label={<b>Location</b>}
                    initialValue={feedbackType?.details?.location !=undefined && !basicInfo?.location ? feedbackType.details.location: basicInfo?.location}
                   
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="joineddate"
                            label={<b>Joined Date</b>}
                            
                            initialValue={feedbackType?.details?.joineddate !=undefined && !basicInfo?.joineddate ? moment(feedbackType?.details?.joineddate,"YYYY-MM-DD") : basicInfo?.joineddate ?
                                 moment(basicInfo?.joineddate,"YYYY-MM-DD") : null}
                             >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="offeredCTC"
                            label={<b>Offered CTC</b>}
                            initialValue={feedbackType?.details?.offeredCTC!=undefined && !basicInfo?.offeredCTC ? feedbackType?.details?.offeredCTC :  basicInfo?.offeredCTC}
                           
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                   
                    
 
            
            
            <Row>
            <Button onClick={() => {
        setOffer(true)
    }}><PlusCircleTwoTone/></Button>
    
   
    </Row>
    {offer===true ? <> 
        <Button type="text" onClick={()=>{setOffer(false)}} style={{color:'red'}}><DeleteOutlined/></Button>
            
                <Row><Col span={12}>
                    <Form.Item
                    name="companynameoffered_2"
                    label={<b>Company Name</b>}
                    initialValue={feedbackType?.details?.companynameoffered_2!=undefined && !basicInfo?.companynameoffered_2 ? feedbackType.details.companynameoffered_2: basicInfo?.companynameoffered_2}
                   
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="location2"
                    label={<b>Location</b>}
                    initialValue={feedbackType?.details?.location2 !=undefined && !basicInfo?.location2 ? feedbackType?.details?.location2  :basicInfo?.location2}
                   
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="joineddate2"
                            label={<b>Joined Date</b>}
                            
                            initialValue={feedbackType?.details?.joineddate2 !=undefined && !basicInfo?.joineddate2 ? moment(feedbackType?.details?.joineddate2,"YYYY-MM-DD") : basicInfo?.joineddate2 ?
                                 moment(basicInfo?.joineddate2,"YYYY-MM-DD") : null}
                             >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="offeredCTC2"
                            label={<b>Offered CTC</b>}
                            initialValue={feedbackType?.details?.offeredCTC2 !=undefined && !basicInfo?.offeredCTC2 ? feedbackType?.details?.offeredCTC2 : basicInfo?.offeredCTC2}       
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                   
                    
 
           
           
    </> : ""}
    <Row justify="end" marginTop="10px"><Button size="large" style={{paddingLeft:"50px",paddingRight:"50px"}} type="primary" htmlType="submit">Save And Next</Button></Row>
    </Form>
    </Card>
    </>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails)