import { createSlice } from "@reduxjs/toolkit";
// import store from "pages/dashboard-home/store";
const initialState = {
    edit:false,
    panelistPool:{},
    panelistId:0,
    
};
const panelistSlice = createSlice({
  name: "panelist",
  initialState,
  reducers: {
    updateEdit(state,action)
    {
        state.edit=action.payload;
    },
    updatePanelistPool(state,action){
        state.panelistPool=action.payload;
    },
    updatePanelistId(state,action){
        state.panelistId=action.payload;
    }
    ,
  },
});
export default panelistSlice.reducer;
export const panelistActions = panelistSlice.actions;


// const panelistSlice = createSlice({
//   name: "panelist",
//   initialState,
//   reducers: {
//     updateEdit(state,action)
//     {
//         state.edit=action.payload;
//     },
//     updatePanelistPool(state,action){
//         state.panelistPool=action.payload;
//     },
    
//   },
// });
// export default panelistSlice.reducer;
// export const panelistActions = panelistSlice.actions;
