import React, { createRef, useState, useEffect } from "react";
import { Card, Form, Input, Row, Col, Radio, Button,InputNumber } from "antd";
import { mapDispatchToProps, mapStateToProps } from "../redux/container";
import { connect, useDispatch, useSelector } from "react-redux";
import AcademicDetails from "./academicDetails";
import { HrFeedbackActions } from "./store";
const BasicInfo = (props, card) => {
  const [loader, setLoader] = useState(false);
  const [married, setMarried] = useState("");
  const { basicInfo } = useSelector((state) => state.HrFeedback)
  const { feedback } = useSelector((state) => state?.candidate?.feedbackCandidateInfo)
  let feedbackType = feedback?.find((value) => value.roundType === "HR")
  const dispatch = useDispatch();
  const onFinish = (values) => {
    dispatch(HrFeedbackActions.updatefeedback(values));
    props.onNext("AcademicDetails");
  }; 
  const onChange = (e) => {
    if (e.target.value === "married") {
      setMarried("married");
    } else {
      setMarried("unmarried");
    }
  };
  return (
    <>
      <Card
        title="Basic Information"
        style={{ border: "black",width:"100%" }}
       
      >
        <Form onFinish={onFinish} layout="vertical">
          <Row>
            <Col span={12}>
             
              <Form.Item
                name="Mother"
                label={<b>Mother</b>}
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={feedbackType !==undefined && !basicInfo.Mother ? feedbackType.details.Mother :  basicInfo?.Mother}
              >
                <Input
                  
                  bordered={false}
                  style={{ borderBottom: "2px  solid grey" }}
                />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item
                name="Father"
                label={<b>Father</b>}
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={feedbackType !==undefined && !basicInfo?.Father ? feedbackType?.details.Father :  basicInfo?.Father}
              >
                <Input
                  
                  bordered={false}
                  style={{ borderBottom: "2px  solid grey" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="BrotherOrSister" label={<b>Brother / Sister</b>} initialValue={feedbackType?.details?.BrotherOrSister!==undefined   ? feedbackType?.details?.BrotherOrSister :  basicInfo?.BrotherOrSister ? basicInfo?.BrotherOrSister : ""}>
                <Input
                  bordered={false}
                  
                  style={{ borderBottom: "2px solid grey" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="Relationship"
                label={<b>Relationship</b>}
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={feedbackType!==undefined && !basicInfo.Relationship ? feedbackType.details.Relationship : basicInfo?.Relationship }
              >
                <Radio.Group  onChange={onChange}>
                  <Radio value="married">Married</Radio>
                  <Radio value="unmarried">Unmarried</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {(married === "married") || (married==="" && basicInfo.relationship==="married")  ? (
            <>
              <Col span={12}>
                <Form.Item
                  name="husbandOrWife"
                  label={<b>Spouse/Husband</b>}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={feedbackType?.details?.husbandOrWife && !basicInfo?.husbandOrWife ? feedbackType?.details?.husbandOrWife : basicInfo?.husbandOrWife}
                >
                  <Input
                   
                    bordered={false}
                    style={{ borderBottom: "2px  solid grey" }}
                  />
                </Form.Item>
              </Col>
              <Row>
                <Col span={24}>
                  <Form.Item name="Kid" label={<b>Kids</b>}initialValue={feedbackType?.details?.Kid!==undefined && !basicInfo.Kid ? feedbackType.details?.Kid:  basicInfo?.Kid}>
                    <Input
                    
                      bordered={false}
                      style={{ borderBottom: "2px  solid grey" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}

          <Row>
            <Col span={24}>
              <Form.Item name="hobbies" label={<b>Hobbies</b>}
              initialValue={feedbackType?.details?.hobbies!==undefined && !basicInfo?.hobbies ? feedbackType.details?.hobbies  :  basicInfo?.hobbies}>
                <Input
                
                  bordered={false}
                  style={{ borderBottom: "2px  solid grey" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row justify="end" marginTop="10px">
              <Button
                loading={props.feedbackLoader}
                size="large"
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
                type="primary"
                htmlType="submit"
              >
                Save And Next
              </Button>
            </Row>
          </Form.Item>
          
        </Form>
      </Card>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
