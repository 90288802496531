import classes from './home.module.css';
import { Radio } from 'antd';
import { useState } from 'react';
import Response from './response';

const FeedbackResponse=()=>{
    const [feedbackType,setFeedbackType]=useState('Panelist');
    const [startDate,setStartDate]=useState("");
    const [endDate,setEndDate]=useState("");
    return(<>

                    <div style={{display:"flex",flexDirection:"column",marginLeft:"2%",marginTop:"1.4%",}}>
                        <h1 style={{fontSize:"1.5rem",fontWeight:"700",margin:"0"}}>Feedback Response Table</h1>
                    </div>
                    <div style={{marginLeft:"2%",marginTop:"0.2rem"}}>
                                <Radio.Group bordered={false}
                                            onChange={(e)=>{                   
                                                setFeedbackType(e.target.value);
                                                // setStartDate("");
                                                // setEndDate("");
                                            }}
                                            value={feedbackType}
                                            style={{display:"flex",flexDirection:"row",gap:"1rem",fontWeight:"700",marginBottom:"0.5rem"}}
                                            >
                                            <Radio value="Panelist" 
                                                style={{color:feedbackType==="Panelist"?"#0086FF":"#161616",fontSize:"1.1rem"}}>Panelist Feedback Response</Radio>
                                            <Radio value="Candidate"
                                            style={{color:feedbackType==="Candidate"?"#0086FF":"#161616",fontSize:"1.1rem"}}>Candidate Feedback Response</Radio>
                                </Radio.Group>
                                {feedbackType==='Panelist'&&<Response feedbackType={feedbackType} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>}
                                {feedbackType==='Candidate'&&<Response feedbackType={feedbackType} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>}
                    </div>
          </>
    )
}
export default FeedbackResponse;