import {
  CopyOutlined,
  EyeOutlined,
  FilePdfOutlined,
  StopOutlined,
  CalendarOutlined,FilterOutlined,
} from "@ant-design/icons";
import {
  Popover,
  Space,
  Table,
  Tabs,
  Tooltip,
  Modal,
  Spin,
  Dropdown,
  Button,
  Checkbox,
  Select,
  Badge,
  Typography,Input, Pagination,
} from "antd";
import React from "react";
import { connect, useSelector } from "react-redux";
import { Color, EmptyTable } from "services";
import { IconButton } from "services/button";
import {
  RxClock,
  HiDotsVertical,
  AiOutlineCalendar,
  AiFillFile
} from "services/reactIcons";
import "services/styles.css";
import { CANDIDATE_DETAILS, operationType, inputFieldType, dateFormat, } from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import "./styles.css";
import classes from "./newCandidate.module.css";
import ResumeIcon from "../../assets/ResumeIconss.svg";
import { userContent } from "services";
import ViewPDF from "./viewPDF";
import DuplicateViewModal from "./duplicateViewModal";
import { filterStatusMenu,filterHackathonStatusMenu,
  rangePickerMenu,
  timelineContent, } from "./shortlistedCandidate";
import moment from "moment";
import AddProfileModal from "./addProfileModal";
// import ScheduleInterview from "./scheduleInterview"
import profileIcon from '../../assets/profileIcon.svg.svg'
import store from "store";
// import candidate_img from '../../assets/candidateIcon.jpg'

// performOperation,
// updateInputField,
// getDuplicateCandidates,
// candidateScreenType,
// currentRound,
// user,


const statusMenu = [
  { key: 1, label: "Not Selected" },
  { key: 2, label: "hold" },
  { key: 3, label: "Not Interested" },
  { key: 4, label: "In Progress" },
  { key: 5, label: "Skip Current Round" },
  { key: 6, label: "View Feedback" },
  { key: 7, label: "Reschedule" },
  { key: 8, label: "Rediscussion" },
];

const newCandidateColumns = (props) => {
  const {user} = store.getState().auth
  const columns =[]
  if(props.candidateScreenType === "New" || props.candidateScreenType === "Ongoing" || props.candidateScreenType === "Rejected"){
    
    const columns = [
      {
        title: <h3 style={{ fontWeight: "bold", marginLeft:"1rem"}}>Name</h3>,
        dataIndex: "name",
        key: "name",
        align: "left",
        width: "16rem",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div>
              {record.photo ? (
                <img
                  src={record.photo}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "1rem",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <img
                  src={profileIcon}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "1rem",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-start",
                marginLeft: "1rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <Popover content={userContent(record)}>
                    <a
                      style={{ fontWeight: "500" }}
                      onClick={() => {
                        props.updateInputField(CANDIDATE_DETAILS, record);
                        props.performOperation(
                          operationType.CANDIDATE_VIEW_MODAL,
                          true
                        );
                      }}
                    >
                     <Typography.Text ellipsis={{tooltip:false}} style={{width:"6.5rem"}}> {record.name}</Typography.Text>
                    </a>
                  </Popover>
                </div>
                <Space size="large">
                  <div>
                    {record.blacklist ? (
                      <Tooltip title="Blacklist">
                        <StopOutlined
                          style={
                            record.blacklist
                              ? { color: Color.black }
                              : { color: Color.midGrey }
                          }
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    {record.duplicate ? (
                      <Tooltip title="Duplicate">
                        <CopyOutlined
                          style={
                            record.duplicate
                              ? { color: Color.red }
                              : { color: Color.midGrey }
                          }
                          onClick={() => {
                            props.updateInputField(CANDIDATE_DETAILS, record);
                            props.getDuplicateCandidates();
                            
                          }}
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                </Space>
              </div>
              <div>
                <span style={{ fontSize: 10 }}>
                  <a
                    onClick={() => {
                      props.updateInputField(
                        inputFieldType.CANDIDATE_RESUME,
                        record.resume
                      );
                      props.performOperation(
                        operationType.CANDIDATE_PDF_VIEW_MODAL,
                        true
                      );
                    }}
                  >
                    <AiFillFile/>
                    Click to view Resume
                  </a>
                </span>
              </div>
            </div>
          </div>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>Experience</h3>,
        dataIndex: "experience",
        key: "experience",
        width: "10rem",
        align: "left",
        render: (text, record) => (
          <span style={{ fontSize: "13px" }}>
            {record.experienceYears} Y 
            {record.experienceMonths !== 0 ? `${record.experienceMonths} M` : ""}
          </span>
        ),
      },
      {
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>Notice Period (Days)</h3>,
        dataIndex: "noticePeriod",
        key: "noticePeriod",
        width: "12rem",
        align: "left",
        render: (text, record) => (
          <span style={{ textAlign:'left', fontSize: "13px" }}>{record?.noticePeriod}</span>
        ),
      },
      {
        width: "10rem",
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>Job Title</h3>,
        dataIndex: "jobs",
        key: "jobs",
        // align: "left",
        render: (text, record) => (
          <Popover content={record?.job?.title}>
            <Space size="small">
              <span style={{ fontSize: "13px" }}>{record?.job?.title}</span>
            </Space>
          </Popover>
        ),
      },
      {
        title: (
          <div style={{ display: "flex", alignItems: "center", justifyContent:'center' }}>
            <h3 style={{ fontWeight: "bold"}}> 
            <Space size="small"> 
            {/* <span> */}
              Date

              {/* <Dropdown
            visible={props.candidateDateDropdown}
            overlay={rangePickerMenu(props)}
            onClick={() =>
              props.updateInputField(
                inputFieldType.CANDIDATE_DATE_DROPDOWN,
                !props.candidateDateDropdown
              )
            }
            trigger={["click"]}
            id="date-dropdown"
          >
            <CalendarOutlined id="dateRange-calender" />
          </Dropdown> */}

          
            <Popover
              placement="bottom"
              content={rangePickerMenu(props)}
              trigger="click"
              visible={props.candidateDateDropdown}
              onVisibleChange={() =>{
                props.updateInputField(
                  inputFieldType.CANDIDATE_DATE_DROPDOWN,
                  !props.candidateDateDropdown
                )    
              }
              }
            >
            <CalendarOutlined id="dateRange-calender" />
           </Popover>
          {/* </span> */}
          </Space>
        </h3>
            {/* <AiOutlineCalendar /> */}
          </div>
        ),
        width: "10rem",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "left",
        render: (text) => (
          <span style={{ fontSize: "13px" }}>
            {moment(text).format(dateFormat)}
          </span>
        ),
      },
      {
        title: (
          <div>
            <Space size="small">
              <h3 style={{ fontWeight: "bold"}}>Hackathon Status</h3>
              <Dropdown
                visible={props.candidateHackathonStatusDropdown}
                overlay={filterHackathonStatusMenu(props)}
                onVisibleChange={(visible) =>
                  props.updateInputField(
                    inputFieldType.CANDIDATE_HACKATHON_STATUS_DROPDOWN,
                    visible
                  )
                }
                trigger={["click"]}
                id="status-dropdown"
              >
                <FilterOutlined style={{color:Color.modalblue}} />
              </Dropdown>
            </Space>
            {Array.isArray(props.candidateFilterHackathonStatus) ? props?.candidateFilterHackathonStatus?.length > 0 && props.candidateFilterHackathonStatus.map((item, index) => {
                return (
                    <h3 style={{ color: Color.modalblue, textAlign: "center" }}>
                        {item === "New" ? "Not applicable" : item}
                        {index === props.candidateFilterHackathonStatus.length - 1 ? "" : ","}
                    </h3>
                )
            }) : 
            <h3 style={{ color: Color.modalblue, textAlign: "center" }}>
               {props?.candidateFilterHackathonStatus}
            </h3>}
          </div>
        ),
        dataIndex: "hackathonStatus",
        key: "hackathonStatus",
        width: "12rem",
        render: (text, record) => (
          <div>
              {/* <Badge
                style={{ cursor: "pointer",widht:"100%" }}
                status={badgeHash[record?.hackathonStatus]} */}
            <span style={{fontSize:"14px"}}>{record?.hackathonStatus === "New" ? "Not applicable" : record?.hackathonStatus}</span>
                {/* <div style={{ fontSize: "14px", marginLeft: "5px" }}>
                Round {record.currentRound}
              </div> */}
          </div>
        ),
      },
      {
        width: "10rem",
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>Hackathon Score</h3>,
        dataIndex: "score",
        key: "score",
        render: (text, record) => (
          <Popover content={record?.test === null ? "NA" : record?.test?.candidateTotal}>
          <Space size="small">
          <span style={{ fontSize: "13px" }}>
          {record?.test !== null && record?.test !== undefined && record?.test?.candidateTotal !== null ? record?.test?.candidateTotal
        : "NA" 
        }</span></Space>
        </Popover>
        ),
      },
    ];
  
    if (user?.role?.includes("Agency") || user?.role.includes("Sourcer") || user?.role.includes("HR") || 
    user?.role.includes("Recruiter") ||user?.role.includes("Admin")|| user?.role.includes("Super-Admin")
    // ||!user?.role?.includes("Shortlister") || !user?.role?.includes("Panelist") || user?.role.length > 1
    ) {    
        columns.push({
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>Agency</h3>,
        dataIndex: "agency",
        key: "agency",
        width: "10rem",
        align: "left",
        render: (agency, record) => (
          <span style={{ fontSize: "13px" }}>{agency?.agencyName}-{ record?.user?.name}</span>
        ),
      });
    }
  
    if (props.candidateScreenType === "New") {
      columns.push({
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>View Candidate</h3>,
        dataIndex: "view",
        key: "view",
        width: "10rem",
        align: "left",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Tooltip title="View Profile">
                <span>
                  <IconButton
                    onClick={() => {
                      props.updateInputField(CANDIDATE_DETAILS, record);
                      props.updateInputField(
                        inputFieldType.CANDIDATE_RESUME,
                        record.resume
                      );
                      props.performOperation(operationType.CANDIDATE_VIEW_MODAL, true);
                    }}
                    icon={<EyeOutlined />}
                    className="editIcon"
                    id={`viewProfile${record.key}`}
                  />
                </span>
              </Tooltip>
              <Tooltip title="View Resume">
                <span>
                  <IconButton
                    icon={<FilePdfOutlined />}
                    className="editIcon"
                    onClick={() => {
                      props.updateInputField(
                        inputFieldType.CANDIDATE_RESUME,
                        record.resume
                      );
                      props.performOperation(
                        operationType.CANDIDATE_PDF_VIEW_MODAL,
                        true
                      );
                    }}
                    id={`viewResume${record.key}`}
                  />
                </span>
              </Tooltip>
            </Space>
          );
        },
      });
    }
  
    if (props.candidateScreenType === "Ongoing" || props.candidateScreenType === "Rejected") {
      if (props.candidateScreenType === "Ongoing") {
        columns.splice(5, 0, {
          title: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3 style={{ fontWeight: "bold" }}>Shortlisted (Time Stamp)</h3>
            </div>
          ),
          dataIndex: "shortlistedTime",
          key: "shortlistedTime",
          width: "15rem",
          align: "left",
          render: (text, record) => {
            return <Space size="middle">{record.shortlistedDate}</Space>;
          },
        });
      }
  
      columns.push(
        {
          title: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3 style={{ fontWeight: "bold" }}>Status</h3>
              <Select
                placeholder="All"
                showArrow={true}
                showSearch={false}
                style={{
                  width: "100%",
                  fontSize: "15px",
                  overflow: "hidden",
                  boxShadow: "none",
                  background: Color.lightGrey,
                }}
                bordered={false}
                // open={true}
              >
                {statusMenu.map((item, i) => {
                  <Option key={i} style={{ width: "200%",}} value={item.label}>
                    <Tooltip title={item.label}> {item.label}</Tooltip>
                  </Option>;
                })}
              </Select>
            </div>
          ),
          dataIndex: "allStatus",
          key: "status",
          width: "10rem",
          align: "left",
  
          render: (text, record) => {
            return (
              <Space size="middle">
                <Tooltip
                  title="Scheduled"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span style={{ color: Color.black }}>Scheduled</span>
                  <div style={{ fontSize: "12px", display: "flex" }}>
                    <Badge
                      size="default"
                      style={{ width: "1rem", height: "1rem" }}
                      color="#faad14"
                    />
                    Round
                    <span style={{ marginLeft: "1rem" }}>
                      {record.currentRound}
                    </span>
                  </div>
                </Tooltip>
              </Space>
            );
          },
        },
        props.candidateScreenType === "Ongoing"
          ? {
              title: <h3 style={{ fontWeight: "bold" }}>HR FEEDBACK</h3>,
              dataIndex: "hrFeedback",
              key: "hrFeedback",
              width: "10rem",
              align: "left",
              render: (text, record) => {
                return (
                  <Button
                    style={{
                      width: "5rem",
                      height: "2rem",
                      background: Color.midGrey,
                      borderRadius: "5px",
                    }}
                  >
                    View
                  </Button>
                );
              },
            }
          : "",
        {
          title: <h3 style={{ fontWeight: "bold" }}>ACTIONS</h3>,
          dataIndex: "actions",
          key: "actions",
          width: "7rem",
          align: "left",
          render: (text, record) => {
            return (
              <div>
                <Button type="text" onClick={()=> props.performOperation(operationType.INTERVIEW_MODAL, true)} icon={<RxClock />} />
                <Button icon={<HiDotsVertical />} />
              </div>
            );
          },
        }
      );
    }
    return columns
  }

  if(props.candidateScreenType === "History" || props.candidateScreenType == "Duplicate"){
    const columns =[
    {
      title: <h3 style={{ fontWeight: "bold", marginLeft:"1rem"}}>Name</h3>,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "15rem",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div>
            {record.photo ? (
              <img
                src={record.photo}
                style={{
                  width: "40px",
                  height: "40px",
                  marginLeft: "1rem",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                src={profileIcon}
                style={{
                  width: "40px",
                  height: "40px",
                  marginLeft: "1rem",
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-start",
              marginLeft: "1rem",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>
                <Popover content={userContent(record)}>
                  <a
                    style={{ fontWeight: "500" }}
                    onClick={() => {
                      props.updateInputField(CANDIDATE_DETAILS, record);
                      props.performOperation(
                        operationType.CANDIDATE_VIEW_MODAL,
                        true
                      );
                    }}
                  >
                    {record.name}
                  </a>
                </Popover>
              </div>
              <Space size="large">
                <div>
                  {record.blacklist ? (
                    <Tooltip title="Blacklist">
                      <StopOutlined
                        style={
                          record.blacklist
                            ? { color: Color.black }
                            : { color: Color.midGrey }
                        }
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {record.duplicate ? (
                    <Tooltip title="Duplicate">
                      <CopyOutlined
                        style={
                          record.duplicate
                            ? { color: Color.red }
                            : { color: Color.midGrey }
                        }
                        onClick={() => {
                          props.updateInputField(CANDIDATE_DETAILS, record);
                          props.getDuplicateCandidates();
                          
                        }}
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </Space>
            </div>
            <div>
              <span style={{ fontSize: 10 }}>
                <a
                  onClick={() => {
                    props.updateInputField(
                      inputFieldType.CANDIDATE_RESUME,
                      record.resume
                    );
                    props.performOperation(
                      operationType.CANDIDATE_PDF_VIEW_MODAL,
                      true
                    );
                  }}
                >
                  <AiFillFile/>
                  Click to view Resume
                </a>
              </span>
            </div>
          </div>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>EMAIL</h3>,
      dataIndex: "email",
      key: "email",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>
          {record.email}
        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>CONTACT</h3>,
      dataIndex: "phone",
      key: "phone",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>
          {record.phone}
        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>AGENCY</h3>,
      dataIndex: "agency",
      key: "agency",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>
       
        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>JOB TITLE</h3>,
      dataIndex: "jobTItle",
      key: "jobTItle",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>LOCATION</h3>,
      dataIndex: "location",
      key: "location",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    {
      title: (<div style={{ display: "flex", alignItems: "center" }}>
      <h3 style={{ fontWeight: "bold" }}>Status</h3>
      <Select
        placeholder="All"
        showArrow={true}
        showSearch={false}
        style={{
          width: "100%",
          fontSize: "15px",
          overflow: "hidden",
          boxShadow: "none",
          background: Color.lightGrey,
        }}
        bordered={false}
        // open={true}
      >
        {statusMenu.map((item, i) => {
          <Option key={i} style={{ width: "200%" }} value={item.label}>
            <Tooltip title={item.label}> {item.label}</Tooltip>
          </Option>;
        })}
      </Select>
    </div>),
      dataIndex: "jobTItle",
      key: "jobTItle",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>TOTAL EXPERIENCE</h3>,
      dataIndex: "totalExperience",
      key: "totalExperience",
      width: "12rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>CURRENT ORGANIZATION</h3>,
      dataIndex: "currentCompany",
      key: "currentCompany",
      width: "14rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>CURRENT CTC</h3>,
      dataIndex: "currentCtc",
      key: "currentCtc",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>EXPECTED CTC</h3>,
      dataIndex: "expectedCtc",
      key: "expectedCtc",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>NOTICE PERIOD (DAYS)</h3>,
      dataIndex: "noticePeriods",
      key: "noticePeriods",
      width: "13rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    {
      title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>COMMENTS</h3>,
      dataIndex: "comments",
      key: "comments",
      width: "10rem",
      align: "left",
      render: (text, record) => (
        <span style={{ fontSize: "13px" }}>

        </span>
      ),
    },
    
  ]

    return columns
  }

  if(props.candidateScreenType === "AssignedJD"){
    const columns=[
      {
        title: <h3 style={{ fontWeight: "bold", marginLeft:"1rem"}}>Name</h3>,
        dataIndex: "name",
        key: "name",
        align: "left",
        width: "11rem",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div>
              {record.photo ? (
                <img
                  src={record.photo}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "1rem",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <img
                  src={profileIcon}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "1rem",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-start",
                marginLeft: "1rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <Popover content={userContent(record)}>
                    <a
                      style={{ fontWeight: "500" }}
                      onClick={() => {
                        props.updateInputField(CANDIDATE_DETAILS, record);
                        props.performOperation(
                          operationType.CANDIDATE_VIEW_MODAL,
                          true
                        );
                      }}
                    >
                      {record.name}
                    </a>
                  </Popover>
                </div>
                <Space size="large">
                  <div>
                    {record.blacklist ? (
                      <Tooltip title="Blacklist">
                        <StopOutlined
                          style={
                            record.blacklist
                              ? { color: Color.black }
                              : { color: Color.midGrey }
                          }
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    {record.duplicate ? (
                      <Tooltip title="Duplicate">
                        <CopyOutlined
                          style={
                            record.duplicate
                              ? { color: Color.red }
                              : { color: Color.midGrey }
                          }
                          onClick={() => {
                            props.updateInputField(CANDIDATE_DETAILS, record);
                            props.getDuplicateCandidates();
                           
                          }}
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                </Space>
              </div>
              <div>
                <span style={{ fontSize: 10 }}>
                  <a
                    onClick={() => {
                      props.updateInputField(
                        inputFieldType.CANDIDATE_RESUME,
                        record.resume
                      );
                      props.performOperation(
                        operationType.CANDIDATE_PDF_VIEW_MODAL,
                        true
                      );
                    }}
                  >
                    <AiFillFile/>
                    Click to view Resume
                  </a>
                </span>
              </div>
            </div>
          </div>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>ASSIGNED JD</h3>,
        dataIndex: "comments",
        key: "comments",
        width: "7rem",
        align: "left",
        render: (text, record) => (
          <span style={{ fontSize: "13px" }}>
  
          </span>
        ),
      },
      {
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>PROPOSED JD</h3>,
        dataIndex: "comments",
        key: "comments",
        width: "7rem",
        align: "left",
        render: (text, record) => (
          <span style={{ fontSize: "13px" }}>
  
          </span>
        ),
      },
      {
        title: <h3 style={{ fontWeight: "bold",textAlign:'center' }}>SHORTLISTER COMMENT</h3>,
        dataIndex: "comments",
        key: "comments",
        width: "15rem",
        align: "left",
        render: (text, record) => (
          <span style={{ fontSize: "13px" }}>
  
          </span>
        ),
      },

    ]

    return columns
  }
  

  return columns;
};

class NewCandidate extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { page: 1, pageSize: 25, addprofileColumn: false,
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      }, };
  }
  
  componentDidMount() {
 
    window.addEventListener("resize", this.handleResize);

    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.handleResize);
  // }

  handleResize = () => {
    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };

  handlePageChange(page, pageSize, value) {
    this.props.updateInputField(inputFieldType.CANDIDATE_NEW_PAGE_NUMBER, page);

    this.setState({ page: page, pageSize: pageSize });
    this.props.getAllCandidates(this.props.history, page, pageSize);
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // setSelectedRow(selectedRowKeys);
      this.props.performOperation(inputFieldType.SELECTED_ROW,selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {
    },
    onSelectAll: (selected, selectedRows, changeRows) => {},
    getCheckboxProps: (record) => ({}),
  };
  

  render() {
    const {
      candidates,
      candidateTableLoader,
      candidateJobDescription,
      candidatePageMeta,
      candidateScreenType,
    } = this.props;
    const {user} =store.getState().auth
    const { width, } = this.state.windowDimensions;

  //   const candidateViewHandler = (record) => {
  //     this.props.updateInputField(CANDIDATE_DETAILS, record);
  //     this.props.performOperation(operationType.CANDIDATE_VIEW_MODAL, true);
  //  }
    return (
      <div
        className={classes.candidateTable}
      >
        {width > 700 ?
        <div>
        <Table
            rowSelection={this.rowSelection}
            columns={newCandidateColumns(this.props)?.map((col) => ({
            ...col,
            align: "center",
            }))}
            scroll={{ y: 370 }}
            pagination={{
            position: "bottomRight",
            pageSize: 25,
            size: "small",
            pageSizeOptions: [10, 30, 50, 100],
            pageSize: this.state.pageSize,
            current: this.state.page,
            total: candidatePageMeta?.totalItems
              ? candidatePageMeta?.totalItems
              : 0,
            onChange: (page, pageSize) => this.handlePageChange(page, pageSize),
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          dataSource={candidates}
          rowClassName="data-row"
          locale={{
            emptyText: candidateTableLoader ? (
              <div className="no-data-loader"></div>
            ) : Object.keys(candidateJobDescription).length === 0 ? (
              <EmptyTable text="No Profiles Found" />
            ) : (
              <EmptyTable text="No Profiles Found" />
            ),
          }}
          loading={candidateTableLoader}
        />
        </div>    
        :<>
        <div className={classes.ListBodyContainer} style={{height:"67vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",marginTop:"0.7rem"}}>
          {candidateTableLoader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
             candidates?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
             candidates?.map((record,id,index)=>{
                return(
                 <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"0.9rem 0.7rem"}}>
                 <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"0.5rem",paddingTop:"0.3rem",borderBottom:"1px solid #DFDFDF"}}>
                  <div style={{display: "flex",justifyContent: "flex-start"}}>
                    <div>
                      {record.photo ? (
                        <img src={record.photo} style={{ width: "40px",height: "40px",borderRadius: "50%", }} />
                      ) : (
                        <img src={profileIcon} style={{ width: "40px",height: "40px",borderRadius: "50%",}} />
                      )}
                    </div>
                    <div style={{display: "flex",flexDirection:"row",marginLeft: "0.7rem",alignItems:"center",}}>
                      <div style={{ display: "flex"}}>
                        <div>
                          <Popover content={userContent(record)}>
                            <a style={{fontWeight:600}}
                              // onClick={() => {
                              //   props.updateInputField(CANDIDATE_DETAILS, record);
                              //   props.performOperation(
                              //     operationType.CANDIDATE_VIEW_MODAL,
                              //     true
                              //   );
                              // }}
                            >
                            <Typography.Text ellipsis={{tooltip:false}} style={{width:"7.5rem",fontSize:"1rem",fontWeight:600}}>{record.name}</Typography.Text>
                            </a>
                          </Popover>
                        </div>
                        <Space size="large">
                          <div>
                            {record.blacklist ? (
                              <Tooltip title="Blacklist">
                                <StopOutlined
                                  style={
                                    record.blacklist
                                      ? { color: Color.black }
                                      : { color: Color.midGrey }
                                  }
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                            {record.duplicate ? (
                              <Tooltip title="Duplicate">
                                <CopyOutlined
                                  style={
                                    record.duplicate
                                      ? { color: Color.red }
                                      : { color: Color.midGrey }
                                  }
                                  onClick={() => {
                                    this.props.updateInputField(CANDIDATE_DETAILS, record);
                                    this.props.getDuplicateCandidates();
                                    
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                        </Space>
                      </div>
                    </div>
                  </div>
                  <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                        <span style={{ fontSize: 10 }}>
                          <a
                            onClick={() => {
                              this.props.updateInputField(
                                inputFieldType.CANDIDATE_RESUME,
                                record.resume
                              );
                              this.props.performOperation(
                                operationType.CANDIDATE_PDF_VIEW_MODAL,
                                true
                              );
                            }}
                          >
                            <img src={ResumeIcon} />
                          </a>
                        </span>
                   </div>
                 </div>

                 <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>Job Title</span>
                  <p style={{fontSize:15,color:"#161616"}}>
                  <Popover content={record?.job?.title}>
                    <Space size="small">
                      <span style={{ fontSize: "13px" }}>{record?.job?.title}</span>
                    </Space>
                  </Popover>
                  </p>
                 </div>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>Experience</span>
                  <p style={{fontSize:15,color:"#161616"}}>{`${record.experienceYears} Y ${
                    record.experienceMonths !== 0 ? `${record.experienceMonths} M` : ""}`}</p>
                 </div>
                 </div>

                 <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>Date</span>
                  <p style={{fontSize:15,color:"#161616"}}>{record?.createdAt ? moment(record?.createdAt).format(dateFormat):"NA"}</p>
                 </div>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>Notice Period (Days)</span>
                  <p style={{fontSize:15,color:"#161616"}}>{record?.noticePeriod ? record?.noticePeriod:"NA"}</p>
                 </div>
                 </div>

                 <div style={{width:"100%",marginTop:"1rem",borderBottom:"0.6rem"}}>
                  <Button type="primary" style={{height:"2.1rem",width:"100%",borderRadius:5}}
                   onClick={()=>{
                    this.props.updateInputField(CANDIDATE_DETAILS, record);
                    this.props.performOperation(operationType.CANDIDATE_VIEW_MODAL, true);
                  }}
                  >
                    View Profile
                  </Button>
                 </div>

              </div>
              )
            })}
        </div> 
        <div>
         <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",fontSize:width<700&&15,}}
           pageSizeOptions= {[10,25, 30, 50, 100]}
           showSizeChanger={true}
           onChange={(page, pageSize) => this.handlePageChange(page, pageSize)}
           onShowSizeChange={(page, pageSize) => this.handlePageChange(page, pageSize)}
           pageSize= {this.state.pageSize}
           current= {this.state.page}
           total={candidatePageMeta?.totalItems
             ? candidatePageMeta?.totalItems
             : 0}
           showTotal={(total, range) =>
             `${range[0]}-${range[1]} of ${total} items`}
         /> 
        </div>
        </>
        }    
      <div>
        <AddProfileModal />
        <ViewPDF />
        <DuplicateViewModal />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCandidate);
