 class Filter{
    constructor(){
        this.xStatus = false;
        this.xiiStatus = false;
        this.cgpaStatus = false;
        this.backlogsStatus = false;
        this.eligibility = false;
        this.overAllStatus = false;
        this.hackathonStatus = false;
        this.jobFilterStatus = false;
        this.hackathonScoreStatus = false;
        this.xmin = 0;
        this.xmax = 0;
        this.xiimin = 0;
        this.xiimax = 0;
        this.cgpamin=0;
        this.cgpamax=0;
        this.backlogsmin=0;
        this.backlogsmax=0;
        this.eligibiltyStatus = []
        this.allStatusList = []
        this.scoremax = 0
        this.scoremin = 0    
    }

    setXMin(value){
      
        this.xmin = value
    }

    setXiiMin(value){
        this.xmin = value
    }

    
    setCgpaMin(value){
        this.cgpamin = value
    }

    setBacklogMin(value){
        this.backlogsmin = value;
    }

    setXMax(value){
        this.xmax = value
    }

    setXiiMax(value){
        this.xiimax = value
    }

    
    setCgpaMax(value){
        this.cgpamax = value
    }

    setBacklogMax(value){
        this.backlogsmax = value;
    }

    setEligibilityStatus(values){
        this.eligibiltyStatus = values
    }

    getEligibilityStatus(){
        return this.eligibiltyStatus.slice();
    }

    resetFitler(){
        this.xStatus = false;
        this.xiiStatus = false;
        this.cgpaStatus = false;
        this.backlogsStatus = false;
        this.eligibility = false;
        this.overAllStatus = false;
        this.hackathonStatus = false;
        this.jobFilterStatus = false;
        this.hackathonScoreStatus = false;
        this.xmin = 0;
        this.xmax = 0;
        this.xiimin = 0;
        this.xiimax = 0;
        this.cgpamin=0;
        this.cgpamax=0;
        this.backlogsmin=0;
        this.backlogsmax=0;
        this.eligibiltyStatus = []
        this.allStatusList = []
        this.scoremax = 0
        this.scoremin = 0
    }

}

export default Filter;