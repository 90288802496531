import moment from "moment";
import { Api } from "services";
export const TOGGLE_SUMMARY_LOADER = "TOGGLE_SUMMARY_LOADER";
export const UPDATE_SUMMARY_DATA = "UPDATE_SUMMARY_DATA";
export const UPDATE_FILTER_PARAMS = "UPDATE_FILTER_PARAMS";
export const UPDATE_SUMMARY_DETAILS_DATA = "UPDATE_SUMMARY_DETAILS_DATA";
export const CANDIDATE_DATE_DROPDOWN = "CANDIDATE_DATE_DROPDOWN";
export const CANDIDATE_DATE_RANGEVAL = "CANDIDATE_DATE_RANGEVAL";

export const fetchSummary = () => {
  return (dispatch, getState) => {
    var params = {};
    if (getState().agencySummary.candidateDateRangeVal) {
      const range = getState().agencySummary.candidateDateRangeVal;
      params = {
        startDate: moment(range[0]).format("YYYY-MM-DD"),
        endDate: moment(range[1]).format("YYYY-MM-DD"),
      };
    } else {
      params = {
        startDate: "",
        endDate: "",
      };
    }
    dispatch(toggleSummaryLoader(true));
    return Api.get(`/hiring-overview/agencyProfile-count`)
      .params(params)
      .send((response, error) => {
        dispatch(updateSummaryData(response));
        dispatch(toggleSummaryLoader(false));
      });
  };
};

export const fetchSummaryDetails = () => {
  return (dispatch, getState) => {
    var params = {};
    const filterParams = getState().agencySummary.filterParams;
    if (getState().agencySummary.candidateDateRangeVal) {
      const range = getState().agencySummary.candidateDateRangeVal;
      params = {
        startDate: moment(range[0]).format("YYYY-MM-DD"),
        endDate: moment(range[1]).format("YYYY-MM-DD"),
        agencyId: filterParams.agencyId,
      };
    } else {
      params = {
        startDate: "",
        endDate: "",
        agencyId: filterParams.agencyId,
      };
    }
    dispatch(toggleSummaryLoader(true));
    return Api.get(`/hiring-overview/agency-summary`)
      .params(params)
      .send((response, error) => {
        if (!response)
        {dispatch(toggleSummaryLoader(false));
          return;
          }
        dispatch(updateSummaryDetailsData(response));
        dispatch(toggleSummaryLoader(false));
      });
  };
};

export const getDownload=()=>{
  return (dispatch, getState) => {
    var params = {};
    if (getState().agencySummary.candidateDateRangeVal) {
      const range = getState().agencySummary.candidateDateRangeVal;
      params = {
        startDate: moment(range[0]).format("YYYY-MM-DD"),
        endDate: moment(range[1]).format("YYYY-MM-DD"),
        download: true,
      };
    } else {
      params = {
        startDate: "",
        endDate: "",
        download: true,
      };
    }
    dispatch(toggleSummaryLoader(true));
    return Api.get('/hiring-overview/agencyProfile-count')
      .params(params)
      .send((response) => {
        if (typeof response != "undefined") {
          window.open(response, "_blank")?.focus();
         }
        dispatch(toggleSummaryLoader(false));
      });
  };
}

export const getAgencyDownload=()=>{
  return (dispatch, getState) => {
    var params = {};
    const filterParams = getState().agencySummary.filterParams;
    if (getState().agencySummary.candidateDateRangeVal) {
      const range = getState().agencySummary.candidateDateRangeVal;
      params = {
        startDate: moment(range[0]).format("YYYY-MM-DD"),
        endDate: moment(range[1]).format("YYYY-MM-DD"),
        agencyId: filterParams.agencyId,
        download: true,
      };
    } else {
      params = {
        startDate: "",
        endDate: "",
        agencyId: filterParams.agencyId,
        download: true,
      };
    }
    return Api.get(`/hiring-overview/agency-summary`)
      .params(params)
      .send((response) => {
        if (typeof response != "undefined") {
          window.open(response, "_blank")?.focus();
         }
      });
  };
}

const toggleSummaryLoader = (bool) => ({
  type: TOGGLE_SUMMARY_LOADER,
  bool,
});

const updateSummaryData = (data) => ({
  type: UPDATE_SUMMARY_DATA,
  data,
});

const updateSummaryDetailsData = (data) => ({
  type: UPDATE_SUMMARY_DETAILS_DATA,
  data,
});

export const updateFilterParams = (val, key) => ({
  type: UPDATE_FILTER_PARAMS,
  filterParams: { [key]: val },
});

export const updateInputField = (inputFieldType, value) => ({
  type: inputFieldType,
  value,
});

export const inputFieldType = {
  CANDIDATE_DATE_DROPDOWN: CANDIDATE_DATE_DROPDOWN,
  CANDIDATE_DATE_RANGEVAL: CANDIDATE_DATE_RANGEVAL,
};

export const inputFieldTypeHash = {
  CANDIDATE_DATE_DROPDOWN: "candidateDateDropdown",
  CANDIDATE_DATE_RANGEVAL: "candidateDateRangeVal",
};
