import { createSlice } from "@reduxjs/toolkit";
import { clearFromLocalStorage } from "services/Storage";


const initialState = {
    warnings: 3,
    warningsReload: 0,
    token: null,
    user: {},  
    candidateId:0,
    pageLoad: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateToken(state, action) {
      state.token = action.payload.token;
    },
    updatePageLoad(state, action) {
      state.pageLoad = action.payload.pageLoad;
    },
    updateWarningLoad(state, action) {
      let val = state.warningsReload
      if(action.payload.warningsReload === "add"){
        val = val + 1
      }
      else{
        val = 0;
      }
      state.warningsReload = val;
    },
    updateUser(state, action) {
      state.user = { ...action.payload.user };
      state.warnings = 3;
    },
    removeUser(state) {
      state.user = {};
      state.token = null;
      state.warnings = 3;
      clearFromLocalStorage();
    },
    decrementWarning(state,action)
    {
      
      // let obj = JSON.parse(localStorage.getItem('auth'));
      // obj.warnings = parseInt(obj.warnings) - 1;
      state.warnings = state.warnings-1
      // localStorage.setItem("auth", JSON.stringify(obj));
    },
    incrementWarning(state,action)
    {
      
    
      
      // let obj = JSON.parse(localStorage.getItem('auth'));
      // obj.warnings = parseInt(obj.warnings + 1);
      state.warnings = state.warnings+1
      // localStorage.setItem("auth", JSON.stringify(obj));
     },
     candidateId(state,action)
     {
       
       state.candidateId = action.payload
      
     },
  },
});

export default authSlice.reducer;

export const authActions = authSlice.actions;
