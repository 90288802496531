import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Input, Button, Modal, Select } from "antd";
import { useHistory } from "react-router-dom";

import classes from "./FeedBackManagement.module.css";
import { feedbackActions } from "./store";
import { Api } from "services";
// const Option={Select};

const CreateFeedbackModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    openCreateFeedbackModal,
    openEditFeedbackModal,
    feedbackData,
    selectCardCount,
    feedbackMetaType,
    feedbackSaveState,
    selectedCard,
  } = useSelector((state) => state?.feedbacks);
  const feedbackquestions = useSelector((state) => state?.feedbacks?.lists);
  const [dropdown, setDropDown] = useState(feedbackSaveState==="edit" ?
  feedbackData?.roundType:undefined);

  const [title, setTitle] = useState("");
  const [feedbacksquestions, setFeedbacksQuestions] = useState([]);
  
  const [selectcardclone, setSelectClone] = useState();
  const [saveState, setsaveState] = useState("");

  useEffect(() => {
    if (feedbackData != null) {
      setTitle(feedbackData?.title);
      setDropDown(feedbackData?.roundType);
    }
  }, [feedbackData]);

  // useEffect(()=>{
  //   dispatch(feedbackActions.updateFeedbackState(feedbackMetaType))
  // },[feedbackMetaType])

  const handleCancel = () => {
    dispatch(feedbackActions.updateEditFeedbackModal(false));
    dispatch(feedbackActions.updateCreateFeedbackModal(false));
    dispatch(feedbackActions.updateFeedbackSaveState("create"));
  };

  const createFeed = () => {};

  const fetchCard = () => {
    // setLoading(true)
    Api.get(`/hiring-feedback/getfeedbackQuestions`)
      .params({  feedbackMetaType: feedbackMetaType })
      .send((response) => {
        if (typeof response != "undefined") {
          
          setFeedbacksQuestions(response);
          dispatch(feedbackActions.updateFeedbacksQuestions(response));
        } else {
          setBodyLoader(true);
          setError("No data Found");
        }
        // setLoading(false)
      });
  };

  const onChangeTF = (value) => {
    setTitle(value.target.value);
  };

  const onChangeDropDown = (value) => {
    setDropDown(value);
  };
  return (
    <Modal
      open={openCreateFeedbackModal || openEditFeedbackModal}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      // bodyStyle={{ height: "75vh" }}
      closable={true}
      centered
      header={null}
      width="45%"
      className={classes.createFeedback}
    >
      <div className={classes.modalContainer}>
        <h1>Select Your Preference</h1>
        <div>
          <label>TITLE</label>
        </div>
        <Input
          rules={[
            {
              required: true,
              message: "Please Input Description",
            },
          ]}
          style={{
            width: "100%",
          }}
          // dropdownStyle={{
          //   maxHeight: 400,
          //   overflow: "auto",
          // }}
          defaultValue={feedbackData?.title}
          //showSearch
          placeholder="Enter the title"
          //optionFilterProp="children"
          onChange={onChangeTF}
          size="middle"
          // defaultValue={}
          // filterOption={(input, option) =>
          //   option.children.toLowerCase().includes(input.toLowerCase())
          // }
          value={title}
        ></Input>
        <br /> <br />
        <div>
          <label>ROUND TYPE</label>
        </div>
        <Select
          style={{
            width: "100%",
          }}
          // dropdownStyle={{
          //   maxHeight: 400,
          //   overflow: "auto",
          // }}
          //showSearch
          placeholder={"Select round(s)"}
          onChange={onChangeDropDown}
          size="middle"
          allowClear={true}
          value={dropdown === "" ?"Select dropdown" :dropdown}
        >
          {feedbackquestions?.roundType?.map((item,index) => {
            return (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            );
          })}
        </Select>
        <div className={classes.buttonDiv}>
          <Button style={{ marginRight: "10px" }} onClick={handleCancel}>
            CANCEL
          </Button>
          <Button
            //classsName={classes.createButton}
            style={{
              color: !(title.trim() === "" || dropdown === "" || dropdown === undefined) ? "#ffffff" : "",
            }}
            disabled={title.trim() === "" || dropdown === "" || dropdown === undefined}
            type="primary"
            onClick={() => {
              {
                selectCardCount > 0 && feedbackSaveState !== "edit"
                  ? Api.post(`/hiring-feedback/clone/${selectedCard}`)
                      .params({
                        title: title,
                        roundType: dropdown,
                        feedbackMetaType: feedbackMetaType,
                      })
                      .send((res) => {
                       

                        if (res) {
                          dispatch(
                            feedbackActions.updateCreateFeedbackModal(false)
                          );
                 
                          dispatch(
                            feedbackActions.updateFeedbackData(
                              res?.show?.feedback
                            )
                          );
                          props.setSelectCard([])
                          props.fetchCard();
                          props.setcardChecked([])
                          setTitle("");
                          setDropDown("");
                         // history.push("/fbround");
                        } else {
                       
                        }
                      
                      
                      })
                  : feedbackSaveState === "create"
                  ? Api.post(
                      `/hiring-feedback/createFeedBack?feedbackMetaType=${feedbackMetaType}`
                    )
                      .params({
                        title: title,
                        roundType: dropdown,
                      })
                      .send((res) => {
                        if (res.show.type==="success") {
                         
                          dispatch(
                            feedbackActions.updateFeedbackData(
                              res?.show?.feedback
                            )
                          );
                          dispatch(
                            feedbackActions.updateCreateFeedbackModal(false)
                          );
                          history.push("/fbround");
                          // fetchCard();
                        } else {
                       
                        }
                      })
                  : "";
                feedbackData != null && feedbackSaveState === "edit"
                  ? Api.patch(
                      `/hiring-feedback/updateFeedBack?feedbackMetaType=${feedbackMetaType}`
                    )
                      .params({
                        feedbackId: feedbackData.feedbackId,
                        title: title,
                        roundType: dropdown,
                      })
                      .send((res) => {
                        if (res) {
                         
                          dispatch(
                            feedbackActions.updateFeedbackData(
                              res?.show?.feedback
                            )
                          );
                          dispatch(
                            feedbackActions.updateEditFeedbackModal(false)
                          );
                          dispatch(
                            feedbackActions.updateFeedbackSaveState("create")
                          );

                          history.push("/fbround");
                          // fetchCard();
                        } else {
                          
                        }
                      })
                  : "";
              }
            }}
          >
            {feedbackData != null && feedbackSaveState === "edit"
              ? "Update"
              :feedbackData != null 
              ?"Save"
              : selectCardCount > 0
              ? "CLONE"
              : "CREATE"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFeedbackModal;
