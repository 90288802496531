import React, { useEffect, useState } from "react";
  import classes from './candidate-detail-upload.module.css';
  import uploadStyleImg from '../../assets/candidateUploadImg.jpg';
import uploadResumeIcon from '../../assets/uploadResumeIcon.svg';
import profileIcon from '../../assets/profileIcon.svg.svg';
import cameraIcon from '../../assets/cameraIcon.svg';
import { candidateActions } from "pages/dashboard-candidate/store";
import Api from "services/Api";
import {
  LoadingOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  CheckOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  message,
  Upload,
  Row,
  Typography,
  Card,
  Col,
  Input,
  Button,
  Form,
  Select,
  Modal,
} from "antd";
import Notification from "services/Notification";
import OTPModal from "pages/dashboard-candidate/OTPModal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
const { Title } = Typography;

const CandidateDetailupload = () => {
  const [loading, setLoading] = useState(false);
  const[btnLoader,setBtnLoader] = useState(false)
  const[btnLoader1,setBtnLoader1] = useState(false)
  const[btnLoader2,setBtnLoader2] = useState(false)
  const [value, setValue] = useState();
  const [phn, SetPhn] = useState("");
  const [otp, setotp] = useState(false);
  const [otpval,setOtpval]=useState("")
  const [otpverified,setOtpverified]=useState(false)
  const [imageUrl, setImageUrl] = useState("");
  const [imgFile,setImgFile] = useState("");
  const [resumeFile,setResumeFile]= useState("");
  const [resumeUrl,setResumeUrl]=useState('');
  const [mobile,setMobile]=useState('');
  const [width, setWidth]   = useState(window.innerWidth);
  const {modalOpen}=useSelector(state=>state.userCandidate);
  const {verifyOTP} = useSelector((state => state.userCandidate));
   const dispatch = useDispatch()
  const { Option } = Select;
  let params = new URL(location.href);
  
   let id = params.pathname.slice(12);
  const history=useHistory();
const modalWidth=width>700?"45%":"100%";

const updateDimensions = () => {
  setWidth(window.innerWidth);
}
useEffect(() => {
  window.addEventListener("resize", updateDimensions);
  return () => window.removeEventListener("resize", updateDimensions);
}, []);

useEffect(()=>{
  dispatch(candidateActions.updateVerifyOTP(false));
  dispatch(candidateActions.updateModalOpen(false));
},[mobile])

  //   const reader = new FileReader();
  //   reader.addEventListener("load", () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // };
  // const imgbeforeUpload = (file) => {
  //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG file!");
  //   } else if (!isLt2M) {
  //     message.error("Image must smaller than 2MB!");
  //   } else {
  //     getBase64(file, (url) => {
  //       setLoading(false);
  //       setImageUrl(url);
  //       setImgFile(file)
  //     });
  //   }
  //   setTimeout(() =>{

  //   },5000)
  //   return false;
  // };
  // const beforeUpload = (file) => {
  //   const isJpgOrPng = file.type === "application/pdf";
  //   const isLt2M = file.size / 1024 / 1024 < 5;
  //   if (!isJpgOrPng) {
  //     Notification.error("error", "You can only upload PDF file!");
  //   } else if (!isLt2M) {
  //     Notification.error("error", "PDF must smaller than 5MB!");
  //   } else {
  //       setresumeFile(file)
  //     //   this.props.updateInputField(profileOtherFieldType.PROFILE_RESUME, [file]);
  //   }
  //   return false;
  // };
  useEffect(() => {
    Api.get(`/hiring-publicapi/${parseInt(id)}`).send((response) => {
      setValue(response);
    });
  }, []);

  const uploadButton = (
    <div>
               {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const resumeBeforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    const docsOrPdf = file.type ==='application/docs' || file.type ==='application/pdf';
      if (!docsOrPdf) {
        Notification.error('You can only upload Docs/PDF file!');
        setResumeUrl('');
        return;
      }
     else if (!isLt10M) {
       Notification.error("Image must be smaller than 10MB!");
    }
    else {
      getBase64(file, (url) => {
        setLoading(false);   
        setResumeUrl(url);
        setResumeFile(file);     
      });
    }
  }

const imgbeforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 2;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJpgOrPng) {
        Notification.error("You can only upload JPG/PNG file!");
        setImageUrl('')
        return
      }
     else if (!isLt10M) {
      Notification.error("Image must be smaller than 2MB!");
    }
    else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
        setImgFile(file);
      });
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
      defaultValue={'+'+91}
        style={{
          width: 70,
        }}
      >
        <Option value="91">+91</Option>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className={classes.wrapper}>
              <div className={classes.container}>
                        <div>
                                    <img src={uploadStyleImg}></img>
                        </div>
                        <div>                     
                                    <Form layout="vertical" onFinish={(values)=>{
                                      let params={photo:imgFile,resume:resumeFile}                                                          
                                      Api.patch(`/hiring-publicapi/uploadResumephoto/${id}`)
                                      .uploadAndPatch(params,(percentCompleted,response) =>{                                       
                                        setBtnLoader2(false)
                                        if(response?.data?.show?.type==='success')
                                        {
                                          history.push('/thanks');
                                        }                                     
                                    })
                                    }}>
                                            <Form.Item name="photo" rules={[{required:true,message:"Upload a valid document!"}]}>
                                                    <div className={classes.uploadPhoto}>
                                                                  <Upload onRemove={() => setImageUrl("")} showUploadList={false} beforeUpload={imgbeforeUpload} 
                                                                                accept=".png, .jpg, .jpeg"
                                                                                maxCount={1}
                                                                                > 
                                                                                <div>
                                                                                      <div>
                                                                                              {imageUrl&&<img src={imageUrl} style={{width:"10rem",height:"10rem",borderRadius:"50%"}}></img>}
                                                                                              {imageUrl==''&&<img src={profileIcon} ></img>}                                                                          
                                                                                      </div>
                                                                                      <Button>
                                                                                            <img src={cameraIcon}></img>
                                                                                      </Button>
                                                                                      <span>Upload Photo</span>
                                                                                  </div>                                                                                                                                 
                                                                  </Upload>
                                                  </div>
                                            </Form.Item>

                                            <Form.Item label={<span>Mobile Number</span>} name="mobile">
                                              <div className={classes.mobileDiv}>
                                                        <Input addonBefore={prefixSelector} placeholder="Enter your Mobile number" onChange={(event)=>setMobile(event.target.value)}/>
                                                        {!verifyOTP&&<Button disabled={mobile.trim().length!==10} 
                                                        loading={btnLoader}
                                                        onClick={()=>{
                                                          dispatch(candidateActions.updateModalOpen(true));
                                                          setBtnLoader(true)                                                       
                                                          Api.post("/hiring-publicapi/sendotp")
                                                            .params({ candidateId: parseInt(id), phoneNumber: mobile })
                                                            .send((response) => {                                                         
                                                              setBtnLoader(false)
                                                              setotp(true);
                                                            });
                                                        }}>Verify</Button>}
                                                        {verifyOTP&&
                                                        <div className={classes.verified}>
                                                                      <CheckCircleOutlined></CheckCircleOutlined>
                                                                       <span>Verified</span>
                                                        </div>}
                                              </div>                              
                                              <p className={classes.note}>(<b>Note: </b>Please enter the registered mobile number with the company)</p>                                            
                                            </Form.Item>

                                            <Form.Item  label={<span>Upload Resume</span>} name="resume"  rules={[{required:true,message:"Enter valid document!"}]}>
                                                    <div className={classes.resumeUpload}>
                                                          <Upload onRemove={() => setResumeFile("")} showUploadList={true} beforeUpload={resumeBeforeUpload} 
                                                                  accept=".png, .jpg, .jpeg, .pdf, .docs"
                                                                  maxCount={1}                                                               
                                                                  >                                       
                                                                                <Button>
                                                                                          <img src={uploadResumeIcon}></img>
                                                                                          <h4>Drag and drop it here</h4>
                                                                                          <span>Support files include pdf,doc.</span>
                                                                                </Button>
                                                            </Upload>
                                                      </div>
                                            </Form.Item>
                                                      <div className={classes.submitButton}>
                                                                <Button htmlType="submit" disabled={!imgFile||!resumeFile||!verifyOTP ||imageUrl === '' ||resumeUrl === ''}>SUBMIT</Button>
                                                      </div>                                         
                                              </Form>

                                        <Modal open={modalOpen&&!verifyOTP}  footer={null} width={modalWidth} centered closable={true} maskClosable={true} onCancel={()=>dispatch(candidateActions.updateModalOpen(false))}>
                                                    <OTPModal candidateId={parseInt(id)} phone={mobile} calledFrom={'CANDIDATE_UPLOAD'}/>
                                      </Modal>  
                        </div>
              </div>
    </div>
  );
};
export default CandidateDetailupload;
