import {
    Modal,
    Row,
    Col,
    Typography,
    Checkbox,
    Card,
    Button,
    DatePicker,
    TimePicker,
    Select
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState, useEffect, useRef } from "react";
import Api from "../../services/Api";
import classes from './sendEmailModal.module.css';
import "./style.css";
import Notification from "services/Notification";
import EmailIcon from "../../assets/emailTemp.png"
import { useDispatch, useSelector } from "react-redux";
import {EmailTemplateBody} from '../email-template/create-modal';
import BackspaceIcon from '../../assets/backspace-icon.svg';
import { testActions } from "pages/dashboard-testpapers/store";
import moment from 'moment';
import { tooltip } from "@antv/g2plot/lib/adaptor/common";
const { Title } = Typography;
const { Option } = Select;

function TemplateModal(props) {
    const dispatch=useDispatch()
    const [selectTemplate, setTemplate] = useState();
    const templates = useSelector(state => state.templates.list);
    const [page, setPage] = useState(1);
    const tests = useSelector(state => state.test.testList);
    const [testList ,setTestList] = useState();
    const [selectedTest, setTest] = useState([]);
    const [ btnLoader,setBtnLoader]=useState(false)
    const [testdate,setTestdate] = useState("")
    const[time,setTime] = useState(null)
    const[duration,setDuration] = useState(7)
    const[testToggle,setToggle] = useState(false)

    let numArr = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];

console.log("ssssTemplateeee",props)
   const getDisabledHours = () => {
    var hours = [];
    for(var i =0; i < moment().hour(); i++){
        hours.push(i);
    }
    return hours;
}

const getDisabledMinutes = (selectedHour) => {
    var minutes= [];
    if (selectedHour === moment().hour()){
        for(var i =0; i < moment().minute(); i++){
            minutes.push(i);
        }
    }
    return minutes;
}


   useEffect(() => {
    if(props.isOpen)
    Api.post("/hiring-tests/getAllTests")

     .params({status:"Active"})
      .send((response) => {
        if (typeof response != "undefined") {
          setToggle(true)
          dispatch(testActions.updateTest( response ));

        }
      });
  }, [props.isOpen]);
  
  let options=[]
    useEffect(() => {
        if(tests && tests.length > 0){   
       
            tests.forEach((item) => {
                
                if(item.createdMarks===  item.totalMarks){
                options.push({ label: item.title, value: item.id });
                }
              });
              setTestList(options)
        }
    }, [testToggle,tests])

    const sendEmail = () => {
        if(props.selectedData.length===0){
            Notification.error("Select Candidate","")
            return
        }
        if(selectedTest.length===0)
       { Notification.error("Select tests","")
        return
    }
        let requestbody = {
            testIds : selectedTest,
            templateID: selectTemplate.id,
            candidateIds : props.selectedData,
            testDate:testdate,
            starttime: time
        }
        if(props?.selectedRowData[0]?.candidateType === "Experienced"){
            requestbody={...requestbody, testActiveDays: duration}
        }
        Api.post('/hiring-candidate/sendTestLink', requestbody).send(res => {
            if (res && res.show && res.show.type == "success") {
                // Notification.success(res.message);
                props.toggle();
                props.refreshGetdata();
            } else {

            }
        })
    }

    const templateCard = (templateObj) => {
        return <div  key={templateObj.id}>
                        <Card
                            className={(selectTemplate && selectTemplate.id == templateObj.id) ? "selected_template custom-card" : "custom-card"}
                            onClick={() => { setTemplate(templateObj) }}
                        >
                            <div style={{ textAlign: "center" }} className="custom-card-bg">
                                <img src={EmailIcon} style={{ height: "5rem",width:"5rem",margin:"1rem" }} />
                            </div>
                            <div className="cursor-pointer custom-card-footer">
                                <div>
                                    <h4 style={{fontWeight:"700"}}>
                                        <Typography.Text ellipsis={{tooltip:true}}  style={{width:"7rem"}}>{templateObj.subject}</Typography.Text>
                                        
                                        </h4>
                                </div>
                                <div className="card-subtitle" style={{fontSize:"0.8rem"}}>
                                    To: {templateObj.type}
                                </div>
                            </div>
                        </Card>
            </div>
    }

    return (
        <Modal
            width={700}
            bodyStyle={{borderRadius:"5px",backgroundColor:"#ffffff"}}
            centered
            open={props.isOpen}
            onCancel={() => {
                if(props.value==="candidateProfile"){
                    props.setSendMailVisible(false)
                    return
                }
                props.toggle();               
            }}
            footer={false}
        >
            <div style={{display:"flex",flexDirection:"column",padding:"3%"}}>
            
         <div>     {
                    page > 1 && <img src={BackspaceIcon} onClick={()=> setPage(page-1)}/>
                    }           
                    <Title
                        style={{
                            textAlign: "center",
                            marginBottom: "10px",
                            color: "#161616",
                            fontWeight:"bold"
                        }}
                        level={3}
                    >
                        
                        {page == 1 && "Select Email Template"}
                        {page == 2 && "Preview Email Template"}
                        {page == 3 && "Select Test"}
                    </Title>
            </div>



            {page == 1 &&
                <div style={{margin:" 0.5rem 0 1rem 0"}}><div className="template-list-scroller">
                    <Row className={classes.templateCard}>
                        {templates.map((item, index) => {
                            if(item.type!== "Institution"){
                            return templateCard(item)
                        }
                        else{
                            return ""
                        }
                            
                            })}
                    </Row>
                </div>
                {props?.value==="candidateProfile" ?selectTemplate &&<Row justify="end"><Button size="large" type="primary" loading={btnLoader} onClick={() =>{setBtnLoader(true)
                    Api.post(`/hiring-candidate/sendDocuploadLink?candidateId=${props.selectedData}&templateId=${selectTemplate.id}`)
                    .params({
                        // candidateId:props.selectedData,
                        // templateID:selectTemplate
                    })
                    .send((response) =>{
                         props.setSendMailVisible(false)
                         setBtnLoader(false)
                       
                    } )
                }}>Send</Button></Row> :
                    <div style={{ display: "flow-root" }}>

                        {
                            selectTemplate && <Button type="primary" style={{
                                float: "right",
                                marginTop:"2rem",
                                height:"2.2rem",
                                width:"8rem",
                                fontWeight:"700",
                            }}
                                onClick={() => setPage(2)}
                            >Next</Button>
                        }
                    </div>}</div>}

                    {
                page == 2 &&
                <div style={{height:"450px",overflowY:"auto",}}>
                <EmailTemplateBody 
                isModalVisible={true}
                templateObj={selectTemplate} 
                id={selectTemplate.id ? selectTemplate : null}
                type="preview"
                sendEmail="true"
                />
                <div style={{ display: "flow-root" }}>
                <Button type="primary" onClick={()=> setPage(3)} style={{float : "right",height:"2.5rem",width:"8rem",fontWeight:"700", margin: "2rem"}} >Next {">>"}</Button>
           
                </div>

                </div>
              
                }
            {
                page == 3 &&
                <div >
                        <Row style={{marginTop:"1rem",display:"flex",justifyContent:"left",gap:"1rem",alignItems:"center"}}>
                            <div>Test Start Date : </div>
                    <DatePicker format="YYYY/MM/DD" disabledDate={(current) =>{return current && current < moment().startOf('day');}}  onChange={(date,dateString) =>{
                          setTestdate(dateString)
                    }}
                    style={{width:"30%"}}
                    />
                    {props?.selectedRowData[0]?.candidateType === "Experienced" ?
                    <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",alignItems:"center",marginLeft:"0.3rem"}}>
                    <div style={{fontWeight:550}}>Duration(days) :</div>
                    <Select size="medium" style={{width:"10rem"}} placeholder="Select" defaultValue={duration} 
                    onChange={(value) =>setDuration(value)}>
                    {numArr.map((item,index)=>{
                        return(
                            <Option key={index} value={item}>{item}</Option>
                        )
                    })}
                    </Select>
                    </div>
                    :
                    <TimePicker 
                    // disabledHours={}
                    placeholder="Select Start Time" onChange={(time,timeString) =>setTime(timeString)}  format="HH:mm"
                      style={{width:"30%"}}
                    />
                    }
                    </Row>
                    <div style={{marginTop:"1rem",display:"flex",width:"100%",flexDirection:"row",height:"50vh",overflow:"auto",}}>
                  <Checkbox.Group
                    style={{  color: "#000000",fontSize:"20px",fontWeight:"700",width:"100%",padding:5,gridTemplateColumns:"auto auto auto",display:"grid",columnGap:30}}
                    size="large"
                    options={testList}
                    onChange={(e)=>{
                        
                        setTest(e);
                    }}
                  />
                </div>
                <Row style={{ marginTop: "30px" }} justify="center">
                
                  <Button
                    size={"middle"}
                    style={{ borderColor: "#0086FF", backgroundColor: "#0086FF" ,border:"0",marginBottom:"1rem",color:"#ffffff",fontWeight:"620"}}
                    onClick={sendEmail}
                  >
                    Send Test Link
                  </Button>
                </Row>

                </div>
            }

        </div>
        
        </Modal>
    );
}
export default TemplateModal;