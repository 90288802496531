import React, { useState } from "react";
import { Card,Form, Input,Row,Col,Radio,Button } from "antd";
import { mapDispatchToProps, mapStateToProps } from "../redux/container"
import { connect,useDispatch,useSelector } from "react-redux";
import {ArrowLeftOutlined } from "@ant-design/icons";
import { HrFeedbackActions, } from "./store";
const AcademicDetails = (props) => {
    const dispatch = useDispatch()
    const {basicInfo} = useSelector((state) => state.HrFeedback)
  const { feedback } = useSelector((state) => state.candidate.feedbackCandidateInfo)
   let feedbackType = feedback.find((value) => value.roundType === "HR")
    const onFInish=(values) => {           
              dispatch(HrFeedbackActions.updatefeedback(values))          
              props.onNext("CompanyDetails")
    }
    const goBack = () => {
        props.onBack("basicInfo")
    }
    return(
        <>
        <Card title="Academic details"style={{border:"black",width: "100%" }} >
            <Button type="text" onClick={goBack}><ArrowLeftOutlined /></Button>
            <Form onFinish={onFInish} layout="vertical">
                <Row><Col span={12}>
                    <Form.Item
                    name="AcademicsX"
                    label={<b>10th(%)</b>}
                    rules={[{
                        required:true
                    }]}
                    initialValue={feedbackType !==undefined && !basicInfo?.AcademicsX ? feedbackType?.details?.AcademicsX :  basicInfo?.AcademicsX}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="AcademicsXII"
                    label={<b>12th(%)</b>}
                    rules={[{
                        required:true
                    }]}
                    initialValue={ feedbackType !==undefined && !basicInfo?.AcademicsXII   ? feedbackType?.details?.AcademicsXII :   basicInfo?.AcademicsXII}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="AcademicsDegree"
                            label={<b>Degree</b>}
                            rules={[{
                                required:true
                            }]}
                            initialValue={feedbackType !==undefined && !basicInfo?.AcademicsDegree ? feedbackType?.details?.AcademicsDegree  : basicInfo?.AcademicsDegree}
                             >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="AcademicsOthers"
                            label={<b>Others</b>}
                            initialValue={ feedbackType?.details?.AcademicsOthers && ! basicInfo?.AcademicsOthers ? feedbackType?.details?.AcademicsOthers  : basicInfo?.AcademicsOthers}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row justify="end" marginTop="10px"><Button size="large" style={{paddingLeft:"50px",paddingRight:"50px"}} type="primary" htmlType="submit">Save And Next</Button></Row>
            </Form>
      
    </Card>
    
    </>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(AcademicDetails)