import { Api, Notification} from "services";
import moment from "moment";




export const HR_PROCESS_CANDIDATE_TABLE_LOADER =
  "HR_PROCESS_CANDIDATE_TABLE_LOADER";
export const HR_PROCESS_CANDIDATE_SCREEN_TYPE =
  "HR_PROCESS_CANDIDATE_SCREEN_TYPE";
export const HR_PROCESS_CANDIDATE_VIEW_MODAL =
  "HR_PROCESS_CANDIDATE_VIEW_MODAL";
export const HR_PROCESS_CANDIDATE_PREVIOUS_ORGS_VIEW_MODAL =
  "HR_PROCESS_CANDIDATE_PREVIOUS_ORGS_VIEW_MODAL";
export const HR_PROCESS_CANDIDATE_JOB_CHANGE_MODAL =
  "HR_PROCESS_CANDIDATE_JOB_CHANGE_MODAL";
export const HR_PROCESS_CANDIDATE_SHORTLISTED_LOADER =
  "HR_PROCESS_CANDIDATE_SHORTLISTED_LOADER";
export const HR_PROCESS_CANDIDATE_REJECTED_LOADER =
  "HR_PROCESS_CANDIDATE_REJECTED_LOADER";
export const HR_PROCESS_INTERVIEW_MODAL = "HR_PROCESS_INTERVIEW_MODAL";
export const HR_PROCESS_INTERVIEW_SUBMIT_LOADER =
  "HR_PROCESS_INTERVIEW_SUBMIT_LOADER";
export const HR_PROCESS_INTERVIEW_ONMODAL_LOADER =
  "HR_PROCESS_INTERVIEW_ONMODAL_LOADER";
export const HR_PROCESS_INTERVIEW_DELETE_LOADER =
  "HR_PROCESS_INTERVIEW_DELETE_LOADER";
export const HR_PROCESS_INTERVIEW_CONFIRM_MODAL =
  "HR_PROCESS_INTERVIEW_CONFIRM_MODAL";
export const HR_PROCESS_CANDIDATE_PDF_VIEW_MODAL =
  "HR_PROCESS_CANDIDATE_PDF_VIEW_MODAL";
export const HR_PROCESS_CANDIDATE_IMAGE_VIEW_MODAL =
  "HR_PROCESS_CANDIDATE_IMAGE_VIEW_MODAL";
export const HR_PROCESS_DUPLICATE_VIEW_MODAL =
  "HR_PROCESS_DUPLICATE_VIEW_MODAL";
export const HR_PROCESS_CANDIDATE_PHOTO_LOADER =
  "HR_PROCESS_CANDIDATE_PHOTO_LOADER";
export const HR_PROCESS_CANDIDATE_PHOTO_EDIT_BOOL = 
  "HR_PROCESS_CANDIDATE_PHOTO_EDIT_BOOL";
export const HR_PROCESS_CANDIDATE_FILTER_STATUS =
  "HR_PROCESS_CANDIDATE_FILTER_STATUS";
export const HR_PROCESS_HIRING_FILTER_STATUS =
  "HR_PROCESS_HIRING_FILTER_STATUS";

export const HR_PROCESS_CANDIDATE_ROUND_CHANGE =
  "HR_PROCESS_CANDIDATE_ROUND_CHANGE";
export const HR_PROCESS_CANDIDATE_JOB_CHANGE =
  "HR_PROCESS_CANDIDATE_JOB_CHANGE";
export const HR_PROCESS_CANDIDATE_JOB_DESCRIPTION =
  "HR_PROCESS_CANDIDATE_JOB_DESCRIPTION";
export const HR_PROCESS_PANELIST = "HR_PROCESS_PANELIST";
export const HR_PROCESS_CHANGED_PANELIST = "HR_PROCESS_CHANGED_PANELIST";
export const HR_PROCESS_CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY =
  "HR_PROCESS_CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY";
export const HR_PROCESS_CANDIDATE_AGENCIES = "HR_PROCESS_CANDIDATE_AGENCIES";
export const HR_PROCESS_CANDIDATE_AGENCY = "HR_PROCESS_CANDIDATE_AGENCY";
export const HR_PROCESS_CANDIDATE_AGENCY_FOR_HISTORY =
  "HR_PROCESS_CANDIDATE_AGENCY_FOR_HISTORY";
export const HR_PROCESS_INTERVIEW_TYPE = "HR_PROCESS_INTERVIEW_TYPE";
export const HR_PROCESS_INTERVIEW_SCHEDULE = "HR_PROCESS_INTERVIEW_SCHEDULE";
export const HR_PROCESS_INTERVIEW_DATE = "HR_PROCESS_INTERVIEW_DATE";
export const HR_PROCESS_INTERVIEW_AVAILABLE_SLOTS =
  "HR_PROCESS_INTERVIEW_AVAILABLE_SLOTS";
export const HR_PROCESS_INTERVIEW_PANELIST_NAME =
  "HR_PROCESS_INTERVIEW_PANELIST_NAME";
export const HR_PROCESS_INTERVIEW_SELF_BOOKED_SLOTS =
  "HR_PROCESS_INTERVIEW_SELF_BOOKED_SLOTS";
export const HR_PROCESS_CANDIDATE_FEEDBACK = "HR_PROCESS_CANDIDATE_FEEDBACK";
export const HR_PROCESS_CANDIDATE_CLEAR = "HR_PROCESS_CANDIDATE_CLEAR";
export const HR_PROCESS_CANDIDATE_RESUME = "HR_PROCESS_CANDIDATE_RESUME";
export const HR_PROCESS_CANDIDATE_IMAGE = "HR_PROCESS_CANDIDATE_IMAGE";
export const HR_PROCESS_CANDIDATE_DATE_DROPDOWN =
  "HR_PROCESS_CANDIDATE_DATE_DROPDOWN";
export const HR_PROCESS_CANDIDATE_DATE_RANGE =
  "HR_PROCESS_CANDIDATE_DATE_RANGE";
export const HR_PROCESS_CANDIDATE_STATUS_DROPDOWN =
  "HR_PROCESS_CANDIDATE_STATUS_DROPDOWN";
export const HR_PROCESS_HIRING_STATUS_DROPDOWN =
  "HR_PROCESS_HIRING_STATUS_DROPDOWN";
export const HR_PROCESS_CANDIDATE_HR_STATUS = "HR_PROCESS_CANDIDATE_HR_STATUS";
export const HR_PROCESS_CANDIDATE_OFFER_SENT =
  "HR_PROCESS_CANDIDATE_OFFER_SENT";
export const HR_PROCESS_Expected_DOJ = "HR_PROCESS_Expected_DOJ";
export const HR_PROCESS_CANDIDATE_HR_NOTICE_PERIOD =
  "HR_PROCESS_CANDIDATE_HR_NOTICE_PERIOD";
export const HR_PROCESS_LWD = "HR_PROCESS_LWD";
export const HR_PROCESS_HR_COMMENT = "HR_PROCESS_HR_COMMENT";
export const HR_PROCESS_CANDIDATE_ROUND = "HR_PROCESS_CANDIDATE_ROUND";
export const HR_PROCESS_CANDIDATE_NEW_PAGE_NUMBER =
  "HR_PROCESS_CANDIDATE_NEW_PAGE_NUMBER";
export const HR_PROCESS_CANDIDATE_ONGOING_PAGE_NUMBER =
  "HR_PROCESS_CANDIDATE_ONGOING_PAGE_NUMBER";
export const HR_PROCESS_CANDIDATE_REJECTED_PAGE_NUMBER =
  "HR_PROCESS_CANDIDATE_REJECTED_PAGE_NUMBER";
export const HR_PROCESS_CANDIDATE_HISTORY_PAGE_NUMBER =
  "HR_PROCESS_CANDIDATE_HISTORY_PAGE_NUMBER";
export const HR_PROCESS_CANDIDATE_PHOTO_EDIT =
  "HR_PROCESS_CANDIDATE_PHOTO_EDIT";
export const HR_PROCESS_CANDIDATE_SHORTLISTER_NOTE =
  "HR_PROCESS_CANDIDATE_SHORTLISTER_NOTE";

export const HR_PROCESS_CANDIDATE_RECRUITMENT_STATUS =
  "HR_PROCESS_CANDIDATE_RECRUITMENT_STATUS";
export const HR_PROCESS_CANDIDATE_RECRUITMENT_ROUND =
  "HR_PROCESS_CANDIDATE_RECRUITMENT_ROUND";

export const HR_PROCESS_CANDIDATE_BLACKLIST = "HR_PROCESS_CANDIDATE_BLACKLIST";
export const HR_PROCESS_CANDIDATE_DUPLICATE = "HR_PROCESS_CANDIDATE_DUPLICATE";

export const HR_PROCESS_CANDIDATE_DETAILS = "HR_PROCESS_CANDIDATE_DETAILS";

export const HR_PROCESS_CANDIDATE_SKIP_ROUND =
  "HR_PROCESS_CANDIDATE_SKIP_ROUND";
export const HR_PROCESS_DUPLICATE_CANDIDATE_GET =
  "HR_PROCESS_DUPLICATE_CANDIDATE_GET";
export const HR_PROCESS_CANDIDATE_DETAILS_GET_FOR_HR_PROCESS =
  "HR_PROCESS_CANDIDATE_DETAILS_GET_FOR_HR_PROCESS";
export const HR_PROCESS_CANDIDATE_DETAILS_EDIT =
  "HR_PROCESS_CANDIDATE_DETAILS_EDIT";
export const HR_PROCESS_CANDIDATE_RECRUITMENT_EDIT =
  "HR_PROCESS_CANDIDATE_RECRUITMENT_EDIT";
export const HR_PROCESS_CANDIDATE_DETAILS_HIDE =
  "HR_PROCESS_CANDIDATE_DETAILS_HIDE";

export const HR_PROCESS_INTERVIEW_SLOTS_DELETE =
  "HR_PROCESS_INTERVIEW_SLOTS_DELETE";
export const HR_PROCESS_INTERVIEW_SLOTS_ADD = "HR_PROCESS_INTERVIEW_SLOTS_ADD";
export const HR_PROCESS_PANELIST_NAME_CHANGED =
  "HR_PROCESS_PANELIST_NAME_CHANGED";
export const HR_PROCESS_CANDIDATE_COMMENT_TOGGLE =
  "HR_PROCESS_CANDIDATE_COMMENT_TOGGLE";
export const HR_PROCESS_CANDIDATE_OFFER_TOGGLE =
  "HR_PROCESS_CANDIDATE_OFFER_TOGGLE";
export const HR_PROCESS_CANDIDATE_OFFER_READ =
  "HR_PROCESS_CANDIDATE_OFFER_READ";
export const HR_PROCESS_CANDIDATE_OFFER_LOADER =
  "HR_PROCESS_CANDIDATE_OFFER_LOADER";
export const HR_PROCESS_CANDIDATE_OFFER_UPDATE_LOCAL =
  "HR_PROCESS_CANDIDATE_OFFER_UPDATE_LOCAL";
export const HR_PROCESS_CANDIDATE_HR_STATUS_UPDATE_LOCAL =
  "HR_PROCESS_CANDIDATE_HR_STATUS_UPDATE_LOCAL";
export const HR_PROCESS_CANDIDATE_JOB_CHANGE_TOGGLE =
  "HR_PROCESS_CANDIDATE_JOB_CHANGE_TOGGLE";
export const HR_PROCESS_CANDIDATE_ROUND_CHANGE_TOGGLE =
  "HR_PROCESS_CANDIDATE_ROUND_CHANGE_TOGGLE";

export const HR_PROCESS_CANDIDATE_JOB_CHANGE_LOADER =
  "HR_PROCESS_CANDIDATE_JOB_CHANGE_LOADER";
export const HR_PROCESS_SET_FEEDBACK_VIEW = "HR_PROCESS_SET_FEEDBACK_VIEW";
export const HR_PROCESS_SET_HR_ACTION_EDITING_VIEW =
  "HR_PROCESS_SET_HR_ACTION_EDITING_VIEW";
export const HR_PROCESS_SET_CANDIDATE_SCREENSHOT =
  "HR_PROCESS_SET_CANDIDATE_SCREENSHOT";
export const HR_PROCESS_TOOGLE_FEEDBACK_LOADER =
  "HR_PROCESS_TOOGLE_FEEDBACK_LOADER";
export const HR_PROCESS_SET_CANDIDATE_FEEDBACK =
  "HR_PROCESS_SET_CANDIDATE_FEEDBACK";

export const HR_PROCESS_SELECT_CANDIDATE_ROUND =
  "HR_PROCESS_SELECT_CANDIDATE_ROUND";
export const HR_PROCESS_SET_SELECTED_CANDIDATE =
  "HR_PROCESS_SET_SELECTED_CANDIDATE";

export const HR_PROCESS_HR_ACTION_EDITING_MODAL =
  "HR_PROCESS_HR_ACTION_EDITING_MODAL";
  export const HR_PROCESS_ACTION_EDITING_MODAL =
  "HR_PROCESS_ACTION_EDITING_MODAL";
export const HR_PROCESS_HR_ACTION_EDITING_LOADER =
  "HR_PROCESS_HR_ACTION_EDITING_LOADER";
export const HR_PROCESS_HR_ACTION_EDITING_ID =
  "HR_PROCESS_HR_ACTION_EDITING_ID";

  export const HR_PROCESS_SEARCH_VALUE = "HR_PROCESS_SEARCH_VALUE"

// export const candidateAgencyFormat = []
export const candidateAgencyFormat = {
  id: -1,
  agencyName: "All",
};

const slotFormat = {
  id: null,
  date: "",
  panelist: {
    id: null,
    name: "",
    email: "",
  },
  schedule: {
    id: null,
    startTime: "",
    endTime: "",
  },
};

//This is candidate's panelist info before updation
const panelistFormat = {
  id: null,
  name: "",
  email: "",
  phone: "",
  designation: "",
};

export const candidateFormat = {
  id: null,
  name: "",
  email: "",
  phone: "",
  skypeId: "",
  experienceYears: null,
  experienceMonths: null,
  currentCompany: "",
  currentCtc: "",
  expectedCtc: "",
  note: null,
  currentLocation: "",
  preferredLocation: "",
  noticePeriod: null,
  resume: "",
  candidatePhoto: "",
  duplicate: null,
  blacklist: false,
  reference: null,
  candidateStatus: "",
  currentRound: null,
  comment: "",
  shortlisterComment: "",
  createdAt: "",
  updatedAt: "",
  hrStatus: {},
  hiringStatus:"",
  feedback: [],
};

export const dateFormat = "YYYY-MM-DD";
export const postDateFormat = "YYYY-MM-DD";

export const showHrActionEditingModal = (record) => {
  return (dispatch) => {
    dispatch(updateInputField(HR_PROCESS_CANDIDATE_DETAILS, record));
    dispatch(performOperation(HR_PROCESS_HR_ACTION_EDITING_ID, record.id));
  };
};

export const setFeedbackView = (bool) => ({
  type: HR_PROCESS_SET_FEEDBACK_VIEW,
  bool,
});

export const setHrActionEditingView = (bool) => ({
  type: HR_PROCESS_SET_HR_ACTION_EDITING_VIEW,
  bool,
});

export const selectCandidateRound = (val) => ({
  type: HR_PROCESS_SELECT_CANDIDATE_ROUND,
  val,
});

export const setCandidateScreenshot = (value) => ({
  type: HR_PROCESS_SET_CANDIDATE_SCREENSHOT,
  value,
});

export const getCandidateFeedback = () => {
  return (dispatch, getState) => {
    const candidateId = getState().hrProcess.selectedCandidate.id;
    dispatch(fetchFeedbackLoader(true));
    return Api.get(`/hiring-recruitment/feedback/${candidateId}`).send(
      (response, error) => {
        if (response !== undefined) {
        }
        dispatch(fetchFeedbackLoader(false));
        dispatch(setCandidateFeedback(response));
      }
    );
  };
};

const fetchFeedbackLoader = (bool) => ({
  type: HR_PROCESS_TOOGLE_FEEDBACK_LOADER,
  bool,
});

const setCandidateFeedback = (data) => ({
  type: HR_PROCESS_SET_CANDIDATE_FEEDBACK,
  data,
});

export const setCurrenCandidate = (data) => ({
  type: HR_PROCESS_SET_SELECTED_CANDIDATE,
  data,
});

export const handleSkipRound = (id) => {
  return (dispatch) => {
    return Api.patch(`/hiring-recruitment/skipround/${id}`).send((response, error) => {
      dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, true));
      if (response !== undefined) {
        dispatch(candidateSkipRound(id, response.params));
      }
      dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, false));
    });
  };
};



export const getAllCandidates = (history, page = 1, limit = null) => {
 
  return (dispatch, getState) => {
    // const { searchValue } = getState().createUser;
    const { user } = getState().session;
    const {
      candidateAgency,
      candidateJobDescription,
      candidateRound,
      candidateDateRange,
      candidateFilterStatus,
      hiringFilterStatus,
      candidateScreenType,
      candidateAgencyForHistory,
      candidateJobDescriptionForHistory,
      hrProcessSearchValue
    } = getState().hrProcess;
    let candidateJDFH = candidateJobDescriptionForHistory.slice();
    let candidateAFH = candidateAgencyForHistory.slice();

   

    // if(candidateJDFH.length){
    //   candidateJDFH.map((item, index) => {
    //      candidateJobs.map((jobItem, i) => {
    //        if(index === i){
    //          candidateJDFH[index] = jobItem.id
    //        }
    //      })
    //   })
    // }
    // if(candidateAFH.length){
    //   candidateAFH.map((item, index) => {
    //      candidateAgencies.map((agencyItem, i) => {
    //        if(index === i){
    //          candidateAFH[index] = agencyItem.id
    //        }
    //      })
    //   })
    // }
    const agencyQuery =
      candidateAgency?.id !== undefined && candidateAgency?.id !== -1
        ? `&agencyId=${candidateAgency.id}`
        : "";
    const jobQuery =
      Object.keys(candidateJobDescription).length === 0
        ? ""
        : `&jobId=${candidateJobDescription.id}`;
    const agencyQueryHistoryTab = candidateAFH.length
      ? `&agencyId=[${candidateAFH}]`
      : "";
    const jobQueryHistoryTab = !candidateJDFH.length
      ? ""
      : `&jobId=[${candidateJDFH}]`;
    const roundQuery =
      candidateRound !== undefined && candidateRound !== "All"
        ? `&round=${candidateRound}`
        : "";
    const dateRangeQuery = candidateDateRange
      ? `&dateRange=${moment(candidateDateRange[0], dateFormat).format(
          postDateFormat
        )}/${moment(candidateDateRange[1], dateFormat).format(postDateFormat)}`
      : "";
    const statusQuery =
      candidateFilterStatus !== undefined && candidateFilterStatus !== "All"
        ? `&hrStatus=${candidateFilterStatus}`
        : "";
    const hiringStatusQuery =
        hiringFilterStatus !== undefined && hiringFilterStatus !== "All"
          ? `&hiringStatus=${hiringFilterStatus}`
          : "";
    const statusQueryHistoryTab =
      candidateFilterStatus !== undefined && candidateFilterStatus !== "All"
        ? `&status=${candidateFilterStatus}`
        : "";
    let pageQuery = `&page=${parseInt(page)}`;
    if (limit) {
      pageQuery = pageQuery + `&limit=${parseInt(limit)}`;
    }
    const mainUrl = user?.role?.includes("Agency")
      ? "/hiring-candidates/agency"
      : "/hiring-candidates/all";
    const url =
      candidateScreenType === "New"
        ? `${mainUrl}?search=${hrProcessSearchValue}${jobQuery}${agencyQuery}${roundQuery}&status=New${pageQuery}`
        : `${mainUrl}?search=${hrProcessSearchValue}${jobQuery}${agencyQuery}${roundQuery}${dateRangeQuery}${statusQuery}${pageQuery}`;

    let browserURL = new URL(window.location.href);
    dispatch(candidateCommentToggle(false, null));
    if (user?.role?.includes("Agency") && browserURL.pathname === "/candidate") {
      history.push("/agency-candidate");
    } else {
      dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, true));

      const urlFinal =
        candidateScreenType === "History"
          ? `/hiring-candidates/history?search=${hrProcessSearchValue}${jobQueryHistoryTab}${agencyQueryHistoryTab}${roundQuery}${statusQueryHistoryTab}${pageQuery}`
          : candidateScreenType === "HrProcess"
          ? `/hiring-hrprocess/?search=${hrProcessSearchValue}${statusQuery}${hiringStatusQuery}${pageQuery}`
          : url;
      return Api.get(urlFinal).send((response, error) => {
    
        if (response !== undefined) {
          dispatch(candidateDetailsGetForHrProcess(response));
        }
        // dispatch(performOperation(SEARCH_VALUE, ""));
        dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, false));
      });
    }
  };
};

export const getCandidatesOnTabChange = (history) => {
  return (dispatch) => {
    dispatch(updateInputField(HR_PROCESS_CANDIDATE_CLEAR, []));
    //dispatch(updateInputField(HR_PROCESS_CANDIDATE_FILTER_STATUS, "All"));
    dispatch(getAllCandidates(history));
  };
};

export const getDuplicateCandidates = () => {
  return (dispatch, getState) => {
    const { candidateInfo } = getState().candidate;
    dispatch(
      performOperation(operationType.HR_PROCESS_DUPLICATE_VIEW_MODAL, true)
    );
    return Api.get(`/hiring-candidates/duplicate/${candidateInfo.id}`).send(
      (response, error) => {
        if (response !== undefined) {
          dispatch(duplicateCandidateGet(response));
        }
      }
    );
  };
};

export const statusDropdownAction = (history) => {
  return (dispatch, getState) => {
    const { candidateStatusDropdown } = getState().hrProcess;
    dispatch(
      updateInputField(
        inputFieldType.HR_PROCESS_CANDIDATE_STATUS_DROPDOWN,
        !candidateStatusDropdown
      )
    );
    dispatch(getAllCandidates(history));
  };
};

export const hiringStatusDropdownAction = (history) => {
  return (dispatch, getState) => {
    const { hiringStatusDropdown } = getState().hrProcess;
    dispatch(
      updateInputField(
        inputFieldType.HR_PROCESS_HIRING_STATUS_DROPDOWN,
        !hiringStatusDropdown
      )
    );
    dispatch(getAllCandidates(history));
  };
};

export const handleCandidateEdit = () => {
  return (dispatch, getState) => {
    const { candidateInfo, candidateJobDescription } = getState().candidate;
    dispatch(performOperation(HR_PROCESS_CANDIDATE_VIEW_MODAL, false));
    const postJson = { ...candidateInfo, jobId: candidateJobDescription.id };
    if (!candidateJobDescription.id) {
      Notification.error("error", "please select job title");
    } else {
      dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, true));
      return Api.patch(`/hiring-candidates/${candidateInfo.id}`)
        .params(postJson)
        .send((response, error) => {
          if (response !== undefined) {
            dispatch(candidateDetailsEdit(candidateInfo.id, candidateInfo));
          }
          dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, false));
        });
    }
  };
};

export const handleCandidatePhotoEdit = () => {
  return (dispatch, getState) => {
    const { candidateInfo, candidateJobDescription, candidatePhotoEdit } =
      getState().candidate;
    const postJson = { candidatePhoto: candidatePhotoEdit };

    dispatch(performOperation(HR_PROCESS_CANDIDATE_PHOTO_LOADER, true));
    return Api.patch(`/hiring-candidates/candidatephoto/${candidateInfo.id}`)
      .params(postJson)
      .uploadAndPatch(postJson, (percent, response) => {
        if (response !== undefined && percent === 100) {
          dispatch(
            performOperation(HR_PROCESS_CANDIDATE_PHOTO_EDIT_BOOL, false)
          );
          dispatch(
            candidateDetailsEdit(candidateInfo.id, {
              ...candidateInfo,
              candidatePhoto: response.data.candidatePhoto,
            })
          );
        }
        if (percent === 100) {
          dispatch(performOperation(HR_PROCESS_CANDIDATE_PHOTO_LOADER, false));
        }
      });
    //This change is to remove check of select job for photo add or edit.
    // if (!candidateJobDescription.id) {
    //   Notification.error("error", "please select job title");
    // } else {

    // }
  };
};

export const handleCandidateFlagEdit = () => {
  return (dispatch, getState) => {
    const { candidateInfo, candidateJobDescription } = getState().candidate;
    dispatch(performOperation(HR_PROCESS_CANDIDATE_VIEW_MODAL, false));
    const postJson = {
      blacklist: candidateInfo.blacklist,
      duplicate: candidateInfo.blacklist,
    };
    dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, true));
    return Api.patch(`/hiring-candidates/flag/${candidateInfo.id}`)
      .params(postJson)
      .send((response, error) => {
        if (response !== undefined) {
          dispatch(candidateDetailsEdit(candidateInfo.id, candidateInfo));
        }
        dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, false));
      });
    //This change is to remove check of select job for blacklist and duplicate flag.
    // if (!candidateJobDescription.id) {
    //   Notification.error("error", "please select job title");
    // } else {

    // }
  };
};

// handleCandidateAction

export const handleCandidateHrAction = (candidateId, hrStatus) => {
  return (dispatch, getState) => {
    const { history, candidateHistoryPageNumber } = getState().hrProcess;

    const postJson = {
      candidateId: candidateId,
      hrComment: hrStatus?.hrComment,
      hrNoticePeriod: hrStatus?.hrNoticePeriod === 0 ? null : hrStatus?.hrNoticePeriod,
      hrStatus: hrStatus?.hrStatus === "" ? null : hrStatus?.hrStatus,
      hiringStatus: "",
      lwd: hrStatus?.lwd,
      offeredCTC: hrStatus?.offeredCTC === "" ? undefined : hrStatus?.offeredCTC,
      expectedDoj: hrStatus?.expectedDoj,
    };

    const dispatchSpecificLoader = (value) =>
      dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, value));

    dispatchSpecificLoader(true);
    return Api.patch(`/hiring-hrprocess`)
      .params(postJson)
      .send((response, error) => {
        if (response !== undefined) {
          
          dispatch(getAllCandidates(history, candidateHistoryPageNumber));
          //dispatch(candidateHrStatusUpdateLocal(id, status));
        }
        dispatchSpecificLoader(false);
      });
  };
};

export const handleCandidateAction = (candidateId, status) => {
  return (dispatch, getState) => {
    const { history, candidateHistoryPageNumber } = getState().hrProcess;

    const postJson = {
      candidateId: candidateId,
      status: status,
    };

    const dispatchSpecificLoader = (value) =>
      dispatch(performOperation(HR_PROCESS_CANDIDATE_TABLE_LOADER, value));

    dispatchSpecificLoader(true);
    return Api.patch(`/hiring-hrprocess/hrStatus?candidateId=${candidateId}&status=${status}`)
      // .params(postJson)
      .send((response, error) => {
        if (response !== undefined) {
    
          dispatch(getAllCandidates(history, candidateHistoryPageNumber));
          //dispatch(candidateHrStatusUpdateLocal(id, status));
        }
        dispatchSpecificLoader(false);
      });
  };
};

export const getInterviewSlots = () => {
  return (dispatch, getState) => {
    const {
      interviewDate,
      candidateJobDescription,
      candidateInfo,
      panelist,
      changedPanelist,
    } = getState().candidate;

    const postInterviewDate = moment(interviewDate, dateFormat).format(
      postDateFormat
    );

    const url = `/hiring-recruitment/interview-slot/?candidateId=${
      candidateInfo.id
    }&date=${postInterviewDate}&panelistId=${
      candidateInfo.panelist
        ? changedPanelist
          ? panelist.id
          : candidateInfo.panelist.id
        : ""
    }`;

    dispatch(performOperation(HR_PROCESS_INTERVIEW_ONMODAL_LOADER, true));
    return Api.get(url).send((response, error) => {
      if (response !== undefined) {
        dispatch(
          updateInputField(
            HR_PROCESS_INTERVIEW_AVAILABLE_SLOTS,
            response.availableSlot
          )
        );
        dispatch(
          updateInputField(
            HR_PROCESS_INTERVIEW_SELF_BOOKED_SLOTS,
            response.selfBookedSlot
          )
        );
        dispatch(
          updateInputField(
            HR_PROCESS_INTERVIEW_PANELIST_NAME,
            response.panelistName
          )
        );
      }
      dispatch(performOperation(HR_PROCESS_INTERVIEW_ONMODAL_LOADER, false));
    });

    // if (!candidateJobDescription.id) {
    //   Notification.error("error", "please select job title");
    // } else {

    // }
  };
};

export const scheduleInterview = () => {
  return (dispatch, getState) => {
    const {
      interviewSchedule,
      interviewDate,
      candidateInfo,
      candidateStatus,
      interviewType,
      history,
    } = getState().candidate;
    const postInterviewDate = moment(interviewDate, dateFormat).format(
      postDateFormat
    );
    const params = {
      interviewMode: interviewType,
      date: postInterviewDate,
      candidateId: candidateInfo.id,
      scheduleId: interviewSchedule.id,
      currentRound: candidateInfo.currentRound,
    };

    dispatch(performOperation(HR_PROCESS_INTERVIEW_SUBMIT_LOADER, true));
    return Api.post("/hiring-recruitment/bookslot")
      .params(params)
      .send((response, error) => {
        if (response !== undefined && response.interviewSlots !== undefined) {
          dispatch(
            candidateRecruitmentEdit(
              HR_PROCESS_CANDIDATE_RECRUITMENT_STATUS,
              candidateInfo.id,
              candidateStatus.scheduled
            )
          );
          dispatch(
            candidateRecruitmentEdit(
              HR_PROCESS_CANDIDATE_RECRUITMENT_ROUND,
              candidateInfo.id,
              candidateInfo.currentRound + 1
            )
          );
          dispatch(
            interviewSlotsAdd(candidateInfo.id, response.interviewSlots)
          );
          dispatch(panelistNameChanged(candidateInfo.id, response.panelist));
          dispatch(getAllCandidates(history));
          dispatch(closeInterviewModal());
        }
        dispatch(performOperation(HR_PROCESS_INTERVIEW_SUBMIT_LOADER, false));
      });
  };
};

export const deleteScheduledInterview = () => {
  return (dispatch, getState) => {
    const { candidateInfo, candidateStatus } = getState().candidate;
    const status =
      candidateInfo.currentRound === 1
        ? candidateStatus.shortlisted
        : candidateStatus.inprogress;
    const interviewSlotId = candidateInfo.interviewSlots[0].id;

    dispatch(performOperation(HR_PROCESS_INTERVIEW_DELETE_LOADER, true));
    return Api.delete(`/hiring-recruitment/bookslot/${interviewSlotId}`).send(
      (response, error) => {
        if (response !== undefined) {
          dispatch(
            candidateRecruitmentEdit(
              HR_PROCESS_CANDIDATE_RECRUITMENT_STATUS,
              candidateInfo.id,
              status
            )
          );
          dispatch(
            candidateRecruitmentEdit(
              HR_PROCESS_CANDIDATE_RECRUITMENT_ROUND,
              candidateInfo.id,
              candidateInfo.currentRound - 1
            )
          );
          dispatch(interviewSlotsDelete(candidateInfo.id));
        }
        dispatch(performOperation(HR_PROCESS_INTERVIEW_DELETE_LOADER, false));
      }
    );
  };
};

export const candidateOfferEdit = (id, text) => {
  return (dispatch, getState) => {
    dispatch(candidateOfferLoader(true));
    return Api.patch(`/hiring-hrProcess`)
      .params({ candidateId: id, offeredCTC: text })
      .send((response, error) => {
        dispatch(candidateOfferLoader(false));
        if (response !== undefined) {
          dispatch(candidateOfferRead(""));
          dispatch(candidateOfferToggle(false, null));
          dispatch(candidateOfferUpdateLocal(id, text));
        }
      });
  };
};
export const candidateOfferToggle = (id, text) => ({
  type: HR_PROCESS_CANDIDATE_OFFER_TOGGLE,
  id,
  text,
});
export const candidateCommentToggle = (id, text) => ({
  type: HR_PROCESS_CANDIDATE_COMMENT_TOGGLE,
  id,
  text,
});

export const candidateOfferRead = (input) => ({
  type: HR_PROCESS_CANDIDATE_OFFER_READ,
  input,
});
export const candidateOfferLoader = (bool) => ({
  type: HR_PROCESS_CANDIDATE_OFFER_LOADER,
  bool,
});
export const candidateOfferUpdateLocal = (id, text) => ({
  type: HR_PROCESS_CANDIDATE_OFFER_UPDATE_LOCAL,
  id,
  text,
});

export const candidateHrStatusUpdateLocal = (id, text) => ({
  type: HR_PROCESS_CANDIDATE_HR_STATUS_UPDATE_LOCAL,
  id,
  text,
});

export const patchCandidateJobChange = () => {
  return (dispatch, getState) => {
    const {
      candidateJobChange,
      candidateInfo,
      candidateOriginalInfo,
      history,
      candidateRoundChange,
    } = getState().candidate;
    dispatch(candidateJobChangeLoader(true));
    return Api.patch(
      `/hiring-recruitment/changejob/${candidateInfo.id}/?jobId=${
        candidateJobChange.id
      }&reset=${candidateRoundChange.title === "Round 0" ? true : false}`
    ).send((response, error) => {
      dispatch(candidateJobChangeLoader(false));
      if (response !== undefined) {
        dispatch(candidateRoundChangeToggle(false, {}));
        dispatch(
          performOperation(HR_PROCESS_CANDIDATE_JOB_CHANGE_MODAL, false)
        );
        dispatch(candidateJobChangeToggle(false, {}));
        dispatch(performOperation(HR_PROCESS_CANDIDATE_VIEW_MODAL, false));
        dispatch(candidateDetailsHide(candidateOriginalInfo.id));
        dispatch(getAllCandidates(history));
      }
    });
  };
};
export const candidateJobChangeToggle = (bool, obj) => ({
  type: HR_PROCESS_CANDIDATE_JOB_CHANGE_TOGGLE,
  bool,
  obj,
});

export const candidateRoundChangeToggle = (bool, obj) => ({
  type: HR_PROCESS_CANDIDATE_ROUND_CHANGE_TOGGLE,
  bool,
  obj,
});
export const candidateJobChangeLoader = (bool) => ({
  type: HR_PROCESS_CANDIDATE_JOB_CHANGE_LOADER,
  bool,
});

// export const getCandidateHistoryData = () => {
//   return (dispatch, getState) => {
//     return Api.get('/candidates/history').send((response, error) => {
//       if (response !== undefined) {
//       }
//     });
//   }
// }

export const closeInterviewModal = () => {
  return (dispatch) => {
    dispatch(performOperation(HR_PROCESS_INTERVIEW_MODAL, false));
    dispatch(performOperation(HR_PROCESS_INTERVIEW_CONFIRM_MODAL, false));
    dispatch(updateInputField(HR_PROCESS_INTERVIEW_TYPE, "Offline"));
    dispatch(updateInputField(HR_PROCESS_INTERVIEW_SCHEDULE, ""));
    dispatch(updateInputField(HR_PROCESS_INTERVIEW_DATE, null));
    dispatch(updateInputField(HR_PROCESS_INTERVIEW_AVAILABLE_SLOTS, []));
    dispatch(updateInputField(HR_PROCESS_INTERVIEW_SELF_BOOKED_SLOTS, []));
    dispatch(updateInputField(HR_PROCESS_INTERVIEW_PANELIST_NAME, ""));
  };
};

export const candidateSkipRound = (id, value) => ({
  type: HR_PROCESS_CANDIDATE_SKIP_ROUND,
  id,
  value,
});

export const candidateDetailsGetForHrProcess = (value) => ({
  type: HR_PROCESS_CANDIDATE_DETAILS_GET_FOR_HR_PROCESS,
  value,
});

export const duplicateCandidateGet = (value) => ({
  type: HR_PROCESS_DUPLICATE_CANDIDATE_GET,
  value,
});

export const candidateDetailsEdit = (id, candidate) => ({
  type: HR_PROCESS_CANDIDATE_DETAILS_EDIT,
  id,
  candidate,
});

export const candidateDetailsHide = (id) => ({
  type: HR_PROCESS_CANDIDATE_DETAILS_HIDE,
  id,
});

export const candidateRecruitmentEdit = (kind, id, value) => ({
  type: HR_PROCESS_CANDIDATE_RECRUITMENT_EDIT,
  kind,
  id,
  value,
});

export const candidateFeedbackAdd = (id, value) => ({
  type: HR_PROCESS_CANDIDATE_FEEDBACK,
  id,
  value,
});

export const interviewSlotsDelete = (id) => ({
  type: HR_PROCESS_INTERVIEW_SLOTS_DELETE,
  id,
});

export const interviewSlotsAdd = (id, value) => ({
  type: HR_PROCESS_INTERVIEW_SLOTS_ADD,
  id,
  value,
});

export const panelistNameChanged = (id, value) => ({
  type: HR_PROCESS_PANELIST_NAME_CHANGED,
  id,
  value,
});

export const performOperation = (operationType, value) => ({
  type: operationType,
  value,
});

export const updateInputField = (inputFieldType, value) => ({
  type: inputFieldType,
  value,
});

//Hr action
export const updateHrActionInputField = (hrActionInputFieldType, value) => ({
  type: hrActionInputFieldType,
  value,
});

export const hrActionInputFieldType = {
  HR_PROCESS_CANDIDATE_HR_STATUS: HR_PROCESS_CANDIDATE_HR_STATUS,
  HR_PROCESS_CANDIDATE_OFFER_SENT: HR_PROCESS_CANDIDATE_OFFER_SENT,
  HR_PROCESS_CANDIDATE_HR_NOTICE_PERIOD: HR_PROCESS_CANDIDATE_HR_NOTICE_PERIOD,
  HR_PROCESS_LWD: HR_PROCESS_LWD,
  HR_PROCESS_HR_COMMENT: HR_PROCESS_HR_COMMENT,
  HR_PROCESS_Expected_DOJ: HR_PROCESS_Expected_DOJ,
};

export const inputFieldType = {
  HR_PROCESS_CANDIDATE_ROUND_CHANGE: HR_PROCESS_CANDIDATE_ROUND_CHANGE,
  HR_PROCESS_CANDIDATE_JOB_CHANGE: HR_PROCESS_CANDIDATE_JOB_CHANGE,
  HR_PROCESS_CANDIDATE_JOB_DESCRIPTION: HR_PROCESS_CANDIDATE_JOB_DESCRIPTION,
  HR_PROCESS_CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY:
    HR_PROCESS_CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY,
  HR_PROCESS_CANDIDATE_AGENCIES: HR_PROCESS_CANDIDATE_AGENCIES,
  HR_PROCESS_CANDIDATE_AGENCY: HR_PROCESS_CANDIDATE_AGENCY,
  HR_PROCESS_CANDIDATE_AGENCY_FOR_HISTORY:
    HR_PROCESS_CANDIDATE_AGENCY_FOR_HISTORY,
  HR_PROCESS_INTERVIEW_TYPE: HR_PROCESS_INTERVIEW_TYPE,
  HR_PROCESS_INTERVIEW_DATE: HR_PROCESS_INTERVIEW_DATE,
  HR_PROCESS_INTERVIEW_AVAILABLE_SLOTS: HR_PROCESS_INTERVIEW_AVAILABLE_SLOTS,
  HR_PROCESS_INTERVIEW_PANELIST_NAME: HR_PROCESS_INTERVIEW_PANELIST_NAME,
  HR_PROCESS_INTERVIEW_SELF_BOOKED_SLOTS:
    HR_PROCESS_INTERVIEW_SELF_BOOKED_SLOTS,
  HR_PROCESS_INTERVIEW_SCHEDULE: HR_PROCESS_INTERVIEW_SCHEDULE,
  HR_PROCESS_CANDIDATE_CLEAR: HR_PROCESS_CANDIDATE_CLEAR,
  HR_PROCESS_CANDIDATE_RESUME: HR_PROCESS_CANDIDATE_RESUME,
  HR_PROCESS_CANDIDATE_IMAGE: HR_PROCESS_CANDIDATE_IMAGE,
  HR_PROCESS_CANDIDATE_DATE_DROPDOWN: HR_PROCESS_CANDIDATE_DATE_DROPDOWN,
  HR_PROCESS_CANDIDATE_DATE_RANGE: HR_PROCESS_CANDIDATE_DATE_RANGE,
  HR_PROCESS_CANDIDATE_STATUS_DROPDOWN: HR_PROCESS_CANDIDATE_STATUS_DROPDOWN,
  HR_PROCESS_HIRING_STATUS_DROPDOWN: HR_PROCESS_HIRING_STATUS_DROPDOWN,
  HR_PROCESS_CANDIDATE_ROUND: HR_PROCESS_CANDIDATE_ROUND,
  HR_PROCESS_CANDIDATE_NEW_PAGE_NUMBER: HR_PROCESS_CANDIDATE_NEW_PAGE_NUMBER,
  HR_PROCESS_CANDIDATE_ONGOING_PAGE_NUMBER:
    HR_PROCESS_CANDIDATE_ONGOING_PAGE_NUMBER,
  HR_PROCESS_CANDIDATE_REJECTED_PAGE_NUMBER:
    HR_PROCESS_CANDIDATE_REJECTED_PAGE_NUMBER,
  HR_PROCESS_CANDIDATE_HISTORY_PAGE_NUMBER:
    HR_PROCESS_CANDIDATE_HISTORY_PAGE_NUMBER,
  HR_PROCESS_CANDIDATE_PHOTO_EDIT: HR_PROCESS_CANDIDATE_PHOTO_EDIT,
  HR_PROCESS_PANELIST: HR_PROCESS_PANELIST,
  HR_PROCESS_CHANGED_PANELIST: HR_PROCESS_CHANGED_PANELIST, 
  HR_PROCESS_CANDIDATE_FILTER_STATUS: HR_PROCESS_CANDIDATE_FILTER_STATUS,
  HR_PROCESS_HIRING_FILTER_STATUS: HR_PROCESS_HIRING_FILTER_STATUS,
  HR_PROCESS_SEARCH_VALUE:HR_PROCESS_SEARCH_VALUE
};

export const candidateFieldType = {
  HR_PROCESS_CANDIDATE_BLACKLIST: HR_PROCESS_CANDIDATE_BLACKLIST,
  HR_PROCESS_CANDIDATE_DUPLICATE: HR_PROCESS_CANDIDATE_DUPLICATE,
  HR_PROCESS_CANDIDATE_RECRUITMENT_STATUS:
    HR_PROCESS_CANDIDATE_RECRUITMENT_STATUS,
  HR_PROCESS_CANDIDATE_RECRUITMENT_ROUND:
    HR_PROCESS_CANDIDATE_RECRUITMENT_ROUND,
  HR_PROCESS_CANDIDATE_SHORTLISTER_NOTE: HR_PROCESS_CANDIDATE_SHORTLISTER_NOTE,
};

export const operationType = {
  HR_PROCESS_CANDIDATE_TABLE_LOADER: HR_PROCESS_CANDIDATE_TABLE_LOADER,
  HR_PROCESS_CANDIDATE_SCREEN_TYPE: HR_PROCESS_CANDIDATE_SCREEN_TYPE,
  HR_PROCESS_CANDIDATE_VIEW_MODAL: HR_PROCESS_CANDIDATE_VIEW_MODAL,
  HR_PROCESS_CANDIDATE_PREVIOUS_ORGS_VIEW_MODAL:
    HR_PROCESS_CANDIDATE_PREVIOUS_ORGS_VIEW_MODAL,
  HR_PROCESS_CANDIDATE_JOB_CHANGE_MODAL: HR_PROCESS_CANDIDATE_JOB_CHANGE_MODAL,
  HR_PROCESS_CANDIDATE_SHORTLISTED_LOADER:
    HR_PROCESS_CANDIDATE_SHORTLISTED_LOADER,
  HR_PROCESS_CANDIDATE_REJECTED_LOADER: HR_PROCESS_CANDIDATE_REJECTED_LOADER,
  HR_PROCESS_INTERVIEW_MODAL: HR_PROCESS_INTERVIEW_MODAL,
  HR_PROCESS_INTERVIEW_SUBMIT_LOADER: HR_PROCESS_INTERVIEW_SUBMIT_LOADER,
  HR_PROCESS_INTERVIEW_ONMODAL_LOADER: HR_PROCESS_INTERVIEW_ONMODAL_LOADER,
  HR_PROCESS_INTERVIEW_DELETE_LOADER: HR_PROCESS_INTERVIEW_DELETE_LOADER,
  HR_PROCESS_INTERVIEW_CONFIRM_MODAL: HR_PROCESS_INTERVIEW_CONFIRM_MODAL,
  HR_PROCESS_CANDIDATE_PDF_VIEW_MODAL: HR_PROCESS_CANDIDATE_PDF_VIEW_MODAL,
  HR_PROCESS_CANDIDATE_IMAGE_VIEW_MODAL: HR_PROCESS_CANDIDATE_IMAGE_VIEW_MODAL,
  HR_PROCESS_DUPLICATE_VIEW_MODAL: HR_PROCESS_DUPLICATE_VIEW_MODAL,
  HR_PROCESS_CANDIDATE_PHOTO_LOADER: HR_PROCESS_CANDIDATE_PHOTO_LOADER,
  HR_PROCESS_CANDIDATE_PHOTO_EDIT_BOOL: HR_PROCESS_CANDIDATE_PHOTO_EDIT_BOOL,
  HR_PROCESS_HR_ACTION_EDITING_MODAL: HR_PROCESS_HR_ACTION_EDITING_MODAL,
  HR_PROCESS_ACTION_EDITING_MODAL: HR_PROCESS_ACTION_EDITING_MODAL,
  HR_PROCESS_HR_ACTION_EDITING_LOADER: HR_PROCESS_HR_ACTION_EDITING_LOADER,
  HR_PROCESS_HR_ACTION_EDITING_ID: HR_PROCESS_HR_ACTION_EDITING_ID,
};

export const hrActionInputFieldTypeHash = {
  HR_PROCESS_CANDIDATE_HR_STATUS: "hrStatus",
  HR_PROCESS_CANDIDATE_OFFER_SENT: "offeredCTC",
  HR_PROCESS_CANDIDATE_HR_NOTICE_PERIOD: "hrNoticePeriod",
  HR_PROCESS_LWD: "lwd",
  HR_PROCESS_HR_COMMENT: "hrComment",
  HR_PROCESS_Expected_DOJ: "expectedDoj",
};

export const inputFieldTypeHash = {
  HR_PROCESS_CANDIDATE_ROUND_CHANGE: "candidateRoundChange",
  HR_PROCESS_CANDIDATE_JOB_CHANGE: "candidateJobChange",
  HR_PROCESS_CANDIDATE_JOB_DESCRIPTION: "candidateJobDescription",
  HR_PROCESS_CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY:
    "candidateJobDescriptionForHistory",
  HR_PROCESS_CANDIDATE_AGENCIES: "candidateAgencies",
  HR_PROCESS_CANDIDATE_AGENCY_FOR_HISTORY: "candidateAgencyForHistory",
  HR_PROCESS_CANDIDATE_AGENCY: "candidateAgency",
  HR_PROCESS_INTERVIEW_TYPE: "interviewType",
  HR_PROCESS_INTERVIEW_DATE: "interviewDate",
  HR_PROCESS_INTERVIEW_AVAILABLE_SLOTS: "interviewAvailableSlots",
  HR_PROCESS_INTERVIEW_PANELIST_NAME: "interviewPanelistName",
  HR_PROCESS_INTERVIEW_SELF_BOOKED_SLOTS: "interviewSelfBookedSlots",
  HR_PROCESS_INTERVIEW_SCHEDULE: "interviewSchedule",
  HR_PROCESS_CANDIDATE_CLEAR: "candidates",
  HR_PROCESS_CANDIDATE_RESUME: "candidateResume",
  HR_PROCESS_CANDIDATE_IMAGE: "candidateImage",
  HR_PROCESS_CANDIDATE_DATE_DROPDOWN: "candidateDateDropdown",
  HR_PROCESS_CANDIDATE_DATE_RANGE: "candidateDateRange",
  HR_PROCESS_CANDIDATE_STATUS_DROPDOWN: "candidateStatusDropdown",
  HR_PROCESS_HIRING_STATUS_DROPDOWN: "hiringStatusDropdown",
  HR_PROCESS_CANDIDATE_ROUND: "candidateRound",
  HR_PROCESS_CANDIDATE_NEW_PAGE_NUMBER: "candidateNewPageNumber",
  HR_PROCESS_CANDIDATE_ONGOING_PAGE_NUMBER: "candidateOngoingPageNumber",
  HR_PROCESS_CANDIDATE_REJECTED_PAGE_NUMBER: "candidateRejectedPageNumber",
  HR_PROCESS_CANDIDATE_HISTORY_PAGE_NUMBER: "candidateHistoryPageNumber",
  HR_PROCESS_CANDIDATE_PHOTO_EDIT: "candidatePhotoEdit",
  HR_PROCESS_PANELIST: "panelist",
  HR_PROCESS_CHANGED_PANELIST: "changedPanelist",
  HR_PROCESS_CANDIDATE_FILTER_STATUS: "candidateFilterStatus",
  HR_PROCESS_HIRING_FILTER_STATUS: "hiringFilterStatus",
  HR_PROCESS_SEARCH_VALUE: "hrProcessSearchValue"
};

export const candidateFieldTypeHash = {
  HR_PROCESS_CANDIDATE_BLACKLIST: "blacklist",
  HR_PROCESS_CANDIDATE_DUPLICATE: "duplicate",
  HR_PROCESS_CANDIDATE_RECRUITMENT_STATUS: "candidatesHrStatus",
  HR_PROCESS_CANDIDATE_RECRUITMENT_ROUND: "currentRound",
  HR_PROCESS_CANDIDATE_SHORTLISTER_NOTE: "shortlisterComment",
};

export const operationTypeHash = {
  HR_PROCESS_CANDIDATE_TABLE_LOADER: "candidateTableLoader",
  HR_PROCESS_CANDIDATE_SCREEN_TYPE: "candidateScreenType",
  HR_PROCESS_CANDIDATE_VIEW_MODAL: "candidateViewModal",
  HR_PROCESS_CANDIDATE_PREVIOUS_ORGS_VIEW_MODAL:
    "candidatePreviousOrgsViewModal",
  HR_PROCESS_CANDIDATE_JOB_CHANGE_MODAL: "candidateJobChangeModal",
  HR_PROCESS_CANDIDATE_SHORTLISTED_LOADER: "candidateShortlistedLoader",
  HR_PROCESS_CANDIDATE_REJECTED_LOADER: "candidateRejectedLoader",
  HR_PROCESS_INTERVIEW_MODAL: "interviewModal",
  HR_PROCESS_INTERVIEW_SUBMIT_LOADER: "interviewSubmitLoader",
  HR_PROCESS_INTERVIEW_ONMODAL_LOADER: "interviewOnModalLoader",
  HR_PROCESS_INTERVIEW_DELETE_LOADER: "interviewDeleteLoader",
  HR_PROCESS_INTERVIEW_CONFIRM_MODAL: "interviewConfirmModal",
  HR_PROCESS_CANDIDATE_PDF_VIEW_MODAL: "candidatePDFViewModal",
  HR_PROCESS_CANDIDATE_IMAGE_VIEW_MODAL: "candidateImageViewModal",
  HR_PROCESS_DUPLICATE_VIEW_MODAL: "duplicateViewModal",
  HR_PROCESS_CANDIDATE_PHOTO_LOADER: "candidatePhotoLoader",
  HR_PROCESS_CANDIDATE_PHOTO_EDIT_BOOL: "candidatePhotoEditBool",
  HR_PROCESS_HR_ACTION_EDITING_MODAL: "hrActionEditingModal",
  HR_PROCESS_ACTION_EDITING_MODAL: "actionEditingModal",
  HR_PROCESS_HR_ACTION_EDITING_LOADER: "hrActionEditingLoader",
  HR_PROCESS_HR_ACTION_EDITING_ID: "hrActionEditingId",
};

export const badgeHash = {
  Shortlisted: "processing",
  Skipped: "processing",
  Scheduled: "warning",
  Selected: "success",
  "Not Selected": "error",
  Hold: "default",
  "In Progress": "success",
  "Not Shortlisted": "error",
  All: "",
  New: "warning",
  "Not Interested": "error",
};
