import { Modal,
  Form,
  Input, 
  Row, 
  Col,
  Tag,
  TimePicker,
  Checkbox,
  Button ,
Radio} from "antd";
import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import moment from 'moment';
import Api from "services/Api";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "./redux/store";
import classes from './timeSlotModal.module.css'
import { Notification } from "services";

// const format = 'HH:mm:ss';
const format = "hh:mm a"
const hourFormat = "HH:mm"



const { RangePicker } = TimePicker;
const { Title } = Typography;
 
const TimeSlotModal = ({setIsClickAssign,isClickAssign,refresh,slotRecord}) =>{
 

const {panelistId}=useSelector(state=>state.panelist);

const [day,setDay]=useState()
const [schedule,setSchedule]=useState([])

const map = {
  'Monday': 1,
  'Tuesday': 2,
  'Wednesday': 3,
  'Thursday': 4,
  'Friday': 5,
  'Saturday': 6,
  'Sunday': 7
};

slotRecord?.scheduleCap?.sort((a,b) =>{

  return map[a.weekday]-map[b.weekday]
})


 const [weekdays, setWeekDays] = useState("");
 // const weekdays= useSelector((state) => state.weekdays)   
 // const dispatch = useDispatch()
 const [starttime,setStartTime] = useState([])
 const [endtime,setEndTime] = useState([])
 const [deletedId, setDeletedId] = useState([]);
 
//  const [addvalue,setAddValue] = useState('')
 const [inputVisible, setInputVisible] = useState(false);
 const [inputValue, setInputValue] = useState([]);
 const [slotTime,setSlotTime] = useState(null)
const [inputVaueCap,setInputValueCap]=useState()
const [inputValCheck,setInputValCheck]=useState(false)
 const [timeadd,setTimeAdd] = useState(true)
 const [tags ,setTags ] =useState(false)
 const [idValue,setIdxValue]=useState()
 const [slotArray,setSlotArray] = useState([])
 const[checkedday,setCheckeddays] = useState([])
 let slotdata=slotRecord?.scheduleCap
 let data=slotRecord?.scheduleCap?.find(element =>element.weekday==="Sunday")
 if(!data){
  slotdata?.push(  {
    weekday:"Sunday",
    maxCap:0,
    },)
 }
 const [maxcapArr,setMaxcapArr] = useState(slotRecord? slotdata:[
  { 
    weekday:"Monday",
    maxCap:0
    
 },
 {   
  weekday:"Tuesday",
  maxCap:0,},
{
   
  weekday:"Wednesday",
  maxCap:0
  
},
{
   
  weekday:"Thursday",
  maxCap:0,
  },
{
   
  weekday:"Friday",
  maxCap:0,
},
  
{
   
  weekday:"Saturday",
  maxCap:0,
  },
  {
    weekday:"Sunday",
    maxCap:0,
    },
  
 
])

 const selectedWeek=()=>{


 }
//  const options = [
//   { label: 'Mon', value: 'Monday' },
//   { label: 'Tue', value: 'Tuesday' },
//   { label: 'Wed', value: 'Wednesday' },
//   { label: 'Thu', value: 'Thursday' },
//   { label: 'Fri', value: 'Friday' },
//   { label: 'Sat', value: 'Saturday' },
// ];
//  const arr = ["Mon","Tue","Wed","Thu","Fri","Sat"]
//  const arr2 = [false,false,false,false,false,false]

// setTimeout(()=>{

// },2000)

     // const slots = [{day:"M",click:false}, {day:"T",click:false}, {day:"W",click:false},{day:"T",click:false}, {day:"F",click:false}, {day:"S",click:false}];
     const slots = [
      { label: 'MON', value: 'Monday' },
      { label: 'TUE', value: 'Tuesday' },
      { label: 'WED', value: 'Wednesday' },
      { label: 'THU', value: 'Thursday' },
      { label: 'FRI', value: 'Friday' },
      { label: 'SAT', value: 'Saturday' },
      { label: 'SUN', value: 'Sunday' },
    ];
   useEffect(() =>{

    if(slotRecord){
    setSlotArray(slotRecord?.schedule)
      setMaxcapArr(slotRecord?.scheduleCap)
      
    }
   },[])
 useEffect(() => {
     if (inputVisible) {
       inputRef.current?.focus();
     }
   }, [inputVisible]);

   useEffect(() => {
     setDay(day)
   }, [slots]);

 const handleCancel = () => {
     setIsClickAssign(false);
     setSlotArray([])
   };

 const onAdd = () =>{
     setIsClickAssign(false);
 }




   const changeHandler = (event, name,id) => {
     let values = parseInt(event.target.value);
    //  if(!values){
    //   return
    //  }
    //  setMaxcapMon(values);
    let obj={}
    if(slotArray){
       obj={
        id:id,
        weekday:name,
        maxCap: values
       }

    }
    else{
      obj={
        weekday:name,
        maxCap: values
       }

    }
 
      
     let valueArr= maxcapArr.filter(element => element.weekday!==name)
   valueArr.push(obj)
   valueArr?.sort((a,b) =>{

    return map[a.weekday]-map[b.weekday]
  })
    setMaxcapArr(valueArr)
  
   };


 

   const submitHandler=(e)=>{
     e.preventDefault()
   }

   const timeDiff = (starttime, endtime) => {
     const startTimeUpdate = moment(starttime, "hh:mm a").format("HH:mm a")
     const endTimeUpdate = moment(endtime, "hh:mm a").format("HH:mm a")
     return moment.utc(moment(endTimeUpdate, "HH:mm").diff(moment(startTimeUpdate, "HH:mm"))).format("HH:mm")
   };
   const setDays = {
     M:"Monday",
     T:"Tuesday",
     W:"Wednesday",
     Th:"Thursday",
     F:"Friday",
     S:"Saturday",
   }
   let temp = [];
   return(
     <>
         <Modal 
         maskClosable={false}
         open={isClickAssign} 
          centered
         onOk={onAdd} 
         onCancel={handleCancel} 
         style={{ top: 10,bottom:10}}
         // handleChange={handleChange}
         width="40rem"
         footer={null} 
         bodyStyle={{height:"100vh"}}
        >
          <div style={{height:"90vh",overflowY:"auto"}}>
         <div style={{margin:"1rem",fontWeight:"600",display:"flex",flexDirection:"column"}}>
         <h1 style={{fontWeight:"bold",margin:"0 auto",fontSize:"1.5rem"}}>Time Slot</h1>

         <Form onSubmit={submitHandler} id="timeform" layout="vertical">
                <Form.Item
                name="interviews_per_day"
                label="INTERVIEWS PER DAY"
                rules={[{ required: true,message: "Please enter interview per day!"}]}>
                       
                          <Row style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"0.5rem"}}>
                          {/* <div style={{display:"flex",flexDirection:"row",gap:"1rem",justifyContent:"space-around"}}> */}
                          {maxcapArr.map((element,i) =>{
                           
                            return (
                              <div style={{flexDirection:"row",display:"flex",gap:"0.5rem",width:"10rem",justifyContent:"space-between",alignItems:"center"}}>
                              
                              <p>{element.weekday}</p>              
                                  <Input value={inputVaueCap!==undefined&&idValue===i?inputVaueCap: element?.maxCap} type="number" min={0} style={{width:'60px',height:'30px',borderRadius:'3px',border:'1.2px solid #0086FF',justifyContent:"center",alignItems:"center",fontWeight:"bold"}} 
                                  onChange={(e) => {
                                    if(!e.target.value){
                                      setInputValueCap("")
                                      setIdxValue(i)
                                      // forceUpdate()
                                      return
                                      
                                    }
                                    
                                    setIdxValue(i)
                                    setInputValueCap(e.target.value)
                                    if(e.target.value){
                                    setInputValCheck(e.target.value < 0 ? true : false)
                                    changeHandler(e,element.weekday,element?.id)
                                  }}}/></div>
                            )
                          })}
                          </Row>
                          
                </Form.Item>             
                 <hr/>

               
               <div className={classes.duration}>
                 <Form.Item
                 style={{width:"100%"}}
                 name="time"
                 label="TIME"
                 rules={[{ required: true,message: "Please Select the times"}]}
                 >                     
                                  <RangePicker 
                                  allowClear
                                     style={{width:"100%"}}          
                                      format={format}
                                      onChange={(value, dateString) => {
                        
                                      if(dateString[0] && dateString[1])
                                      {
                                        setTimeAdd(false)
                                      }
                                      // else{
                                      //  setTimeAdd(true)
                                      // }
                                      setStartTime(dateString[0])                   
                                      setEndTime(dateString[1])
                                   
                                      setSlotTime(dateString[1] ? timeDiff(dateString[0], dateString[1]) : null,)                  
                                  }}
                                  />                    
                 </Form.Item>
                  <Form.Item  label="DURATION"   style={{width:"100%"}} >
                            <TimePicker
                             style={{width:"100%"}} 
                            // style={{width:"40rem",border:"2px solid red"}}
                              placeholder="Duration"
                              format={hourFormat}
                              disabled={true}
                              suffixIcon={null}
                              value={slotTime ? moment(slotTime, hourFormat) : null}
                              // style={{background:'#E5E5E5',border:'1px solid #E5E5E5'}}
                            />              
                  </Form.Item>
              </div>      
         {/* <Row>
         <div style={{marginBottom:'0.5rem'}}>
         <Checkbox 
         onClick={(e)=>{
             setRepeat(!repeat)
             setCheckeddays([])
         }}
         size="medium"
         >
          REPEAT
         </Checkbox> 
         </div>
         </Row>      */}
         
         <Row style={{marginLeft:'0px',marginTop:'0px'}} disabled={true}>
         <Checkbox.Group options={slots}  onChange={(checkedValues) =>{
            setCheckeddays(checkedValues)
         }} />
         </Row>
          
         
    

         <div style={{marginRight:'73%'}} >
          <Row disabled={true}>
         <Button 
         disabled={timeadd}
         type="primary"
         style={{width:'7rem',borderRadius:'2px',marginTop:'10px',height:"2.3rem",fontWeight:"bold",}}
         onClick={(e)=>{
            
             e.preventDefault();
             if(checkedday.length!==0){
             let arrayrepeat=checkedday.map((element) =>{
                return {
                  weekday:element,
                  startTime:starttime,
                  endTime:endtime
                }
             })
            
             let  newArray=slotArray.concat(arrayrepeat)
          
             setSlotArray(newArray)
             setTimeAdd(true)
          
             return
            }
          
         
          //  if((value?.length)===storedval?.maxCap){
          //   Notification.error(`maxcount exceeded for ${day}`)
          //   return
          //  }
             let temp={
              weekday:day,
              startTime:starttime,
              endTime:endtime
             }
             
             if(!day){
              Notification.error("Please select week day")
              return
             }
             setSlotArray(oldArray => [...oldArray,temp])
            
             setTags(true)
             setTimeAdd(true)
             
         }}
         
        >
         ADD
         </Button>
         </Row>
         </div>
             
         <hr style={{marginTop:'30px'}}/>
         <div 
         style={{marginRight:'71%',fontSize:'14px',marginTop:'10px'}}
         >
         ASSIGNED TIME SLOT
         {/* {
             starttime? starttime-endtime:"null"
         } */}
         </div>

         <Row className={classes.assignSlot}>{slotArray?.map((item,index)=>
                <div>
                          <Tag  
                          style={{width:"100%",border:'1.2px solid #0086FF'}}
                          
                        visible={true}
                          closable
                          onClose={(e) => {
                            
                          let array=  slotArray.filter((item,indexed) =>{
                              return index!==indexed
                            })
                            if(slotArray[index]?.id){
                            setDeletedId((oldArray) => [...oldArray, slotArray[index]?.id]);
                            }
                           
                            setSlotArray(array)
                        
                          ;}}          
                            >
                            {<h3>{item.weekday}  {item.startTime} : {item.endTime}</h3>}           
                            </Tag>
                    </div>
                 
            )}
            </Row>
     

         <div style={{  display: "flex", alignItems: "center", justifyContent: "center", width: "100%",marginTop:"0.5rem"}}>
             <Button

             style={{ marginRight: '0.8rem',fontWeight:"700",width:"7rem",height:"2.3rem",border:"1.5px solid #0086FF",color:"#0086FF" }}
             onClick={handleCancel}>
             CANCEL
             </Button>

             <Button
             type="primary"
             disabled={slotArray?.length === 0 ||inputVaueCap ===""|| inputValCheck === true ? true : false}
             style={{ fontWeight:"700",width:"7rem",height:"2.3rem" }}
          
             onClick={(event)=>{
                 setInputValue({...inputValue, [event.target.name] : event.target.value})
                 
                
              
               
              
                // let count=0
                // for(let i =0;i<maxcapArr.length;i++){
                //     count=count+maxcapArr[i].maxCap
                // }
                // if(count!==slotArray.length){
                //   Notification.error("Added Slot And interview per day count is mismatched","")
                //   return
                // }
                
                 Api.post("/hiring-panelist/timeSlot")
                 .params(
                     {
                         panelistId: panelistId,
                         scheduleCap: maxcapArr,
                          schedules:slotArray,
                          deleted:deletedId
              
                         
                     
                     }
                 )
                 .send((res) => {
                    
                     if(res!==undefined && res.show.type==="success"){
                      refresh()
                     setIsClickAssign(false);}
                
                 })
                 
             }}>
             SAVE
             </Button>
         </div>
     </Form>
 </div></div>
</Modal>
</>
)
}

export default TimeSlotModal;