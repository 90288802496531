import { Button, Col, Modal, Row, Select, Avatar, Spin,Typography } from "antd";
import React, { Component } from "react";
import { Color } from "services";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import * as moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import "./styles.css"
import {
  CANDIDATE_IMAGE_VIEW_MODAL,
  CANDIDATE_IMAGE,
} from "pages/candidate/redux/action";
import ViewImage from "pages/candidate/viewImage";
import download from "../../assets/download.png";
import { Api, Notification } from "services";
import classes from "./candidateViewModal.module.css";

const { Option } = Select;
const { Title } = Typography;
class FeedbackViewModal extends Component {
  componentDidMount() {
    const { getCandidateFeedback } = this.props;
    getCandidateFeedback();
  }

  downloadHandler = () => {
    const {
      candidateFeedbackRound,
      candidateFeedback,
    } = this.props;
    Api.get(`/hiring-recruitment/feedback/${candidateFeedback?.id}`)
      .params({
        // candidateId: candidateFeedback?.id,
        download: true,
        round: candidateFeedback && candidateFeedback.feedback &&
        candidateFeedback.feedback[candidateFeedbackRound]?.round
      })
      .send((res)=>{
        if(res?.show?.type !== "error"){
          window.open(res, "_blank")?.focus();
        }
      })
  }
   
  render() {
    const {
      feedbackCandidateInfo,
      viewFeedback,
      feedbackLoader,
      candidateRounds,
      candidatesScreenshot,
      candidateFeedbackRound,
      candidateFeedback,
      selectCandidateRound,
      updateInputField,
      performOperation,
    } = this.props;

    let indexValue = 3;

    return (
      <Modal
      style={{ top: 10,bottom:10}}
        visible={viewFeedback}
        width="70%"
        bodyStyle={{height:"90vh"}}
        centered
        // bodyStyle={{height:"32rem"}}
        title="View Feedback"
        onCancel={() => {
          this.props.setCandidateScreenshot([]);
          this.props.setFeedbackView(false);
        }}
        footer={false}
        // footer={
        //   <Button
        //     shape="round"
        //     onClick={() => this.props.setFeedbackView(false)}
        //     style={{margin:"1rem",width:"6rem"}}
        //   >
        //     Cancel
        //   </Button>
        // }
      >
        {/* <div style={{padding:"2rem"}}> */}
        <Row style={{ justifyContent: "flex-end" }}>
          <Select
            style={{ width: "100px",height:"2.5rem",marginTop:"-0.5rem" }}
            value={candidateFeedbackRound}
            disabled={feedbackLoader}
            selectCandidateRound
            placeholder="Select Round"
            onChange={(val) => selectCandidateRound(val)}
          >
            {candidateRounds?.map((round) => (
              <Option value={round.value}>{round.label}</Option>
            ))}
          </Select>
        </Row>
        <Row >
          {!feedbackLoader ? (
            <Row style={{height:"80vh",overflowY:"auto"}} className={classes.scrollTab}>
              <Col span={6}  >
              <div style={{minHeight:"100%",overflowY:"auto"}}>
                <div className="candidate-details ">
                  <div className="feedback-left-content">
                    {candidateFeedback.photo !== "" ? (
                      <img
                        src={candidateFeedback.photo}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                        alt=""
                        onClick={() => {
                          // updateInputField(CANDIDATE_IMAGE, feedbackCandidateInfo.candidatePhoto);
                          // performOperation(CANDIDATE_IMAGE_VIEW_MODAL, true);
                        }}
                      />
                    ) : (
                      <Avatar size="100" icon={<UserOutlined />} />
                    )}
                    <Row>
                      <Col span={24}>Name of Candidate</Col>
                      <Col span={24} style={{ color: Color.modalblue }}>
                        {candidateFeedback.name}
                      </Col>
                    </Row>
                  </div>
                  <Row className="feedback-left-content">
                    <Col span={24}>Round</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {/* {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound]
                          ?.round} */}
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound] &&
                        candidateFeedback.feedback[candidateFeedbackRound]?.round
                          ? candidateFeedback.feedback[candidateFeedbackRound]?.round
                          : "NA"}
                    </Col>

                    <Col span={24}>Round Type</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound]
                          ?.roundType === "Relationship" ? "HR Relationship"
                          : 
                          // candidateFeedback &&
                          // candidateFeedback.feedback &&
                          // candidateFeedback.feedback[candidateFeedbackRound]
                          //   ?.roundType
                            candidateFeedback &&
                            candidateFeedback.feedback &&
                            candidateFeedback.feedback[candidateFeedbackRound] &&
                            candidateFeedback.feedback[candidateFeedbackRound]?.roundType
                              ? candidateFeedback.feedback[candidateFeedbackRound]?.roundType
                              : "NA"
                      }
                    </Col>

                    <Col span={24}>Name of Interviewer</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {/* {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound]
                          ?.interviewer ? candidateFeedback &&
                          candidateFeedback.feedback &&
                          candidateFeedback.feedback[candidateFeedbackRound]
                            ?.interviewer : "NA"} */}
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound] &&
                        candidateFeedback.feedback[candidateFeedbackRound].interviewer
                          ? candidateFeedback.feedback[candidateFeedbackRound].interviewer
                          : "NA"}
                    </Col>

                    <Col span={24}>Date of Interview</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {/* {candidateFeedback &&
                        candidateFeedback.feedback ?
                        moment(
                          candidateFeedback.feedback[candidateFeedbackRound]
                            ?.interviewDate,
                          "YYYY-MM-DD"
                        ).format("DD MMM YYYY")} */}
                        {candidateFeedback &&
                          candidateFeedback.feedback &&
                          candidateFeedback.feedback[candidateFeedbackRound] &&
                          candidateFeedback.feedback[candidateFeedbackRound].interviewDate
                            ? moment(
                              candidateFeedback.feedback[candidateFeedbackRound]
                                ?.interviewDate,
                              "YYYY-MM-DD"
                            ).format("DD MMM YYYY")
                            : "NA"}
                      {/* {interviewSlots.date
                                ? moment(interviewSlots.date, postDateFormat).format("DD MMM YYYY")
                                : null} */}
                    </Col>

                    <Col span={24}>Mode of Interview</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {/* {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound]
                          ?.interviewMode} */}
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound] &&
                        candidateFeedback.feedback[candidateFeedbackRound].interviewMode
                          ? candidateFeedback.feedback[candidateFeedbackRound].interviewMode
                          : "NA"}
                    </Col>

                    <Col span={24}>Experience</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback.experienceYears} Years{" "}
                      {candidateFeedback.experienceMonths !== 0
                        ? `${candidateFeedback.experienceMonths} Months`
                        : ""}
                    </Col>

                    <Col span={24}>Current Location</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback.currentLocation}
                    </Col>

                    <Col span={24}>Preferred Location</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback.preferredLocation?.name}
                    </Col>
                  </Row>
                </div>
                </div>
              </Col>
              <Col span={18} >
                <div style={{minHeight:"100%"}}>
               
                {candidatesScreenshot?.map((feedbackScreenshot) =>
                  feedbackScreenshot.value + 1 ===
                  candidateFeedback.feedback[candidateFeedbackRound]?.round && feedbackScreenshot.label !== "null"? (
                    <img
                      src={feedbackScreenshot.label}
                      style={{
                        width: "110px",
                        height: "100px",
                        borderRadius: "0%",
                        marginLeft: "1.4rem",
                      }}
                      alt=""
                      onClick={() => {
                        updateInputField(CANDIDATE_IMAGE, feedbackScreenshot.label);
                        performOperation(CANDIDATE_IMAGE_VIEW_MODAL, true);
                      }}
                    />
                  ) : (
                    ""
                  )
                )}
                  <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",alignItems:"center",marginTop:"0.9rem",paddingLeft:"1.2rem",marginBottom:"0.4rem"}}>
                    <h2 style={{fontSize:"1.2rem",fontWeight:"bold",}}>Feedback Questions & Answers</h2>
                    {candidateFeedback && candidateFeedback.feedback?.length === 0 ? ""
                    :
                    <Button
                        style={{
                          borderRadius: "none",
                          color: "blue",
                          fontSize: "1rem",
                          fontWeight: "bold",border:"none",
                        }}
                        icon={
                          <img
                            style={{ width: "24px", height: "24px" }}
                            src={download}
                          ></img>
                        }
                        onClick={this.downloadHandler}
                      />
                      }
                  </div>
                  {console.log("sssDetails",candidateFeedback,candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound] 
                       && candidateFeedback.feedback[candidateFeedbackRound]?.lastRoundHrReview &&
                       candidateFeedback.feedback[candidateFeedbackRound]?.lastRoundHrReview?.reviewData
                        )}
                    {candidateFeedback && candidateFeedback.feedback &&
                          candidateFeedback.feedback[candidateFeedbackRound]?.roundType === "HR" &&
                          candidateFeedback && candidateFeedback.feedback &&
                          candidateFeedback.feedback[candidateFeedbackRound] &&
                          candidateFeedback.feedback[candidateFeedbackRound]?.lastRoundHrReview !== undefined &&
                          candidateFeedback && candidateFeedback.feedback &&
                          candidateFeedback.feedback[candidateFeedbackRound] &&
                          candidateFeedback.feedback[candidateFeedbackRound]?.lastRoundHrReview !== null ?
                          <div style={{overflowY:"scroll",height:"55vh"}}>
                          <div style={{marginLeft:"1.5rem",fontSize:"10px",marginTop:"0.8rem"}}><h2>Evaluated Previous Round Feedbacks :</h2></div>
                          <div style={{height:"45vh"}} className="question2">
                         {candidateFeedback &&
                            candidateFeedback.feedback &&
                            candidateFeedback.feedback[candidateFeedbackRound] &&
                            candidateFeedback.feedback[candidateFeedbackRound]?.lastRoundHrReview &&
                            candidateFeedback.feedback[candidateFeedbackRound]?.lastRoundHrReview?.reviewData &&
                            candidateFeedback?.feedback[candidateFeedbackRound]?.lastRoundHrReview?.reviewData?.map(
                              (item, index) => {
                                const { question, answer, review } = item;
                                return ( 
                                  <div style={{ marginTop: "0.5rem" }} key={index}>
                                    <h4 style={{ color: "#08ff", fontSize: "1rem" }}>
                                      {index + 1}. {question} :{" "}
                                    </h4>
                                    {question === "resume" ? (
                                      <Button
                                        style={{
                                          backgroundColor: "#F2F7F2",
                                          fontWeight: "600",
                                          marginLeft: "1.1rem",
                                        }}
                                        onClick={() => {
                                          window.open(answer);
                                        }}
                                      >
                                        View Resume
                                      </Button>
                                    ) : (
                                      <span style={{ fontWeight: "600", marginLeft: "1.1rem" }}>
                                        {answer}
                                      </span>
                                    )}
                                    {review && (
                                      <div style={{display:"flex",flexDirection:"row",gap:"0.3rem",marginLeft: "1.1rem"}}>
                                        <h4>Review : </h4>
                                        <span>{review}</span>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div style={{marginLeft:"1.5rem",fontSize:"10px",marginTop:"0.8rem"}}><h2>HR Round Feedbacks :</h2></div>
                        <div className="question2" style={{height:"45vh"}}>
                        <div style={{marginTop:"0.5rem",marginLeft:"0.5rem",marginRight:"1.5rem",marginBottom:"0.4rem",}}>
                        {candidateFeedback?.feedback &&candidateFeedback?.feedback[candidateFeedbackRound]?.overallComment === null ? "" :
                              <div>
                                          <h4 style={{color:"#08ff",fontSize:"1rem"}}>1. OverallComment : </h4>
                                            <span style={{fontWeight:"600",marginLeft:"1.1rem"}}>
                                            {/* {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.overallComment} */}
                                            {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound] &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.overallComment
                                              ? candidateFeedback.feedback[candidateFeedbackRound]?.overallComment
                                              : "NA"}
                                            </span>
                              </div>}
                              {candidateFeedback.feedback &&candidateFeedback.feedback[candidateFeedbackRound]?.overallRating === null ? "" :
                              <div style={{marginTop:"0.5rem"}}>
                                          <h4 style={{color:"#08ff",fontSize:"1rem"}}>2. OverallRating : </h4>
                                          <span style={{fontWeight:"600",marginLeft:"1.1rem"}}>
                                          {/* {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.overallRating} */}
                                          {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound] &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.overallRating
                                              ? candidateFeedback.feedback[candidateFeedbackRound]?.overallRating
                                              : "NA"}
                                          </span>
                              </div>}
                              
                        </div>
                        
                    <div style={{marginLeft:"0.5rem",marginRight:"1.5rem",marginBottom:"0.8rem",}}>  
                    <div>
                                            <h4 style={{color:"#08ff",fontSize:"1rem"}}>3. Status : </h4>
                                            <span style={{fontWeight:"600",marginLeft:"1.1rem"}}>
                                            {/* {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.status} */}
                                            {
                                            // candidateFeedback && candidateFeedback.feedback?.length === 0 ? "NA"
                                            // :
                                            candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound] &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.status
                                              ? candidateFeedback.feedback[candidateFeedbackRound]?.status
                                              : "NA"}
                                            </span>
                    </div>
                    {candidateFeedback &&
                      candidateFeedback.feedback &&
                      candidateFeedback.feedback[candidateFeedbackRound] &&
                    Object.entries(
                        candidateFeedback?.feedback[candidateFeedbackRound]?.details
                      )&& Object.entries(
                        candidateFeedback?.feedback[candidateFeedbackRound]?.details
                      )?.map(([key, val,index]) => {
                        indexValue=indexValue+1
                        return(key === "overallComment" || key === "overallrating" || key === "attachment" 
                        (typeof val === "object" && Object.keys(val).includes("file") || Object.keys(val).includes("fileList")) 
                        ? "":
                        <div style={{marginTop:"0.5rem"}}>{key !== "screenshot" &&  <>                   
                        <h4 style={{color:"#08ff",fontSize:"1rem"}}>{indexValue}. {key} : </h4>
                        {key === "resume" ? 
                        <Button style={{backgroundColor:"#F2F7F2",fontWeight:"600",marginLeft:"1.1rem"}}
                        onClick={()=>{
                          window.open(val)
                        }}
                        >View Resume</Button>
                        :
                        typeof val === "string" && (val.startsWith("http://") || val.startsWith("https://")) && val.split('.').pop().toLowerCase() === "pdf" ?
                        <div style={{fontWeight:"600",marginTop:"0.3rem",cursor:"pointer",display:"flex",textAlign:"center",justifyContent:"center",marginLeft:"1.1rem",border:"1px solid #0086FF",padding:"0.5rem",width:"8rem"}}
                        onClick={()=>{
                          window.open(val, "_blank")?.focus();
                        }}> View File </div>
                        :
                        typeof val === "string" && (val.startsWith("http://") || val.startsWith("https://")) && ["png", "jpg", "jpeg", "gif", "bmp"].includes(val.split('.').pop().toLowerCase()) ?
                        <img src={val} alt="" style={{height:"4rem",width:"4rem",marginLeft:"1.1rem"}} />
                        :
                        <span style={{fontWeight:"600",marginLeft:"1.1rem"}}>{val}</span>
                        }
                        </>
                      }</div>)
                      })
                    
                        }
                      </div>
                       </div>
                        </div>
                        :
                       <div style={{height:"56vh",overflowY:"scroll",overflowX:"hidden",marginTop:"0.7rem",marginLeft:"1rem",border:"1px solid #D3D3D3"}}>
                        <div style={{marginTop:"0.5rem",marginLeft:"0.5rem",marginRight:"1.5rem",marginBottom:"0.4rem",}}>
                            {candidateFeedback.feedback &&candidateFeedback.feedback[candidateFeedbackRound]?.overallComment === null ? "" :
                              <div>
                                          <h4 style={{color:"#08ff",fontSize:"1rem"}}>1. OverallComment : </h4>
                                            <span style={{fontWeight:"600",marginLeft:"1.1rem"}}>
                                            {/* {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.overallComment} */}
                                            {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound] &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.overallComment
                                              ? candidateFeedback.feedback[candidateFeedbackRound]?.overallComment
                                              : "NA"}
                                            </span>
                              </div>}
                              {candidateFeedback.feedback &&candidateFeedback.feedback[candidateFeedbackRound]?.overallRating === null ? "" :
                              <div style={{marginTop:"0.5rem"}}>
                                          <h4 style={{color:"#08ff",fontSize:"1rem"}}>2. OverallRating : </h4>
                                          <span style={{fontWeight:"600",marginLeft:"1.1rem"}}>
                                            {/* {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.overallRating} */}
                                          {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound] &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.overallRating
                                              ? candidateFeedback.feedback[candidateFeedbackRound]?.overallRating
                                              : "NA"}  
                                          </span>
                              </div>}
                              
                        </div>
                        
                    <div style={{marginLeft:"0.5rem",marginRight:"1.5rem",marginBottom:"0.8rem",}}>  
                    <div>
                                            <h4 style={{color:"#08ff",fontSize:"1rem"}}>3. Status : </h4>
                                            <span style={{fontWeight:"600",marginLeft:"1.1rem"}}>
                                            {/* {candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.status} */}
                                            {
                                            // candidateFeedback && candidateFeedback.feedback?.length === 0 ? "NA"
                                            // :
                                            candidateFeedback &&
                                            candidateFeedback.feedback &&
                                            candidateFeedback.feedback[candidateFeedbackRound] &&
                                            candidateFeedback.feedback[candidateFeedbackRound]?.status
                                              ? candidateFeedback.feedback[candidateFeedbackRound]?.status
                                              : "NA"}
                                            </span>
                    </div>
                    {candidateFeedback &&
                      candidateFeedback?.feedback &&
                      candidateFeedback?.feedback[candidateFeedbackRound] &&
                    Object.entries(
                        candidateFeedback.feedback[candidateFeedbackRound]?.details
                      )&&Object.entries(
                        candidateFeedback.feedback[candidateFeedbackRound]?.details
                      )?.map(([key, val,index]) => {
                        indexValue=indexValue+1
                        return(key === "overallComment" || key === "overallrating" || key === "attachment" ||
                        (typeof val === "object" && Object.keys(val).includes("file") || Object.keys(val).includes("fileList")) 
                        ? "":
                        <div style={{marginTop:"0.5rem"}}>{key !== "screenshot" &&  <>                   
                        <h4 style={{color:"#08ff",fontSize:"1rem"}}>{indexValue}. {key} : </h4>
                        {key === "resume" ? 
                        <Button style={{backgroundColor:"#F2F7F2",fontWeight:"600",marginLeft:"1.1rem"}}
                        onClick={()=>{
                          window.open(val)
                        }}
                        >View Resume</Button>
                        :
                        typeof val === "string" && (val.startsWith("http://") || val.startsWith("https://")) && val.split('.').pop().toLowerCase() === "pdf" ?
                        <div style={{fontWeight:"600",marginTop:"0.3rem",cursor:"pointer",display:"flex",textAlign:"center",justifyContent:"center",marginLeft:"1.1rem",border:"1px solid #0086FF",padding:"0.5rem",width:"8rem"}}
                        onClick={()=>{
                          window.open(val, "_blank")?.focus();
                        }}> View File </div>
                        :
                        typeof val === "string" && (val.startsWith("http://") || val.startsWith("https://")) && ["png", "jpg", "jpeg", "gif", "bmp"].includes(val.split('.').pop().toLowerCase()) ?
                        <img src={val} alt="" style={{height:"4rem",width:"4rem",marginLeft:"1.1rem"}} />
                        :
                        <span style={{fontWeight:"600",marginLeft:"1.1rem"}}>{val}</span>
                        }
                        </>
                      }</div>)
                      })
                        }
                      </div>
                    </div>
                    }
                    </div>
              </Col>
              
            </Row>

            
            
          ) : (
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin tip="loading" className="on-page-loader"></Spin>
              </div>
            </Col>
          )}
        </Row>
        <ViewImage />
        {/* </div> */}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackViewModal);