import { CheckCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Upload,Select,Checkbox,Modal} from 'antd';
import classes from './fresherRegistration.module.css';
import cameraIcon from '../../assets/cameraIcon.svg';
import profileIcon from '../../assets/profileIcon.svg.svg';
import brigoshaLogo from '../../assets/brigoshaLogo.svg';
import resIcon from '../../assets/resIcon.svg'
import regisSideImg from '../../assets/regisImg2.jpg';
import { useEffect, useState } from 'react';
import { candidateActions } from 'pages/dashboard-candidate/store';
import { useDispatch, useSelector } from 'react-redux';
import OTPModal from 'pages/dashboard-candidate/OTPModal';
import { useHistory } from 'react-router-dom';
import { Api, Notification } from 'services';
import { canRegistrationAction } from './store';
const { Option } = Select;
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
          return {
                  width,
                  height
          };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}
const FresherRegistration=()=>{
    const [loading,setLoading]=useState(true);
    const [imageUrl,setImageUrl]=useState('');
    const [resumeUrl,setResumeUrl]=useState('');
    const [resumeFile,setResumeFile]=useState('');
    const [imageFile,setImageFile]=useState('');
    const [checked, setChecked] = useState(false);
    const [mobile,setMobile]=useState('');
    const [selectedJob,setSelectedJob]=useState('');
    const [btnLoader,setBtnLoader]=useState(false)
    const dispatch=useDispatch();
    const {modalOpen}=useSelector(state=>state.userCandidate);
    const {verifyOTP} = useSelector((state => state.userCandidate));
    const {fresherDetails,sessionId    }=useSelector(state=>state?.canRegistration);
    const { height, width } = useWindowDimensions(); 
    const [width2, setWidth]   = useState(window.innerWidth);
    const modalWidth=width>700?"45%":"100%";
    const history=useHistory();
  const [form]=Form.useForm();
    let params = new URL(location.href);
    let id = params.pathname.split("/");
    const jobDetails=fresherDetails?.jobs;
    const candidateDetails=fresherDetails?.candidate;
    const userId=candidateDetails?.userId;
    const preferredLocation=fresherDetails?.locations;
useEffect(()=>{
  dispatch(candidateActions.updateVerifyOTP(false));
  dispatch(candidateActions.updateModalOpen(false));
},[mobile])
    useEffect(() => {
      Api.get(`/hiring-publicapi/${id[2]==="registerLink" ? "registerLink":"registredCandidate"}/${parseInt(id[3])}/${id[2]==="registerLink" ?true:""}`)
      .send((response) => {
        if(response)
        {
          dispatch(canRegistrationAction.updateFresherDetails(response));
        }
      });
    }, []);
    const resumeBeforeUpload = (file) => {
        const isLt10M = file.size / 1024 / 1024 < 10;
        const docsOrPdf = file.type ==='application/docs' || file.type ==='application/pdf';
        if (!docsOrPdf) {
          Notification.error('You can only upload Docs/PDF file!');
          setResumeUrl('');
          return;
        }
         if (!isLt10M) {
          Notification.error("Image must be smaller than 10MB!");
        }
        else {
          setResumeFile(file);
          getBase64(file, (url) => {
            setLoading(false);
            setResumeUrl(url); 
          });
        }
      }
    const imgbeforeUpload = (file) => {
        const isLt10M = file.size / 1024 /1024 < 10;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'image/jpg';
        if (!isJpgOrPng) {
          Notification.error('You can only upload JPG/PNG/JPEG file!');
          setImageUrl('')
          return;
        }
         if (!isLt10M) {
          Notification.error("Image must be smaller than 10MB!");
        }
        else {
          setImageFile(file);
          getBase64(file, (url) => {
            setLoading(false);          
            setImageUrl(url);       
          });
        }
      }  
      const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
      };     
    return(
        <>
            <header className={classes.mainHeader}>
                        <img src={brigoshaLogo}></img>
            </header> 
          <div className={classes.wrapper}>
                {width>700&&<div>
                        <img src={regisSideImg}></img>
                </div>}
                <div className={classes.container}>
                      <div>
                                      <h1>Candidate Details</h1>
                                      <span>Kindly attach the file and fill in the informations below</span>
                      </div>
                      <div>
                              <div className={classes.uploadPhoto}>
                                                      <Upload  showUploadList={false} beforeUpload={imgbeforeUpload}
                                                                  accept=".png, .jpg, .jpeg"
                                                                  maxCount={1}  
                                                                  onRemove={()=>setImageUrl('')}
                                                                 > 
                                                                    <div>
                                                                          <div className={classes.profileImg}>
                                                                                  {imageUrl&&<img src={imageUrl}></img>}
                                                                                  {imageUrl==''&&<img src={profileIcon} ></img>}                                                                          
                                                                          </div>
                                                                          <Button>
                                                                                <img src={cameraIcon}></img>
                                                                          </Button>
                                                                          <span>Upload Photo</span>
                                                                      </div>                                                                                                                    
                                                    </Upload>
                                      </div>
                                      <span style={{fontSize:"14px",fontWeight:"600",marginBottom:"0.5rem"}}>Upload Resume <span style={{color:"red"}}>*</span></span>
                                      <div className={classes.attachResume} style={{height:"9rem",backgroundColor:"#EAF5FF",overflow:"auto"}} >                                                                                                    
                                                        <Upload.Dragger  name="file" showUploadList={resumeUrl?true:false} beforeUpload={resumeBeforeUpload} 
                                                                        accept=".pdf, .docs"
                                                                        maxCount={1}
                                                                        progress={{
                                                                          strokeColor: {
                                                                            '0%': '#108ee9',
                                                                            '100%': '#87d068',
                                                                          },
                                                                          strokeWidth: 3,
                                                                          format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
                                                                        }}                                                                      
                                                                        onRemove={()=>setResumeUrl('')}
                                                                       style={{backgroundColor:"#EAF5FF",border:"0",height:"5rem"}}
                                                                        >
                                                                         {!resumeUrl&&<div className={classes.drag}>
                                                                                     <span>Drag & Drop It Here<br/>OR</span>                                      
                                                                                    <Button type='primary'>Browse File</Button>
                                                                        </div>   }                                                                           
                                                        </Upload.Dragger>
                                      </div>
            </div>
            <h3>Personal Information</h3>
            <Form layout='vertical' form={form} onFinish={(values)=>{            
              values={...values,
                registerId:id[2]!=="registerLink"?parseInt(id[3]):null,
                userId:id[2]!=="registerLink"?userId:id[3],
                photo:imageFile,
                resume:resumeFile,
                 phone:mobile?.slice(-10)             
                }
                setBtnLoader(true)
              Api.post("/hiring-publicapi/registerFreCandidate").upload(values, (per,res)=>{
                if(res)
                {        
                        if(res.data.show.type==='success'){
                          setBtnLoader(false);
                        history.push("/thanks");
                        return;
                        }
                        setBtnLoader(false);
                }  
                setBtnLoader(false);            
              })
            }}>          
                            <Form.Item name="jobId" label={<span>Job Title</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Select placeholder="Select job title" size='large' onChange={(event)=>{
                                setSelectedJob(event);
                                }
                                }>
                                  {jobDetails?.map((item,index)=>{
                                    return(
                                      <Option key={index} value={item.id}>{item.title}</Option>
                                    )
                                  })}                                  
                                  </Select>                           
                            </Form.Item>
                            <Form.Item name="name" label={<span>Candidate name</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Input placeholder='Enter name'/>
                            </Form.Item>
                           <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",marginBottom:"1rem"}}>
                              <span>Job Location(s)</span>
                             <div>
                               {selectedJob&&jobDetails?.filter(item=>item.id===selectedJob)[0]?.locations?.map((item2,index)=>{
                                        return(
                                          <span key={index} value={item2.id}>{item2.name}{jobDetails?.filter(item=>item.id===selectedJob)[0]?.locations?.length-1!==index?",":""}</span>
                                        )
                                      })}
                              </div>
                            </div>
                            <Form.Item name="preferredLocation" label={<span>Preferred Location</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Select size='large' placeholder="Select location">
                                  {preferredLocation?.map((item,index)=>{
                                    return(
                                      <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                  })}                                 
                                </Select>
                            </Form.Item>
                            <Form.Item name="email" type="email" label={<span>Email id</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Input placeholder='Enter email Id '/>
                            </Form.Item>                          
                                      <Form.Item name="phone" label={<span>Phone number</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                                <div>
                                                          <Input  placeholder='Enter phone number' onChange={(event)=>setMobile(event.target.value)} type="number"/>
                                                          {!verifyOTP&&<Button disabled={mobile.trim().length<10} 
                                                          type="text"
                                                            onClick={()=>{
                                                              dispatch(candidateActions.updateModalOpen(true));                                                                                                                    
                                                              if(id[2]==="registerLink"){
                                                                Api.post("/hiring-publicapi/sendOtpCollege")
                                                                .params({phoneNumber: mobile?.slice(-10) })
                                                                .send((response) => {
                                                                  dispatch(canRegistrationAction.updateSeesionid(response))                                                                                                                                
                                                                });
                                                                return
                                                              }
                                                              Api.post("/hiring-publicapi/registerSendOtp")
                                                                .params({ registerId: parseInt(id[3]), phoneNumber: mobile?.slice(-10) })
                                                                .send((response) => {                                                                                                                                 
                                                                });
                                                            }}>Verify Number</Button>}
                                                            {verifyOTP&&
                                                            <div className={classes.verified}>
                                                                        <CheckCircleOutlined></CheckCircleOutlined>
                                                                        <span>Verified</span>
                                                           </div>  } 
                                                </div>                                                                                                                                                       
                                      </Form.Item>                                                                         
                          <h3>Educational Information</h3>            
                          <Form.Item name="Xth" label={<span>10th Marks(%)</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Input type={"number"} min={0} max={100} oninput="validity.valid||(value='')" placeholder="Enter Marks"/>
                            </Form.Item>
                            <Form.Item name="XIIth" label={<span>12th Marks(%)</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Input  type={"number"} min={0} max={100} placeholder="Enter Marks"/>
                            </Form.Item>
                            <Form.Item name="collegeName" label={<span>College Name </span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Input placeholder='Enter college name'/>
                            </Form.Item>
                            <Form.Item name="department" label={<span>Department(Bachelors)</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Input placeholder='Enter department'/>
                            </Form.Item>
                            <Form.Item name="cgpa"  label={<span>Overall CGPA(Bachelors)</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Input placeholder="Enter CGPA" type="number" min={0} max={10}/>
                            </Form.Item>
                            <Form.Item name="backlogs"
                            label={<span>Backlogs(Bachelors)</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                <Input placeholder='Enter number of backlogs'  type="number"  min={0} max={100}/>
                            </Form.Item>
                            <Form.Item name="instituteName" label={<span>Training Institute Name (optional)</span>}>
                                <Input placeholder='Enter name'/>
                           </Form.Item>
                            <Form.Item name="courseDetail" label={<span>Course Detail ( optional)</span>}>
                                <Input.TextArea placeholder='Enter Detail' rows={6}/>
                            </Form.Item>                              
                            <div>
                                      <Checkbox checked={checked}
                                        onChange ={ (e) => {                                      
                                        setChecked(e.target.checked);
                                      }}
                                      />
                                      <h4>MASTER'S STUDENT</h4>
                            </div>
                           {checked&&
                                  <div >
                                              <h3>Master Degree</h3>
                                              <Form.Item name="masterCollegeName" label={<span>College Name </span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                                        <Input placeholder='Enter college name'/>
                                            </Form.Item>
                                            <Form.Item name="masterDepartment" label={<span>Department</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                                      <Input placeholder='Enter department'/>
                                            </Form.Item>
                                            <Form.Item name="masterCgpa"  label={<span>Overall CGPA</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                                      <Input placeholder="Enter CGPA" type="number" min={0} max={10}/>
                                            </Form.Item>
                                            <Form.Item name="masterBacklogs"  label={<span>Backlogs</span>} rules={[{required:true,message:"Enter valid data!"}]}>
                                                    <Input placeholder='Enter number of backlogs' type="number" min={0} max={10}/>
                                            </Form.Item>
                                    </div>
                            }             
                        <div className={classes.submitBtn}>
                              <Button loading={btnLoader} htmlType='submit' disabled={!resumeFile||!imageFile||!verifyOTP || imageUrl === '' || resumeUrl === ''}>SUBMIT</Button>
                        </div>
            </Form>
            <Modal open={modalOpen&&!verifyOTP}  footer={null} width={modalWidth}  centered closable={true} maskClosable={true} onCancel={()=>dispatch(candidateActions.updateModalOpen(false))}>
                                    <OTPModal registerId={parseInt(id[3])} phone={mobile} calledFrom={'FRESHER_REGISTRATION'}/>
              </Modal>  
           </div>
        </div>
      </>
    )
}
export default FresherRegistration;