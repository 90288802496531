import classes from './panelist.module.css';
import {Select, DatePicker,Spin,Button,Tooltip,Badge} from 'antd'
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import { overallDashboardActions } from '../store';
import {DownloadOutlined,} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import TargetModal from './targetModal';
import { Api } from 'services';
import ViewFeedback from 'pages/feedbackManagementResponse/viewFeedback';
const Option={Select};
const { RangePicker } = DatePicker;
const Panelist=(props)=>{
    const [isHover,setIsHover]=useState(false);
    const [panNames,setPanNames]=useState([]);
    const [panelistName,setPanelistName]=useState([]);
    const [searchPanelist,setSearchPanelist]=useState("");
    const [selectedPanelist,setSelectedPanelist]=useState([]);
    const [selectedJobTitles,setSelectedJobTitles]=useState([]);
    const [selectedDate,setselectedDate]=useState([]);
    const [tableData,setTableData]=useState([]);
    const [sheduleDetails,setSheduleDetails]=useState([]);
    const[onHoverData,setOnHoverData]=useState({day:"",time:""});
    const [isDownload,setIsDownload]=useState(false);
    const [modalData,setModalData]=useState([]);
    const [loader,setLoader]=useState(true);
    const [tabLoader,setTabLoader]=useState(false);
    const [panelistInfo,setPanelistInfo]=useState({});
    const [dataKeys,setDataKeys]=useState("");
    const [dataTable,setDataTable]=useState([]);
    const [summeryLoader,setSummeryLoader]=useState(false);
    const [data,setData]=useState( [
        {
            type: 'Selected',
            value:0,
          },
        {
            type: 'Rejected',
            value:0,
          },  
        {
          type: 'On Hold',
          value: 0,
        },
      ])
    const dispatch=useDispatch();
    const targetModalOpen=useSelector(state=>state?.overallDashboard?.targetModalOpen);
    const [viewFeedbackModal,setViewFeedbackModal]=useState(false);

    const interviewSum=[
        {
                title:"Total Interviews",value:panelistInfo?.Interview?.targetCount,dataKey:"target",
         },
         {
                title:"Scheduled",value:panelistInfo?.Interview?.schdeduleCount,dataKey:"scheduled"
        },
        {
                title:"Conducted",value:panelistInfo?.Interview?.conductedCount,dataKey:"conducted"
        },
        {
            title:"Cancelled",value:panelistInfo?.Interview?.cancelledCount,dataKey:"cancelled"
        },
        {
            title:"Skipped",value:panelistInfo?.Interview?.skippedCount,dataKey:"skipped"
        },
        {
            title:"Feedback Pending",value:panelistInfo?.Interview?.feedbackPendingCount,dataKey:"hold"
        },
]
const config = {
   
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color:({type}) =>{
        if(type==="Rejected"){
            return "#EF5050"
        }
       else if (type === "Selected") {
            return "#0DA253";
          }
          else{
            return "#C4C4C4"
          }
    },
     radius: 1,
    innerRadius: 0.30,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 12,
        margin:"2px",
        fontWeight:"700"
        
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
  };

const dayInFull=(ch)=>{
    let val;
    switch(ch)
    {
        case "Sun":
            val="Sunday"
            break;
            case "Mon":
            val="Monday"
            break;
            case "Tue":
            val="Tuesday"
            break;
            case "Thu":
            val="Thursday"
            break;
            case "Fri":
            val="Friday"
            break;
            case "Sat":
            val="Saturday"
            break;
            case "Wed":
            val="WednesDay"
            break;
    }
    return val;
}
useEffect(()=>{
    // setLoader(true);
    Api.get("/hiring-overview/panelMembers").params({
        search:"",
    }).send(resp=>{
        if(resp)
        {
            // setLoader(false);
            setPanNames(resp);
        }
    })
},[])

let scheduleDetails2=[
    {day:"Sun", slots:[]},
    { day:"Mon", slots:[]},
    { day:"Tue", slots:[]},
    { day:"Wed", slots :[]},
    { day:"Thu", slots:[]},
    { day:"Fri", slots:[]},
    {day:"Sat", slots:[]},
    {availableSlot:[]}
  ]

useEffect(()=>{
    setLoader(targetModalOpen === true ? false : true);
    setTabLoader(true)
    let param=  {}
    
    if(selectedPanelist)
    {
        param={...param,panelistId:selectedPanelist,}
    }
    if(selectedJobTitles?.length>0)
    {
        param={...param,jobId:selectedJobTitles,}
    }
    if(selectedDate.length>0)
    {
        param={...param,startDate:selectedDate?.[0],
            endDate:selectedDate?.[1], }
    }
    // if(dataKeys)
    // {
    //     param ={...param, dataKey: targetModalOpen === true ? dataKeys:undefined}
    // }
    Api.get("/hiring-overview/panelist").params(param).send(res=>{
        if(res)
        { 
            setSheduleDetails([]);
            setSummeryLoader(true);
            Api.get("/hiring-overview/panelistSchedule")
            .params(param)
            .send(res =>{
                setSummeryLoader(false);
                //setPanelistName(res?.panelistName);
                setPanelistName(res?.schedule?.map(item5=>item5.name))    
                for(let i =0;i<res.schedule.length;i++){
                    let totSlots=[];
                   
                Object.keys(res?.schedule[0])?.forEach(key=>{                 
                    if(key!=="name"&&key!=="id") 
                    {                      
                    res?.schedule[i][key]?.map((item,index)=>{
                         let temp=item.startTime+"-"+item.endTime                 
                         if(!totSlots.includes(`${temp}`))
                         {
                             totSlots.push(`${temp}`);
                         }                 
                         return  `${temp}`;
                     })
                    }
                 })               
                     Object.keys(res?.schedule[0])?.forEach(key=>{
                        if(key!=="name"&&key!=="id")
                        {                     
                         let tempArr=[]
                             res?.schedule[i][key]?.forEach((item2,index)=>{                                               
                                 let temp2=item2.startTime+"-"+item2.endTime;                       
                                 tempArr.push({value:`${temp2}`,interviewDetails:item2?.interviewDetails?item2?.interviewDetails:{}});                          
                             })                     
                             scheduleDetails2=scheduleDetails2.map((item,index2)=>{
                                 if(item.day===key)
                                 {
                                     return{
                                         ...item,slots:tempArr,
                                     }                              
                                 }
                                 if(index2===7){
                                     return {availableSlot:totSlots}
                                 }
                                 return item;
                             })  
                            }                                                                       
                      }) 
                      setSheduleDetails((oldArray) => [...oldArray,scheduleDetails2])                                           
                    }                
            }
            )
            setLoader(false);
            setTabLoader(false)
            if(dataKeys)
            {    
                    setTableData(res)
             } 
            setDataTable(targetModalOpen === true ? res: [])
            dispatch(overallDashboardActions.updatePanelistInfo(res));
            setData(data.map(item=>{
                if(item.type==='Selected')
                {
                    return {...item,value:res?.candidateSelectedCount}
                }
                else if(item.type==='Rejected')
                {
                    return {...item,value:res?.candidateRejectedCount}
                }
                else if(item.type==='On Hold')
                {
                    return {...item,value:res?.candidateOnholdCount}
                } 
            }))        
            setPanelistInfo(res);                                
        }
    })
},[selectedJobTitles,selectedPanelist,selectedDate,isDownload,]);

    return(
        <div className={classes.dashboardPan}>        
                        <div>
                                    <h3>Panelist Overview</h3>
                                    {selectedDate.length!==2&&<span style={{color:"#B7B7B7",fontWeight:"400",fontStyle:"italic"}}>Displaying Last 7 Days Data</span>}
                                    {/* <div >
                                      <Button type='primary' style={{display:"flex",marginLeft:"41rem",fontWeight:"630",textAlign:"center",alignItems:"center",borderRadius:"5px"}}>
                                      <DownloadOutlined style={{fontSize:"1.1rem"}}/> Download</Button>
                                    </div> */}
                        </div>
                       
                        <div>
                                            <Select placeholder="All" 
                                                mode='multiple'
                                                showSearch={true}
                                                allowClear={true}
                                                listHeight={160} 
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={selectedPanelist}
                                                onChange={(e)=>{                                              
                                                setSelectedJobTitles([]);
                                                setSelectedPanelist(e);   
                                                setIsHover(false)   
                                                                                       
                                            }}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                              }
                                            defaultValue={""}
                                            >
                                                {panNames?.map((item,index)=>(
                                                    <Option value={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                            <Select  
                                            placeholder="Select Job Profile" 
                                            mode='multiple'
                                            showSearch={true}
                                            listHeight={160} 
                                            getPopupContainer={trigger => trigger.parentNode}
                                            value={selectedJobTitles}
                                            allowClear={true}
                                            onChange={(e)=>{                                               
                                                setSelectedJobTitles(e);
                                            }}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                              }
                                            defaultValue={""}
                                            > 
                                                {panelistInfo?.jobTitles?.map((item,index)=>(
                                                    <Option  value={item.id}>
                                                     {/* <Tooltip title={item.agencyName}>
                                                        <Badge
                                                        status={item.status === "Active" ? "success" : "error"}
                                                        />{" "} */}
                                                        {item.title}
                                                     {/* </Tooltip> */}
                                                    </Option>
                                                ))}
                                            </Select>
                                            <RangePicker onChange={(e,dateString)=>{                                             
                                                if(dateString.includes(''))
                                                {
                                                    setselectedDate([]);
                                                    return;
                                                } 
                                                setselectedDate(dateString);                                             
                                            }}                                          
                                            >
                                            </RangePicker>
                        </div>
                            {loader === true?<Spin style={{margin:"10% auto"}}></Spin>
                            :<div className={classes.dashInfo}>
                                            <div>
                                                            <div>
                                                                            <h4>Interview Summary</h4>
                                                                            <div className={classes.summery}>
                                                                                        {interviewSum?.map((item,index)=>{
                                                                                            return(
                                                                                                <div>
                                                                                                            <div style={{cursor:"pointer"}}
                                                                                                            onClick={()=>{                                                                                                            
                                                                                                                    setModalData({data:item.value,dataKey:item.dataKey,title:item.title});
                                                                                                                    setDataKeys(item?.dataKey)
                                                                                                                    dispatch(overallDashboardActions.updateTargetModalOpen(!targetModalOpen));                                                                                                               
                                                                                                            }}  
                                                                                                            >
                                                                                                                            <span>{item.title}</span>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                               
                                                                                                                            <h4>{item.value}</h4>
                                                                                                            </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                            </div>
                                                            </div>                                                           
                                            </div>
                                            <div>
                                                                            <h4>Interview round outcome </h4>
                                                                            <Pie {...config} style={{maxHeight:"150px"}} />                                                                                                                                    
                                            </div>
                                            <div>                                                       
                                                        <div >
                                                                        <div className={classes.slotInfoDes}>
                                                                                        <span>Time Slot Summary <span style={{color:"gray",fontWeight:"400",fontStyle:"italic"}}>(Displaying current week's data only)</span></span>
                                                                                        <div>
                                                                                                        <div className={classes.activeSlot} style={{width:"1rem",height:"1rem",borderRadius:"50%"}}>
                                                                                                        </div>
                                                                                                        <span style={{marginRight:"1rem"}}>Available</span>
                                                                                                        <div className={classes.completedInterview} style={{width:"1rem",height:"1rem",borderRadius:"50%"}}>
                                                                                                        </div>
                                                                                                        <span>Completed</span>                                                                                                    
                                                                                                        <div className={classes.scheduleInterview} style={{width:"1rem",height:"1rem",borderRadius:"50%"}}>
                                                                                                        </div>
                                                                                                        <span>Scheduled</span>                                                                                                                                                                                                                                                                                                                  
                                                                                                        <div className={classes.feedPending} style={{width:"1rem",height:"1rem",borderRadius:"50%"}}>
                                                                                                        </div>
                                                                                                        <span>Feedback-Pending</span>
                                                                                                        <div className={classes.cancelledInterview} style={{width:"1rem",height:"1rem",borderRadius:"50%"}}>
                                                                                                        </div>
                                                                                                        <span>Cancelled</span>
                                                                                                        <div className={classes.inActiveSlot} style={{width:"1rem",height:"1rem",borderRadius:"50%"}}>
                                                                                                        </div>
                                                                                                        <span>Not Available</span>                                                                                                     
                                                                                        </div>
                                                                        </div>
                                                                        {summeryLoader?<Spin  style={{margin:"10% auto"}}></Spin>:<div>
                                                                            
                                                                                {selectedPanelist?.length>0 ? sheduleDetails.map((scheduleDetail,index) =>{
                                                                                    // if(panelistName[index])
                                                                                    // {
                                                                                        return ( <>
                                                                                            <h3 style={{marginBottom:"0.5rem"}}><span style={{ height: "10px",
                                                                                                               width: "10px",
                                                                                                               backgroundColor: "black",
                                                                                                               borderRadius: "50%",
                                                                                                               display: "inline-block",
                                                                                                               marginRight:"3px",
                                                                                                                }}></span>{panelistName[index]}</h3>
                                                                                                                <div style={{maxWidth:"52rem",overflowX:"auto",marginBottom:"1rem"}}>
                                                                                                                <table>                                                                                                                                                                                       
                                                                                                               {scheduleDetail?.map((item,index)=>{
                                                                                                                       return(
                                                                                                                           <>
                                                                                                                               {index===7&& <tr>
                                                                                                                                       <td></td>
                                                                                                                                   {scheduleDetail[7]?.availableSlot?.map((item4)=>{                                                                                                                         
                                                                                                                                           return(
                                                                                                                                               <> 
                                                                                                                                               <td>
                                                                                                                                               <span style={{margin:"0.5rem",color:"#AFB2BB",fontWeight:"500",fontSize:"0.83rem"}}>{item4}</span>
                                                                                                                                               </td>
                                                                                                                                               </>
                                                                                                                                           )                                                                                                                           
                                                                                                                                   })}
                                                                                                                                   </tr>}
                                                                                                                                   {index!==7&&<tr>
                                                                                                                                       <td style={{fontWeight:"500",color:"#AFB2BB",fontSize:"0.84rem"}}>{item.day}</td>                                                                                                                                                                                                               
                                                                                                                             { scheduleDetail[7]?.availableSlot?.map((item2,index2)=>{                                                                                                                              
                                                                                                                                     let temp=item?.slots?.filter(item3=>item3.value===item2) ;                                                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                               if(temp.length>0)
                                                                                                                               {                                                                                                                      
                                                                                                                                   if(Object.keys(temp[0]?.interviewDetails).length!==0)
                                                                                                                                   {                                                                                                                                                                                                                                                 
                                                                                                                                       if(temp[0]?.interviewDetails?.interviewStatus==='Cancelled')
                                                                                                                                       {
                                                                                                                                           return(
                                                                                                                                               <td>
                                                                                                                                                   <div  style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                                                                                                                               <div className={classes.cancelledInterview} 
                                                                                                                                                                       onClick ={()=>{
                                                                                                                                                                           setIsHover(true);
                                                                                                                                                                           setOnHoverData({day:item.day,time:item2,...temp[0]?.interviewDetails})
                   
                                                                                                                                                                       }}                                                                                  
                                                                                                                                                                       >
                                                                                                                                                               </div>
                                                                                                                                                   </div>
                                                                                                                                               </td>
                                                                                                                                           )
                                                                                                                                       }
                                                                                                                                       if(temp[0]?.interviewDetails?.interviewStatus==='Completed')
                                                                                                                                       {
                                                                                                                                           return(
                                                                                                                                               <td>
                                                                                                                                                   <div  style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                                                                                                                               <div className={classes.completedInterview} 
                                                                                                                                                                       onClick ={()=>{
                                                                                                                                                                           setIsHover(true);
                                                                                                                                                                           setOnHoverData({day:item.day,time:item2,...temp[0]?.interviewDetails})       
                                                                                                                                                                       }}                                                                                  
                                                                                                                                                                       >
                                                                                                                                                               </div>
                                                                                                                                                   </div>
                                                                                                                                               </td>
                                                                                                                                           )
                                                                                                                                       }
                                                                                                                                       if(temp[0]?.interviewDetails?.interviewStatus==='Feedback-Pending')
                                                                                                                                       {
                                                                                                                                           return(
                                                                                                                                               <td>
                                                                                                                                                   <div  style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                                                                                                                               <div className={classes.feedPending} 
                                                                                                                                                                       onClick ={()=>{
                                                                                                                                                                           setIsHover(true);
                                                                                                                                                                           setOnHoverData({day:item.day,time:item2,...temp[0]?.interviewDetails})      
                                                                                                                                                                       }}                                                                                  
                                                                                                                                                                       >
                                                                                                                                                               </div>
                                                                                                                                                   </div>
                                                                                                                                               </td>
                                                                                                                                           )
                                                                                                                                       }
                                                                                                                                       if(temp[0]?.interviewDetails?.interviewStatus==="Scheduled")
                                                                                                                                       {                                                                                                                 
                                                                                                                                           return(
                                                                                                                                               <td>
                                                                                                                                                   <div  style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                                                                                                                               <div className={classes.scheduleInterview} 
                                                                                                                                                                       onClick ={()=>{
                                                                                                                                                                           setIsHover(true);
                                                                                                                                                                           setOnHoverData({day:item.day,time:item2,...temp[0]?.interviewDetails})       
                                                                                                                                                                       }}                                                                                  
                                                                                                                                                                       >
                                                                                                                                                               </div>
                                                                                                                                                   </div>
                                                                                                                                               </td>
                                                                                                                                           )
                                                                                                                                       }                                                                                                                                                                                                                                             
                                                                                                                                   }
                                                                                                                                   else
                                                                                                                                   {                                                                                                                      
                                                                                                                                   return(
                                                                                                                                       <td>
                                                                                                                                           <div  style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                                                                                                                       <div className={classes.activeSlot} 
                                                                                                                                                               onClick ={()=>{
                                                                                                                                                                   setIsHover(true);
                                                                                                                                                                   setOnHoverData({day:item.day,time:item2,...temp[0]?.interviewDetails})
                                                                                                                                                               }}                                                                                                                                                  
                                                                                                                                                               >
                                                                                                                                                       </div>
                                                                                                                                           </div>
                                                                                                                                       </td>
                                                                                                                                   )
                                                                                                                                                           }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                                                                                                                                     
                                                                                                                               } 
                                                                                                                               else
                                                                                                                               {                                                                                                                                                                                
                                                                                                                                   return(                                                                       
                                                                                                                                   <td>
                                                                                                                                       <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                                                                                                                   <div className={classes.inActiveSlot} style={{cursor:"not-allowed"}}>
                                                                                                                                       </div> 
                                                                                                                                       </div>
                                                                                                                                   </td>
                                                                                                                           )
                                                                                                                                   }                                                                       
                                                                                                                           }) }                                                                                                                           
                                                                                                                                   </tr>}                                                                                                                   
                                                                                                                           </>
                                                                                                                       )                                                                                                   
                                                                                                               })}
                                                                                           </table>
                                                                                           </div>
                                                                                           </>)
                                                                                    //}
                                                                               
                                                                                })
                                                                               :<div style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center",margin:"auto",marginTop:"15%"}}>
                                                                                    <span style={{fontWeight:"600",fontSize:"1rem",color:"gray"}}>Select a panelist to see the time slots</span>
                                                                                    </div>}
                                                                        </div>}                                                      
                                                        </div>
                                                        <div className={classes.onHover}>
                                                                    {!isHover&&selectedPanelist&&<h4>Click on the Slots to view the Details</h4>}
                                                                    {isHover&&selectedPanelist&&<div>
                                                                                {onHoverData?.candidate?.name&&<h4>Candidate's Name:</h4>}          
                                                                                {onHoverData?.candidate?.name&&<span>{onHoverData?.candidate?.name}</span>}
                                                                                {onHoverData?.interviewStatus&&<h4>Interview Status:</h4>}          
                                                                                {onHoverData?.interviewStatus&&<span>{onHoverData?.interviewStatus}</span>}
                                                                                {onHoverData?.interviewDate&&<h4>Interview Date:</h4>}          
                                                                                {onHoverData?.interviewDate&&<span>{onHoverData?.interviewDate}</span>}                                                                                
                                                                                <h4>Day:</h4>
                                                                                <span>{dayInFull(onHoverData?.day)}</span>
                                                                                <h4>TIME</h4>
                                                                                <span>{onHoverData?.time}</span>
                                                                                {onHoverData?.interviewStatus==="Completed"&&<Button type="primary" size="small" onClick={()=>{
                                                                                    setViewFeedbackModal(true);
                                                                                }}>View Feedback</Button>}
                                                                    </div>}
                                                                    {!selectedPanelist&&<span style={{fontWeight:"600",fontSize:"1rem",color:"gray"}}>No data to display!</span>}
                                                        </div>
                                                        {targetModalOpen&&<TargetModal summery={modalData} 
                                                        tableData={tableData} selectedPanelist={selectedPanelist} 
                                                        selectedJobTitles={selectedJobTitles} dataKeys={dataKeys}
                                                        startDate ={selectedDate[0]} endDate={selectedDate[1]}
                                                        tabloader={tabLoader} datatable={dataTable}/>}
                                            </div>
                          </div>}
                          {viewFeedbackModal &&
                            <ViewFeedback
                            feedbackType={"Panelist"}
                            details={onHoverData}
                            viewFeedbackModal={viewFeedbackModal}
                            setViewFeedbackModal={setViewFeedbackModal}/>
                            }      
        </div>
    )
}
export default Panelist;