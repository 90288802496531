import { createSlice } from "@reduxjs/toolkit";

const initialState={
    fresherDetails:[],
    sessionId:"",
    experienceDetails:{}

}
const canRegistrationSlice=createSlice({
    name:"canRegistration",
    initialState,
    reducers:{
        updateFresherDetails(state,action){
            state.fresherDetails=action.payload;
        },
        updateSeesionid(state,action){
            state.sessionId=action.payload;
        },
        updateExperienceDetails(state,action){
            state.experienceDetails=action.payload
        }

    }

})

export default canRegistrationSlice.reducer;
export const canRegistrationAction=canRegistrationSlice.actions;