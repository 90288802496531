import { createSlice } from "@reduxjs/toolkit";
const initialState={
    moduleKey:'',
    sidemenu:false,
}
const moduleSlice=createSlice({
    name:"moduleKey",
    initialState,
    reducers:{
        updatemoduleKey(state,action){
            state.moduleKey=action.payload;
        },
        setSidemenu(state,action){
            state.sidemenu=action.payload;
        }
    }
})
export default moduleSlice.reducer;
export const moduleActions=moduleSlice.actions;



