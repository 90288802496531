import React, { useState } from "react";
import styled from "./style.css";
import { Form, Modal, Button, Upload, Col, Row, Typography,Result } from "antd";
import Api from "services/Api";
import { InboxOutlined, PropertySafetyFilled } from "@ant-design/icons";
import { adminActions } from "./store";
import { useDispatch,useSelector } from "react-redux";
import Notification from "services/Notification";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const { Title } = Typography;

const ImportData = ({ setLoader,refresh }) => {
   const [isModalVisible2,setIsModalVisible2] = useState(false)
  const {duplicates,} = useSelector((state) => state.candidateFresher)
  const {duplicateMail,duplicatephone,type}  = useSelector((state) => state.candidateFresher.excelDuplicates)
  const [btnLoader,setBtnLoader] = useState(false)
  const [uploadList,setUploadList] =useState(true)
  const dispatch = useDispatch();
  
  const onFinish = (values) => {
    if((!values?.dragger) || (values?.dragger.length===0)  )
    {   let title="please import file"
      Notification.error(title)
      return
    }
    setLoader(true)
    const params = {
      file:values?.dragger[0]?.originFileObj,
      };
     
      setBtnLoader(true)
    Api.post("/hiring-candidate/storeExcelData?file=2")
    .upload(params, (percentCompleted, response) => {
      setIsModalVisible(false)
      setBtnLoader(false)
      setLoader(false);
     if(response?.show?.type === "error" && response?.message === "Only excel (.xlsx and .csv) files are allowed!"){
        setLoader(false);
     }
      if (typeof response != "undefined" && percentCompleted === 100) {
       
        if (response.data.show.type === "error" && response?.data?.show?.message==="Duplicate Data Found In Portal") {
          setLoader(false);
          setIsModalVisible2(true)
          dispatch(adminActions.updateDuplicates({ duplicates: response?.data?.duplicates }))
        }
        else if (response?.data?.show.type === "error" && response?.data?.show?.message==="Duplicate Data Found In Excel") {
          setLoader(false);
          setIsModalVisible2(true)
          dispatch(adminActions.updateExcelDuplicates(response?.data))
        }
        setUploadList(false)
        setLoader(false);
        setBtnLoader(false)
        refresh()    
      }
   
    });
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setBtnLoader(false)
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };
  let ob={}
  duplicatephone?.forEach(element => {
    ob[element.phone]=true
  });
  let duplicateEmails;
  duplicateEmails= duplicateMail?.map((duplicate,index) => {
   
    if(!ob[duplicate.phone])
    {
      return(
        <Row><Col span={8}><p><b style={{marginRight:"10px"}}>name:</b>{duplicate.Name}{"  "}</p></Col><Col span={8}><p><b style={{marginRight:"10px"}}> email:</b>{duplicate.Email}</p></Col><Col span={8}><p><b style={{marginRight:"10px"}}>phone:</b>{duplicate.Phone}</p></Col></Row>
      )
    }
  })

  return (
  <>
            {duplicates?.length!=0 ? 
                <Modal title="Candidates already present in portal" visible={isModalVisible2} closable={true}
                  maskClosable={true} onCancel={handleCancel2} footer={false} style={{minWidth:1000}}>
                            <Result
                              status="error"
                              title="Submission Failed"
                              subTitle="Please check and modify the following information before resubmitting."
                              style={{height:"20%"}}
                            />
                              {duplicates?.map((duplicate) => {
                                return(
                                  <Row><Col span={8}><p><b style={{marginRight:"10px"}}>name:</b>{duplicate.name}{"  "}</p></Col><Col span={8}><p><b style={{marginRight:"10px"}}> email:</b>{duplicate.email}</p></Col><Col span={8}><p><b style={{marginRight:"10px"}}>phone:</b>{duplicate.phone}</p></Col></Row>
                                )
                              })}                       
                </Modal>
                 : (duplicatephone?.length!=0 && duplicatephone != undefined) || (duplicateMail?.length!==0 && duplicateMail!=undefined) ? 
                <Modal title="Candidates having duplicates in excel" closable={true} maskClosable={true} visible={isModalVisible2}  onCancel={handleCancel2} footer={false} style={{minWidth:1000}}>
                                <Result
                                  status="error"
                                  title="Submission Failed"
                                  subTitle="Please check and modify the following information before resubmitting."
                                  style={{height:"20%"}}
                                />
                                <h1> Duplicates</h1>
                                  {duplicatephone?.map((duplicate) => {  
                                    return(
                                      <Row><Col span={8}><p><b style={{marginRight:"10px"}}>name:</b>{duplicate.name}{"  "}</p></Col><Col span={8}><p><b style={{marginRight:"10px"}}> email:</b>{duplicate.email}</p></Col><Col span={8}><p><b style={{marginRight:"10px"}}>phone:</b>{duplicate.phone}</p></Col></Row>
                                    )
                                  })}   
                                      {duplicateEmails}
                </Modal> :"" }    
              <div className={styled}>
                        <>
                                  <Button
                                    // type="primary"
                                    onClick={showModal}
                                    style={{ paddingRight: "20px", backgroundColor:"#4DAAFF",color:"#FFFFFF",paddingLeft: "20px" ,height:"2.2rem",width:"7rem",borderRadius:"5px"}}
                                  >
                                    <b>IMPORT</b>
                                  </Button>
                                  <Modal                                  
                                    visible={isModalVisible}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                    footer={false}
                                    centered={true}
                                  >
                                    <div style={{display:"flex",flexDirection:"column"}}>
                                             <h2 style={{fontWeight:"bold",margin:"1rem auto"}}>Import data</h2>                                                                   
                                              <Form onFinish={onFinish}>
                                                          <Form.Item
                                                            name="dragger"
                                                            valuePropName="fileList"
                                                            getValueFromEvent={normFile}
                                                            noStyle
                                                          >
                                                                      <Upload.Dragger name="files" action="/upload.do" showUploadList={uploadList}>
                                                                                 <div style={{padding:"1rem",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                                                                              <p className="ant-upload-drag-icon">
                                                                                                <InboxOutlined />
                                                                                              </p>
                                                                                              <p className="ant-upload-text">
                                                                                                Click or drag file to this area to upload
                                                                                              </p>
                                                                                              <p className="ant-upload-hint">
                                                                                                Support for a single or bulk upload.
                                                                                              </p>
                                                                                  </div>
                                                                      </Upload.Dragger>
                                                          </Form.Item>{" "}
                                                          <br /> <br />
                                                          <Form.Item
                                                            wrapperCol={{
                                                              span: 24,
                                                              offset: 0,
                                                            }}
                                                          >
                                                                    <Row justify="center">
                                                                                  <Col span={6}>
                                                                                          <Button onClick={handleCancel} style={{ width: "100%",fontWeight:"700",border:"1.3px solid gray"}}>
                                                                                            Cancel
                                                                                          </Button>
                                                                                  </Col>
                                                                                  <Col span={6} offset={2}>
                                                                                            <Button
                                                                                              type="primary"
                                                                                              htmlType="submit"
                                                                                              style={{
                                                                                                width: "100%",
                                                                                                fontWeight:"700",
                                                                                                letterSpacing:"0.8px"
                                                                                              }}
                                                                                              loading={btnLoader}
                                                                                            >
                                                                                              Upload
                                                                                            </Button>
                                                                                  </Col>
                                                                    </Row>
                                                          </Form.Item>
                                              </Form>
                                      </div>      
                                  </Modal>
                        </>
              </div>
    </>
  );
};
export default ImportData;
