import { createSlice } from "@reduxjs/toolkit";
import store from "pages/dashboard-home/store";

const initialState = {
   
    info:{},
    OpenFeedback:false,
    hrRoundQuestions:{},
      
};

const feedbackQuestion = createSlice({
  name: "feedbackQuestion",
  initialState,
  reducers: {
    updateFeedbackQuestion: (state, action) => {
      state.info= { ...action.payload };
    },

    updateOpenFeedback: (state, action) => {
      state.OpenFeedback= { ...action.payload };
    },

    updateHrRoundQuestions: (state, action) => {
      state.hrRoundQuestions= { ...action.payload };
    }
   
  //  updateWarning(state,action)
  //  {
  //    state.warnings=action.payload
  //  }
  },
});
export default feedbackQuestion.reducer;
export const feedbackQuesActions = feedbackQuestion.actions;