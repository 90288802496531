import React from 'react';
import classes from './HRRound.module.css';
import { DatePicker,Input,Space,Row,Select,Card,Popover,Tabs,Table,Button,Radio,Spin,Badge,Typography } from 'antd';
import { useState } from 'react';
import searchIcon from '../../../assets/searchIcon.png';
import { Pie } from '@ant-design/plots';
import { useEffect } from 'react';
import { Api } from 'services';
import moment from "moment";
import ViewModal from "./ViewModal"
import { overallDashboardActions } from '../store';
import {DownloadOutlined,} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';


const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const { TabPane } = Tabs;
const Option={Select};


const HRRound=()=>{

    const [pagekey,setPageKey]=useState("1")
    const [types, setTypes] = useState("Experienced");
    const [dateVal,setDateVal] = useState([])
    // const [endDate,setEndDate] = useState("")    
    const [searchVal,setSearchVal]=useState("")
    const [jobLists,setJobLists] = useState([])
    const [jobId,setJobId] = useState([])
    const [info,setInfo] = useState([])
    const [datas,setDatas] = useState([])
    const [candidatetList,setCandidatetList] = useState([])
    const [candidatetListCount,setCandidatetListCount] = useState("")
    const [tableLoader, setTableLoader] = useState(false);
    const [loader,setLoader]=useState(true);
    const [modalData,setModalData]=useState([]);
    const [pageChange, setPageChange] = useState(1);
    const [pageSize,setPageSize]=useState(100);
    // const [dataKeys,setDataKeys]=useState("");
    const viewModalOpen=useSelector(state=>state?.overallDashboard?.viewModalOpen)
    const dataKeys=useSelector(state=>state?.overallDashboard?.dataKeys)
    const dispatch=useDispatch();



    useEffect(()=>{
      setLoader(true);
      if(!jobId)
      {
        setTimeout(()=>{
          setLoader(false)
        },3000)
      }

      let param={
        candidateType: types,
        // jobId: jobId?jobId:"",
      }
        if(jobId)
        {
          param={...param,jobId:jobId}
        }
        if(dateVal.length>1&&dateVal[0])
        {
          param={...param,startDate:dateVal[0],endDate:dateVal[1]}
        }
        
      Api.get('/hiring-overview/hrRound')
         .params(param)
         .send((response)=>{
        
          if (typeof response != "undefined") {
            setLoader(false);
            setJobLists(response?.jobs);
            setInfo(response);
          }
         })
     },[types,jobId,dateVal,])

     
    useEffect(()=>{
      setTableLoader(true)

      let param={
        candidateType: types,
        search: searchVal,
        page:pageChange,
        limit:pageSize,
      }
        if(jobId)
        {
          param={...param,jobId:jobId}
        }
        if(dateVal.length>1&&dateVal[0])
        {
          param={...param,startDate:dateVal[0],endDate:dateVal[1]}
        }
        
      Api.get('/hiring-overview/hrRoundData')
         .params(param)
         .send((response)=>{
        
          if (typeof response != "undefined") {
            setTableLoader(false);
            setCandidatetList(response?.hrcandidates);
            setCandidatetListCount(response?.hrCandidatesCount)
          }
         })
     },[types,jobId,dateVal,searchVal,pageSize,pageChange])



    const candidateSum=[
        {
                title:"Offer Released",value:info?.offerReleased,dataKey:"offerReleased",
        },
        {
               title:"Offer Accepted",value:info?.offerAccepted,dataKey:"offerAccepted",
         },
        //  {
        //   title:"Offer Rejected",value:info?.offerDeclined,dataKey:"offerRejected",
        //  },
        {
                title:"Offered",value:info?.offered,dataKey:"offered",
        },
        {
                title:"Offer Declined",value:info?.offerDeclined,dataKey:"offerDeclined",
        },
        {
                title:"Joined",value:info?.joined,dataKey:"joined",
        },
        {
                title:"Not Joined",value:info?.notJoined,dataKey:"notJoined",
        },
        {
                title:"Not Interested",value:info?.notInterested,dataKey:"notInterested",
        },
        {
                title:"Not Responding",value:info?.notResponding,dataKey:"notResponding",
        },
        {
                title:"On Hold",value:info?.onHold,dataKey:"onHold",
        }, 
        {
                title:"Not Offered",value:info?.notOffered,dataKey:"notOffered",
        },
        {
                title:"Dead End",value:info?.deadEnd,dataKey:"deadEnd",
        },
        {
                title:"Offer Rejected",value:info?.offerRejected,dataKey:"offerRejected",
        },
        // {
        //         title:"DeadEnd",value:info?.deadEnd,dataKey:"deadEnd",
        // },
    ]

    const data = [
      {
        type: 'Offer Accepted',
        value: info?.offerAccepted?.length,
      },
      {
        type: 'Offer Rejected',
        value: info?.offerRejected?.length,
      },
      
    ];

    const config = {
      appendPadding: 65,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 0.85,
      innerRadius: 0.7,
      color:({ type }) => {
        if(type === 'Offer Accepted'){
          return '#4C9DD4';
        }
        return '#F17777';
      },
      label: {
        type: 'inner',
        offset: '-50%',
        content: '{value}',
        style: {
          textAlign: 'center',
          fontSize: 12,
          fontWeight:"700",
        },
      },
      interactions: [
        {
          type: 'element-accepted',
        },
        {
          type: 'element-active',
        },
      ],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          content: '',
        },
      },
    };

    const columns1 = [ 
        {
            title:<div className={classes.headerName}>
                    <span>NAME</span>
              </div>,
            dataIndex: 'name',
            key: 'name',
    
            render: (text, record) => {
              return (
                    <div>{text}</div>
              );
          }
        },
        {
            title:<div className={classes.headerName}>
                    <span>SHORTLISTED ON</span>
            </div>,
            dataIndex: 'shortlistedOn',
            key: 'shortlistedOn',
           
        render: (text, record) => {
          const date=moment(record?.updatedAt).format('DD-MM-YYYY')
          return (
                <div>{date?date:"NA"}</div>
          );
         }
        },
        {
            title:<div className={classes.headerName}>
                    <span>OFFER RELEASED</span>
            </div>,
            dataIndex: 'offerReleased',
            key: 'offerReleased',
            render: (text, record) => {
              const date=moment(record?.hrStatus?.createdAt).format('DD-MM-YYYY')
              return (
                   <div>{date?date:"NA"}</div>
              );
          } 
        },
        {
            title:<div className={classes.headerName}>
                  <span>OFFER STATUS</span>
            </div>,
            dataIndex: 'offerStatus',
            key: 'offerStatus',
            render: (text,record) => {
                return(<>
                      <div>{record?.hrStatus?.hrStatus ? record?.hrStatus?.hrStatus:"NA"}</div>
                      <div>DOJ: {record?.hrStatus?.expectedDoj?record?.hrStatus?.expectedDoj:"NA"}</div>
                      </>
                     );
                  }
         },
         {
            title:<div className={classes.headerName}>
                  <span>REASON</span>
            </div>,
            dataIndex: 'reason',
            key: 'reason',
            render: (text,record) => {
             
                return(
                      <div>{record?.hrStatus?.hrComment?record?.hrStatus?.hrComment:"NA"}</div>
                     );
                  }
         },
      ];

      const columns2 = [ 
        {
            title:<div className={classes.headerName}>
                    <span>NAME</span>
            </div>,
            dataIndex: 'name',
            key: 'name',
    
            render: (text, record) => {
              return (
                    <div>{text}</div>
              );
          }
        },
        {
            title:<div className={classes.headerName}>
                    <span>TEST DATE</span>
                </div>,
            dataIndex: 'shortlistedOn',
            key: 'shortlistedOn',
           
        render: (text, record) => {
          const dateData = record?.result?.length > 0 ? `${record?.result[0]?.createdAt}` :undefined
          const data = dateData ? moment(dateData).format('YYYY-MM-DD'):"NA"
          return (
                <div>
                  {data}
                </div>
          );
         }
        },
        {
            title:<div className={classes.headerName}>
                    <span>OFFER RELEASED</span>
            </div>,
            dataIndex: 'offerReleased',
            key: 'offerReleased',
            render: (text, record) => {
              const date=moment(record?.hrStatus?.createdAt).format('DD-MM-YYYY')
              return (
                   <div>{date?date:"NA"}</div>
              );
          } 
        },
        {
            title:<div className={classes.headerName}>
                  <span>OFFER STATUS</span>
            </div>,
            dataIndex: 'offerStatus',
            key: 'offerStatus',
            render: (text,record) => {
                return(<>
                      <div>{record?.hrStatus?.hrStatus?record?.hrStatus?.hrStatus:"NA"}</div>
                      <div>DOJ: {record?.hrStatus?.expectedDoj?record?.hrStatus?.expectedDoj:"NA"}</div>
                      </>
                     );
                  }
         },
         {
            title:<div className={classes.headerName}>
                  <span>REASON</span>
            </div>,
            dataIndex: 'reason',
            key: 'reason',
            render: (text,record) => {
     
                return(
                      <div>{record?.hrStatus?.hrComment?record?.hrStatus?.hrComment:"NA"}</div>
                     );
                  }
         },

      ];

      const handlePageChange = (page, pageSize) => {
        setPageChange(page);
        setPageSize(pageSize);
      };

    return(
        <>
           <div className={classes.title}>
            <div style={{display:"flex",flexDirection:"row",gap:"1rem",width:"98%"}}>
            <h3>HR Round</h3>
            <span style={{ fontWeight: "490",fontStyle: "italic",color: "#B7B7B7",marginTop: "0.3rem",fontSize: "13px",}}>Displaying Last 7 Days Data</span>
            </div>
            {/* <div style={{marginBottom:"0.5rem"}}>
                  <Button type='primary' style={{fontWeight:"630",borderRadius:"5px"}}
                   onClick={()=>{
                    let param={
                      candidateType: types,
                      search: searchVal,
                      download:true,
                    }
                      if(jobId)
                      {
                        param={...param,jobId:jobId}
                      }
                      if(dateVal.length>1&&dateVal[0])
                      {
                        param={...param,startDate:dateVal[0],endDate:dateVal[1]}
                      }
                    Api.get('/hiring-overview/hrRound')
                       .params(param)
                       .send((res) => {
                         if (typeof res != "undefined") {                                               
                           window.open(res, "_blank")?.focus();
                        }
                      })
                    }}>
                  <DownloadOutlined style={{fontSize:"1.1rem"}}/> Download</Button>
            </div> */}
           </div>
           {/* <Row> */}
           <div style={{ display: 'flex',marginLeft:"1rem",border:"1px", justifyContent:"space-between",width:"100%",marginBottom:"0.1rem"}}>
            <div className={classes.radio}>
            <Radio.Group bordered={false}
                onChange={(e)=>{
                    setJobId([]);
                    setTypes(e.target.value)
                    setDateVal([])
                }}
                value={types}>
                <Radio value="Experienced" 
                    style={{color:types==="Experienced"?"#0086FF":"#161616"}}>Experienced Candidates</Radio>
                <Radio value="Fresher"
                style={{color:types==="Fresher"?"#0086FF":"#161616"}}>Fresher Candidates</Radio>
            </Radio.Group>
         </div>
          
         <div>
          <Select
                  placeholder="All"
                  allowClear={true}
                  mode='multiple'
                  listHeight={160} 
                  getPopupContainer={trigger => trigger.parentNode}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={jobId}
                  defaultValue={""}
                  className={classes.selectDiv}
                  bordered={false}
                  onChange={(event) => {
                    setJobId(event)
                  }}
                >
                  {/* <Option value="">All</Option> */}
                  {jobLists?.map((item, i) => (
                    <Option key={i} style={{ width: "100%" }} value={item?.id}>
                    {/* <Tooltip title={item.agencyName}>
                    <Badge
                       status={item.status === "Active" ? "success" : "error"}
                     />{" "} */}
                    {item?.title}
                  {/* </Tooltip> */}
                    </Option>
                  ))}
                </Select>
                {/* <div>
                                
                </div> */}
           </div>
           <div>{""}</div>

          { types === "Experienced" && 
         <div>  
          <Space direction="vertical">
    
                <RangePicker
                    format={dateFormat}
                    allowClear={true}
                    onChange={(date, dateString) => {
                      setDateVal(dateString)               
                 }}/>
                
          </Space>
         </div>
         }
         { types === "Fresher" && 
         <div>  

                <RangePicker
                    format={dateFormat}
                    allowClear={true}
                    onChange={(date, dateString) => {
                      setDateVal(dateString)               
                 }}/>
         </div>
         }
         <div>{""}</div>
         </div>
         {/* </Row> */}
        
         {/* <Row>
          <div>
          <Select
                  placeholder="Select Job Profile"
                  allowClear={true}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={jobId?jobId:undefined}
                  defaultValue={""}
                  className={classes.selectDiv}
                  bordered={false}
                  onChange={(event) => {
                    setJobId(event)
                  }}
                >
                  <Option value="">All</Option>
                  {jobLists.map((item, i) => (
                    <Option key={i} style={{ width: "100%" }} value={item.id}>
                     {item.title}
                    </Option>
                  ))}
                </Select>
           </div>
         </Row> */}
        {loader  && searchVal === "" ? <Spin style={{margin:"10% auto"}}></Spin>
        :
        <div style={{margin:"1rem",}} >
        <Row>
         <div style={{display:'flex',flexDirection:'row',marginTop:"1.1rem",width:"100%",gap:"2rem",marginBottom:"1rem",height:"14rem"}} >
          
          <Card bordered={false} style={{ flex:"1 1 75%"}}>
            <div>
                <h3 className={classes.jobTitle}>Candidates Summary</h3>
            </div>
            <div className={classes.summary}>
                  {candidateSum?.map((item,index)=>{
                        return(
                            <div >
                              <div style={{cursor:"pointer"}} onClick={()=>{
                                dispatch(overallDashboardActions.updateDataKeys(item.dataKey))
                                // setDataKeys(item?.dataKey)
                                setModalData({data:item.value,dataKey:item.dataKey,title:item.title});
                                dispatch(overallDashboardActions.updateViewModalOpen(true))
                            }} >
                               {item.title === "Offer Released" ? 
                                
                                <Popover trigger="hover" placement="top"
                                    content={<>
                                      <div>Offered + Offer Accepted + </div>
                                      <div>Offer Declined + Joined + </div>  
                                      <div>NotJoined</div>  
                                      </>} >   
                                  <span>{item.title}</span>
                                </Popover>
                                : item.title === "Offer Rejected" ? 
                                
                                <Popover trigger="hover" placement="top"
                                    content={<>
                                      <div>Offer Declined + Not Joined + </div>
                                      <div>Not Interested + Not Responding  </div>  
                                      </>} >   
                                  <span>{item.title}</span>
                                </Popover>
                                :
                                <span>{item.title}</span>
                               }
                              </div>
                              <div>
                                 <h4>{item?.value}</h4>
                              </div>
                            </div>
                          )
                     })}
              </div>
          </Card>
        
        
          {/* <Card style={{ flex: "1 1 23%",}}>
           <div>
                <h3 className={classes.jobTitle}>Offer Released Summary</h3>
            </div>
            <div className={classes.piechart}>
               <Pie {...config} style={{marginLeft:"-2rem"}}/>
            </div>
           
          </Card> */}
        </div>
       </Row> 
       <Row> 
                <div className={classes.searchDiv}>
                    <div>
                        <img src={searchIcon}></img>
                    </div>
           
                    <Input
                     type="text"
                     bordered={false}
                     placeholder='Search'
                     allowClear={true}
                     style={{color:"#161616", fontSize:"16px"}}
                     onChange={(event)=>{
                      setSearchVal(event.target.value)
                     }}

                     >
                     </Input>
                </div>
        </Row>  
        <div style={{marginTop:"1rem"}}>
        { types === "Fresher" ?
           <div>
                <Table
                    columns={columns2}
                    loading={tableLoader}
                    dataSource={candidatetList}
                    scroll={{ y: 160 }}
                    pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                    pageSizeOptions:[10, 30, 50, 100], 
                    size:"small",
                    total: candidatetListCount,
                    pageSize: pageSize, 
                    onShowSizeChange: handlePageChange,
                    onChange:handlePageChange,
                    }}
                  />
            </div>
            :
            <div>
                    <Table
                        columns={columns1}
                        loading={tableLoader}
                        dataSource={candidatetList}
                        scroll={{ y: 160 }}
                        pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        pageSizeOptions:[10, 30, 50, 100], 
                        size:"small",
                        total: candidatetListCount,
                        pageSize: pageSize, 
                        onShowSizeChange: handlePageChange,
                        onChange:handlePageChange,
                        }}
                    />
            </div>
            }
            
        </div>

       
        {viewModalOpen && <ViewModal modaldata={modalData}
         startDate={dateVal[0]}
         endDate={dateVal[1]}
         datakeys={dataKeys} jobprofile={jobId}
         types={types}
         />}

        </div>
        }

    
    </>
    )
}

export default HRRound;