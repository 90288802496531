class Filter{
    constructor(){
        this.role=false
        this.tableType = false;
        this.logType = false;
    }

    resetFitler(){
        this.role=false
        this.tableType=false;
        this.logType = false;
    }
}

export default Filter;