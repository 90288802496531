import React,{useRef} from "react";
import classes from './feedback.module.css';
import {
    Table,
    Row,
    Col,
    Tabs,
    Space,
    Modal,
    Typography,
    Input,
    Button,
    Card,
    Carousel,
    Select,
    Pagination,
    DatePicker,
    Dropdown,
    Menu,
    Spin,Radio
  } from "antd";
import Api from "services/Api";
// import filter from "../assets/filter.png";
import { SearchOutlined,EyeOutlined,ArrowRightOutlined,ArrowLeftOutlined } from "@ant-design/icons";
import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  feedbackActions } from "./store";
import moment from "moment";
import Download from '../../assets/download.png';
import DownloadIcon from '../../assets/downloadIconNew.svg';
import FilterIcon from "../../assets/filterIconNew.svg";
import { setFeedbackView } from "pages/hr-process/redux/action";
// import ViewFeedback from "./viewFeedback";
import {
  FilterOutlined,
} from "@ant-design/icons";
import { useWindowDimensions } from "components/layout";
const { TabPane } = Tabs;
const {Title} =Typography;
const {RangePicker}=DatePicker;

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//           return {
//                   width,
//                   height
//           };
// }
// export function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);
//   return windowDimensions;
// }

const InterviewSchedules = () => {
  const { height, width } = useWindowDimensions(); 
  const slider = useRef(null);
  const [currentCarousel,setCurrentCarousel]=useState(1)
  const [loader, setLoader] = useState(false)
  const [holdLoader, setHoldLoader] = useState(false)
  const dispatch = useDispatch()
  const feedbacks = useSelector(state => state?.panFeedback?.feedbackList)
  
  const [feedbacksLists,setFeedback] = useState([])

  const [status,setStatus]=useState(false);
  const [statusName,setStatusName]=useState("Pending Feedback");
  const [searchVal, setSearchVal] = useState("");
  const [pageChange, setPageChange] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [viewFeedbackModal,setViewFeedbackModal]=useState(false);
  const[details,SetDetails] = useState("");
  const [statusOpen, setStatusOpen] = useState(false)
  const [statusValue, setStatusValue] = useState("")
  const [statusReason, setStatusReason] = useState("");
  const [dateRangeVal, setDateRangeVal]=useState([]);
  const [columns, setFilteredColumns] = useState([]);
  const [filteredStatus,setFilteredStatus]=useState("");
  const [filteredMobStatus,setFilteredMobStatus]=useState("");
  const [filterModal,setFilterModal]=useState(false);
  const questions=details !== "" && details?.details;
  const lastRoundData =details !== "" && details?.lastRoundHrReview?.reviewData;

const getData=(download=false)=>{
  setLoader(true)
  Api.get(`/hiring-panelist/panelistfeedbacks/?feedback=${status}&search=${searchVal}&page=${pageChange}&limit=${pageSize}&startDate=${dateRangeVal[0]?dateRangeVal[0]:""}&endDate=${dateRangeVal[1]?dateRangeVal[1]:""}&status=${filteredStatus}&download=${download}`)

     .send((response) => {
      if (typeof response != "undefined") {
        if(download){
          const link = document.createElement('a');
          link.href = response;
          const filename = response.substring(response.lastIndexOf('/') + 1);
          link.setAttribute('download', filename);
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          if (isSafari) {
            link.setAttribute('download', filename);
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            link.click();
          }
          setLoader(false)
        }else{
          if(response.items.length===0){
           setPageChange(1)
          }
         dispatch(feedbackActions.updateFeedback(response?.items));
         setFeedback(response?.meta)
         // setItemsPerPage(response?.meta?.itemsPerPage)
         // setCurrentPage(response?.meta?.currentPage)
         // setTotalItems(response?.meta?.totalItems)
         setLoader(false)
        }
      }
    });
}

const getHoldData=()=>{
    setHoldLoader(true)
   Api.get(`/hiring-panelist/panelistHoldFeedbacks?search=${searchVal}&page=${pageChange}&limit=${pageSize}&startDate=${dateRangeVal[0]?dateRangeVal[0]:""}&endDate=${dateRangeVal[1]?dateRangeVal[1]:""}`)
     .send((response) => {
      if(response.items.length===0){
        setPageChange(1)
      }
      if (typeof response != "undefined") {
        dispatch(feedbackActions.updateFeedback(response?.items));
        setFeedback(response?.meta)
        setHoldLoader(false)
      }
    });
}

const getStatus=()=>{
  
  Api.patch('/hiring-hrprocess/updateHrFeedback')
     .params({
      status:statusValue,
      panelistId:details?.panelistId,
      feedbackId:details?.id,
      reason:statusReason,
      candidateId:statusName==="OnHold Feedback" ?details?.candidate?.id: details?.candidateId,
      roundType:details?.roundType
     })
     .send((response)=>{
      if(width<420){
        getData()
      }
      getHoldData()
     })
}

  useEffect(() => {
    if(statusName!=="OnHold Feedback"){
    getData();
    }
  }, [status,searchVal,pageChange,pageSize,statusName,dateRangeVal[1] !== undefined &&dateRangeVal,filteredStatus]);

  
  useEffect(() => {
    if(statusName==="OnHold Feedback"){
    getHoldData()
    }
  }, [statusName,searchVal,pageChange,pageSize,dateRangeVal[1] !== undefined &&dateRangeVal]);


  const handlePageChange = (page, pageSize) => {
    console.log(page,pageSize)
    setPageChange(page);
    setPageSize(pageSize);
  };

  // const holdHandlePageChange = (page, pageSize) => {
    
  //   setHoldPageChange(page);
  //   setHoldPageSize(pageSize);
  // };

  const MobFeedCard=({item,key})=>{
    let dates=moment(item?.date)?.format('YYYY-MM-DD');
    return(
      <div className={classes.mobFeedCard}>
            <div>
              <span>{statusName === "OnHold Feedback" ? item?.candidate?.name 
               :item?.candidateName}</span>
              <span>{statusName === "OnHold Feedback" ? item?.candidate?.job?.title 
               :item?.jobTitle}</span>
            </div>
            <div>
              <div>
                <span>Round</span>
                <span>{item?.roundType}</span>
              </div>
              <div>
                <span>Date</span>
                <span>{item?.date!=="undefined" ? dates:"NA"}</span>
              </div>
            </div>
            <div>
              <div>
                <span>Time</span>
                <span>{item?.startTime!=="undefined"?item?.startTime:"NA"} - {item?.endTime!=="undefined"?item?.endTime:"NA"}</span>
              </div>
              <div>
                <span>Status</span>
                <span>{item?.status ? item?.status:"NA"}</span>
              </div>
            </div>
            <div style={{textAlign:"center"}}>
          {
            status === true || statusName==="OnHold Feedback" ? 
             <div>
              <Button style={{color:"#2797FF",backgroundColor:"#E1EBFE",width:width>700?"11.5rem":"100%",height:"2.5rem",borderRadius:"5px"}}
              onClick={(e)=>{
                SetDetails(item)
                setViewFeedbackModal(true)
              }}
              >
                Feedback Submitted<EyeOutlined />
              </Button>
            </div>
          : 
          <div>
            <Button style={{color:"#FFFFFF",backgroundColor:"#0086FF",width:width>700?"10rem":"100%",height:"2.5rem",borderRadius:"5px"}}
             onClick={(e)=>{
              window.open(item?.feedbacklink, "_blank")?.focus();
              getData();
             }}
            >
              Feedback Required
            </Button>
          </div>
          }
          </div>
        </div>
    )
  }

  
  const dataSource = [
    {
      key: '1',
      jobTitle: 'Node JS',
      name: 'candidate name',
      datetime: '6-Dec-2022,02pm - 03pm',
      round: "Technical Round",
      feedbackLink:"http://www.brigosha.com",
    },
  ];  

  let columnsIni = [
    {
      title: <div className={classes.headerName} style={{minWidth:"5rem",textAlign:"center"}}>
            <span>JOB TITLE</span>
              </div>,
      dataIndex: "jobTitle",
      key: "jobTitle",

      render: (text, record) => (
        <div style={{textAlign:"center"}}>
          <Typography.Text ellipsis={{tooltip:true}} style={{width:"8rem",textAlign:"center"}}>
            {record?.jobTitle ? record?.jobTitle
            : statusName==="OnHold Feedback" ? record?.candidate?.job?.title
            :"NA"}
          </Typography.Text>
        </div>
      ),
    },

    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center"}}>
                      <span>CANDIDATE NAME</span>
              </div>,
      dataIndex: "name",
      key: "name",
 
      render: (text,record) => {
       
        return(
          <div style={{textAlign:"center"}}>
           <Typography.Text ellipsis={{tooltip:true}} style={{width:"8rem",textAlign:"center"}}>
            {record?.candidateName ? record?.candidateName 
            : statusName==="OnHold Feedback" ? record?.candidate?.name
            :"NA"}
           </Typography.Text>
          </div>
        )
      },
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"6rem",textAlign:"center"}}>
                        <span>ROUND</span>
                </div>,
      dataIndex: "round",
      key: "round",

      render:(text,record)=>{
        return(
          <div style={{textAlign:"center"}}>
            {record?.roundType && record?.roundType === "Relationship" ? "HR Relationship" 
            : record?.roundType ? record?.roundType :"NA"}
          </div>)}
    },
    {
      title:  <div className={classes.headerName} style={{minWidth:"9rem",textAlign:"center"}}>
                  <span>DATE & TIME</span>
              </div>,
      dataIndex: "datetime",
      key: "datetime",
    
      render:(text,record)=>{
      
        let dates=moment(record?.date)?.format('YYYY-MM-DD')
        
        return(
          <div style={{textAlign:"center"}}>
            {
              status === true ? <>
              <div>{record?.date!=="undefined" ? dates:"NA"}</div> 
             <div style={{textAlign:"center"}}>{record?.startTime!=="undefined"?record?.startTime:"NA"} - {record?.endTime!=="undefined"?record?.endTime:"NA"}</div> 
              </>
              : statusName==="OnHold Feedback" ? <>
              <div>{record?.date!=="undefined" ? dates:"NA"}</div>
              <div style={{textAlign:"center"}}>{record?.startTime!=="undefined"?record?.startTime:"NA"} - {record?.endTime!=="undefined"?record?.endTime:"NA"}</div> 
              </>
              : <>
              <div>{record?.date!=="undefined" ? dates:"NA"}</div>
              <div style={{textAlign:"center"}}>{record?.startTime!=="undefined"?record?.startTime:"NA"} - {record?.endTime!=="undefined"?record?.endTime:"NA"}</div> 
              </>
            }
          </div>
        )}
    },
    statusName === "Completed Feedback"&&
    {
      title: <div className={classes.headerName} style={{minWidth:"5rem",textAlign:"center",textAlign:"center",alignItems:"center"}}>
                      {/*  */}
                      <div>
        <Space size="small">
        <span>FEEDBACK STATUS</span>
          <Dropdown
            overlay={()=>{
              const iterableStatus = [
                "All","Selected","Not Selected","Hold"
              ];
              return (
                <Menu  selectedKeys={[0]} style={{ height:"135px",overflowY:"scroll", left: "-90%" }}>
                {[...iterableStatus].map((status) => (
                  <Menu.Item
                    key={status}
                    onClick={() => {
                      if(status==="All"){
                        setFilteredStatus("")
                      }else
                      setFilteredStatus(status)
                    }}
                  >
                    {" "}
                    {status}
                  </Menu.Item>
                ))}
              </Menu>
              )
            }}
            onClick={() =>
            ""
            }
            trigger={["click"]}
            id="status-dropdown"
          >
            <div>
            <FilterOutlined />
            <div style={{fontWeight:500,color:"#2797FF"}}>{filteredStatus!==''?filteredStatus:"All"}</div>

            </div>
           
          </Dropdown>
        </Space>
       
      </div>
                </div>,
      dataIndex: "feedbackStatus",
      key: "feedbackStatus",
        
      render:(text,record)=>{
        return(
          <div style={{textAlign:"center"}}>
            {record?.status}
          </div>
          )}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"8rem",textAlign:"center",textAlign:"center",alignItems:"center"}}>
                      <span>FEEDBACK LINK</span>
                </div>,
      dataIndex: "feedbackLink",
      key: "feedbackLink",
        
      render:(text,record)=>{
        console.log("sssRecord",record);
        return(
          <div style={{textAlign:"center"}}>
          {
            status === true || statusName==="OnHold Feedback" ? 
             <div>
              <Button style={{color:"#2797FF",backgroundColor:"#E1EBFE",width:"11.5rem",height:"2.5rem",borderRadius:"5px"}}
              onClick={(e)=>{
                SetDetails(record)
                setViewFeedbackModal(true)
              }}
              >
                Feedback Submitted<EyeOutlined />
              </Button>
            </div>
          : 
          <div>
            <Button style={{color:"#FFFFFF",backgroundColor:"#0086FF",width:"10rem",height:"2.5rem",borderRadius:"5px"}}
             onClick={(e)=>{
              window.open(record?.feedbacklink, "_blank")?.focus();
              getData();
             }}
            >
              Feedback Required
            </Button>
          </div>
          }
          </div>
          )}
    },
   
    
  ];
  const renderStatus = () =>{
    columnsIni = columnsIni.filter((item) => item);
    setFilteredColumns(columnsIni)
  }
  useEffect(()=>{
    renderStatus()
  },[statusName,filteredStatus])

  const mobView=()=>{
    return(
      <>
      {width<700&&<div style={{display:"flex",gap:"0.5rem",flexDirection:"column",marginBottom:"0.5rem"}}>
        {/* <RangePicker style={{borderRadius:"5px",width:"100%",height:"2.2rem"}} format={'YYYY-MM-DD'} onChange={(date,dateString)=>{
            setDateRangeVal(dateString);
            }}
        /> */}
          <Input
            type="text"
            placeholder='Search by candidate name'
            style={{color:"#161616",width:width>700?"17rem":"100%",fontWeight:"600",borderRadius:"5px",height:"2.3rem"}}
            onChange={(event)=>{

            setSearchVal(event.target.value)
            }}
            prefix={<SearchOutlined className="custom-search-icon" style={{ Color:"#0086FF", }} />}
          >
          </Input>
          <div style={{display:"flex",flexDirection:"row",gap:"0.5rem"}}>
          <div style={{width:statusName!=="Completed Feedback"?"100%":"60%",borderRadius:"1px",border:"1.5px solid #D9D9D9",display: "flex",height:"2.3rem",backgroundColor:"white" }}>
            <DatePicker format={"YYYY-MM-DD"} style={{width:"100%",height:"2.1rem"}} bordered={false}
              allowClear placeholder="Start Date"
              onChange={(date, dateString) => {
                if(!dateString){
                  setDateRangeVal([]); 
                }else{
                setDateRangeVal([dateString, dateRangeVal[1]]);  
                }            
              }}
              value={dateRangeVal[0] !== "" && dateRangeVal[0] !== undefined && dateRangeVal[0] !== null ? moment(dateRangeVal[0], "YYYY-MM-DD") : null}
            />
            <span style={{ fontSize: "1rem", alignItems: "center" }}>-</span>
              <DatePicker format={"YYYY-MM-DD"} style={{width:"100%",height:"2.1rem"}} bordered={false}
              allowClear  disabled={!dateRangeVal[0] && !dateRangeVal[1]} placeholder="End Date"
                onChange={(date, dateString) => {
                  setDateRangeVal([dateRangeVal[0], dateString]);              
                }}
                value={dateRangeVal[1] !== "" && dateRangeVal[1] !== undefined && dateRangeVal[1] !== null ? moment(dateRangeVal[1], "YYYY-MM-DD") : null}
              />
          </div>
          {statusName ==="Completed Feedback" &&
            <div style={{width:"40%",borderRadius:"1px",border:"1.5px solid #D9D9D9",display: "flex",height:"2.3rem",backgroundColor:"white",alignItems:"center",cursor:"pointer",fontSize:"0.9rem",justifyContent:"space-between",padding:"0 0.3rem"}}
            onClick={() =>{setFilterModal(true)}}>
              <span style={{color:filteredStatus===""&&"#CACACA",fontWeight:400}} >{filteredStatus!==""?filteredStatus:"Filter status"}</span>
              <img src={FilterIcon} style={{height:"1rem",color:"#CACACA"}} />
            </div>
          }
          </div>
          </div>}

            {statusName ==="OnHold Feedback" && holdLoader?<Spin style={{display:"flex",alignItems:"center",height:"50vh",justifyContent:"center"}}/>: 
            statusName !=="OnHold Feedback" && loader?<Spin style={{display:"flex",alignItems:"center",height:"50vh",justifyContent:"center"}}/>:
                feedbacks?.length>0?<div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
                  {feedbacks?.map((item,index)=>{
                    return(
                      <MobFeedCard item={item} key={index}/>
                    )
                  })}
            
            <Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginBottom:"1rem"}}
              current={pageChange} showSizeChanger={true} total={feedbacksLists?.totalItems} pageSize={pageSize} 
              onChange={handlePageChange}
               onShowSizeChange={handlePageChange} 
              pageSizeOptions={[10,20,30,50,100]}
              />
                </div>:<span style={{height:"50vh",display:"flex",alignItems:"center",justifyContent:"center"}}>No data!</span>}
        </>
    )
  }

    return (
      <div style={{height:"90vh",overflowY:"auto",padding:width>700?0:"0.3rem 1rem"}}>
        {width > 700 ?
        <h1 style={{ margin: "1% 0 0 1.5%",fontWeight:"bold",color:"#161616",fontSize:"1.5rem"}}>
        Feedback
        </h1>
        : <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
        <h1 style={{ margin: "",fontWeight:"bold",color:"#161616",fontSize:"1.26rem"}}>
        Feedback
        </h1>
        <div className={classes.headIcon} style={{display:"flex",flexDirection:"row",gap:"0.7rem",alignItems:"center",color:"#0086FF",}}> 
            {statusName === "Completed Feedback" &&<span style={{cursor:"pointer",}}>
              <img src={DownloadIcon} onClick={() =>{getData(true)}}/> Download</span>
            }
        </div>
        </div>
        }
        <div
          style={{
            padding:width>700&&"0.1% 1.5% 1.5% 1.5%",
            width: "100%",
            boxSizing:"border-box",
            
          }}
        >
         {/* <Row> 
         <div style={{ display: 'flex', gap:"1rem",justifyContent:"space-between",width:"99%",marginTop:"0.1rem"}}>
  
         </div>
          </Row>   */}
          
         {
        //  width>420?
          <Tabs 
          defaultActiveKey="Pending Feedback"
          onChange={(key)=>{
           if(key==="Pending Feedback")
           {
             setStatus(false)
             setStatusName("Pending Feedback")
           }
           else if(key === "OnHold Feedback"){
            
             setStatusName(key)
           }
           else
           {
            setStatus(true);
             setStatusName("Completed Feedback")
           }
           setSearchVal("")
          }
         }
          style={{ overflow: "hidden",fontSize:width>700&&"16px"}}
          tabBarExtraContent={
            width>700?<div className={classes.headIcon}> 
            {
              statusName === "Completed Feedback"&&
              <img src={Download}
                                style={{ cursor:"pointer", width: "25px", height: "25px",  marginRight: '10px' ,fontWeight:"bold"}}  onClick={() =>{
                                  getData(true)
                                }}/>
            }

            <RangePicker style={{marginRight:"1.3rem",borderRadius:"5px",width:"14rem",}} format={'YYYY-MM-DD'} onChange={(date,dateString)=>{

                setDateRangeVal(dateString);
              }}
                
             />

            <Input
               type="text"
               placeholder='Search by candidate name'
               style={{color:"#161616",width:"17rem",fontWeight:"600",borderRadius:"5px",}}
               onChange={(event)=>{
              
                setSearchVal(event.target.value)
               }}
               prefix={<SearchOutlined className="custom-search-icon" style={{ Color:"#0086FF", }} />}
               >
              </Input>
              
        </div>:null
          }
        >
         
          
          <TabPane tab={width>700?"Pending Feedback":"Pending"} key="Pending Feedback">
            <div>
              {width>700?<Table columns={columns} loading={loader} dataSource={feedbacks} rowClassName={classes.rowStyle} className={classes.table}
               pagination={{ 
                position: ["bottomRight"],
                showSizeChanger: true,
                pageSizeOptions:[10, 20,30, 50, 100],
                pageSize: pageSize, 
                size:"small",
                total:feedbacksLists?.totalItems,
                showTotal: (total, count) =>
                `${count[0]}-${count[1]} of ${total} items`,
                onChange: handlePageChange,
                onShowSizeChange: handlePageChange,                      
              }}
              scroll={{
                y:600,
                }}/>:
                 mobView()
                }    
            </div>
          </TabPane >

          <TabPane tab={width>700?"Completed Feedback":"Completed"} key="Completed Feedback">
            <div>
              {width>700?<Table columns={columns} loading={loader} dataSource={feedbacks} rowClassName={classes.rowStyle} className={classes.table}
               pagination={{ 
                position: ["bottomRight"],
                showSizeChanger: true,
                pageSizeOptions:[10,20, 30, 50, 100],
                pageSize: pageSize, 
                size:"small",
                total:feedbacksLists?.totalItems,
                showTotal: (total, count) =>
                `${count[0]}-${count[1]} of ${total} items`,
                onChange: handlePageChange,
                onShowSizeChange: handlePageChange,                      
              }}
              scroll={{
                y:600,
                }}/>:
                 mobView()
              }     
            </div>
          </TabPane >
          <TabPane tab={width>700?"OnHold Feedback":"On-hold"} key="OnHold Feedback">
            <div>
              {width>700?<Table columns={columns} dataSource={feedbacks} loading={holdLoader} rowClassName={classes.rowStyle} className={classes.table}
               pagination={{ 
                position: ["bottomRight"],
                showSizeChanger: true,
                pageSizeOptions:[10,20, 30, 50, 100],
                pageSize: pageSize, 
                size:"small",
                total:feedbacksLists?.totalItems,
                showTotal: (total, count) =>
                `${count[0]}-${count[1]} of ${total} items`,
                onChange: handlePageChange,
                onShowSizeChange: handlePageChange,                      
              }}
              scroll={{
                y:600,
                }}/>:
                mobView()
              }     
            </div>
          </TabPane >

       </Tabs>
    //     :
    //    <Tabs defaultActiveKey="Uncompleted Feedback"  onChange={(key)=>{
            
    //         if(key==="Uncompleted Feedback")
    //         {
    //           setStatus(false)
    //         }
    //         else
    //         {
    //           setStatus(true);
    //         }
    //         setStatusName(key)
    //        }
    //       }>
         
    //         <TabPane tab="Pending Feedback" key="Uncompleted Feedback">
    //         <Card style={{width:"97%",  backgroundColor:"#F2F7F2",marginTop:"20px"}} >   
    //         <Carousel ref={slider} dotPosition="top" afterChange={(value)=>{
    //             setCurrentCarousel(value+1)
    //         }}>   
    //         {feedbacks.length>0?feedbacks?.map((element,index) =>{
              
    //           return(
              
                  
    //             <div key={index} style={{backgroundColor:"#F2F7F2",marginTop:"8px"}}>
    //               <h3>JOB TITLE :</h3>
    //               <p style={{color:"grey",marginTop:"4px",marginBottom:"12px"}}>{element?.jobTitle}</p>
    //               <h3>CANDIDATE NAME </h3>
    //               <p style={{color:"grey",marginTop:"4px",marginBottom:"12px"}}>{element?.candidateName}</p>
    //               <h3>ROUND TYPE</h3>
    //               <p style={{color:"grey",marginTop:"4px",marginBottom:"12px"}}>{element?.roundType}</p>
    //               <h3>DATE AND TIME</h3>
    //               <p style={{color:"grey",marginTop:"4px",marginBottom:"12px"}}>{element?.date}, {element?.startTime}-{element?.endTime}</p>
               
    //               <Button 
    //       disabled={element?.interviewStatus ==="Completed" || element?.interviewStatus ==="Feedback-Pending"} 
    //       onClick={() => {
    //         window.open(element?.feedbacklink, "_blank")?.focus();
    //       }} 
    //       style={{ backgroundColor: element?.interviewStatus==="Completed"||element?.interviewStatus ==="Feedback-Pending"?"#6F6F6F":'#0086FF',color:"white",fontFamily:"Open Sans",
    //       fontWeight:"bold",height:"2rem",borderRadius:"5px",textAlign:"center",fontSize:"0.9rem",marginTop:"16px",
    //     width:width<400?"100%":""}} 
    //       size="small">
    //         Feedback Link
    //       </Button>
    //             </div>
            
    //           )
              
    //         }):<Row justify={"center"} >No Data Available</Row>}</Carousel>    </Card>
    //         { feedbacks.length>0?<Typography.Text style={{color:"black",fontSize:"20px"}}><ArrowLeftOutlined onClick={() =>slider.current.prev()
           
    //       } style={{fontSize:"30px",marginRight:"30px"}} />{currentCarousel}/{feedbacks.length}<ArrowRightOutlined  style={{fontSize:"30px",marginLeft:"30px"}}  onClick={() =>slider.current.next()
           
    //       }/></Typography.Text>:""}
    //      {feedbacks.length>0? <Pagination
    //           style={{marginTop:"20px"}}
    //    showSizeChanger={true}
    //   pageSizeOptions={[10,20, 30, 50, 100]}
    //   pageSize={pageSize}
    //   onChange={handlePageChange}
    //   onShowSizeChange={handlePageChange}
    //   current={parseInt(pageChange)}
    //   total={parseInt(feedbacksLists?.totalItems)}
    // />:""}
    //         </TabPane>
            
    //         <TabPane tab="Completed Feedback" key="Completed Feedback">
    //         <Select value={statusName} allowClear  placeholder="Select feedback Type" style={{width:"100%"}} onChange={(value) =>{
    //           if(value==="OnHold Feedback"){
             
    //             getHoldData()
    //           }
    //           else{
                 
    //             getData()
    //           }
    //           setStatusName(value)
    //         }}>
    //           <Select.Option value={"OnHold Feedback"}> OnHold Feedback</Select.Option>
    //           <Select.Option value="Completed Feedback"> Completed Feedback</Select.Option>
    //         </Select>
     
            
    //         {feedbacks?.map((element,index) =>{
             
    //           return(
    //             <Card style={{backgroundColor:"#F2F7F2",marginTop:"20px"}} >
    //             <div key={index} style={{backgroundColor:"#F2F7F2",marginTop:"8px"}}>
    //               <h3>JOB TITLE :</h3>
    //               <p style={{color:"grey",marginTop:"4px",marginBottom:"12px"}}>{statusName==="OnHold Feedback" ?element?.candidate?.job?.title:element?.jobTitle}</p>
    //               <h3>CANDIDATE NAME </h3>
    //               <p style={{color:"grey",marginTop:"4px",marginBottom:"12px"}}>{statusName==="OnHold Feedback" ?element?.candidate?.name:element?.candidateName}</p>
    //               <h3>ROUND TYPE</h3>
    //               <p style={{color:"grey",marginTop:"4px",marginBottom:"12px"}}>{element?.roundType}</p>
    //               <h3>DATE AND TIME</h3>
    //               <p style={{color:"grey",marginTop:"4px",marginBottom:"12px"}}>{element?.date}, {element?.startTime}-{element?.endTime}</p>
    //               {element?.status === "Hold" ?<>
    //               <h3>STATUS:{" "}Hold</h3>
    //     <div style={{marginTop:"1rem",marginRight:"1.3rem",display:"flex",float:"right",justifyContent: "flex-end",}}>
    //         <Button type="primary"style={{fontSize:"0.9rem",fontWeight:"600",borderRadius:"5px",height:"2.3rem"}}
    //         onClick={()=>{
    //           SetDetails(element)
    //           setViewFeedbackModal(false)
    //           setStatusOpen(true)
    //           }}
    //         >
    //         Click to Change Candidate Status</Button>
    //     </div></>
    //     :""
    //     }
    //               <Button 
    //       disabled={element?.interviewStatus ==="Completed" || element?.interviewStatus ==="Feedback-Pending"} 
    //       onClick={() => {
            
    //          SetDetails(element)
    //         setViewFeedbackModal(true)
    //       }} 
    //       style={{ backgroundColor: element?.interviewStatus==="Completed"||element?.interviewStatus ==="Feedback-Pending"?"#6F6F6F":'#0086FF',color:"white",fontFamily:"Open Sans",
    //       fontWeight:"bold",height:"2rem",borderRadius:"5px",textAlign:"center",fontSize:"0.9rem",marginTop:"16px",
    //     width:width<400?"100%":""}} 
    //       size="small">
    //         Feedback Submitted
    //       </Button>
    //             </div>
    //             </Card>
    //           )
              
    //         })}
    //          <Pagination
    //   style={{marginTop:"20px"}}
    //   showSizeChanger={true}
    //   pageSizeOptions={[10,20, 30, 50, 100]}
    //   pageSize={pageSize}
    //   onChange={handlePageChange}
    //   onShowSizeChange={handlePageChange}
    //   current={parseInt(pageChange)}
    //   total={parseInt(feedbacksLists?.totalItems)}
    // />
    //         </TabPane>
    //     </Tabs>
        }


    <Modal
        visible={viewFeedbackModal}
        onCancel={()=>{setViewFeedbackModal(false)}}
        header={null}
        centered
        maskClosable={true}
        closable={true}
        title={width < 700 &&<h3 style={{fontWeight:"700",fontSize:"1rem",marginTop:"0.5rem",textAlign:"left",width:"90%"}}>Feedback Questions & Answers</h3>}
        footer={null}
        width={width>700?"55%":"100%"}
    >
      <div style={{display:"flex",flexDirection:"column"}}>
      {width>700?
        <div className={classes.container}>
                <div style={{marginLeft:"3%",display:"flex",flexDirection:"column"}}>
                  <h2>
                  {/* <Typography.Text style={{width: "12.5rem",fontWeight:"700"}} ellipsis={{tooltip:true}}>{statusName==="OnHold Feedback" ? details?.candidate?.name:details?.candidateName}</Typography.Text> */}
                  </h2> 
                  <img src={details?.screenshot} style={{width:"7rem",height:"5rem",marginTop:"1rem",border:"1.4px solid blue",padding:"0.5rem",borderRadius:"3px"}}></img>
                </div>
                <div className={classes.description}>
                            <div>
                                <span>Job Title:</span>
                                <span >Round Type:</span>
                                <span>Round:</span>
                                <span >Interview Date:</span>
                            </div>
                            
                            <div>
                                <Typography.Text style={{width: "7rem",fontWeight:"700"}} ellipsis={{tooltip:true}}>
                                <span>{statusName==="OnHold Feedback" ? details?.candidate?.job?.title:details?.jobTitle}</span>
                                </Typography.Text>
                                <Typography.Text style={{width: "7rem",fontWeight:"700"}} ellipsis={{tooltip:true}}>
                                <span>{details?.roundType && details?.roundType === "Relationship" ? "HR Relationship" 
                                : details?.roundType ? details?.roundType : "NA"}</span>
                                </Typography.Text>
                                <span>{details?.round}</span>
                                <span>{statusName==="OnHold Feedback" ? details?.interviewDate : details?.date}</span>  
                            </div>
                            
                </div>
        </div>
        :
        <div style={{borderBottom:"1px solid #D9D9D9",marginBottom:"0.8rem"}}>
          <div style={{display:"flex",flexDirection:"row",width:"100%",marginBottom:"0.5rem"}}>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Job</span>
                        <Typography.Text style={{width: "8rem",fontSize:15,color:"#161616",fontWeight:600}} ellipsis={{tooltip:true}}>
                          <span>{statusName==="OnHold Feedback" ? details?.candidate?.job?.title:details?.jobTitle}</span>
                        </Typography.Text>
                        {/* <p style={{fontSize:15,color:"#161616"}}>{statusName==="OnHold Feedback" ? details?.candidate?.job?.title:details?.jobTitle}</p> */}
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Round Type</span>
                        <p style={{fontSize:15,color:"#161616"}}>{details?.roundType && details?.roundType === "Relationship" ? "HR Relationship" 
                                : details?.roundType ? details?.roundType : "NA"}</p>
                      </div>
          </div>
          <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Round</span>
                        <p style={{fontSize:15,color:"#161616"}}>{details?.round}</p>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                        <span style={{fontSize:14,color:"#808080"}}>Status</span>
                        <p style={{fontSize:15,color:"#161616"}}>{details?.status}</p>
                      </div>
          </div>
        </div>}
        {details?.status === "Hold" && statusName === "OnHold Feedback" && width>700 ?
        <div style={{marginTop:"1rem",marginRight:"1rem",display:"flex",float:"right",justifyContent: "flex-end",}}>
            <Button type="primary"style={{fontSize:"0.9rem",fontWeight:"600",borderRadius:"5px",height:"2.3rem"}}
            onClick={()=>{
              setViewFeedbackModal(false)
              setStatusOpen(true)
              }}
            >
            Candidate Status</Button>
        </div>
        :""
        }
        { width>700 &&<h3 style={{margin:'2%',fontWeight:"700",fontSize:"1.1rem"}}>Feedback Questions & Answers</h3>}
        {details?.roundType === "HR" && lastRoundData !== undefined && lastRoundData !== null&&lastRoundData.length !== 0 ?
          <div className={classes.questionsDiv}>
          <div style={{marginLeft:"1rem",fontSize:"11px"}}><h2>Evaluated Previous Round Feedbacks :</h2></div>
            <div className={classes.question2}>
                <div style={{marginLeft:"0.2rem"}}>
                   {lastRoundData.map((item,index) => {
                            return(<div style={{display:"flex",flexDirection:"column"}}>
                                <h3> {item.question}</h3>
                                <span style={{marginLeft:width>700 &&"1.5rem",}}>{item.answer}</span>
                                <h4 style={{marginLeft:width>700 &&"1.5rem",marginBottom:"0.2rem",fontSize:"1.0rem"}}>review : {item.review}</h4>
                               </div>
                            )}       
                    )}
                </div>
            </div>
            <div style={{marginLeft:"1rem",fontSize:"11px",}}><h2>HR Round Feedbacks :</h2></div>
            <div className={classes.question}>
                        <div className={classes.defaultQues}>
                                        {details?.overallComment === null ? "" :<>
                                        <h3> Overall Comment:</h3>
                                        <span style={{marginLeft:width>700 &&"1.7rem"}}>{details?.overallComment}</span>
                                        </>}
                                        {details?.overallRating === null ? "" :<>
                                        <h3> Overall Rating:</h3>
                                        <span style={{marginLeft:width>700 &&"1.7rem"}}>{details?.overallRating}</span>
                                        </>}
                                        <h3> Candidate Status:</h3>
                                        <span style={{marginLeft:width>700 &&"1.7rem"}}>{details?.status!==null?details?.status:"NA"}</span>
                        </div>
                    {     
                    Object.keys(questions).map((key,index) => {
                          return(key === "overallComment" || key === "overallrating" || key === "status"
                          ||(typeof key === "object" && Object.keys(key).includes("file") || Object.keys(key).includes("fileList")) 
                          ? "" :
                          <div>{key !== "attachment" &&  <>
                                <h3>{" "+key}.</h3>
                                {typeof key === "string" && (key.startsWith("http://") || key.startsWith("https://")) && key.split('.').pop().toLowerCase() === "pdf" ?
                                    <div style={{fontWeight:"600",marginTop:"0.3rem",cursor:"pointer",display:"flex",textAlign:"center",justifyContent:"center",marginLeft:"1.1rem",border:"1px solid #0086FF",padding:"0.5rem",width:"8rem"}}
                                    onClick={()=>{
                                    window.open(key, "_blank")?.focus();
                                    }}> View File </div>
                                    :
                                    typeof key === "string" && (key.startsWith("http://") || key.startsWith("https://")) && ["png", "jpg", "jpeg", "gif", "bmp"].includes(key.split('.').pop().toLowerCase()) ?
                                    <img src={key} alt="" style={{height:"4rem",width:"4rem",marginLeft:"1.1rem"}} />
                                    :
                                <span style={{marginLeft:width>700 &&"1.7rem"}}>{questions[key]?questions[key]:"NA"}</span>
                                }</>}    
                          </div>)}    
                    )}
            </div>
          </div>
        :
          <div className={classes.question}>
                        <div className={classes.defaultQues}>
                                        {details?.overallComment === null ? "" :<>
                                        <h3> Overall Comment:</h3>
                                        <span style={{marginLeft:width>700 &&"1.7rem"}}>{details?.overallComment}</span>
                                        </>}
                                        {details?.overallRating === null ? "" :<>
                                        <h3> Overall Rating:</h3>
                                        <span style={{marginLeft:width>700 &&"1.7rem"}}>{details?.overallRating}</span>
                                        </>}
                                        <h3>
                                          {/* {details?.overallComment === null&&details?.overallRating === null?1:3}. */}
                                           Candidate Status:</h3>
                                        <span style={{marginLeft:width>700 &&"1.7rem"}} >{details?.status!==null?details?.status:"NA"}</span>
                        </div>
                    {     
                    Object.keys(questions).map((key,index) => {
                          if (key === "overallComment" || key === "overallrating" || key === "status") {
                            return null;
                          }
                          const questionValue = questions[key];
                            if (typeof questionValue === "object" && questionValue.hasOwnProperty("file") && questionValue.hasOwnProperty("fileList")) {
                                return null; 
                            }
                          if (key !== "attachment") {
                            let questionNumber = questions.hasOwnProperty("overallComment") || questions.hasOwnProperty("overallrating") || questions.hasOwnProperty("status")
                            ? index
                            : index + 4;                          
                          return(key === "overallComment" || key === "overallrating" || key === "status" 
                          ||(typeof key === "object" && Object.keys(key).includes("file") || Object.keys(key).includes("fileList"))
                          ? "" :
                          <div>{key !== "attachment" &&  <>
                                <h3>{" "+key}.</h3>
                                {typeof key === "string" && (key.startsWith("http://") || key.startsWith("https://")) && key.split('.').pop().toLowerCase() === "pdf" ?
                                    <div style={{fontWeight:"600",marginTop:"0.3rem",cursor:"pointer",display:"flex",textAlign:"center",justifyContent:"center",marginLeft:"1.1rem",border:"1px solid #0086FF",padding:"0.5rem",width:"8rem"}}
                                    onClick={()=>{
                                    window.open(key, "_blank")?.focus();
                                    }}> View File </div>
                                    :
                                    typeof key === "string" && (key.startsWith("http://") || key.startsWith("https://")) && ["png", "jpg", "jpeg", "gif", "bmp"].includes(key.split('.').pop().toLowerCase()) ?
                                    <img src={key} alt="" style={{height:"4rem",width:"4rem",marginLeft:"1.1rem"}} />
                                    :
                                <span style={{marginLeft:width>700 &&"1.7rem"}}>{questions[key]?questions[key]:"NA"}</span>
                                }</>}    
                          </div>)}
                          return null;
                          }    
                    )}
          </div>
          }
        {details?.status === "Hold" && statusName === "OnHold Feedback" && width < 700 ?
        <div style={{margin:"1rem 0",display:"flex",float:"right",justifyContent: "flex-end",}}>
            <Button type="primary"style={{fontSize:"0.9rem",fontWeight:"600",borderRadius:"5px",height:"2.3rem",width:"100%"}}
            onClick={()=>{
              setViewFeedbackModal(false)
              setStatusOpen(true)
              }}
            >
            Candidate Status</Button>
        </div>
        :""
        }
      </div>
    </Modal>

    {filterModal &&
    <Modal open={filterModal}
        title={<div style={{fontSize:"1.4rem",textAlign:"center",margin:"1rem"}}>Filter</div>}
        onCancel={()=>{setFilterModal(false); setFilteredMobStatus("")
        }}
        // onOk={()=>{}}
        header={null}
        bodyStyle={{height:"13rem",}}
        centered
        closable={true}
        footer={false}
        // width={300}
      >
        <div style={{marginLeft:"1rem",}}>
        <div style={{marginBottom:"1rem"}}>
          <Radio.Group bordered={false} style={{fontWeight:"bold"}}
           onChange={(item)=>{
            //  if(item.target.value==="All"){
            //   setFilteredMobStatus()
            // }else
            setFilteredMobStatus(item.target.value)
           }}
          >
             <Space direction="vertical">
                <Radio value={"All"}>All</Radio>
                <Radio value={"Selected"}>Selected</Radio>
                <Radio value={"Not Selected"}>Not Selected</Radio>
                <Radio value={"Hold"}>Hold</Radio>
              </Space>                                                                             
          </Radio.Group> 
           
        </div>  
        <div style={{margin:"1rem 0",display:"flex",justifyContent:"center",alignItems:"center"}}>
          <Button type="primary" style={{height:"2.5rem",width:"7rem"}} disabled={!filteredMobStatus}
            onClick={()=>{
              if(filteredMobStatus==="All"){
                setFilteredStatus("")
              }else{
              setFilteredStatus(filteredMobStatus)
              }
              setFilteredMobStatus("")
              setFilterModal(false);
           }}>Apply</Button>
        </div>
        </div>
    </Modal>
    }

   <Modal 
        open={statusOpen}
        title={<div style={{fontSize:"1.4rem",textAlign:"center",margin:"1rem"}}>Change Candidate Status</div>}
        onCancel={()=>{
            setStatusOpen(false)
            setStatusReason("")
            setStatusValue("")
        }}
        onOk={()=>{
            setStatusOpen(false)
            getStatus();
            setStatusReason("")
            setStatusValue("")
        }}
        okButtonProps={{disabled: statusValue==="" || statusValue=== undefined || statusReason === "" ? true : false}}
        header={null}
        bodyStyle={{height:"13rem",}}
        centered
        closable={false}
        // footer={}
        // width={300}
      >
    
        <div style={{marginLeft:"1rem",}}>
        <div style={{}}>
            <h3 style={{fontSize:"1.1rem",marginRight:"0.5rem",marginBottom:"0.5rem"}}>Change Status :  </h3>
            <Select
                placeholder="Select a Status"
                allowClear={true}
                // value={statusValue}
                style={{width:"90%" }}
                onChange={(item) => {
                    setStatusValue(item)
                }}
            >
                    <Option style={{ width: "100%" }} value={"Selected"}>
                         Selected
                    </Option>
                    <Option style={{ width: "100%" }} value={"Not Selected"}>
                        Not Selected
                    </Option>
                       
            </Select>  
        </div>  
        <div style={{marginBottom:"1rem"}}>
            <h3 style={{fontSize:"1.1rem",marginRight:"0.5rem",marginTop:"1rem",marginBottom:"0.5rem"}}>Reason : </h3>
            <Input style={{width:"90%",marginBottom:"1rem"}} value={statusReason} placeholder="Enter the Reason" type="text" 
             onChange={(e)=>{
                setStatusReason(e.target.value);
             }}/>
        </div>
        </div>
    </Modal>
                  {/* {
                    viewFeedbackModal &&
                            <ViewFeedback
                            width={width}
                            details={details}
                            refresh={getData.bind(this)}
                            viewFeedbackModal={viewFeedbackModal}
                            setViewFeedbackModal={setViewFeedbackModal}/>
                  }      */}
</div>
</div>
);

}

export default InterviewSchedules;