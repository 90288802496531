import React from "react";
import classes from './EngagementGraph.module.css';

const EngagementGraph=({data})=>{
    const xAxis=[0,25,50,75,100];
    
    const yAxis=[
        {
            ypoint:"Dashboard",value:0,
        },
        {
            ypoint:"Feed",value:parseInt(data?.feed*100/(data?.totalEngagement)),
        },
        {
            ypoint:"Job search",value:parseInt(data?.jobSearch*100/(data?.totalEngagement)),
        },
        {
            ypoint:"Hiring status",value:parseInt(data?.hiringStatus*100/(data?.totalEngagement)),
        },
        {
            ypoint:"Profile",value:parseInt(data?.profile*100/(data?.totalEngagement)),
        },
    ]
    return(
        <div className={classes.engGraph}>
            <h3>Engagement by Feature</h3>
            <span>Summary in percentage</span>
            <div className={classes.graphPart}>
                <div>

                </div>
                <div className={classes.xAxis}>
                    {xAxis?.map((item,index)=>{
                        return(
                            <div>
                                <span></span>
                                <span>{item}</span>
                            </div>
                        )
                    })}


                </div>
                <div className={classes.yAxis}>
                    {yAxis?.map((item,index)=>{
                        return(
                            <div>
                                <span>{item?.ypoint}</span>
                                <span>
                                    <span style={{backgroundColor:"#1089FF",width:`${item?.value}%`,height:"70%",borderRadius:"0.5rem"}}></span>
                                </span>
                            </div>
                        )
                    })}

                </div>

            </div>

        </div>
    )
}
export default EngagementGraph;