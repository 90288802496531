import { Api, SEARCH_VALUE } from "services";
import {dateFormat, postDateFormat} from "pages/candidate/redux/action";
import moment from "moment"

export const DASHBOARD_DETAILS_GET = "DASHBOARD_DETAILS_GET";
export const DASHBOARD_TABLE_LOADER = "DASHBOARD_TABLE_LOADER";
export const DASHBOARD_INTERVIEW_DATE = "DASHBOARD_INTERVIEW_DATE";
export const DASHBOARD_DATE_DROPDOWN = "DASHBOARD_DATE_DROPDOWN";
export const DASHBOARD_CLEAR = "DASHBOARD_CLEAR";

export const getDashboard = (value) => {
 
  if(!value)
  {
    value=false
  }
  return (dispatch, getState) => {
    const {searchValue} = getState().createUser
    const { dashboardInterviewDate } = getState().dashboard;
    const dateQuery = dashboardInterviewDate ? `&date=${moment(dashboardInterviewDate, dateFormat).format(postDateFormat)}`: ""
    dispatch(performOperation(operationType.DASHBOARD_TABLE_LOADER, true));
    return Api.get(`/hiring-overview/interviews?search=${searchValue}${dateQuery}`).send((response, error) => {
      if (response !== undefined) {
       
        dispatch(dashboardDetailsGet(response));
        dispatch(performOperation(SEARCH_VALUE, ""));
      }
      dispatch(performOperation(operationType.DASHBOARD_TABLE_LOADER, false));
    });
  };
  
};

export const dashboardDetailsGet = (value) => ({
  type: DASHBOARD_DETAILS_GET,
  value,
});

export const getactive = (page, limit) => {
 
  if (!page && !limit) {
    page = 1;
    limit = 25;
  }}

export const updateInputField = (inputFieldType, value) => ({
  type: inputFieldType,
  value,
});

export const performOperation = (operationType, value) => ({
  type: operationType,
  value,
});

export const operationType = {
  DASHBOARD_TABLE_LOADER: DASHBOARD_TABLE_LOADER,
  DASHBOARD_CLEAR: DASHBOARD_CLEAR,
};

export const operationTypeHash = {
  DASHBOARD_TABLE_LOADER: "dashboardTableLoader",
  DASHBOARD_CLEAR: "dashboardInterviewers",
};

export const inputFieldType = {
  DASHBOARD_INTERVIEW_DATE: DASHBOARD_INTERVIEW_DATE,
  DASHBOARD_DATE_DROPDOWN: DASHBOARD_DATE_DROPDOWN,
};

export const inputFieldTypeHash = {
  DASHBOARD_INTERVIEW_DATE: "dashboardInterviewDate",
  DASHBOARD_DATE_DROPDOWN: "dashboardDateDropdown",
};
