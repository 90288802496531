import React, { useState } from "react";
import styled from "./style.css";
import { Form, Modal, Button, Upload, Col, Row, Typography,Result } from "antd";
import Api from "services/Api";
import { InboxOutlined } from "@ant-design/icons";
// import { adminActions } from "./store";
import { useDispatch,useSelector } from "react-redux";
import Notification from "services/Notification";
import { objectExpression } from "@babel/types";
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};
const { Title } = Typography;

const ImportData = ({ isOpen ,toggle ,fetchColleges}) => {
 
   const [isModalVisible2,setIsModalVisible2] = useState(false)
  // const {duplicates,} = useSelector((state) => state.candidateFresher)
  // const {duplicateMail,duplicatephone,type}  = useSelector((state) => state.candidate.excelDuplicates);
  const [duplicateErrorObj,setDuplicateError] = useState();
  const [btnLoader,setBtnLoader] = useState(false);
  const [loader ,setLoader] = useState(false);
  const dispatch = useDispatch();
  
  const onFinish = (values) => {
    if((!values?.dragger) || (values?.dragger.length===0)  )
    {   let title="please import file"
      Notification.error(title)
      return
    }
    const params = {
      file:values?.dragger[0]?.originFileObj,
      
      };
    setLoader(true)
    setBtnLoader(true)
    Api.post("/hiring-college/addCollegeData?file=1")
    .upload(params, (percentCompleted, response) => {
      setBtnLoader(false)
      setLoader(false);
      if (typeof response != "undefined") {
        if(response.data.type == "error"){ 
          setDuplicateError(response.data);
          setIsModalVisible2(true);
        //  dispatch(adminActions.updateDuplicates({duplicates:response.data.duplicates}))
        } else if(response.data && response.data.show && response.data.show.type == "success"){
          fetchColleges("","","");
          toggle();
        }  else {
          setIsModalVisible2(true);
        }
        
      }
    });
  };


  const props = {
    name: 'file',
    multiple: true,
    action: 'https://98e0-119-82-123-111.in.ngrok.io/api/college/addCollegeData?file=1',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      // if (status === 'done') {
      //   message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === 'error') {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
    },
    onDrop(e) {
    },
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setBtnLoader(false)
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };


  return (<>
  {isModalVisible2 ? 
  <Modal title="Colleges already present in portal-this" 
   visible={isModalVisible2} centered onCancel={handleCancel2}
   closable={true}
   maskClosable={true} footer={false}
   style={{minWidth:500,borderRadius:"5px",}}>
    <div style={{margin:" 1rem 0 2rem 0"}}>

    
          <Result
            status="error"
            title="Submission Failed"
            subTitle="Please check and modify the following information before resubmitting."
            style={{height:"20%"}}
          />
            {duplicateErrorObj && duplicateErrorObj.duplicates?.map((duplicate) => {
              return(
                <Row><Col span={8}><p><b style={{marginRight:"10px"}}>name:</b>{duplicate.name}{"  "}</p></Col><Col span={8}><p><b style={{marginRight:"10px"}}> email:</b>{duplicate.email}</p></Col><Col span={8}><p><b style={{marginRight:"10px"}}>phone:</b>{duplicate.phone}</p></Col></Row>
              )
            })}
    </div>
  </Modal>
   : "" }
      
      
    <div className={styled}>
      <>
        <Modal
          visible={isOpen}
          closable={true}
          centered={true}
          maskClosable={true} 
          onCancel={()=>{
            toggle()
          }}
          footer={false}
        >
          <Title
            style={{
              textAlign: "center",
              margin: "1rem",
              marginBottom: "2rem",
            }}
            level={3}
          >
            Import data
          </Title>
          <Form onFinish={onFinish}>
            <Form.Item
              name="dragger"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
        
              <Upload.Dragger>
              <div style={{textAlign:"center",display:"flex",flexDirection:"column",flexWrap:"nowrap",alignItems:"center"}}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
               </div>
              </Upload.Dragger>
            </Form.Item>{" "}
            <br /> <br />
            <Form.Item
              wrapperCol={{
                span: 24,
                offset: 0,
              }}
            >
              <Row justify="center">
                <Col span={6}>
                  <Button onClick={toggle} style={{ width: "100%" }}>
                    Cancel
                  </Button>
                </Col>
                <Col span={6} offset={2}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                    }}
                    loading={btnLoader}
                  >
                    UPLOAD
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </>
    </div></>
  );
};

export default ImportData;
