import { Col, Form, Modal, Row, DatePicker } from "antd";
import React from "react";
import {Input,Button } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { PrimaryButton, SecondaryButton } from "services/button";
import { Color } from "services/color";
import {
  TextFormInput,
  SelectFormInput,
  TextAreaFormInput,
} from "services/input";
import {
  inputFieldType,
  hrActionInputFieldType,
  operationType,
  candidateFormat,
  dateFormat,
  HR_PROCESS_CANDIDATE_DETAILS,
} from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";

class CandidateActionsModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hrStatusVal: false,
    };
  }

  onFinish = () => {
    
    this.props.handleCandidateHrAction(
      this.props.candidateInfo.id,
      this.props.candidateInfo.hrStatus
    );
    this.props.performOperation(
      operationType.HR_PROCESS_HR_ACTION_EDITING_MODAL,
      false
    );
  };
  

  changeStatus = (value) => {
                  this.props.updateHrActionInputField(
                    hrActionInputFieldType.HR_PROCESS_CANDIDATE_HR_STATUS,
                    value
                  )
               this.setState({hrStatusVal: true});

            }

  closeModal = () => {
    this.props.performOperation(
      operationType.HR_PROCESS_HR_ACTION_EDITING_MODAL,
      false
    );
    this.props.updateInputField(HR_PROCESS_CANDIDATE_DETAILS, candidateFormat);
    this.props.setHrActionEditingView(false);
  };
   disabledfutureDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    const {
      hrActionEditingModal,
      hrActionEditingLoader,
      updateHrActionInputField,
      candidatesHrStatus,
      candidateInfo,
    } = this.props;
    return (
      <Modal
        visible={hrActionEditingModal}
        title={<div style={{display:"flex",justifyContent:"center",marginTop:"0.3rem",fontSize:"1.1rem",marginBottom:"-0.5rem"}}>HR Actions</div>}
        color={Color.blue}
        onOk={this.onFinish}
        onCancel={this.closeModal}
        maskClosable={false}
        footer={[
          // <SecondaryButton
          //   onClick={this.closeModal}
          //   style={{margin:"0.3rem 1rem 1rem 0"}}
          //   id="candidateHrActions"
          //   text="Discard"
          //   className="modalButton"
          //   size="large"
          // />,

          <Button
          onClick={this.closeModal}
          style={{margin:"0.3rem 1rem 1rem 0",width:"10rem"}}
          id="candidateHrActions"
          text="Discard"
          className="modalButton"
          size="middle"
        >Discard</Button>,
          <PrimaryButton
            htmlType="submit"
            style={{margin:"0.3rem 1rem 1rem 0",borderRadius:"10px",width:"8.5rem"}}
            id="hrEditCandidate"
            loading={hrActionEditingLoader}
            onClick={this.onFinish}
            text="Save"
            className="modalButton"
            size="large"
          />,
        ]}
      >
        <Form id="candidate-hrActions-form" onFinish={this.onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <SelectFormInput
                text="HR Status"
                dropDownList={candidatesHrStatus}
                style={{ marginBottom: "24px",color:"black" }}
                onChange={ (value) => {
                  updateHrActionInputField(
                    hrActionInputFieldType.HR_PROCESS_CANDIDATE_HR_STATUS,
                    value
                  )
                  // updateHrActionInputField(hrActionInputFieldType.HR_PROCESS_CANDIDATE_OFFER_SENT,
                  //   ""
                  // )
                  // updateHrActionInputField(hrActionInputFieldType.HR_PROCESS_CANDIDATE_HR_NOTICE_PERIOD,
                  //   null
                  // );
                  // updateHrActionInputField(hrActionInputFieldType.HR_PROCESS_LWD,
                  //   ""
                  // );
                  // updateHrActionInputField(hrActionInputFieldType.HR_PROCESS_Expected_DOJ,
                  //   ""
                  // );
                  // updateHrActionInputField(hrActionInputFieldType.HR_PROCESS_HR_COMMENT,
                  //   ""
                  // )
                }}
                value={candidateInfo.hrStatus?.hrStatus}
              />
            </Col>
            <Col span={12}>
            <div style={{ marginBottom: "2.8px",fontSize:"15px",}}>Offered CTC ( in LPA )</div>
            <Input type="number"
                placeholder="Offered ctc (in LPA)"
                min={0}
                style={{ marginBottom: "24px" }}
                onChange={(record) =>
                  updateHrActionInputField(
                    hrActionInputFieldType.HR_PROCESS_CANDIDATE_OFFER_SENT,
                    record.target.value
                  )
                }
                value={ candidateInfo.hrStatus?.offeredCTC}
              />
            </Col>
            <Col span={12}>
            <div style={{ marginBottom: "2.8px",fontSize:"15px" }}>HR Notice Period ( In Days )</div>
              <Input
                type="number"
                min={0}
                placeholder="HR Notice Period ( In Days )"
                style={{ marginBottom: "24px" }}
                onChange={(record) => {
                  updateHrActionInputField(
                    hrActionInputFieldType.HR_PROCESS_CANDIDATE_HR_NOTICE_PERIOD,
                    record.target.value ? parseInt(record.target.value) : 0
                  );
                }}
                value={ candidateInfo.hrStatus?.hrNoticePeriod}
              />
            </Col>
            <Col span={12}>
              <div style={{ marginBottom: "2.8px",fontSize:"15px" }}>LWD ( Optional )</div>
              <DatePicker
                format={dateFormat}
                style={{ width: "220px" }}
                onChange={(date, dateString) => {
                  const updatedDate = date ? dateString : null;
                  updateHrActionInputField(
                    hrActionInputFieldType.HR_PROCESS_LWD,
                    updatedDate
                  );
                  //getDashboard();
                }}
                value={ 
                  candidateInfo.hrStatus?.lwd
                    ? moment(candidateInfo.hrStatus?.lwd, dateFormat)
                    : null
                }
              />
            </Col>
            <Col span={12}>
              <div style={{ marginBottom: "2.8px",fontSize:"15px" }}>Expected Doj</div>
              <DatePicker
                format={dateFormat}
                style={{ width: "220px" }}
                disabledDate={this.disabledfutureDate}
                onChange={(date, dateString) => {
                  const updatedDate = date ? dateString : null;
                  updateHrActionInputField(
                    hrActionInputFieldType.HR_PROCESS_Expected_DOJ,
                    updatedDate
                  );
                  //getDashboard();
                }}
                value={
                  candidateInfo.hrStatus?.expectedDoj
                    ? moment(candidateInfo.hrStatus?.expectedDoj, dateFormat)
                    : null
                }
              />
            </Col>
            <Col span={12}>
            <div style={{ marginBottom: "2.8px",fontSize:"15px" }}>HR Comment</div>
              <Input
                placeholder="Hr comment"
                style={{ marginBottom: "24px",height:"3rem" }}
                onChange={(record) =>
                  updateHrActionInputField(
                    hrActionInputFieldType.HR_PROCESS_HR_COMMENT,
                    record.target.value
                  )
                }
                value={ candidateInfo.hrStatus?.hrComment}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateActionsModal);
