import React from 'react';
import { useState, useEffect } from 'react';
import classes from './allPanelist.module.css';
import {
  Input,
  Row,
  Col, 
  Button, 
  Table,
  Select,
  Tabs ,
  Checkbox,
  Popover,
  Modal,
  Space,
  List,
  Divider, 
  Steps,
  Timeline, 
  Dropdown
  // List.Item,
  // List.Item.Meta
 } from 'antd';  
import {
  InfoCircleOutlined,
  FilterOutlined,
  DownloadOutlined,
  CloseOutlined,
  DeleteOutlined,
  SearchOutlined,
  DownOutlined,
} from "@ant-design/icons";
//import AddPanelistModal from './addPanelistModal';
import TimeSlotModal from './timeSlotModal';
import PanelistPoolCreate from './createPanelistPool';
import Constants from "../jd-dashboard/constants";
import FilterModel from './filter';
import { useDispatch, useSelector } from 'react-redux';
import { panelistActions } from './store';
// import Api from "../../services/Api"
import Api from 'services/Api';
import filter from "../../assets/filter.png";
import Filter from 'pages/jd-dashboard/filter.cls';
import EditPool from './editPool';
// import EditTimeModal from './edittimeSlot';
// import EditPanelistModal from './EditPanelist';


const { Search } = Input;
const Option = Select;
// // const { Step } = Steps;
// const Step = Steps.Step;

const Allpanelist = () => {
    
   // const [isAddPanelistVisible, setIsAddPanelistVisible] = useState(false);
    const [isClickAssign, setIsClickAssign] = useState(false);
    const [isCreatePanelistPool, setIsCreatePanelistPool] = useState(false);
    const [meta1,setMeta]=useState()
    const [meta2,setMeta2]=useState()
    const [filter1, setFilter1] = useState(false);
    

    const [designations,setDesignations]=useState([])
    const [searchValue, setSearchValue] = useState("");
    const [pageChange, setPageChange] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageChange2, setPageChange2] = useState(1);
    const [pageSize2, setPageSize2] = useState(10);
    const [busy, setBusy] = useState(null);
    const [tableLoader, setTableLoader] = useState(false);

    
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [filterDetailsFlag,setFilterDetailsFlag] = useState(false);
    const [isCustomPopoverVisible, setCustomPopoverVisible] = useState({ sortDetails: false, filterDetails: false });
    const [job,setJob]=useState([])
    const [panelistPoolId,setPanelistPoolId]=useState([]);
  
    const [delModal,setDelModal]=useState(false)
    const [btnLoader, setBtnLoader] = useState(false);

    const [filterDetails,setFilterDetails]=useState(false)
    const [designationfilter,setDesignationfilter]=useState([])
    const [jdTypefilter,setJdTypeFilter]=useState(null)
    const [tabkey,setTabKey]=useState("1")
    const [jobType,setJobType]=useState([])
    const [jobIdValue,setJobId]=useState([])
    const [jobVacancy,setJobVacancy]=useState([])
    const [selectedRowSel, setSelectedRowSel] = useState([])
    // const [editPanelistVisible, setEditPanelistVisible] = useState(false);

    const { selectedRowKeys, loading } = selectedRowSel;
    const tabname="ALL PANELIST"

    let temp = [];
  

    const options = [
      {
        value: 'Junior Software Engineer',
  },
  {
    value:'Associate Software Engineer'
  },
  {
    value:'Relationship Manager'
  },
      {
      value: 'COO',
    },
    {
      value: 'CTO',
    },
    {
      value: 'HR',
    },
{
  value:'Senior Software Engineer'
},
{
  value:'Project Manager'
},
];

const options2 = [
{
  value: 'Fresher',
},
{
  value: 'Experienced',
},
];
    const[editSlot,setEditSlot] = useState(false)
    const [slotRecord,setSlotrecord] = useState("")
    const [panelistId,setPanelistId]=useState();
    const filterObj = new Filter();
    const [editPools,setEditPools] = useState()
    const [editModal,setEditModal] = useState(false)
    const [editDay,setEditday] = useState("")
    const dispatch=useDispatch();

    const {user}= useSelector(state =>state.auth);


    const [dataSource,setDataSource]=useState( []);
    const slots = [
      { label: 'M', value: 'Monday' },
      { label: 'T', value: 'Tuesday' },
      { label: 'W', value: 'Wednesday' },
      { label: 'Th', value: 'Thursday' },
      { label: 'F', value: 'Friday' },
      { label: 'S', value: 'Saturday' },
    ];

    const handlePageChange = (page, pageSize) => {
      setPageChange(page);
      setPageSize(pageSize);
    };
  const handlePaginationChange = (e) => {
    
    setPageChange(e);
    // setPageSize(pageSize);
  };
  const handlePageChange2 = (page, pageSize) => {
    
    setPageChange2(page);
    setPageSize2(pageSize);
  };

  const getfilterRequest = (key) => {
    switch (key) {
      case "designation":
        filterObj.designation = true;
        break;
      default:
        break;
    }
  }

  const onNameChange = (e) => {
    let values = e.target.value;
    setSearchValue(values);
    if (!busy) {
      setBusy(true);
      setTimeout(() => {
        setBusy(false);
      }, 1500);
    }
  };

  // const resetFilter = () => {
  //   filterObj.resetFitler();
  //   filterJob();
  //   let jobList = [];
  //   forceUpdate();
  // }

  const deleteModal = () => {
    setDelModal(true)
  }

  

  const customFilterPopover = (type = null, data = null, logo) => {
    let contentBody;
     contentBody = <div className="sort-popover"> 
    <Checkbox.Group
     style={{
      width: '100%',
    }}>
      {designations.map((item) =>{
        return item ? <div>
          {/* <span style={{ fontSize: '20px' }}>{el.title}</span> */}
                  <Checkbox value={item} style={{ float: "right" }}
                    onChange={(e) => {
                    
                      let temp = eligibilityStatus;

                      let index = eligibilityStatus.findIndex(a => a.title == element.title);
                      if (index != -1) {
                        temp[index].selected = !temp[index].selected;
                      }
                      setEligibilityStatus(temp);
                    }}

                  ></Checkbox></div> : ""
        //  <Row> <Checkbox value={item} onChange={(e) =>{
        //   let value=e.target.value
        //   setFiltDesignation(oldArr =>[...oldArr,value])
          
        //  }}>{item}</Checkbox></Row>
        // )
      })}
    </Checkbox.Group>

    <div className="sort-action">
   <Button className="cancel" 
        // onClick={() => {     
        //  let tempStatus = filterStatus;
        //  tempStatus.designation = false
        //  switchFilterStatus(tempStatus)
        //  forceUpdate();}}
        // onClick={resetFilter}
        onClick={() =>{
          setCustomPopoverVisible({ ...isCustomPopoverVisible, sortDetails: true })}}
        >Reset</Button>
   <Button className="float-right ok" onClick={() => {
     getfilterRequest(type);
     filterDesignation();
   }}>OK</Button>
 </div>
   </div>
    return <Popover
    placement="bottom"
    content={contentBody}
    trigger="click"
    visible={isCustomPopoverVisible.sortingFields}
    onVisibleChange={toggleSortingPopover(null)}
  >
    {logo}
  </Popover>
  }
  const content = (event) => {

    return (
      <div className={classes.scroll}>
        <Timeline
          mode="left"
          style={{ width: "200px" }}
          pending={false} >
          {
            event.map((arr,index) => {
              let element=event[index]?.weekday
              let Time=event[index]?.startTime
              let Time2=event[index]?.endTime
              
              return (
                <>
                <Timeline.Item color="green"><h3 style={{ color: "#6F6F6F", marginTop: "22px",lineHeight:"0.9" }}><b>{element}</b></h3>
                <p >{Time}-{Time2}</p>
                </Timeline.Item>
               
                </>
               ) 
             })
          } 
        </Timeline></div>
    );
  };
  
  const columns = [
      {
        title: 
        <div className={classes.headerName}>
                <h1 className={classes.nameMargin}>NAME</h1>
        </div>,
        dataIndex: 'name',
        key:"name",
        name:name,
        render: (text, record) => {
          
          return (
                <div style={{cursor:"pointer"}} 
                // onClick={(e)=>{
      
                //   setEditPanelistVisible(true)
                //   setSelectDetails(record)
                // }}
                >
                  {record?.name}
                  </div> 
          )
          }
      },
      {
        title: 
        <div className={classes.headerName}>
                <h1 className={classes.nameMargin}>CONTACT DETAILS</h1>
        </div>,
        dataIndex: 'contact',
        key:"email",
        width:"22%",
        render: (text, record) => {
          return (
            <div style={{minWidth:"15rem",display:"flex",flexDirection:"column",marginRight:"3rem"}}>
                <p>{record?.email}</p> 
                <p>{record?.phone}</p> 
            </div>
          );
      }
      },
      {
        title: 
        <>        
        <div style={{width:"14rem" , display:"flex",flexDirection:"row",gap:"1rem"}}>
          <div className={classes.headerName}>
                <h1 className={classes.nameMargin}>DESIGNATION</h1>
        </div>
        {/* <Select bordered={false} style={{width:"25rem", color:"white"}} 
        onChange={(event)=>{
          
          if(event==="All"){
          
            setDesignationfilter()
          }else{
            setDesignationfilter([event])
          }
            }}
              >
              <Option value="All" >ALL</Option> 
          {designations?.map((item,index)=>{
            return (<><Option value={item} key={index}>{item}</Option></>)
          })}

        </Select> */}

        </div>
        </>,
        dataIndex: 'designation',
        key:"designation",
        width:"21%",
        render: (text, record) => {
          return (
            <div>
                <p>{record?.designation} 
                
                </p> 
            </div>
              
          );
      },
     
      },
      // {
      //   title:
      //   <div className={classes.headerName} >
      //             <h1 className={classes.nameMargin}>AVAILABILITY SLOT</h1>
      //   </div>,
      //   dataIndex: 'contact',
      //   key:"email",
      //   width:'15rem',
      //   // name:email,phone,
      //   render: (text, record) => {
      //     let btnStyle=(arg)=>{
      //       if(!arg)
      //       {
      //         return {marginLeft:"2px",backgroundColor:"green",border:"1px solid green",color:"#ffffff",fontWeight:"700"}
      //       }
      //       return {marginLeft:"2px",fontWeight:"700"}

      //     }
      //     return (
      //       <div style={{minWidth:"13rem"}}>
      //           {
      //             slots.map(element =>{
      //                return <Button disabled={record.schedule.length===0} type='circle' style={btnStyle(record.schedule.length===0)} onClick={() => {
      //                 setEditSlot(true)
      //                 setEditday(element?.value)
      //                 setSlotrecord(record)
      //                }}>{element.label}</Button>
      //             })
      //           }
      //       </div>
      //     );
      // }
      // },
      {
        title: 
        <div className={classes.headerName} >
              <h1 className={classes.nameMargin}>TIME SLOT</h1>
        </div>,
        dataIndex: 'time slot',
        key:"time slot",
        render: (text, record) => {
          
          return (
            <>
              <Popover placement="left" content={content(record?.schedule)} trigger="click" style={{ marginTop:"600px" }}
            >
              <InfoCircleOutlined style={{fontSize:"17px",color:'#0086FF'}}/>
            </Popover>
          
          {record.schedule.length!==0 ? <Button 
          style={{width:"7rem",height:"2rem",border:"1.4px solid #0086FF",borderRadius:"0.5rem",marginLeft:'5%',fontWeight:'bold',color:"#0086FF"}}
          onClick={() =>{
            setIsClickAssign(true)
            dispatch(panelistActions.updatePanelistId(record.id))
              // setEditSlot(true)
              setEditday("Monday")
              setSlotrecord(record)
          }
          }>EDIT</Button> :
            <Button type="submit" 
            style={{width:"7rem",height:"2rem",marginLeft:'5%',background:'#EAFFF2',border:'1.4px solid #00FF61',borderRadius:'4px',fontWeight:'bold',borderRadius:"0.5rem"}} 
            cursor='pointer'
            onClick={()=>{
              setSlotrecord("") 
              setIsClickAssign(true);dispatch(panelistActions.updatePanelistId(record.id));}}
            >
            ASSIGN
            </Button>}
            </>
          )
          }
      },
    ];

    const [value, setValue] = useState("");


  const getpanelist = () =>{
    setTableLoader(true)
    setValue(true);
    
    Api.get("/hiring-panelist/allPanelist")
      .params({
        designation: designationfilter ,
        search:searchValue, 
        page:searchValue==="" ?pageChange:"",
        limit:searchValue ===""?pageSize:"",
        })
      .send((response) => {
        setTableLoader(false)
        if (response !== undefined) {
          if(response?.panelist.items.length===0){
            setPageChange(1)
          }
          setDataSource(response?.panelist?.items?.map((item,index)=>{
            return(
              {...item,key:index}
            )
          })
          ) ;
          setDesignations(response?.designations);
        
          setMeta2(response.panelist.meta)
          setValue(false)
        }
      });
    }


    useEffect(() => {
      
      if(tabkey==="1"){
      getpanelist()
      }

    }, [designationfilter,searchValue,pageChange,pageSize,tabkey]);
  

  

    // const sortCandidate = () => {
    //   let requestBody;
    //   requestBody = {
    //     search: searchValue,
    //     collegeName: collegeName,
    //     page: pageChange
    //   }
    //   let selectedField = sortFieldList.filter(a => a.selected);
    //   if(selectedField && selectedField.length > 0){
    //     requestBody[selectedField[0].sortKey] = sortType ? "ASC" : "DESC"
    //   }
    //   getCandidateApiCall(requestBody);
    // }

  //   const dataSource1 = [
  //     {
  //         key: '1',
  //         jobvacancy: 'IOS Developer',
  //         jdtype: 'Experience',
  //         panelists: '9 panelists',
  //     },
  // ]

const refreshed = () =>{
  setTableLoader(true);
  Api.get("/hiring-panelist/getAllPools")
     .params({
        jdType:jdTypefilter,
        jobIds:jobIdValue,
        search:searchValue,
        page:searchValue!==""?"":pageChange2,
        limit:searchValue!==""?"":pageSize2,
      })       
      .send((response)=>{
   
        let value = [];
        let list = [];
        if (response !== undefined){
          if(response.items.length===0){
            setPageChange2(1)
          }
          setTableLoader(false);
            setJobType(response?.jobType)
            setJobVacancy(response?.jobVacancies) 
            list = response?.items?.map((element)=>{value.push(element);return element;})
        }
        setMeta(response.meta)      
          setJob(list?.map((item,index)=>{
            return(
               {...item,key:index}
            )
      }));
  })
    };

    useEffect(()=>{
      if(tabkey==="2"){     
      refreshed()
      }
    },[jdTypefilter,jobIdValue,searchValue,pageChange2,pageSize2,tabkey])
  

  const columns1 = [ 
    {
        title:  <>        
        <div style={{width:"20rem" , display:"flex",flexDirection:"row",gap:"1rem"}}>
        <div className={classes.headerName}>
                <h1 className={classes.nameMargin}>JOB VACANCY</h1>
        </div>
          
        {/* <Select bordered={false} style={{width:"6rem",color:"white"}} 
        onChange={(event)=>{

          if(event==="All"){
            
            setJobId([])
          }else{
            setJobId([event])
          }
        }} 
        >
              <Option value="All" >ALL</Option>
              
                {jobVacancy?.map((item,index)=>{
            return (<><Option value={item.id} key={item.id}>{item.title}</Option></>)
          })}
        </Select> */}
     </div></>,
        dataIndex: 'jobvacancy',
        key: 'jobvacancy',

        render: (text, record) => {
          return (
                <p>{record?.jobVacancy}</p>
          );
      }
    },
    {
        title: <><div style={{width:"14rem" , display:"flex",flexDirection:"row",gap:"1rem"}}>
          <div className={classes.headerName}>
                <h1 className={classes.nameMargin}>JD TYPE</h1>
        </div>
        {/* <Select bordered={false} style={{width:"8rem",color:"white"}}  
         onChange={(event)=>{
          
          if(event==="All"){

            setJdTypeFilter()
          }else{
          setJdTypeFilter(event)
          }
         }} 
        >
              <Option value="All" >

                ALL</Option>
            
                {jobType?.map((item,index)=>{
            return (<><Option value={item} key={index}>{item}</Option></>)
          })}
        </Select> */}
     </div></>,
        dataIndex: 'jdtype',
        key: 'jdtype',
       
    render: (text, record) => {
      return (
            <p>{record?.jdtype}</p>
      );
  }
    },
    {
        title: 
        <div className={classes.headerName}>
                <h1 className={classes.nameMargin}>PANELISTS POOL</h1>
        </div>,
        dataIndex: 'panelists',
        key: 'panelists',
        render: (text, record) => {
          
          
          return (
                // <p>{record?.pools[0]?.panelist?.length}</p>
                <Popover
                trigger="hover"
                placeholder= {record.pools.length+" Rounds"}
                style={{width:'80%',}}
                content={() =>(
                  <>
                  {record?.pools.map((val,index)=>{
                    
                    return(
                      <div>
                        <h4 style={{fontWeight:'bold'}}>Round: {val?.round}</h4>
                        <div style={{fontSize:'12px',color:'#6F6F6F',fontWeight:'bold'}}>
                          {val.panelist?.map((val)=>{
                           return(
                            <p style={{fontSize:'12px'}}>{val?.name}</p>
                  )})}
                        </div>

                      </div>
                    )
                  })}
                  </>
                )}
                >
                <InfoCircleOutlined style={{fontSize:"17px",color:'#0086FF'}}/> <span style={{color:"grey"}}>Rounds</span>
                </Popover> 
          );
      } 
    },
    {
        title: 
        <div className={classes.headerName}>
              <h1 className={classes.nameMargin}>ACTION</h1>
        </div>,
        dataIndex: 'action',
        key: 'action',
        render: (record, val) => {
         
            return(
                   <Button
                   id={val?.id}
                    style={{width:'7rem',height:'2rem',background:'#EAFFF2',border:'1.5px solid #00FF61',borderRadius:'4px',fontWeight:"bold"}} 
                    onClick={()=>{
                      
                    // setIsCreatePanelistPool(true); dispatch(panelistActions.updateEdit(true));
                    // dispatch(panelistActions.updatePanelistPool(val));
                      setEditPools(val)
                      setEditModal(true)
                 
                    }}    
                    >EDIT
                    </Button>
                    )}
                  }
                ];

                const onChange = (filters) => {
                  
                };

                const rowSelection = {
                  selectedRowKeys,
                  onChange: (selectedRowKeys, selectedRows) => {
                    
                    setSelectedRowSel(selectedRowKeys)
                    setPanelistId(selectedRowKeys?.map(item=>item.id))
                    setPanelistPoolId(selectedRowKeys?.map(item=>item.id))
                  }
                };

    const tabOnChange = (e) => {
      
      setTabKey(e)
    };

    // const deletePanelist = () => {
    //    setBtnLoader(true);
    //   Api.delete("/panelist/deletePanelists")
    //   .params({
    //     ids:panelistId
    //   })
    //   .send(res => {
      
    
    //    if (response.type === "success") {
    //     // setDataSource()
    //     setDelModal(false)
    //    }

    //    setBtnLoader(false)
    // })
    // }

    const filterPopover=(logo)=>{
      let contentBody;
      contentBody = <div className="sort-popover">
    
      <div> <span className="sort-heading-css">ALL Filter(s)</span>
      {/* <span className="float-right"
      onClick={resetFilter}
      >Reset</span> */}
      </div>
      
      {tabkey==="2" ?
       <>
               <div>
                <Row>
                  <Col>
                  <span>
                    <h3 style={{fontWeight:"bold",marginTop:"20px",marginBottom:"2px"}}>Job Vacancy</h3>
                  </span>
                </Col>
                <Col>
                <span className="float-right">
                <CloseOutlined style={{marginTop:"30px",cursor:"pointer",marginRight:"3.6rem",fontSize:'medium',marginLeft:'200%',color:'#FF9797'}} 
                onClick={()=>{
                  setJobId([])
                }} 
                />
                </span>
                </Col> 
                </Row>
                <Select
                 mode="multiple"
                 allowClear={true}
                 placeholder="Please select"
                 value={jobIdValue}
                 onChange={(e)=>{
              
                 setJobId(e)
                 }}
                  style={{
                  width: '100%',
                 marginBottom:"10px",
                  marginTop:"6px"
                }}
                //  options={options1}
                 >
                  { 
                jobVacancy.map((item)=>{ 
                       
                      //  temp.push(item.id)
                    
                       return (<><Option value={item.id} key={item.id}>{item.title}</Option></>)
                     })
                  } 
                </Select> 
              </div> 
                <div>
                <Row>
                  <Col>
                <h3 style={{fontWeight:"bold",marginTop:"15px",marginBottom:"60px"}}>Job Type</h3>
                </Col>
                <Col >
                <CloseOutlined style={{marginTop:"21px",cursor:"pointer",marginRight:"4.2rem",fontSize:'medium',marginLeft:'200%',color:'#FF9797'}} 
                onClick={()=>{
                  setJdTypeFilter()
                }}
                />
                </Col>
                </Row>
                <Row>
                <Select
                //  mode="multiple"
                 allowClear={true}
                 placeholder="Please select"
                 value={jdTypefilter}
                 onChange={(e)=>{
                
                  setJdTypeFilter(e)
                 }}
                 style={{
                  width: '100%',
                  marginBottom:"90px",
                  marginTop:"-48px",
                }}
                 options={options2}/>
                 </Row>
                </div>
                {/* <h5 style={{color:'#8F8F8F'}}>All</h5> */}
                <br/>
       </>
      : <div>
        <div>
        <Row>
          <Col>
       <h3  style={{ fontWeight:"bold",marginTop:"20px",marginBottom:"2px" }}>Designation</h3>
       </Col>
       <Col>
        <CloseOutlined style={{marginTop:"30px",cursor:"pointer",marginRight:"3.6rem",fontSize:'medium',marginLeft:'200%',color:'#FF9797'}} 
         onClick={()=>{
          setDesignationfilter([])
        }}
        />
        </Col>
       </Row>
       </div>
       <Select
        mode="multiple"
        allowClear={true}
        value={designationfilter}
        placeholder="Please select"
        onChange={(event)=>{
          // event.preventDefault();
          setDesignationfilter(event)
        }}
        style={{
          width: '100%',
          marginBottom:"80px",
          marginTop:"5px"
        }}
        options={options}
      />
      </div>
    }
    <div className="sort-action">
               <Button className="cancel"
                onClick={() => { 
                  setFilterDetails(false)
                  // setDesignationfilter([])
                  // setJdTypeFilter()
                  // setJobVacancyFilter([])
                  }}
                  >CANCEL</Button>
               <Button className="float-right ok" style={{backgroundColor :"red", }} 
                onClick={() => { 
                  setFilterDetails(false)
                  setDesignationfilter([])
                  setJdTypeFilter()
                  setJobId([])
                }}
                >CLEAR ALL</Button>
              </div>
            </div>
            return <Popover
              placement="bottom"
              content={contentBody}
              trigger="click"
              visible={filterDetails}
              onVisibleChange={() => { 
                if(filterDetails){
                  setFilterDetails(false)
                }else{
                setFilterDetails(true)
              }}}
            >
              {logo}
            </Popover>
    
    }

    const deletePool = () => {
      setBtnLoader(true);
      
      Api.delete('/hiring-panelist/deletePools')
      .params({
        ids:panelistPoolId  
      })
      .send(res => {


       if (res !== undefined){
        // setJob(response)
        refreshed();
        
        setDelModal(false)
        setBtnLoader(false);
      }
    })
    setTimeout(()=>{
      setBtnLoader(false);
    },3000)
    }

    // const handleCancel = () => {
    //   setIsEdit(false);
    // };
    const cancelHandler = () => {
      setDelModal(false)
    }

    const VisibleChange = (flag) => {
      setVisible(flag);
    };
  
    return (
      <>
      
                  <h2 style={{fontWeight:"bold",fontSize:"1.5rem",margin:"1% 0% 0.5% 2%"}}>Panelist</h2>
         
        <div style={{padding:" 0 2% 0 2%",}}>
          

            <div className={classes.searchRow}>  
                  <div className={classes.searchDiv2}>           
                          <Input 
                          size="middle" 
                          placeholder= "Search" 
                          showSearch
                          allowClear={true}                   
                          onSearch={{}}
                          onChange={onNameChange}                         
                          prefix={<SearchOutlined className="custom-search-icon"/>} 
                          />
                    </div>
                
                    <div className="head-icon">
                            {filterPopover( <img src={filter}  
                                style={{width:"1.2rem",height:"1.2rem",marginRight: user?.role.includes('Recruiter') || user?.role.includes('Sourcer') ? "2rem":""}}
                                onVisibleChange={VisibleChange}
                             />)}

                              {/* <DownloadOutlined 
                              style={{fontSize:'large',color:'#0086FF',cursor:'pointer'}}
                              // onClick={downloadHandler} 
                            /> */}
                            
                            { user?.role.includes('Recruiter') || user?.role.includes('Sourcer') ? "" 
                            :<>
                              {tabkey==='2'&& 
                              <Button type="text" disabled={panelistPoolId?.length > 0 ? false : true}>
                                <DeleteOutlined onClick={deleteModal} 
                                  style={{fontSize:'large',color:'#0086FF',cursor:'pointer'}}/>
                              </Button>}
                              
                            <Button  size='middle' type="primary" style={{cursor:"pointer",fontWeight:"700",fontSize:"0.9rem",letterSpacing:"0.5px"}} 
                                onClick={()=>{setIsCreatePanelistPool(true)}}>
                                  CREATE POOL
                            </Button>
                            </>
                            }
                            {/* <Button 
                              type='primary' 
                              size='large'
                              style={{cursor:"pointer",borderRadius:'0.5rem',fontWeight:"bold",fontSize:"1rem"}} 
                              onClick={()=>{setIsAddPanelistVisible(true)}}>
                              ADD PANELIST
                              </Button> */}
                    </div> 
          </div>

          <Row style={{width:"100%",display:"flex"}}>

              <Tabs defaultActiveKey="1" onChange={tabOnChange} style={{}} >
                  <Tabs.TabPane  tab={<h3 style={{fontWeight:"700",fontSize:"1rem"}}>All Panelists</h3>} key={1}>
                    <div>
                    <Table 
                         key={tabkey}                        
                          className={classes.tableContent}
                         // rowSelection={rowSelection}
                          columns={columns}
                          loading={tableLoader}
                          dataSource={dataSource}
                          // rowKey={(record) => record}
                          scroll={{ y: 450 }}
                          pagination={{
                            position: ["bottomRight"],
                            pageSize: meta2?.itemsPerPage,
                             showSizeChanger: true,
                            pageSizeOptions:[10, 30, 50, 100], 
                            size:"small",
                            total:meta2?.totalItems,
                            current:parseInt(meta2?.currentPage),
                            onChange: handlePaginationChange,
                            onShowSizeChange: handlePageChange
                          }}
                          >
                      </Table>
                      </div>
                  </Tabs.TabPane>

                      <Tabs.TabPane tab={<h3 style={{fontWeight:"700",fontSize:"1rem"}}>Panelist Pool</h3>} key={2} >
                        <Table
                        className={classes.tableContent}
                            rowSelection={rowSelection}
                             loading={tableLoader}
                            columns={columns1}
                            dataSource={job}
                            rowKey={(record) => record}
                            scroll={{ y: 450 }}
                            pagination={{
                             
                              pageSize: meta1?.itemsPerPage,
                              total:meta1?.totalItems,
                              current:parseInt(meta1?.currentPage),
                               showSizeChanger: true,
                              pageSizeOptions:[10, 30, 50, 100], 
                              onChange:handlePageChange2,
                             
                              //onShowSizeChange:handlePageChange
                            }}
                        />
                      </Tabs.TabPane>
                  </Tabs>

                  <Modal visible={delModal} footer={false} onCancel={cancelHandler}>
                <Row justify="center" style={{marginTop:"17px"}}>
                  <h3 ><b>Delete?</b></h3></Row>
                <Row justify="center"><p> Are you sure you want to delete?</p></Row>
                <Row justify="center"><Space style={{marginBottom:"20px",marginTop:"30px"}}><Button style={{ borderColor: "#4DAAFF", color: "#4DAAFF" }} onClick={() => { cancelHandler() }}>Cancel</Button>
                  <Button loading={btnLoader} style={{ backgroundColor: "#FF9797", borderColor: "#FF9797", color: "white",marginLeft:"15px" }} onClick={() => {
                    // deletePanelist();
                    deletePool();
                    setDelModal(false)
                  }}>Yes</Button></Space></Row>
              </Modal>
               </Row>

        {/* {isAddPanelistVisible &&
        <AddPanelistModal
        isAddPanelistVisible={isAddPanelistVisible}
        setIsAddPanelistVisible={setIsAddPanelistVisible}
        designation={designations}
        refresh={getpanelist.bind(this)}
        />        
        } */}

        {/* {editPanelistVisible &&
        <EditPanelistModal
        editPanelistVisible={editPanelistVisible}
        setEditPanelistVisible={setEditPanelistVisible}
        selectDetails={selectDetails}
        setSelectDetails={setSelectDetails}
        designation={designations}
        refresh={getpanelist.bind(this)}
        />        
        } */}

        {isClickAssign &&
        <TimeSlotModal
        slotRecord={slotRecord}
        refresh={getpanelist.bind(this)}
        isClickAssign={isClickAssign}
        setIsClickAssign={setIsClickAssign}/>        
        } 

        {isCreatePanelistPool &&
        <PanelistPoolCreate
        isCreatePanelistPool={isCreatePanelistPool}
        setIsCreatePanelistPool={setIsCreatePanelistPool}
        refresh={ refreshed.bind(this)}/>        
        } 

        {filter1 &&
        <FilterModel
        filter1={filter1}
        setFilter1={setFilter1}/>        
        } 
        {editModal &&
        <EditPool
        refresh={refreshed.bind(this)}
        editPools={editPools}
        editModal={editModal}
        setEditModal={setEditModal}
        />}
        {
          editSlot && 
          <EditTimeModal 
          refresh={getpanelist.bind(this)}
          editSlot={editSlot}
          setEditSlot={setEditSlot}
          slotRecord={slotRecord}
          editDay={editDay}
          />
        }
        </div>
        </>
    )
}
export default Allpanelist;
