import React, { useState } from "react";
import { Card,Form, Input,Row,Col,Radio,Button,DatePicker } from "antd";
import { mapDispatchToProps, mapStateToProps } from "../redux/container"
import { connect ,useDispatch,useSelector} from "react-redux";
import { DeleteOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import {ArrowLeftOutlined } from "@ant-design/icons";
import { HrFeedbackActions } from "./store";

import moment from "moment";
const CompanyDetails = (props) => {
    const dispatch = useDispatch()
    const[addCompany,setAddComapny] = useState(false)
    const[addCompany2,setAddComapny2] = useState(false)
    const[addCompany3,setAddComapny3] = useState(false)
    const[addCompany4,setAddComapny4] = useState(false)
    const { basicInfo } = useSelector((state) => state.HrFeedback)
    const { feedback } = useSelector((state) => state.candidate.feedbackCandidateInfo)
   let feedbackType = feedback.find((value) => value.roundType === "HR")
    const { feedbackCandidate, updateInputField, calculateCompletion, feedbackStatus, maritalStatusList, automotiveBackground } = props;
    const onFInish=(values) => {        
              const value = {
                  ...values,
                  'Joinedon1': values['Joinedon1']?.format('YYYY-MM-DD'),
                  'Joinedon2': values['Joinedon2']?.format('YYYY-MM-DD'),
                  'Joinedon3': values['Joinedon3']?.format('YYYY-MM-DD'),
                  'Joinedon4': values['Joinedon4']?.format('YYYY-MM-DD'),
                  'Joinedon5': values['Joinedon5']?.format('YYYY-MM-DD'),
                  'Lefton1': values['Lefton1']?.format('YYYY-MM-DD'),
                  'Lefton2': values['Lefton2']?.format('YYYY-MM-DD'),
                  'Lefton3': values['Lefton3']?.format('YYYY-MM-DD'),
                  'Lefton4': values['Lefton4']?.format('YYYY-MM-DD'),
                  'Lefton5': values['Lefton5']?.format('YYYY-MM-DD'),
                 
                  
              }
              props.onNext("offerDetails")
              dispatch(HrFeedbackActions.updatefeedback(value))
      
    }
    const onAddCOmpany = () => {
           setAddComapny(true)
           
    }
    const onRemoveCompany = () => {
        setAddComapny(false) 
    }
    const onAddCOmpany2 = () => {
        setAddComapny2(true)
 }
 const onRemoveCompany2 = () => {
     setAddComapny2(false) 
 }
 const onAddCOmpany3 = () => {
    setAddComapny3(true)
}
const onRemoveCompany3 = () => {
 setAddComapny3(false) 
}
const onAddCOmpany4 = () => {
    setAddComapny4(true)
}
const onRemoveCompany4 = () => {
 setAddComapny4(false) 
}
const goBack = () => {
    props.onBack("AcademicDetails")
}

 // dateFormat = "YYYY-MM-DD"
    return(
        <>
        <Card title="Company details"style={{border:"black",width: "100%"}}>
        <Button type="text" onClick={goBack}><ArrowLeftOutlined /></Button>
            <Form onFinish={onFInish} layout="vertical">
                <Row><Col span={12}>
                    <Form.Item
                    name="Companyname1"
                    label={<b>Name</b>}
                    initialValue={feedbackType?.details?.Companyname1!==undefined && !basicInfo?.Companyname1 ? feedbackType?.details?.Companyname1 : basicInfo?.Companyname1}
                   
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="Jobpropfile1"
                    label={<b>Job Profile</b>}
                    initialValue={feedbackType?.details?.Jobpropfile1!==undefined ? feedbackType?.details?.Jobpropfile1  : basicInfo?.Jobpropfile1}
                  
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedon1"
                            label={<b>Joined On</b>}
                            initialValue={feedbackType?.details?.Joinedon1!==undefined ? moment(feedbackType?.details?.Joinedon1,"YYYY-MM-DD"): basicInfo?.Joinedon1 ? moment(basicInfo.Joinedon1,"YYYY-MM-DD"):null}
                             >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lefton1"
                            label={<b>Left On</b>}
                            initialValue={feedbackType?.details?.Lefton1!==undefined ? moment(feedbackType?.details?.Lefton1,"YYYY-MM-DD"): basicInfo?.Lefton1 ? moment(basicInfo.Lefton1,"YYYY-MM-DD"):null}
                            
                    >
                        <DatePicker format="YYYY-MM-DD" 
                       
                  /> 
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                              name="Leavereasons1"
                            label={<b>Leave reasons</b>}
                            initialValue={feedbackType?.details?.Leavereasons1!=undefined ?feedbackType?.details?.Leavereasons1 :   basicInfo?.Leavereasons1}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedctc1"
                            label={<b>Joined CTC</b>}
                            initialValue={feedbackType?.details?.Joinedctc1!=undefined && !basicInfo?.Joinedctc1 ?feedbackType?.details?.Joinedctc1 :   basicInfo?.Joinedctc1}
                             >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lastctc1"
                            label={<b>Last CTC</b>}
                            initialValue={feedbackType?.details?.Lastctc1!=undefined && !basicInfo?.Lastctc1 ?feedbackType?.details?.Lastctc1 :   basicInfo?.Lastctc1}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    
                    { addCompany === true ?  (<> <Row>
                     <p style={{marginTop:"8px"}}><b>Company 2</b></p>   <Button type="text" size="large" onClick={onRemoveCompany} style={{marginLeft:"10px"}}><DeleteOutlined style={{color:"red"}}/></Button></Row>
                        <Row>
                        <Col span={12}>
                    <Form.Item
                    name="Company2"
                    label={<b>Name</b>}
                    initialValue={feedbackType?.details?.Company2!==undefined && !basicInfo?.Company2 ? feedbackType?.details?.Company2 : basicInfo?.Company2}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="Jobpropfile2"
                    label={<b>Job Profile</b>}
                    initialValue={feedbackType?.details?.Jobpropfile2!==undefined ? feedbackType?.details?.Jobpropfile2 : basicInfo?.Jobpropfile2}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedon2"
                            label={<b>Joined On</b>}
                            initialValue={feedbackType?.details?.Joinedon2!==undefined ? moment(feedbackType?.details?.Joinedon2,"YYYY-MM-DD"): basicInfo?.Joinedon2 ? moment(basicInfo.Joinedon2,"YYYY-MM-DD"):null}
                             >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lefton2"
                            label={<b>Left On</b>}
                            initialValue={feedbackType?.details?.Lefton2!==undefined ? moment(feedbackType?.details?.Lefton2,"YYYY-MM-DD"): basicInfo?.Lefton2 ? moment(basicInfo.Lefton2,"YYYY-MM-DD"):null}
                    >
                        <DatePicker format="YYYY-MM-DD"
                        />
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                              name="Leavereasons2"
                            label={<b>Leave reasons</b>}
                            initialValue={feedbackType?.details?.Leavereasons2!=undefined ?feedbackType?.details?.Leavereasons2 :   basicInfo?.Leavereasons2}
                            
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedctc2"
                            label={<b>Joined CTC</b>}
                            initialValue={feedbackType?.details?.Joinedctc2!=undefined && !basicInfo?.Joinedctc2 ?feedbackType?.details?.Joinedctc2 :   basicInfo?.Joinedctc2}
                             >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lastctc2"
                            label={<b>Last CTC</b>}
                            initialValue={feedbackType?.details?.Lastctc2!=undefined && !basicInfo?.Lastctc2 ?feedbackType?.details?.Lastctc2 :   basicInfo?.Lastctc2}
                            
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>{addCompany2===false ?  (<Button onClick={onAddCOmpany2}>
                    <PlusCircleTwoTone size="large" /></Button>):("")}</>) : <Button onClick={onAddCOmpany}>
                    <PlusCircleTwoTone size="large" /></Button> }
                    { addCompany2 === true ?  (<>  <Row>
                     <p style={{marginTop:"8px"}}><b>Company 3</b></p>   <Button type="text" size="large" onClick={onRemoveCompany2} style={{marginLeft:"10px"}}><DeleteOutlined style={{color:"red"}}/></Button></Row>
                        <Row>
                        <Col span={12}>
                    <Form.Item
                    name="Companyname3"
                    label={<b>Name</b>}
                    initialValue={feedbackType?.details?.Companyname3!==undefined && !basicInfo?.Companyname3 ? feedbackType?.details?.Companyname3 : basicInfo?.Companyname3}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="Jobpropfile3"
                    label={<b>Job Profile</b>}
                    initialValue={feedbackType?.details?.Jobpropfile3!==undefined ? feedbackType?.details?.Jobpropfile3  : basicInfo?.Jobpropfile3}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedon3"
                            label={<b>Joined On</b>}
                            initialValue={feedbackType?.details?.Joinedon3!==undefined ? moment(feedbackType?.details?.Joinedon3,"YYYY-MM-DD"): basicInfo?.Joinedon3 ? moment(basicInfo.Joinedon3,"YYYY-MM-DD"):null}
                             >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lefton3"
                            label={<b>Left On</b>}
                            initialValue={feedbackType?.details?.Lefton3!==undefined ? moment(feedbackType?.details?.Lefton3,"YYYY-MM-DD"): basicInfo?.Lefton3 ? moment(basicInfo.Lefton3,"YYYY-MM-DD"):null}
                    >
                        <DatePicker format="YYYY-MM-DD" 
                       
                        />
                    </Form.Item>
                    </Col></Row>
                    <Row>
                    
                        <Col span={24}>
                            <Form.Item
                              name="Leavereasons3"
                            label={<b>Leave reasons</b>}
                            initialValue={feedbackType?.details?.Leavereasons3!=undefined ?feedbackType?.details?.Leavereasons3 :   basicInfo?.Leavereasons3}
                           
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedctc3"
                            label={<b>Joined Ctc</b>}
                            initialValue={feedbackType?.details?.Joinedctc3!=undefined && !basicInfo?.Joinedctc3 ?feedbackType?.details?.Joinedctc3 :   basicInfo?.Joinedctc3}
                           
                             >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lastctc3"
                            label={<b>Last CTC</b>}
                            initialValue={feedbackType?.details?.Lastctc3!=undefined && !basicInfo?.Lastctc3 ?feedbackType?.details?.Lastctc3 :   basicInfo?.Lastctc3}
                            
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>{addCompany3===false ?  (<Button onClick={onAddCOmpany3}>
                    <PlusCircleTwoTone size="large" /></Button>):("")}</>) : "" }
                    { addCompany3 === true ?  (<> <Row>
                     <p style={{marginTop:"8px"}}><b>Company 4</b></p>   <Button type="text" size="large" onClick={onRemoveCompany3} style={{marginLeft:"10px"}}><DeleteOutlined style={{color:"red"}}/></Button></Row>
                        <Row>
                        <Col span={12}>
                    <Form.Item
                    name="Companyname4"
                    label={<b>Name</b>}
                    initialValue={feedbackType?.details?.Companyname4!==undefined && !basicInfo?.Companyname4 ? feedbackType?.details?.Companyname4 : basicInfo?.Companyname4}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="Jobpropfile4"
                    label={<b>Job Profile</b>}
                    initialValue={feedbackType?.details?.Jobpropfile4!==undefined ? feedbackType?.details?.Jobpropfile4  : basicInfo?.Jobpropfile4}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedon4"
                            label={<b>Joined On</b>}
                            initialValue={feedbackType?.details?.Joinedon4!==undefined ? moment(feedbackType?.details?.Joinedon4,"YYYY-MM-DD"): basicInfo?.Joinedon4 ? moment(basicInfo.Joinedon4,"YYYY-MM-DD"):null}
                             >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lefton4"
                            label={<b>Left On</b>}
                            initialValue={feedbackType?.details?.Lefton4!==undefined ? moment(feedbackType?.details?.Lefton4,"YYYY-MM-DD"): basicInfo?.Lefton4 ? moment(basicInfo.Lefton4,"YYYY-MM-DD"):null}
                    >
                        <DatePicker format="YYYY-MM-DD"
                        
                        />
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        
                    
                    
                        <Col span={24}>
                            <Form.Item
                              name="Leavereasons4"
                            label={<b>Leave reasons</b>}
                            initialValue={feedbackType?.details?.Leavereasons4!=undefined ?feedbackType?.details?.Leavereasons4 :   basicInfo?.Leavereasons4}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedctc4"
                            label={<b>Joined CTC</b>}
                            initialValue={feedbackType?.details?.Joinedctc4!=undefined && !basicInfo?.Joinedctc4 ?feedbackType?.details?.Joinedctc4:   basicInfo?.Joinedctc4}
                             >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lastctc4"
                            label={<b>Last CTC</b>}
                            initialValue={feedbackType?.details?.Lastctc4!=undefined && !basicInfo?.Lastctc4 ?feedbackType?.details?.Lastctc4 :   basicInfo?.Lastctc4}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>{addCompany4===false ?  (<Button onClick={onAddCOmpany4}>
                    <PlusCircleTwoTone size="large" /></Button>):("")}</>
                    ) : "" }
                     { addCompany4 === true ?  (<> <Row>
                     <p style={{marginTop:"8px"}}><b>Company 5</b></p>   <Button type="text" size="large" onClick={onRemoveCompany4} style={{marginLeft:"10px"}}><DeleteOutlined style={{color:"red"}}/></Button></Row>
                        <Row>
                        <Col span={12}>
                    <Form.Item
                    name="Companyname5"
                    label={<b>Name</b>}
                    initialValue={feedbackType?.details?.Companyname5!==undefined && !basicInfo?.Companyname5 ? feedbackType?.details?.Companyname5 : basicInfo?.Companyname5}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="Jobpropfile5"
                    label={<b>Job Profile</b>}
                    initialValue={feedbackType?.details?.Jobpropfile5!==undefined && !basicInfo?.Jobpropfile5 ? feedbackType?.details?.Jobpropfile5  : basicInfo?.Jobpropfile5}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedon5"
                            label={<b>Joined On</b>}
                            initialValue={feedbackType?.details?.Joinedon5!==undefined && !basicInfo?.Joinedon5 ?feedbackType?.details?.Joinedon5:   basicInfo?.Joinedon5}
                             >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lefton5"
                            label={<b>Left On</b>}
                            initialValue={feedbackType?.details?.Lefton5!==undefined ? moment(feedbackType?.details?.Lefton5,"YYYY-MM-DD"): basicInfo?.Lefton5 ? moment(basicInfo.Lefton5,"YYYY-MM-DD"):null}
                    >
                        <DatePicker format="YYYY-MM-DD"
                        
                        
                        />
                    </Form.Item>
                    </Col></Row>
                    <Row>
                    
                        <Col span={24}>
                            <Form.Item
                              name="Leavereasons5"
                            label={<b>Leave reasons</b>}
                            initialValue={feedbackType?.details?.Leavereasons5!=undefined && !basicInfo?.Leavereasons5 ?feedbackType?.details?.Leavereasons5 :   basicInfo?.Leavereasons5}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Joinedctc5"
                            label={<b>Joined CTC</b>}
                            initialValue={feedbackType?.details?.Joinedctc5!=undefined && !basicInfo?.Joinedctc5 ?feedbackType?.details?.Joinedctc5 :   basicInfo?.Joinedctc5}
                             >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="Lastctc5"
                            label={<b>Last CTC</b>}
                            initialValue={feedbackType?.details?.Lastctc5!=undefined && !basicInfo?.Lastctc5 ?feedbackType?.details?.Lastctc5 :   basicInfo?.Lastctc5}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row></>) :("")}
                    <Row justify="end" marginTop="10px"><Button size="large" style={{paddingLeft:"50px",paddingRight:"50px"}} type="primary" htmlType="submit">Save And Next</Button></Row>
            </Form>
          
    </Card>
    
    </>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails)