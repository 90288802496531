import React from 'react';
import classes from "./viewModal.style.css"
import {Table,Modal,Button,Typography} from 'antd';
import { Api } from 'services';
import { overallDashboardActions } from '../store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useState,useEffect } from 'react';

const ViewModal=(props)=>{

    const dispatch=useDispatch();
    const viewModalOpen=useSelector(state=>state?.overallDashboard.viewModalOpen)
    const [tableData,setTableData]=useState([]);
    const [tableLoader,setTableLodaer]=useState(false);
    const [pageChange, setPageChange] = useState(1);
    const [pageSize,setPageSize]=useState(100);
    const dataKeys=props?.dataKeys;
    const types=props?.types;
    const jobId=props?.jobId;
    const startDate=props?.startDate;
    const endDate=props?.endDate;
    const totalCount=props?.modaldata?.data;

    
    const Columns=[
        {
            title:<span style={{fontSize: "14px",fontWeight: "700", color:"#000000"}}>CANDIDATE NAME</span>,
            dataIndex:"name",
            key:"name",
            render:(text,record)=>{
                return(
                    <div>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.7rem"}}>{record?.name ? record?.name:"NA"}</Typography.Text>
                    </div>
                )
            }
        },
        {
            title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>EMAIL</span>,
            dataIndex:"email",
            key:"email",
            render:(text,record)=>{
                return(
                    <div>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"8rem"}}>{record?.email ? record?.email:"NA"}</Typography.Text>
                    </div>
                )
            }
        },
        {
            title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>JOB PROFILE</span>,
            dataIndex:"job",
            key:"job",
            render:(text,record)=>{
                return(
                  <div>
                    <Typography.Text ellipsis={{tooltip:true}} style={{width:"8rem"}}>{record?.job ? record?.job : "NA"}</Typography.Text>
                  </div>
                )
              }
        },
        props?.modaldata?.title === "Offer Accepted" ? 
        {
            title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>ADDED BY</span>,
            dataIndex:"addedBy",
            key:"addedBy",
           
        }
        :
        {
            title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>HR COMMENT</span>,
            dataIndex:"hrComment",
            key:"hrComment",
           
        },
        {
            title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>HR STATUS</span>,
            dataIndex:"hrStatus",
            key:"hrStatus",
           
        },
        {
            title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>EXPECTED DOJ</span>,
            dataIndex:"expectedDoj",
            key:"expectedDoj",
           
        },
            
    ]

    useEffect(()=>{
        setTableLodaer(true)
  
        Api.get('/hiring-overview/hrRound')
           .params({
            dataKey:props?.modaldata?.dataKey,
            candidateType: types,
            jobId:jobId,
            startDate:startDate,
            endDate:endDate,
            page:pageChange,
            limit:pageSize,
           })
           .send((response)=>{
          
            if (typeof response != "undefined") {
              setTableLodaer(false);
              setTableData(response)
            }
           })
       },[jobId,startDate,endDate,dataKeys,pageSize,pageChange])


       const handlePageChange = (page, pageSize) => {
        setPageChange(page);
        setPageSize(pageSize);
      };

    return(
        <Modal 
                open={viewModalOpen} 
                onCancel={()=>{
                    dispatch(overallDashboardActions.updateViewModalOpen(false))}} 
                width="62rem"
                bodyStyle={{height: 500}}
                centered 
                className={classes.modalDesign}
                header={null} 
                footer={null}  
                closable={false}              
        >

        <div className={classes.modalView}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",margin:"1rem"}}>
                <div>
                    <h2>Interview {props?.modaldata?.title}</h2>
                </div>
                <div>
                    <Button disabled={props?.modaldata?.data === 0 ? true : false} type="primary" onClick={()=>{
                         Api.get('/hiring-overview/hrRound')
                            .params({
                                dataKey:props?.modaldata?.dataKey,
                                download:true,
                                candidateType:props?.types,
                                jobId:jobId,
                                startDate:startDate,
                                endDate:endDate,
                                page:1,
                                limit:totalCount,
                            })
                         .send((res) => {
                           if (typeof res != "undefined") {                                               
                             window.open(res, "_blank")?.focus();
                           }
                         })
                     }}>Export to Excel Sheet</Button>
                </div>
            </div>
            
            <Table style={{marginBottom:"1rem"}}
              loading={tableLoader}
              columns={Columns} 
              scroll={{y:270,x:0}}
              dataSource={tableData?.length > 0 ? tableData : []}
              pagination={{
                size:"small",
                pageSize: pageSize, 
                pageSizeOptions:[20, 30, 50, 100,150],
                total: totalCount,
                showSizeChanger:true,
                onShowSizeChange: handlePageChange,
                onChange:handlePageChange,
             }}            
           />          
        </div>         
       </Modal>
    )
}
export default ViewModal;