import "./overAllDashboard.css";
import Panelist from "./panelistTab/panelist";
import {Button,Tabs} from 'antd'
import JobProfile from "./jobProfileTab/jobProfile";
import Recruiter from "./recruiterTab/recruiter";
import HRRound from "./HRRoundTab/HRRound";
import MainPage from "./AppInsightTab/MainPage";
const { TabPane } = Tabs;

const OverAllDashboard=()=>{
    return(
        <div className="overAllDashboard" >
                <h1>Dashboard</h1>
                <div style={{marginTop:"0rem"}}>
                                <Tabs>
                                                <TabPane tab={<span>Panelist</span>} key="Panelist">
                                                    <Panelist/>
                                                </TabPane>

                                                <TabPane tab={<span>Job Profiles</span>} key="Job Profiles">
                                                    <JobProfile/>
                                                </TabPane>

                                                <TabPane tab={<span>Recruiters</span>} key="Recruiters">
                                                    <Recruiter/>
                                                </TabPane>

                                                <TabPane tab={<span>HR Rounds</span>} key="HR Rounds">
                                                    <HRRound/>
                                                </TabPane>

                                                <TabPane tab={<span>App Insights</span>} key="App Insights">
                                                    <MainPage/>
                                                </TabPane>
                                </Tabs>                 
                </div>
        </div>
    )
}
export default OverAllDashboard;

