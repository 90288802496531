import React, { useEffect, useState } from 'react';
import { 
     Row,
     Col, 
     Input, 
     Modal, 
     Select, 
     Form, 
     TreeSelect,
     Button
 } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { TreeNode } from 'antd/lib/tree-select';
import Api from 'services/Api';
import { useSelector } from 'react-redux';
import classes from './createPanelistPool.module.css';

const PanelistPoolCreate = ({setIsCreatePanelistPool,isCreatePanelistPool,refresh}) =>{
    // const dispatch = useDispatch();
   const {edit,panelistPool}=useSelector(state=>state.panelist);

    const handleCancel = () => {
        setIsCreatePanelistPool(false);
      };

    const onAdd = () => {
        setIsCreatePanelistPool(false);
    }
    let obj=[]
    
    const onFinish= (val) =>{
        const job=jobData.find(element=>element.title===vacancy)
       Api.post("/hiring-panelist/createPool")
       .params({jdType:val.jobExperience,jobId:job?.id,pools:obj})
       .send((response)=>{
       if(response?.show?.type === "success"){
        refresh();
        setIsCreatePanelistPool(false);
       }
    })}

    const [array,setArray]=useState([])

    const [jobData, setJobData]=useState([])
    const [vacancy,setVacancy]=useState('');
    const [panelist,setPanelist]=useState([])
  

    const [search,setsearch]=useState("")
    const [jobExperience,setJobExperience]=useState("")
    const [jobs,setJobs]=useState("")
   
    // const CreatePool = ()=>{
      
    //      const job=jobData.find(element=>element.title===vacancy)
        
    
        
    //     Api.post("/hiring-panelist/createPool")
    //     .params({jdType:jobExperience,jobId:job?.id,pools:obj})
    //     .send((response)=>{
    //     setIsCreatePanelistPool(false);})
        
    // }

const [pool,setPool]=useState({})
// const Getvacancy =(q)=>{
//     Api.get("/hiring-panelist/getjobRounds")
//     .params({jobExp:jobExperience,search:q})
//     .send((response)=>{
//         let value = [];
//         let list = [];
//         let list1 =[];
//     if (response !== undefined){
//         list = response?.jobs?.map((element)=>{value.push(element);return element;})
//         list1 = response?.panelist?.map((element)=>{
//         value.push(element);
//     return element;
//         })
//         }
//         setJobData(list);
//         setPanelist(list1);
//         })} 

      

        const Title=edit?"Edit Pool of Panelist":"Pool of Panelist";
        const buttonName=edit?"SAVE":"CREATE";

    return(
        <>
         
              
                    <Modal 
                    // style={{textAlign:'center'}} 
                    visible={isCreatePanelistPool}
                    // onOk={onAdd} 
                    onCancel={handleCancel}
                    width="80vh"
                    
                    footer={null}
                    >
                    <div className={classes.container}>
                        <h1>Pool of Panelist</h1>
                            <Form  layout="vertical" autoComplete="off" onFinish={onFinish} style={{maxHeight:"60vh",overflowY:"auto"}}>
                                <div className={classes.jdRow}>
                                    
                                        <Form.Item name="jobExperience" label="JD TYPE" 
                                        rules={[
                                        {
                                        required: true,
                                        message:"Please select the valid JD Type!"
                                        },
                                        ]}>
                                            <Select
                                                 size='middle'
                                                style={{width:"100%"}}
                                                dropdownStyle={{
                                                maxHeight:400,
                                                overflow:"auto",
                                                }}
                                                placeholder="Select JD type"
                                                onChange={(val)=>{
                                                    
                                                    setJobExperience(val)
                                                    Api.get("/hiring-panelist/getjobRounds")
                                                    .params({jobExp:val,search:search})
                                                    .send((response)=>{
                                                        let value = [];
                                                        let list = [];
                                                        let list1 =[];
                                            if (response !== undefined){
                                            list = response?.jobs?.map((element)=>{value.push(element);return element;})
                                            list1 = response?.panelist?.map((element)=>{
                                                value.push(element);
                                                return element;
                                            })
                                                    }
                                                    
                                                    setJobData(list);
                                                    setPanelist(list1);
                                                })}}
                                            >
                                                <Option value="Fresher">Fresher</Option>
                                                <Option value="Experienced">Experience</Option>
                                            </Select>
                                        </Form.Item>
                                    
                                    
                                    <Form.Item name="job_vacancy" label="JOB VACANCY"
                                    rules={[
                                        {
                                            required:true,
                                            message:"Please select the valid job vacancy!"
                                        }
                                    ]}
                                    >
                        
                                        <Select 
                                                size='middle'
                                                showSearch
                                                placeholder="Select"
                                                optionFilterProp="children"
                                                onChange={(e)=>{setVacancy(e)}}
                                                onSearch={(e)=>{}}
                                            allowClear
                                            >
                                                {jobData?.map((val,index)=>{
                                                    return(
                                                        <Option value={val?.title} key={val?.id}>{val?.title}</Option>
                                                        )
                                                })}
                                            </Select>
                                    
                                    </Form.Item>
                                    

                                </div>
            {/* <Form.Item name="pool"> */}
                                <h2 name="h5"  style={{color:'#6F6F6F',display: "flex", justifyContent: "left",marginTop:'-10px'}}>PANELIST POOL</h2>
                                <div name="div" >
                                    {jobData?.map((item,key)=>{

                                        let arr1=[];
                                        let arr2=[];
                                        if(item.title===vacancy)
                                        {
                                            let length=item?.jobInterviewrounds.length;
                                            let pool=[];
                                            
                                            return (<div key={key} name="div1">
                                                
                                                {item.jobInterviewrounds.map((inter,index)=>{
                                                    return(
                                                        <>
                                                        {/* <div key={index} name={"round"+inter.round}>
                                                            <h5 style={{textAlign:'left'}} name="h5 round">Round {parseInt(inter.round)} | {inter.roundType}</h5> */}
                                                            <Form.Item name={"round"+inter.round} label={<span style={{textAlign:'left'}} name="round">Round {parseInt(inter.round)} | {inter.roundType === "Relationship"?"HR Relationship":inter.roundType}</span>}
                                                            rules={[{required:true,message:"Please select the panelist!"}]}
                                                            >
                                                            <Select 
                                                            size='middle'
                                                            style={{width:'100%',textAlign:'left'}}
                                                showSearch
                                                mode="multiple"
                                                placeholder="Select panelist"
                                                optionFilterProp="children"
                                                onChange={(e, val)=>{
                                                let arr=[];                                       
                                                    val?.map(val=>{arr.push(parseInt(val.key))})
                                                   
                                                    if (!(obj.map(item=>item.round).includes(inter.round))){
                                                        obj.push({round:inter.round, roundType:inter?.roundType,panelistIds:arr,interViewRoundId:inter?.id})
                                                    }else{
                                                        obj.forEach((item)=>{
                                                            if(item.round===inter.round){
                                                                item.panelistIds = arr;
                                                            }
                                                        })
                                                    }
                                                   
                                                    }}
                                                onSearch={(e)=>{}}
                                            allowClear
                                            >
                                                {panelist?.map((val,index)=>{
                                                    return(
                                                        <Option value={val?.name} key={val?.id}>{val?.name}</Option>
                                                        )
                                                })}
                                            </Select>
                                            </Form.Item>
                                           
                                                        </>
                                                    )
                                                })}
                                            </div>)
                                        }
                                    })}
                                </div>
                                {/* </Form.Item> */}

                                        <div className={classes.buttonDiv}>
                                            <Button
                                            style={{marginRight:'10px'}}
                                            onClick={handleCancel}
                                            >
                                            CANCEL
                                            </Button>

                                            <Button 
                                            htmlType='submit'
                                            type='primary'
                                            // onClick={CreatePool}
                                            className={classes.create}
                                            >
                                           CREATE
                                            </Button>
                                            </div>
                            </Form>
                    </div>

     
       
                    </Modal>
              
        </>
    )
}

export default PanelistPoolCreate;