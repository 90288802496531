import { PageHeader, Tabs, Button, Table, Input,Select,Popover,Typography,DatePicker,Modal } from "antd";
import React from "react";
import { connect } from "react-redux";
import { SearchBar } from "services/button";
import { Color, SEARCH_VALUE } from "services";
import "services/styles.css";
import CandidateCard from "./candidateCard";
import CandidateViewModal from "./candidateViewModal";
import NewCandidate from "./newCandidate";
import ShortlistedCandidate from "./shortlistedCandidate";
import { operationType, inputFieldType } from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import "./styles.css";
import moment from "moment";
import Notification from "services/Notification";
import HistoryCandidate from "./historyCandidate";
import DuplicateCandidate from "./duplicateCandidate";
import DownloadCandidateModal from "./downloadCandidateModal";
import ProposeJdChangeProfile from "./proposedJdchngCandidate";
import classes from "./candidate.module.css";
import { adminActions } from "pages/dashboard-home/store";
import RegLinkModal from "pages/dashboard-home/regLinkModal/regLinkModal";
import { AiOutlineSearch } from "services/reactIcons";
import download from "../../assets/download.png";
import store from "store";
import CandidateEmailSend from "./candidateEmailSend";
import { DownOutlined, SearchOutlined,} from "@ant-design/icons";
import SendEmailModal from "../dashboard-home/sendEmailModal"
import { templateActions } from "pages/email-template/store";
import Api from "../../services/Api"

const { TabPane } = Tabs;

class Candidate extends React.Component {

constructor(){
  super();
  this.state={candidateLinkModal:false,tabStatus:"New",searchKey:"",jobStatus:"Active",open:false,
  selectedComment:"",searchedComment:"",
  windowDimensions: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  isSendEmailMailVisible:false,
  selectedUpdateObject:null,
  selectedRowData:[],
  showDateModal:false, 
  excelDate:null,
  btn2Loader:false,
}
}

  refreshPage() {
    window.location.reload();
    this.funcRef();
  }

  fetchTemplate = () => {
    Api.get("/hiring-template/all").send((res) => {
      if (res && res.length > 0) {
        store.dispatch(templateActions.updateTemplates(res));
      }
    });
  };

  componentDidMount() {
    this.props.updateInputField(inputFieldType.CANDIDATE_CLEAR, []);
    this.props.getAllCandidates(this.props.history);
    // this.refreshPage()
    window.addEventListener("resize", this.handleResize);

    // Initial dimensions
    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
    this.fetchTemplate();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.fetchTemplate();
  }

  handleResize = () => {
    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };

  onSearch = (event) => {
    this.props.performOperation(
      inputFieldType.CANDIDATE_TABLE_SEARCH,
      event.target.value
    );
    this.props.getAllCandidates(this.props.history);
  };

  toggleSendMailModal = (record = null) => {
    if (!this.state.isSendEmailMailVisible) {
        // setUpdateObject(selectedRow);
        this.setState({selectedUpdateObject:this.props.selectedRow})
        const filteredCandidates = this.props.candidates.filter(candidate => {
          return this.props.selectedRow.includes(candidate.id);
        });;        
        this.setState({
            selectedRowData: filteredCandidates
        });
    }
    this.setState({isSendEmailMailVisible:!this.state.isSendEmailMailVisible});
  };


  componentDidUpdate(prevProps, prevState){
    // if(prevState.searchKey!==this.state.searchKey)
    // {
    //   this.props.performOperation(
    //     inputFieldType.CANDIDATE_TABLE_SEARCH,
    //     this.state.searchKey
    //   );
    //   this.props.getAllCandidates(this.props.history);
    // // }
    // else if(this.state.searchKey===""&&this.props.candidateTableSearch){
    //   this.props.performOperation(
    //     inputFieldType.CANDIDATE_TABLE_SEARCH,
    //     this.state.searchKey
    //   );
    //   this.props.getAllCandidates(this.props.history);
    // }

    if(this.state.tabStatus==="New"&&this.props.candidateScreenType!=="New")
    {
      this.props.performOperation(operationType.CANDIDATE_SCREEN_TYPE, "New");
      this.props.getAllCandidates(this.props.history);
    }
    if (this.props.candidateJobDescription.length >0) {
      let value=this.props.candidateJobs.filter(
      (candidateJob) => this.props.candidateJobDescription.includes(candidateJob.id)
    )
          let positiveVacancies=0
      if(this.props.candidateJobDescription?.length > 0){
          for(let i=0;i<value.length;i++){
            positiveVacancies=positiveVacancies+value[i].vacancies
          }
        if(this.props.candidateJobDescription.length >0 && positiveVacancies !== this.props.totalExperiencedVacancies){
        this.props.updateInputField(inputFieldType.TOTAL_EXPERIENCED_VACANCIES, positiveVacancies)
        this.props.getAllCandidates(this.props.history);
      }}
      if(this.props.candidateJobDescription.length ===0){
        this.props.updateInputField(inputFieldType.TOTAL_EXPERIENCED_VACANCIES, [])
        this.props.getAllCandidates(this.props.history);
      }
      }
}

  render() {
    const {
      performOperation,
      candidateScreenType,
      history,
      getCandidatesOnTabChange,
      candidateTableSearch,
      jobStatus,
      candidateViewModal,
      candidates
    } = this.props;
    const Option=Select.Option;
    
    const {user} = store.getState().auth
    
    const selectOptions = [
      "No relevant experience","Less relevant experience","Fake Experience",
      "Very High CTC",
      "Not compatible",
      "NP not acceptable",
      "Wrong Profile ",
      "90 days Consider later",
      "Other"
    ]
    const { width, } = this.state.windowDimensions;
    const { windowDimensions } = this.state;
    const {regLinkModalOpen}=store.getState().candidateFresher

    return (
      <div className={classes.container} style={{display:"flex",flexDirection:"column",width:"100%"}}>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",}}>
              {/* <PageHeader
                onBack={() => null}
                title={ */}
                <span style={{fontSize: width > 700 ? "1.5rem":"1rem",fontWeight:"bold"}}>Candidate Management: Experienced</span>
                {/* backIcon={false}
              /> */}
              {width > 700 && 
              <div>
                 <Button style={{height:"1.9rem",borderColor: "#0086FF",color:"#0086FF",borderRadius: "4px",fontWeight:"600",
                          marginRight:"0.9rem",width:""}}
                  onClick={()=>{this.setState({showDateModal:true});}}
                 >
                  Export Excel
                 </Button>
                <span style={{fontSize:"0.95rem",fontWeight:"600"}}>Job Status : </span>
                <Select defaultValue={jobStatus} value={jobStatus} bordered={false} placeholder="Active Job" style={{width:"8rem",backgroundColor:"white",height:"2rem",marginLeft:"0.5rem"}} onChange={(event)=>{
                  // this.setState({jobStatus:event})
                  this.props.performOperation(inputFieldType.CANDIDATE_JOB_STATUS,event);
                  this.props.updateInputField(inputFieldType.CANDIDATE_JOB_DESCRIPTION, []);
                  let activeJobsValue=this.props.candidateJobs?.filter((item)=>item.status.includes(event))
                  console.log("ssssValue",activeJobsValue)
        
                  let positiveVacancies=0
                  for(let i=0;i<activeJobsValue.length;i++){
                    positiveVacancies=positiveVacancies+activeJobsValue[i].vacancies
                  }
                  this.props.updateInputField(inputFieldType.TOTAL_EXPERIENCED_VACANCIES, positiveVacancies)
                  this.props.getAllCandidates(this.props.history);
                }}>
                    <Option value="Active">Active</Option>
                    <Option value="Inactive">In active</Option>
                </Select>
              </div>
              }
              {width < 700 && <Button
                        type="text"
                        style={{
                          borderRadius: "none",
                          color: "blue",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                        icon={
                          <img
                            style={{ width: "24px", height: "24px" }}
                            src={download}
                          ></img>
                        }
                        // size="large"
                        onClick={() => {
                          performOperation(
                            operationType.SHOW_DOWNLOAD_CANDIDATE_MODEL,
                            true
                          );
                        }}
                      />}
        </div>
        {width > 700 && 
        <div style={{marginTop:"-0.2rem"}}>
              <CandidateCard jobStatus={this.state.jobStatus}/>
        </div>
        }
      <div>
                <Tabs
                  defaultActiveKey="New"
                  activeKey={this.state.tabStatus}
                  tabBarExtraContent={width > 700 && 
                    <div className={classes.tabSection}>
                      {(this.state.tabStatus === "New" || this.state.tabStatus === "Ongoing") && (user?.role.includes("Admin")|| user?.role.includes("Super-Admin")||user?.role.includes("Recruiter")) ?
                        <Button disabled={this.props.selectedRow.length <= 0}                                     
                        style={{
                          height:"2.1rem",
                          backgroundColor: "#76DC4B",
                          color: "#FFFFFF",
                          borderRadius: "4px",
                          fontWeight:"610",
                          opacity:this.props.selectedRow.length <= 0 && 0.5 
                        }}
                        onClick={() => {
                          this.toggleSendMailModal(null);
                        }}
                      >
                        Send Test Link
                      </Button>:""
                      }
                      <Button
                        type="text"
                        style={{
                          borderRadius: "none",
                          color: "blue",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                        icon={
                          <img
                            style={{ width: "24px", height: "24px" }}
                            src={download}
                          ></img>
                        }
                        // size="large"
                        onClick={() => {
                          performOperation(
                            operationType.SHOW_DOWNLOAD_CANDIDATE_MODEL,
                            true
                          );
                        }}
                      />

                      {this.state.tabStatus!=="New"&&<Popover
                      placement="bottomRight"
                          content={
                            <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"16vw",maxHeight:"43vh",overflowY:"auto"}}>
                              {selectOptions?.map((item,index)=>{
                                  return(
                                    <span
                                    style={{color:this.state.selectedComment===item?"#0086ff":"#000",cursor:"pointer"}}
                                    onClick={()=>{
                                      this.setState({selectedComment:item});
                                      if(item!=="Other")
                                      {
                                        this.props.performOperation(
                                          inputFieldType.SHORTLISTER_COMMENT,
                                         item
                                        );
                                        this.props.getAllCandidates(this.props.history);
                                        this.setState({open:false})
                                      }
                                      else 
                                      {

                                      }
                                      
                                    }}
                                    >{item}</span>
                                  )
                              })}

                              {this.state.selectedComment==="Other"&&<Input type="text" placeholder="Search Comment" onChange={(e)=>{
                                this.setState({searchedComment:e.target.value.toLocaleLowerCase()});
                              }}/>}

                              {this.state.selectedComment==="Other"&&<div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                              <Button type="primay" style={{background:"#0086ff",color:"#fff",border:"1px solid #0086ff"}} onClick={()=>{
                                 this.props.performOperation(
                                  inputFieldType.SHORTLISTER_COMMENT,
                                 this.state.searchedComment
                                );
                                this.props.getAllCandidates(this.props.history);
                               this.setState({open:false})
                              }}>Ok</Button>
                               <Button type="primay" style={{color:"#0086ff",border:"1px solid #0086ff"}} onClick={()=>{
                                this.props.performOperation(
                                  inputFieldType.SHORTLISTER_COMMENT,
                                 ''
                                );
                                this.setState({selectedComment:''});
                                this.setState({searchedComment:''});
                                this.props.getAllCandidates(this.props.history);
                                this.setState({open:false});
                                this.setState({open:false});
                              }}>Cancel</Button>
                              </div>}
                           
                              
                           </div>
                        }
                          title={<div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <span style={{fontSize:"1.3rem"}}>Title</span>
                            <Button 
                            // style={{color:"#0086ff",border:"1px solid #0086ff"}}
                            type="primary"
                            onClick={()=>{
                              this.props.performOperation(
                                inputFieldType.SHORTLISTER_COMMENT,
                               ''
                              );
                              this.setState({selectedComment:''});
                              this.setState({searchedComment:''});
                              this.props.getAllCandidates(this.props.history);
                              this.setState({open:false});
                            }}

                            >Reset</Button>
                          </div>}
                          trigger="click"
                          open={this.state.open}
                          onOpenChange={(e)=>{
                            this.setState({open:e})
                          }}
                        >
                          <div style={{border:"1px solid #dfdfdf",width:"16vw",height:"1.95rem",background:"#fff",display:"flex",alignItems:"center",padding:"0 0.5rem",justifyContent:"space-between",cursor:"pointer"}}>
                          <Typography.Text style={{maxWidth:"13vw"}} ellipsis={{tooltip:true}}> <span style={{color:"#d9d9d9"}}>{this.state.selectedComment?this.state.selectedComment:"Filter by shortlister's comment"}</span></Typography.Text>
                          <DownOutlined/>
                          </div>
                        </Popover>}

                      <Input
                        type="text"
                        allowClear={true}
                        style={
                          this.state.tabStatus === "New"? {
                          width: "12.5rem",
                          borderRadius: "none",
                          // background: "none",
                        }: {
                          width: "15rem",
                          borderRadius: "none",
                          // background: "none",
                        }}
                        prefix={<SearchOutlined className="custom-search-icon" />}
                        placeholder={"Type And Press Enter.."}
                        // onClick={this.onSearch}
                        onPressEnter={this.onSearch}
                        value={candidateTableSearch}
                        onChange={(event) =>{
                          this.props.performOperation(
                            inputFieldType.CANDIDATE_TABLE_SEARCH,
                            event.target.value
                          );
                        }}
                      />
                    
                      {!user?.role?.includes("Agency") 
                      && this.state.tabStatus === "New" ? (
                        <>
                        {user?.role.includes("Sourcer")||
                        user?.role.includes("Recruiter") ||user?.role.includes("Admin")|| user?.role.includes("Super-Admin")?
                          <Button
                            // size="large"
                            style={{ width: "8rem", height: "2rem" }}
                            type="primary"
                            onClick={() => {
                              store.dispatch(adminActions.updateModalOpen(true));
                            }}
                          >
                            <span style={{ fontSize: "12px" }}>Add Profile</span>
                          </Button>:""}

                          { user?.role?.includes("Admin") || user?.role?.includes("Super-Admin") ||
                           user?.role?.includes("Recruiter") || user?.role.includes("Sourcer") ? 
                          <Button
                            type="primary"
                            ghost
                            style={{
                              width: "8rem",
                              height: "2rem",
                              background: "#E1EBFE",
                              border: "none",
                              borderRadius: "4px",
                            }}
                            onClick={() => {
                              store.dispatch(adminActions.updateRegLinkModalOpen(true))
                              store.dispatch(adminActions.updateRegLinkcandType("Experienced"))
                              this.setState({candidateLinkModal:true})
                            }}
                          >
                            <span style={{ fontSize: "12px" }}>Registration link</span>
                          </Button>
                         :""} 
                        
                        </>

                      ):""}
                    </div>
                  }
                  onChange={(key) => {
                    this.props.performOperation(
                      inputFieldType.SHORTLISTER_COMMENT,
                     ''
                    );
                    this.setState({selectedComment:''});
                    this.setState({searchedComment:''});
                    this.setState({tabStatus:key});
                    this.props?.updateInputField(inputFieldType.CANDIDATE_AGENCY,"")
                    performOperation(operationType.CANDIDATE_SCREEN_TYPE, key);
                    // getCandidatesOnTabChange(history);

                    this.props.updateInputField(
                      inputFieldType.CANDIDATE_AGENCY_FOR_HISTORY,
                      []
                    );
                    getCandidatesOnTabChange(history);
                  }}
                  style={{ overflow: "hidden" }}
                >
                  <TabPane
                    tab={
                      <span
                        style={
                          this.state.tabStatus === "New"
                            ? { margin:'0', fontSize: "12px", fontWeight: "bold" }
                            : {
                                fontSize: "13px",
                                fontWeight: "bold",
                                color: Color.midGrey,
                              }
                        }
                      >
                        New
                      </span>
                    }
                    key="New"
                  > 
                    {this.state.tabStatus==="New"&&<>{width > 700 ?
                    <NewCandidate />
                    : <>
                    <Input
                          type="text"
                          allowClear={true}
                          style={{
                            width: "100%",
                            height:"2.2rem",
                            borderRadius: "none",
                            marginTop:"-0.5rem",
                            marginBottom:"0.5rem"
                          }}
                          prefix={<SearchOutlined className="custom-search-icon" />}
                          placeholder={"Type And Press Enter / Click outside anywhere.."}
                          // onClick={this.onSearch}
                          onBlur={this.onSearch} 
                          onPressEnter={this.onSearch}
                          value={candidateTableSearch}
                          onChange={(event) =>{
                            this.props.performOperation(
                              inputFieldType.CANDIDATE_TABLE_SEARCH,
                              event.target.value
                            );
                          }}
                      />
                      {width < 700 && 
                      <div style={{marginTop:"-0.2rem"}}>
                            <CandidateCard jobStatus={this.state.jobStatus}/>
                      </div>
                      }
                      <NewCandidate />
                     </>
                    }</>}
                  </TabPane>
                  <TabPane
                    tab={
                      <span
                        style={
                          this.state.tabStatus === "Ongoing"
                            ? { margin:'0', fontSize: "13px", fontWeight: "bold" }
                            : {
                              margin:'0',
                                fontSize: "13px",
                                fontWeight: "bold",
                                color: Color.midGrey,
                              }
                        }
                      >
                        Ongoing
                      </span>
                    }
                    key="Ongoing"
                  >
                      {this.state.tabStatus==="Ongoing"&&<>{width > 700 ?
                        <ShortlistedCandidate />
                        : <><Input
                          type="text"
                          allowClear={true}
                          style={{
                            width: "100%",
                            height:"2.2rem",
                            borderRadius: "none",
                            marginTop:"-0.5rem",
                            marginBottom:"0.5rem"
                          }}
                          prefix={<SearchOutlined className="custom-search-icon" />}
                          placeholder={"Type And Press Enter / Click outside anywhere.."}
                          // onClick={this.onSearch}
                          onBlur={this.onSearch} 
                          onPressEnter={this.onSearch}
                          value={candidateTableSearch}
                          onChange={(event) =>{
                            this.props.performOperation(
                              inputFieldType.CANDIDATE_TABLE_SEARCH,
                              event.target.value
                            );
                          }}
                      />
                       {width < 700 && 
                        <div style={{marginTop:"-0.2rem"}}>
                              <CandidateCard jobStatus={this.state.jobStatus}/>
                        </div>
                        }
                      <ShortlistedCandidate />
                     </>
                    }</>}
                     {/* {this.state.tabStatus==="Ongoing"&&<ShortlistedCandidate />} */}
                  </TabPane>
                  <TabPane
                    tab={
                      <span
                        style={
                          this.state.tabStatus === "Rejected"
                            ? {  margin:'0',fontSize: "13px", fontWeight: "bold" }
                            : {
                              margin:'0',
                                fontSize: "13px",
                                fontWeight: "bold",
                                color: Color.midGrey,
                              }
                        }
                      >
                        Rejected
                      </span>
                    }
                    key="Rejected"
                  >
                     {this.state.tabStatus==="Rejected"&&<>{width > 700 ?
                        <ShortlistedCandidate tabStatus={this.state.tabStatus}/>
                        : <><Input
                          type="text"
                          allowClear={true}
                          style={{
                            width: "100%",
                            height:"2.2rem",
                            borderRadius: "none",
                            marginTop:"-0.5rem",
                            marginBottom:"0.5rem"
                          }}
                          prefix={<SearchOutlined className="custom-search-icon" />}
                          placeholder={"Type And Press Enter / Click outside anywhere.."}
                          // onClick={this.onSearch}
                          onBlur={this.onSearch} 
                          onPressEnter={this.onSearch}
                          value={candidateTableSearch}
                          onChange={(event) =>{
                            this.props.performOperation(
                              inputFieldType.CANDIDATE_TABLE_SEARCH,
                              event.target.value
                            );
                          }}
                      />
                       {width < 700 && 
                        <div style={{marginTop:"-0.2rem"}}>
                              <CandidateCard jobStatus={this.state.jobStatus}/>
                        </div>
                        }
                      <ShortlistedCandidate tabStatus={this.state.tabStatus}/>
                     </>
                    }</>}
                    {/* {this.state.tabStatus==="Rejected"&&<ShortlistedCandidate tabStatus={this.state.tabStatus}/>} */}
                  </TabPane>

                  {/* <TabPane
                    tab={<span
                      style={
                        candidateScreenType === "Rejected"
                          ? { fontSize: "13px", fontWeight: "bold" }
                          : {
                              fontSize: "13px",
                              fontWeight: "bold",
                              color: Color.midGrey,
                            }
                      }
                    >History</span>}
                    key="History"
                  >
                    <NewCandidate />
                  </TabPane> */}
                  {/* <TabPane tab={<span
                      style={
                        candidateScreenType === "Duplicate"
                          ? { fontSize: "13px", fontWeight: "bold" }
                          : {
                              fontSize: "13px",
                              fontWeight: "bold",
                              color: Color.midGrey,
                            }
                      }
                    >Duplicate</span>} key="Duplicate">
            

                  </TabPane> */}
              

                  {user?.role?.includes("Admin") ||
                  user?.role?.includes("Super-Admin") ||
                  user?.role?.includes("Agency") ||
                  user?.role?.includes("Recruiter") || user?.role.includes("Sourcer") ||
                  user?.role?.includes("HR")? (
                    <TabPane
                      tab={<span style={
                        this.state.tabStatus === "History"
                            ? {  margin:'0',fontSize: "13px", fontWeight: "bold" }
                            : {
                              margin:'0',
                                fontSize: "13px",
                                fontWeight: "bold",
                                color: Color.midGrey,
                              }}>History</span>}
                      key="History"
                    >
                      {this.state.tabStatus==="History"&&<>{width > 700 ?
                        <HistoryCandidate />
                        : <><Input
                          type="text"
                          allowClear={true}
                          style={{
                            width: "100%",
                            height:"2.2rem",
                            borderRadius: "none",
                            marginTop:"-0.5rem",
                            marginBottom:"0.5rem"
                          }}
                          prefix={<SearchOutlined className="custom-search-icon" />}
                          placeholder={"Type And Press Enter / Click outside anywhere.."}
                          // onClick={this.onSearch}
                          onBlur={this.onSearch} 
                          onPressEnter={this.onSearch}
                          value={candidateTableSearch}
                          onChange={(event) =>{
                            this.props.performOperation(
                              inputFieldType.CANDIDATE_TABLE_SEARCH,
                              event.target.value
                            );
                          }}
                      />
                       {width < 700 && 
                        <div style={{marginTop:"-0.2rem"}}>
                              <CandidateCard jobStatus={this.state.jobStatus}/>
                        </div>
                        }
                      <HistoryCandidate />
                     </>
                        }</>}
                      {/* {this.state.tabStatus==="History"&&<HistoryCandidate />} */}
                    </TabPane>
                  ) : (
                    ""
                  )}
                  {user?.role?.includes("Admin") ||
                  user?.role?.includes("Super-Admin") ||
                  user?.role?.includes("HR") ||
                  user?.role?.includes("Recruiter") || user?.role.includes("Sourcer") ? (
                    <TabPane
                      tab={<span style={
                        this.state.tabStatus === "Duplicate"
                            ? {  margin:'0',fontSize: "13px", fontWeight: "bold" }
                            : {
                              margin:'0',
                                fontSize: "13px",
                                fontWeight: "bold",
                                color: Color.midGrey,
                              }
                        
                            }>Duplicate</span>}
                      key="Duplicate"
                    >
                       {this.state.tabStatus==="Duplicate"&&<>{width > 700 ?
                        <DuplicateCandidate />
                        : <><Input
                          type="text"
                          allowClear={true}
                          style={{
                            width: "100%",
                            height:"2.2rem",
                            borderRadius: "none",
                            marginTop:"-0.5rem",
                            marginBottom:"0.5rem"
                          }}
                          prefix={<SearchOutlined className="custom-search-icon" />}
                          placeholder={"Type And Press Enter / Click outside anywhere.."}
                          // onClick={this.onSearch}
                          onBlur={this.onSearch} 
                          onPressEnter={this.onSearch}
                          value={candidateTableSearch}
                          onChange={(event) =>{
                            this.props.performOperation(
                              inputFieldType.CANDIDATE_TABLE_SEARCH,
                              event.target.value
                            );
                          }}
                      />
                      <DuplicateCandidate />
                     </>
                        }</>}
                       {/* {this.state.tabStatus==="Duplicate"&&<DuplicateCandidate />} */}
                    </TabPane>
                  ) : (
                    ""
                  )}
                  {user?.role?.includes("Admin") ||
                  user?.role?.includes("Super-Admin") ||
                  user?.role?.includes("HR") ||
                  user?.role?.includes("Recruiter") || user?.role.includes("Sourcer") ? (
                    <TabPane
                      tab={<span style={this.state.tabStatus === "jdChangeProfile"
                      ? {  margin:'0',fontSize: "13px", fontWeight: "bold" }
                      : {
                          margin:'0',
                          fontSize: "13px",
                          fontWeight: "bold",
                          color: Color.midGrey,
                        }}>AssignedJD</span>}
                      key={"jdChangeProfile"}
                    >
                       {this.state.tabStatus==="jdChangeProfile"&&<>{width > 700 ?
                        <ProposeJdChangeProfile />
                        : <><Input
                          type="text"
                          allowClear={true}
                          style={{
                            width: "100%",
                            height:"2.2rem",
                            borderRadius: "none",
                            marginTop:"-0.5rem",
                            marginBottom:"0.5rem"
                          }}
                          prefix={<SearchOutlined className="custom-search-icon" />}
                          placeholder={"Type And Press Enter / Click outside anywhere.."}
                          // onClick={this.onSearch}
                          onBlur={this.onSearch} 
                          onPressEnter={this.onSearch}
                          value={candidateTableSearch}
                          onChange={(event) =>{
                            this.props.performOperation(
                              inputFieldType.CANDIDATE_TABLE_SEARCH,
                              event.target.value
                            );
                          }}
                      />
                       {width < 700 && 
                        <div style={{marginTop:"-0.2rem"}}>
                              <CandidateCard jobStatus={this.state.jobStatus}/>
                        </div>
                        }
                      <ProposeJdChangeProfile />
                     </>
                      }</>}
                      {/* {this.state.tabStatus==="jdChangeProfile"&&<ProposeJdChangeProfile />} */}
                    </TabPane>
                  ) : (
                    ""
                  )}
                </Tabs>
      </div>
      <div>{console.log("ssssShowModal",this.state.showDateModal)}
        {this.state.showDateModal &&
        <Modal centered                                                      
          onOk={()=>this.setState({showDateModal:false})}
          visible={this.state.showDateModal}
          onCancel={()=>this.setState({showDateModal:false})}
          footer={false}
        >
          <div className={classes.export}>   
          <h2>Dates</h2>  
          <p> Select Date</p>     
          <DatePicker onChange={(date, dateString)=>{this.setState({excelDate:dateString})}} 
          disabledDate={(current)=>{return current && current > moment().endOf("day");}} />                                                                                                           
          <Button onClick={()=>{
            this.setState({btn2Loader:true});
            if (!this.state.excelDate) {
              let title = "please select date";
              this.setState({btn2Loader:false});
              Notification.error(title);
              return;
            }
            Api.get(`/hiring-tests/generateExcel?date=${this.state.excelDate}&type=Experienced`)
              .params()
              .send((response) => {
                if (typeof response != "undefined") {
                  window.open(response, "_blank")?.focus();
                  this.setState({showDateModal:false})
                }
                this.setState({btn2Loader:false});
              });
          }} type="primary" loading={this.state.btn2Loader}>
            Export
          </Button>                        
          </div>                                                   
        </Modal>}
        
        <CandidateEmailSend operations={this.props} />
       
        {candidateViewModal&&<CandidateViewModal />}
        <DownloadCandidateModal />
       
       {this.state.candidateLinkModal && <RegLinkModal/>}
       {this.state.isSendEmailMailVisible && (
        <SendEmailModal
          isOpen={this.state.isSendEmailMailVisible}
          toggle={this.toggleSendMailModal.bind(this)}
          selectedData={this.state.selectedUpdateObject}
          selectedRowData={this.state.selectedRowData}
          setSelectedRow={this.updateSelectedRow}
          refreshGetdata={() =>{this.props.getAllCandidates(this.props.history);
            this.setState({selectedUpdateObject:null})
          }}
          />
      )}
       </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Candidate);
