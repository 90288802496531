import { DASHBOARD_DETAILS_GET, inputFieldType, inputFieldTypeHash, operationType, operationTypeHash } from "./action";

let initialState = {
  dashboardInterviewers: [],
  dashboardTableLoader: false,
  dashboardInterviewDate: null,
  dashboardDateDropdown: false,
  meta:{},
  candidateStatus:[]
};

const reducer = (state = initialState, action) => {
  
  switch (action.type) {
    case DASHBOARD_DETAILS_GET:
      return {
        ...state,
        dashboardInterviewers: action.value.candidates
          ? action.value.candidates.map((interviewer) => ({ ...interviewer, key: interviewer.id }))
          : [],
          candidateStatus:action.value.candidateStatus,
          meta:action.value.meta
      };
    case inputFieldType[action.type]:
      return {
        ...state,
        [inputFieldTypeHash[action.type]]: action.value,
      };
    case operationType[action.type]:
      return {
        ...state,
        [operationTypeHash[action.type]]: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
