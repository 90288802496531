

import classes from './addScoreModal.module.css';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Modal, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { adminActions } from './store';
import { useState } from 'react';
import imgUploadIcon from '../../assets/imgUploadIcon.svg';
const AddScoreModal=()=>{
    const dispatch=useDispatch();
    const {form}=Form;
    const [loading,setLoading]=useState(false);
    const {modalOpen}=useSelector(state=>state.candidateFresher)

    const docBeforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";  
        const isLt10M = file.size / 1024 / 1024 < 10;
         if (!isLt10M) {
          message.error("Image must smaller than 10MB!");
        }
        else {
          getBase64(file, (url) => {
            setLoading(false);
          });
        }
      }  
  
      const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
      };

      const addScoreHandler=(values)=>{

        values={
            ...values,
            test_paper:values.test_paper.file.originFileObj,
        }
        document.getElementById("addScore").reset();
    }

    return (
        <>
            <Modal visible={modalOpen} footer={null} header={null} closable={true} onCancel={()=>dispatch(adminActions.updateModalOpen(false))} width="55rem" centered>
                <div className={classes.container}>
                    <Form onFinish={addScoreHandler} layout="vertical" id="addScore" form={form}>

                        <div className={classes.section}>
                                    <Form.Item label="SECTION 1" name="section1" className={classes.formItem}>
                                        <Input type="text" placeholder='Enter the score' />
                                    </Form.Item>
                                    <Form.Item label="SECTION 2" name="section2" className={classes.formItem}>
                                        <Input type="text" placeholder='Enter the score' />
                                    </Form.Item>
                        </div>

                        <div className={classes.section}>
                                    <Form.Item label="SECTION 3" name="section3" className={classes.formItem}>
                                        <Input type="text" placeholder='Enter the score' />
                                    </Form.Item>
                                    <Form.Item label="SECTION 4" name="section4" className={classes.formItem}>
                                        <Input type="text" placeholder='Enter the score' />
                                    </Form.Item>
                        </div>

                        <div className={classes.section}>
                        <Form.Item label="TOTAL SCORE" name="total_score"  className={classes.formItem} style={{width:"100%"}}>
                            <Input type="text"/>
                        </Form.Item>
                        </div>

                        <div className={classes.section}>
                              <Form.Item label="TEST PAPER" name="test_paper" className={classes.formItem} style={{width:"100%"}}>
                                                                  
                                                      <Upload name="file" showUploadList={true} beforeUpload={docBeforeUpload} 
                                                                                  accept=".png, .jpg, .jpeg, .pdf, .docs"
                                                                                  maxCount={1}
                                                                                  >
                                                                                    <Button  style={{display:"flex", flexDirection:"row",width:"47.9rem" , justifyContent:"space-between", alignItems:"center"}}><p> Upload the test paper </p> <img src={imgUploadIcon}></img></Button> 
                                                      </Upload>                                               
                                </Form.Item>                          
                              </div>

                              <div className={classes.addScore}>
                                <Button onClick={()=>{document.getElementById("addScore").reset()
                              dispatch(adminActions.updateModalOpen(false))}}>CANCEL</Button>
                                <Button htmlType='submit' className={classes.add} 
                                  onClick={()=>{
                                  dispatch(adminActions.updateModalOpen(false))}}>ADD</Button>
                              </div>

                    </Form>
                </div>
            </Modal>

        </>
    )
}
export default AddScoreModal;