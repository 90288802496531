import React,{useEffect} from "react";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "./editor-toolbar";
 import "react-quill/dist/quill.snow.css";
import { useState } from "react";


export const Editor = ({value,onChange }) => {
  useEffect(() =>{
  },[])
  const [state, setState] = useState("");
  const handleChange = value => {
    onChange({ value });
  };
  setTimeout(()=>{
    setState("snow")
  },200)

  return (
    <div className="text-editor" style={{maxHeight:"400px",overflowY:"auto",width:"51rem",marginInline:"auto",marginBottom:"1rem",padding:"0.7rem"}}>
      <EditorToolbar />
      <ReactQuill
        theme={state}
        value={value || ''}
        onChange={handleChange}
        placeholder={"type question here"}
        modules={modules}
        formats={formats}
        ref={(el) => Editor.quillRef = el}
      />
    </div>
  );
};

export default Editor;
