import React, { useState } from "react"
import {
    Modal,
    Row,
    Col,
    Typography
} from "antd";
import "./style.css";
import ViewScreenshotModal from "./viewScreenshotModal";
const ViewFeedback = (props) => {
    const panelistInfo=props?.details?.panelist;
    const details=props?.details;
   const [screenshotModalOpen,setScreenshotModalOpen]=useState(false);
   
    const questions = details?.details;
    const lastRoundData = details?.lastRoundHrReview?.reviewData;

    console.log("sssLastRound",details,details?.lastRoundHrReview?.reviewData)
   
    const handleCancel = () => {
        props.setViewFeedbackModal(false);
      };
   
    let initialIndex=1;
    if(props.feedbackType==='Panelist')
    {
        initialIndex=1;
    }
    return(
        <Modal
        visible={props.viewFeedbackModal}
        onCancel={handleCancel}
        header={null}
        centered
        maskClosable={true}
        closable={true}
        footer={null}
        width="55%"      
        >
                <div style={{margin:"1rem"}}>
                <Row>
                    {props?.feedbackType==='Panelist'&&<Col span={9}  style={{display:"flex",justifyContent:"center",alignItems:"center",marginRight:"5%",maxHeight:"12rem"}}>
                        {/* <h2>{panelistInfo?.name}</h2>
                        <h4>{panelistInfo?.designation}</h4>
                        <h4>{panelistInfo?.email}</h4>
                        <h4>{panelistInfo?.phone}</h4> */}
                        <img src={details?.screenshot} style={{height:'85%',width:"95%",marginRight:"5%",borderRadius:"5px",padding:"0.5rem"}} onClick={()=>{
                            setScreenshotModalOpen(true);
                        }}></img>
                    </Col>}
                    <Col span={12}>
                        {/* <div className="description"> */}
                                    <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                    <h4 style={{fontWeight:"600",color:"gray"}}>Candidate Name : </h4>
                                    <span  style={{marginTop:"0.1rem",fontWeight:"700",marginLeft:"0.5rem"}}>
                                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"6.5rem",fontWeight:"700",color:"black"}}>{details?.candidate?.name?details?.candidate?.name:"NA"}</Typography.Text>
                                    </span>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                                    <h4 style={{fontWeight:"600",color:"gray"}}>Job Title : </h4>
                                    <span>
                                        <Typography.Text ellipsis={{tooltip:true}} style={{marginTop:"0.1rem",width:"6.5rem",fontWeight:"700",color:"black",marginLeft:"0.5rem"}}>{details?.candidate?.job?.title?details?.candidate?.job?.title:"NA"}</Typography.Text>
                                    </span>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                                    <h4 style={{fontWeight:"600",color:"gray"}}>Round Type:</h4>
                                    <span style={{marginTop:"0.1rem",fontWeight:"700",color:"black",marginLeft:"0.5rem"}}>
                                    {details?.roundType && details?.roundType === "Relationship" ? "HR Relationship"
                                    : details?.roundType ? details?.roundType : "NA"}</span>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                                    <h4 style={{fontWeight:"600",color:"gray"}}>Round:</h4>
                                    <span style={{marginTop:"0.1rem",fontWeight:"700",color:"black",marginLeft:"0.5rem"}}>{details?.round?details?.round:"NA"}</span>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                                    <h4 style={{fontWeight:"600",color:"gray"}}>Interview Date:</h4>
                                    <span style={{marginTop:"0.1rem",fontWeight:"700",color:"black",marginLeft:"0.5rem"}}>{details?.interviewDate?details?.interviewDate:"NA"}</span>
                                    </div>

                                    <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"0.3rem"}}>
                                    <h4 style={{fontWeight:"600",color:"gray"}}>Panelist Name:</h4>
                                    <span style={{marginTop:"0rem",fontWeight:"700",color:"black",marginLeft:"0.5rem"}}><Typography.Text style={{width: "7rem",fontSize:"0.9rem",fontWeight:"700"}} ellipsis={{tooltip:true}} >{panelistInfo?.name?panelistInfo?.name:"NA"}</Typography.Text>
                                    </span>
                                    </div>
                        {/* </div> */}
                    {/* </Col >
                    <Col span={7}> */}
                        {/* <div  className="description"> */}
                                   
                        {/* </div> */}
                    </Col>
                </Row>
                </div>
                <h3 style={{margin:'3%',fontWeight:"700",fontSize:"1.2rem"}}>Feedback Questions & Answers</h3>
                {details?.roundType === "HR" && lastRoundData !== undefined && lastRoundData !== null&&lastRoundData.length !== 0 ?
                <div className="questionsDiv">
                <div style={{marginLeft:"1.3rem",fontSize:"11px"}}><h2>Evaluated Previous Round Feedbacks :</h2></div>
                <div className="question2">
                {props.feedbackType!=='Candidate'&&<div style={{marginLeft:"0.2rem"}}>
                   {lastRoundData !== undefined && lastRoundData.map((item,index) => {
                            return(<div style={{display:"flex",flexDirection:"column"}}>
                                <h3 style={{marginLeft:"0.2rem",marginBottom:"0.5rem"}}> {item.question}</h3>
                                <span style={{marginLeft:"1.2rem",marginBottom:"0.5rem"}}>{item.answer}</span>
                                <h4 style={{marginLet:"1.2rem",marginBottom:"0.5rem",fontSize:"1.0rem"}}>review : {item.review}</h4>
                               </div>
                            )}       
                    )}  
                </div>
                }
                </div>
                <div style={{marginLeft:"1.3rem",fontSize:"11px",}}><h2>HR Round Feedbacks :</h2></div>
                
                <div className="question" style={{marginTop:"0.5rem"}}>
                    {props.feedbackType==='Panelist'&&<div style={{marginLeft:"0.2rem"}}>
                                {details?.overallComment === null ? "" :<>
                                <h3 style={{marginBottom:"0.1rem"}}> Overall Comment</h3>
                                <span style={{marginLeft:"1rem",marginBottom:"1rem"}}>{details?.overallComment}</span>
                                </>}
                                {details?.overallRating === null ? "" :<>
                                <h3 style={{marginBottom:"0.1rem"}}> Overall Rating</h3>
                                <span style={{marginLeft:"1rem",marginBottom:"1rem"}}>{details?.overallRating}</span>
                                </>}
                                <h3 style={{marginBottom:"0.1rem"}}>
                                    {/* {details?.overallComment === null&&details?.overallRating === null?1:3}. */}
                                      Candidate's Status</h3>
                                <span style={{marginLeft:"1rem",marginBottom:"1rem"}}>{details?.status}</span>
                    </div>}
                    {props.feedbackType==='Candidate' &&  Object.keys(questions).length === 0 ?
                    <div style={{display:"flex",margin:"2rem",alignItems:"center",justifyContent:"center"}}>Feedback is not filled...</div>  
                    :
                    Object.keys(questions).map((key,index) => {
                            return(key === "overallComment" || key === "overallrating" ||(typeof key === "object" && Object.keys(key).includes("file") || Object.keys(key).includes("fileList"))                             
                            ? "" :
                            <div>{key !== "screenshot" &&  <> <h3 style={{marginLeft:"0.2rem",marginBottom:"0.2rem"}}>{" "+key==="status"?"Candidate's Status":key}</h3>
                                <span style={{marginLeft:"1rem",marginBottom:"1rem"}}>{questions[key]}</span>
                            </>}</div>
                            )}       
                    )}
                </div>
                </div>
                :
                <div className="question">
                    {props.feedbackType==='Panelist'&&<div style={{marginLeft:"0.2rem"}}>
                                {details?.overallComment === null ? "" :<>
                                <h3 style={{marginBottom:"0.2rem"}}> Overall Comment</h3>
                                <span style={{marginLeft:"1rem",marginBottom:"1rem"}}>{details?.overallComment}</span>
                                </>}
                                {details?.overallRating === null ? "" :<>
                                <h3 style={{marginBottom:"0.2rem"}}> Overall Rating</h3>
                                <span style={{marginLeft:"1rem",marginBottom:"1rem"}}>{details?.overallRating}</span>
                                </>}
                                <h3 style={{marginBottom:"0.2rem"}}>  Candidate's Status</h3>
                                <span style={{marginLeft:"1rem",marginBottom:"1rem"}}>{details?.status}</span>
                    </div>}
                    {props.feedbackType==='Candidate' &&  Object.keys(questions).length === 0 ?
                    <div style={{display:"flex",margin:"2rem",alignItems:"center",justifyContent:"center"}}>Feedback is not filled...</div>  
                    :
                    Object.keys(questions).map((key,index) => {
                            if ((key === "overallComment"&&details?.overallComment !== null)||(key === "overallrating"&&details?.overallRating !== null)
                            ) {
                                return null;
                            }
                            const questionValue = questions[key];
                            if (typeof questionValue === "object" && questionValue.hasOwnProperty("file") && questionValue.hasOwnProperty("fileList")) {
                                return null; 
                            }
                            if (key !== "screenshot") {
                                let questionNumber = 
                                (questions.hasOwnProperty("overallComment")&&details?.overallComment === null)
                                ||(questions.hasOwnProperty("overallrating")&&details?.overallRating === null) 
                                ? index + 2
                                : index + 4;                          
                            return((key === "overallComment"&&details?.overallComment !== null)||(key === "overallrating"&&details?.overallRating !== null)
                            || (typeof key === "object" && Object.keys(key).includes("file") || Object.keys(key).includes("fileList")) 
                            ? "" :
                            <div>{key !== "screenshot" &&  <>
                                    <h3>{" "+key}.</h3>
                                    {typeof key === "string" && (key.startsWith("http://") || key.startsWith("https://")) && key.split('.').pop().toLowerCase() === "pdf" ?
                                    <div style={{fontWeight:"600",marginTop:"0.3rem",cursor:"pointer",display:"flex",textAlign:"center",justifyContent:"center",marginLeft:"1.1rem",border:"1px solid #0086FF",padding:"0.5rem",width:"8rem"}}
                                    onClick={()=>{
                                    window.open(key, "_blank")?.focus();
                                    }}> View File </div>
                                    :
                                    typeof key === "string" && (key.startsWith("http://") || key.startsWith("https://")) && ["png", "jpg", "jpeg", "gif", "bmp"].includes(key.split('.').pop().toLowerCase()) ?
                                    <img src={key} alt="" style={{height:"4rem",width:"4rem",marginLeft:"1.1rem"}} />
                                    :
                                    <span style={{marginLeft:"1rem"}}>{questions[key]?questions[key]:"NA"}</span>
                                    }</>}    
                            </div>)}
                            return null;
                            }  
                        )}
                </div>
                }
                <ViewScreenshotModal setScreenshotModalOpen={setScreenshotModalOpen} screenshotModalOpen={screenshotModalOpen} screenshot={details?.screenshot}/>
        </Modal>
    )
}
export default ViewFeedback;