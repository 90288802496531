import { Select,Row,Col,Tabs,Carousel,Typography,DatePicker,Button} from 'antd';
import { useEffect,useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import classes from './dashboard.module.css';
import "./dashboard.module.css"
import FeedbackItem from './feedbackItem';
import SheduleItem from './sheduleItem';
import { useDispatch, useSelector } from "react-redux";
import { Api, Notification } from 'services';

import { SearchOutlined,EyeOutlined,ArrowRightOutlined,ArrowLeftOutlined } from "@ant-design/icons";
import { useWindowDimensions } from 'components/layout';
const {TabPane}=Tabs
// function getWindowDimensions() {
//     const { innerWidth: width, innerHeight: height } = window;
//             return {
//                     width,
//                     height
//             };
//   }
//   export function useWindowDimensions() {
//     const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
//     useEffect(() => {
//       function handleResize() {
//         setWindowDimensions(getWindowDimensions());
//       }
//       window.addEventListener('resize', handleResize);
//       return () => window.removeEventListener('resize', handleResize);
//     }, []);
//     return windowDimensions;
//   }
const DashboardPanView=(props)=>{
    const slider=useRef(null)
    const slider2=useRef(null)
    const [currentCar,setCurcar]=useState(1)
    const [currentcarF,setCurrentcarF]=useState(1)
    const[feedbackCard,setFeedbackCard] = useState("interview")
    const [titleValue,setTitleValue] = useState({})
    const [schedule,setSchedule] = useState([])
    const [years,setYear] = useState("")
    const [months,setMonth] = useState("")
    const [loader, setLoader] = useState(false);
    const [feedbacksLists,setFeedback] = useState([]);
    const {user}=useSelector(state=>state?.auth);
    
    const { height, width } = useWindowDimensions(); 
    
 

    useEffect(() => {
        props.setNavlist(props.navList.map((item,index)=>{
            if(index===0){
                return {...item,clicked:true}
            }
            return {...item,clicked:false}
        }))
        Api.get(`/hiring-panelist/panelistDashboard?year=${years}&month=${years === "" ? "" : months}`)
        .send((response)=>{
            if(typeof response != "undefined"){
         
            setTitleValue(response?.itemCount)
          }
        })
       
    }, [years,years === "" ? "" : months]);


    useEffect(() => {

        setLoader(true)
        Api.get(`/hiring-panelist/interviewSchedule`)
           .send((response)=>{
            setLoader(false)
            if(typeof response != "undefined"){
      
            setSchedule(response?.items)
          }
        })
       
    }, []);


    useEffect(() => {
        setLoader(true)
        Api.get(`/hiring-panelist/panelistfeedbacks`)
           .send((response) => {
            if (typeof response != "undefined") {
             
              setFeedback(response?.items)
              setLoader(false)
            }
          });
    }, []);

    
    const briefDetails=[
        {
            title:'Interview Taken',value:titleValue?.interviewsTaken,
        },
        {
            title:'Feedback Filled',value:titleValue?.feedbackFilled,
        },
        {
            title:'Interview Schedule',value:titleValue?.scheduledInterviews,
        },
        {
            title:'Interview Re-Schedule',value:titleValue?.reScheduledInterviews,
        },
        {
            title:'Interview Cancelled',value:titleValue?.cancelledInterviews,
        },
    ]

    return(
    <>
        <div className={classes.container}>
                                               
                        <div className={classes.title}>
                                        <h1>Dashboard</h1>
                                        <div>
                                        {/* {width<450 && user?.role?.length===1? <Row justify={"end"}>
                                            <Col span={12} offset={12}><span style={{marginRight:"0.7rem"}}>View by</span></Col>
                                            <Col span={8}>
                                            <DatePicker bordered={false} onChange={(date,dateString)=>{
                                                    setMonth(dateString)
                                                    if(dateString !== "" && years === ""){
                                                        Notification.error("Please Select a Year for Filter")
                                                        return
                                                    }
                                                }} picker="month" format={'MM'}/>
                                               </Col>
                                               <Col span={8}>
                                                <DatePicker bordered={false} onChange={(date,dateString)=>{
                                                    setYear(dateString) 
                                                    if(months !== "" && dateString === ""){
                                                        Notification.error("Please Select a Year for Filter")
                                                        return 
                                                    }                                                  
                                                }} picker="year" format={'YYYY'} /></Col>
                                                </Row>: */}
                                            <div>
                                           
                                                <span style={{marginRight:"0.7rem"}}>View by</span>
                                                <div style={{border:"0.7px solid #BFBFBF",borderRadius:"5px",width:"16rem"}}>
                                                <DatePicker bordered={false} onChange={(date,dateString)=>{
                                                    setMonth(dateString)
                                                    if(dateString !== "" && years === ""){
                                                        Notification.error("Please Select a Year for Filter")
                                                        return
                                                    }
                                                }} picker="month" format={'MM'}/>

                                                <DatePicker bordered={false} onChange={(date,dateString)=>{
                                                    setYear(dateString) 
                                                    if(months !== "" && dateString === ""){
                                                        Notification.error("Please Select a Year for Filter")
                                                        return 
                                                    }                                                  
                                                }} picker="year" format={'YYYY'} />
                                                </div>
                                            </div>
                                        <div >
                                        {/* <span>2023</span> */}
                        </div>
                                        </div>
                        </div>

                       {/* {width<450 && user?.role?.length===1? 
                       <Row >
                      
                        {
                                briefDetails.map((item,index)=>{
                                    
                                    if(feedbackCard==="interview" && item.title==="Feedback Filled"){
                                        return ""
                                    }
                                    else if(feedbackCard==="feedback" && item.title!=="Feedback Filled"){
                                          return ""
                                          
                                        }
                                        else{
                                       return(
                                        <Col span={11} offset={feedbackCard==="interview"&&index%3!==0?2:0} style={{  padding: "0.9rem 0",
                                        marginTop:"30px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        backgroundColor: "#F2F7F2",
                                        boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
                                        borderRadius: "4px",}}>
                                        <div key={index}>
                                                <h2 style={{textAlign:"center"}}>{item.value}</h2>
                                                <span>{item.title}</span>
                                        </div> </Col>
                                    )
                                        }
                                        
                                    }
                                    
                                )
                            }
                       
                        
                       </Row>
                       : */}
                       <><div className={classes.fiveItems} >
                            {
                                briefDetails.map((item,index)=>{
                                    return(
                                        <div key={index}>
                                                <h2>{item.value}</h2>
                                                <span>{item.title}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {schedule?.length>0&&<div className={classes.sheduled}>
                                    <h2>Interviews</h2>
                        </div>}</>
                        {/* {width<450 && user?.role?.length===1? 
                       <> <Tabs onChange={(key) =>{
                        setFeedbackCard(key)
                       
                        
                       }} >
                        
                         <TabPane tab={"Interviews"} key="interview">  
                            <Carousel ref={slider} dots={classes.Carousel} afterChange={(val) =>{
                                setCurcar(val+1)
                            }} >
                             
                            { 
                                schedule?.map((item,index)=>(
                                    <div key={index}>
                                    <SheduleItem item={item} navList={props.navList} setNavlist={props.setNavlist}/>
                                    </div>
                                    
                                ))
                            }
                           
                            </Carousel>
                            <Typography.Text style={{color:"black",fontSize:"20px"}}><ArrowLeftOutlined onClick={() =>slider.current.prev()
           
        } style={{fontSize:"30px",marginRight:"30px"}} />{currentCar}/{schedule.length}<ArrowRightOutlined  style={{fontSize:"30px",marginLeft:"30px"}}  onClick={() =>slider.current.next()
         
        }/></Typography.Text>
                            
                           
                            </TabPane>
                         <TabPane  tab={"Feedback"} key="feedback"><div >
                           <div>
                            <Carousel ref={slider2} afterChange={(val) =>{
                              setCurrentcarF(val+1)
                            }}>
                                {
                                    feedbacksLists?.map((item,index)=>(
                                        <div key={index} >
                                        <FeedbackItem item={item}/>
                                        </div>
                                    ))
                                }</Carousel>
                                 <Typography.Text style={{color:"black",fontSize:"20px"}}><ArrowLeftOutlined onClick={() =>slider2.current.prev()
           
        } style={{fontSize:"30px",marginRight:"30px"}} />{currentcarF}/{feedbacksLists.length}<ArrowRightOutlined  style={{fontSize:"30px",marginLeft:"30px"}}  onClick={() =>slider2.current.next()
         
        }/></Typography.Text>
                           </div></div></TabPane>
                        </Tabs></>
                       :  */}
                       <>{schedule?.length>0&&<div className={classes.sheduleDiv}>
                            <div>
                            
                                {
                                    schedule?.map((item,index)=>(
                                        <div key={index}>
                                        <SheduleItem item={item} navList={props.navList} setNavlist={props.setNavlist}/>
                                        </div>
                                        
                                    ))
                                } 
                                </div>
                            <div>
                            <span style={{display:"flex",flexDirection:"row",justifyContent:"center",textAlign:"center",}}>
                            <Link style={{color:"black",fontWeight:"700",fontSize:"1rem",fontSize:"1.3rem"}} to= '/panelist/interviewSchedule' onClick={()=>{
                                props.setNavlist(props.navList.map((item,index)=>{
                                    if(index===1){
                                        return {...item,clicked:true}
                                    }
                                    return {...item,clicked:false}
                                }))
                            }}>See All</Link>
                            </span>
                            </div>
                        </div>}

                        {feedbacksLists?.length>0&&<div className={classes.sheduled}>
                                    <h2>Feedbacks</h2>
                        </div>}
                        {feedbacksLists?.length>0&&<div className={classes.feedbacks}>
                           <div>
                                {
                                    feedbacksLists?.map((item,index)=>(
                                        <div key={index}>
                                        <FeedbackItem item={item}/>
                                        </div>
                                    ))
                                }
                           </div>

                            <div >
                            <span style={{display:"flex",flexDirection:"row",justifyContent:"center",textAlign:"center",}}>
                            <Link style={{color:"black",fontWeight:"700",fontSize:"1.3rem"}} to= '/panelist-feedback' onClick={()=>{
                                props.setNavlist(props.navList.map((item,index)=>{
                                    if(index===2){
                                        return {...item,clicked:true}
                                    }
                                    return {...item,clicked:false}
                                }))
                            }}>See All</Link>
                            </span>
                            </div>
                        </div>}</>
        </div>
        </>
    )
}

export default DashboardPanView;