import "./styles.css";
import React from "react";
import { Color } from "services";
import { connect } from "react-redux";
import { IntRange } from "services/input";
import { UploadOutlined } from "@ant-design/icons";
import { Row, Upload, Col, Spin, Button, message, Avatar} from "antd";
import { mapDispatchToProps, mapStateToProps } from "../redux/container";
import { PrimaryButton, SecondaryButton } from "services/button";
import {CANDIDATE_IMAGE_VIEW_MODAL, CANDIDATE_IMAGE , feedbackOtherFieldType, operationType} from "../redux/action";
import { FeedBackFormInput, RadioNode, RateNode, RequiredField, SliderNode, TextInputNode, NumberInputNode } from "./function";

class FeedbackQuestion extends React.Component {
  func = (valueObj, index) => {
    const { feedbackCandidate, updateInputField, calculateCompletion, feedbackStatus, maritalStatusList, automotiveBackground } = this.props;
    switch (valueObj.type) {
      case "slider":
        return (
          <SliderNode
            value={feedbackCandidate[valueObj.key] * 10}
            onChange={(value) => {
              updateInputField(`FEEDBACK_INPUT_${index}`, value / 10);
              if (valueObj.required) calculateCompletion();
            }}
          />
        );
      case "rate":
        return (
          <RateNode
            value={feedbackCandidate[valueObj.key]}
            onChange={(value) => {
              updateInputField(`FEEDBACK_INPUT_${index}`, value);
              if (valueObj.required) calculateCompletion();
            }}
          />
        );
      case "intRange":
        return (
          <Col span={16} className="feedback-size">
            <IntRange
              minOnChange={(value) => {
                updateInputField(`FEEDBACK_INPUT_${index}_0`, value);
                if (valueObj.required) calculateCompletion();
              }}
              maxOnChange={(value) => {
                updateInputField(`FEEDBACK_INPUT_${index}_1`, value);
                if (valueObj.required) calculateCompletion();
              }}
              minValue={feedbackCandidate[valueObj.key[0]]}
              maxValue={feedbackCandidate[valueObj.key[1]]}
              minName="Years"
              maxName="Months"
              width="100"
            />
          </Col>
        );
      case "input":
        return (
          <TextInputNode
            span={16}
            value={feedbackCandidate[valueObj.key]}
            onChange={(e) => {
              updateInputField(`FEEDBACK_INPUT_${index}`, e.target.value);
              if (valueObj.required) calculateCompletion();
            }}
          />
        );
      case "radio":
        return (
          <RadioNode
            // radios={valueObj.key === "status" ? feedbackStatus : valueObj.radios}
            radios={valueObj.key === "status" ? feedbackStatus : valueObj.key === "maritalStatus" ? maritalStatusList :  valueObj.key === "automotiveBackground" ? automotiveBackground : []}
            value={feedbackCandidate[valueObj.key]}
            onChange={(e) => {
              updateInputField(`FEEDBACK_INPUT_${index}`, e.target.value);
              if (valueObj.required) calculateCompletion();
            }}
          />
        );
      case "number": 
        return (
          <NumberInputNode
            span={16}
            value={feedbackCandidate[valueObj.key]}
            onChange={(value) => {
              updateInputField(`FEEDBACK_INPUT_${index}`, value);
              if (valueObj.required) calculateCompletion();
            }}
          />
        )
      default:
        return;
    }
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  imageBeforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    } else if (!isLt2M) {
      message.error('Image must smaller than 5MB!');
    } 
    else {
      this.getBase64(file, imageUrl => {
        this.props.updateInputField(feedbackOtherFieldType.FEEDBACK_RECENT_PHOTO, [file]);
        this.props.updateInputField(feedbackOtherFieldType.FEEDBACK_RECENT_PHOTO_URL, imageUrl);
      });
    }
    return false;
  }

  closeModal = () => {
    this.props.performOperation(operationType.HR_FEEDBACK_MODAL, false);
  };

  render() {
    const { feedbackQuestions, feedbackSubmitLoader, postRecruitmentFeedback, performOperation, feedbackCandidateInfo, updateInputField } = this.props;
    return (
      <div>
        { feedbackQuestions && feedbackQuestions.questions ? (
            <div style = {{marginLeft : '120px', fontSize: "20px"}}>
              <div className="" style = {{maxWidth: "90vh", marginLeft: "300px"}}>
                <div style = {{boxShadow: "5px 0px 5px #C0C0C0"}}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", paddingRight: "5px", color: Color.darkGrey } }>Basic Information
                  </div>
                  
                  <Row gutter={24} style = {{paddingRight: "5px"}}>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Total Experience") ||
                        key.includes("Father") ||
                        key.includes("Husband/Wife") ||
                        key.includes("Hobbies") ?
                        <FeedBackFormInput
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Mother") ||
                        key.includes("Brother/Sister") ||
                        key.includes("Kid") ?
                        <FeedBackFormInput
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                  </Row>
                </div>

                <div style = {{boxShadow: "5px 0px 5px #C0C0C0", marginTop : "50px" }}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", color: Color.darkGrey }}>Academics
                  </div>
                  <Row gutter={24} style = {{paddingRight: "5px"}}>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("10th") ||
                        key.includes("Degree") ?
                        <FeedBackFormInput
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("12th") ||
                        key.includes("Others") ?
                        <FeedBackFormInput
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                  </Row>
                </div>

                <div style = {{boxShadow: "5px 0px 5px #C0C0C0", marginTop : "50px" }}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", color: Color.darkGrey }}>Location Information
                  </div>
                  <Row gutter={24} style = {{paddingRight: "5px"}}>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Preferred Location") ?
                        <FeedBackFormInput
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Current Location") ?
                        <FeedBackFormInput
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                  </Row>
                </div>

                <div style = {{boxShadow: "5px 0px 5px #C0C0C0", marginTop : "50px" }}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", color: Color.darkGrey }}>Notice Period ( In Days ) / CTC ( in LPA )
                  </div>
                  <Row gutter={24} style = {{paddingRight: "5px"}}>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Notice Period") ||
                        key.includes("Expected CTC") ||
                        key.includes("Offer in Hand") ?
                        <FeedBackFormInput
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Hike") ||
                        key.includes("Offered CTC") ?
                        <FeedBackFormInput
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                  </Row>
                </div>

                <div style = {{boxShadow: "5px 0px 5px #C0C0C0", marginTop : "50px", marginBottom : "20px" }}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", color: Color.darkGrey }}>Previous Organisations
                  </div>

                  <Row gutter={24} style = {{paddingRight: "5px"}}>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Company1 Name") ||
                        key.includes("Company1 leave Reason") ||
                        key.includes("Company1 Current CTC") ?
                        <FeedBackFormInput
                          serialNo={index - 10}
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                    <Col span={12}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Company1 Job Profile") ||
                        key.includes("Company1 Joining CTC") ?
                        <FeedBackFormInput
                          serialNo={index - 10}
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>

                    <Col span={12} style = {{marginTop : "30px"}}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Company2 Name") ||
                        key.includes("Company2 leave Reason") ||
                        key.includes("Company2 Current CTC") ?
                        <FeedBackFormInput
                          serialNo={index - 10}
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                    <Col span={12} style = {{marginTop : "30px"}}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Company2 Job Profile") ||
                        key.includes("Company2 Joining CTC") ?
                        <FeedBackFormInput
                          serialNo={index - 10}
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>

                    <Col span={12} style = {{marginTop : "30px"}}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Company3 Name") ||
                        key.includes("Company3 leave Reason") ||
                        key.includes("Company3 Current CTC") ?
                        <FeedBackFormInput
                          serialNo={index - 10}
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                    <Col span={12} style = {{marginTop : "30px"}}>
                      {/* Previous orgs questions will appear */}
                      {Object.entries(feedbackQuestions.questions).map(([key, value], index) => ( 
                        key.includes("Company3 Job Profile") ||
                        key.includes("Company3 Joining CTC") ?
                        <FeedBackFormInput
                          serialNo={index - 10}
                          text={value.required ? <RequiredField text={key} /> : key}
                          inputNode={this.func(value, index)}
                        /> : ""
                      ))}
                    </Col>
                  </Row>
                </div>
                
                <div style = {{boxShadow: "5px 0px 5px #C0C0C0", marginTop : "50px", marginBottom : "20px" }}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", color: Color.darkGrey }}>Miscellaneous
                  </div>
                  
                  {/* Only miscellaneous questions will appear */}
                  {Object.entries(feedbackQuestions.questions).map(([key, value], index) => (
                    key.includes("growing") ||
                    key.includes("time span") ||
                    key.includes("carrier aspiration") ?
                    <FeedBackFormInput
                    serialNo={index}
                    text={value.required ? <RequiredField text={key} /> : key}
                    inputNode={this.func(value, index)}
                    /> : ""
                  ))}
                </div>

                <div style = {{boxShadow: "5px 0px 5px #C0C0C0", marginTop : "50px", marginBottom : "20px" }}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", color: Color.darkGrey }}>Rating
                  </div>
                  
                  {/* Only rating question will appear */}
                  {Object.entries(feedbackQuestions.questions).map(([key, value], index) => (
                    key.includes("rate") ||
                    key.includes("Communication") ||
                    key.includes("Attitude") ||
                    key.includes("Body Language") ||
                    key.includes("overall") ?
                    <FeedBackFormInput
                    serialNo={index}
                    text={value.required ? <RequiredField text={key} /> : key}
                    inputNode={this.func(value, index)}
                    /> : ""
                  ))}
                </div>

                <div style = {{boxShadow: "5px 0px 5px #C0C0C0", marginTop : "50px", marginBottom : "-20px" }}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", color: Color.darkGrey }}>HR Comment
                  </div>
                  <Col span={24}>
                    {Object.entries(feedbackQuestions.questions).map(([key, value], index) => (
                      key.includes("Comments") ?
                      <FeedBackFormInput
                      serialNo={index}
                      text={value.required ? <RequiredField text={key} /> : key}
                      inputNode={this.func(value, index)}
                      /> : ""
                    ))}
                  </Col>
                </div>

                <div style = {{boxShadow: "5px 0px 5px #C0C0C0", marginTop : "70px", marginBottom : "-20px" }}>
                  <div className = "text-bold" style={{ paddingTop: "10px" , paddingBottom: "20px", color: Color.darkGrey }}>Selection
                  </div>
                  <Col span={24}>
                    {Object.entries(feedbackQuestions.questions).map(([key, value], index) => (
                      key.includes("Candidate Result") ?
                      <FeedBackFormInput
                      serialNo={index}
                      text={value.required ? <RequiredField text={key} /> : key}
                      inputNode={this.func(value, index)}
                      /> : ""
                    ))}
                  </Col>
                </div>
              </div>
              <Row style = {{marginLeft : '300px', marginTop: "70px"}}>
                  {feedbackCandidateInfo.feedbackRecentPhoto !== "" ? (
                    <img
                      src={feedbackCandidateInfo.feedbackRecentPhotoUrl} 
                      style={{ width: "200px", height: "100px" }}
                      alt=""
                      onClick={() => {
                        updateInputField(CANDIDATE_IMAGE, feedbackCandidateInfo.feedbackRecentPhotoUrl);
                        performOperation(CANDIDATE_IMAGE_VIEW_MODAL, true);
                      }}
                  />
                  ) : (
                    <Avatar size="100" icon={<UploadOutlined />} />
                  )}
                  <Col span={6} style = {{marginLeft : "25px", marginTop : "25px"}}>
                    <Upload
                      onRemove={() => updateInputField(feedbackOtherFieldType.FEEDBACK_RECENT_PHOTO, [])}
                      beforeUpload={this.imageBeforeUpload} 
                      showUploadList={false}
                    >
                      <Button icon={<UploadOutlined />} style = {{width: '305px',
                                                                  height: '52px'}} className = "text-bold">
                        Attach a screenshot of the candidate<span style={{color: Color.red}}>*</span>
                      </Button>
                    </Upload>
                  </Col>
              </Row>
              <div className="r-c-fe">
                <PrimaryButton
                  className="feedback-form-item r-c-fe"
                  text="Submit"
                  size="medium"
                  style={{ padding: "25px 50px", fontSize: "1.0em" }}
                  loading={feedbackSubmitLoader}
                  onClick={() => { feedbackCandidateInfo.feedbackRecentPhoto === undefined
                     ? message.error('Please attach a screenshot of the candidate.')
                     : postRecruitmentFeedback()}}
                />
                {/* <SecondaryButton onClick={this.closeModal} id="" text="Discard" className="r-c-fe" size="medium" style={{ padding: "25px 50px", fontSize: "1.0em" }} /> */}
              </div>
              
            </div>
          ) : (
            <Spin tip="loading" className="on-page-loader"></Spin>
          )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackQuestion);
