import React, { useState } from "react";
import classes from './StoreGraph.module.css';
import {Popover} from "antd";
const StoreGraph=({bar,xAxis,})=>{
    const [barVals,setBarVals]=useState([])
    
    let xmax=0;
    if(xAxis)
    {
        for(let item of xAxis)
        {
          if(xmax<Math.max(...item?.bar))
          {
            xmax=Math.max(...item?.bar);
          }
        }

    }
   

    console.log("xmaxxx",xAxis)

let yAxis=[xmax-Math.floor(xmax)!==0?xmax.toFixed(2):xmax, (xmax*75/100).toFixed(2), (xmax*50/100).toFixed(2), (xmax*25/100).toFixed(2),0];

  
    return(
        <div className={classes.storeGraph}>
            <h3 style={{fontSize:"1.1rem"}}>{bar?.graphTitle}</h3>
            {bar?.graphTitle!=="Session Overview"?<div className={classes.chartDesc}>
                {Object?.values(bar)?.map((item,index)=>{
                    if(index!==0)
                    {
                        return(
                            <div>
                            <span style={{backgroundColor:item?.bg}}></span>
                            <span>{item?.title}</span>
                        </div>
                        )
                    }
                  
                })}
                {/* <div>
                    <span style={{backgroundColor:bar?.bar1?.bg}}></span>
                    <span>{bar?.bar1?.title}</span>
                </div>
                <div>
                    <span style={{backgroundColor:bar?.bar2?.bg}}></span>
                    <span>{bar?.bar2?.title}</span>
                </div> */}

            
            </div>:<span style={{fontSize:"0.8rem"}}>Overview in hours</span>}
            <div className={classes.graph} style={{width:`${xAxis?.length*8+8}vw`}}>
                {yAxis?.map((item,index)=>
                <div className={classes.yAxis}>
                    <span>{item}</span>
                    <span></span>
                </div>
                )
                }

<div className={classes.xAxis}>
                {xAxis?.map((item,index)=>{
                    return(
                        <div className={classes.bar}>
                            <div>
                                <Popover
                                trigger={"click"}
                                onOpenChange={()=>{
                                    setBarVals(item?.bar);
                                }}
                                content={
                                    bar?.graphTitle!=="Session Overview"?<div className={classes.tooltip}>
                                    {item?.bar?.map((item2,index2)=>{
                                        return(
                                            <div>
                                            <div>
                                                <span style={{backgroundColor:Object.values(bar)[index2+1]?.bg}}></span>
                                                <span>{Object.values(bar)[index2+1]?.title}</span>
                                            </div>
                                            <span>{item2}</span>
                                        </div>
                                        )
                                    })}
                                   
                                    {/* <div>
                                        <div>
                                            <span  style={{backgroundColor:bar?.bar2?.bg}}></span>
                                            <span>{bar?.bar2?.title}</span>
                                        </div>
                                        <span>{barVals?.bar2}</span>
                                    </div> */}
                                    
                                </div>:
                                <div style={{display:"flex",alignItems:"center",gap:"1rem",}}>
                                    <span>Session</span>
                                    <span style={{fontWeight:"600"}}>{item?.bar?.[0]?.toFixed(2)} hours</span>
                                </div>
                                }
                                >
                                <div className={classes.eachBar}>
                                    {Object?.values(bar)?.map((item2,index2)=>{
                                        if(index2!==0)
                                        {
                                            console.log("isisisis",  item?.bar[index2-1])
                                            return(
                                                <span style={{height:`${item?.bar[index2-1]===0?0:item?.bar[index2-1]*100/yAxis[0]}%`,backgroundColor:item2?.bg}}></span>
                                            )
                                        }
                                      
                                    })}
                                        {/* <span style={{height:`${item?.bar1}%`,backgroundColor:bar?.bar1?.bg}}></span>
                                       <span style={{height:`${item?.bar2}%`,backgroundColor:bar?.bar2?.bg}}></span> */}
                                </div>
                                </Popover>
                                
                            </div>
                             <span>{item?.x}</span>
                        </div>
                    )
                })}
            </div>

            </div>
           

        </div>
    )
}
export default StoreGraph;