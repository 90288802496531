import {
  Space,
  Table,
  Button,
  PageHeader,
  Row,
  Col,
  Typography,
  Timeline,
  Input,
  Checkbox,
  Menu,
  Dropdown,
  Card,
  Radio,
  Avatar,
  List,
  Popover,
  Tabs, 
  Select,
  Spin,
  Pagination
} from "antd";
import "./style.css";
import {
  MoreOutlined,
  UserOutlined,
  CloseOutlined,
  DownOutlined,
  CheckCircleTwoTone,
  SearchOutlined,
  SwapOutlined
} from "@ant-design/icons";
import filter from "../../assets/filter.png";
import sort from "../../assets/sort.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import settings from "../../assets/settings.png";
import searchIcon from '../../assets/searchIcon.png';
import SearchIconBlack from "../../assets/searchIconBlack.svg";
import React, { useState, useEffect, useReducer } from "react";
import Api from "../../services/Api";
import JdActionModal from "./jdActionModal";
const { Title } = Typography;
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PdfLogo from "../../assets/pdf-logo.svg";
import download from "../../assets/download.png";
import { jdActions } from "./store";
import CreateJDModal from "./createJDModal";
import Warning from "../uiutils/warningModal";
import { authActions } from "pages/login/store";
import Constants from "./constants";
import { adminActions } from "pages/dashboard-home/store";
import Filter from "./filter.cls";
import DragAndDrop from "components/dragAndDrop";
import Sorter from "components/sorter";
import classes from './jdListExperienced.module.css';
import AddProfileModal from "pages/candidate/addProfileModal";
import { MapDispatchToProps,mapStateToProps } from "react-redux";
import JobTitleModal from "./jobTitleModal";
import { Option } from "antd/lib/mentions";
import JobTitlePopover from "./jobTitlePopover";
import { ServerStyleSheet } from "styled-components";
import { Notification } from "services";
import { getAllCandidates, getJobsForDropdownInJd } from "pages/candidate/redux/action";
import { useWindowDimensions } from "components/layout";
import ViewPDF from "./ViewPdf";
// import { mapDispatchToProps, mapStateToProps } from "./redux/container";
const { TabPane } = Tabs;
const constant = new Constants();
const filterObj = new Filter();

 const filterReducer=(state,action)=>{
  if(action.type==='JOB_TITLE' )
  {
    return {
      ...state,
       job:{id:action.value.id,name:action.value.name},}
  }
  if(action.type==='MIN_EXP')
  {
    return {
      ...state,
    minExp:action.value, 
  }
  }
  if(action.type==='MAX_EXP')
  {
    return {
      ...state,
       maxExp:action.value}
  }
  if(action.type==='SEARCH')
  {
    return {
      ...state,
      searchValue:action.value}
  }
  return {job:{name:'',id:''}, minExp:'', maxExp:'',searchValue:''}
}

function JdDashboard() {
  const [sortType,setSortType] = useState(true);
  const[priorities,setPriority] = useState([])
  const [locn,setLocn] = useState([])
  const [jobTypes,setJobTypes] = useState([])
  const [filterDetailsFlag,setFilterDetailsFlag] = useState(false);
  const [sortFieldList, setSortOrderlist] = useState(constant.sortingFields)
  const [filterStatus,switchFilterStatus] = useState(constant.filterStatus);
  const [pageChange, setPageChange] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isDataImportModalVisible, setDataImportModal] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [downloadVal, setDownloadVal] = useState(false);
  const [btnVisible, setBtnVisible] = useState(false);
  const [, updateState] = React.useState();
  const details = useSelector((state) => state?.jd);
  const info=details?.info;
  const experienceColumn=details?.experienceColumn;
  const tabStatus=details?.tabStatus;
 // const {  info,experienceColumn,tabStatus } = useSelector((state) => state?.jd);
  let [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();
  const jdStore = useSelector((state) => state.jd);
  const [selectedUpdateObject, setUpdateObject] = useState();
  const [searchStr, setSearchStr] = useState("");
  const [isWaningModalVisible, setWaningvisibility] = useState(false);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [jobLocn,setJobLocn] = useState([])
  const[orgType,setorgType] = useState([]);
  const [poc,setPoc] = useState("")
  const [itemList, setItemList] = useState("");
  const [selectCol, setSelectCol] = useState("");
  const [sortPopoverFlag,setSortPopoverFlag] = useState(false);
  const[isSortLoader,setIsSortLoader] = useState(false)
  const [idArray,setIdArray]=useState([]);
  const [activeness,setActiveness]=useState('Inactive');
  const [loader,setLoader]=useState(false);
  const [tableType,setTableType]=useState("");
  const [shortlister,setShortlister] = useState([])
  const [resData,setResData]=useState([]);
  const [roundAgency,setRoundAgency] = useState({
    roundType:[],
    agencyType:[],
    feedbackQuestion:{}
  })
  const [titleModal,setTitleModal]=useState(false);
  const [addBtnClick,setAddBtnClick]=useState(false);
  const [addDetails,setAddDetails]=useState({});
  const [filterKey,dispatchFK]=useReducer( filterReducer ,{job:{name:'',id:''},minExp:'',maxExp:'',searchValue:''});
const[reset,setReset]=useState(false);
const [initialAgencyData,setInitialAgencyData]=useState([]);
const [agencyDetails,setAgencyDetails]=useState({});
const [priorityData,setPriorityData]=useState([{value:"Low",selected:false},{value:"High",selected:false},{value:"Moderate",selected:false}]);
const [orgTypeData,setOrgTypeData]=useState([]);
const [locationData,setLocationData]=useState([]);
const [requestBodyData,setRequestBodyData]=useState()
const [vacanciesSum,setVacancySum]=useState(0);
const [minMaxFilterValue,setMinMaxFilterValue]=useState({expMin:"" ,expMax:"" ,NPMin:"" ,NPMax:""});
const {width}=useWindowDimensions();
const [viewPdfModal,setViewPdfModal] = useState(false);
const [resumePdf,setResumePdf] = useState(null);

  const filterJobHandler=(obj)=>{
    dispatchFK(obj);
  }

  const {user}= useSelector(state =>state.auth);
  // const userRole='agency';
  const expValues=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

  useEffect(()=>{
    if(orgType.length>0)
    {
      setOrgTypeData(orgType.map(item=>({...item,selected:false})))
    }
  },[orgType.length>0])

  useEffect(()=>{
    if(jobLocn.length>0)
    {
      setLocationData(jobLocn.map(item=>({...item, selected:false})))
    }
  },[jobLocn.length>0])
useEffect(() =>{
if(user?.role.includes("Agency")){
 getJobsForDropdownInJd()
}
},[user?.role])

  useEffect(() => {
   setTableType("jdExperienced");
   let requesestBody={ search: searchStr,status:tabStatus,jobExperience:"Experienced" };
     if(filterKey.job.id){
              requesestBody={...requesestBody,jobId:filterKey.job.id, }
    }
   
    if(filterKey.minExp!==-1&&filterKey.maxExp!==-1){
                 requesestBody={...requesestBody,minExp:filterKey.minExp,maxExp:filterKey.maxExp,}
    }
    /*
    if(user?.role?.includes("Agency"))
    {
      delete(requesestBody.status);
    }
      */
    setRequestBodyData(requesestBody)
    jdQuery(requesestBody);
    
  }, [searchStr,tabStatus,filterKey.job.id,filterKey.minExp,filterKey.maxExp]);


  const filterJob = (internalfilterObj = null) => {
    let requestBody;
    let filterArr = []
    setIsSortLoader(true)
    let sortorder
    let selectedField = sortFieldList.filter(a => a.selected);
    if(selectedField[0]?.sortKey!=="none"){
    sortorder={
      order:sortType ? "ASC" : "DESC",
      columnKey:selectedField[0]?.sortKey
    }
  }
  else{
    sortorder={}
  }
    let statusArr = ["experience","noticePeriod"];
    statusArr.map(element => {
      switch (element) {
        case "experience":    
          if (filterObj.experience) {
            filterArr.push({
              key: element,
              min: filterObj.expmin,
              max: filterObj.expmax
            })
          }
          break;
        case "noticePeriod":

          if (filterObj.noticePeriod) {

            filterArr.push({
              key: element,
              min: filterObj.npmin === "" ? 0 : filterObj.npmin,
              max: filterObj.npmax === "" ? 0 :  filterObj.npmax
            })

          }
          break;
        default:
          break;
      }
    })
    if(filterObj?.priority || filterObj.location|| filterObj.orgtype)
    {
      requestBody = {
        search: searchStr,
        jobExperience:"Experienced",
        status:tabStatus,
        sortOrder:sortorder,
        // limit:String(pageSize),
        // collegeName: collegeName,
        // page: String(pageChange),
        filter: filterArr
      }
    }
    else if (!filterArr || filterArr.length===0) {
      
      requestBody = {
        search: searchStr,
        jobExperience:"Experienced",
        page:pageChange,
        status:tabStatus,
        sortOrder:sortorder,
        limit:pageSize,
      }
    } else {
      requestBody = {
        search: searchStr,
        jobExperience:"Experienced",
        status:tabStatus,
        sortOrder:sortorder,
        // limit:String(pageSize),
        // collegeName: collegeName,
        // page: String(pageChange),
        filter: filterArr
      }
    }
    if(filterObj?.priority){
      requestBody['priority'] = priorityData.filter(item=>item.selected).map(item=>item.value);
    }
   if(filterObj.location)
   {
    requestBody['location'] = locationData.filter(item=>item.selected).map(item=>item.id);
   }
   if(filterObj.orgtype)
   {
    requestBody['jobType'] = orgTypeData.filter(item=>item.selected).map(item=>item.id);
   }
   setRequestBodyData(requestBody)
   jdQuery(requestBody)
  };
  const resetFilter = () => {
    filterObj.resetFitler();
    filterJob();
    let jobList = [];
    forceUpdate();
  }


  const sortCandidate = () => {
    setIsSortLoader(true)
    let requestBody;
    let selectedField = sortFieldList.filter(a => a.selected);
    if(selectedField[0].sortKey!=="none"){
    let sortorder={
      order:sortType ? "ASC" : "DESC",
      columnKey:selectedField[0].sortKey
    }
    requestBody = {
      search:searchStr,
      jobExperience: "Experienced",
      status: tabStatus,
      page: pageChange,
      limit:pageSize,
      sortOrder:sortorder
    }
  }else{
    requestBody = {
      search:searchStr,
      jobExperience: "Experienced",
      status: tabStatus,
      limit:pageSize,
      page: pageChange,
    }

  }
    

    // if(selectedField && selectedField.length > 0){
    //   requestBody[selectedField[0].sortKey] = sortType ? "ASC" : "DSC"
    // }
    setRequestBodyData(requestBody)
    jdQuery(requestBody);
  }
  useEffect(() =>{
   filterJob()
  },[pageSize,pageChange])
  const getfilterRequest = (key) => {
  
    switch (key) {
      case "jobType":
       filterObj.orgtype=true;
       break;
      case "experience":
        filterObj.experience = true;
        break;
      case "noticePeriod":
        filterObj.noticePeriod = true;
        break;
     
      case "vacancies":
        filterObj.vacancies=true
        break;
      case "priority" : 
        filterObj.priority = true;
        break;
      case "location":
        filterObj.location=true
        break;
      default:
        break;
    }
  }
  const activeFilterList = ()=>{
    let list = [];
   
    constant.filterFields.map(element => {
     

      switch(element.value){
        case "experience": 
        if(filterObj.experience){

          list.push({
            ...element,
            discription : filterObj.expmin + "-" + filterObj.expmax,
            min : filterObj.expmin,
            max : filterObj.expmax
          })

        }
        break;
     

        case "noticePeriod":
          if(filterObj.noticePeriod){
          let description=filterObj.npmin + "-" + filterObj.npmax
          list.push({
            ...element,
            discription : description
          })}
          break;
        // case "vacancies":
        //   if(filterObj.vacancies){
        //     let description=filterObj.vacancies ;
           
        //     list.push({
        //       ...element,
        //       discription : description
        //     })
        //   }
        // break;
        case "priority" : 
          if(filterObj.priority&&priorities.length>0){
            let description =priorities.map(item=>item.value);          
            list.push({
              ...element,
              discription : description.join(",")
            })
          }
        break;
        case "orgtype" : 
        if(filterObj.orgtype&&jobTypes.length>0){
          let map={}
          let values=[]
         jobTypes?.forEach((element) =>{
          map[element.id]=true
         })
       orgTypeData.forEach((element) => {
        if(map[element.id]===true)
        {
          values.push(element.name)
        }
        })       
          let description = values?.map(element => element).join(", ");
          list.push({
            ...element,
            discription: description,
          });          
        }
      break;
        case "location" : 
        if(filterObj.location&&locn.length>0){
          let map={}
            let values=[]
           locn?.forEach((element) =>{
            map[element.id]=true
           })
         locationData.forEach((element) => {
          if(map[element.id]===true)
          {
            values.push(element.name)
          }
          })
        

            list.push({
              ...element,
              discription: values?.map(element => element).join(", "),
            });
          
        }
      break;

        default :
        
        break;    
      }
    })

   
    return list;
  }
  const setFilterFilter = (type = null, valueType = null, value) => {
   
    if (valueType === "min") {
      switch (type) {
        case "experience":
          filterObj.expmin = parseInt(value)
          break;
       case "noticePeriod":
        filterObj.npmin = value
        break;
        default:
          break;
      }
    } else if (valueType === "max") {
      switch (type) {
        case "experience":
          filterObj.expmax = value
          break;
          case "noticePeriod":
            filterObj.npmax = value
            break;
        default:
          break;
      }
    }
  }
  
  const customPopover = (type = null, data = null, logo) => {
    let contentBody;

    let handler;

    switch (type) {
      case "sort":
        contentBody = <div className="sort-popover">

          <div> 
            <span className="sort-heading-css">Sort</span> <span className="float-right">Reset</span></div>

          <div>


      <div className="sorting-radio-container">
      <div> <span className="sort-heading-css">Sort Order</span></div>
        <Title style={{fontSize : "15px" , display : "inline"}}>ASCENDING</Title> <Radio 
        value={true} style={{float : "right"}} checked={sortType} 
        onClick={()=>{
          setSortType(true)
        }}></Radio>
      </div>
      <div>
      <Title style={{fontSize : "15px", display : "inline"}}>DESCENDING</Title> 
      <Radio value={false} style={{float : "right"}} 
      checked={!sortType}
      onClick={()=>{
        setSortType(false)
      }}
      ></Radio>
      </div>


          </div>

          <ul className="sorting-list-css">

            {
              sortFieldList.map((item, index) =>
                <li key={index}
                  className={item.selected ? "select-sorting-menu" : ""}
                  onClick={() => {
                    let temp = sortFieldList.slice();
                    temp.map((e,i) => {
                      if(index == i){
                        e.selected = true;
                      } else {
                        e.selected = false;
                      }
                    })
                
                    setSortOrderlist(temp);
                  }}
                >{item.label} <span className="float-right"> <span className={!item.selected ? "check-icon" : ""}><CheckCircleTwoTone /></span></span></li>)
            }
          </ul>

          <div className="sort-action">
            <Button className="cancel" onClick={() => setSortPopoverFlag(false)}>CANCEL</Button>
            <Button className="float-right ok" loading={isSortLoader} onClick={() => filterJob()}>OK</Button>
          </div>
        </div>

        return <Popover
          placement="bottom"
          content={contentBody}
          trigger="click"
          visible={sortPopoverFlag}
          onVisibleChange={() =>{
            if (sortPopoverFlag){
              setSortPopoverFlag(false)
            }else{
              setSortPopoverFlag(true)
            }}
          }
        >
          {logo}
        </Popover>


        break;
      case "filter-details":
        contentBody = <div className="sort-popover">
          <div> 
                      <span className="sort-heading-css">ALL Filter(s)</span>
                      <Button 
                      type="text" 
                      size="large" 
                      className="float-right"
                      onClick={()=>{
                                resetFilter();
                                setPriorityData(priorityData.map(item=>({...item,selected:false})));
                                setOrgTypeData(orgTypeData.map(item=>({...item, selected:false})));
                                setLocationData(locationData.map(item=>({...item, selected:false})));
                                setMinMaxFilterValue({expMin:"" ,expMax:"" ,NPMin:"" ,NPMax:""});
                              }}
                              >
                                Reset
                      </Button>
          </div>
          <List
          dataSource={activeFilterList()}
          renderItem={(item) => (
            <List.Item key={item.title}>
              <List.Item.Meta
                title={<span>{item.title}</span>}
                description={<span>{item.discription  }</span>}
              />
              {/* <div><CloseOutlined/></div> */}
            </List.Item>
          )}
        />

          <div className="sort-action">
            <Button className="cancel" onClick={() => { setFilterDetailsFlag(false)}}>CANCEL</Button>
            <Button className="float-right ok"
            onClick={() => { setFilterDetailsFlag(false)}}
            >OK</Button>
          </div>
        </div>

        return <Popover
          placement="bottom"
          content={contentBody}
          trigger="hover"
          visible={filterDetailsFlag}
          onVisibleChange={() => { 
            if (filterDetailsFlag){
              setFilterDetailsFlag(false)
            }else{
              setFilterDetailsFlag(true)
            }
          }}
        >
          {logo}
        </Popover>

            // <ul className="sorting-list-css">
            //   {sortFieldList.map((item, index) => (
            //     <li
            //       key={index}
            //       className={item.selected ? "select-sorting-menu" : ""}
            //       onClick={() => {
            //         let temp = sortFieldList.slice();
            //         temp[index].selected = !temp[index].selected;
            //         setSortOrderlist(temp);
            //       }}
            //     >
            //       {item.label}{" "}
            //       <span className="float-right">
            //         {" "}
            //         <span className={!item.selected ? "check-icon" : ""}>
            //           <CheckCircleTwoTone />
            //         </span>
            //       </span>
            //     </li>
            //   ))}
            // </ul>

        break;
      case "filter":



        break;
      default:

        contentBody = <div className="sort-popover">

          <div> <span className="sort-heading-css">Sort By</span> <span className="float-right">Reset</span></div>

          <ul className="sorting-list-css">

            {
              sortFieldList.map((item, index) =>
                <li key={index}
                  className={item.selected ? "select-sorting-menu" : ""}
                  onClick={() => {
                    let temp = sortFieldList.slice();
                    temp[index].selected = !temp[index].selected;
                    setSortOrderlist(temp);
                  }}
                >{item.label} <span className="float-right"> <span className={!item.selected ? "check-icon" : ""}><CheckCircleTwoTone /></span></span></li>)
            }
          </ul>

          <div className="sort-action">
            <Button className="cancel" onClick={() => setCustomPopoverVisible({ ...isCustomPopoverVisible, sortDetails: true })}>CANCEL</Button>
            <Button className="float-right ok">OK</Button>
          </div>
        </div>

        return <Popover
          placement="bottom"
          content={contentBody}
          trigger="hover"
          visible={isCustomPopoverVisible.sortingFields}
          onVisibleChange={() => toggleSortingPopover(null)}
        >
          {logo}
        </Popover>

        break;
    }
  };
  const customFilterPopover = (type = null, data = null, logo) => {
    let contentBody;
    switch (type) {
      case "priority" : 
      contentBody = 
      <div className="sort-popover">
              <div
                style={{
                  width: '100%',
                }}
              >
                {priorityData.map((item,index) =>{
                  return(
                          <div style={{display:"flex",flexDirection:"row",gap:"0.4rem",alignItems:"center"}}>
                                    <input 
                                      value={item.value} 
                                      type="checkbox"
                                      checked={item.selected}
                                      onChange={(e) =>{
                                              setPriorityData(priorityData.map((item2,index2)=>{
                                                if(index===index2){
                                                  return{
                                                    ...item2,selected:e.target.checked
                                                }
                                                }
                                                return item2;
                                              }))                                                               
                                  }}/>
                                  <span>{item.value}</span>
                          </div>)
                })}      
              </div>
              <div className="sort-action" style={{marginTop:"0.7rem"}}>
                          <Button 
                          className="cancel" 
                          onClick={() => {          
                                        let tempStatus = filterStatus;
                                        tempStatus.priority = false
                                        switchFilterStatus(tempStatus)
                                        forceUpdate();
                                  }}>CANCEL
                          </Button>
                          <Button 
                            className="float-right ok" 
                            onClick={() => {           
                                              setPriority(priorityData.filter(a=>a.selected));
                                              getfilterRequest(type);
                                              filterJob();
                                              let tempStatus = filterStatus;
                                              tempStatus.priority = false
                                              switchFilterStatus(tempStatus)
                                              forceUpdate();
                                      }}>                   
                                        OK
                            </Button>
              </div>
      </div>
     return <Popover
     placement="bottom"
     content={contentBody}
     trigger="hover"
     visible={filterStatus.priority}
     onVisibleChange={() => {
       let tempStatus = filterStatus;
       tempStatus.priority=!tempStatus.priority
      //  if(filterStatus.priority===false){
      //  tempStatus.priority = true
      //  switchFilterStatus(tempStatus)
         forceUpdate();
      //  }
      //  else{
      //   tempStatus.priority = false
      //   switchFilterStatus(tempStatus)
      //   forceUpdate();
      //  }
     }}
   >
     {logo}
   </Popover>
     case "jobType" : 
     contentBody = <div>
                <div
                  style={{
                    display:"flex",
                    flexDirection:"column",
                    maxHeight: "22vh",
                      overflowY:"auto",                                                               
                    }}
                  >
                            {orgTypeData?.map((item,index) =>{
                              return(
                                        <div>
                                                    <input
                                                    type="checkbox"
                                                    checked={item?.selected}
                                                     value={item.id} 
                                                     onChange={(e) =>{
                                                      setOrgTypeData(orgTypeData.map((item2,index2)=>{
                                                        if(index===index2)
                                                        {
                                                          return {
                                                            ...item2,selected:e.target.checked
                                                          }
                                                        }
                                                        return item2;
                                                      }))                                                           
                                                  }}/>
                                                   <span> {item.name}  </span>                                              
                                        </div>)
                            })}             
                </div>
                <div className="sort-action" style={{marginTop:"0.7rem"}}>
                            <Button className="cancel" onClick={() => {
                                  
                                  let tempStatus = filterStatus;
                                  tempStatus.orgtype = false
                                  switchFilterStatus(tempStatus)
                                  forceUpdate();

                            }}>CANCEL</Button>
                            <Button className="float-right ok" onClick={() => {                            
                              setJobTypes(orgTypeData.filter(a=>a.selected));
                              getfilterRequest(type);
                              filterJob();
                              let tempStatus = filterStatus;
                              tempStatus.orgtype = false
                              switchFilterStatus(tempStatus)
                              forceUpdate();
                            }}>OK</Button>
                </div>
     </div>
    return <Popover
    placement="bottom"
    content={contentBody}
    trigger="hover"
    visible={filterStatus.orgtype}
    onVisibleChange={() => {  
      let tempStatus = filterStatus;
      if(filterStatus.orgtype===false){
      tempStatus.orgtype = true
      switchFilterStatus(tempStatus)
      forceUpdate();
      }
      else{
       tempStatus.orgtype = false
       switchFilterStatus(tempStatus)
       forceUpdate();
      }
    }}
  >
    {logo}
  </Popover>
   case "location" : 
   contentBody = 
   <div className="sort-popover">
              <div
              style={{
                display:"flex",
                flexDirection:"column",
                maxHeight: "22vh",
                overflowY:"auto",                                                               
                }}>
                        {locationData.map((item,index) =>{
                          return(
                                  <div style={{display:"flex",flexDirection:"row",gap:"0.4rem", alignItems:"center"}}>
                                            <input
                                            type="checkbox"
                                            checked={item.selected}
                                            value={item.id}
                                              onChange={(e) =>{
                                                setLocationData(locationData.map((item2,index2)=>{
                                                  if(index===index2)
                                                  {
                                                    return {
                                                      ...item2, selected:e.target.checked
                                                    }
                                                  }
                                                  return item2;
                                                }))                                        
                                          }}/>
                                          <span>{item.name}</span>
                                  </div>
                          )
                        })}
              </div>
              <div className="sort-action" style={{marginTop:"0.7rem"}}>
                          <Button className="cancel" 
                                onClick={() => {                                   
                                      let tempStatus = filterStatus;
                                      tempStatus.location = false
                                      switchFilterStatus(tempStatus)
                                      forceUpdate();
                                }}>
                                  CANCEL
                          </Button>
                          <Button 
                                      className="float-right ok" 
                                      onClick={() => {               
                                          setLocn(locationData.filter(a=>a.selected));
                                          getfilterRequest(type); 
                                          filterJob();
                                      }}>
                                        OK
                          </Button>
                </div>
   </div>
    return <Popover
    placement="bottom"
    content={contentBody}
    trigger="hover"
    visible={filterStatus.location}
    onVisibleChange={(flag) => {
      // setLocationModal(flag)
      let tempStatus = filterStatus;
      if(tempStatus.location===true){
        // setLocationModal(flag)
        tempStatus.location = false
      }else{
      tempStatus.location = true
      }
      
      forceUpdate();
    }}
  >
    {logo}
  </Popover>
   break;
      default:
      if(type === 'experience' || type ==='noticePeriod' ){      
            contentBody =
             <div className="sort-popover">
                        <div>
                                      <span>min</span>
                                      <Input 
                                      type="number" 
                                      value={type === 'experience'?minMaxFilterValue.expMin:type ==='noticePeriod'?minMaxFilterValue.NPMin:""}
                                      onChange={(e) => {
                                        if(type==="experience")
                                        {
                                               setMinMaxFilterValue(prev=>({...prev,expMin:e.target.value}));
                                        }
                                        else if(type==="noticePeriod")
                                        {
                                               setMinMaxFilterValue(prev=>({...prev,NPMin:e.target.value}));
                                        }                                       
                                        setFilterFilter(type, "min", e.target.value);
                                      }}
                                      min={0}
                                      />
                                      <span>max</span>
                                      <Input 
                                      type="number"
                                      value={type === 'experience'?minMaxFilterValue.expMax:type === 'noticePeriod'?minMaxFilterValue.NPMax:""}
                                        onChange={(e) => {
                                          if(type==="experience")
                                          {
                                                 setMinMaxFilterValue(prev=>({...prev,expMax:e.target.value}));
                                          }
                                          else if(type==="noticePeriod")
                                          {
                                                 setMinMaxFilterValue(prev=>({...prev,NPMax:e.target.value}));
                                          }
                                          setFilterFilter(type, "max", e.target.value);
                                        }}
                                        min={0}
                                      />
                        </div>
                        <div className="sort-action" style={{marginTop:"0.7rem"}}>
                                        <Button 
                                        className="cancel"                
                                        onClick={()=>{               
                                                      let temp = filterStatus;
                                                      temp[type] = !temp[type];                
                                                      switchFilterStatus(temp);
                                                      forceUpdate();
                                                    }}
                                                      >
                                                      CANCEL
                                          </Button>
                                        <Button
                                        className="float-right ok" 
                                        onClick={() => {
                                                  if(type==="experience")
                                                  {
                                                  if(minMaxFilterValue.expMin > minMaxFilterValue.expMax){
                                                    Notification.error("Enter valid inputs to apply filter!");
                                                    return;
                                                  }else{
                                                    getfilterRequest(type);
                                                    filterJob();
                                                    forceUpdate();
                                                  }
                                                }
                                                else if(type==="noticePeriod")
                                                {
                                                  if(minMaxFilterValue.NPMin > minMaxFilterValue.NPMax){
                                                    Notification.error("Enter valid inputs to apply filter!");
                                                    return;
                                                  }else{
                                                    getfilterRequest(type);
                                                    filterJob();
                                                    forceUpdate();
                                                  }}
                                                  // else
                                                  // {
                                                  //     Notification.error("Enter valid inputs to apply filter!");
                                                  //     return;
                                                  // }                                             
                                                }}>OK
                                        </Button>
                        </div>
            </div>
    
            return <Popover
              placement="bottom"
              content={contentBody}
              trigger="hover"
              visible={filterStatus[type]}
              onVisibleChange={()=>{
       
                // settemp(true)
                let temp = filterStatus;
                // temp["x"]= !temp["x"];
                temp[type] = !temp[type];
                switchFilterStatus(temp);
                forceUpdate();
              }}
            >
              {logo}
            </Popover>
      }
      break;  
    }
  };
 
  
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys);
      if (selectedRowKeys.length === 0) {
        setBtnVisible(false);
      } else {
        setBtnVisible(true);
      }
    },
    onSelect: (record, selected, selectedRows) => { 
      setIdArray((prev)=>(
        [...prev,record.id]
      ));
  
    },
    onSelectAll: (selected, selectedRows, changeRows) => { },
    getCheckboxProps: (record) => ({}),
  };

  const handlePageChange = (page, pageSize) => {
 
    setPageChange(page);
    setPageSize(pageSize);
  };

  const getFileName = (fileName) => {
    return fileName.split('/').at(-1);
  }

 let column=[]

 column=experienceColumn?.map((item) =>{
  let render = "";
    let header;

    switch(item.key){
    
    case "title":
    header = <div>{item?.title} </div>
    render = (text,record) =>(
       <> 
      {user?.role.includes('Agency')?
       <div
       onClick={()=>{
         dispatch(jdActions.updateJobTitleModalOpen(true));
         setTitleModal(true);
         setAgencyDetails(record);
      }}
      //  type="text"
      style={{ color: "black",cursor: "pointer",textAlign:"left" }}
     >
    {text}
</div>
      :
      <div
                  onClick={() =>
                     toggleUpdateModal(record)
                    }
                  type="text"
                  style={{ color: "black" ,fontSize:"14px",fontWeight:"600",cursor: "pointer"}}
                >
            <span>{text}</span>
     </div>}
     </>
    )
    break;

    case "departments":
      header = <div >{item?.title} </div>
      render = (text,record) =>(
         <span>{text?text:"NA"}</span>
      )
      break;

      case "keywords":
        header = <div>{item?.title} </div>
        render = (text,record) =>(
          <>
                  {record?.keywords?.length>0&&<div>{record?.keywords?.map((item,index)=>{
                    if(record.keywords.length-1>index)
                    {
                      return <span>{item+",  "}</span>
                    }
                    return <span>{item}</span>
                  })}
                  </div>}
                  {record?.keywords?.length===0&&<span>NA</span>}

           </>
        )
        break;


    case "descriptionFile":
    header = <div>{item?.title} </div>
    render= (text, record) => <div>
    {text ? (
      <div>

        <div style={{cursor:"pointer"}}>
          <img
            onClick={() => window.open(text)}
            src={PdfLogo}
            alt="pdf-logo"
            className="pdf-logo"
          />
        </div>
       

      </div>
    ) : (
      <div><span>N/A</span></div>
    )}</div>
    break;
    case "spoc" : 
    header=<div>{item?.title}</div>
    render=(text,record) => (<>
    {record?.spoc?.name!==null ?
    <>
      <p>{record?.spoc?.name}</p>
      {record?.spoc?.phone}
      </> :""}</>
    )
    break;
    case "shortlister" : 
    header=<div>{item?.title}</div>
    render=(text,record) => (<>
    {record?.shortlister?.name!==null ?
    <>
      <p>{record?.shortlister?.name}</p>
    
      </> :""}</>
    )
    break;
    case "jobType" : 
    header=<div>{item?.title}  {customFilterPopover("jobType", null, <DownOutlined />)}</div>
    render=(text,record) => (<>
    {record?.jobType?.name!==null ?
    <>
      <p>{record?.jobType?.name}</p>
    
      </> :"NA"}</>
    )
    break;
    case "priority":
      header = <div>{item?.title} {customFilterPopover("priority", null, <DownOutlined />)} </div>;
    break;
    case "locations":
          header = <div>{item?.title} {customFilterPopover("location", null, <DownOutlined />)} </div>
          render=(text,_)=>{
            return (
              <>
                      {text?.map((item,index)=>(
                        <div>
                              {text.length-1>index&&<span >{item?.name+", "}</span>}
                              {text.length-1===index&&<span >{item?.name}</span>}
                        </div>
                      ))
                      }
              </>
              )
          }
    break;


    case "noticePeriod":
      header = <div>{item.title} {customFilterPopover("noticePeriod", null, <DownOutlined />)} </div>;
      break;
    case "experience":
      header=<div>{item.title} 
      {customFilterPopover("experience", null, <DownOutlined />)} 
      </div>
      render= (text,record) => (
        <span>{record?.minExperience}-{record?.maxExperience}</span>
      )
      break;
     case "round1" :  
     header=<div>{item.title}</div>
     render=(text,record) =>(     
        <p>{record?.jobInterviewrounds[0]?.roundType && record?.jobInterviewrounds[0]?.roundType === "Relationship"
        ? "HR Relationship":
        record?.jobInterviewrounds[0]?.roundType ? record?.jobInterviewrounds[0]?.roundType
        :"NA"}</p>
     )
      break;
      case "round2" :  
      header=<div>{item.title}</div>
      render=(text,record) =>(
        <p>{record?.jobInterviewrounds[1]?.roundType && record?.jobInterviewrounds[1]?.roundType === "Relationship"
          ? "HR Relationship"
          :record?.jobInterviewrounds[1]?.roundType ? record?.jobInterviewrounds[1]?.roundType
          :"NA"}</p>
      )
       break;

       case "round3" :  
       header=<div>{item?.title}</div>
       render=(text,record) =>(
        <p>{record?.jobInterviewrounds[2]?.roundType && record?.jobInterviewrounds[2]?.roundType === "Relationship"
          ? "HR Relationship"
          :record?.jobInterviewrounds[2]?.roundType ? record?.jobInterviewrounds[2]?.roundType
          :"NA"}</p> 
       )
        break;
        case "round4" :  
        header=<div>{item?.title}</div>
        render=(text,record) =>(
          <p>{record?.jobInterviewrounds[3]?.roundType && record?.jobInterviewrounds[3]?.roundType === "Relationship"
            ? "HR Relationship"
            :record?.jobInterviewrounds[3]?.roundType ? record?.jobInterviewrounds[3]?.roundType
            :"NA"}</p>  
        )
         break;
         case "round5":
          header = <div>{item.title}</div>;
          render = (text, record) => (
            <p>{record?.jobInterviewrounds[4]?.roundType && record?.jobInterviewrounds[4]?.roundType === "Relationship"
              ? "HR Relationship"
              : record?.jobInterviewrounds[4]?.roundType ? record?.jobInterviewrounds[4]?.roundType
              :"NA"}</p>
          );
          break;
    case "action" : 
    header=<div>{item?.title} </div>
    render = (_,record) => (
      <>
      {user?.role.includes('Agency') ?
      <Button className={classes.addButton} onClick={()=>{             
                  setAddDetails(record)
                  setAddBtnClick(true);
                  store.dispatch(adminActions.updateModalOpen(true))
                  }}
                  disabled={record?.status==="Inactive"}
                  >Add Profile
      </Button>
      :
      
      <JdActionModal 
                refresh={jdQuery.bind(this)}
                record={record} 
                poc={poc}
                roundAgency={roundAgency}
                orgType={orgType}
                jobLocations={jobLocn}
                isOpen={showUpdateModal}
                shortlister={shortlister}
                user={user}/>
                }
        </>
    )
    default:
      header = <div>{item?.title}</div>;
    }
    let width=`${(item?.title?.length+5)}ch`;
    if (render) {
      return ({
        title:<div className={classes.headerName} style={{width:width}}>
                      <h1>{header}</h1>
              </div>,
        key: item.key,
        dataIndex: item.key,
        render: render,
        width:270,
        
      })
    } else {
      return {
        title:<div className={classes.headerName} style={{width:width}}>
                        <h1>{header}</h1>
                </div>,
        key: item.key,
        dataIndex: item.key,
        width:250,
        
      };
    }
 })
 


 const agencyCol=column.filter((item)=>item.key==='title'||item.key==='vacancies'||item.key==='spoc'||item.key==='vacancy'||item.key==='experience'||item.key==='noticePeriod'||item.key==='locations'||item.key==='action' || item.key==="descriptionFile")

  const data = () => {
    let list = [];
    jdStore.jdList.map((item) => {
      list.push(item);
    });
   
    

    

    // if(filterKey.job==='All')
    // {
    //    tempList=list;
    // }
    // if(filterKey.job&&filterKey.job!=='All')
    // {
    //   tempList=tempList.filter(item=>item.title===filterKey.job);
    // }
    
    // if(filterKey.minExp!==-1&&filterKey.maxExp!==-1)
    // {
    //   tempList=tempList.filter(item=>item.minExperience===filterKey.minExp&&item.maxExperience===filterKey.maxExp);
    // }
    // if(filterKey.searchValue)
    // {
    //   tempList=tempList.filter(item=>item.title.toLowerCase().includes(filterKey.searchValue.toLowerCase()));
    // }
   
    return list;
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const toggleCollegeDataImportModal = () => {
    setDataImportModal(!isDataImportModalVisible);
  };

  const DeleteApi = (values, type) => {
    // setBtnLoader(true);
    

    if (values.length === 0) {
      let text = "please select JD";
      return;
    }
    const deleteValues = { ids: values };
    Api.delete("/hiring-job/deletejob")
      .params(deleteValues)
      .send((response) => {
       
        if (response && response.show && response.show.type === "success") {
          setSelectedRow([]);
          jdQuery();
        }
      });
  };

  const toggleUpdateModal = (record) => {
    if (!showUpdateModal) {
      setUpdateObject(record);
    }
    setUpdateModal(!showUpdateModal);
  };

  // useEffect(() => {
  //   if (columnFeature.length !== allColumn.length) {
  //     columnFeature?.forEach((item) => {
  //       setSelectCol((oldArray) => [...oldArray, item]);
  //     });
  //   }
  // }, []);

  const jdQuery = (requestBody) => {
    if(!requestBody)
    {
       requestBody={ search: searchStr,status:tabStatus,jobExperience:"Experienced", }
    }
    if(user?.role?.includes("Agency"))
    {
     // requestBody={...requestBody,id:user?.id}
      //delete(requestBody.status);
      if(filterObj?.priority){
        requestBody['priority'] = priorityData.filter(item=>item.selected).map(item=>item.value);
      }
     if(filterObj.location)
     {
      requestBody['location'] = locationData.filter(item=>item.selected).map(item=>item.id);
     }
     if(filterObj.orgtype)
     {
      requestBody['jobType'] = orgTypeData.filter(item=>item.selected).map(item=>item.id);
     }
     if(filterKey.job.id){
      requestBody={...requestBody,jobId:filterKey.job.id, }
}
    }
   
   
    setTableLoader(true);
    Api.post("/hiring-job/AllJobs")
      .params(requestBody)
      .send((res) => {
        setIsSortLoader(false)
        if(res){
        let list = [];
        if(filterKey.job.id===''&&filterKey.maxExp===''&&searchStr===''){
          setInitialAgencyData(res.jobs);
         
        }
        res?.jobs?.map((item) => {
          item["key"] = item.id;
          list.push(item);
        });
       
        setResData(res)
        dispatch(jdActions.updateJdList({ items: list }));
        dispatch(jdActions.updateInfo({ meta: res?.meta }));
        dispatch(jdActions.updateAllColumns(res?.columns))
        setJobLocn(res?.jobLocations);
        setVacancySum(res.vacanciesSum);
        setorgType(res?.orgType)
        setShortlister(res?.shortlister)
        setPoc(res.poc)
        setItemList(res.columns)
        setRoundAgency({roundType:res?.roundType,
        agencyType:res?.agencies,feedbackQuestion:res?.feedbackQuestion})
        
        if(selectCol?.length===0)
        {
          
          dispatch(jdActions.updateExperienceMappedColumn(res?.mappedcolumns))
       }

        //dispatch(jdActions.updateExperienceMappedColumn(res?.mappedcolumns))
        setTableLoader(false);
      }
      });
    
  };

  const handlePaginationChange = (e) => {
    setPageChange(e);
  };

  const onSearch = (event) => {
    setSearchStr(event.target.value);
  };

  const setDeleteConfirmation = (value) => {
    if (value) {
      DeleteApi(selectedRow, "multiple");
      setWaningvisibility(false);
    } else {
      setWaningvisibility(false);
    }
  };

  const logoutHAndler = () => {
    dispatch(authActions.removeUser());
    window.location.replace('/')
  };

  const onChange = (key) => {
  };

  const MobJobCard=({item,index})=>{
    return(
      <div className={classes.mobJobCard}>
        <div>
          <span  onClick={() =>{
                     toggleUpdateModal(item);
                    }}>{item?.title}</span>
          <img
            onClick={() => {
              setViewPdfModal(true);
              setResumePdf(item?.descriptionFile);
            }}
            src={PdfLogo}
            alt="pdf-logo"
            className="pdf-logo"
          />
        </div>
        <div>
          <div>
            <span>Department</span>
            <span>{item?.jobType?item?.jobType?.name:"NA"}</span>
          </div>
          <div>
            <span>No. of opening(s)</span>
            <span>{item?.vacancies}</span>
          </div>
          </div>

          <div>
          <div>
            <span>Location(s)</span>
            <Typography.Text style={{maxWidth:"40vw"}} ellipsis={{tooltip:true}}> <span>{item?.locations?.map(item=>item?.name)?.join(", ")}</span></Typography.Text>
           
          </div>
          <div>
            <span>Experience (Years)</span>
            <span>{item?.minExperience+"-"+item?.maxExperience}</span>
          </div>
          </div>

          <div>
              <div>
                <span>Notice period (Days)</span>
                <span>{item?.noticePeriod}</span>
              </div>
              <div>
                <span>Shortlister</span>
                <span>{item?.shortlister?.name}</span>
              </div>
          
          </div>
          {/* <button onClick={()=>{}}>View</button> */}
          <JdActionModal 
                refresh={jdQuery.bind(this)}
                record={item} 
                poc={poc}
                roundAgency={roundAgency}
                orgType={orgType}
                jobLocations={jobLocn}
                isOpen={showUpdateModal}
                shortlister={shortlister}
                user={user}/>
                
      
      </div>
    )
  }


  return (
    <div style={{height:"90vh"}}>
            <div className={classes.header}>
                          <h1>Job Description Management</h1>
            </div>
    <div className={classes.container}>
      

        {selectedRow.length == 0 ? (
          <div>
           
              {user?.role.includes('Agency')?
              <div className={classes.jobCard} >
                      <div>
                              <h3>Job Title</h3>
                                  <div className={classes.searchDiv2} style={{height:"2.3rem",}}> 
                                  <div>
                                        <img src={SearchIconBlack}></img>
                                     </div>
                                        <input value={searchStr} allowClear={true} size="medium" placeholder="Search"  
                                          prefix={<SearchOutlined className="custom-search-icon" />} style={{ height: "100%" }}
                                          onChange={(event)=>setSearchStr(event.target.value)} />
                                          {searchStr && (<button className={classes.clearBtn} onClick={() => setSearchStr('')}>&times;</button>)}
                                      </div>
                      </div>
                                <div>
                                <h3>Experience</h3>
                                <div>
                                            <Select placeholder="Min Yr" bordered={false} onChange={(value)=>dispatchFK({type:"MIN_EXP",value:value})} value={filterKey.minExp!==""?filterKey.minExp:undefined}>                                             
                                                    {expValues.map((item,index)=><Option value={item} key={index} ></Option>)}                                                    
                                            </Select>

                                            <Select placeholder="Max Yr"  bordered={false} onChange={(value)=>dispatchFK({type:"MAX_EXP",value:value})} value={filterKey.maxExp?filterKey.maxExp:undefined}>
                                                    {expValues.map((item,index)=><Option value={item} key={index}></Option>)}
                                            </Select>
                                            <Button type='text' onClick={()=>{
                                              dispatchFK({type:"MIN_EXP",value:''});
                                              dispatchFK({type:"MAX_EXP",value:''});
                                            }}><b>Reset</b></Button>                                      
                                </div>                      
                        </div>
                        <div>
                                <h3>Vacancy</h3>
                                <div>
                                        <span>{filterKey.job.id ? jdStore?.jdList[0]?.vacancies : resData?.vacanciesSum} Vacancies</span>                                     
                                        {/* <span>{vacanciesSum}</span>                                      */}
                                </div>                      
                        </div>
                        
              </div>
              :
              <div className={classes.searchDiv} style={{width:width>700?"20rem":"70vw"}}> 
                          <Input value={searchStr} allowClear={true} size="medium" placeholder="Search" style={{height:"2.2rem"}}  
                          prefix={<SearchOutlined className="custom-search-icon" />} 
                          onChange={(event)=>setSearchStr(event.target.value)} />
              </div>
              }

              {user?.role.includes('Agency')?
              // <Input size="large" placeholder="Search" prefix={<SearchOutlined className="custom-search-icon" />}
              //     onChange={(event)=>setSearchStr(event.target.value)}/>
              
              <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",margin:" 1.7rem 0.5rem 0 0"}}>
                        {/*<div className={classes.searchDiv2} style={{height:"2.3rem",}}> 
                          <div>
                            <img src={SearchIconBlack}></img>
                          </div>
                          <input value={searchStr} allowClear={true} size="medium" placeholder="Search"  
                          prefix={<SearchOutlined className="custom-search-icon" />} style={{ height: "100%" }}
                          onChange={(event)=>setSearchStr(event.target.value)} />
                        </div>*/}
                        <div className="head-icon">{customPopover("filter-details", null, <img src={filter} />)}</div>  
                        
                </div>
              :
              <div>
                        {width>700&&<div className="head-icon">
                          
                                    <DragAndDrop itemList={itemList} setItemList={setItemList} selectCol={selectCol} setSelectCol={setSelectCol} tableType={tableType}/>
                        </div> }          
                        {width>700&&<div className="head-icon">
                          {/* {customPopover("sort", null, <img src={sort} />)} */}
                                  <Sorter tableType={tableType} sortType={sortType} setSortType={setSortType} getFunc={resetFilter} activeFilterList={activeFilterList} sortFieldList={sortFieldList} setSortOrderlist={setSortOrderlist} sortCandidate={filterJob}/>   
                        </div>}             
                          {width>700&&<div className="head-icon">{customPopover("filter-details", null, <img src={filter} />)}</div>}
                          <div style={{display:"flex",alignItems:"center"}}>
                            <img
                              src={download}
                              style={{ height: "2rem", cursor: "pointer" }}
                              onClick={() => {
                               
                                setDownloadVal(!downloadVal)
                                Api.post("/hiring-job/AllJobs")
                                   .params({
                                      download: true,
                                      mappedcolumns: experienceColumn,
                                    ...requestBodyData
                                   })
                                   .send((response) => {
                                     if (typeof response != "undefined") {
                                       window.open(response, "_blank")?.focus();
                                     }
                                   });
                              }}
                            />
                            {width<700&&<span style={{color:"#1089FF",fontSize:"0.85rem"}}>Download</span>}
                          </div>
                          {user?.role.includes("Admin")||user?.role.includes("Super-Admin")?<Button type="primary" disabled={tableLoader} key="1" size="middle" onClick={toggleModal} > Create JD</Button>:""}
               </div>
               }
            
          </div>
        ) : (
          <div  style={{display:"flex",justifyContent:"right",}}>
            
              <Button
              size="middle"
                 type="primary"
                key="1"
                onClick={()=>{
                  Api.patch("/hiring-job/updateStatus").params({
                    jobIds:selectedRow,
                    status:activeness

                  }).send(resp=>{
                    jdQuery()
                    if(resp)
                    {
                    setSelectedRow([])
                   
                    }
                    else
                    {
                      
                    }
                  })

                }}
                
              >
                {activeness==='Inactive'? "Inactive":"Active"}           
              </Button>         
          </div>
        )}

     { user?.role.includes('Agency')  ? 
    <div>
      <Tabs activeKey={tabStatus} onChange={(key) =>{
      
      resetFilter();
      
      setPriorityData(priorityData.map(item=>({...item,selected:false})));
      setOrgTypeData(orgTypeData.map(item=>({...item, selected:false})));
      setLocationData(locationData.map(item=>({...item, selected:false})));
      setMinMaxFilterValue({expMin:"" ,expMax:"" ,NPMin:"" ,NPMax:""});
      dispatch(jdActions.updateTabState(key))
       
      }}>
      <TabPane disabled={selectedRow.length === 0 ? false : true}
   
      tab={(<span style={{fontSize:"1rem",fontWeight:"700",margin:0}}>Active</span>)} key="Active">
    {tabStatus==="Active"&&
    width>700?<Table
     key={tabStatus}
          columns={agencyCol}
          dataSource={data()}
          loading={tableLoader}
          scroll={{ y: 450, }}
          className={classes.tableStyle}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10,20, 30, 50, 100],
            pageSize: jdStore.info.itemPerPage,
            total: jdStore.info.totalItems,
            onShowSizeChange: handlePageChange,
            onChange: handlePaginationChange,
          }}
          locale={{emptyText:"No JD Found"}}
        />
        :
        <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        {
          tableLoader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}/>:
        data()?.length>0?data()?.map((item,index)=>(
          <MobJobCard key={index} item={item}/>
        ))
        :
        <span style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh",fontSize:"1rem"}}>No data!</span>
      }
        {data()?.length>0&&<Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginBottom:"1rem"}}
              current={pageChange} showSizeChanger={true} total={jdStore.info.totalItems} pageSize={ jdStore?.info?.itemPerPage?jdStore?.info?.itemPerPage:10} 
              onChange={handlePaginationChange}
               onShowSizeChange={handlePageChange} 
              pageSizeOptions={[10,20,30,50,100]}
              />}
        </div>

      }
    </TabPane>
    <TabPane disabled={selectedRow.length === 0 ? false : true}
       tab={(<span style={{fontSize:"1rem",fontWeight:"700",margin:0}}>Inactive</span>)} key="Inactive">
    {tabStatus==="Inactive"&&
    width>700?<Table 
          columns={agencyCol}
          key={tabStatus}
          dataSource={data()}
          loading={tableLoader}
          className={classes.tableStyle}
          //rowSelection={ (user?.role?.includes("Admin") || user?.role?.includes("Super-Admin"))?rowSelection:null}
          scroll={{ y: 450, }}
          pagination={{
            position: "bottomRight",
            size:"small",
            showSizeChanger: true,
            pageSizeOptions: [10,20, 30, 50, 100],
            pageSize: jdStore.info.itemPerPage,
            total: jdStore.info.totalItems,
            onShowSizeChange: handlePageChange,
            onChange: handlePaginationChange,
          }}
          locale={{emptyText:"No JD Found"}}
        />:
        <div style={{display:"flex",flexDirection:"column",gap:"1rem",}}>
        {tableLoader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}/>:
        data()?.length>0?data()?.map((item,index)=>(
          <MobJobCard key={index} item={item}/>
        ))
        :
        <span style={{display:"flex",alignItems:"center",justifyContent:"center",height:"60vh",fontSize:"1rem",}}>No data!</span>
        }
         {data()?.length>0&&<Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginBottom:"1rem"}}
              current={pageChange} showSizeChanger={true} total={jdStore.info.totalItems} pageSize={ jdStore?.info?.itemPerPage?jdStore?.info?.itemPerPage:10} 
              onChange={handlePaginationChange}
               onShowSizeChange={handlePageChange} 
              pageSizeOptions={[10,20,30,50,100]}
              />}
        </div>
        }
      </TabPane>   
      </Tabs> 
      </div>

      :
     <Tabs activeKey={tabStatus} onChange={(key) =>{
      resetFilter();
      setPriorityData(priorityData.map(item=>({...item,selected:false})));
      setOrgTypeData(orgTypeData.map(item=>({...item, selected:false})));
      setLocationData(locationData.map(item=>({...item, selected:false})));
      setMinMaxFilterValue({expMin:"" ,expMax:"" ,NPMin:"" ,NPMax:""});
          setSelectCol("");
           dispatch(jdActions.updateTabState(key))
           if(key==="Active")
           {
            setActiveness("Inactive");

           }
           else if(key==='Inactive')
           {
            setActiveness("Active");
           }
            
           setSortOrderlist(sortFieldList.map(item=>({...item,selected:false})));
      }}>
      <TabPane disabled={selectedRow.length === 0 ? false : true}
   
      tab={(<span style={{fontSize:"1rem",fontWeight:"700",margin:0}}>Active</span>)} key="Active">
    {tabStatus==="Active"&&
    width>700?<Table
     key={tabStatus}
          columns={column}
          dataSource={data()}
          loading={tableLoader}
          rowSelection={ (user?.role?.includes("Admin") || user?.role?.includes("Super-Admin"))?rowSelection:null}
           scroll={{ y: 450, }}
          // className={classes.tableStyle}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10,20, 30, 50, 100],
            pageSize: jdStore.info.itemPerPage,
            total: jdStore.info.totalItems,
            onShowSizeChange: handlePageChange,
            onChange: handlePaginationChange,
          }}
          locale={{emptyText:"No JD Found"}}
        />
        :
        <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        {
          tableLoader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}/>:
        data()?.length>0?data()?.map((item,index)=>(
          <MobJobCard key={index} item={item}/>
        ))
        :
        <span style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh",fontSize:"1rem"}}>No data!</span>
      }
        {data()?.length>0&&<Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginBottom:"1rem"}}
              current={pageChange} showSizeChanger={true} total={jdStore.info.totalItems} pageSize={ jdStore?.info?.itemPerPage?jdStore?.info?.itemPerPage:10} 
              onChange={handlePaginationChange}
               onShowSizeChange={handlePageChange} 
              pageSizeOptions={[10,20,30,50,100]}
              />}
        </div>

      }
    </TabPane>
    <TabPane disabled={selectedRow.length === 0 ? false : true}
       tab={(<span style={{fontSize:"1rem",fontWeight:"700",margin:0}}>Inactive</span>)} key="Inactive">
    {tabStatus==="Inactive"&&
    width>700?<Table 
          columns={column}
          key={tabStatus}
          dataSource={data()}
          loading={tableLoader}
          rowSelection={ (user?.role?.includes("Admin") || user?.role?.includes("Super-Admin"))?rowSelection:null}
          scroll={{ y: 450, }}
          pagination={{
            position: "bottomRight",
            size:"small",
            showSizeChanger: true,
            pageSizeOptions: [10,20, 30, 50, 100],
            pageSize: jdStore.info.itemPerPage,
            total: jdStore.info.totalItems,
            onShowSizeChange: handlePageChange,
            onChange: handlePaginationChange,
          }}
          locale={{emptyText:"No JD Found"}}
        />:
        <div style={{display:"flex",flexDirection:"column",gap:"1rem",}}>
        {tableLoader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}/>:
        data()?.length>0?data()?.map((item,index)=>(
          <MobJobCard key={index} item={item}/>
        ))
        :
        <span style={{display:"flex",alignItems:"center",justifyContent:"center",height:"60vh",fontSize:"1rem",}}>No data!</span>
        }
         {data()?.length>0&&<Pagination position="bottomRight" size="middle" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",marginBottom:"1rem"}}
              current={pageChange} showSizeChanger={true} total={jdStore.info.totalItems} pageSize={ jdStore?.info?.itemPerPage?jdStore?.info?.itemPerPage:10} 
              onChange={handlePaginationChange}
               onShowSizeChange={handlePageChange} 
              pageSizeOptions={[10,20,30,50,100]}
              />}
        </div>
        }
    </TabPane>   
  </Tabs> 
  }           
      <div>
        {isModalVisible && (
          <CreateJDModal
          poc={poc}
          roundAgency={roundAgency}
            orgType={orgType}
            jobLocations={jobLocn}
            isOpen={isModalVisible}
            toggle={toggleModal.bind(this)}
            tableLoader={tableLoader}
            shortlister={shortlister}
            refresh={jdQuery.bind(this)}
          />
        )}
        {showUpdateModal && (
          <CreateJDModal
          poc={poc}
          roundAgency={roundAgency}
            orgType={orgType}
            jobLocations={jobLocn}
            isOpen={showUpdateModal}
            toggle={toggleUpdateModal.bind(this)}
            tableLoader={tableLoader}
            shortlister={shortlister}
            // setTableLoader={setBtnLoader}
            selectedObject={selectedUpdateObject}
            refresh={jdQuery.bind(this)}
          />
        )}
        {addBtnClick&&<AddProfileModal addBtnClick={addBtnClick} addDetails={addDetails} jobLocn={jobLocn}/>}
        {titleModal&&<JobTitleModal agencyDetails={agencyDetails}/>}
        {viewPdfModal && <ViewPDF viewPdfModal={viewPdfModal} setViewPdfModal={setViewPdfModal} resumePdf={resumePdf} setResumePdf={setResumePdf} />}
        <Warning
          title="Warning"
          visible={isWaningModalVisible}
          confirmationRequst={setDeleteConfirmation.bind(this)}
          okText="Yes"
          onCancel="No"
          subject=""
        />
      </div>
    </div>
    </div>
  );
}
export default JdDashboard;