
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Typography,Row,Col } from 'antd';
import React, { useEffect, useState } from 'react';
import LogoutPalette from './headers'
import classes from './layout.module.css';
import './style.css';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import brigoshaIcon from "../assets/brigosha_logo_1.png";
import { useSelector, useDispatch } from 'react-redux';
import { adminActions } from '../pages/dashboard-home/store';
import dashboardIcon from '../assets/dashboardIcon.svg';
import dashboardIcon2 from '../assets/ic-dashboard-icon.svg';
import emailTemplateIcon from '../assets/emailTemplateIcon.svg';
import emailTemplateIcon2 from '../assets/siderIcon/ic-email-template.svg';
import testpaperIcon from '../assets/testPaperIcon.svg';
import testpaperIcon2 from '../assets/siderIcon/ic-test-paper-creation.svg';
import feedbackManagementIcon from '../assets/feedbackManagementIcon.svg';
import feedbackManagementIcon2 from '../assets/siderIcon/ic-feedback-management.svg';
import panelistIcon from '../assets/panelistIcon.svg';
import panelistIcon2 from '../assets/siderIcon/ic-panelist-icon.svg';
import hrProcessIcon from '../assets/hrProcessIcon.svg';
import logsActionIcon from '../assets/logsActionIcon.svg';
import logsActionIcon2 from '../assets/siderIcon/log-action-icon.svg';
import InterviewScheduleIcon from "../assets/scheduleIcon.svg";
import InterviewScheduleIcon2 from "../assets/siderIcon/schedule-icon.svg";
import PanFeedbackIcon from "../assets/feedbackIcon.svg";
import PanFeedbackIcon2 from "../assets/siderIcon/feedback-icon.svg";
import hrProcessIcon2 from '../assets/siderIcon/ic-hr-process-icon.svg';
import userIcon from '../assets/usersIcon.svg';
import userIcon2 from '../assets/siderIcon/ic-user-icon.svg';
import interviewIcon from '../assets/interviewsIcon.svg';
import interviewIcon2 from '../assets/siderIcon/ic-interviews-icon.svg';
import jdIcon from '../assets/jdManagementIcon.svg';
import jdIcon2 from '../assets/siderIcon/ic-job-description-management icon.svg';
import agencySummaryIcon from '../assets/agencySummary.svg';
import agencySummaryIcon2 from '../assets/siderIcon/ic-agency-summary.svg';
import candidateManagementIcon from '../assets/candidateManagement.svg';
import candidateManagementIcon12 from '../assets/siderIcon/ic-candidate-management-1.svg';
import candidateManagementIcon2 from '../assets/candidateManagementIcon2.svg';
import candidateManagementIcon22 from '../assets/siderIcon/ic-candidate-management-2.svg';
import jdManagementIcon from '../assets/jdManagementIcon.svg';
import instituteManagementIcon from '../assets/instituteManagementIcon.svg';
import instituteManagementIcon2 from '../assets/siderIcon/ic-institute-management.svg';
import panDashboardIcon from '../assets/panDashboardIcon.svg';
import panDashboardIcon2 from "../assets/dashboardIcon2.svg";
import VideoLayoutIcon from "../assets/videoLayoutIcon.svg";
import VideoLayoutIcon2 from "../assets/videoLayoutIcon 2.svg";
import BackIcon from "../assets/backIcon.svg";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    useHistory 
} from "react-router-dom";
import Routes from './routes';
import Ribbon from 'antd/lib/badge/Ribbon';
import { getStatusClassNames } from 'antd/lib/_util/statusUtils';
import MainHeader from './mainHeader';
import MobileNavModal from './MobileNavModal/MobileNavModal';
import { moduleActions } from './store';

const { Header, Content, Sider,Footer } = Layout;
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
            return {
                    width,
                    height
            };
  }
  export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  }
function DefaultLayout() {

    const {user} = useSelector(state =>state.auth);
    const { height, width } = useWindowDimensions(); 
    const testMatch = useRouteMatch('/test/:testId');
    
    const {candidateJobFilter,candidateRound,candidateAgency} = useSelector(state =>state.candidate);
    let path=new URL(location.href).pathname;
    const dispatch=useDispatch();
 
    let mainkeyList=[]
    let value=false
    let locn=""
    const locations = useLocation();
   locn=locations.search.split('=')
useEffect(() =>{
   
    window.localStorage.setItem("navKey" ,locations.pathname+locations.search);
},[])
  
    if(user?.role.includes("Super-Admin") || user?.role.includes("Admin") ){
        value=true
     mainkeyList=[  
    { key: "13", label: "User" ,url : '/create-user',icon:{icon1:<img src={userIcon}></img>,icon2:<img src={userIcon2}></img>} ,clicked:false},
    { key: "19", label: "Location And Department" ,url : '/locn-dept',icon:{icon1:<img src={userIcon}></img>,icon2:<img src={userIcon2}></img>} ,clicked:false},
    { key: "0", label: "Dashboard" , url : '/Dashboard', icon:{icon1:<img src={dashboardIcon2} ></img>,icon2:<img src={dashboardIcon} ></img>} ,clicked:false},
    { key: "1", label: "Email Template" , url : '/email-template',icon:{icon1:<img src={emailTemplateIcon}></img>,icon2:<img src={emailTemplateIcon2}></img>},clicked:false},
    { key: "2", label: "Test Papers" , url : '/test-list',icon:{icon1:<img src={testpaperIcon}></img>,icon2:<img src={testpaperIcon2}></img>},clicked:false},
    { key: "3", label: "Feedback Management" , url : '/fbmanagement', icon:{icon1:<img src={feedbackManagementIcon}></img>,icon2:<img src={feedbackManagementIcon2}></img>} ,clicked:false},
    { key: "4", label: "Feedback Response" , url : '/feedback-management-response', icon:{icon1:<img src={feedbackManagementIcon}></img>,icon2:<img src={feedbackManagementIcon2}></img>} ,clicked:false},
    { key: "5", label: "Panelist Management" , url : '/panelistmanagement',icon:{icon1:<img src={panelistIcon}></img>,icon2:<img src={panelistIcon2}></img>},clicked:false},

    { key: "6", label: "Job Description Management" ,url : '/jd', icon:{icon1:<img src={jdIcon}></img>,icon2:<img src={jdIcon2}></img>} ,clicked:false},
    { key: "7", label: "Agency Summary" ,url : '/agency-summary', icon:{icon1:<img src={agencySummaryIcon}></img>,icon2:<img src={agencySummaryIcon2}></img>},clicked:false},
    { key: "8", label: "Candidate Management" ,url : '/exp-candidate-management', icon:{ icon1:<img src={candidateManagementIcon}></img>, icon2:<img src={candidateManagementIcon12}></img>},clicked:false},
    
    { key: "9", label: "Job Description Management",url : '/jdFreshers',icon:{icon1:<img src={jdIcon}></img>,icon2:<img src={jdIcon2}></img>},clicked:false},
    { key: "10", label: "Institute Management" ,url : '/institutes',icon:{ icon1:<img src={instituteManagementIcon}></img>,icon2:<img src={instituteManagementIcon2}></img>},clicked:false},
    { key: "11", label: "Candidate Management" ,url : '/fresher-candidate-management' ,icon:{icon1:<img src={candidateManagementIcon2}></img>,icon2:<img src={candidateManagementIcon22}></img>},clicked:false},
    
    { key: "12", label: "HR Process" ,url : '/hr-process',icon:{icon1:<img src={hrProcessIcon}></img>,icon2:<img src={hrProcessIcon2}></img>} ,clicked:false},
     
    { key: "14", label: "Interviews" ,url : '/interview-exp',icon:{ icon1:<img src={interviewIcon}></img>,icon2:<img src={interviewIcon2}></img>} ,clicked:false},
    { key: "15", label: "Logs and Actions" ,url : '/logs-action',icon:{icon1:<img src={logsActionIcon}></img>,icon2:<img src={logsActionIcon2}></img>} ,clicked:false},
    { key: "20", label: "Recorded Videos" ,url : "/recorded-videos",icon:{icon1:<img src={VideoLayoutIcon2} style={{opacity:"0.6"}}></img>,icon2:<img src={VideoLayoutIcon}></img>} ,clicked:false},
    { key: "18", label: "Interviews" ,url : '/interview-fresher',icon:{ icon1:<img src={interviewIcon}></img>,icon2:<img src={interviewIcon2}></img>} ,clicked:false},     
    ]
}
    if(user?.role?.includes("Admin") && !user?.role?.includes("Super-Admin")){
        mainkeyList=mainkeyList.filter(element => element.label !=="HR Process")    
    } 
    let keyList=[]
   
    if((user?.role?.includes("Recruiter")|| user?.role?.includes("Sourcer")) && value!==true){
          keyList= [
            { key: "2", label: "Test Papers" , url : '/test-list',icon:{icon1:<img src={testpaperIcon}></img>,icon2:<img src={testpaperIcon2}></img>},clicked:false},
            { key: "6", label: "Job Description Management" ,url : '/jd', icon:{icon1:<img src={jdIcon}></img>,icon2:<img src={jdIcon2}></img>} ,clicked:false},
            { key: "8", label: "Candidate Management" ,url : '/exp-candidate-management', icon:{ icon1:<img src={candidateManagementIcon}></img>, icon2:<img src={candidateManagementIcon12}></img>},clicked:false},
            { key: "14", label: "Interviews" ,url : '/interview-exp',icon:{ icon1:<img src={interviewIcon}></img>,icon2:<img src={interviewIcon2}></img>} ,clicked:false},
            { key: "9", label: "Job Description Management",url : '/jdFreshers',icon:{icon1:<img src={jdIcon}></img>,icon2:<img src={jdIcon2}></img>},clicked:false},
            { key: "11", label: "Candidate Management" ,url : '/fresher-candidate-management' ,icon:{icon1:<img src={candidateManagementIcon2}></img>,icon2:<img src={candidateManagementIcon22}></img>},clicked:false},
            { key: "18", label: "Interviews" ,url : '/interview-fresher',icon:{ icon1:<img src={interviewIcon}></img>,icon2:<img src={interviewIcon2}></img>} ,clicked:false},
          ]     
            mainkeyList= mainkeyList.concat(keyList)
         
    }
    if(user?.role?.includes("Shortlister")&& value!==true){
        keyList= [
                  { key: "6", label: "Job Description Management" ,url : '/jd', icon:{icon1:<img src={jdIcon}></img>,icon2:<img src={jdIcon2}></img>} ,clicked:false},
                 { key: "8", label: "Candidate Management" ,url : '/exp-candidate-management', icon:{ icon1:<img src={candidateManagementIcon}></img>, icon2:<img src={candidateManagementIcon12}></img>},clicked:false},
                 ]
              
                if(mainkeyList.length===0){
                  
                    mainkeyList= mainkeyList.concat(keyList)
               
                }
    }
    if(user?.role?.includes("Panelist")||user?.role.includes("HR")){
        keyList= [
            { key: "20", label: " Personal Dashboard" ,url : '/dashboard-view',icon:{ icon1:<img src={panDashboardIcon}></img>,icon2:<img src={panDashboardIcon2}></img>} ,clicked:false},
            { key: "16", label: "Interview Schedules" ,url : `/panelist/interviewSchedule?search=${!locn[1]?"":locn[1]}`,icon:{icon1:<img src={InterviewScheduleIcon}></img>,icon2:<img src={InterviewScheduleIcon2}></img>} ,clicked:false},
            { key: "17", label: "Feedback" ,url : '/panelist-feedback',icon:{icon1:<img src={PanFeedbackIcon}></img>,icon2:<img src={PanFeedbackIcon2}></img>} ,clicked:false},
            ]
            if(user?.role.includes("HR") && !user?.role.includes("Super-Admin")){
             keyList.push({ key: "12", label: "HR Process" ,url : '/hr-process',icon:{icon1:<img src={hrProcessIcon}></img>,icon2:<img src={hrProcessIcon2}></img>} ,clicked:false})
            }
            if(user?.role.includes("HR") && !user?.role.includes("Super-Admin")&&!user?.role?.includes("Admin")&&!user?.role.includes("Shortlister")&&!user?.role?.includes("Recruiter")&&!user?.role?.includes("Sourcer")){
                keyList.push({ key: "8", label: "Candidate Management" ,url : '/exp-candidate-management', icon:{ icon1:<img src={candidateManagementIcon}></img>, icon2:<img src={candidateManagementIcon12}></img>},clicked:false})
            }
            mainkeyList= mainkeyList.concat(keyList)
    }
    if(user?.role?.includes("Agency")){
        keyList= [
            { key: "8", label: "Candidate Management" ,url : '/exp-candidate-management', icon:{ icon1:<img src={candidateManagementIcon}></img>, icon2:<img src={candidateManagementIcon12}></img>},clicked:false},
            { key: "6", label: "Job Description Management" ,url : '/jd', icon:{icon1:<img src={jdIcon}></img>,icon2:<img src={jdIcon2}></img>} ,clicked:false},
            { key: "14", label: "Interviews",url : '/interview-exp',icon:{ icon1:<img src={interviewIcon}></img>,icon2:<img src={interviewIcon2}></img>} ,clicked:false},
        ]
        mainkeyList= mainkeyList.concat(keyList)
    } 
    if(user?.role.includes("College-Admin"))
    {
        keyList= [
            { key: "20", label: "Dashboard" , url : '/institute-dashboard', icon:{icon1:<img src={dashboardIcon2} ></img>,icon2:<img src={dashboardIcon} ></img>} ,clicked:false},
        ]
        mainkeyList= mainkeyList.concat(keyList)
    }
    const history = useHistory();
    const [current, setCurrent] = useState();
   const [navList,setNavlist]=useState(mainkeyList);
   const updatePath=(val)=>{
    path=val;
   }
    useEffect(()=>{   
        let navKey = window.localStorage.getItem("navKey");
     
        if(navKey && navKey != '')
        {          
            setCurrent(navKey)
        } 
        else
         {     
                 navKey = navList[0]?.url;
        } 
        window.localStorage.setItem("navKey" , navKey)
        history.push(navKey);       
    },[current])

    const testPaperPath = '/test/:testId'; 
    const testPaperRegex = new RegExp(`^${testPaperPath.replace(/:\w+/g, '\\w+')}$`);

    const siderStyle=(isActive)=>{
        return {
            backgroundColor: isActive ? '#1890ff' : '#ffffff',
            borderRight: isActive ? '3px solid #1890ff' : 'none',
            color: isActive ? '#ffffff' : 'rgb(106, 106, 106)',
          };
        // if(clicked===true)
        // {
        //     return { color:"#ffffff",backgroundColor:"#0086FF"}
        // }
        // return {color:"#6f6f6f",};
    }
    // const { user} = useSelector((state) => state.auth);
    //  const history = useHistory();
    const logoutHAndler = () => {
      if(user?.role?.includes("Agency") || user?.role?.includes("College-Admin")){
        window.localStorage.clear()
        window.location.replace("https://newpmt.brigosha.com");
      }else{
        // dispatch(authActions.removeUser());
        history.goBack();
        history.goBack();
        window.location.replace("https://newpmt.brigosha.com/homePage")
      }
      };

    return (
        <Layout  style={{height:"100vh",}}>
            <Layout style={{backgroundColor:"#ffffff"}}>
               {
                width>700?
                <Sider width={230} className={classes.container} >
                    <div className="app-logo" style={{ margin:"0.5rem auto 0.5rem 0.5rem",alignItems:"center",display:"flex",gap:"0.9rem",flexDirection:"row"}}>
                      {(!user?.role?.includes("Agency") && !user?.role?.includes("College-Admin")) &&
                      <img src={BackIcon} alt="" style={{height:"1rem",cursor:"pointer",}}  onClick={logoutHAndler} />}
                        <img className="logo" src={brigoshaIcon} alt="brigosha"
                            style={{ height: "53px", padding: "10px"}} />
                    </div>
                    <div>
                        {
                            navList.map((item, index) =>{
                                if (item.key === "2") {
                                    let value = item.url.split("?");
                                    const isTestPaper = testPaperRegex.test(path); // Check if the current URL matches the pattern
                              
                                    return (
                                      <ul
                                        key={item.url}
                                        className={classes.module}
                                        onClick={() => {
                                          setCurrent(item.url);
                                          history.push(item.url);
                                          window.localStorage.setItem("navKey", value[0]);
                                        }}
                                        style={siderStyle(value[0] === path || isTestPaper)} 
                                      >
                                        <li>
                                          <div>{value[0] === path || isTestPaper ? item.icon.icon2 : item.icon.icon1}</div>
                                          <Typography.Text
                                            style={{
                                              maxWidth: "10rem",
                                              color: value[0] === path || isTestPaper ? "#ffffff" : "rgb(106, 106, 106)",
                                            }}
                                            ellipsis={{ tooltip: false }}
                                          >
                                            {item.label}
                                          </Typography.Text>
                                        </li>
                                      </ul>
                                    );
                                  }
                                  else{
                                if(item.key!=="9"&&item.key!=="10"&&item.key!=="11"&&item.key!=="14"&&item.key!=="6"&&item.key!=="7"&&item.key!=="8"&&item.key!=="18")
                                {  let value=item.url.split("?")
                                return(
                                    <ul
                                        key={item.url}  
                                        className={classes.module} 
                                        onClick={()=>{
                                        setCurrent(item.url);
                                        history.push(item.url);  
                                        window.localStorage.setItem("navKey" ,value[0]);
                                    }}                         
                                        style={siderStyle(value[0]===path)}                                     
                                    >
                                            <li>
                                                    <div>{item.url===path?item.icon.icon2:item.icon.icon1}</div>
                                                    <Typography.Text style={{maxWidth:"10rem",color:value[0]===path?"#ffffff":"rgb(106, 106, 106)",}} ellipsis={{tooltip:false}}>  {item.label}</Typography.Text>
                                            </li>
                                </ul>
                                )}}})
                        }
                     {(user?.role?.includes("Super-Admin") || user?.role?.includes("Admin") ||user?.role?.includes("Recruiter") || user?.role?.includes("Sourcer") || user?.role?.includes("Shortlister"))
                      ?   <p style={{margin:"0.7rem 0.5rem 0.7rem 1.1rem",fontSize:"16px", color:"#000000",fontWeight:"600"}}>EXPERIENCED HIRING</p>:""}
                        {
                            navList.map((item, index) =>{
                              if(item.key==="6"||item.key==="7"||item.key==="8" ||item.key==="14")
                              {                            
                                return(                   
                                <ul 
                                    key={item.url}  
                                    className={classes.module}
                                    onClick={()=>{
                                    setCurrent(item.url);
                                    history.push(item.url);
                                    if(item.key==="8" && (candidateJobFilter.length!==0 || candidateRound!=="All")){
                                    
                                    window.location.reload();
                                    }
                                    window.localStorage.setItem("navKey" ,item.url)  
                                }}                                  
                                    style={siderStyle(item.url===path)}                                                                                              
                                >
                                            <li>
                                                    <div>{item.url===path?item.icon.icon2:item.icon.icon1}</div>
                                                    <Typography.Text style={{maxWidth:"10rem",color:item.url===path?"#ffffff":"rgb(106, 106, 106)",}} ellipsis={{tooltip:false}}>  {item.label}</Typography.Text>
                                            </li>
                                </ul>
                                )}})
                        }   
                       {(user?.role?.includes("Super-Admin") || user?.role?.includes("Admin") ||user?.role?.includes("Recruiter") || user?.role?.includes("Sourcer")) ?
                        <p style={{margin:"0.7rem 0.5rem 0.7rem 1.1rem",fontSize:"16px", color:"#000000",fontWeight:"600"}}>CAMPUS RECRUITMENT</p>  :""}
                        {
                            navList.map((item, index) =>{                            
                               if(item.key==="9"||item.key==="10"||item.key==="11"||item.key==="18")
                               {    
                                return(                   
                                <ul
                                    key={item.url}  
                                    className={classes.module}
                                    onClick={()=>{
                                    setCurrent(item.url);
                                    history.push(item.url);
                                    window.localStorage.setItem("navKey" ,item.url)  
                                }}                           
                                     style={siderStyle(item.url===path)}                                                  
                                >
                                        <li>
                                                <div>{item.url===path?item.icon.icon2:item.icon.icon1}</div>
                                                <Typography.Text style={{maxWidth:"10rem",color:item.url===path?"#ffffff":"rgb(106, 106, 106)",}} ellipsis={{tooltip:false}}>  {item.label}</Typography.Text>
                                        </li>
                                </ul>)}})
                        }               
                    </div>
                </Sider>:
                <MobileNavModal>
                  <div>
                  {/* <div className="app-logo" style={{ margin:"0.5rem auto 0.5rem 0.5rem",alignItems:"center",display:"flex",gap:"0.9rem",flexDirection:"row"}}>
                      {(!user?.role?.includes("Agency") && !user?.role?.includes("College-Admin")) &&
                      <img src={BackIcon} alt="" style={{height:"1rem",cursor:"pointer",}}  onClick={logoutHAndler} />}
                        <img className="logo" src={brigoshaIcon} alt="brigosha"
                            style={{ height: "53px", padding: "10px"}} />
                    </div> */}
                    <div>
                     
                        {
                            navList.map((item, index) =>{
                                if (item.key === "2") {
                                    let value = item.url.split("?");
                                    const isTestPaper = testPaperRegex.test(path); // Check if the current URL matches the pattern
                              
                                    return (
                                      <ul
                                        key={item.url}
                                        className={classes.module}
                                        onClick={() => {
                                          setCurrent(item.url);
                                          history.push(item.url);
                                          window.localStorage.setItem("navKey", value[0]);
                                          dispatch(moduleActions.setSidemenu(false));
                                        }}
                                        style={siderStyle(value[0] === path || isTestPaper)} 
                                      >
                                        <li>
                                          <div>{value[0] === path || isTestPaper ? item.icon.icon2 : item.icon.icon1}</div>
                                          <Typography.Text
                                            style={{
                                              maxWidth: "10rem",
                                              color: value[0] === path || isTestPaper ? "#ffffff" : "rgb(106, 106, 106)",
                                            }}
                                            ellipsis={{ tooltip: false }}
                                          >
                                            {item.label}
                                          </Typography.Text>
                                        </li>
                                      </ul>
                                    );
                                  }
                                  else{
                                if(item.key!=="9"&&item.key!=="10"&&item.key!=="11"&&item.key!=="14"&&item.key!=="6"&&item.key!=="7"&&item.key!=="8"&&item.key!=="18")
                                {  let value=item.url.split("?")
                                return(
                                    <ul
                                        key={item.url}  
                                        className={classes.module} 
                                        onClick={()=>{
                                        setCurrent(item.url);
                                        history.push(item.url);  
                                        window.localStorage.setItem("navKey" ,value[0]);
                                        dispatch(moduleActions.setSidemenu(false));
                                    }}                         
                                        style={siderStyle(value[0]===path)}                                     
                                    >
                                            <li>
                                                    <div>{item.url===path?item.icon.icon2:item.icon.icon1}</div>
                                                    <Typography.Text style={{maxWidth:"10rem",color:value[0]===path?"#ffffff":"rgb(106, 106, 106)",}} ellipsis={{tooltip:false}}>  {item.label}</Typography.Text>
                                            </li>
                                </ul>
                                )}}})
                        }
                     {(user?.role?.includes("Super-Admin") || user?.role?.includes("Admin") ||user?.role?.includes("Recruiter") || user?.role?.includes("Sourcer") || user?.role?.includes("Shortlister"))
                      ?   <p style={{margin:"0.7rem 0.5rem 0.7rem 1.1rem",fontSize:"16px", color:"#000000",fontWeight:"600"}}>EXPERIENCED HIRING</p>:""}
                        {
                            navList.map((item, index) =>{
                              if(item.key==="6"||item.key==="7"||item.key==="8" ||item.key==="14")
                              {                            
                                return(                   
                                <ul 
                                    key={item.url}  
                                    className={classes.module}
                                    onClick={()=>{
                                    setCurrent(item.url);
                                    history.push(item.url);
                                    if(item.key==="8" && (candidateJobFilter.length!==0 || candidateRound!=="All")){
                                    
                                    window.location.reload();
                                    }
                                    window.localStorage.setItem("navKey" ,item.url)  
                                    dispatch(moduleActions.setSidemenu(false));
                                }}                                  
                                    style={siderStyle(item.url===path)}                                                                                              
                                >
                                            <li>
                                                    <div>{item.url===path?item.icon.icon2:item.icon.icon1}</div>
                                                    <Typography.Text style={{maxWidth:width>700?"10rem":"100%",color:item.url===path?"#ffffff":"rgb(106, 106, 106)",}} ellipsis={{tooltip:false}}>  {item.label}</Typography.Text>
                                            </li>
                                </ul>
                                )}})
                        }   
                       {(user?.role?.includes("Super-Admin") || user?.role?.includes("Admin") ||user?.role?.includes("Recruiter") || user?.role?.includes("Sourcer")) ?
                        <p style={{margin:"0.7rem 0.5rem 0.7rem 1.1rem",fontSize:"16px", color:"#000000",fontWeight:"600"}}>CAMPUS RECRUITMENT</p>  :""}
                        {
                            navList.map((item, index) =>{                            
                               if(item.key==="9"||item.key==="10"||item.key==="11"||item.key==="18")
                               {    
                                return(                   
                                <ul
                                    key={item.url}  
                                    className={classes.module}
                                    onClick={()=>{
                                    setCurrent(item.url);
                                    history.push(item.url);
                                    window.localStorage.setItem("navKey" ,item.url)  ;
                                    dispatch(moduleActions.setSidemenu(false));
                                }}                           
                                     style={siderStyle(item.url===path)}                                                  
                                >
                                        <li>
                                                <div>{item.url===path?item.icon.icon2:item.icon.icon1}</div>
                                                <Typography.Text style={{maxWidth:"10rem",color:item.url===path?"#ffffff":"rgb(106, 106, 106)",}} ellipsis={{tooltip:false}}>  {item.label}</Typography.Text>
                                        </li>
                                </ul>)}})
                        }               
                    </div>
                  </div>
                 
                </MobileNavModal>
                }
                <Layout style={{backgroundColor:"#f8f8f8"}}
                >
                    <MainHeader/>
                    <div  style={{  backgroundColor:"#f8f8f8",minHeight:"90vh",overflowY:"auto",display:"flex",flexDirection:"column",}}>
                            <Content>
                                <Routes navList={navList} setNavlist={setNavlist} updatePath={updatePath}/>
                            </Content>
                            {/* { user?.role?.includes("Panelist")&& width<450?
                             <Footer>
                     <Row>
                            {navList.map((item, index) =>{
                              let value=item.url.split("?")
                                return(
                                <Col span={7} offset={1}>
                                    <ul
                                        key={item.url}  
                                        className={classes.module} 
                                        onClick={()=>{
                                        setCurrent(item.url);
                                        history.push(item.url);  
                                        window.localStorage.setItem("navKey" ,value[0]);
                                    }}                         
                                        style={siderStyle(value[0]===path)}                                     
                                    >
                                            <li>
                                                    <div style={{textAlign:"center",alignSelf:"center",marginLeft:"10px"}}>{value[0]===path?item.icon.icon2:item.icon.icon1}</div>
                                                   
                                            </li>
                                </ul>
                                <div style={{fontSize:"15px",textAlign:"center"}}>{item?.label==="Personal Dashboard" ? "Dashboard":item?.label==="Interview Schedules" ? "Interview":item.label}</div></Col>
                                )
                            })
                        }</Row> 
               </Footer>
               :""} */}
                    </div>
                </Layout>
            </Layout>
        </Layout>
    )
}
export default DefaultLayout;



