import React, { useState, useEffect } from "react";
import { Button, Card,Checkbox,Spin } from "antd";
import Api from "services/Api";
import moment from "moment";
import { authActions } from "pages/login/store";
import Webadaptor from "./webadaptor";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { candidateActions } from "./store";
import Notification from "services/Notification";
import classes from './candidateInfo.module.css';
import { Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import brigoshaLogo from '../../assets/brigoshaLogo.svg';
import RoomClient from "./roomClient";
import { setUpChatSocket, setUpRoomClientSocket } from './utils';

const TestInstructions = () => {
  let [btnDisable, setBtnDisable] = useState(true);

  let history = useHistory();
  const dispatch = useDispatch();
  const { candidateInfo } = useSelector((state) => state.userCandidate);

  const {candidateId, pageLoad}=useSelector(state=>state.auth);
  const [hour,setHour]=useState('');
  const [checked,setchecked]=useState(false);
  const [day,setDay]=useState(0);
  const [minute,setMinute]=useState(0);
  const [second,setSecond]=useState(0);
  const [loading,setLoading]=useState(true);
  let value = candidateInfo.name + "_" + candidateInfo.id;
  let valued = value.replace(/[\s.-]/g, '_');
  const { instruction } = useSelector((state) => {
    return state.userCandidate;
  });
  let startTime = instruction.startTime;
  let endTime = instruction.endTime;
  let testDate = instruction.date;
  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setchecked(e.target.checked);
  };

  useEffect(() => {
    Api.get(`/hiring-tests/getTestInstructions/?candidateId=${candidateId}`).send((response) => {
      setLoading(false);    
      if(response?.show?.type!=="error")
      {
        setLoading(false);    
      dispatch(candidateActions.updateTestInstruction(response));
      if(response?.candidates[0]?.candidateType !== "Experienced"){
      const date = new Date().toLocaleDateString();
      const time = moment(new Date());
      let beginTime = moment(
        `${response?.date} ${response?.startTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      let endingTime = moment(
        `${response?.date} ${response?.endTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );

      const timeToStart = beginTime?.diff(time, "seconds");
      const timeToEnd = endingTime?.diff(time, "seconds");

      if (time <= endingTime) {
        setTimeout(function () {
          setBtnDisable(false);
        }, timeToStart * 1000);
        setTimeout(function () {         
          setBtnDisable(true);
        }, timeToEnd * 1000);
      }
    }}
    });
  }, []);

  // let roomClientAulasRecording = null;

  // const socketCallback = (event, data) => {
  //   // console.log("ssssEvent",event)
  //   if (event==='muxError'){
  //       // console.log('muxError ', data);
  //       // // if(thiz.audioOn)
  //       // // if(thiz.screenON)
  //       // roomClient?.closeProducer('screenType');
  //       // setTimeout(()=>{
  //       //     roomClient?.closeProducer('audioType');
  //       // }, 1001)
  //       // setTimeout(()=>{
  //       //     roomClient?.produce('audioType')
  //       // },2000)
  //       // this.produce("audioType")
  //       // setPageLoad(false)
  //       dispatch(authActions.updatePageLoad({ pageLoad: false }));
  //   }else if(event==='connect'){
  //       clearTimeout(broadcastHealthTimer)
  //       // setPageLoad(false)
  //       dispatch(authActions.updatePageLoad({ pageLoad: false }));
  //   }
  //   else if(event==='disconnect'){
  //       console.log('19x socket is disconnected');
  //       broadcastHealthTimer = setTimeout(() => {
  //           // if (window.location.pathname==='/broadcast'){
  //           //   Notification.error("", "Socket disconnected, join again to resume the class")
  //           //   history.goBack();
  //           // }
  //           console.log("19x exitRoom called after 10 sec");
  //         }, 10000);
  //       // setPageLoad(false)
  //       dispatch(authActions.updatePageLoad({ pageLoad: false }));
  //   }
  // }

  // const recordingCallback = async (event, dataReceived) => {
  //   console.log("ssssssEventtttttt318eee", event, dataReceived)
  //   let shareType = ''; 

  //   if (event==='rtmpStarted'){
  //     // setPageLoad(true);
  //     dispatch(authActions.updatePageLoad({ pageLoad: true }));
  //   }else if(event==='rtmpRemoved'){
  //       // setTimeout(()=>{
  //           roomClientAulasRecording?.closeProducer('audioType');
  //       // }, 1000);/\
  //       // setPageLoad(false)
  //       dispatch(authActions.updatePageLoad({ pageLoad: false }));
  //   }else if(event==='audioAdded'){
  //     shareType = dataReceived; 
  //       // console.log("439", shareType)
  //       // if (shareType==='screen'){
  //           roomClientAulasRecording.produce('screenType')
  //       // }else{
  //           // roomClientAulasRecording.produce('videoType')
  //           // setPageLoad(true)
  //           dispatch(authActions.updatePageLoad({ pageLoad: true }));
  //       // }
  //   }
  //   else if(event === "Transport connected"){
  //     roomClientAulasRecording.produce("audioType");
  //   }
  // }

  const handleStartTest = () => {
      // let details ={ 
      //   room_id: "159_random", 
      //   // room_id:valued,
      //   refer:false, 
      //   user:candidateInfo, 
      //   name: candidateInfo.name, 
      //   user_id:candidateId, 
      //   socket:setUpRoomClientSocket("/aulasrecordingdev.io", history, socketCallback), 
      //   // "/aulasstudent.io"
      //   eventCallback:recordingCallback, setStreamCallback: ()=>{}
      //   }
      // localStorage.setItem("roomClientAulas", JSON.stringify(roomClientAulasRecording));
      // roomClientAulasRecording = new RoomClient(details)
      history.push(`/candidate-test/${valued}`);
  };
  const startTimer=()=>{
    const countDownDate=new Date( instruction.date+" "+instruction.startTime).getTime();
   const  interval=setInterval(()=>{
      const now=new Date().getTime();
      const distance=countDownDate-now;
      const days=Math.floor(distance/(24*60*60*1000));
      const hours=Math.floor((distance%(24*60*60*1000))/(1000*60*60));
      const minutes=Math.floor((distance%(60*60*1000))/(1000*60));
      const seconds=Math.floor((distance%(60*1000))/(1000));
      if(distance<0)
      {
        clearInterval(interval);
      }
      else
      {
        setDay(days);
        setHour(hours);
        setMinute(minutes);
        setSecond(seconds);
      }
    })
  }

  useEffect(()=>{
    startTimer();
  })

  return (
    <>
    {loading?(
    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",textAlign:"center",height:"90vh"}}>
    <Spin className="loader" style={{ fontSize: 40 }} />
    </div>
    ):(<div style={{backgroundColor:"#F8F8F8"}}>
      <header className={classes.mainHeader}>
      <img src={brigoshaLogo}></img>
    </header> 
      
     <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#F8F8F8",
        marginTop:"1rem",overflow:"auto"
      }}
    >
      <Card
        //   title="Card title"
        bordered={false}
        style={{ height: "85vh", width: "70%", fontSize: "15px",borderRadius:"1rem",overflow:"auto" }}
      >
        <div style={{display:"flex", justifyContent:"center", alignItems:"center",margin:"0rem auto 1rem auto",flexDirection:"column",borderBottom:"1px solid #D3D3D3",padding:"1rem"}}>
              <h1>Instructions:</h1>
              <span style={{color:"black",fontFamily:"Open Sans",fontSize:"1rem"}}>Please Read The Instructions Carefully Before Starting The Test.</span>
        </div>
        
        <ul style={{display:"flex",gap:"0.5rem",flexDirection:"column",fontWeight:600,borderBottom:"1px solid #D3D3D3",padding:"1rem"}}>
          <li>
            Candidate shall have a laptop/desktop(with webcam) and a working
            internet connection.
          </li>
          {/* <li>
            Candidate shall open the link in this mail and register themselves
            using the phone number mentioned in his/her resume to start the
            test.
          </li> */}
          <li>
            Candidate shall grant permission to access the webcam camera for the
            test to begin.
          </li>
          <li>
            Candidate’s video, audio and screen shall be recorded during the
            test.
          </li>
          <li>
            Candidate shall not switch tabs during the test. Repeating the same
            more than twice shall lead to forced submission of the test.
          </li>
          <li>
            Any unfair means used shall lead to candidate’s disqualification and
            the candidate may be barred from appearing in any future hirings in
            Brigosha.
          </li>
          <li>
            Candidate shall review all questions before final submission.
            Changes cannot be made once the test is submitted.
          </li>
          <li>Each candidate can appear the test only once.</li>
          <li>
            If a candidate changes the screen from the test screen for more than
            15 seconds, test shall be autosubmitted.
          </li>
          <li>
          The test can be taken only during the duration given. As soon as the time is over, the test shall be auto-submitted.
          </li>
          {candidateInfo?.candidateType === "Experienced" ?
          <li>
          Candidates may participate in the test only during the specified dates.
        </li>
          :
          <li>
            Candidates are not allowed to take the test before/after time
            mentioned.
          </li>
          }
          <li>
            All decisions made by Brigosha Hackathon Team shall be final.
          </li>
        </ul>
        <ul style={{display:"flex",gap:"0.5rem",flexDirection:"column",fontWeight:600,padding:0,margin:"0 1rem"}}>
          <li><Row><p ><b style={{color:"#1089FF"}}>Save And Next-</b></p>The question has been answered and saved succesfully</Row></li>
          <li><Row><p style={{color:"#26DE81"}}><b>Save And Mark For Review-</b></p>The question has been answered and marked for review which will be considered for evaluation</Row></li>
          <li><Row><p style={{color:"#FD9644"}}><b>Marked For Review And Next-</b></p>The question has been marked for review and will not be considered for evaluation</Row></li>
          </ul>
          
          <div style={{display:"flex", justifyContent:"center", margin:"1.8rem auto",flexDirection:"column"}}>
          {(btnDisable && (instruction?.candidates?.length > 0 && instruction?.candidates[0]?.candidateType === "Experienced")) ?
          <div style={{display:"flex",justifyContent:"flex-start",}}>
            <Checkbox onChange={onChange} style={{fontSize:"1rem"}}>I state that I have taken the time to read and comprehend the provided instructions entirely.</Checkbox>
            </div>
          :Object.keys(instruction).length > 0 &&
          <div style={{display:"flex", flexDirection:"row"}}>
          <li style={{ color: "#161616",fontFamily:"Open Sans", fontSize:"1rem" }}>
          The Test will Start at Scheduled time.
        </li>
        {day===0&&<span style={{marginLeft:"1rem",width:"8rem",color:"#0086FF",fontSize:"1rem"}}><b>{hour<10?'0'+hour:hour}:{minute<10?'0'+minute:minute}:{second<10?'0'+second:second} </b></span>}
        </div>}
        <div style={{display:"flex",justifyContent:"center",marginTop:"2.3rem",}}>
        <Button type="primary" 
        disabled={(btnDisable && (instruction?.candidates?.length > 0 && instruction?.candidates[0]?.candidateType !== "Experienced")) || !checked}
         onClick={handleStartTest} style={{height:"3rem",width:"9.8rem",borderRadius:"0.5rem"}}>
          START
        </Button>
        </div>
        </div>
        
      </Card>
    </div> 
    </div>
    
    )}

</>
  );
};

export default TestInstructions;
