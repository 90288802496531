import React,{useEffect, useState} from "react";
import { Modal, Input, DatePicker, Row,Col, TimePicker ,Radio,Button, Form, Select,Typography,} from "antd";
import Slots from "./check-slots";
import classes from "./schedule.module.css";
import Api from "services/Api";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "./store";
import moment from "moment";
import store from "store";
import { BiUserVoice, FiUsers } from "../../services/reactIcons";
import { Notification } from "services";
import { operationType,performOperation, } from "pages/candidate/redux/action";

const {Title} = Typography

const Schedule = (props) => {
    
    const [candidateFeedback,setCandidateFeedback]=useState([])
    const [slotsModal,setSlotModal] = useState(false);
    const [initialData,setInitialData]=useState(new Array(3).fill(''));
   const {interviewModal,candidateInfo} = useSelector(state => state.candidate)
   const [btnLoader,setBtnLoader]=useState(false)
    const {scheduleRecord}=props;
    let rounds
    let candidateId
    if(!scheduleRecord){
        rounds=candidateInfo?.job?.jobInterviewrounds
        candidateId=candidateInfo?.id;
    }
   else {
    rounds=scheduleRecord?.job?.jobInterviewrounds;
    candidateId=scheduleRecord?.id;
}
   
    const {scheduleDetails}=useSelector(state=>state.candidateFresher);
    const [loader,setLoader]=useState(false);
    const [scheduleId,setScheduleId]=useState();
    const [dateId,setDateId]=useState();
    const dispatch=useDispatch();
    const [slot,setSlot]=useState('');
    const [isSelected,setIsSelected]=useState(false);
    const [timeRange,setTimeRange]=useState(new Array(2).fill(''));
    const [getCall,setGetCall]=useState(false);
    const [intType,setIntType]=useState("");
    const {user}= useSelector(state =>state.auth);
    const [form]=Form.useForm()

const dayArray=[ 
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]

  let oobj={
    Monday:[],
    Tuesday:[],
    Wednesday:[],
    Thursday:[],
    Friday:[],
    Saturday:[],
    Sunday:[]
  }


  const [Obj,setObj]=useState(oobj)
    
    const scheduleHandler=(values)=>{

        console.log("ssssValuessfinal",values)
        setBtnLoader(true)
        const timeRange=values['time'];
        let tomorrow
        let date=!values["date"]? moment():values["date"]
      
        let trueValue
        scheduleDetails.forEach((item) =>{     
            if(initialData[2]!==""){
                trueValue=initialData[2].find(element =>element===item?.panelistCopy?.id)
            
            }
        if(trueValue){     
           item.availableSlot.forEach((element) =>{   
            if(element.id===parseInt(dateId)){

                const d = new Date(date?.format('YYYY/MM/DD'));
                let day = dayArray[d.getDay()];
                let curr;
                let final;
              
                for(let i=0;i<7;i++){
                    if(dayArray[i]===day)
                    {  
                      curr=i
                    }
                    if(dayArray[i]===element.weekday)
                    {   
                      final=i
                    }
                }
                let diff
                if(final-curr<0){
                     diff =(7-(curr-final))%7
                }else{
                    diff=(final-curr)%7
                }
              
                const today = moment(date);
                 tomorrow = moment(today).add(diff, 'days');
            }
           })
          }
         else if(user?.role?.includes("Agency")){
            item.availableSlot.forEach((element) =>{         
                if(element.id===parseInt(dateId)){
   
                   const d = new Date(date?.format('YYYY/MM/DD'));
                   let day = dayArray[d.getDay()];
                   let curr;
                   let final;
                   for(let i=0;i<7;i++){
                       if(dayArray[i]===day)
                       {
                         curr=i
                       }
                       if(dayArray[i]===element.weekday)
                       {
                         final=i
                       }
                   }
                   let diff
                   if((final-curr<0)){
                     diff=(7-(curr-final))%7
                   }
                   else{
                    diff=(final-curr)%7
                   }
               
                   const today = moment(date);
                    tomorrow = moment(today).add(diff, 'days');
               }
              })
         }
        })
        let val=candidateFeedback.map((event)=>{return event.id})
        let MinValue=Math.min(...val)

        const finalValue={
            date:tomorrow?.format('YYYY/MM/DD'),
            candidateId:candidateId,
            currentRoundId:values.currentRound,
            interviewMode:values.interviewMode,
            scheduleId:parseInt(dateId),
            googleMeet:values.interviewFrom === "googleMeet" ? true : false,
            candidateFeedbackId:user?.role?.includes("Agency") ? MinValue : values.candidateFeedbackId
        }
        // props?.performOperation(operationType.CANDIDATE_TABLE_LOADER, true);
         
        Api.post('/hiring-recruitment/bookslot').params(finalValue).send(resp=>{
            setBtnLoader(false)
            if(resp && resp?.show?.type!=="error"
                ){    
                if(!scheduleRecord){

                } 
                if(props?.setScheduleModal){       
                props.setScheduleModal(false);

                }
                props.refresh() 
                props?.performOperation(operationType.INTERVIEW_MODAL,
                    false)
                // props?.performOperation(operationType.CANDIDATE_TABLE_LOADER, false);
            } 
               
        })
    }

    useEffect(()=>{             
        if(initialData[0] &&candidateId)
        {         
            setGetCall(true);       
            Api.get(`/hiring-recruitment/interview-slot?jobInterviewRoundId=${initialData[0]}&date=${initialData[1]}&candidateId=${candidateId}`).send(res=>{
                if(res)
                {        
                    setLoader(false);                                
                    dispatch(adminActions.updateSheduleDetails(res.data));    
                    setCandidateFeedback(res?.candidateFeedbacks)               
                }               
            })
        }
    },[
        initialData[0],initialData[1],slotsModal
    ])
 
useEffect(() =>{
    let oobj={
        Monday:[],
        Tuesday:[],
        Wednesday:[],
        Thursday:[],
        Friday:[],
        Saturday:[],
        Sunday:[]
      }
   
      if(scheduleDetails.length===0){
        setObj(oobj)
      }
    scheduleDetails?.map((elements) =>{
        let trueValue
       if(initialData[2]!==""){
         trueValue=initialData[2].find(element =>element===elements?.panelistCopy?.id)
        }
       if(trueValue){
       elements.availableSlot.map((element) =>{
        if(element.weekday==="Monday" && element.enable!==false){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
        oobj.Monday.push(value)
        }
        if(element?.weekday==="Tuesday" && element.enable!==false){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
        oobj.Tuesday?.push(value)
        }
        if(element?.weekday==="Wednesday" && element.enable!==false){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
            oobj.Wednesday?.push(value)
        }
        if(element?.weekday==="Thursday" && element.enable!==false){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
        oobj.Thursday?.push(value)
        }
        if(element?.weekday==="Friday" && element.enable!==false){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
        oobj.Friday?.push(value)
        }
        if(element.weekday==="Saturday" && element.enable!==false){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
            oobj.Saturday?.push(value)
        }
        if(element.weekday==="Sunday" && element.enable!==false){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
            oobj.Sunday?.push(value)
        }
     })
    }
    else if(user?.role?.includes("Agency")){
        elements.availableSlot.map((element) =>{
            if(element.weekday==="Monday" && element.enable!==false){
                let value=element.id+ "_"+element.startTime+"-"+element?.endTime
            oobj.Monday.push(value)
            }
            if(element?.weekday==="Tuesday" && element.enable!==false){
                let value=element.id+ "_"+element.startTime+"-"+element?.endTime
            oobj.Tuesday?.push(value)
            }
            if(element?.weekday==="Wednesday" && element.enable!==false){
                let value=element.id+ "_"+element.startTime+"-"+element?.endTime
                oobj.Wednesday?.push(value)
            }
            if(element?.weekday==="Thursday" && element.enable!==false){
                let value=element.id+ "_"+element.startTime+"-"+element?.endTime
            oobj.Thursday?.push(value)
            }
            if(element?.weekday==="Friday" && element.enable!==false){
                let value=element.id+ "_"+element.startTime+"-"+element?.endTime
            oobj.Friday?.push(value)
            }
            if(element.weekday==="Saturday" && element.enable!==false){
                let value=element.id+ "_"+element.startTime+"-"+element?.endTime
                oobj.Saturday?.push(value)
            }
            if(element.weekday==="Sunday" && element.enable!==false){
                let value=element.id+ "_"+element.startTime+"-"+element?.endTime
                oobj.Sunday?.push(value)
            }
      })
    }
  
    setObj(oobj)
    }) 
},[initialData[2],initialData[1],scheduleDetails])

    useEffect(()=>{
        if(getCall){
            setLoader(true);
        }

    },[getCall])
   
    return (
        <>
            <Modal visible={props.scheduleModal || interviewModal} onCancel={() => {
                if(interviewModal===false)
                {props.setScheduleModal(false)
             return}
            
           props?.performOperation(operationType.INTERVIEW_MODAL,
            false)
            }} width="40rem" centered footer={null} bodyStyle={{height:"96vh"}}>
                <div className={classes.container}>
                <h1>Schedule Interview</h1>                 
                    <Form form={form} layout="vertical"  id="schedule" onFinish={(values)=>{
                         
                        scheduleHandler(values);

                    }}
                    >            
                            <Form.Item name="interviewMode" label={<p style={{fontWeight:650}}>INTERVIEW MODE</p>}
                            rules={[{ required: true, message: "Select Interview Mode!" }]}
                            >        
                                            <Radio.Group
                                                buttonStyle="solid"
                                                size="middle"
                                                className="interview-type"
                                            >
                                                <Radio 
                                                value={"Offline"}
                                                >
                                                <FiUsers /> Face to Face Interview
                                                </Radio>
                                                <Radio
                                                value={"Online"}
                                                >
                                                <BiUserVoice /> Remote Interview
                                                </Radio>
                                            </Radio.Group>
                            </Form.Item>
                            <Form.Item label={<p style={{fontWeight:650}}>INTERVIEW FROM</p>} name="interviewFrom" rules={[{ required: true, message: "Select Interview From!.." }]}>
                                        <Select style={{width:"100%"}} placeholder="Select Interview From">
                                          <Select.Option value={"googleMeet"}>Google Meet</Select.Option>
                                          <Select.Option value={"Brogisha"}>Brigosha Conference Meet</Select.Option>
                                        </Select>
                            </Form.Item> 
                            {rounds&& <Form.Item label={<p style={{fontWeight:650}}>ROUND</p>} name="currentRound" 
                                        rules={[{ required: true, message: "Select Round!" }]}
                                       >
                                            <Select  placeholder="Select round" onChange={(event)=>
                                            {setInitialData(initialData.map((item,index)=>{
                                                if(index===0)
                                                {
                                                    return event;
                                                }
                                                else{
                                                    return item;
                                                }
                                            }));
                                            form.setFieldsValue({ ["currentRound"]: event, ["panelist"]: undefined,["candidateFeedbackId"]:undefined, ["date"]: undefined });
                                            }} size="middle">
                                                {rounds?.map((item,index)=>{                                                                   
                                                    return (<Select.Option disabled={item?.round<props.scheduleRecord?.currentRound || item?.round<candidateInfo.currentRound} value={item?.id}>Round{" "} {item?.round}</Select.Option>)                                                                   
                                                })}
                                                
                                            </Select>                                   
                             </Form.Item>}
                             {user?.role?.includes("Agency") ? ""
                             :
                             <>
                            <Form.Item label={<p style={{fontWeight:650}}>PANELIST</p>} name="panelist"  rules={[{ required: true, message: "Select Panelist!.." }]}>
                                        <Select
                                        allowClear
                                        showArrow={true}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        // value={initialData[2] === "" ? undefined:initialData[2]}
                                        mode="multiple" size={"middle"} placeholder="Select the panelist" onChange={(event)=>{setInitialData(initialData.map((item,index)=>{
                                           
                                            if(index===2)
                                            {
                                                return event;
                                            }
                                            else{
                                                return item;
                                            }
                                        }))}}>
                                            {scheduleDetails.map((item,index)=>{     
                                                    return (<Select.Option value={item.panelistCopy.id}>{item.panelistCopy.name}</Select.Option>)                              
                                            })}  
                                        </Select>
                            </Form.Item>
                            <Form.Item label={<p style={{fontWeight:650}}>CANDIDATE FEEDBACK</p>} name="candidateFeedbackId" rules={[{ required: true, message: "Select Candidate Feedback!.." }]}>
                                        <Select style={{width:"100%"}} placeholder="Select Candidate Feedback">
                                            {
                                                candidateFeedback?.map(element =>{
                                                    return(
                                                        <>
                                                        <Select.Option value={element?.id}>{element?.title}</Select.Option>
                                                        </>
                                                    )
                                                })
                                            }
                                            
                                        </Select>
                            </Form.Item> 
                            </>
                            }
                            <div className={classes.picker}>
                                            <Form.Item label={<p style={{fontWeight:650}}>DATE</p>} name="date"  >
                                                        <DatePicker style={{width:"100%",}} size="middle" disabledDate={(current) =>{return current && current < moment().startOf('day');}} onChange={(event)=>setInitialData(initialData.map((item,index)=>{
                                                        if(index===1)
                                                        {   if(!event){
                                                            
                                                           return moment().format('YYYY/MM/DD')
                                                        }
                                                            return event.format('YYYY/MM/DD');
                                                        }
                                                        else{
                                                            return item;
                                                        }
                                                    }))}>

                                                        </DatePicker>
                                            </Form.Item>
                                </div>     
                                {user?.role?.includes("Agency") ? 
                                <>
                                {Object.keys(Obj).map((element) =>{  
                                    if(Obj[element].length===0){
                                        return ""
                                    }
                                 return(
                                     <>
                                            <Row style={{marginBottom:"10px",marginTop:"10px"}}>{element}</Row>
                                            <Form.Item>
                                                            <Radio.Group  onChange={(e) =>{                                                                                                                          
                                                                    setDateId(e.target.value)

                                                                    }}>
                                                                    {
                                                                        Obj[element].map((item) =>{
                                                                            let values=item.split("_")
                                                                        return(
                                                                            <Radio.Button style={{marginTop:"5px"}} value={values[0]}>{values[1]}</Radio.Button>
                                                                        )
                                                                        })
                                                                    }
                                                            </Radio.Group>
                                            </Form.Item>
                                         </>
                                 ) })}</>
                                 :<>
                                    {(initialData[1] || initialData[2]) && Object.keys(Obj).map((element) =>{  
                                        if(Obj[element].length===0){
                                            return ""
                                        }
                                    return(
                                        <>
                                                <Row style={{marginBottom:"10px",marginTop:"10px"}}>{element}</Row>
                                                <Form.Item>
                                                                <Radio.Group  onChange={(e) =>{                                                                                                                          
                                                                        setDateId(e.target.value)
                                                                        }}>
                                                                        {
                                                                            Obj[element].map((item) =>{
                                                                                // console.log("element",item)  
                                                                                let values=item.split("_")
                                                                            return(
                                                                                <Radio.Button style={{marginTop:"5px"}} value={values[0]}>{values[1]}</Radio.Button>
                                                                            )
                                                                            })
                                                                        }
                                                                </Radio.Group>
                                                </Form.Item>
                                            </>
                                        )
                                    })}
                                    </>}                                         

                                    <div className={classes.buttonDiv}>
                                                    <Button onClick={() => {
                                                        
                                                        if(interviewModal===false)
                                                        {props.setScheduleModal(false)
                                                     return}
                                                    
                                                   props?.performOperation(operationType.INTERVIEW_MODAL,
                                                    false)
                                                        }}>CANCEL</Button>
                                                    <Button loading={btnLoader} className={classes.schedule} htmlType="submit">SCHEDULE</Button>
                                    </div>
                    </Form>
                    </div>  
            </Modal>
            {slotsModal&&  <Slots slotsModal={slotsModal} setSlotModal={setSlotModal}
                panelistId={initialData[2]}
                setSlot={setSlot} setIsSelected={setIsSelected} setTimeRange={setTimeRange} 
                timeRange={timeRange} jobId={initialData[0]} candidateId={candidateId}/>}
        </>
    )
}
export default Schedule