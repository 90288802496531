import React, { useState,useEffect } from "react";
import classes from './checkSlot.module.css';
import { Modal,DatePicker,Button, Form, Radio, TimePicker ,Row} from "antd";
import { useSelector,useDispatch } from "react-redux";
import Api from "services/Api";
import moment from "moment";
import { adminActions } from "./store";
const Slots = (props) => {
    const [slotData,setSlotData]=useState(new Array(3).fill(''));
    const {scheduleDetails}=useSelector(state=>state.candidateFresher);
    const [toggle,setToggle]=useState(true)
    const [dateId,setDateId]=useState();
    const dispatch=useDispatch()
    const getDisabledHours = () => {
        var hours = [];
        for(var i =0; i < moment().hour(); i++){
            hours.push(i);
        }
        return hours;
    }
    const getDisabledMinutes = (selectedHour) => {
        var minutes= [];
        if (selectedHour === moment().hour()){
            for(var i =0; i < moment().minute(); i++){
                minutes.push(i);
            }
        }
        return minutes;
    }

    useEffect(()=>{
        if(props.jobId &&props.candidateId)
        {     
            Api.get(`/hiring-recruitment/interview-slot?jobInterviewRoundId=${props.jobId}&date=${slotData[0]}&candidateId=${props?.candidateId}`).send(res=>{
                if(res)
                {                          
                    dispatch(adminActions.updateSheduleDetails(res));                   
                }
            })
        }
    },[slotData[0],toggle])

    let oobj={
        Monday:[],
        Tuesday:[],
        Wednesday:[],
        Thursday:[],
        Friday:[],
        Saturday:[]
      }
      const [Obj,setObj]=useState(oobj)
    useEffect(() =>{
        let oobj={
            Monday:[],
            Tuesday:[],
            Wednesday:[],
            Thursday:[],
            Friday:[],
            Saturday:[]
          }
        scheduleDetails.map((elements) =>{
            if(elements?.panelistCopy?.id===props?.panelistId){
          elements.availableSlot.map((element) =>{
          
         
         if(element.weekday==="Monday"){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
           oobj.Monday.push(value)
         }
         if(element?.weekday==="Tuesday"){
             let value=element.id+ "_"+element.startTime+"-"+element?.endTime
          oobj.Tuesday?.push(value)
         }
         if(element?.weekday==="Wednesday"){
             let value=element.id+ "_"+element.startTime+"-"+element?.endTime
             oobj.Wednesday?.push(value)
         }
         if(element?.weekday==="Thursday"){
             let value=element.id+ "_"+element.startTime+"-"+element?.endTime
           oobj.Thursday?.push(value)
         }
         if(element?.weekday==="Friday"){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
           oobj.Friday?.push(value)
         }
         if(element.weekday==="Saturday"){
            let value=element.id+ "_"+element.startTime+"-"+element?.endTime
            oobj.Saturday?.push(value)
         }
        })
        }
        setObj(oobj)
        })   
    },[scheduleDetails,toggle])

    const checkslotHandler=(values)=>{
        const finalValue={
           scheduleId:values.scheduleId
        }
    }

    const addSlotHandler=()=>{
        Api.patch('/hiring-panelist/additionalSchedule').params(
           {
                        panelistId:props?.panelistId,
                        date:slotData[0],
                        startTime:slotData[1] +"pm",
                        endTime: slotData[2] +"pm",          
           }
        ).send(resp=>{
            Api.get(`/hiring-recruitment/interview-slot?jobInterviewRoundId=${props.jobId}&date=${slotData[0]}&candidateId=${props?.candidateId}`).send(res=>{
                if(res)
                {                         
                    dispatch(adminActions.updateSheduleDetails(res));                 
                }
            })
        })
    }

    const panelistId=props?.panelistId;
    const exactScheduleDetails=scheduleDetails.filter( item=>{
        if(item?.panelistCopy?.id===panelistId){
           return  item
        }})
        const schedule=exactScheduleDetails[0]?.availableSlot;
      
    return(
       <Modal visible={props.slotsModal} onCancel={() => props.setSlotModal(false)} footer={false} width="40rem">
                <div className={classes.container}>
                    <h1>Slots</h1>
                            <Form layout="vertical" id="check slot" onFinish={checkslotHandler}>
                                            <Form.Item label="DATE" name="date"> 
                                                            <DatePicker style={{width:"100%"}}  size="middle" disabledDate={(current) =>{return current && current < moment().startOf('day');}}  onChange={(event)=>setSlotData(slotData.map((item,index)=>{
                                                                    if(index===0)
                                                                    {
                                                                        return event.format('YYYY/MM/DD');
                                                                    }
                                                                    else{
                                                                        return item;
                                                                    }
                                                                }))}>
                                                            </DatePicker>
                                            </Form.Item>                              
                                        
                                        { Object.keys(Obj).map((element) =>{
                                                if(Obj[element].length===0){
                                                    return ""
                                                }
                                                return(
                                                    <>
                                                                <b>{element}</b>
                                                                <Form.Item name="scheduleId">
                                                                                    <Radio.Group onChange={(e) =>{                                                                                               
                                                                                    setDateId(e.target.value)
                                                                                    }}>
                                                                                            {
                                                                                            slotData[0]&& Obj[element].map((item) =>{
                                                                                                    let values=item.split("_")
                                                                                                return(
                                                                                                    <Radio.Button value={values[0]}>{values[1]}</Radio.Button>
                                                                                                )
                                                                                                })
                                                                                            }
                                                                                            </Radio.Group>
                                                                </Form.Item>
                                                 </>
                                )
                            })}                                           
                                     
                                <div className={classes.timePicker}>
                                                <Form.Item label="CUSTOM TIME" name="start_time">
                                                                <TimePicker style={{width:"100%"}} format="HH:mm"
                                                                    size="middle"
                                                                    disabledHours={getDisabledHours}
                                                                    disabledMinutes={getDisabledMinutes}
                                                                    onChange={(event)=>setSlotData(slotData.map((item,index)=>{
                                                                                if(index===1)
                                                                                {
                                                                                    return event.format('HH:mm');
                                                                                }
                                                                                else{
                                                                                    return item;
                                                                                }
                                                                            }))}>
                                                                </TimePicker>
                                                </Form.Item>

                                                <Form.Item label="" name="end_time">
                                                                <TimePicker style={{width:"100%"}}  format="HH:mm"
                                                                size="middle"
                                                                onChange={(event)=>setSlotData(slotData.map((item,index)=>{
                                                                        if(index===2)
                                                                        {
                                                                            return event.format('HH:mm');
                                                                        }
                                                                        else{
                                                                            return item;
                                                                        }
                                                                    }))}>        
                                                                </TimePicker>
                                            </Form.Item>
                                </div>
                                            <Button className={classes.addButton} onClick={addSlotHandler}>ADD</Button>
                                            <div className={classes.buttonDiv}>
                                                            <Button onClick={() => props.setSlotModal(false)}>BACK</Button>
                                            </div>
                            </Form>
                </div>
       </Modal>
    )
}
export default Slots