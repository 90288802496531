import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "pages/login/store";
import Webadaptor from "./webadaptor";
import { useParams } from "react-router";
import smileIcon from '../../assets/smileIcon.png';
import Api from "services/Api";
import { candidateActions } from "./store";

const ThankYou = ({ adapter }) => {
  const { score } = useSelector((state) => state.userCandidate.result);
  const {candidateId} = useSelector(state => state.auth)
  const [testMsg,setTestMsg]=useState(null)
  const { instruction } = useSelector((state) => {
    return state.userCandidate;
  });
  const {status}=useParams()
  const dispatch = useDispatch();
 
  console.log("sssResultVaue",score,Object.values(instruction).length)

  useEffect(() => {
    setTimeout(() => {
        dispatch(authActions.removeUser());
    },15000)   
  }, []);

  useEffect(() => {
    Api.get(`/hiring-tests/getTestInstructions?candidateId=${candidateId}`).send((response) => {
      if(response?.show?.message === "Test has expired!"){
        setTestMsg("yes")
      }else{
        dispatch(candidateActions.updateTestInstruction(response));
      }
    });
  }, []);


  return (
    <div style={{height:"100vh",border:"2px solid #ffffff",backgroundColor:"#ffffff"}}>
      <div
        style={{
          fontSize: "30px",
          textAlign: "center",
          justifyContent: "center",
          display:"flex",
          flexDirection:"column",
          margin:"1rem auto",
          flex: "1",
          justifyContent:"center",
          alignItems:"center",
          marginTop:"20%",
        }}
      >
        <div style={{marginBottom:"1rem"}}>
          <img src={smileIcon}></img>
        </div>
        <p>
          <b>Thank You!</b>
        </p>
        {testMsg === "yes" ? ""
        :
        <>
        {
        status === 'true' ? 
        <p>Your test is already submitted</p> : 
        <><p>Your submission has been sent.</p>
        <h1 style={{ color: "blue",fontWeight:"bold",fontSize:"1.8rem" }}>
          Your Score is {score === undefined ? instruction?.candidateScore : score} out of {instruction?.totalMarks}
        </h1></>
        }
        </>}
      </div>
      <p style={{ textAlign: "center", justifyContent: "center", flex: "1" }}>
        <b>We will update you regarding the further process</b>
      </p>
      <p style={{ textAlign: "center", justifyContent: "center", flex: "1" }}>
        <b>Now you can close the Window</b>
      </p>
    </div>
  );
};

export default ThankYou;
