import { Col, Modal, Row, Select } from "antd";
import { Form, Input, InputNumber, Checkbox,Button } from "antd"; 
import React from "react";
import { connect } from "react-redux";
import { PrimaryButton, SecondaryButton } from "services/button";
import { Color } from "services/color";
import { TextFormInput, SelectFormInput } from "services/input";
import { adminInputFieldType, adminOperationType, userFormat, ADMIN_DETAILS } from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import {StarFilled,CloseOutlined} from "@ant-design/icons"
class AdminModal extends React.Component {

  onFinish = () => {
    const { page, pageSize } = this.props;

    this.props.handleAdmin();
    this.props.getAdmins(page, pageSize);
  };

  closeModal = () => {
    this.props.performOperation(adminOperationType.ADMIN_DETAILS_MODAL, false);
    this.props.updateInputField(ADMIN_DETAILS, userFormat);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keyPress);
    this.props.getUserDetails();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
  }

  keyPress = (e) => {
    if (e.keyCode === 13 && this.props.adminDetailsModal === true) {
      this.onFinish()
    }
  }


  render() {
    const { adminDetailsModal, adminDetailsLoader, updateInputField, adminUser, userRole,userDesignations, performOperation, isAddingAdmin,
      userDetailLists } = this.props;

    const isValidPhone = (phone) => {
      const phoneRegex = /^\d{10}$/;
      return phoneRegex.test(phone);
    };
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

     console.log("sssAdminUsers",adminUser)

    return (
      <Modal
        visible={adminDetailsModal}
        title={<div style={{textAlign:"center",marginTop:"10px",marginBottom:"10px",fontWeight:"bold",color:"black",fontSize:"23px"}}>{isAddingAdmin ? "Add User" : "Edit User"}</div>}
        color={Color.blue}
        onOk={this.onFinish}
        onCancel={this.closeModal}
        maskClosable={false}
        width="600px"
        height="800px"
        footer={<div style={{display:"flex",justifyContent:"center",width:"100%"}}>
          {/* // <SecondaryButton onClick={this.closeModal} id="discardAdmin" text="Discard" className="modalButton" size="large" />, */}
          <Button onClick={this.closeModal} style={{width:"10rem",marginRight:"0.5rem",marginBottom:"1rem",borderRadius:"5px"}}>Discard</Button>,
            <Button type="primary"
               disabled={(!isValidPhone(adminUser.phone) && isAddingAdmin) || (!isValidEmail(adminUser.email) && isAddingAdmin) || 
                !adminUser.name || (!adminUser.designation && isAddingAdmin) || adminUser.role.length === 0}
                htmlType="submit"
                className="modalButton" 
                loading={adminDetailsLoader}
                onClick={this.onFinish}
                text={isAddingAdmin ? "Add User" : "Save"}
                id={isAddingAdmin ? "Add User" : "Save"}
                size="medium"
                style={{width:"10rem",borderRadius:"5px",marginBottom:"1rem",marginLeft:"0.5rem"}}
                onKeyDown={(e) => this.keyPress(e)}
            >{isAddingAdmin ? "Add User" : "Save"}</Button>,
        </div>}
      >
        <Form onFinish={this.onFinish} style={{marginBottom:"1.5rem"}}>
          {/* <Row> */}
            {/* <Col span={12}> */}
              {/* <TextFormInput
                required={true}
                text="Enter the Name"
                labelText="Additional Text or Label"
                style={{ marginBottom: "24px" }}
                onChange={(record) => updateInputField(adminInputFieldType.ADMIN_NAME, record.target.value)}
                value={adminUser.name}
              /> */}
            <p> <span style={{fontSize:"1rem",}}>Name<sup><StarFilled style={{color:"red",fontSize:"6px",marginLeft:"5px",marginBottom:"0.6rem"}} /></sup></span></p>
            <Select  required={true}
                text="name"
                name="name"
                // allowClear={true}
                showSearch={true}
                placeholder="Select the name"
                optionFilterProp="children"
                disabled={!isAddingAdmin}
                style={{ marginBottom: "24px",width:"100%" }}
                onChange={(value) => {
                  if(value !== undefined){
                  let nameDetail = userDetailLists.find(item => item.id === value)
                  updateInputField(adminInputFieldType.ADMIN_NAME, nameDetail.name)
                  updateInputField(adminInputFieldType.ADMIN_EMAIL, nameDetail.email)
                  updateInputField(adminInputFieldType.ADMIN_PHONE, nameDetail.phone)
                  updateInputField(adminInputFieldType.ADMIN_DESIGNATION, nameDetail.designation)
                  }
                  // console.log("sssNAmeSelected", nameDetail)
                }}
                value={adminUser.name === '' ? null : adminUser.name}>
                  {userDetailLists.map((element) =>{
                      return (
                        <Select.Option value={element.id} key={element.id}>{element.name}</Select.Option>
                      )
                  })}
          
              </Select>
            {/* </Col> */}
            <Row gutter={24}>
            <Col span={12}>
              <p> <span style={{fontSize:"1rem"}}>Email<sup><StarFilled style={{color:"red",fontSize:"6px",marginLeft:"5px",marginBottom:"0.6rem"}} /></sup></span></p>
              {/* <p style={{marginBottom:"4px"}}>Email <span style={{color:"red"}}>*</span></p> */}
              <Input
                required={true}
                placeholder="Enter the Email"
                text="Email"
                name="email"
                style={{ marginBottom: "24px" }}
                onChange={(record) => updateInputField(adminInputFieldType.ADMIN_EMAIL, record.target.value)}
                value={adminUser.email}
                disabled={!isAddingAdmin || adminUser.email || !adminUser.name || adminUser.email === '' }
              />
            </Col>
            <Col span={12}>
             <p> <span style={{fontSize:"1rem"}}>Phone<sup><StarFilled style={{color:"red",fontSize:"6px",marginLeft:"5px",marginBottom:"0.9rem"}} /></sup></span></p>
              <Input
                text="Phone"
                name="phone"
                type="number"
                // max={10}
                placeHolder={"Enter the Phone Number"}
                style={{ marginBottom: "24px",marginTop:"-37px" }}
                onChange={(record) =>  updateInputField(adminInputFieldType.ADMIN_PHONE, record.target.value)}
                value={adminUser.phone}
                disabled={!isAddingAdmin || adminUser.phone || !adminUser.name || adminUser.phone === ''}
              />
            </Col>
            <Col span={12}>
              <p> <span style={{fontSize:"1rem"}}>Designation<sup><StarFilled style={{color:"red",fontSize:"6px",marginLeft:"5px",marginBottom:"0.6rem"}} /></sup></span></p>
              {/* <SelectFormInput
                required={true}
                placeHolder="Select the Designation"
                dropDownList={userDesignations}
                style={{ marginBottom: "24px" }}
                onChange={(value) => updateInputField(adminInputFieldType.ADMIN_DESIGNATION, value)}
                value={adminUser.designation}
                disabled={!isAddingAdmin || adminUser.designation || !adminUser.name || adminUser.designation === '' || adminUser.designation === null}
              /> */}
              <Select required={true} text="Designation" name="designation"
                allowClear={true}
                showSearch={true}
                placeholder="Select the Designation"
                optionFilterProp="children"
                disabled={true 
                  // || adminUser.designation === '' || adminUser.designation === null
                }
                style={{ marginBottom: "24px",width:"100%" }}
                onChange={(value) => {
                  updateInputField(adminInputFieldType.ADMIN_DESIGNATION, value)
                }}
                value={adminUser.designation === '' ? null : adminUser.designation === null ? "NA" : adminUser.designation}>
                  {userDesignations.map((element) =>{
                      return (
                        <Select.Option value={element} key={element}>{element}</Select.Option>
                      )
                  })}
          
              </Select>
            </Col>
            <Col span={12}>
            <p> <span style={{fontSize:"1rem"}}>Role<sup><StarFilled style={{color:"red",fontSize:"6px",marginLeft:"5px",marginBottom:"0.6rem"}} /></sup></span></p>
              <Select  required={true}
                text="Role"
                name="role"
                mode="multiple"
                allowClear={true}
                showSearch={true}
                placeholder="Select the Role"
                optionFilterProp="children"
                suffixIcon={<CloseOutlined />}
                style={{ marginBottom: "24px",width:"100%" }}
                onChange={(value) => updateInputField(adminInputFieldType.ADMIN_ROLE, value)}
                value={adminUser.role}>
                  {userRole.map((element) =>{
                    if(element==="Recruiter"){
                    return(
                      <Select.Option disabled={adminUser?.role.includes('Admin') ? true:false} value="Recruiter" key="Recruiter">Scheduler</Select.Option>
                    )
                    }else if (element === "Admin" && adminUser?.role.includes("Admin")) {
                      return (
                        <Select.Option value="Admin" key="Admin">Admin</Select.Option>
                      );
                    } 
                    else{
                      return (
                        <Select.Option disabled={adminUser?.role.includes('Admin') && element !== "Panelist" && element !== "HR"  ? true : false} value={element} key={element}>{element}</Select.Option>
                      )
                    }
                  })}
          
              </Select>
              
              
            </Col>
          </Row>
            
        </Form>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminModal);
