import { Button, Input } from "antd";
import { useState } from "react";
import Api from "services/Api";
import mobileIcon from "../../assets/mobileIcon.svg";
import classes from "./OTPModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { candidateActions } from "./store";

const OTPModal = (props) => {
  const { fresherDetails, sessionId } = useSelector(
    (state) => state.canRegistration
  );
  const dispatch = useDispatch();
  const { candidateId, calledFrom, registerId } = props;
  const [OTP, setOTP] = useState(new Array(6).fill(""));

  const changeHandler = (element, index) => {
    if (isNaN(element.value)) {
      return false;
    }
    setOTP([
      ...OTP.map((item, indx) => (index === indx ? element.value : item)),
    ]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  return (
    <div className={classes.container}>
      <h1>Verification</h1>
      <div>
        <img src={mobileIcon} style={{ height: "7rem" }}></img>
      </div>
      <h2>Please enter the OTP to Verify your Mobile Number</h2>
      <span>An OTP has been sent to {" " + props.phone}</span>
      <div className={classes.inputDiv}>
        {OTP.map((item, index) => (
          <input
            type="text"
            maxLength={1}
            key={index}
            onChange={(event) => changeHandler(event.target, index)}
            value={item}
            onFocus={(event) => event.target.select()}
          />
        ))}
      </div>
      <span>Didn't receive OTP?</span>
      <p
        onClick={() => {
            
          dispatch(candidateActions.updateModalOpen(true));
          if (calledFrom === "CANDIDATE_INFO") {
            Api.post("/hiring-candidate/sendotp")
              .params({
                candidateId: candidateId,
                phoneNumber: props.phone,
              })
              .send((response) => {
                if (response) {                
                }
              });
          } else if (calledFrom === "CANDIDATE_UPLOAD") {
            Api.post("/hiring-publicapi/sendotp")
              .params({
                candidateId: candidateId,
                phoneNumber: props.phone,
              })
              .send((response) => {
                if (response) {                
                }
              });
          } else if (calledFrom === "FRESHER_REGISTRATION") {        
            Api.post("/hiring-publicapi/registerSendOtp")
              .params({
                registerId: registerId,
                phoneNumber: props.phone,
              })
              .send((response) => {
                if (response) {                 
                }
              });
          } 
           else if (fresherDetails?.fromCollege === true) {
          Api.post("/hiring-publicapi/sendOtpCollege")
        .params({phoneNumber: mobile })
        .send((response) => {
          dispatch(canRegistrationAction.updateSeesionid(response))                  
        });
           } 
          else if (calledFrom === "EXP_REGISTRATION") {
            Api.post("/hiring-publicapi/registerSendOtp")
              .params({
                registerId: parseInt(registerId),
                phoneNumber: props.phone,
              })
              .send((response) => {
                if (response) {               
                }
              });
          }
        }}
      >
        Resend OTP
      </p>
      <div>
        <Button
        className={classes.confirm}
        style={{color:"#ffffff"}}
         type="primary"
         size="middle"
          onClick={() => {
            if (calledFrom === "CANDIDATE_INFO") {
              Api.post("/hiring-candidate/verifyOtp")
                .params({
                  candidateId: candidateId,
                  otp: parseInt(OTP.join("")),
                })
                .send((response) => {
                  if (response) {              
                    if (response?.show?.type === "success") {
                      dispatch(candidateActions.updateVerifyOTP(true));
                    }
                  } else if(response?.show?.type === "error"){ 
                    dispatch(candidateActions.updateVerifyOTP(false));
                  }
                });
            } else if (calledFrom === "CANDIDATE_UPLOAD") {
              Api.post("/hiring-publicapi/verifyOtp")
                .params({
                  candidateId: candidateId,
                  otp: parseInt(OTP.join("")),
                })
                .send((response) => {                
                  if (response?.show?.type === "success") {
                    dispatch(candidateActions.updateVerifyOTP(true));
                  }
                  else if(response?.show?.type === "error"){ 
                    dispatch(candidateActions.updateVerifyOTP(false));
                  }
                });
            } else if (fresherDetails?.fromCollege === true) {
              Api.post("/hiring-publicapi/verifyOtpCollege")
                .params({
                  sessionId: sessionId?.show?.sessionId?.key,
                  otp: parseInt(OTP.join("")),
                })
                .send((response) => {                  
                  if (response?.show?.type === "success") {
                    dispatch(candidateActions.updateVerifyOTP(true));
                  }
                  else if(response?.show?.type === "error"){ 
                    dispatch(candidateActions.updateVerifyOTP(false));
                  }
                });
            } else if (calledFrom === "FRESHER_REGISTRATION") {              
              Api.post("/hiring-publicapi/registerVerifyOtp")
                .params({
                  registerId: registerId,
                  otp: parseInt(OTP.join("")),
                })
                .send((response) => {                 
                  if (response?.show?.type === "success") {
                    dispatch(candidateActions.updateVerifyOTP(true));
                  }
                  else if(response?.show?.type === "error"){ 
                    dispatch(candidateActions.updateVerifyOTP(false));
                  }
                });
            } else if (calledFrom === "EXP_REGISTRATION") {
              Api.post("/hiring-publicapi/registerVerifyOtp")
                .params({
                  registerId: parseInt(registerId),
                  otp: parseInt(OTP.join("")),
                })
                .send((response) => {
                  if (response?.show?.type === "success") {                   
                    dispatch(candidateActions.updateVerifyOTP(true));
                  }
                  else if(response?.show?.type === "error"){ 
                    dispatch(candidateActions.updateVerifyOTP(false));
                  }
                });
            }
          }}
        >
          <span style={{color:"#ffffff",}}> CONFIRM</span>
         
        </Button>
      </div>
    </div>
  );
};
export default OTPModal;
