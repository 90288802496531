import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import classes from "./addProfileModal.module.css";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Upload,
  Select,
  Modal,
  Dropdown,
  Tooltip,
  Menu,
  List,
  Row,
  Col,
  Typography,Checkbox
} from "antd";
import ProfilView from "./addprofileView";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { adminActions } from "pages/dashboard-home/store";
import { Api, Color, Notification } from "services";
import { RxImage } from "services/reactIcons";
import store from "store";
import "./styles.css";
const AddProfileModal = (props) => {
  const {user}=useSelector((state) => state.auth);
  let candidateJob
  let Jobvalue=useSelector((state) => state.candidate.candidateJobs);
  if(user?.role?.includes("Agency") ||Jobvalue.length>0 ){
    candidateJob=useSelector((state) => state.candidate.candidateJobs);
  }
  else{
     candidateJob  = useSelector((state) => state.candidate.proposeJob);
  }
  const { Option } = Select;
  const [form] = Form.useForm();
  const lable_style = { fontSize: "0.7rem" };
  const [jobLocations, setJobloactions] = useState([]);
  const [finalvalue,setFinal]=useState("")
  const [preferredJobLocation, setpreferredJobLocation] = useState([]);
  const { modalOpen } = useSelector((state) => state.candidateFresher);
  const [imageUrl, setImageUrl] = useState("");
  //const [resumeUrl, setResumeUrl] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [resume, setResume] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [btnLoader,setBtnLoader]=useState(false);
  const [checkValue,setCheckValue]=useState(false);
  const [phoneNumber,setPhoneNumber]=useState("");
  const [emailid,setEmailid]=useState("");

  console.log("ssssPops",props)
useEffect(()=>{
  // let arr=[]
  // let map={}
  // for(let i=0;i<candidateJob?.length;i++)
  // {  
  // arr= arr?.concat(candidateJob?.[i]?.locations)
  // }
  // let arr2=[]
  // for(let i=0;i<arr?.length;i++){
    
  //   if(map[arr[i]?.name]!==true){     
  //   arr2?.push(arr[i])
  //   map[arr[i]?.name]=true
  // }
    
  // }
  
  if(user?.role?.includes("Agency")){
    candidateJob?.map((jobs) => {
      jobs?.id == props?.addDetails?.id ? setJobloactions(jobs.locations) : null;
    });
    setpreferredJobLocation(props?.jobLocn)
  }
  else{
    setpreferredJobLocation(props?.locations)

  }
},[props])
  const handleJob = (item) => {
    candidateJob?.map((jobs) => {
      jobs.id == item ? setJobloactions(jobs.locations) : null;
    });
  };

  const handlePrefferedJL = (item) => {
    // setpreferredJobLocation(item);
  };

  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
      
        setImgFileName(file.name.split(".")[0]);
        setImgFile(file);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const resumeBeforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        
        setResumeFileName(file.name.split(".")[0]);
        setResume(file);
      });
    }
  };
  useEffect(() =>{
    if(resumeFileName===""){
   form.resetFields()
    }
  },[resumeFileName])

  const addProfileHandler = (values) => {
    // setEventValChanged(false)
    
    if(user?.role?.includes("Agency")){
      values.jobId=props.addDetails.id
    }
    if(resume==="")
    {
      Notification.error("Please Upload resume")
      return
    }
    const finalVal = {
      ...values,
      comment: values.comment === undefined ? "" : values.comment,
      photo: imgFile,
      resume: resume,
    };
    if(!finalVal.jobId)
    {
      Notification.error("Job role should not be empty")
      return
    }
    if(values.name.trim() === "")
    {
      Notification.error("Name should not be empty")
      return
    }
    if(values.currentLocation.trim() === "")
    {
      Notification.error("Current Location should not be empty")
      return
    }
    if(values.currentCompany.trim() === "")
    {
      Notification.error("Current Company should not be empty")
      return
    }
    setFinal(finalVal)
    
 const {getAllCandidates}=props
 
 return
 setBtnLoader(true)
    Api.post("/hiring-candidate/addExpCandidate").upload(
      finalVal,
      (percentCompleted, response) => { 
        if (percentCompleted === 100 && response !== undefined) {
          dispatch(adminActions.updateModalOpen(false));
          // setImgFile("");
          // setImageUrl("");       
          // setJobloactions([])
          // setImgFileName("")        
          // setResumeFileName("")
          // form.resetFields();        
          // getAllCandidates("","") 
          //setBtnLoader(false);     
          // setEventValChanged(!eventValChanged);                                    
          // dispatch(authActions.addEvent(finalVal))
         // document.getElementById("profile").reset();                  
        }
        setBtnLoader(false);          
      }
    );
  };

  const menuItem = (title, key) => {
    return (
      <Option key={key} style={{ width: "100%" }} value={key}>
        <Tooltip title={title}>{title}</Tooltip>
      </Option>
    );
  };

  const joblocationMenu = jobLocations?.map((item) =>
    menuItem(item.name, item.id)
  );

  useEffect(()=>{
    form.setFieldsValue({jobId:props?.addDetails?.title});
  },[props])

  // console.log("ssssVAlueeeeeeeeeee",phoneNumber,emailid)

  const checkDuplicateHandlerPhone = (e) =>{
    const phoneNumberValue = e.target.value;
    setPhoneNumber(phoneNumberValue);
    // if (phoneNumberValue.length === 10) {
      Api.post("/hiring-candidate/checkDuplicateCandidate")
       .params({
          phone:phoneNumberValue,
          email:emailid,
       })
      .send((res)=>{
        // console.log("ssssREsponse",res);
        setCheckValue(res?.isDuplicate)
       })
    // }
  }
  const checkDuplicateHandler = (e) =>{
    setEmailid(e.target.value)
    Api.post("/hiring-candidate/checkDuplicateCandidate")
       .params({
          email:e.target.value,
          phone:phoneNumber,
       })
      .send((res)=>{
        // console.log("ssssREsponse",res);
        setCheckValue(res?.isDuplicate)
       })
  }

  return (<>
    <Modal
      bodyStyle={{ height: "85vh" }}
      width="43%"
      centered
      visible={modalOpen}
      className="addCandidate"
      footer={[
        <Button
          form="profile"
          style={{
            width: "8rem",
            height: "2rem",
            borderRadius: "5px",
            marginBottom: "2px",
            background: Color.midGrey,
            fontSize: "0.8rem",
          }}
          onClick={() => {
            dispatch(adminActions.updateModalOpen(false));
            document.getElementById("profile").reset();
            setImageUrl("");
            setImgFile("");
            setImgFileName("");
            setCheckValue(false);
          }}
        >
          CANCEL
        </Button>,

        <Button
          htmlType="submit"
          form="profile"
          key="submit"
          loading={btnLoader}
          style={{
            width: "8rem",
            height: "2rem",
            borderRadius: "5px",
            marginRight: "2rem",
            color: Color.white,
            background: Color.modalblue,
            fontSize: "0.8rem",
          }}
        >
          ADD
        </Button>,
      ]}
      onCancel={() => {
        dispatch(adminActions.updateModalOpen(false));
        setImageUrl("");
        setImgFile("");
        setImgFileName("");
        document.getElementById("profile").reset();
        setCheckValue(false);

      }}
    >
      <div style={{ width: "100%", margin: "0" }}>
        <div className={classes.container}>
          <div className={classes.formHeader}>
            <h1 style={{ fontSize: "1.5rem" }}>Add Candidate Profile</h1>
          </div>

          <Form
            layout="vertical"
            onFinish={addProfileHandler}
            id="profile"
            className={classes.scrollbody}
            form={form}
          >
            <h3 style={{ width: "9ch", fontSize: "1rem" }}>Job Profile</h3>
            <div className={classes.jobRow}>
              <Form.Item
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    JOB ROLE
                  </span>
                }
                name="jobId"
                // rules={[
                //   { required: true, message: "Please select valid input!" },
                // ]}
                className={classes.addProfileForm}
              >
              { props?.addBtnClick ?
                <Input required style={{ height: "2rem", }} />
                :
                <Select
                  showSearch
                  type="text"
                  placeholder="Select a Job Role"
                  bordered={false}
                  className={classes.select}
                  style={{ boxShadow: "none" }}
                  optionFilterProp="children"
                  onChange={(item) => handleJob(item)}
                >
                  {candidateJob?.map((item, i) => {
                    if(item.status==="Active"){
                    return(<Option
                     //key={i} 
                     style={{ width: "100%" }} value={item.id}>
                     {item.title}
                    </Option>)
                    }
                    else{
                      return ""
                    }
                    })}
                </Select>
               }
              </Form.Item>
            </div>
            <div className={classes.jobRow}>
              <Form.Item
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    JOB LOCATION
                  </span>
                }
                name="jobLocation"
                style={{
                  width: "150%",
             
                }}
              >

                {jobLocations.length>0 ? 
                <div style={{border:"1px solid #D3D3D3",height:"1.9rem",display:"flex",flexDirection:"row",alignItems:"center",padding:"0.4rem"}}>
                  <Typography.Text style={{width:"32rem"}} ellipsis={{tooltip:true}}>
                  {jobLocations.map((item, i) =>{
                    if(i<jobLocations.length-1)
                    {
                      return(
                          <span>
                          {item.name + ","}
                          </span>
                      )
                    }
                    else
                      {
                        return(
                            <span>{item.name}</span>
                        )
                      }
                  })}
                 </Typography.Text>
                </div>
                  : 
                  <Select placeholder="Select job location(s)">
                    {/* {jobLocations.map((item,index)=>{
                      return(
                        <Option value={item.id}>{item.name}</Option>
                      )
                    })} */}
                    {joblocationMenu}

                  </Select>
                }
                
                {/* </List> */}
              </Form.Item>
            </div>

            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",}}>
            <h3 style={{ width: "19ch" }}>Personal Information</h3>
            <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",marginRight:"0.3rem"}}>
            <Checkbox style={{color:"#0086FF",pointerEvents: "none"}}
              // disabled
              checked={checkValue}
            >
            </Checkbox>
            <span style={{fontSize:"0.95rem",fontWeight:500}}>Duplicate candidate</span>
            </div>
            </div>
            <div className={classes.jobRow}>
              <Form.Item
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    NAME
                  </span>
                }
                name="name"
                rules={[
                  { required: true, message: "Please enter valid name!" },
                ]}
              >
                <Input
                  type="text "
                  min={0}
                  placeholder="Enter Candidate Name"
                  style={{ height: "2rem" }}
                />
              </Form.Item>

              <Form.Item
                name="phone"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    MOBILE NUMBER
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter valid mobile!",
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: 'Please enter a valid 10-digit mobile number!',
                },
                ]}
              >
                <Input
                  min={0}
                  type={"number"}
                  size="middle"
                  placeholder="Enter mobile number"
                  style={{ height: "2rem" }} 
                  maxlength={10}
                  onChange={checkDuplicateHandlerPhone}
                />
              </Form.Item>
            </div>

            <div className={classes.jobRow}>
              <Form.Item
              
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    EMAIL ID
                  </span>
                }
                name="email"
                rules={[
                  { required: true, message: "Please enter valid email ID!" },
                  {
                    validator: (_, value) => {
                      if (value && value.toLowerCase().includes('@brigosha.com')) {
                        return Promise.reject('Email Id should not contain @brigosha.com!');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder=" Enter email ID"
                  style={{ height: "2rem" }}
                  onChange={checkDuplicateHandler}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    CURRENT LOCATION
                  </span>
                }
                name="currentLocation"
                rules={[
                  { required: true, message: "Please enter valid location!" },
                ]}
              >
                <Input
                  type="text "
                  placeholder="Enter the current location"
                  style={{ height: "2rem" }}
                />
              </Form.Item>
            </div>

            <div className={classes.jobRow}>
              <Form.Item
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    SKYPE ID
                  </span>
                }
                name="skypeId"
                // rules={[
                //   { required: true, message: "Please enter valid skype ID!" },
                // ]}
              >
                <Input
                  type="text "
                  placeholder="Enter skype ID"
                  style={{ height: "2rem" }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    REFFERED BY
                  </span>
                }
                name="refered_by"
                // rules={[
                //   { required: true, message: "Please enter valid input!" },
                // ]}
              >
                <Input
                  type="text "
                  placeholder="Enter refered by"
                  style={{ height: "2rem" }}
                />
              </Form.Item>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <div style={{ flex: "1 1 50%" }}>
                <Form.Item
                  name="photo"
                  label={
                    <span style={lable_style} className={classes.itemHeader}>
                      UPLOAD IMAGE
                    </span>
                  }
                 
                >
                  <div
                    style={{
                      width: "100%",
                      border: " 1.3px solid rgb(215, 211, 211)",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "1rem",
                        marginTop: "0",
                        fontSize: "1.2rem",
                      }}
                    >
                      {imgFileName}
                    </span>
                    <Upload
                  
                      showUploadList={false} //{{ showRemoveIcon: false }}
                      accept=".png,.jpeg,.jpg"
                      beforeUpload={imgbeforeUpload}
                    >
                      <Button
                        style={{ marginRight: "0.3rem" }}
                        icon={
                          <UploadOutlined
                            style={{
                              background: Color.white,
                              border: "none",
                              color: Color.midGrey,
                              fontSize: "1.5rem",
                            }}
                          />
                        }
                        type="text"
                      ></Button>
                    </Upload>
                  </div>
                </Form.Item>
              </div>
              <div
                style={{
                  width: "70px",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: `1px solid ${Color.midGrey}`,
                  borderRadius: "10px",
                }}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    style={{
                      width: "90%",
                      height: "90%",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <RxImage
                    style={{
                      width: "90%",
                      height: "90%",
                      borderRadius: "10px",
                      color: Color.midGrey,
                    }}
                  />
                )}
              </div>
            </div>

            <h3 style={{ width: "16ch" }}>Work Information</h3>
            <h4 style={{ fontWeight: "bold" }}>Experience</h4>
            <div className={classes.jobRow}>
              <Form.Item
                name="experienceYears"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    YEARS
                  </span>
                }
                rules={[
                  { required: true, message: "Please enter experience in years!" },
                ]}
              >
                <Input
                  type={"number"}
                  min={0}
                  placeholder="Enter experience in years"
                  style={{ height: "2rem" }}
                />
              </Form.Item>

              <Form.Item
                name="experienceMonths"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    MONTHS
                  </span>
                }
                rules={[
                  { required: true, message: "Please enter experience in months!" },
                ]}
              >
                <Input
                  type={"number"}
                  min={0}
                  placeholder="Enter experience in months"
                  style={{ height: "2rem" }}
                />
              </Form.Item>
            </div>

            <div className={classes.jobRow}>
              <Form.Item
                name="noticePeriod"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    NOTICE PERIOD(days)
                  </span>
                }
                rules={[
                  { required: true, message: "Please enter notice period !" },
                ]}
              >
                <Input
                  type={"number"}
                  min={0}
                  placeholder="Enter notice period"
                  style={{ height: "2rem" }}
                />
              </Form.Item>
              <Form.Item
                name="preferredLocation"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    PREFERRED LOCATION
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter preferred location!",
                  },
                ]}
              >
                <Select
                  type="text"
                  placeholder="Enter preferred location"
                  className={classes.preferredLoc}
                  //  size="large"
                  style={{ boxShadow: "none",fontSize:"1rem" }}
                  // className={classes.select}
                  bordered={false}
                  onChange={(item) => handlePrefferedJL(item)}
                >
                  {preferredJobLocation?.map((item) => (
                    <Option style={{ width: "100%" }} value={item.id}>
                      <span key={item?.name}>{item?.name}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={classes.jobRow}>
              <Form.Item
               rules={[
                { required: true, message: "Please enter current CTC!" },
              ]}
                name="currentCtc"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    CURRENT CTC
                  </span>
                }
              >
                <Input
                  type={"number"}
                  min={0}
                  placeholder="Enter current CTC"
                  style={{ height: "2rem" }}
                />
              </Form.Item>
              <Form.Item
               rules={[
                { required: true, message: "Please enter Expected Ctc!" },
              ]}
                name="expectedCtc"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    EXPECTED CTC
                  </span>
                }
              >
                <Input
                  type={"number"}
                  min={0}
                  placeholder="Enter expected CTC"
                  style={{ height: "2rem" }}
                />
              </Form.Item>
            </div>
            <div className={classes.curCom}>
              <Form.Item
                name="currentCompany"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    CURRENT COMPANY
                  </span>
                }
                rules={[
                  { required: true, message: "Please enter current company!" },
                ]}
              >
                <Input
                  style={{ height: "2rem" }}
                  placeholder="Enter current company's name"
                />
              </Form.Item>

              <Form.Item
                name="resume"
                label={
                  <span style={lable_style} className={classes.itemHeader}>
                    UPLOAD RESUME
                  </span>
                }
                className={classes.headerDiv}
                // required
                rules={[
                  {
                    required: true,
                    message: "Please upload the valid resume!",
                  },
                ]}
              >
                <div
                  style={{
                    width: "100%",
                    border: " 1.3px solid rgb(215, 211, 211)",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 1rem",
                  }}
                >
                  {resumeFileName ? (
                    <span
                      style={{
                        marginTop: "5px",
                        color: Color.black,
                        fontSize: "0.9rem",
                      }}
                    >
                      {resumeFileName}
                    </span>
                  ) : (
                    <span
                      style={{
                        marginTop: "5px",
                        color: Color.midGrey,
                        fontSize: "0.9rem",
                      }}
                    >
                      Upload Candidate Resume
                    </span>
                  )}

                  <Upload
                    name="resume"
                    showUploadList={false}
                    beforeUpload={resumeBeforeUpload}
                    accept=".pdf"
                    style={{
                      width: "100%",
                      border: "2px solid red",
                    }}
                  >
                    <Button
                      icon={<UploadOutlined style={{ fontSize: "1rem" }} />}
                      type="text"
                      style={{ width: "100%" }}
                      bordered={false}
                    />
                  </Upload>
                </div>
              </Form.Item>
             
            </div>
            <Form.Item name="comment" label="Agency Comment">
                <Input placeholder="Enter the Comment"/>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
    {finalvalue.jobId?<ProfilView finalVal={finalvalue} prevdata={props}
     setImgFile={setImgFile}
     setImageUrl={setImageUrl}    
     setJobloactions={setJobloactions}
     setImgFileName={setImgFileName}      
     setResumeFileName={setResumeFileName}
     jobLocations={jobLocations}
     preferredJobLocation={preferredJobLocation}
     candidateJob={candidateJob}
     />:""}</>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AddProfileModal);

