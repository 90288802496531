import classes from './feedbackItem.module.css';
import { useState,useEffect } from "react";
import { Typography } from 'antd';
import moment from "moment";
import Api from "services/Api";


const FeedbackItem=(props)=>{

    const [loader, setLoader] = useState(false)
    const item=props?.item;

    let dates = moment(new Date(item?.interviewDate)).format('YYYY-MM-DD')



    return(
        <div className={classes.container}>
          
                        <h3>{item?.jobTitle?item?.jobTitle:"NA"}</h3>
                        <h4>CANDIDATE NAME:</h4>
                        <span>{item?.candidateName?item?.candidateName:"NA"}</span>
                        <h4>CONDUCTED ON</h4>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"90%",}}>
                                <span>
                                        {item?.date ? item?.date:"NA"}, {item?.startTime} - {item?.endTime}
                                </span>
                        </Typography.Text>

                         <h4>ROUND:</h4>
                        <span>{item?.roundType && item?.roundType === "Relationship" ? "HR Relationship" 
                        : item?.roundType ? item?.roundType
                        :"NA"}</span>
                        <h4>FEEDBACK LINK:</h4> 
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"70%",}}>                     
                                    <span>

                                        <a href={item?.feedbacklink?item?.feedbacklink:"NA"}>{item?.feedbacklink?item?.feedbacklink:"NA"}</a>
                                    </span>
                        </Typography.Text>
                            
        </div>
    )
}
export default FeedbackItem;