import { SwapOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Dropdown, Input, Row } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import settings from "../assets/settings.png";
import { jdFresherActions } from "pages/jd-dashboard-fresher/store";
import { adminActions } from "pages/dashboard-home/store";
import { jdActions } from "pages/jd-dashboard/store";
import { useState } from "react";
import classes from './dragAndDrop.module.css';
import searchIcon from '../assets/searchIcon.png'

const DragAndDrop=(props)=>{
    const itemList=props?.itemList?props?.itemList:[];
    const setItemList=props?.setItemList;
    const selectCol=props?.selectCol;
    const setSelectCol=props?.setSelectCol;
    const tableType=props?.tableType;
    const dispatch=useDispatch();
    const [visible, setVisible] = useState(false);
    const [search,setSearch]=useState('');
    const handleVisibleChange = (flag) => {
        setVisible(flag);
      };
    const onColumnChange = (e, value) => {  
        if (e.target.checked === true) 
        {     
            setSelectCol((oldArray) => [...oldArray,  value ]);
        }
         else if (e.target.checked === false) 
         {
              let indexed;
             let valued= selectCol.filter((item, index) => {
                if (item.key !== value.key) {
                  return true
                } 
              }); 
            
              setSelectCol(valued)      
              // if(selectCol.length===1)
              //   setSelectCol([])
              // }
              // else
              // {
              //     selectCol.splice(indexed, indexed);      
              //     setSelectCol(selectCol);
              // }
            
        }      
      };
    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination)
         return;
        var updatedList = [...itemList];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);    
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setItemList(updatedList);
      };
    const columnMenu = () => (
        <Card>
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container">
              {(provided) => (
                <>
                 <Input  bordered={false}  placeholder="Search" type="text" 
                    onChange={(event)=>{
                    setSearch(event.target.value.toUpperCase());                  
                   }}
                  style={{marginBottom:"1rem",border:"1.7px solid grey",borderRadius:"1rem",padding:" 0.4 0.5rem"}} suffix={<div ><img src={searchIcon} style={{width:"15px", height:"15px"}}></img></div>} />
                <div
                      className={classes.container}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      >  
                      <div style={{display:"flex",flexDirection:"row",gap:"0.5rem"}}>
                      <input type="checkbox"    onChange={(e) => onColumnChange(e,{key:"All"})} style={{fontWeight:"600"}} />
                        <span>ALL</span>
                      </div>
                        
                        
                        <hr class="solid"></hr>
                          {search&&itemList?.filter(it=>it.title.includes(search))?.map((item, index) =>  
                          (<Draggable key={item.key} draggableId={item.key} index={index}>
                              {(provided) => (
                                <div
                                  className="item-container"
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <Row>
                                    <Col span={22} style={{display:"flex",flexDirection:"row",gap:"0.5rem"}}>                         
                                      <input
                                      type="checkbox"
                                        onChange={(e) => onColumnChange(e, item)}
                                        className="name"
                                        checked={ selectCol!=="" ? selectCol?.find(element => element.title===item.title):false}
                                      />                                                                           
                                        <span>{item.title}</span>                                     
                                    </Col>
                                    <Col>
                                      {/* <SwapOutlined /> */}
                                    </Col>
                                  </Row>
                                  <hr class="solid"></hr>
                                </div>
                              )}
                            </Draggable>)
                            )}
                          {!search&&itemList?.map((item, index) =>  
                          (<Draggable key={item.key} draggableId={item.key} index={index}>
                              {(provided) => (
                                <div
                                  className="item-container"
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <Row>
                                    <Col span={22} style={{display:"flex",flexDirection:"row",gap:"0.5rem"}}>                             
                                      <input
                                        type="checkbox"
                                        onChange={(e) => onColumnChange(e, item)}
                                        className="name"
                                        checked={ selectCol!=="" ? selectCol?.find(element => element.title===item.title):false}
                                      />
                                       <span>{item.title}</span>
                                     
                                    </Col>
                                    <Col>
                                      {/* <SwapOutlined /> */}
                                    </Col>
                                  </Row>
                                  <hr class="solid"></hr>
                                </div>
                              )}
                            </Draggable>)
                            )}
                        {provided.placeholder}
                </div>
                </>
              )}
            </Droppable>
          </DragDropContext>
            <div className={classes.buttonDiv}>
            <Button onClick={()=> setVisible(false)}>CANCEL</Button>
            <Button
              onClick={() => {
                let found=false
                if(selectCol!==""){
                found=selectCol?.find(element => element.key==="All")}             
                if(found || selectCol==="" || selectCol?.length===0 )
                {                  
                    if(tableType==='candidateFresher')
                    {
                        dispatch(adminActions.updateColumns(itemList))
                    }
                    else if(tableType==='jdFresher')
                    {
                        dispatch(jdFresherActions.updateFresherMappedColumn(itemList));
                    }
                    else if(tableType==='jdExperienced')
                    {
                        dispatch(jdActions.updateExperienceMappedColumn(itemList));
                    }
                    setVisible(false);               
                  return
                }              
                let map = {};
                let selectedCol = [];
                if(selectCol.length!=0){
                selectCol?.forEach((item) => {
                  map[item.key] = true;
                });          
                itemList.map((item) => {
                  if (map[item.key] === true) {
                    return selectedCol.push(item);
                  }
                });
              }            
                if(tableType==='candidateFresher')
                {
                    dispatch(adminActions.updateColumns(selectedCol))
                }
                else if(tableType==='jdFresher')
                 {
                     dispatch(jdFresherActions.updateFresherMappedColumn(selectedCol));
                  }
                  else if(tableType==='jdExperienced')
                  {
                    dispatch(jdActions.updateExperienceMappedColumn(selectedCol));
                   }
                  setVisible(false);             
              }}
              className={classes.apply}
            >
              APPLY
            </Button>
            </div>
        </Card>
      );

    return(
        <div>
            <Dropdown overlay={columnMenu} trigger={['click']} onVisibleChange={()=>{setVisible(prev=>!prev)}} visible={visible} placement="bottom">
                      <img src={settings}  style={{height:"25px"}}/>
             </Dropdown>
        </div>
    )
}
export default DragAndDrop;