import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   
    scheduleList:[], 
    finalValue: "",
};

const interviewSlice = createSlice({
  name: "scheduleInterview",
  initialState,
  
  reducers: {
    updateSchedule(state, action) {
      state.scheduleList = action.payload;
    },
    updateFinalValues(state, action) {
      state.finalValue = action.payload;
    }
  },
});

export default interviewSlice.reducer;

export const interviewActions = interviewSlice.actions;
