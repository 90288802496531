import React, { useEffect, useState } from "react";
import { Typography, Input, Card, Button, Select, Checkbox, Spin } from "antd";
import { BgColorsOutlined, SearchOutlined,WarningOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { feedbackActions } from "./store";
import CreateFeedbackModal from "./createFeedbackModal";
import {Notification} from "services"
import { Api, InformationModal } from "services";
import { Color } from "services";

import classes from "./FeedBackManagement.module.css";
import CheckableTag from "antd/lib/tag/CheckableTag";
import feedbackSvg from "./asset/Feedback-5.svg";
import feedbackStar from "./asset/Feedback-rafiki-2.svg";
import onlineReview from "./asset/Online Review-cuate.svg";
import cards from "./asset/Group_16955.svg";

const image = require("../../assets/Feedback-rafiki-2.png").default;
const alterImg1 = require("./asset/1.png").default;
const alterImg2 = require("./asset/2.png").default;
const { Title } = Typography;
const { Meta } = Card;
const { Option } = Select;
const navBtnStyle = {
  width: "8rem",
  fontWeight: "bold",
  borderRadius: "0.2rem",
  color: Color.white,
  background: Color.modalblue,
};

function FeedBackManagement() {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardtitle, setCardTitle] = useState("");
  const [feedbacksquestions, setFeedbacksQuestions] = useState([]);
  const [selectcard, setSelectCard] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState([1]);
  // const [selectcardclone, setSelectClone] = useState();
  const [cardChecked, setcardChecked] = useState([]);

  const dispatch = useDispatch();
  const feedbackquestions = useSelector((state) => state?.feedbacks?.lists);
  const feedbackData = useSelector((state) => state?.feedbacks?.feedbackData);
  const feedbackMetaType = useSelector(
    (state) => state?.feedbacks?.feedbackMetaType
  );
  const [feedbackType, setfeedbackType] = useState(feedbackMetaType
    );


  const { items } = feedbackquestions;

  useEffect(() => {
    if (items) {
      const updated = [1].concat(items);
      setFeedbackContent(updated);
    }
  }, [items]);


  useEffect(() => {
    dispatch(feedbackActions.updateSelectedCardCount(selectcard.length));
  }, [selectcard]);

  useEffect(() => {
    dispatch(feedbackActions.updateSelectedCardCount(selectcard.length));
  }, []);

  useEffect(() => {
    dispatch(feedbackActions.updateFeedbackState(feedbackType));
  }, [feedbackType]);

  useEffect(() => {
    fetchCard();
  }, [searchedText,feedbackType]);

  const showModal = () => {
    dispatch(feedbackActions.updateCreateFeedbackModal(true));
    dispatch(feedbackActions.updateFeedbackData(null));
  };

  const onSearch = (e) => {
    setSearchedText(e.target.value);
  };

  const onPanelistChange = () => {
    setfeedbackType("Panelist");
    dispatch(feedbackActions.updateFeedbackState(feedbackType));
    //fetchCard("Panelist");
  };
  const onCandidateChange = () => {
    setfeedbackType("Candidate");
    dispatch(feedbackActions.updateFeedbackState(feedbackType));
   // fetchCard("Candidate");
  };

  const fetchCard = () => {
    setLoader(true)
    Api.get(`/hiring-feedback/getfeedbackQuestions`)
      .params({
        search: searchedText,
        feedbackMetaType: feedbackType,
      })
      .send((response) => {
        if (typeof response != "undefined") {
       
          setFeedbacksQuestions(response);
          dispatch(feedbackActions.updateFeedbacksQuestions(response));
          setLoader(false);
        } else {
          setError("No data Found");
        }
      });
  };

  const Deleteapi = () => {
    setLoader(true)
    Api.delete("/hiring-feedback/deleteFeedbakMeta", { ids: selectcard, }).send(
      (response) => {
        if (typeof response != "undefined" && response?.show?.type === "success") {
          setFeedbacksQuestions(response);
          dispatch(feedbackActions.updateFeedbacksQuestions(response));
          setSelectCard([]);
          setcardChecked([]);
          setLoader(false);
          if(searchedText === ""){
            fetchCard();
          }
          setSearchedText("");
        }
        else{
          setLoader(false);
        }
      }
    );
  };

  const confirmDelete = () => {
    InformationModal.confirm(
      
      <div><WarningOutlined style={{ fontSize: "3em", color: Color.red,display:"flex",justifyContent:"center" }} /></div>,
      <div style={{ textAlign: "center" }}>
        Are you sure you want to delete?
      </div>,
      "",
      () => Deleteapi(),
      (close) => {
        close();
      }
    );
  };

  return (
    <div>
      <div className={classes.header}>
        <h1>Feedback Management</h1>
      </div>
      <div className={classes.container}>
        <div className={classes.navBar}>
          {selectcard.length > 0 ? (
            <div style={{ width: "25rem", display: "flex", gap: "1rem" }}>
              <Button
                style={{
                  ...navBtnStyle,
                  color: Color.modalblue,
                  background: Color.white,
                  border: `1px solid ${Color.modalblue}`,
                }}
                onClick={() => {
                  if(selectcard.length>1){
                    Notification.error("Error","Cannot Edit Multiple cards")
                    return
                  }
                  history.push({
                    pathname: "/fbround",
                    state: {
                      id:selectcard[0],
                    },
                  });
                }}
              >
                Edit
              </Button>
            </div>
          ) : (
            <div style={{ width: "25rem", display: "flex", gap: "1rem" }}>
              <Button
                style={
                  feedbackType === "Panelist"
                    ? {
                        ...navBtnStyle,
                        color: Color.white,
                        background: Color.modalblue,
                      }
                    : {
                        ...navBtnStyle,
                        color: Color.black,
                        background: Color.midGrey,
                      }
                }
                onClick={onPanelistChange}
              >
                Panelist
              </Button>
              <Button
                style={
                  feedbackType === "Candidate"
                    ? {
                        ...navBtnStyle,
                        color: Color.white,
                        background: Color.modalblue,
                      }
                    : {
                        ...navBtnStyle,
                        color: Color.black,
                        background: Color.midGrey,
                      }
                }
                onClick={onCandidateChange}
              >
                Candidate
              </Button>
            </div>
          )}
          <div>
            {selectcard.length > 0 ? (
              <div
                style={{
                  width: "15rem",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Button
                  type="primary"
                  style={navBtnStyle}
                  onClick={() => {
                    if (selectcard.length > 1) {
                      Notification.error("select only one feedback for clone","")
                    } else {
                      showModal();
                    }
                  }}
                >
                  CLONE
                </Button>
                <Button
                  onClick={confirmDelete}
                  style={{
                    ...navBtnStyle,
                    color: Color.red,
                    background: Color.white,
                    border: `1px solid ${Color.red}`,
                  }}
                >
                  DELETE
                </Button>
              </div>
            ) : (
              <Input
                placeholder="Search"
                allowClear={true}
                prefix={<SearchOutlined />}
                onChange={onSearch}
                className={classes.searchDiv}
              />
            )}
          </div>
          {/* NavBar ends here */}
        </div>
        <div style={{position:'relative'}} className={classes.innerContainer}>
          {loader && (
            <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background:Color.midGrey,
              position:'absolute',
              opacity:'50%'
            }}
          >
            <Spin />
          </div>
          )}
          {items?.length === 0 ? (
            <div className={classes.firstPageCard}>
              <h1>Feedback Form</h1>
              <div
                style={{
                  width: "100%",
                  // height: "50vh",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <img
                  src={feedbackType === "Panelist" ? feedbackSvg : onlineReview}
                  style={{ marginLeft: "3rem", width: "20rem", height: "45vh" }}
                />
                <div
                  style={{
                    marginRight: "3rem",
                    width: "20rem",
                    height: "45vh",
                    borderRadius: "10px",
                    border: `1px solid ${Color.modalblue}`,
                  }}
                >
                  <img
                    src={feedbackStar}
                    style={{ width: "7rem", marginTop: "1rem", height: "7rem" }}
                  />
                  <div
                    style={{
                      marginTop: "1rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3 style={{ fontWeight: "900" }}>Create Feedback</h3>
                    <p
                      style={{
                        width: "15rem",
                        fontSize: "0.7rem",
                        textAlign: "center",
                        color: "#6F6F6F",
                      }}
                    >
                      Create and save perfect feedback form for every occasion.
                    </p>
                    <Button
                      style={{
                        width: "7.5rem",
                        margin: "1rem",
                        background: Color.modalblue,
                        borderRadius: "4px",
                        color: Color.white,
                        fontWeight: "700",
                      }}
                      onClick={showModal}
                    >
                      CREATE
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.cardContainer}>
              {feedbackContent?.map((item, index) => {
                if (index === 0) {
                  return (
                    <div className={classes.creatFeedbackCard} key={index}>
                      <div>
                        <img
                          style={{
                            marginBottom: "3rem",
                            width: "5rem",
                            height: "4.5rem",
                          }}
                          src={image}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h3 style={{ fontWeight: "700" }}>Create Feedback</h3>
                        <span
                          style={{
                            color: Color.darkGrey,
                            textAlign: "center",
                            fontSize: "0.8rem",
                            margin: "0 1rem",
                          }}
                        >
                          Create and save perfect feedback form for every
                          occasion
                        </span>
                      </div>
                      <div style={{ marginTop: "0.5rem" }}>
                        <Button
                          type="primary"
                          style={{ width: "8rem", fontWeight: "600" }}
                          onClick={showModal}
                        >
                          CREATE
                        </Button>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className={classes.feedbackDetailsCard} key={index} >
                    <div
                      style={{
                        margin: "0.5rem",
                        backgroundColor: index % 2 == 0 ? "#F0FAF1" : "#FFF0F0",
                      }}
                    >
                      <div>
                        <Checkbox
                         checked={index===cardChecked.find(element=>element===index)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setcardChecked([...cardChecked,index]);
                              setSelectCard([...selectcard, item?.id]);
                              // setSelectClone(item?.id);
                              let selectesCards=[...selectcard, item?.id]
                              dispatch(
                                feedbackActions.updateSelectedCards(selectesCards)
                              );
                            } else {
                              setcardChecked(cardChecked.filter(element=>element!==index))
                              setSelectCard(
                                selectcard.filter((a) => a !== item?.id)
                              );
                              dispatch(
                                feedbackActions.updateSelectedCards(selectcard.filter((a) => a !== item?.id))
                              );
                              // setSelectClone(
                              //   selectcard.filter((a) => a !== item?.id)
                              // );
                            }
                          }}
                        ></Checkbox>
                      </div>
                      <div style={{cursor:"pointer"}} onClick={() => {
                    history.push({
                      pathname: "/fbround",
                      state: {
                        id: item?.id,
                      },
                    });
                  }}>
                        <img
                          src={index % 2 === 0 ? alterImg2 : alterImg1}
                          style={{
                            width: "25vh",
                            height: index % 2 === 0 ? "25vh" : "20vh",
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                      <Button
                        type="text"
                        style={{ padding: "0" }}
                        onChange={(value) => {
                          setCardTitle(value);
                        }}
                       onClick={() => {
                          history.push({
                            pathname: "/fbround",
                            state: {
                              id: item?.id,
                            },
                          });
                        }}
                        
                      >
                        <Typography.Text style={{width: "300px",fontSize:"1.2rem",fontWeight:"bold"}} ellipsis={{tooltip:true}}>{item?.title}</Typography.Text> 
                      </Button>
                      <span
                        style={{
                          fontSize: "0.65rem",
                          // fontWeight: "600",
                          color: Color.darkGrey,
                        }}
                      >
                        Round Type - {item?.roundType}
                      </span>
                      <div
                        style={{
                          width: "95%",
                          height: "8vh",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            marginRight: "0.5rem",
                          }}
                          src={cards}
                        ></img>
                        <span style={{ fontSize: "0.7rem" }}>
                          {item?.questionCount} Questions
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          
        </div>
      </div>
      <CreateFeedbackModal fetchCard={fetchCard} setcardChecked={setcardChecked} setSelectCard={setSelectCard} />
    </div>
  );
}
export default FeedBackManagement;
