import React,{useState,useEffect} from "react";
import { Table,Button,Spin} from "antd";
 import classes from './home.module.css';
import './override.scss';
import { LocAndDeptActions } from "./store";
import { useDispatch } from "react-redux";
import { Api } from "services";
import AddAndEditLoc from "./addAndEditLoc/addAndEditLoc";

const Location = (props) =>{
  const dispatch=useDispatch();
  const [datas,setData] = useState([])
  const loader=props?.loader;
  const setLoader=props?.setLoader;
  const searchValue=props?.searchVal;
  // const [pageChange, setPageChange] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  

  const getApi= () =>{
    setLoader(true);
    let par={page:props?.pageChange,
      limit:props?.pageSize,  
    }
    if(searchValue)
    {
      par= {search:searchValue,}
    }
    Api.get(`/hiring-job/locations`)
    .params(par)
    .send(response =>{
      if(response)
      {
        setLoader(false);
        setData(response);
      }
    })
  }
  

  useEffect(() =>{
   getApi();
   dispatch(LocAndDeptActions.udateEditLocnDetails({getApi}))
  },[props?.pageChange,props?.pageSize,searchValue])
   

      const columns = [
        {
          title:<h4 className={classes.title}>NAME</h4>,
          dataIndex: 'name',
          key: 'name',
          render: (text) => <div style={{color:"#1890FF"}}>{text}</div>,
        },
        {
            title:<h4 className={classes.title}>PINCODE</h4>,
            dataIndex: 'pinCode',
            key: 'pinCode',
          },
        
          {
            title: <h4 className={classes.title}>ACTION</h4>,
            key: 'action',
            render: (_, record) => (
              <div className={classes.editBtn}>
                <Button type="primary" onClick={()=>{      dispatch(LocAndDeptActions.updateSearch(searchValue))
                                            dispatch(LocAndDeptActions.udateEditLocnDetails({...record,getApi}))
                                            dispatch(LocAndDeptActions.updateEditLocModal(true))
                                        }} >Edit</Button>
              </div>
            ),
          },    
      ];


   
    return(
        <>
            <Table columns={columns} dataSource={datas?.items} scroll={{y:450,x:1000}}
               loading={loader}
               pagination={{
                showSizeChanger: true,
                pageSizeOptions: [10,20, 30, 50, 100],
                pageSize: datas?.meta?.itemsPerPage,                
                onChange: (page,pageSize) =>{
                    props?.setPageChange(page)
                    props?.setPageSize(pageSize)
                },
                onShowSizeChange: (page,pageSize) =>{
                    props?.setPageChange(page)
                    props?.setPageSize(pageSize)
                },
                total: datas?.meta?.totalItems,
                current: parseInt(datas?.meta?.currentPage),
              }}
            
            /> 
            < AddAndEditLoc pageSize={props.pageSize}  pageChange={props.pageChange} setPageChange={props.setPageChange} loader={props.loader} setLoader={props.setLoader} searchVal={props.searchVal} getApi={getApi}/>
        </>
    )
}
export default Location