export default class Constants{

    // static sortingFields = [{"key": "10th", label : "10th Marks" , selected : false},
    // {"key": "12th", label : "12th Marks" , selected : false},
    // {"key": "cgpa", label : "College CGPA", selected : false},
    // {"key": "maxBacklogs", label : "Max Backlogs", selected : false},
    // {"key": "score", label : "Hackathon Score", selected : false}
    // ]

    constructor(){
        this.sortingFields = [
                {"key": "class_10", label : "10th Class" , selected : false ,sortKey : "class_10th"},
                {"key": "class_12th", label : "12th Class" , selected : false ,sortKey : "class_12th"},
                {"key": "experience", label : "CGPA" , selected : false, sortKey : "cgpa"},
                // {"key": "none", label : "None" , selected : false ,sortKey : ""},
                {"key": "vacancies", label : "vacancies" , selected : false ,sortKey : "vacancies"}, 
                {"key": "title", label : "Job title" , selected : false ,sortKey : "title"}
        ]
        this.filterFields = [
            {title : "Experience", value : "experience"},
            {title : "No of Openings", value : "vacancies"},
            {title : "Notice Period", value : "noticePeriod"},
            {title : "Priority", value : "priority"},
            {title : "Organizational Department", value : "orgtype"},
            {title : "location", value : "location"},
            {title : "Hackathon score", value : "score"},
            {title : "Maxbacklogs", value : "backlogs"},
            {title : "CGPA" , value : "cgpa"},
            {title : "DEPARTMENT(S) (BACHELOR)", value : "departments"}
          ]

        this.filterStatus = {
           "departments":false,
           "experience":false,
           "noticePeriod":false,
            "xii" : false,
            "cgpa" : false,
            "backlogs" : false,
            "eligibility" : false,
            "mainStatus" : false,
            "orgtype" :false,
            "location" : false,
            "priority" : false,
            "institute" : false,
            "score" : false
          }

          this.sortStatus = {
            "x" : {selected : false, order : true},
            "xii" : {selected : false, order : true},
            "cgpa" : {selected : false, order : true},
            "backlogs" : {selected : false, order : true},
            "score" : {selected : false, order : true}
          }
    }


}