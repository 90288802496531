import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import { Button, List, Popover, Radio, Typography } from "antd";
import { useDispatch } from "react-redux";
import sort from "../assets/sort.png";
import { useState } from "react";
const { Title } = Typography;
const Sorter=(props)=>{
    const [sortPopoverFlag,setSortPopoverFlag] = useState(false);
    const getFunc=props?.getFunc;
    const activeFilterList=props?.activeFilterList;
    const sortFieldList=props?.sortFieldList;
    const setSortOrderlist=props?.setSortOrderlist;
    const sortCandidate=props?.sortCandidate;
    const sortType=props?.sortType;
    const setSortType=props?.setSortType;
    const customPopover = (type = null, data = null, logo) => {
    let  contentBody = <div className="sort-popover" style={{maxHeight:"40vh",overflowY:"auto",padding:"1rem"}}>    
              <div style={{marginBottom:"1rem",display:"flex",justifyContent:"space-between",fontSize:"1rem"}}>
                        <span className="sort-heading-css">Sort</span>
                         <Button  style={{width:"5rem",borderRadius:"4px",fontWeight:"600",backgroundColor:"#0086FF",color:"#ffffff",border:"1px solid #0086FF"}}
                         onClick={()=>{                                              
                        let array=  sortFieldList.map((element) =>{
                             if(element.selected===true)
                             {
                                element["selected"]=false
                             }
                             return element
                          })
                          setSortOrderlist(array)
                          setSortPopoverFlag(false);
                          setTimeout(() =>{
                            getFunc();
                          },1000
                          )
                         }}
                         >RESET</Button>
                </div>   
                <hr />
              <div>       
                    <div style={{marginBottom:"1rem"}}>
                    <div style={{marginBottom:"-0.5rem"}}><span className="sort-heading-css">Sort Order</span></div><br />
                                <Title style={{fontSize : "15px", display : "inline",marginTop:"1rem"}}>ASCENDING</Title>
                                <Radio
                                        value={true} style={{float : "right"}} checked={sortType} 
                                        onClick={()=>{
                                        setSortType(true)
                                        }}>                      
                                </Radio>
                    </div>
                    <div style={{borderBottom:"2px solid grey",paddingBottom:"0.5rem"}}>
                            <Title style={{fontSize : "15px", display : "inline"}}>DESCENDING</Title> 
                            <Radio 
                                        value={false} style={{float : "right"}} 
                                        checked={!sortType}
                                        onClick={()=>{
                                            setSortType(false)
                                        }}
                                        >
                            </Radio>
                    </div>
              </div>
              <div></div>
              <div  style={{marginTop:"0.5rem",marginBottom:"-0.5rem"}}><span className="sort-heading-css">Sort By</span></div>
              <ul className="sorting-list-css" >
                {
                        sortFieldList.map((item, index) =>
                            <li key={index}
                                 style={{fontWeight:"600",fontSize:"1rem"}}
                                    className={item.selected ? "select-sorting-menu" : ""}
                                    onClick={() => {
                                        let temp = sortFieldList.slice();
                                        temp.map((e,i) => {
                                                if(index == i)
                                                {
                                                    e.selected = true;
                                                } 
                                                else
                                                {
                                                    e.selected = false;
                                                }
                                        })
                                        setSortOrderlist(temp);
                                    }}
                            >{item.label} <span className="float-right"> <span className={!item.selected ? "check-icon" : ""}><CheckCircleTwoTone/></span></span></li>)
                  }
                  </ul>              
                    <div className="sort-action">
                                <Button  onClick={() => setSortPopoverFlag(false)} style={{width:"5rem",borderRadius:"4px",fontWeight:"700",color:"red",border:"1.5px solid red"}}>CANCEL</Button>
                                <Button className="float-right ok" 
                                onClick={() => {
                                    sortCandidate();
                                    setSortPopoverFlag(false);
                                }} style={{width:"5rem",fontSize:"14px",borderRadius:"4px",fontWeight:"700",border:"1.5px solid #0086FF"}}>OK</Button>
                    </div>
            </div>  
            return <Popover
                    placement="bottom"
                    content={contentBody}
                    trigger="click"
                    visible={sortPopoverFlag}
                    onVisibleChange={() => {
                        if(sortPopoverFlag)
                        {
                                setSortPopoverFlag(false)
                        }
                        else
                        {
                            setSortPopoverFlag(true)
                        }}}
                    >
                         {logo}
            </Popover>   
      }
    return(
        <div>
                    {customPopover("sort", null, <img src={sort} style={{height:"25px"}}/>)}
        </div>
    )
}
export default Sorter;