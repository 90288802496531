class Filter{
    constructor(){
        this.vacancies=false
        this.location = false;
        this.priority = false;
        this.experience=false
        this.noticePeriod=false;
        this.orgtype=false;
        this.expmin = 0;
        this.expmax = 0;
        this.npmin=0;
        this.npmax=0;       
    }
    setXMin(value){
        this.expmin = value
    }
    setXiiMin(value){
        this.expmax = value
    }

    
   

  
 

    resetFitler(){
        this.vacancies=false
        this.noticePeriod=false;
        this.location = false;
        this.priority = false;
        this.experience=false;
        this.orgtype=false
        this.expmin = 0;
        this.expmax = 0;
        this.npmin=0;
        this.npmax=0;
       
    }

}

export default Filter;