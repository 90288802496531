import React from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { operationType } from "./redux/action";

class ViewImage extends React.Component {
  onFinish = () => {
    this.props.performOperation(operationType.CANDIDATE_IMAGE_VIEW_MODAL, false);
  };
  render() {
    const { candidateImageViewModal, candidateImage } = this.props;
    return (
      <Modal
        visible={candidateImageViewModal}
        className="view-pdf"
        closable={false}
        onOk={this.onFinish}
        onCancel={this.onFinish}
        footer={null}
      >
        <div>
          <img src={candidateImage} className="view-pdf-iframe" />
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewImage);