import React, { Component } from "react";
import { Button, Input } from "antd";
import "./styles.css";
import { DeleteOutlined, EditOutlined, DownloadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Color } from "services";

const {Search} = Input

export const PrimaryButton = ({ onClick, text, icon, style, loading, htmlType,
   size, shape, className, disabled, onKeyDown, id }) => {
  return (
    <Button
      // className={className === undefined ? "primaryButton" : `primaryButton ${className}`}
      // style={{borderRadius:"2px",width:"10rem"}}
      type="primary"
      key={id}
      htmlType={htmlType}
      // icon={<PlusCircleOutlined style={{fontSize:"10px"}} size="small"/>}
      id={id}
      // shape={shape}
      size={size}
      loading={loading}
      style={style}
      onClick={onClick}
      disabled={disabled}
      onKeyDown={onKeyDown}
    >
      {text}
    </Button>
  );
};

export const SecondaryButton = ({ onClick, text, icon, style, loading, htmlType, 
  size, shape, className, disabled, onKeyDown, id }) => {
  return (
    <Button
      className={className === undefined ? "secondaryButton" : `secondaryButton ${className}`}
      htmlType={htmlType}
      icon={icon}
      id={id}
      shape={shape}
      key={id}
      size={size}
      loading={loading}
      style={style}
      onClick={onClick}
      disabled={disabled}
      onKeyDown={onKeyDown}
    >
      {text}
    </Button>
  );
};

export const SearchBar = ({ placeholder, onSearch, style, value }) => {
  return (
    <Search
      type="text"
      className="search-bar"
      style={style}
      placeholder={placeholder}
      onSearch={onSearch}
      value={value}
      enterButton
      // allowClear
    />
  );
};

export const AddPrimaryButton = ({ text, onClick, id, style }) => {
  const buttonStyle = style !== undefined ? style : {fontSize: "1rem"}
  return (
    <PrimaryButton
      // icon={<PlusCircleOutlined style={{ fontSize: "0.6em", color: Color.white, verticalAlign: "middle"}} />}
      text={text}
      id={id}
      key={id}
      onClick={onClick}
      style={buttonStyle}
    />
  );
};

export const IconButton = ({ icon, onClick, style, className, size, disabled, id }) => {
  return (
    <Button 
      icon={icon} 
      onClick={onClick} 
      size={size}
      shape="circle" 
      type="primary" 
      className={className} 
      style={style} 
      key={id}
      id={id}
      disabled={disabled}
    >
    </Button>
  );
};

export const EditIconButton = ({ onClick, style, id, size, disabled }) => {
  return (
    <IconButton 
      icon={<EditOutlined />} 
      onClick={onClick} 
      style={style} 
      className="editIcon" 
      size={size}
      id={id}
      key={id}
      disabled={disabled}
    >
    </IconButton>
  )
};

export const DeleteIconButton = ({ onClick, style, id, size, disabled }) => {
  return (
    <IconButton 
      icon={<DeleteOutlined />} 
      onClick={onClick} 
      style={style} 
      className="deleteIcon" 
      id={id}
      key={id}
      size={size}
      disabled={disabled}
    >
    </IconButton>
  )
};

export const DownloadIconButton = ({ onClick, style, id, size }) => {
  return (
    <IconButton 
      icon={<DownloadOutlined />} 
      onClick={onClick} 
      style={style} 
      className="editIcon" 
      id={id}
      key={id}
      size={size}
    >
    </IconButton>
  )
};
