import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Upload,
} from "antd";
import Api from "services/Api";
import { adminActions } from "./store";
import { useDispatch, useSelector } from "react-redux";
import { Simulate } from "react-dom/test-utils";
import {
  LoadingOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Notification } from "services";
const { Option } = Select;
const { Title } = Typography;

const FillForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formValue, setformValue] = useState({});
  const dispatch = useDispatch();
  const [btnLoader, setBtnLoader] = useState(false);
  const [candidateInitalData, setCandidateInitialData] = useState();
  const { jdList } = useSelector((state) => state?.jd);
  const [resumeFile, setresumeFile] = useState("");
  const [jobId, setJobId] = useState("");
  const [jobValue, setJobValue] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [resumeUrl, setResumeUrl] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [resume, setResume] = useState("");
  const [touched,setTouched]=useState(false);
  const [validTitle,setValidTitle]=useState(false);
  const [touched2,setTouched2]=useState(false);
  const [validTitle2,setValidTitle2]=useState(false);
  const [touched3,setTouched3]=useState(false);
  const [validTitle3,setValidTitle3]=useState(false);

  const {user} = store.getState().auth

  let object;


  useEffect(() => {
    if (props?.updateData) {
      setImageUrl(props?.updateData?.photo)
      setResumeUrl(props?.updateData?.resume)
      setCandidateInitialData(props?.updateData);
      object = {
        name: props?.updateData?.name,
        collegeName: props?.updateData?.collegeName,
        email: props?.updateData?.email,
        phone: props.updateData.phone,
        Xth: String(props.updateData.x),
        XIIth: String(props.updateData.xii),
        cgpa: String(props.updateData.cgpa),
        jobId: props.updateData.job.title,
        backlogs: String(props.updateData.backlogs),
        department: props.updateData.department,
      };
    }
    setformValue(object);
  }, []);
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
        setImgFileName(file.name.split(".")[0]);
        setImgFile(file);
      });
    }
  };

  const resumeBeforeUpload = (file) => {
    const isJpgOrPng =
     
      file.type === "application/docs" ||
      file.type === "application/pdf";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload pdf/docs file!");
    } else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setResumeUrl(url);
        setResumeFileName(file.name.split(".")[0]);
        setResume(file);
      });
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  if (props.fillModal == true) {
    setTimeout(() => {
      setIsModalVisible(true);
    }, 500);
  }
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    if (props?.fillModal) {
      props?.setFillModal(false);
    }
    setIsModalVisible(false);
  };
  const onFinish = (values) => {
    if(values.collegeName.trim() === ""){
      Notification.error("Institute's name should not be empty")
      return
    }
    else{
    if (jobValue === true) {
      // delete values.jobId; 
      values.jobId = parseInt(jobId);
    }
    else{
      values.jobId = props.updateData.job.id;
    }
    const XIIth = parseFloat(values.XIIth);
    const Xth = parseFloat(values.Xth);
    const cgpa = parseFloat(values.cgpa);
    const phone = values.phone;
    values.backlogs = parseInt(values.backlogs);
    const value1 = {
      ...values,
      phone: phone,
      XIIth: XIIth,
      Xth: Xth,
      cgpa: cgpa,
      photo: imgFile,
      resume: resume,
    };
    setBtnLoader(true);
    if (props.fillModal !== undefined) {
      Api.patch(`/hiring-candidate/updateCandidate/${props.updateData.id}`)
      .uploadAndPatch(
        value1,
        (percentageComplete, response) => {
           if(percentageComplete===100){
            setIsModalVisible(false);
            props?.refreshApi()
           }
          if (percentageComplete === 100 && response !== undefined) {
            setIsModalVisible(false);
           setformValue("")
           props.setFillModal(false)
            form.resetFields();
            setImgFile("");
            setImageUrl("");
            setResumeUrl("");
            setBtnLoader(false);
            props?.refreshApi()
          }
        }
      );
    } else {

      Api.post("/hiring-candidate/addCandidate").upload(
        value1,
        (percentageComplete, response) => {
          if (percentageComplete === 100 && response !== undefined) {
            setIsModalVisible(false);
         
            props.refresh();
            form.resetFields();
            setImgFile("");
            setImageUrl("");
            setResumeUrl("")
            setBtnLoader(false);
            props?.refreshApi()
          }
        }
      );
   
      props.refresh();
      props?.refreshApi()
      form.resetFields();
    }
  }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Photo
      </div>
    </div>
  );
  return (
    <div>
      {props.fillModal === true ? (
        ""
      ) : (
        <Button
          onClick={showModal}
          style={{ borderColor: "#4DAAFF", color: "#4DAAFF",backgroundColor:"#E6F3FF",height:"2.2rem",width:"7rem",borderRadius:"5px" }}
        >
          <b>FILL FORM</b>
        </Button>
      )}
      <Modal
        width="45%"
        centered
        visible={isModalVisible}
        bodyStyle={{ height: "67vh" }}
        onOk={handleOk}
        onCancel={handleCancel}
        // className="candidate-modal"
        title={
          <div
            style={{
              textAlign: "center",
              font: "bold 26px / 30px Open Sans",
              color: "#161616",
              margin: "0.4rem 0 0.2rem 0",
            }}
          >
            {candidateInitalData != null ? "Edit a " : "Add a "}
            Candidate
          </div>
        }
        footer={[
          <Button
            form="fresherForm"
            onClick={handleCancel}
            style={{ width: "10rem",marginBottom:"1rem",textAlign:"center"}}
          >
            Cancel
          </Button>,
          <Button
            form="fresherForm"
            type="primary"
            htmlType="submit"
            style={{ width: "10rem" }}
            loading={btnLoader}
          >
            {candidateInitalData != null ? "Edit or Update" : "ADD"}
            
          </Button>,
        ]}
      >
        <Form
          id="fresherForm"
          form={form}
          initialValues={formValue}
          onFinish={onFinish}
          layout={"vertical"}
          scrollToFirstError
          // initialValues={}
          className="create-modal-scroll"
        >
          <div
          style={{
            width: "100%",
            height: "5rem",
            display: "flex",
            marginBottom: "0.4rem",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
          }}
        > 
          <Form.Item 
           name="photo"
           rules={user?.role.includes("Admin")|| user?.role.includes("Super-Admin") ? "" : imageUrl === "" ?[
            {
              required: true,
              message:"please upload photo",
            },
          ]:""}
          >
          <Upload
            name="avatar"
            accept=".png, .jpg, .jpeg,"
            listType="picture-card"
            className="candidate-avatar-uploader"
            showUploadList={false}
            beforeUpload={imgbeforeUpload}
          >
            {imageUrl ? (
              <img
                src={imageUrl !== "" ? imageUrl : ""}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
          </Form.Item>

          <Form.Item 
           name="resume"
           rules={user?.role.includes("Admin")|| user?.role.includes("Super-Admin") ? "":resumeUrl ? "":[
            {
              required: true,
              message:"please upload resume!",
            },
          ]}
          >
         <Upload
            name="resume"
            showUploadList={false}
            beforeUpload={resumeBeforeUpload}
            accept=".docs, .pdf"
            style={{
              width: "100%",
              border: "2px solid red",
            }}
          >
            <Button
              icon={<UploadOutlined />}
              style={{ backgroundColor: "#a65d47", color: "white" }}
            >
             {resumeUrl ? "Change" : "Attach"} Resume
            </Button>
           
          </Upload>

          <br></br>
            {resumeUrl ? 
             <a
              // style={{ display: "table-cell",}}
              onClick={() => {window.open(resumeUrl)}}
              target="_blank"
             >
              <FilePdfOutlined style={{ fontSize: "0.9rem",marginLeft:"0.5rem",}} />
              <span
                style={{
                  fontSize: "0.8rem",
                  color: "black",
                  marginLeft: "0.3rem",
                  // marginTop:"0.5rem",
                  color:"#0086FF"
                }}
               >
                Click to view Resume
              </span>
              </a>
              :""
             }
          </Form.Item>
          </div>
          <Form.Item
            name="jobId"
            label="JOB VACANCY"
            // className="form-heading-css"
            placeholder=" Enter Job"
            rules={[
              {
                required: true,
                message:"please select job",
                whitespace: true,
              },
            ]}
          >
          <Select
            showSearch
            type="text"
            placeholder="Please Select Job"
            optionFilterProp="children"
            // style={{ boxShadow: "none" }}
            // bordered={false}
            onChange={(value) => {
                setJobValue(true)
                setJobId(value);
              }}
            >
              {jdList.map((item) => {
                return <Option value={String(item.id)}>{item.title}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label="CANDIDATE NAME"
            className="form-heading-css"
            placeholder=" Enter candidate name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
                whitespace: true,
              },
            ]}
          >
            <Input 
            placeholder="Enter Candidate Name"
            type="text" 
            onChange={(event)=>{
            setTouched(true)
            let tempString=event.target.value;
            let validFlag = ((tempString.charAt(0) === tempString.charAt(0).toUpperCase())) ? true : false;
          let validFlag2=true;
          for(let i=0; i<tempString.length; i++)
          {
            if(tempString[i]!==' ')
          {
                if(tempString[i].toUpperCase() === tempString[i].toLowerCase())
                {
                  validFlag2=false;
                  break;
                }

          }}
          setValidTitle(validFlag&&validFlag2);

           }}/>
          </Form.Item>
          {!validTitle&&touched&&<p style={{color:"red",textAlign:"left",marginTop:"-13px"}}>(Candidate Name should contains letters only, should start with uppercase letter)</p>}
          <Form.Item
            name="collegeName"
            label="INSTITUTE'S NAME"
            className="form-heading-css"
            placeholder=" Enter college name"
            rules={[
              {
                required: true,
                message: "Please input your college name!",
              },
            ]}
          >
            <Input 
             placeholder="Enter Institute's Name"
             type="text"
             onChange={(event)=>{
            setTouched2(true)
            let tempString2=event.target.value;
            let validFlag3 = ((tempString2.charAt(0) === tempString2.charAt(0).toUpperCase())) ? true : false;
          let validFlag4=true;
          for(let i=0; i<tempString2.length; i++)
          {
            if(tempString2[i]!==' ')
          {
                if(tempString2[i].toUpperCase() === tempString2[i].toLowerCase())
                {
                  validFlag4=false;
                  break;
                }

          }}
          setValidTitle2(validFlag3&&validFlag4);

           }}/>
          </Form.Item>
          {!validTitle2&&touched2&&<p style={{color:"red",textAlign:"left",marginTop:"-13px"}}>(Institute Name should contains letters only, should start with uppercase letter)</p>}
          <Row>
            <Col span={11}>
              <Form.Item
                name="phone"
                label="Phone Number"
                min={0}
                minLength={10}
                maxLength={10}
                placeholder=" Enter mobile number"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    min: 10,
                    message: "phone num should not be less than 10 digit",
                  },
                  {
                    max: 10,
                    message: "phone num should not be more than 10 digit",
                  },
                ]}
              >
                <Input
                placeholder="Please enter phone number"
                  type={"number"}
                  minLength={10}
                  maxLength={10}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <Form.Item
                name="email"
                label="EMAIL ID"
                className="form-heading-css"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.toLowerCase().includes('@brigosha.com')) {
                        return Promise.reject('Email Id should not contain @brigosha.com!');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Please Enter Email" />
              </Form.Item>
            </Col>
          </Row>

          <div>
            <Title
              style={{
                margin: "10px 0 20px 0",
                fontFamily: "Open Sans",
                fontSize: "15p",
                color: "#161616",
              }}
              level={5}
            >
              Academic Information
            </Title>
          </div>

          <Row>
            <Col span={24}>
              <Form.Item
                name="department"
                label="DEPARTMENT"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "",
                    whitespace: true,
                  },
                ]}
              >
                <Input required={true} 
                  placeholder="Enter Department"
                  type="text"
                  onChange={(event)=>{
                    setTouched3(true)
                    let tempString3=event.target.value;
                    let valid = ((tempString3.charAt(0) === tempString3.charAt(0).toUpperCase())) ? true : false;
                  let valid2=true;
                  for(let i=0; i<tempString3.length; i++)
                  {
                    if(tempString3[i]!==' ')
                  {
                        if(tempString3[i].toUpperCase() === tempString3[i].toLowerCase())
                        {
                          valid2=false;
                          break;
                        }

                  }}
                  setValidTitle3(valid&&valid2);

                  }}/>
              </Form.Item>
              {!validTitle3&&touched3&&<p style={{color:"red",textAlign:"left",marginTop:"-13px"}}>(Department should contains letters only, should start with uppercase letter)</p>}
            </Col>
          </Row>

          <Row>
            <Col span={11}>
              <Form.Item
                name="cgpa"
                label="CGPA"
                min={0}
                max={10}
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please input CGPA",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Please input CGPA" type={"number"} required={true} max={10} min={0}/>
              </Form.Item>
            </Col>

            <Col span={12} offset={1}>
              <Form.Item
                name="backlogs"
                label="BACKLOGS"
                min={0}
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please enter backlogs",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Please input backlogs" type={"number"} min={0} max={50}/>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={11}>
              <Form.Item
                name="Xth"
                min={0}
                max={100}
                label="10TH PERCENTAGE"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please input 10th percentage",
                    whitespace: true,
                  },
                ]}
              >
                <Input type={"number"} placeholder="Please input 10th percentage" required={true} max={100} min={0}/>
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <Form.Item
                name="XIIth"
                min={0}
                max={100}
                label="12TH PERCENTAGE"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please input 12th percentage",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Please input 12th percentage" type={"number"} required={true} max={100} min={0}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default FillForm;
