import React, { useState } from "react";
import { Card,Form, Input,Row,Col,Radio, DatePicker,Button } from "antd";
import { mapDispatchToProps, mapStateToProps } from "../redux/container"
import { connect,useDispatch,useSelector } from "react-redux";
import { HrFeedbackActions } from "./store";
import {ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
const FinancialDetails = (props) => {
    const dispatch= useDispatch()
    const { basicInfo } = useSelector((state) => state.HrFeedback)
    const { feedback } = useSelector((state) => state.candidate.feedbackCandidateInfo)
   let feedbackType = feedback.find((value) => value.roundType === "HR")
    const { feedbackCandidate, updateInputField, calculateCompletion, feedbackStatus, maritalStatusList, automotiveBackground } = props;
    const onFInish=(values) => {
              const value = {
                 ...values,
                  'Hike': values['Hike']?.format('YYYY-MM-DD'),
                  
              }
              props.onNext("")
              dispatch(HrFeedbackActions.updatefeedback(value))
           
    }
    const goBack = () => {
        props.onBack("LocationDetails")
    } 

    
    return(
        <>
        <Card title="Financial Details"style={{border:"black",width: "100%"}}>
        <Button type="text" onClick={goBack}><ArrowLeftOutlined /></Button>
            <Form onFinish={onFInish} layout="vertical">
                <Row><Col span={12}>
                    <Form.Item
                    name="CurrentCTC"
                    label={<b>Current CTC</b>}
                   initialValue={feedbackType?.details?.CurrentCTC !==undefined ?  feedbackType?.details?.CurrentCTC  : basicInfo?.CurrentCTC}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col>
                    <Col span={11} offset={1}> 
                    <Form.Item
                    name="ExpectedCTC"
                    label={<b>Expected CTC</b>}
                    initialValue={feedbackType?.details?.ExpectedCTC !==undefined && !basicInfo?.ExpectedCTC ? feedbackType?.details?.ExpectedCTC :  basicInfo?.ExpectedCTC}
                    
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                            name="Hike"
                            label={<b>Last Hike</b>}
                            initialValue={feedbackType?.details?.Hike!=undefined ? moment(feedbackType?.details?.Hike,"YYYY-MM-DD") : basicInfo?.Hike ? moment(basicInfo?.Hike,"YYYY-MM-DD"):null}
                           
                             >
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>
                    </Col>
                    
                    
                        <Col span={11} offset={1}>
                            <Form.Item
                              name="NoticePeriod"
                            label={<b>Notice Period</b>}
                            initialValue={feedbackType?.details?.NoticePeriod !=undefined && !basicInfo?.NoticePeriod? feedbackType?.details?.NoticePeriod: basicInfo?.NoticePeriod}
                          
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Col></Row>
                   
                    <Row justify="end" marginTop="10px"><Button size="large" style={{paddingLeft:"50px",paddingRight:"50px"}} type="primary" htmlType="submit">Save And Next</Button></Row>
                    
            </Form>
      
    </Card>
    
    </>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(FinancialDetails)