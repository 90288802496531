import React, { useState } from "react";
import { Tabs,Typography,Row, Input, Button } from "antd";
import './override.scss';
import classes from './home.module.css';
const { TabPane } = Tabs;
const {Title} = Typography
import Department from "./department";
import Location from "./location";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { LocAndDeptActions } from "./store";
import AddAndEditLoc from "./addAndEditLoc/addAndEditLoc";
import AddAndEditDept from "./addAndEditDept/addAndEditDept";

const LocationAndDepartment = () =>{
    const dispatch=useDispatch();
    const [loader,setLoader]=useState(true);
    const [tabType,setTabType]=useState("1");
    const [searchVal,setSearchVal]=useState("");
    const [pageChange, setPageChange] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageChange2, setPageChange2] = useState(1);
    const [pageSize2, setPageSize2] = useState(10);

   
    return(
        <>
                    <div className={classes.containerHome}> 
                    <h1 className={classes.header} >Location And Department</h1>       
                    <div style={{marginTop:"-0.9rem",}}>  
                                <Tabs
                                                defaultActiveKey="1"
                                                onChange={(key)=>setTabType(key)}
                                                tabBarExtraContent={
                                                    <div className={classes.tabContent}>
                                                                <div>
                                                                        <Input type="text" placeholder=' Search' allowClear={true} 
                                                                           onChange={(event)=>{
                                                                            
                                                                            setSearchVal(event.target.value)
                                                                          }} 
                                                                        prefix={<SearchOutlined className="custom-search-icon" style={{ Color:"#0086FF", }} />}
                                                                        ></Input>
                                                                </div>

                                                               {tabType==="1"&&<Button style={{borderRadius:"5px",fontWeight:"600",width:"rem"}}
                                                               type="primary"
                                                                onClick={()=>{
                                                                    dispatch(LocAndDeptActions.updateAddLocModal(true))

                                                                }}>Add Location</Button>}
                                                               {tabType==="2"&& <Button style={{borderRadius:"5px",fontWeight:"600",width:"rem"}}
                                                                  type="primary"
                                                               onClick={()=>{
                                                                    dispatch(LocAndDeptActions.updateAddDeptModal(true))
                                                                    dispatch(LocAndDeptActions.updateDeptName(""))
                                                                }}>Add Department</Button>}                          
                                                    </div>
                                                }
                                                >
                                            <TabPane tab="Location" key="1">
                                            <Location loader={loader} setLoader={setLoader} searchVal={searchVal} setSearchVal={setSearchVal}
                                             pageChange={pageChange} 
                                             setPageChange={setPageChange} 
                                             pageSize={pageSize} 
                                             setPageSize={setPageSize}/></TabPane>
                                            <TabPane tab="Department"  key="2">
                                            <Department 
                                            loader={loader} 
                                            setLoader={setLoader} 
                                            searchVal={searchVal} 
                                            setSearchVal={setSearchVal}
                                            pageChange={pageChange2} 
                                             setPageChange={setPageChange2} 
                                             pageSize={pageSize2} 
                                             setPageSize={setPageSize2}/></TabPane>       
                                </Tabs>
                                {/* < AddAndEditLoc pageSize={pageSize}  pageChange={pageChange} setPageChange={setPageChange} loader={loader} setLoader={setLoader} searchVal={searchVal}/> */}
                                <AddAndEditDept pageSize={pageSize2}  pageChange={pageChange2} setPageChange={setPageChange2}loader={loader} setLoader={setLoader} searchVal={searchVal}/>    
                    </div> 
                    </div>      
        </>
    )
}
export default LocationAndDepartment