import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button,Row,Col } from "antd";
import { SHOW_DOWNLOAD_CANDIDATE_MODEL } from "./redux/action";
import { DownloadOutlined } from "@ant-design/icons";
import { Api } from "services";
import Loader from "../../components/Loader";
import axios from "axios";

import "./styles.css"

const DownloadCandidateModal = () => {
  const { showDownloadCandidateModal, candidatePageMeta, getCandidateUrl,getCandidateParams } =
    useSelector((state) => state.candidate);

  const dispatch = useDispatch();

  const [pages, setPages] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const noOfPage = Math.floor(candidatePageMeta?.totalItems / 300) + 1;
    const temp = [];
    for (let i = 1; i <= noOfPage; i++) {
      temp.push(i);
    }
    setPages(temp);
  }, [showDownloadCandidateModal]);

  const handleDownload = (pages) => {
    // const url = `${getCandidateUrl}&download=true&downloadPart=${pages}`;
    const url = `${getCandidateUrl}`;
    const params ={...getCandidateParams,download:true,downloadPart:pages}
    // const params={download:true,downloadPart:pages}
    setShowLoader(true);
    Api.post(url).params(params).send((response, error) => {
      if (response !== undefined) {
       
        window.open(response, "_blank");
      }
      setShowLoader(false);
    });
  };

  const handleCancel = () => {
    dispatch({ type: SHOW_DOWNLOAD_CANDIDATE_MODEL, action: false });
  };

  const content =
    pages.length > 0
      ? pages.map((page) => {
        return (
            <Col span={8} >
            <div key={page} style={{ borderBottom: "5px" }}>
              <Button
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
                size={"medium"}
                onClick={() => handleDownload(page)}
                key={page}
              >
                Download Part {page}
              </Button>
            </div></Col>
          );
        })
      : null;

  return (
    <>
      <Loader visible={showLoader} />
      <Modal
        title="Download data"
        visible={showDownloadCandidateModal}
        onCancel={handleCancel}
        footer={false}
        centered
        // className="downloadModal"x
      >
        <Row style={{paddingBottom:"30px"}}>{content}</Row>
      </Modal>
    </>
  );
};

export default DownloadCandidateModal;
