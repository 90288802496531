import classes from './thankYou.module.css';
import smileIcon from '../../assets/smileIcon.png'

const Thanks=()=>{
    return(
        <div className={classes.container}>
                    <div className={classes.wrapper}>
                                <img src={smileIcon}></img>
                                <h1>Thank you for response ! </h1>
                                <span>We will get back to you shortly.</span>
                                <div>
                                        <span>For more info please visit{" "}</span>
                                        <a href='https://www.brigosha.com/'>Brigosha.com</a>
                                </div>
                                
                    </div>
        </div>
    )

}

export default Thanks;