import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Select,Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { message, Upload } from "antd";
import infoIcon from "../../assets/infoIcon.svg";
import selectIcon from "../../assets/selectIcon.svg";
import {
  LoadingOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import "./style.css";
import FillForm from "./fill-form";
import name from "../../assets/name.png";
import mobile from "../../assets/mobile.png";
import institute from "../../assets/institut.png";
import jobV from "../../assets/job-vacancy.png";
import email from "../../assets/email-id.png";
import resume from "../../assets/resume.png";
import { useSelector } from "react-redux";
import Api from "services/Api";
import SendEmailModal from "./sendEmailModal";
import classes from "./candidateProfile.module.css";
import { getDefaultNormalizer } from "@testing-library/react";
import cancelIcon from '../../assets/cancelIcon.svg';
import selectedIcon from '../../assets/selectedIcon.svg';
import UploadIcon from '../../assets/UploadIcon.svg';
import { useDispatch } from "react-redux";
import { adminActions } from "./store";
import AddScoreModal from "./addScoreModal";
import uploadIcon2 from "../../assets/uploadIcon2.svg";
import imageIcon from "../../assets/imageIcon2.svg";
import profileIcon from "../../assets/darkProfileIcon.svg";
import arrowIcon from "../../assets/leftArrowIcon.svg";
import ChangeStatusPopover from "./changeStatusPopover";
// let stausprocess = [
//   { name: "Eligibility Criteria ", value: 0 },
//   { name: "Hackathon", value: 1 },
//   { name: "Round 1", value: 2 },
//   { name: "Round 2", value: 3 },
//   { name: "Round 3", value: 4 },
//   { name: "Round 4", value: 5 },
// ];
const { Option } = Select;
const CandidateProfile = (props) => {
  const [updateData, setUpdatedata] = useState("");
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [colorStatus, setColor] = useState("Eligibility criteria");
  const [roundData, setRoundData] = useState({});
  let location = useLocation();
  let history = useHistory();
  const [fillModal, setFillModal] = useState(false);
  const [isSendEmailMailVisible, setSendMailVisible] = useState(false);
  const [overallStatus, setOverallStatus] = useState("");
  const [data, setData] = useState("");
  const datas = location?.state?.data;
  const {user} = useSelector(state =>state.auth);
  // const columns=location.state.mappedColumns
  const { statuses } = useSelector((state) => state.candidateFresher);
  const [tabClick, setTabClick] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [statusVal, setStatusVal] = useState("");
  const [roundStatus, setRoundStatus] = useState([
    { status: ["Eligible", "Not Eligible"] },
    { status: ["Passed", "Failed", "New"] },
  ]);
  const dispatch = useDispatch();
  const roundInfo = data?.interviewSlots;
  const testInfo = data?.test;
  useEffect(() => {
    setStatus(statuses.EligibilityStatus);
  }, []);
  const getApi = () => {
    Api.get(`/hiring-candidate/getCandidateProfile?candidateId=${datas.id}`).send(
      (response) => {
        setData(response?.candidate);
        setLoading(false);
        setRoundData({ round: response?.rounddata });

        setRoundStatus((prev) => [...prev, ...response?.roundStatus]);
      }
    );
  };

  useEffect(() => {
    getApi();
  }, []);
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
    return false;
  };
  const statusChangeApi = (type, value) => {
    if (type === "EligibilityStatus") {
      Api.patch(`/hiring-candidate/updateEligibility/${data.id}?status=${value}`).send(
        (response) =>{

        }
      );
    } else if (type === "Hackathon") {
      Api.patch(`/hiring-candidate/updateHackathon/${data.id}?status=${value}`).send(
        (response) => {

        }
      );
    } else {
      if (value === "Skip Current Round") {
        Api.patch(`/hiring-recruitment/skipround/${data.id}`)
          .params({})
          .send((response) => {

          });
        return;
      }

      Api.patch(`/hiring-recruitment/status/${data.id}`)
        .params({
          status: value,
        })
        .send((response) =>{

        });
    }
  };
  const statusChange = (type, value) => {
    setStatusVal(value);
    if (!value) {
      return;
    }
    if (type === "Eligibility criteria") {
      type = "EligibilityStatus";
    } else if (type === "Hackahon") {
      type = "HackathonStatus";
    }
    statusChangeApi(type, value);
  };

  const statusChange2 = (type, value) => {
    if (!value) {
      return;
    }
    if (type === "Eligibility criteria") {
      type = "EligibilityStatus";
    } else if (type === "Hackahon") {
      type = "HackathonStatus";
    }
    statusChangeApi(type, value);
  };
  let arr = [
    {
      keys: "Department",
      info: data?.job?.departments!==null ?data?.job?.departments.map((element) => {
        return <p>{element}</p>;
      }):"",
      candInfo: data?.department,
    },
    {
      keys: "College CGPA/Percentage",
      info: data?.job?.cgpa,
      candInfo: data?.cgpa,
    },
    {
      keys: "12th CGPA/Percentage",
      info: data?.job?.class_12th,
      candInfo: data?.xii,
    },
    {
      keys: "10th CGPA/Percentage",
      info: data?.job?.class_10th,
      candInfo: data?.x,
    },
    {
      keys: "Backlogs",
      info: data?.job?.maxBackLogs,
      candInfo: data?.backlogs,
    },
  ];
  let statusArr;
  statusArr = statuses.EligibilityStatus;
  const onRoundsClick = (e) => {
    setColor(e);

    if (e === "Eligibility criteria") {
      setStatus(statuses.EligibilityStatus);
    } else if (e === "Hackathon") {
      setStatus(statuses.HackathonStatus);
    } else {
      setStatus(statuses.statusDropdown);
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const roundClickHandler = (element, index) => {
    onRoundsClick(element);
    setTabClick(
      tabClick.map((item, ind) => {
        if (ind === index) {
          return true;
        }
        return false;
      })
    );
  };

  const roundContent = (round) => {
    return (
      <div className={classes.round}>
        {roundInfo.map(
          (item, index) =>
            item.round === round && (
              <div
                className={
                  item.status === "Cancelled"
                    ? `${classes.content} ${classes.conBack}`
                    : `${classes.content}`
                }
                key={index}
              >
                <div>
                  <div>
                    <span>MODE</span>
                    <p>{item.interviewMode}</p>
                  </div>
                  <div>
                    <div>
                      <img
                        src={
                          item.status === "Selected" ? selectedIcon : cancelIcon
                        }
                      ></img>
                    </div>
                    <p>{item.interviewStatus}</p>
                  </div>
                </div>
                <div>
                  <span>PANELIST</span>
                  <p>{item.panelist}</p>
                </div>
                <div>
                  <div>
                    <span>DATE</span>
                    <p>{item.date}</p>
                  </div>
                  <div>
                    <span>TIME</span>
                    <p>{item.time}</p>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    );
  };

  const tabContent = () => {
    let content = (
      <Row style={{ marginTop: "20px" }}>
        <table style={{ width: "100%", border: "2px solid #D3D3D3" }}>
          <tr style={{ border: "2px solid #D3D3D3", padding: "0.5rem" }}>
            <th style={{ fontSize: "1.2rem", border: "2px solid #D3D3D3" }}>
              Eligibility Criteria
            </th>
            <th style={{ border: "2px solid #D3D3D3" }}></th>
            <th style={{ border: "2px solid #D3D3D3", fontSize: "1.2rem" }}>
              Candidates Data
            </th>
          </tr>
          {arr.map((element) => {
            return (
              <tr
                style={{
                  border: "2px solid #D3D3D3",
                  padding: "0.5rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                <td style={{ border: "2px solid #D3D3D3", padding: "0.5rem" }}>
                  {element.keys}
                </td>
                <td style={{ border: "2px solid #D3D3D3", padding: "0.5rem" }}>
                  {element.info}
                </td>
                <td style={{ border: "2px solid #D3D3D3", padding: "0.5rem" }}>
                  {element.candInfo}
                </td>
              </tr>
            );
          })}
        </table>
      </Row>
    );

    if (tabClick[0] === true) {
      content = (
        <Row style={{ marginTop: "20px" }}>
          <table style={{ width: "100%", border: "1.5px solid #D3D3D3" }}>
            <tr style={{ border: "2px solid #D3D3D3", padding: "0.5rem" }}>
              <th style={{ fontSize: "1rem", border: "1.5px solid #D3D3D3" }}>
                Eligibility Criteria
              </th>
              <th style={{ border: "1.5px solid #D3D3D3" }}></th>
              <th style={{ border: "1.5px solid #D3D3D3", fontSize: "1rem" }}>
                Candidates Data
              </th>
            </tr>
            {arr.map((element) => {
              return (
                <tr
                  style={{
                    border: "1.5px solid #D3D3D3",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    fontWeight: "600",
                  }}
                >
                  <td
                    style={{ border: "1.5px solid #D3D3D3", padding: "0.5rem" }}
                  >
                    {element.keys}
                  </td>
                  <td
                    style={{ border: "1.5px solid #D3D3D3", padding: "0.5rem" }}
                  >
                    {element.info}
                  </td>
                  <td
                    style={{ border: "1.5px solid #D3D3D3", padding: "0.5rem" }}
                  >
                    {element.candInfo}
                  </td>
                </tr>
              );
            })}
          </table>
        </Row>
      );
    } else if (tabClick[1] === true) {
      content = (
        <div className={classes.hackathonContainer}>
          <table className={classes.hackathonTable}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  width: "70%",
                  border: "1px solid #D3D3D3",
                  padding: "0.5rem",
                  fontWeight: "800",
                  fontSize: "1rem",
                  color: "#000000",
                }}
              >
                Sections
              </div>
              <div
                style={{
                  width: "30%",
                  border: "1px solid #D3D3D3",
                  padding: "0.5rem",
                  fontWeight: "800",
                  fontSize: "1rem",
                  color: "#000000",
                }}
              >
                Candidates' Score
              </div>
            </div>

            {testInfo?.sections?.map((element) => {
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #D3D3D3",
                      width: "35%",
                      padding: "0.5rem",
                      fontWeight: "600",
                    }}
                  >
                    {element.sectionTitle}
                  </div>
                  <div
                    style={{
                      border: "1px solid #D3D3D3",
                      width: "35%",
                      padding: "0.5rem",
                      fontWeight: "600",
                    }}
                  >
                    {element.sectionMaxMarks}
                  </div>
                  <div
                    style={{
                      border: "1px solid #D3D3D3",
                      width: "30%",
                      padding: "0.5rem",
                      fontWeight: "600",
                    }}
                  >
                    {element.candidateScore}
                  </div>
                </div>
              );
            })}

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  border: "1px solid #D3D3D3",
                  width: "35%",
                  padding: "0.5rem",
                  fontWeight: "800",
                  fontSize: "1rem",
                  color: "#000000",
                }}
              >
                Total Score
              </div>
              <div
                style={{
                  border: "1px solid #D3D3D3",
                  width: "35%",
                  padding: "0.5rem",
                  fontWeight: "600",
                }}
              >
                {testInfo?.totalMarks}
              </div>
              <div
                style={{
                  border: "1px solid #D3D3D3",
                  width: "30%",
                  padding: "0.5rem",
                  fontWeight: "600",
                }}
              >
                {testInfo?.candidateTotal}{" "}
              </div>
            </div>
          </table>

          {/* <div className={classes.hackathonButton}>
          <Button>VIEW TEST</Button>
          <Button className={classes.addScore} onClick={()=>dispatch(adminActions.updateModalOpen(true))}>ADD SCORE</Button>
        </div> */}
          <AddScoreModal />
        </div>
      );
    } else if (tabClick[2] === true) {
      content = roundContent(1);
    } else if (tabClick[3] === true) {
      content = roundContent(2);
    } else if (tabClick[4] === true) {
      content = roundContent(3);
    } else if (tabClick[5] === true) {
      content = roundContent(4);
    } else if (tabClick[6] === true) {
      content = roundContent(5);
    }

    return <>{content}</>;
  };

  let infoStyle = {
    fontSize: "0.8rem",
    color: "#6F6F6F",
    fontWeight: "600",
    margin: "0",
  };
  let detailStyle = {
    fontSize: "0.9rem",
    color: "#000000",
    fontWeight: "600",
    margin: "0",
  };
  return (
    <>
      {loading === true ? (
        <Spin  style={{margin:"20% auto"}}/>
      ) : (
        <div className={classes.canProfile}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "1% 0 0 1%",
            }}
          >
            <Button
              type="text"
              onClick={() => {
                history.push({ pathname: "/fresher-candidate-management", state: true });
              }}
              style={{ margin: "0", width: "3.3%" }}
            >
              <img
                style={{ width: "1.2rem", height: "1.2rem" }}
                src={arrowIcon}
              />
            </Button>
            <h1
              style={{
                fontSize: "1.5rem",
                textAlign: "left",
                fontWeight: "bold",
                margin: "0",
              }}
            >
              Profiles
            </h1>
          </div>

          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0rem auto",
                }}
              >
                {/* <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader custom-avtar"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                > */}
                <div>
                  {imageUrl || data.photo ? (
                    <div
                      style={{
                        width: "7rem",
                        height: "7rem",
                        border: "2px solid white",
                      }}
                    >
                      <img
                        src={imageUrl !== "" ? imageUrl : data.photo}
                        style={{
                          margin: "0 auto",
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          width: "7rem",
                          height: "7rem",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "1.3px solid #0086FF",
                            borderRadius: "50%",
                            padding: "0.4rem",
                          }}
                          src={profileIcon}
                        ></img>
                      </div>
                      {/* <img
                        style={{
                          position: "absolute",
                          marginLeft: "2%",
                          marginTop: "-2.2%",
                        }}
                        src={imageIcon}
                      ></img> */}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <img src={name} />
                <div>
                  <h4>NAME</h4>
                  <span> {data.name}</span>
                </div>
              </div>

              <div>
                <img src={institute} />
                <div>
                  <h4>INSTITUTE NAME</h4>
                  <span> {data.collegeName}</span>
                </div>
              </div>

              <div>
                <img src={mobile} />
                <div>
                  <h4>MOBILE NUMBER</h4>
                  <span>{data.phone}</span>
                </div>
              </div>

              <div>
                <img src={email} />
                <div>
                  <h4>EMAIL ID</h4>
                  <span> {data.email}</span>
                </div>
              </div>
              <div>
                <img src={jobV} />
                <div>
                  <h4>JOB VACANCY</h4>
                  <span>{data?.job?.title}</span>
                </div>
              </div>
              <div>
                <img src={resume} />
                <div>
                  <h4>UPLOAD RESUME </h4>
                  <span>
                    {data.resume ? (
                      <a href={data.resume} target="_blank">
                        Resume
                        <FileDoneOutlined />
                      </a>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>

              <div>
                {user?.role.includes("Recruiter") || user?.role.includes("Sourcer")?
                ""
                :
                <Button
                  onClick={() => {
                    setFillModal(true);
                    setUpdatedata(data);
                  }}
                >
                  EDIT
                </Button>
                }
                <Button
                  onClick={() => {
                    setSendMailVisible(true);
                  }}
                >
                  SEND LINK
                </Button>
              </div>
            </div>

            <div>
              <div>
                <h3>Status</h3>
                <div>
                  <h4>Overall Status:</h4>
                  <Select
                    value={overallStatus ? overallStatus : data?.mainStatus}
                    bordered={false}
                    onChange={(value) => {
                      Api.patch(`/hiring-candidate/updateMain/${data.id}`)
                        .params({
                          status: value,
                        })
                        .send((response) => {

                        });
                      setOverallStatus(value);
                    }}
                  >
                    {statuses?.MainStatus?.map((item) => {
                      return <Option value={item}>{item}</Option>;
                    })}
                  </Select>
                </div>
              </div>
              <Row style={{ marginBottom: "1.4rem" }}>
                {roundData?.round?.map((element, index) => {
                  let colored;
                  let fontcolor;
                  if (colorStatus === element) {
                    fontcolor = "white";
                    colored = "#0086FF";
                  } else {
                    fontcolor = "#0086FF";
                    colored = "white";
                  }

                  return (
                    <Col>
                      <Button
                        size="large"
                        style={{
                          width: "100%",
                          height: "2.3rem",
                          marginRight: "3%",
                          border: "1.3px solid #0086FF",
                          color: fontcolor,
                          borderRadius: "0.1rem",
                          backgroundColor: colored,
                        }}
                        onClick={() => {
                          roundClickHandler(element, index);
                          setStatusVal("");
                        }}
                      >
                        {element}
                      </Button>
                    </Col>
                  );
                })}
              </Row>

              <div>
                {/* <Select
                                          allowClear
                                          placeholder={<div style={{display:"flex",gap:"1rem"}}><span  style={{color:"#4DAAFF", fontWeight:"600", fontSize:"1rem"}}>Change Status</span><img src={selectIcon}></img></div>}
                                          onChange={(value)=>statusChange(colorStatus,value)}
                                          suffixIcon={<div></div>}
                                          bordered={false}
                                        >{status?.map((item) =>{
                                          return(
                                            <Option value={item}>{item}</Option>
                                          )
                                        })}                
                            </Select> */}
                {user?.role.includes("Recruiter") || user?.role.includes("Sourcer")?
                ""
                :
                <div>
                  {tabClick?.map((item, index) => {
                    if (item === true) {                    
                      let status = roundStatus
                        ?.filter((item2, index2) => index2 === index)
                        ?.map((item) => item.status);
                      return (
                        <ChangeStatusPopover
                          status={status}
                          statusChange={statusChange}
                          colorStatus={colorStatus}
                        />
                      );
                    }
                  })}
                </div>
                }
              </div>
              {tabContent()}
            </div>
          </div>
        </div>
      )}
      {updateData !== "" && fillModal === true ? (
        <FillForm
          setFillModal={setFillModal}
          fillModal={fillModal}
          updateData={updateData}
          setUpdatedata={setUpdatedata}
          refreshApi={getApi.bind(this)}
        />
      ) : (
        ""
      )}
      <SendEmailModal
        isOpen={isSendEmailMailVisible}
        value="candidateProfile"
        setSendMailVisible={setSendMailVisible}
        selectedData={data.id}
        sendEmail="true"
        refresh={props.refresh}
      />
    </>
  );
};
export default CandidateProfile;
