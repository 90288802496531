import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { operationType, inputFieldType } from "./redux/action";
import { useWindowDimensions } from "../../components/windowDimensions";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ViewPDF = (props) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { candidatePDFViewModal, candidateResume } = props;
  const {width}=useWindowDimensions();

  const onDocumentLoadSuccess=({ numPages }) => {
    setNumPages(numPages);
  }

  const onFinish = () => {
    props.performOperation(operationType.CANDIDATE_PDF_VIEW_MODAL, false);
  };

  const onClose = () => {
    props.performOperation(operationType.CANDIDATE_PDF_VIEW_MODAL, false);
    props.updateInputField(inputFieldType.CANDIDATE_RESUME, "");
  };

  return (
    <Modal
      visible={candidatePDFViewModal}
      // className="view-pdf"
      centered
      // closable={false}
      onOk={onFinish}
      onCancel={onClose}
      footer={null}
      // bodyStyle={{ height:"80vh" }}
    >
      <div className='jdPdf' style={{height:'80vh',margin:"1.5rem 0 0.5rem 0",}}>
        {/* {width > 700 ? (
          <iframe
            src={`${candidateResume}#view=Stretch&toolbar=0`}
            style={{ height: "80vh" }}
            className="view-pdf-iframe"
          ></iframe>
        ) : ( */}
          <Document
            file={candidateResume}
            width="100%"
            height="100%"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}          
          </Document>
        {/* )} */}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPDF);