import React from "react";
import classes from "./dashboard.module.css"
import {
  
    Table,
    Row,
    Col,
    Input,
    Tabs,
    Space,
    Button,
    PageHeader,
    Card,
    Typography,
    Modal
  } from "antd";
import { SearchOutlined,DownloadOutlined,SmileTwoTone  } from "@ant-design/icons";
import Download from '../../assets/download.png';
import searchIcon from '../../assets/searchIcon.png'
import phoneIcon from '../../assets/phoneIcon.svg'
import mailIcon from '../../assets/mailIcon.svg'
import UserIcon from '../../assets/user-icon.svg'
import { useState,useEffect } from "react";
import iconDashboard from "../../assets/instituteDashboard.svg";
import Excel from "../../assets/excel.png";
import ImportView from "./importView";
import Api from "services/Api";
import MainHeader from "components/mainHeader";


const { TabPane } = Tabs;
const {Title} =Typography


const Dashboard=(props)=>{
 
    const [loader, setLoader] = useState(false)
    const [importModal, setImportModal] = useState(false);
    const [searchVal, setSearchVal] = useState("")
    const [instList, setInstList] = useState([])
    const [pageChange, setPageChange] = useState("");
    const [pageSize, setPageSize] = useState("");
    const [downLink, setDownLink] = useState("");
    const [RegLink,setRegLink] =useState("")
    const [RegLinkModal,setRegModal]=useState("")


    const importModalOpen = () => {
        setImportModal(!importModal);
      };

      
const getApi=()=>{
  props.setNavlist(props.navList.map((item,index)=>{
    if(index===0){
        return {...item,clicked:true}
    }
    return {...item,clicked:false}
  }))
  setLoader(true)
  Api.get(`/hiring-college/collegeCandidates/?search=${searchVal}`)
     .send((response)=>{
      setLoader(false)
      setInstList(response?.items)
      setDownLink(response?.sampleExcel)
     
     })
}


      useEffect(()=>{
           getApi()
      },[searchVal])

    const columns = [
        {
          title: <div className={classes.headerName} style={{minWidth:"7rem"}}>
                <span className={classes.nameMargin}>NAME</span>
                  </div>,
          dataIndex: "name",
          key: "name",
          render: (text, record) => (
            <div style={{display:"flex",flexDirection:"row",gap:"1rem",alignItems:"center",cursor:"pointer",marginLeft:"0.5rem"}}>
              <div>
                <img style={{width:"2.5rem",height:"2.5rem", borderRadius:"50%",borderColor:"#0086FF"}} 
                     src={record.photo ? record.photo : UserIcon} />
              </div> 
              <div style={{color:"#161616",fontSize:"1rem",}}>
                {text?text:"NA"}
              </div>
            </div>
          ),
        },
    
        {
          title: <div className={classes.headerName} style={{minWidth:"5rem",}}>
                          <span >CONTACT DETAILS</span>
                  </div>,
          dataIndex: "contact",
          key: "contact",
     
          render: (text,record) => {
           
            return(
              <div>
                 <div style={{marginBottom:"0.3rem"}}><img src={phoneIcon} style={{marginRight:"0.2rem"}}></img> {record?.phone?record?.phone:"NA"}</div>
                <div><img src={mailIcon} style={{marginRight:"0.2rem"}}></img> {record?.email?record?.email:"NA"}</div>
              </div>
            )
          },
        },
        {
          title:  <div className={classes.headerName} style={{minWidth:"6rem",}}>
                      <span>DEPARTMENT</span>
                  </div>,
          dataIndex: "department",
          key: "department",
        
          render:(text,record)=>{
            return(
              <div>
                {record?.department?record?.department:"NA"}
              </div>)}
        },
        {
          title: <div className={classes.headerName} style={{minWidth:"7rem",}}>
                            <span>JOB TITLE</span>             
                 </div>,
          dataIndex: "jobTitle",
          key: "jobTitle",
          
          render:(text,record)=>{
            return(
              <div>
                {record?.job?.title?record?.job?.title:"NA"}
              </div>)}
        },
        
      ];

      const handlePageChange = (page, pageSize) => {
        setPageChange(page);
        setPageSize(pageSize);
      };



  return(
    <>
     <div
       style={{
         padding: "2.5%",
         width: "100%",
         boxSizing:"border-box",
         height:"88vh",
        
       }}
     >
        <div style={{display:'flex',flexDirection:'row',marginBottom:"1rem",height:"13rem"}} >
           
           
             <Card style={{ flex: "1 1 60%", backgroundColor: "#E6F3FF",display:"flex",}}>

              <div  style={{display:"flex",flexDirection:'row',gap:"5%",flexWrap:"nowrap",}}>
                 <div  style={{display:"flex",marginTop:"0.4rem",marginLeft:"0.7rem",alignItems:"left",flexDirection:"column",textAlign:"left",}}>
                  <div style={{marginBottom:"0.9rem"}}>
                   <h2 style={{fontSize:"23px",fontWeight:"bold",marginBottom:"0.5rem"}}><b>Add Candidate(s)</b></h2>
                   <p style={{fontSize:"14px", color:"#6F6F6F"}}>
                     Add candidates by importing in groups or by filling a form manually 
                     or by sending the registration link.
                   </p>
                   </div>
                   <div style={{display:"flex",flexDirection:'row',gap:"5%",flexWrap:"nowrap",}}>
                   <Space>
                
                        
                        <p
                          style={{
                            color: "#6BCB77",
                            // fontWeight:"bold",
                            marginRight:"2.2rem",
                            cursor:"pointer",
                            font: "normal normal bold 13px/22px Open Sans",
                          }}
                          onClick={()=>{
                            Api.get("/hiring-candidate/candidateTemplate").send(resp=>{
                              if(resp)
                              {
                                window.open(resp, "_blank")?.focus();                               
                               }
                            })
                           }}
                          >
                          <img style={{ width: "1.5rem",height:"1.4rem" }} src={Excel} />
                          &nbsp;   DOWNLOAD EXCEL TEMPLATE
                        </p>
                    
                      <Button
                          type="primary"
                          style={{width:"7rem",height:"2.2rem",fontWeight:"bold",borderRadius:"5px"}}
                          onClick={importModalOpen}
                        >
                          IMPORT
                      </Button>
                      <Button onClick={() =>{
                        Api.get("/hiring-candidate/generateLink")
                        .send(res =>{
            
                          setRegLink(res)
                          setRegModal(true)
                        })
                      }}> GENERATE REGISTRATION LINK</Button>
                    </Space>
                   </div>
                   <a href={RegLink} target="_blank">{RegLink}</a>
                 </div>
                 <div style={{marginBottom:"1rem"}}>
                   <img src={iconDashboard} />
                 </div>

                 </div>
               
             </Card>

     </div>
       <Row> 
      <div style={{ display: 'flex', gap:"1rem",justifyContent:"space-between",width:"98%"}}>
      <div className={classes.searchDiv}>
            <div>
               <img src={searchIcon}></img>
            </div>
                 <Input
                  type="text"
                  placeholder='Search'
                  bordered={false}
                  style={{color:"#161616", fontSize:"17px",fontFamily:"Open Sans",fontWeight:"600"}}
                  onChange={(event)=>{
                  
                   setSearchVal(event.target.value)
                  }}
                  >
                  </Input>
             
       </div>
           <div className="head-icon">    

                            <Button type="primary" 
                                  style={{width:"7.2rem",display:"flex",height:"2.2rem",alignItems:"center",borderRadius:"5px",fontWeight:"bold"}}
                                 onClick={()=>{
                                       Api.get(`/hiring-college/collegeCandidates/?download=true`)
                                          .send((response) => {
                                           if (typeof response != "undefined") {
                                            window.open(response, "_blank")?.focus();
                                           }
                                        })
                                   }}>
                                   {/* <img src={Download}
                                     style={{ width: "24", color:"#FFFFFF",height: "25px", marginRight: '10px' }}  
                                     />  */}
                                     <DownloadOutlined style={{fontSize:"18px",display:"flex",alignItems:"center",marginLeft:"-8px"}}/> Download
                            </Button> 
                          
                    </div>
          </div>
         </Row>   
       {/* <Tabs
         style={{fontWeight:"bold",fontFamily:"Open Sans", fontSize:"16px"}}  
        >
         <TabPane>
         <div> */}
                 <Table columns={columns} dataSource={instList} loading={loader} 
                     style={{marginTop:"1rem"}}
                  //  rowSelection 
                   pagination={{ 
                    position: ["bottomRight"],
                    showSizeChanger: true,
                    pageSizeOptions:[10, 30, 50, 100], 
                    size:"small",
                    onChange: handlePageChange,
                    onShowSizeChange: handlePageChange,                   
                  }}
                  // scroll={{
                  //   y:500
                  //   }}
                    />
           {/* </div>
            </TabPane >
       </Tabs>     */}
         <Modal open={RegLinkModal} onCancel={() => setRegModal(false)} footer={false}>
          <Row justify="center"><SmileTwoTone style={{color:"#87CEEB", fontSize:"60px"}}/></Row>
         <p style={{marginTop:"20px"}}>The Link- <a href={RegLink} target="_blank">{RegLink}</a> </p>
          <p style={{marginTop:"20px",marginBottom:"20px"}}>You can use this link for Registration Purpose</p>
         </Modal>
       {importModal && (
          <ImportView
            isOpen={importModal}
            setImportModal={setImportModal}
            toggle={importModalOpen.bind(this)}
            refresh={ getApi.bind(this)}
          />
        )}

     </div>
   </>
  )

}
export default Dashboard;