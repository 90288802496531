import { UserOutlined } from "@ant-design/icons";
import { Affix, Avatar, Col, Form, Progress, Row, Spin, Popover, Modal, message } from "antd";
import moment from "moment";
import { CANDIDATE_IMAGE, CANDIDATE_IMAGE_VIEW_MODAL } from "pages/candidate/redux/action";
import ViewImage from "pages/candidate/viewImage";
import React from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { Color } from "services";
import { dateFormat, operationType, postDateFormat } from "../redux/action";
import { mapDispatchToProps, mapStateToProps } from "../redux/container";
import "./styles.css";
import Submitted from "./submitted";
import FeedbackQuestion from "./feedbackQuestion";
import { PrimaryButton, SecondaryButton } from "services/button";

class Feedback extends React.Component {
  componentDidMount() {
    let url = new URL(window.location.href);
    let candidateId = url.searchParams.get("candidateId");
    this.props.performOperation(operationType.FEEDBACK_CANDIDATE_ID, candidateId);
    //this.props.getRecruitmentFeedback()
  }

  componentDidUpdate = () => {
    if (!this.props.feedbackSubmitted) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  closeModal = () => {
    this.props.performOperation(operationType.HR_FEEDBACK_MODAL, false);
  };

  render() {
    const {
      hrFeedbackModal,
      updateInputField,
      feedbackCandidateInfo,
      feedbackScrollPosition,
      feedbackSubmitted,
      performOperation,
      feedbackPageLoader,
      feedbackJobInterviewer,
      feedbackErrorPage,
      feedbackQuestionNotFound,
    } = this.props;
    const interviewSlots = feedbackCandidateInfo && feedbackJobInterviewer ? feedbackCandidateInfo.interviewSlots[0] : null;
    const jobId = feedbackCandidateInfo && feedbackCandidateInfo.jobs ? feedbackCandidateInfo.jobs.id : null
    const feedbacks = feedbackCandidateInfo && feedbackCandidateInfo.feedback ? feedbackCandidateInfo.feedback.slice().filter((x) => x.jobId === jobId).sort((a,b) => a.round > b.round ? 1: -1): null;
    
    return (
      <Modal
        visible={hrFeedbackModal}
        width="95%"
        style={{ top: 30 }}
        title={"HR Feedback"}
        color={Color.blue}
        onOk={this.onFinish}
        onCancel={this.closeModal}
        maskClosable={false}
        footer={[
        ]}
      >
        <Prompt when={!feedbackSubmitted} message={(location) => "Are you sure you want to leave this page?"} />
        {feedbackSubmitted || feedbackErrorPage || feedbackQuestionNotFound ? (
          <Submitted />
        ) : interviewSlots === null || feedbackPageLoader || !feedbackJobInterviewer.panelist ? (
          <Spin tip="loading" className="on-page-loader"></Spin>
        ) : ( 
          <Form className="feedback-form">
            {feedbackJobInterviewer.roundType !== "Technical" && feedbacks !== null ?
            <Affix >
              <div className="text-left feedback-affix" style={{ marginTop: -15 }}>
                {feedbacks.map((feedback) => 
                  <Row gutter={24}>
                    <Col span={6}><span className = 'text-bold'>Round :</span> {feedback.round} ({feedback.roundType})</Col>
                    <Col span={6}><span className = 'text-bold'>Interviewer :</span> {feedback.interviewer}</Col>
                    <Col span={4}><span className = 'text-bold'>OverallRating :</span> {feedback.overallRating}</Col>
                    <Popover content={feedback.overallComment} >
                      <Col span={8}><span className = 'text-bold'>OverallComment :</span> {feedback.overallComment}</Col>
                    </Popover>
                  </Row>
                )}
              </div>
            </Affix> : ""}
            <Affix className="feedback-left-affix" style = {{marginLeft : '85px', marginTop : '-12.5px'}}>
              <div>
                <div className="feedback-left-content">
                  {feedbackCandidateInfo.candidatePhoto !== "" ? (
                    <img
                      src={feedbackCandidateInfo.candidatePhoto}
                      style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                      alt=""
                      onClick={() => {
                        updateInputField(CANDIDATE_IMAGE, feedbackCandidateInfo.candidatePhoto);
                        performOperation(CANDIDATE_IMAGE_VIEW_MODAL, true);
                      }}
                    />
                  ) : (
                    <Avatar size="100" icon={<UserOutlined />} />
                  )}
                  <Row>
                    <Col span={24}>Name of Candidate</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {feedbackCandidateInfo.name}
                    </Col>
                  </Row>
                </div>
                <Row className="feedback-left-content">
                  <Col span={24}>Round</Col>
                  <Col span={24} style={{ color: Color.modalblue }}>
                    {feedbackJobInterviewer.round}
                  </Col>

                  <Col span={24}>Round Type</Col>
                  <Col span={24} style={{ color: Color.modalblue }}>
                    {feedbackJobInterviewer.roundType}
                  </Col>

                  <Col span={24}>Name of Interviewer</Col>
                  <Col span={24} style={{ color: Color.modalblue }}>
                    {feedbackJobInterviewer.panelist? feedbackJobInterviewer.panelist.name : ""}
                  </Col>

                  <Col span={24}>Date of Interview</Col>
                  <Col span={24} style={{ color: Color.modalblue }}>
                    {interviewSlots.date
                      ? moment(interviewSlots.date, postDateFormat).format(dateFormat)
                      : null}
                  </Col>

                  <Col span={24}>Mode of Interview</Col>
                  <Col span={24} style={{ color: Color.modalblue }}>
                    {interviewSlots.interviewMode}
                  </Col>

                  <Col span={24}>Experience</Col>
                  <Col span={24} style={{ color: Color.modalblue }}>
                    {feedbackCandidateInfo.experienceYears} Years {feedbackCandidateInfo.experienceMonths !== 0 ? `${feedbackCandidateInfo.experienceMonths} Months` : ""}
                  </Col>

                  <Col span={24}>Current Location</Col>
                  <Col span={24} style={{ color: Color.modalblue }}>
                    {feedbackCandidateInfo.currentLocation}
                  </Col>

                  <Col span={24}>Preferred Location</Col>
                  <Col span={24} style={{ color: Color.modalblue }}>
                    {feedbackCandidateInfo.preferredLocation}
                  </Col>
                </Row>
              </div>
            </Affix>
            <Affix className="feedback-right-affix">
              <Progress
                type="circle"
                percent={feedbackScrollPosition}
                strokeColor={{
                  from: Color.blue,
                  to: Color.green,
                }}
              />
            </Affix>
            <div>
              <FeedbackQuestion />
            </div>
          </Form>
        )}
        <ViewImage />
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
