import React,{useState,useEffect} from "react"
import { Api } from "services"
import {
   Popover,
   Space,
   Table,
   Tabs,
   Tooltip,
   Modal,
   Spin,
   Dropdown,
   Button,
   Checkbox,
   Select,
   Badge,
   Typography,Input
 } from "antd";import { useSelector } from "react-redux"
import { userContent } from "services";
import { connect } from "react-redux";
import { CANDIDATE_DETAILS, operationType, inputFieldType, dateFormat } from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { useWindowDimensions } from "../../components/windowDimensions";
import ResumeIcon from "../../assets/ResumeIconss.svg";
import profileIcon from '../../assets/profileIcon.svg.svg';
import "./styles.css";
import moment from "moment";
import {
   CopyOutlined,
   EyeOutlined,
   FilePdfOutlined,
   StopOutlined,
   CalendarOutlined,
 } from "@ant-design/icons";
import { Color, EmptyTable } from "services";

const ProposeJdChangeProfile = ({ performOperation, updateInputField, getDuplicateCandidates, candidateNewPageNumber, }) =>{
  const {candidates,candidateTableLoader} = useSelector(state => state.candidate)
  const {width}=useWindowDimensions();

  const columns=[{
   
      title: "Name",
      dataIndex: "name",
      key: "name",
      render :(text,record) =>(
         <Popover content={userContent(record)}>
         <a
           style={{ fontWeight: "500" }}
           onClick={() => {
             updateInputField(CANDIDATE_DETAILS, record);
             performOperation(operationType.CANDIDATE_VIEW_MODAL, true);
           }}
         >
           {record?.name}
         </a>
       </Popover>
      )
  },
  {
   
   title: "Assigned JD",
   dataIndex: "job",
   key: "job",
   render: (text,record) =>{
      
      return (
         <>{text?.title ? text?.title : "NA"}</>
      )
     }


},{
   title: "Proposed JD",
   dataIndex: "proposedJd",
   key: "proposedJd",
   render: (text,record) =>{
     
      return (
         <>{text?.title ? text?.title : "NA"}</>
      )
     }
    
},
{
   
   title: "Shortlister Comment",
   dataIndex: "shortlisterComment",
   key: "shortlisterComment",
   render:(record,text)=>{
      return(
         <div>{record || record !== null ? record : "NA"}</div>
      )
   }
},

]
 return(
   <>
   {width > 700 ?
   <div>
   <Table columns={columns} dataSource={candidates} loading={candidateTableLoader} 
    pagination={{
     showSizeChanger:true,
     pageSizeOptions:[10, 30, 50, 100],
     position: "bottomRight",
     size: "small",
    }}
    scroll={{y:360}}
    ></Table>
   </div>
    :
   <div className="ListBodyContainer" style={{height:"67vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",marginTop:"0.7rem"}}>
      {candidateTableLoader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
         candidates?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
         candidates?.map((record,id,index)=>{
            return(
             <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"0.9rem 0.7rem"}}>
             <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"0.5rem",paddingTop:"0.3rem",borderBottom:"1px solid #DFDFDF"}}>
              <div style={{display: "flex",justifyContent: "flex-start"}}>
                <div>
                  {record.photo ? (
                    <img src={record.photo} style={{ width: "40px",height: "40px",borderRadius: "50%", }} />
                  ) : (
                    <img src={profileIcon} style={{ width: "40px",height: "40px",borderRadius: "50%",}} />
                  )}
                </div>
                <div style={{display: "flex",flexDirection:"row",marginLeft: "0.7rem",alignItems:"center",}}>
                  <div style={{ display: "flex"}}>
                    <div>
                      <Popover content={userContent(record)}>
                        <a style={{fontWeight:600}}
                          // onClick={() => {
                          //  updateInputField(CANDIDATE_DETAILS, record);
                          //  performOperation(
                          //     operationType.CANDIDATE_VIEW_MODAL,
                          //     true
                          //   );
                          // }}
                        >
                        <Typography.Text ellipsis={{tooltip:false}} style={{width:"7.5rem",fontSize:"1rem",fontWeight:600}}>{record.name}</Typography.Text>
                        </a>
                      </Popover>
                    </div>
                    <Space size="large">
                      <div>
                        {record.blacklist ? (
                          <Tooltip title="Blacklist">
                            <StopOutlined
                              style={
                                record.blacklist
                                  ? { color: Color.black }
                                  : { color: Color.midGrey }
                              }
                            />
                          </Tooltip>
                        ) : (
                          ""
                        )}
                        {record.duplicate ? (
                          <Tooltip title="Duplicate">
                            <CopyOutlined
                              style={
                                record.duplicate
                                  ? { color: Color.red }
                                  : { color: Color.midGrey }
                              }
                              onClick={() => {
                                updateInputField(CANDIDATE_DETAILS, record);
                                getDuplicateCandidates();
                                
                              }}
                            />
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </Space>
                  </div>
                </div>
              </div>
              <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                    <span style={{ fontSize: 10 }}>
                      <a
                        onClick={() => {
                          updateInputField(
                            inputFieldType.CANDIDATE_RESUME,
                            record.resume
                          );
                          performOperation(
                            operationType.CANDIDATE_PDF_VIEW_MODAL,
                            true
                          );
                          console.log("sssSHeelloo")
                        }}
                      >
                        <img src={ResumeIcon} />
                      </a>
                    </span>
               </div>
             </div>

             <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
             <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
              <span style={{fontSize:14,color:"#808080"}}>Job Title</span>
              <p style={{fontSize:15,color:"#161616"}}>
              <Popover content={record?.job?.title}>
                <Space size="small">
                  <span style={{ fontSize: "13px" }}>{record?.job?.title}</span>
                </Space>
              </Popover>
              </p>
             </div>
             <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
              <span style={{fontSize:14,color:"#808080"}}>Experience</span>
              <p style={{fontSize:15,color:"#161616"}}>{`${record.experienceYears} Y ${
                record.experienceMonths !== 0 ? `${record.experienceMonths} M` : ""}`}</p>
             </div>
             </div>

             <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
             <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
              <span style={{fontSize:14,color:"#808080"}}>Date</span>
              <p style={{fontSize:15,color:"#161616"}}>{record?.createdAt ? moment(record?.createdAt).format(dateFormat):"NA"}</p>
             </div>
             <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
              <span style={{fontSize:14,color:"#808080"}}>Notice Period (Days)</span>
              <p style={{fontSize:15,color:"#161616"}}>{record?.noticePeriod ? record?.noticePeriod:"NA"}</p>
             </div>
             </div>

             <div style={{width:"100%",marginTop:"1rem",borderBottom:"0.6rem"}}>
              <Button type="primary" style={{height:"2.1rem",width:"100%",borderRadius:5}}
               onClick={()=>{
                updateInputField(CANDIDATE_DETAILS, record);
                performOperation(operationType.CANDIDATE_VIEW_MODAL, true);
              }}
              >
                View Profile
              </Button>
             </div>

          </div>
          )
        })}
   </div> 
   } 
   </>
 )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProposeJdChangeProfile)