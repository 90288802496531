import React,{useEffect,useState} from "react";
import { Button, Input, Modal ,Radio,Form} from 'antd';
import "antd/dist/antd.css";
import { useDispatch, useSelector } from 'react-redux';
import { LocAndDeptActions } from '../store';
import classes from './addAndEditLoc.module.css';
import locIcon from '../../../assets/locIcon.svg'
import '../override.scss';
import { Api, Notification } from "services";

const AddAndEditLoc=(props)=>{
const [form] = Form.useForm();
const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);
const {addLocModalOpen,editLocModalOpen,editLocnDetails}=useSelector(state=>state?.LocAndDept);
const dispatch=useDispatch();
const loader=props?.loader;
const setLoader=props?.setLoader;
   


  useEffect(()=>{
    if(editLocModalOpen){
   form.setFieldsValue(editLocnDetails)
    }
   
  },[editLocnDetails,])


    return(
        <Modal open={addLocModalOpen||editLocModalOpen} maskClosable={true} centered closable={true} header={null} footer={null} onCancel={()=>{
            dispatch(LocAndDeptActions.udateEditLocnDetails({}))
            form.resetFields()
            dispatch(LocAndDeptActions.updateAddLocModal(false));
            dispatch(LocAndDeptActions.updateEditLocModal(false))
        }}>
                <Form form={form} className={classes.container} 
                  onFinish={(values)=>{
                    setLoader(true);
                    if(values.name.trim() === ''){
                        Notification.error("Error","Add Location")
                    }
                    else{
                     if(editLocModalOpen){
                        Api.patch("/hiring-job/updateLocation")
                        .params({
                            id:editLocnDetails?.id,
                            ...values
                        })
                        .send(res =>{
                            if(res){
                                setLoader(false);
                                //editLocnDetails.getApi();
                                props?.getApi();
                                dispatch(LocAndDeptActions.updateAddLocModal(false));
                                dispatch(LocAndDeptActions.updateEditLocModal(false))
                                //dispatch(LocAndDeptActions.udateEditLocnDetails({}))
                                form.resetFields()                            
                            }                 
                        })
                        return
                    }
                    Api.post("/hiring-job/addLocation")
                        .params(values)
                        .send(res =>{
                            if(res)
                            {                          
                            if(res?.show?.type==="success"){   
                                setLoader(false);                                                     
                                dispatch(LocAndDeptActions.updateAddLocModal(false));
                                dispatch(LocAndDeptActions.updateEditLocModal(false))                            
                                //dispatch(LocAndDeptActions.udateEditLocnDetails({}))  
                                form.resetFields();
                               // editLocnDetails?.getApi();
                                props.getApi();
                               // window.reload() ;                    
                            }
                            setLoader(false);      
                        }                   
                        })
                  }
                }}>
                    <div>
                                <img src={locIcon}></img>
                    </div>
                    {editLocModalOpen?<h3>Edit Details</h3>:<h3>Location Details</h3>}
                    <span>Kindly fill in the informations below</span>
                    <div>
                                    <span>LOCATION <p >*</p></span>                                
                                        <Form.Item name="name" required={true} rules={[{required:true,message:"Please enter valid data!"}]}>
                                                <Input placeholder='Enter location ' style={{width:"100%",}}/>
                                        </Form.Item>                                                                 
                    </div>
                    <div>
                                <Form.Item name="india" rules={[{required:true,message:"Please select valid data!"}]}>
                                                <Radio.Group                 
                                                >
                                                    <Radio value={true}>India</Radio>
                                                    <Radio value={false}>Other Country</Radio>
                                                    </Radio.Group> 
                                    </Form.Item>
                        </div> 
                    <div>
                                    <span>PINCODE <p >*</p></span>
                                    <Form.Item name="pinCode" rules={[{required:true,message:"Please enter valid data!"}]}>
                                    <Input placeholder='Enter Pincode' type="number"/>
                                    </Form.Item>
                    </div>                
                    <Button htmlType="submit" >{!editLocModalOpen?"SAVE":"UPDATE"}</Button>
                </Form>
        </Modal>      
    )
}
export default AddAndEditLoc;