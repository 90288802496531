import React from "react";
import classes from './interviewFresher.module.css';
import {
    Table,
    Row,
    Input,
    Tabs,
    Space,
    Typography,
    DatePicker,
    Checkbox,
    Popover,
    Button,
    Card,
    List,
    Badge, 
    Timeline,
    Radio,
  } from "antd";
import { SearchOutlined,DownOutlined,CloseOutlined,CheckCircleOutlined,CloseCircleOutlined,ExclamationCircleOutlined,ClockCircleOutlined } from "@ant-design/icons";
import filter from "../../assets/filter.png";
import Download from '../../assets/download.png';
import searchIcon from '../../assets/searchIcon.png';
import phoneIcon from '../../assets/phoneIcon.svg';
import mailIcon from '../../assets/mailIcon.svg';
import iconInterview from "../../assets/InterviewFresherIcons.svg";
import { Color, Notification } from "services";
import Api from "services/Api";
import { useState,useEffect } from "react";
import Filter from "./filter";
import moment from "moment";
import FilterUtility from "components/filterUtility";

const { TabPane } = Tabs;
const {Title} =Typography
const dateFormat = "YYYY-MM-DD";
const postDateFormat = "YYYY-MM-DD";
const filterObj = new Filter();
const { RangePicker } = DatePicker;


const InterviewExp = () => {

  const [loader, setLoader] = useState(false)
  const [interviewLists,setInterview] = useState([])
   const [searchVal, setSearchVal] = useState("")
  //  const [dateVal, setDateVal] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [filterState,setFilterState] = useState({
    "status":false,
    "jobTitle":false,
    "institute":false,
    "collegeName":false,
   });
   const [filterShows, setFilterShows] = useState([
    {title : "Status", value : "status"},
    {title : "JobTitle", value : "jobTitle"},
    {title : "Training Institute", value : "institute"},
    {title : "CollegeName", value : "collegeName"},
  ]);
   const [, updateState] = React.useState();
   const forceUpdate = React.useCallback(() => updateState({}), []);
   const [statusFilterList, setStatusFilterList] = useState([]);
   const [statusFilter, setStatusFilter] = useState("");
   const [jobFilterList, setJobFilterList] = useState([]);
   const [jobFilter, setJobFilter] = useState("");
   const [jobFilterTitle, setJobFilterTitle] = useState(0);
   const [statusFilterTitle, setStatusFilterTitle] = useState("");
   const [instFilterList, setInstFilterList] = useState([]);
   const [instFilter, setInstFilter] = useState([]);
   const [collegeFilterList, setCollegeFilterList] = useState([]);
   const [collegeFilter, setCollegeFilter] = useState("");
   const [filterData, setFilterData] = useState();
   const [pageChange, setPageChange] = useState("");
   const [pageSize, setPageSize] = useState("");
   const [filterDetails,setFilterDetails] = useState(false);
   const [schedule, setSchedule] = useState(0)
   let [selectedRow, setSelectedRow] = useState([]);
   const [statusCheck,setStatusCheck]=useState(new Array(10).fill(false));
   const [jobTitleCheck,setJobTitleCheck]=useState(new Array(10).fill(false));
   const [reset,setReset]=useState(false);


   const list= ['All'].concat(jobFilterList)



   const badgeHash = {
    Shortlisted: "processing",
    Skipped: "processing",
    Scheduled: "warning",
    Selected: "success",
    Completed:"success",
    Cancelled:"error",
    "Feedback-Pending":"warning",
    "Not Selected": "error",
    Hold: "default",
    "In Progress": "warning",
    "Not Shortlisted": "error",
    "All": "success",
    "Re-Discussion":"warning",
    "Re-Schedule":"warning",
  }

  const timelineHash = {
    Shortlisted: Color .blue,
    Skipped: Color.blue,
    Scheduled: Color.yellow,
    Selected: Color.green,
    "Not Selected": Color.red,
    Hold: Color.darkGrey,
    "In Progress": Color.yellow,
    "Not Shortlisted": Color.red,
    "Re-Discussion":Color.yellow,
    "Re-Schedule":Color.yellow,
  };

  const timelineDotHash = {
    Selected: <CheckCircleOutlined style={{ color: Color.green }} />,
    "Not Selected": <CloseCircleOutlined style={{ color: Color.red }} />,
    "Not Shortlisted": <CloseCircleOutlined style={{ color: Color.red }} />,
    Hold: <ExclamationCircleOutlined style={{ color: Color.darkGrey }} />,
    "In Progress": <CheckCircleOutlined style={{ color: Color.green }} />,
    Scheduled: <ClockCircleOutlined style={{ color: Color.yellow }}/>,
    Shortlisted: <ClockCircleOutlined style={{ color: Color.blue }}/>,
    "Re-Discussion": <ClockCircleOutlined style={{ color: Color.yellow }}/>,
    "Re-Schedule": <ClockCircleOutlined style={{ color: Color.yellow }}/>,
  };

   const getInt=(request)=>{

      const Request= {
        ...request
      }
    setLoader(true)
       Api.get(`/hiring-overview/interviews/`)
     .params(Request)
       .send((response) => {
        const tempArr=[]
        if (typeof response != "undefined") {
         
          if (
            response &&
            response.candidates.length > 0
          ){
          response?.candidates?.map((e) => {
            e["key"] = e.id;
            tempArr.push(e);
          })
          }}
          setInterview(tempArr)
          setJobFilterList(response?.jobs);
          //setJobCheck(new Array(response?.jobs?.length).fill(false))
          setStatusFilterList(response?.interviewStatus)
          setCollegeFilterList(response?.agency)
          response?.candidates.forEach((item,index)=>{
          tempArr.push(item?.interviewSlots[index]?.interviewStatus);
          setSchedule(response?.scheduledCount)
          });
         setLoader(false)
      });
  }

  let dates=moment(new Date()).format('DD/MM/YYYY')


  useEffect(() => {
    const request=
    { 
      search: searchVal,
      candidateType:"Fresher",
      startDate: startDate,
      endDate:endDate,
    }
    getInt(request);
    filterInterviews();
  }, [searchVal,startDate,endDate]);

  
  const filterInterviews = ( obj = null) => {
    let request;

    if(filterObj?.status){
      request= {
        search: searchVal,
        candidateType:"Fresher",
        startDate: startDate,
        endDate:endDate,
        jobId: jobFilter==='All'?'':jobFilter,
        status : statusFilter==='All'?'':statusFilter, 
        // page: pageChange,
      }
    }
   else if(filterObj?.jobTitle)
   {
    request= {   
      search: searchVal,
      candidateType:"Fresher",
      startDate: startDate,
      endDate:endDate,
      jobId: jobFilter==='All'?'':jobFilter,
      status : statusFilter==='All'?'':statusFilter, 
      // page: pageChange,
   }
  }
 else{
  request={
  search: searchVal,
  candidateType:"Fresher",
  startDate: startDate,
  endDate:endDate,
  // page: pageChange,
  }
 }
 
   setFilterData(request)
   getInt(request)
  };

  const resetFilter = () => {
    filterObj.resetFilter();
    filterInterviews();
    forceUpdate();
    
  }

  const customFilterPopover = (type = null, logo) => {
    let contentBody;
    
    switch (type) {

      case "status" : 

      contentBody = <div className="sort-popover">
      <div style={{width: '80%',marginBottom:"0.8rem",}}>
                    {
                      ['All'].concat(statusFilterList)?.map((item,index)=>{
                          return(
                            <div>                            
                                      <input type="radio" checked={statusCheck[index]} onChange={(e)=>{
                                        
                                        setStatusCheck(statusCheck.map((item2,index2)=>{
                                          if(index===index2)
                                          {
                                            if(e.target.checked)
                                            {
                                              return true;
                                            }
                                            return false;
                                          }
                                          return false;
                                        }))
                                        if(!e.target.checked)
                                        {
                                          setStatusFilter( statusFilterList.filter(arr => {}))
                                          return 
                                        }
                                          setStatusFilter(item)
                                        
                                      }}/>
                                        <span> {item}</span>
                            </div>
                      )})
                  }
      </div>

      <div className="sort-action">
        <Button className="cancel" onClick={() => {
              let temp = filterState;
              temp.status = false
              setFilterState(temp)  
              forceUpdate(); 
        }}>CANCEL</Button>
        <Button className="float-right ok" onClick={() => {
          getfilterRequest(type);
          filterInterviews();
          setStatusFilterTitle(statusFilter)
          let temp = filterState;
          temp.status = false
          setFilterState(temp)
          forceUpdate();
        }}>OK</Button>
      </div>
      </div>
     return <Popover
     placement="bottom"
     content={contentBody}
     trigger="click"
     visible={filterState.status}
     onVisibleChange={() => {
      let temp = filterState;
      if(filterState.status===false){
        temp.status = true
        setFilterState(temp)
        forceUpdate();
        }
        else{
         temp.status = false
         setFilterState(temp)
         forceUpdate();
        }
     }}
   >
     {logo}
   </Popover>
  
 
   case "jobTitle" : 
   contentBody = <div className="sort-popover">
     <div style={{width: '90%',marginBottom:"0.8rem", display:"flex",flexDirection:"column",maxHeight: "22vh",overflowY:"auto", }}>
      {
          [{title:'All'}].concat(jobFilterList)?.map((item,index)=>{
           
            return(
              <div>                            
                        <input type="radio" value={item?.id} checked={jobTitleCheck[index]}
                          onChange={(e)=>{
                          
                          setJobTitleCheck(jobTitleCheck.map((item2,index2)=>{
                            if(index===index2)
                            {
                              if(e.target.checked)
                              {
                                return true;
                              }
                              return false;
                            }
                            return false;
                          }))
                          if(!e.target.checked)
                          {
                            setJobFilter( jobFilterList.filter(arr => {} ))
                            return 
                          }
                            setJobFilter(item?.id)
                          
                        }}/>
                          <span> {item?.title}</span>
              </div>
        )})
    }
</div>
{/*    
      <div
        style={{
          width: '100%',
          marginBottom:"0.8rem",
        }}>       
          <FilterUtility  setContent={setJobFilter} selectedContent={jobFilter} content={[{id:"All",title:"All"}].concat(jobFilterList)} reset={reset} setReset={setReset}/>
      </div> */}

    <div className="sort-action">
   <Button className="cancel" onClick={() => {
         let temp = filterState;
         temp.jobTitle = false
         setFilterState(temp)
         forceUpdate();
   }}>CANCEL</Button>

   <Button className="float-right ok" onClick={() => {
    // if(jobFilter.length===0)
    // {
    //   Notification.error("Please Select a Job Type to Apply Filter!");
    //   return;
    // }
          getfilterRequest(type);
          filterInterviews();
          setJobFilterTitle(jobFilter)
          let temp = filterState;
          temp.jobTitle = false
          setFilterState(temp)
          forceUpdate();
   }}>OK</Button>
 </div>
   </div>
    return <Popover
    placement="bottom"
    content={contentBody}
    trigger="click"
    visible={filterState.jobTitle}
    onVisibleChange={() => {
      let temp = filterState;
      if(filterState.jobTitle===false){
        temp.jobTitle = true
        setFilterState(temp)
        forceUpdate();
        }
        else{
         temp.jobTitle = false
         setFilterState(temp)
         forceUpdate();
        }
    }}
  >
    {logo}
  </Popover>
   break;
   case "institute" : 
   contentBody = <div className="sort-popover">
    <Checkbox.Group
        style={{
          width: '100%',
        }}
      >
         <Row>
        <Checkbox value={"all"}  onChange={()=>{
          setInstFilter([])
        }}>All</Checkbox></Row>
      </Checkbox.Group>
      <Checkbox.Group
        style={{
          width: '80%',
        }}>
          {
        instFilterList.map((item)=>{
          return(
            <Row><Checkbox value={item} onChange={(e) =>{
              let value=e.target.value
              setInstFilter(arr => [...arr,value])
            }}>{item}</Checkbox></Row>)
        })
      }
      </Checkbox.Group>
    <div className="sort-action">
   <Button className="cancel" onClick={() => {
         let temp = filterState;
         temp.agencyName = false
         setFilterState(temp)
         forceUpdate();
   }}>CANCEL</Button>
   <Button className="float-right ok" onClick={() => {
    getfilterRequest(type);
    filterInterviews()
    let temp = filterState;
    temp.agencyName = false
    setFilterState(temp)
    forceUpdate();
   }}>OK</Button>
 </div>
   </div>
    return <Popover
    placement="bottom"
    content={contentBody}
    trigger="click"
    visible={filterState.institute}
    onVisibleChange={() => {
      let temp = filterState;
       if(filterState.institute===false){
       temp.institute = true
       setFilterState(temp)
       forceUpdate();
       }
       else{
        temp.institute = false
        setFilterState(temp)
        forceUpdate();
       }
    }}
  >
    {logo}
  </Popover>
   break;

   default:
   break; 
 }
};

const filterList = ()=>{
  let list = [];
  
  filterShows.map(element => {
   
    switch(element.value){
  
      case "status" : 
        if(filterObj.status&&statusFilterTitle !== ""){
          let description =statusFilterTitle ;
         
          list.push({
            ...element,
            discription : description
          })
        }
      break;
      case "jobTitle" : 
      // let temp=[{id:"All",title:"All"}].concat(jobFilterList);
      if(filterObj.jobTitle&&jobFilterTitle !== 0&&jobFilterTitle>0 || jobFilterTitle === undefined){
        // let description = jobFilter ;
        let map={}
        let values
      //   jobFilter.forEach((element) =>{
      //   map[element]=true
      //  })
      jobFilterList.forEach((element)=>{
        if(jobFilterTitle===element.id){

          values=element.title
        }
        else if(jobFilterTitle===undefined){
          values='All'
        }
      })

        list.push({
          ...element,
          discription: values==='All'?'All':values,
        })
      }
    break;
    case "institute" : 
    if(filterObj.institute){
      let description = instFilter ;
     
      list.push({
        ...element,
        discription : description
      })
    }
    break;
    case "collegeName" : 
    if(filterObj.collegeName){
      let description = collegeFilter ;
     
      list.push({
        ...element,
        discription : description
      })
    }
    break;

      default :
      break;    
    }
  })
  return list;
}

const getfilterRequest = (key) => {
  switch (key) {
    case "status":
      filterObj.status = true;
      break;
    case "jobTitle":
      filterObj.jobTitle = true;
      break;
    case "institute":
      filterObj.institute=true;
      break;
    case "collegeName":
      filterObj.collegeName=true;
      break;
    default:
      break;
  }
}

  const handlePageChange = (page, pageSize) => {
   
    setPageChange(page);
    setPageSize(pageSize);
  };

  const customPopover = (logo) => {
    let contentBody;

        contentBody = <div className="sort-popover">
          <div> <span style={{fontWeight:"bold",fontSize:"19px"}}>ALL Filter(s)</span>
          <Button type="text" size="medium" className="float-right"
           onClick={()=>{
            resetFilter();
            setReset(true);
            setStatusCheck(new Array(10).fill(false));
            setJobFilterTitle(0)
            setJobTitleCheck(new Array(10).fill(false));
            setJobFilter([]);
            setStatusFilter("");
            setFilterDetails(false);
          }
          }
          >Reset</Button></div>
          <List
          dataSource={filterList()}
          renderItem={(item) => (
            <List.Item key={item.title}>
              <List.Item.Meta
                title={<span>{item.title}</span>}
                description={<span>{item.discription  }</span>}
              />
              {/* <div style={{alignItem:"right",display:"flex",cursor:"pointer",justifyContent:"space-between"}}>
                <CloseOutlined/>
              </div> */}
            </List.Item>
          )}
        />

          <div className="sort-action">
            <Button className="cancel" onClick={() => { setFilterDetails(false)}}>CANCEL</Button>
            <Button className="float-right ok"
            onClick={() => { setFilterDetails(false)}}
            >OK</Button>
          </div>
        </div>

        return (<Popover
          placement="bottom"
          content={contentBody}
          trigger="click"
          visible={filterDetails}
          onVisibleChange={() => { 
            if (filterDetails){
              setFilterDetails(false)
            }else{
              setFilterDetails(true)
            }
          }}
        >
          {logo}
        </Popover>
  )
};

  const columns = [
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem"}}>
            <span 
            // className={classes.nameMargin}
            >CANDIDATE NAME</span>
              </div>,
      dataIndex: "name",
      key: "name",
      // width:"11%",
      render: (text, record) => (
        <div >
          {text?text:"NA"}
        </div>
      ),
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"5rem",textAlign:"center",alignItems:"center"}}>
                      <span>JOB TITLE</span>
                      {customFilterPopover("jobTitle", <DownOutlined />)}
 
                </div>,
      dataIndex: "jobTitle",
      key: "jobTitle",
      width:"12%",
      render:(text,record)=>{
        return(
          <div>
            {record?.job?.title?record?.job?.title:"NA"}
          </div>)}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"17rem",}}>
                      <span>CONTACT DETAILS</span>
              </div>,
      dataIndex: "contact",
      key: "contact",
      width:"15%",
      render: (text,record) => {
       
        return(
          <div>
          <div style={{marginBottom:"0.3rem"}}><img src={phoneIcon} style={{marginRight:"0.2rem"}}></img> {record?.phone?record?.phone:"NA"}</div>
         <div><img src={mailIcon} style={{marginRight:"0.2rem"}}></img> {record?.email?record?.email:"NA"}</div>
       </div>
        )
      },
    },
    {
      title:  <div className={classes.headerName} style={{minWidth:"6rem",}}>
                  <span>PANELIST</span>
              </div>,
      dataIndex: "panelist",
      key: "panelist",
      width:"8%",
      render:(text,record)=>{
        let slotLength = record?.interviewSlots?.length
        return(
            <div>
             {slotLength > 1 ? record?.interviewSlots[slotLength-1]?.panelist?.name ? record?.interviewSlots[slotLength-1]?.panelist?.name :"NA"
             :
             record?.interviewSlots[0]?.panelist?.name ? record?.interviewSlots[0]?.panelist?.name:"NA"}
            </div>
        )}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",}}>
                        <span>DATE</span>             
             </div>,
      dataIndex: "date",
      key: "date",
      width:"8%",
      render:(text,record)=>{
        let slotLength = record?.interviewSlots?.length
        return(
            <div>
             {slotLength > 1 ? record?.interviewSlots[slotLength-1]?.date ? record?.interviewSlots[slotLength-1]?.date :"NA"
             :
             record?.interviewSlots[0]?.date ? record?.interviewSlots[0]?.date:"NA"}
            </div>
        )}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center",alignItems:"center"}}>
                      <span>SLOT</span>
                </div>,
      dataIndex: "slot",
      key: "slot",
      width:"10%",
      render:(text,record)=>{
        let slotLength = record?.interviewSlots?.length
        return(
          <> {slotLength === 1 && record?.interviewSlots[0].schedule === null ? 
            <div>NA</div>
            :
            <div>
              {slotLength > 1 ? record?.interviewSlots[slotLength-1]?.schedule?.startTime : record?.interviewSlots[0]?.schedule?.startTime} - {slotLength > 1 ? record?.interviewSlots[slotLength-1]?.schedule?.endTime : record?.interviewSlots[0]?.schedule?.endTime}
            </div>
            }
            </>
            )}
    },
    {
        title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center",alignItems:"center"}}>
                        <span>STATUS</span>
                        {customFilterPopover("status", <DownOutlined />)}
   
                  </div>,
        dataIndex: "status",
        key: "status",
        width:"10%",
        render:(text,record)=>{
          const content = (
            <div style={{maxHeight:"20vh",overflowY:"auto"}}>
              {record.interviewSlots?.map((item)=>{
              
                return(
                  <div style={{display:'flex',flexDirection:'row',gap:"-0.5rem",marginBottom:"0.5rem",fontSize:"14px",}}>

                    <Card bordered={false} style={{width:"24rem",}}>
                     <Row style={{margin:"-0.5rem"}}>
                    <div style={{ flex:"1 1 13%",marginTop:"-0.3rem"}}>
                    <div style={{display:"flex",alignItems:"left",flexDirection:"column",textAlign:"left"}}>
                        <p>Round: <span style={{color:"#0086FF"}}>Round {item?.round}</span></p>
                        <p>Panelist: <span style={{color:"#0086FF"}}>{item?.panelist?.name}</span></p>
                        <p>Date: <span style={{color:"#0086FF"}}>{item?.date}</span></p>
                    </div>
                    </div>
                
                    <div style={{ flex:"1 1 15%",marginTop:"0.1rem"}}>
                        <div  style={{display:"flex",alignItems:"right",flexDirection:"column",textAlign:"right"}}>
                        {/* <p>Status: <Badge style={{cursor: "pointer",fontSize:"16px"}} status={badgeHash[record.hiringStatus]} text={record.hiringStatus} />
                          <span style={{color:"#0086FF"}}>{item?.interviewStatus}</span></p> */}
                        <Timeline
                         mode="left">
                           <Timeline.Item
                              position="left"
                              style={{display:"flex",height:"2rem",marginBottom:"-0.6rem"}}
                              color={timelineHash[item?.interviewStatus]}
                              dot={timelineDotHash[item?.interviewStatus]}>
                              <div style={{ color: timelineHash[item?.interviewStatus] }}>{item?.interviewStatus}</div>
                            </Timeline.Item>
                           <div style={{marginLeft:"-0.7rem",}}>Time: <span style={{color: timelineHash[item?.interviewStatus]}}>{item?.schedule?.startTime} - {item?.schedule?.endTime}</span></div>
                          </Timeline>
                        </div>
                      </div>
                     </Row>
                    </Card>
                 </div>
             )})}
           </div>
          );
          const texts = (<div style={{marginTop:"0.5rem",color:"#0086FF",fontSize:"18px",textAlign:"center"}}>Interview Rounds Details</div>)
          return(
            <>
            <div>
            <Popover placement="bottom"  content={content}>
             <Badge style={{cursor: "pointer",fontSize:"16px"}} status={badgeHash[record.hiringStatus]} text={record.hiringStatus} />
            </Popover>
            </div>
            <div style={{ marginLeft: "1rem"}}>Round {record.currentRound}</div>
            </>
          )}
      },
      {
        title: <div className={classes.headerName} style={{minWidth:"18rem",textAlign:"center",alignItems:"center"}}>
                        <span>TRAINING INSTITUTE</span>
                        {/* {customFilterPopover("institute", <DownOutlined />)} */}
  
                  </div>,
        dataIndex: "institute",
        key: "institute",
        width:"13%",
        render:(text,record)=>{
          return(
            <div>
              {record?.instituteName?record?.instituteName:"NA"}
            </div>)}
      },
      {
        title: <div className={classes.headerName} style={{minWidth:"11rem",textAlign:"center",alignItems:"center"}}>
                        <span>COLLEGE NAME</span>
                        {/* {customFilterPopover("collegeName", <DownOutlined />)} */}
  
                  </div>,
        dataIndex: "collegeName",
        key: "collegeName",
        width:"13%",
        render:(text,record)=>{
          return(
            <div>
              {record?.collegeName?record?.collegeName:"NA"}
            </div>)}
      },
    
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      
      setSelectedRow(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
    },
    getCheckboxProps: (record) => ({
    }),
  };


    return (
      <>
       
        
        
        
        <div
          style={{
            padding: "2%",
            width: "100%",
            boxSizing:"border-box",
            height:"91vh",
            overflowY:"auto",
          }}
        >
          <h1 style={{ fontWeight:"bold",color:"#161616",fontSize:"1.5rem"}}>
         Interviews
        </h1>
           <div style={{display:'flex',flexDirection:'row',gap:"2.8%",marginBottom:"1rem",height:"13rem"}} >
          
                <Card bordered={false} style={{ flex:"1 1 35%"}}>
                <div  style={{display:"flex",justifyContent:"center",alignItems:"left",flexDirection:"column",textAlign:"center",marginTop:"2rem"}}>
                  <div
                    style={{
                      fontSize: "40px",
                    }}
                    >
                    <b>{schedule}</b>
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                    }}>
                    <b>Scheduled Interviews</b>
                  </div>
                  <div
                    style={{
                      color:"#6F6F6F"
                    }}>
                   Last Updated :  {dates}
                  </div>
                  </div>
                </Card>
              
              
                <Card style={{ flex: "1 1 60%", backgroundColor: "#E6F3FF",display:"flex",flexDirection:'row',gap:"5%",flexWrap:"nowrap"}}>
                 <div  style={{display:"flex",flexDirection:'row',gap:"5%",flexWrap:"nowrap",}}>
                    <div >
                      <img src={iconInterview} style={{height:"11rem"}}/>
                    </div>
                    <div  style={{display:"flex",justifyContent:"center",alignItems:"left",flexDirection:"column",textAlign:"left"}}>
                      <h2 style={{fontSize:"23px",marginBottom:"0.3rem"}}><b>Interview Information</b></h2>
                      <p style={{fontSize:"14px", color:"#6F6F6F"}}>
                        The daily interview schedule for the fresher can be seen 
                        here.
                      </p>
                    </div>

                    </div>
                  
                </Card>
             
          {/* </PageHeader> */}
        </div>
          <Row> 
         <div style={{ display: 'flex', gap:"1rem",justifyContent:"space-between",width:"98%",marginBottom:"-0.7rem"}}>
          <div >
                
                <div className={classes.searchDiv}>
                    {/* <div>
                        <img src={searchIcon}></img>
                    </div> */}
           
                    <Input
                     type="text"
                     size="medium"
                     allowClear={true}
                     prefix={<SearchOutlined className="custom-search-icon" />}
                     placeholder='Search'
                     style={{color:"#161616",}}
                     onChange={(event)=>{
                      setSearchVal(event.target.value)
                     }}
                     />
                </div>
          </div>
              <div className="head-icon">    
    
                       <div className="head-icon">
                       {customPopover(<img src={filter} onClick={()=>{}} />)}</div>

                            <div className="head-icon">
                                      <img src={Download}
                                        style={{ width: "24", height: "30px", marginRight: '10px' }}  
                                        onClick={()=>{
                                          Api.get('/hiring-overview/interviews')
                                             .params({
                                              download:true,
                                              // candidateType:"Fresher",
                                              // search: searchVal,
                                              // startDate: startDate,
                                              // endDate:endDate,
                                              ...filterData
                                             })
                                          // ?download=true&candidateType=Fresher&startDate=${startDate}&endDate=${endDate}`)
                                             .send((response) => {
                                              if (typeof response != "undefined") {
                                               window.open(response, "_blank")?.focus();
                                              }
                                           })
                                           }}
                                        />
                            </div>
                            <Space direction="vertical">
                               <RangePicker style={{color:"blue"}}
                                // bordered={false}
                                format={dateFormat}
                                onChange={(date, dateString) => {
                       
                                //  setDateVal(dateString)
                                 setStartDate(dateString[0])
                                 setEndDate(dateString[1])
                                }}/>
                            </Space>  
                       </div>
             </div>
            </Row>   
              <Row style={{marginTop:"1.5rem"}}>
                    <Table columns={columns} dataSource={interviewLists} loading={loader}
                        // rowSelection={rowSelection}
                        scroll={{
                            x:2100,
                            y:200
                          }}
                        pagination={{ 
                          position: ["bottomRight"],
                          showSizeChanger: true,
                          pageSizeOptions:[10, 30, 50, 100],
                          size:"small",
                          onChange: handlePageChange,
                          onShowSizeChange: handlePageChange,                      
                        }}/>
              </Row>

        </div>
      </>
      );

}

export default InterviewExp;