import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    feedbackResponseList:[],
   columns:[]  
};

const feedbackResponseSlice = createSlice({
  name: "feedbackResponse",
  initialState,
  reducers: {
    updatefeedbackResponseList(state, action) {
      state.feedbackResponseList = [ ...action.payload];
    },
   updateColumns(state,action){
    state.columns=[...action.payload]
   },
  },
});

export default feedbackResponseSlice.reducer;
export const feedbackResponseActions= feedbackResponseSlice.actions;