import React,{ useState,useEffect } from "react";
import { Form, Input, Button, Typography, Card, Row, Col, Modal } from "antd";
import login1 from "../../assets/login1.svg"
import login2 from "../../assets/login2.svg"
import login3 from "../../assets/login3.svg"
import loginBackgroung from "../../assets/loginbackground.svg"
import loginReverse from "../../assets/loginReverse.svg"
import { keyframes } from "styled-components";
import { bounce } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { RotateLeftOutlined } from "@ant-design/icons";
const Designbackground = ({value}) =>{
    const [imageUrl,setImageUrl] = useState(login1)
    const [count,setCount] = useState(0)
    useEffect(() => {
    
        let myInterval = setInterval(() => {
          setCount(count+1)
          if(count%3===1){
                setImageUrl(login1)
              }
              else if(count%3===2){
                setImageUrl(login2)
              }
              else{
                setImageUrl(login3)
              }
         
        }, 3100)
        return () => {
          // history.push("/thank-you")
          clearInterval(myInterval);
         
        };
      });
     
  return(
    <div
    style={{
      width: "100%",
      height : "100%",
      backgroundSize: "cover",
      backgroundPosition:"end",
      backgroundRepeat: "no-repeat",
      justifyContent:"end",
      backgroundImage: `url(${loginBackgroung})`,
      
      

    }}
    className={value!=="login" ? "rotate90":""}
  >
  <Row justify="center">
    <Col style={{marginTop:"30%"}} offset={4}>
    <StyleRoot>
      <div class="bounce">
    <img  src={imageUrl} style={{width:"90%",}}  className={value!=="login" ? "rotate90":""}/>
  </div>
  </StyleRoot></Col>
  </Row>
    
  </div>
  )
}
export default Designbackground