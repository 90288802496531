import { Col, Row, Dropdown, Menu, Select, Badge, Tooltip } from "antd";
import React from "react";
import { connect } from "react-redux";
import "services/styles.css";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import "./styles.css";
import { Color } from "services";
import { candidateJobFilter, getJobsForDropdown, inputFieldType, operationType } from "./redux/action";
import { withRouter } from "react-router";
import store from "store";
import classes from "./candidateCard.module.css";

const { Option } = Select;

class CandidateCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vacanciesCount: 0,
      selectedAgency:undefined,
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      }, };
  }
  
  componentDidMount() {
    this.props.getJobsForDropdown();
    window.addEventListener("resize", this.handleResize);

    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };

  // componentDidMount() {
  //   this.props.getJobsForDropdown();
  // }

  
  handleJobMenuItem = (job) => {
    let jobValues=[]
     if(job !== [] && job.length !== 0) {
      this.props.updateInputField(inputFieldType.CANDIDATE_JOB_DESCRIPTION, job);
      this.props.getAllCandidates(this.props.history);
     }else{
      this.props.updateInputField(inputFieldType.CANDIDATE_JOB_DESCRIPTION, []);
      this.props.getAllCandidates(this.props.history);
     }
      let value= this.props.candidateJobs.filter(
            (candidateJob) => job.includes(candidateJob.id)
          )
          let positiveVacancies=0
          if(job?.length > 0){
          for(let i=0;i<value.length;i++){
            positiveVacancies=positiveVacancies+value[i].vacancies
          }
          if(job.length!==0){
         this.props.updateInputField(inputFieldType.TOTAL_EXPERIENCED_VACANCIES, positiveVacancies)
          }
       this.props.getAllCandidates(this.props.history);
    };
  
  if(job.length===0){
    this.props.getJobsForDropdown()
  }
  this.props.getAllCandidates(this.props.history);
  }

  
  handleJobMenuItemForHistory = (job) => {
    this.props.updateInputField(
      inputFieldType.CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY,
      job
    );
    this.props.getAllCandidates(this.props.history);
  };


  // handleJobFilterChange = (job) => {
  //   const {totalExperiencedVacancies} = this.props
  //   this.props.candidateJobFilter(job?job:[]);
  //   let tempVac=0;
  //   // job.length === 0
  //   //   ? 
  //   // this.props.updateInputField(
  //   //     inputFieldType.EXPERIENCED_VACANCIES,
  //   //     totalExperiencedVacancies
  //   //     )
  //   //   : 
   
  //   this.props.candidateJobs.filter(
  //     (candidateJob) => (job.length>0&&job.includes(candidateJob.id))||(job.length===0)
  //   ).forEach(element => {
  //     tempVac+=parseInt(element.vacancies) 
  //   });
  // ;
  //      this.props.updateInputField(
  //         inputFieldType.EXPERIENCED_VACANCIES,
  //         tempVac
  //         )
  //       //   this.props.candidateJobs.filter(
  //       //     (candidateJob) => job.includes(candidateJob.id)
  //       //   ).map((item,index)=>item.vacancies)
  //       // );
        
  //       this.props.candidateJobs.filter(
  //         (candidateJob) => job.includes(candidateJob.id)
  //       ).map((item,index)=>item.vacancies)
  //   this.props.getAllCandidates(this.props.history);
  // };


  handleAgencyMenuItem = (agency) => {
    // this.props.candidateAgency
    this.setState({selectedAgency:agency})
    // this.props.updateInputField(inputFieldType.CANDIDATE_AGENCY, agency);
    // this.props.getAllCandidates(this.props.history);
  };

  componentDidUpdate(prevProps, prevState){
    if (this.state.selectedAgency!==prevState.selectedAgency)
    {
      this.props.updateInputField(inputFieldType.CANDIDATE_AGENCY, this.state.selectedAgency);
      this.props.getAllCandidates(this.props.history);
    }
    else if(this.state.selectedAgency===undefined&&this.props.candidateAgency!==undefined)
    {
      this.props.updateInputField(inputFieldType.CANDIDATE_AGENCY, undefined);
      this.props.getAllCandidates(this.props.history);
    }
  }

  

  handleAgencyMenuItemForHistory = (agency) => {
    this.props.updateInputField(
      inputFieldType.CANDIDATE_AGENCY_FOR_HISTORY,
      agency
    );
    this.props.getAllCandidates(this.props.history);
  };

  handleRoundMenuItem = (round) => {
    this.props.updateInputField(inputFieldType.CANDIDATE_ROUND, round);
    this.props.getAllCandidates(this.props.history);
  };

  handleMaxExperience = (max) => {
    this.props.updateInputField(inputFieldType.CANDIDATE_EXPERIENCE_MAX, max);
    this.props.getAllCandidates(this.props.history);
  };
  handleMinExperience = (min) => {
    this.props.updateInputField(inputFieldType.CANDIDATE_EXPERIENCE_MIN, min);
    this.props.candidateExperienceMax === ""  || this.props.candidateExperienceMax === undefined ? "" 
    : this.props.getAllCandidates(this.props.history);
  };

  render() {
    const {
      candidateJobs,
      candidateJobDescriptionForHistory,
      totalExperiencedVacancies,
      candidateJobFilter,
      candidateJobDescription,
      candidateAgencyForHistory,
      candidateAgencies,
      candidateAgency,
      experiencedVacancies,
      candidateProfiles,
      candidateScreenType,
      jobStatus,
    } = this.props;
    const {user} = store.getState().auth
    const { width, } = this.state.windowDimensions;

    return (
      <div>{console.log("sssJobDecript",candidateJobDescription)}
      {width > 700 ?
        <div className={classes.container} style={{display:"flex",gap:"1.5rem",overflow:"auto",paddingBottom:"0.2rem"}}>
          <div className={"dashboard-candidate-box"}>
            <h3 style={{ fontSize: "1rem" }}>Job Title</h3>
            <Select
              allowClear
              showArrow={true}
              showSearch={true}
              mode="multiple"
              style={{
                width: "21rem",
                fontSize: "15px",
                height: "6.5vh",
                overflow: "scroll",
                marginTop: "0.2rem",
              }}
              optionFilterProp="children"
              bordered={false}
              placeholder="All"
              defaultValue={candidateJobDescription}
              value={candidateJobDescription}
              // onClear={()=>{this.handleClear()}} 
              onChange={(item) => {
                console.log("sssJob",item)
                this.handleJobMenuItem(item) }}
            >
              {candidateJobs?.filter((item)=>item.status.includes(jobStatus))?.map((item, i) => (
                <Option key={i} style={{ width: "100%" }} value={item.id}>
                    <Badge
                      status={item.status === "Active" ? "success" : "error"}
                    />{" "}
                    {item.title}
                </Option>
              ))}
            </Select>
          </div>
          <div  className={"dashboard-candidate-box"}>
            <h3 style={{ fontSize: "1rem" }}>Round</h3>
            <Select
              allowClear
              showArrow={true}
              showSearch={false}
              style={{
                width: "100%",
                fontSize: "15px",
                height: "6vh",
                overflow: "hidden",
                marginTop: "0.2rem",
              }}
              bordered={false}
              placeholder="Select  Rounds"
              onChange={(item) => this.handleRoundMenuItem(item)}
            >
              {[0, 1, 2, 3, 4, 5, 6].map((item, i) => (
                <Option key={i} style={{ width: "100%" }} value={item}>
                  <Tooltip title={item}>{item}</Tooltip>
                </Option>
              ))}
            </Select>
          </div>
           {user?.role.includes("Sourcer") || user?.role.includes("HR") || 
            user?.role.includes("Recruiter") ||user?.role.includes("Admin")|| user?.role.includes("Super-Admin") ?
          //  { user?.role?.includes("Agency") || user?.role?.includes("Shortlister") || user?.role?.includes("Panelist")
          <div className={"dashboard-candidate-box"}>
            <h3 style={{ fontSize: "1rem" }}>Agency</h3>
            { (candidateScreenType === "History" ||
              candidateScreenType === "Duplicate")?
            <Select
              mode={"multiple"}
              allowClear
              showArrow={true}
              showSearch={false}
              style={{
                width: "100%",
                fontSize: "15px",
                height: "6vh",
                overflow: "hidden",
                marginTop: "0.2rem",
              }}
              bordered={false}
              placeholder="Select Agency"
              value={candidateAgencyForHistory?.length>0?candidateAgencyForHistory:undefined}
              onChange={(item) => {
                 this.handleAgencyMenuItemForHistory(item)
              }}
            >
              {candidateAgencies?.map((item, i) => (
                <Option key={i} style={{ width: "100%" }} value={item.id}>
                  {/* <Tooltip title={item.agencyName}> */}
                   <Badge
                      status={item.status === "Approved" ? "success" : item.status === "Pending" ? "warning" : "error"}
                    />{" "}
                    {item.agencyName}
                  {/* </Tooltip> */}
                </Option>
              ))}
            </Select>
           :
            <Select
             value={candidateAgency&&candidateAgency!==""?candidateAgency:undefined}
              allowClear
              showArrow={true}
              showSearch={false}
              style={{
                width: "100%",
                fontSize: "15px",
                height: "6vh",
                overflow: "hidden",
                marginTop: "0.2rem",
              }}
              bordered={false}
              placeholder="Select Agency"
            //   defaultValue={
            //  }
              onChange={(item) => {
                
                this.handleAgencyMenuItem(item)}}
            >
              {candidateAgencies?.map((item, i) => (
                <Option key={i} style={{ width: "100%" }} value={item.id}>
                  {/* <Tooltip title={item.agencyName}> */}
                   <Badge
                      status={item.status === "Approved" ? "success" : item.status === "Pending" ? "warning" : "error"}
                    />{" "}
                    {item.agencyName}
                  {/* </Tooltip> */}
                </Option>
              ))}
            </Select>}
          </div>
          :""
          }
          <div>
            <h3 style={{ fontSize: "1rem" }}>Experience</h3>
            <div style={{ width: "103%",borderRadius:"1px",boxShadow: `0px 1px 5px grey`,display: "flex",marginTop:"0.2rem",height:"2.35rem", gap: "0.5rem",backgroundColor:"white" }}>
              <Select
                allowClear
                showArrow={true}
                showSearch={false}
                style={{
                  width: "5rem",
                  fontSize: "14px",
                  height: "5vh",
                  overflow: "hidden",
                  marginTop: "0.2rem",
                  marginLeft:"0.5rem",
                  opacity:"0px"
                }}
                bordered={false}
                placeholder="Min Year"
                onChange={(item) => this.handleMinExperience(item)}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
                  <Option key={i} style={{ width: "100%" }} value={item}>
                    <Tooltip title={item}>{item}</Tooltip>
                  </Option>
                ))}
              </Select>
              <span style={{ fontSize: "1.5rem", alignSelf: "center" }}>-</span>
              <Select
                allowClear
                showArrow={true}
                showSearch={false}
                style={{
                  width: "5rem",
                  fontSize: "14px",
                  height: "5vh",
                  overflow: "hidden",
                  marginTop: "0.2rem",
                }}
                bordered={false}
                placeholder="Max Year"
                onChange={(item) => this.handleMaxExperience(item)}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
                  <Option key={i} style={{ width: "100%" }} value={item}>
                    <Tooltip title={item}>{item}</Tooltip>
                  </Option>
                ))}
              </Select>
            </div>
          </div>
         
          <div className={"dashboard-candidate-box"}>
            <h3 style={{ fontSize: "1rem" }}>Profiles/Vacancies</h3>
            <div
              style={{
                width: "99%",
                fontSize: "12.5px",
                height: "6vh",
                overflow: "hidden",
                boxShadow: `0px 1px 5px grey`,
                borderRadius: "1px",
                background: Color.white,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingLeft: "20px",
                marginTop: "0.2rem",
              }}
            >
              <span style={{fontWeight:'bold'}}>{candidateProfiles}/{totalExperiencedVacancies}</span>
              {/* <span style={{ marginLeft: "0.3rem" }}>Profiles/Vacancies</span> */}
            </div>
          </div>
        </div>
      :
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",gap:"0.5rem"}}>
        <div className={"dashboard-candidate-box"}>
            <h3 style={{ fontSize: "1rem" }}>Job Title</h3>
            <Select
              allowClear
              showArrow={true}
              showSearch={true}
              mode="multiple"
              style={{
                width:"45vw",
                fontSize: "15px",
                height:width > 700 && "7vh",
                overflow: "scroll",
                marginTop: "0.2rem",
              }}
              optionFilterProp="children"
              bordered={false}
              placeholder="All"
              defaultValue={candidateJobDescription}
              value={candidateJobDescription}
              // onClear={()=>{this.handleClear()}} 
              onChange={(item) => {
                console.log("sssJob",item)
                this.handleJobMenuItem(item) }}
            >
              {candidateJobs?.filter((item)=>item.status.includes(jobStatus))?.map((item, i) => (
                <Option key={i} style={{ width: "100%" }} value={item.id}>
                    <Badge
                      status={item.status === "Active" ? "success" : "error"}
                    />{" "}
                    {item.title}
                </Option>
              ))}
            </Select>
          </div>
          {/* <div className={"dashboard-candidate-box"} style={{marginRight:"0.2rem"}}>
            <h3 style={{ fontSize: "1rem" }}>Round</h3>
            <Select
              allowClear
              showArrow={true}
              showSearch={false}
              style={{
                width:"45vw",
                fontSize: "15px",
                height:width > 700 && "6vh",
                overflow: width > 700 &&"hidden",
                marginTop: width > 700 ?"0.5rem":"0.2rem",
              }}
              bordered={false}
              placeholder="Select  Rounds"
              onChange={(item) => this.handleRoundMenuItem(item)}
            >
              {[0, 1, 2, 3, 4, 5, 6].map((item, i) => (
                <Option key={i} style={{ width: "100%" }} value={item}>
                  <Tooltip title={item}>{item}</Tooltip>
                </Option>
              ))}
            </Select>
          </div> */}
          <div style={{marginRight:"",marginTop: "0.2rem",}}>
            <h3 style={{ fontSize: "1rem" }}>Experience</h3>
            <div style={{width:"44vw",borderRadius:"1px",boxShadow: `0px 1px 5px grey`,display: "flex",marginTop:"0.1rem",height:"2rem",backgroundColor:"white" }}>
              <Select
                allowClear
                showArrow={true}
                showSearch={false}
                style={{
                  width: "50%",
                  fontSize: "14px",
                  height:width > 700 && "6vh",
                  overflow: width > 700 &&"hidden",
                  marginTop: width > 700 ?"0.5rem":"",
                  // marginLeft:"0.5rem",
                  opacity:"0px"
                }}
                bordered={false}
                placeholder="Min Year"
                onChange={(item) => this.handleMinExperience(item)}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
                  <Option key={i} style={{ width: "100%" }} value={item}>
                    <Tooltip title={item}>{item}</Tooltip>
                  </Option>
                ))}
              </Select>
              <span style={{ fontSize: "1rem", alignItems: "center" }}>-</span>
              <Select
                allowClear
                showArrow={true}
                showSearch={false}
                style={{
                  width: "50%",
                  fontSize: "14px",
                  height:width > 700 && "6vh",
                  overflow: width > 700 &&"hidden",
                  marginTop: width > 700 ?"0.5rem":"",
                }}
                bordered={false}
                placeholder="Max Year"
                onChange={(item) => this.handleMaxExperience(item)}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
                  <Option key={i} style={{ width: "100%" }} value={item}>
                    <Tooltip title={item}>{item}</Tooltip>
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

const routerCandidateCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateCard);

export default withRouter(routerCandidateCard);
