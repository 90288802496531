import { Button, Typography } from 'antd';
import { useHistory } from 'react-router';
import classes from './sheduleItem.module.css';
import { useState,useEffect } from 'react';
import { Api } from 'services';
import moment from "moment";
import resume from "assets/resume icon.svg"
import { FileDoneOutlined } from '@ant-design/icons';
import { useWindowDimensions } from 'components/layout';
import PdfViewModal from 'components/pdfViewModal';
const SheduleItem=(props)=>{
    const { height, width } = useWindowDimensions(); 
    const [schedule,setSchedule] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pdfModal, setPdfModal] = useState(false);

    const item=props?.item;

    const history=useHistory();
    

    return(
        <div className={classes.container}>              
                <div>
                <span> <h3>{item?.candidateName?item?.candidateName:"NA"}
                {width>700 ?
                    <a target="_blank" style={{marginLeft:"10px",}}  href={item.resumeLink}>Resume<FileDoneOutlined/></a>
                    :
                    <a style={{marginLeft:"10px"}} onClick={()=>{setPdfModal(true)}}>Resume<FileDoneOutlined/></a>
                }
                </h3></span>
                      <h4>ROUND:</h4>
                      <p>{item?.roundType && item?.roundType === "Relationship" ? "HR Relationship" 
                       : item?.roundType ? item?.roundType
                       :"NA"}</p>
                      <h4>DATE & TIME:</h4>
                      <Typography.Text ellipsis={{tooltip:true}} style={{width:"100%",}}>
                                    <span>
                                            {moment(new Date(item?.interviewDate)).format('YYYY-MM-DD')?moment(new Date(item?.interviewDate)).format('YYYY-MM-DD, '):"NA"}
                                           {item?.startTime} - {item?.endTime}
                                    </span>
                            </Typography.Text>
                      <h4>MEET LINK:</h4>
                      <Typography.Text ellipsis={{tooltip:true}} style={{width:"100%",marginBottom:"1rem"}}>
                                    <a href={item?.meetingLink} target="_blank">{item?.meetingLink?item?.meetingLink:"NA"}</a>
                                   
                      </Typography.Text>         
                </div>
                <div>
                           {width>700? <Button onClick={()=>{
                                    history.push({pathname:'/panelist/interviewSchedule',
                                state:item.candidateName});
                                    props.setNavlist(props.navList.map((item,index)=>{
                                        if(index===1){
                                            return {...item,clicked:true}
                                        }
                                        return {...item,clicked:false}
                                    }))
                                }}>Details</Button>:<><h3>Job Title:</h3><p style={{color:"grey"}}>{item?.jobTitle}</p></>}
                </div>
                {pdfModal && <PdfViewModal pdf={item.resumeLink} pdfModal={pdfModal} setPdfModal={setPdfModal} />}
        </div>
    )
}

export default SheduleItem;
