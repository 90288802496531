import {Modal,Select,} from "antd";
import React, { useEffect } from "react";
import { Api } from "services";
import { connect } from "react-redux";
import { useWindowDimensions } from "./windowDimensions";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "./style.css"
import { useState } from "react";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewModal = (props) =>{
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const {width}=useWindowDimensions();

  const onDocumentLoadSuccess=({ numPages }) => {
    setNumPages(numPages);
  }
  return(
      <Modal open={props.pdfModal}
              centered
              onOk={() =>{props.setPdfModal(false);}}
              onCancel={()=>{props.setPdfModal(false);}}
              footer={false}
      >
      <div className='jdPdf' style={{height:'80vh',margin:"1.5rem 0 0.5rem 0",}}>
        {/* {width > 700 ? (
          <iframe
            src={props.pdf}
            style={{ width: "100%", height: "80vh" }}
            className="view-pdf-iframe"
          ></iframe>
        ) : ( */}
          <Document
            file={props.pdf}
            width="100%"
            height="100%"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}          
          </Document>
        {/* )} */}
      </div>
      </Modal>
  )
}
export default PdfViewModal;