import React, { useState } from "react";
import { Card,Form, Input,Row,Col,Radio,Slider,Upload,Button,Avatar ,InputNumber,Modal} from "antd";
import { mapDispatchToProps, mapStateToProps } from "../redux/container"
import { UserOutlined,UpOutlined,LoadingOutlined,UploadOutlined } from "@ant-design/icons";
import { connect,useDispatch ,useSelector} from "react-redux";
import { Notification } from "services";
import {CANDIDATE_IMAGE_VIEW_MODAL, CANDIDATE_IMAGE , feedbackOtherFieldType, operationType} from "../redux/action";
import { HrFeedbackActions } from "./store";
import {ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";

const Miscellaneous = (props) => {
  const { basicInfo } = useSelector((state) => state.HrFeedback)
   const { feedback } = useSelector((state) => state.candidate.feedbackCandidateInfo)
  let feedbackType = feedback?.find((value) => value.roundType === "HR")
    const [rate,setRate] = useState(1)
    const dispatch = useDispatch()
    const { feedbackCandidate, updateInputField, calculateCompletion, feedbackStatus, maritalStatusList, automotiveBackground } = props;
    const { feedbackQuestions, feedbackSubmitLoader, postRecruitmentFeedback, performOperation, feedbackCandidateInfo,  } = props;
    const [ inputValue,setInputValue] = useState(1)
    const [openModal,setOpenModal]=useState(false)
    const handleOk = () => {
      setOpenModal(false)
      { feedbackCandidateInfo.feedbackRecentPhoto === undefined  && !feedbackType?.screenshot
        ? Notification.error('Please attach a screenshot of the candidate.')
        : postRecruitmentFeedback()}
        setTimeout(() => {
         props.onNext("basicInfo")
        },5000 );
    }
    const handleCancel = () => {
      setOpenModal(false)
    }
    const onFInish=(values) => {
              if(!values.experience)
              {
                values.experience=0
              }
              if(!values.experienceInMonths)
              {
                values.experienceInMonths=0
              }
              let overallRating= (values.overallRating/10)
              let rateYourself= (values.rateYourself/10)
              let communication= (values.communication/10)
              let bodyLanguage= (values.bodyLanguage/10)
              let attitude= (values.attitude/10)
              delete(values.overallRating)
              delete(values.rateYourself)
              delete(values.communication)
              delete(values.bodyLanguage)
              delete(values.attitude)
              const value={
                ...values,
                OverallRating:overallRating,
                RateYourself:rateYourself,
                Communication:communication,
                BodyLanguage:bodyLanguage,
                Attitude:attitude
              }               
              dispatch(HrFeedbackActions.updatefeedback(value))
              if(Math.abs(((values.experience*12)+values.experienceInMonths)-totalExperienced)>2)
              {   let title="difference between total experience with company details is greater than two months"
                     setOpenModal(true)
                      
                      
                     return;
              }
        

          { feedbackCandidateInfo.feedbackRecentPhoto === undefined && !feedbackType?.screenshot
               ? Notification.error('Please attach a screenshot of the candidate.')
               : postRecruitmentFeedback()}
               setTimeout(() => {
                props.onNext("basicInfo")
               },5000 );

    }
    const goBack = () => {
      props.onBack("financialDetails")
  } 
    const marks = {
    0: '0',
    10: '1',
    20: '2',
    30: '3',
    40: '4',
    50: '5',
    60: '6',
    70: '7',
    80: '8',
    90: '9',
    100: '10',
    }
    const onChange = e => {    
    }
    
let experience1=moment(basicInfo.Lefton1,"YYYY-MM-DD").diff(moment(basicInfo.Joinedon1,"YYYY-MM-DD"),"months")
let experience2=moment(basicInfo.Lefton2,"YYYY-MM-DD").diff(moment(basicInfo.Joinedon2,"YYYY-MM-DD"),"months")
let experience3=moment(basicInfo.Lefton3,"YYYY-MM-DD").diff(moment(basicInfo.Joinedon3,"YYYY-MM-DD"),"months")
let experience4=moment(basicInfo.Lefton4,"YYYY-MM-DD").diff(moment(basicInfo.Joinedon4,"YYYY-MM-DD"),"months")
let experience5=moment(basicInfo.Lefton5,"YYYY-MM-DD").diff(moment(basicInfo.Joinedon5,"YYYY-MM-DD"),"months")
if(isNaN(experience1))
{
  experience1=0
}
 if(isNaN(experience2))
{
  experience2=0
}
 if(isNaN(experience3))
{
  experience3=0
}
 if(isNaN(experience4))
{
  experience4=0
}
 if(isNaN(experience5))
{ 
  experience5=0
}
const totalExperienced=experience1+experience2+experience3+experience4+experience5
    let [color,setColor]=useState("white")
    const onRateChange = (value) => {
         setInputValue(value)      
    }
    
       function getBase64(img, callback) {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(img);
          }
        
         const imageBeforeUpload = (file) => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isJpgOrPng) {
              Notification.error('You can only upload JPG/PNG file!');
            } else if (!isLt2M) {
              Notification.error('Image must smaller than 5MB!');
            } 
            else {
              getBase64(file, imageUrl => {
                props.updateInputField(feedbackOtherFieldType.FEEDBACK_RECENT_PHOTO, [file]);
                props.updateInputField(feedbackOtherFieldType.FEEDBACK_RECENT_PHOTO_URL, imageUrl);
              });
            }
            return false;
          }
    return(
        <>
        <Card title="Miscellaneous"style={{border:"black",width: "100%" }}>
        <Button type="text" onClick={goBack}><ArrowLeftOutlined /></Button>
            <Form onFinish={onFInish} layout="vertical">
            <Row><Form.Item
                name="experience"
                label={<b>Total Experience</b>}
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue= { feedbackType!==undefined ? feedbackType?.details?.experience :  basicInfo?.experience}
              >
                {/* <Input
                //   value={experience}
                  bordered={false}
                  style={{ borderBottom: "2px  solid grey" }}
                /> */}
                <InputNumber placeholder="years" min={0} max={20}/>
              </Form.Item> 
              
              <Form.Item
                name="experienceInMonths"
                style={{marginTop:"30px"}}
                rules={[
                  {
                    required: false,
                  },
                ]}
                initialValue={feedbackType !==undefined ? feedbackType?.details?.experienceInMonths :   basicInfo?.experienceInMonths}
              >
                {/* <Input
                //   value={experience}
                  bordered={false}
                  style={{ borderBottom: "2px  solid grey" }}
                /> */}
                <InputNumber placeholder="months" min={0} max={12}  />
              </Form.Item></Row>
                <Row>
                    <Form.Item
                    name="Growing"
                    label={<b>Awareness about brigosha</b>}
                    rules={[{
                      required:true
                  }]}
                  initialValue={feedbackType!=undefined && !basicInfo?.Growing  ? feedbackType.details.Growing   :   basicInfo?.Growing}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    
                    </Row>
                    <Row>
                    <Form.Item
                    name="SeeYourself"
                    label={<b>Where do you see Yourself after 3 to 5 years</b>}
                    rules={[{
                      required:true
                  }]}
                  initialValue={feedbackType!=undefined && !basicInfo?.SeeYourself ? feedbackType.details.SeeYourself:  basicInfo?.SeeYourself}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                </Row>
                    <Row>
                        
                            <Form.Item
                            name="CarrierAspiration"
                            label={<b>What are your career aspirations</b>}
                            rules={[{
                              required:true
                          }]}
                          initialValue={feedbackType!=undefined && !basicInfo?.CarrierAspiration ? feedbackType?.details?.CarrierAspiration : basicInfo?.CarrierAspiration}
                             >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item></Row>
                    <Row>
                  
                    </Row>
                    <Row>
                        <Col span={18}>
                        <Form.Item name="rateYourself" label={<p><b>How do you rate in terms of 1 to 10</b></p>} rules={[{
                                required:true
                            }]}
                            initialValue={feedbackType!=undefined ? feedbackType?.details?.RateYourself*10 :basicInfo?.RateYourself*10}>
                    
                    <Slider step={null} marks={marks}   onChange={onRateChange} /></Form.Item>
                    <Form.Item name="communication" label={<p><b>Communication</b></p>} rules={[{
                                required:true
                            }]}
                            initialValue={feedbackType!=undefined ? feedbackType?.details?.Communication*10 :  basicInfo?.Communication*10}>
                    
                    <Slider step={null} marks={marks}   onChange={onRateChange} /></Form.Item>
                    <Form.Item name="attitude" label={<p><b>Attitude</b></p>} rules={[{
                      required:true
                  }]}
                  initialValue={feedbackType!=undefined ? feedbackType?.details?.Attitude*10 :  basicInfo?.Attitude*10}>
                    
                    <Slider step={null} marks={marks}   onChange={onRateChange}/></Form.Item>
                    <Form.Item name="bodyLanguage" label={<p><b>Body language</b></p>} rules={[{
                      required:true
                  }]}
                  initialValue={feedbackType!=undefined ? feedbackType?.details?.BodyLanguage*10 : basicInfo?.BodyLanguage*10}>
                    
                    <Slider step={null} marks={marks} onChange={onRateChange}/></Form.Item>
                    <Form.Item name="overallRating" label={<p><b>Candidate overall rating</b></p>} rules={[{
                                required:true
                            }]}
                            initialValue={ feedbackType!=undefined ? feedbackType?.overallRating*10 : basicInfo?.overallRating*10}>
                    
                    <Slider step={null} marks={marks}  onChange={onRateChange} /></Form.Item></Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                              name="candidateresult"
                            label={<b>Candidate Result</b>}
                            initialValue={feedbackType?.status!=undefined && !basicInfo?.candidateresult ? feedbackType?.status: basicInfo?.candidateresult}
                    >
                        <Radio.Group onChange={onChange} >
                        <Radio value="Selected">Selected</Radio>
                          <Radio value="Not Selected">Not Selected</Radio>
                          <Radio value="Hold">On Hold</Radio>
                           
                           </Radio.Group>
                    </Form.Item>
                    </Col></Row>
                    <Row>
                            <Form.Item
                              name="comments"
                            label={<b>Comments</b>}
                            rules={[{
                              required:true
                          }]}
                          initialValue={feedbackType!=undefined ? feedbackType?.overallComment : basicInfo?.comments}
                    >
                        <Input bordered={false} style={{borderBottom:"2px  solid grey"}}/>
                    </Form.Item>
                    </Row>
                    <Row style = {{marginLeft : '300px', marginTop: "70px"}}>
                  {feedbackCandidateInfo.feedbackRecentPhoto !== "" || feedbackType?.screenshot ? (
                    <img
                      src={feedbackCandidateInfo.feedbackRecentPhotoUrl !==undefined ? feedbackCandidateInfo.feedbackRecentPhotoUrl : feedbackType?.screenshot } 
                      style={{ width: "200px", height: "100px" }}
                      alt=""
                      onClick={() => {
                        updateInputField(CANDIDATE_IMAGE, feedbackCandidateInfo.feedbackRecentPhotoUrl);
                        performOperation(CANDIDATE_IMAGE_VIEW_MODAL, true);
                      }}
                  />
                  ) : (
                    <Avatar size="100" icon={<UploadOutlined />} />
                  )}
                  <Col span={6} style = {{marginLeft : "25px", marginTop : "25px"}}>
                    <Upload
                      onRemove={() => updateInputField(feedbackOtherFieldType.FEEDBACK_RECENT_PHOTO, [])}
                      beforeUpload={imageBeforeUpload} 
                      showUploadList={false}
                    >
                      <Button icon={<UploadOutlined />} style = {{width: '305px',
                                                                  height: '52px'}} className = "text-bold">
                        Attach a screenshot of the candidate<span style={{color: "red"}}>*</span>
                      </Button>
                    </Upload>
                  </Col>
              </Row>
              
              <Modal title="Experience Mismatch" visible={openModal} onOk={handleOk} onCancel={handleCancel}>
              <p><b>Total experince</b>- {Math.floor(totalExperienced / 12)}{" "} years {totalExperienced % 12}{" "}months</p>
        <p><b>Kindly give total experience near to above mentioned experience</b></p>
        <p style={{color:"green"}}>Click OK Button to continue submitting</p>
        <p style={{color:"red"}}>Click Cancel Button to go back</p>
        
      </Modal>
                {/* <SecondaryButton onClick={this.closeModal} id="" text="Discard" className="r-c-fe" size="medium" style={{ padding: "25px 50px", fontSize: "1.0em" }} /> */}
                <Row justify="end" marginTop="10px"><Button size="large" style={{paddingLeft:"50px",paddingRight:"50px"}} type="primary" htmlType="submit" loading={feedbackSubmitLoader}
                 
                     >
                       Submit</Button></Row>
            </Form>
      
    </Card>
    
    </>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Miscellaneous)