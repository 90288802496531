import { Modal,Row,Col,Avatar,Button,Popover,Progress, Select} from "antd";
import React,{useEffect, useState} from "react";
import moment from "moment";
import { Color } from "services";
import ViewImage from "pages/candidate/viewImage";
import { mapDispatchToProps, mapStateToProps } from "../redux/container"
import {dateFormat,postDateFormat, operationType } from "../redux/action";
import { connect } from "react-redux";
import "./styles.css";
import { UserOutlined,UpOutlined,LoadingOutlined,UploadOutlined } from "@ant-design/icons";
import { CANDIDATE_IMAGE, CANDIDATE_IMAGE_VIEW_MODAL } from "pages/candidate/redux/action";
import BasicInfo from "./basicInformation";
import AcademicDetails from "./academicDetails";
import CompanyDetails from "./companyDetails";
import OfferDetails from "./offerDetails";
import LocationDetails from "./locationDetails";
import FinancialDetails from "./financialDetails";
import Miscellaneous from "./miscellaneous";
const {Option} =Select
const HrFeedback = (props) => {
  const [percent, setPercent] = useState(0)
   const[screenPhoto,setScreenPhoto]=useState("")
    const [card,setCard]=useState("basicInfo")
    

    const onNext = (nextCard) => {
     
      setCard(nextCard)
      setPercent(percent+14)
      if(card==="")
      {
        setPercent(100)
        setTimeout(() => {
          setPercent(0)
        }, 3000);
      }
    }
    const onBack = (backCard) => {
      setCard(backCard)
      setPercent(percent-14)
    }
    const {
        hrFeedbackModal,
        updateInputField,
        feedbackCandidateInfo,
        feedbackScrollPosition,
        feedbackSubmitted,
        performOperation,
        feedbackPageLoader,
        feedbackJobInterviewer,
        feedbackErrorPage,
        feedbackQuestionNotFound,
      } = props;
      const interviewSlots = feedbackCandidateInfo && feedbackJobInterviewer ? feedbackCandidateInfo.interviewSlots[0] : null;
      const jobId = feedbackCandidateInfo && feedbackCandidateInfo.jobs ? feedbackCandidateInfo.jobs.id : null
      const feedbacks = feedbackCandidateInfo && feedbackCandidateInfo.feedback ? feedbackCandidateInfo.feedback.slice().filter((x) => x.jobId === jobId).sort((a,b) => a.round > b.round ? 1: -1): null;
    const closeModal = () => {
        props.performOperation(operationType.HR_FEEDBACK_MODAL, false);
        setScreenPhoto("")
      };
      const [isModalVisible, setIsModalVisible] = useState(false);

      const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      
      };
        function onScreenshotChnage(value) {
   setScreenPhoto(value)
  }
    
      // if (interviewSlots === null || feedbackPageLoader || !feedbackJobInterviewer.panelist)
      // {
      //   spin=true
      // }
      // else{
      //   spin=false
      // }
  return(
  <Modal className="Modalcontent" visible={props.hrFeedbackModal} onOk={closeModal} onCancel={closeModal}
      footer={false}
      style={{width:"95%",height:"90%"}} >
        <h1 style={{height:100,color:"#00BFFF"}}>HR FEEDBACK</h1>
  { (interviewSlots === null || feedbackPageLoader || !feedbackJobInterviewer.panelist) ? <LoadingOutlined style={{fontSize: "40px",
  position: "absolute",
  left: "50%",
  top: "50%"}}/> :
  <>
      <div>
          <Row justify="end" style={{marginTop:"30px"}}>
           <Button type="text" style={{color:"#00BFFF"}} onClick={showModal}>
        <UpOutlined/>Interview rounds
      </Button></Row>
            <Modal className="interview" title="Interview Rounds" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} style={{ width: "100%" }} >
              <Row>  {screenPhoto==="" ? "" : 
                              <Col span={6} ><img src={screenPhoto} style={{ width: "200px", height: "200px" }} /></Col>}
                              
                              <Col span={6} offset={12}>
                                <Select
                                 
                                  placeholder="Select round"
                                  onChange={onScreenshotChnage} style={{ width: "80%" }}>
                                  {feedbackCandidateInfo.feedback.map((item) => {
                                    
                                    return (
                                      <Option value={item.screenshot}>round:{ item.round}</Option>
                                    )
                                  })}
                                  
                                </Select></Col>
                        </Row>
     <Row > {feedbacks?.map((feedback) =>  
                 
                    <Col span={12}><Row style={{marginTop:"10px"}}><p className = 'text-bold'>Round :</p> {feedback.round}  <p style={{color:"#00BFFF"}}><b>{feedback.roundType}</b></p></Row>
                    
                    <Row>Interviewer :{feedback.interviewer}</Row>
                    <Row style={{marginTop:"10px"}}>
                    <span >OverallRating :</span>{feedback.overallRating}</Row>
                    <Row style={{marginTop:"10px"}}><Popover content={feedback.overallComment} >
                      <span >OverallComment :</span> {feedback.overallComment}
                    </Popover></Row></Col>
                  
                )}</Row>
      </Modal>
      <Row> <Col span={6}>
        {feedbackCandidateInfo.candidatePhoto !== "" ? (
          <img
            src={feedbackCandidateInfo.candidatePhoto}
            style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            alt=""
            onClick={() => {
              updateInputField(CANDIDATE_IMAGE, feedbackCandidateInfo.candidatePhoto);
              performOperation(CANDIDATE_IMAGE_VIEW_MODAL, true);
            }}
          />
        ) : (
          <Avatar size="100" icon={<UserOutlined />} />
        )}
        <Row>
          <Col span={24}>Name of Candidate</Col>
          <Col span={24} style={{ color: Color.modalblue }}>
            {feedbackCandidateInfo.name}
          </Col>
        </Row>
      
      
        <Col span={24}>Round</Col>
        <Col span={24} style={{ color: Color.modalblue }}>
          {feedbackJobInterviewer.round}
        </Col>

        <Col span={24}>Round Type</Col>
        <Col span={24} style={{ color: Color.modalblue }}>
          {feedbackJobInterviewer.roundType}
        </Col>

        <Col span={24}>Name of Interviewer</Col>
        <Col span={24} style={{ color: Color.modalblue }}>
          {feedbackJobInterviewer.panelist? feedbackJobInterviewer.panelist.name : ""}
        </Col>

        <Col span={24}>Date of Interview</Col>
        <Col span={24} style={{ color: Color.modalblue }}>
          {interviewSlots.date
            ? moment(interviewSlots.date, postDateFormat).format(dateFormat)
            : null}
        </Col>

        <Col span={24}>Mode of Interview</Col>
        <Col span={24} style={{ color: Color.modalblue }}>
          {interviewSlots.interviewMode}
        </Col>

        <Col span={24}>Experience</Col>
        <Col span={24} style={{ color: Color.modalblue }}>
          {feedbackCandidateInfo.experienceYears} Years {feedbackCandidateInfo.experienceMonths !== 0 ? `${feedbackCandidateInfo.experienceMonths} Months` : ""}
        </Col>

        <Col span={24}>Current Location</Col>
        <Col span={24} style={{ color: Color.modalblue }}>
          {feedbackCandidateInfo.currentLocation}
        </Col>

        <Col span={24}>Preferred Location</Col>
        <Col span={24} style={{ color: Color.modalblue }}>
          {feedbackCandidateInfo.preferredLocation}
        </Col></Col>
        <Col span={14}>
          { card === "basicInfo"  ? <BasicInfo card={card} onNext={onNext} /> :
            card==="AcademicDetails"? <AcademicDetails card={card} onNext={onNext} onBack={onBack}/> : 
            card==="CompanyDetails" ? <CompanyDetails card={card} onNext={onNext} onBack={onBack}/> :
            card==="offerDetails" ? <OfferDetails card={card} onNext={onNext} onBack={onBack}/>: 
            card==="LocationDetails" ? <LocationDetails card={card} onNext={onNext} onBack={onBack}/> : 
            card==="financialDetails" ? <FinancialDetails card={card} onNext={onNext} onBack={onBack}/> :
           <Miscellaneous card={card} onNext={onNext} onBack={onBack}/>}
           
           </Col>
           <Col span={3} offset={1}><Progress
                type="circle"
                percent={percent}
                strokeColor={{
                  from: Color.blue,
                  to: Color.green,
                }}
              /></Col>
      </Row>
      
    </div>
    
              
    </>}
     </Modal>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(HrFeedback);