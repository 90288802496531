import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Modal, Input, Button, Form } from "antd";

import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { Color } from "services";
import { operationType, inputFieldType } from "./redux/action";
import { PrimaryButton } from "services/button";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlineClose } from "services/reactIcons";
import "./styles.css";
import { AlignRightOutlined } from "@ant-design/icons";

const CandidateEmailSend = (props) => {
  const {
    candidates,
    candidateEmailModal,
    candidateInfo,
    candidateEmailSuggestion,
    candidateMailTo
  } = useSelector((state) => state.candidate);

  const [email, setEmail] = useState(candidateInfo?.email);


  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(
      props.operations.performOperation(
        operationType.CANDIDATE_EMAIL_MODAL,
        false
      )
    );
    dispatch(
      props.operations.performOperation(
        operationType.CANDIDATE_EMAIL_SUGGESTIONS,
        false
      )
    );
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    dispatch(
      props.operations.performOperation(
        operationType.CANDIDATE_EMAIL_SUGGESTIONS,
        true
      )
    );
  };

  const selectEmail = (event) => {
    setEmail(event.email);
    dispatch(
      props.operations.performOperation(
        operationType.CANDIDATE_EMAIL_SUGGESTIONS,
        false
      )
    );
    
  };

  return (
    <div>
      <Modal
        title="Registration Link"
        centered
        // width="38%"
        className="sendInvititaion"
        bodyStyle={{ height: "35vh", color: Color.black }}
        visible={candidateEmailModal}
        closable={true}
        onCancel={closeModal}
        // onOk={this.onFinish}
        footer={[
          <PrimaryButton
            htmlType="submit"
            id="shortlistCandidate"
            onClick={() => closeModal}
            text="Send Mail"
            // className="modalButton"
            // size="large"
            style={{ width: "7rem", height: "2rem", borderRadius: "5px" }}
          />,
        ]}
      >
        <h3 style={{ fontWeight: "bold" }}>EMAIL OF CANDIDATE</h3>
        <div
          style={{
            width: "100%",
            position: "relative",
            display: "inline-block",
          }}
        >
          <Input
            name="email"
            size="middle"
            type="text"
            min={0}
            style={{ border: `none` }}
            placeholder="Type Email here"
            // onFocus={handleEmailSuggestions}
            value={email}
            onChange={handleChange}
          />
          {candidateEmailSuggestion && (
            <div
              style={{
                height: "18vh",
                position: "absolute",
                zIndex: "99",
                top: "100%",
                left: "0",
                right: "0",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                }}
              >
                {candidates
                  ?.filter((item) => item["email"].startsWith(email))
                  .map((candidate) => (
                    <Button
                      style={{
                        border: "none",
                        // textAlign:'left',
                        type: "text",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => selectEmail(candidate)}
                    >
                      {candidate?.email}
                      <AiOutlineClose
                        onClick={() => {
                          dispatch(
                            props.operations.performOperation(
                              operationType.CANDIDATE_EMAIL_SUGGESTIONS,
                              false
                            )
                          );
                        }}
                      />
                    </Button>
                  ))}
              </div>
            </div>
          )}
          <div
            style={{
              position: "absolute",
              width: "29.5rem",
              height: "9rem",
            }}
          >
            {/* <Button>{candidateMailTo.map((item)=>{})}</Button> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CandidateEmailSend;
