import {
  Col,
  Form,
  Modal,
  Row,
  Menu,
  Space,
  Dropdown,
  Checkbox,
  Avatar,
  Tooltip,
  Badge,
  message,
  Upload,
  Input,
  Button,
  Typography,
  Select,Tabs
} from "antd";
import classes from "./candidateViewModal.module.css";
import React, { useEffect } from "react";
import { Api } from "services";
import { connect } from "react-redux";
import { Color, InformationModal, modalContent, downloadPDF } from "services";
import { PrimaryButton, SecondaryButton } from "services/button";
import profileIcon from '../../assets/profileIcon.svg.svg'
import { TextFormDisplay, TextAreaFormInput } from "services/input";
import ResumeIcon from "../../assets/ResumeIconss.svg";
import ViewPDF from "./viewPDF";
import moment from "moment";
import {
  WarningOutlined,
  CheckSquareOutlined,
  FileTextOutlined,
  LoadingOutlined,
  CameraOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import {
  FiEdit2,
  FiCheck,
  FiDownload,
  AiOutlineStop,
  BiRefresh,
} from "../../services/reactIcons";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import {
  operationType,
  candidateFieldType,
  candidateFieldTypeHash,
  CANDIDATE_DETAILS,
  inputFieldType,
  updateInputField,
  getAllCandidates,
} from "./redux/action";
import "./styles.css";
import ViewImage from "./viewImage";
import candidate from "./candidate";
import CandidateJobChangeModal from "./candidateJobChangeModal";
import EditCandidateModal from "./editCandidateModal";
// import { useForm } from "antd/lib/form/Form";
import store from "store";
import { Notification } from "services";

const { Option } = Select;
const { TabPane } = Tabs;

class CandidateViewModal extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      emailUpdate: "",
      btnloader: false,
      resumeUpdate: "",
      resumeBtn: false,
      jobChangeId: "",
      jbchngloader: false,
      overallStatus: this.props.candidateInfo?.hackathonStatus,
      othersValue: null,      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      }, };
  }
  
  componentDidMount() {
 
    window.addEventListener("resize", this.handleResize);

    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  formRef = React.createRef();
  form_row = {
    padding:"0.5rem 0.1rem",
    height:"4.2rem",
    borderBottom: `2px solid ${Color.lightGrey}`,
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    textAlign:"start"
  };

  formTitle = {
    fontSize: "13px",
    color: "#808080",
    textAlign:"start"
  };

  handleResize = () => {
    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.candidateupdatedResume !== prevProps.candidateupdatedResume
    ) {
      this.props.getAllCandidates(
        this.props.history,
        this.props.candidateNewPageNumber
      );
    }
  }

  changeCandidateInfo = (action, value) => {
    const {
      candidateInfo,
      handleCandidateFlagEdit,
      updateInputField,
      candidateOriginalInfo,
    } = this.props;
    updateInputField(action, value);
    const type = candidateFieldTypeHash[action];
    const mark = value ? "mark" : "unmark";

    InformationModal.confirm(
      "",
      <div>
        Are you sure you want to {mark} the candidate as {type}?
      </div>,
      <div style={{ color: Color.darkGrey }}>
        {modalContent("Name:", candidateInfo?.name)}
      </div>,
      () => handleCandidateFlagEdit(),
      (close) => {
        updateInputField(CANDIDATE_DETAILS, candidateOriginalInfo);
        close();
      }
    );
  };

  closeModal = () => {
    const { updateInputField } = this.props;
    this.setState({ emailUpdate: "", jobChangeId: "" });
    updateInputField(inputFieldType.CANDIDATE_UPDATE_RESUME, "");
    this.props.performOperation(operationType.CANDIDATE_VIEW_MODAL, false);
    this.props.performOperation(operationType.EDIT_CANDIDATE_VIEW, false);
    this.props.performOperation(operationType.CANDIDATE_PHOTO_EDIT_BOOL, false);
    this.props.candidateJobChangeToggle(false, {});
    this.props.candidateRoundChangeToggle(false, {});
    this.setState({ othersValue : null })
  };

  onFinish = (id, status, comment,candidatePriority) => {
    
    this.props.handleCandidateStatus(id, status, comment, candidatePriority);
    this.props.getAllCandidates(this.props.history);
    updateInputField(candidateFieldType.CANDIDATE_PRIORITY, "");
    this.setState({ othersValue : null })
  };

  onReject = (id, status, comment,priority) => {
    const {
      updateInputField,
      candidateOriginalInfo,
      handleCandidateStatus,
    } = this.props;

    InformationModal.confirm(
      "",
      <div>
        Do you really want to reject this candidate? This process cannot be
        undone.
      </div>,
      "",
      () => {
        handleCandidateStatus(id, status, comment,priority);
        updateInputField(candidateFieldType.CANDIDATE_PRIORITY, "");
      },
      (close) => {
        updateInputField(CANDIDATE_DETAILS, candidateOriginalInfo);
        this.setState({ othersValue : null })
        close();
      }
    );
  };

  handleJobMenuModalItem = (job) => {
    this.props.updateInputField(inputFieldType.CANDIDATE_JOB_CHANGE, job);
  };

  jobMenuModal = (candidateJobChange, candidateJobs) => {
    return (
      <Menu
        selectedKeys={[candidateJobChange.title]}
        style={{ height: "72vh", overflow: "auto" }}
      >
        {candidateJobs?.map((job, index) => (
          <Menu.Item
            className="text-ellipsis"
            style={{ width: 200 }}
            key={job.title}
            onClick={() => this.handleJobMenuModalItem(job)}
          >
            <Tooltip title={job.title}>
              <Badge status={job.status === "Active" ? "success" : "error"} />
              {job.title}
            </Tooltip>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 5;
    const { candidateInfo } = store.getState().candidate;
    
    const params = { resume: file };
    if (!isJpgOrPng) {
      Notification.error("error", "You can only upload PDF file!");
    } else if (!isLt2M) {
      Notification.error("error", "PDF must smaller than 5MB!");
    } else {
      Api.patch("/hiring-candidate/" + candidateInfo?.id)
        .params(params)
        .uploadAndPatch(params, (percentCompleted, response) => {
          if (typeof response != "undefined" && percentCompleted === 100) {
            store.dispatch(
              updateInputField(
                inputFieldType.CANDIDATE_UPDATE_RESUME,
                response?.data?.resume
              )
            );
          }
        });
    }
    return false;
  };
  imageBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    } else {
      this.props.updateInputField(inputFieldType.CANDIDATE_PHOTO_EDIT, file);
      this.props.handleCandidatePhotoEdit();
    }
    return false;
  };

  onChange(e) {
    this.setState({
      emailUpdate: e.target.value,
    });
  }
  render() {
    const isHrProcess = window.location.pathname == "/hr-process";
    let path=new URL(location.href).pathname;
    const {
      candidateViewModal,
      candidateInfo,
      candidateShortlistedLoader,
      candidateStatus,
      updateInputField,
      performOperation,
      candidateRejectedLoader,
      //user,
      candidateScreenType
,
      getAllCandidates,
      candidatePhotoLoader,
      candidatePhotoEditBool,
      proposeJob,
      candidateJobChangeToggle,
      candidateRoundChangeToggle,
      candidateJobs,
      editCandidateView,
    } = this.props;

    // console.log("candidateInfocandidateInfo",candidateInfo)
    const {user}=store.getState().auth;
    const { width, } = this.state.windowDimensions;
    const img_style = {
      width: "50px",
      height: "50px",
      // marginLeft: "0.5rem",
      borderRadius: "50%",
    };
    const duration = moment.duration(candidateInfo?.testDuration ? candidateInfo?.testDuration : "0", 'minutes');

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    const formattedDuration = candidateInfo?.testDuration ? `${hours} hours ${minutes} minutes` : "NA";
    const uploadButton = (
      <div>
        <div style={{marginTop:"0.3rem"}}>
        {candidatePhotoLoader ? (
          <LoadingOutlined />
        ) : (
          <CameraOutlined style={{ color: Color.darkGrey, fontSize: "1.5em" }} />
        )}
        </div>
        {/* <span >
        upload
        </span> */}
      </div>
    );

    const selectOptions = [
      "No relevant experience","Less relevant experience","Fake Experience",
      "Very High CTC",
      "Not compatible",
      // ("Already working at client" , "Too many company changes" , "Not reliable"),
      "NP not acceptable",
      "Wrong Profile ",
      //  ("JD and Profile are out of sync"),
      "90 days Consider later",
      "Other"
    ]

    return (
      <Modal
        open={candidateViewModal}
        // closable={true}
        centered
        width={width > 700 && "50%"}
        bodyStyle={{ height: width > 700 && "65vh" }}
        className="candidateViewModal"
        title={
          <div style={{width:"100%"}}>
            <div style={{width:"100%"}}>
              <div style={{width:"90%",display:"flex",flexDirection:"row",gap:"0.3rem",justifyContent:"flex-start",marginTop:"0.5rem"}}>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center",}}>
                  {/* <Space> */}
                    {user.role?.includes("Admin") ||
                    user.role?.includes("Super-Admin") ||
                    user.role?.includes("Recruiter") ||
                    user.role?.includes("Sourcer") ||  user.role?.includes("Shortlister") ? (
                      <>
                       {candidateInfo?.candidateType === "Fresher" ? ""
                      :
                      <>
                        {candidatePhotoEditBool ? (
                          <FiCheck style={{ color: Color.green }} />
                        ) : (
                          <div
                            onClick={() =>
                              performOperation(
                                operationType.CANDIDATE_PHOTO_EDIT_BOOL,
                                true
                              )
                            }
                            className="cursor-pointer candidate-modal-job-title-hover"
                          >
                            {/* <FiEdit2 style={{ color: Color.modalblue }} /> */}
                          </div>
                        )}
                       </>
                       }
                        {!candidateInfo?.photo || candidatePhotoEditBool ? (
                          <Upload
                            // name="avatar"
                            // className="avatar-uploader"
                            // listType="picture-card"
                            showUploadList={false}
                            accept=".png,.jpeg,.jpg"
                            beforeUpload={this.imageBeforeUpload}
                          >
                            <Button disabled={true} type="text" style={{borderRadius:"50%",height:"80px",width:"74px",marginLeft:"-1rem"}}>
                            {candidateInfo?.photo && !candidatePhotoLoader ? (
                              <img
                                src={candidateInfo?.photo}
                                alt=""
                                style={img_style}
                              />
                            ) : (
                              // uploadButton
                              <img
                                src={profileIcon}
                                style={{
                                  width: "70px",
                                  height: "73px",
                                  borderRadius: "50%",
                                  marginLeft:"-1rem"
                                }}
                              />
                            )}
                            </Button>
                          </Upload>
                        ) : (
                          <img
                            src={candidateInfo?.photo}
                            // style={img_style}
                            style={{width:"50px",height:"50px",borderRadius:"50%",marginLeft:"-1rem"}}
                            alt=""
                            onClick={() => {
                              updateInputField(
                                inputFieldType.CANDIDATE_IMAGE,
                                candidateInfo?.photo
                              );
                              performOperation(
                                operationType.CANDIDATE_IMAGE_VIEW_MODAL,
                                true
                              );
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={candidateInfo?.photo}
                        style={img_style}
                        alt=""
                        onClick={() => {
                          updateInputField(
                            inputFieldType.CANDIDATE_IMAGE,
                            candidateInfo?.photo
                          );
                          performOperation(
                            operationType.CANDIDATE_IMAGE_VIEW_MODAL,
                            true
                          );
                        }}
                      />
                    )}
                  {/* </Space> */}
                </div>
                <div
                  style={{
                    marginLeft: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",width:"100%",
                  }}
                >
                  {/* <Space style={{ margin: "0.5rem 0 0 0" }}> */}
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
                      <span
                        style={{
                          color: "#000000",
                          margin: "0 0.5rem 0 0",
                          fontSize: "1rem",
                          fontWeight: "900",
                        }}
                      >
                      <Typography.Text ellipsis={{tooltip:false}} style={{width:"9rem",fontSize:"1rem",fontWeight:600}}>
                        {candidateInfo?.name}
                      </Typography.Text>
                      </span>
                      {width > 700 &&
                      <Button
                        type="text"
                        style={{ display: "flex", padding:'0',}}
                        onClick={() => {
                          this.setState({ emailUpdate: "", jobChangeId: "" });
                          updateInputField(
                            inputFieldType.CANDIDATE_UPDATE_RESUME,
                            ""
                          );
                          performOperation(
                            operationType.EDIT_CANDIDATE_VIEW,
                            true
                          );
                         
                          performOperation(
                            operationType.CANDIDATE_PHOTO_EDIT_BOOL,
                            false
                          );
                          candidateJobChangeToggle(false, {});
                          candidateRoundChangeToggle(false, {});
                        }}
                      >
                        <FiEdit2
                          style={{
                            fontSize: "1rem",
                            color: Color.modalblue,
                            marginRight: "5px",
                            marginTop: "0.5rem",
                            
                          }}
                        />
                        <span
                          style={{
                            alignSelf: "center",
                            fontSize: "0.9rem",color:"#0086FF",
                          }}
                        >
                          Edit Profile
                        </span>
                      </Button>
                      // : ""
                      } 
                    </div>
                    <div>
                    </div>
                    <div style={{ display: "flex",flexDirection:"row",justifyContent:"space-between",width:"100%",alignItems:"center",}}>
                    {width > 700 &&
                      <a
                        style={{ display: "table-cell",marginLeft:"0.3rem"}}
                        onClick={() => {window.open(candidateInfo.resume)}}
                        target="_blank"
                      >
                        <FilePdfOutlined style={{ fontSize: "1rem" }} />
                        <span
                          style={{
                            fontSize: "1rem",
                            color: "black",
                            marginLeft: "0.2rem",
                          }}
                        >
                          Resume
                          {/* <img src={ResumeIcon} /> */}
                        </span>
                      </a>}
                      <a type="text"
                        href={candidateInfo.resume}
                        style={{ padding:'0',display: "flex",alignItems:"center",}}
                        download={true}
                        target="_blank"
                        onClick={() =>{
                          downloadPDF(candidateInfo.resume,candidateInfo.name)
                        }}
                      >
                        <FiDownload
                          style={{
                            fontSize: "1rem",
                            color: Color.modalblue,
                            marginRight: "5px",
                            marginTop: "0.1rem",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "0.9rem",
                            alignSelf: "center",
                            color:"#0086FF",
                          }}
                        >
                           Download 
                        </span>
                      </a> 
                      {width < 700 &&
                      <a 
                      // href={candidateInfo.resume} 
                        style={{ display: "table-cell",marginLeft:"0.3rem"}}
                        onClick={() => { 
                          updateInputField(
                            inputFieldType.CANDIDATE_RESUME,
                            candidateInfo.resume
                          );
                          performOperation(
                            operationType.CANDIDATE_PDF_VIEW_MODAL,
                            true
                          );
                        }}
                        target="_blank"
                      >
                        {/* <FilePdfOutlined style={{ fontSize: "1rem" }} />
                        <span
                          style={{
                            fontSize: "1rem",
                            color: "black",
                            marginLeft: "0.2rem",
                          }}
                        >
                          Resume */}
                          <img src={ResumeIcon} />
                        {/* </span> */}
                      </a>}
                      {candidateInfo?.candidateType === "Fresher" || user?.role.includes("Recruiter") || user?.role.includes("Sourcer") ||
                      user?.role.includes('HR') || user?.role.includes('Shortlister') ? 
                      ""
                      :
                      <Button
                        type="text"
                        style={{padding:'0',margin:'0', display: "flex"}}
                        onClick={() =>{
                          Api.patch(`/hiring-candidate/flag/${candidateInfo?.id}`)
                          .params({
                            
                            blacklist:candidateInfo?.blacklist ? false:true

                          })
                          .send((response) =>{
                         
                            getAllCandidates()
                            this.closeModal()
                          })
                        }}
                      >
                        {candidateInfo?.blacklist ? (
                          <CheckSquareOutlined
                            style={{
                              fontSize: "1rem",
                              color: Color.red,
                            margin: "0.4rem 0.2rem 0 0.5rem",
                            }}
                          />
                        ) : (
                          <AiOutlineStop
                            style={{
                              fontSize: "1rem",
                            color: Color.red,
                            margin: "0.4rem 0.2rem 0 0.5rem",
                            }}
                          />
                        )}
                        <span
                          style={{
                            fontSize: "0.7rem",
                            alignSelf: "center",
                            marginRight:'0.5rem'
                          }}
                        >
                          {candidateInfo?.blacklist ? "Whitelist" : "Blacklist"}
                        </span>
                      </Button>
                     }
                    </div>
                  {/* </Space> */}
                </div>
              </div>
              <div
                style={{
                  marginTop: "0.5rem",marginLeft:width<700&&"0.5rem",
                  display: "flex",flexDirection:"row",width:"100%",
                  justifyContent:width >700 ?"flex-end":"space-between",alignItems:"center"
                }}
              >
                {width < 700 && 
                    <Button
                        type="text"
                        style={{ display: "flex", padding:'0' }}
                        onClick={() => {
                          this.setState({ emailUpdate: "", jobChangeId: "" });
                          updateInputField(
                            inputFieldType.CANDIDATE_UPDATE_RESUME,
                            ""
                          );
                          performOperation(
                            operationType.EDIT_CANDIDATE_VIEW,
                            true
                          );
                         
                          performOperation(
                            operationType.CANDIDATE_PHOTO_EDIT_BOOL,
                            false
                          );
                          candidateJobChangeToggle(false, {});
                          candidateRoundChangeToggle(false, {});
                        }}
                      >
                        <FiEdit2
                          style={{
                            fontSize: "1rem",
                            color: Color.modalblue,
                            marginRight: "5px",
                            marginTop: "0.5rem",
                            
                          }}
                        />
                        <span
                          style={{
                            // fontSize: "0.7rem",
                            alignSelf: "center",
                            marginRight:'0.5rem',fontWeight:600
                          }}
                        >
                          Edit Profile
                        </span>
                    </Button>
                }
                
              </div>
            </div>
          </div>
        }
        onOk={() =>{
          this.onFinish(candidateInfo?.id, candidateStatus?.shortlisted)
          this.setState({ othersValue : null })
        }}
        onCancel={this.closeModal}
        footer={ (user?.role.includes('Agency') || user?.role.includes('HR') || user?.role.includes("Recruiter") || user?.role.includes("Sourcer")) 
              && !user.role.includes("Shortlister") && !user?.role?.includes("Admin") && !user?.role.includes("Super-Admin") ? 
          ""
          :
          !isHrProcess && candidateScreenType==="New" 
            ? <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
            <div style={{width:"100%",marginLeft:"1.5rem",}}>
              {!isHrProcess &&!user.role.includes("Agency")&& (
                <>
                    {user?.role.includes("Admin") || user?.role.includes("Super-Admin") ? 
                    <div
                      style={{
                        color: "black",
                        display: "flex",flexDirection:"row",gap:"0.4rem",width:"100%",
                        alignItems:"center"
                      }}
                    >
                      <Checkbox
                        checked={candidateInfo?.duplicate}
                        onChange={() =>
                          this.changeCandidateInfo(
                            candidateFieldType.CANDIDATE_DUPLICATE,
                            !candidateInfo?.duplicate
                          )
                        }
                      ></Checkbox>
                      <span style={{ fontSize: "0.75rem",fontWeight:700}}>
                        MARK AS DUPLICATE
                      </span>
                    </div>
                    : user.role.includes("Shortlister") && candidateInfo?.duplicate ? 
                    <Row>
                      <h4 style={{fontWeight:"780",marginRight:width>700?"0.8rem":"0.2rem"}}>DUPLICATE CANDIDATE</h4>
                    </Row>
                    :""
                    }
                </>
                )}
            </div>
            <div style={{display:"flex",flexDirection: width > 700 ? "row":"column",padding:"1rem",width:"100%",gap:width<700&&"0.7rem",justifyContent:width>700&&"flex-end"}}>
            <SecondaryButton
              onClick={() => {
               
                // if(!candidateInfo?.shortlisterComment)
                // {
                //   Notification.error("Please select the comment!")
                //   return;
                // }
                this.onReject(
                candidateInfo?.id,
                candidateStatus?.notShortlisted,
                candidateInfo?.shortlisterComment,
                candidateInfo?.candidatePriority,
              )
              this.setState({ othersValue : null })}}
              loading={candidateRejectedLoader}
              id="rejectCandidate"
              text="Reject"
              size="large"
              style={{
                width: width > 700 ? "8rem":"100%",
                height: width > 700 ? "2.1rem" :"2.3rem",
                background: Color.red,
                color: Color.white,
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",borderRadius:5,fontSize:"1rem"
              }}
              className="modalButton"
            />
            <PrimaryButton
              htmlType="submit"
              id="shortlistCandidate"
              loading={candidateShortlistedLoader}
              onClick={() =>{
                // console.log("candidateInfocandidateInfo",candidateInfo)
                // if(!candidateInfo?.candidatePriority)
                // {
                //   Notification.error("Please select the priority!")
                //   return;
                // }
                // if(!candidateInfo?.shortlisterComment)
                // {
                //   Notification.error("Please select the comment!")
                //   return;
                // }
              
               
                this.onFinish(
                  candidateInfo?.id,
                  candidateStatus?.shortlisted,
                  candidateInfo?.shortlisterComment,
                  candidateInfo?.candidatePriority,
                )
                this.setState({ othersValue : null })
              }
            }
              text="Shortlist"
              className="modalButton"
              size="large"
              style={{
                width: width > 700 ? "8rem":"100%",
                height: width > 700 ? "2.1rem" :"2.3rem",
                alignSelf: "end",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",borderRadius:5,fontSize:"1rem"
              }}
              disabled={
                candidateInfo?.blacklist ||
                candidateInfo?.duplicate ||
                this.state.jobChangeId !== "" ||
                candidateInfo?.shortlisterComment === "Other"
              }
            />
          </div>
          </div>
            : null
        }
      >
        <Tabs defaultActiveKey="Personal details" activeKey={this.state.tabStatus}
              onChange={(key) => {}}
              style={{ overflow: "hidden",marginTop:"-1.3rem",marginLeft:"1rem"}}
            >
              <TabPane key="Personal details"
                    tab={
                      <span style={{}}>Personal details</span>
                    }
             > 
                <Form className={classes.formControl} layout="vertical">
          <div style={this.form_row}>
            <div style={{width:"50%"}}>
              <div style={this.formTitle}>MOBILE NUMBER:</div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.phone}
                </div>
              </Form.Item>
            </div>
            <div style={{width:"50%"}}>
              <div style={this.formTitle}>E-MAIL:</div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  <Typography.Text ellipsis={{tooltip:false}} style={{width:"7rem",fontSize:"12px",fontWeight:"bold"}}>
                    {candidateInfo?.email}
                  </Typography.Text>
                </div>
              </Form.Item>
            </div>
          </div>
          <div style={this.form_row}>
          {/* <div style={{ marginTop: "0.5rem",display:"flex",flexDirection:"row",justifyContent:"space-between",paddingRight:"5rem" }}> */}
          <div style={{width:"50%"}}>
              <div style={this.formTitle}>JOB TITLE</div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  <Typography.Text ellipsis={{tooltip:false}} style={{width:"7rem",fontSize: "12px",fontWeight:"bold"}}>
                   {candidateInfo?.job?.title}
                  </Typography.Text>
                  
                </div>
              </Form.Item>
          </div>
          <div style={{width:"50%"}}>
              <div style={this.formTitle}>EXPERIENCE</div>
              <Form.Item>
                <div>
                <h3
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "0.5rem",
                  }}
                >{`${candidateInfo.experienceYears} Y ${
                  candidateInfo.experienceMonths
                    ? `${candidateInfo?.experienceMonths} M`
                    : ""
                }`}</h3>
                </div>
              </Form.Item>
          </div>
          </div>
          <div style={this.form_row}>
            <div style={{width:"50%"}}>
              <div style={this.formTitle}>CURRENT COMPANY</div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.currentCompany}
                </div>
              </Form.Item>
            </div>
            <div style={{width:"50%"}}>
              <div style={this.formTitle}>NOTICE (DAYS)</div>
                <Form.Item>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: Color.black,
                    }}
                  >
                    {candidateInfo?.noticePeriod}
                  </div>
                </Form.Item>
            </div>
          </div>
          {(user?.role?.includes("Admin") || user?.role?.includes("Super-Admin") || user?.role?.includes("Recruiter") || user?.role?.includes("Sourcer"))?
          <div style={this.form_row}>
            <div style={{width:"50%"}}>
            {/* {(user?.role?.includes("Admin") || user?.role?.includes("Super-Admin") || user?.role?.includes("Recruiter") || user?.role?.includes("Sourcer") )?<> */}
             <div style={this.formTitle}>CURRENT CTC</div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.currentCtc}
                </div>
              </Form.Item>
              {/* </>:""} */}
            </div>
            <div style={{width:"50%"}}>
            {/* {(user?.role?.includes("Admin") || user?.role?.includes("Super-Admin") || user?.role?.includes("Recruiter") || user?.role?.includes("Sourcer") )?<> */}
              <div style={this.formTitle}>EXPECTED CTC</div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.expectedCtc}
                </div>
              </Form.Item>
              {/* </>:""} */}
            </div>
          </div>
          :""}
          <div style={this.form_row}>
          <div style={{width:"50%"}}>
              <div style={this.formTitle}>CURRENT LOCATION</div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.currentLocation}
                </div>
              </Form.Item>
            </div>
            <div style={{width:"50%"}}>
              <div style={this.formTitle}>
              <Typography.Text ellipsis={{tooltip:false}} style={{width:"7.3rem",fontSize: "13px",color: "#808080",}}>
                  PREFERRED LOCATION
              </Typography.Text></div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.preferredLocation?.name}
                </div>
              </Form.Item>
            </div>
          </div>

          <div style={{borderBottom: `2px solid ${Color.lightGrey}`,padding:"0.1rem 0"}}>
          <div style={this.form_row}>
            <div style={{width:"50%"}}>
            <div style={this.formTitle}>AGENCY COMMENT</div>
              <Form.Item>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.comment&&candidateInfo?.comment!=="undefined" ? candidateInfo?.comment : "NA"}
                </div>
            </Form.Item>
            </div>
            <div style={{width:"50%"}}>
              <div style={this.formTitle}> NOTE</div>
              <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.note && candidateInfo?.note !== "null" ? candidateInfo.note : "NA"}
              </span>
            </div>
            </div>
          </div>
              {/* <div style={{fontSize: "13px",color: Color.darkGrey,marginBottom:"0.5rem"}}>
                NOTE:
                <span
                  style={{
                    marginBottom:"5px",
                    marginLeft: "0.5rem",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                >
                  {candidateInfo?.note && candidateInfo?.note !== "null" ? candidateInfo.note : "NA"}
                </span>
              </div> */}
          {user.role?.includes("Shortlister") && (
            <Row style={{marginTop:"0.5rem"}}>
              <div style={this.formTitle}>Suggest JD Change :</div>
              <Select
               placeholder="Select Proposed job"
               allowClear={true}
               showSearch={true}
               optionFilterProp="children"
               value={this.state.jobChangeId === "" ? null : this.state.jobChangeId}
               style={{
                 width: "100%",
                 background: "#fff",
                 height: "2rem",
                 borderRadius: "none",
                 boxShadow: "none",
                 margin:"0.3rem 0"
               }}
                onChange={(value) => {
                  if (!value) {
                 
                    this.setState({ jobChangeId: "" });
                    return;
                  }
                  this.setState({ jobChangeId: value });
                }}
                // style={{ width: "200px", marginLeft: "20px" }}
              >
                {
                proposeJob?.length>0?
                proposeJob?.map((item) => {
                  return <Option value={item.id}>{item.title}</Option>;
                })
                :<Option></Option>
                }
              </Select>
            </Row>
          )}
          <Row>
            <Col span={24}>
              {/* PROPOSED JOB */}
              {/* <div style={this.formTitle}>PROPOSED JOB</div>
              <Form.Item>
                <Select
                  placeholder="Select Proposed job"
                  style={{
                    width: "100%",
                    background: "#fff",
                    height: "2rem",
                    borderRadius: "none",
                    boxShadow: "none",
                  }}
                >
                  {candidateJobs?.map((item, i) => (
                    <Option key={i} style={{ width: "100%" }} value={item.id}>
                      <Tooltip title={item.title}>{item.title}</Tooltip>
                    </Option>
                  ))}
                </Select>
              </Form.Item> */}
            </Col>
          </Row>
          {/* {  user?.role.includes('Agency')  ? 
          ""
            : */}

          <Row style={{marginTop:"0.5rem"}}>
            {path!=='/hr-process'&&<Col span={24}>
              
              <Form.Item 
              name="candidatePriority"
              // initialValue={candidateInfo?.candidatePriority?candidateInfo?.candidatePriority:undefined}
               label={<div style={this.formTitle}>CANDIDATE'S PRIORITY</div>}>
                <Select allowClear={true} placeholder="Select"
                  disabled={(user?.role.includes("Shortlister") || user?.role?.includes("Admin")) || user?.role.includes("Super-Admin")?false:true}
                  onChange={(value) =>{
                
                      this.setState({ othersValue : null });
                      updateInputField(candidateFieldType.CANDIDATE_PRIORITY, value);
                    
                    }
                  }
                  style={{
                    color: Color.black,
                  }}
                
                  defaultValue={candidateInfo?.candidatePriority}
                >
                  
                  {["High","Moderate","Low"].map((item,index)=>{
                      return(<Option value={item} key={index}>{item}</Option>)
                  })}
                </Select>
               
              </Form.Item>
            </Col>}
          </Row>


          <Row gutter={24}>
            {path!=='/hr-process'&&<Col span={24}>
              
              <Form.Item name="shortlisterComment" label={<div style={this.formTitle}>COMMENTS FROM SHORTLISTER</div>}>
                <Select allowClear={true} placeholder="Select"
                  disabled={(user?.role.includes("Shortlister") || user?.role?.includes("Admin")) || user?.role.includes("Super-Admin")?false:true}
                  onChange={(value) =>{
                    // console.log("sssClg",value)
                    if (value === "Other") {
                      this.setState({ othersValue : value });
                      updateInputField(candidateFieldType.CANDIDATE_SHORTLISTER_NOTE, value);
                    } else {
                      this.setState({ othersValue : null });
                      updateInputField(candidateFieldType.CANDIDATE_SHORTLISTER_NOTE, value);
                    }
                    // updateInputField(
                    //   candidateFieldType.CANDIDATE_SHORTLISTER_NOTE,
                    //   value
                    // )
                    }
                  }
                  style={{
                    // fontSize: "12px",
                    // fontWeight: "bold",
                    color: Color.black,
                  }}
                  value={this.state.othersValue === "Other"? "Other":candidateInfo?.shortlisterComment !== "undefined" && candidateInfo?.shortlisterComment !== "null" &&
                  candidateInfo?.shortlisterComment !== null ? candidateInfo.shortlisterComment : undefined}
                >
                  {selectOptions.map((item,index)=>{
                      return(<Option value={item} key={index}>{item}</Option>)
                  })}
                </Select>
                {this.state.othersValue === "Other"?
                <Input placeholder="Enter the Other Comment" onChange={(record) =>{
                  // console.log("sssClg",record)
                  updateInputField(
                    candidateFieldType.CANDIDATE_SHORTLISTER_NOTE,
                    record?.target?.value
                  )}
                } style={{marginTop:"1rem"}}
                />
                :""}
                {/* <Input
                  disabled={(user?.role.includes("Shortlister") || user?.role?.includes("Admin")) || user?.role.includes("Super-Admin")?false:true}
                  onChange={(record) =>{
                    updateInputField(
                      candidateFieldType.CANDIDATE_SHORTLISTER_NOTE,
                      record?.target?.value
                    )
                  }
                  }
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: Color.black,
                  }}
                  value={candidateInfo?.shortlisterComment !== "undefined" ? candidateInfo.shortlisterComment : undefined}
                /> */}
              </Form.Item>
            </Col>}
          </Row>
          {/* } */}
          {user.role.includes("Shortlister") && candidateScreenType==="New" &&
          <Row justify="center">
            <Button disabled={this.state.jobChangeId === "" ? true : false}
              loading={this.state.jbchngloader}
              style={{marginBottom:"0.5rem",color:this.state.jobChangeId === "" ? "gray":"green",borderColor:this.state.jobChangeId === "" ? "gray":"green"}}
              onClick={() =>{
                this.setState({jbchngloader:true})

                const value=proposeJob?.find(item => item?.id===this.state.jobChangeId)
                
                Api.post(`/hiring-candidate/proposeJd/${candidateInfo.id}`).
                params({
                  candidateId:candidateInfo.id,
                  comment:candidateInfo.shortlisterComment,
                  jd:{id:this.state.jobChangeId,
                  title:value?.title,
                  }})
                .send((response) =>{
                  if(response.show.type!=="error"){
                  this.closeModal()
                  getAllCandidates(history)
                  
                  }
                  this.setState({jbchngloader:false})
              })
                }}> SEND PROPOSAL</Button>
              </Row>}
              </Form>
              </TabPane>
              {candidateInfo?.hackathonStatus !== "New" &&
              <TabPane key="Hackathons"
                    tab={
                      <span style={{}}>Hackathon</span>
                    }
             >
              <div>
                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>
                <h4 style={{fontWeight:650}}>DETAILS</h4>
                <div>
                {candidateInfo?.currentRound > 0 ? ""
                :<>
                  <span style={{fontWeight:600,marginRight:"0.4rem"}}>Change Status :</span>
                  <Select 
                            size="medium" style={{width:"9rem",height:"2.1rem"}}
                            value={this.state.overallStatus}
                            // bordered={false}
                            onChange={(value) => {
                              Api.patch(`/hiring-candidate/updateHackathon/${candidateInfo.id}?status=${value}`)
                                 .send((response) => {
                                    getAllCandidates(history);
                                    this.closeModal();
                                  }
                                 );
                              this.setState({ overallStatus: value });
                            }}
                          >
                            <Option value="New">New</Option>
                            <Option value="Link sent">Link sent</Option>
                            <Option value="Passed">Passed</Option>
                            <Option value="Failed">Failed</Option>
                </Select>
                </>
                }
                </div>
                </div>
                <div style={{ display: "flex", width: "100%",justifyContent: 'space-between',paddingRight:"1rem",marginTop:"1rem"}}>
                      <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: 14, color: "#808080", fontWeight: 600, }}>Status</span>
                          <div style={{ fontSize: 13, color: "black", fontWeight: 600,textAlign:"start" }}>
                          {candidateInfo?.hackathonStatus ? candidateInfo?.hackathonStatus : "NA"}
                          </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: 14, color: "#808080", fontWeight: 600, }}>Sent on</span>
                          <div style={{ fontSize: 13, color: "black", fontWeight: 600 ,textAlign:"start" }}>
                            {candidateInfo?.testLinkSentOn ? candidateInfo?.testLinkSentOn : "NA"}
                          </div>
                      </div>
                </div>
                <div style={{ display: "flex", width: "100%",justifyContent: 'space-between',paddingRight:"1rem",marginTop:"1rem"}}>
                <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: 14, color: "#808080", fontWeight: 600, }}>Test name</span>
                          <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 12, color: "black", fontWeight: 400, width: '100px' }}>
                            {candidateInfo?.testName ? candidateInfo?.testName : "NA"}                          
                          </Typography.Text>
                      </div>
                      <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: 14, color: "#808080", fontWeight: 600, }}>Test duration</span>
                          <div style={{ fontSize: 13, color: "black", fontWeight: 600 ,textAlign:"start" }}>
                          {formattedDuration}
                          </div>
                      </div>
                </div>
                <div style={{ display: "flex", width: "100%",justifyContent: 'space-between',paddingRight:"1rem",marginTop:"1rem"}}>
                <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: 14, color: "#808080", fontWeight: 600, }}>Start date</span>
                          <div style={{ fontSize: 13, color: "black", fontWeight: 600 ,textAlign:"start" }}>
                          {candidateInfo?.testDate ? candidateInfo?.testDate : "NA"}
                          </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: 14, color: "#808080", fontWeight: 600, }}>End date</span>
                          <div style={{ fontSize: 13, color: "black", fontWeight: 600 ,textAlign:"start" }}>
                          {candidateInfo?.testEndDate ? candidateInfo?.testEndDate : "NA"}
                          </div>
                      </div>
                </div>
                <div style={{ display: "flex", width: "100%",justifyContent: 'space-between',paddingRight:"1rem",marginTop:"1rem"}}>
                <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: 14, color: "#808080", fontWeight: 600, }}>Link active period</span>
                          <div style={{ fontSize: 13, color: "black", fontWeight: 600 ,textAlign:"start" }}>
                          {/* {candidateInfo?.testActiveDays ? candidateInfo?.testActiveDays : "NA"} */}
                          {candidateInfo?.testActiveDays && candidateInfo?.testActiveDays > 1 ? `${candidateInfo?.testActiveDays} days`
                          :candidateInfo?.testActiveDays && candidateInfo?.testActiveDays === 1 ? `${candidateInfo?.testActiveDays} day` 
                          : "NA"}
                          </div>
                      </div>
                      
                </div>
              </div>
              </TabPane>
              }
          </Tabs>
        
        <ViewImage />
        <CandidateJobChangeModal />
       { editCandidateView && <EditCandidateModal operation={this.props} />}
       <ViewPDF />
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateViewModal);
