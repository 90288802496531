import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

// add all reducers here
import { saveToLocalStorage, loadFromLocalStorage } from "services/Storage";
import counterReducer from "../pages/counter/redux";
import candidatesReducer from "../pages/dashboard-home/store";
import userCandidateReducer from "../pages/dashboard-candidate/store";
import authUserReducer from "../pages/login/store"
import collegeReducer from "../pages/college-dashboard/store"
import jdReducer from '../pages/jd-dashboard/store';
import templateReducers from 'pages/email-template/store';
import jdFresherReducer from "pages/jd-dashboard-fresher/store"
import testReducer from "pages/dashboard-testpapers/store"
import feedbackReducer from "../pages/feedbackmanagement/store"
import feedbackResponseReducer from "../pages/feedbackManagementResponse/store"
import panelistReducer from "../pages/panelist-all/store";
import hrProcess from "pages/hr-process/redux/reducer";
import createUser from "pages/create-user/redux/reducer";
import { sessionReducer } from "redux-react-session";
import dashboard from "pages/interview/redux/reducer";
// import dashboardReducer from '../pages/dashboard-overview/store';
import candidate from "pages/candidate/redux/reducer";
import agencySummary from "pages/agency-summary/redux/reducer"
import jobSummary from "pages/job-summary/redux/reducer";
import feedback from "pages/feedback/store";
import panFeedback from "pages/panelist-feedback/store"
import LocAndDeptReducer from "pages/location-orgDept/store";
import canRegistrationReducer from 'pages/candidateRegistration/store';
import overallDashboard from 'pages/overAllDashboard/store'; 
import moduleKeyReducer from '../components/store';
const preloadedState = loadFromLocalStorage();
const store = configureStore({
  reducer: {
    counter: counterReducer,
    candidateFresher: candidatesReducer,
    userCandidate: userCandidateReducer,
    auth: authUserReducer,
    college: collegeReducer,
    jd: jdReducer,
    templates: templateReducers,
    JdFresher: jdFresherReducer,
    test: testReducer,
    feedbacks: feedbackReducer,
    feedbackResponse:feedbackResponseReducer,
    panelist:panelistReducer,
    hrProcess:hrProcess,
    createUser:createUser,
    session: sessionReducer,
    dashboard:dashboard,
    // overView:dashboardReducer,
    candidate:candidate,
    agencySummary: agencySummary,
    jobSummary:jobSummary,
    feedback:feedback,
    panFeedback:panFeedback,
    LocAndDept:LocAndDeptReducer,
    canRegistration:canRegistrationReducer,
  
    overallDashboard:overallDashboard,
    moduleKey:moduleKeyReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false}).concat(logger),
  preloadedState,
});
store.subscribe(() => saveToLocalStorage(
  { auth: store.getState().auth }
));

window.store = store;

export default store;
