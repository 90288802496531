class Filter{
    constructor(){
        this.duration=false
        this.totalMarks = false;
        this.testCutt_off = false;
        this.durmin = 0;
        this.durmax = 0;
        this.durnum = 0;
        this.totmin=0;
        this.totmax=0;
        this.totnum=0;
        this.cutmin=0;
        this.cutmax=0;
        this.cutnum=0;
        
    }
    setDurMin(value){
        this.durmin = value
    }

    setTotMin(value){
        this.totmin = value
    }

    setCutMin(value){
        this.cutmin = value
    }

    setDurMax(value){
        this.durmax = value
    }
    setTotMax(value){
        this.totmax = value
    }
    setCutMax(value){
        this.cutmax = value
    }

    setDurNum(value){
        this.durnum = value
    }
    setTotNum(value){
        this.totnum = value
    }
    setCutNum(value){
        this.cutnum= value
    }

    resetFitler(){
        this.duration=false
        this.totalMarks = false;
        this.testCutt_off = false;
        this.durmin = 0;
        this.durmax = 0;
        this.durnum = 0;
        this.totmin=0;
        this.totmax=0;
        this.totnum=0;
        this.cutmin=0;
        this.cutmax=0;
        this.cutnum=0;
       
    }

}

export default Filter;