import { Col, Form, Modal, Row, Space } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Color, InformationModal, modalContent } from "services";
import { DeleteIconButton, EditIconButton } from "services/button";
import { TextFormDisplay } from "services/input";
import { agencyOperationType, AGENCY_DETAILS, agencyInfoFormat } from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { WarningOutlined } from "@ant-design/icons";

class AgencyViewModal extends React.Component {
  closeModal = () => {
    this.props.performOperation(agencyOperationType.AGENCY_VIEW_MODAL, false);
    this.props.updateInputField(AGENCY_DETAILS, agencyInfoFormat);
  };

  handleEdit = () => {
    const {performOperation, showAgencyEditingModal} = this.props
    performOperation(agencyOperationType.AGENCY_VIEW_MODAL, false);
    performOperation(agencyOperationType.AGENCY_DETAILS_MODAL, true);
    showAgencyEditingModal();
  };

  render() {
    const { agencyInfo, agencyViewModal, performOperation, handleAgencyDelete } = this.props;
    return (
      <Modal
        visible={agencyViewModal}
        closable={false}
        title={
          <div style={{display: "flex"}}>
            <span style={{flex: "5"}}>{agencyInfo.agencyName}</span>
            <Space size="large" style={{flex: "1", height: "20px"}}>
              <EditIconButton onClick={this.handleEdit} id="agencyEditIcon" style={{marginRight: "-12px"}}></EditIconButton>
              <DeleteIconButton
                id="agencyDeleteIcon"
                onClick={() => {
                  performOperation(agencyOperationType.AGENCY_VIEW_MODAL, false);
                  InformationModal.confirm(
                    <div>
                      <WarningOutlined style={{ fontSize: "3em", color: Color.red }} />
                    </div>,
                    <div>Are you sure you want to Permanently remove this agency</div>,
                    <div style={{ color: Color.darkGrey }}>{modalContent("Agency Name:", agencyInfo.agencyName)}</div>,
                    () => handleAgencyDelete(agencyInfo.id)
                  );
                }}
              ></DeleteIconButton>
            </Space>
          </div>
        }
        onCancel={this.closeModal}
        footer={null}
      >
        <Form>
          <Row
            gutter={24}
            style={{
              borderBottom: `2px solid ${Color.lightGrey}`,
            }}
          >
            <Col span={12}>
              <TextFormDisplay text="Location" value={agencyInfo.location} />
            </Col>
          </Row>
          <div className="m-t-10" style={{color: Color.modalblue }}>Users</div>
          <div className="modal-scroll">
            {agencyInfo.users.map((user, index) => {
              return (
                <Row gutter={24} key={index}>
                  <Col span={8}>
                    <TextFormDisplay text="Name" value={user.name} />
                  </Col>
                  <Col span={8}>
                    <TextFormDisplay text="Contact" value={user.phone} />
                  </Col>
                  <Col span={8}>
                    <TextFormDisplay text="E-mail" value={user.email} />
                  </Col>
                </Row>
              );
            })}
          </div>
        </Form>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyViewModal);
