import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Input,
  Rate,
  Radio,
  Button,
  Typography,
  Row,
  Col,
  Upload,
  Slider,
} from "antd";

import { PaperClipOutlined } from "@ant-design/icons";
import { feedbackActions } from "./store";
import { Color } from "services";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Api from "services/Api";
import backIcon from "../../assets/backIcon.svg";
function FeedbackPreview() {
  const { feedbackData, feedbackaddquestions, selectedCard } = useSelector(
    (state) => state.feedbacks
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = history.location;

  const [res, SetRes] = useState();
  const feedbackId = feedbackData?.feedbackId;

  const navBtnStyle = {
    width: "8rem",
    fontWeight: "bold",
    borderRadius: "0.2rem",
    color: Color.white,
    background: Color.modalblue,
  };

  const options = (op) => {
    const Data = [];
    Object.keys(op).forEach((key) => {
      if (op[key] !== "") {
        Data.push(op[key]);
      }
    });
    return Data.map((element) => {
      return (
        <Input
          value={element}
          size="large"
          prefix={<Radio></Radio>}
          style={{
            marginBottom: "1rem",
          }}
        />
      );
    });
  };

  useEffect(() => {
    Api.get(
      `/hiring-feedback/questions?questionId=${
        feedbackData?.feedbackId && !state?.id
          ? feedbackData?.feedbackId
          : state?.id
      }`
    ).send((response) => {
      if (typeof response != "undefined") {
        
        let data = {
          feedbackId: response.id,
          roundType: response.roundType,
          title: response.title,
        };
        dispatch(feedbackActions.updateFeedbackData(data));
        dispatch(
          feedbackActions.updateFeedbackAddQuestions(
            response.feedBackQuestionsMeta
          )
        );
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Button type="text" style={{ background: "none" }}>
            <img
              src={backIcon}
              onClick={() => {
                history.push({
                  pathname: "/fbround",
                  state: {
                    id: feedbackData?.feedbackId,
                  },
                });               
                 dispatch(feedbackActions.updateFeedbackData(null));
              }}
              style={{ width: "20px", height: "20px" }}
            />
          </Button>

          <div
            style={{
              fontWeight: "700",
              fontSize: "1.5rem",
              color: "#0086FF",
              padding: "0",
            }}
          >
           
            Preview
            

        </div>
        </div>
          <div style={{color:"black",alignItems:"center",justifyContent:"center",fontSize:"20px"}}>
          <b style={{  color: "#0086FF",}}>{feedbackData?.title}</b> <br></br><b style={{  color: "#0086FF",fontSize:"12px"}}>RoundType-{feedbackData?.roundType}</b></div>
        <Button
          style={{
            ...navBtnStyle,
          }}
          onClick={() => {
            // setcontainerState("panelist");
            history.push({
              pathname: "/fbround",
              state: {
                id: selectedCard,
              },
            });
          }}
        >
          Edit
        </Button>
      </div>

      <div style={{ width: "60%", margin: "2rem auto" }}>
        <div>
          {feedbackaddquestions?.map((item, count) => {
            return (
              <Card
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    padding: "0 1rem",
                    alignItems: "center",
                    gap: "0.5rem",
                    border: `1px solid ${Color.midGrey}`,
                  }}
                >
                  <span>Q{count + 1}. </span>
                  <Input
                    size="large"
                    bordered={false}
                    value={item?.question}
                    disabled={true}
                  ></Input>
                </div>

                {item?.inputType == "Text" ? (
                  <Input
                    size="large"
                    style={{
                      marginBottom: "2rem",
                    }}
                    disabled={true}
                  />
                ) : item?.inputType == "Rating" ? (
                  <Rate
                    size="large"
                    style={{
                      marginBottom: "1rem",
                    }}
                  />
                ) : item?.inputType == "Multiple Choice" ? (
                  <>{options(item?.options)}</>
                ) : item?.inputType == "File Attachment" ? (
                  <Upload
                    multiple={true}
                    listType="text"
                    accept=""
                    action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                    showUploadList={{ showRemoveIcon: true }}
                  >
                    <Button>
                      <PaperClipOutlined />
                      Attach A File
                    </Button>
                  </Upload>
                ) : (
                  <Slider />
                )}
              </Card>
            );
          })}
        </div>
      </div>
    </>
  );
}
export default FeedbackPreview;
