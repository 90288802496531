import React, { useEffect, useState,useRef } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Space,
  Switch,
  Button,
  Rate,
  Radio,
  Upload,
  Typography,
  Slider,
  Select,
  Spin,
} from "antd";
import {
  DownOutlined,
  StarOutlined,
  FileTextOutlined,
  PaperClipOutlined,
  MenuOutlined,
  SlidersOutlined,
} from "@ant-design/icons";

// import ReactQuillText from "react-quill";
// import './textEditor.css'
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useQuill } from "react-quilljs";
import { useHistory } from "react-router-dom";
import { feedbackActions } from "./store";
import CreateFeedbackModal from "./createFeedbackModal";
import classes from "./feedbackRound.module.css";
import Api from "services/Api";
import CardData from "./card";

import backIcon from "../../assets/backIcon.svg";
import deleteIcon from "../../assets/delete.svg";
import saveIcon from "../../assets/save.svg";
import editIcon from "./asset/Group_15123.svg";
import { Color } from "services";
import {Notification} from "services";
const { Option } = Select;
const { Title, Paragraph } = Typography;

const FeedbackRound = () => {
  const history = useHistory();
  const { state } = history.location;

  const [isArray, setIsArray] = useState([]);
  const [dropdownvalue, setDropDownvalue] = useState();
  const [dropdownvalue1, setDropDownvalue1] = useState();
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState("");
  const [options1, setOptions1] = useState("");
  const [multiple, setMultiple] = useState(new Array(6).fill(""));
  const [count, setCount] = useState(3);
  const { quill, quillRef } = useQuill();
  const [required, setRequired] = useState(false);
  const [iscard, setIsCard] = useState(null);
  const [isaddmultiplechoice, setIsAddMultipleChoice] = useState([]);
  const [loader, setLoader] = useState(false);

  const [addQuestionCardShow, setaddQuestionCardShow] = useState(false);

  const { feedbackData, feedbackMetaType, feedbackaddquestions } = useSelector(
    (state) => state.feedbacks
  );
  const dispatch = useDispatch();

  const feedbackPrevquestions = useSelector(
    (state) => state?.feedbacks?.feedbackaddquestions
  );
  const { feedBackQuestionsMeta } = feedbackPrevquestions;
  const feedbackquestions = useSelector((state) => state?.feedbacks?.lists);
  const inputRef = useRef(null);

  useEffect(() => {
    if (addQuestionCardShow === true) {
      inputRef.current.focus();
    }
  }, [addQuestionCardShow]);

  useEffect(() => {
    setLoader(true)
    Api.get(
      `/hiring-feedback/questions?questionId=${
        feedbackData?.feedbackId && !state?.id
          ? feedbackData?.feedbackId
          : state?.id
      }`
    ).send((response) => {
      if (typeof response != "undefined") {
      
        let data = {
          feedbackId: response.id,
          roundType: response.roundType,
          title: response.title,
        };
        dispatch(feedbackActions.updateFeedbackData(data));
        dispatch(
          feedbackActions.updateFeedbackAddQuestions(
            response.feedBackQuestionsMeta
          )
        );
    setLoader(false)
      }
    });
  }, []);

  const modules = {
    toolbar: [["bold", "italic", "underline", "strike"]],
  };
  const getFedques = () => {
    setLoader(true);
    Api.get(
      `/hiring-feedback/questions?questionId=${
        feedbackData?.feedbackId && !state?.id
          ? feedbackData?.feedbackId
          : state?.id
      }`
    ).send((response) => {
      if (typeof response != "undefined") {
        
        let data = {
          feedbackId: response.id,
          roundType: response.roundType,
          title: response.title,
        };
        dispatch(feedbackActions.updateFeedbackData(data));
        dispatch(
          feedbackActions.updateFeedbackAddQuestions(
            response.feedBackQuestionsMeta
          )
        );
        setLoader(false);
      }
    });
  };
  {
    feedBackQuestionsMeta?.map((item) => {
      
      setIsCard(item?.id);
     
    });
  }

  useEffect(() => {
  
  }, [iscard]);

  const addCard = (e) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setaddQuestionCardShow(true);
  };

  const Deleteapi = () => {
    
    
    if(!iscard){
      
      Notification.error("error","you cant delete without saving")
      return
    }
    let params = {
      id: iscard,
    };
    Api.delete("/hiring-feedback/deleteMetaQuestion")
      .params(params)
      .send((response) => {
        
      });
  };

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        
      });
    }
  }, [quill]);
  const addmultiplechoice = () => {
    if (isaddmultiplechoice.length < 4) {
      
      const addOption = [...isaddmultiplechoice, []];
      setIsAddMultipleChoice(addOption);
    }
  };

  const handleAdd = () => {
    const obj = {
      a: options,
      b: options1,
      c: multiple[0],
      d: multiple[1],
      e: multiple[2],
      f: multiple[3],
    };
   
    let questionId;
    if (state?.id && !feedbackData?.feedbackId) {
      questionId = state.id;
    }

    let params = {
      feedbackId: feedbackData?.feedbackId,
      question: question,
      inputType: dropdownvalue,
      require: required,
      options: obj,
    };
    if (questionId) {
      params = {
        feedbackId: feedbackData?.feedbackId,
        question: question,
        inputType: dropdownvalue,
        require: required,
        options: obj,
        questionId: questionId,
        feedbackMetaType: feedbackMetaType,
      };
    }
    Api.post("/hiring-feedback/addFeedBackQuestion")
      .params(params)
      .send((res) => {

        if (res.show.type!=="error") {
          setRequired(false)
          setOptions("");
          setOptions1("");
          setQuestion("");
          setDropDownvalue();
          setMultiple(new Array(6).fill(""));
          setLoader(true);
          Api.get(
            `/hiring-feedback/questions?questionId=${
              feedbackData?.feedbackId ? feedbackData?.feedbackId : state.id
            }`
          ).send((response) => {
            if (typeof response != "undefined") {
             
              setIsAddMultipleChoice([]);
              dispatch(
                feedbackActions.updateFeedbackAddQuestions(
                  response.feedBackQuestionsMeta
                )
              );
              setaddQuestionCardShow(false);
              setLoader(false);
            }
          });
          
        } else {
       
        }
       
      });
  
  };

  const multipleChoiceHandler = (value, index) => {
    setMultiple(
      multiple.map((item, ind) => {
        if (index === ind) {
          return value;
        }
        return item;
      })
    );
  };

  const handleEditModal = () => {
    dispatch(feedbackActions.updateEditFeedbackModal(true));
    dispatch(feedbackActions.updateFeedbackSaveState("edit"));
  };

  
  return (
    <>
      <div style={{ background: "#F8F8F8", padding: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "1%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "2%" }}>
            <Button type="text" style={{ background: "none" }}>
              <img
                src={backIcon}
                onClick={() => {
                  history.push("/fbmanagement");
                  dispatch(feedbackActions.updateFeedbackData(null));
                }}
              ></img>
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                flexDirection: "column",
                height: "4rem",
                gap: "0",
                minWidth: "30rem",
              }}
            >
              <div
                style={{
                  margin: "0",
                  height: "2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "700",
                    textAlign: "left",
                  }}
                >
                  <Typography.Text style={{width: "300px",fontSize:"1.2rem",fontWeight:"bold"}} ellipsis={{tooltip:true}}>{feedbackData?.title}</Typography.Text>
                </h4>
                <Button type="text" onClick={handleEditModal}>
                  <img
                    style={{ width: "1rem", height: "1rem" }}
                    src={editIcon}
                  />
                </Button>
              </div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  height: "1.6rem",
                  margin: "0",
                  textAlign: "start",
                  color: Color.darkGrey,
                }}
              >
                Round Type - {feedbackData?.roundType}
              </span>
            </div>
          </div>

          <div>
            <Button
              onClick={() => {
                history.push({
                  pathname: "/fbpreview",
                  state: feedbackData?.feedbackId
                    ? feedbackData?.feedbackId
                    : state.id,
                });
              }}
              style={{
                color: "#4DAAFF",
                border: "1.5px solid #4DAAFF",
                marginRight: "1rem",
                width: "8rem",
                height: "2.5rem",
                borderRadius: "4px",
                fontWeight: "700",
              }}
            >
              PREVIEW
            </Button>
          </div>
        </div>

        {/* </PageHeader> */}

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            gap: "2rem",
            justifyContent: "space-between",
            maxheight: "81vh",
            postion: "relative",
           
          }}
        >
          {loader && (
            <Row
              style={{
                width: "100%",
                height: "81vh",
                justifyContent: "center",
                overflowY: "scroll",
                background: Color.midGrey,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                zIndex:'99',
                opacity:'50%'
              }}
              className={classes.questionsCol}
            >
              <Spin />
            </Row>
          )}
          <Row
            style={{
              //display:"flex",
              width: "100%",
              height: "81vh",
              justifyContent: "center",
              overflowY: "scroll",
              // border:"2px solid red",
              // position:"relative",
            }}
            className={classes.questionsCol}
          >
            {feedbackaddquestions.length > 0 && (
              <div
                style={{
                  width: "100%",
                }}
              >
                {feedbackaddquestions?.map((data, count) => {
                 
                  if (feedbackaddquestions.length === 0) {
                    return "";
                  }
                  return (
                    <div style={{ marginBottom: "1rem", }}>
                      
                      <CardData
                        isArray={data}
                        refresh={getFedques}
                        onAddMultiple={isaddmultiplechoice}
                        questionNum={count + 1}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {addQuestionCardShow && (
              <Col style={{ width: "100%", borderRadius: "2rem" }}>
                <Card
                  bordered={false}
                  style={{
                    MinHeight: "13rem",
                    width: "100%",
                    margin: "0.5rem auto",
                    borderLeft: `0.4rem solid ${Color.modalblue}`,
                    borderRadius: "0.5rem",                  
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col span={15}>
                      <Input ref={inputRef}
                        value={question}
                        placeholder="Enter Question"
                        size="large"
                        onChange={(event) => setQuestion(event.target.value)}
                        className={classes.input}
                      />
                    </Col>

                    <Col>
                      <Space direction="vertical">
                        <Select 
                        
                          size="large"
                          value={dropdownvalue}
                          placeholder={
                            <span
                              style={{
                                fontWeight: "600",
                                color: Color.black,
                              }}
                            >
                              <MenuOutlined
                                style={{ marginRight: "10px" }}
                                size="large"
                              />
                            Please select
                            </span>
                          }
                          onChange={(e) => {
                            setDropDownvalue(e);
                          
                          }}
                          style={{ width: "13.5rem" }}
                          icon={<DownOutlined />}
                        >
                          {feedbackquestions?.inputType?.map((item) => {
                            if (item == "Text") {
                              return (
                                <Option key={item?.id} value={item}>
                                  <MenuOutlined
                                    style={{ marginRight: "10px",}}
                                    size="large"
                                  />
                                  {item}
                                </Option>
                              );
                            }
                            if (item == "Rating") {
                              return (
                                <Option key={item?.id} value={item}>
                                  <StarOutlined
                                    style={{ marginRight: "10px" }}
                                    size="large"
                                  />
                                  {item}
                                </Option>
                              );
                            }
                            if (item == "Multiple Choice") {
                              return (
                                <Option key={item?.id} value={item}>
                                  <FileTextOutlined
                                    style={{ marginRight: "10px" }}
                                    size="large"
                                  />
                                  {item}
                                </Option>
                              );
                            }
                            if (item == "File Attachment") {
                              return (
                                <Option key={item?.id} value={item}>
                                  <PaperClipOutlined
                                    style={{ marginRight: "10px" }}
                                    size="large"
                                  />
                                  {item}
                                </Option>
                              );
                            }
                            if (item == "Slider") {
                              return (
                                <Option key={item?.id} value={item}>
                                  <SlidersOutlined
                                    style={{ marginRight: "10px" }}
                                    size="large"
                                  />
                                  {item}
                                </Option>
                              );
                            }
                          })}
                        </Select>
                      </Space>
                    </Col>
                  </Row>
                  <br />
                  {dropdownvalue && dropdownvalue == "Text" && (
                    <ReactQuill
                      className="ql-toolbar.ql-snow"
                      placeholder="Text Input"
                      modules={modules}
                    />
                  )}

                  {dropdownvalue && dropdownvalue == "Rating" && (
                    <Rate
                      size="large"
                      style={{
                        border: "1px solid #E5E5E5",
                        width: "498px",
                        paddingTop: "0px",
                        paddingBottom: "1px",
                      }}
                    />
                  )}

                  {dropdownvalue && dropdownvalue == "Multiple Choice" && (
                    <>
                      <Input
                        prefix={<Radio></Radio>}
                        placeholder="Option"
                        size="large"
                        onChange={(e) => setOptions(e.target.value)}
                      />
                      <Input
                        prefix={<Radio></Radio>}
                        placeholder="Option "
                        size="large"
                        style={{ marginTop: "10px" }}
                        onChange={(e) => setOptions1(e.target.value)}
                      />
                      {isaddmultiplechoice?.map((item, index) => {
                        
                        return (
                          <Input
                            prefix={<Radio></Radio>}
                            placeholder="option"
                            size="large"
                            style={{ marginTop: "10px" }}
                            onChange={(e) =>
                              multipleChoiceHandler(e.target.value, index)
                            }
                          />
                        );
                      })}
                      <Input
                        prefix={<Radio></Radio>}
                        placeholder="Add Option or Add Others"
                        size="large"
                        {...() => setCount(count + 1)}
                        style={{ marginTop: "10px" }}
                        onClick={() => {
                          addmultiplechoice();
                        }}
                      />
                    </>
                  )}

                  {dropdownvalue && dropdownvalue == "File Attachment" && (
                    <Upload
                      multiple={true}
                      listType="text"
                      accept=""
                      action={
                        "https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      }
                      showUploadList={{ showRemoveIcon: true }}
                    >
                      <Button>
                        <PaperClipOutlined />
                        Attach A File
                      </Button>
                    </Upload>
                  )}

                  {dropdownvalue && dropdownvalue == "Slider" && (
                    <Slider size="large" />
                  )}

                  <Row
                    style={{
                      marginTop: "3rem",
                      justifyContent: "right",
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <Col>
                      <Button
                        type="text"
                        style={{ background: "none", padding: " 0.2rem 0" }}
                        onClick={() => {
                          handleAdd();
                        }}
                      >
                        <img src={saveIcon} style={{  }} />
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="text"
                        onClick={() => {
                          Deleteapi();
                        }}
                      >
                        <img src={deleteIcon} style={{ color: "#D3D3D3" }} />
                      </Button>
                    </Col>
                    <Col
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                        fontFamily: "Open Sans",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      Required
                     
                      <Switch
                        value={required === true ? true : false}
                        style={{ color: "#D3D3D3" }}
                        onChange={(checked) => setRequired(checked)}
                      />
                    </Col>
                  </Row>
                </Card>
                <br />
              </Col>
            )}
          </Row>

          <div
            style={{
              width: "35%",
              borderTop: `0.4rem solid ${Color.modalblue}`,
              borderRadius: "0.5rem",
              height: "80vh",
              background: Color.white,
            }}
          >
            <div
              style={{
                width: "100%",
                marginTop: "1rem",
                height: "5vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: "2rem",
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "0.8rem",
                      height: "0.8rem",
                      background: "lightgreen",
                    }}
                  ></div>
                  <span style={{ marginLeft: "0.2rem", fontSize: "0.6rem" }}>
                    Saved Questions
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "0.8rem",
                      height: "0.8rem",
                      background: Color.modalblue,
                    }}
                  ></div>
                  <span style={{ marginLeft: "0.2rem", fontSize: "0.6rem" }}>
                    Ongoing Questions
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: Color.darkGrey,
                  marginLeft: "1rem",
                }}
              >
                Add Questions
              </span>
            </div>
            <div
              style={{
                margin: "1rem",
                display: "flex",
                flexWrap: "wrap",
                width: "40vh",

                maxheight: "81vh",
                alignItems: "center",
              }}
            >
              {feedbackaddquestions.length > 0 &&
                feedbackaddquestions.map((item, count) => (
                  <Button
                    size="large"
                    type="link"
                    shape="circle"
                    style={{
                      fontWeight: "bold",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      color: "#6BCB77",
                      border: "2px solid #6BCB77",
                      margin: "0 1rem 1rem 0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: "0.7rem",
                    }}
                  >
                    {count + 1}
                  </Button>
                ))}
              <Button
                onClick={(e) => {
                  addCard(e);
                }}
                size="large"
                type="link"
                shape="circle"
                style={{
                  fontWeight: "bold",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  color: "#4DAAFF",
                  border: "2px solid #4DAAFF",
                  marginBottom: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "0.7rem",
                }}
              >
                +
              </Button>
            </div>
          </div>
        </div>
      </div>
      <CreateFeedbackModal />
    </>
  );
};

export default FeedbackRound;
