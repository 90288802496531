import moment from "moment";
import {
  TOGGLE_SUMMARY_LOADER,
  UPDATE_SUMMARY_DATA,
  UPDATE_FILTER_PARAMS,
  UPDATE_SUMMARY_DETAILS_DATA,
  inputFieldType,
  inputFieldTypeHash,
} from "./action";
const initialState = {
  summaryLoader: false,
  summaryData: [],
  totalProfiles: "",
  totalNotShortlisted: "",
  totalInProgress: "",
  totalPending: "",
  totalDuplicate: "",
  totalRejected: "",
  selectedAgencyName: "",
  selectedAgencyNameTotalCount:"",
  profiles: {
    data: [],
    totalProfiles: "",
    totalNotShortlisted: "",
    totalInProgress: "",
    totalPending: "",
    totalRejected: "",
  },
  filterParams: {
    date: moment(),
    agencyId: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SUMMARY_LOADER:
      return {
        ...state,
        summaryLoader: action.bool,
      };
    case UPDATE_SUMMARY_DATA:
      return {
        ...state,
        summaryData: action.data?.data,
        totalProfiles: action.data?.totalProfiles,
        totalNotShortlisted: action.data?.totalNotShortlisted,
        totalInProgress: action.data?.totalInProgress,
        totalPending: action.data?.totalPending,
        totalDuplicate: action.data?.totalDuplicate,
        totalRejected: action.data?.totalRejected,
      };
    case UPDATE_SUMMARY_DETAILS_DATA:
      return {
        ...state,
        selectedAgencyName: action.data?.agencyName,
        profiles: action.data?.profiles,
        selectedAgencyNameTotalCount: action.data?.itemCount,
      };
    case UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: { ...state.filterParams, ...action.filterParams },
      };
    case inputFieldType[action.type]:
      return {
        ...state,
        [inputFieldTypeHash[action.type]]: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
