import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  lists: {},
  feedbackData: null,
  feedbackaddquestions: [],
  questionId: "",
  openCreateFeedbackModal: false,
  openEditFeedbackModal: false,
  selectedCard:'',
  selectCardCount:0,
  feedbackMetaType:"Panelist",
  feedbackSaveState:"create",
  // cardEditMode:'false'
};

const feedbackSlice = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {
    updateFeedbacksQuestions: (state, action) => {
    
      state.lists = action.payload;
    },
    updateFeedbackData: (state, action) => {
      state.feedbackData = action.payload;
    },
    updateFeedbackAddQuestions: (state, action) => {
    
      state.feedbackaddquestions = [...action.payload];
    },
    updateEditFeedbackModal: (state, action) =>{
      return{...state, openEditFeedbackModal : action.payload}
    },
    updateCreateFeedbackModal: (state, action) =>{
      return{...state, openCreateFeedbackModal : action.payload}
    },
    updateSelectedCardCount : (state, action) => {
      return {...state, selectCardCount: action.payload}
    },
    updateSelectedCard : (state, action) => {
      return {...state, selectCardCount: [...action.payload]}
    },
    updateFeedbackState : (state, action) => {
      return {...state, feedbackMetaType: action.payload}
    },
    updateFeedbackSaveState : (state, action) => {
      return {...state, feedbackSaveState: action.payload}
    },
    updateSelectedCards : (state, action) => {
      return {...state, selectedCard: action.payload}
    },
    // updateCardEditMode : (state, action) => {
    //   return {...state, cardEditMode: action.payload}
    // },
  },
  updateQuesID(state, action) {
    state.questionId = action.payload;
  },
});
export default feedbackSlice.reducer;
export const feedbackActions = feedbackSlice.actions;
