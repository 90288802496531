import {
  Space,
  Table,
  Tag,
  Button,
  Dropdown,
  PageHeader,
  Modal,
  Form,
  Menu,
  Row,
  Col,
  Tabs,
  Typography,
  Card,
  Timeline,
  Select,
  Input,
  Popover,
  Checkbox,
} from "antd";
import {  DownOutlined ,UserOutlined,SearchOutlined} from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import React, { useState, useEffect } from "react";
import Api from "../../services/Api";
import classes from "./institute.module.css";
import CollegeModal from "./addCollege";
import { collegeListActions } from "./store";
import ImportData from "./importCollegeExcel";
import { useDispatch, useSelector } from "react-redux";
import UpdateCollegeModal from "./updateCollege";
import Notification from "services/Notification";
import iconCollege from "../../assets/ill-college.png";
import moment from "moment";
import searchIcon from '../../assets/searchIcon.png'
import Excel from "../../assets/excel.png";
import { backendUrl } from "../../credentials.json"
import StatusIcon from "../../assets/cllgIcon.jpg"
import DownloadIcon from '../../assets/download.png';
import TemplateModal from './templateModal';
import { authActions } from "pages/login/store";
import {templateActions} from '../email-template/store';
//import ColumnFilter from "./columnFilter/columnFilter";


const { TabPane } = Tabs;
const { Title,Text } = Typography;
const { Option } = Select;
const { Search } = Input;
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}


function Colleges() {
  const present = moment().format("YYYY-MM-DD hh:mm a");
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnLoader1, setBtnLoader1] = useState(false);
  const [pageChange, setPageChange] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isDataImportModalVisible, setDataImportModal] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [btnVisible, setBtnVisible] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [emailCount, setEmailCount] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [isTemplateModalVisible, setTemplateModal] = useState(false);
  const [selectedObj,setSelectjob]=useState("")
  const authstate = useSelector((state) => state.auth);
  const [collegesType,setCollegeType]=useState("")
  const [searchStr,setSearchStr] = useState("");
  const [collegeStatus,setCollegeStatus]=useState([]);
  const [statusVal,setStatusVal]=useState("");
  const [jobVacancies,setJobVacancies]=useState([]);
  const [events,setEvent]=useState("")
  const [selectRows,setSelectRows]=useState([])
  // const [collegeList,setColleges] = useState([]);
  let [selectedRow, setSelectedRow] = useState([]);
  const [checkboxStates, setCheckboxState] = useState("hello");
  const dispatch = useDispatch();
  const { collegeList, info, instituteStatus,jd,collegeType } = useSelector(
    (state) => state.college
  );
  const [selectedUpdateObject, setUpdateObject] = useState();
  const onChange = (e) => {
    setCheckboxState("this is a new value");
  };
useEffect(() =>{
   fetchColleges(pageChange,pageSize,"")
},[])
  const customFilterPopover = (type = null, data = null, logo) => {
    let contentBody;
    switch (type) {
      case "Status" : 
      contentBody = <div style={{width:"250px !important"}}>
      <Checkbox.Group
        style={{
          width: '100%',
        }}
      >
        <div><span style={{ fontSize: '20px' }}>ALL</span><Checkbox style={{ float: "right" }}></Checkbox></div>

        {
          jobVacanciesList.map(element => {
         
            return element ? <div>
              <span style={{ fontSize: '20px' }}>{element.title}</span>
              <Checkbox value={element} style={{ float: "right" }}
                onChange={(e) => {
               
                  let temp = jobVacanciesList;
                  
                  let index = jobVacanciesList.findIndex(a => a.title == element.title);
                  
                  if (index !== -1) {

                    temp[index].selected = !temp[index].selected;
                  }

                  setJobVacancies(temp);
                }}

              ></Checkbox></div> : ""
          })
        }

      </Checkbox.Group>

      <div className="sort-action">
        <Button className="cancel" onClick={() => {
              
              let tempStatus = filterStatus;
              tempStatus.title = false
              switchFilterStatus(tempStatus)
              forceUpdate();

        }}>CANCEL</Button>
        <Button className="float-right ok" onClick={() => {
          getfilterRequest(type);
          filterCandiate();
        }}>OK</Button>
      </div>
    </div>

    return <Popover
      placement="bottom"
      content={contentBody}
      trigger="click"
      visible={filterStatus.title}
      onVisibleChange={() => {
        let tempStatus = filterStatus;
        tempStatus.title = true
    
        switchFilterStatus(tempStatus)
        forceUpdate();
      }}
    >
      {logo}
    </Popover>

  


      break;
      case "agency":

        contentBody = <div className="sort-popover">

          <Checkbox.Group
            style={{
              width: '100%',
            }}
          >
            <div><span style={{ fontSize: '20px' }}>ALL</span><Checkbox style={{ float: "right" }}></Checkbox></div>

            {
              agency.map(element => {
                return element ? <div>
                  <span style={{ fontSize: '20px' }}>{element.agencyName}</span>
                  <Checkbox value={element} style={{ float: "right" }}
                    onChange={(e) => {
                    
                      let temp = agency;

                      let index = agency.findIndex(a => a.agencyName == element.agencyName);
                   
                      if (index !== -1) {
                        temp[index].selected = !temp[index].selected;
                      }
                      
                      setAgency(temp);
                    }}

                  ></Checkbox></div> : ""
              })
            }

          </Checkbox.Group>

          <div className="sort-action">
          <Button className="cancel" onClick={() => {
              
              let tempStatus = filterStatus;
              tempStatus.agency = false
              switchFilterStatus(tempStatus)
              forceUpdate();

        }}>CANCEL</Button>
            <Button className="float-right ok" onClick={() => {
              getfilterRequest(type);
              filterCandiate();
            }}>OK</Button>
          </div>
        </div>

        return <Popover
          placement="bottom"
          content={contentBody}
          trigger="click"
          visible={filterStatus.agency}
          onVisibleChange={() => {
            let tempStatus = filterStatus;
            tempStatus.agency = true
        
            switchFilterStatus(tempStatus)
            forceUpdate();
          }}
         
        >
          {logo}
        </Popover>

        break;
        case "candidateStatus":

          contentBody = <div className="sort-popover">
  
            <Checkbox.Group
              style={{
                width: '100%',
              }}
            >
              <div><span style={{ fontSize: '20px' }}>ALL</span><Checkbox style={{ float: "right" }}></Checkbox></div>
  
              {
                candidateStatus.map(element => {
                  return element ? <div>
                    <span style={{ fontSize: '20px' }}>{element.title}</span>
                    <Checkbox value={element} style={{ float: "right" }}
                      onChange={(e) => {
                      
                        let temp = candidateStatus;
  
                        let index = candidateStatus.findIndex(a => a.title == element.title);
                        
                        if (index !== -1) {
                          temp[index].selected = !temp[index].selected;
                        }
                      
                        setCandidateStatus(temp);
                      }}
  
                    ></Checkbox></div> : ""
                })
              }
  
            </Checkbox.Group>
  
            <div className="sort-action">
            <Button className="cancel" onClick={() => {
                
                let tempStatus = filterStatus;
                tempStatus.candidateStatus = false
                switchFilterStatus(tempStatus)
                forceUpdate();
  
          }}>CANCEL</Button>
              <Button className="float-right ok" onClick={() => {
                getfilterRequest(type);
                filterCandiate();
              }}>OK</Button>
            </div>
          </div>
  
          return <Popover
            placement="bottom"
            content={contentBody}
            trigger="click"
            visible={filterStatus.candidateStatus}
            onVisibleChange={() => {
              let tempStatus = filterStatus;
              tempStatus.candidateStatus = true
          
              switchFilterStatus(tempStatus)
              forceUpdate();
            }}
           
          >
            {logo}
          </Popover>
         case "roundStatus1":

          contentBody = <div className="sort-popover">
  
            <Checkbox.Group
              style={{
                width: '100%',
              }}
            >
              <div><span style={{ fontSize: '20px' }}>ALL</span><Checkbox style={{ float: "right" }}></Checkbox></div>
  
              {
                roundStatus.map(element => {
                  return element ? <div>
                    <span style={{ fontSize: '20px' }}>{element.title}</span>
                    <Checkbox value={element} style={{ float: "right" }}
                      onChange={(e) => {
                      
                        let temp = roundStatus;
  
                        let index = roundStatus.findIndex(a => a.title == element.title);
                       
                        if (index !== -1) {
                          temp[index].selected = !temp[index].selected;
                        }
                      
                        setRoundStatus(temp);
                      }}
  
                    ></Checkbox></div> : ""
                })
              }
  
            </Checkbox.Group>
  
            <div className="sort-action">
            <Button className="cancel" onClick={() => {
                
                let tempStatus = filterStatus;
              
                tempStatus.roundStatus1 = false
                switchFilterStatus(tempStatus)
                forceUpdate();
  
          }}>CANCEL</Button>
              <Button className="float-right ok" onClick={() => {
                getfilterRequest(type);
                filterCandiate();
              }}>OK</Button>
            </div>
          </div>
  
          return <Popover
            placement="bottom"
            content={contentBody}
            trigger="click"
            visible={filterStatus.roundStatus1}
            onVisibleChange={() => {
              let tempStatus = filterStatus;
              tempStatus.roundStatus2=false
              tempStatus.roundStatus3=false
              tempStatus.roundStatus4=false
              tempStatus.roundStatus1 = true
          
              switchFilterStatus(tempStatus)
              forceUpdate();
            }}
           
          >
            {logo}
          </Popover>
  
          break;
          case "roundStatus2":

            contentBody = <div className="sort-popover">
    
              <Checkbox.Group
                style={{
                  width: '100%',
                }}
              >
                <div><span style={{ fontSize: '20px' }}>ALL</span><Checkbox style={{ float: "right" }}></Checkbox></div>
    
                {
                  roundStatus.map(element => {
                    return element ? <div>
                      <span style={{ fontSize: '20px' }}>{element.title}</span>
                      <Checkbox value={element} style={{ float: "right" }}
                        onChange={(e) => {
                        
                          let temp = roundStatus;
    
                          let index = roundStatus.findIndex(a => a.title == element.title);
                          if (index !== -1) {
                            temp[index].selected = !temp[index].selected;
                          }
                          setRoundStatus(temp);
                        }}
    
                      ></Checkbox></div> : ""
                  })
                }
    
              </Checkbox.Group>
    
              <div className="sort-action">
              <Button className="cancel" onClick={() => {
                  
                  let tempStatus = filterStatus;
                  tempStatus.roundStatus2 = false
                  switchFilterStatus(tempStatus)
                  forceUpdate();
    
            }}>CANCEL</Button>
                <Button className="float-right ok" onClick={() => {
                  getfilterRequest(type);
                  filterCandiate();
                }}>OK</Button>
              </div>
            </div>
    
            return <Popover
              placement="bottom"
              content={contentBody}
              trigger="click"
              visible={filterStatus.roundStatus2}
              onVisibleChange={() => {
                let tempStatus = filterStatus;
                tempStatus.roundStatus1=false
                tempStatus.roundStatus3=false
                tempStatus.roundStatus4=false
                tempStatus.roundStatus2 = true
            
                switchFilterStatus(tempStatus)
                forceUpdate();
              }}
             
            >
              {logo}
            </Popover>
           case "roundStatus3":
  
            contentBody = <div className="sort-popover">
    
              <Checkbox.Group
                style={{
                  width: '100%',
                }}
              >
                <div><span style={{ fontSize: '20px' }}>ALL</span><Checkbox style={{ float: "right" }}></Checkbox></div>
    
                {
                  roundStatus.map(element => {
                    return element ? <div>
                      <span style={{ fontSize: '20px' }}>{element.title}</span>
                      <Checkbox value={element} style={{ float: "right" }}
                        onChange={(e) => {
                        
                          let temp = roundStatus;
    
                          let index = roundStatus.findIndex(a => a.title == element.title);
                          if (index !== -1) {
                            temp[index].selected = !temp[index].selected;
                          }
                          setRoundStatus(temp);
                        }}
    
                      ></Checkbox></div> : ""
                  })
                }
    
              </Checkbox.Group>
    
              <div className="sort-action">
              <Button className="cancel" onClick={() => {
                  
                  let tempStatus = filterStatus;
                  tempStatus.roundStatus3 = false
                  switchFilterStatus(tempStatus)
                  forceUpdate();
    
            }}>CANCEL</Button>
                <Button className="float-right ok" onClick={() => {
                  getfilterRequest(type);
                  filterCandiate();
                }}>OK</Button>
              </div>
            </div>
    
            return <Popover
              placement="bottom"
              content={contentBody}
              trigger="click"
              visible={filterStatus.roundStatus3}
              onVisibleChange={() => {
                let tempStatus = filterStatus;
                tempStatus.roundStatus2=false
                tempStatus.roundStatus1=false
                tempStatus.roundStatus4=false
                tempStatus.roundStatus3 = true
            
                switchFilterStatus(tempStatus)
                forceUpdate();
              }}
             
            >
              {logo}
            </Popover>
    
            break;
            case "roundStatus4":
  
              contentBody = <div className="sort-popover">
      
                <Checkbox.Group
                  style={{
                    width: '100%',
                  }}
                >
                  <div><span style={{ fontSize: '20px' }}>ALL</span><Checkbox style={{ float: "right" }}></Checkbox></div>
      
                  {
                    roundStatus.map(element => {
                      return element ? <div>
                        <span style={{ fontSize: '20px' }}>{element.title}</span>
                        <Checkbox value={element} style={{ float: "right" }}
                          onChange={(e) => {
                          
                            let temp = roundStatus;
      
                            let index = roundStatus.findIndex(a => a.title == element.title);
                            if (index !== -1) {
                              temp[index].selected = !temp[index].selected;
                            }
                            setRoundStatus(temp);
                          }}
      
                        ></Checkbox></div> : ""
                    })
                  }
      
                </Checkbox.Group>
      
                <div className="sort-action">
                <Button className="cancel" onClick={() => {
                    
                    let tempStatus = filterStatus;
                    tempStatus.roundStatus4 = false
                    switchFilterStatus(tempStatus)
                    forceUpdate();
      
              }}>CANCEL</Button>
                  <Button className="float-right ok" onClick={() => {
                    getfilterRequest(type);
                    filterCandiate();
                  }}>OK</Button>
                </div>
              </div>
      
              return <Popover
                placement="bottom"
                content={contentBody}
                trigger="click"
                visible={filterStatus.roundStatus4}
                onVisibleChange={() => {
                  let tempStatus = filterStatus;
                  tempStatus.roundStatus2=false
                  tempStatus.roundStatus1=false
                  tempStatus.roundStatus3=false
                  tempStatus.roundStatus4 = true
              
                  switchFilterStatus(tempStatus)
                  forceUpdate();
                }}
               
              >
                {logo}
              </Popover>
      
              break;

      default:
       
    if(type === 'currentCtc' || type == 'noticePeriod' || type=="expectedCtc"){
      
        contentBody = <div className="sort-popover">
          <div>
            <span>min</span>
            <Input type="number" onChange={(e) => {
              setFilterFilter(type, "min", e.target.value)
            }} />
            <span>max</span>
            <Input type="number"
              onChange={(e) => {
                setFilterFilter(type, "max", e.target.value);
              }}
            />
          </div>
          <div className="sort-action">
            <Button className="cancel" 
            onClick={()=>{
             
              let temp = filterStatus;
              temp[type] = !temp[type];
             
              switchFilterStatus(temp);
              forceUpdate();
            }}
  >
              CANCEL</Button>
            <Button className="float-right ok" onClick={() => {
              getfilterRequest(type);
              filterJob();
              forceUpdate();
            }}>OK</Button>
          </div>
        </div>

        return <Popover
          placement="bottom"
          content={contentBody}
          trigger="click"
          visible={filterStatus[type]}
          onVisibleChange={()=>{
   
            // settemp(true)
            let temp = filterStatus;
            // temp["x"]= !temp["x"];
            temp[type] = !temp[type];
            switchFilterStatus(temp);
            forceUpdate();
          }}
        >
          {logo}
        </Popover>
  }
     
      break;
   
    }
  };

  // useEffect(() => {

  //   fetchColleges(pageChange, pageSize, "");
  //   fetchTemplate();
  // }, [pageChange, pageSize]);

  const fetchColleges = (pageChange, pageSize, value) => {

    if(!pageChange || pageChange===""){
      pageChange=""
    }
    if(!pageSize || pageSize===""){
      pageSize=""
    }
    if(!value){
      value=""
    }
    setTableLoader(true);
    Api.get(
      `/hiring-college/getAllColleges?job=${selectedObj ? selectedObj :"" }&page=${pageChange}&limit=${pageSize}&search=${value}`
    ).send((response) => {
  
      if (typeof response != "undefined") {
        
        setTableLoader(false);
        dispatch(collegeListActions.updateCollegeList(response.college.items));
        dispatch(collegeListActions.updateInfo(response.college.meta));
        dispatch(collegeListActions.updateJD(response.jobVacancies));
        dispatch(collegeListActions.updateCollegeTYPE(response.collegeType))

        setCollegeStatus(response.collegeStatus);
        setJobVacancies(response.jobVacancies);
      
        dispatch(
          collegeListActions.updateInstituteStatus(response.institueStatus)
        );
        setEmailCount(response.emailCount)
      }
    });
  }

  const fetchDownloadLink = () => {
    let valued
  if(statusVal!=="")
  {
   valued=`status=${statusVal}`
  }
  else{
    valued=""
  }
  let obj={}
  if(collegesType.length!==0)
  {
     obj={
      collegeType:collegesType
     }
  }
    Api.get(`/hiring-college/getAllColleges?collegeIds=${selectRows}&search=${searchStr}&job=${selectedObj ?selectedObj:""}&${valued}`)
    // console.log("Entering")
    // let queryStr=`collegeIds=${selectRows}&search=${searchStr}&job=${selectedObj ?selectedObj:""}`
    // if(events)
    // {
    //   queryStr=`collegeIds=${selectRows}&search=${searchStr}&job=${selectedObj ?selectedObj:""}&status=${events}`
    // }
    //   Api.get(`/hiring-college/getAllColleges?${queryStr}`)   
    .params({ download: true,

      ...obj })
       .send((response) => {
      
          if (typeof response != "undefined") {
           
            window.open(response)
          }
       });
  }

  const fetchTemplate = () => {
    Api.get('/hiring-template/all').send(res => {
      dispatch(templateActions.updateTemplates(res))
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectRows([selectedRowKeys])
      setSelectedRow(selectedRowKeys);
      if (selectedRowKeys.length === 0) {
        setBtnVisible(false);
      } else {
        setBtnVisible(true);
      }
    },
    onSelect: (record, selected, selectedRows) => {
      // selectedRow={selectedRows}
     
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      
    },
    getCheckboxProps: (record) => ({
      // disabled: record.status === "document uploaded",
    }),
  };
  const handlePageChange = (page, pageSize) => {

    setPageChange(page);
    setPageSize(pageSize);
    fetchColleges(page,pageSize,"")
  };




  const timelineCommonCode = (status) => {
    let sortedList = status.collegeStatusHistory.slice().sort((a, b) => a.id > b.id ? 1 : -1)
    return (
      <div style={{ overflowY: "scroll", maxHeight: "300px" }}>
        <Timeline
          mode="left"
          // className="candidate-timeline"
          style={{ width: "200px" }}
          pending={false}
          // pending={status.status === "Accepted" || status.status === "Rejected" ? "" : "Waiting"}
        >
          {
            sortedList.map(element => {
              let date = new Date(element.date).toLocaleDateString().split('/').reverse().join('-')
              let time = new Date(element.date).toLocaleTimeString();
              let dateString = new Date(element.date).toLocaleDateString();
              return (<Timeline.Item color="green"><p style={{ color: "#6F6F6F", marginTop: "20px" }}>{element.status}</p><p>{date}<b>,</b>&nbsp;{time}</p></Timeline.Item>)
            })
          }
        </Timeline></div>
    );
  };
  const onClickMenu = (value, ids) => {
    setTableLoader(true)
   
    Api.patch(`/hiring-college/updateCollegeStatus/${ids}`).params({ status: value.key })
      .send((response) => {
        // setBtnLoader(false);
      
        if (typeof response != undefined && response?.type == "success") {
          // setIsModalVisible(false)
          // Notification.success(response?.message);
          // props.toggle();
          fetchColleges(pageChange, pageSize, "");
        } else {
          Notification.error("Unable to update Institution");
        }
      });
  }


  // const filterFunction=(filterItem,filterType)=>{
  //   switch(filterType){
  //     case "jobVacancy":
  //       let temp=false;
  //       filterItem?.map(item=>{
  //         if(item==='')
  //         {
  //           temp=true;
  //           return;
  //         }
  //       })
  //     if(temp)
  //     {
  //       Api.get(`/hiring-college/getAllColleges`).send(resp=>{
  //         if(resp)
  //         {
  //           dispatch(collegeListActions.updateCollegeList(resp.college));
  //         }
         
  //       })      
  //       return ;

  //     }
        
  //         Api.get(`/hiring-college/getAllColleges?job=${filterItem[0]}&page=${pageChange}&limit=${pageSize}`).send(resp=>{
  //           if(resp)
  //           {
              
  //             dispatch(collegeListActions.updateCollegeList(resp.college));
  //           }
           
  //         })
  //         break;

  //         case "status":
  //           let temp2=false;
  //           filterItem?.map(item=>{
  //             if(item==='')
  //             {
  //               temp2=true;
  //               return;
  //             }
  //           })
            
  //             if(temp2)
  //             {
  //               Api.get(`/college/getAllColleges`).send(resp=>{
  //                 if(resp)
  //                 {
  //                   dispatch(collegeListActions.updateCollegeList(resp.college));
  //                 }
                  
    
  //               })
    
  //               return;
  //             }
  //             Api.get(`/college/getAllColleges?status=${filterItem[0]}`).send(resp=>{
  //               if(resp)
  //               {
               
  //                 dispatch(collegeListActions.updateCollegeList(resp.college));
  //               }
               
  //             })          
  //           break;

  //         default:
  //           break;
  //       }}
        


  const statusMenu = (ids) => (
    <Menu onClick={(key) => onClickMenu(key, ids)}>
      {instituteStatus.map((element) => {
        return (
          <Menu.Item disabled={element !== "Accepted" && element !== "Rejected"} key={element}>{element}</Menu.Item>
        )
      })}

    </Menu>
  );

  const toggleTemplateModal = (data = null) => {
    if (data) {
      if (data.id) {
        setUpdateObject([data.id]);
      } else {
        setUpdateObject(data);
      }
    }
    
    ([])
    setTemplateModal(!isTemplateModalVisible);
  }

  const columns = [

    {
      title: <div className={classes.headerName} style={{minWidth:"7rem"}}>
      <span style={{marginTop:"-1.7rem"}}>INSTITUTE NAME</span>
        </div>,
      dataIndex: "name",
      key: "name",
      width:"17%",
      render: (text, record) => {
        const titles=record?.college.name
        return(
        <a
          onClick={() => toggleUpdateModal(record)}
          type="text"
          style={{ color: "black" }}
        >
          <b><Text style={{width:110,symbol: 'more',}} 
                 ellipsis={{tooltip: {titles},backgroundColor:"white",}}
              >{record.college.name}</Text></b>

        </a>)
    }
    },
    {
      title: 
      <div style={{fontWeight:"bold",width:"12rem"}}><span style={{marginLeft:"0.7rem"}}>JOB VACANCY</span>
      {/* <ColumnFilter content={[{title:"All"}].concat(jobVacancies)} filterType="jobVacancy" filterFunction={filterFunction}/> */}
        <Select 
        placeholder="Select Jobs list"
        // allowClear
        bordered={false}
        style={{width:"9rem"}}  
        onChange={(event)=>{
          
            let obj={}

            if(collegesType.length!==0)
            {
               obj={
                collegeType:collegesType
               }
            }
          if(event==='')
          {   let value
         
            if(events!==""){
                value=`&status=${statusVal}`
            }
            else{
              value=""
            }
            Api.get(`/hiring-college/getAllColleges?${value}`)
            .params(obj)
            .send(resp=>{
              if(resp)
              {
                dispatch(collegeListActions.updateCollegeList(resp.college.items));
                dispatch(collegeListActions.updateInfo(resp.college.meta));
              }
             
            })
           
            return ;

          }
              
              setSelectjob(event === "ALL" ? "" : event)
              let value
              if(statusVal!==""){
                  value=`&status=${statusVal}`
              }
              else{
                value=""
              }
              Api.get(`/hiring-college/getAllColleges?job=${event === "ALL" ? "" : event}${value}`)
              .params(obj)
              .send(resp=>{
                if(resp)
                {
                  
                  dispatch(collegeListActions.updateCollegeList(resp.college.items));
                  dispatch(collegeListActions.updateInfo(resp.college.meta));
                }
               
              })

            }}>
              <Option value={"ALL"}>ALL</Option>
          {jobVacancies?.map((item,index)=>{
           
            return <Option value={item.id} >{item.title}</Option>
          })}

        </Select>

        </div>,
      
      dataIndex: "",
      key: "",
      width:"17%",
      render: (text, record) => {
        return (
          <p>{record.job.title}</p>
        );
      }
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem"}}>
      <span style={{marginTop:"-1.7rem"}}>PRIMARY NAME</span>
        </div>,
      dataIndex: "primaryEmail",
      key: "primaryEmail",
      width:"17%",
      render: (text, record) => {
        const titles=record?.college?.primaryEmail
        return (
          <>
            <p>{record.college.primaryContactName}</p>
            <p style={{ color: "#6F6F6F" }}>
            <Text style={{width:157,symbol: 'more',}} 
                 ellipsis={{tooltip: {titles},backgroundColor:"white",}}
              >{record?.college?.primaryEmail}</Text></p>
            <p>{record.college.primaryPhone}</p>
          </>
        );
      },
    },
    {
      title: <div style={{fontWeight:"bold",width:"13rem"}}>
           <span style={{marginLeft:"0.7rem"}}>COLLEGE TYPE</span>
        <Select
        placeholder="Select CollegeType"
        bordered={false}
        mode="multiple"
        showArrow
        style={{width:"10.4rem"}}
        // suffixIcon={<DownOutlined/>}
        onChange={(event)=>{
          setCollegeType(event)
          
          if(event==='')
          {   let value
            if(events!==""){
                value=`&status=${event}`
            }
            else{
              value=""
            }
            Api.get(`/hiring-college/getAllColleges?job=${selectedObj ?selectedObj :"" }${value}`)
            .send(resp=>{
              if(resp)
              {
                dispatch(collegeListActions.updateCollegeList(resp.college.items));
                dispatch(collegeListActions.updateInfo(resp.college.meta));
              }
            })
           
            return ;
          }

          else{

              let value
              if(statusVal!==""){
                  value=`&status=${statusVal}`
              }
              else{
                value=""
              }
              Api.get(`/hiring-college/getAllColleges?job=${selectedObj ?selectedObj :"" }${value}`)
              .params({collegeType:event})
              .send(resp=>{
                if(resp)
                {
                  
                  dispatch(collegeListActions.updateCollegeList(resp.college.items));
                  dispatch(collegeListActions.updateInfo(resp.college.meta));
                }
               
              })
            }

            }}>
        
             { collegeType.map((element) =>{
              return(
                <Option value={element} >{element}</Option>
              )
             })}
            
         {/* <Option value={} >{item.title}</Option> */}
          

        </Select>
      
        </div>,
      dataIndex: "collegeType",
      key: "collegeType",
      width:"16.5%",
      render: (text, record) => {
        return (
          <>
            <p>{record.college.collegeType}</p>
           
          </>
        );
      },
    },

    {
      title: <div className={classes.headerName} style={{minWidth:"7rem"}}>
      <span  style={{marginTop:"-1.8rem"}}>ACTION</span>
        </div>,
      key: "action",
      render: (_, record) => (

        <Button style={{ backgroundColor: "#6BCB77" }}
          onClick={() => toggleTemplateModal(record)}
        >
          <p style={{ color: "white" }}>Send Email</p>
        </Button>

      ),
    },
    {
        title: <div style={{width:"10rem",fontWeight:"bold",}}>
        <span style={{marginLeft:"0.7rem"}}>STATUS</span>
        <div>
        <Select 
        allowClear
        placeholder="Select Status"
        bordered={false} 
        style={{width:"8rem",}}
         onChange={(event)=>{
          // if(!event)
          // {
          //   setEvent("")
          // }
          // else{
          // setEvent(event)
          // }
          let obj={}
          if(collegesType.length!==0)
          {
             obj={
              collegeType:collegesType
             }
          }
          let value
            if(events!==""){
                value=`&status=${event}`
            }
            else{
              value=""
            }
            setStatusVal(event)
          if(!event ||event==='')
          {
            Api.get(`/hiring-college/getAllColleges?&job=${selectedObj ?selectedObj :"" }`)
            .params(obj)
            .send(resp=>{
              if(resp)
              {
                dispatch(collegeListActions.updateCollegeList(resp.college.items));
                dispatch(collegeListActions.updateInfo(resp.college.meta));
              }
              else
              {              
              }
            })
            return;
          }
         
          Api.get(`/hiring-college/getAllColleges?status=${event}&job=${selectedObj ?selectedObj :"" }${value}`)
          .params(obj)
          .send(resp=>{
            if(resp)
            {
              
              dispatch(collegeListActions.updateCollegeList(resp.college.items));
              dispatch(collegeListActions.updateInfo(resp?.college?.meta));
            }
            else
            {            
            }
          })
        }}
        >
          <Option value="">ALL</Option>        
          {collegeStatus?.map((item,index)=>{
            return <Option value={item} >{item}</Option>
          })}
        </Select>
        </div>
        </div>,
      //title : <div style={{width: "110px"}}>{"Status"} {customFilterPopover("Status", null, <DownOutlined />)} </div>,
      dataIndex: "status",
      key: "status",
      width:"17%",
      render: (text, record) => {      
        return (
         <div style={{marginRight:"0.5rem"}}>
          <Row>
            <div style={{gap:"3.8rem",display:"flex",flexDirection:"row"}}>
            <div>
              <Popover content={timelineCommonCode(record.college)}>
                <Button type="text" style={{marginRight:"1rem",width:"2rem"}}>
                  <p>
                    <img src={StatusIcon} />
                    &nbsp;{text}
                  </p>
                </Button>
              </Popover>
            </div>
            <div style={{ marginTop: "6px"}}>
              <Dropdown overlay={statusMenu(record.key)} trigger={['hover']} >
                <a onClick={(e) => e.preventDefault()}>
                  <DownOutlined />
                </a>
              </Dropdown>
            </div>
            </div>
          </Row>
         </div>
        )
      },
    },
  ];

  const data = () => {
    let list = []
    // collegeList.map((element, index) => list.push({
    //   indexed:index+1,
    //   key: element.id,
    //   ids:element.id,
    //   name:element.name,
    //   primaryContactName: element.primaryContactName,
    //   primaryEmail: element.primaryEmail,
    //   address: element.address,
    //   primaryPhone: element.primaryPhone,
    //   district : element.district,
    //   state : element.state,
    //   jobs : element.jobs,
    //   secondaryContactName: element.secondaryContactName,
    //   tertiaryContactName:element.tertiaryContactName,
    //   secondaryEmail : element.secondaryEmail,
    //   secondaryPhone : element.secondaryPhone,
    //   tertiaryEmail : element.tertiaryEmail,
    //   tertiaryPhone: element.tertiaryPhone,
    //   collegeStatusHistory: element.collegeStatusHistory,
    //   status:element.status
    // }))
    return collegeList.map((element, index) => ({
      ...element,
      key: element.id


    }))


  }


  const handleCancel = () => {
 
    setDeleteModal(false)
  }
  const openModal = () => {
   
    setDeleteModal(true)
  }
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const toggleCollegeDataImportModal = () => {
    setDataImportModal(!isDataImportModalVisible);
  };

  const DeleteApi = (values, type) => {
    setBtnLoader1(true);


    if (values.length === 0) {
      let text = "please select candidate";
      Notification.error(text,"")
      return;
    }

    const deleteValues = {
      ids: values,
    };
    Api.delete("/hiring-college/deleteCollege")
      .params(deleteValues)
      .send((response) => {
        setBtnLoader1(false);

        Notification.success(response.message)
        if (response.type === "success") {
          fetchColleges(pageChange, pageSize, "");
          setDeleteModal(false)
          setBtnVisible(false)
        }
      });
  };

  const toggleUpdateModal = (record) => {
  
    if (!showUpdateModal) {
      setUpdateObject(record);
    }
    setUpdateModal(!showUpdateModal);
  };
  const logoutHAndler = () => {
    if(user?.role?.includes("Agency")){
      window.localStorage.clear()
    }
    window.location.replace("https://newpmt.brigosha.com");
    // dispatch(authActions.removeUser());
    // window.location.replace('/')
  };

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
              1st menu item
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
              2nd menu item
            </a>
          ),
        },
        {
          key: '3',
          label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
              3rd menu item
            </a>
          ),
        },
      ]}
    />
  );

  const text = <span>Title</span>;
const content = (
  <div>logout</div>
);

  return (
    <div
      style={{
       padding:"1% 1.8%",
        backgroundColor: "##F8F8F8",
        height:"90vh",
        overflowY:"auto",
      }}
    >

     <Row>
      <Title style={{ fontSize: "1.5rem",fontWeight: "bold",}}>Institute Management</Title>
     </Row>
      <Row>
        <div style={{display:'flex',flexDirection:'row',flexWrap:"nowrap",gap:"2.3%",marginBottom:"0.5rem",height:"13rem",width:"100%"}} >
                <Card bordered={false} style={{ flex:"1 1 50%"}}>
                <div  style={{display:"flex",justifyContent:"center",alignItems:"left",flexDirection:"column",textAlign:"center",marginTop:"1rem"}}>
                  <div
                    style={{
                      fontSize: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems:"center",
                    }}
                  >
                    <b style={{fontSize: "40px",}}>{emailCount} </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "20px",
                    }}
                  >
                    <b>Email Sent</b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p>Last Updated:{present}</p>
                  </div>
                 </div>
                </Card>
                <Card style={{ flex: "1 1 80%", backgroundColor: "#E6F3FF",display:"flex",flexDirection:'row',gap:"6%",flexWrap:"nowrap"}}>
                {/* <Card style={{ width: "100%", backgroundColor: "#E6F3FF" }}> */}
                  <div  style={{display:"flex",flexDirection:'row',gap:"5%",flexWrap:"nowrap",}}>
                    <div>
                      <img src={iconCollege} style={{height:"10rem",width:"13.5rem"}} />
                    </div>
                    <div>
                      <h2>Add Institutions</h2>
                      <p>
                        Add Institutes by Import in group or by filling form
                        manually
                      </p>
                      <Row   style={{marginTop:"0.6rem"}}>
                        <Button
                          key="2"
                          type="primary"
                          onClick={toggleCollegeDataImportModal}
                        
                        >
                          IMPORT
                        </Button>
                        
                        <Button
                          key="1"
                          onClick={toggleModal}
                          style={{ borderColor: "#4DAAFF",marginLeft:"0.7rem", }}
                        >
                            <b style={{ color: "#4DAAFF" }}>FILL FORM</b>
                        </Button></Row>
                        <div >
                        {/* <a href={"https://brigosha-hackathon-test.s3.ap-south-1.amazonaws.com/file/collegeTemplate.xlsx"} target="_blank"> */}
                        <p
                          style={{
                            color: "#6BCB77",
                            marginLeft:"0.2rem",
                            marginTop:"1.2rem",
                            font: "normal normal bold 12px/22px Open Sans",
                            cursor:"pointer",
                          }} onClick={()=>{
                            Api.get("/hiring-college/collegeTemplate").send(resp=>{
                              if(resp)
                              {
                                window.open(resp, "_blank")?.focus();
                              }
                            })
                          }}
                        >
                          <img style={{ width: "6%" }} src={Excel} />
                          &nbsp;DOWNLOAD EXCEL TEMPLATE
                        </p>
                      {/* </a> */}
                      </div>
                      {/* <Button  
                        style={{fontWeight:"bold",border:"1.2px solid blue",color:"blue",marginTop:"0.5rem"}}                     
                      onClick={()=>{
                        Api.get("/hiring-college/collegeTemplate").send(resp=>{
                          if(resp)
                          {
                            window.open(resp, "_blank")?.focus();
                          }
                        })
                      }}>DOWNLOAD EXCEL TEMPLATE</Button> */}
                    </div>
                 </div>
              </Card>
        </div>
        </Row>
        <Row justify="start" style={{ marginTop: "5px", }}>
        <div style={{ display: 'flex', gap:"1rem",justifyContent:"space-between",width:"100%"}}>
      <div className={classes.searchDiv}>
                {/* <div>
                  <img src={searchIcon}></img>
                </div> */}
                 <Input   
                  type="text"
                  size="medium"
                  allowClear={true}
                  prefix={<SearchOutlined className="custom-search-icon" />}
                  placeholder='Search'
                  style={{color:"#161616",}}
                  onChange={(event)=>{
                    let value=event.target.value
                  setSearchStr(event.target.value)
                  
                  
                    setPageChange(1)
                  
                  fetchColleges("", "", value)
                  }}
                  >
                  </Input>
       </div>
       
          {btnVisible === true ? (
          <>
              <Button
                loading={emailLoader}
                // size="large"
                style={{
                  borderColor: "#6BCB77",
                  backgroundColor: "#6BCB77",
                  color: "white",
                  borderRadius: "5px",
                  marginLeft:"15rem"
                }}
                onClick={() => {
                  toggleTemplateModal(selectedRow);
                  
                }}
              // onClick={showModal}
              >
                Send Link
              </Button>
              <Button

                style={{
                  borderColor: "#FF9797",
                  color: "#FF9797",
                  borderRadius: "5px",
                  marginRight: "5rem",
                }}
                //  size="large"
                onClick={
                  openModal}
              //  loading={deleteAllCandidate}
              >
                <b style={{ font: "normal normal bold 16px/22px Open Sans" }}>
                  Delete
                </b>
              </Button>
             
              <Modal visible={deleteModal} footer={false} onCancel={handleCancel} centered width="40%">
               <div style={{padding:"2rem"}}>
                <Row justify="center">
                  <h2 style={{fontWeight:"bold"}}>Delete</h2></Row>
                <Row justify="center"><p style={{fontSize:17,marginTop:"0.5rem"}}> Are you sure to delete ?</p></Row>
                <Row justify="center" style={{marginTop:"2rem"}}><Space><Button style={{ borderColor: "#4DAAFF", color: "#4DAAFF",borderRadius:5,width:"7vw",marginRight:"0.3rem" }} onClick={() => { handleCancel() }}>Cancel</Button>
                  <Button loading={btnLoader1} style={{ backgroundColor: "#FF9797", borderColor: "#FF9797", color: "white",borderRadius:5,width:"7vw" }} onClick={() => {
          
                    DeleteApi(selectedRow, "multiple");
                  }}>Yes</Button></Space></Row>
                </div>
              </Modal>

          </>
        ) : (
          ""
        )}
        { btnVisible === true ? "" :
          <div className="head-icon">    
            <div style={{textAlign:"left"}}>
              <img src={DownloadIcon} alt="download" 
              style={{ cursor:"pointer",marginRight:"1rem" }} className="donwload-icon-css" 
              onClick={fetchDownloadLink} />
            </div> 
          </div>
        }
         </div> 
         </Row>   
        
      <Tabs>
        <TabPane>
      <div>
        <Table
          columns={columns}
          dataSource={data()}
          loading={tableLoader}
          rowSelection={rowSelection}
          style={{marginTop:"-1.9rem"}}         
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: [10,20, 30, 50, 100],
            pageSize: info.itemPerPAge,
            onChange: handlePageChange,
            onShowSizeChange: handlePageChange,
            total: info.totalItems,
            current: parseInt(info.currentPage),
          }}
          locale={{ emptyText: "No Colleges Found" }}
          scroll={{y:"100",x:350}}
        />
      </div>
     </TabPane >
    </Tabs>  
      <div>
        {isModalVisible && (
          <CollegeModal
            isOpen={isModalVisible}
            toggle={setIsModalVisible}
            tableLoader={tableLoader}
            setTableLoader={setBtnLoader}
            fetchColleges={fetchColleges.bind(this)}
            pageSize={pageSize}
            pageChange={pageChange}
            
          />
        )}
        {isDataImportModalVisible && (
          <ImportData
            isOpen={isDataImportModalVisible}
            toggle={toggleCollegeDataImportModal.bind(this)}
            fetchColleges={fetchColleges.bind(this)}
          />
        )}

        {showUpdateModal && (
          <CollegeModal
            isOpen={showUpdateModal}
            toggle={toggleUpdateModal.bind(this)}
            tableLoader={tableLoader}
            setTableLoader={setBtnLoader}
            collegeData={selectedUpdateObject}
            fetchColleges={fetchColleges.bind(this)}
            pageSize={pageSize}
            pageChange={pageChange}
          />
        )}


        {
          isTemplateModalVisible &&
          <TemplateModal
            isOpen={isTemplateModalVisible}
            toggle={toggleTemplateModal.bind(this)}
            selectedData={selectedUpdateObject}
            setSelectedRow={setSelectedRow}
            templates={templates}
            refresh={fetchColleges.bind(this)}
          />
        }
      </div>
    </div>
  );
}

export default Colleges;