import React, { useState, useEffect } from "react";
import classes from "./candidate.module.css";
import {
  Layout,
  Card,
  Button,
  Space,
  Row,
  Col,
  Table,
  Badge,
  Input,
  Select,
  Typography,
  Modal,
  Image,
  Radio,
  Checkbox,
  DatePicker,
  Divider,
  Popconfirm,
  Dropdown,
  Popover,
  List,
} from "antd";
import Schedule from "./schedule";
import icon from "../../assets/Group11630.svg";
import Excel from "../../assets/excel.png";
import { useHistory } from "react-router";
import "./style.css";
import { useWindowDimensions } from "../../components/windowDimensions";
import {
  SearchOutlined,
  DownOutlined,
  InfoCircleOutlined,
  DeleteFilled,
  CheckCircleTwoTone,
  DownloadOutlined,
  FilterOutlined,
  SettingOutlined,
  SwapOutlined,
  ConsoleSqlOutlined,
  CloseOutlined,
  UserOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
const { Title } = Typography;
import { useSelector, useDispatch } from "react-redux";
import { adminActions } from "./store";
import { FillForm, ImportData } from "pages";
import Api from "services/Api";
import { authActions } from "pages/login/store";
import Notification from "services/Notification";
import moment from "moment";
import sort from "../../assets/sort.png";
import settings from "../../assets/settings.png";
import download from "../../assets/download.png";
import filter from "../../assets/filter.png";
import SendEmailModal from "./sendEmailModal";
import { templateActions } from "pages/email-template/store";
import { collegeListActions } from "pages/college-dashboard/store";
import UserIcon from '../../assets/user-icon.svg';
import searchIcon from '../../assets/searchIcon.png';
import BinIcon from '../../assets/bin-icon.svg';
import { jdActions } from "pages/jd-dashboard/store";
import Constants from "./constants";
import Filter from "./filter.cls";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragAndDrop from "components/dragAndDrop";
import Sorter from "components/sorter";
import RegLinkModal from "./regLinkModal/regLinkModal";
import MainHeader from "components/mainHeader";
const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;
const constant = new Constants();
const filterObj = new Filter();

const Candidates = () => {
  const [visible, setVisible] = useState(false);
  const { columnFeature, allColumn } = useSelector(
    (state) => state.candidateFresher
  );
  const [itemList, setItemList] = useState("");
  let history = useHistory();
  let [selectedRow, setSelectedRow] = useState([]);
  const [busy, setBusy] = useState(null);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(false);
  const [buttLoader, setButtLoader] = useState(false);
  const [btn2Loader, setBtn2Loader] = useState(false);
  const dispatch = useDispatch();
  const [selectCol, setSelectCol] = useState("");
  let [isModalVisible, setIsModalVisible] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [excelDate, setExcelDate] = useState("");
  const present = moment().format("YYYY-MM-DD hh:mm a");
  const [searchValue, setSearchValue] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [scheduleRecord, setScheduleRecord] = useState("");
  const [deleteAllCandidate, setDeleteAllCandidate] = useState(false);
  const [isSendEmailMailVisible, setSendMailVisible] = useState(false);
  const [selectedRowData,setSelectedRowData] = useState([]);
const [saverequestBody,setRequestBody]=useState()
  const [sortFieldList, setSortOrderlist] = useState(constant.sortingFields);
  const [isCustomPopoverVisible, setCustomPopoverVisible] = useState({
    sortDetails: false,
    filterDetails: false,
  });
  const [filterState, setFilterState] = useState({ minx: 0, maxx: 0 });
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [eligibilityStatus, setEligibilityStatus] = useState([]);
  const [overAllStatus, setOverAllStatus] = useState([]);
  const [hackathonStatusList, setHackathonStatus] = useState([]);
  const [jobVacanciesList, setJobVacancies] = useState([]);
  const [isJobAll,setIsJobAll]=useState(false);
  const [isStatusAll,setIsStatusAll]=useState(false);
  const [isEligibilityAll,setIsEligibilityAll]=useState(false);
  const [isEligibilityAllApplied,setIsEligibilityAllApplied]=useState(false);
  const [selectedItem,setSelectedItem]=useState({eligibility:[],hackathon:[],status:[],job:[]})
  const [isHackathonAll,setIsHackathonAll]=useState(false);
  const [filterStatus, switchFilterStatus] = useState(constant.filterStatus);
  const [filterDetailsFlag, setFilterDetailsFlag] = useState(false);
  const [sortPopoverFlag, setSortPopoverFlag] = useState(false);
  const [sortingFlag, setSortingFlag] = useState(constant.sortStatus);
  const [sortType, setSortType] = useState(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [pageChange, setPageChange] = useState(1);
  const [pageSize, setPageSize] = useState("");
const [tableType,setTableType]=useState("");
const[deleteModal,setDeleteModal] =useState(false)
  const [selectedUpdateObject, setUpdateObject] = useState();
  const [regLinkModal, setRegLinkModal] = useState(false);
  let [testValues, setTestValues] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const { jdList } = useSelector((state) => state?.jd);
  // const{summery,setSummery}=useState({});
  const [resp, setResp] = useState("");
  const [XMinValue,setXMinValue]=useState('');
  const [XMaxValue,setXMaxValue]=useState('');
  const [XIIMinValue,setXIIMinValue]=useState('');
  const [XIIMaxValue,setXIIMaxValue]=useState('');
  const [CGPAMinValue,setCGPAMinValue]=useState('');
  const [CGPAMaxValue,setCGPAMaxValue]=useState('');
  const [scoreMinValue,setScoreMinValue]=useState('');
  const [scoreMaxValue,setScoreMaxValue]=useState('');
  const [backlogsMinValue,setBacklogsMinValue]=useState('');
  const [backlogsMaxValue,setBacklogsMaxValue]=useState('');
  const {width}=useWindowDimensions();
  const {user} = useSelector(state =>state.auth);
  const { candidates } = useSelector((state) => {
    return state.candidateFresher;
  });
  let candidatedashboard = [
    { name: "Total Job Profiles", value: resp?.totalJobProfiles },
    { name: "Total Institutes", value: resp?.totalInstitutes },
    { name: "Total Candidates", value: resp?.totalCandidates },
  ];

  useEffect(() => {
    if (columnFeature.length !== allColumn.length) {
      columnFeature?.forEach((item) => {
        setSelectCol((oldArray) => [...oldArray, item]);
      });
    }
  }, []);
  
  useEffect(() => {
    getfun();
  }, [pageChange, pageSize, searchValue, collegeName]);

  const showDate = () => {
    setShowDateModal(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    isModalVisible = false;
    setIsModalVisible(isModalVisible);
  };
  const handleOk2 = () => {
    setShowDateModal(false);
  };
  const DeleteApi = (values, type) => {
    if (values.length === 0) {
      let text = "please select candidate";
      Notification.error(text);
      setDeleteModal(false)
      return;
    }
    else{
    setLoader(true);
    const deleteValues = {
      candidateIds: values,
    };
    Api.post("/hiring-candidate/delete")
      .params(deleteValues)
      .send((response) => {
        const temp = [];
        if (typeof response != "undefined") {
          setLoader(false);
          if (
            response &&
            response.candidates &&
            response.candidates.items.length > 0
          ) {
            response.candidates.items.map((e) => {
              e["key"] = e.id;
              temp.push(e);
            });
          }}
        setSelectedRow(values);
        dispatch(adminActions.updateCandidate(temp));
        setDeleteAllCandidate(false);
        setDeleteModal(false)
        getfun();
      });
    }
  };

  const handleCancel2 = () => {
    setShowDateModal(false);
  };

  const { info } = useSelector((state) => {
    return state.candidateFresher;
  });

  const handlePageChange = (page, pageSize) => {
    setPageChange(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    setTableType("candidateFresher");
    setLoader(true);
    filterCandiate()
  }, [pageChange,pageSize]);
  useEffect(() => {
    setLoader(true);
    Api.post("/hiring-tests/getAllTests").send((response) => {
      if (typeof response != "undefined") {
        setLoader(false);
        dispatch(adminActions.updateTest({ test: response }));
      }
    });
    fetchTemplate();
  }, []);

  const fetchTemplate = () => {
    Api.get("/hiring-template/all").send((res) => {
      if (res && res.length > 0) {
        dispatch(templateActions.updateTemplates(res));
      }
    });
  };

  const filterCandiate = (internalfilterObj = null) => {
    let requestBody;
    let filterArr = [];
    let statusArr = ["x", "xii", "cgpa", "backlogs", "eligibility"];
   let value= statusArr?.map((element) => {
      switch (element) {
        case "x":
          if (filterObj.xStatus) {
            if(((filterObj.xmin>filterObj.xmax && filterObj.xmax !== "100") || filterObj.xmax==="" || filterObj.xmin==="") || (filterObj.xmin===0 &&filterObj.xmax===0))
            { Notification.error("error","kindly enter both values accordingly")
               filterObj.xStatus=false
              return 
            }
            filterArr.push({
              key: element,
              min: filterObj.xmin,
              max: filterObj.xmax,
            });
          }
          break;
        case "xii":
          if (filterObj.xiiStatus) {
            if(((filterObj.xiimin>filterObj.xiimax && filterObj.xiimax !== "100") || filterObj.xiimax==="" || filterObj.xiimin==="") || (filterObj.xiimin===0 &&filterObj.xiimax===0))
            { Notification.error("error","kindly enter both values accordingly")
              filterObj.xiiStatus=false
              return
            }
            filterArr.push({
              key: element,
              min: filterObj.xiimin,
              max: filterObj.xiimax,
            });
          }

          break;
        case "cgpa":
          if (filterObj.cgpaStatus) {
            if((filterObj.cgpamin>filterObj.cgpamax || filterObj.cgpamax==="" || filterObj.cgpamin==="") || (filterObj.cgpamax==="" && filterObj.cgpamin===""))
            { Notification.error("error","kindly enter both values accordingly")
               filterObj.cgpaStatus=false
              return
            }
            filterArr.push({
              key: element,
              min: filterObj.cgpamin,
              max: filterObj.cgpamax,
            });
          }

          break;
        case "backlogs":
          if (filterObj.backlogsStatus) {
            if((filterObj.backlogsmin>filterObj.backlogsmax || filterObj.backlogsmax==="" || filterObj.backlogsmin==="") || (filterObj.backlogsmax===0 && filterObj.backlogsmin===0))
            { Notification.error("error","kindly enter both values accordingly")
               filterObj.backlogsStatus=false
              return
            }
            filterArr.push({
              key: element,
              min: filterObj.backlogsmin,
              max: filterObj.backlogsmax,
            });
          }
          break;

        default:
          break;
      }
    });
 
    if (!filterArr ||filterArr.length===0 ) {
      requestBody = {
        search: searchValue,
        collegeName: collegeName,
        page: pageChange,
        limit:pageSize,
      };
    } else {
      requestBody = {
        search: searchValue,
        collegeName: collegeName,
        page: pageChange,
        limit:pageSize,
        filter: filterArr,
      };
      
    }
    let selectedField = sortFieldList.filter((a) => a.selected);
    if (selectedField && selectedField.length > 0) {
      requestBody[selectedField?.[0]?.sortKey] = sortType ? "ASC" : "DESC";
    }
    if (filterObj.eligibility) {
      let temp = [];
      eligibilityStatus.map((e) => {
        if (e.selected) {
          temp.push(e.title);
        }
      });
      if(temp.length!==0&&!temp.includes("All")){
        requestBody["eligibilityStatus"]=temp;
      }
      
    }

    if (filterObj.overAllStatus) {
      let temp = [];
      overAllStatus.map((e) => {
        if (e.selected) {
          temp.push(e.title);
        }
      });
      if(temp.length!==0&&!temp.includes("All")){
      requestBody["overallStatus"] =temp;
      }
    }

    if (filterObj.hackathonStatus) {
      let temp = [];
      hackathonStatusList.map((e) => {
        if (e.selected) {
          temp.push(e.title);
        }
      });
      if(temp.length!==0&&!temp.includes("All")){
        requestBody["hackathonStatus"] =isHackathonAll?[]:temp;
      }
     
    }

    if (filterObj.jobFilterStatus) {
      let temp = [];
      jobVacanciesList.map((e) => {
        if (e.selected) {
          temp.push(e.id);
        }
      });
    
      requestBody["jobVacancy"] = temp.includes('')?[]:temp;
    }
    setRequestBody(requestBody)
    getCandidateApiCall(requestBody,"filter")
  };

  // const sortCandidate = () => {
  //   let requestBody;
  //   requestBody = {
  //     search: searchValue,
  //     collegeName: collegeName,
  //     page: pageChange,
  //   };
  //   let selectedField = sortFieldList.filter((a) => a.selected);
  //   if (selectedField && selectedField.length > 0) {
  //     requestBody[selectedField[0].sortKey] = sortType ? "ASC" : "DESC";
  //   }
  //   getCandidateApiCall(requestBody);
  // };

  const getCandidateApiCall = (requestBody,filter) => {
    const finalValue = {
      ...requestBody,
      candidateType: "Fresher",
    };
    setLoader(true);
    Api.post(`/hiring-candidate/fresherCandidates`)
      .params(finalValue)
      .send((response) => {
        if (typeof response != "undefined") {
         if(filter==="filter"  && response.candidates.items.length ===0){
            setPageChange(1)
         }       
          setLoader(false);
          let temp = [];
          if (
            response &&
            response.candidates &&
            response.candidates.items.length > 0
          ) {
            response.candidates.items.map((e) => {
              e["key"] = e.id;
              temp.push(e);
            });
          }
          // let columnFeature={columns:response.columns,mappedColumns:response.mappedColumns}
          setItemList(response.columns);
          setResp(response);
          dispatch(adminActions.AllColumns(response.columns));
          // dispatch(adminActions.updateColumns(response.mappedColumns))
          dispatch(adminActions.updateCandidate(temp));
          dispatch(adminActions.updateInfo(response.candidates.meta));
        }
      });
  };

  const setFilterFilter = (type = null, valueType = null, value) => {
    if (valueType === "min") {
      switch (type) {
        case "x":
          filterObj.xmin = value;
          break;
        case "xii":
          filterObj.xiimin = value;
          break;
        case "cgpa":
          filterObj.cgpamin = value;
          break;
        case "backlogs":
          filterObj.backlogsmin = value;
          break;
        default:
          break;
      }
    } else if (valueType === "max") {
      switch (type) {
        case "x":
          filterObj.xmax = value;
          break;
        case "xii":
          filterObj.xiimax = value;
          break;
        case "cgpa":
          filterObj.cgpamax = value;
          break;
        case "backlogs":
          filterObj.backlogsmax = value;
          break;
        default:
          break;
      }
    }
  };

  const resetFilter = () => {
    filterObj.resetFitler();
    filterCandiate();
    let jobList = [];
    // jobVacanciesList.filter()
    forceUpdate();
  };

  const getfilterRequest = (key) => {
    switch (key) {
      case "x":
        filterObj.xStatus = true;
        break;
      case "xii":
        filterObj.xiiStatus = true;
        break;
      case "cgpa":
        filterObj.cgpaStatus = true;
        break;
      case "backlogs":
        filterObj.backlogsStatus = true;
        break;

      case "eligibility":
        filterObj.eligibility = true;
        break;

      case "mainStatus":
        filterObj.overAllStatus = true;
        break;

      case "hackathonStatus":
        filterObj.hackathonStatus = true;
        break;

      case "jobtitle":
        filterObj.jobFilterStatus = true;
        break;
      case "":
        filterObj.hackathonScoreStatus = true;
        break;
      default:
        break;
    }
  };

  const customFilterPopover = (type = null, data = null, logo) => {
    let contentBody;
    switch (type) {
      case "jobtitle":
        contentBody = (
          <div className={classes.filter}>
            {/* <Checkbox.Group>
              <div>
                <span>ALL</span>
                <Checkbox style={{ float: "right" }}></Checkbox>
              </div>
              {jobVacanciesList?.map((element) => {
                return element ? (
                  <div>
                    <span>{element.title}</span>
                    <Checkbox
                      value={element}
                      style={{ float: "right" }}
                      onChange={(e) => {
                        let temp = jobVacanciesList;

                        let index = jobVacanciesList.findIndex(
                          (a) => a.title == element.title
                        );
                        if (index != -1) {
                          temp[index].selected = !temp[index].selected;
                        }
                        setJobVacancies(temp);
                      }}
                    ></Checkbox>
                  </div>
                ) : (
                  ""
                );
              })}
            </Checkbox.Group> */}

            <div>
                          {/* <div>
                                      <span>ALL</span>
                                      <input style={{ float: "right" }}  type="checkbox" checked={isJobAll} onChange={(e)=>{
                                        setIsJobAll(e.target.checked);
                                      }}/>
                          </div> */}
                          {jobVacanciesList?.map((element,itemIndex) => {
                            return element ? (
                              <div>
                                            {element.title === "All" ? "" 
                                            :
                                            <Badge
                                              status={element.status === "Active" ? "success" : "error"}
                                            />}{" "}
                                            <span style={{marginLeft:element.title === "All" ? "0.65rem":""}}> {element.title}</span>
                                            <input
                                              type="checkbox"
                                              checked={element.selected}
                                              value={element}
                                              style={{ float: "right" }}
                                              onChange={(e) => {                                            
                                              let temp = jobVacanciesList;
                                              let index = jobVacanciesList.findIndex(
                                              (a) => a.title == element.title
                                                );
                                                if (index != -1) {
                                                       temp[index].selected = !temp[index].selected;
                                                }
                                                setJobVacancies(temp);
                                                 forceUpdate();
                                              }}
                                              />
                              </div>
                            ) : (
                              ""
                            );
                          })}
              </div>

            <div className="sort-action">
              <Button
                className="cancel"
                onClick={() => {
                  let tempStatus = filterStatus;
                  tempStatus.jobtitle = false;
                  switchFilterStatus(tempStatus);
                  forceUpdate();
                }}
              >
                CANCEL
              </Button>
              <Button
                className="float-right ok"
                onClick={() => {
                  getfilterRequest(type);
                  filterCandiate();
                  setSelectedItem(prev=>({...prev,job:jobVacanciesList.filter(a=>a.selected===true)}));
                }}
              >
                OK
              </Button>
            </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={filterStatus.jobtitle}
           onVisibleChange={() => {
            let tempStatus = filterStatus;
            if(tempStatus.jobtitle === true){
              tempStatus.jobtitle = false;
            }
            else{
            tempStatus.jobtitle = true;
            }
            switchFilterStatus(tempStatus);
            forceUpdate();
           }}
          >
            {logo}
          </Popover>
        );

        break;

        break;
      case "eligibility":
        contentBody = (
          <div className={classes.filter}>
                    {/* <Checkbox.Group>
                      <div>
                        <span>ALL</span>
                        <Checkbox style={{ float: "right" }}></Checkbox>
                      </div>
                      {eligibilityStatus?.map((element) => {
                        return element ? (
                          <div>
                            <span>{element.title}</span>
                            <Checkbox
                              value={element}
                              style={{ float: "right" }}
                              onChange={(e) => {
                                let temp = eligibilityStatus;
                                let index = eligibilityStatus.findIndex(
                                  (a) => a.title == element.title
                                );
                                if (index != -1) {
                                  temp[index].selected = !temp[index].selected;
                                }
                                setEligibilityStatus(temp);
                              }}
                            ></Checkbox>
                          </div>
                        ) : (
                          ""
                        );
                      })}
                    </Checkbox.Group> */}
                    <div>                          
                              {eligibilityStatus?.map((element) => {
                                return element ? (
                                  <div>
                                    <span>{element.title}</span>
                                    <input
                                      type="checkbox"
                                      checked={element.selected}
                                      value={element}
                                      style={{ float: "right" }}
                                      onChange={(e) => {

                                        let temp = eligibilityStatus;
                                        let index = eligibilityStatus.findIndex(
                                          (a) => a.title == element.title
                                        );
                                        if (index != -1) {
                                          temp[index].selected = !temp[index].selected;
                                        }
                                        setEligibilityStatus(temp);
                                        forceUpdate();
                                      }}
                                    />
                                  </div>
                                ) : (
                                  ""
                                );
                              })}
                    </div>
                    <div className="sort-action">
                      <Button
                        className="cancel"
                        onClick={() =>
                          setCustomPopoverVisible({
                            ...isCustomPopoverVisible,
                            sortDetails: true,
                          })
                        }
                      >
                        CANCEL
                      </Button>
                      <Button
                        className="float-right ok"
                        onClick={() => {
                          // if(isEligibilityAll)
                          // {
                          //   setIsEligibilityAllApplied(true);
                          // }                        
                          getfilterRequest(type);
                          filterCandiate();
                          setSelectedItem(prev=>({...prev,eligibility:eligibilityStatus.filter(a=>a.selected===true)}))
                        }}
                      >
                        OK
                      </Button>
                    </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={isCustomPopoverVisible.sortingFields}
            onVisibleChange={() => toggleSortingPopover(null)}
          >
            {logo}
          </Popover>
        );
        break;
      case "hackathonStatus":
        contentBody = (
          <div className={classes.filter}>
                    {/* <Checkbox.Group>
                      <div>
                        <span>ALL</span>
                        <Checkbox value="" style={{ float: "right" }}></Checkbox>
                      </div>
                      {hackathonStatusList?.map((element) => {
                        return element ? (
                          <div>
                            <span>{element.title}</span>
                            <Checkbox
                              value={element}
                              style={{ float: "right" }}
                              onChange={(e) => {
                                let temp = hackathonStatusList;
                                let index = hackathonStatusList.findIndex(
                                  (a) => a.title == element.title
                                );
                                if (index != -1) {
                                  temp[index].selected = !temp[index].selected;
                                }
                                setHackathonStatus(temp);
                              }}
                            ></Checkbox>
                          </div>
                        ) : (
                          ""
                        );
                      })}
                    </Checkbox.Group> */}
                    <div>
              {hackathonStatusList?.map((element,itemIndex) => {
                return element ? (
                  <div>
                    <span>{element.title}</span>
                    <input
                      type="checkbox"
                      checked={element.selected}
                      value={element}
                      style={{ float: "right" }}
                      onChange={(e) => {
                        let temp = hackathonStatusList;
                        let index = hackathonStatusList.findIndex(
                          (a) => a.title == element.title
                        );
                        if (index != -1) {
                          temp[index].selected = !temp[index].selected;
                        }
                        setHackathonStatus(temp);
                        forceUpdate();
                      }}
                    />
                  </div>
                ) : (
                  ""
                );
              })}
            </div>

                    <div className="sort-action">
                      <Button
                        className="cancel"
                        onClick={() =>
                          setCustomPopoverVisible({
                            ...isCustomPopoverVisible,
                            sortDetails: true,
                          })
                        }
                      >
                        CANCEL
                      </Button>
                      <Button
                        className="float-right ok"
                        onClick={() => {
                          getfilterRequest(type);
                          filterCandiate();
                          setSelectedItem(prev=>({...prev,hackathon:hackathonStatusList.filter(a=>a.selected===true)}))                   
                        }}
                      >
                        OK
                      </Button>
                    </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={isCustomPopoverVisible.sortingFields}
            onVisibleChange={() => toggleSortingPopover(null)}
          >
            {logo}
          </Popover>
        );
        break;

      case "mainStatus":
        contentBody = (
          <div className={classes.filter}>
                  {/* <Checkbox.Group>
                    <div>
                      <span>ALL</span>
                      <Checkbox style={{ float: "right" }}></Checkbox>
                    </div>
                    {overAllStatus?.map((element) => {
                      return element ? (
                        <div>
                          <span>{element.title}</span>
                          <Checkbox
                            value={element}
                            style={{ float: "right" }}
                            onChange={(e) => {
                              let temp = overAllStatus;
                              let index = overAllStatus.findIndex(
                                (a) => a.title == element.title
                              );
                              if (index != -1) {
                                temp[index].selected = !temp[index].selected;
                              }
                             
                              setOverAllStatus(temp);
                            }}
                          ></Checkbox>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </Checkbox.Group> */}
                  <div>                   
                    {overAllStatus?.map((element,itemIndex) => {
                      return element ? (
                        <div>
                          <span>{element.title}</span>
                          <input
                           type="checkbox"
                           checked={element.selected}
                            value={element}
                            style={{ float: "right" }}
                            onChange={(e) => {                           
                              let temp = overAllStatus;
                              let index = overAllStatus.findIndex(
                                (a) => a.title == element.title
                              );
                              if (index != -1) {
                                temp[index].selected = !temp[index].selected;
                              }                          
                              setOverAllStatus(temp);
                              forceUpdate();
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                  <div className="sort-action">
                    <Button
                      className="cancel"
                      onClick={() =>
                        setCustomPopoverVisible({
                          ...isCustomPopoverVisible,
                          sortDetails: true,
                        })
                      }
                    >
                      CANCEL
                    </Button>
                    <Button
                      className="float-right ok"
                      onClick={() => {
                        getfilterRequest(type);
                        filterCandiate();
                        // setSelectedItem(overAllStatus.filter(a=>a.selected===true))
                        setSelectedItem(prev=>({...prev,status:overAllStatus.filter(a=>a.selected===true)}))  
                      }}
                    >
                      OK
                    </Button>
                  </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={isCustomPopoverVisible.sortingFields}
            onVisibleChange={() => toggleSortingPopover(null)}
          >
            {logo}
          </Popover>
        );
        break;

      default:
        if (
          type === "x" ||
          type == "xii" ||
          type == "cgpa" ||
          type == "backlogs" ||
          type == "score"
        ) {
          contentBody = (
            <div className={classes.filter}>
              <div>
                <span>min</span>
                <Input
                  type={"number"}
                  min={0}
                  value={type==='x'?XMinValue:type==='xii'?XIIMinValue:type==='cgpa'?CGPAMinValue:type==="score"?scoreMaxValue:backlogsMinValue}
                  onChange={(e) =>{
                    if(type==='x')
                    {
                      setXMinValue(e.target.value);
                    }   
                    if(type==='xii')
                    {
                      setXIIMinValue(e.target.value);
                    }
                    if(type==='cgpa')
                    {
                      setCGPAMinValue(e.target.value);
                    } 
                    if(type==='backlogs')
                    {
                      setBacklogsMinValue(e.target.value);
                    }  
                    if(type==='score')
                    {
                      setScoreMinValue(e.target.value);
                    }                                     
                    setFilterFilter(type, "min", e.target.value);
                  }}
                />
                <span>max</span>
                <Input
                  type={"number"}
                  min={0}
                  value={type==='x'?XMaxValue:type==='xii'?XIIMaxValue:type==='cgpa'?CGPAMaxValue:type==="score"?scoreMaxValue:backlogsMaxValue}
                  onChange={(e) => {
                    if(type==='x')
                    {
                      setXMaxValue(e.target.value);
                    }   
                    if(type==='xii')
                    {
                      setXIIMaxValue(e.target.value);
                    } 
                    if(type==='cgpa')
                    {
                      setCGPAMaxValue(e.target.value);
                    } 
                    if(type==='cgpa')
                    {
                      setCGPAMaxValue(e.target.value);
                    }     
                    if(type==='backlogs')
                    {
                      setBacklogsMaxValue(e.target.value);
                    }  
                    if(type==='score')
                    {
                      setScoreMaxValue(e.target.value);
                    }                     
                    setFilterFilter(type, "max", e.target.value);
                  }}
                />
              </div>
              <div className="sort-action">
                <Button
                  className="cancel"
                  onClick={() => {
                    let temp = filterStatus;
                    temp[type] = !temp[type];                  
                    switchFilterStatus(temp);
                    forceUpdate();
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  className="float-right ok"
                  onClick={() => {
                    getfilterRequest(type);
                    filterCandiate();
                    forceUpdate();
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          );

          return (
            <Popover
              placement="bottom"
              content={contentBody}
              trigger="hover"
              visible={filterStatus[type]}
              onVisibleChange={() => {
                column;
                // settemp(true)
                let temp = filterStatus;
                // temp["x"]= !temp["x"];
                temp[type] = !temp[type];
                switchFilterStatus(temp);
                forceUpdate();
              }}
            >
              {logo}
            </Popover>
          );
        }
        break;
    }
  };

  let column = [];
  column = columnFeature?.map((item) => {
    let render = "";
    let header;
    switch (item.key) {
      case "name":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          return (
            <div
              
              type="text"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div>
                <Image
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    borderRadius: "50%",
                  }}
                  src={record.photo ? record.photo : UserIcon}
                />
              </div>{" "}
              <div onClick={() => {
                history.push({
                  pathname: "/profiles",
                  state: { data: record },
                });
              }} style={{ fontSize: "1rem", fontWeight: "600" }}>{text}</div>
            </div>
          );
        };
        break;

      case "email":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{text}</span>
              <span>{record.phone}</span>
            </div>
          );
        };
        break;
      case "score":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          let cellData = record?.result[0]?.score;
          return (
            <>{cellData >= 0 ? <p>{record?.result[0]?.score}</p> : <p>NA</p>}</>
          );
        };
        break;
      case "hiringStatus":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          return (
            <>
              <p>{text ? text : "NA"}</p>
              <p>Round:{record?.currentRound}</p>
            </>
          );
        };
        break;
      case "interviewround1":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          return (
            <>
              {record?.interviewSlots?.[0]?.interviewStatus && (
                <p>{record?.interviewSlots?.[0]?.interviewStatus}</p>
              )}
              {!record?.interviewSlots?.[0]?.interviewStatus && <p>NA</p>}
            </>
          );
        };
        break;

      case "interviewround2":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          return (
            <>
              {record?.interviewSlots?.[1]?.interviewStatus && (
                <p>{record?.interviewSlots?.[1]?.interviewStatus}</p>
              )}
              {!record?.interviewSlots?.[1]?.interviewStatus && <p>NA</p>}
            </>
          );
        };
        break;

      case "interviewround3":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          return (
            <>
              {record?.interviewSlots?.[2]?.interviewStatus && (
                <p>{record?.interviewSlots?.[2]?.interviewStatus}</p>
              )}
              {!record?.interviewSlots?.[2]?.interviewStatus && <p>NA</p>}
            </>
          );
        };
        break;

      case "interviewround4":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          return (
            <>
              {record?.interviewSlots?.[3]?.interviewStatus && (
                <p>{record?.interviewSlots?.[3]?.interviewStatus}</p>
              )}
              {!record?.interviewSlots?.[3]?.interviewStatus && <p>NA</p>}
            </>
          );
        };
        break;
      case "interviewround5":
        header = <div style={{ fontWeight: "700" }}>{item.title} </div>;
        render = (text, record) => {
          return (
            <>
              {record?.interviewSlots?.[4]?.interviewStatus && (
                <p>{record?.interviewSlots?.[4]?.interviewStatus}</p>
              )}
              {!record?.interviewSlots?.[4]?.interviewStatus && <p>NA</p>}
            </>
          );
        };
        break;

      case "jobtitle":
        header = (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              minWidth: "110px",
              fontWeight: "700",
            }}
          >
            {item.title}{" "}
            {customFilterPopover("jobtitle", null, <DownOutlined />)}{" "}
          </div>
        );
        render = (_, record) => <p>{record.job.title}</p>;
        break;

      case "x":
        header = (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              minWidth: "200px",
              fontSize: "1rem",
            }}
          >
            {item.title} {customFilterPopover("x", null, <DownOutlined />)}{" "}
          </div>
        );
        break;

      case "xii":
        header = (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              minWidth: "200px",
              fontSize: "1rem",
            }}
          >
            {"12TH MARKS"} {customFilterPopover("xii", null, <DownOutlined />)}{" "}
          </div>
        );
        break;

      case "cgpa":
        header = (
          <div style={{ minWidth: "15rem" }}>
            {item.title} {customFilterPopover("cgpa", null, <DownOutlined />)}{" "}
          </div>
        );
        break;

      case "backlogs":
        header = (
          <div>
            {item.title}{" "}
            {customFilterPopover("backlogs", null, <DownOutlined />)}{" "}
          </div>
        );
        break;

      case "masterCgpa":
        header = <div>{item.title} </div>;
        render = (text, record) => {
          return <>{text === null ? <p>NA</p> : <p>{text}</p>}</>;
        };
        break;

      case "masterDepartment":
        header = <div>{item.title} </div>;
        render = (text, record) => {
          return <>{text === null ? <p>NA</p> : <p>{text}</p>}</>;
        };
        break;

      case "eligibility":
        header = (
          <div>
            {item.title}{" "}
            {customFilterPopover("eligibility", null, <DownOutlined />)}{" "}
          </div>
        );
        render = (text, record) => {
          return (
            <>
              {text && <p>{text}</p>}
              {!text && <p>NA</p>}
            </>
          );
        };
        break;

      case "hackathonStatus":
        header = (
          <div style={{ fontSize: "1rem", fontWeight: "700" }}>
            {item.title}{" "}
            {customFilterPopover("hackathonStatus", null, <DownOutlined />)}{" "}
          </div>
        );
        break;

      case "mainStatus":
        header = (
          <div style={{ fontSize: "1rem", fontWeight: "700" }}>
            {item.title}{" "}
            {customFilterPopover("mainStatus", null, <DownOutlined />)}{" "}
          </div>
        );
        break;
      case "actions":
        header = (
          <p style={{ fontSize: "1rem", fontWeight: "700" }}>{item.title}</p>
        );
        render = (text, record) => (
          <Space>
            <Button
              style={{ backgroundColor: "#6BCB77", color: "white" }}
              onClick={() => toggleSendMailModal(record)}
            >
              Send mail
            </Button>
            <Button
              disabled={record?.hiringStatus === "Hold"}
              onClick={() => {
                setScheduleModal(true);
                setScheduleRecord(record);
              }}
            >
              Schedule
            </Button>
          </Space>
        );
        break;
      default:
        header = <div>{item.title}</div>;
        break;
    }

    if (render) {
      return {
        title: <span style={{ fontWeight: "700" }}>{header}</span>,
        key: item.key,
        dataIndex: item.key,
        render: render,
        width: "15rem",
      };
    } else {
      return {
        title: <span style={{ fontWeight: "700" }}>{header}</span>,
        key: item.key,
        dataIndex: item.key,
        width: "15rem",
      };
    }
  });

  let data = candidates;

  const getfun = () => {
    setLoader(true);
    let requestBody = {
      candidateType: "Fresher",
      search: searchValue,
      collegeName: collegeName,
      page: pageChange,
      limit: pageSize,
      candidateType: "Fresher",
    };
    Api.post(`/hiring-candidate/fresherCandidates`)
      .params(requestBody)
      .send((response) => {
        if (typeof response != "undefined") {
          setLoader(false);
          let temp = [];
          if (
            response &&
            response.candidates &&
            response.candidates.items.length > 0
          ) {
            response.candidates.items.map((e) => {
              e["key"] = e.id;
              temp.push(e);
            });
          }
          setItemList(response.columns);
          filterObj.allStatusList = response.statuses;
          let eligibilityList = [];
          response.statuses.EligibilityStatus.map((e, index) => {
            eligibilityList.push({
              title: e,
              selected: false,
            });
          });

          let tempEligibilityList = [];
          response.statuses.MainStatus.map((e) => {
            tempEligibilityList.push({
              title: e,
              selected: false,
            });
          });

          let tempJobVacanciesArr = [];
          response.jobVacancies.map((e) => {
            tempJobVacanciesArr.push({
              ...e,
              selected: false,
            });
          });
          setJobVacancies([{id:"",title:"All",selected:false}].concat(tempJobVacanciesArr));
          setHackathonStatus(
            [{title:"All",selected:false}].concat(formatFilterStatus(response.statuses.HackathonStatus))
          );
          setOverAllStatus([{title:"All",selected:false}].concat(tempEligibilityList));
          setEligibilityStatus([{id:"",title:"All",selected:false}].concat(eligibilityList));
          filterObj.setEligibilityStatus(eligibilityList);
          dispatch(adminActions.AllColumns(response.columns));
          setItemList(response.columns);
          dispatch(adminActions.AllColumns(response.columns));
          setResp(response);
          if (columnFeature.length === 0) {
            dispatch(adminActions.updateColumns(response.columns));
          }
          dispatch(adminActions.updateCandidate(temp));
          dispatch(adminActions.updateInfo(response.candidates.meta));
          dispatch(adminActions.updateStatuses(response.statuses));
        }
      });
  };

  const formatFilterStatus = (list) => {
    let temp = [];
    list?.map((e) => {
      temp?.push({
        title: e,
        selected: false,
      });
    });
    return temp;
  };
  const onCon = (selectedRow) => {
    setButtLoader(true);
    if (selectedRow.length < 1) {
      Notification.error("Error", "No candidate selected");
      setButtLoader(false);
      return;
    }
    if (testValues.length < 1) {
      Notification.error("Error", "No test selected");
      setButtLoader(false);
      return;
    }

    const value1 = {
      candidateIds: selectedRow,
      testIds: testValues,
    };
    Api.post("/hiring-candidate/sendTestLink")
      .params(value1)
      .send((response) => {
        setButtLoader(false);
        setLoader(true);
        setSelectedRow([])
        if (
          typeof response != "undefined" &&
          response.show.type === "success"
        ) {
          setIsModalVisible(false);
          setSelectedRow([]);
          getfun();
        }
        setSelectedRow([]);
        getfun();
      });
     
  };
  
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {
    },
    onSelectAll: (selected, selectedRows, changeRows) => {},
    getCheckboxProps: (record) => ({}),
  };

  const onExport = () => {
    setBtn2Loader(true);
    if (!excelDate) {
      let title = "please select date";
      setBtn2Loader(false);
      Notification.error(title);
      return;
    }
    Api.get(`/hiring-tests/generateExcel?date=${excelDate}`)
      .params()
      .send((response) => {
        if (typeof response != "undefined") {
          window.open(response, "_blank")?.focus();
          setShowDateModal(false);
        }
        setBtn2Loader(false);
      });
  };

  function onExcelDateChange(date, dateString) {
    setExcelDate(dateString);
  }
  useEffect(() => {
    let timeId = null;

    getfun();
  }, []);
  const onNameChange = (e) => {
    let values = e.target.value;
    setSearchValue(values);
    setPageChange("");
    if (!busy) {
      setBusy(true);
      setTimeout(() => {
        setBusy(false);
      }, 1500);
    }
  };

  const onInstituteChange = (e) => {
    let values = e.target.value;
    setCollegeName(values);
    setPageChange("");
    if (!busy) {
      setBusy(true);
      setTimeout(() => {
        setBusy(false);
      }, 1500);
    }
  };

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  const toggleSendMailModal = (record = null) => {
    if (!isSendEmailMailVisible) {
      if (record) {
        setUpdateObject([record.id]);
        const filteredCandidates = data.filter(candidate => {
          return record.id === candidate.id;
        });     
        setSelectedRowData(filteredCandidates);
      } else {
        setUpdateObject(selectedRow);
        const filteredCandidates = data.filter(candidate => {
          return selectedRow.includes(candidate.id);
        });     
        setSelectedRowData(filteredCandidates);
      }
    }
    setSendMailVisible(!isSendEmailMailVisible);
  };

  useEffect(() => {
    fetchColleges();
    jdQuery();
  }, []);

  const jdQuery = () => {
    Api.post("/hiring-job/AllJobs")
      .params({ status: "Active", jobExperience: "Fresher" })
      .send((res) => {
        let list = [];
        res?.jobs?.map((item) => {
          item["key"] = item.id;
          list.push(item);
        });
        dispatch(jdActions.updateJdList({ items: list }));
      });
  };

  const fetchColleges = () => {
    Api.get(`/hiring-college/getAllColleges`).send((response) => {
      if (typeof response != "undefined") {
        let temp = [];
        response.college.items.map((item) => {
          temp.push(item.college);
        });
        // setInstitute(temp);
      }
    });
  };

  const activeFilterList = () => {
    let list = [];
    constant.filterFields.map((element) => {
      switch (element.value) {
        case "x":
          if (filterObj.xStatus) {
            list.push({
              ...element,
              discription: filterObj.xmin + "-" + filterObj.xmax,
              min: filterObj.xmin,
              max: filterObj.xmax,
            });
          }
          break;
        case "xii":
          if (filterObj.xiiStatus) {
            list.push({
              ...element,
              discription: filterObj.xiimin + "-" + filterObj.xiimax,
              min: filterObj.xiimin,
              max: filterObj.xiimax,
            });
          }
          break;

        case "score":
          if (filterObj.hackathonScoreStatus) {
            list.push({
              ...element,
              discription: filterObj.scoremin + "-" + filterObj.scoremax,
              min: filterObj.scoremin,
              max: filterObj.scoremax,
            });
          }
          break;

        case "cgpa":
          if (filterObj.cgpaStatus) {
            list.push({
              ...element,
              discription: filterObj.cgpamin + "-" + filterObj.cgpamax,
              min: filterObj.cgpamin,
              max: filterObj.cgpamax,
            });
          }
          break;

        case "backlogs":
          if (filterObj.backlogsStatus) {
            list.push({
              ...element,
              discription: filterObj.backlogsmin + "-" + filterObj.backlogsmax,
              min: filterObj.backlogsmin,
              max: filterObj.backlogsmax,
            });
          }
          break;

        case "institute":
          break;
        case "jobtitle":
          if (filterObj.jobFilterStatus&&selectedItem.job.length>0) {
            let description = "";
            selectedItem.job
              .filter((a) => a.selected===true||a.selected===false)
              .map((item,index) => {
                if(index< selectedItem.job.filter((a) =>a.selected===true||a.selected===false).length-1)
                {
                  description += item.title +", ";
                }
                else
                {
                  description += item.title;
                }              
              });
            list.push({
              ...element,
              discription:description.includes("All")?"All":description,
            });
          }
          break;
          case "hackathonStatus":
            // let index3 = hackathonStatusList.findIndex(
            //   (a) => a.selected ===true
            // );
          if(filterObj.hackathonStatus&&selectedItem.hackathon.length>0){
            let description=""
            selectedItem.hackathon.filter((a) => a.selected===true||a.selected===false)
            .map((item,index) =>{
              if(index<selectedItem.hackathon.filter((a) =>  a.selected===true||a.selected===false).length-1)
              {
                description += item.title +", ";
              }
              else
              {
                description += item.title;
              }   
            })
           list.push({
            ...element,
            discription:description.includes("All")?"All":description,
          });
          }
          break;
          case "eligibilty":
            let index4 = eligibilityStatus.findIndex((a) =>a.selected===true);
            if(filterObj.eligibility&&selectedItem.eligibility.length>0){
              let description=""
              selectedItem.eligibility.filter((a) =>a.selected===true||a.selected===false)
              .map((item,index) =>{
                if(index<selectedItem.eligibility.filter((a) => a.selected===true||a.selected===false).length-1)
                {
                  description += item.title +", ";
                }
                else
                {
                  description += item.title;
                }   
              })
              
             list.push({
              ...element,
              discription:description.includes("All")?"All":description,
            });
            }
            break;
            case "overallStatus":
              let index2 = overAllStatus.findIndex((a) => a.selected ===true);
              if(filterObj.overAllStatus&&selectedItem.status.length>0){
                let description=""
                selectedItem.status.filter((a) =>a.selected===true||a.selected===false)
                .map((item,index) =>{
                  if(index< selectedItem.status.filter((a) =>a.selected===true||a.selected===false).length-1)
                  {
                    description += item.title +", ";
                  }
                  else
                  {
                    description += item.title;
                  }   
                })
               list.push({
                ...element,
                discription:description.includes("All")?"All":description ,
              });
              }
              break;
        default:
          break;
      }
    });
    return list;
  };

  const customPopover = (type = null, data = null, logo) => {
    let contentBody;
    let handler;
    switch (type) {
      case "sort":
        contentBody = (
          <div className="sort-popover">
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
              <span className="sort-heading-css">Sort By</span>{" "}
              <span className="float-right">Reset</span>
            </div>
            <div className="sorting-radio-container">
              <span className="sort-heading-css" style={{ fontSize: "19px" }}>
                Sort Order
              </span>
            </div>

            <div>
              <div className="sorting-radio-container">
                <Title
                  style={{
                    fontSize: "15px",
                    display: "inline",
                    color: "#1e90ff",
                  }}
                >
                  ASCENDING
                </Title>
                <Radio
                  value={true}
                  style={{ float: "right" }}
                  checked={sortType}
                  onClick={() => {
                    setSortType(true);
                  }}
                ></Radio>
              </div>
              <div className="sorting-radio-container">
                <Title style={{ fontSize: "15px", display: "inline" }}>
                  DESCENDING
                </Title>
                <Radio
                  value={false}
                  style={{ float: "right" }}
                  checked={!sortType}
                  onClick={() => {
                    setSortType(false);
                  }}
                ></Radio>
              </div>

              <div className="sorting-radio-container">
                <span className="sort-heading-css" style={{ fontSize: "19px" }}>
                  Sort By
                </span>
              </div>
            </div>

            <ul className="sorting-list-css">
              {sortFieldList?.map((item, index) => (
                <li
                  key={index}
                  className={item.selected ? "select-sorting-menu" : ""}
                  onClick={() => {
                    let temp = sortFieldList.slice();
                    temp.map((e, i) => {
                      if (index == i) {
                        e.selected = true;
                      } else {
                        e.selected = false;
                      }
                    });
                    setSortOrderlist(temp);
                  }}
                >
                  {item.label}{" "}
                  <span className="float-right">
                    {" "}
                    <span className={!item.selected ? "check-icon" : ""}>
                      <CheckCircleTwoTone />
                    </span>
                  </span>
                </li>
              ))}
            </ul>

            <div className="sort-action">
              <Button
                className="cancel"
                onClick={() => setSortPopoverFlag(false)}
              >
                CANCEL
              </Button>
              <Button
                className="float-right ok"
                onClick={() => filterCandiate()}
              >
                OK
              </Button>
            </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={sortPopoverFlag}
            onVisibleChange={() => {
              if (sortPopoverFlag) {
                setSortPopoverFlag(false);
              } else {
                setSortPopoverFlag(true);
              }
            }}
          >
            {logo}
          </Popover>
        );

        break;
      case "filter-details":
        contentBody = (
          <div className="sort-popover">
            <div>
              {" "}
              <span className="sort-heading-css">ALL Filter(s)</span>
              <Button className="float-right" 
              onClick={()=>{
                setSelectedItem({eligibility:[],hackathon:[],status:[],job:[]});
                resetFilter();
                setXMinValue('');
                setXMaxValue('');
                setXIIMinValue('');
                setXIIMaxValue('');
                setCGPAMinValue('');
                setCGPAMaxValue('');
                setScoreMinValue("");
                setScoreMaxValue("");
                setBacklogsMinValue("");
                setBacklogsMaxValue("");
                setIsJobAll(false);
                setIsStatusAll(false);
                setIsEligibilityAll(false);
                setIsHackathonAll(false);
                setJobVacancies(jobVacanciesList.map((item,index)=>{
                  return(
                    {...item, selected:false}
                  )
                  }
                  ))
                  setOverAllStatus(overAllStatus.map(item=>{
                    return(
                      {...item,selected:false}
                    )
                  }))
                  setHackathonStatus(hackathonStatusList.map(item=>{
                    return(
                      {...item,selected:false}
                    )
                  }))
                  setEligibilityStatus(eligibilityStatus.map(item=>{
                    return(
                      {...item,selected:false}
                    )
                  }))
              }}>
                Reset
              </Button>
            </div>
            <List
              dataSource={activeFilterList()}
              renderItem={(item) => (
                <List.Item key={item.title}>
                  <List.Item.Meta
                    title={<span>{item.title}</span>}
                    description={<span>{item.discription}</span>}
                  />
                  <div>
                    {/* <CloseOutlined /> */}
                  </div>
                </List.Item>
              )}
            />
            <div className="sort-action">
              <Button
                className="cancel"
                onClick={() => {
                  setFilterDetailsFlag(false);
                }}
              >
                CANCEL
              </Button>
              <Button
                className="float-right ok"
                onClick={() => {
                  setFilterDetailsFlag(false);
                }}
                style={{ width: "4.5rem" }}
              >
                OK
              </Button>
            </div>
          </div>
        );

        return (
          <Popover
            placement="bottomLeft"
            content={contentBody}
            trigger="hover"
            visible={filterDetailsFlag}
            onVisibleChange={() => {
              if (filterDetailsFlag) {
                setFilterDetailsFlag(false);
              } else {
                setFilterDetailsFlag(true);
              }
            }}
          >
            {logo}
          </Popover>
        );
        break;
      case "filter":
        break;
      default:
        contentBody = (
          <div className="sort-popover">
            <div>
              {" "}
              <span className="sort-heading-css">Sort By</span>{" "}
              <span className="float-right">Reset</span>
            </div>

            <ul className="sorting-list-css">
              {sortFieldList.map((item, index) => (
                <li
                  key={index}
                  className={item.selected ? "select-sorting-menu" : ""}
                  onClick={() => {
                    let temp = sortFieldList.slice();
                    temp[index].selected = !temp[index].selected;
                    setSortOrderlist(temp);
                  }}
                >
                  {item.label}{" "}
                  <span className="float-right">
                    {" "}
                    <span className={!item.selected ? "check-icon" : ""}>
                      <CheckCircleTwoTone />
                    </span>
                  </span>
                </li>
              ))}
            </ul>

            <div className="sort-action">
              <Button
                className="cancel"
                onClick={() =>
                  setCustomPopoverVisible({
                    ...isCustomPopoverVisible,
                    sortDetails: true,
                  })
                }
              >
                CANCEL
              </Button>
              <Button className="float-right ok">OK</Button>
            </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={isCustomPopoverVisible.sortingFields}
            onVisibleChange={() => toggleSortingPopover(null)}
          >
            {logo}
          </Popover>
        );

        break;
    }
  };

  const toggleSortingPopover = (type = null) => {
    switch (type) {
      case 1:
      default:
        setCustomPopoverVisible({ ...isCustomPopoverVisible, type: true });
        break;
    }
  };

  return (
    <>
          <h1 className={classes.header}>Candidate Management</h1>
          <div className={classes.container}>
          {/* <div  style={{
            padding: "2.5%",
            width: "100%",
            boxSizing:"border-box",
            height:"88vh"
          }}>   */}

          <div style={{display:'flex',flexDirection:'row',gap:"2%",marginBottom:"1rem",height:"13rem"}} >
          
          <Card bordered={false} style={{ flex:"1 1 42%"}}>
          <div>   
            <div style={{marginTop:"-0.5rem"}}>          
              <h2 style={{fontWeight:"bold"}}>Summary</h2>                                     
              <span style={{color:"#6F6F6F",display:"flex",alignItems:"left",}}>Last Updated: {present}{" "}</span>
              <div style={{flex: "1 1 33%",display: "flex",
                          flexDirection: "row",
                          gap:"1.2rem",
                          minHeight: "6.4rem",
                          borderRadius: "3px",
                          marginTop: "3%",
                          marginBottom:"2%",
                          marginRight:"2%",
                        }}>
               {candidatedashboard.map((item, index) => {
                let color = "#F0FAF1";
                if (index === 1) {
                color = "#FFF0F0";
                } else if (index === 2) {
                color = " #FFFBEC";
                }

                return (                                                  
                    <div style={{ backgroundColor: color,display: "flex",flexDirection:"column",justifyContent: "center",alignItems: "center",width:"8.5rem", }}>
                        <h2 style={{fontSize: "1.4rem",fontWeight: "bold"}}>{item.value}</h2>
                         <h4 style={{fontWeight:"700px"}}>{item.name}</h4>
                    </div>                                                  
                  );
                 })}
              </div>     
              </div>                            
           </div>         
          </Card>
          
          <Card style={{ flex: "1 1 48%", backgroundColor: "#E6F3FF",}}>
           <div>                                     
           <div style={{marginTop:"-0.5rem"}}>
               <h2 style={{fontWeight:"bold"}}>Add Candidate(s)</h2>
               <p style={{color:"#6F6F6F"}}>Add candidates by importing in groups or by filling form manually or
                 by sending the registration link.</p>
              <div style={{display:"flex",flexDirection:"row",gap:"1.5rem",marginTop:"1rem",marginBottom:"1rem"}}>                                                              
                  <ImportData                                                                 
                  setLoader={setLoader}
                  refresh={getfun.bind(this)}
                 />                                                                       
                 <FillForm                                                              
                  setLoader={setLoader}
                  refresh={getfun.bind(this)}
                 />
                 <div>
                    <Button style={{ borderColor: "#4DAAFF", color: "#4DAAFF",backgroundColor:"#E6F3FF",height:"2.2rem",width:"11rem",borderRadius:"5px" }}
                        onClick={()=>
                          {
                            dispatch(adminActions.updateRegLinkcandType("Fresher"));
                            dispatch(adminActions.updateRegLinkModalOpen(true));
                          }                
                        }
                        ><b>REGISTRATION LINK</b></Button>
                      <RegLinkModal/>
                 </div>
                 </div>

                  <div>
                    <div >
                      {/* <a href={"https://brigosha-hackathon-test.s3.ap-south-1.amazonaws.com/file/studentTemplate.csv"} target="_blank"> */}
                         {" "}
                       <p style={{
                            color: "#6BCB77",
                            font: "normal normal bold 12px/22px Open Sans",
                            marginTop:"1.2rem",
                            cursor:"pointer",
                            width:"12.2rem"
                         }} onClick={()=>{
                          Api.get("/hiring-candidate/candidateTemplate").send(resp=>{
                            if(resp)
                            {
                              window.open(resp, "_blank")?.focus();
                             }
                          })
                         }}>
                        <img style={{ width: "4%" }} src={Excel} />
                          &nbsp;DOWNLOAD EXCEL TEMPLATE
                        </p>                   
                        </div>                                                        
                    </div>
                   </div>                                                                       
               </div>  
            </Card>
          
         </div>  

                        <div>
                              <div className={classes.searchDiv}>
                                        {/* <div>
                                            <img src={searchIcon}></img>
                                        </div>                                   */}
                                          <Input size="medium" prefix={<SearchOutlined className="custom-search-icon" />} 
                                            style={{color:"#161616",}}
                                            type="text"
                                            allowClear={true}
                                            placeholder="Search"
                                            onChange={onNameChange}
                                          />
                                </div>
                                        {/* <div >                                                 
                                                              <Input
                                                              size="middle"
                                                                placeholder="search by institute "
                                                                onChange={onInstituteChange}
                                                                prefix={<SearchOutlined className="custom-search-icon" />}
                                                              />                                         
                                        </div> */}
                                        <div>                                          
                                                      <Button                                                       
                                                        style={{
                                                          height:"2.1rem",
                                                          borderColor: "#43A187",
                                                          color:"#161616",
                                                          borderRadius: "4px",
                                                          fontWeight:"700",
                                                          marginLeft:"10rem",
                                                          width:"8rem"
                                                        }}
                                                        onClick={showDate}
                                                      >
                                                        Export Excel
                                                      </Button>
                                                      <Modal centered                                                 
                                                        onOk={handleOk2}
                                                        visible={showDateModal}
                                                        onCancel={handleCancel2}
                                                        footer={false}
                                                      >
                                                                  <div className={classes.export}>   
                                                                                    <h2>Dates</h2>                                                                                                        
                                                                                    <p> Select Date</p>                                                                                                                                                                                                                                                                                  
                                                                                      <DatePicker
                                                                                        onChange={onExcelDateChange}
                                                                                        disabledDate={disabledDate}
                                                                                      />                                                                                                           
                                                                                      <Button
                                                                                        onClick={onExport}
                                                                                        type="primary"
                                                                                        loading={btn2Loader}
                                                                                      >
                                                                                        Export
                                                                                      </Button>    
                                                                    </div>                                                   
                                                      </Modal>

                                        </div>
                                        <div>
                                          {!user?.role?.includes("Sourcer")?
                                                      <Button                                          
                                                        style={{
                                                          height:"2.1rem",
                                                          backgroundColor: "#76DC4B",
                                                          color: "#FFFFFF",
                                                          borderRadius: "4px",
                                                          fontWeight:"700",
                                                          marginLeft:"-1rem"
                                                        }}
                                                        onClick={() => {
                                                          toggleSendMailModal(null);
                                                        }}
                                                      >
                                                        Send Test Link
                                                      </Button>:""}
                                                      <Modal
                                                        title="Test Details"
                                                        onOk={handleOk}
                                                        visible={isModalVisible}
                                                        onCancel={handleCancel}
                                                        footer={false}
                                                      >
                                                        <Row >
                          
                          
                          
                                                         <h2 style={{ marginRight: "20px" }}>Select Test</h2>

                                                          {/* <Checkbox.Group
                                                            style={{ fontSize: "70px", color: "brown" }}
                                                            size="large"
                                                            options={}

                                                            onChange={onChange3}

                                                          /> */}
              </Row>
              <Row style={{ marginTop: "30px" }} justify="center">
                <Button
                  size={"large"}
                  style={{ borderColor: "orange", backgroundColor: "orange" }}
                  onClick={() => {
                    onCon(selectedRow);
                  }}
                  loading={buttLoader}
                >
                  Send Test Link
                </Button>
              </Row>
            </Modal>
          </div>
          <div>
            <div>
              <DragAndDrop
                itemList={itemList}
                setItemList={setItemList}
                selectCol={selectCol}
                setSelectCol={setSelectCol}
                tableType={tableType}
              />
            </div>
            <div>
              <Sorter
                tableType={tableType}
                sortType={sortType}
                setSortType={setSortType}
                getFunc={getfun}
                activeFilterList={activeFilterList}
                sortFieldList={sortFieldList}
                setSortOrderlist={setSortOrderlist}
                sortCandidate={filterCandiate}
              />
            </div>
            <div>
              {customPopover(
                "filter-details",
                null,
                <img src={filter} style={{ height: "25px" }} />
              )}
            </div>
            <div className="head-icon">
              <img
                src={download}
                style={{ height: "25px", cursor: "pointer" }}
                onClick={() => {
                
                  let temPar=saverequestBody;
                  delete(temPar.page);
                  delete(temPar.limit);
                  Api.post("/hiring-candidate/fresherCandidates")
                    .params({
                      download: true,
                      mappedcolumns: columnFeature,
                     ...temPar
                    })
                    .send((response) => {
                      if (typeof response != "undefined") {
                        window.open(response, "_blank")?.focus();
                      }
                    });
                }}
              />
            </div>
            { user?.role.includes("Recruiter") || user?.role.includes("Sourcer") ?
            ""
            :
            <div
              onClick={() => {
                setDeleteAllCandidate(true)            
              }}
            >
              <img
                src={BinIcon}
                style={{ height: "25px", cursor: "pointer" }}
              />
            </div>
            }
          </div>
        </div>
          <Modal open={deleteAllCandidate} centered onOk={() => DeleteApi(selectedRow, "multiple")} onCancel={() => setDeleteAllCandidate(false)}
           width="40%"
            footer={<div style={{display:"flex",flexDirection:"row",gap:"0.5rem",margin:"0.5rem",justifyContent:"flex-end",width:"100%",paddingRight:"1rem"}}>
            <Button style={{width:"7vw",borderRadius:5}} onClick={() => setDeleteAllCandidate(false)}>Cancel</Button>
            <Button style={{width:"6vw",borderRadius:5}} type="primary" onClick={() => DeleteApi(selectedRow, "multiple")} >OK</Button>
           </div>}
           >
          <div style={{padding:"3rem"}}>
          <Row justify={"center"}><CloseOutlined style={{color:"red",fontSize:"50px"}} /></Row>
          <br></br>
           <Row justify={"center"}><p style={{fontSize:18}} >Are you Sure, It will be deleted permanently?</p> </Row> 
           </div>
          </Modal>
        <Row>
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              selectedRowKeys
              selectedRows
              columns={column}
              dataSource={data}
              loading={loader}
              scroll={{ y: 500 }}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: [10,20, 30, 50, 100],
                pageSize: info.itemPerPAge,
                onChange: handlePageChange,
                onShowSizeChange: handlePageChange,
                total: info.totalItems,
                current: parseInt(info.currentPage),
              }}
              locale={{ emptyText: "No candidates Found" }}
            />
          </Col>
        </Row>

        {scheduleModal && (
          <Schedule
            refresh={getCandidateApiCall.bind(this)}
            scheduleModal={scheduleModal}
            setScheduleModal={setScheduleModal}
            scheduleRecord={scheduleRecord}
            setScheduleRecord={setScheduleRecord}
          />
        )}
      </div>
      {isSendEmailMailVisible && (
        <SendEmailModal
          isOpen={isSendEmailMailVisible}
          toggle={toggleSendMailModal.bind(this)}
          selectedData={selectedUpdateObject}
          selectedRowData={selectedRowData}
          refresh={fetchTemplate.bind(this)}
        />
      )}
      <RegLinkModal />
    </>
  );
};
export default Candidates;