import React from "react";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./loader.css";
const LoadingModal = ({ visible }) => {
  return (
    <Modal
      className="loadingModal"
      mask={false}
      visible={visible}
      footer={null}
      centered
      closable={false}
      transitionName=""
    >
      {" "}
      <div
        style={{
          backgroundColor: "white",
          width: 60,
          height: 60,
          borderRadius: 30,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <LoadingOutlined style={{ fontSize: 64, color: "skyblue" }} />{" "}
      </div>{" "}
    </Modal>
  );
};
export default LoadingModal;
