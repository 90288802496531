import React, { useEffect, useState } from "react";
  import classes from './candidate-detail-upload.module.css';
  import uploadStyleImg from '../../assets/candidateUploadImg.jpg';
import uploadResumeIcon from '../../assets/uploadResumeIcon.svg';
import profileIcon from '../../assets/profileIcon.svg.svg';
import cameraIcon from '../../assets/cameraIcon.svg';
import { candidateActions } from "pages/dashboard-candidate/store";
import Api from "services/Api";
import {
  LoadingOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  CheckOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  message,
  Upload,
  Row,
  Typography,
  Card,
  Col,
  Input,
  Button,
  Form,
  Select,
  Modal,
} from "antd";
import Notification from "services/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
const { Title } = Typography;

const CandidateSendRemember = () => {
  const [loading, setLoading] = useState(false);
  const[btnLoader,setBtnLoader] = useState(false)
  const[btnLoader1,setBtnLoader1] = useState(false)
  const[btnLoader2,setBtnLoader2] = useState(false)
  const [value, setValue] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [imgFile,setImgFile] = useState("");
  const [resumeFile,setResumeFile]= useState("");
  const [resumeUrl,setResumeUrl]=useState('');
  const [width, setWidth]   = useState(window.innerWidth);
  const {modalOpen}=useSelector(state=>state.userCandidate);
   const dispatch = useDispatch()
  const { Option } = Select;
  let params = new URL(location.href);
  
   let pathname= params.pathname.slice(12);
   let id = params.pathname.slice(18);
  const history=useHistory();
const modalWidth=width>700?"45%":"100%";

const updateDimensions = () => {
  setWidth(window.innerWidth);
}
useEffect(() => {
  window.addEventListener("resize", updateDimensions);
  return () => window.removeEventListener("resize", updateDimensions);
}, []);


  useEffect(() => {
    Api.get(`/hiring-publicapi/${pathname}`).send((response) => {
    //   setValue(response);
    });
  }, []);

  const resumeBeforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    const docsOrPdf = file.type ==='application/docs' || file.type ==='application/pdf';
      if (!docsOrPdf) {
        Notification.error('You can only upload Docs/PDF file!');
        setResumeUrl('');
        return;
      }
     else if (!isLt10M) {
       Notification.error("Image must be smaller than 10MB!");
    }
    else {
      getBase64(file, (url) => {
        setLoading(false);   
        setResumeUrl(url);
        setResumeFile(file);     
      });
    }
  }

const imgbeforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 2;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJpgOrPng) {
        Notification.error("You can only upload JPG/PNG file!");
        setImageUrl('')
        return
      }
     else if (!isLt10M) {
      Notification.error("Image must be smaller than 2MB!");
    }
    else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
        setImgFile(file);
      });
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  return (
    <div className={classes.wrapper}>
              <div className={classes.container}>
                        <div>
                                    <img src={uploadStyleImg}></img>
                        </div>
                        <div>                     
                                    <Form layout="vertical" onFinish={(values)=>{
                                      let params={photo:imgFile,resume:resumeFile}                                                          
                                       Api.patch(`/hiring-publicapi/photoResume/${id}`)
                                      .uploadAndPatch(params,(percentCompleted,response) =>{                                       
                                        setBtnLoader2(false)
                                        if(response?.data?.show?.type==='success')
                                        {
                                          history.push('/thanks');
                                        }                                     
                                    })
                                    }}>
                                            <Form.Item name="photo" rules={[{required:true,message:"Upload a valid document!"}]}>
                                                    <div className={classes.uploadPhoto}>
                                                                  <Upload onRemove={() => setImageUrl("")} showUploadList={false} beforeUpload={imgbeforeUpload} 
                                                                                accept=".png, .jpg, .jpeg"
                                                                                maxCount={1}
                                                                                > 
                                                                                <div>
                                                                                      <div>
                                                                                              {imageUrl&&<img src={imageUrl} style={{width:"10rem",height:"10rem",borderRadius:"50%"}}></img>}
                                                                                              {imageUrl==''&&<img src={profileIcon} ></img>}                                                                          
                                                                                      </div>
                                                                                      <Button>
                                                                                            <img src={cameraIcon}></img>
                                                                                      </Button>
                                                                                      <span>Upload Photo</span>
                                                                                  </div>                                                                                                                                 
                                                                  </Upload>
                                                  </div>
                                            </Form.Item>

                                            <Form.Item  label={<span>Upload Resume</span>} name="resume"  rules={[{required:true,message:"Enter valid document!"}]}>
                                                    <div className={classes.resumeUpload}>
                                                          <Upload onRemove={() => setResumeFile("")} showUploadList={true} beforeUpload={resumeBeforeUpload} 
                                                                  accept=".png, .jpg, .jpeg, .pdf, .docs"
                                                                  maxCount={1}                                                               
                                                                  >                                       
                                                                                <Button>
                                                                                          <img src={uploadResumeIcon}></img>
                                                                                          <h4>Drag and drop it here</h4>
                                                                                          <span>Support files include pdf,doc.</span>
                                                                                </Button>
                                                            </Upload>
                                                      </div>
                                            </Form.Item>
                                                      <div className={classes.submitButton}>
                                                                <Button htmlType="submit" disabled={!imgFile||!resumeFile||imageUrl === ''||resumeUrl === ''}>SUBMIT</Button>
                                                      </div>                                         
                                              </Form>

                        </div>
              </div>
    </div>
  );
};
export default CandidateSendRemember;
