import { Col, Form, Modal, Row, Menu, Dropdown, Tooltip, Badge } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Color } from "services";
import { PrimaryButton, SecondaryButton } from "services/button";
import {
  DownOutlined,
  LoadingOutlined,
  CameraOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { MdCardTravel } from "../../services/reactIcons";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { operationType, inputFieldType } from "./redux/action";
import "./styles.css";
import {Api} from "services";

class CandidateJobChangeModal extends React.Component {
  componentDidMount() {
    const { getJobsForDropdown } = this.props;
    getJobsForDropdown();
  }

  closeModal = () => {
    this.props.performOperation(
      operationType.CANDIDATE_JOB_CHANGE_MODAL,
      false
    );
    this.props.candidateJobChangeToggle(false, {});
    this.props.candidateRoundChangeToggle(false, {});
  };

  onFinish = () => {
     if(this.props.candidateScreenType==="jdChangeProfile")
     {
      Api.patch(`/hiring-candidates/updateProposedJd/${this.props.candidateInfo.id}/?jobId=${this.props.candidateJobChange.id}`)
      .params({})
      .send( (response) =>{
      })
      return
     }
    this.props.patchCandidateJobChange();
  };

  handleJobMenuModalItem = (job) => {
    this.props.updateInputField(inputFieldType.CANDIDATE_JOB_CHANGE, job);
  };

  handleRoundMenuModalItem = (round) => {
    this.props.updateInputField(inputFieldType.CANDIDATE_ROUND_CHANGE, round);
  };

  roundMenuModal = (candidateRoundChange, candidateResetRounds) => {
    return (
      <Menu
        selectedKeys={[candidateRoundChange.title]}
        style={{ height: "9vh", overflow: "auto" }}
      >
        {candidateResetRounds.map((round, index) => (
          <Menu.Item
            className=""
            key={round.title}
            onClick={() => this.handleRoundMenuModalItem(round)}
          >
            {round.title}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  render() {
    const {
      candidateJobChangeModal,
      candidateInfo,
      candidateStatus,
      candidateScreenType,
      candidateJobs,
      candidateJobChange,
      candidateResetRounds,
      candidateRoundChange,
      candidatePhotoLoader,
      candidateJobChangeLoaderBool,
    } = this.props;
    const uploadButton = (
      <div>
        {candidatePhotoLoader ? (
          <LoadingOutlined />
        ) : (
          <CameraOutlined style={{ color: Color.darkGrey, fontSize: "2em" }} />
        )}
      </div>
    );

    const jobMenuModal = () => {
      const jobTitle = candidateInfo.jobs.title;
      return (
        <Menu
          selectedKeys={[candidateJobChange.title]}
          style={{ height: "50.5vh", overflow: "auto" }}
        >
          {candidateJobs.map((job, index) =>
            jobTitle !== job.title ? (
              <Menu.Item
                className=""
                style={{ width: 200 }}
                key={job.title}
                onClick={() => this.handleJobMenuModalItem(job)}
              >
                <Tooltip title={job.title}>
                  <Badge
                    status={job.status === "Active" ? "success" : "error"}
                  />
                  {job.title}
                </Tooltip>
              </Menu.Item>
            ) : (
              ""
            )
          )}
        </Menu>
      );
    };

    return (
      <Modal
        visible={candidateJobChangeModal}
        maskClosable={false}
        title={
          <div className="r-c-sb">
            <span>{candidateInfo.name}</span>
          </div>
        }
        onOk={() =>
          this.onFinish(candidateInfo.id, candidateStatus.shortlisted)
        }
        onCancel={this.closeModal}
        footer={[
          <SecondaryButton
            onClick={this.closeModal}
            text="Discard"
            className="modalButton"
            size="large"
          />,
          <PrimaryButton
            htmlType="submit"
            loading={candidateJobChangeLoaderBool}
            onClick={() =>
              this.onFinish(
                candidateInfo.id,
                candidateStatus.shortlisted,
                candidateInfo.shortlisterComment
              )
            }
            text="Save"
            className="modalButton"
            size="large"
            disabled={
             candidateScreenType==="jdChangeProfile" || (candidateJobChange.title && candidateRoundChange.title)
                ? false
                : true
            }
          />,
        ]}
      >
        <Form>
          <Row
            gutter={24}
            className="m-b-10"
            style={{ borderBottom: `2px solid ${Color.lightGrey}` }}
          >
            <Col span={24}>
              <div className="text-bold m-b-5">
                <div>
                  <MdCardTravel />
                  {`  Job Title`}
                </div>
              </div>
              <Form.Item className="form-item">
                <div className="r-c-sb">
                  <Dropdown overlay={jobMenuModal} trigger={["click"]}>
                    <div className="r-c-sb text-overflow cursor-pointer flex-1">
                      <Tooltip title={candidateJobChange.title}>
                        <div className="text-overflow">
                          {candidateJobChange?.title || candidateInfo?.jobs?.title}
                        </div>
                      </Tooltip>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </div>
              </Form.Item>
            </Col>
          </Row>
          {candidateScreenType=== "jdChangeProfile" ?"" :
          <Row
            gutter={24}
            className=""
            style={{ borderBottom: `2px solid ${Color.lightGrey}` }}
          >
            <Col span={24}>
              <div className="text-bold m-b-5">
                <div>
                  <SyncOutlined />
                  {`  Round`}
                </div>
              </div>
              <Form.Item className="form-item">
                <div className="r-c-sb">
                  <Dropdown
                    overlay={this.roundMenuModal(
                      candidateRoundChange,
                      candidateResetRounds
                    )}
                    trigger={["click"]}
                  >
                    <div className="r-c-sb text-overflow cursor-pointer flex-1">
                      <div className="text-overflow">
                        {candidateRoundChange.title || "Select Round"}
                      </div>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                </div>
              </Form.Item>
            </Col>
          </Row>}
        </Form>
        <br></br>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateJobChangeModal);
