import classes from "./recruiter.module.css";
import { Select, DatePicker, Button,Spin,Modal,Table,Row,Radio,Tooltip,Badge,Typography,Popover } from "antd";
import { Column } from "@ant-design/plots";
import { Api, Color } from "services";
import CheckIcon from "../../../assets/check_Icon.svg";
import xIcon from "../../../assets/x_icon.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { overallDashboardActions } from "../store";
import {DownloadOutlined,} from '@ant-design/icons';

import RecuiterTabModal from "./recruiterTabModal";
const Recruiter = () => {
  const { RangePicker } = DatePicker;
  const [canType,setCanType]=useState('Experienced');
  const [selectedRecruiterId,setSelectedRecruiterId]=useState('');
  const[selectedCollegeId,setSelectedCollegeId]=useState('');
  const [userId,setUserId]=useState([]);
  const [jobId,setJobId]=useState([]);
  const [priority,setPriority]=useState([]);
  const[selectedDate,setSelectedDate]=useState([]);
  const [recuiterList,setRecuiterList]=useState([]);
  const [collegeList ,setCollegeList]=useState([]);
  const [loader,setLoader]=useState(true);
  const recuiterInfo=useSelector(state=>state?.overallDashboard?.recuiterInfo);
  const dispatch=useDispatch();
  const [modalOpen,setModalOpen]=useState(false);
  const [modalData,setModalData]=useState([]);
  const priorityOptions=[
    {value:"Low",},
    {
      value:"Moderate",
    },
    {
      value:"High"
    }
  ]
  const summaryData = [
    {
      title: "Shared",
      value:recuiterInfo?.candidateShared,
      dataKey:"candidateShared",
    },
   
    
  ];

  if(canType === "Experienced"){
    summaryData.push({
      title: "Total Shortlisted",
      value: recuiterInfo?.shortlisted,
      dataKey: "shortlisted",
    },
    {
      title: "New",
      value:recuiterInfo?.yetToStart,
      dataKey:"yetToStart",
    },
    {
      title: "In Shortlisted State",
      value:recuiterInfo?.inShortlistedState,
      dataKey:"inShortlistedState",
    },
    {
      title: "Candidate InProgress",
      value:recuiterInfo?.candidateInprogress,
      dataKey:"candidateInprogress",
    },
    {
      title: "Selected (HR)",
      value: recuiterInfo?.selected,
      dataKey:"selected",
    },
    {
      title: "On Hold",
      value: recuiterInfo?.onHold,
      dataKey:"onHold",
    },
    {
      title: "Not Selected",
      value: recuiterInfo?.notSelected,
      dataKey:"notSelected",
    },
    {
      title: "Not Shortlisted",
      value:recuiterInfo?.notShortlisted,
      dataKey:"notShortlisted",
    },
    {
      title: "Not Interested",
      value:recuiterInfo?.notInterested,
      dataKey:"notInterested",
    },
    {
      title: "Offer Released",
      value:recuiterInfo?.offerReleased,
      dataKey:"offerReleased",
    },
    {
      title: "Offer Accepted",
      value:recuiterInfo?.offerAccepted,
      dataKey:"offerAccepted",
    },
    {
      title: "Offer Rejected",
      value:recuiterInfo?.offerRejected,
      dataKey:"offerRejected",
    },
    {
      title: "Joined",
      value:recuiterInfo?.joined,
      dataKey:"joined",
    },
    {
      title: "Conversion Ratio",
      value:recuiterInfo?.conversionRatio,
      dataKey:"conversionRatio",
    },)
  }else if(canType === "Fresher"){
    summaryData.push(
    {
      title: "New",
      value:recuiterInfo?.yetToStart,
      dataKey:"yetToStart",
    }, {
      title: "Candidate InProgress",
      value:recuiterInfo?.candidateInprogress,
      dataKey:"candidateInprogress",
    },
    {
      title: "Selected (HR)",
      value: recuiterInfo?.selected,
      dataKey:"selected",
    },
    {
      title: "On Hold",
      value: recuiterInfo?.onHold,
      dataKey:"onHold",
    },
    {
      title: "Not Selected",
      value: recuiterInfo?.notSelected,
      dataKey:"notSelected",
    },
    {
      title: "Not Shortlisted",
      value:recuiterInfo?.notShortlisted,
      dataKey:"notShortlisted",
    },
    {
      title: "Not Interested",
      value:recuiterInfo?.notInterested,
      dataKey:"notInterested",
    },
    {
      title: "Offer Released",
      value:recuiterInfo?.offerReleased,
      dataKey:"offerReleased",
    },
    {
      title: "Offer Accepted",
      value:recuiterInfo?.offerAccepted,
      dataKey:"offerAccepted",
    },
    {
      title: "Offer Rejected",
      value:recuiterInfo?.offerRejected,
      dataKey:"offerRejected",
    },
    {
      title: "Joined",
      value:recuiterInfo?.joined,
      dataKey:"joined",
    },
    {
      title: "Conversion Ratio",
      value:recuiterInfo?.conversionRatio,
      dataKey:"conversionRatio",
    })
  }
  

  const CandidateChart = () => {
    const data = canType === "Fresher" ? [
      {
        Status: "Shared",
        candidatesCount:recuiterInfo?.candidateShared,
      },
      {
        Status: "Selected(HR)",
        candidatesCount:recuiterInfo?.selected,
      },
    ]
    :[
      {
        Status: "Shared",
        candidatesCount:recuiterInfo?.candidateShared,
      },
      {
        Status: "Total Shortlisted",
        candidatesCount:recuiterInfo?.shortlisted,
      },
      {
        Status: "Selected(HR)",
        candidatesCount:recuiterInfo?.selected,
      },
    ];

    const config = {
      width: "100%",
      data,
      xField: "Status",
      yField: "candidatesCount",
      label: {
        position: "middle",
        style: {
          fill: "#ffffff",
          opacity: 0.6,
        },
      },
      meta: {
        status: {
          alias: "Status",
        },
        candidatesCount: {
          alias: "Number of Candidates",
        },
      },
    };
    return <Column {...config} />;
  };

  useEffect(()=>{
    setLoader(true);
    if(!selectedCollegeId)
    {
      setTimeout(()=>{
        setLoader(false);
      },[3000])
    }
    let param={
      candidateType:canType,
      startDate:selectedDate[0],
      endDate:selectedDate[1],
    }
    if(selectedRecruiterId||selectedCollegeId)
    {
      if(selectedRecruiterId&&canType==="Experienced")
      {
        param={...param,agencyId:selectedRecruiterId,}
      }
      if(priority&&canType==="Experienced"){
        param={...param,priority:priority}
      }
      if(userId&&canType==="Experienced"){
        param={...param,userId:userId}
      }
      if(selectedCollegeId&&canType==="Fresher")
      {
        param={...param,collegeId:selectedCollegeId}
      }
      if(jobId&&jobId[0]!=='All')
      {
        param={...param,jobId:jobId}
      }
      // if(selectedDate?.length>1&&selectedDate[0])
      // {
      //   param={...param,startDate:selectedDate[0],endDate:selectedDate[1]}
      // }
    }
    Api.get("/hiring-overview/recruitersDashboard").params(param).send(res=>{
      if(res)
      {
        setLoader(false);
        if(canType==='Experienced')
        {
          setRecuiterList(res?.agencyList);
        }
        else if(canType==='Fresher')
        {
          setCollegeList(res?.collegeList);         
        }
        dispatch(overallDashboardActions.updateRecruiterInfo(res));       
      }
    })
  },[selectedRecruiterId,jobId,selectedDate,selectedCollegeId,canType,userId,priority])

  return (
    <>
          <div className={classes.container}>
            <div className={classes.filterBlock}>
              <div
                style={{ width: "100%", display: "flex",alignItems:"center",justifyContent:"space-between",}}
              >
                <div style={{display:"flex",flexDirection:"row",gap: "0.5rem",}}>
                <h3 style={{ fontWeight: "700" }}>Recruiters Overview</h3>
                <span
                  style={{
                    color: Color.darkGrey,
                    fontWeight: "500",
                    alignSelf: "flex-end",
                    fontStyle: "italic",
                    fontSize:"0.8rem",
                    padding:"auto"
                  }}
                >
                  Displaying Last 7 Days Data
                </span>
                </div>
                {/* <div>
                  <Button type='primary' style={{fontWeight:"630",borderRadius:"5px"}}>
                  <DownloadOutlined style={{fontSize:"1.1rem"}}/> Download</Button>
                </div> */}
              </div>
           <div style={{ display: 'flex', gap:"1rem",justifyContent:"space-between",width:"98%",marginBottom:"0.1rem"}}>
            <div className={classes.radio}>
            <Radio.Group bordered={false}
                onChange={(e)=>{
                    setCanType(e.target.value)
                    setPriority([])
                    setUserId([])
                    setJobId([])
                    setRecuiterList([])
                    setCollegeList([])
                    setSelectedCollegeId([])
                    setSelectedRecruiterId([])
                }}
                value={canType}>
                <Radio value="Experienced" 
                    style={{color:canType==="Experienced"?"#0086FF":"#161616"}}>Experienced Candidates</Radio>
                <Radio value="Fresher"
                style={{color:canType==="Fresher"?"#0086FF":"#161616"}}>Fresher Candidates</Radio>
            </Radio.Group>
            </div>
           
           </div>
        
              {/* <div className={classes.canType}>
                          <div className={canType==="Experienced"?classes.activeCircle:""} onClick={()=>{
                            setCanType("Experienced");
                            onSwitchCanType();
                            }}>

                          </div>
                          <span className={canType==="Experienced"?classes.textColor:""}>Experienced Candidates</span>
                          <div className={canType==="Fresher"?classes.activeCircle:""} onClick={()=>{
                            setCanType("Fresher")
                            onSwitchCanType();
                            }}>

                          </div>
                          <span className={canType==="Fresher"?classes.textColor:""}>Fresher Candidates</span>

              </div> */}

              {canType==="Experienced"&&<div
                style={{
                  width: "100%",
                  alignItems: "flex-end",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                  marginTop:"1rem"
                }}
                className={classes.dropdown}
              >
                <Select
                  style={{ width: selectedRecruiterId?"100%":"20%" }}
                  showSearch={true}
                  placeholder="Select Recruiters"
                  allowClear={true}
                  listHeight={170} 
                  getPopupContainer={trigger => trigger.parentNode}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e)=>{
                    setSelectedRecruiterId(e);
                    setJobId([]);
                    setUserId([]);
                    setPriority([]);
                  }}
                >
                  {recuiterList?.map((item,index)=>{
                    return(
                      <Option value={item.id} >                    
                         {item.agencyName}                  
                      </Option>
                    )
                  })}
                </Select>
                {selectedRecruiterId&&<Select
                  style={{ width: "100%" }}
                  placeholder="Select Priority"
                  allowClear={true}
                  mode="multiple" 
                  listHeight={170}               
                  value={priority}
                  getPopupContainer={trigger => trigger.parentNode}
                  onChange={(e)=>{
                    if(jobId?.length>0)
                    {
                      setJobId([]);
                    }                  
                  if(e.includes(''))
                  {
                    setPriority(["High","Moderate","Low"]);
                    return;
                  }
                    setPriority(e);
                  }}
                >
                  {selectedRecruiterId&&<Option value="">All</Option>}
                  {selectedRecruiterId&&priorityOptions?.map((item,index)=>{
                    return(
                      <Option value={item.value}>{item.value}</Option>
                    )
                  })}
                </Select>}
                {selectedRecruiterId&&<Select
                  style={{ width: "100%" }}
                  placeholder="Select Job Profile"
                  getPopupContainer={trigger => trigger.parentNode}
                  value={jobId}
                  listHeight={170} 
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  mode="multiple"
                  onChange={(e)=>{
                    if(userId?.length>0)
                    {
                      setUserId([]);
                    }                
                    if(e.includes(''))
                    {                   
                      setJobId(['All']);
                      return ;
                    }
                    setJobId(e);
                  }}
                >
                  {recuiterInfo?.agencyJob?.filter(item2=>priority.includes(item2.priority)||priority?.length===0)?.length>1&&<Option value="">All</Option>}
                  {recuiterInfo?.agencyJob?.filter(item2=>priority.includes(item2.priority)||priority?.length===0)?.map((item,index)=>{
                    return(
                      <Option value={item.id} key={index}>                  
                         {item.title}                    
                      </Option>
                    )
                  })}
                </Select>}
                {selectedRecruiterId&&<Select style={{ width: "100%" }} 
                placeholder="Select User"
                allowClear={true}
                listHeight={170} 
                getPopupContainer={trigger => trigger.parentNode}
                mode="multiple"
                showSearch={true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={userId}
                onChange={(e)=>{
                  if(e.includes(''))
                  {
                    setUserId(recuiterInfo?.userList?.map(item=>item.id));
                    return;
                  }
                  setUserId(e);
                }}
                >
                  {recuiterInfo?.userList?.length>0&&<Option value="">All</Option>}
                  {recuiterInfo?.userList?.map((item,index)=>{
                    return(
                      <Option value={item.id}>{item.name}</Option>
                    )
                  })}
                </Select>}
                <RangePicker style={{ width: selectedRecruiterId?"100%":"20%" }}  
                  onChange={(e,dateString)=>setSelectedDate(dateString)}/>
              </div>}
              {canType==="Fresher"&&<div className={classes.fresherSearch}>
                          <Select 
                          allowClear={true}
                          placeholder="Select college"
                          listHeight={170} 
                          getPopupContainer={trigger => trigger.parentNode}
                          showSearch={true}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(e)=>{
                            setJobId([]);
                            setSelectedCollegeId(e);
                          }}
                          >
                            {collegeList?.map((item,index)=>{
                              return(
                                <Option value={item.id} key={index}>{item.name}</Option>
                              )
                            })}
                          </Select>
                          <Select 
                          allowClear={true}
                          mode="multiple"
                          showSearch={true}
                          listHeight={170} 
                          getPopupContainer={trigger => trigger.parentNode}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          value={jobId?jobId:undefined}
                          placeholder="Select Job Profile"
                          onChange={(e)=>{                          
                            if(e.includes('All'))
                            {
                              setJobId(['All']);
                              return;
                            }
                            setJobId(e);
                          }}
                          >{recuiterInfo?.collegeJob?.length>0&&<Option value="All">All</Option>}
                            {recuiterInfo?.collegeJob?.map((item,index)=>{
                              return(
                                <Option value={item.id} key={index}>{item.title}</Option>
                              )
                            })}
                          </Select>
                          <div>
                          </div>
                          <div>
                          </div>
                          <RangePicker onChange={(e,dateString)=>setSelectedDate(dateString) }/>
              </div>
              }
            </div>
            {loader?<Spin></Spin>:<div>
            <div className={classes.chartBlock}>            
              <div
                style={{
                  width: "94vh",
                  height: "55vh",
                  margin: "1rem 1.3rem 0 0",
                  padding: "0.6rem",
                  display: "flex",
                  border: `1px solid ${Color.midGrey}`,
                  flexDirection: "column",
                  background: Color.white,
                }}
              >
                <h3 style={{ fontWeight: "700", marginBottom: "0.5rem" }}>
                  Candidate Summary
                </h3>
                <div
                  style={{ display: "flex", height: "30vh", position: "relative" }}
                >
                  <span
                    style={{
                      width: "10rem",
                      height: "1rem",
                      display: "inline-block",
                      position: "absolute",
                      fontWeight: "500",
                      top: "8rem",
                      left: "-4.5rem",
                      WebkitTransform: "rotate(270deg)",
                    }}
                  >
                    Number of Candidates{" "}
                  </span>
                  <div
                    style={{
                      width: "98%",
                      height: "16.5rem",
                      margin: "auto 0.5rem auto 1.5rem",
                    }}
                  >
                    <CandidateChart />
                  </div>
                </div>
                <span
                  style={{
                    width: "10rem",
                    height: "1rem",
                    position: "relative",
                    fontWeight: "500",
                    // bottom: "-4.4rem",
                    top:"4.9rem",
                    left: "8rem",
                  }}
                >
                  Status
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "55vh",
                  margin: "1rem 0",
                  border: `1px solid ${Color.midGrey}`,
                  background: Color.white,
                  padding: "1rem",
                }}
              >
                <h3 style={{ fontWeight: "700", marginBottom: "0.5rem" }}>
                  Offer Released Summary
                </h3>
                <div
                  style={{
                    width: "100%",
                    height: "45vh",
                    padding: "0 2rem",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "30vh",
                      height: "30vh",
                      background: "#E8EBFF",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="text"
                      style={{
                        width: "5rem",
                        height: "5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "4rem",
                          height: "4rem",
                        }}
                        src={CheckIcon}
                      />
                    </Button>
                    <span style={{ color: "#6C63FF" }}>Offer Accepted</span>
                    <h2
                      style={{
                        fontSize: "4rem",
                        fontWeight: "700",
                        color: "#6C63FF",
                      }}
                    >{recuiterInfo ? recuiterInfo?.offerAccepted:"-"}</h2>
                  </div>

                  <div
                    style={{
                      width: "30vh",
                      height: "30vh",
                      background: "#FFCCCC",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="text"
                      style={{
                        width: "3rem",
                        height: "3rem",
                        display: "flex",
                        marginTop: "0.5rem",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "3rem",
                          height: "3rem",
                        }}
                        src={xIcon}
                      />
                    </Button>
                    <span style={{ marginTop: "0.5rem", color: "#FF5C5C" }}>
                     Offer Rejected
                    </span>
                    <h2
                      style={{
                        fontSize: "4rem",
                        fontWeight: "700",
                        color: "#FF5C5C",
                      }}
                    >
                      {recuiterInfo ? recuiterInfo?.offerRejected:"-"}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.summaryBlock}>
              <div style={{ height: "30vh" }}>
                <h3 style={{ fontWeight: "700", margin: "1rem" }}>
                  Candidate Summary
                </h3>
                <div
                  style={{
                    width: "98%",                
                    display: "flex",                  
                    flexDirection:"row",                  
                    flexWrap: "wrap",
                    gap: "2.2rem",
                    overflowY: "auto",
                    maxHeight: "10rem", 
                  }}
                >
                  {summaryData.map((item) => {
                    return (
                        <div
                          style={{
                            cursor:item.title ===  "Conversion Ratio" ?"":"pointer",
                            width: "11.3rem",
                            height: "2.5rem",                       
                            margin:"0.6rem",
                            border: `1px solid ${Color.midGrey}`,
                            borderRadius:'5px',
                            background:`${Color.lightGrey}`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            position:'relative'
                          }} onClick={()=>{
                            setModalOpen(item.title ===  "Conversion Ratio" ? false :true);
                            setModalData({data:item?.value, dataKey:item.dataKey ,title:item.title,})
                          }}
                        >
                          {
                             item.title === "Total Shortlisted" ?
                              <Popover trigger="hover" placement="top"
                              content={<>
                                <div>In Shortlisted State + Candidate InProgress </div>
                                <div> + Selected (HR) + On Hold + Not Selected</div>  
                                <div> </div>  
                                </>} >   
                                <span style={{marginLeft:'0.5rem', }}>{item.title}</span>
                              </Popover>
                              : item.title === "Offer Released" ? 
                                
                                <Popover trigger="hover" placement="top"
                                    content={<>
                                      <div>Offered + Offer Accepted </div>
                                      <div> + Offer Declined + Joined </div>  
                                      <div> + Not Joined</div>  
                                      </>} >   
                                  <span style={{marginLeft:'0.5rem', }}>{item.title}</span>
                                </Popover>
                                : item.title === "Offer Rejected" ? 
                                <Popover trigger="hover" placement="top"
                                    content={<>
                                      <div>Offer Declined + Not Joined </div>
                                      <div> + Not Interested + Not Responding  </div>  
                                      </>} >   
                                  <span style={{marginLeft:'0.5rem', }}>{item.title}</span>
                                </Popover>
                                : item.title === "Conversion Ratio" ? 
                                <Popover trigger="hover" placement="top"
                                    content={<>
                                      <div>Joined : Offer Released </div>
                                      </>} >   
                                  <span style={{marginLeft:'0.5rem', }}>{item.title}</span>
                                </Popover>
                                :
                                <span style={{marginLeft:'0.5rem', }}>{item.title}</span>
                              }
                          <div
                          style={{
                            width: "3rem",
                            height: "3rem",
                            background:Color.white,
                            boxShadow:'0 1px 5px grey',
                            borderRadius: "50%",
                            position:'absolute',right:'-1rem',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                          }}
                        >
                          <h2 style={{fontSize: "1rem",fontWeight: "700",}}>
                            
                            {item?.title === "Conversion Ratio" ? item?.value : item?.value}                         
                          </h2>
                        </div>
                       </div>
                    );
                  })}
                </div>
              </div>
            </div>
           {modalOpen&& <RecuiterTabModal 
                    userId={userId}
                    startDate={selectedDate[0]}
                    endDate={selectedDate[1]}
                    modalData={modalData}
                    modalOpen={modalOpen} 
                    setModalOpen={setModalOpen}
                    jobId={jobId}
                     selectedRecruiterId={selectedRecruiterId}
                     canType={canType}
               />}  
      </div>}
      </div>
    </>
  );
};
export default Recruiter;