import React from "react";
import { Progress, Typography } from "antd";
import { Color } from "services";
import { connect } from "react-redux";
import {WarningOutlined} from "@ant-design/icons"
import { mapDispatchToProps, mapStateToProps } from "../redux/container";

const { Title } = Typography;

class Submitted extends React.Component {
  render() {
    const {feedbackErrorPage, feedbackQuestionNotFound} = this.props
    return (
      <div className="submitted-page">
        {feedbackErrorPage || feedbackQuestionNotFound ? (
          <>
            <WarningOutlined style={{ fontSize: "10em", color: Color.yellow }} />
            <Title level={4} style={{ color: Color.darkGrey }}>
              {feedbackQuestionNotFound ? 
                <>
                  <div>Feedback questions are not present in Backend</div>
                  <div>Please contact R & D Team</div>
                </> :
                <>
                  <div>Feedback has been already submitted Or Interview might be cancelled.</div>
                  <div>Please contact Admin</div>
                </> 
              }
            </Title>
          </>
        ) : (
          <>
            <Progress type="circle" percent={100} style={{color: Color.green}}/>
            <Title level={4} style={{ color: Color.darkGrey }}>
              Feedback has been submitted successfully.
            </Title>
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Submitted);
