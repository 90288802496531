import classes from './viewScreenshotModal.module.css';
import {Modal} from 'antd'
const ViewScreenshotModal=(props)=>{
    const {setScreenshotModalOpen,screenshotModalOpen,screenshot}=props;
    return(
        <div>
            <Modal centered maskClosable={true} footer={null} closable={true} onCancel={()=>setScreenshotModalOpen(false)} open={screenshotModalOpen} width="37rem">
                    <div className={classes.container}>
                        <img src={screenshot}></img>
                    </div>
            </Modal>

        </div>
    )
}
export default ViewScreenshotModal;