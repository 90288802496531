import React, { useState } from // {useEffect}
"react";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Route,Redirect } from "react-router-dom";


import PrivateRoute from "./components/PrivateRoute"

import CandidateInfo from "pages/dashboard-candidate/candidate-info";
import CandidateMockTest from "pages/dashboard-candidate/candidate-test";
import ThankYou from "pages/dashboard-candidate/thank-you";
import { Login } from "pages";
import Api from "services/Api";
import { useEffect } from "react";
import { useSelector,useDispatch } from "react-redux"
import CandidateProfile from "pages/dashboard-home/candidate-profile";
import katex from "katex";
import "katex/dist/katex.min.css";
import CollegeInvitationResposne from '../src/pages/uiutils/invitation';
window.katex = katex;
import DefaultLayout from './components/layout';
import CandidateDetailupload from "pages/uiutils/candidate-detail-upload";
import CandidateSendRemember from "pages/uiutils/candidate-send-remember";
import TestInstructions from "./pages/dashboard-candidate/test-instruction"
import Feedback from "pages/feedback/feedback";
import FresherRegistration from "pages/candidateRegistration/fresherRegistration";
import ExpRegistration from "pages/candidateRegistration/expRegistration";
import ForgotPassword from "pages/login/forgot-password";
import ResetPassword from "pages/login/reset-password";
import Thanks from "pages/candidateRegistration/thankYou";
import AccessLoginLoading from "pages/AccessLoginLoading";
import { useHistory } from "react-router";
import CandidateFeedback from "pages/feedback/candidateFeedback/candidateFeedback";
import { authActions } from "pages/login/store";
import RecordVideo from "pages/dashboard-candidate/recordVideo";
function App() {
  const defaultRoute = {
    Admin: "/Dashboard",
    "Super-Admin":"/Dashboard",
    Panelist:"/dashboard-view",
    Recruiter:"/jd",
    Sourcer:"/jd",
    Shortlister:"/jd",
    HR:"/dashboard-view",
    Agency:"/interview-exp",
    Candidate: "/candidate-info",
    "College-Admin":"/institute-dashboard"
  };
  const { user,token } = useSelector((state) => state.auth);
  let url = new URL(window.location.href);
  // let accessToken = window.location.pathname.split('/')[2].split("=")[1];

  const dispatch=useDispatch()
  let history = useHistory();

  const equalsCheck = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  console.log("sssToenAppp",token)

   useEffect(() => {
     if (token) {
       Api.header(token);
     }
     if(user?.id){
    //  Api.get(`/hiring-auth/checkroles?userId=${user?.id}`)
    //  .send(res =>{
     
    //   if (equalsCheck(res?.roles, user.role)){
     
    //   }
    //   else{
    
    //     dispatch(authActions.removeUser());
    //     window.localStorage.clear()
    //     window.location.replace('/')
    //   }
    //  })
    }
   }, [token]);

  
  return (
    <Router>
      <Switch>
      <Route path="/auth/checktoken"><ResetPassword/></Route>
      <Route  path="/public-api/updateCollegeStatus/">
          <CollegeInvitationResposne/>
        </Route>
       <Route path="/public-api/registerLink/:id">   <FresherRegistration /></Route> 
      <Route  exact path="/public-api/registredCandidate/:id">
          <FresherRegistration />
        </Route>

        <Route exact path="/public-api/exp-candidate-forreg/:id">
          <ExpRegistration />
        </Route>

        <Route exact path="/public-api/candidateFeedback/:slotId/:feedbackId">
        <Feedback type="candidate"/>
        </Route>

        <Route path="/public-api/getpr"><CandidateSendRemember /></Route>

       <Route path="/public-api"><CandidateDetailupload/></Route>
      
        <Route path="/hiring-feedback"><Feedback type="panelist"/></Route>
        <Route exact path="/forgot">
          <ForgotPassword />
        </Route>
        {/* <Route exact path="/login"> */}
          {/* <Login /> */}
        {/* </Route> */}
        <Route path="/AccessLoginLoading/:token">
          <AccessLoginLoading />
        </Route>
        <Route path="/RecordVideo">
          <RecordVideo />
        </Route>
       
        
        <Route exact path="/thank-you/:status">
          <ThankYou />
        </Route>


        <Route exact path="/thanks">
          <Thanks/>
        </Route>

        <Route
          exact
          path="/"
          render={() => {
             
            return token ? (
              <Redirect to={defaultRoute[user?.role?.[0]]} />
            ) : (
              <Redirect to={`/AccessLoginLoading/:token`} />
              
            );
          }}
        />
       
        {/* <Route  exact path="/public-api"><CandidateDetailupload/></Route> */}
        
        <PrivateRoute exact path="/candidate-info" role={["Candidate"]}>
          <CandidateInfo />
        </PrivateRoute>
        <PrivateRoute exact path="/test-instruction" role={["Candidate"]}>
          <TestInstructions />
        </PrivateRoute>
        <PrivateRoute exact path="/candidate-test/:valued" role={["Candidate"]}>
          <CandidateMockTest />
        </PrivateRoute>
        <PrivateRoute  path="/" role={user?.role?.includes("Candidate") ? [] : user?.role}>
          <DefaultLayout />
        </PrivateRoute>
       
        {/* <Route exact path="public-api/"></Route> */}
       

      </Switch>
    </Router>
  );
}

export default App;
