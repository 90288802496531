import { getJobCount, getJobSummary } from "./action";

export const mapStateToProps = (state) => {
  const { user } = state.session;
  const {
    jobSummary,
    jobSummaryLoader,
    jobSummaryProfiles,
    pageMeta,
    totalProfiles,
    totalInProgress,
    totalPending,
    totalNotShortlisted,
    totalRejected,
    totalDuplicate,
  } = state.jobSummary;
  return {
    user,
    jobSummary,
    jobSummaryLoader,
    jobSummaryProfiles,
    pageMeta,
    totalProfiles,
    totalInProgress,
    totalPending,
    totalNotShortlisted,
    totalRejected,
    totalDuplicate,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getJobCount: (dates, page, jobType) =>
    dispatch(getJobCount(dates, page, jobType)),
  getJobSummary: (jobId, dates) => dispatch(getJobSummary(jobId, dates)),
});
