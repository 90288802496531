import { Button, Modal, Form, Input, DatePicker,TimePicker,Row,Col } from "antd";
import React, { useState } from "react";
import moment from "moment";
import Api from "services/Api";
import { adminActions, testActions } from "./store";
import { useDispatch } from "react-redux";
const CloneTest = ({ text, record }) => {
  let [name, setName] = useState(record.name);
 
  let [startTime,setStartTime] = useState(record.time)
  let [endTime,setEndTime] = useState(record.endTime)
  let testId = record.key
  let [date, setDate] = useState(record.date);
  let [instruction, setInstruction] = useState(record.instr);

  //   setStartTime(record.time)

  //   setEndTime(record.endTime)

     

  //   setInstruction(record.instr)
  //   setDate(record.date)
  const [form] = Form.useForm();
  
  
  let day = moment(date, "YYYY-MM-DD");
  
  let time = moment(startTime, "HH:mm");
  let endedTime = moment(endTime, "HH:mm"); 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [btnLoader,setBtnLoader] = useState(false)
  const [btnLoader1,setBtnLoader1] = useState(false)
    const dispatch = useDispatch()
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (e) => {
    
    setName(e.target.value);
  };
  const onInstructionChange = (e) => {
  
    setInstruction(e.target.value);
  };
  const onDateChange = (day, dayString) => {
  
    setDate(day.format("YYYY-MM-DD"));
    
  };
  const onStartTimeChange = (time,timeString) => {
    
    setStartTime(timeString);
    
  };
  const onEndTimeChange = (time,timeString) => {
    
    setEndTime(timeString);
    
  };
  
  const onClick = () => {
    setBtnLoader1(true)
    
    const values={
      testId:testId,
      startTime:startTime,
      endTime:endTime,
      title:name,
      instructions:instruction,
      date:date

    }
    
    Api.post("/hiring-tests/addTest")
    
    .params(values)
    .send((response) => {
      setBtnLoader1(false)
      if (typeof response !== "undefined" && response.show.type === "success")
      {
       setIsModalVisible(false)
      }
      
     
      Api.post("/hiring-tests/getAllTests")
      .params({})
      .send((response) => {
        if (typeof response !== "undefined" ) {
          
          
           dispatch(testActions.updateTest({ test:response }));
           
        }
      });

  })}
   const onFinish = () => {
    setBtnLoader(true)
    
    const values={
      testId:testId,
      startTime:startTime,
      endTime:endTime,
      title:name,
      instructions:instruction,
      date:date

    }
    
    Api.post("/hiring-tests/cloneTest")
    .params(values)
    .send((response) => {
      if(typeof response != "undefined" && response.show.type==="success")
      {
   
      setIsModalVisible(false)
      
     


    }setBtnLoader(false)})
    Api.post("/hiring-tests/getAllTests")
    .params({})
    .send((response) => {
      if (typeof response != "undefined") {
        
        
         dispatch(testActions.updateTest({ test:response }));
         
      }
    });
  };
  function disabledDate(current) {
    
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }
  return (
    <>
      <Button type="text" size="large" onClick={showModal}>
        {text}
      </Button>
      <Modal
        title="Test Info"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form name="basic" form={form} layout = {"vertical"} onFinish={onFinish}>
          <Form.Item label="TITLE" name="title" >
            <Input defaultValue={name} onChange={(e) => onChange(e)} />
          </Form.Item>
          <Row justify="space-between">
            <Col>
          <Form.Item label="Date" name="date">
            <DatePicker
              defaultValue={day}
              onChange={(day, dayString) => onDateChange(day, dayString)}
              disabledDate={disabledDate}
            />
          </Form.Item></Col>
         <Col>
          <Form.Item label="Start Time" name="startTime">
          <TimePicker
              defaultValue={time}  format="HH:mm "
              onChange={(time,timeString) => onStartTimeChange(time,timeString)}
            />
          </Form.Item></Col><Col>
          <Form.Item label="End Time" name="endTime">
          <TimePicker
              defaultValue={endedTime}  format="HH:mm "
              onChange={(time,timeString) => onEndTimeChange(time,timeString)}
            />
          </Form.Item></Col></Row>
          <Form.Item label="Instruction" name="instruction">
            <Input defaultValue={instruction} onChange={(e) => onInstructionChange(e)} />
          </Form.Item><Row justify="space-between"><Col >
          <Form.Item><Button htmlType="submit" size="large" style={{borderColor:"blue" ,color:"blue"}} loading={btnLoader}>Clone Test</Button></Form.Item></Col>
         <Col  > <Button  type="primary" size="large" onClick={onClick}loading={btnLoader1}>Update Info</Button></Col></Row>
        </Form>
      </Modal>
    </>
  );
};
export default CloneTest;