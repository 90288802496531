import {
  fetchSummary,
  fetchSummaryDetails,
  updateFilterParams,
  updateInputField,
  getDownload,
  getAgencyDownload,
} from "./action";
export const mapStateToProps = (state) => {
  const {
    summaryLoader,
    filterParams,
    summaryData,
    totalProfiles,
    totalNotShortlisted,
    totalInProgress,
    totalPending,
    totalDuplicate,
    totalRejected,
    selectedAgencyName,
    selectedAgencyNameTotalCount,
    profiles,
    candidateDateDropdown,
    candidateDateRangeVal,
  } = state.agencySummary;
  const {user} = state.session
  return {
    summaryLoader,
    user,
    filterParams,
    summaryData,
    totalProfiles,
    totalNotShortlisted,
    totalInProgress,
    totalPending,
    totalDuplicate,
    totalRejected,
    candidateDateDropdown,
    candidateDateRangeVal,
    selectedAgencyName,
    selectedAgencyNameTotalCount,
    profiles,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchSummary: () => dispatch(fetchSummary()),
  fetchSummaryDetails: () => dispatch(fetchSummaryDetails()),
  getDownload:() => dispatch(getDownload()),
  getAgencyDownload:() => dispatch(getAgencyDownload()),
  updateFilterParams: (val, key) => dispatch(updateFilterParams(val, key)),
  updateInputField: (inputFieldType, value) => dispatch(updateInputField(inputFieldType, value)),
});
