import {Button, Modal, Table,} from 'antd';
import React, { Component } from 'react';
import { EmptyTable } from "services";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { operationType} from "./redux/action";

const candidatePreviousOrgsColumns = (props) => [
    {
      title: "S. No.",
      render: (text, record, index) => <div className = "text-bold text-black">{(props.candidateHistoryPageNumber - 1) * 10 + index + 1}</div>,
      fixed: true,
    },
    {
      title:"Company Name",
      dataIndex: "",
      key: "",
    },
    {
      title: "From",
      dataIndex: "",
      key: "",
    },
    {
      title: "To",
      dataIndex: "",
      key: "",
    },
    {
      title: "Duration",
      dataIndex: "",
      key: "",
    },
    {
      title: "Employment",
      dataIndex: "",
      key: "",
    },
    {
      title: "Reason",
      dataIndex: "",
      key: "",
    },
    {
        title: (
          <div>
            <div>CTC</div>
            <div style={{ fontSize: "10px" }}>(in LPA)</div>
          </div>
        ),
        dataIndex: "",
        key: "",
    },
];

class CandidatePreviousOrgsViewModal extends Component {
    componentDidMount(){
    }

    render () {
        const {candidatePreviousOrgsViewModal,
               performOperation,
               candidates,
               candidateTableLoader,
               candidateJobDescription,
               candidatePageMeta,
               candidateInfo,
              } = this.props;

          

        return (
           <Modal
            visible={candidatePreviousOrgsViewModal}
            maskClosable={false}
            width="90%"
            title= {`Previous Organisation/s : ${candidateInfo.name}`}
            onCancel={() => {
                performOperation(operationType.HR_PROCESS_CANDIDATE_PREVIOUS_ORGS_VIEW_MODAL, false);
            }}
            footer={
                <Button shape="round" onClick={() => performOperation(operationType.HR_PROCESS_CANDIDATE_PREVIOUS_ORGS_VIEW_MODAL, false)}>
                    Close
                </Button>
            }
           >
            <Table
                columns={candidatePreviousOrgsColumns(this.props).map((col) => ({ ...col, align: "center" }))}
                pagination={{
                    position: "bottomRight",
                    pageSize: 10,
                    pageSizeOptions:[10, 30, 50, 100], 
                    size:"small",
                    total: candidatePageMeta?.totalItems ? candidatePageMeta?.totalItems : 0,
                    onChange: (page,pageSize) => this.handlePageChange(page,pageSize),
                    showSizeChanger: false,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                }}
                dataSource={candidates}
                scroll={{x: 1250}}
                style={{overflow: "hidden"}}
                rowClassName="data-row"
                loading={candidateTableLoader}
                locale={{
                    emptyText: candidateTableLoader ? (
                    <div className="no-data-loader"></div>
                    ) : Object.keys(candidateJobDescription).length === 0 ? (
                    <EmptyTable text="Select Job Title" />
                    ) : (
                    <EmptyTable text="No Profiles Found" />
                    ),
                }}
            />
           </Modal>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidatePreviousOrgsViewModal);