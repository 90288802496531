import "./styles.css";
import React from "react";
import { Color } from "services";
import { Col, Form, Input, Radio, Rate, Row, Slider, InputNumber } from "antd";

export const marks = {
  0: "0",
  10: "1",
  20: "2",
  30: "3",
  40: "4",
  50: "5",
  60: "6",
  70: "7",
  80: "8",
  90: "9",
  100: "10",
};

export const fiveMarks = {
  0: "0",
  20: "1",
  40: "2",
  60: "3",
  80: "4",
  100: "5",
};

export const FeedBackFormInput = ({ serialNo, text, inputNode }) => {
  return (
    <Form.Item className="">
      <Row className="r-c-fs m-b-20">
        <Col className="text-md">{text}</Col>
      </Row>
      <Row style = {{marginLeft : "35px"}}>
        {inputNode}
      </Row>
    </Form.Item>
  );
};

export const RadioNode = ({ radios, value, onChange, disabled }) => {
  return (
    <Col span={16} className="feedback-size">
      <Radio.Group value={value} onChange={onChange} disabled={disabled}>
        {radios && radios.map((radio, index) => (
          <Radio key={index} value={radio}>{radio}</Radio>
        ))}
      </Radio.Group>
    </Col>
  );
};


export const TextInputNode = ({ span, value, onChange, disabled }) => {
  return (
    <Col span={span} className="feedback-size">
      <Input
        placeholder="Your Answer"
        value={value}
        onChange={onChange}
        disabled={disabled}
        bordered={false}
        className="feedback-input"
      />
    </Col>
  );
};

export const NumberInputNode = ({ span, value, onChange, disabled }) => {
  return (
    <Col span={span} className="feedback-size">
      <InputNumber
        placeholder="Your Answer"
        value={value}
        onChange={onChange}
        disabled={disabled}
        bordered={false}
        className="feedback-input"
        style={{ width: "100%" }}
      />
    </Col>
  );
};

export const SliderNode = ({ value, onChange, disabled }) => {
  return (
    <Col span={16} className="feedback-size">
      <Slider step={10} marks={marks} value={value} disabled={disabled} onChange={onChange} />
    </Col>
  );
};

export const RateNode = ({ value, onChange }) => {
  return (
    <Col span={16} className="feedback-size">
      <Rate count={10} value={value} onChange={onChange} />
    </Col>
  );
};

export const RequiredField = ({ text }) => {
  return (
    <div>
      {text}
      <span style={{ color: Color.red }}>*</span>
    </div>
  );
};

export const FeedBackForMultipleInput = ({ serialNo, text, inputNode }) => {
  return (
    <Form.Item className="feedback-form-item">
      <Row className="r-c-fs m-b-20">
        <Col span={2}>
          <div className="feedback-SI text-md">{serialNo}</div>
        </Col>
        <Col className="text-md">{text}</Col>
      </Row>
      {inputNode.map((node) => (
        <div>{node}</div>
      ))}
    </Form.Item>
  );
};

export const SliderNodeForMultipleInput = ({ text, value, onChange, disabled }) => {
  return (
    <Row>
      <Col span={5} className="text-sm">{text}</Col>
      <Col span={2}></Col>
      <Col span={12} className="feedback-size">
        <Slider step={20} marks={fiveMarks} value={value} disabled={disabled} onChange={onChange} />
      </Col>
    </Row>
  );
};
