import {
  Space,
  Table,
  Button,
  PageHeader,
  Row,
  Col,
  Typography,
  Timeline,
  Input,
  Menu,
  Dropdown,
  Card,
  Checkbox,
  Avatar,
  Radio,
  List,
  Popover,
  Tabs,
  Select,
} from "antd";
import "./style.css";
import {
  MoreOutlined,
  UserOutlined,
  CloseOutlined,
  DownOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CheckCircleTwoTone,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import JdViewModal from "./jdViewModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useState, useEffect, useReducer } from "react";
import classes from "./jobListFresher.module.css"
import Api from "../../services/Api";
const { Title } = Typography;
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PdfLogo from "../../assets/pdf-logo.svg";
import download from "../../assets/download.png";
import { jdFresherActions } from "./store";
import CreateJDModal from "./createJdModal";
import Warning from "../uiutils/warningModal";
import { authActions } from "pages/login/store";
import BinIcon from "../../assets/bin-icon.svg";
import { adminActions } from "pages/dashboard-home/store";
import settings from "../../assets/settings.png";
import filter from "../../assets/filter.png";
import sort from "../../assets/sort.png";
import JobTitlePopover from "./jobTitlePopover"
import Constants from "./constants";
import { Notification } from "services";
import Filter from "./filter.cls";
import DragAndDrop from "components/dragAndDrop";
import Sorter from "components/sorter";
const constant = new Constants();
const filterObj = new Filter();
const { TabPane } = Tabs;
const filterReducer=(state,action)=>{
  if(action.type==='JOB_TITLE' )
  {
    return {
      ...state,
       job:{id:action.value.id,name:action.value.name,vacancies:action.value.vacancies},}
  }
  if(action.type==='SEARCH')
  {
    return {
      ...state,
      searchValue:action.value}
  }
  return {job:{name:'',id:'',vacancies:''}, searchValue:''}
}
function JdDashboardFresher() {
  const [sortFieldList, setSortOrderlist] = useState(constant.sortingFields);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [priorities, setPriority] = useState([]);
  const [filterStatus, switchFilterStatus] = useState(constant.filterStatus);
  const [filterDetailsFlag, setFilterDetailsFlag] = useState(false);
  const [sortPopoverFlag, setSortPopoverFlag] = useState(false);
  const [pageChange, setPageChange] = useState(1);
  const [sortType, setSortType] = useState(true);
  const [dept, setDept] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [isDataImportModalVisible, setDataImportModal] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [btnVisible, setBtnVisible] = useState(false);
  const [checkedbox,setCheckbox]=useState(false);
  const [titleModal,setTitleModal]=useState(false);
  const [agencyDetails,setAgencyDetails]=useState({});
  const { list, info, fresherColumn, tabStatus } = useSelector(
    (state) => state.JdFresher
  );
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();
  const [filterKey,dispatchFK]=useReducer( filterReducer ,{job:{name:'',id:'',vacancies:''},searchValue:''});
  const jdStore = useSelector((state) => state.JdFresher);
  const [selectedUpdateObject, setUpdateObject] = useState();
  const authstate = useSelector((state) => state.auth);
  const [searchStr, setSearchStr] = useState("");
  const { Search } = Input;
  const [isWaningModalVisible, setWaningvisibility] = useState(false);
  const [tabstatus, setTabStatus] = useState(false);
  const [jobLocn, setJobLocn] = useState([]);
  const [orgType, setorgType] = useState([]);
  const [poc, setPoc] = useState("");
  const [selectedDept, setSelectDept] = useState("");
  const [itemList, setItemList] = useState("");
  const [selectCol, setSelectCol] = useState("");
  const [visible, setVisible] = useState(false);
  const [isSortLoader, setIsSortLoader] = useState(false);
  const [locn, setLocn] = useState([]);
  const [IdArray, setIdArray] = useState([]);
  const [tableType, setTableType] = useState("");
  const [Status, setStatus] = useState("Inactive");
  const[checkAll,setCheckAll]=useState(false);
  const [priorityData,setPrioritydata]=useState([{value:"Low",selected:false}, {value:"Moderate",selected:false},{value:"High",selected:false}]);
  const [orgTypeData,setOrgTypeData]=useState([]);
  const [jobLocationData,setJobLocationData]=useState([]);
  const [departmentData,setDepartmentData]=useState([]);
  const [initialAgencyData,setInitialAgencyData]=useState([]);
  const [requestBodyData,setRequestBodyData]=useState()
  const [resData,setResData]=useState([]);
  const {user}= useSelector(state =>state.auth);
  const filterJobHandler=(obj)=>{
    dispatchFK(obj);
  }
  const [roundAgency, setRoundAgency] = useState({
    roundType: [],
    feedbackQuestion: {},
  });
  const sortCandidate = () => {
    let requestBody;

    setIsSortLoader(true);
    let selectedField = sortFieldList.filter((a) => a.selected);
    if (selectedField[0]?.sortKey !== "none") {
      let sortorder = {
        order: sortType ? "ASC" : "DESC",
        columnKey: selectedField[0]?.sortKey,
      };
      requestBody = {
        search: searchStr,
        jobExperience: "Fresher",
        status: tabStatus,
        // page: String(pageChange),
        sortOrder: sortorder,
      };
    } else {
      requestBody = {
        search: searchStr,
        jobExperience: "Fresher",
        status: tabStatus,
       page: String(pageChange),
       limit:String(pageSize)
      };
    }
    setRequestBodyData(requestBody)
    jdQuery(requestBody);
  };
  const filterJob = (internalfilterObj = null) => {
    // setLoader(true);
    let requestBody;

    let filterArr = [];

    let selectedField = sortFieldList.filter((a) => a.selected);
    if (selectedField[0]?.sortKey !== "none") {
      let sortorder = {
        order: sortType ? "ASC" : "DESC",
        columnKey: selectedField[0]?.sortKey,
      };
      requestBody = {
        search: searchStr,
        jobExperience: "Fresher",
        status: tabStatus,
        // page: String(pageChange),
        sortOrder: sortorder,
      };
    } else{
      requestBody = {
        search: searchStr,
        jobExperience: "Fresher",
        // page:searchStr===""? String(pageChange):"",
        // limit:searchStr===""? String(pageSize):"",
        status: tabStatus,
      };
    }
    
    
    if (filterObj.priority) {
      requestBody["priority"] = priorityData.filter(item=>item.selected===true).map(item=>item.value);
    }
    if (filterObj.location) {
      requestBody["location"] = jobLocationData.filter(a=>a.selected===true).map(item=>item.id);
    }
    if (filterObj?.departments) {
      requestBody["departments"] = departmentData.filter(item=>item.selected===true).map(item=>item.value);
    }
    if (filterObj.orgtype) {
      requestBody["jobType"] = orgTypeData.filter(item=>item.selected===true).map(item2=>item2.id);
    }
  
    setRequestBodyData(requestBody)
    jdQuery(requestBody);
  };

  useEffect(()=>{
      if(jobLocn.length>0)
      {
        setJobLocationData(jobLocn.map(item=>({...item,selected:false})));
      }
  },
  [jobLocn.length>0])

  useEffect(()=>{
    if(dept.length>0)
    {
      setDepartmentData(dept.map(item=>({value:item,selected:false})))
    }
  },[dept.length>0])

  const getfilterRequest = (key) => {
    switch (key) {
      case "jobType":
        filterObj.orgtype = true;
        break;

      case "priority":
        filterObj.priority = true;
        break;
      case "location":
        filterObj.location = true;
        break;
      case "departments":
        filterObj.departments = true;
        break;
      default:
        break;
    }
  };
  const resetFilter = () => {
    filterObj.resetFitler();

    filterJob();
   
    let jobList = [];
    // jobVacanciesList.filter()
    forceUpdate();
  };

  useEffect(() => {
    setTableType("jdFresher");
  
   filterObj.resetFitler()
   filterJob()
 
  }, [tabStatus]);
  
  useEffect(() =>{
      
   setTableType("jdFresher");
   let requesestBody={ search: searchStr,status:tabStatus,jobExperience:"Fresher" };
  
    if(filterKey.job.id){
             requesestBody={search: searchStr,status:tabStatus,jobExperience:"Fresher",jobId:filterKey.job.id }
   }
  
   if(filterKey.minExp!==-1&&filterKey.maxExp!==-1){
                requesestBody={search: searchStr,status:tabStatus,jobExperience:"Fresher",jobId:filterKey.job.id,}
   }
   setRequestBodyData(requesestBody)
   jdQuery(requesestBody);
 
 }, [searchStr,tabStatus,filterKey.job.id,]);
 
  //  jdQuery()
  // },[searchStr])
  

  useEffect(() =>{
   
  filterJob()
     
  },[pageChange,pageSize])

  const activeFilterList = () => {
    let list = [];
 
    constant.filterFields.map((element) => {
      switch (element.value) {
        case "vacancies":
          if (filterObj.vacancies) {
            let description = "";

            list.push({
              ...element,
              discription: description,
            });
          }
          break;
        case "priority":
          if (filterObj.priority&&priorities.length>0) {
            let description = priorities.map(item=>item.value).join(", ");
            list.push({
              ...element,
              discription: description,
            });
          }
          break;
        case "location":
          if (filterObj.location&&locn.length>0) {
            let map={}
            let values=[]
           locn.forEach((element) =>{
            map[element.id]=true
           })
           jobLocationData.forEach((element) => {
          if(map[element.id]===true)
          {
            values.push(element.name)
          }
          })
        

            list.push({
              ...element,
              discription: values?.map(element => element).join(", "),
            });
          }
          break;
        case "departments":
          if (filterObj.departments&&selectedDept.length>0) {
           
            let description = selectedDept.map(item=>item.value).join(", ");

            list.push({
              ...element,
              discription: description,
            });
          }
          break;
        case "orgtype":
          if (filterObj.orgtype&&jobTypes.length>0) {
            let map={}
            let values=[]
           jobTypes.map(item=>item.id).forEach((element) =>{
            map[element]=true
           })
           orgTypeData?.forEach((element) => {
          if(map[element.id]===true)
          {
            values.push(element.name)
          }
          })
          
            let description = values?.map(element => element).join(", ");
            list.push({
              ...element,
              discription: description,
            });
          }

        default:
         
          break;
      }
    });

    return list;
  };
  const customFilterPopover = (type = null, data = null, logo) => {
    let contentBody;
    switch (type) {
      case "priority":
        contentBody = (
          <div className="sort-popover">
            <div
              style={{
                width: "100%",
              }}           
            >
                    {priorityData.map((item,index) => {
                      return (
                        <div style={{display:"flex",flexDirection:"row",gap:"0.4rem",alignItems:"center"}}>
                                  <input                               
                                    value={item.value}
                                    type="checkbox"
                                    checked={item.selected}
                                    onChange={(e) => {
                                      setPrioritydata(priorityData.map((item2,index2)=>{
                                        if(index===index2)
                                        {
                                          return {...item2,selected:e.target.checked}
                                        }
                                        return item2;
                                      }))                                    
                                    }}
                                  />
                                   <span> {item.value} </span>                                
                        </div>
                      );
                    })}
            </div>
            <div className="sort-action" style={{marginTop:'0.7rem'}}>
              <Button
                className="cancel"
                onClick={() => {
                  let tempStatus = filterStatus;
                  tempStatus.priority = false;
                  switchFilterStatus(tempStatus);
                  forceUpdate();
                }}
              >
                CANCEL
              </Button>
              <Button
                className="float-right ok"
                onClick={() => {
                  setPriority(priorityData.filter(a=>a.selected===true));
                  forceUpdate();
                  getfilterRequest(type);
                  filterJob();
                  let tempStatus = filterStatus;
                  tempStatus.priority = false;
                  switchFilterStatus(tempStatus);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        );
        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            open={filterStatus.priority}
            onOpenChange={() => {
              let tempStatus = filterStatus;
              if(tempStatus.priority === true){
              tempStatus.priority = false;
              }
              else{
                tempStatus.priority = true;
              }

              forceUpdate();
            }}
          >
            {logo}
          </Popover>
        );

      case "jobType":
        contentBody = (
          <div className="sort-popover">
                    <div
                      style={{
                        display:"flex",
                        flexDirection:"column",
                        maxHeight: "22vh",
                          overflowY:"auto",                                                               
                        }}
                    >
                            {orgTypeData?.map((item,index) => {
                              return (
                                    <div style={{display:"flex",flexDirection:"row",gap:"0.4rem",alignItems:"center"}}>
                                              <input
                                                type="checkbox"
                                                checked={item.selected}
                                                value={item.id}
                                                onChange={(e) => {
                                                  let value = e.target.value;
                                                  setOrgTypeData(orgTypeData.map((item2,index2)=>{
                                                    if(index===index2)
                                                    {
                                                          return{
                                                            ...item2, selected:e.target.checked
                                                          }
                                                    }
                                                    return item2;
                                                  }))
                                                  // if (!e.target.checked) {
                                                  //   setJobTypes(
                                                  //     jobTypes.filter(
                                                  //       (element) => element !== e.target.value
                                                  //     )
                                                  //   );
                                                  //   return;
                                                  // }
                                                  // setJobTypes((oldArray) => [...oldArray, value]);
                                                }}
                                              />
                                               <span>{item.name}</span>                                            
                                    </div>
                              );
                            })}
                    </div>
                    <div className="sort-action" style={{marginTop:"0.7rem"}}>
                      <Button
                        className="cancel"
                        onClick={() => {
                          let tempStatus = filterStatus;
                          tempStatus.orgtype = false;
                          switchFilterStatus(tempStatus);
                          forceUpdate();
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        className="float-right ok"
                        onClick={() => {
                          // if(jobTypes.length===0)
                          // {
                          //   Notification.error("Please select a job to apply filter!");
                          //   return;
                          // }
                          setJobTypes(orgTypeData.filter(item=>item.selected===true))
                          getfilterRequest(type);
                          filterJob();
                          let tempStatus = filterStatus;
                          tempStatus.orgtype = false;
                          switchFilterStatus(tempStatus);
                          forceUpdate();
                        }}
                      >
                        OK
                      </Button>
                    </div>
          </div>
        );
        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={filterStatus.orgtype}
            onVisibleChange={() => {
              let tempStatus = filterStatus;
              if (filterStatus.orgtype === false) {
                tempStatus.orgtype = true;
                switchFilterStatus(tempStatus);
                forceUpdate();
              } else {
                tempStatus.orgtype = false;
                switchFilterStatus(tempStatus);
                forceUpdate();
              }
            }}
          >
            {logo}
          </Popover>
        );

      case "location":
        contentBody = (
          <div className="sort-popover">
                  <div  style={{
                              display:"flex",
                              flexDirection:"column",
                              maxHeight: "22vh",
                                overflowY:"auto",                                                               
                              }}>
                                <div>
                                  {jobLocationData.map((item,index) => {
                                    return (
                                      <div style={{display:"flex", flexDirection:"row",gap:"0.4rem",alignItems:"center"}}>                                      
                                              <input
                                                value={item.id}
                                                type="checkbox"
                                                checked={item.selected}
                                                onChange={(e) => {
                                                  let value = e.target.value;
                                                  let checked = e.target.checked;
                                                  setJobLocationData(jobLocationData.map((item2,index2)=>{
                                                        if(index===index2)
                                                        {
                                                          return {
                                                            ...item2,selected:e.target.checked
                                                          }                                                   
                                                        }
                                                        return item2;
                                                  }))
                                                  // if (!checked) {
                                                  //   setLocn(
                                                  //     locn.filter((element) => element !== e.target.value)
                                                  //   );
                                                  //   return;
                                                  // }
                                                  // setLocn((oldArr) => [...oldArr, value]);
                                                }}
                                              />
                                              <span>{item.name}</span>
                                              
                                      </div>
                                    );
                                  })}
                                </div>
                  </div>
            <div className="sort-action" style={{marginTop:"0.7rem"}}>
              <Button
                className="cancel"
                onClick={() => {
                  let tempStatus = filterStatus;
                  tempStatus.location = false;
                  switchFilterStatus(tempStatus);
                  forceUpdate();
                }}
              >
                CANCEL
              </Button>
              <Button
                className="float-right ok"
                onClick={() => {
                  // if(locn.length===0)
                  // {
                  //   Notification.error("Please select a location to apply filter!","")
                  //   return
                  // }
                  setLocn(jobLocationData.filter(a=>a.selected===true));
                  getfilterRequest(type);
                  filterJob();
                }}
              >
                OK
              </Button>
            </div>
          </div>
        );
        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={filterStatus.location}
            onVisibleChange={() => {
              let tempStatus = filterStatus;
              if(tempStatus.location === true){
                tempStatus.location = false
              }
              else{
              tempStatus.location = true;
              }
              switchFilterStatus(tempStatus);
              forceUpdate();
            }}
          >
            {logo}
          </Popover>
        );
        break;
      case "departments":
        contentBody = (
          <div className="sort-popover">
            <div
             style={{
              display:"flex",
              flexDirection:"column",
               maxHeight: "22vh",
                overflowY:"auto",                                                               
              }}
            >
                      {departmentData.map((item,index) => {
                        return (
                          <div style={{display:"flex",flexDirection:"row",gap:"0.4rem",alignItems:"center"}}>               
                                    <input
                                    type="checkbox"
                                    checked={item.selected}
                                      value={item.value}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        setDepartmentData(departmentData.map((item2,index2)=>{
                                          if(index===index2)
                                          {
                                            return{
                                              ...item2,selected:e.target.checked
                                            }                          
                                          }
                                          return item2;
                                        }))
                                        // if (!e.target.checked) {
                                        //   setSelectDept(
                                        //     selectedDept.filter(
                                        //       (element) => element !== e.target.value
                                        //     )
                                        //   );
                                        //   return;
                                        // }
                                        // setSelectDept((oldArr) => [...oldArr, value]);
                                      }}
                                    />
                                     <span>{item.value}  </span>                                
                          </div>
                        );
                      })}
            </div>
            <div className="sort-action" style={{marginTop:"0.7rem"}}>
              <Button
                className="cancel"
                onClick={() => {
                  let tempStatus = filterStatus;
                  tempStatus.departments = false;
                  switchFilterStatus(tempStatus);
                  forceUpdate();
                }}
              >
                CANCEL
              </Button>
              <Button
                className="float-right ok"
                onClick={() => {
                  // if(selectedDept.length===0)
                  // {
                  //   Notification.error("Please select a department to apply filter!")
                  //   return
                  // }
                  setSelectDept(departmentData.filter(a=>a.selected===true));
                  getfilterRequest(type);
                  filterJob();
                }}
              >
                OK
              </Button>
            </div>
          </div>
        );
        return (
          
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={filterStatus.departments}
            onVisibleChange={() => {
              let tempStatus = filterStatus;
              if( tempStatus.departments === true){
                tempStatus.departments = false
              }else{
                tempStatus.departments = true;
              }
              switchFilterStatus(tempStatus);
              forceUpdate();
            }}
          >
            {logo}
          </Popover>
        );
        break;

      default:
        if (type === "experience" || type == "noticePeriod") {

          contentBody = (
            <div className="sort-popover">
              <div>
                <span>min</span>
                <Input
                  type="number"
                  onChange={(e) => {
                    setFilterFilter(type, "min", e.target.value);
                  }}
                />
                <span>max</span>
                <Input
                  type="number"
                  onChange={(e) => {
                    setFilterFilter(type, "max", e.target.value);
                  }}
                />
              </div>
              <div className="sort-action">
                <Button
                  className="cancel"
                  onClick={() => {
                    let temp = filterStatus;
                    temp[type] = !temp[type];
                   
                    switchFilterStatus(temp);
                    forceUpdate();
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  className="float-right ok"
                  onClick={() => {
                    getfilterRequest(type);
                    filterJob();
                    forceUpdate();
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          );

          return (
            <Popover
              placement="bottom"
              content={contentBody}
              trigger="hover"
              visible={filterStatus[type]}
              onVisibleChange={() => {
                // settemp(true)
                let temp = filterStatus;
                // temp["x"]= !temp["x"];
                temp[type] = !temp[type];
                switchFilterStatus(temp);
                forceUpdate();
              }}
            >
              {logo}
            </Popover>
          );
        }
        break;
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
     
      setSelectedRow(selectedRowKeys);
      if (selectedRowKeys.length === 0) {
        setBtnVisible(false);
      } else {
        setBtnVisible(true);
      }
    },
    onSelect: (record, selected, selectedRows) => {
      setIdArray((prev) => [...prev, record.id]);
    },
    onSelectAll: (selected, selectedRows, changeRows,) => {
      setCheckAll(!checkAll)
    },
    getCheckboxProps: (record) => ({}),
  };

  // const rowSelection2 = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedRow2(selectedRowKeys);
  //     if (selectedRowKeys.length === 0) {
  //       setBtnVisible(false);
  //     } else {
  //       setBtnVisible(true);
  //     }
  //   },
  //   onSelect: (record, selected, selectedRows) => {
  //     setIdArray((prev) => [...prev, record.id]);
  //   },
  //   onSelectAll: (selected, selectedRows, changeRows) => {},
  //   getCheckboxProps: (record) => ({}),
  // };

  const handlePageChange = (page, pageSize) => {
   
    setPageChange(page);
    setPageSize(pageSize);
  };

  const getFileName = (fileName) => {
    return fileName.split("/").at(-1);
  };
  let column = [];

  column = fresherColumn?.map((item) => {
    
    let render = "";
    let header;
    switch (item.key) {
      case "title":
        header = <div>{item.title} </div>;

        render = (text, record) => (
          <>
          {user?.role.includes("Recruiter") || user?.role.includes("Sourcer") ?
          <div 
          // style={{ color: "black"}}
        >
          <div style={{fontWeight:"600",fontSize:"15px",textAlign: "left"}}>{text}</div>
        </div>
          :
            <div 
              onClick={() => toggleUpdateModal(record)}
              style={{ color: "black",cursor: "pointer" }}
            >
              <div style={{fontWeight:"600",fontSize:"15px",textAlign: "left"}}>{text}</div>
            </div>
          }
          </>
        );
        break;
      case "descriptionFile":
        header = <div>{item.title} </div>;
        render = (text, record) => {
          
          return (
            <div>
              {text !== null ? (
                <div>
                  <div style={{ textAlign: "left" ,cursor:"pointer"}}>
                    <img
                      onClick={() => window.open(text)}
                      src={PdfLogo}
                      alt="pdf-logo"
                      className="pdf-logo"
                    />
                  </div>
                  <div style={{ textAlign: "left" }}>
                    {" "}
                    {/* <Typography.Text style={{width: "200px",}}>{getFileName(text)}</Typography.Text> */}
                    
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "left" }}>
                  <span>N/A</span>
                </div>
              )}
            </div>
          );
        };
        break;
      case "priority":
        header = (
          <div>
            {item.title}{" "}
            {customFilterPopover("priority", null, <DownOutlined/>)}{" "}
          </div>
        );
        break;
      case "locations":
        header = (
          <div>
            {item.title}{" "}
            {customFilterPopover("location", null, <DownOutlined style={{cursor:"pointer"}}/>)}{" "}
          </div>
        );
        render = (text, _) => {
          return (
            <>
              {text?.map((item, index) => (
                <div>
                  {text.length - 1 > index && <span>{item.name + ", "}</span>}
                  {text.length - 1 === index && <span>{item.name}</span>}
                </div>
              ))}
            </>
          );
        };
        break;

      case "departments":
        header = (
          <div>
            {item.title}{" "}
            {customFilterPopover("departments", null, <DownOutlined style={{cursor:"pointer"}}/>)}{" "}
          </div>
        );
        render = (text, record) => (
          <>
            {text?.map((element) => {
              return <p>{element?element:"NA"}</p>;
            })}
          </>
        );
        break;
      case "spoc":
        header = <div>{item.title}</div>;
        render = (text, record) => (
          <>
            {record?.spoc?.name !== null ? (
              <>
                <p>{record?.spoc?.name}</p>
                {record?.spoc?.phone}
              </>
            ) : (
              ""
            )}
          </>
        );
        break;


        case "masterCgpa":
          header = (
            <div>
              {item.title}         
            </div>
          );
          render = (text, record) => (                   
                 <p>{text?text:"NA"}</p>                        
          );
          break;

          case "masterDepartments":
            header = (
              <div>
                {item.title}         
              </div>
            );
            render = (text, record) => (                   
                   <p>{text?text:"NA"}</p>                        
            );
            break;

            case "masterMaxBackLogs":
              header = (
                <div>
                  {item.title}         
                </div>
              );
              render = (text, record) => (                   
                     <p>{text?text:"NA"}</p>                        
              );
              break;


      case "round1":
        header = <div>{item.title}</div>;
        render = (text, record) => (
          <>         
            <p>{record?.jobInterviewrounds[0]?.roundType && record?.jobInterviewrounds[0]?.roundType === "Relationship"
                ? "HR Relationship":
                record?.jobInterviewrounds[0]?.roundType ? record?.jobInterviewrounds[0]?.roundType
                :"NA"}</p>         
           </>
        );
        break;
      case "round2":
        header = <div>{item.title}</div>;
        render = (text, record) => (
          <>        
              <p>{record?.jobInterviewrounds[1]?.roundType && record?.jobInterviewrounds[1]?.roundType === "Relationship"
                ? "HR Relationship"
                :record?.jobInterviewrounds[1]?.roundType ? record?.jobInterviewrounds[1]?.roundType
                :"NA"}</p>          
          </>
        );
        break;
      case "round3":
        header = <div>{item.title}</div>;
        render = (text, record) => (
          <>      
          <p>{record?.jobInterviewrounds[2]?.roundType && record?.jobInterviewrounds[2]?.roundType === "Relationship"
          ? "HR Relationship"
          :record?.jobInterviewrounds[2]?.roundType ? record?.jobInterviewrounds[2]?.roundType
          :"NA"}</p>      
          </>
        );
        break;
      case "round4":
        header = <div>{item.title}</div>;
        render = (text, record) => (
          <>     
            <p>{record?.jobInterviewrounds[3]?.roundType && record?.jobInterviewrounds[3]?.roundType === "Relationship"
            ? "HR Relationship"
            :record?.jobInterviewrounds[3]?.roundType ? record?.jobInterviewrounds[3]?.roundType
            :"NA"}</p>    
          </>
        );
        break;
        case "round5":
          header = <div>{item.title}</div>;
          render = (text, record) => (
            <>      
              <p>{record?.jobInterviewrounds[4]?.roundType && record?.jobInterviewrounds[4]?.roundType === "Relationship"
              ? "HR Relationship"
              : record?.jobInterviewrounds[4]?.roundType ? record?.jobInterviewrounds[4]?.roundType
              :"NA"}</p>    
            </>
          );
          break;
      case "jobType":
        header = (
          <div>
            {item.title}{" "}
            {customFilterPopover("jobType", null, <DownOutlined style={{cursor:"pointer"}}/>)}
          </div>
        );
        render = (text, record) => (
          <>
            {record?.jobType?.name !== null ? (
              <>
                <p>{record?.jobType?.name?record?.jobType?.name:"NA"}</p>
              </>
            ) : (
              ""
            )}
          </>
        );
        break;
      case "action":
        header = <div>{item.title} </div>;
        render = (_, record) => (
          <>
            <JdViewModal
              roundAgency={roundAgency}
              record={record}
              poc={poc}
              refresh={jdQuery.bind(this)}
              orgType={orgType}
              jobLocations={jobLocn}
              isOpen={showUpdateModal}
              user={user}
            />
          </>
        );
        break;
      // case "round1" :
      // header=<div>{item.title}</div>
      // render=(text,record) =>(      <>
      //  {record.jobInterviewrounds.map((item) =>{
      //    if(item.round===1){

      //      return(
      //        <p>{item.roundType}</p>
      //      )
      //    }

      //  })}

      // </>
      // )
      //  break;
      //  case "round2" :
      //  header=<div>{item.title}</div>
      //  render=(text,record) =>(<>
      //   {record.jobInterviewrounds.map((item) =>{
      //     if(item.round===2){
      //       return(
      //         <p>{item.roundType}</p>
      //       )
      //     }
      //   })}
      //  </>

      //  )
      //   break;
      //   case "round3" :
      //   header=<div>{item.title}</div>
      //   render=(text,record) =>{
      //    {record.jobInterviewrounds.map((item) =>{
      //      if(item.round===3){
      //        return(
      //          <p>{item.roundType}</p>
      //        )
      //      }
      //    })}

      //  }
      //    break;
      //    case "round4" :
      //    header=<div>{item.title}</div>
      //    render=(text,record) =>{
      //     {record.jobInterviewrounds.map((item) =>{
      //       if(item.round===4){
      //         return(
      //           <p>{item.roundType}</p>
      //         )
      //       }
      //     })}

      //   }
      //     break;
      // case "action" :

      // header=<div>{item.title} </div>
      // render = (_,record) => (
      //   <><JdActionModal record={record}  poc={poc}
      //   roundAgency={roundAgency}
      //     orgType={orgType}
      //     jobLocations={jobLocn}
      //     isOpen={showUpdateModal}/></>
      // )
      // break;

      default:
        header = <div>{item.title}</div>;
    }
   
    if (render) {
      return {
        title: <span style={{fontWeight:"bold",fontSize:"0.9rem"}}>{header}</span>,
        key: item.key,
        dataIndex: item.key,
        render: render,
        width: 300,
      };
    } else {
      return {
        title: <span style={{fontWeight:"bold",fontSize:"0.9rem"}}>{header}</span>,
        key: item.key,
        dataIndex: item.key,
        width: 230,
      };
    }
  });

  const agencyCol=column.filter((item)=>item.key==='title'||item.key==='vacancies'||item.key==='spoc'||item.key==='vacancy'||item.key==='locations'||item.key==='action')


  const customPopover = (type = null, data = null, logo) => {
    let contentBody;

    let handler;

    switch (type) {
      case "sort":
        contentBody = (
          <div className="sort-popover">
            <div>
              {" "}
              <span className="sort-heading-css">Sort By</span>{" "}
              <span className="float-right">Reset</span>
            </div>

            <div>
              <div className="sorting-radio-container">
                <Title style={{ fontSize: "15px", display: "inline" }}>
                  ASCENDING
                </Title>{" "}
                <Radio
                  value={true}
                  style={{ float: "right" }}
                  checked={sortType}
                  onClick={() => {
                    setSortType(true);
                  }}
                ></Radio>
              </div>
              <div>
                <Title style={{ fontSize: "15px", display: "inline" }}>
                  DESCENDING
                </Title>
                <Radio
                  value={false}
                  style={{ float: "right" }}
                  checked={!sortType}
                  onClick={() => {
                    setSortType(false);
                  }}
                ></Radio>
              </div>
            </div>

            <ul className="sorting-list-css">
              {sortFieldList.map((item, index) => (
                <li
                  key={index}
                  className={item.selected ? "select-sorting-menu" : ""}
                  onClick={() => {
                    let temp = sortFieldList.slice();
                    temp.map((e, i) => {
                      if (index == i) {
                        e.selected = true;
                      } else {
                        e.selected = false;
                      }
                    });
                    
                    setSortOrderlist(temp);
                  }}
                >
                  {item.label}{" "}
                  <span className="float-right">
                    {" "}
                    <span className={!item.selected ? "check-icon" : ""}>
                      <CheckCircleTwoTone />
                    </span>
                  </span>
                </li>
              ))}
            </ul>

            <div className="sort-action">
              <Button
                className="cancel"
                onClick={() => setSortPopoverFlag(false)}
              >
                CANCEL
              </Button>
              <Button
                className="float-right ok"
                onClick={() => {
                  filterJob()
                  setSortPopoverFlag(false);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={sortPopoverFlag}
            onVisibleChange={() => {
              if (sortPopoverFlag) {
                setSortPopoverFlag(false);
              } else {
                setSortPopoverFlag(true);
              }
            }}
          >
            {logo}
          </Popover>
        );

        break;
      case "filter-details":
        contentBody = (
          <div className="sort-popover">
            <div>
              {" "}
              <span className="sort-heading-css">ALL Filter(s)</span>
              <Button
                type="text"
                size="large"
                className="float-right"
                onClick={()=>{
                resetFilter();
                setPrioritydata(priorityData.map(item=>({...item,selected:false})));
                setOrgTypeData(orgTypeData.map(item=>({...item,selected:false})));
                setJobLocationData(jobLocationData.map(item=>({...item,selected:false})));
                setDepartmentData(departmentData.map(item=>({...item,selected:false})));
                }}
              >
                Reset
              </Button>
            </div>
            <List
              dataSource={activeFilterList()}
              renderItem={(item) => (
                <List.Item key={item.title}>
                  <List.Item.Meta
                    title={<span>{item.title}</span>}
                    description={<span>{item.discription}</span>}
                  />
                  <div>
                    {/* <CloseOutlined /> */}
                  </div>
                </List.Item>
              )}
            />

            <div className="sort-action">
              <Button
                className="cancel"
                onClick={() => {
                  setFilterDetailsFlag(false);
                }}
              >
                CANCEL
              </Button>
              <Button
                className="float-right ok"
                onClick={() => {
                  setFilterDetailsFlag(false);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={filterDetailsFlag}
            onVisibleChange={() => {
              if (filterDetailsFlag) {
                setFilterDetailsFlag(false);
              } else {
                setFilterDetailsFlag(true);
              }
            }}
          >
            {logo}
          </Popover>
        );

        // <ul className="sorting-list-css">
        //   {sortFieldList.map((item, index) => (
        //     <li
        //       key={index}
        //       className={item.selected ? "select-sorting-menu" : ""}
        //       onClick={() => {
        //         let temp = sortFieldList.slice();
        //         temp[index].selected = !temp[index].selected;
        //         setSortOrderlist(temp);
        //       }}
        //     >
        //       {item.label}{" "}
        //       <span className="float-right">
        //         {" "}
        //         <span className={!item.selected ? "check-icon" : ""}>
        //           <CheckCircleTwoTone />
        //         </span>
        //       </span>
        //     </li>
        //   ))}
        // </ul>

        break;
      case "filter":
        break;
      default:
        contentBody = (
          <div className="sort-popover">
            <div>
              {" "}
              <span className="sort-heading-css">Sort By</span>{" "}
              <span className="float-right"></span>
            </div>

            <ul className="sorting-list-css">
              {sortFieldList.map((item, index) => (
                <li
                  key={index}
                  className={item.selected ? "select-sorting-menu" : ""}
                  onClick={() => {
                    let temp = sortFieldList.slice();
                    temp[index].selected = !temp[index].selected;
                    setSortOrderlist(temp);
                  }}
                >
                  {item.label}{" "}
                  <span className="float-right">
                    {" "}
                    <span className={!item.selected ? "check-icon" : ""}>
                      <CheckCircleTwoTone />
                    </span>
                  </span>
                </li>
              ))}
            </ul>

            <div className="sort-action">
              <Button
                className="cancel"
                onClick={() =>
                  setCustomPopoverVisible({
                    ...isCustomPopoverVisible,
                    sortDetails: true,
                  })
                }
              >
                CANCEL
              </Button>
              <Button className="float-right ok">OK</Button>
            </div>
          </div>
        );

        return (
          <Popover
            placement="bottom"
            content={contentBody}
            trigger="hover"
            visible={isCustomPopoverVisible.sortingFields}
            onVisibleChange={() => toggleSortingPopover(null)}
          >
            {logo}
          </Popover>
        );

        break;
    }
  };

  useEffect(()=>{
    if(orgType?.length>0)
    {
      setOrgTypeData(orgType.map(item=>({...item,selected:false})))
    }
  },
  [orgType?.length>0])
  const data = () => {
    let list = [];
    jdStore.jdList.map((item) => {
      list.push(item);
    });
    return list;
  };


  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const toggleCollegeDataImportModal = () => {
    setDataImportModal(!isDataImportModalVisible);
  };

  const DeleteApi = (values, type) => {
    // setBtnLoader(true);
  

    if (values.length === 0) {
      let text = "please select JD";
      return;
    }
    const deleteValues = { ids: values };
    Api.delete("/hiring-job/deletejob")
      .params(deleteValues)
      .send((response) => {

        if (response && response.show && response.show.type === "success") {
     
          jdQuery();
        }
      });
  };

  const toggleUpdateModal = (record) => {

    if (!showUpdateModal) {
      setUpdateObject(record);
    }
    setUpdateModal(!showUpdateModal);
  };

  const jdQuery = (requestBody) => {
    let list = [];
    let obj={}
    if(searchStr===""){
     obj={
      page:String(pageChange),
      limit:String(pageSize)
    }
  }
    if (!requestBody) {
      requestBody = {
        
        search: searchStr,
        status: tabStatus,
        jobExperience: "Fresher",
        
        
      };
    }
    setTableLoader(true);
    Api.post("/hiring-job/AllJobs")
      .params(requestBody)
     
      .send((res,error) => {
        
      //   if(error){
      //   let value=error?.data?.message.split(",")
      //  Notification.error(value[0],"")
      //   }
        // setIsSortLoader(false)

   
        if (!res) {
          setTableLoader(false);
          return;
        }
        if(filterKey.job.id===''&&searchStr===''){
          setInitialAgencyData(res.jobs);
         
        }

        res?.jobs?.map((item) => {
          item["key"] = item.id;
          list.push(item);
        });
        setResData(res)
        dispatch(jdFresherActions.updateJdList({ items: list }));
        dispatch(jdFresherActions.updateInfo({ meta: res?.meta }));
        dispatch(jdFresherActions.updateAllColumns(res?.columns));
        setJobLocn(res?.jobLocations);
        setDept(res?.departments);
        setorgType(res?.orgType?.map(item=>({...item,selected:false})));
        setPoc(res?.poc);
        setItemList(res?.columns);
        setRoundAgency({
          roundType: res?.roundType,
          feedbackQuestion: res?.feedbackQuestion,
        });
        if (selectCol.length===0) {
          dispatch(
            jdFresherActions.updateFresherMappedColumn(res?.mappedcolumns)
          );
        }
        setTableLoader(false);
      });
  };

  const handlePaginationChange = (e) => {

    setPageChange(e);
  };

  const onSearch = (event) => {
    setSearchStr(event.target.value);
  };

  const setDeleteConfirmation = (value) => {

    if (value) {
      DeleteApi(selectedRow, "multiple");
      setWaningvisibility(false);
    } else {
      setWaningvisibility(false);
    }
  };

  const logoutHAndler = () => {
    dispatch(authActions.removeUser());
    window.location.replace("/");
  };

  

  return (
    <div style={{display:"flex",flexDirection:"column",padding:"1.5% 2% 0 2%",}}>
      <div style={{display:"flex",flexDirection:"column",}}>
        <Row>      
            <Title
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Job Description Management{" "}
            </Title>
        </Row>
        
        {selectedRow.length === 0  ? (
          <div style={{display:"flex",flexDirection:"row",gap:"5%",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
           
              <div className={classes.searchDiv}>         
                    <Input    
                      allowClear={true}        
                      size="medium"
                      style={{width:"18rem",}}
                      placeholder="Search"
                      prefix={<SearchOutlined className="custom-search-icon" />}
                      onChange={onSearch}
                    />
              </div> 
           

     
                    <div
                      style={{ display: "flex", flexDirection: "row",justifyContent:"right",alignItems:"right",gap:"1rem",}}
                    >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "1rem",
                                      flex: "1 1 100%",
                                      alignItems: "center",                                   
                                      justifyContent:"right",
                                    }}
                                  >
                                    <div className="head-icon">
                                      <DragAndDrop
                                        itemList={itemList}
                                        setItemList={setItemList}
                                        selectCol={selectCol}
                                        setSelectCol={setSelectCol}
                                        tableType={tableType}
                                      />
                                    </div>
                                    <div className="head-icon">
                                      {/* {customPopover("sort", null, <img src={sort} onClick={()=>{setSortPopoverFlag(false)}} />)} */}
                                      <Sorter
                                        tableType={tableType}
                                        sortType={sortType}
                                        setSortType={setSortType}
                                        getFunc={resetFilter}                                     
                                        sortFieldList={sortFieldList}
                                        setSortOrderlist={setSortOrderlist}
                                        sortCandidate={filterJob}
                                      />
                                    </div>
                                    <div className="head-icon">
                                      {customPopover(
                                        "filter-details",
                                        null,
                                        <img
                                          src={filter}
                                          onClick={() => {
                                            setSortPopoverFlag(false);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                      <img
                                        src={download}
                                        style={{ height: "2rem",marginTop:"0.2rem", cursor: "pointer" }}
                                        onClick={() => {
                                          Api.post("/hiring-job/AllJobs")
                                             .params({
                                                download: true,
                                                mappedcolumns: fresherColumn,
                                              ...requestBodyData
                                             })
                                             .send((response) => {
                                               if (typeof response != "undefined") {
                                                 window.open(response, "_blank")?.focus();
                                               }
                                             });
                                        }}
                                      />
                                  </div>

                                  <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
                                  {user.role?.includes("Admin") || user?.role.includes("Super-Admin") ?
                                          <Button
                                          disabled={tableLoader}
                                            key="1"
                                            size="middle"
                                            type="primary"
                                            onClick={toggleModal}
                                            style={{
                                              background: "#0086ff",
                                              fontWeight: "700",                                  
                                              width: "9rem",                                   
                                              color: "#ffffff",
                                            }}
                                          >
                                            Create JD
                                          </Button>:""}
                                  </div>
                    </div>
            
          </div>
        ) : (
          <div style={{display:"flex",justifyContent:"right",alignItems:"center",}}>          
              <Button type="primary" size="middle" key="1" style={{ background: "#0086ff",color:"#ffffff",width:"9rem",fontWeight:"700" }}                          
                    onClick={() => {
                      Api.patch("/hiring-job/updateStatus")
                        .params({
                          jobIds:selectedRow,
                          status: Status,
                        })
                        .send((resp) => {
                          if (resp) {                         
                            setSelectedRow([])                                                                            
                            jdQuery();
                          } 
                        });                  
                    }}
                  >
                    {Status ==="Inactive" ? "Inactive" : "Active"}
              </Button>        
          </div>
        )}
      </div>
      {/* { user?.role.includes('Recruiter') || user?.role.includes('Sourcer') ?  */}
      
      <div>
        <Tabs
          activeKey={tabStatus}
          onChange={(key) => {
            if (key === "Active") {
              setStatus("Inactive");
            } 
            else if (key === "Inactive") {
              setStatus("Active");
            }
            setPrioritydata(priorityData.map(item=>({...item,selected:false})));
            setOrgTypeData(orgTypeData.map(item=>({...item,selected:false})));
            setJobLocationData(jobLocationData.map(item=>({...item,selected:false})));
            setDepartmentData(departmentData.map(item=>({...item,selected:false})));
            setPageChange(1)
            dispatch(jdFresherActions.updateTabState(key));
            setSortOrderlist(sortFieldList.map(item=>({...item,selected:false})));
            resetFilter();
            setSelectCol("");
          }}
        >
          <TabPane disabled={selectedRow.length === 0 ? false : true}
            tab={
              <span style={{fontWeight:"700"}}>
                Active
              </span>
            }
            key="Active"
          >
           {tabStatus==="Active"&&<Table
            key={tabStatus}
              columns={column}
              dataSource={data()}
              loading={tableLoader}
              rowSelection={ (user?.role?.includes("Admin") || user?.role?.includes("Super-Admin"))?rowSelection:null}
              scroll={{ y: 450 }}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: [10, 20,30, 50, 100],
                pageSize: jdStore.info.itemPerPage,
                total: jdStore.info.totalItems,
                onShowSizeChange: handlePageChange,
                onChange: handlePaginationChange,
              }}
              style={{fontWeight:"600",fontSize:"15px"}}
              locale={{ emptyText: "No JD Found" }}
            />}
          </TabPane>

          <TabPane disabled={selectedRow.length === 0 ? false : true}
            tab={
              <span style={{fontWeight:"700"}}>
                Inactive
              </span>
            }
            key="Inactive"
          >
            {tabStatus==="Inactive"&&<Table
              columns={column}
              key={tabStatus}
              dataSource={data()}
              
              loading={tableLoader}
              rowSelection={ (user?.role?.includes("Admin") || user?.role?.includes("Super-Admin"))?rowSelection:null}
              scroll={{ y: 450 }}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: [10,20, 30, 50, 100],
                pageSize: jdStore.info.itemPerPage,
                total: jdStore.info.totalItems,
                onShowSizeChange: handlePageChange,
                onChange: handlePaginationChange,
              }}
              locale={{ emptyText: "No JD Found" }}
            />}
          </TabPane>
        </Tabs>
      </div>
      {/* } */}
      <div>
        {isModalVisible  && (
          <CreateJDModal
            dept={dept}
            poc={poc}
            roundAgency={roundAgency}
            orgType={orgType}
            jobLocations={jobLocn}
            isOpen={isModalVisible}
            toggle={toggleModal.bind(this)}
            tableLoader={tableLoader}
            refresh={jdQuery.bind(this)}
          />
        )}

        {showUpdateModal && (
          <CreateJDModal
            dept={dept}
            poc={poc}
            roundAgency={roundAgency}
            orgType={orgType}
            jobLocations={jobLocn}
            isOpen={showUpdateModal}
            toggle={toggleUpdateModal.bind(this)}
            tableLoader={tableLoader}
            // setTableLoader={setBtnLoader}
            selectedObject={selectedUpdateObject}
            refresh={jdQuery.bind(this)}
          />
        )}
        <Warning
          title="Warning"
          visible={isWaningModalVisible}
          confirmationRequst={setDeleteConfirmation.bind(this)}
          okText="Yes"
          onCancel="No"
          subject=""
        />
        {titleModal&&<JobTitleModal agencyDetails={agencyDetails}/>}
      </div>
    </div>
  );
}

export default JdDashboardFresher;