import React, { useEffect, useState } from "react";
import classes from './MainPage.module.css';
import { DatePicker, Select, Spin } from 'antd';
import StoreGraph from "./StoreGraph/StoreGraph";
import EngagementGraph from "./EngagementGraph/EngagementGraph";
import { Api } from "services";
import moment from "moment";
const { RangePicker } = DatePicker;

const MainPage=()=>{
    const [date,setDate]=useState({start:"",end:""
    });
    const[dateVal,setDateVal]=useState(null);
    const [viewType,setViewType]=useState("Week");
    const [data,setData]=useState();
    const [loader,setLoader]=useState(false);

    function getKeysInOrder(obj) {
    const keys = [];
  
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
      }
    }
  
    return keys;
  }
   

    useEffect(()=>{
        const today = new Date();

        // Subtract 7 days
        const resultDateObject = new Date(today);
        resultDateObject.setDate(today.getDate() - 7);
    
        // Format the result in YYYY-MM-DD
        const resultFormatted = resultDateObject.toISOString().split('T')[0];
        setDate({start:resultFormatted,end:moment(new Date())?.format('YYYY-MM-DD')})
    },[])
    const playStoreBar={
        graphTitle:"Google Playstore",
        bar1:{title:"Store visits",value:"48",bg:"#FD9644"},
        bar2:{title:"App downloads",value:"32",bg:"#FED330"},
    }
    const appStoreBar={
        graphTitle:"IOS App Store",
        bar1:{title:"Store visits",value:"48",bg:"#FD9644"},
        bar2:{title:"App downloads",value:"32",bg:"#FED330"},
    }
    const userGrowthBar={
        graphTitle:"User Growth and Loss",
        bar1:{title:"Registered account",value:"48",bg:"#1089FF"},
        bar2:{title:"Deleted account",value:"32",bg:"#6BAFFF"},
    }
    const activeUserBar={
        graphTitle:"Active Users",
        bar1:{title:"Candidates",value:"48",bg:"#06539E"},
        bar2:{title:"Associates",value:"32",bg:"#1089FF"},
        bar3:{title:"Guests",value:"32",bg:"#6BAFFF"},
    }
    const sessionOverview_Bar={
        graphTitle:"Session Overview",
        bar1:{title:"Overview in hours",value:"48",bg:"#1089FF"},
    }
    const store_yAxis=[200,150,100,50,0];
    const userGrowth_yAxis=[800,400,200,100,0];
    // const activeUser_yAxis=[800,400,200,100,0];
    const growth_xAxis=data?.statsByGrowthAndLoss;
    let store_xAxis
    // =
    // [
    //    { x:"01/01",bar:[60,70]},
    //    { x:"02/01",bar:[60,70]},
    //    { x:"03/01",bar:[60,30]},
    //    { x:"04/01",bar:[50,10]},
    //    { x:"05/01",bar:[60,0]},
    //    { x:"06/01",bar:[60,70]},
    //    { x:"07/01",bar:[60,20]},
    //    { x:"08/01",bar:[20,70]},
    //    { x:"09/01",bar:[60,70]},
    //    { x:"10/01",bar:[60,70]},
    //    { x:"11/01",bar:[60,70]},
    //    { x:"12/01",bar:[50,60]},
    //    { x:"13/01",bar:[60,70]},
    //    { x:"14/01",bar:[60,70]},
    //    { x:"15/01",bar:[60,70]},
    //    { x:"16/01",bar:[60,70]},
    //    { x:"17/01",bar:[80,50]},
    //    { x:"18/01",bar:[60,70]},
    //    { x:"19/01",bar:[60,70]},
    //    { x:"20/01",bar:[60,70]},
    //    { x:"21/01",bar:[100,70]},
    //    { x:"22/01",bar:[40,70]},
    //         ]
   
            if(growth_xAxis)
            {
                store_xAxis=(Object.keys(growth_xAxis))?.sort()?.map((item,index)=>{
                    let val1=growth_xAxis[item]?.registered*1/8;
                    let val2=growth_xAxis[item]?.deleted*1/8;
                    return({x:item,bar:[growth_xAxis[item]?.registered,growth_xAxis[item]?.deleted]})
                })

            }
            

            let sessionOverview_xAxis=[
                { x:"01/01",bar:[60]},
                { x:"02/01",bar:[60]},
                { x:"03/01",bar:[60]},
                { x:"04/01",bar:[50]},
                { x:"05/01",bar:[60]},
                { x:"06/01",bar:[60]},
                { x:"07/01",bar:[60]},
                { x:"08/01",bar:[20]},
                { x:"09/01",bar:[60]},
                { x:"10/01",bar:[60]},
                { x:"11/01",bar:[60]},
                { x:"12/01",bar:[50]},
                { x:"13/01",bar:[60]},
                { x:"14/01",bar:[60]},
                { x:"15/01",bar:[60]},
                { x:"16/01",bar:[60]},
                { x:"17/01",bar:[80]},
                { x:"18/01",bar:[60]},
                { x:"19/01",bar:[60]},
                { x:"20/01",bar:[60]},
                { x:"21/01",bar:[100]},
                { x:"22/01",bar:[40]},
                     ]
                     if(data?.appUsageData)
                     {
                        sessionOverview_xAxis=(Object.keys(data?.appUsageData))?.sort()?.map((item,index)=>{
                            return({
                                x:item,
                                bar:[data?.appUsageData?.[item]?data?.appUsageData?.[item]:0]
                            })
                         })
                     }
                   

    let activeUser_xAxis
    // =[
    //             { x:"01/01",bar:[60,70,30]},
    //             { x:"02/01",bar:[60,70,20]},
    //             { x:"03/01",bar:[60,30,0]},
    //             { x:"04/01",bar:[50,10,40]},
    //             { x:"05/01",bar:[60,0,80]},
    //             { x:"06/01",bar:[60,70,90]},
    //             { x:"07/01",bar:[60,20,30]},
    //             { x:"08/01",bar:[20,70,50]},
    //             { x:"09/01",bar:[60,70,10]},
    //             { x:"10/01",bar:[60,70,40]},
    //             { x:"11/01",bar:[60,70,70]},
    //             { x:"12/01",bar:[50,60,80]},
    //             { x:"13/01",bar:[60,70,80]},
    //             { x:"14/01",bar:[60,70,50]},
    //             { x:"15/01",bar:[60,70,20]},
    //             { x:"16/01",bar:[60,70,30]},
    //             { x:"17/01",bar:[80,50,15]},
    //             { x:"18/01",bar:[60,70,75]},
    //             { x:"19/01",bar:[60,70,30]},
    //             { x:"20/01",bar:[60,70,70]},
    //             { x:"21/01",bar:[100,70,78]},
    //             { x:"22/01",bar:[40,70,50]},
    //          ]

    // console.log("rrrrrraaaajjjjjuuuuu",Object.keys(data?.statsByUserRole))
             if(data?.statsByUserRole){
                activeUser_xAxis=(Object.keys(data?.statsByUserRole))?.sort()?.map((item,index)=>{
                    let val1=data?.statsByUserRole[item]?.Candidate?data?.statsByUserRole[item]?.Candidate:0;
                    let val2=data?.statsByUserRole[item]?.Associate?data?.statsByUserRole[item]?.Associate:0;
                    let val3=data?.statsByUserRole[item]?.Guest?data?.statsByUserRole[item]?.Guest:0;
                    return({x:item,bar:[val1,val2,val3]})
                })
             }
            
    useEffect(()=>{
        console.log("viewTYYYYPE",viewType)
        setLoader(true);
        if(viewType&&date?.end)
        {
            let url;
            if(viewType==="Week")
            {
                url=`/auth-auth/stats?viewType=${viewType}&startDate=${date?.start}&endDate=${date?.end}`
            }
           else if(viewType==="Month")
            {
                url=`/auth-auth/stats?viewType=${viewType}&queryMonth=${date?.end?.split('-')?.[1]}&queryYear=${date?.end?.split('-')?.[0]}`
            }
           else if(viewType==="Year")
           {
              url=`/auth-auth/stats?viewType=${viewType}&queryYear=${date?.end?.split('-')?.[0]}`
           }
               
            
            Api.get(url).send(res=>{
                setData(res);
                setLoader(false);
            })
        }  
    },[date,viewType]);
    return(
        <div className={classes.mainpage}>
            <section className={classes.header}>
                <div>
                    <span>App Insights</span>
                    <span>{viewType==="Week"?"Displaying last 7 days data against the previous 7 days data.":viewType==="Month"?"Displaying 1 month's data against the previous 1 month's data.":"Displaying  1 year's data against the previous 1 year's  data."}</span>
                </div>
                <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
                    <Select style={{width:"10vw"}} placeholder="Select view type" value={viewType?viewType:undefined}
                    onChange={(e)=>{
                        setViewType(e);
                        if(e==="Year")
                        {
                            setDate({start:"",end:moment().format('YYYY')})
                            setDateVal(moment(new Date()))
                        }
                        else if (e==="Month")
                        {
                            setDate({start:"",end:moment().format('YYYY-MM')})
                            setDateVal(moment(new Date()))
                        }
                        else if (e==="Week")
                        {
                            setDateVal(moment(new Date()))
                            const today = new Date();
                           

                                // Subtract 7 days
                                const resultDateObject = new Date(today);
                                resultDateObject.setDate(today.getDate() - 7);
                            
                                // Format the result in YYYY-MM-DD
                                const resultFormatted = resultDateObject.toISOString().split('T')[0];
                                setDate({start:resultFormatted,end:moment(new Date())?.format('YYYY-MM-DD')})
                        }
                    }}
                    >
                        {["Week","Month","Year"]?.map((item,index)=>{
                            return(
                                <Select.Option key={index} value={item}>{item}</Select.Option>
                            )
                        })}
                    </Select>
                    {console.log("daaaaatmnn",dateVal)}
                {viewType==="Month"?
                <DatePicker
                value={dateVal}
               
                picker="month"
                // placeholder={`${moment(new Date())?.format('YYYY-MM')}`}
                onChange={(date,datestring)=>{
                    setDateVal(date);
                    if(datestring)
                    {
                        setDate({start:"",end:datestring})
                    }
                    else
                    {
                        setDate({start:"",end:""});
                    }
                }}
                />:
                viewType==="Year"?
                <DatePicker picker="year"
                value={dateVal}
                // placeholder={`${moment(new Date())?.format('YYYY')}`}
                onChange={(date,datestring)=>{
                    setDateVal(date);
                    if(datestring)
                    {
                        setDate({start:"",end:datestring})
                    }
                    else
                    {
                        setDate({start:"",end:""});
                    }
                }}
                />
                :<RangePicker
                allowClear={false}
                value={[moment(date?.start,'YYYY-MM-DD'),moment(date?.end,'YYYY-MM-DD')]}
                 onChange={(date,datestring)=>{
                    setDateVal(date);
                    if(datestring)
                    {
                        setDate({start:datestring?.[0],end:datestring?.[1]})
                    }
                    else
                    {
                        setDate({start:"",end:""});
                    }
                }}/> }
                </div>             
            </section>

            {/* <section className={classes.store}>
                <div>
                    <div>
                        <div>
                            <span></span>
                            <span>101</span>
                        </div>
                        <span>Total number of store visits</span>
                        <span>Playstore and IOS App Store</span>
                        <span>-12%</span>
                    </div>
                    <div>
                        <div>
                            <span></span>
                            <span>90</span>
                        </div>
                        <span>Total app downloads</span>
                        <span>90.9% conversion rate</span>
                        <span>+24%</span>
                    </div>
                </div>
                
                <div className={classes.playStore}>
                    <StoreGraph bar={playStoreBar} xAxis={store_xAxis} yAxis={store_yAxis}/>

                </div>
                <div className={classes.appStore}>
                <StoreGraph bar={appStoreBar}  xAxis={store_xAxis} yAxis={store_yAxis}/>
                </div>
            </section> */}
            {loader?<Spin style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50vh"}}/>:<>
            <section className={classes.users}>       
                <div className={classes.playStore}>
                    <StoreGraph bar={userGrowthBar} xAxis={store_xAxis} yAxis={userGrowth_yAxis}/>
                </div>
                <div>
                    <div>
                        <div>
                            <span style={{backgroundColor:"#1089FF"}}></span>
                            <span>{data?.newRegistrations}</span>
                        </div>
                        <span>Total registered account</span>
                        <span>{data?.differenceInRegistrations?.differenceValue} difference</span>
                        <span style={{color:parseFloat(data?.differenceInRegistrations?.percentage)>0&&data?.differenceInRegistrations?.percentage!=="+Infinity"?"#26DE81":"#FC5C65"}}>{data?.differenceInRegistrations?.percentage==="+Infinity"||data?.differenceInRegistrations?.percentage===null?"inadequate data":parseFloat(data?.differenceInRegistrations?.percentage)?.toFixed(2)+"%"}</span>
                    </div>
                    <div>
                        <div>
                            <span  style={{backgroundColor:"#6BAFFF"}}></span>
                            <span>{data?.deletions}</span>
                        </div>
                        <span>Total deleted account</span>
                        <span>{data?.differenceInDeletions?.differenceValue} difference</span>
                        <span style={{color:parseFloat(data?.differenceInDeletions?.percentage)>0&&data?.differenceInDeletions?.percentage!=="+Infinity"?"#26DE81":"#FC5C65"}}>{data?.differenceInDeletions?.percentage==="+Infinity"||data?.differenceInDeletions?.percentage===null?"inadequate data":parseFloat(data?.differenceInDeletions?.percentage)?.toFixed(2)+"%"}</span>
                    </div>
                </div>

                <div className={classes.appStore}>
                <StoreGraph bar={activeUserBar}  xAxis={activeUser_xAxis} yAxis={userGrowth_yAxis}/>
                </div>

            </section>

            <section className={classes.overview}>
                <div>
                    <div>
                        <div>
                            <span>{data?.totalInAppTime }</span>
                            <span>hrs</span>
                        </div>
                        <span>Total time in app</span>
                        <span>{data?.differenceInAppUsage__TOTAL_TIME?.differenceValueInTotalTime} hrs difference</span>
                        <span  style={{color:parseFloat(data?.differenceInAppUsage__TOTAL_TIME?.percentageForTotalTime)>0&&data?.differenceInAppUsage__TOTAL_TIME?.percentageForTotalTime!=="+Infinity"?"#26DE81":"#FC5C65"}}>{data?.differenceInAppUsage__TOTAL_TIME?.percentageForTotalTime==="+Infinity"||data?.differenceInAppUsage__TOTAL_TIME?.percentageForTotalTime===null?"inadequate data":parseFloat(data?.differenceInAppUsage__TOTAL_TIME?.percentageForTotalTime)?.toFixed(2)+"%"}</span>
                    </div>
                    <div>
                        <div>
                            <span>{data?.averageInAppTime}</span>
                            <span>mins</span>
                        </div>
                        <span>Average time in app</span>
                        <span>{data?.differenceInAppUsage__AVERAGE_TIME?.differenceValueAverageTime} mins difference</span>
                        <span style={{color:parseFloat(data?.differenceInAppUsage__AVERAGE_TIME)>0&&data?.differenceInAppUsage__AVERAGE_TIME?.percentageForAverageTime!=="+Infinity"?"#26DE81":"#FC5C65"}}>{data?.differenceInAppUsage__AVERAGE_TIME?.percentageForAverageTime==="+Infinity"||data?.differenceInAppUsage__AVERAGE_TIME?.percentageForAverageTime===null?"inadequate data":parseFloat(data?.differenceInAppUsage__AVERAGE_TIME?.percentageForAverageTime)?.toFixed(2)+"%"}</span>
                    </div>
                </div>
                
                <div className={classes.playStore}>
                    <StoreGraph bar={sessionOverview_Bar} xAxis={sessionOverview_xAxis} yAxis={store_yAxis}/>

                </div>
                <div className={classes.appStore}>
                {/* <StoreGraph bar={appStoreBar}  xAxis={store_xAxis} yAxis={store_yAxis}/> */}
                <EngagementGraph data={data?.engagementDetails}/>
                </div>
            </section>
            </>}

        </div>
    )

}
export default MainPage;