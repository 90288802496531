import {
    Modal,
    Row,
    Col,
    Typography,
    Checkbox,
    Card,
    Button,
    Spin
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState, useEffect, useRef } from "react";
import Api from "../../services/Api";
import "./style.css";
import Notification from "services/Notification";
import EmailIcon from "../../assets/emailTemp.png"
import { useDispatch, useSelector } from "react-redux";
import { templateActions } from "pages/email-template/store";
const { Title } = Typography;
function TemplateModal(props) {
    const dispatch = useDispatch();
    const [selectTemplate,setTemplate] = useState();
    const [loader,setLoader]=useState(false);
    const templates = useSelector(state => state.templates.list);
    const sendEmail = () => {
        let requestbody = {
            college_jobIds: props.selectedData,
            templateID: selectTemplate
        }
        Api.post('/hiring-college/sendMailColleges', requestbody)
        .send(res  => {           
            if(res && res.type == "success"){
                Notification.success(res.message);
                props.toggle();
                props.refresh()
            } 
            else 
            {              
            }
            props.setSelectedRow([])
        })
    }
    useEffect(()=>{
        setLoader(true);
        Api.get('/hiring-template/all').params({search :""}).send(res => {         
            if(res)
            {                         
                 dispatch(templateActions.updateTemplates(res))
                 setLoader(false);
            }
         })
    },[])
    const templateCard = (templateObj) => {
        return <Col span={7} style={{margin : "10px" }} key ={templateObj.id}>
        <Card 
        className={selectTemplate == templateObj.id ? "selected_template custom-card" : "custom-card"}
        onClick={()=>{setTemplate(templateObj.id)}} 
        >
          <div style={{ textAlign: "center" }} className="custom-card-bg">
            <img src={EmailIcon} style={{ height : "140px" }}/>
          </div>
          <div  className="cursor-pointer custom-card-footer">
                <div>
                  <h5>{templateObj.subject}</h5>
                </div>
                <div className="card-subtitle">
                  To: {templateObj.type}
                </div>
            </div>
        </Card></Col>
    }
    return (
        <Modal
            width={1000}
            visible={props.isOpen}
            onCancel={() => {
                props.toggle();
            }}
            footer={false}
        >        
            <Title
                style={{
                    textAlign: "center",
                    marginBottom: "10px",
                    font: "normal normal bold 24px/33px Open Sans",
                    color: "#161616",
                }}
                level={3}
            >
                Select Template
        </Title>
            {loader?<div style={{display:"flex"}}><Spin style={{margin:"5rem auto",}}></Spin></div>: <div>
                            <div className="template-list-scroller">
                                {
                                    templates && templates.length > 0 ?
                                        <Row>
                                            {templates.map((item, index) => 
                                            {   if(item.type==="Institution"){
                                                return templateCard(item)}
                                                else{
                                                    return ""
                                                }
                                            })
                                        }
                                        </Row> : ""
                                }
                            </div>
                            <div style={{display : "flow-root"}}>
                                <Button type="primary" style={{
                                    float: "right",
                                    margin : "10px"
                                }}
                                onClick={sendEmail}
                                >Send</Button>
                            </div>
            </div>}
        </Modal>
    );
}
export default TemplateModal;
