import React from "react";
import { Row,Col,Modal,Select,Button,Typography } from "antd";
import { useEffect,useState } from "react";
import  classes from "./previousFeedback.module.css";
import { useSelector,useDispatch } from "react-redux";
import { feedbackQuesActions } from "./store";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
            return {
                    width,
                    height
            };
  }
  export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  }

const PreviousFeedback = (props) =>{
 
    const feedbacks=props?.feedbacks;
    const [feedbackVal,setFeedbackVal]=useState();
    const imageUrl =feedbackVal?.screenshot;
    const OpenFeedback=useSelector(state=>state?.feedback?.OpenFeedback);
    const dispatch=useDispatch();
    const [feedbackId,setFeedbackId]=useState("");
    const { height, width } = useWindowDimensions();  

return(
    <Modal visible={props?.openFeedback} title={<div style={{fontSize:width>700?"1.3rem":"1.1rem",marginTop:"1rem",textAlign:"center"}}>Previous Round Feedback</div>}
     footer={false}
     width={width>700?"40rem":"100%"} 
     centered 
    //  bodyStyle={{height:"22rem"}}
     closable={true} 
     maskClosable={true} 
     style={{borderRadius:"5px",}}
     onCancel={()=>props?.setOpenFeedback(false)}>
        <>
        <Row style={{ justifyContent: "flex-end" }}>
        <Select 
               // disabled={feedbacks?.length > 0 ? false : true}
                style={{width:"9.5rem",height:"2.5rem",marginTop:"0.05rem",border:"0086ff"}}
                placeholder="Select Round"
                allowClear={true}
                // value={feedbackId}
                onChange={(e) =>{
                    setFeedbackId(e)
                    let values={}
                    feedbacks.forEach((event)=>{
                      if(e===event.id){
                                                  
                        values=event
                       }
                        setFeedbackVal(values)
                    })}}
                >
                {feedbacks !== undefined && feedbacks?.map((item,index)=>{
                    return(
                        <Option value={item?.id} key={index}>Round {item?.round}</Option>
                    )})}
        </Select>
        </Row>
        {feedbackId === undefined || feedbackId === "" ?
        <div style={{fontSize:width>700?"1.2rem":"1rem",fontWeight:"bold",color:"#0086FF",textAlign:"center",margin:"4rem 0",width:"100%"}}>
          Select Round...
        </div>
        : <>{props?.roundType === "Managerial" ?
        <div className={classes.pageControl}>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Round Type : </span>
                <span style={{fontWeight:"600"}}>
                    {feedbackVal?.roundType === "Relationship" ? "HR Relationship":feedbackVal?.roundType}
                </span>
        </h4>
        </div>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Status : </span>
                <span style={{fontWeight:"600"}}>{feedbackVal?.status}</span>
        </h4>
        </div>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Overall Rating : </span>
                <span style={{fontWeight:"600"}}>{feedbackVal?.overallRating}</span>
        </h4>
        </div>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Overall Comment : </span>
                <span style={{fontWeight:"600"}}>
                <Typography.Text style={{width:"24rem"}} ellipsis={{tooltip:true}}>{feedbackVal?.overallComment}</Typography.Text>                               
                </span>
        </h4>
        </div>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Panelist Name : </span>
                <span style={{fontWeight:"600"}}>{feedbackVal?.interviewer?feedbackVal?.interviewer:"NA"}</span>
        </h4>
        </div>
        <div>            
            {feedbackVal?.details !== undefined&&feedbackVal?.details !== null&&Object.entries(feedbackVal?.details)?.map(([key, val,index]) => {
                
                return(
                <div style={{marginTop:"0.8rem"}}>
                
                <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.5rem"}}>{key} {" "}{" "}: </span>
                
                { key === "resume" ? 
                <Button style={{backgroundColor:"#0086FF1A",fontSize:"0.9rem",fontWeight:"600",width:"8rem",height:"2.3rem"}}
                onClick={() => {window.open(val)}}
                >
                 View a File
                </Button>
                :
                typeof val === "string" && (val.startsWith("http://") || val.startsWith("https://")) && val.split('.').pop().toLowerCase() === "pdf" ?
                                    <div style={{fontWeight:"600",marginTop:"0.3rem",cursor:"pointer",display:"flex",textAlign:"center",justifyContent:"center",marginLeft:"1.1rem",border:"1px solid #0086FF",padding:"0.5rem",width:"8rem"}}
                                    onClick={()=>{
                                    window.open(val, "_blank")?.focus();
                                    }}> View File </div>
                                    :
                                    typeof val === "string" && (val.startsWith("http://") || val.startsWith("https://")) && ["png", "jpg", "jpeg", "gif", "bmp"].includes(val.split('.').pop().toLowerCase()) ?
                                    <img src={val} alt="" style={{height:"5rem",width:"7rem",marginLeft:"0.1rem"}} />
                                    :
                <span style={{fontWeight:"600"}}>{val}</span>
                }
                </h4>
                </div>
                )
            })}
        </div>
        <div>  {console.log("ssSImage",feedbackVal?.screenshot)}
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.5rem"}}>Candidate Screenshot : </span>
                {feedbackVal?.screenshot === null ? "NA"
                :
                <img style={{width:"7rem",height:"5rem"}} src={feedbackVal?.screenshot}
                alt=''/>
                }
            </h4>
        </div>
        </div>
        :
        <div className={classes.pageControl}>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Status : </span>
                <span style={{fontWeight:"600"}}>{feedbackVal?.status}</span>
        </h4>
        </div>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Overall Rating : </span>
                <span style={{fontWeight:"600"}}>{feedbackVal?.overallRating}</span>
        </h4>
        </div>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Overall Comment : </span>
                <span style={{fontWeight:"600"}}>
                <Typography.Text style={{width:"24rem"}} ellipsis={{tooltip:true}}>{feedbackVal?.overallComment}</Typography.Text>                               
                </span>
        </h4>
        </div>
        <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.3rem"}}>Panelist Name : </span>
                <span style={{fontWeight:"600"}}>{feedbackVal?.interviewer?feedbackVal?.interviewer:"NA"}</span>
        </h4>
        </div>
       

        {/* <div>            
            { Object.entries(feedbackVal?.details)?.map(([key, val,index]) => {
                
                return(
                <div style={{marginTop:"0.8rem"}}>
                
                <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.5rem"}}>{key} {" "}{" "}: </span>
                
                { key === "resume" ? 
                <Button style={{backgroundColor:"#0086FF1A",fontSize:"0.9rem",fontWeight:"600",width:"8rem",height:"2.3rem"}}
                onClick={() => {window.open(val)}}
                >
                 View a File
                </Button>
                :
                <span style={{fontWeight:"600"}}>{val}</span>
                }
                </h4>
                
                </div>
                )
            })}
        </div> */}
        {/* <div>  
        <h4 style={{fontSize:"1.1rem",marginBottom:"0.8rem"}}>
                <span style={{color:"#08ff",marginRight:"0.5rem"}}>Candidate Screenshot: </span>
                <img style={{width:"40%",height:"40%"}} src={imageUrl}></img>
            </h4>
        </div> */}

        </div>
        }</>
        }
        </>
    </Modal>
   
)
}
export default PreviousFeedback;