class Filter{
    constructor(){
        this.vacancies=false
        this.location = false;
        this.priority = false;
        this.experience=false
        this.noticePeriod=false;
        this.departments=false;
        this.orgtype=false
        this.expmin = 0;
        this.expmax = 0;
        this.npmin=0;
        this.npmax=0;
        
    }

    setXMin(value){
        this.expmin = value
    }

    setXiiMin(value){
        this.expmax = value
    }

    
   

  
 

    resetFitler(){
        this.vacancies=false
        this.noticePeriod=false;
        this.location = false;
        this.priority = false;
        this.experience=false;
        this.departments=false;
        this.orgtype=false
        this.expmin = 0;
        this.expmax = 0;
        this.npmin=0;
        this.npmax=0;
       
    }

    resetFitlerOneByOne(type){
        switch(type){
            case "priority":
                this.priority = false;
            break;

            case "vacancies":
                this.vacancies = false;
            break;

            case "noticePeriod":
                this.noticePeriod = false;
            break;

            case "location":
                this.location = false;
            break;

            case "experience":
                this.experience = false;
            break;

            case "departments":
                this.departments = false;
            break;

            case "orgtype":
                this.orgtype = false;
            break;

            case "expmin":
                this.expmin = false;
            break;

            case "npmin":
                this.npmin = false;
            break;

            case "npmax":
                this.npmax = false;
            break;


            default:
                break;

        }          
    }
}

export default Filter;