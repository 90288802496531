import { PrimaryButton, SecondaryButton } from "./button";
import React from "react";
import { Empty, Spin,Typography } from "antd";
import moment from "moment";
import "./styles.css";
import { AiOutlineMail, BiPhone ,FiUsers} from "../services/reactIcons";
import { camelize } from "./input";

export const EmptyTable = ({text, onClick, buttonText}) => {
  return (
    <Empty className="no-data" description={<span>{text}</span>}>
      {onClick !== undefined ? (
        <SecondaryButton className="modalButton" onClick={onClick} text={`Add ${buttonText}`} />
      ) : (
        ""
      )}
    </Empty>
  );
};

export const timeDifference = (startTime, endTime) => {
  const startTimeModified = moment(startTime, "hh:mm a").format("HH:mm")
  const endTimeModified = moment(endTime, "hh:mm a").format("HH:mm")
  return moment.utc(moment(endTimeModified, "HH:mm").diff(moment(startTimeModified, "HH:mm"))).format("HH:mm")
};

export const userContent = (user) => {
  return (
    <div>
      <div>
        <FiUsers /> <Typography.Text ellipsis={{tooltip:true}} style={{width:"14rem"}}>{user !== null ? user.name : ""}</Typography.Text>
      </div>
      <div>
        <AiOutlineMail /> {user !== null ? user.email : ""}
      </div>
      <div>
        <BiPhone /> {user !== null ? user.phone : ""}
      </div>
    </div>
  );
};

export const modalContent = (key, value) => {
  return (
    <div style={{ display: "flex" }}>
      <div className="text-mid-key">{key}</div>
      <div className="text-mid-value">{value}</div>
    </div>
  );
};

export const handleDownload = (fileContent, fileName) => {
  const element = document.createElement("a");
  const file = new Blob([fileContent], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = `${camelize(fileName)}.txt`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const downloadPDF = (fileURL, name) => {
  const fileName = name ? name : "resume"
  fetch(fileURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  })
  .then((response) => response.blob())
  .then((blob) => {
    const element = document.createElement("a");
    const file = new Blob([blob], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${camelize(fileName)}.pdf`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  });
}
