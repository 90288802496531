import React from "react";
import { useEffect } from "react";
import { Table, Row, Col, Button, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { connect } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";

let jobId = "";
let jobTitle = "";

const { Title } = Typography;

const JobSummaryDetail = ({
  getJobSummary,
  jobSummaryLoader,
  jobSummaryProfiles,
}) => {
  const history = useHistory();
  
  useEffect(() => {
    jobId = history?.location?.state?.record?.jobId;
    jobTitle = history?.location?.state?.record?.jobTitle;
    const dateRange = history?.location?.state?.dates;

    if (jobId) {
      getJobSummary(jobId, dateRange);
    }
  }, []);



  const columns = [
    {
      title: "Agency Name",
      dataIndex: "agencyName",
      key: "agencyName",
    },

    {
      title: "Profiles Shared",
      dataIndex: "profilesShared",
      key: "profilesShared",
    },
    {
      title: "In Progress",
      dataIndex: "inProgress",
      key: "inProgress",
    },
    {
      title: "Action Pending",
      dataIndex: "actionPending",
      key: "actionPending",
    },
    {
      title: "Not Shortlisted",
      dataIndex: "notShortlisted",
      key: "notShortlisted",
    },
    {
      title: "Not Selected",
      dataIndex: "notSelected",
      key: "notSelected",
    },
  ];

  return (
    <div style={{padding:"2%"}}>
      <Row style={{ textAlign: "left",marginBottom:"1rem"}}>
        <Col xs={12}>
          <Row align="middle">
            <Button icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
              Go Back
            </Button>
            <Title  style={{ margin: 0, marginLeft: 8,fontWeight:"700",fontSize:"1.4rem",color:"#0085FF"}}>
              {jobTitle}
            </Title>
          </Row>
        </Col>
      </Row>
      <Row className="m-t-30">
        <Col flex="auto">
          <Table
            dataSource={jobSummaryProfiles}
            columns={columns}
            scroll={{ y: 360 }}
            loading={jobSummaryLoader}
            pagination={{
              position: "bottomRight",
              pageSize: 25,
              showSizeChanger: true,
              pageSizeOptions:[10,25, 30, 50, 100],
              size: "small", 
              total:jobSummaryProfiles?.length,
              showTotal: (total, count) =>
              `${count[0]}-${count[1]} of ${total} items`,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSummaryDetail);
