import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Modal, Row, Tooltip,Button,Select} from "antd";
import React from "react";
import { connect } from "react-redux";
import { PrimaryButton, SecondaryButton } from "services/button";
import { Color } from "services/color";
import { TextFormInput, CollapseTextFormInput } from "services/input";
import { agencyInfoFormat, agencyInputFieldType, agencyOperationType, agencyUsersInputFieldType, AGENCY_DETAILS, AGENCY_PRIMARY_CONTACT } from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";

class AgencyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addModal:false, selectedAgency:{} };
  }

  onFinish = () => {
    this.props.handleAgency();
    this.props.getAgencies();
    this.setState({selectedAgency: {}})
  };

  componentDidMount() {
    this.props.getUserDetails();
  }

  closeModal = () => {
    this.props.performOperation(agencyOperationType.AGENCY_DETAILS_MODAL, false);
    this.props.updateInputField(AGENCY_DETAILS, agencyInfoFormat);
    this.setState({addModal:false});
    this.setState({selectedAgency: {}})
  };

  render() {
    const {
      agencyDetailsModal,
      agencyDetailsLoader,
      updateInputField,
      agencyInfo,
      performOperation,
      isAddingAgency,
      agencyUserAdd,
      agencyUserDelete,
      updateAgencyUsersInputField,userDetailLists
    } = this.props;
    return (
      <Modal
        visible={agencyDetailsModal}
        title={isAddingAgency ? "Add Agency" : "Edit Agency"}
        color={Color.blue}
        centered
        onOk={this.onFinish}
        onCancel={this.closeModal}
        maskClosable={false}
        footer={
          // agencyInfo.agencyName!=="Brigosha Agency"?[
          // <SecondaryButton onClick={this.closeModal} id="discardAgency" text="Discard" className="modalButton" size="large" />,
         [<Button  onClick={this.closeModal} style={{width:"8rem",marginRight:"0.3rem"}}>Discard</Button>,
          <PrimaryButton
            htmlType="submit"
            loading={agencyDetailsLoader}
            onClick={this.onFinish}
            style={{height:"2rem",width:"8rem",marginBottom:"0.5rem",marginRight:"0.5rem"}}
            text={isAddingAgency ? "Add Agency" : "Save"}
            id={isAddingAgency ? "addAgency" : "saveAgency"}
            // className="modalButton"
            size="small"
          />,]
        // ]:false
      }
      >
        <Form id="agency-form" onFinish={this.onFinish} >
          <Row gutter={24}>
            <Col span={12}>
              <TextFormInput
               required={true}
                text="Agency Name"
                onChange={(record) => updateInputField(agencyInputFieldType.AGENCY_NAME, record.target.value.replace(/[^\w\s]/gi, ""))}
                value={agencyInfo.agencyName}
              />
            </Col>
            <Col span={12}>
              <TextFormInput
                text="Location"
                onChange={(record) => updateInputField(agencyInputFieldType.AGENCY_LOCATION, record.target.value)}
                value={agencyInfo.location}
              />
            </Col>
          </Row>

          <div style={{ borderBottom: `2px solid ${Color.lightGrey}` }}>
            <CollapseTextFormInput
              text="Other Details"
              onChange={(record) => updateInputField(agencyInputFieldType.AGENCY_OTHER_DETAILS, record.target.value)}
              value={agencyInfo.otherDetails}
            />
          </div>
          <div style={{ paddingTop: "10px", color: Color.darkGrey }}>Users</div>
          <div className="modal-scroll">
            {agencyInfo.users.map((user, index) => {
                return (
                <div key={index} style={{border:"1px solid #e3e3e3",padding:"0.5rem",margin:"0.5rem"}}>
                     <Row gutter={24}>
                      <Col span={22}>{console.log("ssssUserDetails",index,user)}
                      <Form.Item style={{ marginBottom: "12px" }}>
                      {/* {console.log("agencyInfoagencyInfo",agencyInfo)} */}
                          <Checkbox 
                            style={{color: Color.modalblue}} 
                            checked={user.primaryContact}
                           
                            // disabled={agencyInfo.id === 1 && this.state.addModal && user?.name === '' ? false : true}
                            // disabled={agencyInfo?.id === 1?true:false}
                            onChange={() => updateAgencyUsersInputField(AGENCY_PRIMARY_CONTACT, index, true)} 
                          >
                            Make Primary Contact
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {agencyInfo.users.length > 1&&agencyInfo.agencyName!=="Brigosha Agency" ? 
                      <Col span={2} className="text-red">
                        <Tooltip title="Delete">
                          <MinusCircleOutlined style={{color:"red"}}
                            onClick={() => {
                              agencyUserDelete(index);
                            }}
                          />
                        </Tooltip>
                      </Col> 
                      : ""} 
                    </Row>
                    <Row>
                      <Col span={24}>
                        {agencyInfo.id !== 1 ?
                        <TextFormInput
                         required={true}
                          text="Name"
                          id={3*index}
                          fieldKey={[user.fieldKey, "name"]}
                          onChange={(record) => {
                              updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_NAME, index, record.target.value.replace(/[^\w\s]/gi, ""));
                          }}
                          value={user.name}
                        /> 
                        :
                        <Select  required={true}
                            text="Name"
                            name="name"
                            id={3*index}
                            fieldKey={[user.fieldKey, "name"]}
                            // allowClear={true}
                            showSearch={true}
                            placeholder="Select the name"
                            optionFilterProp="children"
                            disabled={agencyInfo.id === 1 && user.name !== ''}
                            style={{ marginBottom: "24px",width:"100%" }}
                            onChange={(value) => {
                              if(value !== undefined){
                              let nameDetail = userDetailLists.find(item => item.id === value)
                              this.setState({selectedAgency: nameDetail})
                              // updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_NAME, index, nameDetail.name.replace(/[^\w\s]/gi, ""));
                              // updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_PHONE, index, nameDetail.phone)
                              // updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_EMAIL, index, nameDetail.email)
                              }
                             
                            }}
                            value={user.name === '' && this.state.selectedAgency.name === '' ? null 
                                    : user.name === '' && this.state.selectedAgency.name !== '' ? this.state.selectedAgency.name
                                    : user.name}>
                              {userDetailLists.map((element) =>{
                                  return (
                                    <Select.Option value={element.id} key={element.id}>{element.name}</Select.Option>
                                  )
                              })}
                      
                        </Select>
                        }
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <TextFormInput
                         required={true}
                          type="number"
                          name="phone"
                          text="Phone"
                          id={3*index + 1}
                          disabled={agencyInfo.id === 1 && (user.phone || !user.name || user.phone === '')}
                          fieldKey={[user.fieldKey, "phone"]}
                          onChange={(record) => 
                              updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_PHONE, index, record.target.value
                          )}
                          value={this.state.selectedAgency.phone ? this.state.selectedAgency.phone : user.phone}
                        />
                      </Col>
                      <Col span={12}>
                        <TextFormInput
                         required={true}
                          text="Email"
                          id={3*index + 2}
                          disabled={agencyInfo.id === 1 && (user.email || !user.name || user.email === '')}
                          fieldKey={[user.fieldKey, "email"]}
                          onChange={(record) => 
                            updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_EMAIL, index, record.target.value
                          )}
                          value={this.state.selectedAgency.email ? this.state.selectedAgency.email : user.email}
                          // disabled={!isAddingAgency && user.disabled !== undefined && user.disabled === true}
                        />
                      </Col>
                    </Row>
                    {(agencyInfo.id === 1 && this.state.addModal && user?.name === '') &&
                    <PrimaryButton
                      htmlType="submit"
                      loading={agencyDetailsLoader}
                      onClick={()=>{
                        updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_NAME, index, this.state.selectedAgency.name.replace(/[^\w\s]/gi, ""));
                        updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_PHONE, index, this.state.selectedAgency.phone)
                        updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_EMAIL, index, this.state.selectedAgency.email)
                        this.setState({selectedAgency: {}})
                      }}
                      style={{height:"2rem",width:"100%",marginBottom:"0.5rem",marginRight:"0.5rem",borderRadius:5}}
                      text={"Add"}
                      size="small"
                    />
                    }
                    {/* <Row gutter={24}> */}
                      {/* <Col span={12}>
                        <TextFormInput
                         required={true}
                          text="Email"
                          id={3*index + 2}
                          fieldKey={[user.fieldKey, "email"]}
                          onChange={(record) => 
                            updateAgencyUsersInputField(agencyUsersInputFieldType.AGENCY_USER_EMAIL, index, record.target.value
                          )}
                          value={user.email}
                          // disabled={!isAddingAgency && user.disabled !== undefined && user.disabled === true}
                        /> */}
                      {/* </Col> */}
                      
                </div>
                );
            })}
          </div>
          {agencyInfo.agencyName!=="Brigosha Agency"&&
          <Form.Item>
            <SecondaryButton 
            // disabled={this.state.addModal}
              text="Add User"
              onClick={() => {
                agencyUserAdd();
                this.setState({addModal: true});
                this.setState({selectedAgency: {}})
              }}
              id="addUser"
              icon={<PlusOutlined style={{ color: Color.black }} />}
            />
          </Form.Item>
          }
        </Form>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyModal);
