/* eslint-disable react/display-name */
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Tag, Modal, Row, Table, Checkbox, Popover,
  Space,
  Tabs,
  Tooltip,
  Spin,
  Dropdown,
  Button,
  Select,
  Badge,
  Typography, Pagination, } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  inputFieldType,
  operationType,
  dateFormat,
  candidateFieldType,
  candidateFieldTypeHash,
  CANDIDATE_DETAILS,
} from "./redux/action";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import "./styles.css";
import moment from "moment";
import ViewImage from "./viewImage";
import { Color, InformationModal, modalContent } from "services";
import profileIcon from '../../assets/profileIcon.svg.svg'
import store from "store";

const titleStyle = {
  fontSize:'12px',
}
const dataStyle = {
  fontSize:'12px',
}

const columns = [
  {
    title: (<span style={titleStyle}>SUBMITTED ON</span>),
    dataIndex: "createdAt",
    key: "createdAt",
    render: (data, record) => (
      <span style={dataStyle}>{moment(record.createdAt).format(dateFormat)}</span>
    ),
  },
  {
    title: (<span style={titleStyle}>AGENCY NAME</span>),
    dataIndex: "agencyName",
    key: "agencyName",
    render: (data, record) => <span style={dataStyle}>{record?.agency?.agencyName}</span>,
  },
  {
    title:(<span style={titleStyle}>JOB TITLE</span>),
    dataIndex: "title",
    key: "title",
    render: (data, record) => {
      return <span style={dataStyle}>{record.job.title}</span>;
    },
  },
  {
    title:(<span style={titleStyle}>STATUS</span>),
    dataIndex: "status",
    key: "status",
    render: (data, record) => <span style={dataStyle}>{record.hiringStatus}</span>,
  },
];
const onShowSizeChange = (current, pageSize) => {
  // console.log(current, pageSize);
}
class DuplicateViewModal extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {pageCurrent: 1, pageSize: 10, windowDimensions: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                  }, };
}

  componentDidMount() {

  window.addEventListener("resize", this.handleResize);

  this.setState({
    windowDimensions: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  });
  }

  handleResize = () => {
  this.setState({
    windowDimensions: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  });
  };
  changeCandidateInfo = (action, value) => {
    const {
      candidateInfo,
      handleCandidateFlagEdit,
      updateInputField,
      candidateOriginalInfo,
    } = this.props;
    updateInputField(action, value);
    const type = candidateFieldTypeHash[action];
    const mark = value ? "mark" : "unmark";

    InformationModal.confirm(
      "",
      <div>
        Are you sure you want to {mark} the candidate as {type}?
      </div>,
      <div style={{ color: Color.darkGrey }}>
        {modalContent("Name:", candidateInfo?.name)}
      </div>,
      () => handleCandidateFlagEdit(),
      (close) => {
        updateInputField(CANDIDATE_DETAILS, candidateOriginalInfo);
        close();
      }
    );
  };

  closeModal = () => {
    this.props.performOperation(operationType.DUPLICATE_VIEW_MODAL, false);
  };
  render() {
    const isHrProcess = window.location.pathname == "/hr-process";
    const {
      candidateInfo,
      duplicateViewModal,
      duplicateCandidates,
      updateInputField,
      performOperation,
    } = this.props;
    const { width, } = this.state.windowDimensions;
    const startIndex = (this.state.pageCurrent  - 1) * this.state.pageSize;
    const endIndex = Math.min(startIndex + this.state.pageSize, duplicateCandidates?.length);
    const paginatedData = duplicateCandidates?.slice(startIndex, endIndex);
    return (
      <Modal
        // width="45%"
        visible={duplicateViewModal}
        // bodyStyle={{height:"27rem"}}
        closable={true}
        className="dashboard-candidate-box"
        centered
        title={
          <div style={{ display: "flex",width:"80%",alignItems:"center",marginLeft:width>700&&"1rem",flexDirection:width>700?"row":"column",justifyContent:"space-between"}}>
            <div style={{ display: "flex", alignItems: "center",justifyContent: "flex-start",width:width<700&&"100%",}}>
              <div style={{}}>
                {!candidateInfo.photo ? (
                  <Avatar size="50" icon={<UserOutlined />} />
                ) :
                 (
                  <img
                    src={candidateInfo?.photo}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                    alt=""
                    onClick={() => {
                      updateInputField(
                        inputFieldType?.CANDIDATE_IMAGE,
                        candidateInfo?.photo
                      );
                      performOperation(
                        operationType.CANDIDATE_IMAGE_VIEW_MODAL,
                        true
                      );
                    }}
                  />
                 )}
              </div>
              <Typography.Text ellipsis={{tooltip:false}} style={{width:"10rem",fontSize:15,color:"#161616",marginRight:"1rem"}}>
              <span style={{fontSize:'14px',color:Color.black, marginLeft: "1rem", }}>{candidateInfo.name}</span>
              </Typography.Text>
            </div>
            <div
              style={{width:width<700&&"100%",marginTop:width<700&&"0.8rem",
                flex: width>700&&"1 1 50%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div>
                {!isHrProcess && (
                  <Row
                    style={{
                      color: "black",
                      display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={candidateInfo.duplicate}
                      onChange={() =>
                        this.changeCandidateInfo(
                          candidateFieldType.CANDIDATE_DUPLICATE,
                          !candidateInfo.duplicate
                        )
                      }
                    ></Checkbox>
                    <b style={{ fontSize: "12px", marginLeft: "0.5rem" }}>
                      MARK AS DUPLICATE
                    </b>
                  </Row>
                )}
              </div>
            </div>
          </div>
        }
        onCancel={this.closeModal}
        footer={null}
        style={{ marginTop: "5%" }}
      >
        {width > 700 ?
        <Table
          dataSource={duplicateCandidates}
          columns={columns}
          scroll={{y:300}}
          pagination={{
            size:"small",
            position: "bottomRight",
            // showSizeChanger:true,
            // onShowSizeChange:{onShowSizeChange},
            pageSize:20,
            pageSizeOptions:[20,30,50],

          }}
        />
        :<>
        <div className="ListBodyContainer" style={{height:"67vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",marginTop:"0.1rem"}}>
          {
          // candidateTableLoader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
              paginatedData?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
              paginatedData?.map((record,id,index)=>{
                return(
                 <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"0.9rem 0.7rem"}}>
                 <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>SUBMITTED ON</span>
                  <p style={{fontSize:15,color:"#161616"}}>
                      <span style={{fontSize:13,color:"#161616"}}>{moment(record.createdAt).format(dateFormat)}</span>
                  </p>
                 </div>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>AGENCY NAME</span>
                  <Popover content={record?.agency?.agencyName}>
                    <Space size="small">
                      <span style={{fontSize:13,color:"#161616"}}>{record?.agency?.agencyName}</span>
                    </Space>
                  </Popover>
                 </div>
                 </div>

                 <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>JOB TITLE</span>
                    <Popover content={record.job.title}>
                      <Typography.Text ellipsis={{tooltip:false}} style={{width:"7.5rem",fontSize:15,color:"#161616"}}>
                      <span style={{fontSize:13,color:"#161616"}}>{record.job.title}</span>                      
                      </Typography.Text>
                    </Popover>
                 </div>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>STATUS</span>
                  <p style={{fontSize:13,color:"#161616"}}>{record?.hiringStatus ? record?.hiringStatus:"NA"}</p>
                 </div>
                 </div>

              </div>
              )
            })}
        </div> 
        <div>
        <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",margin:"0.6rem 0",fontSize:width<700&&15,}}
          pageSizeOptions= {[10,25, 30, 50, 100]}
          showSizeChanger={true}
          current={this.state.pageCurrent}
          pageSize={this.state.pageSize}
          onChange={(page, pageSize) => {            
            this.setState({ pageCurrent: page, pageSize: pageSize });        
          }}
          onShowSizeChange={(page, pageSize) => {
            this.setState({ pageCurrent: page, pageSize: pageSize });
          }}
          total={duplicateCandidates?.length > 0 ? duplicateCandidates?.length : 0}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`}
        /> 
        </div>
        </>
        }
        {/* <Form>
            {duplicateCandidates.map((candidate, index) => {
                return (
                  <Row key={index}>
                    <Col span={7}>
                      <TextFormDisplay
                        text="Activated On"
                        value={moment(candidate.createdAt).format(dateFormat)}
                      />
                    </Col>
                    <Col span={1}></Col>
                    <Col span={7}>
                      <TextFormDisplay
                        text="Agency Name"
                        value={candidate.agency.agencyName}
                      />
                    </Col>
                    <Col span={1}></Col>
                    <Col span={7}>
                      <TextFormDisplay
                        text="Job Title"
                        value={candidate.jobs.title}
                      />
                    </Col>
                  </Row>
                );
              })}
        </Form> */}
        <ViewImage />
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateViewModal);
