import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Button,
  Input,
  Space,
  DatePicker,
  TimePicker,
  Row,
  Col,
  Typography,
  Switch,
} from "antd";
import { DeleteOutlined,CopyOutlined,PlusCircleOutlined, CheckOutlined, CloseOutlined,EditOutlined } from "@ant-design/icons";
import moment from 'moment';
import Api from "services/Api";
import { adminActions, testActions } from "./store";
import { useDispatch, useSelector } from "react-redux";
import Notification from "services/Notification";
import { MdOutlineSignalCellularConnectedNoInternet4Bar } from "react-icons/md";
import classes from "./test.module.css"
const { Title } = Typography;
const { TextArea } = Input;

const EditTest = (props) => {
  const [form] = Form.useForm();
  const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const dispatch = useDispatch()
  const [page,setPage]=useState(1)
   const [sectName,setSectName] = useState("")
   const [marks,setMarks] = useState(new Array(20).fill(""))
   const [cuttofffMarks,setcuttoffMarks] = useState("")
  const[sectionArray,setSectionArray] = useState([])
  const [sectionDetail,setDetails] = useState([])
  const [formValue,setFormValue]=useState("")

  // const [disabled,setDiisable] = useState(false)
  const [enable,setEnable] = useState({})

  const [buttontrig,setTrig] = useState(false)
  const {disable,disableId} = useSelector(state => state.test)
  const[testTitle,setTitle]=useState("") 
  const [testDuration,setTestDuration] = useState(0)
  const[totMarks,setTotmarks] = useState(0)
  const[totcuttoffMarks,setTotcuttoffmarks] = useState(0)
  const [ins,setIns] = useState("")
  const [cloneLoading,setCloneLoading] = useState(false)
  const [editing,setEditing] = useState(new Array(20).fill(false));
  const [deletedSection,setDeletedSection]=useState([]);

  const [editedIds,setEditedIds]=useState([]);
  const [adding,setAdding]=useState(new Array(20).fill(false));
  // const [reverseAdding,setReverseAdding]=useState(false);

  // useState(()=>{
  //   if(adding)
  //   {
  //     setReverseAdding(false);
  //   }
  //   else
  //   {
  //     setReverseAdding(true);
  //   }
   
  // },[adding])

   useEffect(() =>{
    if(props.update){
     setIsModalVisible(props.update)
    setDetails(props.selectedObj.sections)
    setSectionArray(props.selectedObj.sections)
  }
   },[])

   useEffect(() =>{
      if(disable===false){
      setSectionArray(sectionArray)
      }
   },[disable])
   
  const sectionDetails = sectionDetail.map((value,index) =>{ 
    let namevalue,marksvalue,cuttoffValue
       namevalue=value.sectionTitle
       marksvalue=value.sectionMaxMarks
       cuttoffValue=value.sectionCutt_off
    let named,marked,cuttoffMarks;
    let value2={}
    return(
    <div key={index} style={{fontSize:"12px",fontWeight:"700"}}>
    <div style={{display:"flex",flexDirection:"row",gap:"2%",alignItems:"center",justifyContent:"space-between",margin:"2% 0",width:"98%",}}>
            <b style={{color:"#6F6F6F"}}>{`SECTION ${value.sectionRound?value.sectionRound:value}`}</b>
          <div style={{display:"flex",flexDirection:"row",gap:"3%"}}>
                      <Button type="text" 
                                  onClick={(e) =>{ 
                                  setEditing(editing.map((it,index2)=>{
                                    if(index===index2)
                                    {
                                      return true;
                                    }
                                    return false;
                                  }))
                                  setSectName(value.sectionTitle);
                                  setEditedIds(prev=>[...prev,value.id]);
                                  setMarks(marks.map((it,index2)=>{
                                    if(index===index2)
                                    {
                                      return value.sectionMaxMarks;
                                    }
                                    return "";
                                  }))
                                  setcuttoffMarks(value.sectionCutt_off);
                                  
                                    let valued=enable
                                    valued[value]=false
                                   
                                    setEnable(valued)
                                    forceUpdate()          
                                  }}
                                  style={{fontSize:"0.6rem",fontWeight:'bold',backgroundColor:"green",border:"1px solid green",borderRadius:"3px",color:"#ffffff",height:"1.7rem"}}
                                  >Edit
                                  <EditOutlined />
                      </Button>
                      <Button  style={{fontSize:"0.6rem",fontWeight:'bold',border:"1.3px solid red",borderRadius:"3px",color:"red",height:"1.7rem"}}
                                  onClick={() =>{                       
                                    const id=sectionDetail.filter((item,index2)=>index===index2)[0].id;
                                    setDeletedSection(prev=>[...prev,id]);
                                    let tempArr=[];
                                    for(let i=0; i<sectionDetail.length-1; i++)
                                    {
                                      if(i<index)
                                      {
                                        tempArr.push(sectionDetail[i])
                                      }
                                      else
                                      {
                                        let tempObj={...sectionDetail[i]};
                                        if(sectionDetail[i+1].id)
                                        {
                                          tempObj.id=sectionDetail[i+1].id;
                                          setEditedIds(prev=>[...prev,tempObj.id]);
                                        }
                                        else
                                        {
                                          delete(tempObj.id);
                                        }            
                                        tempObj.sectionTitle=sectionDetail[i+1].sectionTitle;
                                        tempObj.sectionCutt_off=sectionDetail[i+1].sectionCutt_off;
                                        tempObj.sectionMaxMarks=sectionDetail[i+1].sectionMaxMarks;
                                        tempArr.push(tempObj);
                                      }                    
                                    }
                                    setDetails(tempArr);
                                  }}>
                                    Delete
                                    <DeleteOutlined />
                      </Button>
          </div>   
    </div>
    <div style={{border:"1.4px solid #0086ff",marginTop:"1%",padding:"2%"}}>
    
      
    <div className={classes.name}>
        <span>NAME</span>
       {!editing[index]&&<Input  value={value.sectionTitle ? value.sectionTitle:sectName} onChange={(event)=>{
          setSectName(event.target.value);
        }}/>}
   {editing[index]&&<Input  value={sectName} onChange={(event)=>{
          setSectName(event.target.value);
        }}/>}  
    </div>
    
    <div className={classes.marks}>
        <div style={{display:"flex",flexDirection:"column",justifyContent:"left"}}>
        <span>MARKS</span>
        {!editing[index]&&<Input onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} value={value.sectionMaxMarks? value.sectionMaxMarks:marks[index]} type={"number"} style={{width:"100%",flexGrow:"1"}} onChange={(e) =>{marked=e.target.value
        setMarks(marks.map((it,index2)=>{
          if(index===index2)
          {
            return marked;
          }
          return "";
        }))
        }}/>}
        {editing[index]&&<Input onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} value={marks[index]} type={"number"}  style={{width:"100%",flexGrow:"1"}} onChange={(e) =>{marked=e.target.value
        setMarks(marks.map((it,index2)=>{
          if(index===index2)
          {
            return marked;
          }
          return "";
        }))
      }}       
        />}
      </div>

      <div style={{display:"flex",flexDirection:"column",justifyContent:"left"}}>
      <span>CUT-OFF </span>
      {!editing[index]&&<Input min={0} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} value={value.sectionCutt_off ?value.sectionCutt_off:cuttofffMarks} type="number"
        onChange={(e) =>{cuttoffMarks= e.target.value
        setcuttoffMarks(cuttoffMarks)}}/>}

   {editing[index]&&<Input min={0} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} value={cuttofffMarks} type="number"
        onChange={(e) =>{cuttoffMarks= e.target.value
        setcuttoffMarks(cuttoffMarks)}}/>}
      </div>
      </div>
     
    <Row justify="end" style={{margin:"2% 0"}}>  
    {
    // enable[value]===true ?<Button  disabled={true} style={{fontSize:"0.7rem",fontWeight:"bold",borderRadius:"3px",height:"1.7rem",width:"5rem"}}>Save</Button>:
     <Button 
     style={{fontSize:"0.7rem",fontWeight:"bold",borderRadius:"3px",height:"1.7rem",width:"5rem",color:(adding[index]||editing[index])?"#ffffff":"#000000",backgroundColor:(adding[index]||editing[index])?"#0086FF":'',border:(adding[index]||editing[index])?"1px solid #0086FF":''}}
     onClick={() =>{
      setAdding (adding.map((item,index2)=>{
        if(index===index2)
        {
          return false;
        }
        else
        {
          return item;
        }
      }));
      if(editing[index])
      {
              setDetails(sectionDetail.map((item2,index2)=>{
                if(index===index2)
                {
                  return {...item2,sectionTitle:sectName,sectionCutt_off:parseInt(cuttofffMarks),sectionMaxMarks:parseInt(marks[index]),}
                }
                return item2;
              }))
      }
      else
      {        
        let value1={
          sectionRound:index+1,
          sectionTitle:sectName,
          sectionMaxMarks:parseInt(marks[index]),
          sectionCutt_off:parseInt(cuttofffMarks),
        }
        let tempArr=sectionDetail;
        tempArr?.pop();
        tempArr.push(value1);
        setDetails(tempArr);

      }
        setTrig(false)
        // setDiisable(value)
       let value3={}
        value2[value]=true
        value3={
         ...enable,
         ...value2
        }
        setEnable(value3)
    

     

      setSectName("");
      setcuttoffMarks("");
      setMarks(marks.map((it,index2)=>{
        return "";
      }))
      setEditing(new Array(20).fill(false));

    }} disabled={ (adding[index]||editing[index])?false:true
    }  htmlType="submit" >Save</Button>}
    </Row>
    </div>
    </div>
   )
   })
   
   const onFinish = (values) => {
    let duration=parseInt(values.duration)
    let totalMarks=parseInt(values.totalMarks)
    let testCutt_off=parseInt(values.testCutt_off)
    let value={
      ...values,
      duration:duration,
      totalMarks:totalMarks,
      testCutt_off:testCutt_off
    }
    setFormValue(value)
    setPage(() => page+1)
  };  
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    if(props.update){
      props.setUpdate(false)}
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    if(props.update){
    props.setUpdate(false)}
  };

  return (
    <>
      {/* <Button type="primary"
        onClick={showModal}
        size="large"
      style={{
        color: "white",
        width:"9rem",
        height:"3rem",
        fontFamily:"Open Sans",
        fontWeight:"bold",
        fontSize:"1rem",
        borderRadius:"5px",
      }}

      >
        CREATE TEST
      </Button> */}
      <Modal
        style={{
          overflow: "hidden",
          borderRadius: "10px",
          textAlign: "center",
        }}
        width="600px"
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <div style={{margin:"2rem 1rem"}}>
        <Row style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",marginBottom:"0.5rem"}}>
         
          <Title level={4} style={{fontFamily:"Open Sans",fontWeight:"bold", color:"#161616",marginLeft:"5px", fontSize:"1.5rem",marginBottom:"0.5rem",textAlign:"left" }}>         
                    <Typography.Text style={{width:"15rem"}} ellipsis={{tooltip:true}}> {props.selectedObj.title}</Typography.Text>
        </Title>
        
        
        {props.update && page===1?<Button loading={cloneLoading} style={{borderColor:'#0086FF',color:'#0086FF',textAlign:"right" ,marginRight:"13px"}} onClick={() =>{
          setCloneLoading(true)
          let params={
        testId: props.selectedObj.id,
        title:testTitle!=="" ? testTitle :props.selectedObj.title,
        testCutt_off:totcuttoffMarks!==0  ? parseInt(totcuttoffMarks) :props.selectedObj.testCutt_off,
        totalMarks:totMarks!==0 ?  parseInt(totMarks) :props?.selectedObj?.totalMarks,
        duration:testDuration !==0 ? parseInt(testDuration) : props.selectedObj.duration,
        instructions:ins!=="" ? ins : props.selectedObj.instructions
          }
         
         
          Api.post("/hiring-tests/cloneTest")
          .params(params)
          .send(response =>{
           
            setCloneLoading(false)
            props.refresh()
            props.setUpdate(false)
           
          })
        }}><CopyOutlined />  CLONE </Button>:""}
        
        </Row>   
        {page===1 ? 
        <Form form={form} layout={"vertical"} onFinish={onFinish} initialValues={props.selectedObj}>
          <Form.Item
          style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
            name="title"
            label="TEST TITLE"
            rules={[
              {
                required: true,
                message: "Please input your title!",
              },
            ]}
          >
            <Input
            onChange={(e)=>{
              let title=e.target.value
              setTitle(title)
             }}
              style={{
                width: "100%",
              }}
              size={"middle"}
            />
          </Form.Item > 
          <Row>
            <Col span={24}>
              <Form.Item
                name="duration"
                label="TEST DURATION"
                rules={[
                  {                    
                    required: true,
                    message: "Please give duration!",
                  },
                ]}
                style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
              >
                <Input min={0} onChange={(e) =>{
                  let dur=e.target.value
                  setTestDuration(dur)
                }}  type={"number"} size={"middle"}
                />
              </Form.Item>
            </Col>
            <Col span={12} offset={0}>
              <Form.Item name="totalMarks" label="TOTAL MARKS"  rules={[
              {
                required: true,
              },
            ]}
            style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
            >
                <Input min={0}  onChange={(e) =>{
                  let dur=e.target.value
                  setTotmarks(dur)
                }}  type={"number"} size={"middle"} />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item 
              name="testCutt_off" label="OVERALL CUT-OFF" 
              rules={[
                { 
                  required: true,
                  message: "Please enter valid input!",
                },
              ]}
              style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}} >
               <Input min={0} onChange={(e) =>{
                  let dur=e.target.value
                  setTotcuttoffmarks(dur)
                }} type={"number"}
                size="middle"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
            name="instructions"
            label="INSTRUCTIONS"
            placeholder=""
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea onChange={(value) =>{
               setIns(value)
            }} rows={5} />
          </Form.Item>
          <Form.Item
            style={{fontSize:"0.9rem",fontWeight:"600", color:"#6f6f6f",fontFamily:"Open Sans",}}
            wrapperCol={{
              span: 24,
              offset: 0,
            }}
          >
            <Row justify="center" style={{display:"flex",flexDirection:"row",gap:"1rem"}} >
              
                <Button
                  size="middle"
                  onClick={handleCancel}
                  style={{fontSize:"14px",width:"7rem",height:"2rem",borderRadius:"2px", borderColor: "#1e90ff", color: "#1e90ff",fontWeight:"bold",border: "1.5px solid #1e90ff" }}
                >
                  CANCEL
                </Button>
              
              
                <Button  htmlType="submit" size="middle"  style={{fontSize:"14px",width:"7rem",height:"2rem",borderRadius:"2px", border: "2px solid #1e90ff",backgroundColor:"#1e90ff", color: "#FFFFFF",fontWeight:"bold" }}>
                 EDIT
                </Button>
              
            </Row>
          </Form.Item>
        </Form>:<>
        <Row justify="center" style={{marginBottom:"1%", fontWeight:"600"}}><span>Total marks:{totMarks?totMarks:formValue.totalMarks} & Cut off Marks:{totcuttoffMarks?totcuttoffMarks:formValue.testCutt_off}</span></Row>
        <Row justify="center"><h2 span={24} style={{ fontWeight:"600",fontSize:"1.2rem",fontWeight:"bold"}}>Section Details</h2></Row>
         <div style={{overflowY:"scroll",height:"400px"}}>  
          {sectionDetail&&<div>{sectionDetails}</div>}
          
          <Col span={24} >
            {buttontrig===false ? 
            <Button type="link" onClick={() =>{
            
          setTrig(true)
          let len=sectionDetail.length+1;
          setAdding (adding.map((item,index)=>{
            if(index===len-1)
            {
              return true;
            }
            else
            {
              return false;
            }
          }));
          setDetails(prev=>[...prev,len]);        
        }}        
        style={{fontWeight:"600", fontSize:"1.3rem" }}
        ><PlusCircleOutlined /></Button>:""}</Col></div>
       <Row justify="center" style={{display:"flex", flexDirection:"row",gap:"1rem", alignItems:"center",margin:"2% auto"}}>
       <Button size="middle" onClick={() => setPage(() => page-1)} style={{minWidth:"7rem",borderRadius:"0.5rem",fontWeight:"600",fontSize:"0.8rem",fontWeight:"bold",width:"2.4rem"}}>BACK</Button>
        <Button  size="middle" type="primary" style={{minWidth:"7rem",borderRadius:"0.5rem",fontWeight:"600",fontSize:"0.8rem",fontWeight:"bold",width:"2.4rem"}} onClick={()=>{
          
          const sects=sectionDetail.filter(item=>editedIds.includes(item.id)||item.id===undefined);
          
          const finalValue={...formValue,sections:sects,edited:editedIds,deleted:deletedSection, testId:props.selectedObj.id};
          
          Api.patch("/hiring-tests/editTest")
      .params(finalValue)
      .send((response) =>{
        if(response.show.type==="error"){
          return
        }
        
        setFormValue("")
        setDetails([])
        setIsModalVisible(false);
        props.refresh()
        props.setUpdate(false)
        
      })
      return         
        }}>SAVE</Button>         
          </Row>
          </>
          }
          </div>
      </Modal>
    </>
  );
};
export default EditTest;
