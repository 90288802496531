import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  list : []
};



const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    updateTemplates: (state, action) => {
    
      state.list = action.payload;
    },   

  },
});
export default templatesSlice.reducer;
export const templateActions = templatesSlice.actions;
