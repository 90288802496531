import {
  CalendarOutlined,
  FilterOutlined,
  CommentOutlined,
  CopyOutlined,StopOutlined
} from "@ant-design/icons";
import classes from "./newCandidate.module.css";
import { Badge, Dropdown, Popover, Space, Table, Pagination, Input, Modal, Typography,Spin, Button,Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Color, EmptyTable, userContent } from "services";
import "services/styles.css";
import profileIcon from '../../assets/profileIcon.svg.svg'
import {
  operationType,
  CANDIDATE_DETAILS,
  badgeHash,
  inputFieldType,
  dateFormat,
} from "./redux/action";
import ResumeIcon from "../../assets/ResumeIconss.svg";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import {
  filterStatusMenu,
  filterHackathonStatusMenu,
  rangePickerMenu,
  timelineContent,
} from "./shortlistedCandidate";
import "./styles.css";
import DuplicateViewModal from "./duplicateViewModal";

const { TextArea } = Input;


const historyCandidateColumns = (props) => [
  // {
  //   title: "S. No.",
  //   render: (text, record, index) => (
  //     <div>{(props.candidateHistoryPageNumber - 1) * parseInt(props.candidatePageMeta.itemsPerPage)+ index + 1}</div>
  //   ),
  //   fixed: true,
  // },
  {
    title: <div style={{marginLeft:"2rem"}}>Name</div>,
    dataIndex: "name",
    key: "name",
    width:"13rem",
    render: (text, record) => (
      <div>
        <Popover content={userContent(record)}>
          <a
            style={{ fontWeight: "500" }}
            onClick={() => {
              props.updateInputField(CANDIDATE_DETAILS, record);
              props.performOperation(operationType.CANDIDATE_VIEW_MODAL, true);
            }}
          >
            <Typography.Text ellipsis={{tooltip:false}} style={{width:"6.5rem"}}>{record.name}</Typography.Text>
          </a>
        </Popover>
        {record.duplicate ? (
                      <Tooltip title="Duplicate">
                        <CopyOutlined
                          style={
                            record.duplicate
                              ? { color: Color.red }
                              : { color: Color.midGrey }
                          }
                          onClick={() => {
                            props.updateInputField(CANDIDATE_DETAILS, record);
                            props.getDuplicateCandidates();
                            
                          }}
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}
      </div>
    ),
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
    //fixed: true,
  },
  {
    title: "E-Mail",
    dataIndex: "email",
    key: "email",
    width:"15rem",
  },
  {
    title: (
      <div>
        <Space size="small">
          Date

          {/* <Dropdown
            visible={props.candidateDateDropdown}
            overlay={rangePickerMenu(props)}
            onClick={() =>
              props.updateInputField(
                inputFieldType.CANDIDATE_DATE_DROPDOWN,
                !props.candidateDateDropdown
              )
            }
             trigger={["hover"]}
            id="date-dropdown"
          >
            <CalendarOutlined id="dateRange-calender" />
          </Dropdown> */}

         <Popover
          placement="bottom"
          content={rangePickerMenu(props)}
          trigger="click"
          visible={props.candidateDateDropdown}
          onVisibleChange={() =>{
            props.updateInputField(
              inputFieldType.CANDIDATE_DATE_DROPDOWN,          
              !props.candidateDateDropdown
            )    
          }
          }
        >
         <CalendarOutlined id="dateRange-calender" />
        </Popover>
        </Space>
        <div style={{ color: Color.modalblue }}>
          {props.candidateDateRange
            ? `${props.candidateDateRange[0]} - ${props.candidateDateRange[1]}`
            : ""}
        </div>
      </div>
    ),
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => moment(text).format(dateFormat),
    //fixed: true,
  },
  {
    title: "Contact",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Agency",
    dataIndex: "agency",
    key: "agency",
    render: (text,record) => {
      
     return(
    <div>{record?.agency?.agencyName}-{ record?.user?.name}</div>
  )},
  },
  {
    title: "Job Title",
    dataIndex: "job",
    key: "job",
    render: (job) => <div>{job.title}</div>,
  },
  {
    title: "Location",
    dataIndex: "currentLocation",
    key: "currentLocation",
  },
  {
    title: (
      <div>
        <Space size="small">
          Status
          <Dropdown
            //visible={props.candidateStatusDropdown}
            overlay={filterStatusMenu(props)}
            onClick={() =>
              props.updateInputField(
                inputFieldType.CANDIDATE_STATUS_DROPDOWN,
                !props.candidateStatusDropdown
              )
            }
            trigger={["click"]}
            id="status-dropdown"
          >
            <FilterOutlined style={{ color: Color.modalblue }} />
          </Dropdown>
        </Space>
        <div style={{ color: Color.modalblue }}>
          {props.candidateFilterStatus}
        </div>
      </div>
    ),
    dataIndex: "status",
    key: "status",
    width: "8%",
    render: (text, record) => (
      <div>
        <Popover content={timelineContent(props, record)}>
          <Badge
            style={{ cursor: "pointer",widht:"100%" }}
            status={badgeHash[record?.hiringStatus]}
            text={<span style={{fontSize:"14px"}}>{record?.hiringStatus}</span>}
          />
        </Popover>
        <div style={{ fontSize: "14px", marginLeft: "5px" }}>
          Round {record.currentRound}
        </div>
      </div>
    ),
  },
  {
    title: "Total Experience",
    dataIndex: "experience",
    key: "experience",
    render: (text, record) =>
      `${record.experienceYears} Y ${
        record.experienceMonths !== 0 ? `${record.experienceMonths} M` : ""
      }`,
  },
  {
    title: "Current Organisation",
    dataIndex: "currentCompany",
    key: "currentCompany",
    render: (text) => <div>{text === "" ? "NA" : text}</div>,
  },
  {
    title: (
      <div>
        <Space size="small">
          Hackathon Status
          <Dropdown
            visible={props.candidateHackathonStatusDropdown}
            overlay={filterHackathonStatusMenu(props)}
            onVisibleChange={(visible) =>
              props.updateInputField(
                inputFieldType.CANDIDATE_HACKATHON_STATUS_DROPDOWN,
                visible
              )
            }
            trigger={["click"]}
            id="status-dropdown"
          >
            <FilterOutlined style={{color:Color.modalblue}} />
          </Dropdown>
        </Space>
        {Array.isArray(props.candidateFilterHackathonStatus) ? props?.candidateFilterHackathonStatus?.length > 0 && props.candidateFilterHackathonStatus.map((item, index) => {
                return (
                    <h3 style={{ color: Color.modalblue, textAlign: "center" }}>
                        {item === "New" ? "Not applicable" : item}
                        {index === props.candidateFilterHackathonStatus.length - 1 ? "" : ","}
                    </h3>
                )
            }) : 
            <h3 style={{ color: Color.modalblue, textAlign: "center" }}>
               {props?.candidateFilterHackathonStatus}
            </h3>}
      </div>
    ),
    dataIndex: "hackathonStatus",
    key: "hackathonStatus",
    width: "12rem",
    render: (text, record) => (
      <div>
          {/* <Badge
            style={{ cursor: "pointer",widht:"100%" }}
            status={badgeHash[record?.hackathonStatus]} */}
            <span style={{fontSize:"14px"}}>{record?.hackathonStatus === "New" ? "Not applicable" : record?.hackathonStatus}</span>
            {/* <div style={{ fontSize: "14px", marginLeft: "5px" }}>
            Round {record.currentRound}
          </div> */}
      </div>
    ),
  },
  {
    width: "10rem",
    title: <span>Hackathon Score</span>,
    dataIndex: "score",
    key: "score",
    render: (text, record) => (
      <Popover content={record?.test === null ? "NA" : record?.test?.candidateTotal}>
        <Space size="small">
        <span style={{ fontSize: "13px" }}>
        {record?.test !== null && record?.test !== undefined && record?.test?.candidateTotal !== null ? record?.test?.candidateTotal
        : "NA" 
        }
        </span>        
        </Space>
      </Popover>
    ),
  },
  {
    title: (
      <div>
        <div>Current CTC</div>
        <div style={{ fontSize: "10px" }}>(in LPA)</div>
      </div>
    ),
    dataIndex: "currentCtc",
    key: "currentCtc",
  },
  {
    title: (
      <div>
        <div>Expected CTC</div>
        <div style={{ fontSize: "10px" }}>(in LPA)</div>
      </div>
    ),
    dataIndex: "expectedCtc",
    key: "expectedCtc",
  },
  {
    title: "Notice (Days)",
    dataIndex: "noticePeriod",
    key: "noticePeriod",
  },
  {
    title:<span>Shortlister's Comment</span>,
    dataIndex:"shortlisterComment",
    key:"shortlisterComment",
    width:"15rem",
    render:(text,record)=>(
      <div>
        <span>{(record?.shortlisterComment&&record?.shortlisterComment!=="null")?record?.shortlisterComment:"NA"}</span>
      </div>
    )
  },
 {
    title:<span>Candidate's Priority</span>,
    dataIndex:"candidatePriority",
    key:"candidatePriority",
    width:"15rem",
    render:(text,record)=>(
      <div>
        <span>{(record?.candidatePriority&&record?.candidatePriority!=="null")?record?.candidatePriority:"NA"}</span>
      </div>
    )
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    render: (text, record) => (
      <div>
        <Popover content={record.comment}>
          <CommentOutlined
            onClick={() =>
              props.candidateCommentToggle(record.id, record.comment)
            }
            style={{ fontSize: 25, color: Color.modalblue }}
          />
        </Popover>
      </div>
    ),
  },
];

class HistoryCandidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      }, };
  }
  
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };
  componentDidUpdate(prevProps, prevState) {
    
    // Now fetch the new data here.
    if(this.state.page!==parseInt(this.props.candidatePageMeta.currentPage)){
     
      this.setState({page:parseInt(this.props.candidatePageMeta.currentPage)})
    }
    
  
}
  handlePageChange(page, pageSize, value) {
    this.props.updateInputField(
      inputFieldType.CANDIDATE_HISTORY_PAGE_NUMBER,
      page
    );
    this.setState({ page: page, pageSize: pageSize });
    this.props.getAllCandidates(this.props.history, page, pageSize);
  }

  render() {
    const { isModalVisible } = this.state;
    const {
      history,
      candidates,
      candidateTableLoader,
      candidateJobDescription,
      candidateCommentRead,
      candidateCommentText,
      candidateCommentBool,
      candidateCommentLoaderBool,
      candidateCommentEdit,
      candidateCommentToggle,
      candidatePageMeta,
    } = this.props;
    const { width, } = this.state.windowDimensions;

    return (
      <div>
        {width > 700 ?
        <div>
        <Table
          columns={historyCandidateColumns(this.props).map((col) => ({
            ...col,
            align: "center",
          }))}
          pagination={{
            position: "bottomRight",
            // pageSize: 25,
            pageSizeOptions: [10,25, 30, 50, 100],
            size: "small",
            showSizeChanger:true,
            pageSize: parseInt(candidatePageMeta.itemsPerPage),
            current: parseInt(candidatePageMeta.currentPage),
            total: candidatePageMeta?.totalItems
              ? candidatePageMeta?.totalItems
              : 0,
            
            // total: candidatePageMeta?.totalItems
            //   ? candidatePageMeta?.totalItems
            //   : 0,
            onChange: (page, pageSize) => this.handlePageChange(page, pageSize),
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,

            // onShowSizeChange: this.handlePageChange,
          }}
          dataSource={candidates}
          scroll={{ x: 3000,y:340 }}
          style={{ overflow: "hidden" }}
          rowClassName="data-row"
          loading={candidateTableLoader}
          locale={{
            emptyText: candidateTableLoader ? (
              <div className="no-data-loader"></div>
            ) : Object.keys(candidateJobDescription).length === 0 ? (
              <EmptyTable text="Select Job Title" />
            ) : (
              <EmptyTable text="No Profiles Found" />
            ),
          }}
        />
        </div>    
        :<>
        <div className={classes.ListBodyContainer} style={{height:"67vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",marginTop:"0.7rem"}}>
          {candidateTableLoader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
             candidates?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
             candidates?.map((record,id,index)=>{
                return(
                 <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"0.9rem 0.7rem"}}>
                 <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"0.5rem",paddingTop:"0.3rem",borderBottom:"1px solid #DFDFDF"}}>
                  <div style={{display: "flex",justifyContent: "flex-start"}}>
                    <div>
                      {record.photo ? (
                        <img src={record.photo} style={{ width: "40px",height: "40px",borderRadius: "50%", }} />
                      ) : (
                        <img src={profileIcon} style={{ width: "40px",height: "40px",borderRadius: "50%",}} />
                      )}
                    </div>
                    <div style={{display: "flex",flexDirection:"row",marginLeft: "0.7rem",alignItems:"center",}}>
                      <div style={{ display: "flex"}}>
                        <div>
                          <Popover content={userContent(record)}>
                            <a style={{fontWeight:600}}
                              // onClick={() => {
                              //   props.updateInputField(CANDIDATE_DETAILS, record);
                              //   props.performOperation(
                              //     operationType.CANDIDATE_VIEW_MODAL,
                              //     true
                              //   );
                              // }}
                            >
                            <Typography.Text ellipsis={{tooltip:false}} style={{width:"7.5rem",fontSize:"1rem",fontWeight:600}}>{record.name}</Typography.Text>
                            </a>
                          </Popover>
                        </div>
                        <Space size="large">
                          <div>
                            {record.blacklist ? (
                              <Tooltip title="Blacklist">
                                <StopOutlined
                                  style={
                                    record.blacklist
                                      ? { color: Color.black }
                                      : { color: Color.midGrey }
                                  }
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                            {record.duplicate ? (
                              <Tooltip title="Duplicate">
                                <CopyOutlined
                                  style={
                                    record.duplicate
                                      ? { color: Color.red }
                                      : { color: Color.midGrey }
                                  }
                                  onClick={() => {
                                    this.props.updateInputField(CANDIDATE_DETAILS, record);
                                    this.props.getDuplicateCandidates();
                                    
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                        </Space>
                      </div>
                    </div>
                  </div>
                  <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                        <span style={{ fontSize: 10 }}>
                          <a
                            onClick={() => {
                              this.props.updateInputField(
                                inputFieldType.CANDIDATE_RESUME,
                                record.resume
                              );
                              this.props.performOperation(
                                operationType.CANDIDATE_PDF_VIEW_MODAL,
                                true
                              );
                              console.log("sssSHeelloo")
                            }}
                          >
                            <img src={ResumeIcon} />
                          </a>
                        </span>
                   </div>
                 </div>

                 <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>Job Title</span>
                  <p style={{fontSize:15,color:"#161616"}}>
                  <Popover content={record?.job?.title}>
                    <Space size="small">
                      <span style={{ fontSize: "13px" }}>{record?.job?.title}</span>
                    </Space>
                  </Popover>
                  </p>
                 </div>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>Experience</span>
                  <p style={{fontSize:15,color:"#161616"}}>{`${record.experienceYears} Y ${
                    record.experienceMonths !== 0 ? `${record.experienceMonths} M` : ""}`}</p>
                 </div>
                 </div>

                 <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>Date</span>
                  <p style={{fontSize:15,color:"#161616"}}>{record?.createdAt ? moment(record?.createdAt).format(dateFormat):"NA"}</p>
                 </div>
                 <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                  <span style={{fontSize:14,color:"#808080"}}>Notice Period (Days)</span>
                  <p style={{fontSize:15,color:"#161616"}}>{record?.noticePeriod ? record?.noticePeriod:"NA"}</p>
                 </div>
                 </div>

                 <div style={{width:"100%",marginTop:"1rem",borderBottom:"0.6rem"}}>
                  <Button type="primary" style={{height:"2.1rem",width:"100%",borderRadius:5}}
                   onClick={()=>{
                    this.props.updateInputField(CANDIDATE_DETAILS, record);
                    this.props.performOperation(operationType.CANDIDATE_VIEW_MODAL, true);
                  }}
                  >
                    View Profile
                  </Button>
                 </div>

              </div>
              )
            })}
        </div> 
        <div>
          <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.5rem",fontSize:width<700&&15,}}
            pageSizeOptions= {[10,25, 30, 50, 100]}
            showSizeChanger={true}
            onChange={(page, pageSize) => this.handlePageChange(page, pageSize)}
            onShowSizeChange={(page, pageSize) => this.handlePageChange(page, pageSize)}
            pageSize={parseInt(candidatePageMeta.itemsPerPage)}
            current={parseInt(candidatePageMeta.currentPage)}
            total={candidatePageMeta?.totalItems
              ? candidatePageMeta?.totalItems
              : 0}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`}
          /> 
        </div>
       </>
        } 
        <Modal
          title="Add Comment"
          confirmLoading
          okButtonProps={{
            loading: candidateCommentLoaderBool,
            disabled: candidateCommentLoaderBool,
          }}
          visible={candidateCommentBool !== false}
          onOk={() =>
            candidateCommentEdit(
              candidateCommentBool,
              candidateCommentText,
              history
            )
          }
          onCancel={() => candidateCommentToggle(false, null)}
        >
          <TextArea
            style={{ height: "200px" }}
            value={candidateCommentText}
            // defaultValue={candidateCommentText}
            onChange={(text) => candidateCommentRead(text.target.value)}
            placeholder="Add a comment!"
          />
        </Modal>
        <DuplicateViewModal />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCandidate);
