import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Button,
  Input,
  Space,
  DatePicker,
  TimePicker,
  // Select,
  Row,
  Col,
  Typography,
  Switch,
} from "antd";
import { DeleteOutlined,CopyOutlined,PlusCircleOutlined, CheckOutlined, CloseOutlined,EditOutlined } from "@ant-design/icons";
import moment from 'moment';
import Api from "services/Api";
import { adminActions, testActions } from "./store";
import { useDispatch, useSelector } from "react-redux";
import Notification from "services/Notification";


const { Title } = Typography;

const { TextArea } = Input;
const CreateSectionDetails = (props) =>{
    const {sectionDetailsSave} =useSelector((state => state.test) )
    const dispatch=useDispatch()
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [sectionDetail,setDetails] = useState(sectionDetailsSave)
    const [editEnable,setEditing] = useState(false)
    const [secName,setName] = useState("")
    const [marks,setMarks] = useState("")
    const [cuttofffMarks,setcuttoffMarks] = useState("")
    const [enable,setEnable] = useState({})
    let disableElement={}
     useEffect(() =>{
     for(let i =0;i<sectionDetailsSave.length;i++){
        disableElement[sectionDetailsSave[i].sectionRound]=true
     }
     setEnable(disableElement)
     },[])
  const [buttontrig,setTrig] = useState(false)
    function onCreateTest () {
   
    
        if(sectionDetail.length===0){
          Notification.error("Please Add Sections")
          return
        }
        Api.post("/hiring-tests/addTest")
        .params({
          ...props.formValue,
          sections:sectionDetail
        })
        .send((response) =>{
          if(response!==undefined && response.show.type!=="error"){
          props.setFormValue("")
          setDetails([])
          dispatch(testActions.updatereset(true))
          dispatch(testActions.updateSectionDetails([]))
          props.setPage(1)
          props.setIsModalVisible(false);}
          
          props.refresh()
        })
    
      }
    const sectionDetails = sectionDetail.map((value,index) =>{
       let Indexs=value.sectionRound

       
        return(< div key={index}>
        
        
        <Row><Col span={3} justify="start">
          <b style={{color:"#6F6F6F"}}>{`SECTION ${value.sectionRound}`}</b></Col>
         
              <Col span={4} offset={17}>
              <Button type="text" onClick={(e) =>{
                
               setEditing(value)
               setMarks(value.sectionMaxMarks)
               setName(value.sectionTitle)
             setcuttoffMarks(value.sectionCutt_off)
             
                let valued=enable
                valued[value.sectionRound]=false
      
                setEnable(valued)
                forceUpdate()
                
              }}><EditOutlined /></Button>
             <Button type="text" onClick={() =>{
          
              setName("")
              setMarks("")
              setcuttoffMarks("")
              let filterArray=[]
              let indexed=0
              let disablevalue=enable
              disablevalue[sectionDetail.length]=false
              setEnable(disablevalue)
              for(let element of sectionDetail ){
                if(element.sectionRound!==value.sectionRound){
                element["sectionRound"]=indexed+1
              filterArray.push(element)
              }
                else{
                  indexed=indexed-1
                }
    
                indexed++
               
                
                
              }
              setDetails(filterArray)
              setTrig(false)
             }}><DeleteOutlined style={{color:"red"}}/></Button>
              </Col>
         
        
        </Row>
        <div style={{border:"1px solid #0086ff",marginTop:"5px"}}>
         <Row justify="start" style={{margin:"10px"}}>
          
        <Col span={24}>
            <Row>NAME</Row>
            {/* <Form.Item name="sectionTitle"> */}
            <Input key={value.sectionTitle}  value={ editEnable.sectionRound===value.sectionRound? secName===""  ? value.sectionTitle:secName :value.sectionTitle}  disabled={enable[value.sectionRound]} onChange={(e) =>{
                
                setEditing(value)
                let name=e.target.value
                if(name===""){
                  name=undefined
                }
                setName(name)
            }}/>
            {/* </Form.Item> */}
          
        </Col>
        </Row>
        <Row style={{margin:"10px"}}>
            <Col span={11}>
            <Row>MARKS</Row>
            
            {/* <Form.Item name="sectionMaxMarks" initialValue={marksvalue? marksvalue:""}> */}
            <Input key={value.sectionMaxMarks}  
             value={ editEnable.sectionRound===value.sectionRound ? marks===""?value.sectionMaxMarks :marks :value.sectionMaxMarks} 
              disabled={enable[value.sectionRound]} 
              min={0} type="number" onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) =>{
              setEditing(value)
              let disabled=enable
              disabled[value.sectionRound]=false
             
              setEnable(disabled)
              let marked=e.target.value
              if(marked===""){
                marked=undefined
              }
              setMarks(marked)
              // setMarks(marks.map((it,index2)=>{
              //   if(index===index2)
              //   {
              //     return marked;
              //   }
              //   return "";
              // }))
            }}/>
            {/* </Form.Item> */}
          </Col>
          <Col span={11} offset={1}>
          <Row>CUT-OFF </Row>
          {/* <Form.Item name="sectionCutt_off"> */}
            <Input min={0} key={value.sectionCutt_off} disabled={enable[value.sectionRound]} value={editEnable.sectionRound===value.sectionRound?cuttofffMarks==="" ? value.sectionCutt_off:cuttofffMarks:value.sectionCutt_off} 
            type="number" onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            onChange={(e) =>{
              setEditing(value)
              let cuttoffMarked= e.target.value
              if(cuttoffMarked===""){
                cuttoffMarked=undefined
              }
              setcuttoffMarks(cuttoffMarked)
              // setcuttoffMarks(cuttofffMarks.map((it,index2)=>{
              //   if(index===index2)
              //   {
              //     return cuttoffMarked;
              //   }
              //   return "";
              // }))
              }}/>
            {/* </Form.Item> */}
         
          </Col>
          </Row>
        <Row justify="end" style={{marginRight:"2px"}}>  {enable[value.sectionRound]===true ?<Button  disabled={true}>SAVE</Button>: <Button disabled={marks==="" && editEnable===false} onClick={() =>{
        //    if((!secName|| secName==="") || (!marks ||marks===""))
        //    {
        //      Notification.error("name/marks are mandatory field")
        //      return
        //    }
            let disablevalue=enable
               disablevalue[value.sectionRound]=true
               setEnable(disablevalue)
               let copyArray=[]
               let obj={}
               
               sectionDetail.map(element =>{
                obj={
                    sectionRound:element?.sectionRound,
                    sectionTitle:element.sectionTitle,
                    sectionMaxMarks:element.sectionMaxMarks,
                    sectionCutt_off:element.sectionCutt_off
                }
                // element["sectionRound"]=value?.sectionRound,
                // element["sectionTitle"]=secName
                // element["sectionMaxMarks"]=parseInt(marks),
                // element["sectionCutt_off"]=parseInt(cuttofffMarks)
                copyArray.push(obj)
            })
    
            let details=copyArray.map((element) =>{
              if(element?.sectionRound===value?.sectionRound)
    
              { 
                element["sectionRound"]=value?.sectionRound,
                element["sectionTitle"]=secName==="" ?value.sectionTitle:secName
                element["sectionMaxMarks"]=marks===""?value.sectionMaxMarks:parseInt(marks),
                element["sectionCutt_off"]=cuttofffMarks===""?value.sectionCutt_off: parseInt(cuttofffMarks)
              }
              return element
            })
            
            
            setDetails(details)
          
            setTrig(false)
            // setDetails(oldArray => [...oldArray,value1])
        }}>SAVE</Button>}</Row>
          <Row justify="center">
       
            {/* { props.selectedObj && value <= props.selectedObj.sections.length ? 
              <Button   type="primary" onClick={()=>{
              
           Api.patch("/hiring-tests/editSection")
           .params({
            sectionRound:value,
            sectionTitle:named?named:namevalue,
            sectionMaxMarks:marked ?parseInt(marked) : marksvalue,
            sectionCutt_off:cuttoffMarks ? parseInt(cuttoffMarks):cuttoffValue,
            id:props.selectedObj.sections?.[value-1]?.id
           })
           .send(response =>{
          
       
           })
          }}>Edit and Save</Button>:""} */}
          </Row>
          {/* <Button onClick={() =>  onSectionAddn(value,name,marks,cuttofffMarks)}>Save</Button> */}
        </div>
        {/* </Form> */}
        </div>
      )
      })
    return(<>
        <div style={{overflowY:"scroll",height:"420px"}}>
                        
        {/* {enable===true || enable===false ? sectionArray.map((element,index) =>  {
         
          return (element)
          }):""} */}
          {sectionDetails}
          
          <Col span={24} >
           {buttontrig===false ?  
            <Button type="link" onClick={() =>{
          
          // if((secName==="" || marks==="") && sectionArray.length!==0 && !props.selectedObj){
          //   Notification.error("Add section details","")
          //   return
          // }
          // else{
          //   let len=sectionArray.length+1
          //   if(len>=0){
          //     onSectionAddn(Index,secName,marks,cuttofffMarks)
          //   }
          let len=sectionDetail.length+1
          setTrig(true)
          setName("")
          setMarks("")
          setcuttoffMarks("")
          let value1={
          sectionRound:len,
          sectionTitle:"",
          sectionMaxMarks:"",
          sectionCutt_off:""}
           setDetails(oldArray => [...oldArray,value1])
          // let len=sectionArray.length+1
          // // setLength(len)
         
          // setSectionArray(oldArray => [...oldArray,len])
          
        
        }}
        
        style={{fontFamily:"Open Sans",fontWeight:"600", fontSize:"1.3rem" }}
        ><PlusCircleOutlined /></Button>:""}</Col></div>
        <Row justify="center"><Space>
        <Button size="midle" onClick={() => {
            dispatch(testActions.updateSectionDetails(sectionDetail))
            props.setPage(1)}} style={{minWidth:"10rem",borderRadius:"0.5rem",fontWeight:"600",fontSize:"1rem"}}>BACK</Button>
          <Button size="midle" type="primary" onClick={() =>{
            onCreateTest() 
          }} style={{minWidth:"10rem",borderRadius:"0.5rem",fontWeight:"600",fontSize:"1rem"}}>
            CREATE
            </Button></Space></Row></>
    )

}
export default CreateSectionDetails