
import classes from './targetModal.module.css';
import {Table,Modal,Button,Typography} from 'antd'
import { overallDashboardActions } from '../store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useState,useEffect } from 'react';
import { Api } from 'services';


const TargetModal=(props)=>{
    const dispatch=useDispatch();
    const targetModalOpen=useSelector(state=>state?.overallDashboard?.targetModalOpen);
    const datas=props?.datatable;
    const [tableData,setTableData]=useState([]);
    const [tableLoader,setTableLodaer]=useState(false);
    const [pageChange, setPageChange] = useState(1);
    const [pageSize,setPageSize]=useState(100);
    const dataKeys=props?.dataKeys;
    const selectedPanelist=props?.selectedPanelist;
    const selectedJobTitles=props?.selectedJobTitles;
    const startDate=props?.startDate;
    const endDate=props?.endDate;


    const columnInfo=[
        {
            title:<span className={classes.header}>CANDIDATE NAME</span>,
            dataIndex:"name",
            key:"name",
            render:(text,record)=>{
                return(
                    <div>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.7rem"}}>{record?.name?record?.name:"NA"}</Typography.Text>
                    </div>
                )
            }
        },
        {
            title:<span className={classes.header}>EMAIL</span>,
            dataIndex:"email",
            key:"email",
            render:(text,record)=>{
                return(
                    <div>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"8rem"}}>{record?.email?record?.email:"NA"}</Typography.Text>
                    </div>
                )
            }
        },
        {
            title:<span className={classes.header}>JOB PROFILE</span>,
            dataIndex:"jobTitle",
            key:"jobProfile",
            render:(text,record)=>{
                return(
                    <div>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.5rem"}}>{record?.jobTitle?record?.jobTitle:"NA"}</Typography.Text>
                    </div>
                )
            }
        },
        {
            title:<span className={classes.header}>CURRENT ROUND</span>,
            dataIndex:"round",
            key:"round",
        },
        {
            title:<span className={classes.header}>INTERVIEW DATE</span>,
            dataIndex:"date",
            key:"date",
        },
        {
            title:<span className={classes.header}>INTERVIEW STATUS</span>,
            dataIndex:"interviewStatus",
            key:"interviewStatus",
        },      
        {
            title:<span className={classes.header}>REASON</span>,
            dataIndex:"reason",
            key:"reason",
            render:(text,record)=>{
                return(
                    <div>
                        <Typography.Text style={{maxWidth:"9rem",}} ellipsis={{tooltip:true}}>{text?text:"NA"}</Typography.Text>
                        </div>
                )
            }
        },
    ]

    const handlePageChange = (page, pageSize) => {
        setPageChange(page);
        setPageSize(pageSize);
      };

    
useEffect(()=>{
    setTableLodaer(true)
    let param=  {
        dataKey: dataKeys,
        panelistId:selectedPanelist,
        jobId:selectedJobTitles,
        startDate:startDate,
        endDate:endDate,
        page:pageChange,
        limit:pageSize,
    }
    
    Api.get("/hiring-overview/panelist").params(param).send(res=>{
        if(res)
        {
            setTableLodaer(false)
            if(dataKeys)
            {    
                    setTableData(res)
             } 
                                     
        }
    })
},[selectedJobTitles,selectedPanelist,startDate,endDate,dataKeys,pageSize,pageChange])


    return(
        <Modal 
                open={targetModalOpen} 
                onCancel={()=>{dispatch(overallDashboardActions.updateTargetModalOpen(!targetModalOpen))}} 
                width="62rem"
                bodyStyle={{height: 500}}
                centered 
                className={classes.modal}
                header={null} 
                footer={null}               
                closable={false}              
        >
                    <div className={classes.targetModal}>
                                <div>
                                            <h2>Interview {props?.summery?.title}</h2>
                                            <Button disabled={props?.summery?.data === 0 ? true : false} type="primary" onClick={()=>{
                                                Api.get("/hiring-overview/panelist")
                                                .params({
                                                    panelistId:props?.selectedPanelist,
                                                    download: true,
                                                    dataKey:props?.summery?.dataKey,
                                                    panelistId:selectedPanelist,
                                                    jobId:selectedJobTitles,
                                                    startDate:startDate,
                                                    endDate:endDate,
                                                })
                                                .send((response) => {
                                                  if (typeof response != "undefined") {                                               
                                                    window.open(response, "_blank")?.focus();
                                                  }
                                                });                                          
                                            }}>Export to Excel Sheet</Button>
                                </div>
                                <div>
                                <Table style={{marginBottom:"10rem"}}
                                            columns={columnInfo}
                                            loading={tableLoader}
                                            dataSource={tableData ? tableData : []}
                                            scroll={{x:1150,y:270}}
                                            pagination={{
                                                size:"small",
                                                pageSize: pageSize, 
                                                pageSizeOptions:[20, 30, 50, 100,150],
                                                total: props?.summery?.data,
                                                showSizeChanger:true,
                                                onShowSizeChange: handlePageChange,
                                                onChange:handlePageChange,
                                            }}
                                 />
                                </div>
                    </div>         
        </Modal>
    )
}
export default TargetModal;