class Filter{
    constructor(){
        this.status=false;
        this.jobTitle = false;
        this.institude = false;
        this.collegeName = false;
    }

    resetFilter(){
        this.status=false;
        this.jobTitle = false;
        this.institude = false;
        this.collegeName = false;
    }
}

export default Filter;