import React, { useEffect, useMemo, useState } from "react"
import {Typography,Input,Table, DatePicker, Space, Spin} from "antd"
import {
    InfoCircleOutlined,
    FilterOutlined,
    DownloadOutlined,
    DeleteOutlined,
    SearchOutlined,
    DownOutlined,
    CloseOutlined,
    EyeOutlined,
  } from "@ant-design/icons";
import settings from "../../assets/settings.png";
import Download from '../../assets/download.png';
import sort from "../../assets/sort.png";
import deleteIcon from '../../assets/deleteIcon2.svg';
import filter from "../../assets/filter.svg";
import { useDispatch } from "react-redux";
import { feedbackResponseActions } from "./store";
import { useSelector } from "react-redux";
import { Api } from "services";
import classes from './response.module.css';
import ViewFeedback from "./viewFeedback";
import moment from "moment";
const { RangePicker } = DatePicker;

const Response = (props) =>{
    const [selectionType, setSelectionType] = useState('checkbox');
    const dispatch=useDispatch();
    const [pageChange,setPageChange]=useState(1)
    const [pageSize,setPageSize]=useState(10)
    const {feedbackResponseList,columns}=useSelector(state=>state?.feedbackResponse);
    const [viewFeedbackModal,setViewFeedbackModal]=useState(false);
    const[details,SetDetails] = useState("");
    const [searchedText , setSearchedText] = useState("");
    const [loader,setLoader]=useState(true);
    const [info,setInfo]=useState("");
    const startDate=props?.startDate;
    const setStartDate=props?.setStartDate;
    const endDate=props?.endDate;
    const setEndDate=props?.setEndDate;
   

   useEffect(()=>{
    if(startDate&&endDate)
    {
      setPageChange(1);
    }
   },[startDate,endDate])

  useEffect(()=>{
  
    
    if(startDate===""&&endDate==="")
    { setLoader(true);
      Api.post(`/hiring-feedback/getAllPanelistResponse`).params({
        page:searchedText===""?pageChange:"",
        limit:searchedText===""?pageSize:"",
        search : searchedText,
        feedbackType: props.feedbackType,
      }).send(resp=>{
      
        if(resp)
        {
          setLoader(false);
          dispatch(feedbackResponseActions.updatefeedbackResponseList(resp.feedback.items));
       
          setInfo(resp.feedback.meta);
        }
      })
      return

     
      
    }

    else if(startDate!==""&&endDate!=="")
    { 
      setLoader(true);
      Api.post(`/hiring-feedback/getAllPanelistResponse`).params({
        startDate:startDate,
        endDate:endDate,
        page:searchedText===""?pageChange:"",
        limit:searchedText===""?pageSize:"",
        search : searchedText,
        feedbackType: props.feedbackType,
      }).send(resp=>{
  
        if(resp)
        {
          setLoader(false);
          dispatch(feedbackResponseActions.updatefeedbackResponseList(resp.feedback.items));
       
          setInfo(resp.feedback.meta);
        }
      })
      
    }

  
  },[startDate,endDate])

    // useEffect(()=>{
     
    //   Api.post(`/hiring-feedback/getAllPanelistResponse`)
    //   .params({
    //     search : searchedText
    //   })
    //   .send(resp=>{
    //     setLoader(true);

    //     if(resp)
    //     {
    //       setLoader(false);
    
    //           dispatch(feedbackResponseActions.updatefeedbackResponseList(resp.feedback.items));
    //           dispatch(feedbackResponseActions.updateColumns(resp.columns));
    //     }
    //   })
    // },[searchedText])
  //  const handlePaginationChange =(page,pageSize) =>{

  //   setPageChange(page);
  //     setPageSize(pageSize);
  //  }
  //   const handlePageChange = (page, pageSize) => {

  //     setPageChange(page);
  //     setPageSize(pageSize);
  //   };
      const onSearch = (e) => {
        setSearchedText(e.target.value)
    }
      useEffect(()=>{
     
        setLoader(true);
        Api.post(`/hiring-feedback/getAllPanelistResponse`)
        .params({
          startDate:startDate,
          endDate:endDate,
          search : searchedText,
          feedbackType: props.feedbackType,
          page:searchedText===""?pageChange:"",
          limit:searchedText===""?pageSize:"",
        })
        .send(resp=>{
          
          if(resp)
          { if(resp?.feedback?.items?.length===0){
            
            setPageChange(1)
          }
            setInfo(resp.feedback.meta);
            setLoader(false)
                dispatch(feedbackResponseActions.updatefeedbackResponseList(resp.feedback.items.map((item,index)=>{
                  return {
                    ...item,key:index
                  }
                })));
                dispatch(feedbackResponseActions.updateColumns(resp.columns));
          }
        })
      },[searchedText,pageChange,pageSize,])

      const data=feedbackResponseList?.map((item,index)=>{
        return {
          ...item,key:index
        }
      })

      let column=useMemo(()=>{
         return columns?.map((item,index)=>{
        let render="";
        let header;
        switch(item.key)
        {
          case "jobTitle":
            header=<div>{item.title}</div>
            render=(text,record)=>{
              return(
                <span>{record?.candidate?.job?.title}</span>
              )
            }
            break;

            case "roundType":
            header=<div>{item.title}</div>
            render=(text,record)=>{
              return(
                <span>{record?.roundType === "Relationship" ? "HR Relationship": record?.roundType}</span>
              )
            }
            break;

            case "candidateType":
              header=<div>{item.title}</div>
              render=(text,record)=>{
                return(
                  <span>{record?.candidate?.candidateType}</span>
                )
              }
              break;

              case "candidateName":
                header=<div>{item.title}</div>
                render=(text,record)=>{
                  return(
                    <span>{record?.candidate?.name}</span>
                  )
                }
                break;

                case "feedbackView":
                  header=<div>{item.title}</div>
                  render=(text,record)=>{
                    console.log("sssRecord",record)
                    return(
                      <div>
                            <EyeOutlined  style={{fontSize:"1.4rem",}} onClick={()=>{
                              SetDetails(record)
                              setViewFeedbackModal(true)}} 
                              feedbackType={props.feedbackType}/>                      
                      </div>
                    )
                  }
                  break;
            default:
              header=<div>{item.title}</div>
        }
        
        if(render)
        {
          return{
            title:<div className={classes.headerName}>
                <h1>{header}</h1>
        </div>,
            key:item.key,
            dataIndex:item.key,
            render:render,      
          }
        }
        else
        {
          return{
            title:<div className={classes.headerName}>
                <h1>{header}</h1>
        </div>,
            key:item.key,
            dataIndex:item.key,
            render:render,          
          }
        }
      })
    },[columns,details,viewFeedbackModal])
   
    return(
      <>
          {/* <div className={classes.header}>
                    <h1> Feedback Response Table</h1>
        </div> */}
        <div className={classes.container}>       
           <div className={classes.searchDiv}>  
               <div>    
                <div style={{display:"flex",flexDirection:"row",gap:"1rem"}}>  
                   <div>  
                    <Input
                    size="medium"
                    allowClear={true}
                    style={{width:"18rem"}}
                    placeholder="Search"
                    prefix={<SearchOutlined className="custom-search-icon" />}
                    onChange={onSearch}
                    /></div>
                    <div style={{marginTop:"0.2rem"}}>{(!startDate||!endDate)&&<span style={{color:"#B7B7B7",fontStyle:"italic",fontWeight:"500",}}>(Displaying last 7 days' data)</span>}</div>        
                </div>
               </div>
               <div>   
                      <div className="head-icon">    
                                <img src={Download}
                                     style={{ width: "2rem",cursor:"pointer",color:"#FFFFFF",height: "2rem",marginTop:"-0.1rem"}}  
                                 onClick={()=>{
                                  Api.post('/hiring-feedback/getAllPanelistResponse')
                                     .params({
                                         startDate:startDate,
                                         endDate:endDate,
                                         search : searchedText,
                                         download:true,
                                         feedbackType: props.feedbackType,
                                       })
                                     .send((response) => {
                                      if (typeof response != "undefined") {
                                        window.open(response, "_blank")?.focus();
                                      }
                                   })
                              }}/> 
                      </div>
                      <div>
                            <RangePicker size="medium" onChange={(dates,dateString)=>{
                              console.log("sssDate",dates,dateString)
                              setStartDate(dateString[0])
                              setEndDate(dateString[1])
                             }}
                             value={(startDate&&endDate)&&[moment(startDate), moment(endDate)]}
                             />
                      </div>
                  </div>
            </div>
                  
                <Table
                  loading={loader}
                          className={classes.tableStyle}
                          // rowSelection={{
                          //   type: selectionType,
                          //   ...rowSelection,
                          //   }}
                          columns={column}
                          dataSource={data}
                           scroll={{ x: 1410,y:420 }}
                           pagination={{
                            position: "bottomRight",
                            size:"small",
                            showSizeChanger: true,
                            pageSizeOptions: [10, 20,30, 50, 100],
                            pageSize: info.itemPerPage,
                            total: info.totalItems,
                            current:info?.currentPage,
                            onChange:(page,pageSize) =>{
                          
                             setPageSize(pageSize);
                             setPageChange(page);
                            }
                          }}
                          >
                      </Table>
                  
                      
                      {viewFeedbackModal &&
                            <ViewFeedback
                            feedbackType={props.feedbackType}
                            details={details}
                            viewFeedbackModal={viewFeedbackModal}
                            setViewFeedbackModal={setViewFeedbackModal}/>
                            }                 
           </div>
      </>     
    )
}
export default Response;