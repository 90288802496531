import React, { useState } from 'react';
import { Modal, List, Spin } from 'antd';

const VideoModal = ({ isVisible, videos, setVisible, loader }) => {


    const handleVideoClick = (video) => {
        setSelectedVideo(video);
    };



    return (
        <Modal
            title="Video Player"
            open={isVisible}
            onCancel={() => setVisible(null)}
            footer={null}
        >
            <>
                {
                    loader ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10rem 9rem" }}>
                            <Spin />
                        </div>
                        :
                        <>
                            {
                                videos?.length ?
                                    <>
                                        <div style={{ display: 'flex' }}>

                                            <div style={{ flex: "flex", flexDirection: "column", overflow: "scroll" }}>
                                                <List
                                                    bordered
                                                    dataSource={videos}
                                                    style={{ overflow: "scroll", height: "50vh" }}
                                                    renderItem={(video) => (
                                                        <List.Item onClick={() => handleVideoClick(video)}>
                                                            <video width="100%" controls src={video}></video>
                                                        </List.Item>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                     <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%", width:"100%"}}>
                                        NO DATA
                                     </div>
                                    </>
                            }


                        </>
                }
            </>

        </Modal>
    );
};

export default VideoModal;
