import {
  Button,
  Modal,
  Form,
  Select,
  Row,
  Col,
  Typography,
  Input,
  TreeSelect,
  Upload,
  InputNumber,
  Space
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState, useEffect, useReducer } from "react";
import Api from "../../services/Api";
import "./style.css";
import {
  LoadingOutlined,
  CloseOutlined,
  PlusCircleTwoTone,
  FundProjectionScreenOutlined,
  
} from "@ant-design/icons";
import Notification from "services/Notification";
import fileUpload from "../../assets/file upload icon.svg"
import { useWindowDimensions } from "components/windowDimensions";
// import { useDispatch,useSelector } from "react-redux";
// import pdfLogo from "../../assets/pdf-logo.svg";
// import JdSelect from "../../assets/jdcandidate.svg";
const { Title } = Typography;
const { TreeNode } = TreeSelect;
const { Option } = Select;
function CreateJDModal(props) {
  const {user} = store.getState().auth
  const [form] = Form.useForm();
  const [btnLoader, setBtnLoader] = useState(false);
  const [department, setDepartment] = useState([]);
  const [priority, setPriority] = useState();
  const [page, setPage] = useState(1);
  const [deletedRound,setDeleteRound] = useState([])
  const [interviewRounds, setRound] = useState([]);
 const [roundChng,setRoundChng] = useState(false)
  const[roundUpdate,setRoundUpdate] = useState(false)
  const [roundvalue,setRoundValue] = useState("")
  const [feedbackvalue,setFeedbackValue] = useState("")
  const [Index,setIndex] = useState("")
  const [formValue,setFormValue] = useState("")
  const [phnNo,setPhnNo]= useState()
  const [jdFile,setJdFile] =useState()
  const[poc,setSpoc] = useState("")
  const [remainingRound,setRemainingRound] = useState();
  const [exp,setExp]=useState(new Array(2).fill(0));
  
  const [descriptionData,setDescData]=useState("")
  const [uploadedDoc,setUploadedDoc]=useState('');
  const [btnClick,setBtnClick]=useState(new Array(20).fill(false));
  const [contctName,setCntctName] = useState()
  const [shortlister,setShortlister] = useState("")
  const [imageUrl, setImageUrl] = useState("");
    const [imgFile,setImgFile] = useState("");
    const [disableSave,setDisableSave] =useState(false)
   const [disableAgency,setDisAgency]=useState([]);
   const {width}=useWindowDimensions();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  // const options = [];
  // for (let i = 10; i < 36; i++) {
  //   options.push({
  //     value: i.toString(36) + i,
  //     label: i.toString(36) + i,
  //   });
  // }
  useEffect(() => {
    if (props.selectedObject) { 
     setDisAgency(props.selectedObject.agencies)    
 let maxBackLogs=String(props.selectedObject.maxBackLogs)
 let  maxExperience=String(props.selectedObject.maxExperience)
 let minExperience=String(props.selectedObject.minExperience)
 let vacancies=String(props.selectedObject.vacancies)
 let noticePeriod=String(props.selectedObject.noticePeriod)
 setSpoc(props?.selectedObject?.spoc?.id)

 let round=props.selectedObject.jobInterviewrounds.map((item) =>{
  return {
   roundType:item.roundType,
   feedbackQuestions:item?.feedbackMeta?.id,
   round:item.round

  }
})
if(round && round.length>0){
setIndex(1)
setRoundUpdate(true)
setBtnClick(
  btnClick.map((it, index2) => {
    if (index2 === 1) {
      return true;
    }
    return false;
  })
);
setDisableSave(true)
}


round.sort(function(a, b){return a.round - b.round})
 setRound(round)
  let agencies=props.selectedObject.agencies.map((item) =>{
   return item.agencyName
  
 })
 let location=props.selectedObject.locations?.map((item) =>{
  return item.name
})
let orgType=props.selectedObject.jobType.name
 setRoundUpdate(true)
// delete(props.selectedObject.maxBackLogs)
//  delete(props.selectedObject.maxExperience)
// delete(props.selectedObject.minExperience)
 const selectedObject={
  ...props.selectedObject,
  minExperience:minExperience,
  maxExperience:maxExperience,
  maxBackLogs:maxBackLogs,
  vacancies:vacancies,
  agencies:agencies,
  noticePeriod:noticePeriod,
  location:location,
  jobType:orgType
 }
 let temp1=props.selectedObject.jobInterviewrounds.map((item) =>{
  return item
 })
 setRemainingRound(temp1)
      form.setFieldsValue(selectedObject);
      form.setFieldsValue({ shortlister:props.selectedObject.shortlister?.name  });
      setDepartment(props.selectedObject.departments);
      // setShortlister(props.selectedObject.shortlister?.name)
      if (props.priority) {
        
        setPriority(props.priority);
      }
      setUploadedDoc(props.selectedObject?.descriptionFile)
    }
    
  }, []);
  
  const roundBtnStyle={width:"6.5rem",height:"2.2rem",borderRadius:"4px",color:"#4DAAFF",fontWeight:"600",border:"1.5px solid #4DAAFF",};
  const roundBtnStyle2={width:"6.5rem",height:"2.2rem",borderRadius:"4px",color:"#ffffff",fontWeight:"600",border:"1.5px solid #4DAAFF",backgroundColor:"#4DAAFF",};

  const onDeleteRound =(indexValue) => {
  let deleteId;
  if (props.selectedObject) {
    remainingRound.sort(function (a, b) {
      return a.round - b.round;
    });
    deleteId = remainingRound[remainingRound.length - 1].id;
    setDeleteRound((oldArray) => [...oldArray, deleteId]);
    setRemainingRound(
      remainingRound.filter((element) => element.id !== deleteId)
    );
    let remIntvRounds = interviewRounds.filter(
      (element) => element.round !== indexValue
    );
    let list = [];
    remIntvRounds.forEach((item, index) => {
      item["round"] = index + 1;
      list.push(item);
    });

    setRound(list);
  } else {
    let list = [];
    let remIntvRounds = interviewRounds.filter(
      (element) => element.round !== indexValue
    );
    remIntvRounds.forEach((item, index) => {
      item["round"] = index + 1;
      list.push(item);
    });

    setRound(list);
    // setFeedbackValue("")
    // setRoundValue("")
  
  }
  setFeedbackValue("")
  setRoundValue("")
  setIndex("")
  setBtnClick(
    btnClick.map((it, index2) => {
      if (index2 === 0) {
        return true;
      }
      return false;
    })
  );
 
 setTimeout(() =>{
 },4000)
}

const onJdCreateAndUpdate =() =>{
   setBtnLoader(true)
   let valueHr=[]
   interviewRounds.forEach((element) =>{ 
   if(element.roundType==="HR")
   {
     valueHr.push(element)
     props.toggle();
   }})
   if(valueHr.length===0 || valueHr.length >1 || interviewRounds[interviewRounds.length-1].roundType!=="HR"){
    if(valueHr.length>1){
      Notification.error("Only one Hr round at last is compulsory")
      return
    }
     Notification.error("One Hr round at last is compulsory")
     return
   }
  let intvArr=interviewRounds
  let recordArr=props?.selectedObject?.jobInterviewrounds
  let edited=[]
  let deleteVal=[]
  if(props.selectedObject){
  for(let i =0;i<interviewRounds.length;i++)
  {
    for(let j=0;j<recordArr.length;j++)
    {  
       if(interviewRounds[i].round===recordArr[j].round)
       { 
       intvArr=intvArr.filter((element,index) => element.round!==recordArr[j].round)
         if(interviewRounds[i].roundType!==recordArr[j].roundType || interviewRounds[i].feedbackQuestions!==recordArr[j].feedbackMeta.id)
         {
             
         edited.push(recordArr[j].id)
         
         intvArr.push({id:recordArr[j].id,
        round:interviewRounds[i].round,
        roundType:interviewRounds[i].roundType,
       feedbackQuestions:interviewRounds[i].feedbackQuestions})
         }
    break;
       }
    }
  }

  let map={}
for(let i=0;i<edited.length;i++){
  map[edited[i]]=true
}
for (let i=0;i<deletedRound.length;i++){
  if(map[deletedRound[i]]!==true){
   deleteVal.push(deletedRound[i])
  }
}
}
    let finalvalue={
      ...formValue,
      descriptionData:descriptionData,
      descriptionFile:uploadedDoc,
      interviewRounds:intvArr,
      jobExperience:"Experienced",
      spoc:poc,
      jdfile:jdFile,
      deleted:!props.selectedObject ?deletedRound :deleteVal,
      edited:edited,
      roundCount:interviewRounds?.length
    }
    if (props.selectedObject) {
     
      Api.patch(`/hiring-job/updatejob/${props.selectedObject.id}`).params(
        finalvalue)
        .send(res => {
          setBtnLoader(false);
          if(res!==undefined){
          props.refresh();
          props.toggle();
          }
        }
      );
      return
    }
   
    Api.post("/hiring-job/addJob").params(finalvalue)
    .send(res => {
        props.refresh();
        props.toggle();
      }
    );
  }
  const onFinish = (values) => {
    if(props.selectedObject){
      if(values.agencies.length===0)
      {
        Notification.error("Add Agencies")
        return
      }
    }
    else{
    if(!values.agencies){
 
      Notification.error("Add Agencies")
      return
    }
  }
    // setBtnLoader(true);
  // else{
  //   setPage((page) => page + 1)
  // }
   
    // let requesestBody = { ...values };
    let vacancies=parseInt(values.vacancies)
    let agencyValue={}
    let locationsvalue={}
    values.agencies.forEach((item) => {
       agencyValue[item]=true
  })
  values.location.forEach((item) => {
    locationsvalue[item]=true
})
let locationfinalArray=values.location.filter(element => typeof element !=="string")
  let agencyfinalArray= values.agencies.filter(element => typeof element !=="string")
 
  let agencyId=props?.selectedObject?.agencies.map((item) =>{
    if(agencyValue[item.agencyName]===true)
    {
      return item.id
    }
  })
  let locId=props?.selectedObject?.locations.map((item) =>{
    if(locationsvalue[item.name]===true)
    {
      return item.id
    }
  })
  let finalagency=agencyId?.concat(agencyfinalArray)
  let finalLocation=locId?.concat(locationfinalArray)
 
    let maxExperience=parseInt(values.maxExperience)
    let minExperience= parseInt(values.minExperience)
 
    let noticePeriod = parseInt(values.noticePeriod)
    let jobTypes=[]
   
    if(typeof(values.jobType)==="string"){
     props.orgType.map((item) =>{
       if(values.jobType===item.name){
        jobTypes.push(item.id)
       }
    })}
    let shortlisters=[]
    props?.shortlister?.map((item) =>{
     
     if(item.name===values.shortlister)
        {
          shortlisters.push(item.id)
        }
    })
    delete(values.files)
    delete(values.vacancies)
    if(!poc){
      Notification.error("please select contact person");
      return
    }
    if(uploadedDoc===""){
      Notification.error("Please Upload Job desription","Csv, pdf and docs(Preferred)!");
      return
    }
    // if(!props.selectedObject){
    if(exp[0]>exp[1])
    {
      Notification.error("error","Min experience cannot be more than max experience")
      return
    }
  // }
    // JSON.parse(values.agencies)
    const value={
      ...values,
      keywords:values.keywords,
      shortlister:shortlisters.length!==0 ? shortlisters[0] : values.shortlister,
      vacancies:vacancies,
      maxExperience:maxExperience,
      minExperience:minExperience,
      agencies:!finalagency ? values.agencies : finalagency,
      noticePeriod:noticePeriod,
      location:!finalLocation ?values.location :finalLocation,
      jobType:jobTypes.length===0? values.jobType:jobTypes[0]
    }
  
    setFormValue(value)
    setPage((page) => page + 1)
    return

  };

  // const uploaderProps = {
  //   name: "files",
  //   multiple: true,
  //   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  //   onChange(info) {
  //     setFiles(info.fileList);
  //      Api.post("/hiring-job/uploadDesc")
  //      .upload({file:info.fileList[0].originFileObj}, (percentCompleted, response) => {
  //       setJdFile(response?.data)
  //      })
  //   },
  //   onDrop(e) {
  //   },
  // };

  const getImageSizeKb = (value) => {
    return Math.round(value / 1024);
  };

  const getRoundUi = (Index) => {
    if(Index!=="" && roundChng===false)
    {
         const valued = interviewRounds.find((element,index) => element.round===Index)
      setTimeout(() =>{
        setFeedbackValue(feed?.title)
        setRoundValue(valued?.roundType)
      },300) 
      const feed=props.roundAgency.feedbackQuestion.find((element) => {
      
        return element.id === valued?.feedbackQuestions})
     
       
        
    }
    const onIntvChange = (newValue,type) => {
      setRoundChng(true)
      
      if(type==="round")
      {    setDisableSave(true)
        setRoundValue(newValue)
        setFeedbackValue("")
        return
      }
      setDisableSave(false)
      setFeedbackValue(newValue)
    };
 
      
   
    return (
      <div style={{border: "1px solid var(--unnamed-color-0086ff)",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #0086FF",
        borderRadius: "4px",marginTop:"50px",marginBottom:"50px"}}>
        <Row style={{margin:"20px"}}>
          <Col span={24}>
            ROUND TYPE
            <Select
              showSearch
              placeholder="Select Round type"
              style={{ width: "100%" }}
              value={roundvalue?roundvalue:undefined}
              // optionFilterProp="children"
              onChange={(value) => onIntvChange(value,"round")}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              // }
            >
              {props.roundAgency.roundType.map((item =>{
                return(
                  <><Option value={item}>{item === "Relationship" ? "HR Relationship": item}</Option></>
                )
              }))}
            </Select>
          </Col>
        </Row>
        <Row style={{margin:"20px"}}>
          <Col span={24} style={{marginTop:"100"}}>
            FEEDBACK TYPE
            <Select
              showSearch
              placeholder="Select Feedback Type"
              style={{ width: "100%" }}
              value={feedbackvalue?feedbackvalue:undefined}
              optionFilterProp="children"
              onChange={(value) => onIntvChange(value,"feedback")}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              // }
            >
       {props.roundAgency.feedbackQuestion.map((item,index) =>{
            return(
              <Option value={item.id}>{item.title}</Option>
            )
       })

            }
            </Select>
          </Col>
        </Row>
       <Row style={{margin:"20px"}}>
        <Button 
        //  disabled={interviewRounds.find(element =>element.round===Index && element.roundType==="HR")}  
        style={{color:"#FF6B6B",borderColor:"#FF6B6B"}}
         onClick={() => onDeleteRound(Index)}
    
      >
        DELETE</Button></Row>
        <Row justify="center" style={{marginBottom:"20px"
        }}>
          <Col span={6}>
            <Button
            type="primary"
            disabled={disableSave}
              onClick={() => {
                
                if (Index !== "") {
                  let roundno
                  if(props?.selectedObject?.jobInterviewrounds){
                  roundno = props?.selectedObject?.jobInterviewrounds.find(
                    (element, index) => element.round === Index
                  );
                  }
                  else{
                  roundno = interviewRounds.find(
                    (element, index) => element.round === Index
                  );
                  }
         
                  let valued = interviewRounds.filter(
                    (element) => element?.round !== roundno?.round
                  );
           
                  let value = valued;
                  let temp1 = {
                    round: roundno.round,
                    roundType: roundvalue,
                    feedbackQuestions: feedbackvalue,
                  };
              
                  value.push(temp1);
                  value.sort(function (a, b) {
                    return a.round - b.round;
                  });
                 

                  setRound(value);
                  setDisableSave(true)
                  // setIndex("")
                  return;
                }
                let value = interviewRounds.find(
                  (element) => element.roundType === "HR"
                );
                if (roundvalue === "" || feedbackvalue === "") {
                  Notification.error("Please select Round/feedbacktype");
                  return;
                }
                if (value?.roundType) {
                 
                  let temp = {
                    round: interviewRounds.length,
                    roundType: roundvalue,
                    feedbackQuestions: feedbackvalue,
                  };
                  let list = [];
                  interviewRounds.forEach((item) => {
                    if (item.roundType === "HR") {
                      item["round"] = interviewRounds.length + 1;
                    }

                    list.push(item);
                  });
                  list.push(temp);
        
                  list.sort(function (a, b) {
                    return a.round - b.round;
                  });
                  setRound(list);
                } else {
                  let temp = {
                    round: interviewRounds.length + 1,
                    roundType: roundvalue,
                    feedbackQuestions: feedbackvalue,
                  };
                  setRound((oldArray) => [...oldArray, temp]);
                }
                setDisableSave(true)
                // forceUpdate();
   
             
                setFeedbackValue("");
                setRoundValue("");
              }}
              style={{ width: "100%" }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  };
  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/svg";  
    if (isJpgOrPng) {
      Notification.error("You can  upload CSV,PDF and Docs,Jpg,Png file only!");
      setImageUrl('');
      return;
    }
   
    else {
      getBase64(file, (url) => {

        setImageUrl("");
        setImgFile(file)
        setUploadedDoc("");
        Api.post("/hiring-publicapi/converter").upload({file:file}, (percentCompleted, response) => {                       
          if(response && percentCompleted===100 )
          {  
            setUploadedDoc(response.data.key);
            setDescData(response.data.descriptionData)                    
          }
          if(percentCompleted===100 ||percentCompleted===-1 ){        
          }
        }
      
        )
      });
    }
  }


  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  return (
    <Modal
      width={page == 1 ? 700 : 600}
     
      className="jd-custom-modal"
      visible={props.isOpen}
      centered={true}
      onCancel={() => {
        props.toggle();
      }}
      footer={false}
    >
      

      {page == 1 && (
        <div>
          <Title
            style={{
              textAlign: "center",
              marginBottom: "10px",
              fontSize: width>700?"1.5rem":"1.2rem",
              color: "#161616",
              fontWeight:"700"
            }}
            level={3}
          >
            {props.selectedObject ? "Update" : "Create"} Job Description
          </Title>

          <Form
            form={form}
            onFinish={onFinish}
            layout={"vertical"}
            scrollToFirstError
          >
            <div className="college-modal-scroll" style={{marginBottom:"20px"}}>
              <Row>
                <Col  span={24}>
                  <Form.Item
                    name="title"
                    label="TITLE"
                   
                    className="college-modal-field-sm"
                 
                    rules={[
                      {
                        required: true,
                        message: "Please enter Job title!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input  placeholder=" Enter job title" size="small" 
                    type="text"
                    onChange={(event)=>{
                              //setTouched(true)
                            //   let tempString=event.target.value;
                            //   let validFlag = ((tempString.charAt(0) === tempString.charAt(0).toUpperCase()) && 
                            // (tempString.trim().length > 2 && tempString.trim().length < 100 )) ? true : false;
                            // let validFlag2=true;
                            // for(let i=0; i<tempString.length; i++)
                            // {
                            //   if(tempString[i]!==' ')
                            // {
                            //       if(tempString[i].toUpperCase() === tempString[i].toLowerCase())
                            //       {
                            //         validFlag2=false;
                            //         break;
                            //       }

                            // }
                            // }
                            // setValidTitle(validFlag&&validFlag2);
                    }}
                    
                    
                    />
                  </Form.Item>
                  {/* {!validTitle&&touched&&<p style={{color:"red"}}>(Title field should contains letters only, should start with uppercase letter and length should be within 2-100)</p>} */}
                </Col>
              </Row>
              <div style={{background:"#EAF5FF 0% 0% no-repeat padding-box",width:"100%"}}>
              <Row  style={{marginTop:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"1rem"}}>  
              <div>                          
                   <Form.Item>
                   <Upload
                    name="file"
                    maxCount={1}
                    accept=".csv,.pdf,.jpeg,.png,.docs"
                    showUploadList={imgFile?true:false}
                      // beforeUpload={imgbeforeUpload}
                    style={{ backgroundColor: "#6FC78F",}}
                    onRemove={() => {                                   
                      setUploadedDoc("")
                      setImageUrl("");
                      setImgFile("");
                    }}
                  beforeUpload={imgbeforeUpload} 
                  >
                    <div style={{display:"flex",alignItems:"center",}}>
                      <div>                                                                                  
                                    {!imgFile&&<p>Click on the icon to upload</p>}
                                    <Button  type="text" style={{marginLeft:"30px"}}>
                                            {imgFile==="" ?  <img src={fileUpload} /> : uploadedDoc==="" ?<LoadingOutlined style={{fontSize:"10px"}}/>:""}
                                    </Button>                                                                                              
                      </div>                             
              </div>
                  </Upload>
                   </Form.Item>
                   <div  style={{marginBottom:"10px",justifyContent:"left",display:"flex",flexDirection:"column"}}>
                     {(uploadedDoc!=="") &&<a href={uploadedDoc} target="_blank" >Click to view the Document</a>}
                     <a href={"https://brigosha-hackathon-test-brigosha-5777.s3.ap-south-1.amazonaws.com/file/jobdescription.csv"} target="_blank" style={{color:"green",fontWeight:"bold",}}>Download Excel Sample</a>
                     </div>                     
                   </div>  
                </Row>
                </div>

                <Row>
                <Col span={24}>

                <div>
                    <Form.Item
                      label="ORGANIZATION DEPARTMENT"
                      name="jobType"
                      className="college-modal-field-sm"
                      rules={[
                        {
                          required: true,
                          message: "please select department",
                        },
                      ]}>
                      <Select                        
                          style={{
                          width: "100%",
                        }}
                        showSearch
                        type="text"
                        optionFilterProp="children"
                        value={department}
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        placeholder="Please select department"
                        
                        onChange={(item) => {
                          form.setFieldsValue({ departments: item });
                          setDepartment(item);
                        }}
                      >
                       {props?.orgType?.map((item) =>{
                        return(
                          <Option value={item.id} >{item.name}</Option>
                        )
                       })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    {" "}
                    <Form.Item
                      name="vacancies"
                      label="NUMBER OF OPENING(S)"
                      placeholder=" Enter job title"
                      className="college-modal-field-sm"
                      rules={[
                        {
                          required: true,
                          message: "Please enter vacancies!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input    placeholder=" Enter number of openings" type={"number"} min="1" />
                    </Form.Item>
                  </div>

                  <div>
                    <Form.Item
                      name="priority"
                      label="Priority"
                      className="college-modal-field-sm"
                      rules={[
                        {
                          required: true,
                          message: "please select priority",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="please select priority"
                        showSearch
                        type="text"
                        optionFilterProp="children"
                        value={priority}
                        onChange={(item) => {
                          setPriority(item);
                          form.setFieldsValue({ priority: item });
                        }}
                        allowClear>
                        <Option value="Low" key={1}>
                          Low
                        </Option>
                        <Option value="Moderate" key={2}>
                          Moderate
                        </Option>
                        <Option value="High" key={3}>
                          High
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>

                </Col>
              </Row>
       
           
                  
                  <Row>
                  
                    <Col span={11}>
                    {/* <b style={{font: "normal normal 600 14px Open Sans",color:"#6F6F6F"}}>EXPERIENCE</b> */}
                      <Form.Item
                        className="college-modal-field-sm"
                        name="minExperience"
                        label="MIN EXPERIENCE"
                        rules={[
                          {
                            required: true,
                            message: "Please enter min experience!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                         type={"number"}
                         onChange={(event)=>{
                          setExp(exp.map((item,index)=>{
                            if(index===0)
                            {
                              return parseInt(event.target.value);
                            }
                            return item;
                          }))
                         }}
                          placeholder=" Enter the minimum experience"
                          min={0}
                          max={20}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                      <Form.Item name="maxExperience"
                       label="MAX EXPERIENCE"
                        className="college-modal-field-sm"
                       rules={[
                        {
                          required: true,
                          message: "Please enter max experience!",
                          whitespace: true,
                        },
                      ]}
                      >
                        <Input
                        type={"number"}
                          placeholder="Enter the maximum experience"
                          min={0}
                          max={12}
                          onChange={(event)=>{
                            setExp(exp.map((item,index)=>{
                              if(index===1)
                              {
                                return parseInt(event.target.value);
                              }
                              return item;
                            }))
                           }}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                  name="noticePeriod"
                    className="college-modal-field-sm"
                    label="NOTICE PERIOD"
                    rules={[
                      {
                        required: true,
                        message: "Please enter  Notice period!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="enter notice period" type={"number"}
                    min={0} />
                  </Form.Item>
                  <Form.Item name={"location"
                  } className="college-modal-field-sm" label="LOCATION"
                  rules={[
                      {
                        required: true,
                        message: "Please select locations!",
                      },
                    ]}
                    >
                  <TreeSelect
                        showSearch
                        filterTreeNode={(search, item) => {
                          return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                        }}
                        style={{
                          width: "100%",
                        }}
                       
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        placeholder="Please select locations"
                        allowClear
                        multiple
                        treeDefaultExpandAll
                       
                      > {props.jobLocations.map((item) =>{
                        return(
                          <TreeNode value={item.id} title={item.name}></TreeNode>
                        )
                      })}
                        
                      
                      </TreeSelect>
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Form.Item
                   rules={[
                    {
                      required: true,
                      message: "Please select contact person!",
                      whitespace: true,
                    },
                  ]}
                    className="college-modal-field-sm"
                    label={<><b style={{color:"red",fontSize:"8px"}}>*</b><span style={{paddingLeft:"3px"}}> CONTACT PERSON</span></>}
                  
                  >
                    <Select showSearch type="text"
                      optionFilterProp="children" 
                      placeholder="please Select contact person"   
                      value={(props?.selectedObject&&!contctName) ? props?.selectedObject?.spoc?.name:contctName} 
                      onChange={(value) => {
                      const valued=value.split("-")
                      setCntctName(valued[2])
                      setSpoc(parseInt(valued[0]))
                      setPhnNo(parseInt(valued[1]))}} style={{width:"100%"}} >
                      {props?.poc?.map((item) =>{
                        return(<Option value={`${item.id}-${item.phone}-${item.name}`}>{item.name}</Option>) 
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={1} span={11}>
                  <Form.Item
                  //  rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter Job title!",
                  //     whitespace: true,
                  //   },
                  // ]}
                    className="college-modal-field-sm"
                    label="MOBILE NO"
                  >
                    <Input disabled={true} style={{width:"100%"}} type={"number"} value={props?.selectedObject && !phnNo ? props?.selectedObject?.spoc?.phone:phnNo}/>
                  </Form.Item>
                </Col>
              </Row>
          
              <Form.Item
                      label="SHORTLISTER"
                      name="shortlister"
                      className="college-modal-field-sm"
                      rules={[
                        {
                          required: true,
                          message: "please select Shortlister",
                        },
                      ]}
                    
                      >
                      <Select                        
                        showSearch
                        type="text"
                        optionFilterProp="children"
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        placeholder="Please select shortlister"
                        
                        onChange={(item) => {                        
                          form.setFieldsValue({ shortlister: item });                         
                        }}
                      >
                       {props?.shortlister?.map((item) =>{
                        return(
                          <Option value={item.id}>{item.name}</Option>
                        )
                       })}
                      </Select>
                    </Form.Item>
                  <Form.Item
                  name="keywords"
                  className="college-modal-field-sm"
                    label="KEYWORD"
                  >
                     <Select
    mode="tags"
    style={{
      width: '100%',
    }}
    placeholder="Type and select keywords"
  />
                  </Form.Item>

               {user?.role?.includes("Admin") || user?.role?.includes("Super-Admin") ? 
                  <Form.Item
                  style={{marginBottom:"1rem"}}
                  required={true}
                  name="agencies"
                  className="college-modal-field-sm"
                  label="AGENCY">
                  <TreeSelect
                        showSearch
                        filterTreeNode={(search, item) => {
                          return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                        }}
                        style={{
                          width: "100%",
                        }}
                       
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        placeholder="Please select agencies"
                        allowClear
                        multiple
                        treeDefaultExpandAll
                        onChange={(value)=>{                
                          setDisAgency(value);
                          // (prev)=>[...prev,...props.roundAgency.agencyType.filter(item=>item.id===value[value.length-1])]
                        }}
                      >
                        {props.roundAgency.agencyType.map((item) =>{
                          return(
                            <>
                                    <TreeNode disabled={disableAgency.find(items =>items.id===item.id) || disableAgency.find(items =>items===item.agencyName) ? true : false} 
                                      value={item.id } title={item.agencyName}/>
                             </>
                          )
                        })}
                        {/* <TreeNode value="parent 1" title="parent 1"></TreeNode>
                        <TreeNode value="parent 2" title="parent 2"></TreeNode>
                        <TreeNode value="parent 3" title="parent 3"></TreeNode> */}
                      </TreeSelect>
                    </Form.Item >
                    :
                    ""
                  }
                
              
            </div>
            <Row>
            {exp[0]>exp[1]&&<p style={{color:"red",margin:"0.5rem auto"}}>Min experience can not be greater than max experience!</p>}
            </Row>
            
            {user?.role?.includes("Admin") || user?.role?.includes("Super-Admin") ? 
            <Row  style={{marginTop:"60x"}}>
            <Col span={8} offset={16}>
              <Space>
              <Button   style={{ width: "100px" }} onClick={() =>{
                  props.toggle();
              }}>Cancel</Button>
              <Button disabled={uploadedDoc === undefined ? true : false}
                type="primary"
                htmlType="submit"
                style={{ width: "100px" }}
                // onClick={() => setPage((page) => page + 1)}
              >
                {/* {props.selectedObject ? "Update" : "Create"} */}
                Next
              </Button>
            </Space>
            </Col>
            </Row>
            :
            ""
            }
          </Form>
        </div>
      )}

      {page == 2&& (
        <div>
          <div>
            <Title style={{ fontSize: "15px" }}>Interview Details</Title>
          </div>
          <Row>
            <Col span={23} style={{display:"flex",flexWrap:"wrap",gap:"0.5rem"}}>
              {interviewRounds.map((item, index) => (
                <Button style={btnClick[index+1]?roundBtnStyle2:roundBtnStyle} onClick={() => {
                setDisableSave(true)
                setIndex(item.round)
                setRoundChng(false)
                setBtnClick(btnClick.map((it,index2)=>{
                  if(index+1===index2)
                  {
                    return true;
                  }
                  return false;
                }))
                }}>
                  Round {" " + (index+1)}
                </Button>
              ))}
              <Button style={btnClick[0]?roundBtnStyle2:roundBtnStyle}
              onClick={() =>{
                setDisableSave(true)
                setRoundUpdate(true),
                setBtnClick(btnClick.map((it,index2)=>{
                  if(index2===0)
                  {
                    return true;
                  }
                  return false;
                }))
              setFeedbackValue("")
              setRoundValue("")
              setIndex("")}}>Add</Button>
            </Col>
          </Row>
          <Row>
            {roundUpdate && <Col span={24}>{getRoundUi(Index)}</Col>}
          </Row>
          {props.selectedObject?<span style={{color:"red"}}>*Round Type And Feedback Type should be saved before clicking on Update</span>:""}
          <Row justify="center" style={{marginTop:"20px",}}>
            
            <Col span={6}>
              <Button
                onClick={() => setPage((page) => page - 1)}
                style={{ width: "100%" }}
              >
                Back
              </Button>
            </Col>
            <Col span={6} offset={1}>
              <Button
                // loading={btnLoader}
                type="primary"
                // htmlType="submit"
                style={{ width: "100%", }}
                onClick={() => {
                onJdCreateAndUpdate();
              //  setTimeout(()=>{
              //   window.location.reload();
              //  },3000)
              }}
              >
                {props.selectedObject ? "Update" : "Create"}
                {/* Create */}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
export default CreateJDModal;