import React, { useEffect,useState } from "react";
import { Table,Tag,Space,Button,Spin} from "antd";
import classes from './home.module.css';
import { useDispatch, useSelector } from "react-redux";
import { LocAndDeptActions } from "./store";
import { Api } from "services";

const Department = (props) =>{
  const dispatch=useDispatch();
  const deptDetails=useSelector(state=>state?.LocAndDept?.deptDetails);
  const loader=props?.loader;
  const [datas,setData]=useState("")
  const setLoader=props?.setLoader;
  const searchValue=props?.searchVal;

  // const [pageChange, setPageChange] = useState(1);
  // const [pageSize, setPageSize] = useState(10);


  useEffect(()=>{
    setLoader(true);
    let par={
      page:props?.pageChange,
      limit:props?.pageSize,  
    }
    if(searchValue)
    {
      par= {search:searchValue,}
    }
    Api.get("/hiring-job/orgDeps")
    .params(par)
    .send(resp=>{
      if(resp){
      
        setLoader(false);
        dispatch(LocAndDeptActions.updateDeptDetails(resp?.items));
        setData(resp?.meta)
      }
    })
  },[props?.pageChange,props?.pageSize,searchValue])
   

      const columns = [
        {
          title:<h4 className={classes.title}> ORGANIZATIONAL DEPARTMENT</h4>,
          dataIndex: 'name',
          key: 'name',
          render: (text) => {
     
          return(<div style={{color:"#1890FF"}}>{text}</div>)},
        },
        {
          title: <h4 className={classes.title}>ACTION</h4>,
          key: 'action',
          render: (_, record) =>
          {        
            return (
            <div className={classes.editBtn}>                  
                    <Button
                    type="primary"
                     onClick={()=>{
                      dispatch(LocAndDeptActions.updateSearch(searchValue))
                      dispatch(LocAndDeptActions.updateEditDeptModal(true))
                      dispatch(LocAndDeptActions.updateDeptId(record?.id));
                      dispatch(LocAndDeptActions.updateDeptName(record?.name));
                 }}>Action</Button>
            </div>)}
        },
      ];
      
    return(
        <>
             <Table columns={columns} dataSource={deptDetails} scroll={{y:450,x:1000}}
             loading={loader}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: [10,20, 30, 50, 100],
                pageSize: datas?.itemsPerPage,                
                onChange: (page,pageSize) =>{
                    props.setPageChange(page)
                    props?.setPageSize(pageSize)
                },
                onShowSizeChange: (page,pageSize) =>{
                    props?.setPageChange(page)
                    props?.setPageSize(pageSize)
                },
                total: datas?.totalItems,
                current: parseInt(datas?.currentPage),
              }}
              />
        </>
    )
}
export default Department