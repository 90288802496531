import {
    Space,
    Table,
    Tag,
    Button,
    Descriptions,
    PageHeader,
    Modal,
    Form,
    Select,
    Row,
    Col,
    Typography,
    Input,
  } from "antd";
  import { useForm } from "antd/lib/form/Form";
  import React, { useState, useEffect } from "react";
  import Api from '../../services/Api';
  import {states} from './store';
  import { PlusCircleFilled, HeartTwoTone, SmileTwoTone } from '@ant-design/icons';
  import Notification from '../../services/Notification';
  const { Title } = Typography; 
  function UpdateCollegeModal(props) {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [selectedState, setSelectedState] = useState([]);
    const [btnLoader, setBtnLoader] = useState(false);
    const [allState,setStates] = useState();
    const { Option } = Select;
    const [data,setData] = useState();
    const [emailElements,setEmailElements] = useState([{
      index : 1,
    }]);   
    useEffect(() => {    
        form.setFields([{
          name : "abc"
        }])
        let count = 1;
        let tempEmailElements = [{
          index : 1,
          email : props.collegeData.email,
          phone : props.collegeData.phone
        }]
        if(props.collegeData.secondaryEmail){
          tempEmailElements.push({
            index : 2,
            email : props.collegeData.secondaryEmail,
            phone : props.collegeData.secondaryPhone
          })
        } 

        if(props.collegeData.tertiaryEmail){
          tempEmailElements.push( {
            index : 3,
            email : props.collegeData.tertiaryEmail,
            phone : props.collegeData.tertiaryPhone
          })
        }

        setEmailElements(tempEmailElements);
        setData(props.collegeData);
    },[]);
  
    const onFinish = (values) => {
      if(values.email1){
         values['email'] = values.email1
         delete values['email1']
      }
      if(values.phone1){
        values['phone'] = values.phone1
        delete values['phone1']
      }
      let requestParams = {...values};
          Api.post('/hiring-college/addCollage')
        .params(requestParams)
        .send((response) => {        
          if (typeof response != undefined && response?.show?.type=="success") {          
            props.toggle();
             } 
            });
    };
    const fetchOrder = (index,value) => {
        switch(index){
            case 1: return "Primary "+ value;
            case 2: return "Secondary " + value;
            case 3: return "Tertiary "+value;
            default : return "abcd"
        }
      }
    const updataCollegDetails = (values) => {
      let requestBody = {
        "name" : values.name == undefined ? data.name : values.name,
        state : values.state == undefined ? data.state : values.state,
        address: values.address == undefined ? data.address : values.address,
        district : values.district == undefined ? data.district : values.district,
        email : values.email == undefined ? data.email : values.email,
        phone : values.phone == undefined ? data.phone : values.phone
      };
      if(values.email2 || data.secondaryEmail){
        requestBody['secondaryEmail'] = values.email2 ? values.email2 : data.secondaryEmail;
        requestBody['secondaryPhone'] = values.phone2 ? values.phone2 : data.secondaryPhone
      }
      if(values.email3 || data.tertiaryEmail){
        requestBody['tertiaryEmail'] = values.email3 ? values.email3 : data.tertiaryEmail;
        requestBody['tertiaryPhone'] = values.phone3 ? values.phone3 : data.tertiaryPhone;
      }    
      // Api.patch(`/hiring-college/updateCollege/${data.ids}`)
      //   .params(requestBody)
      //   .send((response) => {
      //     // setBtnLoader(false);
      //     if (typeof response != undefined && response?.type=="success") {
      //       // setIsModalVisible(false)
      //       Notification.success(response?.message);
      //       props.toggle();
      //       props.fetchColleges();
      //        } else {
      //         Notification.error("Unable to update Institution");
      //        }
      //       });
    }     
    return (
      <Modal
        title={props.title}
        visible={props.isOpen}
        onCancel={()=>{
          props.toggle();
        }}
        footer={false}
      >

        {
            data ? 
            <Form
            form={form}
            onFinish={updataCollegDetails}
            layout={"vertical"}
            scrollToFirstError
          >
            <Form.Item
              name="name"
              label="Name"
              placeholder=" Enter College name"
              rules={[
                {
                  required: data.name ? false :true,
                  message: "Please input college name!",
                  whitespace: true,
                },
              ]}
            >
              <Input  defaultValue={data.name} value={data.name}/>
            </Form.Item>  
            {
                emailElements.map( (e)=>{
                 return(<>
                  <Row> <Col span={10} offset={1}>
                  <Form.Item
                    name={`email${e.index}`}
                    label={fetchOrder(e.index,"Email")}
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: e.email || e.index>1 ?false:true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    <Input value={data.email} defaultValue={e.email}/>
                  </Form.Item>
                  
                </Col>
                <Col span={10} offset={1}>
                  <Form.Item
                    name={`phone${e.index}`}
                    label={fetchOrder(e.index,"phone")}
                    rules={[
                      {
                        min: 10,
                        message: "The input is not valid phone number!",
                      },
                      {
                        required: e.phone || e.index>1 ?false:true,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Input 
                    type={"number"}
                    minLength={10}      
                    style={{
                      width: "100%",
                    }}
                    value={data.phone}
                    defaultValue={e.phone}
                    />
                  </Form.Item>
                 
                </Col><Col span={1} offset={0} style={{marginTop:"30px"}}> {
                    e.index != 1 && <Button onClick={()=>{
                      let temp = emailElements.filter(element => element.index != e.index)
                      setEmailElements(temp);
                    }}>-</Button>
                  }</Col></Row></>)})
              
              }
              <div>
              {(emailElements.length < 3) && <PlusCircleFilled twoToneColor="#1890ff" style={{float : "right"}} onClick={()=>{ setEmailElements([...emailElements,{
                  index : emailElements.length + 1
                }])}}/>}
              </div>
            <Row>
              <Col span={11} offset={1}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: data.state ? false : true }]}
                >
                  {/* <Select
                        
                        placeholder="Select a option"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        id="state-id"
                  >
                    {
                      allState?.map((e,index) => <Option key={e.name + index} value={e.name}>{e.name}</Option>)
                    }
                  </Select> */}
                  <Input  defaultValue={data.state}/>
                </Form.Item>
              </Col>
    
              <Col span={11} offset={1}>
    
                <Form.Item
                  name="district"
                  label="District"
                  rules={[{ required: data.district ? false : true }]}
                >
                  <Input  defaultValue={data.district}/>
                  {/* <Select
                    placeholder="Select a option "
                    // onChange={onGenderChange}
                    onChange={handleChange}
                    allowClear
                  >
                    {
                      selectedState.districts?.map(e => <Option value={e.name}>{e.name}</Option>)
                    }
                  </Select> */}
                </Form.Item>
              </Col>
            </Row>
    
            <Row>
              <Col offset={1} span={23}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: data.address ? false : true,
                      message: "Please input your Full Address",
                    },
                  ]}
                >
                  <TextArea placeholder="Please input your Full Address" allowClear  defaultValue={data.address}/>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              wrapperCol={{
                span: 24,
                offset: 0,
              }}
            >
              <Row justify="center">
                <Col span={6}>
                  <Button onClick={props.close} style={{ width: "100%" }}>
                    Cancel
                  </Button>
                </Col>
                <Col span={6} offset={1}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    // loading={btnLoader}
                  >
                    ADD
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>  : ""
        }
                
      </Modal>
    );
  }
  export default UpdateCollegeModal