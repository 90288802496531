import { createSlice } from "@reduxjs/toolkit";
import store from "pages/dashboard-home/store";
const initialState = {
    collegeList: [],
  info: {},
  jd: [],
  collegeType:[],
  instituteStatus:[]
};
const collegeListSlice = createSlice({
  name: "CollegeList",
  initialState,
  reducers: {
    updateCollegeList: (state, action) => {
      state.collegeList= [ ...action.payload];
    },
     updateInfo(state, action) {
      state.info = { ...action.payload };
    },
       updateJD: (state, action) => {
      state.jd= [ ...action.payload ];
    },
    updateCollegeTYPE: (state, action) => {
      state.collegeType= [ ...action.payload ];
    },
       updateInstituteStatus: (state, action) => {
      state.instituteStatus= [ ...action.payload ];
    },
  },
});
export default collegeListSlice.reducer;
export const collegeListActions = collegeListSlice.actions;
