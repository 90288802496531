import React, { useState } from "react";
import styled from "./style.css";
import { Form, Modal, Button, Upload, Col, Row, Typography,Result } from "antd";
import Api from "services/Api";
import { InboxOutlined } from "@ant-design/icons";
import Notification from "services/Notification";


const normFile = (e) => {
  
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};
const { Title } = Typography;

const ImportView = ({ isOpen ,setImportModal,refresh}) => {
 
   const [isModalVisible,setIsModalVisible] = useState(false)
  const [duplicateErrorObj,setDuplicateError] = useState();
  const [btnLoader,setBtnLoader] = useState(false);
  const [loader ,setLoader] = useState(false);


  const onFinish = (values) => {
    if((!values?.dragger) || (values?.dragger.length===0)  )
    {   let title="please import file"
      Notification.error(title)
      return
      
    }
    const params = {
      file:values?.dragger[0]?.originFileObj,
      };
    setLoader(true)
    setBtnLoader(true)
    Api.post("/hiring-candidate/storeExcelData")
    .upload(params, (response) => {

      setBtnLoader(false)
      setLoader(false);
      setImportModal(false);
      refresh();
      
      
      if (typeof response != "undefined") {
      
        
        if(response?.data?.show.type == "error"){ 
          setDuplicateError(response.data);
          setIsModalVisible(true);
        } else if(response?.data && response?.data?.show && response?.data?.show?.type == "success"){
          refresh();
        }  else {
          setIsModalVisible(true); 
          refresh();
        }
      }
    });
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    setBtnLoader(false)
  };


  return (
  <>    
    <div className={styled}>
      <>
        <Modal
          visible={isOpen}
          closable={true}
          maskClosable={true} 
          onCancel={()=>{
            refresh();
            setImportModal(false);
          }}
          footer={false}
        >
          <Title
            style={{
              textAlign: "center",
              margin: "1rem",
              marginBottom: "2rem",
            }}
            level={3}
          >
            Import data
          </Title>
          <Form onFinish={onFinish}>
            <Form.Item
              name="dragger"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
             
              <Upload.Dragger >
               <div style={{textAlign:"center",display:"flex",flexDirection:"column",flexWrap:"nowrap",alignItems:"center"}}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
               </div>
              </Upload.Dragger>
             
            </Form.Item>{" "}
            <br /> <br />
            <Form.Item
              wrapperCol={{
                span: 24,
                offset: 0,
              }}
            >
              <Row justify="center">
                <Col span={6}>
                  <Button onClick={()=>{
                     refresh();
                     setImportModal(false);
                    }} style={{ width: "100%" }}>
                    Cancel
                  </Button>
                </Col>
                <Col span={6} offset={2}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                    }}
                    loading={btnLoader}
                  >
                    UPLOAD
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </>
    </div>

    {isModalVisible ? 
  <Modal title="Colleges already present in portal-this" 
    visible={isModalVisible}  
    centered
    closable={true}
    maskClosable={true} 
    onCancel={handleCancel} 
    footer={false} 
    style={{minWidth:500}}>

    <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
        style={{height:"20%"}}
    />
    {duplicateErrorObj && duplicateErrorObj.duplicates?.map((duplicate) => {
      return(
        <Row>
          <Col span={8}><p><b style={{marginRight:"10px"}}>name:</b>{duplicate.name}{"  "}</p>
          </Col><Col span={8}><p><b style={{marginRight:"10px"}}> email:</b>{duplicate.email}</p></Col>
          <Col span={8}><p><b style={{marginRight:"10px"}}>phone:</b>{duplicate.phone}</p></Col>
        </Row>
      )
    })}
   </Modal>
   : "" }
  </>
  );
};

export default ImportView;
