import "./styles.css";
import React from "react";
import "services/styles.css";
import {SEARCH_VALUE} from "services"
import { connect } from "react-redux";
import { PageHeader, Tabs,Input } from "antd";
import { SearchBar } from "services/button";
import HrProcessCandidate from "./hrProcessCandidate";
import HrProcessCandidateCard from "./hrProcessCandidateCard";
import { operationType,inputFieldType, inputFieldTypeHash } from "./redux/action";
import CandidateViewModal from "../candidate/candidateViewModal";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import {performOperation} from "./redux/action";
import { SearchOutlined,} from "@ant-design/icons";
const { TabPane } = Tabs;
class HrPorcess extends React.Component {
  componentDidMount()
 {
        this.props.updateInputField(inputFieldType.HR_PROCESS_CANDIDATE_CLEAR, [])
        this.props.getAllCandidates(this.props.history)
        this.props.performOperation(operationType.HR_PROCESS_CANDIDATE_SCREEN_TYPE, "HrProcess")
        this.props.getCandidatesOnTabChange(this.props.history)
  }

  onSearch = (e) => {
          this.props.performOperation(inputFieldType.HR_PROCESS_SEARCH_VALUE, e.target.value);
          this.props.getAllCandidates(this.props.history);
  }

  render() {
    const { candidateScreenType } = this.props;
    return (
      <>        
            <h1 style={{ margin: "1% 0 0 2%",fontWeight:"bold",color:"#161616",fontSize:"1.5rem",}}>HR Process</h1>           
            <div style={{padding:" 0.5rem 1.5rem 1.5rem 1.5rem",}}>
              <PageHeader onBack={() => null}  backIcon={false}/>
              <HrProcessCandidateCard />
              <Tabs
                          defaultActiveKey="HrProcess"
                          activeKey={"HrProcess"}
                          tabBarExtraContent={<Input size="medium" style={{width:"17rem"}}
                          prefix={<SearchOutlined className="custom-search-icon" />} placeholder={" Search"} onChange ={this.onSearch} allowClear={true}/>}
                          style={{overflow:"hidden"}}
                        >
                          <TabPane tab="HR Process" key="HrProcess">
                                       <HrProcessCandidate />
                          </TabPane>
              </Tabs>
              <CandidateViewModal />
            </div>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HrPorcess);
