import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   
    testList:[],
    sectionDetails:{},
    details:{},
    testDetails:{},
     columnFeature:[],
     allColumn:[],
     disable:true,
     disableId:"",
     resetFeilds:false,
     sectionDetailsSave:[],
     Status:"Active"
    
    
};

const testSlice = createSlice({
  name: "test",
  initialState,
  
  reducers: {
    updateSectionDetails(state,action){
     state.sectionDetailsSave=action.payload
    },
    updatereset(state,action){
      state.resetFeilds=action.payload
    },
    updateTest(state, action) {
      state.testList = [ ...action.payload.tests ];
    },
    updateQuestionDetail(state, action) {
  
      state.sectionDetails = {...action.payload};
    },
     updateTestDetails(state,action)
     {
       state.testDetails = {...action.payload.details}
     },
   updateDetails(state,action)
   {
    state.details = {...action.payload.details}
   },
 
  
   
   updateColumns(state,action){
    state.columnFeature=[...action.payload]
   },
   AllColumns(state,action){
     state.allColumn=[...action.payload.rawmappedColumns
     ]
   },
   updateDisable(state,action){
    state.disable=action.payload
   },
   updateDisableId(state,action){
    state.disableId=action.payload
   },

   setStatus(state,action)
   {
      state.Status=action.payload;
   }
  
   
  },
});

export default testSlice.reducer;

export const testActions = testSlice.actions;
