import React, { useState } from "react";
import { Card, Form, Input, Row, Col, Radio, Button, Select } from "antd";
import { mapDispatchToProps, mapStateToProps } from "../redux/container"
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect, useDispatch, useSelector } from "react-redux";
import { HrFeedbackActions } from "./store";
const { Option } = Select;
const LocationDetails = (props) => {
    const dispatch = useDispatch()
    const { basicInfo } = useSelector((state) => state.HrFeedback)
     const { feedback } = useSelector((state) => state.candidate.feedbackCandidateInfo)
   let feedbackType = feedback.find((value) => value.roundType === "HR")
    const onFInish = (values) => {
        props.onNext("financialDetails")
        dispatch(HrFeedbackActions.updatefeedback(values))
        //   updateInputField(`FEEDBACK_INPUT_40`, values.currentlocation);//mother
        //   updateInputField(`FEEDBACK_INPUT_41`, values.currentworklocation);
        //   updateInputField(`FEEDBACK_INPUT_42`, values.native);
        //   updateInputField(`FEEDBACK_INPUT_38`, values.prefferedlocation);

    }
    const goBack = () => {
        props.onBack("offerDetails")
    }
    // //     let academicsX=""
    // let currentlocation=""
    // let currentworklocation=""
    // let preferredLocation=""
    // let native=""
    //   if(!basicInfo)
    //   {
    //     currentlocation=""
    //  currentworklocation=""
    //  preferredLocation=""
    //   native=""


    //   }
    //   else{
    //     currentlocation=basicInfo.currentlocation
    //     currentworklocation=basicInfo.currentworklocation
    //     preferredLocation=basicInfo.preferredLocation
    //     native=basicInfo.native

    //   }
    return (
        <>
            <Card title="Location Details" style={{ border: "black" , width: "100%" }} >
                <Button type="text" onClick={goBack}><ArrowLeftOutlined /></Button>
                <Form onFinish={onFInish} layout="vertical">
                    <Row><Col span={12}>
                        <Form.Item
                            name="CurrentLocation"
                            label={<b>Current Location</b>}
                            initialValue={feedbackType?.details?.CurrentLocation!==undefined  && !basicInfo?.CurrentLocation ? feedbackType?.details?.CurrentLocation   :   basicInfo?.CurrentLocation}
                        >
                            <Input bordered={false} style={{ borderBottom: "2px  solid grey" }} />
                        </Form.Item>
                    </Col>
                        <Col span={11} offset={1}>
                            <Form.Item
                                name="CurrentWorklocation"
                                label={<b>Current Work Location</b>}
                                initialValue={feedbackType?.details?.CurrentWorklocation!==undefined  && !basicInfo?.CurrentWorklocation ? feedbackType?.details?.CurrentWorklocation :basicInfo?.CurrentWorklocation}
                            >
                                <Input bordered={false} style={{ borderBottom: "2px  solid grey" }} />
                            </Form.Item>
                        </Col></Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="PreferredLocation"
                                label={<b>Preffered Location</b>}
                                rules={[{
                                    required: true
                                }]}
                                initialValue={feedbackType!==undefined ? feedbackType?.details?.PreferredLocation   : basicInfo?.PreferredLocation}
                            >

                                <Select placeholder="Please select location">
                                    <Option value="Bangalore">Bangalore</Option>
                                    <Option value="Coimbatore">Coimbatore</Option>
                                    <Option value="both">both</Option>
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={11} offset={1}>
                            <Form.Item
                                name="Native"
                                label={<b>Native</b>}
                                initialValue={feedbackType?.details?.Native !==undefined  ? feedbackType?.details?.Native :basicInfo?.Native}
                            >
                                <Input bordered={false} style={{ borderBottom: "2px  solid grey" }} />
                            </Form.Item>
                        </Col></Row>


                    <Row justify="end" marginTop="10px"><Button size="large" style={{ paddingLeft: "50px", paddingRight: "50px" }} type="primary" htmlType="submit">Save And Next</Button></Row>

                </Form>

            </Card>

        </>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails)