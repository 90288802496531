import React from "react";
import classes from './log.module.css';
import {
  Table,
  Row,
  Col,
  Input,
  Button,
  Tabs,
  Space,
  Modal,
  Dropdown,
  Typography,
  Switch,
  Checkbox,
  Popover,
  DatePicker,
  List,
  Radio
} from "antd";
import { SearchOutlined, DownOutlined, CheckCircleOutlined, CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import filter from "../../assets/filter.png";
import Download from '../../assets/download.png';
import searchIcon from '../../assets/searchIcon.png';
import UserIcon from '../../assets/user-icon.svg'
import Api from "services/Api";
import { useState, useEffect } from "react";
import Filter from "./filter";
import moment from "moment";
const { TabPane } = Tabs;
const { Title } = Typography
const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;
const filterObj = new Filter();


const LogsActions = () => {
  const [loader, setLoader] = useState(false)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [logAction, setLogs] = useState([])
  const [searchValue, setSearch] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [ellipsis, setEllipsis] = useState(true);
  const [pageChange, setPageChange] = useState("");
  const [pageSize, setPageSize] = useState("");
  const [userFilter, setUserFilter] = useState([]);
  const [paginations, setPaginations] = useState();
  const [moduleFilter, setModuleFilter] = useState([]);
  const [moduleFilter2, setModuleFilter2] = useState([]);
  const [userFilters2, setUserFilters2] = useState([]);
  const [logFilter, setlogFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const [filterDetails, setFilterDetails] = useState(false);
  const [logTypeCheck, setLogTypeCheck] = useState(new Array(10).fill(false));
  // const [statusCheck,setStatusCheck]=useState(new Array(10).fill(false));
  // const [statusCheck,setStatusCheck]=useState(new Array(10).fill(false));
  const [filterShows, setFilterShows] = useState([
    { title: "User", value: "role" },
    { title: "Module", value: "tableType" },
    { title: "Log Info", value: "logType" },
  ]);
  const [filterState, setFilterState] = useState({
    "role": false,
    "tableType": false,
    "logType": false,
  });

  const Logs = ["All", "Success", "Error"]


  const filterList = () => {
    let list = [];

    filterShows.map(element => {

      switch (element.value) {

        case "role":
          if (filterObj.role && users.length > 0) {

            // let description =users?.value ;

            let map = {}
            let values = []
            users.forEach((element) => {
          
              map[element.value] = true
            })
            userFilters2.forEach((element) => {
              if (map[element.value] === true) {
                values.push(element.value)
              }
            })

            list.push({
              ...element,
              discription: values?.map(element => element).join(", "),
            });
          }
          break;
        case "tableType":
          if (filterObj.tableType && modules.length > 0) {
            // let description = moduleFilter2 ;
            let map = {}
            let values = []
            modules.forEach((element) => {
              
              map[element.value] = true
            })
            moduleFilter2.forEach((element) => {
              if (map[element.value] === true) {
                values.push(element.value)
              }
            })

            list.push({
              ...element,
              discription: values?.map(element => element).join(", "),
            });
          }
          break;
        case "logType":
          if (filterObj.logType && logFilter !== "") {
            let description = logFilter;

            list.push({
              ...element,
              discription: description
            })
          }
          break;

        default:
          break;
      }
    })
    return list;
  }
  

  useEffect(() => {
    if (userFilter.length > 0) {
      setUserFilters2(userFilter.map(item => ({ value: item, check: false })))
    }
  }, [userFilter.length > 0])


  useEffect(() => {
    if (moduleFilter.length > 0) {
      setModuleFilter2(moduleFilter.map(item => ({ value: item, check: false })))
    }
  }, [moduleFilter.length > 0])


  const customFilterPopover = (type = null, logo) => {
    let contentBody;

    switch (type) {

      case "role":

        contentBody = <div className="sort-popover">
          <div style={{ width: '97%', display: "flex", flexDirection: "column", marginBottom: "1rem", maxHeight: "22vh", overflowY: "auto", }}>

            {
              userFilters2.map((item, index) => {
                return (
                  <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={item.check}
                      value={item.id}
                      onChange={(e) => {
                        let value = e.target.value;
                        setUserFilters2(userFilters2.map((items, index2) => {
                          if (index === index2) {
                            return {
                              ...items, check: e.target.checked
                            }
                          }
                          return items;
                        }))

                        //    if(!e.target.checked)
                        // {
                        //   setUserFilters2( userFilter.filter(arr =>{} ))
                        //   return 
                        // }

                        // setUserFilters2(arr => [...arr,value])
                      }}
                    />
                    <span>{item.value}</span>
                  </div>
                )
              })
            }
          </div>
          <div className="sort-action">
            <Button className="cancel" onClick={() => {
              let temp = filterState;
              temp.user = false
              setFilterState(temp)
              forceUpdate();
            }}>CANCEL</Button>
            <Button className="float-right ok" onClick={() => {
              getfilterRequest(type);
              filterLogs()
              setUsers(userFilters2.filter(arr => arr.check === true))
              let temp = filterState;
              temp.user = false
              setFilterState(temp)
              forceUpdate();
            }}>OK</Button>
          </div>
        </div>
        return <Popover
          placement="bottom"
          content={contentBody}
          trigger="click"
          visible={filterState.user}
          onVisibleChange={() => {
            let temp = filterState;
            if (filterState.user === false) {
              temp.user = true
              setFilterState(temp)
              forceUpdate();
            }
            else {
              temp.user = false
              setFilterState(temp)
              forceUpdate();
            }
          }}
        >
          {logo}
        </Popover>

      case "tableType":
        contentBody = <div className="sort-popover">
          <div style={{ width: "97%", maxHeight: "22vh", overflowY: "auto", }}>

            {
              moduleFilter2.map((item, index) => {
                return (
                  <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={item.check}
                      value={item.id}
                      onChange={(e) => {
                        let value = e.target.value;
                        setModuleFilter2(moduleFilter2.map((items, index2) => {
                          if (index === index2) {
                            return {
                              ...items, check: e.target.checked
                            }
                          }
                          return items;
                        }))
                      }}
                    />
                    <span>{item.value}</span>

                    {/* // let value=e.target.value
              // if(!e.target.checked)
              // {
              //   setModuleFilter2( moduleFilter.filter(arr => {} ))
              //   return 
              // }else{
              // setModuleFilter2(arr => [...arr,value])
              // return
              // } */}
                  </div>
                )
              })
            }
          </div>

          <br></br>
          <div className="sort-action">
            <Button className="cancel" onClick={() => {
              let temp = filterState;
              temp.module = false
              setFilterState(temp)
              forceUpdate();

            }}>CANCEL</Button>
            <Button className="float-right ok" onClick={() => {
              getfilterRequest(type);
              filterLogs()
              setModules(moduleFilter2.filter(arr => arr.check === true))
              let temp = filterState;
              temp.module = false
              setFilterState(temp)
              forceUpdate();
            }}>OK</Button>
          </div>
        </div>
        return <Popover
          placement="bottom"
          content={contentBody}
          trigger="click"
          visible={filterState.module}
          onVisibleChange={() => {
            let temp = filterState;
            if (filterState.module === false) {
              temp.module = true
              setFilterState(temp)
              forceUpdate();
            }
            else {
              temp.module = false
              setFilterState(temp)
              forceUpdate();
            }
          }}
        >
          {logo}
        </Popover>
        break;

      case "logType":
        contentBody = <div className="sort-popover">
          <div style={{ width: '100%', marginBottom: "1rem", }}>
            {
              Logs?.map((item, index) => {
                return (
                  <div>
                    <input type="radio" checked={logTypeCheck[index]} onChange={(e) => {
                 
                      setLogTypeCheck(logTypeCheck.map((item2, index2) => {
                        if (index === index2) {
                          if (e.target.checked) {
                            return true;
                          }
                          return false;
                        }
                        return false;
                      }))
                      if (!e.target.checked) {
                        setlogFilter(Logs.filter(arr => arr !== item))
                        return
                      }
                      setlogFilter(item)

                    }} />
                    <span style={{ fontSize: "0.9rem" }}> {item}</span>
                  </div>
                )
              })
            }
          </div>

          <div className="sort-action">
            <Button className="cancel" onClick={() => {
              let temp = filterState;
              temp.loginfo = false
              setFilterState(temp)
              forceUpdate();

            }}>CANCEL</Button>
            <Button className="float-right ok" onClick={() => {
              getfilterRequest(type);
              filterLogs()
              let temp = filterState;
              temp.loginfo = false
              setFilterState(temp)
              forceUpdate();
            }}>OK</Button>
          </div>
        </div>
        return <Popover
          placement="bottom"
          content={contentBody}
          trigger="click"
          visible={filterState.loginfo}
          onVisibleChange={() => {
            let temp = filterState;
            if (filterState.loginfo === false) {
              temp.loginfo = true
              setFilterState(temp)
              forceUpdate();
            }
            else {
              temp.loginfo = false
              setFilterState(temp)
              forceUpdate();
            }
          }}
        >
          {logo}
        </Popover>
        break;

      default:
        break;

    }
  };

  const resetFilter = () => {
    filterObj.resetFitler();
    filterLogs();
    
  }

  const getLogs = (request) => {
    const Par = {
      ...request
    }
    setLoader(true)
    Api.post("/hiring-users/logs")
      .params(Par)
      .send((response) => {
        if (typeof response != "undefined") {
          
          setLogs(response?.items)
          setUserFilter(response?.role)
          setModuleFilter(response?.tableType)
          setPaginations(response?.meta)
          setLoader(false)
        }
      });
  }

  useEffect(() => {
    let request = {
      search: searchValue,
      startDate: startDate,
      endDate: endDate,
      // page: searchValue === "" || logFilter === "" || logFilter === "All"? pageChange : undefined,
      // limit: searchValue === "" ? pageSize : undefined,
      logType: logFilter === "All" || logFilter === "" ? undefined : logFilter,
      ["tableType"]: moduleFilter2.filter(item => item.check === true).map(items => items.value),
      ["role"]: userFilters2.filter(item => item.check === true).map(items => items.value),
    }

    getLogs(request);

  }, [startDate, endDate, searchValue,])

  const getfilterRequest = (key) => {
    switch (key) {
      case "role":
        filterObj.role = true;
        break;
      case "tableType":
        filterObj.tableType = true;
        break;
      case "logType":
        filterObj.logType = true
        break;
      default:
        break;
    }
  }

  const filterLogs = (internalfilterObj = null) => {
    let request;

    if (filterObj?.role) {
      request = {
        search: searchValue,
        startDate: startDate,
        endDate: endDate,
        logType: logFilter === "All" || logFilter === "" ? undefined : logFilter,
        ["role"]: userFilters2.filter(item => item.check === true).map(items => items.value),
        ["tableType"]: moduleFilter2.filter(item => item.check === true).map(items => items.value),
      }
    }
    else if (filterObj?.tableType) {
      request = {
        search: searchValue,
        startDate: startDate,
        endDate: endDate,
        logType: logFilter === "All" || logFilter === "" ? undefined : logFilter,
        ["tableType"]: moduleFilter2.filter(item => item.check === true).map(items => items.value),
        ["role"]: userFilters2.filter(item => item.check === true).map(items => items.value),
      }
    }
    else if (filterObj?.logType) {
      request = {
        search: searchValue,
        startDate: startDate,
        endDate: endDate,
        logType: logFilter === "All" || logFilter === "" ? undefined : logFilter,
        ["tableType"]: moduleFilter2.filter(item => item.check === true).map(items => items.value),
        ["role"]: userFilters2.filter(item => item.check === true).map(items => items.value),
      }
    }
    else{
      request = {
        search: searchValue,
        startDate: startDate,
        endDate: endDate,
        // page: searchValue === "" || logFilter === "" || logFilter === "All"? pageChange : undefined,
        // limit: searchValue === "" ? pageSize : undefined,
        // logType: logFilter === "All" || logFilter === "" ? undefined : logFilter,
        // ["tableType"]: moduleFilter2.filter(item => item.check === true).map(items => items.value),
        // ["role"]: userFilters2.filter(item => item.check === true).map(items => items.value),
      }
    }
    getLogs(request)
  };


  const customPopover = (logo) => {
    let contentBody;

    contentBody = <div className="sort-popover">
      <div> <span style={{ fontWeight: "bold", fontSize: "19px" }}>ALL Filter(s)</span>
        <Button type="text" size="medium" className="float-right"
          onClick={() => {
            resetFilter()
            setLogTypeCheck(new Array(10).fill(false));
            setUserFilters2(userFilters2.map(item => ({ ...item, check: false })));
            setModuleFilter2(moduleFilter2.map(item => ({ ...item, check: false })));
            setlogFilter("")
          }}
        >Reset</Button></div>
      <List
        dataSource={filterList()}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <List.Item.Meta
              title={<span>{item.title}</span>}
              description={<span>{item.discription}</span>}
            />
            <div style={{ alignItem: "right", display: "flex" }}>
              {/* <CloseOutlined/> */}
            </div>
          </List.Item>
        )}
      />

      <div className="sort-action">
        <Button className="cancel" onClick={() => { setFilterDetails(false) }}>CANCEL</Button>
        <Button className="float-right ok"
          onClick={() => { setFilterDetails(false) }}
        >OK</Button>
      </div>
    </div>

    return (<Popover
      placement="bottom"
      content={contentBody}
      trigger="click"
      visible={filterDetails}
      onVisibleChange={() => {
        if (filterDetails) {
          setFilterDetails(false)
        } else {
          setFilterDetails(true)
        }
      }}
    >
      {logo}
    </Popover>
    )
  };

  const columns = [
    {
      title: <div className={classes.headerName} style={{ minWidth: "5rem" }}>
        <span className={classes.nameMargin}>NAME </span>
      </div>,
      dataIndex: "name",
      key: "name",

      render: (text, record) => (
        <div onClick={() => { }} style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center",}}>
          <div>
            <img style={{ width: "2.5rem", height: "2.5rem", borderRadius: "50%" }}
              src={record.photo ? record.photo : UserIcon} />
          </div>
          <div style={{ color: "#161616", fontSize: "1rem", fontWeight: "bold" }}>
            {record?.userName}
          </div>
        </div>
      ),
    },

    {
      title: <div className={classes.headerName} style={{ minWidth: "7rem", }}>
        <span>USER</span>
        {customFilterPopover("role", <DownOutlined />)}

      </div>,
      dataIndex: "role",
      key: "role",

      render: (text, record) => {
     
        const roles= []

        return (
          <div>
            {/* {record?.role?.length >= 2 ? <span>{record?.role},</span> : record?.role} */}
            {record?.role?.map((item,index)=>{
          
               if(record?.role?.length-1>index)
               {
                return(
                  <span>{item+", "}</span>
                )
               }
              return(
                <span>{item}</span>
              )
            })}
          </div>
        )
      },
    },
    {
      title: <div className={classes.headerName} style={{ minWidth: "8rem", }}>
        <span>MODULE </span>
        {customFilterPopover("tableType", <DownOutlined />)}
      </div>,
      dataIndex: "module",
      key: "module",

      render: (text, record) => {
        return (
          <div>
            {record?.tableType}
          </div>)
      }
    },
    {
      title: <div className={classes.headerName} style={{ minWidth: "7rem", }}>
        <span>LOG INFO </span>
        {customFilterPopover("logType", <DownOutlined />)}
      </div>,
      dataIndex: "logandinfo",
      key: "logandinfo",

      render: (text, record) => {
        return (<>
          {record?.logType === "Success" ?
            <div style={{ color: "#FF6B6B",}} >
              <Typography.Text ellipsis={{ tooltip: true, }} style={{ width: 200, color: "#0086FF" }}>
                <CheckCircleOutlined style={{ backgroundColor: "#0086FF", color: "white", borderRadius: "10px" }} /> {record?.logType}, {record?.logDescription}
              </Typography.Text>
            </div>
            :
            <div style={{ color: "#FF6B6B",}}>
              <Typography.Text ellipsis={{ tooltip: true, }} color={"white"} style={{ width: 200, color: "#FF6B6B" }}>
                <CloseCircleOutlined style={{ backgroundColor: "#FF6B6B", color: "white", borderRadius: "10px" }} /> {record?.logType}, {record?.logDescription}
              </Typography.Text>
            </div>
          }
        </>)
      }
    },
    {
      title: <div className={classes.headerName} style={{ minWidth: "7rem", textAlign: "center", alignItems: "center" }}>
        <span>DATE AND TIME</span>
      </div>,
      dataIndex: "timeanddate",
      key: "timeanddate",

      render: (text, record) => {
        let dates = moment(new Date(record?.createdAt)).format('YYYY-MM-DD & hh:mm A')
        return (
          <div>
            {dates}
          </div>)
      }
    },
  ];

  const handlePageChange = (page, pageSize) => {
   
    setPageChange(page);
    setPageSize(pageSize);
  };

  return (
    <>


      <h1 style={{ margin: "1% 0 0 2%", fontWeight: "bold", color: "#161616", fontSize: "1.5rem" }}>
        Logs and Action
      </h1>

      <div
        style={{
          padding: "0.8% 2% 2% 2%",
          width: "100%",
          boxSizing: "border-box",
          height: "85vh",
        }}
      >
        <Row>
          <div style={{ display: 'flex', gap: "1rem", justifyContent: "space-between", width: "100%", }}>
            <div className={classes.searchDiv}>
              {/* <div>
                <img src={searchIcon}></img>
              </div> */}
              <Input
                type="text"
                prefix={<SearchOutlined className="custom-search-icon" />}
                size="medium"
                allowClear={true}
                placeholder=' Search'
                style={{ color: "#161616",}}
                onChange={(event) => {
                  setSearch(event.target.value)
                }}
              //  prefix={<SearchOutlined className="custom-search-icon" style={{ Color:"#0086FF",fontWeight:"bold" }} />}
              />
            </div>

            <div>
              <div className="head-icon">
                {customPopover(<img src={filter} onClick={() => { }} />)}
              </div>
              <div className="head-icon">
                <img src={Download}
                  style={{ width: "24", height: "30px", marginRight: '10px' }}
                  onClick={() => {
                    Api.post("/hiring-users/logs")
                      .params({
                        download: true,
                        search: searchValue,
                        startDate: startDate,
                        endDate: endDate,
                        logType: logFilter === "All" || logFilter === "" ? undefined : logFilter,
                        ["tableType"]: moduleFilter2.filter(item => item.check === true).map(items => items.value),
                        ["role"]: userFilters2.filter(item => item.check === true).map(items => items.value),
                      })
                      .send((response) => {
                        if (typeof response != "undefined") {
                          window.open(response, "_blank")?.focus();
                        }
                      })
                  }} />
              </div>
              <Space direction="vertical">
                <RangePicker
                  format={dateFormat}
                  value={startDate || endDate ?
                    [moment(startDate, dateFormat),
                      moment(endDate, dateFormat),
                    ]:[]}                 
                  onChange={(date, dateString) => {
                    setStartDate(dateString[0])
                    setEndDate(dateString[1])
                  }}
                />
              </Space>
            </div>
          </div>
        </Row>
        {/* <Tabs
          style={{ fontWeight: "bold", fontFamily: "Open Sans", fontSize: "16px" }}
        > */}
          {/* <TabPane> */}
            <div style={{ height: "100%",marginTop:"0.9rem" }}>
              <Table columns={columns} dataSource={logAction} loading={loader}
                rowClassName={classes.rowStyle} className={classes.table}
                pagination={{
                  position: ["bottomRight"],
                  showSizeChanger: true,
                  pageSizeOptions: [10,25, 30, 50, 100],
                  // pageSize: paginations?.itemsPerPage, 
                  size: "small",
                  // current: parseInt(paginations?.currentPage),
                  // total: paginations?.totalItems,
                  onChange: handlePageChange,
                  onShowSizeChange: handlePageChange,
                }}
                scroll={{ y: 460 }}
              />

            </div>
          {/* </TabPane >
        </Tabs> */}

      </div>
    </>
  );

}

export default LogsActions;