
import { Col, Row, Select } from "antd";
import React from "react";
import { connect } from "react-redux";
import "services/styles.css";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { withRouter } from "react-router";
import classes from './hrProcessCandidateCard.module.css';

class HrProcessCandidateCard extends React.Component {
  render() {
    const {
      candidatePageMeta,
    } = this.props;

    return (   
            <div className={classes.container} > 
                    <div>        
                          <h3 >Profiles:</h3>               
                          <span>{candidatePageMeta?.totalItems || "NA"}</span>
                      </div>              
              </div>     
    );
  }
}
const routerCandidateCard = connect(mapStateToProps, mapDispatchToProps)(HrProcessCandidateCard)
export default withRouter(routerCandidateCard);
