import {
  UPDATE_PAGE_META,
  UPDATE_JOB_SUMMARY,
  TOGGLE_JOB_SUMMARY_LOADER,
  UPDATE_JOB_SUMMARY_PROFILES,
  UPDATE_ORG_TYPE
} from "./action";

const initialState = {
  pageMeta: {},
  jobSummary: [],
  orgTypes:[],
  jobSummaryLoader: false,
  jobSummaryProfiles: [],
  totalProfiles: 0,
  totalInProgress: 0,
  totalPending: 0,
  totalNotShortlisted: 0,
  totalRejected: 0,
  totalDuplicate: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAGE_META:
      return {
        ...state,
        pageMeta: action.data,
      };
    case TOGGLE_JOB_SUMMARY_LOADER:
      return {
        ...state,
        jobSummaryLoader: action.value,
      };
    case UPDATE_JOB_SUMMARY:
      return {
        ...state,
        jobSummary: action.data.data,
        totalProfiles: action.data.totalProfiles,
        totalInProgress: action.data.totalInProgress,
        totalPending: action.data.totalPending,
        totalNotShortlisted: action.data.totalNotShortlisted,
        totalRejected: action.data.totalRejected,
        totalDuplicate: action.data.totalDuplicate,
      };
    case UPDATE_JOB_SUMMARY_PROFILES:
      return {
        ...state,
        jobSummaryProfiles: action.data.profiles.data,
      };
      case UPDATE_ORG_TYPE:
        return {
          ...state,
          orgTypes: action.data,
        };
    default:
      return state;
  }
};
export default reducer;
