import React from 'react';
import classes from './jobProfile.module.css';
import { DatePicker, Space,Row,Select,Card,Tabs,Table ,Spin,Modal,Typography,Tooltip,Badge} from 'antd';
import { useState } from 'react';
import userIcon from '../../../assets/userIcon.svg';
import userCheckIcon from '../../../assets/userCheckIcon.svg';
import locationIcon from '../../../assets/locationIcon.svg';
import calendarIcon from '../../../assets/calendarIcon.svg';
import Button from 'antd/lib/button';
import {DownloadOutlined,} from '@ant-design/icons';
import Api from "services/Api";
import { useEffect } from 'react';


const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const { TabPane } = Tabs;
const Option={Select};

const JobProfile=(props)=>{

    const [startDate,setStartDate] = useState("")
    const [pageLoader,setLoader] = useState(true)
    const [endDate,setEndDate] = useState("")    
    const [jobLists,setJobLists] = useState([])
    const [jobId,setJobId] = useState("")
    const [jobProfile,setJobProfile] = useState([])
    const [panelistLists,setPanelistLists] = useState([])
    const [agencyLists,setAgencyLists] = useState([])
    const [instituteLists,setInstituteLists] = useState([])
    const [candidateSum,setCandidateSum] = useState([])
    const [roundSum,setRoundSum] = useState([])
    const [jobType,setJobType] = useState("")
    const [modalData,setModalData]=useState([]);
    const [modalOpen,setModalOpen]=useState(false);



    const interviewSum=[
        {
                title:"Shared",value:candidateSum?.shared,dataKey:"shared",
         },
         {
                title:"New",value:candidateSum?.newCandidates,dataKey:"new",
         },
         {
                title:jobType === "Fresher" ?"Hackathon Passed":"Shortlisted",value:jobType === "Fresher" ? candidateSum?.passedCand : candidateSum?.shortlistedCand,
                dataKey:jobType === "Fresher" ?"passed":"shortlisted",
         },
       
    ]
      
if(jobType === "Experienced"){
  interviewSum.push({
    title: "Not Shortlisted",value:candidateSum?.notShortlistedCand,
    dataKey:"notShortlisted",
}, {
  title:"In Progress",value:candidateSum?.inProgressCand,dataKey:"In Progress",
},
{
  title:"Scheduled",value:candidateSum?.scheduledCand,dataKey:"Scheduled",
},
{
  title:"Re-Schedule",value:candidateSum?.reScheduleCand,dataKey:"Re-Schedule",
}, 
{
  title:"Re-Discussion",value:candidateSum?.rediscussionCand,dataKey:"Re-Discussion",
}, 
{
  title:"Feedback Pending",value:candidateSum?.feedbackPendingCand,dataKey:"Feedback-Pending",
},
{
  title:"Selected",value:candidateSum?.selectedCand,dataKey:"selected",
},
{
  title:"Rejected",value:candidateSum?.rejectedCand,dataKey:"rejected",
},
{
  title:"On Hold",value:candidateSum?.onHold,dataKey:"onHold",
},)
}
else{
  interviewSum.push({
    title:"In Progress",value:candidateSum?.inProgressCand,dataKey:"In Progress",
},
{
    title:"Scheduled",value:candidateSum?.scheduledCand,dataKey:"Scheduled",
},
{
    title:"Re-Schedule",value:candidateSum?.reScheduleCand,dataKey:"Re-Schedule",
}, 
{
    title:"Re-Discussion",value:candidateSum?.rediscussionCand,dataKey:"Re-Discussion",
}, 
{
    title:"Feedback Pending",value:candidateSum?.feedbackPendingCand,dataKey:"Feedback-Pending",
},
{
    title:"Selected",value:candidateSum?.selectedCand,dataKey:"selected",
},
{
    title:"Rejected",value:candidateSum?.rejectedCand,dataKey:"rejected",
},
{
    title:"On Hold",value:candidateSum?.onHold,dataKey:"onHold",
},)
}

    useEffect(()=>{
      setLoader(true)
      Api.get('/hiring-job/dropdown')
      .send((res)=>{
         
         setJobLists(res)
         setLoader(false)
     })
    },[])

    const Columns=[
      {
          title:<span style={{fontSize: "14px",fontWeight: "700", color:"#000000"}}>CANDIDATE NAME</span>,
          dataIndex:"name",
          key:"name",
          width:"9.8rem",
          render:(text,record)=>{
              return(
                    <div>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.7rem"}}>{record?.name?record?.name:"NA"}</Typography.Text>
                    </div>              
                )
          }
      },
      {
          title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>EMAIL</span>,
          dataIndex:"email",
          key:"email",
          render:(text,record)=>{
            return(
                <div>
                    <Typography.Text ellipsis={{tooltip:true}} style={{width:"8rem"}}>{record?.email?record?.email:"NA"}</Typography.Text>
                </div>
            )
        }
      },
      {
          title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>CANDIDATE TYPE</span>,
          dataIndex:"candidateType",
          key:"candidateType",
       
      },
      {
          title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>JOB PROFILE</span>,
          dataIndex:"jobProfile",
          key:"jobProfile",
          render:(text,record)=>{
            return(
                <div>
                    <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.9rem"}}>{record?.jobProfile?record?.jobProfile:"NA"}</Typography.Text>
                </div>
            )
        }
      },
      {
        title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>HIRING STATUS</span>,
        dataIndex:"hiringStatus",
        key:"hiringStatus",
        render:(text,record)=>{
          return(
              <div>
                  <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.9rem"}}>{record?.hiringStatus ? record?.hiringStatus : "NA"}</Typography.Text>
              </div>
          )
      }
      },
      jobType === "Experienced" ?
      {
          title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>AGENCY NAME</span>,
          dataIndex:"agency",
          key:"agency",
          render:(text,record)=>{
            return(
                <div>
                    <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.9rem"}}>{record?.agency ? record?.agency:"NA"}</Typography.Text>
                </div>
            )
        }
      }
      :
      {
        title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>INSTITUTE NAME</span>,
        dataIndex:"instituteName",
        key:"instituteName",
        render:(text,record)=>{
          return(
              <div>
                  <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.9rem"}}>{record?.instituteName ? record?.instituteName:"NA"}</Typography.Text>
              </div>
          )
      }
      },
      // {
      //     title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>EXPECTED DOJ</span>,
      //     dataIndex:"expectedDoj",
      //     key:"expectedDoj",
         
      // },
          
  ]

  // const Columns2=modalData?.title === "Shared" ?Columns.push({
  //   title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>HIRING STATUS</span>,
  //   dataIndex:"hiringStatus",
  //   key:"hiringStatus",
  //   render:(text,record)=>{
  //     return(
  //         <div>
  //             <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.9rem"}}>{record?.hiringStatus ? record?.hiringStatus : "NA"}</Typography.Text>
  //         </div>
  //     )
  // }
  // },):""

  candidateSum?.roundTypes?.map((item,index)=>{
  })


    return(
        <div>
         <div className={classes.title}>
            <div style={{display:"flex",flexDirection:"row",gap:"1rem"}}>
            <h3>Job Profiles Overview</h3>
            <span style={{ fontWeight: "490",fontStyle: "italic",color: "#B7B7B7",marginTop: "0.3rem",fontSize: "13px",}}>Displaying Last 7 Days Data</span>
            </div>
            {/* <div >
            <Button type='primary'
             style={{display:"flex",marginLeft:"37rem",fontWeight:"630",textAlign:"center",alignItems:"center",borderRadius:"5px"}}>
            <DownloadOutlined style={{fontSize:"1.1rem"}}/> Download</Button>
            </div> */}
         </div>

       <Row> 
         <div style={{ display: 'flex', gap:"1rem",justifyContent:"space-between",width:"98%",marginTop:"1rem"}}>
          <div >
               <Select
                  placeholder="Select Job Profile"
                  showSearch={true}
                  className={classes.selectDiv}
                  bordered={false}
                  listHeight={160} 
                  getPopupContainer={trigger => trigger.parentNode}
                  size={"middle"}
                  // value={jobId?jobId:undefined}
                  allowClear={true}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(event) => {
                    setJobId(event)
                    setLoader(true)
                    setTimeout(()=>{setLoader(false)},3000);
                    if(!event)
                    {
                      setJobProfile([]);
                      setPanelistLists([]);
                      setAgencyLists([]);
                      setInstituteLists([]);
                      setCandidateSum([]);
                      setRoundSum([]);
                      return;

                    }
                    if(event){
                      const ids=event === undefined ? "" : event
                     Api.get(`/hiring-overview/jobProfileDashboard?jobId=${ids}&startDate=${startDate}&endDate=${endDate}`)
                      .send((response)=>{
                        if (typeof response != "undefined") {
                         
                          setLoader(false)
                          setJobProfile(response?.profileOverview)
                          setPanelistLists(response?.panelistData)
                          setAgencyLists(response?.data)
                          setInstituteLists(response?.collegePerformance)
                          setCandidateSum(response)
                          setRoundSum(response?.roundWise)
                          setJobType(response?.jobExperience)
                        }
                      })
                    }
                  }}
                >
                  {jobLists?.map((item, i) => (
                    <Option key={i} style={{ width: "100%" }} value={item.id}>
                       {/* <Tooltip title={item.agencyName}>
                        <Badge
                          status={item.status === "Active" ? "success" : "error"}
                        />{" "} */}
                        {item.title}
                      {/* </Tooltip> */}
                    </Option>
                  ))}
                </Select>
                    
          </div>
              <div className="head-icon">  
                            <Space direction="vertical">
                               <RangePicker
                                format={dateFormat}
                                onChange={(date, dateString) => {
                                 setStartDate(dateString[0])
                                 setEndDate(dateString[1])
                                 setLoader(true);
                                 if(!jobId)
                                 {
                                  setLoader(false);
                                 }
                                 if(jobId)
                                 {


                                 Api.get(`/hiring-overview/jobProfileDashboard?jobId=${jobId}&startDate=${dateString[0]}&endDate=${dateString[1]}`)
                                    .send((response)=>{
                                      if (typeof response != "undefined") {
                                        setLoader(false)
                                        setJobProfile(response?.profileOverview)
                                        setPanelistLists(response?.panelistData)
                                        setAgencyLists(response?.data)
                                        setInstituteLists(response?.collegePerformance)
                                        setCandidateSum(response)
                                        setRoundSum(response?.roundWise)
                                        setJobType(response?.jobExperience)
                                      }
                                    })
                                  }
                                }}
                              />
                            </Space>  
                </div>
             </div>
       </Row> 
       {pageLoader?<Spin style={{margin:"10% auto"}}></Spin>:<div> 
       <Row>
           <div style={{display:'flex',flexDirection:'row',marginTop:"1rem",width:"98%",gap:"2.8%",marginBottom:"1.4rem",height:"15rem"}} >
          
          <Card bordered={false} style={{ flex:"1 1 60%"}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
              <div>
                <h3 className={classes.jobTitle}>Job Profile Details</h3>
              </div>
              <div>
                <h3 style={{fontSize:"15px",marginTop:"-0.4rem",marginRight:"5rem"}}>Shortlister:- {candidateSum?.shortlisterName}</h3>
              </div>
            </div>

            <Row>
            <div className={classes.cardsHead}>
            <Card className={classes.cards}>
            <div className={classes.cardDiv}>

            <div className={classes.cardSide1}>
            <Card.Grid className={classes.gridStyle}>
            <img src={userIcon} style={{marginLeft:"-1rem"}}/>
            </Card.Grid>
            </div>
             
            <div className={classes.cardSide2}>
                <h2 >{jobProfile?.minExperience?jobProfile?.minExperience:""} - {jobProfile?.maxExperience?jobProfile?.maxExperience:""}</h2>
                <p>Required Experience (in years)</p>
            </div>
            
            </div>
            </Card>

            <Card className={classes.cards}>
            <div className={classes.cardDiv}> 

            <div className={classes.cardSide1}>
            <Card.Grid className={classes.gridStyle}>
            <img src={userCheckIcon} style={{marginLeft:"-1rem"}}/>
            </Card.Grid>
            </div>

            <div className={classes.cardSide2}>
                <h2>{jobProfile?.vacancies?jobProfile?.vacancies:"-"}</h2>
                <p>No. of Vacancies</p>
            </div>

            </div>
            </Card>
            </div>
           </Row>

           <Row>            
            <div className={classes.cardsHead}>
            <Card className={classes.cards}>
            <div className={classes.cardDiv}>   

            <div className={classes.cardSide1}>
            <Card.Grid className={classes.gridStyle}>
            <img src={locationIcon} style={{marginLeft:"-1.2rem"}}/>
            </Card.Grid>
            </div>

            <div className={classes.cardSide2} style={{marginRight:"3.3rem",}}>
              <div style={{maxHeight:"2.5rem",overflowY:"auto",paddingRight:"0.5rem"}}>
                          {jobProfile?.locations?jobProfile?.locations?.map((item)=>{
                            return(<div><span>{item?.name}</span></div>)
                          }):<h2>-</h2>}
                </div>
                <p>Locations</p>
            </div>
            </div>
            </Card>

            <Card className={classes.cards}>
            <div className={classes.cardDiv}>

            <div className={classes.cardSide1}>
            <Card.Grid className={classes.gridStyle}>
            <img src={calendarIcon} style={{marginLeft:"-1.2rem"}}/>
            </Card.Grid>
            </div>

            <div className={classes.cardSide2} style={{marginRight:"0.6rem"}}>
              {!jobProfile?.jobExperience&&<h2>-</h2>}
                {jobProfile?.jobExperience&&<h2>{jobProfile?.noticePeriod?jobProfile?.noticePeriod:jobProfile?.jobExperience==='Fresher'?"NA":"0"}</h2>}
                <p>Notice Period</p>
            </div>
            </div>
            </Card>

            </div>
           </Row>

          </Card>
        
        
          <Card style={{ flex: "1 1 40%",}}>
           <div>
                <h3 className={classes.jobTitle}>List of Panelists</h3>
            </div>
           <Row>
            <div className={classes.rows}>
             <p>Name</p>
             <p>Round</p>
            </div>
           </Row>
           {panelistLists.length > 0 ? 
            <div className={classes.scroll}>
                {panelistLists?.map((item,index)=>{
                    return(
                        <div className={classes.rows2}>
                          <div>
                            <h3>{item.Name}</h3>
                            <h5><Typography.Text style={{width:"9rem"}} ellipsis={{tooltip:true}}>{item.Designation}</Typography.Text></h5>
                          </div>

                          <div >
                            <h3>Round {item.Round}</h3>
                            <h5>{item.RoundType === "Relationship" ? "HR Relationship" : item.RoundType}</h5>
                          </div>
                        </div>
                    )
                })}
            </div>
            :
            <div className={classes.conditionsum2}>
                  <h2>No data to display</h2>
            </div>
           }
          </Card>
        </div>
       </Row>
       <Row>
           <div style={{display:'flex',flexDirection:'row',width:"98%",gap:"2.8%",marginBottom:"1.3rem",height:"15rem"}} >
          
           <Card style={{ flex: "1 1 50%",}}>
           <div>
                <h3 className={classes.jobTitle}>Agency Performance</h3>
            </div>
            <div className={classes.secondRows}>
             <p>Agency Name</p>
             <p>Candidates Shared</p>
            </div>
            {agencyLists.length > 0 ? 
              <div className={classes.scroll2}>
                {agencyLists?.map((item,index)=>{
                    return(
                        <div className={classes.secondRows2}>
                          <div>
                            <h3>{item.agencyName}</h3>
                          </div>

                          <div>
                            <h3>{item.profilesShared}</h3>
                          </div>
                        </div>
                    )
                })}
            </div>
             :
             <div className={classes.conditionsum2}>
                   <h2>No data to display</h2>
             </div>
            }
          </Card>

          <Card style={{ flex: "1 1 50%",}}>
           <div>
                <h3 className={classes.jobTitle}>Institution Performance</h3>
            </div>
           <Row>
            <div className={classes.secondRows}>
             <p>Institute Name</p>
             <p>Hackathon Passed</p>
            </div>
           </Row>
            {instituteLists.length > 0 ? 
              <div className={classes.scroll2}>
                {instituteLists?.map((item,index)=>{
                    return(
                        <div className={classes.secondRows2}>
                          
                            <h3>{item.collegeName}</h3>
                          

                          
                            <h3>{item.candidatesLength}</h3>
                        
                        </div>
                    )
                })}
            </div>
            :
              <div className={classes.conditionsum2}>
                    <h2>No data to display</h2>
              </div>
             }
          
          </Card>
        
        </div>
       </Row>
       <Row>
           <Card style={{ width:"98%",marginBottom:"1.3rem"}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",}}>
                <h3 className={classes.jobTitle}>Candidates Summary</h3>
                <Button type='primary' disabled= { jobId === "" || jobId === undefined ? true : false }
                 style={{borderRadius:"5px"}} 
                 onClick={()=>{
                  Api.get(`/hiring-overview/jobProfileDashboard?jobId=${jobId}&dataKey=shared&download=true&startDate=${startDate}&endDate=${endDate}`)
                    .send((res) => {
                      if (typeof res != "undefined") {                                               
                        window.open(res, "_blank")?.focus();
                      }
                    })
                  }}>
                  <DownloadOutlined style={{fontSize:"1rem"}}/>
                  Download
                </Button>
            </div>
            { jobId === "" || jobId === undefined ? 
              <div className={classes.conditionsum}>
                 <h2>No data to display</h2>
              </div>
              :
              <div className={classes.summary}>
                  {interviewSum?.map((item,index)=>{
                        return(
                            <div >
                              <div style={{cursor:"pointer"}} onClick={()=>{
                                 setModalOpen(true)
                                 setModalData({data:item?.value,dataKey:item?.dataKey,title:item?.title});
                              }}>
                                <span><Typography.Text ellipsis={{tooltip:true}} style={{width:"7rem"}}>{item?.title}</Typography.Text></span>
                              </div>
                              <div>
                                 <h4>{item?.value?.length}</h4>
                              </div>
                            </div>
                          )
                     })}
              </div>
             }
          </Card>
       </Row>
       <Row>
           <Card style={{ width:"98%"}}>
            <div>
                <h3 className={classes.jobTitle}>Round Wise Summary</h3>
            </div>
            { jobId === "" || jobId === undefined ? 
              <div className={classes.conditionsum}>
                 <h2>No data to display</h2>
              </div>
              :
             
                 <div className={classes.roundSummary}>
                    {roundSum?.map((item,index)=>{ 
                         return(
                           <div>
                                <Button>
                                  <Typography.Text style={{width:"15rem"}} ellipsis={{tooltip:true}}>
                                  {item?.roundTypes === "Relationship" ? "HR Relationship":item?.roundTypes} Round
                                  </Typography.Text>
                                  </Button>
                                 <div>
                                     <h4>Conducted: <span style={{marginLeft:"2.3rem"}}>{item.counts}</span></h4>
                                 </div>
                                 <div>
                                     <h4>Selection Ratio: <span style={{marginLeft:"0.3rem"}}>{item.ratio}</span></h4>
                                 </div>
                             
                             </div>
                          )
                   })}
                    </div> 
            } 
          </Card>
       </Row>
     </div>}
     <Modal 
            open={modalOpen} 
            onCancel={()=>{setModalOpen(false)}} 
            width="62rem"
            bodyStyle={{height: 500}}
            centered 
            className={classes.modalDesign}
            header={null} 
            footer={null}  
            closable={false}              
        >
        <div className={classes.modalView}>
     
                <div>
                    <h2>Interview {modalData?.title}</h2>
                    <Button disabled={modalData?.data?.length === 0 ? true : false} type="primary" 
                     onClick={()=>{
                        Api.get(`/hiring-overview/jobProfileDashboard?jobId=${jobId}&dataKey=${modalData?.dataKey}&download=true&startDate=${startDate}&endDate=${endDate}`)
                          .send((res) => {
                            if (typeof res != "undefined") {                                               
                              window.open(res, "_blank")?.focus();
                            }
                          })
                     }}>Export to Excel Sheet</Button>
              
            </div>
            <div>
              {modalData?.data?.length>0?
                <Table style={{marginBottom:"1rem"}}
                  columns={Columns} 
                  scroll={{y:270,x:0}}
                  dataSource={modalData?.data}
                  pagination={{
                    size:"small",
                    showSizeChanger:true,
                    // pageSize:10,
                    pageSizeOptions:[10,20,30,50,100]
                  }}
                  // locale={{
                  //   emptyText:modalData?.data?.length===0?<EmptyTable text="NO data Found"/>:""
                  // }}
                />
                :
                <div style={{fontWeight:"bold",width:"95%",margin:"3rem",textAlign:"center"}}>No Data Found</div>
                }
            </div>
        </div>         
       </Modal>

     </div>
    )
}

export default JobProfile;