import { createSlice } from "@reduxjs/toolkit";
import store from "pages/dashboard-home/store";

const initialState = {
    jdList: [],
    info:{},
    columns:[],
    experienceColumn:[],
    tabStatus:"Active",
    modalOpen:false,
    jobTitleModalOpen:true,
  // warnings:4
  
};

const jdListSlice = createSlice({
  name: "jd",
  initialState,
  reducers: {
    updateJdList: (state, action) => {
      state.jdList= [ ...action.payload.items ];
    },
    
     updateInfo(state, action) {
      state.info = { ...action.payload.meta };
    },
    updateAllColumns(state,action){
      state.columns=[...action?.payload]
    },
    
    updateExperienceMappedColumn(state,action)
    {
      state.experienceColumn=[...action.payload]
    },
    updateTabState (state,action){
     state.tabStatus=action.payload
    },
  //  updateWarning(state,action)
  //  {
  //    state.warnings=action.payload
  //  }

  updateModalOpen(state,action)
  {
    state.modalOpen=action.payload;
  },

  updateJobTitleModalOpen(state,action){
    state.jobTitleModalOpen=action.payload;
  },


  },

 


});
export default jdListSlice.reducer;
export const jdActions = jdListSlice.actions;
