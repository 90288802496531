import React, { useState, useEffect } from "react";

import {
  Col,
  Form,
  Modal,
  Row,
  Space,
  Checkbox,
  Tooltip,
  message,
  Upload,
  Input,
  Button,
  Select,
} from "antd";
import classes from "./candidateViewModal.module.css";
import { Api, Notification } from "services";
import { Color, InformationModal, modalContent } from "services";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";

import {
  WarningOutlined,
  CheckSquareOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { FiEdit2, AiOutlineStop, BiRefresh } from "../../services/reactIcons";
import {
  operationType,
  candidateFieldType,
  CANDIDATE_DETAILS,
  inputFieldType,
} from "./redux/action";
import "./styles.css";
// import { Api, Color } from "services";
import { connect, useDispatch, useSelector } from "react-redux";
import { useRef } from "react";

const { TextArea } = Input;

const EditCandidateModal = (props) => {
  const {
    editCandidateView,
    candidateInfo,
    candidateRejectedLoader,
    candidateShortlistedLoader,
    candidateJobs,
    candidateImage,
    candidateOriginalInfo,
    candidateScreenType,
    proposeJob,
    candidateResume,
    history,
  } = useSelector((state) => state.candidate);

  //const candidate=useSelector(state=>state.candidate)
  const [name, setName] = useState(candidateInfo?.name);
  const [img, setImage] = useState("");
  const [resumeUrl, setResumeUrl] = useState("");
  const [imgUrl, setImageUrl] = useState(candidateImage);
  const [ImgFileName, setImgFileName] = useState("");
  const [resume, setResume] = useState("");
  const [blacklist, setblacklist] = useState(candidateInfo?.blacklist);
  const [duplicate, setduplicate] = useState(candidateInfo?.duplicate);
  const [preferredJobLocation, setpreferredJobLocation] = useState([]);
  const [othersValue,setOthersValue] = useState(null);
  const [inputVal,setInputVal] = useState(null);

  const userdata = {
    name: name,
    blacklist: blacklist,
    duplicate: duplicate,
    phone: candidateInfo?.phone,
    email: candidateInfo?.email,
    // jobTitle: candidateInfo?.job?.title,
    jobId: candidateInfo?.job?.id,
     currentCompany: candidateInfo?.currentCompany,
    noticePeriod: candidateInfo?.noticePeriod,
    experienceYears: candidateInfo?.experienceYears,
    experienceMonths: candidateInfo?.experienceMonths,
    currentCtc: candidateInfo?.currentCtc,
    expectedCtc: candidateInfo?.expectedCtc,
    currentLocation: candidateInfo?.currentLocation,
    // preferredLocation: candidateInfo?.preferredLocation?.id,
    preferredLocation: candidateInfo?.preferredLocation?.id?candidateInfo?.preferredLocation?.id:0,
    note: candidateInfo?.note,
    // proposedJd: candidateInfo?.proposedJd,
    shortlisterComment: candidateInfo?.shortlisterComment,
    photo: img,
    resume: resume,
  };

  const state = useSelector((state) => state);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user} = useSelector((state) => state.auth);
  const [addOtherShow, setAddOtherShow] = useState(false);

  useEffect(() => {
    if (addOtherShow === true) {
      inputRef.current.focus();
    }
  }, [addOtherShow]);

  useEffect(()=>{
    let arr=[]
    let jobArr=[]
    if(proposeJob?.length>0){
      jobArr=proposeJob
    }

    else{
      jobArr=candidateJobs
    }
    for(let i=0;i<jobArr.length;i++)
    {
    arr= arr.concat(jobArr[i].locations)
    }
    let arr2=[]
    let map={}
    for(let i=0;i<arr?.length;i++){
      
      if(map[arr[i]?.name]!==true){     
      arr2?.push(arr[i])
      map[arr[i]?.name]=true
    }
  }
    setpreferredJobLocation(arr2)
  },[])
  useEffect(() => {

    form.setFieldValue({currentCompany: candidateInfo?.currentCompany})
    dispatch(
      props.operation.updateInputField(
        inputFieldType.CANDIDATE_IMAGE,
        candidateInfo?.photo
      )
    );
  }, [candidateInfo]);

  const candidateFieldTypeHash = {
    CANDIDATE_BLACKLIST: "blacklist",
    CANDIDATE_DUPLICATE: "duplicate",
    CANDIDATE_RECRUITMENT_STATUS: "candidateStatus",
    CANDIDATE_RECRUITMENT_ROUND: "currentRound",
    CANDIDATE_SHORTLISTER_NOTE: "shortlisterComment",
  };

  const changeCandidateInfo = (action, value) => {
    dispatch(props.operation.updateInputField(action, value));
    const type_value = candidateFieldTypeHash[action];
    const mark = value ? "mark" : "unmark";

    InformationModal.confirm(
      <div>
        <WarningOutlined style={{ fontSize: "3em", color: Color.red }} />
      </div>,
      <div>
        Are you sure you want to {mark} the candidate as {type_value}?
      </div>,
      <div style={{ color: Color.darkGrey }}>
        {modalContent("Name:", candidateInfo?.name)}
      </div>,
      () => {
        candidateInfo?.duplicate ? setduplicate(false) : setduplicate(true);
      },
      (close) => {
        dispatch(
          props.operation.updateInputField(
            CANDIDATE_DETAILS,
            candidateOriginalInfo
          )
        );
        close();
      }
    );
  };

  const imgbeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    } else {
      getBase64(file, (url) => {
        setImageUrl(url);
        setImgFileName(file.name.split(".")[0]);
        setImage(file);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const resumeBeforeUpload = (file) => {
    const isJpgOrPng =
      
      file.type === "application/pdf";
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpgOrPng) {
      message.error("You can only upload pdf file!");
    } else if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    } else {
      getBase64(file, (url) => {
        setResumeUrl(url);
        // setResumeFileName(file.name.split(".")[0]);
        setResume(file);
      });
    }
  };

  const closeModal = () => {
    dispatch(
      props.operation.performOperation(
        operationType.CANDIDATE_VIEW_MODAL,
        false
      )
    );
    dispatch(
      props.operation.performOperation(operationType.EDIT_CANDIDATE_VIEW, false)
    );
    dispatch(
      props.operation.performOperation(
        operationType.CANDIDATE_PHOTO_EDIT_BOOL,
        false
      )
    );
    setOthersValue(null)
  };

  const discard = () => {
    // dispatch(
    //   props.operation.performOperation(operationType.CANDIDATE_VIEW_MODAL, true)
    // );
    dispatch(
      props.operation.performOperation(operationType.EDIT_CANDIDATE_VIEW, false)
    );
  };

  const onChangeInput = (e) => {
    setName(e.target.value);
  };

  const onFinish = (formdata) => {
    if(formdata.currentLocation.trim() === "")
    {
      Notification.error("Current Location should not be empty")
      return
    }
    else if(formdata.currentCompany.trim() === "")
    {
      Notification.error("Current Company should not be empty")
      return
    }
    else{
    if(typeof (formdata.jobId)==="string")
    {
      formdata.jobId=candidateInfo.job.id
    }
    if(typeof (formdata.preferredLocation)==="string")
    {
      formdata.preferredLocation=candidateInfo?.preferredLocation?.id?candidateInfo?.preferredLocation?.id:0;
    }
    if(inputVal){
     formdata.shortlisterComment=inputVal;
    }
    for (const [key, value] of Object.entries(formdata)) {
      value === undefined ? "" : (userdata[key] = value);
    }
    if(candidateScreenType==="jdChangeProfile")
     {
      Api.patch(`/hiring-candidate/updateProposedJd/${candidateInfo.id}/?jobId=${formdata.jobId}`)
      .params({})
      .send( (response) =>{
      })
      
     }
    // dispatch(props.operation.performOperation(CANDIDATE_TABLE_LOADER, true));
    Api.patch(`/hiring-candidate/updateExpCan/${candidateInfo.id}`)
      .params(userdata)
      .uploadAndPatch(userdata, (percentageComplete, response) => {
        if(percentageComplete===100 && response!==undefined){
     
      props.getAllCandidates()
      props.performOperation(operationType.EDIT_CANDIDATE_VIEW, false)
      props.operation.performOperation(operationType.CANDIDATE_VIEW_MODAL, false)
      setAddOtherShow(false)
      setOthersValue(null)
        // dispatch(
        //   props.operation.performOperation(operationType.EDIT_CANDIDATE_VIEW, false)
        // );
        }
      
      });
    // .send((response, error)=>{
    //     dispatch(pro/ps.operation.performOperation(CANDIDATE_TABLE_LOADER, false));
    // });
   
    }
  };
  const modify=(values)=>{
    // for (const [key, value] of Object.entries(values)) {
    //  if(key==="jobId")
    //  {
    //     values.jobId=candidateInfo?.job?.title
    //  }
    // }
    let temp={...values,jobId:candidateInfo?.job?.title,note:userdata?.note&&userdata?.note!=="null"?userdata?.note:"",
    shortlisterComment:candidateInfo?.shortlisterComment!=="null"?candidateInfo?.shortlisterComment:"",
  preferredLocation:candidateInfo?.preferredLocation?.name
  }
    return temp;
  }

  const form_row = {
    marginTop: "0",
    paddingTop: "0",
  };

  const img_style = {
    width: "65px",
    height: "70px",
    borderRadius: "50%",
    position: "absolute",
    top: "0.1rem",
    right: "0.1rem",
    opacity: "50%",
  };

  const button_upload = {
    background: "rgb(204, 202, 198, 50%)",
    width: "65px",
    height: "70px",
    borderRadius: "50px",
  };

  const formTitle = {
    fontWeight: "bold",
    fontSize: "12px",
    color: Color.black,
  };

  const inputStyle = {
    width: "100%",
    height: "2rem",
    padding: "0 0 0 0.2rem",
  };

  const selectOptions = [
    "No relevant experience","Less relevant experience","Fake Experience",
    "Very High CTC",
    "Not compatible",
    // ("Already working at client" , "Too many company changes" , "Not reliable"),
    "NP not acceptable",
    "Wrong Profile ",
    //  ("JD and Profile are out of sync"),
    "90 days Consider later",
    "Other"
  ]

  const isHrProcess = window.location.pathname == "/hr-process";

  return (
    <Modal
      visible={editCandidateView}
      closable={true}
      centered
      // width="35%"
      bodyStyle={{ height: "57vh" }}
      className="candidateViewModal"
      title={
        <div>
          <div className="candidate-title">
            <Space>
              <div>
                <Space>
                  {user.role?.includes("Admin") ||
                  user.role?.includes("Super-Admin") ||
                  user.role?.includes("Recruiter") ||
                  user.role?.includes("Sourcer") ? (
                    <div>
                      <Upload
                        // action={""} // Upload to the URL
                        // listType="picture"
                        showUploadList={false} //{{ showRemoveIcon: false }}
                        accept=".png,.jpeg,.jpg"
                        beforeUpload={imgbeforeUpload}
                      >
                        <Button style={button_upload} type="text">
                          <img
                            src={imgUrl ? imgUrl : candidateImage}
                            style={img_style}
                          />
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "end",
                              alignItems: "center",
                              fontSize: "10px",
                            }}
                          >
                            <span>Upload</span>
                            <span>Image</span>
                          </span>
                        </Button>
                      </Upload>
                    </div>
                  ) : (
                    <div>
                      <Upload
                        // action={""} // Upload to the URL
                        // listType="picture"
                        showUploadList={false} //{{ showRemoveIcon: false }}
                        accept=".png,.jpeg,.jpg"
                        beforeUpload={imgbeforeUpload}
                      >
                        <Button style={button_upload} type="text">
                          <img
                            src={imgUrl ? imgUrl : candidateImage}
                            style={img_style}
                          />
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "end",
                              alignItems: "center",
                              fontSize: "10px",
                            }}
                          >
                            <span>Change</span>
                            <span>Image</span>
                          </span>
                        </Button>
                      </Upload>
                    </div>
                  )}
                </Space>
              </div>
              <div
                style={{
                  marginLeft: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Space style={{ margin: "0.5rem 0 0 0" }}>
                  <Row>
                    <span
                      style={{
                        color: "#000000",
                        margin: "0 0.5rem 0 0",
                        fontSize: "1rem",
                        fontWeight: "900",
                      }}
                    >
                      <Input
                        style={{ width: "10rem" }}
                        id="name"
                        disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
                        value={name}
                        onChange={onChangeInput}
                      />
                    </span>
                    <a
                      style={{ display: "table-cell", marginLeft: "0.5rem" }}
                      onClick={() => {window.open(candidateInfo.resume)}}
                      target="_blank"
                    >
                      <FilePdfOutlined style={{ fontSize: "1rem" }} />
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "black",
                          marginLeft: "0.5rem",
                        }}
                      >
                        Resume
                      </span>
                    </a>
                  </Row>
                </Space>
                <Space>
                  <Row style={{ display: "flex" }}>
                    <Upload
                      name="resume"
                      disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
                      showUploadList={false}
                      beforeUpload={resumeBeforeUpload}
                      accept=".pdf"
                      style={{
                        width: "100%",
                        border: "2px solid red",
                      }}
                    >
                      <Button
                        disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
                        type="text"
                        style={{ paddingLeft: "0", display: "flex" }}
                      >
                        <BiRefresh
                          style={{
                            fontSize: "1rem",
                            color: Color.black,
                            marginRight: "5px",
                            marginTop: "5px",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "0.8rem",
                            alignSelf: "center",
                            marginTop: "5px",
                          }}
                        >
                          Change Resume
                        </span>
                      </Button>
                    </Upload>
                    {user?.role?.includes("Agency") ||user?.role.includes("Recruiter") || user?.role.includes("Sourcer") ? ""
                    :
                    <Button
                      type="text"
                      style={{ paddingLeft: "0", display: "flex" }}
                      onClick={() =>
                        changeCandidateInfo(
                          candidateFieldType?.CANDIDATE_BLACKLIST,
                          !candidateInfo?.blacklist
                        )
                      }
                    >
                      {candidateInfo?.blacklist ? (
                        <CheckSquareOutlined
                          style={{
                            color: "red",
                            fontSize: "1.5rem",
                            marginRight: "5px",
                          }}
                        />
                      ) : (
                        <AiOutlineStop
                          style={{
                            color: "red",
                            fontSize: "1rem",
                            marginRight: "5px",
                            marginTop: "5px",
                          }}
                        />
                      )}
                      <span
                        style={{
                          fontSize: "0.8rem",
                          alignSelf: "center",
                          marginTop: "5px",
                        }}
                      >
                        {candidateInfo?.blacklist ? "Whitelist" : "Blacklist"}
                      </span>
                    </Button>
                    }
                  </Row>
                </Space>
              </div>
            </Space>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
               {user?.role?.includes("Agency") ||user?.role.includes("Recruiter") || user?.role.includes("Sourcer") ?
                ""
                :
              <div>
                {!isHrProcess&&!user?.role?.includes("Agency")&&(
                  <Row
                    style={{
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={candidateInfo?.duplicate}
                      onChange={() => {
                        changeCandidateInfo(
                          candidateFieldType?.CANDIDATE_DUPLICATE,
                          !candidateInfo?.duplicate
                        );
                      }}
                    ></Checkbox>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        marginRight: "2rem",
                        marginLeft: "0.5rem",
                      }}
                    >
                      MARK AS DUPLICATE
                    </span>
                  </Row>
                )}
              </div>
              }
            </div>
          </div>
        </div>
      }
      onOk={() => {}}
      onCancel={closeModal}
      footer={[
        <Button
          onClick={discard}
          loading={candidateRejectedLoader}
          form="editCandidateDetail"
          style={{
            width: "7rem",
            height: "2rem",
            background: Color.midGrey,
            borderRadius: "5%",
            color: Color.black,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="modalButton"
          size="large"
        >
          Discard
        </Button>,
        <Button
          htmlType="submit"
          form="editCandidateDetail"
          key="submit"
          loading={candidateShortlistedLoader}
          className="modalButton"
          size="large"
          style={{
            width: "7rem",
            background: Color.blue,
            color: Color.white,
            height: "2rem",
            borderRadius: "5%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        id="editCandidateDetail"
        layout="vertical"
        onFinish={onFinish}
        className={classes.formControl}
        form={form}
        initialValues={modify(candidateInfo)}
      >
        <Row gutter={24} style={form_row}>
          <Col span={12}>
            <Form.Item name="phone" label={ <div style={formTitle}>MOBILE NUMBER</div>}
             rules={[
              {
                required: true,
                message: "Please enter valid data!",
              },
            ]}
            >
              <Input type="number" disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label={<div style={formTitle}>EMAIL ID</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter valid data!",
                },
              ]}
            >
              <Input type="text" disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={form_row}>
          <Col span={12}>
            <Form.Item name="jobId" label={ <div style={formTitle}>JOB ROLE</div>} 
              rules={[
                {
                  required: true,
                  message: "Please enter valid data!",
                },
              ]}
            >
              <Select
              showSearch
              disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
                type="text"
                placeholder="Select a Job Role"
                className={classes.select}
                optionFilterProp="children"
                // style={{ boxShadow: "none" }}
                //defaultValue={candidateInfo?.job?.title}
                // onChange={(item) => }
              >
                {candidateJobs?.map((item, i) => (
                  <Option key={i} style={{ width: "100%" }} value={item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="currentCompany" label={<div style={formTitle}>CURRENT COMPANY</div>}
            rules={[
              {
                required: true,
                message: "Please enter Current Company or None!",
              },
            ]}
            >
              <Input disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
              //  defaultValue={candidateInfo?.currentCompany} 
                required  />
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={24}
          style={{
            borderBottom: `2px solid ${Color.lightGrey}`,
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            height: "4rem",
          }}
        >
          <Col span={12}>
            <Form.Item name="noticePeriod" label={<div style={formTitle}>NOTICE PERIOD(days)</div>}
            rules={[
              {
                required: true,
                message: "Please enter valid data!",
              },
            ]}
            >
              <Input type={"number"} disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
              // defaultValue={candidateInfo?.noticePeriod}
               required min={0}/>
            </Form.Item>
          </Col>
        </Row>
        <h3 style={{ fontWeight: "bold",marginTop:"1.5rem" }}>EXPERIENCE</h3>
        <Row gutter={24} style={form_row}>
          <Col span={12}>
            
            <Form.Item name="experienceYears" label={<div style={formTitle}>YEAR</div>}
             rules={[
              {
                required: true,
                message: "Please enter valid data!",
              },
            ]}
            >
              <Input type="number" disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
              // defaultValue={candidateInfo?.experienceYears}
               min={0}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="experienceMonths" label={<div style={formTitle}>MONTH</div>} 
             rules={[
              {
                required: true,
                message: "Please enter valid data!",
              },
            ]}
            >
              <Input type="number" disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
              // defaultValue={candidateInfo?.experienceMonths}
               min={0}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={form_row}>
          <Col span={12}>
            <Form.Item name="currentCtc"label={<div style={formTitle}
            >CURRENT CTC</div>}
            rules={[
              {
                required: true,
                message: "Please enter valid data!",
              },
            ]}
            >
              <Input type="number" disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
              //  defaultValue={candidateInfo?.currentCtc} 
               />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="expectedCtc" label={<div style={formTitle}
            >EXPECTED CTC</div>}
            rules={[
              {
                required: true,
                message: "Please enter valid data!",
              },
            ]}
            >
              <Input type="number" disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
              // defaultValue={candidateInfo?.expectedCtc} 
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={form_row}>
          <Col span={12}>
            <Form.Item name="currentLocation" label={<div style={formTitle}
            >CURRENT LOCATION</div>}
            rules={[
              {
                required: true,
                message: "Please enter valid data!",
              },
            ]}
            >
              <Input disabled={user?.role?.includes("Agency") ||user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
              // defaultValue={candidateInfo?.currentLocation} 
              />
            </Form.Item>
          </Col>
         
              <Col span={12}>
                <Form.Item name="preferredLocation" label={ <div style={formTitle}>PREFERRED LOCATION</div>}
                rules={[
                  {
                    required: true,
                    message: "Please enter valid data!",
                  },
                ]}
                >
                  <Select
                    disabled={user?.role?.includes("Agency") || user.role?.includes("Recruiter") || user.role?.includes("Sourcer") ? true : false }
                    type="text"
                    placeholder="Select a preferred Location"
                    className={classes.select}
                    style={{ boxShadow: "none" }}
                    // defaultValue={candidateInfo?.preferredLocation?.name
                    // }
                    // onChange={(item) => }
                  >
                    {preferredJobLocation?.map((item, i) => (
                      <Option key={i} style={{ width: "100%" }} value={item.id}>
                        <Tooltip title={item.name}>{item.name}</Tooltip>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            
        </Row>
        <Row gutter={24} style={form_row}>
          <Col span={24}>
            <Form.Item name="comment" label={<div style={formTitle}>AGENCY COMMENT</div>}>
              <Input type="text"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={form_row}>
          <Col span={12}>
            <Form.Item name="note" label={<div style={formTitle}>NOTE </div>}>
              <Input disabled={user?.role?.includes("Agency")? true : false }/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
          
          </Col>
        </Row>
        {user?.role?.includes("Agency") || user?.role.includes("Recruiter") || user?.role.includes("Sourcer")?
         "" :
        <Row gutter={24} style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Form.Item
              name="shortlisterComment"
              placeholder="Type your comments here"
              label={<div style={formTitle}>COMMENTS FROM SHORTLISTER</div>}
            >
              <Select 
              disabled={(user?.role.includes("Shortlister") || user?.role?.includes("Admin")) || user?.role.includes("Super-Admin")?false:true}
              allowClear={true} placeholder="Select" name="shortlisterComment"
                  style={{
                    color: Color.black,
                  }}
                  onChange={(value) =>{
                
                      setOthersValue(value);
                    
                    }
                  }
                >
                  {selectOptions.map((item,index)=>{
                      return(<Option value={item} key={index}>{item}</Option>)
                  })}
                </Select>
             
              {/* <TextArea 
                style={{ fontWeight: "bold",  }}
                // defaultValue={candidateInfo?.shortlisterComment}
              /> */}
            </Form.Item>
            {othersValue === "Other"?
                <Input placeholder="Enter the Other Comment"
                onChange={(e)=>{
                  setInputVal(e.target.value);
                }}
                />
                :""}
          </Col>
        </Row>
        }
      </Form>
    </Modal>
  );
};

export default connect(mapStateToProps,mapDispatchToProps) (EditCandidateModal);
