import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  ClockCircleOutlined,
  MoreOutlined,
  StopOutlined,
  WarningOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Popover,
  Space,
  Table,
  Input,
  Timeline,
  Tooltip,
  Typography,
  Modal,
  Row, Pagination,
  Radio,Spin, Checkbox
} from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import ResumeIcon from "../../assets/ResumeIconss.svg";
import {
  Color,
  InformationModal,
  userContent,
  EmptyTable,
  modalContent,
} from "services";
import {
  badgeHash,
  CANDIDATE_DETAILS,
  dateFormat,
  inputFieldType,
  operationType,
  postDateFormat,
  NOTICE_PERIOD_ORDER,
  REASONS
} from "./redux/action";
import profileIcon from '../../assets/profileIcon.svg.svg'
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import Schedule from "pages/dashboard-home/schedule";
import "./styles.css";
import "services/styles.css";
import DuplicateViewModal from "./duplicateViewModal";
import FeedbackViewModal from "./candidateFeedbackView";
import { withRouter } from "react-router";
import HrFeedback from "./feedback/hrFeedback";
import Feedback from "./feedback/feedback";
import store from "store";
const { RangePicker } = DatePicker;

const statusConfirmModal = (props, record, status) => {
   
  const { handleCandidateStatus,getAllCandidates,candidatePageMeta} = props;
  InformationModal.confirm(
    <div>
      <WarningOutlined style={{ fontSize: "3em", color: Color.red }} />
    </div>,
    <div>Are you sure you want to mark the candidate as {status}?</div>,
    <div style={{ color: Color.darkGrey }}>
      {modalContent("Candidate Name:", record.name)}
    </div>,
    
    () => {handleCandidateStatus(record.id, status)
    getAllCandidates("",candidatePageMeta.page,candidatePageMeta.itemsPerPage)}
  );
};

const cancelCurrentRound = (props, record) => {

  const { getAllCandidates,candidatePageMeta,cancelRound,cancelButton,recordId,} = props;
  InformationModal.confirm(
    <div>
      <WarningOutlined style={{ fontSize: "3em", color: Color.red }} />
    </div>,
    <div>Are you sure you want to cancel the current round?</div>,
    
    <div style={{ color: Color.darkGrey }}>
      
      {modalContent("Candidate Name :  " ,<Typography.Text ellipsis={{tooltip:true}} style={{width:"12rem"}}>{record.name}</Typography.Text>)}
      {modalContent("Round : ", record.currentRound)}
    </div>,
    () => {
      props.performOperation(operationType.CANCEL_BUTTON,true)
      props.performOperation(operationType.RECORD_ID,record.id)
  }
  );
};


const skipRoundConfirmModal = (props, record) => {
  
  const { getAllCandidates,candidatePageMeta, handleSkipRound, candidateStatus} = props;
  const sameRoundSkipCheck =
    record.candidateStatus === candidateStatus.scheduled ||
    record.candidateStatus === candidateStatus.hold ||
    record.candidateStatus === candidateStatus.notSelected;
  InformationModal.confirm(
    <div>
      <WarningOutlined style={{ fontSize: "3em", color: Color.red }} />
    </div>,
    <div>{record.hiringStatus !="Selected" ? <p>Are you sure you want to skip the next round?</p> : <p style={{color:"red"}}>candidate is already selected</p>}</div>,
    
    <div style={{ color: Color.darkGrey }}>
      
      {modalContent("Candidate Name:",<Typography.Text ellipsis={{tooltip:true}} style={{width:"11rem"}}>{record.name}</Typography.Text>)}
      {modalContent(
        "Round:",
        sameRoundSkipCheck ? record.currentRound : record.currentRound + 1
      )}
      {modalContent("Candidate status:", record.hiringStatus)}
    </div>,
    () => {
     
      handleSkipRound(record.id, record.hiringStatus)
      getAllCandidates("",candidatePageMeta.page,candidatePageMeta.itemsPerPage)}
    
  );
};

const skipCurrentRoundConfirmModal = (props, record) => {
  
  const { getAllCandidates,candidatePageMeta, handleSkipCurrentRound, candidateStatus} = props;
  const sameRoundSkipCheck =
    record.candidateStatus === candidateStatus.scheduled ||
    record.candidateStatus === candidateStatus.hold ||
    record.candidateStatus === candidateStatus.notSelected;
  InformationModal.confirm(
    <div>
      <WarningOutlined style={{ fontSize: "3em", color: Color.red }} />
    </div>,
    <div>{record.hiringStatus !="Selected" ? <p>Are you sure you want to skip the current round?</p> : <p style={{color:"red"}}>candidate is already selected</p>}</div>,
    
    <div style={{ color: Color.darkGrey }}>
      
      {modalContent("Candidate Name:",<Typography.Text ellipsis={{tooltip:true}} style={{width:"11rem"}}>{record.name}</Typography.Text>)}
      {modalContent(
        "Round:",
        sameRoundSkipCheck ? record.currentRound : record.currentRound
      )}
      {modalContent("Candidate status:", record.hiringStatus)}
    </div>,
    () => {
     
      handleSkipCurrentRound(record.id, record.hiringStatus)
      getAllCandidates("",candidatePageMeta.page,candidatePageMeta.itemsPerPage)}
    
  );
};

const showInterviewFeedback = (props, record) => {
  setTimeout(() =>{
    props.setFeedbackView(true);
  },500)

  props.setCurrenCandidate(record);
};

const statusMenu = (props, record) => (
  <Menu style={{}}>
    <Menu.Item
      key="1"
      onClick={() =>
        statusConfirmModal(props, record, props.candidateStatus.notSelected)
      }
    >
      Not Selected
    </Menu.Item>
    <Menu.Item
      key="2"
      onClick={() =>
        statusConfirmModal(props, record, props.candidateStatus.hold)
      }
    >
      Hold
    </Menu.Item>
    <Menu.Item
      key="3"
      onClick={() =>
        statusConfirmModal(props, record, props.candidateStatus.notInterested)
      }
    >
      Not Interested
    </Menu.Item>
    {/* <Menu.Item key="3" onClick={() => statusConfirmModal(props, record, props.candidateStatus.selected)}>
      Selected
    </Menu.Item> */}
    <Menu.Item
      key="4"
      onClick={() =>
        statusConfirmModal(props, record, props.candidateStatus.inprogress)
      }
    >
      In Progress
    </Menu.Item>
    {record?.hiringStatus === "Scheduled"&&<Menu.Item
      key="5"
      onClick={() =>
        cancelCurrentRound(props, record, "Cancel Current Round")
      }
    >
      Cancel Current Round
    </Menu.Item>}
    {props?.tabStatus!=="Rejected"&&<Menu.Item key="6" onClick={() => skipCurrentRoundConfirmModal(props, record)}>
      Skip Current Round
    </Menu.Item>}
    <Menu.Item
      key="7"
      onClick={() =>
        statusConfirmModal(props, record, "Re-Schedule")
      }
    >
      Reschedule
    </Menu.Item>
    {props?.tabStatus!=="Rejected"&&<Menu.Item key="8" onClick={() => skipRoundConfirmModal(props, record)}>
      Skip Upcoming Round
    </Menu.Item>}
    {/* {record?.feedback?.length > 0 && */}
    <Menu.Item key="9" onClick={() => showInterviewFeedback(props, record)}>
      View Feedback
    </Menu.Item>
    {/* } */}
  </Menu>
);

const timelineHash = {
  Cancelled: Color.red,
  Shortlisted: Color.blue,
  Skipped: Color.blue,
  Scheduled: Color.yellow,
  Selected: Color.green,
  "Not Selected": Color.red,
  Hold: Color.darkGrey,
  New: Color.yellow,
  "In Progress": Color.yellow,
  "Not Shortlisted": Color.red,
  "Not Interested": Color.red,
  Completed: Color.blue,
  "Re-Discussion":Color.yellow,
  "Re-Schedule":Color.yellow,
  "Feedback-Pending":"#6C0BA9",
  "ProposedJd":Color.blue,
};

const timelineDotHash = {
  Cancelled: <CloseCircleOutlined style={{ color: Color.red }} />,
  Selected: <CheckCircleOutlined style={{ color: Color.green }} />,
  "Not Selected": <CloseCircleOutlined style={{ color: Color.red }} />,
  "Not Shortlisted": <CloseCircleOutlined style={{ color: Color.red }} />,
  "Not Interested": <CloseCircleOutlined style={{ color: Color.red }} />,
  Hold: <ExclamationCircleOutlined style={{ color: Color.darkGrey }} />,
  New: <ExclamationCircleOutlined style={{ color: Color.yellow }} />,
  "In Progress": <CheckCircleOutlined style={{ color: Color.green }} />,
  Scheduled: <ClockCircleOutlined style={{ color: Color.yellow }} />,
  Shortlisted: <ClockCircleOutlined style={{ color: Color.blue }} />,
  "Re-Discussion": <ClockCircleOutlined style={{ color: Color.yellow }}/>,
  "Re-Schedule": <ClockCircleOutlined style={{ color: Color.yellow }}/>,
  "Feedback-Pending": <ClockCircleOutlined style={{ color: "#6C0BA9"}}/>,
  "ProposedJd": <ClockCircleOutlined style={{  color: Color.blue }}/>,

};


export const timelineContent = (props, record) => {
  const { candidateStatus, candidateJobDescription, candidateScreenType } =
    props;
    const {user}=store.getState().auth
 
  const timelineCommonCode = (status) => {
    return (
      <Timeline
        mode="left"
        className="candidate-timeline"
        style={{ marginBottom: "0px",height:"11rem",overflowY:"scroll" }}
      >
        {record?.interviewSlots
          .sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1))
          .map((scheduleDetails) => {
            
            return (
              <>
                {/*{timelineSkipButton()}*/}
                <Timeline.Item
                  position="left"
                  color={timelineHash[scheduleDetails?.interviewStatus]}
                  dot={timelineDotHash[scheduleDetails?.interviewStatus]}
                  label={
                    <div className="m-b-10 m-l-20" style={{marginLeft:"1rem"}}>
                      {scheduleDetails !== null ? (
                        <div style={{ color: Color.darkGrey }}>
                          {moment(scheduleDetails.date, postDateFormat).format(
                            dateFormat
                          )}
                        </div>
                      ) : (
                        "NA"
                      )}
                      {/* { scheduleDetails && scheduleDetails.schedule ? <div style={{ color: Color.darkGrey }}>{moment(scheduleDetails.date, postDateFormat).format(dateFormat)}</div> : ""} */}
                      <div><b>Round </b><b>{scheduleDetails.round}</b></div>
                     {user.role.includes("Agency")?"":<div>Panelist-{scheduleDetails?.panelist?.name}</div>} 
                    </div>
                  }
                >
                  <div
                    style={{
                      color: timelineHash[scheduleDetails?.interviewStatus],
                      marginTop:"1rem"
                    }}
                  >
                    {scheduleDetails?.interviewStatus}
                  </div>
                  {/* { scheduleDetails !== null ? <div style={{ color: timelineHash[status] }}><div>{scheduleDetails.schedule.startTime} - {scheduleDetails.schedule.endTime}</div></div> : ""} */}
                  {scheduleDetails && scheduleDetails.schedule !== null ? (
                    <div
                      style={{
                        color: timelineHash[scheduleDetails?.interviewStatus],
                      }}
                    >
                      <div>
                        {scheduleDetails.schedule.startTime} -{" "}
                        {scheduleDetails.schedule.endTime}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Timeline.Item>
              </>
            );
          })}
      </Timeline>
    );
  };
  

  switch (record.hiringStatus) {
    case candidateStatus.new:
    case candidateStatus.selected:
    case candidateStatus.notSelected:
    case candidateStatus.hold:
    case candidateStatus.notInterested:
    case candidateStatus.rediscussion:
    case candidateStatus.reschedule:

    case candidateStatus.inprogress:
      return timelineCommonCode("In Progress");
    case candidateStatus.scheduled:
      return timelineCommonCode("Scheduled");
    case candidateStatus.shortlisted:
      return timelineCommonCode("Shortlisted");
    case candidateStatus.feedbackPending:
      return timelineCommonCode("Feedback-Pending");
    case candidateStatus.proposed:
      return timelineCommonCode("ProposedJd");;
    case candidateStatus.notShortlisted:
      return;
    default:
      return;
  }
};

const disableAction = (record, { candidateStatus, user }) => {
  const currentStatus = record.hiringStatus;
  return (
    currentStatus === candidateStatus.notSelected ||
    currentStatus === candidateStatus.hold ||
    currentStatus === candidateStatus.selected ||
    currentStatus === candidateStatus.notShortlisted ||
    currentStatus === candidateStatus.notInterested
  );
};

// const disableMoreOutlined = (record) => {
//   return record.feedback.length === 0 || (record.feedback.length > 0 && record.feedback.slice().sort((a, b) => (a.round > b.round ? 1 : -1))[record.feedback.length -1].roundType === "Technical")
// }

export const rangePickerMenu = ({
  updateInputField,
  candidateDateDropdown,
  candidateDateRange,
  getAllCandidates,
  history,
}) => (
  <div>
  <span>
  <Menu style={{ left: "-90%" }}>
    <Menu.Item key="1">
      <RangePicker
        format={"YYYY-MM-DD"}
        onChange={(date, dateString) => {
          
          const updatedDate = date ? dateString : null;
          updateInputField(inputFieldType.CANDIDATE_DATE_RANGE, updatedDate);
          updateInputField(
            inputFieldType.CANDIDATE_DATE_DROPDOWN,
            !candidateDateDropdown
          );
          
          getAllCandidates(history);
        }}
        value={
          candidateDateRange
            ? [
                moment(candidateDateRange[0], "YYYY-MM-DD"),
                moment(candidateDateRange[1], "YYYY-MM-DD"),
              ]
            : null
        }
      />
    </Menu.Item>
  </Menu>
  </span>
  </div>
);

export const filterStatusMenu = ({
  updateInputField,
  statusDropdownAction,
  candidateFilterStatus,
  history,
  candidateRejectedStatus,
  candidateOngoingStatus,
  candidateScreenType,
}) => {
  const iterableStatus =
      candidateScreenType === "History" ? ["New","Pre Shortlisting","Pre Shortlisted","ProposedJd", ...candidateRejectedStatus, ...candidateOngoingStatus, ]
      : candidateScreenType === "New" ? ["New","Pre Shortlisting","Pre Shortlisted", ...candidateRejectedStatus, ...candidateOngoingStatus, ]
      : candidateScreenType === "Rejected" ? candidateRejectedStatus
      : candidateOngoingStatus;
  return (
    <Menu selectedKeys={[candidateFilterStatus]} style={{ left: "-90%" }}>
      {["All", ...iterableStatus].map((status) => (
        <Menu.Item
          key={status}
          onClick={() => {
            updateInputField(inputFieldType.CANDIDATE_FILTER_STATUS, status);
            statusDropdownAction(history);
          }}
        >
          <Badge status={badgeHash[status]} text={status} />
        </Menu.Item>
      ))}
    </Menu>
  );
};

export const filterHackathonStatusMenu = ({
  updateInputField,
  hackathonStatusDropdownAction,
  candidateFilterHackathonStatus,
  history,
  candidateHackathonStatusDropdown
}) => {
  const iterableStatus = ["New","Passed","Failed","Link sent",]
//   return (
//     <Menu selectedKeys={candidateFilterHackathonStatus} mode="multiple" style={{ left: "-90%" }}>
//   {["All", "Passed", "Failed", "Link sent", "Document Uploaded"].map((status) => (
//     <Menu.Item
//       key={status}
//       onClick={() => {
//         updateInputField(inputFieldType.CANDIDATE_FILTER_HACKATHON_STATUS, status);
//         hackathonStatusDropdownAction(history);
//       }}
//     >
//       <input
//         type="checkbox"
//         checked={candidateFilterHackathonStatus.includes(status)}  // Adjust this line based on your logic
//         style={{ float: "right" }}
        
//       />
//       <span>{status}</span>
//     </Menu.Item>
//   ))}
// </Menu>
//   );
return (
  <div style={{ backgroundColor: '#fff', padding: '10px',boxShadow:`1px 1px 6px grey`,display:"flex",flexDirection:"column",gap:"1rem"}}> {/* Add background color and padding */}
  <div style={{fontSize:"1rem",paddingLeft:"0.4rem",cursor:"pointer",backgroundColor:candidateFilterHackathonStatus === "All"&&"#E1EBFE"}} 
  onClick={(selectedOptions) => {
    updateInputField(inputFieldType.CANDIDATE_FILTER_HACKATHON_STATUS, "All");
  }}>All</div>
  <Checkbox.Group
    onChange={(selectedOptions) => {
      updateInputField(inputFieldType.CANDIDATE_FILTER_HACKATHON_STATUS, selectedOptions);
    }}
    value={candidateFilterHackathonStatus}
  >
    <div style={{display:"flex",flexDirection:"column"}}>
    {[ ...iterableStatus].map((status) => (
      <Checkbox
        key={status}
        value={status}
        style={{ margin: "0 8px 8px 0", }}
      >
        {status === "New" ? "Not applicable" : status}
      </Checkbox>
    ))}
    </div>
  </Checkbox.Group>
  <div style={{display:"flex",flexDirection:"row",gap:"0.5rem",justifyContent:"flex-end"}}>
    {/* <Button onClick={()=>{
            updateInputField(inputFieldType.CANDIDATE_FILTER_HACKATHON_STATUS, candidateFilterHackathonStatus);
            updateInputField(
              inputFieldType.CANDIDATE_HACKATHON_STATUS_DROPDOWN,
              false
            )
    }}>Cancel</Button>{console.log("ssssDropdown",candidateHackathonStatusDropdown)} */}
  <Button type="primary" onClick={()=>{
          hackathonStatusDropdownAction(history);
          updateInputField(
            inputFieldType.CANDIDATE_HACKATHON_STATUS_DROPDOWN,
            false
          )
  }}>Ok</Button>
  </div>
</div>
);
};

const sortMenu = (props) => (
  <Menu onClick={({ key }) => handleSortClick(props, key)}>
    <Menu.Item key="ASC">Ascending</Menu.Item>
    <Menu.Item key="DESC">Descending</Menu.Item>
    <Menu.Item key="null">NA</Menu.Item>
  </Menu>
);

const handleSortClick = (props, key) => {
    props.performOperation(NOTICE_PERIOD_ORDER, key);
    props.getAllCandidates();
};

const shortlistedCandidateColumns = (props) => {
  const {user} = store.getState().auth
  const columns = [
    {
      title: <div style={{marginLeft:"1rem"}}>Name</div>,
      dataIndex: "name",
      key: "name",
      width: "14.5rem",
      render: (text, record) => (
        <div>
           <div style={{display:"flex"}}>
              {record.photo ? (
                <img
                  src={record.photo}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "1rem",
                    borderRadius: "50%",
                    marginRight:"15px",
                  }}
                />
              ) : (
                <img
                  src={profileIcon}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "1rem",
                    marginRight:"15px",
                    borderRadius: "50%",
                  }}
                />
              )}
            
            <Popover content={userContent(record)}>
              <a
                style={{ fontWeight: "500",marginTop:"5px" }}
                onClick={() => {
                  props.performOperation(
                    operationType.CANDIDATE_VIEW_MODAL,
                    true
                  );
                  props.updateInputField(CANDIDATE_DETAILS, record);
                 
                }}
              >
                <Typography.Text ellipsis={{tooltip:false}} style={{width:"6.5rem"}}>{record.name}</Typography.Text>
              </a>
            </Popover>
            </div>
          <div>
           
          </div>
          {record.blacklist || record.duplicate ? (
            <Space size="large">
              <div style={{marginLeft:"2.9rem", }}>
                <Tooltip title="Blacklist">
                  <StopOutlined
                    style={
                      record.blacklist
                        ? { color: Color.black, marginRight:"0.3rem", }
                        : { color: Color.midGrey, marginRight:"0.3rem", }
                    }
                  />
                </Tooltip>

                <Tooltip title="Duplicate">
                  <CopyOutlined
                    style={
                      record.duplicate
                        ? { color: Color.red }
                        : { color: Color.midGrey }
                    }
                    onClick={() => {                   
                      props.updateInputField(CANDIDATE_DETAILS, record);
                      props.getDuplicateCandidates();
                    }}
                  />
                </Tooltip>
              </div>
            </Space>
          ) : (
            ""
          )}
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
      width:"10rem",
      render: (text, record) =>
        `${record.experienceYears} Y ${
          record.experienceMonths !== 0 ? `${record.experienceMonths} M` : ""
        }`,
    },
    {
      title: (
        <div>
          <Space size="small">
            Notice Period (Days)
            <Dropdown
              //visible={props.candidateStatusDropdown}
              overlay={sortMenu(props)}           
              // trigger={["click"]}
              id="notice-period-dropdown"
            >
              <SortDescendingOutlined />
            </Dropdown>
          </Space>
        </div>
      ),
      dataIndex: "noticePeriod",
      key: "noticePeriod",
      width:"12rem",
    },
    {
      width: 170,
      title: "Job Title",
      dataIndex: "job",
      key: "job",
      render: (job) => (
        <Popover content={job?.title}>
          <Space size="small">{job?.title}</Space>
        </Popover>
      ),
    },
    {
      title: (
        <div>
          <Space size="small">
          Date

            {/* <Dropdown
              trigger={['click']}
              visible={props.candidateDateDropdown}
              overlay={rangePickerMenu(props)}
              onClick={() =>
                props.updateInputField(
                  inputFieldType.CANDIDATE_DATE_DROPDOWN,
                  !props.candidateDateDropdown
                )
              }           
              id="date-dropdown"
            >
              <a>
                <CalendarOutlined id="dateRange-calender" />
              </a>
            </Dropdown> */}

         <Popover
          placement="bottom"
          content={rangePickerMenu(props)}
          trigger="click"
          visible={props.candidateDateDropdown}
          onVisibleChange={() =>{
            props.updateInputField(
              inputFieldType.CANDIDATE_DATE_DROPDOWN,
              !props.candidateDateDropdown
            )      
          }
          }
        >
         <CalendarOutlined id="dateRange-calender" />
        </Popover>

          </Space>
          <div style={{ color: Color.modalblue }}>
            {props.candidateDateRange
              ? `${props.candidateDateRange[0]} - ${props.candidateDateRange[1]}`
              : ""}
          </div>
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      width:"10rem",
      render: (text) => moment(text).format(dateFormat),
    },
  ];
  if (user?.role?.includes("Agency") || user?.role.includes("Sourcer") || user?.role.includes("HR") || 
      user?.role.includes("Recruiter") ||user?.role.includes("Admin")|| user?.role.includes("Super-Admin")
      // ||!user?.role?.includes("Shortlister") || !user?.role?.includes("Panelist") || user?.role.length > 1
      ) {
      columns.push({
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
      width:"10rem",
      render: ( agency,record) => {
       
        return (<div>{agency?.agencyName}-{ record?.user?.name}</div>)
      },
    });
  }
  columns.push({
    title: (
      <div>
        <Space size="small">
          Status
          <Dropdown
            //visible={props.candidateStatusDropdown}
            overlay={filterStatusMenu(props)}
            onClick={() =>
              props.updateInputField(
                inputFieldType.CANDIDATE_STATUS_DROPDOWN,
                !props.candidateStatusDropdown
              )
            }
            trigger={["click"]}
            id="status-dropdown"
          >
            <FilterOutlined style={{color:Color.modalblue}} />
          </Dropdown>
        </Space>
        <div style={{ color: Color.modalblue }}>
          {props.candidateFilterStatus}
        </div>
      </div>
    ),
    dataIndex: "status",
    key: "status",
    width:"10rem",
    render: (text, record) => (
      <div>
        <Popover content={() =>timelineContent(props, record)}>
          <Badge
            style={{ cursor: "pointer",widht:"100%" }}
            status={badgeHash[record?.hiringStatus]}
            text={<span style={{fontSize:"14px"}}>{record?.hiringStatus}</span>}
          />
        </Popover>
        <div style={{ fontSize: "14px", marginLeft: "5px" }}>
          Round {record.currentRound}
        </div>
      </div>
    ),
  },
  );

  columns?.push({
    title:<span>Shortlister's Comment</span>,
    dataIndex:"shortlisterComment",
    key:"shortlisterComment",
    width:"15rem",
    render:(text,record)=>(
      <div>
        <span>{(record?.shortlisterComment&&record?.shortlisterComment!=="null")?record?.shortlisterComment:"NA"}</span>
      </div>
    )
  })
  columns.push({
    title: (
      <div>
        <Space size="small">
          Hackathon Status
          <Dropdown
            visible={props.candidateHackathonStatusDropdown}
            overlay={filterHackathonStatusMenu(props)}
            onVisibleChange={(visible) =>
              props.updateInputField(
                inputFieldType.CANDIDATE_HACKATHON_STATUS_DROPDOWN,
                visible
              )
            }
            trigger={["click"]}
            id="status-dropdown"
          >
            <FilterOutlined style={{color:Color.modalblue}} />
          </Dropdown>
        </Space>
        {Array.isArray(props.candidateFilterHackathonStatus) ? props?.candidateFilterHackathonStatus?.length > 0 && props.candidateFilterHackathonStatus.map((item, index) => {
                return (
                    <h3 style={{ color: Color.modalblue, textAlign: "center" }}>
                        {item === "New" ? "Not applicable" : item}
                        {index === props.candidateFilterHackathonStatus.length - 1 ? "" : ","}
                    </h3>
                )
            }) : 
            <h3 style={{ color: Color.modalblue, textAlign: "center" }}>
               {props?.candidateFilterHackathonStatus}
            </h3>}
      </div>
    ),
    dataIndex: "hackathonStatus",
    key: "hackathonStatus",
    width:"12rem",
    render: (text, record) => (
      <div>
          {/* <Badge
            style={{widht:"100%" }}
            status={badgeHash[record?.hackathonStatus]}
            text={ */}
            <span style={{fontSize:"14px"}}>{record?.hackathonStatus === "New" ? "Not applicable" : record?.hackathonStatus}</span>
          {/* /> */}
           {/* <div style={{ fontSize: "14px", marginLeft: "5px" }}>
          Round {record.currentRound}
        </div> */}
      </div>
    ),
  }
  
  );
  columns.push({
    width: "10rem",
    title: <span>Hackathon Score</span>,
    dataIndex: "score",
    key: "score",
    render: (text, record) => (
      <Popover content={record?.test === null ? "NA" : record?.test?.candidateTotal}>
        <Space size="small">
        <span style={{ fontSize: "13px" }}>
        {record?.test !== null && record?.test !== undefined && record?.test?.candidateTotal !== null ? record?.test?.candidateTotal
        : "NA" 
        }
        </span>        
        </Space>
      </Popover>
    ),
  },
  
  );
  columns?.push({
    title:<span>Candidate's Priority</span>,
    dataIndex:"candidatePriority",
    key:"candidatePriority",
    width:"15rem",
    render:(text,record)=>(
      <div>
        <span>{(record?.candidatePriority&&record?.candidatePriority!=="null")?record?.candidatePriority:"NA"}</span>
      </div>
    )
  })
  return columns;
};

const adminShortlistedCandidateColumns = (props) => {
  const {user} = store.getState().auth

  // props?.user?.role?.includes("Sourcer") ? () :
  return (
  [
   
     {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width:"10%",
      render: (text, record) => (
        <>
           {user?.role?.includes("Agency") ? 
           <Space size="small">
            <Button
            onClick={() => {            
              props.updateInputField(CANDIDATE_DETAILS, record);
              props.performOperation(operationType.INTERVIEW_MODAL, true);
            }}
            disabled={disableAction(record, props) }
            icon={<ClockCircleOutlined />}
            style={{ border: "none", backgroundColor: "inherit" }}
            id={`clock${record.key}`}
          />
          </Space>
          :user?.role?.includes("HR") && !user?.role.includes("Recruiter") && !user?.role?.includes("Super-Admin") && !user?.role?.includes("Admin") ? 
          <Dropdown
          overlay={statusMenu(props, record,)}
          trigger={["click"]}
           >
          <Button
            style={{ border: "none", backgroundColor: "inherit" }}
            icon={<MoreOutlined />}
            id={`moreOutlined${record.key}`}
          />
          </Dropdown>
          :
          <Space size="small">
          <Button
            onClick={() => {            
              props.updateInputField(CANDIDATE_DETAILS, record);
              props.performOperation(operationType.INTERVIEW_MODAL, true);
            }}
            disabled={disableAction(record, props) }
            icon={<ClockCircleOutlined />}
            style={{ border: "none", backgroundColor: "inherit" }}
            id={`clock${record.key}`}
          />
          <Dropdown
            overlay={statusMenu(props, record)}
            trigger={["click"]}
          >
            <Button
              style={{ border: "none", backgroundColor: "inherit" }}
              icon={<MoreOutlined />}
              id={`moreOutlined${record.key}`}
            />
          </Dropdown>
          </Space>
          }
        </>
      ),
    },
  ])
}

const okHandler =(props)=>{
  
}

class ShortlistedCandidate extends React.Component {
  constructor(props) {
      super(props); 
      // Don't call this.setState() here!
      this.props.updateInputField(inputFieldType.CANDIDATE_DATE_RANGE,"");

      this.state = { windowDimensions: {
                  width: window.innerWidth,
                  height: window.innerHeight,
                }, };
    }

    
    componentDidMount() {
   
      window.addEventListener("resize", this.handleResize);
  
      this.setState({
        windowDimensions: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
    }
  
    componentWillUnmount() {
      window.removeEventListener("resize", this.handleResize);
    }
  
    handleResize = () => {
      this.setState({
        windowDimensions: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
    };
  // componentDidUpdate() {
  //   const {updateInputField, candidateDateDropdown} = this.props
  //   window.addEventListener('click', function(e) {
  //     if (e.target.id !== "date-dropdown" && e.target.id !== "dateRange-calender" && candidateDateDropdown === true)
  //       updateInputField(inputFieldType.CANDIDATE_DATE_DROPDOWN, false)
  //   });
  // }
  handlePageChange(page,) {
    this.props.candidateScreenType === "Rejected"
      ? this.props.updateInputField(
          inputFieldType.CANDIDATE_REJECTED_PAGE_NUMBER,
          page
        )
      : this.props.updateInputField(
          inputFieldType.CANDIDATE_ONGOING_PAGE_NUMBER,
          page
        );
    this.props.getAllCandidates(this.props.history, page);
  }

  handlePageChange = ( page, pageSize,value) => {
    this.props.getAllCandidates(this.props.history,page, pageSize);
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // setSelectedRow(selectedRowKeys);
      this.props.performOperation(inputFieldType.SELECTED_ROW,selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {
    },
    onSelectAll: (selected, selectedRows, changeRows) => {},
    getCheckboxProps: (record) => ({}),
  };
  
  render() {
    const {
      candidates,
      candidateTableLoader,
      candidateJobDescription,
      // user,
      candidateinfo,
      interviewModal,
      candidatePageMeta,
      viewFeedback,
      getAllCandidates,
      cancelButton,
      recordId,
      reasonVal,
      otherReasonVal,
      cancelRound,
      candidateScreenType
    } = this.props;
    const {user} = store.getState().auth
    const { width, } = this.state.windowDimensions;

    return (
      <div style={{
        }} >
          {width > 700 ?
        <div>
          <Table
            rowSelection={candidateScreenType === "Rejected" ? null : this.rowSelection}
            columns={ user?.role.includes("Recruiter")||user?.role?.includes("Super-Admin") || user?.role?.includes("Admin") || user?.role?.includes("Agency")||user?.role?.includes("HR")? 
            shortlistedCandidateColumns(this.props)
              .concat(
                adminShortlistedCandidateColumns(this.props))
              .map((col) => ({ ...col, align: "center" }))
            : shortlistedCandidateColumns(this.props)
            .map((col) => ({ ...col, align: "center" })) 
            
            
            }
            scroll={{ x:2300, y:390 }}
            pagination={{
              position: "bottomRight",
              pageSize: candidatePageMeta.itemsPerPage ? candidatePageMeta?.itemsPerPage:10,
              pageSizeOptions:[10, 30, 50, 100],
              size: "small",
              total: candidatePageMeta?.totalItems
                ? candidatePageMeta?.totalItems
                : 0,
              onChange: (page,pageSize) => this.handlePageChange(page,pageSize),
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              // defaultCurrent: candidateScreenType === "Rejected" ? candidateRejectedPageNumber : candidateOngoingPageNumber,
            }}
            dataSource={candidates}
            rowClassName="data-row"
            //rowSelection="checkbox"
            loading={candidateTableLoader}
            locale={{
              emptyText: candidateTableLoader ? (
                <div className="no-data-loader"></div>
              ) : Object.keys(candidateJobDescription).length === 0 ? (
                <EmptyTable text="No Profiles Found" />
              ) : (
                <EmptyTable text="No Profiles Found" />
              ),
            }}
          />
        </div>    
        :<>
        <div className="ListBodyContainer" style={{height:"60vh", overflowY:'scroll',overflowX:"scroll",width:"100%",backgroundColor:"#F9F9F9",marginTop:"0.7rem"}}>
         {candidateTableLoader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><Spin /></div>:
            candidates?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'69vh'}}><div>No data found</div></div>:
            candidates?.map((record,id,index)=>{
               return(
                <div style={{width:"100%",marginTop:"0.3rem",marginBottom:"1rem",borderRadius:10,backgroundColor:"#FFFFFF",padding:"0.8rem 0.7rem"}}>
                {/* <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"0.5rem",paddingTop:"0.3rem",borderBottom:"1px solid #DFDFDF"}}>
                 <div style={{display: "flex",justifyContent: "flex-start"}}>
                   <div>
                     {record.photo ? (
                       <img src={record.photo} style={{ width: "40px",height: "40px",borderRadius: "50%", }} />
                     ) : (
                       <img src={profileIcon} style={{ width: "40px",height: "40px",borderRadius: "50%",}} />
                     )}
                   </div>
                   <div style={{display: "flex",flexDirection:"row",marginLeft: "0.7rem",alignItems:"center",}}>
                     <div style={{ display: "flex"}}>
                       <div>
                         <Popover content={userContent(record)}>
                           <a style={{fontWeight:600}}
                             // onClick={() => {
                             //   props.updateInputField(CANDIDATE_DETAILS, record);
                             //   props.performOperation(
                             //     operationType.CANDIDATE_VIEW_MODAL,
                             //     true
                             //   );
                             // }}
                           >
                           <Typography.Text ellipsis={{tooltip:false}} style={{width:"7.5rem",fontSize:"1rem",fontWeight:600}}>{record.name}</Typography.Text>
                           </a>
                         </Popover>
                       </div>
                       <Space size="large">
                         <div>
                           {record.blacklist ? (
                             <Tooltip title="Blacklist">
                               <StopOutlined
                                 style={
                                   record.blacklist
                                     ? { color: Color.black }
                                     : { color: Color.midGrey }
                                 }
                               />
                             </Tooltip>
                           ) : (
                             ""
                           )}
                           {record.duplicate ? (
                             <Tooltip title="Duplicate">
                               <CopyOutlined
                                 style={
                                   record.duplicate
                                     ? { color: Color.red }
                                     : { color: Color.midGrey }
                                 }
                                 onClick={() => {
                                   this.props.updateInputField(CANDIDATE_DETAILS, record);
                                   this.props.getDuplicateCandidates();
                                   
                                 }}
                               />
                             </Tooltip>
                           ) : (
                             ""
                           )}
                         </div>
                       </Space>
                     </div>
                   </div>
                 </div>
                 
                 <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                       <span style={{ fontSize: 10 }}>
                         <a
                           onClick={() => {
                             this.props.updateInputField(
                               inputFieldType.CANDIDATE_RESUME,
                               record.resume
                             );
                             this.props.performOperation(
                               operationType.CANDIDATE_PDF_VIEW_MODAL,
                               true
                             );
                            //  console.log("sssSHeelloo")
                           }}
                         >
                           <img src={ResumeIcon} />
                         </a>
                       </span>
                  </div>
                </div> */}

                <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"0.5rem",paddingTop:"0.3rem",borderBottom:"1px solid #DFDFDF"}}>
                <div style={{display:"flex"}}>
                    {record.photo ? (
                      <img src={record.photo}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginLeft: "1rem",
                          borderRadius: "50%",
                          marginRight:"15px",
                        }}
                      />
                    ) : (
                      <img
                        src={profileIcon}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginLeft: "1rem",
                          marginRight:"15px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  
                  <Popover content={userContent(record)}>
                    <a
                      style={{ fontWeight: "500",marginTop:"5px" }}
                      // onClick={() => {
                        // this.props.performOperation(
                        //   operationType.CANDIDATE_VIEW_MODAL,
                        //   true
                        // );
                        // this.props.updateInputField(CANDIDATE_DETAILS, record);
                      
                      // }}
                    >
                      <Typography.Text ellipsis={{tooltip:false}} style={{width:"6.5rem"}}>{record.name}</Typography.Text>
                    </a>
                  </Popover>
                  </div>
                  <div>
                  </div>
                    {record.blacklist || record.duplicate ? (
                      <Space size="large">
                        <div style={{marginLeft:"2.9rem", }}>
                          <Tooltip title="Blacklist">
                            <StopOutlined
                              style={
                                record.blacklist
                                  ? { color: Color.black, marginRight:"0.3rem", }
                                  : { color: Color.midGrey, marginRight:"0.3rem", }
                              }
                            />
                          </Tooltip>

                          <Tooltip title="Duplicate">
                            <CopyOutlined
                              style={
                                record.duplicate
                                  ? { color: Color.red }
                                  : { color: Color.midGrey }
                              }
                              onClick={() => {                   
                                this.props.updateInputField(CANDIDATE_DETAILS, record);
                                this.props.getDuplicateCandidates();
                              }}
                            />
                          </Tooltip>
                        </div>
                      </Space>
                    ) : (
                      ""
                    )}
                </div>

                <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                 <span style={{fontSize:14,color:"#808080"}}>Job Title</span>
                 <p style={{fontSize:15,color:"#161616"}}>
                 <Popover content={record?.job?.title}>
                  <Space size="small">{record?.job?.title ? record?.job?.title:"NA"}</Space>
                </Popover></p>
                </div>
                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                 <span style={{fontSize:14,color:"#808080"}}>Experience</span>
                 <p style={{fontSize:15,color:"#161616"}}>{`${record.experienceYears} Y ${
                    record.experienceMonths !== 0 ? `${record.experienceMonths} M` : ""}`}</p>
                </div>
                </div>

                <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"0.5rem 0"}}>
                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                 <span style={{fontSize:14,color:"#808080"}}>Date</span>
                 <p style={{fontSize:15,color:"#161616"}}>{record?.createdAt ? moment(record?.createdAt).format(dateFormat):"NA"}</p>
                </div>
                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"50%"}}>
                 <span style={{fontSize:14,color:"#808080"}}>Notice Period (Days)</span>
                 <p style={{fontSize:15,color:"#161616"}}>{record?.noticePeriod ? record?.noticePeriod:"NA"}</p>
                </div>
                </div>

                <div style={{width:"100%",marginTop:"1rem",borderBottom:"0.6rem"}}>
                 <Button type="primary" style={{height:"2.1rem",width:"100%",borderRadius:5}}
                  onClick={()=>{
                   this.props.updateInputField(CANDIDATE_DETAILS, record);
                   this.props.performOperation(operationType.CANDIDATE_VIEW_MODAL, true);
                 }}
                 >
                   View Profile
                 </Button>
                </div>

             </div>
             )
           })}
        </div> 
        <div>
          <Pagination position="bottomRight" size="small" style={{display:"flex",justifyContent:"flex-end",marginTop:"0.8rem",fontSize:width<700&&15,}}
            pageSizeOptions= {[10,25, 30, 50, 100]}
            showSizeChanger={true}
            pageSize= {candidatePageMeta.itemsPerPage ? candidatePageMeta?.itemsPerPage:10}
            onChange={(page, pageSize) => this.handlePageChange(page, pageSize)}
            onShowSizeChange={(page, pageSize) => this.handlePageChange(page, pageSize)}
            // pageSize={parseInt(candidatePageMeta.itemsPerPage)}
            // current={parseInt(candidatePageMeta.currentPage)}
            total={candidatePageMeta?.totalItems
              ? candidatePageMeta?.totalItems
              : 0}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`}
          /> 
        </div>
        </>
        } 

        {/* <Table
          columns={ user?.role.includes("Recruiter")||user?.role?.includes("Super-Admin") || user?.role?.includes("Admin") || user?.role?.includes("Agency")||user?.role?.includes("HR")? 
          shortlistedCandidateColumns(this.props)
            .concat(
               adminShortlistedCandidateColumns(this.props))
            .map((col) => ({ ...col, align: "center" }))
          : shortlistedCandidateColumns(this.props)
          .map((col) => ({ ...col, align: "center" })) 
          
          
          }
          scroll={{ x:2000, y:390 }}
          pagination={{
            position: "bottomRight",
            pageSize: candidatePageMeta?.itemsPerPage,
            pageSizeOptions:[10, 30, 50, 100],
            size: "small",
            total: candidatePageMeta?.totalItems
              ? candidatePageMeta?.totalItems
              : 0,
            onChange: (page,pageSize) => this.handlePageChange(page,pageSize),
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            // defaultCurrent: candidateScreenType === "Rejected" ? candidateRejectedPageNumber : candidateOngoingPageNumber,
          }}
          dataSource={candidates}
          rowClassName="data-row"
          //rowSelection="checkbox"
          loading={candidateTableLoader}
          locale={{
            emptyText: candidateTableLoader ? (
              <div className="no-data-loader"></div>
            ) : Object.keys(candidateJobDescription).length === 0 ? (
              <EmptyTable text="No Profiles Found" />
            ) : (
              <EmptyTable text="No Profiles Found" />
            ),
          }}
        /> */}

        <Modal visible={cancelButton} centered closable={false} 
          width="35%" onOk={()=>{
            
          cancelRound(recordId,reasonVal === "Others" ? otherReasonVal : reasonVal )
          this.props.performOperation(operationType.REASON_VAL,"")
          this.props.performOperation(operationType.OTHER_REASON_VAL,"")
          getAllCandidates("",candidatePageMeta.page,candidatePageMeta.itemsPerPage)
          this.props.performOperation(operationType.CANCEL_BUTTON,false)
         }} 
         okButtonProps={{disabled: reasonVal==="" ? true : reasonVal === "Others" && otherReasonVal === "" ? true : false}}
         onCancel={()=>{
          this.props.performOperation(operationType.REASON_VAL,"")
          this.props.performOperation(operationType.OTHER_REASON_VAL,"")
          this.props.performOperation(operationType.CANCEL_BUTTON,false)
          // getAllCandidates("",candidatePageMeta.page,candidatePageMeta.itemsPerPage)
        }} 
        >
        <div style={{margin:"1.5rem"}}>
          <div style={{display:"flex",fontSize:"1.3rem",fontWeight:"bold",marginBottom:"0.5rem",}}>Reason : </div>
          <Row >
          <h4 style={{fontWeight:"600",fontSize:"0.93rem"}}>
            Choose Appropriate Reason for not attending interview
          </h4>
        </Row>
        <Row>
        <Radio.Group value={reasonVal} style={{width:"100%",marginTop:"0.5rem"}}
            onChange={(e)=>{
              this.props.performOperation(operationType.REASON_VAL,e.target.value)
            }} >
            <Space direction="vertical" style={{marginLeft:"10px",textAlign:"left",width:"100%",display:'flex'}}>
                  <Radio value={"Candidate was Unavailable"}>Candidate was Unavailable</Radio>
                  <Radio value={"Panelist was Unavailable"} >Panelist was Unavailable</Radio>
                  <Radio value={"Network Issues"} >Network Issues</Radio>
                  <Radio value={"Others"} style={{width:"100%",display:"flex"}}>
                    Others
                  </Radio>
          </Space>
        </Radio.Group>
        <Row>
        {reasonVal === "Others" ? (
                      <Input type="text" value ={otherReasonVal}
                        style={{
                          margin:"0.5rem",
                          width:"175%",
                          height:60,
                        }} 
                        onChange={(event)=>{
                          let values=event.target.value
                          this.props.performOperation(operationType.OTHER_REASON_VAL,values)
                        }}
                      />
                    ) : null}
        </Row>
        </Row>
         {/* <div>
            <Input style={{width:"90%"}} value={reasonVal} placeholder="Enter the Reason" type="text" onChange={(e)=>{
              this.props.performOperation(operationType.REASON_VAL,e.target.value)
            }}/>
        </div> */}
        </div>
        </Modal>

     {interviewModal && <Schedule
       performOperation={this.props.performOperation}
         refresh={getAllCandidates.bind(this)}
        // scheduleRecord={candidateinfo}
        />}
        <DuplicateViewModal />
        {viewFeedback && <FeedbackViewModal />}
        <HrFeedback/>
      </div>
    );
  }
}
// Form.create()(ShortlistedCandidate);

const routerShortlistedCandidate = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortlistedCandidate);

export default withRouter(routerShortlistedCandidate);
// refresh={this.props.getAllCandidates(this.props.history,"","");.bind(this)}
// scheduleModal={scheduleModal}
// setScheduleModal={setScheduleModal}
// scheduleRecord={scheduleRecord}
// setScheduleRecord={setScheduleRecord}