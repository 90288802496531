import { Row, Col, Typography, Modal, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./style.css";

export default function Warning(props) {
  const { Title } = Typography;

  return (
    <Modal
      visible={props.visible}
      footer={false}
      centered={true}
      className="modal"
      onCancel={(value) => {
      props.confirmationRequst(false);
    }}
    >

      <div style={{
        textAlign: "center",
        marginTop: "30px",
        marginBottom: "10px",
      }}>
        <Title
          style={{
            marginBottom: "10px",
            font: "normal normal normal 24px/33px Open Sans",
            color: "#161616",
          }}
        >
          Delete ? {props.subject}
        </Title>
      </div>

      <div style={{
          textAlign: "center",
          marginBottom: "50px",
        }}> <Title
            style={{
              marginBottom: "10px",
              font: "normal normal normal 12px/20px Open Sans",
              color: "#6F6F6F",
            }}
          >
            Are u sure to delete this ? {props.subject}
          </Title></div>

      <Row justify="center">
        <Col span={6}>
          <Button
            onClick={(value) => {
              props.confirmationRequst(false);
            }}
            style={{ width: "100%" }}
          >
            Cancel
          </Button>
        </Col>
        <Col span={6} offset={1}>
          <Button
            htmlType="submit"
            style={{ width: "100%", background: "#FF9797 0% 0% no-repeat padding-box" }}
            onClick={(value) => {
              props.confirmationRequst(true);
            }}
          >
            {props.okText}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
