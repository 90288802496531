import { DownloadOutlined } from '@ant-design/icons';
import { Modal,Typography } from 'antd';
import classes from './jobTitleModal.module.css';
import { jdActions } from './store';
import { useDispatch,useSelector } from 'react-redux';
import PdfLogo from "../../assets/pdf-logo.svg";


const JobTitleModal=(props)=>{
    const jobTitleModalOpen=useSelector(state=>state?.jd?.jobTitleModalOpen);
    const dispatch=useDispatch();
    const agencyDetails=props?.agencyDetails;
    return(
        <Modal open={jobTitleModalOpen} onCancel={()=>dispatch(jdActions.updateJobTitleModalOpen(false))} maskClosable={true} footer={null} width="700px" centered>  
                    <div className={classes.container}>
                                <div>
                                       <div>
                                        <h1>
                                        {/* <Typography.Text ellipsis={{tooltip:true}} style={{width:"6.5rem"}}> */}
                                         {agencyDetails?.title}
                                        {/* </Typography.Text> */}
                                        </h1>
                                        </div>
                                        <div style={{cursor:"pointer"}} onClick={() => window.open(agencyDetails?.descriptionFile)}>
                                             {/* <a>   */}
                                             <img
                                                src={PdfLogo}
                                                alt="pdf-logo"
                                                className="pdf-logo"
                                             />
                                            <h2 style={{fontSize:"0.9rem",color:"#0086FF"}}>View the Document</h2>
                                            {/* </a> */}
                                        </div>
                                </div>
                        <div>
                                    <div>
                                                <span>Activated On:</span>
                                                <h4>{agencyDetails?.updatedAt?.split('T')[0]}</h4>
                                    </div>
                                    <div>
                                                <span>Notice in Days:</span>
                                                <h4>{agencyDetails?.noticePeriod}</h4>
                                    </div>            
                        </div>
                        <div>
                                    <div>
                                                    <span>Vacancies:</span>
                                                    <h4>{agencyDetails?.vacancies}</h4>
                                    </div>
                                    <div>
                                                    <span>Experience in Years:</span>
                                                    <h4>{agencyDetails?.maxExperience}</h4>
                                    </div>              
                        </div>
                        <div>
                                    <div>
                                                    <span>Keywords:</span>
                                                    <h4>{agencyDetails?.keywords?.map((item,index)=>{
                                                        if(index<agencyDetails?.keywords?.length-1)
                                                        {
                                                            return item+","
                                                        }
                                                        return item;
                                                    })}</h4>
                                        </div>
                                        <div>
                                                    <span>Location:</span>
                                                    <h4>{agencyDetails?.locations?.map((item,index)=>{
                                                        console.log("sssLocation",index,agencyDetails?.locations?.length)
                                                        if(index === agencyDetails?.locations?.length-1)
                                                        {
                                                            return item.name
                                                        }
                                                        return item.name+",";
                                                    })}</h4>
                                        </div>              
                        </div>                   
                        <div>
                                        <span>Contact Person</span>
                                        <div>
                                                    <div>
                                                                <span>Name:</span>
                                                                <h4>{agencyDetails?.spoc?.name}</h4>
                                                    </div>
                                                    <div>
                                                                <span>Phone:</span>
                                                                <h4>{agencyDetails?.spoc?.phone}</h4>
                                                    </div>
                                        </div>                 
                            </div>
                            
                    </div>
        </Modal>
    )
}
export default JobTitleModal;