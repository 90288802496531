import React, { useEffect } from "react";
import RoomClient from "./roomClient";
import { setUpChatSocket, setUpRoomClientSocket, user } from './utils';

let roomClientAulasRecording = null;
let broadcastHealthTimer = null;

const RecordVideo =()=>{

    // const socketCallback = (event, data) => {
    //     if (event==='muxError'){
    //         // console.log('muxError ', data);
    //         // // if(thiz.audioOn)
    //         // // if(thiz.screenON)
    //         // roomClient?.closeProducer('screenType');
    //         // setTimeout(()=>{
    //         //     roomClient?.closeProducer('audioType');
    //         // }, 1001)
    //         // setTimeout(()=>{
    //         //     roomClient?.produce('audioType')
    //         // },2000)
    //         // this.produce("audioType")
    //     }else if(event==='connect'){
    //         clearTimeout(broadcastHealthTimer)
    //     }
    //     else if(event==='disconnect'){
    //         console.log('19x socket is disconnected');
    //         broadcastHealthTimer = setTimeout(() => {
    //             // if (window.location.pathname==='/broadcast'){
    //             //   Notification.error("", "Socket disconnected, join again to resume the class")
    //             //   history.goBack();
    //             // }
    //             console.log("19x exitRoom called after 10 sec");
    //           }, 10000);
    //     }
    // }

    // const recordingCallback = async (event, dataReceived) => {
    //     console.log("318eee", event, dataReceived)
    //     if (event==='rtmpStarted'){
           
    //     }else if(event==='rtmpRemoved'){
    //         setTimeout(()=>{
    //             roomClientAulasRecording.closeProducer('audioType');
    //         }, 1000);
            
    //     }else if(event==='audioAdded'){
    //         // console.log("439", shareType)
    //         // if (shareType==='screen'){
    //         //     roomClientAulasRecording.produce('screenType')
    //         // }else{
    //             roomClientAulasRecording.produce('videoType')
    //         // }
    //     }
    //     // else if (obj.command === "webSocketConnected") {
    //     //     //     // adapter.toggle("camera+screen");     
    //     //       }
    //   }

    // const onUnmount = () =>{
    //     window.addEventListener("beforeunload", onUnload);
    // }

    // const onUnload = (e) => {
    //         onUnmount();
    //         e.preventDefault();
    //         // e.returnValue = '';
    // }

    // useEffect(()=>{
    //     roomClientAulasRecording = new RoomClient({ 
    //     room_id: `123_random`, 
    //     refer:false, 
    //     user:user, 
    //     name: user.name, 
    //     user_id:user.id, 
    //     socket:setUpRoomClientSocket("/aulasrecording.io", history, socketCallback), 
    //     // socket:setUpRoomClientSocket("/aulasstudent.io", null, socketCallback), 
    //     eventCallback:recordingCallback, setStreamCallback: ()=>{}})    

    //     onUnmount();

    //     return ()=>{
    //         window.removeEventListener("beforeunload", onUnload)
    //         }
    // },[])

    return(
        <>
        <button onClick={()=>{
            // roomClientAulasRecording.produce("audioType")
        }}>start Sharing</button>
        <button onClick={()=>{
            // roomClientAulasRecording.closeProducer('videoType')
        }}>stop Sharing</button>
        </>
    )
}
export default RecordVideo;