import { Button, Col, Modal, Row, Select, Avatar, Spin } from "antd";
import React, { Component } from "react";
import { Color } from "services";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import * as moment from "moment";
import { UserOutlined } from "@ant-design/icons";
// import ViewImage from "pages/candidate/viewImage";

const { Option } = Select;

class HrProcessFeedbackViewModal extends Component {
  componentDidMount() {
    const { getCandidateFeedback } = this.props;
    getCandidateFeedback();
  }

  render() {
    const {
      viewFeedback,
      feedbackLoader,
      candidateRounds,
      candidatesScreenshot,
      candidateFeedbackRound,
      candidateFeedback,
      selectCandidateRound,
      updateInputField,
      performOperation,
    } = this.props;
    return (
      <Modal
        visible={viewFeedback}
        width="90%"
        title="View Feedback"
        onCancel={() => {
          this.props.setCandidateScreenshot([]);
          this.props.setFeedbackView(false);
        }}
        footer={
          <Button
            shape="round"
            onClick={() => this.props.setFeedbackView(false)}
          >
            Cancel
          </Button>
        }
      >
        <Row style={{ justifyContent: "flex-end" }}>
          <Select
            style={{ width: "100px" }}
            value={candidateFeedbackRound}
            disabled={feedbackLoader}
            selectCandidateRound
            placeholder="Select Round"
            onChange={(val) => selectCandidateRound(val)}
          >
            {candidateRounds.map((round) => (
              <Option value={round.value}>{round.label}</Option>
            ))}
          </Select>
        </Row>
        <Row style={{ height: "80%" }}>
          {!feedbackLoader ? (
            <Row>
              <Col span={6}>
                <div className="candidate-details ">
                  <div className="feedback-left-content">
                    {candidateFeedback.candidatePhoto !== "" ? (
                      <img
                        src={candidateFeedback.candidatePhoto}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                        alt=""
                        onClick={() => {
                          // updateInputField(CANDIDATE_IMAGE, feedbackCandidateInfo.candidatePhoto);
                          // performOperation(CANDIDATE_IMAGE_VIEW_MODAL, true);
                        }}
                      />
                    ) : (
                      <Avatar size="100" icon={<UserOutlined />} />
                    )}
                    <Row>
                      <Col span={24}>Name of Candidate</Col>
                      <Col span={24} style={{ color: Color.modalblue }}>
                        {candidateFeedback.name}
                      </Col>
                    </Row>
                  </div>
                  <Row className="feedback-left-content">
                    <Col span={24}>Round</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound]
                          ?.round}
                    </Col>

                    <Col span={24}>Round Type</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound]
                          ?.roundType}
                    </Col>

                    <Col span={24}>Name of Interviewer</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound]
                          ?.interviewer}
                    </Col>

                    <Col span={24}>Date of Interview</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        moment(
                          candidateFeedback.feedback[candidateFeedbackRound]
                            ?.interviewDate,
                          "YYYY-MM-DD"
                        ).format("DD MMM YYYY")}
                      {/* {interviewSlots.date
                                ? moment(interviewSlots.date, postDateFormat).format("DD MMM YYYY")
                                : null} */}
                    </Col>

                    <Col span={24}>Mode of Interview</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback &&
                        candidateFeedback.feedback &&
                        candidateFeedback.feedback[candidateFeedbackRound]
                          ?.interviewMode}
                    </Col>

                    <Col span={24}>Experience</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback.experienceYears} Years{" "}
                      {candidateFeedback.experienceMonths !== 0
                        ? `${candidateFeedback.experienceMonths} Months`
                        : ""}
                    </Col>

                    <Col span={24}>Current Location</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback.currentLocation}
                    </Col>

                    <Col span={24}>Preferred Location</Col>
                    <Col span={24} style={{ color: Color.modalblue }}>
                      {candidateFeedback.preferredLocation}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={18}>
                {candidatesScreenshot.map((feedbackScreenshot) =>
                  feedbackScreenshot.value + 1 ===
                  candidateFeedback.feedback[candidateFeedbackRound]?.round ? (
                    <img
                      src={feedbackScreenshot.label}
                      style={{
                        width: "200px",
                        height: "100px",
                        borderRadius: "0%",
                        marginLeft: "10px",
                      }}
                      alt=""
                      onClick={() => {
                        // updateInputField(CANDIDATE_IMAGE, feedbackScreenshot.label);
                        // performOperation(CANDIDATE_IMAGE_VIEW_MODAL, true);
                      }}
                    />
                  ) : (
                    ""
                  )
                )}
                {candidateFeedback &&
                  candidateFeedback.feedback &&
                  candidateFeedback.feedback[candidateFeedbackRound] &&
                  Object.entries(
                    candidateFeedback.feedback[candidateFeedbackRound]?.details
                  ).map(([key, val]) => (
                    <div style={{ padding: 10 }}>
                      <span style={{ fontWeight: "bold" }}>{key}:</span> {val}
                    </div>
                  ))}
              </Col>
            </Row>
          ) : (
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin tip="loading" className="on-page-loader"></Spin>
              </div>
            </Col>
          )}
        </Row>
        {/* <ViewImage /> */}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HrProcessFeedbackViewModal);
