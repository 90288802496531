import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import CreateUser from "pages/create-user/adminAgency";

import Test from "pages/dashboard-testpapers/test";
import TestList from "pages/dashboard-testpapers/test-list";
import CandidateInfo from "pages/dashboard-candidate/candidate-info";
import CandidateMockTest from "pages/dashboard-candidate/candidate-test";
import ThankYou from "pages/dashboard-candidate/thank-you";
import CandidateProfile from "pages/dashboard-home/candidate-profile";
import PrivateRoute from './PrivateRoute';
import TestInstructions from "pages/dashboard-candidate/test-instruction"
import JdDashboard from 'pages/jd-dashboard/jdListExperienced';
import Colleges from 'pages/college-dashboard/collegeList';
import EmailTemplate from 'pages/email-template/email-template';
import RecordVideos from "pages/recorded-videos/recordVideos";
import Candidates from 'pages/dashboard-home/candidate';
import JdDashboardFresher from "pages/jd-dashboard-fresher/jdListFresher";
import FeedBackManagement from "pages/feedbackmanagement/FeedBackManagement";
import FeedbackRound from "pages/feedbackmanagement/feedbackRound";
import FeedbackPreview from "pages/feedbackmanagement/feedbackpreview";

import Candidate from "pages/candidate/candidate";
import AgencySummary from "pages/agency-summary/summary"
import Allpanelist from "pages/panelist-all/allPanelist";
import HrPorcess from "pages/hr-process/hrProcess";
import LogsActions from "pages/logs-action/logsActions";
import InterviewSchedules from "pages/Panelist-interviewSchedules/interviewSchedules";
import PanelistFeedback from "pages/panelist-feedback/panelistFeedback"
import InterviewExp from "pages/inerview-exp/interviewExp";
import InterviewFresher from "pages/interview-fresher/interviewFresher";
import JobSummary from "pages/job-summary";
import DashboardPanView from "pages/panelistView/dashboard/dashboard";
import LocationAndDepartment from "pages/location-orgDept/home";
import InstituteDashboard from "pages/institute-dashboard/dashboard";
import OverAllDashboard from "pages/overAllDashboard/overAllDashboard";
import FeedbackResponse from "pages/feedbackManagementResponse/feedbackResponse";
import JobSummaryDetail from "pages/job-summary/detail"
// /panelist/interviewSchedule?search=agency cand
export default function Routes(props){
  let path=new URL(location.href).pathname;
  let value=path.split("=")
  
  return <Switch>
      <Route exact path="/thank-you/:status">
      {/* {console.log("etere")} */}
        <ThankYou />
      </Route>
      {value[0]==="/panelist/interviewSchedule?search"?
      <Route  path="/panelist/interviewSchedule?:search" >
        {/* {console.log("enetere")} */}
      <InterviewSchedules/>
    </Route>
       :value[0]==="/panelist/interviewSchedule"? <Route exact path="/panelist/interviewSchedule" >
      <InterviewSchedules/>
     </Route>:""}
      {/* <PrivateRoute exact path="/candidate-info" ate">
        <CandidateInfo />
      </PrivateRoute>
      <PrivateRoute exact path="/test-instruction" ate">
        <TestInstructions />
      </PrivateRoute>
      <PrivateRoute exact path="/candidate-test/:valued" ate">
        <CandidateMockTest />
      </PrivateRoute> */}
     <Route exact path="/locn-dept" >
      <LocationAndDepartment/>
    </Route>

    <Route exact path="/institute-dashboard" >
      <InstituteDashboard navList={props.navList} setNavlist={props.setNavlist} updatePath={props.updatePath}/>
    </Route>

    <Route exact path="/interview-exp" >
      <InterviewExp/>
    </Route>

    <Route exact path="/interview-fresher" >
      <InterviewFresher/>
    </Route>

      <Route exact path="/create-user" >
      <CreateUser/>
    </Route>
           
      {/* <DashboardDesign/> */}
      <Route exact path="/Dashboard" >
      <OverAllDashboard/>
    </Route>
    <Route exact path="/hr-process" >
      <HrPorcess/>
    </Route>

    <Route exact path="/logs-action" >
      <LogsActions/>
    </Route>
    {/* /panelist/interviewSchedules */}
   

    <Route exact path="/panelist-feedback" >
      <PanelistFeedback/>
    </Route>

      <Route exact path="/test/:testId" >
        <Test />
      </Route>
      <Route exact path="/test-list" >
        <TestList />
      </Route> 

      <Route exact path="/feedback-management-response" >
        <FeedbackResponse/>
      </Route> 

      <Route exact path="/profiles" >
        <CandidateProfile />
      </Route>  
      <Route exact path="/jd" >
        <JdDashboard />
      </Route>
      <Route exact path="/job-summary"  >
        <JobSummary />
      </Route>
      <Route
                      exact
                      path="/job-summary/:id"
                      
                    >
                      <JobSummaryDetail/>
                    </Route>
      <Route exact path="/jdFreshers" >
        <JdDashboardFresher />
      </Route>
      <Route exact path="/institutes" >
        <Colleges />
      </Route>

      <Route exact path="/email-template" >
        <EmailTemplate />
      </Route>

      <Route exact path="/recorded-videos" >
        <RecordVideos />
      </Route>

      <Route exact path="/fresher-candidate-management" >
        <Candidates />
      </Route>
      
      <Route exact path="/fbmanagement" >
        <FeedBackManagement/>
      </Route>

      <Route exact path="/fbround" >
        <FeedbackRound/>
      </Route>

      <Route exact path="/fbpreview" >
        <FeedbackPreview/>
      </Route>


      <Route exact path="/panelistmanagement" >
        <Allpanelist/>
      </Route>

      <Route  exact path="/dashboard-view" >
        <DashboardPanView navList={props.navList} setNavlist={props.setNavlist} updatePath={props.updatePath}/>
      </Route>
      
      
      
      <Route  exact path="/agency-summary" >
        <AgencySummary/>
      </Route>
      

      <Route  exact path="/exp-candidate-management" >
        <Candidate />
      </Route>

  


</Switch>
}


function UiComponent(){
  return <div>Ui components</div>
}