import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    basicInfo:{},
}
    const HrFeedbackSlice = createSlice({
        name: "hrFeedback",
        initialState,
        reducers: {
            updatefeedback(state, action) {
              state.basicInfo= {...state.basicInfo,...action.payload};
            },
        removeFeedback(state,action){
            state.basicInfo={}
        }}
         
        
      });   
    
      
      

export default HrFeedbackSlice.reducer;
export const HrFeedbackActions = HrFeedbackSlice.actions;