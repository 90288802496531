import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
const PrivateRoute = ({ role, children, ...rest }) => {
  const { token, user } = useSelector((state) => state.auth);
  
  let map={}
  let isAllowed
  for(let i=0;i<user?.role?.length;i++){
    map[user?.role[i]]=true
  }
  for(let j=0;j<role?.length;j++){
    if(map[role[j]] ===true){
      isAllowed = true
      break;
    }
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAllowed ? (
          children
        ) : (
          <Redirect to={{ pathname: "/AccessLoginLoading/token", state: { from: location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
