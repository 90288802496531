import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Select,
  Modal,
  Input,
  Typography,
  Button,
  Divider,
  Radio,
  Tabs,
  Space
} from "antd";

import Edit from '../../assets/ic-edit.svg';
import classes from './test.module.css';
import Marks from "./../../Marks.svg";
import Cutoff from "./../../Cutoff.svg";
import Question from "./../../Question.svg";
import Duration from "./../../Duration.svg"
import { useSelector, useDispatch } from "react-redux";
import Editor from "./editor";
import Frames from "./../../Frames.svg"
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router";
import Api from "services/Api";
import { testActions } from "./store";
import { LoadingOutlined,DeleteOutlined,ArrowLeftOutlined,MoreOutlined,CarryOutOutlined,ClockCircleOutlined,FileDoneOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import Notification from "services/Notification";
import { authActions } from "pages/login/store";
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;
const Test = () => {
  const dispatch = useDispatch();
  const { testId } = useParams();
  let history = useHistory();

  const { testList,sectionDetails,details } = useSelector((state) => state.test);
  const [form] = Form.useForm();

  // const { question } = useSelector((state) => {
  //   return state.candidate;
  // });

  const [input, setInput] = useState("");
  const [finalInput, setFinalInput] = useState("");
  const [spin, setSpin] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let [questionValue, setQuestionValue] = useState({});
  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState(0);
  let [questionId, setQuestionId] = useState(null);
  let [options, setOptions] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);
  const [answer, setAnswer] = useState("");
  const[sectionId,setSectionId]=useState("")
  const[questionsList,setQuestionsList] = useState([])
  const [btnSideSecName,setBtnSecName] =useState("")
  const [quesDeleteLoader,setQuesDeleteLoader] = useState(false)
  const [ seccuttoff,setCuttOff] = useState("")
  const [secTotal,setSecTotal] = useState("")
  const [colorId,setColorId] = useState("")
  const [warModal,setWarModal]=useState(false)
  const [showDelModal,setShowDelModal]=useState(false)  
  const [quenum,setQueNum] = useState()
  const [totMark,setTotMark] = useState()
  const [secRemngMark,setSecRemngMark] = useState()
  const [secvalMarks,setSecValMarks] = useState("")
  const [show, setShow] = useState(false);
  const [isClick,setIsClick]=useState(new Array(20).fill(false));
  const { duration, title, totalMarks,testCutt_off } = useSelector((state) => {
    return state.test.sectionDetails;
  });
  // const  details = useState((state) => state.candidate);

  useEffect(() => {
    Api.get(`/hiring-tests/getQuestionsByTestId?testId=${testId}`)
      .params()
      .send((response) => {
        setSecRemngMark(response?.details?.totalSecRemMarks)
        setTotMark(response?.details?.totalMarks)
        setSectionId(response?.testExist?.sections[0]?.id)
        setSecValMarks(response?.testExist?.sections[0]?.addedMarks)
        setQuestionsList(response?.testExist?.sections[0]?.question)
        setBtnSecName(response?.testExist?.sections[0]?.sectionTitle)
        setSecTotal(response?.testExist?.sections[0]?.sectionMaxMarks)
        setCuttOff(response?.testExist?.sections[0]?.sectionCutt_off)
        setSpin(false);
        dispatch(
          testActions.updateQuestionDetail( response?.testExist )
        );
        dispatch(testActions.updateDetails({ details: response?.details }));
        setIsClick(isClick.map((item,index)=>{
          if(index===0)
          {
            return true;
          }
          else
          {
            return item;
          }
        }))
      });
  }, []);

  let testDetail = testList?.find((item) => {
    if (testId == item?.id) {
      return true;
    }
  });
  const onIncrease = () => {
    setCounter(counter + 1);
  };
  const onDecrease = () => {
    if (counter == 0) {
      return
    }
    setCounter(counter - 1);
  };
  const onIncrease2 = () => {
    setCounter2(counter2 + 1);
  };
  const onDecrease2 = () => {
    if (counter2 == 0) {
      return
    }
    setCounter2(counter2 - 1);
  };
  const onChange = (value) => {
    form.setFieldsValue(value);
    setAnswer(value);
  };

  const gridStyle = {
    width: '20%',
    height:"80%",
    marginBottom:"-20px",
    textAlign: 'center',
    borderRadius: "10px",
  };

  const onFinish = () => {
    setBtnLoader(true);
    const testkey = parseInt(testId);
    const value1 = {
      sectionId:sectionId,
      testId: testkey,
      quesId: questionId,
      options: options,
      question: finalInput,
      positiveMark: counter,
      negativeMark: counter2,
      answer: answer,
    };
    var span = document.createElement('span');
    span.innerHTML = finalInput;
  
    let result=span.textContent 
    let title = "Please fill all mandatory fields";
    if (!options || counter == 0 || !answer || !result) {
      Notification.error(title);
      setBtnLoader(false);
      return;
    }
    Api.post("/hiring-tests/addQuestions")
      .params(value1)
      .send((res,) => {      
       
       Api.get(`/hiring-tests/getQuestionsByTestId?testId=${testId}`)
      .params()
      .send((response) => {
        setSecRemngMark(response?.details?.totalSecRemMarks)
        setSpin(false);
        dispatch(
          testActions.updateQuestionDetail( response?.testExist )
        );
        dispatch(testActions.updateDetails({ details: response?.details }));
        let data= response?.testExist?.sections.find((element => element.id===sectionId))
        setQuestionsList(data?.question)
        setSecValMarks(data?.addedMarks)
      });
      
        setBtnLoader(false);
        if (res?.show?.type=="success"){
          onNew()
         }
      });

   
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const cancelHandler = () => {
    setWarModal(false)
  }
  const WarningShowModal = () => {
    setWarModal(true)
  }
  const delCancelHandler =() =>{
    setShowDelModal(false)
  }
  const DeleteShowModal = () => {
    setShowDelModal(true)
  }
  const handleOk = (e) => {
    e.preventDefault();

    setFinalInput(input);
    setIsModalVisible(false);
  };
  const textInput = (ob) => {
    setInput(ob.value);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClick = (id) => {
    questionValue = questionsList.find((items) => {

      if (id === items?.id) {
        return true;
      }
    });

    setOptions(questionValue.options);
    questionId = questionValue.id;
    setQuestionValue(questionId);
    setQuestionId(questionValue.id);
    setCounter(questionValue.positiveMark);
    setCounter2(questionValue.negativeMark);
    setInput(questionValue.question);
    setFinalInput(questionValue.question);
    setAnswer(questionValue.answer);
  };

   const showCard=(()=>{ <>
    <Card style={{boxShadow:"3px 3px 5px 3px #B4B0B0",margin:"20px"}}>
     <Row style={{ marginBottom: "0.7rem",display:"flex", flexDirection:"column" }}  >
       <Row >
     <h3 style={{color:"#6F6F6F",marginLeft:"20px"}}>MARKS</h3>
     </Row>
     <Row style={{display:"flex",alignItems:"center"}}>
             <Col lg={{ span: 3 }} className={classes.buttonDiv}  >
              
               <div
                 className={classes.button1}
               >
                 <Button type="text" size="large" onClick={onIncrease}>
                   +
                 </Button>
                 {counter}
                 <Button type="text" size="large" onClick={onDecrease}>
                   -
                 </Button>
               </div>
             </Col>
       <Col lg={{ span:3 }}  className={classes.buttonDiv}>
         {/* <h3>Negative marks </h3> */}
         <div
         className={classes.button2}
         >
           <Button type="text" size="large" onClick={onIncrease2}>
             +
           </Button>
           {counter2}
           <Button type="text" size="large" onClick={onDecrease2}>
             -
           </Button>
         </div>
       </Col>
       <Col lg={{ span: 5, offset: 17}}>
         <div  style={{marginTop:"-20%",marginRight:"-140%"}} >
           {questionId!==null ?
           <MoreOutlined style={{fontSize:"22px"}} onClick={()=>{
               return(<div>            
            <Button loading={quesDeleteLoader} style={{color:"white",backgroundColor:"red",marginBottom:"10px"}} onClick={() =>{
             setQuesDeleteLoader(true)
             Api.delete(`/hiring-tests/deleteQuestion?questionId=${questionId}`)
             .send(response =>{
               setQuesDeleteLoader(false)
               Api.get(`/hiring-tests/getQuestionsByTestId?testId=${testId}`)
               .params()
               .send((response) => {
                setSecRemngMark(response?.details?.totalSecRemMarks)
                 setSpin(false);
                 dispatch(
                   testActions.updateQuestionDetail( response?.testExist )
                 );
                 dispatch(testActions.updateDetails({ details: response?.details }));
                 let data= response?.testExist?.sections.find((element => element.id===sectionId))
                 setQuestionsList(data?.question)
               });
                 onNew()
             })
           }}>Delete</Button></div>)
           }}/>
           :""}
         </div>
       </Col>
       </Row>
     </Row>

     <Row >
     <h4 style={{color:"#6F6F6F",marginLeft:"20px"}} required={true}>QUESTION {quenum}</h4>
     </Row>

     <div
       style={{
         margin:"10px auto 10px",
         // marginTop:"1rem",
         cursor: "pointer",
         minHeight: "80px",
         border: "1px solid",
         // marginLeft:"-10px",
         width:"95%"
       }}
       onClick={showModal}
       className="displayquestion"
     >
       {" "}
       {ReactHtmlParser(finalInput)}
     </div>
     <Modal
       title={<p style={{ textAlign: "center" }}>Text Editor</p>}
       width={900}
       visible={isModalVisible}
       onOk={handleOk}
       onCancel={handleCancel}
     >
       <Editor value={input} onChange={textInput} />
     </Modal>
     <div style={{
         margin:"30px auto 10px",
         width:"95%"
       }}>
     {mcq}
     </div>
     <Col lg={{ span: 5, offset: 17}}>
         <div  style={{marginTop:"10%",marginRight:"-65px",marginBottom:"-20px"}} >
         <Form.Item >
       <Button
             type="primary"
             htmlType="submit"
             loading={btnLoader}
             className={classes.saveButton}
           >
             SAVE & NEXT
           </Button>
         </Form.Item>
         </div>
       </Col>
       </Card>
       </>})

  const Buttons = questionsList?.map((item, index) => {
    let id = item?.id;

    return (
      <>
        <Col  style={{ margin: " 0 1.25rem 1.25rem 0"}}>
          <Button size="large"  shape="circle" 
          onClick={() => {
            onClick(id)
            setColorId(id)
            setQueNum(index+1)
            setShow(false)
          }} 
          style={{border:"2px solid #636363",width:"3rem",height:"3rem",color:id===colorId ? "blue" : "#636363", borderColor:id===colorId ? "blue" : "#636363", fontSize:"1.1rem", fontWeight:"bold"}}>
            {index + 1}
          </Button>
        </Col>
      </>
    );
  });
 
  const onNew = () => {
   
    setCounter(0);
    setCounter2(0);
    setFinalInput("");
    setInput("");
    setAnswer("");
    setOptions({});
    setQuestionId(null);
    setQueNum("")
    setColorId("")
  };
  const onOptionChange = (e, field) => {
    const newOptions = {
      ...options,
      [field]: e.target.value,
    };
    setOptions(newOptions);
  };
  let mcq = "";

  mcq = (
    <>
     <Row >
        <h4 style={{color:"#6F6F6F"}}><h1 >Options</h1></h4>
        </Row>
      <div style={{display:"flex" }}>
      
        <div>
        <Radio.Group value={answer} onChange={(e) =>{setAnswer(e.target.value)}} style={{width:"50vw"}}>
        <Row style={{margin:"0px auto 10px",width:"100%"}}>
          <Col span={2}>
          <Button size="large" shape="circle" style={{fontWeight:"600",backgroundColor:answer==="A"?"skyblue":"white"}}>
            A
          </Button></Col>
          <Col span={16}>
          
            <TextArea
             showCount 
            maxLength={500}
              value={options.A}
              size={"large"}
              style={{ width: "100%", marginLeft: '20px', height: "100%" }}
              placeholder="Add option 1 "
              // prefix={<b>A</b>}
             
              onChange={(e) => {
                onOptionChange(e, "A");
              }}
              required={true}
            />
             
          </Col>
          <Col span={5} offset={1}><Radio value={"A"} >Correct answer </Radio></Col>
        </Row>
        <Row style={{margin:"20px auto 10px",width:"100%"}}>
        <Col span={2}>
          <Button size="large" shape="circle" style={{fontWeight:"600",backgroundColor:answer==="B"?"skyblue":"white"}}>
           B
          </Button></Col>
          <Col span={16} >
            <TextArea
             showCount 
             maxLength={500}
              value={options.B}
              size={"large"}
              style={{ width: "100%", height: "100%", marginLeft: '20px' }}
              placeholder="Add option 2"
              // prefix={<b>B</b>}
              // suffix={
              //   <Radio value={"B"} >Correct answer </Radio>
              // }
              onChange={(e) => {
                onOptionChange(e, "B");
              }}
              required={true}
            />
                
          </Col>
          <Col span={5} offset={1}><Radio value={"B"} >Correct answer </Radio></Col>
        </Row>
        <Row style={{ marginTop: "20px",width:"100%" }}>
          <Col span={2}>
          <Button size="large" shape="circle" style={{fontWeight:"600",backgroundColor:answer==="C"?"skyblue":"white"}}>
            C
          </Button></Col>
          <Col span={16}>
            <TextArea
             showCount 
             maxLength={500}
              value={options.C}
              size={"large"}
              style={{ width: "100%", height: "100%", marginLeft: '20px', }}
              placeholder="Add option 3"
              // prefix={<b>C</b>}
              // suffix={
              //   <Radio value={"C"}  >Correct answer </Radio>
              // }
              onChange={(e) => {
                onOptionChange(e, "C");
              }}
              required={true}
            />
            
          </Col>
          <Col span={5} offset={1}><Radio value={"C"}  >Correct answer </Radio></Col>
        </Row>
        <Row style={{ marginTop: "20px",width:"100%" }}>
        <Col span={2}>
          <Button size="large" shape="circle" style={{fontWeight:"600",backgroundColor:answer==="D"?"skyblue":"white"}}>
            D
          </Button></Col>
          <Col span={16} >
            <TextArea
            showCount 
            maxLength={500}
              size={"large"}
              style={{ width: "100%", marginLeft: '20px', height: "100%" }}
              placeholder="Add option 4"
              // prefix={<b>D</b>}
              // suffix={
              //   <Radio value={"D"} >Correct answer </Radio>
              // }
              onChange={(e) => {
                onOptionChange(e, "D");
              }}
              value={options.D}
              required={true}
            />
             
          </Col>
          <Col span={5} offset={1}><Radio value={"D"} >Correct answer </Radio></Col>
        </Row>
        </Radio.Group>
        </div>
      </div>
    </>
  );
  
  const logoutHAndler = () => {
    dispatch(authActions.removeUser());
  };
  return (
    <>
      {spin ? (
        <LoadingOutlined className="loader" style={{ fontSize: 40 }} />
      ) : (
        <div
         className={classes.bodyscr}
        >
          <Form
            name="basic"
            form={form}
            onFinish={onFinish}
            layout={"vertical"}
            style={{height:"99vh"}}
          >
            <Row
              style={{
                backgroundColor: "#e6f2ff",
                display:"flex",
                alignItems:"center",
                height:"10%",
                justifyContent:"space-between",  
                padding:"0 2% 0 2%"                        
              }}
            >
              
                
                  <div  style={{display:"flex",flexDirection:"row"}}>
                                <ArrowLeftOutlined
                                style={{fontSize:"1rem",display:"flex",alignContent:"center",paddingTop:"0.7rem",fontWeight:"800"}}
                                  onClick={() => {
                                    history.goBack();
                                  }}
                                />
                                <Title style={{  color:"#191919", fontSize:"1.5rem",fontWeight:"bold",marginLeft:"1%"}} level={3}>
                                             <Typography.Text style={{width:"20rem"}} ellipsis={{tooltip:true,color:"#ffffff"}}>  {title}</Typography.Text>                               
                                </Title>
                  </div>

                  {/* <img src={Edit} style={{ marginLeft: '10px' }} /> */}
                          <div >
                          <Button type="primary" style={{float:"right", backgroundColor: "#0086FF",
                            height:"2.3rem",
                            borderRadius:"0.1rem",
                            border:"1px solid #0086FF",
                            color:"#ffffff",
                            fontSize: "1rem",                    
                            fontWeight:700, 
                            width:"8rem",
                            marginRight:"20px"
                            }}
                            onClick={()=>{
                              // if(secRemngMark === 0){      
                              //   onFinish()
                              // }else{
                              WarningShowModal()
                            // }
                            }}
                            >SAVE</Button>                         
                          </div>
            </Row>

            <Row   style={{height:"92%",width:"100%"}}>
              <Col span={16}  >
                <div
                  bordered={true}
                  style={{ height: "100%",background:"#F8F8F8",}}
                >
                  <div>
                  <Row style={{backgroundColor:"#FFFFFF",width:"100%",height:"125px"}}>
                    <div className={classes.duration}>
                            <Card.Grid style={gridStyle}>
                                <span >Questions</span><br></br>
                                <p ><img src={Question} style={{color:"#4DAAFF",fontSize:"27px",marginRight:"2px" }}></img>{details?.totalNumberOfQuestion}</p>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}>
                                <span>Duration</span><br></br>
                                <p ><img src={Duration} style={{color:"#4DAAFF",fontSize:"27px",marginRight:"2px"}}></img>{duration}</p>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}>
                                <span >Marks</span><br></br>
                                <p ><img src={Marks} style={{color:"#4DAAFF",fontSize:"25px"}}></img>{totalMarks}</p>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}>
                                <span >Cut-Off</span><br></br>
                                <p ><img src={Cutoff} style={{color:"#4DAAFF",fontSize:"25px",marginRight:"2px"}}></img>{testCutt_off}</p>
                            </Card.Grid>    
                    </div>
                  </Row>
                  </div>
                 <div  className={classes.sectionDetails}>
                  {sectionDetails?.sections?.map((element,index) =>{
                    let value=element.id     
                    return(
                      <>
                      <Button onClick={() =>{setSectionId(element.id)
                      setIsClick(isClick.map((item2,index2)=>{
                        if(index2===index)
                        {
                          return true;
                        }
                        return false;
                      }))
                      setBtnSecName(element.sectionTitle)
                      setSecTotal(element.sectionMaxMarks)
                      setCuttOff(element.sectionCutt_off)         
                      setSecValMarks(element.addedMarks)
                      onNew()
                     let sectionQues= sectionDetails.sections.find(elements =>elements.id===value)
                    setQuestionsList(sectionQues.question)}
                   } 
                   className={isClick[index]?classes.onClick:""}
                   >{element.sectionTitle}</Button></>
                    )
                  })}
                 </div>
                 <div >
                 
                 <Card style={{boxShadow:"3px 3px 5px 3px #B4B0B0",margin:"20px"}}>
                 {/* {Buttons.length === 0 ? 
                 <><div style={{height:"600px"}}>
                    <img style={{margin:"150px"}} src={Frames}></img>
                    <h2 style={{marginTop:"-135px",fontWeight:"bold",marginLeft:"20px"}}>Objective type<br></br> questions</h2>
                    </div>
                 </>  :
                 <> */}
                  <Row style={{ marginBottom: "0.7rem",display:"flex", flexDirection:"column" }}  >
                    <Row >
                  <h3 style={{color:"#6F6F6F",marginLeft:"20px"}}>MARKS</h3>
                  </Row>
                  <Row style={{display:"flex",alignItems:"center",gap:"1.3rem"}}>
                          <Col lg={{ span: 3 }} className={classes.buttonDiv}  >
                           
                            <div
                              className={classes.button1}
                            >
                              <Button type="text" size="large" onClick={onIncrease}>
                                +
                              </Button>
                              {counter}
                              <Button type="text" size="large" onClick={onDecrease}>
                                -
                              </Button>
                            </div>
                          </Col>
                    <Col lg={{ span:3 }}  className={classes.buttonDiv}>
                      {/* <h3>Negative marks </h3> */}
                      <div
                      className={classes.button2}
                      >
                        <Button type="text" size="large" onClick={onIncrease2}>
                          +
                        </Button>
                        {counter2}
                        <Button type="text" size="large" onClick={onDecrease2}>
                          -
                        </Button>
                      </div>
                    </Col>
                    </Row>
                  </Row>
                  {/* <Row> */}
                      {/* <div  style={{marginTop:"-20%",marginRight:"-100%"}} > */}
                        {questionId!==null ? <>
                        <Col lg={{ span: 6, offset: 17}} style={{display:'flex',flexDirection:'row',gap:'0.3rem',alignItems:"flex-start",justifyContent:"right",height:"2rem"}}>
                        <div style={{}}>
                        {show === true ?
                          <Button
                          style={{borderColor: "#FF6B6B",backgroundColor:"#FFE7EB",color: "black",height:"2rem",width:"6.5rem",borderRadius:"6px",marginLeft:"70px"}} 
                          onClick={DeleteShowModal}>
                          <DeleteOutlined style={{fontSize:"large",color: "#FF6B6B"}}/>Delete</Button>
                          :""}
                          </div>
                        <MoreOutlined style={{fontSize:"22px",}} onClick={()=>{
                         setShow(prev=>!prev)
                        }}/></Col></>
                        :""}
                       
                      {/* </div> */}
                    {/* </Row> */}

                  <Row >
                  <h4 style={{color:"#6F6F6F",marginLeft:"10px"}} required={true}>QUESTION {quenum}</h4>
                  </Row>

                  <div
                    style={{
                      margin:"10px auto 10px",
                      // marginTop:"1rem",
                      cursor: "pointer",
                      minHeight: "80px",
                      border: "1px solid",
                      // marginLeft:"-10px",
                      paddingLeft:10,
                      width:"95%"
                    }}
                    onClick={showModal}
                    className="displayquestion"
                  >
                    {" "}
                    {ReactHtmlParser(finalInput)}
                  </div>
                  <Modal
                    title={<p style={{ textAlign: "center" }}>Text Editor</p>}
                    width={900}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <Editor value={input} onChange={textInput} />
                  </Modal>
                  <div style={{
                      margin:"10px auto 10px",
                      width:"100%"
                    }}>
                  {mcq} 
                  </div>
                  {/* <h5>Correct Answer</h5>
                  <Select
                    placeholder="correct Option"
                    value={answer}
                    onChange={onChange}
                    size="large"
                    style={{ width: "50%" }}
                    allowClear
                  >
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                    <Option value="C">C</Option>  
                    <Option value="D">D</Option>
                  </Select> */}
                  <Col lg={{ span: 5, offset: 17}}>
                      <div  style={{marginTop:"10%",marginRight:"-65px",marginBottom:"-20px"}} >
                      <Form.Item >
                    <Button
                          type="primary"
                          htmlType="submit"
                          loading={btnLoader}
                          className={classes.saveButton}
                        >
                          SAVE & NEXT
                        </Button>
                      </Form.Item>
                      </div>
                    </Col>
                    {/* </>} */}
                    </Card>
                    
                  </div>
                </div>  
              </Col >
              
              <Col span={8}>
                <Card
                 
                  bordered={false}
                  style={{ height: "100%", marginLeft: "-3px",}}
                >
                  <div
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    <b> {btnSideSecName}</b>
                    <Divider type="vertical" />
                    <b> {secvalMarks} / {secTotal} MARKS</b>
                    <Divider type="vertical" />
                    <b> {seccuttoff} CUT-OFF</b>
                  </div>
                  <Divider />
                  <Title level={5} style={{color:"#6F6F6F",fontWeight:"bold",marginBottom:"2rem"}}>ALL QUESTIONS</Title>
                  <Row>
                    {Buttons}
                    <Button size="large" shape="circle" onClick={()=>{
                        onNew()
                        setShow(false)}} style={{border:"2px solid #AEAEAE",width:"3rem",height:"3rem",color:"#AEAEAE",fontSize:"1.1rem", fontWeight:"bold",display:"flex",justifyContent:"center",alignItems:'center'}}>
                      +
                    </Button>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
          
        </div>
      )}
      ,
      <Modal visible={warModal} footer={false} onCancel={cancelHandler}  style={{
          borderRadius: "10px",
          textAlign: "center",
          marginTop:"95PX"
        }}>
                <Row justify="center" style={{marginTop:"15px"}}>
                  <h2 style={{color:"red"}} ><b>Warning !!!</b></h2></Row>
                  {secRemngMark !== 0 ? <Row justify="center"><p style={{fontSize:"1.1rem",marginTop:"10px"}}> Your Total Marks is {totMark} and Section Remaining Marks is {secRemngMark}</p></Row>:""}
                   <Row justify="center"><p style={{fontSize:"1rem"}}> Are you sure you want to save?</p></Row>
                <Row justify="center"><Space style={{marginBottom:"20px",marginTop:"30px"}}><Button style={{ borderColor: "#4DAAFF", color: "#4DAAFF" }} 
                onClick={() => { cancelHandler() }}>Cancel</Button>

                <Button loading={btnLoader} style={{ backgroundColor: "#4daaff",width:"6rem", borderColor: "#4daaff", color: "white",marginLeft:"15px" }} 
                  onClick={() => {
                    history.goBack();
                  }}>Yes</Button></Space></Row>
              </Modal>
      
              <Modal visible={showDelModal} footer={false} onCancel={delCancelHandler}  style={{
          borderRadius: "1px",
          textAlign: "center",
          marginTop:"95PX"
        }}
         bodyStyle={{height:"220px"}}>
                <Row justify="center" style={{marginTop:"15px"}}>
                  <h2 ><b>Delete?</b></h2></Row>
                <Row justify="center"><p style={{fontSize:"1rem"}}> Are you sure you want to delete?</p></Row>
                
                <Row justify="center"><Space style={{marginBottom:"10px",marginTop:"30px",}}>
                
                <Button style={{ borderColor: "#4DAAFF", width:"6rem",color: "#4DAAFF" }} 
                onClick={() => { delCancelHandler() }}>No</Button>

                <Button loading={btnLoader} 
                style={{ backgroundColor: "#FF414D",width:"6rem", borderColor: "#FF414D", color: "white",marginLeft:"15px" }} 
                  onClick={() => {
                      setQuesDeleteLoader(true)
                      Api.delete(`/hiring-tests/deleteQuestion?questionId=${questionId}`)
                      .send(response =>{
                        setSecRemngMark(response?.details?.totalSecRemMarks)
                        setQuesDeleteLoader(false)
                        Api.get(`/hiring-tests/getQuestionsByTestId?testId=${testId}`)
                        .params()
                        .send((response) => {
                          setSpin(false);
                          setSecRemngMark(response?.details?.totalSecRemMarks)
                          setTotMark(response?.details?.totalMarks)
                          setSectionId(response?.testExist?.sections[0]?.id)
                          setSecValMarks(response?.testExist?.sections[0]?.addedMarks)
                          setQuestionsList(response?.testExist?.sections[0]?.question)
                          setBtnSecName(response?.testExist?.sections[0]?.sectionTitle)
                          setSecTotal(response?.testExist?.sections[0]?.sectionMaxMarks)
                          setCuttOff(response?.testExist?.sections[0]?.sectionCutt_off)
                          dispatch(
                            testActions.updateQuestionDetail( response?.testExist )
                          );
                          dispatch(testActions.updateDetails({ details: response?.details }));
                          
                          let data= response?.testExist?.sections.find((element => element.id===sectionId))
                          setQuestionsList(data?.question)
                        });
                          onNew()
                          setShowDelModal(false)
                      })
                  }}>Yes</Button></Space></Row>
              </Modal>
    </>
  );
};
export default Test;