import React,{useState,useEffect} from "react";
import { Form, Input, Button,  Typography, Card, Row, Col, Modal } from "antd";
import Designbackground from "./design-background";
import { Api } from "services";
import brigoshaIcon from "../../assets/brigosha_logo.svg";
import { useHistory } from "react-router-dom";
const ForgotPassword =() =>{
 const [input,setInput]=useState("")
 const history =useHistory()
 let value=window.innerWidth
 
    return (
        <div style={{backgroundColor:"white",width:"100%",height:"100%"}}>
             <Row style={{height:"100vh"}}>
      {value>728?<Col span={12}>
      <Designbackground value="Forgot"/></Col>:""}
      <Col span={value>728?10:20}>
      <img src={brigoshaIcon} alt="brigosha" />
        <Card
        bordered={true}
        
        style={{
          width:value>728?"65%":"100%",
          marginTop:"20%",
          marginLeft:value>728?"15%":"5%",
          justifyContent: "center",
          borderRadius: "12px",
          boxShadow: "5px 12px 50px 5px rgba(208, 216, 243, 0.6)"
        }}
      >
        <Row justify={"center"}>
        <h3 style={{fontWeight:"700",marginRight:"5px",padding:"0",fontSize:"1.2rem",}}>Forgot</h3>
        <span style={{fontSize:"1.2rem",fontWeight:"700", color: "#0086FF"}}>{" "}Password </span>
        </Row>
        <h4 style={{textAlign:"center",fontSize:"14px",fontWeight:"600",color:"#636363",marginTop:"10px"}}> Please Enter your registered email ID.</h4>
        <Row style={{marginTop:"40px"}}>
            EMAIL
        </Row>
       <Row>
        <Input type={"email"} onChange={(e) =>{
            let value=e.target.value
            setInput(value)
        }}/></Row>
        <Row style={{marginTop:"50px"}}>
        <Button type="primary" style={{width:"100%",marginBottom:"30px"}} onClick={() =>{
     
                  
                  Api.post("/hiring-auth/forgotPassword")
                    .params({ email: input })
                    .send((response, error) => {
                    
                      if (response !== undefined && response.show.type === "success") {
                        history.push("/login");
                      }
                    });
                
              
        }}>SUBMIT</Button></Row>
      </Card>
      </Col></Row>
      </div>
    )


}
export default ForgotPassword