import classes from './recuiterTabModal.module.css';
import { Button,Modal,Table, Typography} from 'antd';
import { useEffect, useState } from 'react';
import moment from "moment";
import { Api } from 'services';
const RecuiterTabModal=(props)=>{
    const modalData=props?.modalData;
    const modalOpen=props?.modalOpen;
    const setModalOpen=props?.setModalOpen;
    const canType=props?.canType;
    const selectedRecruiterId=props?.selectedRecruiterId;
    const jobId=props?.jobId;
    const userId=props?.userId;
    const startDate=props?.startDate;
    const endDate=props?.endDate;
    const [tableData,setTableData]=useState([]);
    const [tableLoader,setTableLodaer]=useState(false);
    const [pageChange, setPageChange] = useState(1);
    const [pageSize,setPageSize]=useState(100);
    const Columns=[
        {
            title:<span style={{fontSize: "14px",fontWeight: "700", color:"#000000"}}>CANDIDATE NAME</span>,
            dataIndex:"name",
            key:"name",
            width:"9.8rem",
            render:(text,record)=>{
              return(
                <div>
                  <Typography.Text ellipsis={{tooltip:true}} style={{width:"7.7rem"}}>{record?.name?record?.name:"NA"}</Typography.Text>
                </div>           
              )
          }
        },
        {
            title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>EMAIL</span>,
            dataIndex:"email",
            key:"email",
            render:(text,record)=>{
              return(
                  <div>
                      <Typography.Text ellipsis={{tooltip:true}} style={{width:"8rem"}}>{record?.email?record?.email:"NA"}</Typography.Text>
                  </div>
              )
          }
        },
        {
            title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>JOB PROFILE</span>,
            dataIndex:"job",
            key:"job",
            render:(text,record)=>{
              return(
                <div>
                  <Typography.Text ellipsis={{tooltip:true}} style={{width:"8rem"}}>{record?.job ? record?.job : "NA"}</Typography.Text>
                </div>
              )
            }
        },
         modalData?.title === "Candidate InProgress" ? 
        {
          title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>CURRENT ROUND</span>,
          dataIndex:"currentRound",
          key:"currentRound",
        }
        :
        {
          title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>EXPERIENCE</span>,
          dataIndex:"experience",
          key:"experience",
          render:(text,record)=>{
            return(
              <div><span style={{padding:"5px"}}>{record?.experienceYears} Y</span> <span>{record?.experienceMonths} M</span></div>
            )
          }
        },
        {
          title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>HIRING STATUS</span>,
          dataIndex:"hiringStatus",
          key:"hiringStatus",
        },
      modalData?.title === "Offer Released" ?
      {
        title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>EXPECTED DOJ</span>,
        dataIndex:"expectedDoj",
        key:"expectedDoj",
      }
      :
      {
        title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>ADDED BY</span>,
        dataIndex:"addedBy",
        key:"addedBy",
     },  
     
    ]

    if(modalData?.title === "Shared"){
      Columns.push({
        title:<span style={{fontSize: "14px",fontWeight: "700",color:"#000000"}}>SHARED DATE</span>,
        dataIndex:"date",
        key:"date",
        render:(text,record)=>{
          const dates=moment(record?.date).format('DD-MM-YYYY')
          return(
            <div>{dates ? dates :"NA"}</div>
            )
        }
      }, )
    }

    useEffect(()=>{
        setTableLodaer(true);
        Api.get('/hiring-overview/recruitersDashboard/')
        .params({
         dataKey:modalData?.dataKey,
         candidateType:canType,
         agencyId:selectedRecruiterId,
         jobId:jobId&&jobId[0]!=='All'?jobId:"",
         startDate:startDate,
         endDate:endDate,
         userId:userId,
         page:pageChange,
         limit:pageSize,
        })
       .send((res) => {
        if(res)
        {
            setTableData(res);
            setTableLodaer(false);
        }
       })
    },[props,pageChange,pageSize])

    const handlePageChange = (page, pageSize) => {
      setPageChange(page);
      setPageSize(pageSize);
    };

    return (
        <div>
                    <Modal 
                    open={modalOpen} 
                    onCancel={()=>{setModalOpen(false)}} 
                    width="62rem"
                    bodyStyle={{height: 500}}
                    centered 
                    className={classes.modalDesign}
                    header={null} 
                    footer={null}  
                    closable={false}              
                >
                        <div className={classes.modalView}>
                                <div>
                                    <h2>Interview {modalData?.title}</h2>
                                    <Button disabled={tableData?.length === 0 ? true : false} type="primary" onClick={()=>{
                                        Api.get('/hiring-overview/recruitersDashboard/')
                                        .params({
                                            dataKey:modalData?.dataKey,
                                            download:true,
                                            candidateType:canType,
                                            agencyId:selectedRecruiterId,
                                            jobId:jobId&&jobId[0]!=='All'?jobId:"",
                                            startDate:startDate,
                                            endDate:endDate,
                                            userId:userId,
                                        })
                                        .send((res) => {
                                            if (typeof res != "undefined") {                                               
                                            window.open(res, "_blank")?.focus();
                                            }
                                        })
                                    }}>Export to Excel Sheet</Button>
                            </div>
                            <Table style={{marginBottom:"1rem"}}
                              loading={tableLoader}
                              columns={Columns} 
                              scroll={{y:270,x:1100}}
                              dataSource={tableData}
                              pagination={{
                                size:"small",
                                pageSize: pageSize, 
                                pageSizeOptions:[20,30,50,100,150],
                                total:props?.modalData?.data,
                                showSizeChanger:true,
                                onShowSizeChange: handlePageChange,
                                onChange:handlePageChange,
                            }}                               
                           />
                        </div>         
                    </Modal>
        </div>
    )
}
export  default RecuiterTabModal;