import { UploadOutlined,CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload, Select, Checkbox, Modal,Row } from "antd";
import classes from "./expRegistration.module.css";
import cameraIcon from "../../assets/cameraIcon.svg";
import profileIcon from "../../assets/profileIcon.svg.svg";
import brigoshaLogo from "../../assets/brigoshaLogo.svg";
import resIcon from "../../assets/resIcon.svg";
import regisSideImg from "../../assets/regisImg2.jpg";
import { useEffect, useState } from "react";
import { candidateActions } from "pages/dashboard-candidate/store";
import { useDispatch, useSelector } from "react-redux";
import OTPModal from "pages/dashboard-candidate/OTPModal";
import { useHistory } from "react-router-dom";
import { Api } from "services";
import { canRegistrationAction } from "./store";
const { Option } = Select;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
          return {
                  width,
                  height
          };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

const ExpRegistration = () => {
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [resumeUrl, setResumeUrl] = useState("");
  const [resumeFile, setResumeFile] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [jobLocn, setJobLocn] = useState();
  const [mobile, setMobile] = useState("");
  const dispatch = useDispatch();
  const [loader, setLoader]=useState(false);
  const { height, width } = useWindowDimensions(); 
 // const [width2, setWidth]   = useState(window.innerWidth);
  const modalWidth=width>700?"45%":"100%";
  let params = new URL(location.href);
  let id = params.pathname.split("/");
  const { modalOpen } = useSelector((state) => state.userCandidate);
  const { verifyOTP } = useSelector((state) => state.userCandidate);
  const history = useHistory();
  const { experienceDetails } = useSelector((state) => state.canRegistration);
  useEffect(() => {
    Api.get(`/hiring-publicapi/exp-candidate-forreg/${id[3]}`).send((response) => {
      dispatch(canRegistrationAction.updateExperienceDetails(response));
    });
  }, []);
  setTimeout(() =>{
  },5000)
  const resumeBeforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Image must be smaller than 10MB!");
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setResumeUrl(url);
        setResumeFile(file);
      });
    }
  };

  const imgbeforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Image must be smaller than 10MB!");
    } else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
        setImageFile(file);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  return (
    <>
      <header className={classes.mainHeader}>
        <img src={brigoshaLogo}></img>
      </header>
      <div className={classes.wrapper}>
        {width>700&&<div>
          <img src={regisSideImg}></img>
        </div>}
        <div className={classes.container}>
          <div>
            <h1>Candidate Details</h1>
            <span>
              Kindly attach the file and fill in the informations below
            </span>
          </div>
          <div>
            <div className={classes.uploadPhoto}>
              <Upload
                showUploadList={false}
                beforeUpload={imgbeforeUpload}
                accept=".png, .jpg, .jpeg,"
                maxCount={1}
              >
                <div>
                  <div className={classes.profileImg}>
                    {imageUrl && (
                      <img src={imageUrl}></img>
                    )}
                    {imageUrl == "" && <img src={profileIcon}></img>}
                  </div>
                  <Button>
                    <img src={cameraIcon}></img>
                  </Button>
                  <span>Upload Photo { " "}<b style={{color:"red",fontSize:"12px"}}>*</b></span>
                </div>
              </Upload>
            </div>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "600",
                marginBottom: "0.5rem",
              }}
            >
              Upload Resume <span style={{ color: "red" }}>*</span>
            </span>
            <div className={classes.attachResume} style={{height:"9rem",backgroundColor:"#EAF5FF",overflow:"auto",padding:"0 0.5rem"}}>
              {/* <div id="img">{!resumeUrl && <img src={resIcon}></img>}</div> */}
              <Upload.Dragger
                name="file"
               showUploadList={resumeUrl?true:false}
                beforeUpload={resumeBeforeUpload}
                accept=".png, .jpg, .jpeg, .pdf, .docs"
                maxCount={1}
                // listType="picture"
                progress={{
                  strokeColor: {
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  },
                  strokeWidth: 3,
                  format: (percent) =>
                    percent && `${parseFloat(percent.toFixed(2))}%`,
                }}
                onRemove={() => setResumeUrl("")}
                style={{
                  backgroundColor: "#EAF5FF",
                  border: "0",
                  height: "5rem",
                }}
              >
                {/* <div className={classes.drag}>
                  <span>
                    Drag & Drop It Here
                    <br />
                    OR
                  </span>
                  <Button type="primary">Browse File</Button>
                </div> */}
                  {!resumeUrl&&<div className={classes.drag}>
                                                                                     <span>Drag & Drop It Here<br/>OR</span>                                      
                                                                                    <Button type='primary'>Browse File</Button>
                                                                        </div>   }   
              </Upload.Dragger>
            </div>
          </div>
          <h3>Basic Information</h3>
          <Form
            layout="vertical"
            onFinish={(values) => {
              // setLoader(true);
              values = { ...values,email:experienceDetails.candidate.email,userId:experienceDetails?.candidate?.userId,registerId:parseInt(id[3]) ,photo: imageFile, resume: resumeFile,phone:values.phone.slice(-10) };
              Api.post("/hiring-publicapi/registerExpCandidate")
              .upload(values,(percentCompleted,response) =>{
                if(response.data.show.type==='success'){
                  setLoader(false);
                history.push("/thanks");
                return;
                }
              //  setLoader(false);
              })                      
            }}
          >
            <Form.Item
              name="jobId"
              label={<span>Job Title </span>}
              rules={[{ required: true, message: "Select job Title!" }]}
            >
              <Select
                onChange={(value) => {
                 let values= experienceDetails?.jobs?.find((element) => {
                    if (element.id === value) {
                      return element
                      
                    }
                  })
                  setJobLocn(values.locations)
                   
                  
                  setTimeout(() => {}, 2000);
                }}
                placeholder="Please select"
                bordered={false}
              >
                {experienceDetails?.jobs?.map((element) => {
                  return <Option value={element.id}>{element.title}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="jobLocation"
              label={<span>Job Location</span>}
            >            
              <Input value={
                jobLocn?.map(element =>element.name).join(",")
                } placeholder="Enter location" />
            </Form.Item>

            <Form.Item
              name="name"
              label={<span>Candidate name</span>}
              rules={[{ required: true, message: "Enter valid data!" }]}
            >
              <Input placeholder="Enter name" />
            </Form.Item>

            <Form.Item
              name="referredBy"
              label={<span>Referred By (optional) </span>}
            >
              <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item
              name="currentLocation"
              label={<span>Current Location </span>}
              rules={[{ required: true, message: "Enter valid data!" }]}
            >
              <Input placeholder="Enter current location" />
            </Form.Item>

            {/* <Form.Item
              name="emailId"
              label={<span>Email id</span>}
             initialValue={experienceDetails?.candidate?.email}
            > */}
              <Row style={{fontWeight:"600"}}>Email id</Row>
              <Input disabled={true} value={experienceDetails?.candidate?.email}  />
            {/* </Form.Item> */}

            <Form.Item
              name="phone"
              label={<span>Phone number</span>}
              rules={[{ required: true, message: "Enter valid data!" }]}
            >
              <div>
                <Input
                  min={0}
                  type="number"
                  placeholder="Enter phone number"
                  onChange={(event) => setMobile(event.target.value)}
                />
                {!verifyOTP && (
                  <Button
                  size="small"
                  style={{marginTop:"0.5rem"}}
                    disabled={mobile.trim().slice(-10).length !== 10}
                    type="primary"
                    onClick={() => {
                      dispatch(candidateActions.updateModalOpen(true));                 
                      Api.post("/hiring-publicapi/registerSendOtp")
                        .params({ registerId: parseInt(id[3]), phoneNumber: mobile?.slice(-10) })
                        .send((response) => {                     
                        });
                    }}
                  >
                    Verify Number
                  </Button>
                )}
                {verifyOTP && (
                  <div className={classes.verified}>
                    <CheckCircleOutlined></CheckCircleOutlined>
                    <span>Verified</span>
                  </div>
                )}
              </div>
            </Form.Item>
            <span>
              {/* (<b>Note:</b> Please enter the registered mobile number with the
              company) */}
            </span>

            <h3>Work Information</h3>
                  <div>
                          <span>*</span>
                        <span>Total Experience</span>
                  </div>
           
              <div>
                      <Form.Item
                        name="experienceYears"
                        // label={<span> In Years</span>}
                        rules={[{ required: true, message: "Enter valid data!" }]}
                      >
                        <Input placeholder="In years" style={{width:"100%"}} type="number" min={0}/>
                      </Form.Item>
                      <Form.Item
                        name="experienceMonths"
                        // label={<span>In months</span>}
                        rules={[{ required: true, message: "Enter valid data!" }]}
                      >
                        <Input placeholder="In month" style={{width:"100%"}} type="number" min={0}/>
                      </Form.Item>
            </div>

            <Form.Item
              name="relevantExperience"
              label={<span>Relevant Experience (optional)</span>}
            >
              <Input placeholder="Enter in month(s)"  type="number" min={0} />
            </Form.Item>

            <Form.Item
              name="noticePeriod"
              label={<span>Notice period(days)</span>}
              rules={[{ required: true, message: "Enter valid data!" }]}
            >
              <Input type={"number"} placeholder="Enter days" min={0} />
            </Form.Item>

            <Form.Item
              name="currentCompany"
              label={<span>Current Company</span>}
              rules={[{ required: true, message: "Enter valid data!" }]}
            >
              <Input placeholder="Enter company name" />
            </Form.Item>

            <Form.Item
              name="currentCtc"
              label={<span>Current CTC</span>}
              rules={[{ required: true, message: "Enter valid data!" }]}
            >
              <Input placeholder="Enter current CTC"  type="number" min={0}/>
            </Form.Item>

            <Form.Item
              name="expectedCtc"
              label={<span>Expected CTC</span>}
              rules={[{ required: true, message: "Enter valid data!" }]}
            >
              <Input placeholder="Enter expected CTC*" type="number" min={0}/>
            </Form.Item>

            <Form.Item
              name="preferredLocation"
              label={<span>Preferred Location</span>}
              rules={[
                { required: true, message: "Select your prefered location!" },
              ]}
            >
               <Select placeholder="Please select" bordered={false}>
              {experienceDetails?.locations?.map((element) =>{
                return    <Option value={element.id}>{element.name}</Option>
              })
              }
              </Select>
            </Form.Item>

            <div className={classes.submitBtn}>
              <Button htmlType="submit" disabled={!resumeFile || !imageFile || resumeUrl === ""}>
                SUBMIT
              </Button>
            </div>
          </Form>

          <Modal
            open={modalOpen && !verifyOTP}
            footer={null}
            width={modalWidth}
            centered
            closable={true}
            maskClosable={true}
            onCancel={() => dispatch(candidateActions.updateModalOpen(false))}
          >
            <OTPModal
            registerId={id[3]}
             // candidateId={id[3]}
              phone={mobile}
              calledFrom={"EXP_REGISTRATION"}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};
export default ExpRegistration;
