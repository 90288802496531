

export default class Constants{

    constructor(){
        this.sortingFields = [
        {"key": "10th", label : "10th Marks" , selected : false, sortKey : "xOrder"},
        {"key": "12th", label : "12th Marks" , selected : false ,sortKey : "xiiOrder"},
        {"key": "cgpa", label : "College CGPA", selected : false , sortKey : "cgpaOrder"},
        {"key": "maxBacklogs", label : "Max Backlogs", selected : false ,sortKey : "backlogsOrder"},
        {"key": "score", label : "Hackathon Score", selected : false ,sortKey : "scoreOrder"}
        ]

        this.filterFields = [
            {title : "Job Vacancy", value : "jobtitle"},
            {title : "Institute", value : "institute"},
            {title : "10th Marks", value : "x"},
            {title : "12th Marks", value : "xii"},
            {title : "Hackathon score", value : "score"},
            {title : "Maxbacklogs", value : "backlogs"},
            {title : "CGPA" , value : "cgpa"},
            {title : "Hackathon Status",value:"hackathonStatus" },
            {title : "Overall Status",value:"overallStatus" },
            {title : "Eligibility Status",value:"eligibilty" },
          ]
          

        this.filterStatus = {
            "x" : false,
            "xii" : false,
            "cgpa" : false,
            "backlogs" : false,
            "eligibility" : false,
            "mainStatus" : false,
            "hackathonStatus" : false,
            "jobtitle" : false,
            "institute" : false,
            "score" : false
          }

          this.sortStatus = {
            "x" : {selected : false, order : true},
            "xii" : {selected : false, order : true},
            "cgpa" : {selected : false, order : true},
            "backlogs" : {selected : false, order : true},
            "score" : {selected : false, order : true}
          }
    }
}
