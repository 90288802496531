import {
  Col,
  DatePicker,
  Dropdown,
  Row,
  Button,
  Table,
  Menu,
  Typography,
} from "antd";
import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import moment from "moment";
import { inputFieldType } from "./redux/action";
import { withRouter } from "react-router";
import { CalendarOutlined, ArrowRightOutlined,CloseOutlined,ArrowLeftOutlined,DownloadOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';
import Download from '../../assets/download.png';
import { Api } from "services";
const { RangePicker } = DatePicker;
const { Title} = Typography;
var showSummaryDetailsTable = false;

export const dateFormat = "YYYY-MM-DD";

export const rangePickerMenuInAgencySummary = ({
  updateInputField,
  candidateDateDropdown,
  candidateDateRangeVal,
  fetchSummary,
  getDownload,
  getAgencyDownload,
}) => (
  <div style={{marginTop:"0.5rem",marginBottom:"0.5rem"}}>
      <RangePicker
        format={dateFormat}
        onChange={(date, dateString) => {
          const updatedDate = date ? dateString : null;
          updateInputField(inputFieldType.CANDIDATE_DATE_RANGEVAL, updatedDate);
          // updateInputField(
          //   inputFieldType.CANDIDATE_DATE_DROPDOWN,
            // !candidateDateDropdown
          // );
          fetchSummary();
        }}
        value={
          candidateDateRangeVal
            ? [
                moment(candidateDateRangeVal[0], dateFormat),
                moment(candidateDateRangeVal[1], dateFormat),
              ]
            : []
        }/>
      </div>
);


class AgencySummary extends Component {

  constructor() {
    super();
    this.state = {closeBtn:true};
  }

  componentDidMount() {
    this.props.updateFilterParams(moment(), "date");
    this.props.updateFilterParams(null, "agencyId");
    this.props.fetchSummary();
  }


  onDateChange(val) {
    this.props.updateFilterParams(val, "date");
  }

  handleAgencySelect(date, agencyId) {
    this.props.updateFilterParams(agencyId, "agencyId");
    this.props.fetchSummaryDetails();
  }

  handleDateChange(value) {
    this.props.updateInputField(inputFieldType.CANDIDATE_DATE_DROPDOWN, value);
  }

  render() {
    const {
      summaryLoader,
      filterParams,
      summaryData,
      totalProfiles,
      totalNotShortlisted,
      totalInProgress,
      totalPending,
      totalDuplicate,
      totalRejected,
      candidateDateDropdown,
      candidateDateRangeVal,
      selectedAgencyName,
      selectedAgencyNameTotalCount,
      profiles,
      history,
    } = this.props;
    let agencyIdVar = "";

    const columns = [
      !showSummaryDetailsTable
        ? {
            title: "Agency Name",
            dataIndex: "agencyName",
            key: "agencyName",
            render: (record) => (
              <div>
                <a
                  style={{ fontWeight: "500" }}
                  onClick={() => {
                    showSummaryDetailsTable = true;

                    let data = summaryData?.map((i, item) => {
                      if (i.agencyName === record) {
                        agencyIdVar = i.agencyId;
                      }
                    });

                    this.handleAgencySelect(candidateDateRangeVal, agencyIdVar);
                  }}
                >
                  {record}
                </a>
              </div>
            ),
          }
        : {
            title: "Job Title",
            dataIndex: "jobTitle",
            key: "jobTitle",
          },
      {
        title: "Profiles Shared",
        dataIndex: "profilesShared",
        key: "profilesShared",
      },
      {
        title: "In Progress",
        dataIndex: "inProgress",
        key: "inProgress",
      },
      {
        title: "Action Pending",
        dataIndex: "actionPending",
        key: "actionPending",
      },
      {
        title: "Not Shortlisted",
        dataIndex: "notShortlisted",
        key: "notShortlisted",
      },
      {
        title: "Not Selected",
        dataIndex: "notSelected",
        key: "notSelected",
      },
    ];

    if (!showSummaryDetailsTable) {
      columns.push({
        title: "Duplicate Profiles",
        dataIndex: "duplicateProfiles",
        key: "duplicateProfiles",
      });
    }

    return (
      <div style={{padding:"0.9rem 1.5rem 1.5rem 1.5rem"}}>
        <Row>
          <Title style={{ fontSize: "1.5rem",fontWeight: "bold",}}>Agency Summary</Title>
        </Row>
        <Row gutter={24}>
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginLeft:"0.7rem",width:"98%"}}>
          {!showSummaryDetailsTable ? (
            <>
            <div style={{display:"flex",flexDirection:"row"}}>
            <div>
              <div className="dashboard-box" style={{minHeight:"1rem",minWidth:"13rem"}}>
                {/* <div style={{display:"flex",}}> */}
                  {/* <div>Date :</div> */}
                  {/* <div className="m-l-10">
                    {candidateDateRange
                      ? <div>{this.state.closeBtn?<div>{candidateDateRange[0]} to {candidateDateRange[1]} <CloseOutlined  
                          onClick={()=>{this.setState({closeBtn:false})}} style={{fontSize: 13,marginLeft:"0.5rem"}}/></div>:""}</div>
                      : ""}
                  </div> */}
                {/* </div> */}
                <div
                  style={{
                    display: "flex",
                    alignItems:"center",
                    justifyContent:"center",
                  }}
                >

                 {rangePickerMenuInAgencySummary(this.props)}

                  {/* <Dropdown
                    visible={rangePickerMenuInAgencySummary(this.props)}
                    // overlay={}
                    onClick={() =>
                      this.handleDateChange(!candidateDateDropdown)
                    }
                    trigger={["click"]}
                    id="date-dropdown"
                  >
                    <a className="text-sm">
                      <CalendarOutlined id="dateRange-calender" />
                    </a>
                  </Dropdown> */}
                 
                </div>
              </div>
            </div>
            <div style={{margin:"1rem",marginTop:"1.5rem",}}>
              <h4 style={{color:"#B7B7B7"}}>Displaying Last 7 Days Data.</h4>
            </div>
            </div>
            </>
          ) : (
            <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"99%"}}>
              <div className="dashboard-box" style={{minWidth:"16rem",minHeight:"1rem",}}>
                {/* <div style={{display:"flex",flexDirection:"row"}}>
                  <div className="text-bold text-sm">
                    Agency Name
                  </div>
                </div> */}
                <div style={{display:"flex",flexDirection:"row",gap:"4rem",marginTop:"0.3rem",marginBottom:"0.3rem"}}>
                <div>
                  <span>
                  <a
                    className="text-bold text-sm"
                    style={{
                      fontWeight: "500",
                      alignItems:"left",
                    }}
                    onClick={() => {
                      showSummaryDetailsTable = false;
                      this.onDateChange(filterParams.date);
                    }}
                  >
                    <ArrowLeftOutlined /> {"Go Back"}
                  </a>
                  </span>
                </div>
                  <div
                    className="text-bold text-sm m-l-200"
                    style={{
                      alignItems:"right",
                      marginRight:"1rem",
                    }}
                   >
                     Agency Name : <span style={{color: "#0588BC",fontWeight:"bold"}}>{selectedAgencyName}</span>
                  </div>
                </div>
              </div>
              <div className="head-icon">
                    <img src={Download}
                      style={{ width: "2.2rem", height: "2.2rem",marginTop:"-0.5rem"}}  
                      onClick={()=>{
                                
                          this.props.getAgencyDownload();
                      }}
                />
              </div>
            </div>
          )}
          {!showSummaryDetailsTable && (
            <div>
              <Button style={{borderRadius:"5px",marginTop:"1rem",marginRight:"1rem"}}
                type="primary"
                icon={<ArrowRightOutlined />}
                onClick={() => {
                history.push("/job-summary");
                }}
              >
                Job Summary
              </Button>
              <div className="head-icon">
                                      <img src={Download}
                                        style={{ width: "24", height: "2.1rem", marginRight: '1.2rem' }}  
                                        onClick={()=>{
                                
                                            this.props.getDownload();

                                           }}
                                        />
              </div>
              
            </div>
          )}
          {/* <div > */}
          {/* {/* <Button type="primary" style={{borderRadius:"5px",margin:"1.5rem"}}
           ><DownloadOutlined style={{fontSize:"1rem"}}/> Download</Button>
          </div> */}
          </div>
        </Row>

        <Table
          columns={columns.map((col) => ({ ...col, align: "center" }))}
          loading={summaryLoader}
          dataSource={!showSummaryDetailsTable ? summaryData : profiles.data}
          pagination={{
            position: "bottomRight",
            // pageSize: 25,
            showSizeChanger: true,
            pageSizeOptions:[10,25, 30, 50, 100],
            size: "small",
            total:!showSummaryDetailsTable ? 0 : selectedAgencyNameTotalCount,
            showTotal: (total, count) =>
            `${count[0]}-${count[1]} of ${total} items`,
            // onChange: this.handlePageChange,
            // onShowSizeChange: this.handlePageChange,  
          }}
          scroll={{ y: 340 }}
          summary={() => {
            return (
              <>
                {/* <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  {!showSummaryDetailsTable ? (
                    <Table.Summary.Cell></Table.Summary.Cell>
                  ) : (
                    ""
                  )}
                </Table.Summary.Row> */}
                <Table.Summary.Row
                  className="text-black text-bold text-sm"
                  style={{ textAlign: "center", }}
                >
                  <Table.Summary.Cell>
                    <a>Total</a>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {!showSummaryDetailsTable
                      ? totalProfiles
                      : profiles.totalProfiles}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {!showSummaryDetailsTable
                      ? totalInProgress
                      : profiles.totalInProgress}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {!showSummaryDetailsTable
                      ? totalPending
                      : profiles.totalPending}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {!showSummaryDetailsTable
                      ? totalNotShortlisted
                      : profiles.totalNotShortlisted}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {!showSummaryDetailsTable
                      ? totalRejected
                      : profiles.totalRejected}
                  </Table.Summary.Cell>
                  {!showSummaryDetailsTable ? (
                    <Table.Summary.Cell>{totalDuplicate}</Table.Summary.Cell>
                  ) : (
                    ""
                  )}
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgencySummary));
