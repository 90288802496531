/* eslint-disable react/display-name */
import React from "react";
import moment from "moment";
import "services/styles.css";
import "./styles.css";
import { connect } from "react-redux";
import { EditIconButton } from "services/button";
import { Color, EmptyTable, userContent } from "../../services";
import {
  IdcardOutlined,
  FilterOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import CandidateActionsModal from "./hrProcessCandidateActionsModal";
import HrProcessFeedbackViewModal from "./hrProcessViewFeedbackModal";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import {
  Popover,
  Table,
  Input,
  Modal,
  Dropdown,
  Menu,
  Space,
  Timeline,
  Badge,
} from "antd";
import CandidatePreviousOrgsViewModal from "./candidatePreviousOrgsViewModal";
import {
  HR_PROCESS_CANDIDATE_DETAILS,
  inputFieldType,
  operationType,
  dateFormat,
} from "./redux/action";
import {
  CANDIDATE_VIEW_MODAL,
  CANDIDATE_DETAILS,
} from "../candidate/redux/action";
import Select from "rc-select";

const { TextArea } = Input;

export const timelineContent = (hrStatus) => {
  return (
    <div style={{maxHeight:"30vh",overflowY:"auto"}}>
                <Timeline
                  mode="left"
                  className="candidate-timeline"
                  style={{ marginBottom: "-50px",marginLeft:"-5px"}}
                >
                  {hrStatus?.hrStatusHistory
                    .sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1))
                    .map((item) => {
                      return (
                        <>
                          <Timeline.Item
                            position="left"
                            style={{marginLeft:"1rem",}}
                            label={
                              <div className="m-b-10 m-l-20" style={{marginLeft:"1rem",}}>
                                {item !== null ? (
                                  <div
                                    style={{ color: Color.darkGrey, marginBottom: "10px",}}
                                  >
                                    {moment(item.createdAt, dateFormat).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </div>
                                ) : (
                                  "No History!"
                                )}
                                <div style={{ marginBottom: "10px" }}>
                                  {" "}
                                  HR Status -{" "}
                                  <span className="text-black text-bold">
                                    {item.hrStatus}
                                  </span>{" "}
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                  {" "}
                                  Offered CTC -{" "}
                                  <span className="text-black text-bold">
                                    {item.offeredCTC === (null || "")
                                      ? "NA"
                                      : item.offeredCTC}
                                  </span>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                  {" "}
                                  LWD -{" "}
                                  <span className="text-black text-bold">
                                    {moment(item.lwd, dateFormat).format("DD-MM-YYYY") ===
                                    "Invalid date"
                                      ? "NA"
                                      : moment(item.lwd, dateFormat).format("DD-MM-YYYY")}
                                  </span>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                  {" "}
                                  Expected DOJ -{" "}
                                  <span className="text-black text-bold">
                                    {moment(item.expectedDoj, dateFormat).format(
                                      "DD-MM-YYYY"
                                    ) === "Invalid date"
                                      ? "NA"
                                      : moment(item.expectedDoj, dateFormat).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </span>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                  {" "}
                                  HR Notice Period -{" "}
                                  <span className="text-black text-bold">
                                    {item.hrNoticePeriod === null
                                      ? "NA"
                                      : item.hrNoticePeriod}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    marginBottom: "10px",
                                    maxHeight: "12vh",
                                    overflowY: "auto",
                                  }}
                                >
                                  {" "}
                                  HR Comment -{" "}
                                  <span className="text-black text-bold">
                                    {item.hrComment}
                                  </span>{" "}
                                </div>
                              </div>
                            }
                          ></Timeline.Item>
                        </>
                      );
                    })}
                </Timeline>
    </div>
  );
};

const showInterviewFeedback = (props, record) => {
  props.setFeedbackView(true);
  props.setCurrenCandidate(record);
};

const showHrActionEditingView = (props) => {
  props.setHrActionEditingView(true);
};

export const filterHrStatusMenu = ({
  updateInputField,
  history,
  statusDropdownAction,
  candidateFilterStatus,
  candidatesHrStatus,
}) => {
  const iterableStatus = [
    "All",
    "Offered",
    "Not Offered",
    "Offer Accepted",
    "Offer Declined",
    "Joined",
    "Not Joined",
    "Rejected",
    "On Hold",
    "Not Interested",
    "Not Responding",
    "Dead End",
  ];
  return (
    <Menu  selectedKeys={[0]} style={{ height:"200px",overflowY:"scroll", left: "-90%" }}>
      {[...iterableStatus].map((status) => (
        <Menu.Item
          key={status}
          onClick={() => {
            updateInputField(
              inputFieldType.HR_PROCESS_CANDIDATE_FILTER_STATUS,
              status
            );

            statusDropdownAction(history);
          }}
        >
          {" "}
          {status}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export const hiringStatusMenu = ({
  updateInputField,
  history,
  hiringStatusDropdownAction,
  hiringFilterStatus
}) => {
  const arrayStatus = [
    "All",
    "Selected",
    "Not Selected",
    "Hold"
  ];
  return (
    <Menu  selectedKeys={[0]} style={{ height:"9rem",overflowY:"scroll", left: "-90%" }}>
      {[...arrayStatus].map((status) => (
        <Menu.Item
          key={status}
          onClick={() => {
            updateInputField(
              inputFieldType.HR_PROCESS_HIRING_FILTER_STATUS,
              status
            );

            hiringStatusDropdownAction(history);
          }}
        >
          {" "}
          {status}
        </Menu.Item>
      ))}
    </Menu>
  );
};

const actionList = (props, record) => (
  <Menu style={{width:"5rem",margin:"1rem"}}>
    <Menu.Item
      key="1"
      onClick={() =>{
    
        props.handleCandidateAction(record?.id,"Selected");
      }}>
     Selected
    </Menu.Item>
    <Menu.Item
      key="2"
      onClick={() =>{
        props.handleCandidateAction(record?.id,"Rejected");
        // statusConfirmModal(props, record, props.candidateStatus.hold)
      }}>
     Rejected
    </Menu.Item>
  </Menu>
);

const hrProcessCandidateColumns = (props) => [
  {
    title: "S. No.",
    render: (text, record, index) => (
      <div className="text-bold text-black">
        {(props.candidateHistoryPageNumber - 1) * 25 + index + 1}
      </div>
    ),
    
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <div>
        <a
          style={{ fontWeight: "500",cursor:"pointer" }}
          onClick={() => {
         
            props.updateInputField(CANDIDATE_DETAILS, record);
            props.performOperation(CANDIDATE_VIEW_MODAL, true);
          }}
        >
          {record.name}
        </a>
      </div>
    ),
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
   
  },
  {
    title: "E-mail ID",
    dataIndex: "email",
    key: "email",
    width:"13rem",
  },
  {
    title: "Contact No.",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Job Title",
    dataIndex: "jobTitle",
    key: "jobTitle",
    render: (text, record) => <div>{record?.job?.title}</div>,
  },
  {
    title: "Candidate Type",
    dataIndex: "candidateType",
    key: "candidateType",
    render: (text, record) =>
      <div>{record?.candidateType}</div>
  },
  {
    title: "Experience",
    dataIndex: "experience",
    key: "experience",
    render: (text, record) =>
      `${record.experienceYears} Y ${
        record.experienceMonths !== 0 ? `${record.experienceMonths} M` : ""
      }`,
  },
  {
    title: (
      <div>
        <div>Current CTC</div>
        <div style={{ fontSize: "10px" }}>(in LPA)</div>
      </div>
    ),
    dataIndex: "currentCtc",
    key: "currentCtc",
    render: (text) => <div>{text&&text!=="null"?text:"NA"}</div>
  },
  {
    title: (
      <div>
        <div>Expected CTC</div>
        <div style={{ fontSize: "10px" }}>(in LPA)</div>
      </div>
    ),
    dataIndex: "expectedCtc",
    key: "expectedCtc",
    key: "currentLocation",
    render: (text) => <div>{text&&text!=="null"?text:"NA"}</div>
  },
  {
    title: "Native",
    dataIndex: "currentLocation",
    key: "currentLocation",
    render: (text) => <div>{text ? text:'NA' }</div>
  },
  // {
  //   title: "Feedback",
  //   dataIndex: "",
  //   key: "",
  //   render: (record) => (
  //     <a
  //       className="text-sm"
  //       onClick={() => {
  //         showInterviewFeedback(props, record);
  //       }}
  //     >
  //       <IdcardOutlined />
  //     </a>
  //   ),
  // },
 
  // {
  //   title: "Previous Organisation/s",
  //   dataIndex: "",
  //   key: "",
  //   render: (record) => (
  //       <a  className = 'text-sm'
  //           onClick={() => {
  //               props.updateInputField(HR_PROCESS_CANDIDATE_DETAILS, record);
  //               props.performOperation(operationType.HR_PROCESS_CANDIDATE_PREVIOUS_ORGS_VIEW_MODAL, true);
  //           }}
  //       >
  //         <SolutionOutlined/>
  //       </a>
  //     ),
  // },
  {
    title: (
      <div>
        <Space size="small">
          HR Status
          <Dropdown
            overlay={filterHrStatusMenu(props)}
            onClick={() =>
              props.updateInputField(
                inputFieldType.HR_PROCESS_CANDIDATE_STATUS_DROPDOWN,
                !props.candidateStatusDropdown
              )
            }
            trigger={["click"]}
            id="status-dropdown"
          >
            <FilterOutlined />
          </Dropdown>
        </Space>
        <div style={{ color: Color.modalblue }}>
          {props.candidateFilterStatus}
        </div>
      </div>
    ),
    dataIndex: "hrStatus",
    key: "hrStatus",
    render: (hrStatus, record) => (
      <div>
        <Popover content={timelineContent(hrStatus)}>
          {hrStatus?.hrStatus === undefined ? "NA" : hrStatus?.hrStatus}
        </Popover>
      </div>
    ),
  },
  {
    title:  (
      <div>
        <Space size="small">
           Hiring Status
          <Dropdown
            overlay={hiringStatusMenu(props)}
            onClick={() =>
              props.updateInputField(
                inputFieldType.HR_PROCESS_CANDIDATE_STATUS_DROPDOWN,
                !props.hiringStatusDropdown
              )
            }
            trigger={["click"]}
            id="status-dropdown"
          >
            <FilterOutlined />
          </Dropdown>
        </Space>
        <div style={{ color: Color.modalblue }}>
          {props.hiringFilterStatus}
        </div>
      </div>
    ),
    dataIndex: "hiringStatus",
    key: "hiringStatus",
   
    // render: (text) => <div>{text ? text:'NA' }</div>
  },
  {
    title: (
      <div>
        <div>Offered CTC</div>
        <div style={{ fontSize: "10px" }}>(in LPA)</div>
      </div>
    ),
    dataIndex: "hrStatus",
    key: "hrStatus",
    render: (hrStatus, record) => (
      <div className="text-black text-bold">
        {`${
          hrStatus?.offeredCTC === undefined || hrStatus?.offeredCTC === null
            ? "NA"
            : `${hrStatus?.offeredCTC} `
        }`}
      </div>
    ),
  },
  {
    title: <h1 >Notice Period (Days) </h1>,
    dataIndex: "noticePeriod",
    key: "noticePeriod",
  },
  {
    title: "LWD",
    dataIndex: "hrStatus",
    key: "hrStatus",
    render: (hrStatus, record) => (
      <div>
        {`${hrStatus?.lwd ? moment(hrStatus?.lwd).format("DD-MM-YYYY") : "NA"}`}
      </div>
    ),
  },
  {
    title: "HR Notice Period (Days)",
    dataIndex: "hrStatus",
    key: "hrStatus",
    render: (hrStatus, record) => (
      <div>
        {`${hrStatus?.hrNoticePeriod ? hrStatus?.hrNoticePeriod : "NA"}`}
      </div>
    ),
  },
  {
    title: "Expected Doj",
    dataIndex: "hrStatus",
    key: "hrStatus",
    render: (hrStatus, record) => (
      <div>{`${hrStatus?.expectedDoj ? hrStatus?.expectedDoj : "NA"}`}</div>
    ),
  },

  {
    title: "HR Comment",
    dataIndex: "hrStatus",
    key: "hrStatus",
    render: (hrStatus, record) => (
      <div>
        <Popover
          content={`${
            hrStatus?.hrComment ? hrStatus?.hrComment : "No comments!"
          }`}
        >
          <CommentOutlined style={{ fontSize: 20, color: Color.modalblue }} />
        </Popover>
      </div>
    ),
  },
  {
    title: "HR Actions",
    dataIndex: "hrStatus",
    key: "hrStatus",
    render: (hrStatus, record) => (
      <div>
        <Space size="large">
          <EditIconButton 
            disabled={record?.hiringStatus === "Selected" ? false : true}
            onClick={() => {
              showHrActionEditingView(props);
              props.performOperation(
                operationType.HR_PROCESS_HR_ACTION_EDITING_MODAL,
                true
              );
              props.showHrActionEditingModal(record);
            }}
            id={`editActions${record.key}`}
          ></EditIconButton>
        </Space>
      </div>
    ),
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (hrStatus, record) => (
      <div>
        <Space size="large">
        <Dropdown
            disabled={record?.hiringStatus === "Selected" ? true : false}
            overlay={actionList(props, record)}
            trigger="click"
         >
            <EditIconButton 
            onClick={() => {
              
              
            }}
            id={`moreOutlined${record.key}`}
          >
          </EditIconButton>
          </Dropdown>
        </Space>
      </div>
    ),
  },
];

class HrProcessCandidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  // handlePageChange(page) {
  //   this.props.updateInputField(
  //     inputFieldType.HR_PROCESS_CANDIDATE_HISTORY_PAGE_NUMBER,
  //     page
  //   );
  //   this.props.getAllCandidates(this.props.history, page);
  // }

  handlePageChange = (page, pageSize, value) => {
    
    this.props.getAllCandidates(this.props.history, page, pageSize);
  };

  render() {
    const { isModalVisible } = this.state;
    const {
      candidates,
      viewFeedback,
      editHrActions,
      candidateTableLoader,
      candidatePageMeta,
    } = this.props;

    // const props = this.props;
    // const { pageMeta } = store.getState().createUser;
    

    // const candidates = [...jobs,...jobs];

    return (
      <div>
        <Table
          columns={hrProcessCandidateColumns(this.props).map((col) => ({
            ...col,
            align: "center",
          }))}
          pagination={{
            position: "bottomRight",
            showSizeChanger: true,
            current:parseInt(candidatePageMeta.currentPage),
            // pageSize: candidatePageMeta.itemsPerPage,
            pageSizeOptions: [10,25, 30, 50, 100],
            total: candidatePageMeta?.totalItems
              ? candidatePageMeta?.totalItems
              : 0,
            onChange: (page, pageSize) => {
             
              this.handlePageChange(page, pageSize, "allCandidates");
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onShowSizeChange: this.handlePageChange,
            // onShowSizeChange: this.handlePageChange,
            // this.handlePageChange(page)
          }}
          dataSource={candidates}
          scroll={{ x: 3000,y:390 }}
          style={{ overflow: "hidden" }}
          rowClassName="data-row"
          loading={candidateTableLoader}
          locale={{
            emptyText: candidateTableLoader ? (
              <div className="no-data-loader"></div>
            ) : Object.keys(candidates).length === 0 ? (
              <EmptyTable text="No Profiles Found" />
            ) : (
              <EmptyTable text="Something went wrong!" />
            ),
          }}
        />
        {viewFeedback && <HrProcessFeedbackViewModal />}
        <CandidatePreviousOrgsViewModal />
        {editHrActions && <CandidateActionsModal />}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HrProcessCandidate);
