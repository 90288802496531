/* eslint-disable react/display-name */
import React from "react";
import { useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  DatePicker,
  Dropdown,
  Menu,
  Typography,
} from "antd";
import { mapDispatchToProps, mapStateToProps } from "./redux/container";
import { connect,useSelector } from "react-redux";
import { ArrowLeftOutlined, CaretDownOutlined,DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import classes from "./index.module.css";
import { Api } from "services";
const { Title } = Typography;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const JobSummary = ({
  
  getJobCount,
  jobSummary,
  jobSummaryLoader,
  pageMeta,
  totalProfiles,
  totalInProgress,
  totalPending,
  totalNotShortlisted,
  totalRejected,
  totalDuplicate,
}) => {
  const {orgTypes}=useSelector(state=>state.jobSummary)
  const history = useHistory();
  const [dateRange, setDateRange] = useState([]);
  const [jobType, setJobType] = useState(0);
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("")
  const [pageChange, setPageChange] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getJobCount(dateRange,jobType,pageSize, pageChange);
  }, [dateRange,jobType,pageSize, pageChange]);
  
  const handlePickerSelect = (date, datesFormatted) => {
    setDateRange(datesFormatted);
    getJobCount(datesFormatted,jobType,pageSize, pageChange);
  };

  const handlePageChange = (page, pageSize) => {
    setPageChange(page);
    setPageSize(pageSize);
    console.log("sssRedsjn",page,pageSize)
    getJobCount(dateRange,jobType,pageSize,page);
  };

  const handleJobTypeChange = (jobType) => {
    setJobType(jobType);
  };

  const jobTypeMenu = (
    <Menu className={classes.scrollItem}>
      <Menu.Item key="0" onClick={() => handleJobTypeChange("All")}>
        <div>All</div>
      </Menu.Item>
      {orgTypes.map((element) =>{
        return(
          <Menu.Item key={element.id} onClick={() => handleJobTypeChange(element.id)}>
            <div>{element.name}</div>
          </Menu.Item>
        )
      })}
      
      {/* <Menu.Item key="1" onClick={() => handleJobTypeChange("Automotive")}>
        <div>Automotive</div>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleJobTypeChange("Other")}>
        <div>Other</div>
      </Menu.Item> */}
    </Menu>
  );

  const columns = [
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: (title, record) => (
        <div>
          <a
            style={{ fontWeight: "500" }}
            onClick={() => {
              
              history.push({
                pathname: `job-summary/${record.jobId}`,
                state: { record, dates: dateRange },
              });
            }}
          >
            {title}
          </a>
        </div>
      ),
    },

    {
      title: "Profiles Shared",
      dataIndex: "profilesShared",
      key: "profilesShared",
    },
    {
      title: "In Progress",
      dataIndex: "inProgress",
      key: "inProgress",
    },
    {
      title: "Action Pending",
      dataIndex: "actionPending",
      key: "actionPending",
    },
    {
      title: "Not Shortlisted",
      dataIndex: "notShortlisted",
      key: "notShortlisted",
    },
    {
      title: "Not Selected",
      dataIndex: "notSelected",
      key: "notSelected",
    },
    {
      title: "Duplicate Profiles",
      dataIndex: "duplicateProfiles",
      key: "duplicateProfiles",
    },
  ];

  return (
    <div style={{padding:"2%"}}>
      <Row style={{ justifyContent:"space-between",marginBottom:"1.3rem",display:"flex",flexDirection:"row"}}>
        <div style={{maxWidth:"50%",display:"flex",flexDirection:"row",justifyContent:"space-between",gap:"2rem"}}>
        
          <Button icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
            Go Back
          </Button>
        

          <Dropdown overlay={jobTypeMenu} trigger={["click"]} >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Title
                level={4}
                style={{ display: "inline-block", margin: 0, marginRight: 8 }}
              >
                {orgTypes.map(element =>{
                  if(element.id===jobType)
                  {
                    return element.name
                  }})} Department
              </Title>{" "}
              <CaretDownOutlined style={{ color: "black", fontSize: 20 }} />
            </a>
          </Dropdown>
        </div>
        
        <div>
           <Button type="primary" style={{borderRadius:"5px",marginRight:"1.2rem"}}
              onClick={()=>{
                Api.get('/hiring-overview/jobProfile-count')
                   .params({
                    download:true,
                    startDate:startDate,
                    endDate:endDate,
                    orgType:jobType,
                   })
                  .send((response) => {
                    if (typeof response != "undefined") {
                    window.open(response, "_blank")?.focus();
                    }
                })
                }}               
          >
            <DownloadOutlined style={{fontSize:"1rem"}}/> Download
          </Button>

          <RangePicker
            format={dateFormat}
            onChange={(date, dateString) =>{
              handlePickerSelect(date, dateString)
           
              setStartDate(dateString[0])
              setEndDate(dateString[1])
            }}
          />
        </div>
      </Row>
      <Row className="m-t-30">
        <Col flex="auto">
          <Table
            pagination={{
              position: "bottomRight",
              size: "small",
              showSizeChanger: true,
              pageSizeOptions:[10,25, 30, 50, 100],
              // pageSize: pageSize,
              total: pageMeta?.totalItems,
              showTotal: (total, count) =>
              `${count[0]}-${count[1]} of ${total} items`,
              onShowSizeChange: handlePageChange,
              onChange: handlePageChange,
            }}
            dataSource={jobSummary}
            columns={columns}
            scroll={{ y: 360 }}
            loading={jobSummaryLoader}
            summary={() => {
              if (!jobSummary.length) {
                return null;
              }
              return (
                <>
                  {/* <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                  </Table.Summary.Row> */}
                  <Table.Summary.Row className="text-black text-bold text-sm">
                    <Table.Summary.Cell>
                      <b>Total</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>{totalProfiles}</Table.Summary.Cell>
                    <Table.Summary.Cell>{totalInProgress}</Table.Summary.Cell>
                    <Table.Summary.Cell>{totalPending}</Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {totalNotShortlisted}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>{totalRejected}</Table.Summary.Cell>

                    <Table.Summary.Cell>{totalDuplicate}</Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobSummary));
