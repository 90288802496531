import { createSlice } from "@reduxjs/toolkit";
import store from "pages/dashboard-home/store";

const initialState = {
  candidatequestion: [],
  submittedAnswers:[],
  instruction:{},
  candidateInfo:{},
  result:{},
  attempted:{},
  storedFile:{},
  modalOpen:false,
  verifyOTP:false,
};

const candidateSlice = createSlice({
  name: "userCandidate",
  initialState,
  reducers: {
    updateQuestions: (state, action) => {
      state.candidatequestion = [ ...action.payload.candidatequestion ];
    },
    updateSelectedAnswers(state,action)
    {
      state.submittedAnswers =[...action.payload]
    },
    updateTestInstruction(state,action)
    {
      state.instruction = {...action.payload}
    },
    getCandidateInfo (state,action) {
      state.candidateInfo = {...action.payload}
    },
    updateResult(state,action){
      state.result = {...action.payload}
    },
    updateAttempt(state,action)
    {
      state.attempted={...action.payload}
    },
    uploadFile(state,action)
    {   
      state.storedFile={...state.storedFile,...action.payload}
    },
    resetFile(state,action){
      delete state.storedFile[action.payload]
    },
    updateModalOpen(state,action){
      state.modalOpen=action.payload;
    },
    updateVerifyOTP(state,action){
      state.verifyOTP=action.payload;
    }
  },
});
export default candidateSlice.reducer;
export const candidateActions = candidateSlice.actions;
