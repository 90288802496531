class Webadaptor {
    constructor({ streamId, canvasId, callback }) {
      this.stream = null;
      // this.local = document.getElementById("local");
      this.audiotrack = new MediaStream();
      this.candidateTypes = ["udp", "tcp"];
      this.remotePeerConnection = [];
      this.iceCandidateList = [];
      this.remoteDescriptionSet = {};
      this.stream3 = null;
      this.streamId = streamId;
      this.sender = null;
      this.ctx = null;
      this.sender2 = null;
      this.changedstream = null;
      this.camera_location = "top";
      this.timer1 = null;
      this.timer2 = null;
      this.canvasId = canvasId;
      this.callback = callback ? callback : () => {};
      this.rec = false;
      this.camerastream = null;
      this.screenstate = false;
      this.isonline = true;
      this.canvasstreamnew = null;
  
      this.wsConn = null;
      this.offlineevent = function (e) {
        this.callback({
          command: "wentOffline",
        });
        // let thiz = this;
        this.rec = true;
        
        
      };
      this.offlinefunction = this.offlineevent.bind(this);
      this.onlineevent = function (e) {
        this.callback({
          command: "wentOnline",
        });
        // let thiz = this;
        // if (this.timer2) {
        //   clearTimeout(this.timer2);
        //   this.timer2 = null;
        // }
        if (this.rec && this.isonline) {
          this.setWebsocket();
        }
      };
      this.onlinefunction = this.onlineevent.bind(this);
    }
    sendPing = () => {
      var jsCmd = {
        command: "ping",
      };
      this.wsConn.send(JSON.stringify(jsCmd));
    };
  
    addIceCandidate = (streamId, candidate) => {
      var protocolSupported = false;
      if (candidate.candidate == "") {
        //candidate can be received and its value can be "".
        //don't compare the protocols
        protocolSupported = true;
      } else if (typeof candidate.protocol == "undefined") {
        this.candidateTypes.forEach((element) => {
          if (candidate.candidate.toLowerCase().includes(element)) {
            protocolSupported = true;
          }
        });
      } else {
        protocolSupported = this.candidateTypes.includes(
          candidate.protocol.toLowerCase()
        );
      }
  
      if (protocolSupported) {
        let thiz = this;
        this.remotePeerConnection[streamId]
          .addIceCandidate(candidate)
          .then(function (response) {})
          .catch((e) => {
            thiz.callback({
              command: "error",
              error: e,
              consoleError: e,
            });
          });
      } else {
      }
    };
  
    takeConfiguration = (idOfStream, configuration, typeOfConfiguration) => {
      let thiz = this;
      var streamId = idOfStream;
      var type = typeOfConfiguration;
      var conf = configuration;
      var isTypeOffer = type == "offer";
  
      var dataChannelMode = "publish";
      if (isTypeOffer) {
        dataChannelMode = "play";
      }
  
      if (this.remotePeerConnection[streamId] == null) {
        var closedStreamId = streamId;
  
        this.remotePeerConnection[streamId] = new RTCPeerConnection({
          iceServers: [
            {
              urls: "stun:stun.l.google.com:19302",
            },
          ],
        });
        this.remoteDescriptionSet[streamId] = false;
        this.iceCandidateList[streamId] = new Array();
  
        this.remotePeerConnection[streamId].addStream(this.stream);
  
        this.remotePeerConnection[streamId].onicecandidate = function (event) {
          if (event.candidate) {
            var protocolSupported = false;
  
            if (event.candidate.candidate == "") {
              //event candidate can be received and its value can be "".
              //don't compare the protocols
              protocolSupported = true;
            } else if (typeof event.candidate.protocol == "undefined") {
              thiz.candidateTypes.forEach((element) => {
                if (event.candidate.candidate.toLowerCase().includes(element)) {
                  protocolSupported = true;
                }
              });
            } else {
              protocolSupported = thiz.candidateTypes.includes(
                event.candidate.protocol.toLowerCase()
              );
            }
  
            if (protocolSupported) {
              var jsCmd = {
                command: "takeCandidate",
                streamId: streamId,
                label: event.candidate.sdpMLineIndex,
                id: event.candidate.sdpMid,
                candidate: event.candidate.candidate,
              };
  
              thiz.wsConn.send(JSON.stringify(jsCmd));
            } else {             
              if (event.candidate.candidate != "") {               
              }
            }
          }
        };
        this.remotePeerConnection[streamId].ontrack = function (event) {};
  
        if (dataChannelMode == "publish") {       
          const dataChannelOptions = {
            ordered: true,
          };
          if (this.remotePeerConnection[streamId].createDataChannel) {
            var dataChannel = this.remotePeerConnection[
              streamId
            ].createDataChannel(streamId, dataChannelOptions);
            dataChannel.onopen = () => {
              this.remotePeerConnection[streamId].dataChannel = dataChannel;
            };
          } else {           
          }
        }
      }
  
      this.remotePeerConnection[streamId]
        .setRemoteDescription(
          new RTCSessionDescription({
            sdp: conf,
            type: type,
          })
        )
        .then((response) => {
          thiz.remoteDescriptionSet[streamId] = true;
          var length = thiz.iceCandidateList[streamId].length;
  
          for (var i = 0; i < length; i++) {
            thiz.addIceCandidate(streamId, thiz.iceCandidateList[streamId][i]);
          }
          thiz.iceCandidateList[streamId] = [];
        })
        .catch((e) => {
          thiz.callback({
            command: "error",
            error: "can not set remote description",
            consoleError: e,
          });
        });
    };
  
    takeCandidate = (idOfTheStream, tmpLabel, tmpCandidate) => {
      var streamId = idOfTheStream;
      var label = tmpLabel;
      var candidateSdp = tmpCandidate;
      var candidate = new RTCIceCandidate({
        sdpMLineIndex: label,
        candidate: candidateSdp,
      }); 
      if (this.remotePeerConnection[streamId] == null) {      
        this.remotePeerConnection[streamId] = new RTCPeerConnection({
          iceServers: [
            {
              urls: "stun:stun.l.google.com:19302",
            },
          ],
        });
        this.remoteDescriptionSet[streamId] = false;
        this.iceCandidateList[streamId] = new Array();
        this.remotePeerConnection[streamId].addStream(this.stream);
        let thiz = this; 
        this.remotePeerConnection[streamId].onicecandidate = function (event) {
          if (event.candidate) {
            var protocolSupported = false;
            if (event.candidate.candidate == "") {            
              protocolSupported = true;
            } else if (typeof event.candidate.protocol == "undefined") {
              thiz.candidateTypes.forEach((element) => {
                if (event.candidate.candidate.toLowerCase().includes(element)) {
                  protocolSupported = true;
                }
              });
            } else {
              protocolSupported = thiz.candidateTypes.includes(
                event.candidate.protocol.toLowerCase()
              );
            }
  
            if (protocolSupported) {
              var jsCmd = {
                command: "takeCandidate",
                streamId: streamId,
                label: event.candidate.sdpMLineIndex,
                id: event.candidate.sdpMid,
                candidate: event.candidate.candidate,
              };
  
              thiz.wsConn.send(JSON.stringify(jsCmd));
            } else {            
              if (event.candidate.candidate != "") {               
              }
            }
          }
        };
        this.remotePeerConnection[streamId].ontrack = function (event) {};    
        const dataChannelOptions = {
          ordered: true,
        };
  
        if (this.remotePeerConnection[streamId].createDataChannel) {
          var dataChannelPeer = this.remotePeerConnection[
            streamId
          ].createDataChannel(streamId, dataChannelOptions);
          dataChannelPeer.onopen = () => {
            thiz.remotePeerConnection[streamId].dataChannel = dataChannelPeer;            
          };
  
          this.remotePeerConnection[streamId].ondatachannel = function (ev) {
            dataChannelPeer.onopen = () => {
              thiz.remotePeerConnection[streamId].dataChannel = ev.channel;            
            };
          };
        } else {       
        }
      }
  
      if (this.remoteDescriptionSet[streamId] == true) {
        this.addIceCandidate(streamId, candidate);
      } else {
        this.iceCandidateList[streamId].push(candidate);
      }
    };
  
    startpublish = (streamId = this.streamId) => {
      let thiz = this;
      if (!this.remotePeerConnection[streamId]) {
      }
      this.remotePeerConnection[streamId] = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
        ],
      });
      this.iceCandidateList[streamId] = new Array();
      this.remotePeerConnection[streamId].addStream(this.stream);
      this.remotePeerConnection[streamId].onicecandidate = (event) => {
        if (event.candidate) {
          var protocolSupported = false; 
          if (event.candidate.candidate == "") {
            protocolSupported = true;
          } else if (typeof event.candidate.protocol == "undefined") {
            this.candidateTypes.forEach((element) => {
              if (event.candidate.candidate.toLowerCase().includes(element)) {
                protocolSupported = true;
              }
            });
          } else {
            protocolSupported = this.candidateTypes.includes(
              event.candidate.protocol.toLowerCase()
            );
          }  
          if (protocolSupported) {
            var jsCmd = {
              command: "takeCandidate",
              streamId: streamId,
              label: event.candidate.sdpMLineIndex,
              id: event.candidate.sdpMid,
              candidate: event.candidate.candidate,
            }; 
            this.wsConn.send(JSON.stringify(jsCmd));
          } else {           
            if (event.candidate.candidate != "") {             
            }
          }
        }
      };
      const dataChannelOptions = {
        ordered: true,
      };
      if (this.remotePeerConnection[streamId].createDataChannel) {
        var streamId = streamId;
        var dataChannel = this.remotePeerConnection[streamId].createDataChannel(
          streamId,
          dataChannelOptions
        );
  
        dataChannel.onopen = () => {
          thiz.remotePeerConnection[streamId].dataChannel = dataChannel;
        };
        dataChannel.onerror = (error) => {        
        };
      }
  
      this.remotePeerConnection[streamId]
        .createOffer({
          OfferToReceiveAudio: false,
          OfferToReceiveVideo: false,
        })
        .then((configuration) => {
          thiz.remotePeerConnection[streamId]
            .setLocalDescription(configuration)
            .then((responose) => {
              var jsCmd = {
                command: "takeConfiguration",
                streamId: streamId,
                type: configuration.type,
                sdp: configuration.sdp,
              };
  
              thiz.wsConn.send(JSON.stringify(jsCmd));
            })
            .catch((e) => {
              thiz.callback({
                command: "error",
                error: "can not set local description",
                consoleError: e,
              });
            });
        })
        .catch((e) => {
          thiz.callback({
            command: "error",
            error: "can not create offer",
            consoleError: e,
          });
        });
    };
    clearListeners() {
      if (this.timer1) {
        clearInterval(this.timer1);
        this.timer1 = null;
      }
      window.removeEventListener("offline", this.offlinefunction);
      window.removeEventListener("online", this.onlinefunction);
    } 
    closeConnection() {
      this.screenstate = false;
      this.removecamera();
      this.stopdesktop();
      if (this.wsConn && this.wsConn.readyState === WebSocket.OPEN) {
        var jsCmd = {
          command: "stop",
          streamId: this.streamId,
        };
        this.wsConn.send(JSON.stringify(jsCmd));
      } 
      this.clearListeners();
      if (this.remotePeerConnection[this.streamId])
        this.remotePeerConnection[this.streamId].close();
      if (this.wsConn) {
        this.wsConn.close();
      }
    }
  
    trackSenders() {
      if (this.remotePeerConnection[this.streamId]) {
        let videoTrack = this.stream.getVideoTracks()[0];
        this.sender = this.remotePeerConnection[this.streamId]
          .getSenders()
          .find(function (s) {
            return s.track.kind == videoTrack.kind;
          });
      }
    }
    async getdesktopstream() {
      let thiz = this;
  
      try {
        if (!this.stream3) {
          this.stream3 = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: false,
          });
          this.stream3.getVideoTracks()[0].onended = function () {
            thiz.callback({
           command:"screenSharingStopped"
         })
};
        }

        thiz.callback({
          command: "cameraOn",
          data: false,
          stream: null,
        });
      } catch (e) {
        thiz.callback({
          command: "error",
          error: "can not get desktop stream",
          consoleError: e,
        });
      }
    }
    async sendscreen() {
      try {
        await this.getdesktopstream();
        let thiz = this;
        if (!thiz.remotePeerConnection[thiz.streamId]) {
          let oldstream = thiz.stream;
          thiz.stream = this.stream3;
          if (oldstream == null) {           
            thiz.callback({
              command: "localStreamRcv",
            });
          }        
        } else {
          thiz.changedstream = this.stream3;
        }
        thiz.setStreamToLocal();
      } catch (e) {      
      }
    } 
    toggle(param) {
      this.removecamera();
      if (this.timer1) {
        clearInterval(this.timer1);
        this.timer1 = null;
      }
  
      this.trackSenders();
  
      switch (param) {
        case "camera+screen":
          this.cameraopen();
          break;
  
        case "screen":        
          this.sendscreen();
          break;
        case "canvas":
          this.stopdesktop();
          this.createcanvas(false);
          break;
        case "camera+canvas":
          this.stopdesktop();
          this.createcanvas(true);
          break;
        default:
          this.createcanvas(false);
      }
    }
    switchMute(param) {
      this.stream.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
        this.callback({
          command: "switchMute",
          data: track.enabled,
        });
      });
    }
    reconnectsocket() {
      if (navigator.onLine) {
        this.rec = true;
        this.setWebsocket();
      }
    }
    closewebsocket() {
      this.wsConn.close();
    }
    listen() {
      let thiz = this;
      window.addEventListener("offline", this.offlinefunction);
      window.addEventListener("online", this.onlinefunction);
    }
  
    setWebsocket() {
      this.screenstate = true;
      let thiz = this;
      this.wsConn = new WebSocket(
        "wss://media.aulas.in:443/WebRTCAppEE/websocket"
      );
      this.wsConn.onopen = () => {
        if (this.rec) {
          if (this.stream) {
            this.publish();
            this.rec = false;
          }
        }
        this.callback({
          command: "webSocketConnected",
        });
  
        setInterval(() => {
          if (this.wsConn && this.wsConn.readyState === WebSocket.OPEN)
            thiz.sendPing();
        }, 3000);
      };
      this.wsConn.onmessage = (event) => {
        var obj = JSON.parse(event.data);
        this.callback(obj);
        if (obj.command != "pong");
        if (obj.command == "start") {
          thiz.startpublish();
        } else if (obj.command == "takeCandidate") {
          thiz.takeCandidate(obj.streamId, obj.label, obj.candidate);
        } else if (obj.command == "takeConfiguration") {
          thiz.takeConfiguration(obj.streamId, obj.sdp, obj.type);
        } else if (obj.command == "pong") {
        }
      };
      this.wsConn.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
      this.wsConn.onclose = () => {
        this.callback({
          command: "websocketclosed",
        });
        if (this.screenstate) {
          this.reconnectsocket();
        }
      };
    }
    removecamera() {
      if (this.camerastream) {
        this.camerastream.getVideoTracks()[0].stop();
        this.camerastream = null;
      }
    }
    stopdesktop() {
      if (this.stream3) {
        this.stream3.getVideoTracks()[0].stop();
        this.stream3 = null;
      }
    }
    refreshdesktop(params) {
      this.stopdesktop();
      this.toggle(params);
    }
    createcanvas(param) {
      var c = document.getElementById(this.canvasId);
      var ctx = c.getContext("2d");
  
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, c.width, c.height);
  
      //
      let thiz = this;
  
      this.canvasstreamnew = c.captureStream(0);
      this.timer1 = setInterval(function () {
        setTimeout(function () {
          try {
            thiz.stream3.getVideoTracks()[0].requestFrame();
          } catch (e) {
            clearInterval(this.timer1);
          }
        }, 10);
      }, 10);
      if (!param) this.canvas();
      else {
        this.canvasWithCamera();
      }
    }
    canvasWithCamera() {
      let thiz = this;
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .then(function (cameraStream) {
          thiz.camerastream = cameraStream;
          thiz.callback({
            command: "cameraOn",
            data: true,
            stream: cameraStream,
          });
          var canvas = document.createElement("canvas");
          var canvasContext = canvas.getContext("2d");
          var screenVideo = document.createElement("video");
          screenVideo.srcObject = thiz.canvasstreamnew;
          screenVideo.play();
          var cameraVideo = document.createElement("video");
          cameraVideo.srcObject = cameraStream;
          cameraVideo.play();
          var canvasStream = canvas.captureStream(15);
          thiz.setStream(canvasStream);
          thiz.setStreamToLocal();
          setInterval(function () {
            canvas.width = screenVideo.videoWidth;
            canvas.height = screenVideo.videoHeight;
            canvasContext.drawImage(
              screenVideo,
              0,
              0,
              canvas.width,
              canvas.height
            );
  
            var cameraWidth = screenVideo.videoWidth * (15 / 100);
            var cameraHeight =
              (cameraVideo.videoHeight / cameraVideo.videoWidth) * cameraWidth;
  
            var positionX = canvas.width - cameraWidth - 15;
            var positionY;
  
            if (thiz.camera_location == "top") {
              positionY = 15;
            } else {            
              positionY = canvas.height - cameraHeight - 15;
            }
            canvasContext.drawImage(
              cameraVideo,
              positionX,
              positionY,
              cameraWidth,
              cameraHeight
            );
          }, 66);
        })
        .catch((e) => {
          thiz.callback({
            command: "error",
            error: "can not turn on the video",
            consoleError: e,
          });
        });
    }
  
    canvas() {
      var c = document.createElement("canvas");
      let thiz = this;
  
      var t = true;
      var d = 1;
      var ctx = c.getContext("2d");
      var displayvideo = document.createElement("video");
      displayvideo.srcObject = thiz.canvasstreamnew;
      displayvideo.play();
      var thisstream = c.captureStream(15);
      thiz.callback({
        command: "cameraOn",
        data: false,
        stream: null,
      });
      this.setStream(thisstream);
      thiz.setStreamToLocal();
      setInterval(function () {
        c.width = displayvideo.videoWidth;
        c.height = displayvideo.videoHeight;
        ctx.drawImage(displayvideo, 0, 0, c.width, c.height);
      }, 66);
    }
    setStreamToLocal() {
      let thiz = this;
      if (this.changedstream) {
        if (thiz.remotePeerConnection[thiz.streamId]) {
          thiz.sender.replaceTrack(thiz.changedstream.getVideoTracks()[0]);
        }
      }
      if (this.stream) {
        if (this.stream.getAudioTracks().length == 0) {
          navigator.mediaDevices
            .getUserMedia({
              video: false,
              audio: true,
            })
            .then(function (stream2) {
              thiz.stream.addTrack(stream2.getAudioTracks()[0]);
              // thiz.local.srcObject = thiz.stream;
              thiz.callback({
                command: "audioAdded",
              });
            })
            .catch((e) => {
              thiz.callback({
                command: "error",
                error: "audio device is not found",
                consoleError: e,
              });
            });
        } else {          
        }
      }
    }
    setStream(canvasStream) {
      let thiz = this;
      if (!thiz.remotePeerConnection[thiz.streamId]) {
        let oldstream = thiz.stream;
        thiz.stream = canvasStream;
        if (!oldstream) {       
          this.callback({
            command: "localStreamRcv",
          });
        }
      } else {
        thiz.changedstream = canvasStream;
      }
    }
  
    getDisplayStream() {
      if (navigator.mediaDevices.getDisplayMedia && !this.stream3) {
        let thiz = this;
        navigator.mediaDevices
          .getDisplayMedia({
            video: true,
            audio: false,
          })
          .then(function (stream) {
            thiz.callback({
              command: "cameraOn",
              data: false,
              stream: null,
            });
  
            if (!thiz.remotePeerConnection[thiz.streamId]) {
              let oldstream = thiz.stream;
              thiz.stream = stream;
              if (oldstream == null) {
                thiz.callback({
                  command: "localStreamRcv",
                });
              }
            } else {
              thiz.changedstream = stream;
            }
  
            thiz.setStreamToLocal();
          })
          .catch((e) => {
            thiz.callback({
              command: "error",
              error: "can not get display media of device",
              consoleError: e,
            });
          });
      } else {
        let thiz = this;
        thiz.callback({
          command: "cameraOn",
          data: false,
          stream: null,
        });
  
        thiz.changedstream = this.stream3;
  
        thiz.setStreamToLocal();
      }
    }
    publish = () => {
      if (this.stream) {
        var obj = {
          command: "publish",
          streamId: this.streamId,
        };
        this.wsConn.send(JSON.stringify(obj));
      } else {
        alert("please share screen");
      }
      // webrtcadapter.publish();
    };
  
    async getScreenAndCamera() {
      if (this.timer1) {
        clearInterval(this.timer1);
        this.timer1 = null;
        var thiz = this;
        await this.getdesktopstream();
      }
    }
    cameraopen = async () => {
      if (this.timer1) {
        clearInterval(this.timer1);
      }
      var thiz = this;
      try {
        await this.getdesktopstream();
      } catch (e) {
        return thiz.callback({
          command: "error",
          error: "can not get display media of the device",
          consoleError: e,
        });
      }
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .then(function (cameraStream) {
          thiz.camerastream = cameraStream;
          thiz.callback({
            command: "cameraOn",
            data: true,
            stream: cameraStream,
          });
          var canvas = document.createElement("canvas");
          var canvasContext = canvas.getContext("2d");
          var screenVideo = document.createElement("video");
          var streamOfVideo = new MediaStream();
          streamOfVideo.addTrack(thiz.stream3.getVideoTracks()[0]);
          screenVideo.srcObject = streamOfVideo;
          screenVideo.play();
          var cameraVideo = document.createElement("video");
          cameraVideo.srcObject = cameraStream;
  
          cameraVideo.play();
          var canvasStream = canvas.captureStream(15);
          thiz.setStream(canvasStream); 
          thiz.setStreamToLocal();
          setInterval(function () {
            canvas.width = screenVideo.videoWidth;
            canvas.height = screenVideo.videoHeight;
            canvasContext.drawImage(
              screenVideo,
              0,
              0,
              canvas.width,
              canvas.height
            );
  
            var cameraWidth = screenVideo.videoWidth * (15 / 100);
            var cameraHeight =
              (cameraVideo.videoHeight / cameraVideo.videoWidth) * cameraWidth;
  
            var positionX = canvas.width - cameraWidth - 15;
            var positionY;
  
            if (thiz.camera_location == "top") {
              positionY = 15;
            } else {             
              positionY = canvas.height - cameraHeight - 15;
            }
            canvasContext.drawImage(
              cameraVideo,
              positionX,
              positionY,
              cameraWidth,
              cameraHeight
            );
          }, 66);
        })
        .catch((e) => {
          thiz.callback({
            command: "error",
            error: "can not turn on the video",
            consoleError: e,
          });
        });
    };
  }
  export default Webadaptor;