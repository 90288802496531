import {
  performOperation,
  updateInputField,
  handleAdmin,
  handleAdminDelete,
  showAdminEditingModal,
  updateAgencyUsersInputField,
  agencyUserAdd,
  agencyUserDelete,
  handleAgency,
  showAgencyEditingModal,
  handleAgencyDelete,
  getAdmins,
  getAgencies,
  getUserDetails
} from "./action";
export const mapStateToProps = (state, ownProps) => {
  const {
    adminUser,
    userRole, 
    userStatus,
    adminUsers,
    userDetailLists,
    adminDetailsModal,
    adminDetailsLoader,
    adminEditingId,
    adminTableLoader,
    isAddingAdmin,
    screenType,
    agencyInfo,
    agencies,
    agencyDetailsModal,
    agencyViewModal,
    agencyDetailsLoader,
    isAddingAgency,
    agencyEditingId,
    agencyTableLoader,
    userDesignations,
    searchValue,
  } = state.createUser;
  const history = ownProps.history;
  return {
    adminUser,
    userRole, 
    userStatus,
    adminUsers,
    userDetailLists,
    adminDetailsModal,
    adminDetailsLoader,
    adminEditingId,
    adminTableLoader,
    isAddingAdmin,
    screenType,
    agencyInfo,
    agencies,
    agencyDetailsModal,
    agencyViewModal,
    agencyDetailsLoader,
    isAddingAgency,
    agencyEditingId,
    agencyTableLoader,  
    history,
    userDesignations,
    searchValue,
    user: state.session.user,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    handleAdmin: () => dispatch(handleAdmin()),
    handleAdminDelete: (id) => dispatch(handleAdminDelete(id)),
    showAdminEditingModal: (record) => dispatch(showAdminEditingModal(record)),
    getAdmins: (page,limit) => dispatch(getAdmins(page,limit)),

    agencyUserAdd: () => dispatch(agencyUserAdd()),
    agencyUserDelete: (index) => dispatch(agencyUserDelete(index)),
    handleAgency: () => dispatch(handleAgency()),
    showAgencyEditingModal: () => dispatch(showAgencyEditingModal()),
    handleAgencyDelete: (id) => dispatch(handleAgencyDelete(id)),
    getAgencies: (page,limit) => dispatch(getAgencies(page,limit)),
    getUserDetails: () => dispatch(getUserDetails()),

    performOperation: (operationType, value) => dispatch(performOperation(operationType, value)),
    updateInputField: (inputFieldType, value) => dispatch(updateInputField(inputFieldType, value)),
    updateAgencyUsersInputField: (inputFieldType, index, value) =>
      dispatch(updateAgencyUsersInputField(inputFieldType, index, value)),
  };
};
