import { Modal,Row,Col,Typography,Space,Button } from "antd";
import React, { useEffect, useState } from "react";
import { Api } from "services";
import { useDispatch } from "react-redux";
import { adminActions } from "pages/dashboard-home/store";
import { authActions } from "pages/login/store";
const ProfilView = (props) =>{
    const dispatch=useDispatch()
    const [btnLoader,setBtnLoader]=useState(false)
  
    const [openModal,setModal]=useState(false)
    useEffect(() =>{
    if(props.finalVal!=="")
    {
        setModal(true)
    }
    },[props?.finalVal])
   
   return (
    <Modal footer={false} centered={true}    width={"50%"} open={openModal} onCancel={() =>setModal(false)}>
        <div style={{maxHeight:"86vh",overflowY:"auto",marginBottom:"1rem"}}>
       <Row justify={"center"} style={{marginRight:"30px"}}><Typography.Text style={{fontWeight:"400px",fontSize:"25px",alignSelf:"center",color:"#0059b3",fontWeight:"700"}}>Preview</Typography.Text></Row> 
        <Row>
        <Col style={{marginTop:"20px",fontSize:"17px"}} span={10} offset={2}><b style={{color:"#3399ff",fontWeight:"600"}}>Job Title</b>: {" "}{props?.candidateJob.map((element) =>{
                            if(element.id===props?.finalVal["jobId"]){
                            return element.title
                            }
                        })}</Col>
        {Object.keys(props?.finalVal).map(keys =>{
            
            if(keys!=="resume" && keys!=="photo" && props?.finalVal[keys]){
                if(keys==="jobId")
                {
                    return ""
                }
                if(keys==="comment"){
    
                    return <Col style={{marginTop:"20px",fontSize:"17px"}} span={10} offset={2}><b style={{color:"#3399ff",fontWeight:"600"}}>Agency {keys}</b>: {" "}{props?.finalVal[keys]}</Col>
                }
                if(keys==="preferredLocation"){
                    return(
                        <Col style={{marginTop:"20px",fontSize:"17px"}} span={10} offset={2}><span><b style={{color:"#3399ff",fontWeight:"600"}}>{keys}</b>: {" "}{props?.preferredJobLocation.map((element) =>{
                            if(element.id===props?.finalVal[keys]){
                            return element.name
                            }
                        })}</span></Col>
                    )
                }
           
            
            return(
                <Col style={{marginTop:"20px",fontSize:"17px"}} span={10} offset={2}><b style={{color:"#3399ff",fontWeight:"600"}}>{keys}</b>: {" "}{props?.finalVal[keys]}</Col>
            )
            
            }
            else if(keys==="jobLocation"){
                return(
                    <Col style={{marginTop:"20px",fontSize:"17px"}} span={10} offset={2}><span><b style={{color:"#3399ff",fontWeight:"600"}}>{keys}</b>: {" "}{props?.jobLocations.map((element,i) =>{
                        
                            if(i<props?.jobLocations.length-1)
                            {
                              return(
                                <>
                                {element.name + ","}
                                </>
                              
                              )
                            }
                            else
                              {
                                return(
                                  <>
                                  {element.name}
                                </>
                                )
                              }
                          
                    })}</span></Col>
                )
            }
             
            else if(keys==="resume" || keys==="photo"){
                return  <Col style={{marginTop:"20px",fontSize:"17px"}} span={10} offset={2}><b style={{color:"#3399ff",fontWeight:"600"}}>{keys}</b>: {" "}{props?.finalVal[keys]?.name}</Col>
            }
          
        })}
        </Row>
       <Row style={{marginTop:"26px"}} justify={"center"}><Space><Button size="middle" style={{width:"9rem"}} onClick={() =>{
            setModal(false)
        }}>Cancel</Button><Button loading={btnLoader} size="middle" type={"primary"} style={{width:"9rem"}} onClick={() =>{
            setBtnLoader(true)
             Api.post("/hiring-candidate/addExpCandidate").upload(
                props?.finalVal,
                (percentCompleted, response) => { 
                   if(percentCompleted===-1){
                    setBtnLoader(false)
                   }
                  if (percentCompleted === 100 && response !== undefined) {
                    setBtnLoader(false)
                    dispatch(adminActions.updateModalOpen(false));
                    props?.setImgFile("");
                    props?.setImageUrl("");       
                    props?.setJobloactions([])
                    props?.setImgFileName("")        
                    props?.setResumeFileName("")
                          
                    props?.prevdata?.getAllCandidates("","") 
                        
                    setModal(false)                                 
                    dispatch(authActions.addEvent(finalVal))
                    dispatch(adminActions.updateModalOpen(false))
                   document.getElementById("profile").reset();                  
                  }
                        
                }
              );
        }}>Confirm</Button></Space></Row> 
        </div>
    </Modal>
   )
}
export default ProfilView