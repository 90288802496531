import React, { useEffect ,useState} from "react";
// import Div from "components/style";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Upload,
  message,
  Card,
  Space,
  Modal,Popover,Spin,Typography,Checkbox
} from "antd";
import classes from './candidateInfo.module.css';
import { useHistory } from "react-router";
import Api from "services/Api";
import { useDispatch,useSelector } from "react-redux";
import { candidateActions } from "./store";
import Notification from "services/Notification";
import moment from "moment";
import OTPModal from "./OTPModal";
import brigoshaLogo from '../../assets/brigoshaLogo.svg'
import addFileIcon from '../../assets/addFileIcon.svg';
import profileIcon from '../../assets/profileIcon.svg.svg'
import {
  WarningOutlined,
  CheckSquareOutlined,
  FileTextOutlined,
  LoadingOutlined,
  CameraOutlined,
  FilePdfOutlined,DownOutlined,CheckOutlined,CloseOutlined, UploadOutlined  
} from "@ant-design/icons";
const { Option } = Select;
const countryCodes = require('country-codes-list')
const myCountryCodesObject = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');

export const NoData = (props) =>{
  const {width} =useWindowDimensions();
  return(
    <>
    <div style={{height:width<700?"20vh":"70vh",width:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:'column', marginTop:20,}}>
      <img src={NoDataImg}></img>
      <div>{props?.text?props?.text:"No Data"}</div>
    </div>
    </>
  )
}
const CandidateInfo = () => {
  const [phone,setPhone]=useState("");
  const {user,candidateId} = useSelector(state => state.auth)
  const {storedFile} = useSelector((state) => state.userCandidate);
  //const {verifyOTP} = useSelector((state) => state.userCandidate)
  const [form] = Form.useForm();
  const [btntLoader, setBtnLoader] = useState(false);
  const [spin,setSpin] = useState(true)
  let [file,setFile] = useState(false)
  let [file1, setFile1] = useState(false)
  const [resumeFile,setResumeFile] = useState(false)
  const {candidateInfo,verifyOTP} = useSelector((state => state.userCandidate));
  const [search,setSearch]=useState('');
    const [open,setOpen]=useState(false);
  const {modalOpen}=useSelector(state=>state.userCandidate);
   const dispatch = useDispatch()
   const [countryCode,setCountryCode]=useState("+91");
   const codes=Object.values(myCountryCodesObject); 

   const [imageUrl, setImageUrl] = useState("");
    const [resumeUrl,setResumeUrl]=useState('');
    const [imgFile,setImgFile] = useState("");
    const [tenthFile,setTenthFile] = useState("");
    const [twelthFile,setTwelthFile] = useState("");
    const [clgFile,setClgFile] = useState("");
    const [resume,setResume]=useState("");
    const [loading,setLoading]=useState(false);
    const [checked,setchecked]=useState(false);

   const { Option } = Select;
   let disabled=false;

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="86">+86</Option>
            <Option value="87">+87</Option>
            <Option value="88">+88</Option>
            <Option value="89">+89</Option>
            <Option value="90">+90</Option>
            <Option value="91">+91</Option>
            <Option value="92">+92</Option>
            <Option value="93">+93</Option>
            <Option value="94">+94</Option>
            <Option value="95">+95</Option>
            <Option value="96">+96</Option>
            <Option value="97">+97</Option>
            <Option value="98">+98</Option>
          </Select>
        </Form.Item>
      ); 

    const imgbeforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";  
      const isLt10M = file.size / 1024 / 1024 < 10;
      // if (!isJpgOrPng) {
      //   message.error("You can only upload JPG/PNG file!");
      // }
       if (!isLt10M) {
        message.error("Image must be smaller than 10MB!");
      }
      else {
        getBase64(file, (url) => {
          setLoading(false);
          setImageUrl(url);
          setImgFile(file);
        });
      }
    }

    const photoBeforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"||file.type === "image/jpg";  
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJpgOrPng) {
        setImageUrl("");
        Notification.error("Error", "You can only upload JPG/PNG/JPEG file!");
        return;
      }
       if (!isLt10M) {
        Notification.error("Error", "Image must be smaller than 10MB!");
      }
      else {
        getBase64(file, (url) => {
          setLoading(false);
          setImageUrl(url);
          setImgFile(file);
        });
      }
      if (allowedFiles.includes(file.name)) {
        // setList(prev => [...prev, file])
        setLoading(false);
          setResumeUrl(url);
          setImgFile(file);
        return false;
    }
    }

    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    };

    const resumeBeforeUpload = (file) => {
      const isJpgOrPng = file.type === "application/docs" || file.type === "application/pdf";  
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJpgOrPng) {
        setResumeUrl("");
        Notification.error("Error", "You can only upload doc/pdf file!");
        return;
      }
       if (!isLt10M) {
        Notification.error("Error", "Image must be smaller than 10MB!");
      }
      else {
        getBase64(file, (url) => {
          setLoading(false);
          setResumeUrl(url);
          setResume(file);
        });
      }
      if (allowedFiles.includes(file.name)) {
        // setList(prev => [...prev, file])
        setLoading(false);
          setResumeUrl(url);
          setResume(file);
        return false;
    }
    }
    
    const TenthBeforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";  
      const isLt10M = file.size / 1024 / 1024 < 1/10;
    
      //  if (!isLt10M) {
      //   message.error("Image must smaller than 100KB!");
      // }
      // else {
      //   if (allowedFiles.includes(file.name)) {
      //     // setList(prev => [...prev, file])
      //     setLoading(false);
      //     return false;
      // }
      // }
      
      // else {
        getBase64(file, (url) => {
          setLoading(false);
          setTenthFile(file);
        });
      // }
      if (allowedFiles.includes(file.name)) {
        // setList(prev => [...prev, file])
        setLoading(false);
        setTenthFile(file);
        return false;
    }
    }

    const TwelthBeforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";  
      const isLt10M = file.size / 1024 / 1024 < 1/10;
    
      //  if (!isLt10M) {
      //   message.error("Image must smaller than 100KB!");
      // }
      // else {
      //   if (allowedFiles.includes(file.name)) {
      //     // setList(prev => [...prev, file])
      //     setLoading(false);
      //     return false;
      // }
      // }
      
      // else {
        getBase64(file, (url) => {
          setLoading(false);
          setTwelthFile(file);
        });
      // }
      if (allowedFiles.includes(file.name)) {
        // setList(prev => [...prev, file])
        setLoading(false);
        setTwelthFile(file);
        return false;
    }
    }

    const ClgBeforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";  
      const isLt10M = file.size / 1024 / 1024 < 1/10;
    
      //  if (!isLt10M) {
      //   message.error("Image must smaller than 100KB!");
      // }
      // else {
      //   if (allowedFiles.includes(file.name)) {
      //     // setList(prev => [...prev, file])
      //     setLoading(false);
      //     return false;
      // }
      // }
      
      // else {
        getBase64(file, (url) => {
          setLoading(false);
          setClgFile(file);
        });
      // }
      if (allowedFiles.includes(file.name)) {
        // setList(prev => [...prev, file])
        setLoading(false);
        setClgFile(file);
        return false;
    }
    }

    const docBeforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";  
      const isLt10M = file.size / 1024 / 1024 < 1/10;
    
      //  if (!isLt10M) {
      //   message.error("Image must smaller than 100KB!");
      // }
      // else {
        getBase64(file, (url) => {       
          setLoading(false);
        });
      //   if (allowedFiles.includes(file.name)) {
      //     // setList(prev => [...prev, file])
      //     setLoading(false);
      //     return false;
      // }
      // }
    }  

  useEffect(() => {
    Api.get("/hiring-candidate/getCandidateById")
    .params({candidateId:candidateId})
    .send((response) => {

      if(typeof response!="undefined"){  
      setSpin(false)
      dispatch(candidateActions.getCandidateInfo(response))
      }
    })
  },[])

  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setchecked(e.target.checked);
  };

   let history = useHistory()
   
   if (candidateInfo.xFile && candidateInfo.xiiFile )
   {
     history.push("/test-instruction")
   }
   const onReset = (type) => {
    dispatch(candidateActions.resetFile(type))
    if(type==="tenthDoc")
    {
      setFile(false)
    }
    else if(type==="twelvthDoc"){
      setFile1(false)
     }
    else {
      setResumeFile(false)
     }
   }
  // const props = (type) => ({ 
  //   name: "file",
  //   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   showUploadList:false,

  //   onChange(info) {
  //     if (info.file.status === "uploading") {
  //      return
  //     }
  //     else{
  //       dispatch(candidateActions.uploadFile({[type]: info.file.originFileObj}))
        
  //       if(type==="twelvthDoc"){
  //       setFile1(true)}
  //       else if(type==="tenthDoc"){
  //         setFile(true)
  //       }
  //       else if (type == "resume") {
  //         setResumeFile(true)
  //       }
  //     }  
  //   },
  // });

  // const onFinish = (values) => {
  //   const value1={
  //     ...values,
  //     xFile:storedFile.tenthDoc,
  //     xiiFile: storedFile.twelvthDoc,
  //     degreeFile:storedFile.resume,
  //     'dateOfBirth': values['dateOfBirth'].format('YYYY-MM-DD'),
  //   }
  //   if((!value1.xFile) && (!value1.xiiFile))
  //   {
  //     let title="please upload  Marksheets"
  //     Notification.error(title)
  //     return
  //   }
  //   if(!value1.xFile )
  //   {  let title="please upload 10th Marksheet"
  //     Notification.error(title)
  //     return
  //   }
  //   else if(!value1.xiiFile)
  //   {
  //     let title="please upload 12th Marksheet"
  //     Notification.error(title)
  //     return
  //   }
  //   else if (!value1.degreeFile)
  //   {
  //      let title="please upload Resume"
  //     Notification.error(title)
  //     return
  //     }
  //   setBtnLoader(true)
    
  //   Api.post("/hiring-candidate/addCandidateInfo")
  //   .upload(value1, (percentCompleted, response) => {  
  //     if (typeof response != "undefined" && percentCompleted === 100) {  
        
  //       setBtnLoader(false)
  //       history.push("/test-instruction")      
  //     }}     
  //   // history.push("/test-instruction")
  //   )  
  // }
  ;
  function disabledDate(current) { 
    // Can not select days before today and today
    return current && current > moment().startOf('day');
  }
  const {initialval}= useSelector((state => state.userCandidate));
  const addCandidateInfoHandler=(values)=>{
    // setEventValChanged(false)
      // setBtnLoader(true)
      console.log("ssssFileee",values)
      if(tenthFile===""|| twelthFile==="" || clgFile===""  || imgFile==="" || resume===""){
          Notification.error("Error", "Please Upload Files in Rquired format")
          return
      }
          const finalVal={
                                ...values,
                                name:candidateInfo.name,
                                email:candidateInfo.email,
                                collegeName:candidateInfo.collegeName,
                                 photo:imgFile,
                                 resume:resume,
                                 xFile:tenthFile,
                                 xiiFile:twelthFile,
                                 degreeFile:clgFile,
                                // xFile:values.xFile,
                                // xiiFile:values.xiiFile,
                                // degreeFile:values.degreeFile,
                                 dateOfBirth:values.dateOfBirth.format('YYYY/MM/DD')
                                };                                                                  
                                Api.patch(`/hiring-candidate/addCandidateInfo/${candidateId}`).uploadAndPatch(finalVal,(percentCompleted, response)=>{
                                  if(response && percentCompleted===100)
                                  {
                                    history.push("/test-instruction") 
                                    setPhone("");                                 
                                  }
                                })
  }
  return (
  <>
  {spin ? (
  <div style={{display:"flex",flexDirection:"column",justifyContent:"center",textAlign:"center",height:"90vh"}}>
    <Spin className="loader" style={{ fontSize: 40 }} />
    </div>)
   : 
  //  (<>{
    candidateInfo?.name&& <>
    {candidateInfo?.candidateType === "Experienced" ?
    <div style={{backgroundColor:"#DFDFDF",padding:"2rem 20%",height:"100vh"}}>
      <div  style={{backgroundColor:"#fff",padding:"1.5rem 3rem",height:"91vh"}}>
        <div style={{display:"flex",flexDirection:"column",width:"100%",justifyContent:"center",textAlign:"center",alignItems:"center"}}>
          <h2 style={{color:"#191919",fontWeight:650}}>Verification</h2>
          <span style={{color:"#5E5E5E",fontWeight:600}}>Validate your details provided below.</span>
        </div>
        <div style={{borderBottom:"1px solid #DFDFDF",width:"100%",marginTop:"1.5rem",paddingBottom:"0.8rem",display:"flex",flexDirection:"row",gap:"0.8rem",alignItems:"center"}}>
          {console.log("sssssCandidateInfo",candidateInfo)}
        {candidateInfo?.photo ? (
            <img
            src={candidateInfo?.photo}
            alt=""
            style={{
              width: "50px",
              height: "53px",
              borderRadius: "50%",
            }}
          />
        ) : (
          <img
            src={profileIcon}
            style={{
              // width: "70px",
              // height: "73px",
              borderRadius: "50%",
            }}
          />
        )}
        <div>
          <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: "1rem", fontWeight: 600, width: '20rem' }}>
            {candidateInfo?.name ?candidateInfo?.name: "NA"}                          
          </Typography.Text>
          {/* <h3>{candidateInfo?.name}</h3> */}
          <a style={{ display: "table-cell",marginLeft:"0.3rem"}}
                        onClick={() => {window.open(candidateInfo.resume)}}
                        target="_blank"
                      >
                        <FilePdfOutlined style={{ fontSize: "0.95rem" }} />
                        <span
                          style={{
                            fontSize: "0.8rem",
                            color: "black",
                            marginLeft: "0.2rem",color:"#0086FF"
                          }}
                        >
                          Click here to view resume
                        </span>
          </a>
        </div>
        </div>
        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"60vh"}}>
        <div>
        <div style={{ display: "flex", width: "100%",justifyContent: 'space-between',paddingRight:"1rem",marginTop:"1rem"}}>
                      <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: "0.9rem", color: "#808080", fontWeight: 600, }}>Mobile number</span>
                          <div style={{ fontSize: "0.9rem", color: "black", fontWeight: 600,textAlign:"start" }}>
                          {candidateInfo?.phone ? candidateInfo?.phone : "NA"}
                          </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: "0.9rem", color: "#808080", fontWeight: 600, }}>E-mail ID</span>
                          <div style={{ fontSize: "0.9rem", color: "black", fontWeight: 600 ,textAlign:"start" }}>
                            {candidateInfo?.email ? candidateInfo?.email : "NA"}
                          </div>
                      </div>
        </div>
                <div style={{ display: "flex", width: "100%",justifyContent: 'space-between',paddingRight:"1rem",marginTop:"1rem"}}>
                <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: "0.9rem", color: "#808080", fontWeight: 600, }}>Job</span>
                          <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: "0.9rem", color: "black", fontWeight: 600, width: '10rem' }}>
                            {candidateInfo?.job?.title ? candidateInfo?.job?.title : "NA"}                          
                          </Typography.Text>
                      </div>
                      <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: "0.9rem", color: "#808080", fontWeight: 600, }}>Experience </span>
                          <div style={{ fontSize: "0.9rem", color: "black", fontWeight: 600 ,textAlign:"start" }}>
                          {candidateInfo?.experienceYears ? `${candidateInfo?.experienceYears} y - ${candidateInfo?.experienceMonths} m` : 0}
                          </div>
                      </div>
                </div>
                <div style={{ display: "flex", width: "100%",justifyContent: 'space-between',paddingRight:"1rem",marginTop:"1rem"}}>
                <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: "0.9rem", color: "#808080", fontWeight: 600, }}>Current company</span>
                          <div style={{ fontSize: "0.9rem", color: "black", fontWeight: 600 ,textAlign:"start" }}>
                          {candidateInfo?.currentCompany ? candidateInfo?.currentCompany : "NA"}
                          </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "flex-start",flexDirection:"column",color: "#808080", fontWeight: 600, width: "50%" }}>
                          <span style={{ fontSize: "0.9rem", color: "#808080", fontWeight: 600, }}>Notice period (Days)</span>
                          <div style={{ fontSize: "0.9rem", color: "black", fontWeight: 600 ,textAlign:"start" }}>
                          {candidateInfo?.noticePeriod ? candidateInfo?.noticePeriod : "NA"}
                          </div>
                      </div>
                </div>
        </div>
        <div>
        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:"2rem"}}>
        <div style={{display:"flex",justifyContent:"flex-start",}}>
            <Checkbox onChange={onChange} style={{fontSize:"0.95rem",fontWeight:500,color:"#5E5E5E"}}>I hereby confirm that the information presented herein is true and verifiable.</Checkbox>
        </div>
        <div style={{display:"flex",justifyContent:"center",}}>
          <Button type="primary" style={{height:"2.3rem",width:"7rem",borderRadius:5,fontWeight:650}}
          disabled={!checked} onClick={()=>{
            history.push("/test-instruction") 
          }}>Submit</Button>
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
    :
   <div>
    <header className={classes.mainHeader}>
      <img src={brigoshaLogo}></img>
    </header>  
    <div className={classes.wrapper}>
       <div className={classes.header}>
                                <h1 style={{marginBottom:"0.5rem"}}>Candidate’s info</h1>
                                <span>Kindly fill in the information below.</span>
                      </div>
    <Form layout="vertical" className={classes.form}  id="candidate" onFinish={addCandidateInfoHandler} initialValues={{candidateInfo}}>                    
                      <div className={classes.eachRow}>
                                <div>
                                <Form.Item  label={<b>NAME </b>} name="name" >
                                            <Input type="text " size="large"  disabled={true} defaultValue={candidateInfo.name} />
                                </Form.Item>                          
                                </div>
                                <div>
                                      <div className={classes.rightCol}>
                                      <Form.Item
                                              name="phone"
                                              label={<b>MOBILE NUMBER</b>}                                            
                                              rules={[{ required: true, message: 'Please input your phone number!' }]}
                                          >
                                            {/* <Input addonBefore={prefixSelector}  size="large"  placeholder="Enter mobile number" onChange={(event)=>{setPhone(event.target.value);}}/> */}
                                            <div  className={`${classes.container} ${disabled?classes.disabled:''}`}>
                                              <Popover trigger='click'
                                                  open={open}
                                                  onOpenChange={(e)=>{
                                                      setOpen(e);
                                                      setSearch('');
                                                  }}
                                                  style={{width:'30vw',border:'2px solid red'}}
                                                      placement="bottomLeft"
                                                      content={
                                                          <div className={classes.dropdown}>
                                                              <div>
                                                                  <Input
                                                                  
                                                                  placeholder="Type to search"
                                                                  type="text"
                                                                  
                                                                  value={search?search:undefined}
                                                                  allowClear={true}
                                                                  onChange={(e)=>{
                                                                      setSearch(e.target.value);
                                                                  }}/>
                                                              </div>                          
                                                                  { (search?codes?.filter(item=>item.toLocaleLowerCase().includes(search.toLocaleLowerCase())):codes)?.map((item,index)=>{
                                                                      return(                            
                                                                          <span style={{cursor:"pointer"}} onClick={()=>{
                                                                              setCountryCode(item.split(':')[1]?.trim());
                                                                              setOpen(prev=>!prev);
                                                                          }}>{item}</span>
                                                                      )
                                                                  })}
                                                                  {search&&codes?.filter(item=>item.toLocaleLowerCase().includes(search.toLocaleLowerCase()))?.length==0&&
                                                                  <NoData/>}
                                                      </div>
                                                      }
                                                      >
                                                          <div className={classes.code}>                 
                                                                      <span>{countryCode}</span>
                                                                      <DownOutlined/>                
                                                          </div>
                                              </Popover>
                                              <Input size="large"  onWheel={(e) => e.target.blur()} type='number' bordered={false} placeholder='Enter mobile number' style={{height:"2.2rem"}}
                                              onChange={(event)=>{setPhone(event.target.value);}}
                                              // value={value?value:undefined}
                                              onKeyDown={(e)=>{
                                                  if (e.key === 'e' || e.key === 'E') {
                                                      e.preventDefault();
                                                    }
                                              }}
                                              />
                                            </div>
                                          </Form.Item>
                                          <div>
                                          <Button type="primary" style={{marginTop:"0.4rem",height:"3rem"}}
                                          disabled={verifyOTP||phone.trim().length!==10}
                                           onClick={()=>{dispatch(candidateActions.updateModalOpen(true))
                                          Api.post("/hiring-candidate/sendotp").params({
                                            candidateId:candidateInfo.id,
                                            phoneNumber:phone,
                                            countryCode:countryCode
                                        }).send(response=>{
                                          if(response)
                                          {                                        
                                          }
                                          })
                                          }} >SEND OTP</Button>
                                          </div>                                                                                 
                                      </div>
                                     {!verifyOTP&&<span style={{color:"#6F6F6F",fontWeight:"400", fontSize:"14px"}}>Please verify your mobile number</span>}
                                     {verifyOTP&&<span style={{color:"green",fontWeight:"400", fontSize:"14px"}}>verified</span>}
                                </div>
                      </div>
                      <div className={classes.eachRow}>
                      <div style={{paddingTop:"1.6rem"}}>
                                <Form.Item  label={<b>EMAIL ID</b>} name="emailId" >
                                            <Input type="text " size="large"   disabled={true}  defaultValue={candidateInfo.email}/>
                                </Form.Item>                                 
                                </div>
                                <div>
                                        <div className={classes.rightCol}>
                                                  <Form.Item  label={<b>10TH PERCENTAGE </b>} name="Xth" rules={[{required: true,message: "Please enter valid input!",}]}>
                                                              <Input type="number" placeholder='Enter cgpa / percentage' min={0} max={100} size="large" 
                                                              pattern="^\d*(\.\d{0,2})?$" step="0.01" onWheel={(e) => e.target.blur()} />
                                                  </Form.Item>                                     
                                                  <Form.Item label="" name="xFile" className={classes.headerDiv} required   multiple={false} rules={[{required: true,message: "Please upload the valid document!",},]}>                                                    
                                                  <div className={classes.buttonDiv}>
                                                      <Upload name="file" showUploadList={true} beforeUpload={TenthBeforeUpload} 
                                                                                  accept=".pdf, .docs,.png,.jpg"
                                                                                  maxCount={1}
                                                                                   listType="text"
                                                                                   
                                                                                  >
                                                                      {/* {  imageUrl ? (<img src={imageUrl} alt="Error" style={{width: "100%",height: "30px",borderRadius: "5%",}}/>) : (<div style={{display:"flex", gap:"0.5rem", alignItems:"center", cursor:"pointer"}}><UploadOutlined/><span>Attach photo</span></div>)} */}
                                                                      {/* <div className={classes.buttonDiv}> */}
                                                                        <Button type="primary">UPLOAD DOCUMENT</Button>

                                                      </Upload> 
                                                      </div>                                               
                                                   </Form.Item>                                    
                                      </div>
                                <span style={{color:"#6F6F6F",fontWeight:"400", fontSize:"14px"}}>Upload Document below 1GB</span>
                                </div>                                                    
                      </div>
                      <div className={classes.eachRow}>
                      <div style={{paddingTop:"1.6rem"}}>
                                <Form.Item  label={<b>COLLEGE NAME </b>} name="college_name" >
                                            <Input type="text " size="large" placeholder='Enter college name' disabled={true}  defaultValue={candidateInfo.collegeName}/>
                                </Form.Item>                                                            
                                </div>
                                <div>
                                          <div className={classes.rightCol}>
                                                      <Form.Item  label={<b>12TH PERCENTAGE  </b>} name="XIIth" rules={[{required: true,message: "Please enter valid input!",}]}>
                                                                  <Input type="number" placeholder='Enter cgpa / percentage' min={0} max={100} size="large" 
                                                                  pattern="^\d*(\.\d{0,2})?$" step="0.01" onWheel={(e) => e.target.blur()} />
                                                      </Form.Item> 
                                                      <Form.Item label="" name="xiiFile" className={classes.headerDiv} required  rules={[{required: true,message: "Please upload the valid document!",},]}>                                                    
                                                      <div className={classes.buttonDiv}>
                                                      <Upload name="file" showUploadList={true} beforeUpload={TwelthBeforeUpload} 
                                                                                  accept=".pdf, .docs,.png,.jpg"
                                                                                  maxCount={1}
                                                                                  >
                                                                      {/* {  imageUrl ? (<img src={imageUrl} alt="Error" style={{width: "100%",height: "30px",borderRadius: "5%",}}/>) : (<div style={{display:"flex", gap:"0.5rem", alignItems:"center", cursor:"pointer"}}><UploadOutlined/><span>Attach photo</span></div>)} */}
                                                                     <Button type="primary">UPLOAD DOCUMENT</Button>
                                                      </Upload> 
                                                      </div>                                               
                                                      </Form.Item>
                                        </div>
                                <span style={{color:"#6F6F6F",fontWeight:"400", fontSize:"14px"}}>Upload Document below 1GB</span>
                                </div>                                                
                      </div>
                      <div className={classes.eachRow}>
                                <div style={{paddingTop:"1.6rem"}}>
                                <Form.Item name="dateOfBirth" label={<b>DATE OF BIRTH </b>}
                                  rules={[
                                    {
                                      type: "object",
                                      required: true,
                                      message: "Please select date!",
                                    },
                                  ]}
                                >
                                  <DatePicker format={"YYYY-MM-DD"} disabledDate={(current) =>{return current && current > moment().startOf('day');}} style={{width:"100%",height:"3.5rem"}}/>
                                </Form.Item>
                                </div>
                                <div>
                                  <div className={classes.rightCol}>                              
                                <Form.Item  label={<b>CGPA </b>} name="cgpa" rules={[{required: true,message: "Please select valid input!",}]}>
                                            <Input type="number" placeholder='Enter cgpa / percentage' min={0} max={10} size="large"
                                            pattern="^\d*(\.\d{0,2})?$" step="0.01" onWheel={(e) => e.target.blur()} />
                                </Form.Item> 
                                <Form.Item label="" name="degreeFile" className={classes.headerDiv} required  rules={[{required: true,message: "Please upload the valid document!",},]} multiple="false">                                                 
                                                      <div className={classes.buttonDiv}> 
                                                      <Upload name="file" showUploadList={true} beforeUpload={ClgBeforeUpload} 
                                                                                  accept=".pdf, .docs,.png,.jpg"
                                                                                  maxCount={1}
                                                                                  >
                                                                      {/* {  imageUrl ? (<img src={imageUrl} alt="Error" style={{width: "100%",height: "30px",borderRadius: "5%",}}/>) : (<div style={{display:"flex", gap:"0.5rem", alignItems:"center", cursor:"pointer"}}><UploadOutlined/><span>Attach photo</span></div>)} */}
                                                                     <Button type="primary">UPLOAD DOCUMENT</Button>
                                                      </Upload>  </div>                                            
                                </Form.Item>
                                </div>
                                <span style={{color:"#6F6F6F",fontWeight:"400", fontSize:"14px"}}>Upload  Document below 1GB</span>                                
                                </div>                           
                                <Modal visible={modalOpen&&!verifyOTP}  footer={null} width="850px" centered closable={true} maskClosable={true} onCancel={()=>dispatch(candidateActions.updateModalOpen(false))}>
                                    <OTPModal candidateId={candidateInfo.id} phone={phone} calledFrom="CANDIDATE_INFO"/>
                                </Modal>                       
                      </div>
                      <div className={classes.eachRow}>
                              <div>
                              <Form.Item label={<b>PHOTO</b>} name="photo" className={classes.headerDiv} required  rules={[{required: true,message: "Please upload a photo!",},]}>
                                <div className={classes.dashedBorder}>
                                                    <img src={addFileIcon}></img>                                                    
                                                      <Upload.Dragger style={{backgroundColor:"#fff",border:"none"}} name="file" showUploadList={imageUrl?true:false} beforeUpload={photoBeforeUpload} 
                                                                                  accept=".png,.jpg"
                                                                                  maxCount={1}
                                                                                  onRemove={()=>setImageUrl('')}
                                                                                  >
                                                                      {/* {  imageUrl ? (<img src={imageUrl} alt="Error" style={{width: "100%",height: "30px",borderRadius: "5%",}}/>) : (<div style={{display:"flex", gap:"0.5rem", alignItems:"center", cursor:"pointer"}}><UploadOutlined/><span>Attach photo</span></div>)} */}
                                                                      <div style={{fontSize:"1rem"}}><b style={{color:"#4DAAFF", marginLeft:"0.5rem"}}>Click to Upload your file here</b> </div>
                                                      </Upload.Dragger>
                                     </div>                                                
                                </Form.Item>                          
                              </div>
                              <div>
                              <Form.Item label={<b>RESUME</b>} name="resume" className={classes.headerDiv} required  rules={[{required: true,message: "Please upload the  resume!",},]}>
                                <div className={classes.dashedBorder}>
                                                    <img src={addFileIcon}></img>                                                      
                                                      <Upload.Dragger style={{backgroundColor:"#fff",border:"none",}} name="file" showUploadList={resumeUrl?true:false} beforeUpload={resumeBeforeUpload} 
                                                                                  accept=".pdf, .docs"
                                                                                  maxCount={1}
                                                                                  onRemove={()=>setResumeUrl('')}
                                                                                  >
                                                                      {/* {  imageUrl ? (<img src={imageUrl} alt="Error" style={{width: "100%",height: "30px",borderRadius: "5%",}}/>) : (<div style={{display:"flex", gap:"0.5rem", alignItems:"center", cursor:"pointer"}}><UploadOutlined/><span>Attach photo</span></div>)} */}
                                                                      <div style={{fontSize:"1rem"}}><b style={{color:"#4DAAFF", marginLeft:"0.5rem"}}>Click to Upload your file here</b> </div>
                                                      </Upload.Dragger>
                                     </div>                                            
                                </Form.Item> 
                              </div>
                      </div>
                                  <div className={classes.nextButton}>
                                            <Button htmlType="submit" 
                                               loading={btntLoader} 
                                                disabled={!verifyOTP||!resumeUrl||!imageUrl}
                                               >NEXT</Button>
                                </div>                 
                      <div>                        
                      </div>                   
    </Form>
    </div>
    </div>
    }
    </>
    // }</>)
  }
    </>
    
  );
};

export default CandidateInfo;
