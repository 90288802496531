
import React, { useEffect,useState } from 'react';
import Api from '../../services/Api';
import { SmileOutlined } from '@ant-design/icons';
import { Button, Modal, Result,Space,Row,Col,Typography } from 'antd';
const {Title} = Typography
function CollegeInvitationResposne() {
    const [state,setState] = useState(false)
    const[resultvis,setVis] = useState(true)
    const func= () =>{
        let params = (new URL(document.location));
         let id=params?.pathname?.split("/")
         let status=params.search
       
      
        if (id[3]&&status) {
            Api.patch(`/hiring-publicapi/updateCollegeStatus/${id[3]}/${status}`).send(res => {
           
                setVis(false)
                setState(false)
            })
        }
    }

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let status=params.get('status')
        if(status==="Rejected"){
         setState(true)
         return
        }
        

       func()

    }, [])

    return (<>
    <Modal visible={state} 
    footer={false}
    width="1000px">
        <Row justify='center' ><Title level={3}>Are you sure you want to reject?</Title></Row>
        <Row justify='center' style={{marginTop:"10px"}}>
        <Space>
            <Button type='primary' onClick={() =>{
                func()
            }}>Submit</Button>
            <Button onClick={() =>{
                window.open("https://mail.google.com/mail/u/0/#inbox", "_self", "");
                
            }}>Cancel</Button>
        </Space></Row>
    </Modal>
    {resultvis===false ?
        <Result
            icon={<SmileOutlined />}
            title="Thank you for response!"
        extra={<div>
            <div>For more info please visit <a href="https://www.brigosha.com/">Brigosha.com</a></div>
        </div>}
        />:""}
   </> )
}

export default CollegeInvitationResposne;