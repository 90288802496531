import React, { useEffect, useState } from 'react';
import { 
     Row,
     Col, 
     Input, 
     Modal, 
     Select, 
     Form, 
     TreeSelect,
     Button
 } from "antd";
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { TreeNode } from 'antd/lib/tree-select';
import Api from 'services/Api';
import classes from './editPool.module.css';
import { Notification } from "services";
import { useSelector } from 'react-redux';

const EditPool= (props) =>{
    
   const[poolsSet,setPools] = useState([])
   const[panelistIds,setPanelistIds] = useState(props.editPools.pools.map(item=>item.panelist.map(item2=>item2.id)));
 
   const[editLoader,setEditLoader]= useState(false)
    props?.editPools?.pools.sort(function(a, b){return a.round - b.round})
    setTimeout(() =>{
        setPools(props.editPools.pools)
    },1000)
 
     const handleCancel = () => {
         setIsCreatePanelistPool(false);
       };
     const [array,setArray]=useState([])
     const [jobData, setJobData]=useState([])
     const [vacancy,setVacancy]=useState(''); 
     const [panelist,setPanelist]=useState(poolsSet)
     const [search,setsearch]=useState("")
     const [jobExperience,setJobExperience]=useState("")
         
    const onEdit = () =>{
//    setEditLoader(true)
    let data=[]
    poolsSet.sort(function(a, b){return a.round - b.round})
    poolsSet.forEach((element,index) => {
        data.push(element.panelist)
    })
  
    let panelistPoolArray=[]
    let map={}
   
    let objs={
        poolId:"",
        panelistIds:[],
        deleted:[]
    }
   for (let i=0 ;i<panelistIds.length;i++){
     map={}
    for(let ids of panelistIds[i]){      
        
           
            if(typeof(ids)==="string"){
                data[i].forEach((element) =>{
                    if(ids===element.name){
                        objs.panelistIds.push(element.id)
                        // props?.refresh()
                    }            
                })
            }
            else{
             objs.panelistIds.push(ids)
            }
          
        }
        objs.poolId=poolsSet[i].id
        objs.panelistIds.forEach((item) =>{
         map[item]=true
        })
     
        data[i].forEach((item) =>{
            console.log('aaa',map[item.id],item.id)
            if(map[item.id]!==true){
                objs.deleted.push(item.id)
            }
        })
        panelistPoolArray.push(objs)
        objs={
            poolId:"",
            panelistIds:[],
            deleted:[]
        }
    }


    for(let i =0;i<panelistPoolArray?.length;i++){
        if(panelistPoolArray[i]?.panelistIds?.length===0){
            console.log("panelistssss", )
            return Notification.error("error",`please Add panelist for round ${i+1}`)
        }
    }
   
    Api.patch(`/hiring-panelist/updateMultiplePool`)
    .params({poolData:panelistPoolArray})
        .send((response)=>{
            if(response.show.type === "error"){
                // Notification.error("Same Panelist Should Not Take Multiple Rounds");
            }
            else if(response)
            props.setEditModal(false)
            setEditLoader(false)
            props?.refresh()
        }) 
}  
 useEffect(() =>{
    Api.get("/hiring-panelist/getjobRounds")
    .params({jobExp:jobExperience!=="" ? jobExperience :props.editPools.jdtype,search:search})
    .send((response)=>{
        let value = [];
        let list = [];
        let list1 =[];
if (response !== undefined){
list = response?.jobs?.map((element)=>{value.push(element);return element;})
props.refresh()
setJobData(list);
setPanelist(response?.panelist);}
}) 
 },[jobExperience])
    
     return(
         <>      
                   <Modal 
                    centered
                     style={{textAlign:'center'}} 
                     visible={props.editModal}
                     onCancel={() =>{props.setEditModal(false)
                        
                    }}
                     width="40rem"
                     footer={null}
                     >
                         <div style={{display:"flex",flexDirection:"column",margin:"1.5rem"}}>
                                <h1 style={{fontFamily:"Open Sans", fontWeight:"bold", color:"#161616",fontSize:"1.5rem"}}>Edit Pool of Panelist</h1>
                                <Form  layout="vertical" autoComplete="off"  style={{fontFamily:"Open Sans", fontWeight:"bold", color:"#161616"}}>   
                                        <div  className={classes.firstRow}>
                                            <Form.Item name="jdType" label="JD TYPE"
                                            >
                                                <Select
                                                disabled={true}
                                                size='large'
                                                    style={{width:"100%"}}
                                                    dropdownStyle={{
                                                    maxHeight:400,
                                                    overflow:"auto",
                                                    }}
                                                    defaultValue={props.editPools.jdtype}
                                                    placeholder="Select JD type"
                                                    onChange={(val)=>{
                                                    setJobExperience(val)
                                                    }}
                                                >
                                                    <Option value="Fresher">Fresher</Option>
                                                    <Option value="Experienced">Experience</Option>
                                                </Select>
                                            </Form.Item>                                  
                                            <Form.Item name="job_vacancy" label="JOB VACANCY">                             
                                                <Select  
                                                disabled={true} style={{width:"20rem"}}
                                                        size='large'
                                                        defaultValue={props.editPools.jobVacancy}
                                                        showSearch
                                                        placeholder="Select"
                                                        optionFilterProp="children"
                                                        onChange={(e)=>{setVacancy(e)}}
                                                        onSearch={(e)=>{}}
                                                        allowClear
                                                    >
                                                        {jobData?.map((val,index)=>{
                                                            return(
                                                                <Option value={val?.title} key={val?.id}>{val?.title}</Option>
                                                                )
                                                        })}
                                                    </Select>
                                            </Form.Item>
                                        </div> 

                                        <div style={{maxHeight:"25vw",display:"flex",overflowY:"auto",flexDirection:"column"}}>
                                                <Form.Item rules={[{required:true,message:"Please Select the panelist!"}]} name="pools" label={<h2 style={{fontFamily:"Open Sans", fontWeight:"bold", color:"#161616"}}>Panelist Pool</h2>}>
                                                {poolsSet.map((element,index) =>{
                                                    let arr=element.panelist.map(elements =>{
                                                        return elements.name
                                                    })
                                                    return (<>
                                                    <Row style={{marginTop:"1rem"}}>
                                                        <p>Round : {element.round}</p></Row>
                                                        <div  style={{display:"flex", flexDirection:"row",width:"100%"}} >
                                                        <div style={{display:"flex", flexDirection:"column",width:"100%",justifyContent:"left"}}>
                                                            <Select
                                                            size='large'
                                                            showSearch
                                                            dropdownStyle={{
                                                            maxHeight: 400,
                                                            overflow: "auto",
                                                            }}
                                                            placeholder="Please select"
                                                            allowClear
                                                            mode='multiple'
                                                            optionFilterProp="children"
                                                            defaultValue={arr}  
                                                            onChange={(value) =>{
                                                                    setPanelistIds(panelistIds.map((item2,index2)=>{
                                                                        if(index===index2)
                                                                        {
                                                                            return value;
                                                                        }
                                                                        else
                                                                        {
                                                                            return item2;
                                                                        }
                                                                    }));
                                                            }}
                                                            >
                                                                {panelist.map((element) =>{
                                                                    return <Select.Option value={element.id} >{element.name}</Select.Option>
                                                                })}
                                                            </Select>
                                                            <Row>
                                                            {panelistIds[index].length===0&&<p style={{color:"red",marginTop:"0.5rem"}}>Please select the panelist!</p>}
                                                            </Row>
                                                            </div>
                                                &nbsp; 
                                                {/* <Button  size="large" style={{paddingLeft:"0.5rem"}} loading={editLoader} onClick={ () =>{                                      
                                                        if(panelistIds[index].length!==0)
                                                        {
                                                            onEdit(index)
                                                            
                                                        } 
                                                    }}> <EditOutlined /></Button> */}
                                                        </div>
                                                    </>)
                                                })}
                                                </Form.Item>
                                                <Button loading={editLoader} disabled={panelistIds[0]?.length === 0 ||(panelistIds.length >1?panelistIds[1]?.length === 0:"") ||
                                                (panelistIds.length >2?panelistIds[2]?.length === 0 : "")  || (panelistIds.length >3 ? panelistIds[3]?.length === 0:"") || 
                                                (panelistIds.length >4 ? panelistIds[4].length === 0:"") ? true : false} 
                                                type='primary' style={{width:"20%",margin:"auto"}} onClick={() =>{
                                                    onEdit()
                                                }}>Save</Button>

                                    </div>
                                </Form>
                     </div>
                </Modal>              
            </>
    )
 }
export default EditPool;