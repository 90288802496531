
import { Api, Notification } from "services";

export const ADMIN_DETAILS_GET = "ADMIN_DETAILS_GET";
export const USER_DETAILS_GET = "USER_DETAILS_GET";
export const ADMIN_DETAILS_ADD = "ADMIN_DETAILS_ADD";
export const ADMIN_DETAILS_EDIT = "ADMIN_DETAILS_EDIT";
export const ADMIN_DETAILS_DELETE = "ADMIN_DETAILS_DELETE";

export const AGENCY_DETAILS_GET = "AGENCY_DETAILS_GET";
export const AGENCY_DETAILS_ADD = "AGENCY_DETAILS_ADD";
export const AGENCY_DETAILS_EDIT = "AGENCY_DETAILS_EDIT";
export const AGENCY_DETAILS_DELETE = "AGENCY_DETAILS_DELETE";

export const SCREEN_TYPE = "SCREEN_TYPE";
export const ADMIN_DETAILS_MODAL = "ADMIN_DETAILS_MODAL";
export const ADMIN_DETAILS_LOADER = "ADMIN_DETAILS_LOADER";
export const IS_ADDING_ADMIN = "IS_ADDING_ADMIN";
export const ADMIN_EDITING_ID = "ADMIN_EDITING_ID";
export const ADMIN_TABLE_LOADER = "ADMIN_TABLE_LOADER";
export const SEARCH_VALUE = "SEARCH_VALUE";
export const ADMIN_CLEAR = "ADMIN_CLEAR";
export const USER_DETAIL_LISTS = "USER_DETAIL_LISTS";

export const AGENCY_DETAILS_MODAL = "AGENCY_DETAILS_MODAL";
export const AGENCY_VIEW_MODAL = "AGENCY_VIEW_MODAL";
export const AGENCY_DETAILS_LOADER = "AGENCY_DETAILS_LOADER";
export const IS_ADDING_AGENCY = "IS_ADDING_AGENCY";
export const AGENCY_EDITING_ID = "AGENCY_EDITING_ID";
export const AGENCY_TABLE_LOADER = "AGENCY_TABLE_LOADER";
export const AGENCY_CLEAR = "AGENCY_CLEAR";

export const ADMIN_NAME = "ADMIN_NAME";
export const ADMIN_EMAIL = "ADMIN_EMAIL";
export const ADMIN_PHONE = "ADMIN_PHONE";
export const ADMIN_DESIGNATION = "ADMIN_DESIGNATION";
export const ADMIN_ROLE = "ADMIN_ROLE";
export const ADMIN_DETAILS = "ADMIN_DETAILS";

export const AGENCY_NAME = "AGENCY_NAME";
export const AGENCY_LOCATION = "AGENCY_LOCATION";
export const AGENCY_OTHER_DETAILS = "AGENCY_OTHER_DETAILS";
export const AGENCY_PRIMARY_CONTACT = "AGENCY_PRIMARY_CONTACT";
export const AGENCY_DETAILS = "AGENCY_DETAILS";

export const AGENCY_USER_NAME = "AGENCY_USER_NAME";
export const AGENCY_USER_EMAIL = "AGENCY_USER_EMAIL";
export const AGENCY_USER_PHONE = "AGENCY_USER_PHONE";

export const AGENCY_USER_ADD = "AGENCY_USER_ADD";
export const AGENCY_USER_DELETE = "AGENCY_USER_DELETE";

export const userFormat = {
  name: "",
  email: "",
  phone: "",
  designation: "",
  role: [],
};

export const agencyUserFormat = {
  name: "",
  email: "",
  phone: "",
  primaryContact: false,
  role: ["Agency"],
};

export const agencyInfoFormat = {
  agencyName: "",
  location: "",
  otherDetails: "",
  users: [agencyUserFormat],
};

const handleAdminCommonCode = (dispatch) => {
  dispatch(performOperation(ADMIN_DETAILS_MODAL, false));
  dispatch(updateInputField(ADMIN_DETAILS, userFormat));
};

export const handleAdmin = () => {
  return (dispatch, getState) => {
    const { adminUser, isAddingAdmin, adminEditingId } = getState().createUser;
    dispatch(performOperation(ADMIN_DETAILS_LOADER, true));
    if (isAddingAdmin) {
      return Api.post("/hiring-users")
        .params(adminUser)
        .send((response, error) => {
          if (response !== undefined && response.user !== undefined) {
            dispatch(adminDetailsAdd(response.user.id, response.user));
            handleAdminCommonCode(dispatch);
          }
          dispatch(performOperation(ADMIN_DETAILS_LOADER, false));
        });
    } else {
      return Api.patch(`/hiring-users/${adminEditingId}`)
        .params(adminUser)
        .send((response, error) => {
          if (response !== undefined) {
            dispatch(adminDetailsEdit(adminEditingId, response.user));
            handleAdminCommonCode(dispatch);
            if(response.show.type==="error")
            {
              getAdmins(1,10);
              
              // console.log("WHAT IS THE ERROR",response.show.type);
            
            }
          }
         
          dispatch(performOperation(ADMIN_DETAILS_LOADER, false));
        });
    }
  };
};

export const handleAdminDelete = (id) => {
  return (dispatch) => {
    dispatch(performOperation(ADMIN_TABLE_LOADER, true));
    return Api.delete(`/hiring-users/${id}`).send((response, error) => {
      if (response !== undefined) {
        dispatch(adminDetailsDelete(id));
      }
      dispatch(performOperation(ADMIN_TABLE_LOADER, false));
    });
  };
};

export const showAdminEditingModal = (record) => {
  return (dispatch, getState) => {
    dispatch(performOperation(IS_ADDING_ADMIN, false));
    dispatch(updateInputField(ADMIN_DETAILS, record));
    dispatch(performOperation(ADMIN_EDITING_ID, record.id));
  };
};
export const getAdmins = (page, limit) => {
  if (!page ) {
    page ="";
  }
  if(!limit)
  {
    limit=""
  }
  return (dispatch, getState) => {
    const { searchValue } = getState().createUser;
    dispatch(performOperation(ADMIN_TABLE_LOADER, true));
    let userUrl= `/hiring-users?search=${searchValue}&page=${page}&limit=${limit}`;
    if(searchValue)
    {
      userUrl= `/hiring-users?search=${searchValue}`;
    }
    return Api.get(
      `${userUrl}`
      ).send((response, error) => {
      if (response !== undefined) {
        dispatch(adminDetailsGet(response));
        // dispatch(performOperation(SCREEN_TYPE, "Admin"))
      }
      // dispatch(performOperation(SEARCH_VALUE, ""));
      dispatch(performOperation(ADMIN_TABLE_LOADER, false));
    });
  };
};

export const getUserDetails = () => {
  return (dispatch, getState) => {

    return Api.get(`/hiring-users/resourceUsers?all=true`).send(
      (response, error) => {
        if (response !== undefined) {
          dispatch(userDetailsGet(response));
        }
      }
    );
  };
};

//code related to agency

export const getAgencies = (page,limit) => {
  if (!page ) {
    page = 1;
    
  }
  if(!limit)
  {
    limit=25
  }
  return (dispatch, getState) => {
    const { searchValue } = getState().createUser;
    dispatch(performOperation(AGENCY_TABLE_LOADER, true));
    return Api.get(`/hiring-users/agency?status=Approved&search=${searchValue}&page=${page}&limit=${limit}`).send(
      (response, error) => {
        if (response !== undefined) {
          dispatch(agencyDetailsGet(response.agency));
        }
        // dispatch(performOperation(SEARCH_VALUE, ""));
        dispatch(performOperation(AGENCY_TABLE_LOADER, false));
      }
    );
  };
};

const differenceInAgencyInfoCommonCode = (usersOne, usersTwo, isDeleted) => {
  return usersOne.reduce((finalArray, user) => {
    const index = isDeleted
      ? usersTwo.findIndex((x) => x.id === user.id)
      : usersTwo.findIndex(
          (x) =>
            x.name === user.name &&
            x.email === user.email &&
            x.phone === user.phone &&
            x.primaryContact === user.primaryContact
        );
    if (index != -1) return finalArray;
    else return [...finalArray, { ...user }];
  }, []);
};

const differenceInAgencyInfo = (userInfo, agencyInfo) => {
  const users = differenceInAgencyInfoCommonCode(
    agencyInfo.users,
    userInfo.users,
    false
  );
  const deleted = differenceInAgencyInfoCommonCode(
    userInfo.users,
    agencyInfo.users,
    true
  ).map((user) => user.id);
  return { ...agencyInfo, users, deleted };
};

const handleAgencyCommonCode = (dispatch) => {
  dispatch(performOperation(AGENCY_DETAILS_MODAL, false));
  dispatch(updateInputField(AGENCY_DETAILS, agencyInfoFormat));
};

export const handleAgency = () => {
  return (dispatch, getState) => {
    const { userInfo, agencyInfo, isAddingAgency, agencyEditingId } =
      getState().createUser;
    if (
      agencyInfo.users.findIndex((user) => user.primaryContact === true) === -1
    ) {
      Notification.error("error", "Select the primary contact");
    } else {
      dispatch(performOperation(AGENCY_DETAILS_LOADER, true));
      if (isAddingAgency) {
        return Api.post("/hiring-users/agency")
          .params({ ...agencyInfo })
          .send((response, error) => {
            if (response !== undefined && response.agency !== undefined) {
              dispatch(
                agencyDetailsAdd(response.agency.id, {
                  ...response.agency,
                  users: response.users,
                })
              );
              handleAgencyCommonCode(dispatch);
            }
            dispatch(performOperation(AGENCY_DETAILS_LOADER, false));
          });
      } else {
        const patchAgencyInfo = differenceInAgencyInfo(userInfo, agencyInfo);
        return Api.patch(`/hiring-users/agency/${agencyEditingId}`)
          .params({ ...patchAgencyInfo })
          .send((response, error) => {
            dispatch(performOperation(AGENCY_DETAILS_LOADER, false));
            if (response !== undefined) {
              dispatch(
                agencyDetailsEdit(agencyEditingId, {
                  ...response.agency,
                  users: response.users,
                })
              );
              handleAgencyCommonCode(dispatch);
            }
          });
      }
    }
  };
};

export const showAgencyEditingModal = () => {
  return (dispatch, getState) => {
    const { agencyInfo } = getState().createUser;
    dispatch(performOperation(IS_ADDING_AGENCY, false));
    dispatch(performOperation(AGENCY_EDITING_ID, agencyInfo.id));
  };
};

export const handleAgencyDelete = (id) => {
  return (dispatch, getState) => {
    dispatch(performOperation(AGENCY_TABLE_LOADER, true));
    return Api.delete(`/hiring-users/agency/${id}`).send((response, error) => {
      if (typeof response != undefined) {
        dispatch(agencyDetailsDelete(id));
        dispatch(updateInputField(AGENCY_DETAILS, agencyInfoFormat));
      }
      dispatch(performOperation(AGENCY_TABLE_LOADER, false));
    });
  };
};

export const adminDetailsGet = (value) => ({
  type: ADMIN_DETAILS_GET,
  value,
});

export const userDetailsGet = (value) => ({
  type: USER_DETAILS_GET,
  value,
});

export const adminDetailsAdd = (id, admin) => ({
  type: ADMIN_DETAILS_ADD,
  id,
  admin,
});

export const adminDetailsEdit = (id, admin) => ({
  type: ADMIN_DETAILS_EDIT,
  id,
  admin,
});

export const adminDetailsDelete = (id) => ({
  type: ADMIN_DETAILS_DELETE,
  id,
});

export const updateInputField = (inputFieldType, value) => ({
  type: inputFieldType,
  value,
});

export const updateAgencyUsersInputField = (inputFieldType, index, value) => ({
  type: inputFieldType,
  value,
  index,
});

export const performOperation = (operationType, value) => ({
  type: operationType,
  value,
});

export const agencyUserAdd = () => ({
  type: AGENCY_USER_ADD,
});

export const agencyUserDelete = (index) => ({
  type: AGENCY_USER_DELETE,
  index,
});

export const agencyDetailsGet = (value) => ({
  type: AGENCY_DETAILS_GET,
  value,
});

export const agencyDetailsAdd = (id, agency) => ({
  type: AGENCY_DETAILS_ADD,
  id,
  agency,
});

export const agencyDetailsEdit = (id, agency) => ({
  type: AGENCY_DETAILS_EDIT,
  id,
  agency,
});

export const agencyDetailsDelete = (id) => ({
  type: AGENCY_DETAILS_DELETE,
  id,
});

export const adminOperationType = {
  ADMIN_DETAILS_MODAL: ADMIN_DETAILS_MODAL,
  ADMIN_DETAILS_LOADER: ADMIN_DETAILS_LOADER,
  IS_ADDING_ADMIN: IS_ADDING_ADMIN,
  ADMIN_EDITING_ID: ADMIN_EDITING_ID,
  ADMIN_TABLE_LOADER: ADMIN_TABLE_LOADER,
  SCREEN_TYPE: SCREEN_TYPE,
  SEARCH_VALUE: SEARCH_VALUE,
  ADMIN_CLEAR: ADMIN_CLEAR,
  USER_DETAIL_LISTS: USER_DETAIL_LISTS,
};

export const agencyOperationType = {
  AGENCY_DETAILS_MODAL: AGENCY_DETAILS_MODAL,
  AGENCY_DETAILS_LOADER: AGENCY_DETAILS_LOADER,
  IS_ADDING_AGENCY: IS_ADDING_AGENCY,
  AGENCY_EDITING_ID: AGENCY_EDITING_ID,
  AGENCY_TABLE_LOADER: AGENCY_TABLE_LOADER,
  AGENCY_VIEW_MODAL: AGENCY_VIEW_MODAL,
  AGENCY_CLEAR: AGENCY_CLEAR,
};

export const adminInputFieldType = {
  ADMIN_NAME: ADMIN_NAME,
  ADMIN_EMAIL: ADMIN_EMAIL,
  ADMIN_PHONE: ADMIN_PHONE,
  ADMIN_DESIGNATION: ADMIN_DESIGNATION,
  ADMIN_ROLE: ADMIN_ROLE,
};

export const agencyInputFieldType = {
  AGENCY_NAME: AGENCY_NAME,
  AGENCY_LOCATION: AGENCY_LOCATION,
  AGENCY_OTHER_DETAILS: AGENCY_OTHER_DETAILS,
};

export const agencyUsersInputFieldType = {
  AGENCY_USER_NAME: AGENCY_USER_NAME,
  AGENCY_USER_EMAIL: AGENCY_USER_EMAIL,
  AGENCY_USER_PHONE: AGENCY_USER_PHONE,
};

export const adminOperationTypeHash = {
  ADMIN_DETAILS_MODAL: "adminDetailsModal",
  ADMIN_DETAILS_LOADER: "adminDetailsLoader",
  IS_ADDING_ADMIN: "isAddingAdmin",
  ADMIN_EDITING_ID: "adminEditingId",
  ADMIN_TABLE_LOADER: "adminTableLoader",
  SCREEN_TYPE: "screenType",
  SEARCH_VALUE: "searchValue",
  ADMIN_CLEAR: "adminUsers",
  USER_DETAIL_LISTS: "userDetailLists",
};

export const agencyOperationTypeHash = {
  AGENCY_DETAILS_MODAL: "agencyDetailsModal",
  AGENCY_VIEW_MODAL: "agencyViewModal",
  AGENCY_DETAILS_LOADER: "agencyDetailsLoader",
  IS_ADDING_AGENCY: "isAddingAgency",
  AGENCY_EDITING_ID: "agencyEditingId",
  AGENCY_TABLE_LOADER: "agencyTableLoader",
  AGENCY_CLEAR: "agencies",
};

export const adminInputFieldTypeHash = {
  ADMIN_NAME: "name",
  ADMIN_EMAIL: "email",
  ADMIN_PHONE: "phone",
  ADMIN_DESIGNATION: "designation",
  ADMIN_ROLE: "role",
};

export const agencyInputFieldTypeHash = {
  AGENCY_NAME: "agencyName",
  AGENCY_LOCATION: "location",
  AGENCY_OTHER_DETAILS: "otherDetails",
};

export const agencyUsersInputFieldTypeHash = {
  AGENCY_USER_NAME: "name",
  AGENCY_USER_EMAIL: "email",
  AGENCY_USER_PHONE: "phone",
};
