import classes from './itemValue.module.css'

const ItemValue=(props)=>{
    return(
        <ul key={props.key} className={classes.itemVal}  onClick={()=>{props?.statusChange(props?.colorStatus,props?.item);
            props?.setOpen(false);
            }}>
                        <li 
                                    onClick={()=>{props?.statusChange(props?.colorStatus,props?.item);
                                    props?.setOpen(false);
                                    }}
                                    >
                                    {props?.item}
                        </li>
        </ul>
    )
}
export default ItemValue;