import React from "react";
import classes from './interview.module.css';
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Tabs,
    Space,
    Typography,
    DatePicker,
    Checkbox,
    Popover,
    Badge,
    Radio,
    Timeline,
    Card,
    List,
  } from "antd";
import { SearchOutlined,DownOutlined,CloseOutlined,CheckCircleOutlined,CloseCircleOutlined,ExclamationCircleOutlined,ClockCircleOutlined } from "@ant-design/icons";
import filter from "../../assets/filter.png";
import Download from '../../assets/download.png';
import searchIcon from '../../assets/searchIcon.png';
import iconInterview from "../../assets/InterviewFresherIcons.svg";
import { Color, Notification } from "services";
import Api from "services/Api";
import { useState,useEffect } from "react";
import moment from "moment";
import Filter from "./filter";
import { useDispatch, useSelector } from "react-redux";

import FilterUtility from "components/filterUtility";
import { title } from "process";

const { TabPane } = Tabs;
const {Title} =Typography
const dateFormat = "YYYY-MM-DD";
const postDateFormat = "YYYY-MM-DD";
const filterObj = new Filter();
const { RangePicker } = DatePicker;


const InterviewExp = () => {

  const [loader, setLoader] = useState(false)
  const [closeBtn, setCloseBtn] = useState(true)
  const [filterState,setFilterState] = useState({
    "status":false,
    "jobTitle":false,
    "institute":false,
    "agencyName":false,
   });
   const [interviewLists,setInterview] = useState([])
   const [, updateState] = React.useState();
   const forceUpdate = React.useCallback(() => updateState({}), []);
   const [searchVal, setSearchVal] = useState("")
  //  const [dateVal, setDateVal] = useState("")
   const [startDate, setStartDate] = useState("")
   const [endDate, setEndDate] = useState("")
  //  const [candidateStatus,setCandidateStatus] = useState([{}]) 
  
   const [jobFilterTitle, setJobFilterTitle] = useState(0);
   const [statusFilterTitle, setStatusFilterTitle] = useState("");
   const [agencyFilterTitle, setAgencyFilterTitle] = useState(0);
   const [statusFilterList, setStatusFilterList] = useState([]);
   const [statusFilter, setStatusFilter] = useState("");
   const [jobFilterList, setJobFilterList] = useState([]);
   const [jobFilter, setJobFilter] = useState("");

   const [agencyFilterList, setAgencyFilterList] = useState([]);

   const [agencyFilter, setAgencyFilter] = useState("");
   const [pageChange, setPageChange] = useState("");
   const [pageSize, setPageSize] = useState("");
   const [filterDetails,setFilterDetails] = useState(false);
   const [schedule, setSchedule] = useState(0);
   const [reset,setReset]=useState(false);
   const [statusCheck,setStatusCheck]=useState(new Array(10).fill(false));
   const [jobTitleCheck,setJobTitleCheck]=useState(new Array(10).fill(false));
   const [agencyCheck,setAgencyCheck]=useState(new Array(10).fill(false));
   const [filterShows, setFilterShows] = useState([
    {title : "Status", value : "status"},
    {title : "JobTitle", value : "jobTitle"},
    {title : "Training Institute", value : "institute"},
    {title : "AgencyName", value : "agencyName"},
  ]);


   const badgeHash = {
    Shortlisted: "processing",
    Skipped: "processing",
    Scheduled: "warning",
    Selected: "success",
    "Not Selected": "error",
    Hold: "default",
    "Completed":"processing",
    "In Progress": "warning",
    "Not Shortlisted": "error",
    "All": "success",
    "Re-Discussion":"warning",
    "Re-Schedule":"warning",
    "Feedback-Pending": "purple",
  }

  const timelineHash = {
    Shortlisted: Color .blue,
    Skipped: Color.blue,
    Scheduled: Color.yellow,
    Selected: Color.green,
    "Not Selected": Color.red,
    "Completed":Color.green,
    Hold: Color.darkGrey,
    "In Progress": Color.yellow,
    "Not Shortlisted": Color.red,
    "Re-Discussion":Color.yellow,
    "Re-Schedule":Color.yellow,
    "Feedback-Pending":"#834a85",
  };

  const timelineDotHash = {
    Selected: <CheckCircleOutlined style={{ color: Color.green }} />,
    "Not Selected": <CloseCircleOutlined style={{ color: Color.red }} />,
    "Not Shortlisted": <CloseCircleOutlined style={{ color: Color.red }} />,
    Hold: <ExclamationCircleOutlined style={{ color: Color.darkGrey }} />,
    "In Progress": <CheckCircleOutlined style={{ color: Color.green }} />,
    Scheduled: <ClockCircleOutlined style={{ color: Color.yellow }}/>,
    Shortlisted: <ClockCircleOutlined style={{ color: Color.blue }}/>,
    "Re-Discussion": <ClockCircleOutlined style={{ color: Color.yellow }}/>,
    "Re-Schedule": <ClockCircleOutlined style={{ color: Color.yellow }}/>,
    "Feedback-Pending": <ClockCircleOutlined style={{ color: "#834a85" }}/>,
    "Completed": <ClockCircleOutlined style={{ color: Color.green  }}/>,
  };

  const {user}= useSelector(state =>state.auth);

  const handlePageChange = (page, pageSize) => {
   
    setPageChange(page);
    setPageSize(pageSize);
  };

  const getInt=(request)=>{
    
    request={ 
      search: searchVal,
      candidateType:"Experienced",
      startDate: startDate,
      endDate:endDate,
      jobId:jobFilter,
      status:statusFilter==="All"?"":statusFilter,
      agencyId:agencyFilter
    }
    const Request= {
      ...request
    }
    setLoader(true)
      Api.get('/hiring-overview/interviews/')
        .params(Request)
        .send((response) => {
          if(typeof response != "undefined") {
              let tempArr=[]
              
              setStatusFilterList(response?.interviewStatus)
              setInterview(response?.candidates)

              response?.candidates.forEach((item,index)=>{
              tempArr.push(item?.interviewSlots[index]?.interviewStatus);
            });

              setAgencyFilterList(response?.agency)
              setJobFilterList(response?.jobs)
              setSchedule(response?.scheduledCount)
              // setCandidateStatus(tempArr)
           }
            setLoader(false);
            setReset(false);
        });
  }


  let dates=moment(new Date()).format('DD/MM/YYYY')

   useEffect(() => {

    // const request={ 
    //   search: searchVal,
    //   candidateType:"Experienced",
    //   startDate: startDate,
    //   endDate:endDate,
    // }

    getInt();

  }, [searchVal,startDate,endDate,reset]);



  const filterInterviews = ( internalfilterObj = null) => {
    let request;

    if(filterObj?.status){
      request= {
        search: searchVal,
        candidateType:"Experienced",
        startDate: startDate,
        endDate:endDate,
        status : statusFilter==='All'?'':statusFilter, 
        agencyId: agencyFilter==='All'?'':agencyFilter,
        jobId: jobFilter==='All'?'':jobFilter,

      }
    }
    else if(filterObj?.jobTitle)
   {
    request= {   
      search: searchVal,
      candidateType:"Experienced",
      startDate: startDate,
      endDate:endDate,
      status : statusFilter==='All'?'':statusFilter, 
      jobId: jobFilter==='All'?'':jobFilter,
      agencyId: agencyFilter==='All'?'':agencyFilter,
    }
  }
  else if(filterObj?.institute)
   {
    request= {  
      search: searchVal,
      candidateType:"Experienced",
      startDate: startDate,
      endDate:endDate,
      status : statusFilter==='All'?'':statusFilter, 
      jobId: jobFilter==='All'?'':jobFilter,
      agencyId: agencyFilter==='All'?'':agencyFilter,
    }
  }
  else if(filterObj?.agencyName)
  {
   request= { 
    search: searchVal,
    candidateType:"Experienced",
    startDate: startDate,
    endDate:endDate,
    status : statusFilter==='All'?'':statusFilter, 
    jobId: jobFilter==='All'?'':jobFilter,
    agencyId: agencyFilter==='All'?'':agencyFilter,
  }
 }
 else{
  request= { 
    search: searchVal,
    candidateType:"Experienced",
    startDate: startDate,
    endDate:endDate,
   }
   
  }
  //  setFilterData(request)
   getInt(request)
  };

  const resetFilter = () => {
    filterObj.resetFilter();
    filterInterviews();
    forceUpdate();
  }

  const customFilterPopover = (type = null, logo) => {
    let contentBody;
    
    switch (type) {

      case "status" : 

      contentBody = <div className="sort-popover">
       {/* <Radio.Group style={{width: '80%',marginBottom:"0.8rem",}} 
        onChange={(e) =>{
          let value=e.target.value
          if(!e.target.checked)
          {
            setStatusFilter( statusFilterList.filter(arr => {} ))
            return 
          }
          // else{
            // setStatusFilter((arr) => [...arr, value])
            setStatusFilter(value)
          // return
          // }
        }}>
          <Space direction="vertical">
           <Radio value={""}>All</Radio>
            {
              statusFilterList?.map((item)=>{
                  return(
                    <Radio value={item}>{item}</Radio>
              )})
           }
        </Space>
      </Radio.Group> */}
       {
                      ['All'].concat(statusFilterList)?.map((item,index)=>{
                          return(
                            <div>                            
                                      <input type="radio" checked={statusCheck[index]}onChange={(e)=>{
                                        
                                        setStatusCheck(statusCheck.map((item2,index2)=>{
                                          if(index===index2)
                                          {
                                            if(e.target.checked)
                                            {
                                              return true;
                                            }
                                            return false;
                                          }
                                          return false;
                                        }))
                                        if(!e.target.checked)
                                        {
                                          setStatusFilter( statusFilterList.filter(arr => arr !== item))
                                          return 
                                        }
                                          setStatusFilter(item)
                                        
                                      }}/>
                                        <span> {item}</span>
                            </div>
                      )})
                  }
      <div className="sort-action">
        <Button className="cancel" onClick={() => {
              let temp = filterState;
              temp.status = false
              setFilterState(temp)  
              forceUpdate(); 
        }}>CANCEL</Button>
        <Button className="float-right ok" onClick={() => {
          getfilterRequest(type);
          filterInterviews();
          setStatusFilterTitle(statusFilter)
          let temp = filterState;
          temp.status = false
          setFilterState(temp)
          forceUpdate();
          //getInt();
        }}>OK</Button>
      </div>
      </div>
     return <Popover
     placement="bottom"
     content={contentBody}
     trigger="click"
     visible={filterState.status}
     onVisibleChange={() => {
      let temp = filterState;
      if(filterState.status===false){
        temp.status = true
        setFilterState(temp)
        forceUpdate();
        }
        else{
         temp.status = false
         setFilterState(temp)
         forceUpdate();
        }
     }}
   >
     {logo}
   </Popover>
  
 
   case "jobTitle" : 
   contentBody = <div className="sort-popover">
     <div style={{width: '90%',marginBottom:"0.8rem", display:"flex",flexDirection:"column",maxHeight: "22vh",overflowY:"auto", }}>
      {/* <div> */}
      {
          [{title:'All'}].concat(jobFilterList)?.map((item,index)=>{
        
            return(
              <div>                            
                        <input type="radio" value={item?.id} checked={jobTitleCheck[index]}
                          onChange={(e)=>{
                       
                          setJobTitleCheck(jobTitleCheck.map((item2,index2)=>{
                            if(index===index2)
                            {
                              if(e.target.checked)
                              {
                                return true;
                              }
                              return false;
                            }
                            return false;
                          }))
                          if(!e.target.checked)
                          {
                            setJobFilter( jobFilterList.filter(arr => {} ))
                            return 
                          }
                            setJobFilter(item?.id)
                          
                        }}/>
                          <span> {item?.title}</span>
              </div>
        )})
      }
    {/* </div> */}
</div>


      {/* <FilterUtility  setContent={setJobFilter} selectedContent={jobFilter} content={[{id:"All",title:"All"}].concat(jobFilterList)} reset={reset} setReset={setReset}/> */}
    <div className="sort-action">
   <Button className="cancel" onClick={() => {
         let temp = filterState;
         temp.jobTitle = false
         setFilterState(temp)
         forceUpdate();
   }}>CANCEL</Button>
   <Button className="float-right ok" onClick={() => {
          getfilterRequest(type);
          filterInterviews();
          setJobFilterTitle(jobFilter)
          let temp = filterState;
          temp.jobTitle = false
          setFilterState(temp)
          forceUpdate();
   }}>OK</Button>
 </div>
   </div>
    return <Popover
    placement="bottom"
    content={contentBody}
    trigger="click"
    visible={filterState.jobTitle}
    onVisibleChange={() => {
      let temp = filterState;
      if(filterState.jobTitle===false){
        temp.jobTitle = true
        setFilterState(temp)
        forceUpdate();
        }
        else{
         temp.jobTitle = false
         setFilterState(temp)
         forceUpdate();
        }
    }}
  >
    {logo}
  </Popover>
   break;

  
   case "agencyName" : 
   contentBody = <div className="sort-popover">
     <div style={{width: '90%',marginBottom:"0.8rem",overflow:"auto",height:"30vh"}}>
      {
            [{agencyName:'All'}].concat(agencyFilterList)?.map((item,index)=>{
         
            return(
              <div>                            
                        <input type="radio" value={item?.id} checked={agencyCheck[index]}
                          onChange={(e)=>{
                      
                          setAgencyCheck(agencyCheck.map((item2,index2)=>{
                            if(index===index2)
                            {
                              if(e.target.checked)
                              {
                                return true;
                              }
                              return false;
                            }
                            return false;
                          }))
                          if(!e.target.checked)
                          {
                            setAgencyFilter( agencyFilterList.filter(arr => {} ))
                            return 
                          }
                            setAgencyFilter(item?.id)
                          
                        }}/>
                          <span> {item?.agencyName}</span>
              </div>
        )})
    }
   </div>
  
      {/* <FilterUtility  setContent={setAgencyFilter} selectedContent={agencyFilter} content={[{id:"All",title:"All"}].concat(agencyFilterList)} reset={reset} setReset={setReset}/> */}
    <div className="sort-action">
   <Button className="cancel" onClick={() => {
         let temp = filterState;
         temp.agencyName = false
         setFilterState(temp)
         forceUpdate();
   }}>CANCEL</Button>
   <Button className="float-right ok" onClick={() => {
    getfilterRequest(type);
    filterInterviews();
    setAgencyFilterTitle(agencyFilter)
    let temp = filterState;
    temp.agencyName = false
    setFilterState(temp)
    forceUpdate();
    //getInt();
   }}>OK</Button>
 </div>
   </div>
    return <Popover
    placement="bottom"
    content={contentBody}
    trigger="click"
    visible={filterState.agencyName}
    onVisibleChange={() => {
      let temp = filterState;
       if(filterState.agencyName===false){
       temp.agencyName = true
       setFilterState(temp)
       forceUpdate();
       }
       else{
        temp.agencyName = false
        setFilterState(temp)
        forceUpdate();
       }
    }}
  >
    {logo}
  </Popover>
   break;

   default:
   break; 
 }
};

const filterList = ()=>{
  let list = [];
  
  filterShows.map(element => {
   
    switch(element.value){
  
      case "status" : 
        if(filterObj.status&&statusFilterTitle !== ""){

          let description =statusFilterTitle;    

          list.push({
            ...element,
            discription : description
          })
        }
      break;
      case "jobTitle" : 
      // let temp=[{id:"All",title:"All"}].concat(jobFilterList);
      if(filterObj.jobTitle&&jobFilterTitle !== 0&&jobFilterTitle>0 || jobFilterTitle === undefined){
        // let description = jobFilter ;
        let map={}
        let values
      //   jobFilter.forEach((element) =>{
      //   map[element]=true
      //  })
      jobFilterList.forEach((element)=>{
        if(jobFilterTitle===element.id){

          values=element.title
        }
        else if(jobFilterTitle===undefined){
          values='All'
        }
      })

        list.push({
          ...element,
          discription: values==='All'?'All':values,
        })
      }
    break;
    case "agencyName" : 
    // let temp2=[{id:"All",title:"All"}].concat(agencyFilterList);
    if(filterObj.agencyName&&agencyFilterTitle !== 0&&agencyFilterTitle>0 || agencyFilterTitle===undefined){
      let map={}
      let values
    //   agencyFilter?.forEach((element) =>{
    //   map[element]=true
    //  })

    agencyFilterList.forEach((element)=>{
    
      if(agencyFilterTitle===element.id){

        values=element.agencyName
      }
      else if(agencyFilterTitle===undefined){
        values='All'
      }
    })

    list.push({
        ...element,
        discription:values==='All'?'All':values,
      })
    }
    break;

      default :
      break;    
    }
  })
  return list;
}

const getfilterRequest = (key) => {
  switch (key) {
    case "status":
      filterObj.status = true;
      break;
    case "jobTitle":
      filterObj.jobTitle = true;
      break;
    case "institute":
      filterObj.institute=true;
      break;
    case "agencyName":
      filterObj.agencyName=true;
      break;
    default:
      break;
  }
}


  const columns = [
    {
      title: <div className={classes.headerName} style={{minWidth:"11rem"}}>
             <span className={classes.nameMargin}>CANDIDATE NAME</span>
            </div>,
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className={classes.nameMargin}>
          
          {text}
        </div>
      ),
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"5rem",textAlign:"center",alignItems:"center"}}>
                      <span>JOB TITLE</span>
                      {customFilterPopover("jobTitle", <DownOutlined />)}
 
                </div>,
      dataIndex: "jobTitle",
      key: "jobTitle",
      width:"12%",
      render:(text,record)=>{
        return(
          <div>
            {record?.job?.title?record?.job?.title:"NA"}
          </div>)}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",}}>
                      <span>CONTACT DETAILS</span>
              </div>,
      dataIndex: "contact",
      key: "contact",
      width:"15rem",
      render: (text,record) => {
       
        return(
          <>
          <div>{record?.email}</div>
          <div>{record?.phone}</div>
          </>
        )
      },
    },

  ];

  if(user?.role.includes('Agency')){
    columns.push(
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",}}>
                        <span>DATE</span>             
             </div>,
      dataIndex: "date",
      key: "date",
      
      render:(text,record)=>{
        let slotLength = record?.interviewSlots?.length
        let slot = record?.interviewSlots
        let filterData=slot.map((e)=>e?.interviewStatus !== "Cancelled" && e.date)
        let filterDate=filterData.find(item=>item >= startDate && item <= endDate)
        return(
          (startDate && endDate) ?
          <div>{filterDate}</div>
          :
          <div>
           {record?.interviewSlots[0]?.date ? record?.interviewSlots[0]?.date:"NA"}
          </div>
        )}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center",alignItems:"center"}}>
                      <span>SLOT</span>
                </div>,
      dataIndex: "slot",
      key: "slot",

      render:(text,record)=>{
        let slotLength = record?.interviewSlots?.length
        let slot = record?.interviewSlots
        let filterData=slot.map((e)=>e?.interviewStatus !== "Cancelled" && e)
        let filterSlot=filterData.find(item=>item?.date >= startDate && item?.date <= endDate)
        return(
          (startDate && endDate) ?
          <>
          {filterSlot?.schedule?.startTime} - {filterSlot?.schedule?.endTime}          
          </>
          :
          <> {slotLength === 1 && record?.interviewSlots[0].schedule === null ? 
          <div>NA</div>
          :
          <div>
          {record?.interviewSlots[0]?.schedule?.startTime} - {record?.interviewSlots[0]?.schedule?.endTime}
          </div>
          }
          </>
          )}
    },
    {
        title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center",alignItems:"center"}}>
                        <span>STATUS</span>
                        {customFilterPopover("status", <DownOutlined />)}
   
                  </div>,
        dataIndex: "status",
        key: "status",

        render:(text,record)=>{
          const content = (
            <div style={{maxHeight:"20vh",overflowY:"auto"}}>
              {record.interviewSlots?.map((item)=>{
                return(
                  <div style={{display:'flex',flexDirection:'row',gap:"-0.5rem",marginBottom:"0.5rem",fontSize:"14px"}}>

                    <Card bordered={false} style={{width:"24rem"}}>
                     <Row style={{margin:"-0.5rem"}}>
                    <div style={{ flex:"1 1 13%",marginTop:"-0.3rem"}}>
                    <div style={{display:"flex",alignItems:"left",flexDirection:"column",textAlign:"left"}}>
                        <p>Round: <span style={{color:"#0086FF"}}>Round {item?.round}</span></p>
                        {user?.role.includes('Agency')? ""
                        :
                        <p>Panelist: <span style={{color:"#0086FF"}}>{item?.panelist?.name}</span></p>
                        }
                        <p>Date: <span style={{color:"#0086FF"}}>{item?.date}</span></p>
                    </div>
                    </div>
                
                    <div style={{ flex:"1 1 15%",marginTop:"0.1rem"}}>
                        <div  style={{display:"flex",alignItems:"right",flexDirection:"column",textAlign:"right"}}>
                        <Timeline
                          mode="left">
                           <Timeline.Item
                              position="left"
                              style={{display:"flex",height:"2rem",marginBottom:"-0.6rem"}}
                              color={timelineHash[item?.interviewStatus]}
                              dot={timelineDotHash[item?.interviewStatus]}
                            >
                              <div style={{ color: timelineHash[item?.interviewStatus] }}>{item?.interviewStatus}</div>
                            </Timeline.Item>
                           <div style={{marginLeft:"-0.7rem",}}>Time: <span style={{color: timelineHash[item?.interviewStatus]}}>{item?.schedule?.startTime} - {item?.schedule?.endTime}</span></div>
                          </Timeline>
                        </div>
                      </div>
                     </Row>
                    </Card>
                 </div>
             )})}
           </div>
          );
          const texts = (<div style={{marginTop:"0.5rem",color:"#0086FF",fontSize:"18px",textAlign:"center"}}>Interview Rounds Details</div>)
          return(
            <>
            <div>
            <Popover placement="bottom"  content={content}>
             <Badge style={{cursor: "pointer",fontSize:"16px"}} status={badgeHash[record.hiringStatus]} text={record.hiringStatus} />
            </Popover>
            </div>
            <div style={{ marginLeft: "1rem"}}>Round {record.currentRound}</div>
            </>            
          )}
      },
      {
        title: <div className={classes.headerName} style={{minWidth:"11rem",textAlign:"center",alignItems:"center"}}>
                        <span>AGENCY NAME</span>
                        {customFilterPopover("agencyName", <DownOutlined />)}

                  </div>,
        dataIndex: "agencyName",
        key: "agencyName",

        render:(text,record)=>{
          return(
            <div>
              {record?.agency?.agencyName}
            </div>)}
      },)
  }else{
    columns.push({
      title:  <div className={classes.headerName} style={{minWidth:"8rem",}}>
                  <span>PANELIST</span>
              </div>,
      dataIndex: "panelist",
      key: "panelist",
    
      render:(text,record)=>{
        let slotLength = record?.interviewSlots?.length
        let slot = record?.interviewSlots
        let filterData=slot.map((e)=>e?.interviewStatus !== "Cancelled" && e)
        let filterSlot=filterData?.find(item=>item?.date >= startDate && item?.date <= endDate)
        console.log("sssMappFilter",filterData,filterSlot)
        return(
          (startDate && endDate) ?
            <div>{filterSlot?.panelist?.name}</div>
            :
            <div>
             {record?.interviewSlots[0]?.panelist?.name ? record?.interviewSlots[0]?.panelist?.name:"NA"}
            </div>
        )}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",}}>
                        <span>DATE</span>             
             </div>,
      dataIndex: "date",
      key: "date",
      
      render:(text,record)=>{
        let slot = record?.interviewSlots
        let filterData=slot.map((e)=>e?.interviewStatus !== "Cancelled" && e.date)
        let filterDate=filterData.find(item=>item >= startDate && item <= endDate)
        return(
            (startDate && endDate) ?
            <div>{filterDate}</div>
            :
            <div>
             {record?.interviewSlots[0]?.date ? record?.interviewSlots[0]?.date:"NA"}
            </div>
        )}
    },
    {
      title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center",alignItems:"center"}}>
                      <span>SLOT</span>
                </div>,
      dataIndex: "slot",
      key: "slot",

      render:(text,record)=>{
        let slotLength = record?.interviewSlots?.length
        let slot = record?.interviewSlots
        let filterData=slot.map((e)=>e?.interviewStatus !== "Cancelled" && e)
        let filterSlot=filterData.find(item=>item?.date >= startDate && item?.date <= endDate)
        return(
          (startDate && endDate) ?
          <>
          {filterSlot?.schedule?.startTime} - {filterSlot?.schedule?.endTime}          
          </>
          :
          <> {slotLength === 1 && record?.interviewSlots[0].schedule === null ? 
          <div>NA</div>
          :
          <div>
          {record?.interviewSlots[0]?.schedule?.startTime} - {record?.interviewSlots[0]?.schedule?.endTime}
          </div>
          }
          </>
          )}
    },
    {
        title: <div className={classes.headerName} style={{minWidth:"7rem",textAlign:"center",alignItems:"center"}}>
                        <span>STATUS</span>
                        {customFilterPopover("status", <DownOutlined />)}
   
                  </div>,
        dataIndex: "status",
        key: "status",

        render:(text,record)=>{
          const content = (
            <div style={{maxHeight:"20vh",overflowY:"auto"}}>
              {record?.interviewSlots?.map((item)=>{
                return(
                  <div style={{display:'flex',flexDirection:'row',gap:"-0.5rem",marginBottom:"0.5rem",fontSize:"14px"}}>

                    <Card bordered={false} style={{width:"24rem"}}>
                     <Row style={{margin:"-0.5rem"}}>
                    <div style={{ flex:"1 1 13%",marginTop:"-0.3rem"}}>
                    <div style={{display:"flex",alignItems:"left",flexDirection:"column",textAlign:"left"}}>
                        <p>Round: <span style={{color:"#0086FF"}}>Round {item?.round}</span></p>
                        <p>Panelist: <span style={{color:"#0086FF"}}>{item?.panelist?.name}</span></p>
                        <p>Date: <span style={{color:"#0086FF"}}>{item?.date}</span></p>
                    </div>
                    </div>
                
                    <div style={{ flex:"1 1 15%",marginTop:"0.1rem"}}>
                        <div  style={{display:"flex",alignItems:"right",flexDirection:"column",textAlign:"right"}}>
                        <Timeline
                         mode="left">
                           <Timeline.Item
                              position="left"
                              style={{display:"flex",height:"2rem",marginBottom:"-0.6rem"}}
                              color={timelineHash[item?.interviewStatus]}
                              dot={timelineDotHash[item?.interviewStatus]}
                            >
                              <div style={{ color: timelineHash[item?.interviewStatus] }}>{item?.interviewStatus}</div>
                            </Timeline.Item>
                           <div style={{marginLeft:"-0.7rem",}}>Time: <span style={{color: timelineHash[item?.interviewStatus]}}>{item?.schedule?.startTime} - {item?.schedule?.endTime}</span></div>
                          </Timeline>
                        </div>
                      </div>
                     </Row>
                    </Card>
                 </div>
             )})}
           </div>
          );
          const texts = (<div style={{marginTop:"0.5rem",color:"#0086FF",fontSize:"18px",textAlign:"center"}}>Interview Rounds Details</div>)
          return(
            <>
            <div>
            <Popover placement="bottom"  content={content}>
             <Badge style={{cursor: "pointer",fontSize:"16px"}} 
             status={badgeHash[record.hiringStatus]} 
             text={record.hiringStatus} />
            </Popover>
            </div>
            <div style={{ marginLeft: "1rem"}}>Round {record.currentRound}</div>
            </>            
          )}
      },
      {
        title: <div className={classes.headerName} style={{minWidth:"11rem",textAlign:"center",alignItems:"center"}}>
                        <span>AGENCY NAME</span>
                        {customFilterPopover("agencyName", <DownOutlined />)}

                  </div>,
        dataIndex: "agencyName",
        key: "agencyName",

        render:(text,record)=>{
          return(
            <div>
              {record?.agency?.agencyName}
            </div>)}
      },)
  }

  const customPopover = (logo) => {
    let contentBody;

        contentBody = <div className="sort-popover">
          <div> <span style={{fontWeight:"bold",fontSize:"19px"}}>ALL Filter(s)</span>
          <Button type="text" size="medium" className="float-right"
           onClick={()=>{
            resetFilter();
            setReset(true);
             setStatusCheck(new Array(10).fill(false));
             setJobTitleCheck(new Array(10).fill(false));
             setJobFilterTitle(0)
             setAgencyFilterTitle(0)
             setAgencyCheck(new Array(10).fill(false));
             setStatusFilter('');
            setJobFilter("");
            setAgencyFilter("");
            setFilterDetails(false);
           // getInt();
            //forceUpdate();
           }}
          >Reset</Button></div>
  
          <List
          dataSource={filterList()}
          renderItem={(item) => (
           
            <List.Item key={item.title} value={item.title}>
              <List.Item.Meta
                title={<span>{closeBtn?item.title:""}</span>}
                description={<span>{closeBtn?item.discription:""}</span>}
              />
              {/* {closeBtn?<div>
              <div style={{alignItem:"right",display:"flex",cursor:"pointer",justifyContent:"space-between"}}>
                <CloseOutlined 
                 onClick={()=>{
                   setCloseBtn(false)
                   filterInterviews();
                 }}
              />
              </div>
              </div>
              :""
              } */}
            </List.Item>
          )}
        />
       
          <div className="sort-action">
            <Button className="cancel" onClick={() => { setFilterDetails(false)}}>CANCEL</Button>
            <Button className="float-right ok"
            onClick={() => { setFilterDetails(false)}}
            >OK</Button>
          </div>
        </div>

        return (<Popover
          placement="bottom"
          content={contentBody}
          trigger="click"
          visible={filterDetails}
          onVisibleChange={() => { 
            if (filterDetails){
              setFilterDetails(false)
            }else{
              setFilterDetails(true)
            }
          }}
        >
          {logo}
        </Popover>
  )
};


    return (
      <>
       
        
        <h1 style={{ margin: "1% 0 0 2%",fontWeight:"bold",color:"#161616",fontSize:"1.5rem"}}>
         Interviews
        </h1>
       
        <div
          style={{
            padding: "2%",
            width: "100%",
            boxSizing:"border-box",
            height: "88vh",
          }}
        >
          <div style={{display:'flex',flexDirection:'row',gap:"2.8%",marginBottom:"1rem",height:"13rem"}} >
          
          <Card bordered={false} style={{ flex:"1 1 35%"}}>
          <div  style={{display:"flex",justifyContent:"center",alignItems:"left",flexDirection:"column",textAlign:"center",marginTop:"2rem"}}>
            <div
              style={{
                fontSize: "40px",
              }}
              >
              <b>{schedule}</b>
            </div>
            <div
              style={{
                fontSize: "20px",
              }}>
              <b>Scheduled Interviews</b>
            </div>
            <div
              style={{
                color:"#6F6F6F"
              }}>
             Last Updated :  {dates?dates:"NA"}
            </div>
            </div>
          </Card>
        
        
          <Card style={{ flex: "1 1 60%", backgroundColor: "#E6F3FF",display:"flex",flexDirection:'row',gap:"5%",flexWrap:"nowrap"}}>
           <div  style={{display:"flex",flexDirection:'row',gap:"5%",flexWrap:"nowrap",}}>
              <div >
                <img src={iconInterview} style={{height:"11rem"}}/>
              </div>
              <div  style={{display:"flex",justifyContent:"center",alignItems:"left",flexDirection:"column",textAlign:"left"}}>
                <h2 style={{fontSize:"23px",marginBottom:"0.3rem"}}><b>Interview Information</b></h2>
                <p style={{fontSize:"14px", color:"#6F6F6F"}}>
                  The daily interview schedule for the experience can be seen 
                  here.
                </p>
              </div>

              </div>
            
          </Card>
        </div>

          <Row> 
         <div style={{ display: 'flex', gap:"1rem",justifyContent:"space-between",width:"98%",marginBottom:"0.8rem"}}>
          <div >
                
             <div className={classes.searchDiv}>
                    {/* <div>
                        <img src={searchIcon}></img>
                    </div> */}
                    <Input
                     type="text"
                     size="medium"
                     allowClear={true}
                     prefix={<SearchOutlined className="custom-search-icon" />}
                     placeholder='Search'
                     style={{color:"#161616",}}
                     onChange={(event)=>{
                      setSearchVal(event.target.value)
                     }}
                     >
                     </Input>
                </div>
          </div>
              <div className="head-icon">    
    
                       <div className="head-icon">
                       {customPopover(<img src={filter} onClick={()=>{}} />)}</div>

                            <div className="head-icon">
                                      <img src={Download}
                                        style={{ width: "24", height: "30px", marginRight: '10px' }}  
                                        onClick={()=>{                                      
                                          Api.get('/hiring-overview/interviews')
                                             .params({ 
                                                download:true,
                                                candidateType:"Experienced",
                                               search: searchVal,
                                                startDate: startDate,
                                                endDate:endDate,
                                                jobId:jobFilter,
                                                status:statusFilter==="All"?"":statusFilter,
                                                agencyId:agencyFilter,                                                                                         
                                              })
                                             .send((response) => {
                                              if (typeof response != "undefined") {
                                               window.open(response, "_blank")?.focus();
                                              }
                                           })
                                           }}/>
                            </div>
                            <Space direction="vertical">
                               <RangePicker style={{color:"blue"}}
                                format={dateFormat}
                                onChange={(date, dateString) => {
                                  
                                  // setDateVal(dateString)
                                  setStartDate(dateString[0])
                                  setEndDate(dateString[1])
                               }}/>
                            </Space>  
                       </div>
             </div>
            </Row>   
            <Row style={{marginTop:"0.1rem"}}>
            {user?.role.includes('Agency')? 
                    <Table columns={columns} dataSource={interviewLists} loading={loader} 
                    scroll={{ y: 150,x:1500 }} 
                    pagination={{ 
                      position: ["bottomRight"],
                      showSizeChanger: true,
                      pageSizeOptions:[10, 30, 50, 100],
                      size:"small",
                      onChange: handlePageChange,
                      onShowSizeChange: handlePageChange,                      
                    }}/>
                    :
                    <Table columns={columns} dataSource={interviewLists} loading={loader} 
                          // rowSelection
                          pagination={{ 
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            pageSizeOptions:[10, 30, 50, 100],
                            size:"small",
                            onChange: handlePageChange,
                            onShowSizeChange: handlePageChange,                      
                          }}
                          scroll={{
                             x:2000,
                             y:350
                           }}/>
            }
            </Row>  

        </div>
      </>
      );

}

export default InterviewExp;