import { Modal, Button, Space } from "antd";
import React from "react";
import { Color } from "./color";
import { disable } from "workbox-navigation-preload";

const okButtonProps = { fontSize: "1em", paddingLeft: "40px", paddingRight: "40px", borderRadius: "10px" }
const cancelButtonProps = {
  fontSize: "1em",
  paddingLeft: "40px",
  paddingRight: "40px",
  borderRadius: "10px",
  backgroundColor: Color.lightGrey,
}

class InformationModal {
  info(icon, title, content, onOk) {
    Modal.info({
      title,
      content,
      icon,
      onOk,
      okText: "Yes",
      cancelText: "No",
      className: "modal-popup",
      okButtonProps: {style: okButtonProps},
      cancelButtonProps: {style: cancelButtonProps},
    });
  }

  success(icon, title, content, onOk) {
    Modal.success({
      title,
      content,
      icon,
      onOk,
      okText: "Yes",
      cancelText: "No",
      className: "modal-popup",
      okButtonProps: {style: okButtonProps},
      cancelButtonProps: {style: cancelButtonProps},
    });
  }

  error(icon, title, content, onOk) {
    Modal.error({
      title,
      content,
      icon,
      onOk,
      okText: "Yes",
      cancelText: "No",
      className: "modal-popup",
      okButtonProps: {style: okButtonProps},
      cancelButtonProps: {style: cancelButtonProps},
    });
  }

  warning(icon, title, content, onOk) {
    Modal.warning({
      title,
      content,
      icon,
      onOk,
      okText: "Yes",
      cancelText: "No",
      className: "modal-popup",
      okButtonProps: {style: okButtonProps},
      cancelButtonProps: {style: cancelButtonProps},
    });
  }

  confirm(icon, title, content, onOk, onCancel) {
    
    Modal.confirm({
      title,
      content,
      icon,
      onOk,
      onCancel,
      okText: "Yes",
      cancelText: "No",
      className: "modal-popup",
      okButtonProps: {style: okButtonProps} ,
      cancelButtonProps: {style: cancelButtonProps},
    });
  }
}

export default new InformationModal();
