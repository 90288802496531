import { createSlice } from "@reduxjs/toolkit";

const initialState={
    panelistInfo:[],
    targetModalOpen:false,
    viewModalOpen:false,
    recuiterInfo:[],
    dataKeys:"",
}

const overAllDashboardSlice=createSlice({
    name:"overallDashboard",
    initialState,
    reducers:{
        updateTargetModalOpen(state,action){
            state.targetModalOpen=action.payload;
        },
        updateViewModalOpen(state,action){
            state.viewModalOpen=action.payload;
        },
        updatePanelistInfo(state,action){
            state.panelistInfo=action.payload;
        },
        updateRecruiterInfo(state,action){
            state.recuiterInfo=action.payload;
        },
        updateDataKeys(state,action){
            state.dataKeys=action.payload;
        },
    }
});

export default overAllDashboardSlice.reducer;
export const overallDashboardActions=overAllDashboardSlice.actions;