import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   
    feedbackList:[], 
    holdFeedback:[],
};

const feedbackSlice = createSlice({
  name: "panFeedback",
  initialState,
  
  reducers: {
    updateFeedback(state, action) {
      state.feedbackList = action.payload;
    },
  
  },
});

export default feedbackSlice.reducer;

export const feedbackActions = feedbackSlice.actions;
