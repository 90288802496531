import {
  Button,
  Modal,
  Form,
  Select,
  Row,
  Col,
  Typography,
  Input,
  TreeSelect,
  Upload,
  InputNumber,
  Space
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState, useEffect, useReducer } from "react";
import Api from "../../services/Api";
import fileUpload from "../../assets/file upload icon.svg";
import "./style.css";
import {
  LoadingOutlined,
  CloseOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import pdfLogo from "../../assets/pdf-logo.svg";
import JdSelect from "../../assets/jdcandidate.svg";
import Item from "antd/lib/list/Item";
import Notification from "services/Notification";

import classes from "./createJdModal.module.css";

const { Title } = Typography;
const { TreeNode } = TreeSelect;
const { Option } = Select;

function CreateJDModal(props) {
  const [form] = Form.useForm();
  const [btnLoader, setBtnLoader] = useState(false);
  const [department, setDepartment] = useState([]);
  const [priority, setPriority] = useState();
  const [fileList, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [interviewRounds, setRound] = useState([]);
  const [jdType, setJdtype] = useState("");
  const [roundUpdate, setRoundUpdate] = useState(false);
  const [roundvalue, setRoundValue] = useState("");
  const [feedbackvalue, setFeedbackValue] = useState("");
  const [Index, setIndex] = useState("");
  const [formValue, setFormValue] = useState("");
  const [phnNo, setPhnNo] = useState();
  const [poc, setSpoc] = useState("");
  const [deletedRound, setDeleteRound] = useState([]);
  const [remainingRound, setRemainingRound] = useState();
  const [jdFile, setJdFile] = useState();
  const [roundChng, setRoundChng] = useState(false);
  const [btnClick, setBtnClick] = useState(new Array(20).fill(false));
  const [imageUrl, setImageUrl] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [contctName, setCntctName] = useState("");
  const [disableSave,setDisableSave] =useState(false)
  const [uploadedDoc,setUploadedDoc]=useState('');
  const [descriptionData,setDescData]=useState("")
  const [inputdept,setInputdept]=useState(false)
  const [inputdeptMas,setInputdeptMas]=useState(false)
  // const [uploadComplete,setUploadComplete]=useState(true);
  const [count,setCount]=useState(0)
  const[remove,setRemove] =useState(false)
  const normFile = (e) => {


    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const onDeleteRound = (indexValue) => {
    let deleteId;
    if (props.selectedObject) {
      remainingRound.sort(function (a, b) {
        return a.round - b.round;
      });
      deleteId = remainingRound[remainingRound.length - 1].id;
      setDeleteRound((oldArray) => [...oldArray, deleteId]);
      setRemainingRound(
        remainingRound.filter((element) => element.id !== deleteId)
      );
      let remIntvRounds = interviewRounds.filter(
        (element) => element.round !== indexValue
      );
      let list = [];
      remIntvRounds.forEach((item, index) => {
        item["round"] = index + 1;
        list.push(item);
      });

      setRound(list);
    } else {
      let list = [];
      let remIntvRounds = interviewRounds.filter(
        (element) => element.round !== indexValue
      );
      remIntvRounds.forEach((item, index) => {
        item["round"] = index + 1;
        list.push(item);
      });

      setRound(list);
      // setFeedbackValue("")
      // setRoundValue("")
    
    }
    setFeedbackValue("")
    setRoundValue("")
    setIndex("")
    setBtnClick(
      btnClick.map((it, index2) => {
        if (index2 === 0) {
          return true;
        }
        return false;
      })
    );
   
  };

  useEffect(() => {
    if (props.selectedObject) {
   
     if(props.selectedObject.otherBachelorDepartment){
      setInputdept(true)
     }
     if(props.selectedObject.otherMasterDepartment){
      setInputdeptMas(true)
     }
      setDepartment(props.selectedObject.departments);
      let class_10th = String(props.selectedObject.class_10th);
      let class_12th = String(props.selectedObject.class_12th);
      let cgpa = String(props.selectedObject.cgpa);
      let maxBackLogs = String(props.selectedObject.maxBackLogs);
      let vacancies = String(props.selectedObject.vacancies);
      let masterCgpa = String(props.selectedObject.masterCgpa);
      let masterMaxBackLogs = String(props.selectedObject.masterMaxBackLogs);
      let location = props.selectedObject.locations?.map((item) => {
        return item.name;
      });
      let orgType = props.selectedObject.jobType.name;
      let SelectedValue = {
        ...props.selectedObject,
        class_10th: class_10th,
        class_12th: class_12th,
        cgpa: cgpa,
        maxBackLogs: maxBackLogs,
        vacancies: vacancies,
        masterMaxBackLogs: masterMaxBackLogs,
        masterCgpa: masterCgpa,
        jobType: orgType,
        location: location,
      };
      setRoundUpdate(true);
       setUploadedDoc(props.selectedObject?.descriptionFile)
      setSpoc(props?.selectedObject?.spoc?.id);

      let round = props.selectedObject.jobInterviewrounds.map((item) => {
        return {
          roundType: item.roundType,
          feedbackQuestions: item.feedbackMeta.id,
          round: item.round,
        };
      });
      
      if(round && round.length>0){
        setIndex(1)
        setRoundUpdate(true)
        setBtnClick(
          btnClick.map((it, index2) => {
            if (index2 === 1) {
              return true;
            }
            return false;
          })
        );
        setDisableSave(true)
        }
      round.sort(function (a, b) {
        return a.round - b.round;
      });
   
      setRound(round);
      let temp1 = props.selectedObject.jobInterviewrounds.map((item) => {
        return item;
      });
      setRemainingRound(temp1);
     
      form.setFieldsValue(SelectedValue);
      if(props.selectedObject.masterDepartments===null){
        
        form.setFieldsValue(({masterDepartments:undefined}))

      }
      if (props.priority) {
        setPriority(props.priority);
      }
    }
  }, []);

  const roundBtnStyle = {
    width: "6.5rem",
    height: "2.2rem",
    borderRadius: "4px",
    color: "#4DAAFF",
    fontWeight: "600",
    border: "1.5px solid #4DAAFF",
    margin:'0.5rem',
    marginRight: "0.5rem",
  };
  const roundBtnStyle2 = {
    width: "6.5rem",
    height: "2.2rem",
    borderRadius: "4px",
    color: "#ffffff",
    fontWeight: "600",
    border: "1.5px solid #4DAAFF",
    backgroundColor: "#4DAAFF",
    margin:'0.5rem',
    marginRight: "0.5rem",
  };

  const onJdCreateAndUpdate = () => {

    let intvArr = interviewRounds;
    let recordArr = props?.selectedObject?.jobInterviewrounds;
    let edited = [];
    let deleteVal = [];
    let valueHr = [];
    interviewRounds.forEach((element) => {
      if (element.roundType === "HR") {
        valueHr.push(element);
      }
    });
    if (
      valueHr.length === 0 ||
      valueHr.length > 1 ||
      interviewRounds[interviewRounds.length - 1].roundType !== "HR"
    ) {
      Notification.error(`One Hr round at last is compulsory`, "");
      return;
    }
    setBtnLoader(true);
    if (props.selectedObject) {
      for (let i = 0; i < interviewRounds.length; i++) {
        for (let j = 0; j < recordArr.length; j++) {
          if (interviewRounds[i].round === recordArr[j].round) {
            intvArr = intvArr.filter(
              (element, index) => element.round !== recordArr[j].round
            );         
            if (
              interviewRounds[i].roundType !== recordArr[j].roundType ||
              interviewRounds[i].feedbackQuestions !==
                recordArr[j].feedbackMeta.id
            ) {
              edited.push(recordArr[j].id);
              intvArr.push({
                id: recordArr[j].id,
                round: interviewRounds[i].round,
                roundType: interviewRounds[i].roundType,
                feedbackQuestions: interviewRounds[i].feedbackQuestions,
              });
            }
            break;
          }
        }
      }

      let map = {};
      for (let i = 0; i < edited.length; i++) {
        map[edited[i]] = true;
      }


      for (let i = 0; i < deletedRound.length; i++) {
        if (map[deletedRound[i]] !== true && interviewRounds.length!==recordArr.length) {
          deleteVal.push(deletedRound[i]);
        }
      }
    }

    
    let finalvalue = {
      ...formValue,
      descriptionData:descriptionData,
      descriptionFile:uploadedDoc,
      interviewRounds: intvArr,
      jobExperience: "Fresher",
      spoc: poc,
      jdfile: jdFile,
      deleted: !props.selectedObject ? deletedRound : deleteVal,
      edited: edited,
      roundCount: interviewRounds?.length,
    };

    if (props.selectedObject) {
      Api.patch(`/hiring-job/updatejob/${props.selectedObject.id}`)
        .params(finalvalue)
        .send((res) => {
          setBtnLoader(false);
          if (res !== undefined && res.show.type === "success") {
    

            props.refresh();
            props.toggle();
          }
        });
      return;
    }
    Api.post("/hiring-job/addJob")
      .params(finalvalue)
      .send((res) => {
        setBtnLoader(false);
        if (res !== undefined && res.show.type === "success") {
          props.refresh();

          props.toggle();
        
        }
      });
  };
  const onIntvChange = (newValue, type) => {
   
    setRoundChng(true);
   
    if (type === "round") {
      setDisableSave(true)
      setRoundValue(newValue);
      setFeedbackValue("");
      return;
    }
    setDisableSave(false)
    setFeedbackValue(newValue);
  };

  const onFinish = (values) => {
    // setBtnLoader(true);

    // let requesestBody = { ...values };

    if (!values.departments) {
      Notification.error("please select department");
      return;
    }
    if(!poc){
      Notification.error("please select contact person");
      return
    }
    if(uploadedDoc===""){
      Notification.error("Please Upload Job desription","Csv, pdf and docs(Preferred)!");
      return
    }

    let class_10th = parseInt(values.class_10th);
    let class_12th = parseInt(values.class_12th);
    let vacancies = parseInt(values.vacancies);
    let cgpa = parseInt(values.cgpa);
    let maxBackLogs = parseInt(values.maxBackLogs);
    let masterMaxBackLogs;
    let masterCgpa;
    if (values.masterCgpa || values.masterMaxBackLogs) {
      masterMaxBackLogs = parseInt(values.masterMaxBackLogs);
      masterCgpa = parseInt(values.masterCgpa);
    }
    let locationsvalue = {};
    values.location.forEach((item) => {
      locationsvalue[item] = true;
    });
    let locId = props?.selectedObject?.locations.map((item) => {
      if (locationsvalue[item.name] === true) {
        return item.id;
      }
    });
    let locationfinalArray = values.location.filter(
      (element) => typeof element !== "string"
    );
    let finalLocation = locId?.concat(locationfinalArray);
    let jobTypes = [];

    if (typeof values.jobType === "string") {
      props.orgType.map((item) => {
        if (values.jobType === item.name) {
          jobTypes.push(item.id);
        }
      });
    }
   
    const value = {
      ...values,
      class_10th: class_10th,
      class_12th: class_12th,
      vacancies: vacancies,
      cgpa: cgpa,
      maxBackLogs: maxBackLogs,
      masterMaxBackLogs: masterMaxBackLogs ? masterMaxBackLogs : null,
      masterCgpa: masterCgpa ? masterCgpa : null,
      location: !finalLocation ? values.location : finalLocation,
      jobType: jobTypes.length === 0 ? values.jobType : jobTypes[0],
    };
    setFormValue(value);
   
    setPage((page) => page + 1);

   
   
  };
  const imgbeforeUpload = (file) => {
    //  const isJpgOrPng = file.type === "image/svg"|| file.type === "application/pdf"|| file.type === "image/jped"|| file.type === "image/png";  
     const isJpgOrPng = file.type === "image/svg";  
    if (isJpgOrPng) {
      Notification.error("You can  upload CSV,PDF and Docs,Jpg,Png file only!");
      setImageUrl('');
      return;
    }
   
    else {
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl("");
        setImgFile(file)
        setUploadedDoc("");
        Api.post("/hiring-publicapi/converter").upload({file:file}, (percentCompleted, response) => {
                       
          if(response && percentCompleted===100 )
          {  
            setUploadedDoc(response.data.key);
            setDescData(response.data.descriptionData)
       
           
           
          }
          if(percentCompleted===100 ||percentCompleted===-1 ){
         
          }
        }
      
        )
      });
    }
  }
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  // const uploaderProps = {
  //   name: "files",
  //   multiple: true,
  //   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  //   onChange(info) {
  //     setFiles(info.fileList);
  //     Api.post("/hiring-job/uploadDesc").upload(
  //       { file: info.fileList[0].originFileObj },
  //       (percentCompleted, response) => {
  //         setJdFile(response?.data);
  //       }
  //     );
  //   },
  //   onDrop(e) {
  //   },
  // };

  const getImageSizeKb = (value) => {
    return Math.round(value / 1024);
  };
//   useEffect(() =>{
//     if(count===1 && remove!==true){
//     Api.post("/hiring-publicapi/converter") .upload({file:imgFile}, (percentCompleted, response) => {
                       
//       if(response && percentCompleted===100 )
//       {  
//         setUploadedDoc(response.data);
   
       
       
//       }
//       if(percentCompleted===100 ||percentCompleted===-1 ){
//       setUploadComplete(true );
//       }
//     }
   
//     )
   
//     setRemove(false)
// }
  
// },[count])
  const getRoundUi = (Index) => {
    if (Index !== "" && roundChng == false) {
      const valued = interviewRounds.find(
        (element, index) => element.round === Index
      );

      setTimeout(() => {
        setFeedbackValue(feed?.title);
        setRoundValue(valued?.roundType);
      }, 300);
      const feed = props.roundAgency.feedbackQuestion.find((element) => {
        return element.id === valued?.feedbackQuestions;
      });

    }
    //   const valued = interviewRounds.find((element,index) => index===Index)
    //   setRoundValue(valued[0].roundType)
    //   // const feed=props.roundAgency.feedbackQuestion.find((element) => {
    //   //   return element.id === valued[0].feedbackQuestion})

    return (
      <div
        style={{
          border: "1px solid var(--unnamed-color-0086ff)",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          border: "1px solid #0086FF",
          borderRadius: "4px",
          marginTop: "0.5rem",
          marginBottom: "30px",
        }}
      >
        <Row style={{ margin: "20px" }}>
          <Col span={24}>
            ROUND TYPE
            <Select
              showSearch
              placeholder="Select Round Type"
              style={{ width: "100%" }}
              value={roundvalue?roundvalue:undefined}
              // optionFilterProp="children"
              onChange={(value) => onIntvChange(value, "round")}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              // }
            >
              {props.roundAgency.roundType.map((item) => {
                return (
                  <>
                    <Option value={item}>{item === "Relationship" ? "HR Relationship": item}</Option>
                  </>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row style={{ margin: "20px" }}>
          <Col span={24} style={{ marginTop: "100" }}>
            FEEDBACK TYPE
            <Select
              showSearch
              placeholder="Select Feedback Type"
              style={{ width: "100%" }}
              value={feedbackvalue?feedbackvalue:undefined}
              optionFilterProp="children"
              onChange={(value) => onIntvChange(value, "feedback")}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              // }
            >
              {props.roundAgency.feedbackQuestion.map((item, index) => {
                return <Option value={item.id}>{item.title}</Option>;
              })}
            </Select>
          </Col>
        </Row>
        <Row style={{ margin: "20px" }}>
          <Button
            
            style={{ color: "#FF6B6B", borderColor: "#FF6B6B" }}
            onClick={() => {
              onDeleteRound(Index);
            }}
          >
            DELETE
          </Button>
        </Row>
        <Row justify="center" style={{ marginBottom: "20px" }}>
          <Col span={6}>
            <Button
             disabled={disableSave}
              type="primary"
              onClick={() => {
                if (Index !== "") {
                  let roundno
                  if(props?.selectedObject?.jobInterviewrounds){
                  roundno = props?.selectedObject?.jobInterviewrounds.find(
                    (element, index) => element.round === Index
                  );
                  }
                  else{
                  roundno = interviewRounds.find(
                    (element, index) => element.round === Index
                  );
                  }
         
                  let valued = interviewRounds.filter(
                    (element) => element?.round !== roundno?.round
                  );
           
                  let value = valued;
                  let temp1 = {
                    round: roundno.round,
                    roundType: roundvalue,
                    feedbackQuestions: feedbackvalue,
                  };
              
                  value.push(temp1);
                  value.sort(function (a, b) {
                    return a.round - b.round;
                  });
                 

                  setRound(value);
                  setDisableSave(true)
                  // setIndex("")
                  return;
                }
                let value = interviewRounds.find(
                  (element) => element.roundType === "HR"
                );
                if (roundvalue === "" || feedbackvalue === "") {
                  Notification.error("Please select Round/feedbacktype");
                  return;
                }
                if (value?.roundType) {
                 
                  let temp = {
                    round: interviewRounds.length,
                    roundType: roundvalue,
                    feedbackQuestions: feedbackvalue,
                  };
                  let list = [];
                  interviewRounds.forEach((item) => {
                    if (item.roundType === "HR") {
                      item["round"] = interviewRounds.length + 1;
                    }

                    list.push(item);
                  });
                  list.push(temp);
        
                  list.sort(function (a, b) {
                    return a.round - b.round;
                  });
                  setRound(list);
                } else {
                  let temp = {
                    round: interviewRounds.length + 1,
                    roundType: roundvalue,
                    feedbackQuestions: feedbackvalue,
                  };
                  setRound((oldArray) => [...oldArray, temp]);
                }
                setDisableSave(true)
                // forceUpdate();
   
             
                setFeedbackValue("");
                setRoundValue("");
               
           
              }}
              style={{ width: "100%" }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Modal
      visible={props.isOpen}
      // closable={false}
      maskClosable={false}
      closable={true}
      centered
      width="45rem"
      className="create-jd-custom-modal"
      onCancel={() => {
        props.toggle();
      }}
      title={
        <Title
          style={{
            textAlign: "center",
            color: "#161616",
            margin:"0",
            padding:0,
          
          }}
          level={3}
        >
          {props.selectedObject ? "Update" : "Create"} Job Description
        </Title>
      }
      footer={false}
     
    >
      {page === 1 && (
        <Form
          layout="vertical"
          onFinish={onFinish}
          id="jdescription"
          className={classes.scrollbody}
          form={form}
        >
          <div  >
          <Row>
            <Col span={24}>
              <Form.Item
                name="title"
                label="TITLE"
                placeholder=" Enter job title"
                className="college-modal-field-sm"
                size="middle"
                rules={[
                  {
                    required: true,
                    message: "Please enter Job title!",
                    whitespace: true,
                  },
                ]}
              >
                <Input size="small" />
              </Form.Item>
            </Col>
          </Row>
          <Row >
            <Col span={24} style={{border:"1px solid blue",minHeight:"100px",marginTop: "10px",padding:"1rem",borderStyle:"dashed",backgroundColor:"#e8fcfc",padding:"1rem"}}>
              <Row justify="center" style={{display:"flex",justifyContent:"center", flexDirection:"column", alignItems:"center",}}>                      
               <div>
                  <Upload
                    name="file"
                    maxCount={1}                 
                    accept=".csv,.pdf,.jpeg,.png,.docs"
                    showUploadList={imgFile?true:false}                     
                    style={{ backgroundColor: "#6FC78F",}}
                    onRemove={() => {                           
                      setUploadedDoc("")
                      setImageUrl("");
                      setImgFile("");
                    }}                   
                  beforeUpload={imgbeforeUpload}                     
                  >
                          <div style={{display:"flex",alignItems:"left",}} className="ant-upload-hint">
                                 <div>                                                                                     
                                 {!imgFile&&<p className="ant-upload-hint">Click on the icon to upload</p>}
                                                <Button  type="text" style={{marginLeft:"30px"}}>
                                                {imgFile==="" ?  <img src={fileUpload} /> : uploadedDoc==="" ?<LoadingOutlined style={{fontSize:"10px"}}/>:""}
                                                </Button>                                                                                            
                                    </div>                            
                        </div>
                  </Upload>             
                  <div  style={{marginBottom:"10px",justifyContent:"left",display:"flex",flexDirection:"column",marginTop:"2rem"}}>
                            {(uploadedDoc!=="") &&<a href={uploadedDoc} target="_blank" >Click to view the Document</a>}
                           <a href={"https://brigosha-hackathon-test-brigosha-5777.s3.ap-south-1.amazonaws.com/file/jobdescription.csv"} target="_blank" style={{color:"green",fontWeight:"bold",}}>Download Excel Sample</a>
                   </div>   
                  </div>               
              </Row>                         
            </Col>
            <Col span={24}>
              <div>
                <Form.Item
                  label="ORGANIZATION DEPARTMENT"
                  name="jobType"
                  className="college-modal-field-sm"
                  rules={[
                    {
                      required: true,
                      message: "please select department",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    type="text"
                    optionFilterProp="children"
                    style={{
                      width: "100%",
                    }}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="Please select"
                  >
                    {props?.orgType?.map((item) => {
                      return <Option value={item.id}>{item.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                {" "}
                <Form.Item
                  name="vacancies"
                  label="NUMBER OF OPENING (S)"
   
                  className="college-modal-field-sm"
                  rules={[
                    {
                      required: true,
                      message: "please enter vacancies",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input  placeholder=" Enter number of openings" type={"number"} min="1" />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  name="priority"
                  label="Priority"
                  className="college-modal-field-sm"
                  rules={[
                    {
                      required: true,
                      message: "please select priority",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    type="text"
                    optionFilterProp="children"
                    placeholder="please select priority"
                    value={priority}
                    onChange={(item) => {
                      setPriority(item);
                      form.setFieldsValue({ priority: item });
                    }}
                    allowClear
                  >
                    <Option value="Low" key={1}>
                      Low
                    </Option>
                    <Option value="Moderate" key={2}>
                      Moderate
                    </Option>
                    <Option value="High" key={3}>
                      High
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Form.Item
            name={"location"}
            className="college-modal-field-sm"
            label="LOCATION"
            rules={[
                {
                  required: true,
                  message: "Please enter location!",
                  
                },
              ]}
          >
            <TreeSelect
              showSearch
              filterTreeNode={(search, item) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
              }}
              style={{
                width: "100%",
              }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              placeholder="Please select"
              allowClear
              multiple
              treeDefaultExpandAll
            >
              {" "}
              {props.jobLocations.map((item) => {
                return <TreeNode value={item.id} title={item.name}></TreeNode>;
              })}
            </TreeSelect>
          </Form.Item>

          <Row>
            <Col span={11}>
              <Form.Item
                label="CONTACT PERSON"
                name="contact person"
                className="college-modal-field-sm"
                required={true}
              >
                <Select
                  showSearch
                  type="text"
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Select the contact person"
                  defaultValue={
                    props?.selectedObject?.spoc?.name
                      ? props?.selectedObject?.spoc?.name
                      : contctName
                  }
                  onChange={(value) => {
                    const valued = value.split("_");
                    
                    setCntctName(valued[2]);
                    setSpoc(parseInt(valued[0]));
                    setPhnNo(parseInt(valued[1]));
                  }}
                >
                  {props?.poc?.map((item) => {
                    return (
                      <Option value={`${item.id}_${item.phone}_${item.name}`}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col offset={2} span={11}>
              <Form.Item
                //  rules={[
                //   {
                //     required: true,
                //     message: "Please enter Job title!",
                //     whitespace: true,
                //   },
                // ]}
                className="college-modal-field-sm"
                label="MOBILE NO"
              >
                <Input min={0} 
                disabled={true}
                  style={{ width: "100%" }}
                  type={"number"}
                  value={
                    props?.selectedObject && !phnNo
                      ? props?.selectedObject?.spoc?.phone
                      : phnNo
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <div>
            <Title
              style={{
                margin: "10px 0 20px 0",
                fontFamily: "Open Sans",
                fontSize: "15p",
                color: "#161616",
              }}
              level={5}
            >
              Eligibilty Criteria
            </Title>
          </div>

          <Row>
            <Col span={24}>
              <Form.Item
                name="departments"
                label="DEPARTMENT (Bachelors)"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "please select department(s)!",                   
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  onChange={(value) =>{
                    if(value.includes("Other")){
                    setInputdept(true)
                    }
                    else{
                      setInputdept(false)
                    }
                  }}
                >
                  {" "}
                  {props?.dept?.map((item) => {
                    return <TreeNode value={item} title={item}></TreeNode>;
                  })}
                </TreeSelect>
              </Form.Item>
            </Col>
          </Row>
          {inputdept===true?
          <Form.Item
                name="otherBachelorDepartment"
                label="Kindly fill other bachelor department"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please input CGPA",
                    whitespace: true,
                  },
                ]}
              >
                <Input    />
              </Form.Item>:""
}
          <Row>
            <Col span={11}>
              <Form.Item
                name="cgpa"
                label="CGPA (Bachelors)"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please input CGPA",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="please enter"  type={"number"} required={true} min={1} max={10} />
              </Form.Item>
            </Col>

            <Col span={12} offset={1}>
              <Form.Item
                name="maxBackLogs"
                label="BACKLOGS ACCEPTED (Bachelors)"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please enter CGPA Backlogs",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="please enter"  type={"number"} min={0} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                name="masterDepartments"
                label="DEPARTMENT (Masters)"
                className="form-heading-css"
                // rules={[
                //   {
                //     required: true,
                //     message: "",
                //     whitespace: true,
                //   },
                // ]}
              >
                <TreeSelect
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  multiple
                  placeholder="Please select"
                   allowClear
                  
                   treeDefaultExpandAll
                   onChange={(value) =>{              
                    if(value.includes("Other")){
                    setInputdeptMas(true)
                    }
                    else{
                      setInputdeptMas(false)
                    }
                  }}
                >
                  {" "}
                  {props?.dept?.map((item) => {
                    return <TreeNode value={item} title={item}></TreeNode>;
                  })}
                </TreeSelect>
              </Form.Item>
            </Col>
          </Row>
          {inputdeptMas===true ? 
          <Form.Item
          name="otherMasterDepartment"
          label="Kindly fill other Master department"
          className="form-heading-css"
          rules={[
            {
              required: true,
              message: "Please input CGPA",
              whitespace: true,
            },
          ]}
        >
          <Input    />
        </Form.Item>:""}
          <Row>
            <Col span={11}>
              <Form.Item
                name="masterCgpa"
                label="Masters Degree (Cgpa)"
                className="form-heading-css"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input CGPA",
                //     whitespace: true,
                //   },
                // ]}
              >
                <Input placeholder="please enter" type={"number"} required={false} min={1} max={10} />
              </Form.Item>
            </Col>

            <Col span={12} offset={1}>
              <Form.Item
                name="masterMaxBackLogs"
                label="Max BACKLOGS ACCEPTED(Masters)"
                className="form-heading-css"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input CGPA",
                //     whitespace: true,
                //   },
                // ]}
              >
                <Input placeholder="please enter"  type={"number"} min={0} max={100}/>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" style={{ display: "flex" }}>
            <Col span={11}>
              <Form.Item
                name="class_10th"
                label="10TH PERCENTAGE"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please input 10th percentage",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="please enter"  type={"number"} required={true} min={1} max={100} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="class_12th"
                label="12TH PERCENTAGE"
                className="form-heading-css"
                rules={[
                  {
                    required: true,
                    message: "Please input 12th percentage",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="please enter"  type={"number"} required={true} min={1} max={100} />
              </Form.Item>
            </Col>
          </Row>
          </div>
          <Row  style={{marginTop:"60x",marginRigth:"5px"}}>
           
           

           <Col span={7} offset={15}>
             
             <Space>
             <Button   style={{ width: "100px" }} onClick={() =>{
                 props.toggle();
             }}>Cancel</Button>
             <Button disabled={uploadedDoc === undefined ? true : false}
               // loading={btnLoader}
               type="primary"
               htmlType="submit"
               style={{ width: "100px", }}
               // onClick={() => setPage((page) => page + 1)}
             >
               {/* {props.selectedObject ? "Update" : "Create"} */}
               Next
             </Button>
           </Space>
           </Col>
         </Row>
        </Form>
      )}

      {page == 2 && (
        <div>
          <div>
            <Title style={{ fontSize: "15px" }}>Interview Details</Title>
          </div>
          <Row >
            <Col span={23}>
              {interviewRounds.map((item, index) => (
                <Button
                  style={btnClick[index + 1] ? roundBtnStyle2 : roundBtnStyle}
                  onClick={() => {
                    setDisableSave(true)
                    setIndex(item.round);
                    setRoundChng(false);
                   
                    setBtnClick(
                      btnClick.map((it, index2) => {
                        if (index + 1 === index2) {
                          return true;
                        }
                        return false;
                      })
                    );
                  }}
                >
                  Round {" " + (index + 1)}
                </Button>
              ))}
              <Button
                style={btnClick[0] ? roundBtnStyle2 : roundBtnStyle}
                onClick={() => {
                 setDisableSave(true)
                  setRoundUpdate(true),
                    setBtnClick(
                      btnClick.map((it, index2) => {
                        if (index2 === 0) {
                          return true;
                        }
                        return false;
                      })
                    );
                  setFeedbackValue("");
                  setRoundValue("");
                  setIndex("");
             
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
          <Row>{roundUpdate && <Col span={24}>{getRoundUi(Index)}</Col>}</Row>
          <Row justify="center" style={{marginBottom:"2rem"}}>
            <Col span={6}>
              <Button
                onClick={() => setPage((page) => page - 1)}
                style={{ width: "100%" }}
              >
                Back
              </Button>
            </Col>
            <Col span={6} offset={1}>
              <Button
                // loading={btnLoader}
                type="primary"
                // htmlType="submit"
                style={{ width: "100%" }}
                onClick={() => {
                onJdCreateAndUpdate()}}
              >
                {props.selectedObject ? "Update" : "Create"}
                {/* Create */}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
export default CreateJDModal;
