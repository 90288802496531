import {
  Space,
  Table,
  Tag,
  Button,
  Descriptions,
  PageHeader,
  Modal,
  Form,
  Select,
  Row,
  Col,
  Typography,
  Input,
  TreeSelect,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState, useEffect, useRef } from "react";
import Api from "../../services/Api";
import "./style.css";
import Notification from "services/Notification";
import { collegeListActions } from "./store";
import { useDispatch, useSelector } from "react-redux";

import classes from "./addCollege.module.css"

const { TreeNode } = TreeSelect;
const { Title } = Typography;

function CollegeModal(props) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [btnLoader, setBtnLoader] = useState(false);
  const { jd } = useSelector((state) => state.college);
  const { collegeType } = useSelector((state) => state.college);
  const [jdValue, setJdValue] = useState([]);
  const [typeValue, setTypeValue] = useState("");
  const [initialJd, setInitialJd] = useState([]);
  const [collegeInitalData, setCollegeData] = useState();
  const [validTitle, setValidTitle] = useState(false);
  const [touched, setTouched] = useState(false);
  const dispatch = useDispatch();

  //   const treeData = jd.map((element) => {
  //     return {
  //       title: element.title,
  //       value: element.id,
  //       key:element.id
  //   }
  // })
  useEffect(() => {
    if (props.collegeData) {
      let obj = {
        ...props.collegeData.college,
        jd: props.collegeData.job.title,
      };
      setCollegeData(props.collegeData);
      let temp = [];
      temp.push(props.collegeData.job.title);
      form.setFieldsValue(obj);
      setJdValue(temp);
      // let temp2 = [];
      // // temp.push(props.collegeData);
      // form.setFieldsValue(obj);
      // setTypeValue(temp2);
    }
  }, []);

  const onJdChange = (newValue) => {
  
    setJdValue(newValue);
  };

  const onTypeChange = (newValue) => {
  
    setTypeValue(newValue);
  };

  const onFinish = (values) => {
    if(values.district.trim() === ""){
      Notification.error("District should not be empty")
      return
    }
    else if(values.state.trim() === ""){
      Notification.error("State should not be empty")
      return
    }
    else if(values.address.trim() === ""){
      Notification.error("Institute Address should not be empty")
      return
    }
    else{
    setBtnLoader(true);
    props.setTableLoader(true);
    if (collegeInitalData != null) {
      let temp = [];
   
      jdValue.map((item) => {
    
        if (parseInt(item) && typeof(item)!=="string") {
    
          temp.push(item);
        } else if (jd && jd.length > 0) {
          temp.push(jd.find((a) => a.title == item).id);
        }
      });

      let requestParams = { ...values, jobs: temp };
     
     

      Api.patch(`/hiring-college/updateCollege/${collegeInitalData.college.id}`)
        .params(requestParams)
        .send((response) => {
          // setBtnLoader(false);
         
          setBtnLoader(false);
          if (typeof response != undefined && response?.type == "success") {
            // setIsModalVisible(false)
            Notification.success(response?.message);
            props.toggle();
            props.fetchColleges(props.pageChange, props.pageSize, "");
          } else {
            Notification.error("Unable to update Institution");
          }
        });
    } else {
      let requestParams = { ...values, jobs: jdValue };
      Api.post("/hiring-college/addCollege")
        .params(requestParams)
        .send((response) => {
          setBtnLoader(false);
        
          if (
            typeof response != undefined &&
            response?.show?.type == "success"
          ) {
            props.fetchColleges(props.pageChange, props.pageSize, "");
            props.toggle(false)
            // Api.get("/hiring-college/getAllColleges")
            //    .send((response) => {
            //     if (typeof response != "undefined") {
            //     props.setTableLoader(false);
            //     dispatch(
            //       collegeListActions.updateCollegeList(response.college)
            //     );
            //     dispatch(collegeListActions.updateInfo(response.college));
            //     dispatch(collegeListActions.updateJD(response.jobVacancies));
            //     dispatch(collegeListActions.updateCollegeTYPE(response.collegeType));

            //   }
            // });
            
          }
        });
    }
  }
  };

  const setNumber = (e) => {
    let keyValue = Object.keys(e)[0] + "";
    let inputValue = e[keyValue];

    if (
      keyValue === "primaryPhone" ||
      keyValue === "secondaryPhone" ||
      keyValue === "tertiaryPhone"
    ) {
      let value;
      if (inputValue && inputValue.match(/^\d+$/g) != null) {
        value = inputValue;
      } else {
        if (inputValue.match(/[1-9]/g)) {
          value = inputValue.match(/[1-9]/g).join("");
        } else {
          value = "";
        }
      }

      switch (keyValue) {
        case "primaryPhone":
          form.setFieldsValue({ primaryPhone: value });
          break;

        case "secondaryPhone":
          form.setFieldsValue({ secondaryPhone: value });
          break;

        case "tertiaryPhone":
          form.setFieldsValue({ tertiaryPhone: value });
          break;

        default:
          break;
      }
    }
  };

  return (
    <Modal
      width="35%"
      visible={props.isOpen }
      onCancel={() => {
        props.toggle();
        props.toggle(false)
      }}
      centered
      // className="create-jd-custom-modal"
      bodyStyle={{height:'68vh'}}
      title={<div style={{textAlign: "center",font: "normal normal bold 24px/33px Open Sans",color: "#161616"}}>
          {collegeInitalData != null ? "Update" : "Add an"} Institution
        </div>}
      footer={[
        <Button
          form="addform"
          onClick={()=>{props.toggle
          props.toggle(false)}}
          style={{ width: "10rem",marginTop:"0.5rem",marginBottom:"0.5rem",marginRight:"0.4rem" }}
        >
          Cancel
        </Button>,
        <Button
          form="addform"
          loading={btnLoader}
          type="primary"
          htmlType="submit"
          style={{ width: "10rem" }}
          // loading={btnLoader}
        >
          {collegeInitalData != null ? "Update" : "Add"}
        </Button>,
      ]}
    >
      <div>
        <Form
          id="addform"
          form={form}
          onFinish={onFinish}
          onValuesChange={setNumber}
          layout={"vertical"}
          // scrollToFirstError
          initialValues={collegeInitalData}
          className={classes.scrollbody}
        >
            <Row>
              <Col offset={1} span={22}>
                <Form.Item
                  name="name"
                  label="INSTITUTE’S NAME"
                  placeholder="Enter College name"
                  className="college-modal-field"
                  rules={[
                    {
                      required: true,
                      message: "Please input college name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    type="text"
                    onChange={(event) => {
                      setTouched(true);
                      let tempString = event.target.value;
     
                      let validFlag =
                        tempString.charAt(0) ===
                          tempString.charAt(0).toUpperCase() &&
                        tempString.trim().length > 2 &&
                        tempString.trim().length < 100
                          ? true
                          : false;
                      let validFlag2 = true;
                      for (let i = 0; i < tempString.length; i++) {
                        if (tempString[i] !== " ") {
                          if (
                            tempString[i].toUpperCase() ===
                            tempString[i].toLowerCase()
                          ) {
                            validFlag2 = false;
                            break;
                          }
                        }
                      }
                      setValidTitle(validFlag && validFlag2);
                    }}
                  />
                </Form.Item>
                {!validTitle && touched && (
                  <p style={{ color: "red" }}>
                    (Title field should contains letters only, should start with
                    Uppercase letter)
                  </p>
                )}
              </Col>
            </Row>

            <Row>
              <Col span={22} offset={1}>
                <Form.Item
                  name="collegeType"
                  label="INSTITUTES TYPE"
                  className="college-modal-field"
                  rules={[
                    {
                      required: true,
                      message: "please select institute type",
                    },
                  ]}
                >
                  <TreeSelect
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Select institute type"
                    allowClear={false}
                    // multiple
                    treeDefaultExpandAll
                    onChange={onTypeChange}
                    defaultValue={typeValue}
                    size="middle"
                  >
                    {collegeType.map((event,index) => {
                   
                      return (
                        <TreeNode value={event} title={event} />
                      );
                    })}
                  </TreeSelect>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={11} offset={1}>
                <Form.Item
                  name="district"
                  label="District"
                  rules={[
                    { required: true, message: "please input district name !" },
                  ]}
                  className="college-modal-field"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10} offset={1}>
                <Form.Item
                  name="state"
                  label="State"
                  className="college-modal-field"
                  rules={[
                    { required: true, message: "please input state name !" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={22} offset={1}>
                <Form.Item
                  name="jd"
                  label="JOB VACANCY"
                  className="college-modal-field"
                  rules={[
                    {
                      required: true,
                      message: "please select job vacancy",
                    },
                  ]}
                >
                  <TreeSelect
                    style={{ width: "100%" }}
                    // value={value}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select jd"
                    allowClear={false}
                    multiple
                    treeDefaultExpandAll
                    onChange={onJdChange}
                    defaultValue={jdValue}
                    size="middle"
                  >
                    {jd.map((element) => {
                      return (
                        <TreeNode value={element.id} title={element.title} />
                      );
                    })}
                  </TreeSelect>
                </Form.Item>
              </Col>
            </Row>

            <div>
              <span className="sub-heading">Primary Contact</span>
              <Row>
                <Col offset={1} span={22}>
                  <Form.Item
                    name="primaryContactName"
                    label="NAME"
                    placeholder="Enter primary contact person’s name"
                    className="college-modal-field"
                    rules={[
                      {
                        required: true,
                        message: "Enter primary contact person’s name!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11} offset={1}>
                  <Form.Item
                    name="primaryEmail"
                    label="EMAIL ID"
                    className="college-modal-field"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10} offset={1}>
                  <Form.Item
                    name="primaryPhone"
                    label="PHONE"
                    className="college-modal-field"
                    rules={[
                      {
                        len: 10,
                        message: "The input is not valid phone number!",
                      },

                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Input
                      type="tel"
                      minLength={10}
                      maxLength={10}
                      min={0}
                      pattern="\d{10}"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div>
              <span className="sub-heading">Secondary Contact</span>
              <Row>
                <Col offset={1} span={22}>
                  <Form.Item
                    name="secondaryContactName"
                    label="NAME"
                    placeholder="Enter Secondary contact person’s name"
                    className="college-modal-field"
                  >
                    <Input
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11} offset={1}>
                  <Form.Item
                    name="secondaryEmail"
                    label="EMAIL ID"
                    className="college-modal-field"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10} offset={1}>
                  <Form.Item
                    name="secondaryPhone"
                    label="PHONE"
                    className="college-modal-field"
                    rules={[
                      {
                        len: 10,
                        message: "The input is not valid phone number!",
                      },
                    ]}
                  >
                    <Input
                      type="tel"
                      min={0}
                      minLength={10}
                      maxLength={10}
                      style={{
                        width: "100%",
                      }}
                      onValuesChange={(e) => {}}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div>
              <span className="sub-heading">Tertiary Contact</span>
              <Row>
                <Col offset={1} span={22}>
                  <Form.Item
                    name="tertiaryContactName"
                    label="NAME"
                    placeholder="Enter contact person’s name"
                    className="college-modal-field"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11} offset={1}>
                  <Form.Item
                    name="tertiaryEmail"
                    label="email"
                    className="college-modal-field"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10} offset={1}>
                  <Form.Item
                    name="tertiaryPhone"
                    label="Phone"
                    className="college-modal-field"
                    rules={[
                      {
                        len: 10,
                        message: "The input is not valid phone number!",
                      },
                    ]}
                  >
                    <Input
                      type="tel"
                      min={0}
                      minLength={10}
                      maxLength={10}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Row>
              <Col offset={1} span={22}>
                <Form.Item
                  name="address"
                  label="Institute's Address"
                  className="college-modal-field"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Full Address",
                    },
                  ]}
                  style={{
                    fontSize: "10px",
                  }}
                >
                  <TextArea
                    placeholder="Please input your Full Address"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>

          {/* <Form.Item
            wrapperCol={{
              span: 24,
              offset: 0,
            }}
          ></Form.Item> */}
        </Form>
      </div>
    </Modal>
  );
}
export default CollegeModal;
