import { Api } from "services";

export const UPDATE_PAGE_META = "UPDATE_PAGE_META";
export const UPDATE_JOB_SUMMARY = "UPDATE_JOB_SUMMARY";
export const TOGGLE_JOB_SUMMARY_LOADER = "TOGGLE_JOB_SUMMARY_LOADER";
export const UPDATE_JOB_SUMMARY_PROFILES = "UPDATE_JOB_SUMMARY_PROFILES";
export const UPDATE_ORG_TYPE="UPDATE_ORG_TYPE"

export const updateJobSummary = (data) => ({
  type: UPDATE_JOB_SUMMARY,
  data,
});
export const updateOrgType = (data) => ({
  type: UPDATE_ORG_TYPE,
  data,
});
export const updatePageMeta = (data) => ({
  type: UPDATE_PAGE_META,
  data,
});

export const updateJobSummaryProfiles = (data) => ({
  type: UPDATE_JOB_SUMMARY_PROFILES,
  data,
});

export const toggleJobSummaryLoader = (value) => ({
  type: TOGGLE_JOB_SUMMARY_LOADER,
  value,
});

export const getJobCount = (dates = ["", ""],jobType,pageSize, pageChange) => {
  return (dispatch) => {
    const params = {
      orgType: jobType !== "" ? jobType : undefined,
      // page:pageChange,
      // limit:pageSize,
      startDate: dates[0],
      endDate: dates[1],
    };
    dispatch(toggleJobSummaryLoader(true));
    return Api.get(`/hiring-overview/jobProfile-count`)
      .params(params)
      .send((response) => {
        dispatch(toggleJobSummaryLoader(false));
        if (typeof response !== "undefined") {
          dispatch(updateJobSummary(response));
          dispatch(updatePageMeta(response.pageMeta));
          dispatch(updateOrgType(response.jobType))
        }
      });
  };
};

export const getJobSummary = (jobId, dates = ["", ""]) => {
  return (dispatch) => {
    const params = {
      jobId,
      startDate: dates[0],
      endDate: dates[1],
    };
    dispatch(toggleJobSummaryLoader(true));
    return Api.get(`/hiring-overview/job-summary`)
      .params(params)
      .send((response) => {
        dispatch(toggleJobSummaryLoader(false));
        if (typeof response !== "undefined") {
          dispatch(updateJobSummaryProfiles(response));
        }
      });
  };
};
