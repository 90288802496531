import {
  adminOperationType,
  agencyOperationType,
  adminInputFieldType,
  adminInputFieldTypeHash,
  agencyInputFieldType,
  agencyInputFieldTypeHash,
  agencyUsersInputFieldType,
  agencyUsersInputFieldTypeHash,
  adminOperationTypeHash,
  agencyOperationTypeHash,
  ADMIN_DETAILS_GET,
  USER_DETAILS_GET,
  ADMIN_DETAILS_ADD,
  ADMIN_DETAILS_EDIT,
  ADMIN_DETAILS_DELETE,
  ADMIN_DETAILS,
  agencyInfoFormat,
  agencyUserFormat,
  AGENCY_USER_ADD,
  AGENCY_USER_DELETE,
  AGENCY_DETAILS_ADD,
  AGENCY_DETAILS_EDIT,
  AGENCY_DETAILS_DELETE,
  AGENCY_DETAILS,
  AGENCY_DETAILS_GET,
  AGENCY_PRIMARY_CONTACT,
  userFormat,
} from "./action";

let initialState = {
  adminUser: userFormat,
  userRole: [],
  userStatus: [],
  userDesignations: [],
  searchValue: "",
  adminUsers: [],
  userDetailLists: [],
  meta: {},
  agencyInfo: agencyInfoFormat,
  userInfo: agencyInfoFormat,
  agencies: [],
  adminDetailsModal: false,
  adminDetailsLoader: false,
  adminEditingId: "",
  adminTableLoader: false,
  isAddingAdmin: true,
  screenType: "Users",
  agencyDetailsModal: false,
  agencyViewModal: false,
  agencyDetailsLoader: false,
  isAddingAgency: true,
  agencyEditingId: "",
  agencyTableLoader: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_DETAILS_GET:   
      const users =
        action.value.users.items !== undefined &&
        action.value.users.items.length > 0
          ? action.value.users.items.map((admin) => ({
              ...admin,
              key: admin.id,
            }))
          : [];
      return {
        ...state,
        meta: action.value.users.meta,
        adminUsers: users,
        userRole: action.value.userRole,
        userStatus: action.value.userStatus,
        userDesignations: action.value.designations,
      };
    case USER_DETAILS_GET:   
      const userDetails =
        action.value !== undefined &&
        action.value.length > 0
          ? action.value.map((details) => ({
              ...details,
              key: details.id,
            }))
          : [];
      return {
        ...state,
        userDetailLists: userDetails,
      };
    case ADMIN_DETAILS_ADD:
      return {
        ...state,
        adminUsers: [
          {
            key: action.id,
            ...action.admin,
          },
          ...state.adminUsers,
        ],
      };
    case ADMIN_DETAILS_EDIT:
      return {
        ...state,
        adminUsers: state.adminUsers.map((admin) =>
          action.id !== undefined && admin.id === action.id
            ? {
                id: admin.id,
                key: admin.key,
                ...action.admin,
              }
            : admin
        ),
      };
    case ADMIN_DETAILS_DELETE:
      return {
        ...state,
        adminUsers: state.adminUsers.filter((admin) => admin.id !== action.id),
      };
    case ADMIN_DETAILS:
      return {
        ...state,
        adminUser: action.value,
      };
    case AGENCY_DETAILS_GET:
      const agencies =
        action.value.items !== undefined && action.value.items.length > 0
          ? action.value.items.map((agency) => ({ ...agency, key: agency.id }))
          : [];
      return {
        ...state,
        meta: action.value.meta,
        agencies: agencies,
      };
    case AGENCY_DETAILS_ADD:
      return {
        ...state,
        agencies: [
          { ...action.agency, key: action.id, id: action.id },
          ...state.agencies,
        ],
      };
    case AGENCY_DETAILS_EDIT:
      return {
        ...state,
        agencies: state.agencies.map((agency) =>
          action.id !== undefined && agency.id === action.id
            ? {
                id: agency.id,
                key: agency.key,
                ...action.agency,
              }
            : agency
        ),
      };
    case AGENCY_DETAILS_DELETE:
      return {
        ...state,
        agencies: state.agencies.filter((agency) => agency.id !== action.id),
      };
    case AGENCY_DETAILS:
      return {
        ...state,
        agencyInfo: action.value,
        userInfo: action.value,
      };
    case adminInputFieldType[action.type]:
      return {
        ...state,
        adminUser: {
          ...state.adminUser,
          [adminInputFieldTypeHash[action.type]]: action.value,
        },
      };
    case agencyInputFieldType[action.type]:
      return {
        ...state,
        agencyInfo: {
          ...state.agencyInfo,
          [agencyInputFieldTypeHash[action.type]]: action.value,
        },
      };
    case agencyUsersInputFieldType[action.type]:
      const index = action.index;
      const usersArray = state.agencyInfo.users;
      return {
        ...state,
        agencyInfo: {
          ...state.agencyInfo,
          users: [
            ...usersArray.slice(0, index),
            {
              ...usersArray[index],
              [agencyUsersInputFieldTypeHash[action.type]]: action.value,
            },
            ...usersArray.slice(index + 1),
          ],
        },
      };
    case AGENCY_PRIMARY_CONTACT:
      return {
        ...state,
        agencyInfo: {
          ...state.agencyInfo,
          users: state.agencyInfo.users.map((user, index) =>
            action.index === index
              ? { ...user, primaryContact: action.value }
              : { ...user, primaryContact: false }
          ),
        },
      };
    case AGENCY_USER_DELETE:
      return {
        ...state,
        agencyInfo: {
          ...state.agencyInfo,
          users: [
            ...state.agencyInfo.users.slice(0, action.index),
            ...state.agencyInfo.users.slice(action.index + 1),
          ],
        },
      };
    case AGENCY_USER_ADD:
      return {
        ...state,
        agencyInfo: {
          ...state.agencyInfo,
          users: [{ ...agencyUserFormat }, ...state.agencyInfo.users],
        },
      };
    case adminOperationType[action.type]:
      return {
        ...state,
        [adminOperationTypeHash[action.type]]: action.value,
      };
    case agencyOperationType[action.type]:
      return {
        ...state,
        [agencyOperationTypeHash[action.type]]: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
