import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    candidates:[],
    info:{},
    test:{},
    question:[],
    details:{},
    testDetails:{},
    duplicates:[],
  excelDuplicates: {},
    menuPage:"",
     columnFeature:[],
     allColumn:[],
     statuses:{},
     modalOpen:false,
     scheduleDetails:[],
     regLinkModalOpen:false,
     candType:""
    
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  
  reducers: {
    updateCandidate(state, action) {
      state.candidates = [...action.payload];
    },
    updateInfo(state, action) {
      state.info = { ...action.payload };
    },
   updateDetails(state,action)
   {
    state.details = {...action.payload.details}
   },
   updateDuplicates(state,action){
     state.duplicates=[...action.payload.duplicates]
   },
   updateExcelDuplicates(state,action)
   {
     state.excelDuplicates={...action.payload}
    },
    updateMenuPage(state,action) {
     state.menuPage=action.payload
   },
   updateColumns(state,action){
    state.columnFeature=[...action.payload]
   },
   AllColumns(state,action){
     state.allColumn=[...action.payload]
   },
   updateStatuses(state,action){
      state.statuses={...action.payload}
   },
   updateModalOpen(state,action){
    state.modalOpen=action.payload;
   },
   updateRegLinkModalOpen(state,action){
    state.regLinkModalOpen=action.payload;
   },
   updateRegLinkcandType(state,action){
    state.candType=action.payload;
   },

   updateSheduleDetails(state,action){
    state.scheduleDetails=action.payload;
   }
   
  },
});

export default adminSlice.reducer;

export const adminActions = adminSlice.actions;