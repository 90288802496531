import React, { useEffect, useState, useRef } from "react";
import { Modal, Select, Row, Col, Input, Button, Typography ,Space} from "antd";
import { useQuill } from 'react-quilljs';
// import 'react-quill/dist/quill.snow.css';
// import CustomQuillEditor from './quillEditor';
import ReactHtmlParser from "react-html-parser";
import { LinkOutlined, CloseOutlined } from '@ant-design/icons';
import AttachmentLogo from '../../assets/attachment.svg';
import CrossIcon from '../../assets/cross.png';
import Api from '../../services/Api';
import PdfLogo from '../../assets/pdf-logo.svg'
// import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
// import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import {useSelector ,useDispatch} from 'react-redux'; 
import { templateActions } from "./store";

const { Title } = Typography;

export function EmailTemplateBody(props){

    const quillEditorValue = React.useRef();
    const files = React.useRef([]);
    const [selectedTemplateObj, setTemplateObj] = React.useState();
    const [fileList, setFiles] = React.useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isPreviewEnabled, setPreview] = useState(false);
    const [isQuillUpdated, setQuillStatus] = useState(false);
    const [isDeleteModal,setDeleteModal] = useState(false);
    const [selectedAttachment,setAttachment] = useState({item : "", index : ""});
    const canvasEl = useRef(null);
    const template = useSelector(state => state?.templates?.list);
    const dispatch = useDispatch();
    const [content, setContent] = useState({
        type: "",
        subject: "",
        body: ""
    });
    // const content=props?.content;
    // const setContent=props?.setContent;
    const createContent=props?.createContent;
    const setCreateContent=props?.setCreateContent;
    

    const getfileType = (item) => {

        switch (item.type) {
            case "pdf":
                return <img src={PdfLogo} onClick={() => { window.open(item.name) }} style={{ height: "100px", width: "100px" }} />
                break;
            default:
                return <img src={item.name} onClick={() => { window.open(item.name) }} style={{ height: "100px", width: "100px" }} />;
                break;
        }
    }



    const { quill, quillRef, Quill } = useQuill({
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ align: [] }],

                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ size: ['small', false, 'large'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                ['clean'],
                ['link', { linkClass: 'custom-link' }], 
            ],

        },
        placeholder: "Compose a Email Template..."
    });

    const fetchTemplate = () => {
        Api.get("/hiring-template/all").send((res) => {
            if (res && res.length > 0) {
            dispatch(templateActions.updateTemplates(res));
            }
          });
    }



    useEffect(() => {
        if (template && template.length > 0 && props.templateObj) {
            let tempSelectedTemp = template.filter(a => a.id == props.templateObj.id)[0];
            
            
            // setContent({type : props.selectedObject})
            setContent({
                type: tempSelectedTemp?.type,
                subject: tempSelectedTemp?.subject,
            })

            if (document.querySelector("#email-preview")) {
                document.querySelector("#email-preview").innerHTML = tempSelectedTemp.body;
            }

          

            if (document.querySelector(".quill-editor-menu")) {
                let attachementElement = document.createElement('div');
                attachementElement.setAttribute("id", "qull_attachment_id");
                document.querySelector(".quill-editor-menu").appendChild(attachementElement);
            }
            setTemplateObj(tempSelectedTemp);
        }
    }, [template]);

    React.useEffect(() => {
        

        if (quill && !isQuillUpdated) {
           
            quill.on('text-change', (delta, oldDelta, source) => {
                
             

            });

            setQuillStatus(true)
        }
        forceUpdate();
    }, [quill, isPreviewEnabled]);


    React.useEffect(() => {
        if (props && props.templateObj && props.templateObj.body) {
            
            htmlToDelta(props.templateObj.body)
            forceUpdate();
        }
    }, [isPreviewEnabled,template])




    const createTemplate = () => {
    
        let requestBody 
        if(props?.createTempIndex===0)
        {
             requestBody = { ...createContent, body: quill.root.innerHTML }
        }
        else
        {
             requestBody = { ...content, body: quill.root.innerHTML }
        }
      
        

        if (fileList.length > 0) {
       
            requestBody['files'] = fileList
        }

        if (props.templateObj) {
          
            Api.patch(`/hiring-template/update/${props.templateObj.id}`).uploadAndPatch(requestBody, (per,res) => {
                
              if(res?.data?.show.type==="success"){
                if(!props.sendEmail){
                    // props.close();
                }
                props.close();
                props.refresh()
               
                
                // fetchTemplate();
                setPreview(false);
            }
            })
        } else {
           
          
            Api.post("/hiring-template/create").uploadAndPost("/hiring-template/create", requestBody, (per,res) => {
                if(res?.data?.show?.type==="success"){ 
                 props.refresh()
                 props.close();
                }
                
            })
        
        
    }
    }

    const deleteAttachment = () => {
        Api.delete(`/hiring-template/removeattachment/${selectedAttachment.item.id}`).send(res => {

            document.querySelector(`#attachment${selectedAttachment.index}`).remove();
            // setAttachment({item : "", index : ""});
            fetchTemplate();
            setDeleteModal(false);
            if(res)
            {
                setBtnLoader(false);
            }
        })
    }


    const htmlToDelta = (html) => {
     
        if (quill) {
            if (!document.querySelector('#attachment-container')) {
                

               //settting attachment in case of edit using dom

                let attachmentElement = document.createElement("div");
                selectedTemplateObj.attchments.map((item, index) => {
                    let attachementDoc = document.createElement('span');
                    attachementDoc.innerHTML = item.name;
                    let crossElement = document.createElement('img');
                    crossElement.setAttribute('src', CrossIcon);
                    crossElement.setAttribute("class",'cross-css');
                    crossElement.addEventListener('click', () => {
                        setAttachment({item : item, index : index})
                        setDeleteModal(true);
                        
                    })
                    attachementDoc.setAttribute('class', "attachment-link");
                    let row = document.createElement('div');
                    row.setAttribute('id', `attachment${index}`);
                    row.appendChild(attachementDoc);
                    row.appendChild(crossElement);
                    attachmentElement.appendChild(row)
                })


                if (document.querySelector(".ql-container ") && document.querySelector(".quill-editor-menu")) {
                    let parentNode = document.querySelector(".quill-editor-menu");
                    attachmentElement.setAttribute('id', "attachment-container")
                    parentNode.insertBefore(attachmentElement, document.querySelector(".ql-container"));
                }


                // setting quill editor value in case of updation 
                // to update html values directly to quill , we first need to convert html to delta format 
                // and then using setContent method of quill we can do update html to quill editor.

                const div = document.createElement('div');
                div.setAttribute('id', 'htmlToDelta');
                
                div.innerHTML = `<div id="quillEditor"
                  style="display:none"
                 >${html}</div>`;
                
                document.body.appendChild(div);
                var tempCont = document.createElement("div");
                tempCont.setAttribute('id', 'htmlToDelta');
                
                tempCont.innerHTML = `<div id="quillEditor" style="display:none"><div>${html}</div></div>`;
                // tempCont.innerHTML = `${html}`;
                let quillElement = new Quill(tempCont);
                
                document.getElementById('htmlToDelta').remove();
                quill.updateContents(quillElement.getContents());
            }
        }

    }


    



    const quillEditor = () => {
       
        return (
        <div  >
            <div style={{marginLeft:"30px",marginBottom:"18px"}} className={((!isPreviewEnabled && props.type != "preview") || isPreviewEnabled) ? "quill-editor-menu" : "d-none"}
            >

                <div id="qull_attachment_id"  ></div>

                <div ref={quillRef}  >


                    {/* <div className={((!isPreviewEnabled && props.type != "preview") || isPreviewEnabled) ?  "quill-editor-menu":"d-none"} >
                   {
                        props.templateObj && props.templateObj.attchments && props.templateObj.attchments.length > 0 ? <div>
                            {
                                props.templateObj.attchments.map((item,index) => {
                                    return  <div style={{ margin: "10px" }} key={index + "update"}><span className="attachment-link">{item.name + " " }</span>
                                    <span><CloseOutlined onClick={() => {
                        
                                    }} /></span></div>
                                })
                            }
                        </div> : ""
                    }
                   </div> */}

                    {
                        fileList.map(e => {
                            return <div style={{ margin: "10px", }} key={e.className + "add"}><span className="attachment-link">{e.name + " " + (e.size / 1024) + " kb"}</span>
                                <span><CloseOutlined onClick={() => {
                                    let temp = fileList.filter(a => a.name != e.name);
                                    setFiles(temp)
                                    forceUpdate();
                                }} /></span></div>
                        })
                    }


                </div>


            </div>

            {
                (props.templateObj && !isPreviewEnabled) &&props.createTempIndex!==0&&
                <div >
                    <div style={{whiteSpace:"pre-line",marginBottom:"1rem"}} id={"email-preview"}>
                        {/* {ReactHtmlParser(selectedTemplateObj?.body)} */}
                    </div>
                    {
                        props.templateObj.attchments && props.templateObj.attchments.length > 0 ? <div>
                            {
                                props.templateObj.attchments.map((item, index) => {
                                    return <a key={index + "embed"} style={{ margin: "10px" }}>{getfileType(item)}</a>

                                })
                            }
                        </div> : ""
                    }

                </div>
            }



        </div>)
    }


    const deleteModal = () => {
        
        return <Modal visible={isDeleteModal} footer={false} onCancel={()=>{setDeleteModal(false)}}>
        <Row justify="center">
        <p ><b>Delete?</b></p></Row>
        <Row justify="center"><p> Are you sure to delete ?</p></Row>
        <Row justify="center">
            <Space><Button style={{borderColor:"#4DAAFF",color:"#4DAAFF"}}
             onClick={() => {
                  setAttachment({item : "", index : ""})
                  setDeleteModal(false) 
                  }}>Cancel</Button>
          <Button  
          style={{ backgroundColor: "#FF9797",borderColor:"#FF9797",color:"white" }} onClick={() => {
                        deleteAttachment()
                    }}                
      >Yes</Button></Space></Row>
      </Modal>
    }


    return (
        <div style={{ padding: "0 20px 0 20px" }}>
                <div style={{display:"flex",flexDirection:"column",marginBottom:"1rem"}}>
                    <Row>
                        <Col style={{marginRight:"0.5rem"}}><span className="vertical-align-middle" style={{fontWeight:"bold",}}>To</span></Col>
                        <Col span={23}>{}
                            <Select defaultValue="" bordered={true} placeholder="To" value={props?.createTempIndex===0?undefined: content.type} style={{ width: "100%",outline:"none",marginBottom:"1rem" }}  onChange={(items) => {
                                
                                if(props?.createTempIndex===0)
                                {
                                    setCreateContent({...createContent,type:items})
                                    
                                }
                                setContent({ ...content, type: items });
                            }
                            }>
                                
                                <Select.Option value="Candidate">Candidate</Select.Option>
                                    <Select.Option value="Institution">Institute</Select.Option>
                                
                            </Select>
                        </Col>
                    </Row>
                    <Row >
                        {/* <Col span={2}><span className="vertical-align-middle">Subject</span></Col> */}
                        <Col span={23} style={{border:"1px solid #d0cfcf",marginLeft:"1.5rem"}}>
                            <Input className="subject-input-border" style={{ width: "90%", }} placeholder="Subject" 
                            onChange={(e) => { 
                                if(props?.createTempIndex===0)
                                {
                                    setCreateContent({...createContent,subject: e.target.value})
                                    
                                }
                                setContent({ ...content, subject: e.target.value });

                                 }} value={props?.createTempIndex===0?undefined: content.subject} />
                        </Col>
                    </Row>
                </div>
                {quillEditor()}
                <div >
                    <input type="file" id="attachment" style={{ display: "none" }} onChange={(e) => {

                     
                        setFiles([...fileList, ...e.target.files])
                    }} accept={"*"} multiple />
                </div>

                {
                    (isPreviewEnabled || props.type !== "preview") && <div className="d-flow-root">
                        <Button type="primary" style={{ float: "right",marginLeft:"1rem",width:"8rem",fontWeight:"bold" }}
                            onClick={()=>{
                                if(props.type === "preview" )
                                {
                                    props.setEditedTemp({edited:true,index:props.editedIndex})
                                }                            
                                createTemplate();
                                // props.close();                             
                                
                            }                               
                            }
                        >Save</Button>
                        <Button
                            style={{ float: "right", margin: "0px", padding: "0px" }}
                            onClick={() => {
                                document.querySelector("#attachment").click();
                            }}><img src={AttachmentLogo} style={{ height: "15px", width: "15px", margin: "9px" }} /> </Button>
                    </div>
                }
                <div>

                    {
                        (!isPreviewEnabled && props.type === "preview") && <Row justify="center" style={{ margin: "35px" }}>
                            <Col span={6}>
                                <Button
                                    //   onClick={props.toggle} 
                                    style={{ float: "right", margin: "10px", width:"8rem",fontWeight:"700"}}
                                    onClick={() => props.close()}
                                >
                                    Cancel
                             </Button>
                            </Col>
                            <Col span={6} >
                                <Button
                                    // loading={btnLoader}
                                    style={{ margin: "10px", width:"8rem",fontWeight:"700",}}
                                    type="primary"
                                    htmlType="submit"
                                    onClick={() => setPreview(true)}

                                // loading={btnLoader}
                                >
                                    Edit
                          </Button>
                            </Col>
                        </Row>

                    }
                </div>
                {
                isDeleteModal && deleteModal()
            }
            </div>
    )

}


const CreateModal = (props) => {
    return (
        <Modal 
                centered
                open={props.isModalVisible}
                className="email-modal"
                footer={false}
                onCancel={() => props.close()}
                width={"65%"}
               
        >
            <div style={{ padding: "1rem",marginBottom:"1rem"}}>
                <Title style={{ textAlign: "center", fontSize: "20px",fontWeight:"bold",marginBottom:"0.8rem", }}>{props.type === "preview" ? "Email Preview" : "New Email Template"}</Title>
                <div style={{height:"400px",overflowY:"auto",}}><EmailTemplateBody {...props}/></div>
   
            </div>
        </Modal>
    )
}
export default CreateModal;
