import React,{useState,useEffect} from "react";
import { Form, Input, Button, Typography, Card, Row, Col, Modal } from "antd";
import Designbackground from "./design-background";
import { Api } from "services";
import brigoshaIcon from "../../assets/brigosha_logo.svg";
import {Notification} from "services";
import classes from './resetPassword.module.css';
import { useHistory } from "react-router-dom";
import { center } from "@antv/g2plot/lib/plots/sankey/sankey";
const ResetPassword =() =>{
  const history=useHistory()
    let url = new URL(window.location.href);
    let userId = url.searchParams.get("userId");
    let token = url.searchParams.get("token");
 const [input,setInput]=useState("")
 const [input1,setInput1]=useState("")
 const channel = new BroadcastChannel('password-change-channel');

    return (
        <div className={classes.recovery}>
                    <div className={classes.moveIcon}>
                    <Designbackground value="Forgot"/>
                    </div>
                    <div>
                                <div>
                                          <img src={brigoshaIcon} alt="brigosha" />
                                </div>                        
                                <div>
                                <Row justify={center} style={{alignItems:"center",justifyContent:"center"}}>
                                <h3 style={{fontWeight:"700",marginRight:"5px",padding:"0",fontSize:"1.2rem",}}>Password</h3>
                                <span style={{fontSize:"1.2rem",fontWeight:"700", color: "#0086FF"}}>{" "}Recovery </span>
                                </Row>
                                <h4 style={{textAlign:"center",fontSize:"14px",fontWeight:"600",color:"#636363",marginTop:"10px"}}> Please Enter your New Password.</h4>
                                <Row style={{marginTop:"40px"}}>
                                    Password
                                </Row>
                              <Row>
                                <Input.Password  onChange={(e) =>{
                                    let value=e.target.value
                                    setInput(value)
                                }}/></Row>
                                  <Row style={{marginTop:"40px"}}>
                                    Confirm Password
                                </Row>
                              <Row>
                                <Input.Password  onChange={(e) =>{
                                    let value=e.target.value
                                    setInput1(value)
                                }}/></Row>
                                <Row style={{marginTop:"50px"}}>
                                <Button type="primary" style={{width:"100%",marginBottom:"30px"}} onClick={() =>{
                                    if(input1 !== input) {
                                        return Notification.error('Error', 'Password and Confirm Password should be the same!');
                                      }                           
                                      Api.patch("/hiring-auth/updatepassword")
                                      .params({ userId: parseInt(userId), password: input,passwordToken:token })
                                      .send((response, error) => {
                                        if (response !== undefined) {
                                          history.push("/login");
                                        }
                                        // dispatch(toggleResetLoader(false));
                                      });                                                                            
                                }}>SUBMIT</Button></Row>
                              </div>
                    </div>
      </div>
    )
}
export default ResetPassword